import React, { useState } from 'react';
import * as Yup from 'yup';
import { Grid, Box, Typography, Link } from '@mui/material';
import { useStyles } from '../ProfileIntegration/styles';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Button, Form } from 'src/components/shared';
import Stack from '@mui/material/Stack';

export default function Notification({ editUser, status, ...props }) {
  const classes = useStyles();
  const [dailyReminder] = useState(false);
  const [productUpdates] = useState(false);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    flexDirection: 'initial',
    textAlign: 'left',
    color: theme.palette.text.secondary,
    gridRow: '1',
    gridColumn: 'span 3',
  }));

  const handleSubmit = async (val, name) => {
    const data = {};
    if (name === 'dailyReminder') {
      data.dailyReminder = val;
    } else if (name === 'productUpdates') {
      data.productUpdates = val;
    }
    if (editUser && editUser?.id) {
      const updatedRow = { id: editUser?.id, data: data };
      await props.putUser(updatedRow);
    }
  };

  return (
    <>
      <Form
        initialValues={{
          dailyReminder: editUser.dailyReminder || false,
          productUpdates: editUser.productUpdates || false,
        }}
        validationSchema={Yup.object().shape({})}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {(props) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                props.submitForm();
                return false;
              }}
              style={{ height: '100%' }}
              noValidate
            >
              <Item>
                <Box mb={2}>
                  <Typography fontSize={'18px'} fontWeight={500} color={'#000000DE'}>
                    Email Notification
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  height="100%"
                >
                  <Stack direction="column" justifyContent="end">
                    <Typography variant="h4" className={classes.headerTitle}>
                      Daily task reminder
                    </Typography>
                    <Typography variant="body2" className={classes.headerSubTitle} mb={2}>
                      Get daily reminders of todays tasks that need to be done.
                    </Typography>
                  </Stack>

                  <Stack display="flex" pb="2">
                    <Form.Field.Switch
                      name="dailyReminder"
                      label=""
                      onChange={(val) => {
                        handleSubmit(val, 'dailyReminder');
                      }}
                    />
                  </Stack>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  height="100%"
                >
                  <Stack direction="column" justifyContent="end">
                    <Typography variant="h4" className={classes.headerTitle}>
                      Product updates
                    </Typography>
                    <Typography variant="body2" className={classes.headerSubTitle} mb={2}>
                      Stay upto date with the latest features being released by SuperReach
                    </Typography>
                  </Stack>

                  <Stack display="flex" pb="2">
                    <Form.Field.Switch
                      name="productUpdates"
                      label=""
                      onChange={(val) => {
                        handleSubmit(val, 'productUpdates');
                      }}
                    />
                  </Stack>
                </Box>
              </Item>
              {/* <Item sx={{ marginTop: '36px' }}>
                <Box mb={2}>
                  <Typography fontSize={'18px'} fontWeight={500} color={'#000000DE'}>
                    In-app Notifications
                  </Typography>
                </Box>
                <Box mt={4}>
                  <Typography fontSize={'16px'} fontWeight={500} color={'#000000DE'}>
                    Emails:
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  height="100%"
                >
                  <Stack direction="column" justifyContent="end">
                    <Typography variant="h4" className={classes.headerTitle1}>
                      New Replies
                    </Typography>
                  </Stack>

                  <Stack display="flex" pb="2">
                    <Form.Field.Switch
                      name="dailyReminder"
                      label=""
                      onChange={(val) => {
                        // handleSubmit(val, 'dailyReminder');
                      }}
                    />
                  </Stack>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  height="100%"
                >
                  <Stack direction="column" justifyContent="end">
                    <Typography variant="h4" className={classes.headerTitle1}>
                      Bounce Notifications
                    </Typography>
                  </Stack>

                  <Stack display="flex" pb="2">
                    <Form.Field.Switch
                      name="dailyReminder"
                      label=""
                      onChange={(val) => {
                        // handleSubmit(val, 'dailyReminder');
                      }}
                    />
                  </Stack>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  height="100%"
                >
                  <Stack direction="column" justifyContent="end">
                    <Typography variant="h4" className={classes.headerTitle1}>
                      Out of Office (OOO) Notifications
                    </Typography>
                  </Stack>

                  <Stack display="flex" pb="2">
                    <Form.Field.Switch
                      name="dailyReminder"
                      label=""
                      onChange={(val) => {
                        // handleSubmit(val, 'dailyReminder');
                      }}
                    />
                  </Stack>
                </Box>
                <Box mt={4}>
                  <Typography fontSize={'16px'} fontWeight={500} color={'#000000DE'}>
                    Enrolments:
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  height="100%"
                >
                  <Stack direction="column" justifyContent="end">
                    <Typography variant="h4" className={classes.headerTitle1}>
                      Enrolment Success Notifications
                    </Typography>
                  </Stack>

                  <Stack display="flex" pb="2">
                    <Form.Field.Switch
                      name="dailyReminder"
                      label=""
                      onChange={(val) => {
                        // handleSubmit(val, 'dailyReminder');
                      }}
                    />
                  </Stack>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  height="100%"
                >
                  <Stack direction="column" justifyContent="end">
                    <Typography variant="h4" className={classes.headerTitle1}>
                      Enrolment Error Notifications
                    </Typography>
                  </Stack>

                  <Stack display="flex" pb="2">
                    <Form.Field.Switch
                      name="dailyReminder"
                      label=""
                      onChange={(val) => {
                        // handleSubmit(val, 'dailyReminder');
                      }}
                    />
                  </Stack>
                </Box>

                <Box mt={4}>
                  <Typography fontSize={'16px'} fontWeight={500} color={'#000000DE'}>
                    Auto Add to CRM:
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  height="100%"
                >
                  <Stack direction="column" justifyContent="end">
                    <Typography variant="h4" className={classes.headerTitle1}>
                      Auto Add to CRM Success Notifications
                    </Typography>
                  </Stack>

                  <Stack display="flex" pb="2">
                    <Form.Field.Switch
                      name="dailyReminder"
                      label=""
                      onChange={(val) => {
                        // handleSubmit(val, 'dailyReminder');
                      }}
                    />
                  </Stack>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  height="100%"
                >
                  <Stack direction="column" justifyContent="end">
                    <Typography variant="h4" className={classes.headerTitle1}>
                      Auto Add to CRM Error Notifications
                    </Typography>
                  </Stack>

                  <Stack display="flex" pb="2">
                    <Form.Field.Switch
                      name="dailyReminder"
                      label=""
                      onChange={(val) => {
                        // handleSubmit(val, 'dailyReminder');
                      }}
                    />
                  </Stack>
                </Box>
              </Item> */}
            </form>
          );
        }}
      </Form>
    </>
  );
}
