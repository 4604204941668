import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import parse from 'html-react-parser';
import CloseIcon from '@mui/icons-material/Close';
import useStyles from './components/sessionDetail/styles';
import { Typography } from '@mui/material';
import { themeColors } from '../../config';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: 'fitContent',
  width: 'fitContent',
  outline: 'none',
  transform: 'translate(-50%, -50%)',
};

function VideoFrame({
  VideoUrl,
  videoList = false,
  open = false,
  isFullScreen = false,
  handleClose = () => {},
  ...props
}) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        open={open}
        sx={{ zIndex: '1501' }}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {videoList ? (
          <Box className={classes.videoFrameContainer}>
            <Box display="flex" justifyContent="right">
              <CloseIcon onClick={handleClose} className={classes.closeIcon} />
            </Box>
            <Box className={classes.videoFrameBox}>{parse(VideoUrl || '')}</Box>
          </Box>
        ) : (
          <>
            {VideoUrl ? (
              <Box sx={!isFullScreen ? style : { ...style, height: '100%', width: '100%' }}>
                {parse(VideoUrl || '')}
              </Box>
            ) : (
              <Box sx={!isFullScreen ? style : { ...style, height: '100%', width: '100%' }}>
                <Typography variant="h1" color={themeColors.yellow}>
                  Video not found
                </Typography>
              </Box>
            )}
          </>
        )}
      </Modal>
    </div>
  );
}

export default VideoFrame;
