import React, { useState, useMemo } from 'react';
import { Card, CardContent, Box, Stack, Typography } from '@mui/material';
import { Spacer, Button } from 'src/components/shared';
import BillingAddressForm from './BillingAddressForm';

import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

function BillingDetailsCard({ customer, chargeBeeId }) {
  const [showForm, setShowForm] = useState(false);

  const BillingItem = ({ title, value }) => {
    return (
      <Stack direction="row" mb={2}>
        <Box flex={1}>
          <Typography color="textSecondary">{title}</Typography>
        </Box>
        <Box flex={3}>
          <div dangerouslySetInnerHTML={{ __html: value }} style={{ lineHeight: '1.6em' }} />
        </Box>
      </Stack>
    );
  };

  const billingAddress = useMemo(() => {
    const billing = customer.billing_address;
    return `${billing?.line1 ? billing.line1 + ', <br/>' : ''}${
      billing?.city ? billing.city + ', ' : ''
    }${billing?.state ? billing.state + ' - ' : ''}${billing?.zip ? billing?.zip + ', ' : ''} ${
      billing?.country || ''
    }`;
  }, [customer]);

  const billingContact = useMemo(() => {
    return `${customer?.first_name || ''} ${customer?.last_name || ''}<br />${
      customer?.email || ''
    }`;
  }, [customer]);

  const otherBilling = '';
  const VAT = '';

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  return (
    <>
      <Card>
        <CardContent>
          <Stack direction="row" justifyContent="space-between">
            <Typography color="textPrimary" sx={{ fontWeight: 500 }}>
              Edit Billing Details
            </Typography>
            <Button iconButton onClick={toggleForm}>
              {showForm ? <CloseIcon /> : <EditIcon />}
            </Button>
          </Stack>

          <Spacer x={3} y={3} />
          {showForm ? (
            <BillingAddressForm
              customer={customer}
              chargeBeeId={chargeBeeId}
              onClose={toggleForm}
            />
          ) : (
            <>
              <BillingItem title="Company name" value={customer?.company || ''} />
              <BillingItem title="Billing address" value={billingAddress} />
              <BillingItem title="Billing contact" value={billingContact} />
              <BillingItem title="Other billing" value={otherBilling} />
              <BillingItem title="Currency" value={customer?.preferred_currency_code || ''} />
              <BillingItem title="VAT" value={VAT} />
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
}

export default BillingDetailsCard;
