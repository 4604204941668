import React from 'react';
import Autocomplete from '@mui/lab/Autocomplete';
import { Box, Popper, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  autoCompleteTags: {
    '& .MuiChip-root': {
      backgroundColor: '#4789C2',
      color: '#FFFFFF',
    },
    '& .MuiChip-deleteIcon': {
      color: 'rgba(255, 255, 255, 0.7)',
      '&:hover': {
        color: 'rgba(255, 255, 255, 0.6)',
      },
    },
  },
}));

/**
 * Main basic or default complaint for the application for the auto complete
 * @param props
 * @returns {*}
 * @constructor
 */
const AutoCompleteSelect = (props, ref) => {
  const classes = useStyles();
  const { renderInput, ...rest } = props;
  return (
    <Box className={classes.autoCompleteTags}>
      <Autocomplete {...rest} ref={ref} renderInput={(params) => renderInput(params)} />
    </Box>
  );
};

/**
 * render option with info icon
 */
export const AutoCompleteRenderInfoOption = ({ title, helperText }) => {
  return (
    <Box width={1} display="flex" justifyContent="space-between">
      <Typography noWrap>{title}</Typography>
      <Tooltip title={helperText} placement="bottom">
        <InfoIcon style={{ fill: '#a3a3a3' }} />
      </Tooltip>
    </Box>
  );
};

/**
 * Popover component of the auto select cna be use to make autocomplete customize
 * @param props
 * @returns {*}
 * @constructor
 */
export const AutoCompletePopper = (props) => {
  return <Popper {...props} style={{ width: 'fit-content' }} placement="bottom-start" />;
};

export default React.forwardRef(AutoCompleteSelect);
