import React from 'react';
import { Typography, Box } from '@mui/material';
import { Spacer } from 'src/components/shared';
import { SequenceWrapper } from './styles';
import { Link, useHistory } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import { Menu, Button } from 'src/components/shared';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';

const anchorStyle = {
  color: '#1976D2',
  textDecoration: 'underline',
};

function SequenceItem({ type, title, date = new Date(), id }) {
  const history = useHistory();

  function isToday(date = new Date()) {
    const today = new Date();
    const compare = new Date(date);
    if (today > compare) {
      return true;
    }
    return false;
  }

  const openSequenceDetails = (id) => {
    if (id && id !== '') {
      let path = `/sequence/${id}/steps`;
      history.push(path);
    }
  };

  const getIcon = (id) => {
    switch (type) {
      case 'linkedin':
        return (
          <>
            <LinkedInIcon style={{ fill: '#1282B2', width: 24, height: 24 }} />
            <Spacer x={1} />
            <Link
              className="text-truncate td-Link-content-ellipsis"
              to={`/sequence/${id}`}
              target="_blank"
              style={anchorStyle}
            >
              Send Linkedin Message
            </Link>
          </>
        );
        break;

      case 'call':
        return (
          <>
            <CompleteI style={{ fill: '#66BB6A', width: 24, height: 24 }} />
            <Spacer x={1} />
            <Link
              className="text-truncate td-Link-content-ellipsis"
              to={`/sequence/${id}`}
              target="_blank"
              style={anchorStyle}
            >
              Call Back
            </Link>
          </>
        );
        break;

      case 'unpublished':
        return (
          <>
            <UnpublishedIcon style={{ fill: '#66BB6A', width: 24, height: 24 }} />
            <Spacer x={1} />
            <Link
              className="text-truncate td-Link-content-ellipsis"
              to={`/sequence/${id}`}
              target="_blank"
              style={anchorStyle}
            >
              Unpublished
            </Link>
          </>
        );
        break;

      case 'published':
        return (
          <>
            <DoneOutlineIcon style={{ fill: '#66BB6A', width: 24, height: 24 }} />
            <Spacer x={1} />
            <Link
              className="text-truncate td-Link-content-ellipsis"
              to={`/sequence/${id}`}
              target="_blank"
              style={anchorStyle}
            >
              Published
            </Link>
          </>
        );
        break;

      default:
      case 'email':
        return (
          <>
            <EmailIcon style={{ fill: '#42A5F5', width: 24, height: 24 }} />
            <Spacer x={1} />
            <Link
              className="text-truncate td-Link-content-ellipsis"
              to={`/sequence/${id}`}
              target="_blank"
              style={anchorStyle}
            >
              Send Email
            </Link>
          </>
        );
        break;
    }
  };

  return (
    <SequenceWrapper>
      <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
        <Box mt={1}>
          <Typography className="text-truncate td-Link-content-ellipsis">{title}</Typography>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          {isToday(date) ? (
            <Typography variant="body2" color="danger" sx={{ color: 'red' }}>
              Over Due: {moment(date).format('llll')}
            </Typography>
          ) : (
            <Typography variant="body2" color="textSecondary">
              Due: {moment(date).format('llll')}
            </Typography>
          )}
          <Menu
            options={[
              {
                label: 'Edit Steps',
                icon: <EditIcon />,
                onClick: () => openSequenceDetails(id),
              },
            ]}
          />
        </Box>
      </Box>

      <Box display="flex" flexDirection="row">
        {getIcon(id)}
      </Box>
    </SequenceWrapper>
  );
}

export default SequenceItem;
