import React, { useEffect, useState } from 'react';
import { Box, Typography, Avatar, ButtonBase } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { deepOrange, green } from '@mui/material/colors';
import VideoStableIcon from '@mui/icons-material/VideoStable';
import useStyles from './styles';
import { themeColors } from '../../config';

const Pillar = ({ option, data, handleClick, onClose }) => {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const classes = useStyles();
  return (
    <ButtonBase
      onClick={() => {
        handleClick(data);
        onClose();
      }}
      sx={{ width: '100%', justifyContent: 'flex-start' }}
    >
      <Box display="flex" flexDirection="row" p={1} ml={1}>
        <Avatar
          size={26}
          variant="circle"
          sx={{ bgcolor: themeColors.greenText, width: '36px', height: '36px', marginTop: '4px' }}
        >
          <VideoStableIcon sx={{ fontSize: '1.25rem', color: 'white' }} />
        </Avatar>
        <Box ml={2} maxWidth={300} display="flex" alignItems="flex-start" flexDirection="column">
          <Typography
            noWrap
            variant="body1"
            className={classes.title}
            sx={{ color: themeColors.greyText }}
          >
            {data.title}
          </Typography>
          <Typography noWrap variant="body2" sx={{ color: themeColors.greyText }}>
            {capitalizeFirstLetter(data.contentType)}
          </Typography>
        </Box>
      </Box>
    </ButtonBase>
  );
};

export default Pillar;
