import React, { useRef } from 'react';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Box, Typography, InputAdornment, Input } from '@mui/material';
import { Form, Button, Spacer } from 'src/components/shared';
import { cacheTest, checkUniqueUserEmail } from 'src/utils/validation';

import MailIcon from '@mui/icons-material/Mail';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const RegistrationForm = ({ onSubmit }) => {
  const [loading, setLoading] = React.useState(false);
  const emailUniqueTest = useRef(cacheTest(checkUniqueUserEmail));

  return (
    <Form
      initialValues={{
        email: '',
        terms: false,
      }}
      validationSchema={Yup.object().shape({
        terms: Yup.boolean().oneOf([true], 'Please agree to terms and conditions.'),
        email: Yup.string()
          .required('Email is required!')
          .email('Please enter a valid email address.')
          .test(
            'username-backend-validation',
            'This email is already taken!',
            async (value, { createError }) => {
              return emailUniqueTest.current(value, {
                createError,
                skipAllowed: false,
              });
            },
          ),
      })}
      onSubmit={onSubmit}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            noValidate
          >
            <Form.Field.Input
              fullWidth
              variant="outlined"
              name="email"
              placeholder="Email"
              disabled={loading}
              autoComplete="on"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailIcon style={{ fill: '#d1d1d1' }} />
                  </InputAdornment>
                ),
              }}
            />
            <Spacer x={2} y={2} />
            <Form.Field.Checkbox
              name="terms"
              label={
                <Typography variant="body2" component="span">
                  Get emails about product updates, industry news, and events. If you change your
                  mind, you can unsubscribe at any time. I accept{' '}
                  <a href="http://superreach.com/terms-of-service.html" target="_blank">
                    <Typography variant="body2" color="secondary" component="span">
                      Privacy Policy and Terms
                    </Typography>
                    .
                  </a>
                </Typography>
              }
            />
            <Spacer x={1} y={1} />

            <Box mt={2}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                loading={loading}
                fullWidth
                size="large"
                endIcon={<ArrowForwardIcon />}
              >
                Next
              </Button>
            </Box>
            <Box
              mt={3}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Box mt={1} display="flex" justifyContent="flex-end">
                <Typography color="textSecondary" variant="body2">
                  Already have an account?{' '}
                  <Link to="/auth/login">
                    <Typography color="secondary" variant="body2" display="inline" component="span">
                      Log in
                    </Typography>
                  </Link>
                </Typography>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
};

export default RegistrationForm;
