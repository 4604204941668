import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { Stack, Box } from '@mui/material';
import { Modal, Form, Button } from 'src/components/shared';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';
import { useDataProviders } from '../../utils/dataProviderStore';
import { isEmpty } from 'lodash';

function CreditAllocationForm({ editCredit, open, onClose }) {
  const [loading, setLoading] = useState(false);
  const { postCreditAllocation, putCreditAllocation } = useDataProviders();

  const handleSubmit = async (data) => {
    try {
      setLoading(true);
      if (isEmpty(editCredit)) {
        await postCreditAllocation(data);
      } else {
        await putCreditAllocation(data);
      }

      onClose();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const defUser = useMemo(() => {
    if (editCredit && editCredit?.user && editCredit?.user?.id) {
      return {
        ...editCredit?.user,
        name: `${editCredit?.user?.fname} ${editCredit?.user?.lname}`,
      };
    }
    return {};
  }, [editCredit]);

  return (
    <Modal title="Add Bespoke credit allocation" open={open} onClose={onClose} size="sm">
      <Form
        initialValues={{
          user: defUser,
          credit: editCredit?.credits,
        }}
        validationSchema={Yup.object().shape({
          user: Yup.object().test('country', 'Please choose a user.', function (item) {
            return item?.id && item?.id !== '';
          }),
          credit: Yup.number().positive().min(0),
        })}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ values, ...formProps }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formProps.submitForm();
                return false;
              }}
              noValidate
            >
              <Stack direction="row" gap={2}>
                <Form.Field.AutoComplete
                  multiple={false}
                  fullWidth
                  options={[defUser]}
                  variant="outlined"
                  placeholder="Search user..."
                  remoteMethod={(search) => {
                    return fetchUsersLookup(search);
                  }}
                  name="user"
                  label="Name of the user"
                  optLabel="name"
                  optValue="id"
                />
                <Form.Field.Number
                  fullWidth
                  sx={{
                    '& input': {
                      textAlign: 'left !important',
                    },
                  }}
                  placeholder="Add value..."
                  variant="outlined"
                  endAdornment={'credits'}
                  name="credit"
                  label="Credit Allowance per month"
                />
              </Stack>

              <Stack direction="row" gap={2} justifyContent="flex-end" mt={2}>
                <Button type="submit" loading={loading} color="secondary" variant="contained">
                  Save
                </Button>
              </Stack>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
}

export default CreditAllocationForm;
