import React, { useRef } from 'react';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Box, Typography, InputAdornment, Input } from '@mui/material';
import { Form, Button, Spacer } from 'src/components/shared';
import { cacheTest, checkUniqueUserEmail } from 'src/utils/validation';

import MailIcon from '@mui/icons-material/Mail';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const PersonalDetailsForm = ({ onSubmit }) => {
  const [loading, setLoading] = React.useState(false);
  const emailUniqueTest = useRef(cacheTest(checkUniqueUserEmail));

  return (
    <Form
      initialValues={{
        fname: '',
        lname: '',
        tenant: '',
      }}
      validationSchema={Yup.object().shape({
        fname: Yup.string().required('First name is required!'),
        lname: Yup.string().required('Last name is required!'),
        tenant: Yup.string().required('Company name is required!'),
      })}
      onSubmit={async (values, form) => {
        try {
          setLoading(true);
          const res = await onSubmit(values, form);
          setLoading(false);
          form.prevent;
        } catch (error) {
          setLoading(false);
        }
      }}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            noValidate
          >
            <Form.Field.Input fullWidth variant="outlined" name="fname" placeholder="First name" />
            <Form.Field.Input fullWidth variant="outlined" name="lname" placeholder="Last name" />
            <Form.Field.Input
              fullWidth
              variant="outlined"
              name="tenant"
              placeholder="Company name"
            />

            <Box mt={2}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                loading={loading}
                fullWidth
                size="large"
                endIcon={<ArrowForwardIcon />}
              >
                Next
              </Button>
            </Box>
          </form>
        );
      }}
    </Form>
  );
};

export default PersonalDetailsForm;
