import React from 'react';
import { IconButton, Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { makeStyles } from '@mui/styles';

// css styling for user form
const addUseStyles = makeStyles(({ custom }) => ({}));

// Form Drawer wrapper to mange drawer events
const DrawerFormHeader = ({
  handleCloseClick,
  children,
  formHeaderTitleClassName = '',
  titleText = 'Create User',
  subTitleText = '',
  showBackButton = false,
  backClick = () => {},
  drawerElement,
  elementLeftToCloseIcon,
}) => {
  const classes = addUseStyles();

  // to close the drawer
  const handleDrawerClose = (data) => {
    handleCloseClick(data);
  };

  return (
    <Box>
      <Box display={'flex'} justifyContent="flex-end">
        {showBackButton && (
          <Box>
            <IconButton
              onClick={() => backClick()}
              className={classes.formHeaderCloseIconButton}
              style={{ padding: 6 }}
              size="large">
              <ChevronLeftIcon
                fontSize="large"
                className={classes.formHeaderCloseIcon}
              />
            </IconButton>
          </Box>
        )}
        {drawerElement && (
          <Box display="flex" alignItems="center">
            {drawerElement}
          </Box>
        )}
      </Box>

      <div className={classes.formContentWrapper}>{children}</div>
    </Box>
  );
};

export default DrawerFormHeader;
