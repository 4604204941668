import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Box, Typography, InputAdornment } from '@mui/material';
import { Form, Button } from 'src/components/shared';

import MailIcon from '@mui/icons-material/Mail';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';

function ForgotPasswordForm({ onSubmit }) {
  const [loading, setLoading] = React.useState(false);

  return (
    <Form
      initialValues={{
        email: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Enter a valid email address')
          .required('Please enter your email address!'),
      })}
      onSubmit={async (values, form) => {
        form.prevent;
        setLoading(true);
        try {
          await onSubmit(values, form);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            noValidate
          >
            <Form.Field.Input
              fullWidth
              variant="outlined"
              name="email"
              placeholder="Email"
              disabled={loading}
              autoComplete="on"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailIcon style={{ fill: '#d1d1d1' }} />
                  </InputAdornment>
                ),
              }}
            />

            <Box mt={2}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                loading={loading}
                fullWidth
                size="large"
                endIcon={<ArrowForwardIcon />}
              >
                Next
              </Button>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}

export default ForgotPasswordForm;
