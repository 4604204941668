import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { PdfViewer } from 'src/components/App';

import { useSessionProvider } from 'src/modules/lms/utils/sessionStore';

function SessionDescription({ session }) {
  const { view } = useSessionProvider();
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  if (view == 'guide') {
    return <PdfViewer url={session.howToGuidePdf} />;
  }

  if (session.overviewText && session.overviewText !== '') {
    return <Desc dangerouslySetInnerHTML={{ __html: session.overviewText }} />;
  }
  return null;
  // return <Desc dangerouslySetInnerHTML={{ __html: session.guideText }} />;
}

export default SessionDescription;

const Desc = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.2px;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    color: rgba(255, 255, 255, 0.87);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 24px 0px 10px 0px;
  }
  & p {
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.2px;
  }
  & a {
    color: #ffd47e;
  }
`;
