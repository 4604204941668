import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import styled from 'styled-components';
const bg = require(`src/assets/lms/CourseBG.svg`).default;
import darkTheme from 'src/config/darkTheme';

import Skeleton from '@mui/lab/Skeleton';

import { ReactComponent as Assigned } from 'src/assets/lms/icons/assigned.svg';

export const AssignedIcon = () => (
  <Assigned
    style={{
      color: darkTheme.palette.secondary.main,
      width: 20,
      height: 20,
    }}
  />
);

export const Wrapper = styled(ButtonBase)`
  aspect-ratio: 0.88/1;
  width: 100%;
  // height: 100%;
  position: relative;
  display: block;
`;

export const CourseWrap = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 16px;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
  background: #090d11;
  justify-content: space-between;
`;

export const ProgressWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
`;

export const ProgressSkeleton = styled(Skeleton)``;

export const SessionImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px 8px 0px 0px;
  object-fit: cover;
  left: 0;
  top: 0;
`;

export const Content = styled.div`
  position: relative;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  height: 100%;
  /* height: ${(props) => (props.hasCourse ? '100%' : 'calc(100% + 40px)')}; */
  background: linear-gradient(180deg, #222 0%, #555 100%);
`;

export const Gradient = styled.div`
  width: 100%;
  height: 120px;
  position: absolute;
  bottom: 0;
  background: var(--Dark-Gradient, linear-gradient(180deg, rgba(9, 13, 17, 0) 0%, #090d11 100%));
  z-index: 999;
`;

export const GradientTop = styled.div`
  width: 100%;
  height: 64px;
  position: absolute;
  top: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.68) 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 990;
  border-radius: 8px;
`;

export const Title = styled(Typography)`
  font-size: 24px;
  position: absolute;
  bottom: 10px;
  left: 16px;
  z-index: 999;
  text-align: left;
  max-width: calc(100% - 32px);
  @media (max-width: 786px) {
    font-size: 18px;
  }
`;

export const TitleSkeleton = styled(Skeleton)`
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 999;
  text-align: left;
`;

export const Experts = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const Expert = styled.div`
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
