import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: '100%',
    minWidth: 750,
    maxWidth: 750,
    display: 'flex',
  },
  checkBoxLabel: {
    margin: 0,
  },
  leftWrapper: {
    padding: '0 8px 0 0',
  },
  checkBoxWrapper: {
    maxHeight: 324,
    overflow: 'auto',
  },
  rightWrapper: {
    padding: '0 0 0 8px',
  },
  wrappers: {
    minWidth: 350,
  },
  draggableItem: {
    paddingBottom: 8,
    paddingRight: 8,
    maxHeight: 324,
    overflow: 'auto',
  },
  draggableItemTextWrapper: {
    borderBottom: `2px solid ${palette.primary.light}32`,
    zIndex: 99999999,
    // border: `1px solid #dddddd`,
    borderRadius: 3,
    padding: 0,
    backgroundColor: `${palette.primary.light}20`,
    margin: '8px 0',
  },
  draggableItemText: {
    width: 'calc(100% - 50px)',
    cursor: 'grab',
    padding: 6,
    '& svg': {
      margin: 8,
      cursor: 'grab',
      // fill: '#dddddd',
    },
    '& p': {
      margin: 0,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  closeIcon: {
    cursor: 'pointer',
  },
  hiddenCloseIcon: {
    height: 20,
    margin: 12,
  },
}));

export default useStyles;
