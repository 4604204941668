import React from 'react';
import styled from 'styled-components';
import { Box, Tabs, Tab, Stack, Card, CardContent, Grid } from '@mui/material';
import { Skeleton } from '@mui/lab';
import { makeStyles, withStyles } from '@mui/styles';
import { Avatar, Spacer } from 'src/components/shared';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export const Banner = styled.div`
  width: 100%;
  height: 250px;
`;

function SessionSkelton() {
  return (
    <>
      <Skeleton animation="wave" height={'250px'} width={'100%'} />
      <Stack direction="row" mt={1} gap={2}>
        <Skeleton animation="wave" height={32} width={100} />
        <Skeleton animation="wave" height={32} width={120} />
      </Stack>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item md={8}>
            <Card>
              <CardContent>
                <Stack direction="row" gap={2}>
                  <Skeleton animation="wave" height={125} width={225} />
                  <Skeleton animation="wave" height={125} width={225} />
                </Stack>
              </CardContent>
            </Card>
            <Spacer x={2} y={2} />
            <Skeleton animation="wave" height={36} width={200} />
            <Skeleton animation="wave" height={24} width={'100%'} />
            <Skeleton animation="wave" height={24} width={'100%'} />
            <Skeleton animation="wave" height={24} width={'100%'} />
            <Skeleton animation="wave" height={24} width={'40%'} />

            <Spacer x={2} y={2} />
            <Skeleton animation="wave" height={36} width={200} />
            <Skeleton animation="wave" height={24} width={'100%'} />
            <Skeleton animation="wave" height={24} width={'100%'} />
            <Skeleton animation="wave" height={24} width={'100%'} />
            <Skeleton animation="wave" height={24} width={'40%'} />
          </Grid>
          <Grid item md={4}>
            <Skeleton animation="wave" height={44} width={'100%'} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default SessionSkelton;
