import React from 'react';
import useStyles from './styles';
import Company from '../../../../assets/images/LMS/Competition/company.png';
import SuperreachLogo from '../../../../assets/images/superWhite.png';
import { useSelector } from 'react-redux';
const CompetitionHeader = () => {
  const classes = useStyles();
  const settings = useSelector(
    (state) => state?.app?.tenant?.tenant_settings?.[0]?.competitionSettings,
  );
  return (
    <div className={classes.CompetitionHeader}>
      <img src={settings?.logo || Company} width={139} height={44} />
      <img src={SuperreachLogo} />
    </div>
  );
};

export default CompetitionHeader;
