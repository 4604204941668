import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { ButtonBase, Grid, Stack, Tooltip } from '@mui/material';

import styled from 'styled-components';
import UseMenu from './UseMenu';

import { topLinks, themeColors } from '../../config';
import GlobalSearch from '../GlobalSearch';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { useIntercom } from 'react-use-intercom';
import { Button } from '@mui/base';
import toast from 'src/utils/toast';

const MenuLink = styled(Link)`
  color: #bfbfbf;
  &.active {
    color: #ffd47e;
  }
  &:hover {
    color: #ffd47e;
  }
`;

function LMSHeader(props) {
  const user = useSelector((state) => state.auth.user);
  const { boot, show } = useIntercom();

  const location = useLocation();

  // React.useEffect(() => {
  //   props.fetchHomePage();
  // }, []);

  return (
    <div>
      <AppBar
        color="inherit"
        position="fixed"
        sx={{
          background: themeColors.mainBg,
          boxShadow: 'none',
          left: '230px',
          width: 'calc(100% - 230px)',
        }}
      >
        <Container maxWidth="xxl">
          <Toolbar disableGutters>
            <Grid container alignItems="center">
              <Grid item lg={3}></Grid>
              <Grid item lg={6} alignItems="center">
                <Stack
                  direction="row"
                  gap={3}
                  sx={{ flexGrow: 1 }}
                  alignItems="center"
                  justifyContent="center"
                >
                  {topLinks.map((item, index) => (
                    <MenuLink
                      key={`topLink-${index}`}
                      to={item.link}
                      className={`${location.pathname === item.link ? 'active' : ''}`}
                    >
                      {item.title}
                    </MenuLink>
                  ))}
                </Stack>
              </Grid>
              <Grid item lg={3}>
                <Stack direction="row" alignItems="center" gap={1}>
                  <GlobalSearch placeholder={'Search by keywords'} />

                  <Tooltip title="Help & Support">
                    <ButtonBase
                      onClick={async () => {
                        try {
                          await boot();
                          await show();
                        } catch (error) {}
                      }}
                      sx={{ padding: '0px 8px' }}
                    >
                      <HelpOutlineIcon sx={{ fill: 'rgba(255,255,255,1)' }} />
                    </ButtonBase>
                  </Tooltip>
                  <UseMenu />
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}
export default LMSHeader;
