import React from 'react';
import { Card, CardContent, Box, Stack, Typography } from '@mui/material';
import { Spacer } from 'src/components/shared';

import images from 'src/config/images';

function PaymentMethods({ card }) {
  const CardItem = () => {
    let maskedNumber = card?.masked_number || '';
    if (maskedNumber && maskedNumber !== '') {
      maskedNumber = maskedNumber
        .replace(/.(?=.{4})/g, '*')
        .match(/.{1,4}/g)
        .join(' ');
    } else {
      return null;
    }

    return (
      <Card
        variant="outlined"
        sx={{
          maxWidth: 400,
          marginTop: '12px',
          height: '54px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Stack direction="row" p={1.6} alignItems="center">
          <Box sx={{ width: '64px' }}>
            <img src={images.billing[card.card_type]} style={{ maxWidth: '40px' }} />
          </Box>
          {maskedNumber}
        </Stack>
      </Card>
    );
  };

  return (
    <Card>
      <CardContent>
        <Typography color="textPrimary" sx={{ fontWeight: 500 }}>
          Payment Method
        </Typography>

        <CardItem />
        {/* <CardItem type="visa" cc="1234321465476512" /> */}
      </CardContent>
    </Card>
  );
}

export default PaymentMethods;
