import React, { useEffect, useState, useMemo } from 'react';
import * as Yup from 'yup';
import { Grid, Box, Tooltip, Backdrop, Stack, Card, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Button, Form, Spacer } from 'src/components/shared';
import moment from 'moment';
import { isEmailExist, linkedinRegex } from 'src/config';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAddContactForm } from 'src/modules/app/actions/appActions';
import { useCallback } from 'react';
import {
  AddCRMLookup,
  fetchAccountsLookup,
  fetchCRMAccountsLookup,
} from 'src/modules/admin/api/adminApi';
import { fetchCountryLookup, fetchTimezoneLookup } from 'src/modules/users/api/usersApi';
import Matches from 'src/components/App/Matches';
import { getIntegrations } from 'src/modules/app/actions/appSelector';
import { getContact, getGlobalContacts } from '../../saga/contactSagas';
import { useRef } from 'react';
import { capitalize } from 'lodash';

const multiSelectMap = [];

const TitleBar = ({ title, tooltip }) => {
  return (
    <Box display="flex" alignItems="center">
      <Typography variant="h4">{title}</Typography>
      {tooltip && tooltip !== '' ? (
        <>
          <Spacer y={1} x={1} />

          <Tooltip title={tooltip}>
            <InfoIcon style={{ fill: 'rgba(0, 0, 0, 0.26)', width: 22 }} />
          </Tooltip>
        </>
      ) : null}
    </Box>
  );
};

function ContactForm({ edit, formData, drawerVisibility, ...props }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef();
  const integrations = useSelector((state) => getIntegrations(state));
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [customFields, setCustomFields] = useState([]);
  const [countries, setCountries] = useState([]);
  const [timezones, setTimezones] = useState([]);
  const [matches, setMatches] = useState([]);
  const [onSubmitHit, setOnSubmitHit] = useState(false);
  const [openNewEmployerModal, setOpenNewEmployerModal] = useState(null);
  const [newEmployerLookup, setNewEmployerLookup] = useState({});
  const [addingNewEmployerLoading, setAddingNewEmployerLoading] = useState(false);

  const defaultAccount = useMemo(() => {
    return edit?.account || {};
  }, [edit]);

  useEffect(() => {
    if (!drawerVisibility) setCurrentUser({}); // clear form off toggle
  }, [drawerVisibility]);

  const contact = useSelector((state) => getContact(state));
  const contacts = useSelector((state) => getGlobalContacts(state));
  const handleCloseMatches = () => {
    setDrawerAndLoader();
    history.push(`/contact/${contact.id}`);
  };

  useEffect(() => {
    const crmActiveField = ['bullhorn', 'vincere', 'jobadder', 'hubspot', 'salesforce']?.find(
      (crmFeild) => contact?.[crmFeild + 'Id'],
    );
    const crmActiveFieldType = crmActiveField ? contact?.[crmActiveField + 'Type'] : null;
    if (onSubmitHit && !edit?.id && contact?.id && !crmActiveFieldType) {
      if (integrations?.isBullhornActive) {
        props.fetchBullhornMatches(contact);
      } else if (integrations?.isVincereActive) {
        props.fetchVincereMatches(contact);
      } else if (integrations?.isJobAdderActive) {
        props.fetchJobAdderMatches(contact);
      } else if (integrations?.isSalesforceActive) {
        props.fetchSalesforceMatches(contact);
      } else if (integrations?.isHubspotActive) {
        props.fetchHubspotMatches(contact);
      } else {
        handleCloseMatches();
      }
    }
  }, [contact, onSubmitHit]);

  const handleSyncContact = (contactId, match) => {
    if (integrations?.isBullhornActive) {
      props.syncBullhornContact(contactId, match);
    } else if (integrations?.isVincereActive) {
      props.syncVincereContact(contactId, match);
    } else if (integrations?.isJobAdderActive) {
      props.syncJobAdderContact(contactId, match);
    } else if (integrations?.isSalesforceActive) {
      props.syncSalesforceContact(contactId, match);
    } else if (integrations?.isHubspotActive) {
      props.syncHubspotContact(contactId, match);
    }
  };

  useEffect(() => {
    if (
      onSubmitHit &&
      !edit?.id &&
      !matches?.length &&
      contact?.id &&
      !props?.crmLoading?.fetchContact
    ) {
      const matches =
        contacts?.[
          integrations?.isBullhornActive
            ? 'bullhornMatches'
            : integrations?.isVincereActive
            ? 'vincereMatches'
            : integrations?.isJobAdderActive
            ? 'jobAdderMatches'
            : integrations?.isSalesforceActive
            ? 'salesforceMatches'
            : integrations?.isHubspotActive
            ? 'hubspotMatches'
            : null
        ] || [];
      setMatches(matches);
      if (!matches?.length) handleCloseMatches();
    }
  }, [props?.crmLoading?.fetchContact]);

  // useEffect(() => {
  //   if (!onSubmitHit && !matches?.length) setDrawerAndLoader();
  // }, [onSubmitHit, matches]);

  useEffect(() => {
    fetchCountriesOptions();
    fetchTimezonesOptions();
    setLoading(false);
  }, []);

  const fetchCountriesOptions = async () => {
    const res = await fetchCountryLookup();
    setCountries(res);
  };

  const fetchTimezonesOptions = async () => {
    const res = await fetchTimezoneLookup();
    setTimezones(res);
  };

  useEffect(() => {
    setCustomFields(
      formData
        .filter((field) => {
          if (
            field?.inContact &&
            field?.status === 'active' &&
            !dontShowEmailFieldsOnEdit(
              field?.fieldName,
              currentUser && currentUser?.id ? true : false,
            )
          )
            if (
              (edit?.id && field?.fieldOptions?.showOnView) ||
              (!edit?.id && field?.fieldOptions?.showOnForm)
            )
              return true;
        })
        .sort(function (a, b) {
          return a?.fieldOptions &&
            a?.fieldOptions?.contacts &&
            b?.fieldOptions &&
            b?.fieldOptions?.contacts
            ? a.fieldOptions?.contacts.sortOrder - b.fieldOptions?.contacts.sortOrder
            : -1;
        })
        .map((ele) => {
          return { ...ele, label: capitalize(ele.label) };
        }),
    );
  }, [formData, currentUser]);

  const [formField] = useState(
    formData.map((form) => ({
      id: form.id,
      fieldOptions: form.fieldOptions,
      fieldName: form.fieldName,
      fieldType: form.fieldType,
      label: form.label,
      status: form.status,
    })),
  );

  useEffect(() => {
    if (edit && edit?.id) {
      setCurrentUser(edit);
    }
  }, [edit]);

  const findContestId = (emails, type) => {
    const filtered = emails?.find((e) => {
      return e.type === type;
    });
    if (filtered) {
      return filtered.id;
    } else return '';
  };

  const crmShortForm = {
    bullhorn: 'bh',
    vincere: 'vin',
    jobadder: 'ja',
    hubspot: 'hubspot',
    salesforce: 'sf',
  };
  const crmActiveField = ['bullhorn', 'vincere', 'jobadder', 'hubspot', 'salesforce']?.find(
    (crmField) => contact?.[crmField + 'Id'],
  );
  const crmActiveFieldType = crmActiveField
    ? contact?.[crmActiveField + 'Type'] === 'ClientContact'
      ? 'Contact'
      : contact?.[crmActiveField + 'Type']
    : null;

  const handleSubmit = async (values, form) => {
    const data = { ...values, account: values.account || null };

    const workContact = findContestId(currentUser?.emails, 'work');
    const personalContact = findContestId(currentUser?.emails, 'personal');
    const mobileContact = findContestId(currentUser?.phones, 'mobile');

    if (workContact || personalContact || mobileContact) {
      data.workEmail = {
        email: data.workEmail,
        type: 'work',
        ...(workContact && { contact: workContact }),
      };

      data.personalEmail = {
        email: data.personalEmail,
        type: 'personal',
        ...(personalContact && { contact: personalContact }),
      };

      data.phoneContact = {
        phone: data.mobile,
        type: 'mobile',
        ...(mobileContact && { contact: mobileContact }),
      };
    }
    const defaultAddressData = currentUser?.address?.[0] || {};
    delete defaultAddressData?.id;

    data.address = [
      {
        ...defaultAddressData,
        country:
          data?.country === '' && defaultAddressData?.country
            ? ''
            : data?.country || defaultAddressData?.country || '',
        city:
          data?.city === '' && defaultAddressData?.city
            ? ''
            : data?.city || defaultAddressData?.city || '',
        countryCode:
          data?.countryCode === '' && defaultAddressData?.countryCode
            ? ''
            : data?.countryCode || defaultAddressData?.countryCode || '',
        lat:
          data?.lat === '' && defaultAddressData?.lat
            ? ''
            : data?.lat || defaultAddressData?.lat || '',
        long:
          data?.long === '' && defaultAddressData?.long
            ? ''
            : data?.long || defaultAddressData?.long || '',
        location:
          data?.location === '' && defaultAddressData?.location
            ? ''
            : data?.location || defaultAddressData?.location || '',
        street1:
          data?.street1 === '' && defaultAddressData?.street1
            ? ''
            : data?.street1 || defaultAddressData?.street1 || '',
        street2:
          data?.street2 === '' && defaultAddressData?.street2
            ? ''
            : data?.street2 || defaultAddressData?.street2 || '',
        town:
          data?.town === '' && defaultAddressData?.town
            ? ''
            : data?.town || defaultAddressData?.town || '',
        state:
          data?.state === '' && defaultAddressData?.state
            ? ''
            : data?.state || defaultAddressData?.state || '',
        zip:
          data?.zip === '' && defaultAddressData?.zip
            ? ''
            : data?.zip || defaultAddressData?.zip || '',
        address1:
          data?.address1 === '' && defaultAddressData?.address1
            ? ''
            : data?.address1 || defaultAddressData?.address1 || '',
        address2:
          data?.address2 === '' && defaultAddressData?.address2
            ? ''
            : data?.address2 || defaultAddressData?.address2 || '',
        googleAddress:
          data?.googleAddress === '' && defaultAddressData?.googleAddress
            ? ''
            : data?.googleAddress || defaultAddressData?.googleAddress || '',
      },
    ];

    delete data?.country;
    delete data?.countryCode;
    delete data?.lat;
    delete data?.long;
    delete data?.location;
    delete data?.street1;
    delete data?.street2;
    delete data?.town;
    delete data?.state;
    delete data?.zip;
    delete data?.address1;
    delete data?.address2;
    delete data?.zip;
    delete data?.googleAddress;
    delete data?.city;
    if (data.DateOfBirth) {
      data.DateOfBirth = moment(data.DateOfBirth).format('YYYY-MM-DD');
    }

    if (edit?.id && crmActiveFieldType?.toLowerCase() === 'contact') {
      data[crmShortForm[crmActiveField] + 'AccountId'] =
        newEmployerLookup?.name === data?.account?.name
          ? newEmployerLookup?.id
          : typeof data.account?.id === 'number'
          ? data.account?.id
          : data?.account?.[crmShortForm[crmActiveField] + 'AccountId'];
      data.account =
        newEmployerLookup?.name === data?.account?.name
          ? newEmployerLookup?.srAccount
          : data?.account?.srAccount
          ? data?.account?.srAccount
          : data?.account?.id;
    } else if (data.account?.id) data.account = data.account.id; // existing account
    else if (data.account?.name) {
      data.employer = data.account?.name; // new account entry
      delete data.account;
    } else if (!data.account || Object.keys(data?.account).length === 0) {
      data.account = null;
      data.employer = '';
    }
    let media = [];
    Object.entries(data).forEach(([key, value]) => {
      if (data[key]?.length && Array.isArray(data[key])) {
        if (data[key][0]?.url && data[key][0]?.filename) {
          media = data[key][0].url;
          data[key] = media;
        }
      }
    });

    setLoading(true);

    if (multiSelectMap.length) {
      multiSelectMap.forEach((key) => {
        data[key] = data[key]
          ? data[key]
              .filter((item) => item && typeof item !== 'undefined' && item !== null)
              .map((x) => {
                return typeof x === 'string' || x instanceof String || typeof x === 'number'
                  ? x
                  : x.value;
              })
          : [];
      });
    }

    if (edit && edit?.id) {
      const updatedRow = { id: edit?.id, data: data };
      await props.putContact(edit.id, updatedRow);
      setDrawerAndLoader();
    } else {
      await props.addContacts(data);
      setOnSubmitHit(true);
    }
  };

  const setDrawerAndLoader = useCallback(() => {
    setLoading(false);
    dispatch(toggleAddContactForm());
  });

  const onCancel = useCallback(() => {
    setCurrentUser({});
    dispatch(toggleAddContactForm());
  });

  const validationSchema = {
    ...formField.reduce((result, field) => {
      result = {
        ...result,
        [`${field.fieldName}`]: Yup.string().required(`Please enter a valid ${field.fieldName}`),
      };
      return result;
    }, {}),
  };

  const customValues = useMemo(() => {
    const value = {};
    for (let i = 0; i < customFields.length; i++) {
      const field = customFields[i];
      const { fieldName } = customFields[i];

      value[field.fieldName] =
        currentUser && currentUser[field.fieldName]
          ? field?.fieldOptions?.multiple === true &&
            typeof currentUser[field.fieldName] === 'string'
            ? [currentUser[field.fieldName]]
            : currentUser[field.fieldName]
          : field?.fieldOptions?.multiple === true
          ? []
          : '';

      if (
        field.fieldType === 'lookup' &&
        field?.fieldOptions?.multiple &&
        !multiSelectMap.includes(field.fieldName)
      ) {
        multiSelectMap.push(field.fieldName);
      }

      if (fieldName === 'personalEmail' && currentUser?.emails?.length) {
        value[fieldName] = getPersonalEmail(currentUser);
      }

      if (fieldName === 'account' || fieldName === 'employer') {
        value[fieldName] = currentUser.account || '';
      }

      if (fieldName === 'workEmail' && currentUser?.emails?.length) {
        value[fieldName] = getWorkEmail(currentUser);
      }

      if (fieldName === 'mobile' && currentUser?.phones?.length) {
        const mobile = currentUser?.phones?.find(
          (k) => k.type === 'mobile' && !k.isDeleted && k.isPrimary,
        );
        value[fieldName] = mobile?.phone || '';
      }

      if (fieldName === 'workPhone' && currentUser?.phones?.length) {
        const mobile = currentUser?.phones?.find(
          (k) => k.type === 'office' && !k.isDeleted && k.isPrimary,
        );
        value[fieldName] = mobile?.phone || '';
      }

      if (fieldName === 'homePhone' && currentUser?.phones?.length) {
        const mobile = currentUser?.phones?.find(
          (k) => k.type === 'home' && !k.isDeleted && k.isPrimary,
        );
        value[fieldName] = mobile?.phone || '';
      }

      if (fieldName === 'linkedinProfile' && currentUser?.socialLinks?.length) {
        const linkedin = currentUser?.socialLinks?.filter((k) => k.type === 'linkedin');
        value[fieldName] = linkedin?.length ? linkedin[0]?.link : '';
      }

      if (field.fieldType === 'upload') {
        const url = value[fieldName];
        value[fieldName] = [{ url: url }];
      }

      // if (field.fieldName === 'address' && currentUser[field.fieldName]?.length) {
      //   value[field.fieldName] = currentUser[field.fieldName][0]?.address;
      // }

      [
        'country',
        'countryCode',
        'lat',
        'long',
        'location',
        'street1',
        'street2',
        'town',
        'state',
        'zip',
        'address1',
        'address2',
        'zip',
        'googleAddress',
        'city',
      ].forEach((field) => {
        if (fieldName === field && currentUser?.address?.length) {
          value[fieldName] = getAddress(currentUser, field);
        }
      });
      if (fieldName === 'DateOfBirth') {
        value[field.fieldName] = currentUser?.DateOfBirth ? moment(currentUser?.DateOfBirth) : '';
      } else if (field.fieldType === 'date') {
        value[field.fieldName] = currentUser[field.fieldName]
          ? currentUser[field.fieldName]
          : currentUser?.updatedAt
          ? moment(currentUser?.updatedAt)
          : moment().add('days', 5);
      }
      // if (field.fieldType === 'upload' && !value[field.fieldName]) {
      //   value[field] = {};
      // }
    }

    return value;
  }, [customFields, currentUser]);

  const findEmailType = (emails, type) => {
    const filtered = emails.find((e) => {
      return e.type === type;
    });
    if (filtered) {
      return filtered.email;
    } else return '';
  };

  const findPhoneType = (phones, type) => {
    const filtered = phones.find((e) => {
      return e.type === type;
    });
    if (filtered) {
      return filtered.phone;
    } else return '';
  };

  const CRMField = integrations?.isBullhornActive
    ? 'Bullhorn'
    : integrations?.isJobAdderActive
    ? 'JobAdder'
    : integrations?.isVincereActive
    ? 'Vincere'
    : integrations?.isSalesforceActive
    ? 'Salesforce'
    : integrations?.isHubspotActive
    ? 'Hubspot'
    : '';

  const handleAddEmployerSubmit = async (values) => {
    setAddingNewEmployerLoading(true);
    const result = await AddCRMLookup({
      name: values?.employer,
      service: CRMField?.toLowerCase(),
    });
    if (!result.srAccount) {
      formRef?.current?.setFieldValue(`account`, '');
    } else {
      const newEmployer = {
        name: values?.employer,
        id: result?.crmCompany?.id,
        srAccount: result?.srAccount?.id,
      };
      formRef?.current?.setFieldValue(`account`, newEmployer);
      setNewEmployerLookup(newEmployer);
    }
    setAddingNewEmployerLoading(false);
    setOpenNewEmployerModal(null);
  };

  const handleBackdropClick = (e) => {
    if (e?.target?.classList?.contains('backdrop-content')) {
      onClose();
    }
  };

  return (
    <React.Fragment>
      <Form
        initialValues={{
          ...customValues,
        }}
        // validationSchema={Yup.object().shape(validationSchema)}
        validationSchema={Yup.object().shape({
          ...(customFields &&
            customFields.length &&
            Object.assign(
              {},
              ...customFields.map(({ fieldName, fieldOptions, fieldType, type }) => {
                // Will refactor the below, given time
                // need a way to make it DRY
                if (fieldName === 'workEmail')
                  if (fieldOptions?.required === true)
                    return {
                      [fieldName]: Yup.string()
                        .required('This field is required!')
                        .email('Invalid email format')
                        .test({
                          message: () => 'Email already exists',
                          test: (values) => isEmailExist(values, getWorkEmail(edit)),
                        }),
                    };
                  else
                    return {
                      [fieldName]: Yup.string()
                        .required('This field is required!')
                        .email('Invalid email format')
                        .test({
                          message: () => 'Email already exists',
                          test: (values) =>
                            values ? isEmailExist(values, getWorkEmail(edit)) : true,
                        }),
                    };

                if (fieldName === 'account' && fieldOptions?.required)
                  return {
                    [fieldName]: Yup.object().test({
                      message: () => 'Please choose employer.',
                      test: (item) => item?.name && item?.name !== '',
                    }),
                  };

                if (fieldName === 'personalEmail')
                  if (fieldOptions?.required === true)
                    return {
                      [fieldName]: Yup.string()
                        .required('This field is required!')
                        .email('Invalid email format')
                        .test({
                          message: () => 'Should not be same as Work Email.',
                          test: function () {
                            const { workEmail, personalEmail } = this.parent;
                            if (!personalEmail) return true;
                            return workEmail !== personalEmail;
                          },
                        })
                        .test({
                          message: () => 'Email already exists',
                          test: (values) => isEmailExist(values, getPersonalEmail(edit)),
                        }),
                    };
                  else
                    return {
                      [fieldName]: Yup.string()
                        .email('Invalid email format')
                        .test({
                          message: () => 'Should not be same as Work Email.',
                          test: function () {
                            const { workEmail, personalEmail } = this.parent;
                            if (!personalEmail) return true;
                            return workEmail !== personalEmail;
                          },
                        })
                        .test({
                          message: () => 'Email already exists',
                          test: (values) =>
                            values ? isEmailExist(values, getPersonalEmail(edit)) : true,
                        }),
                    };

                if (fieldName === 'fname')
                  // always required

                  return {
                    [fieldName]: Yup.string().required('Please enter First Name!'),
                    // .test('fanme', 'First Name cannot contain special character', contactNameRegex),
                  };

                if (fieldType === 'date') {
                  return {
                    [fieldName]: Yup.date()
                      .typeError('Please Enter Date in Correct format')
                      .label('Date')
                      .nullable(),
                  };
                }

                if (fieldName === 'lname')
                  // always required
                  return {
                    [fieldName]: Yup.string().required('Please enter Last Name!'),
                    // .test('lname', 'Last Name cannot contain special character', contactNameRegex),
                  };

                if (fieldName === 'jobTitle' && fieldOptions?.required) {
                  return {
                    [fieldName]: Yup.string().required('Please enter Job Title!'),
                    // .test(
                    //   'job title',
                    //   'Job Title cannot contain special character',
                    //   specialCharacterRegex,
                    // ),
                  };
                }

                if (fieldName === 'mobile' && fieldOptions?.required) {
                  return { [fieldName]: Yup.string().required('Please enter Mobile Number!') };
                }

                if (fieldName === 'linkedinProfile' && fieldOptions?.required)
                  return {
                    [fieldName]: Yup.string()
                      .required('This field is required!')
                      .matches(linkedinRegex, 'Enter valid linkedin profile url!'),
                  };
                if (fieldName === 'linkedinProfile')
                  return {
                    [fieldName]: Yup.string().matches(
                      linkedinRegex,
                      'Enter valid linkedin profile url!',
                    ),
                  };

                if (fieldName === 'country' && fieldOptions?.required)
                  return {
                    [fieldName]: Yup.string().required('This field is required!'),
                  };

                if (fieldName === 'city' && fieldOptions?.required)
                  return {
                    [fieldName]: Yup.string().required('This field is required!'),
                  };

                if (fieldType === 'formula' && !fieldOptions?.multiple && fieldOptions?.required)
                  return {
                    [fieldName]: Yup.object().test({
                      message: () => 'This field is required!',
                      test: (values) => {
                        if (!values) return false;
                        return Object.keys(values).length !== 0; // empty {}
                      },
                    }),
                  };

                if (fieldType === 'lookup' && fieldOptions?.multiple && fieldOptions?.required)
                  return {
                    [fieldName]: Yup.array().test({
                      message: () => 'This field is required!',
                      test: (values) => {
                        if (!values) return false;
                        return values?.length !== 0; // empty {}
                      },
                    }),
                  };

                if (fieldType === 'upload' && fieldOptions?.required)
                  return { [fieldName]: Yup.array() };

                if (fieldOptions?.required)
                  return { [fieldName]: Yup.string().required('This field is required!') };
              }),
            )),
        })}
        enableReinitialize={true}
        innerRef={formRef}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, ...props }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                props.submitForm();
                return false;
              }}
              style={{ height: '100%' }}
              noValidate
            >
              <Box
                display="flex"
                flex={1}
                flexDirection="column"
                justifyContent="space-between"
                alignItems="flex-end"
                height="100%"
              >
                <Box>
                  {customFields.length > 0 ? (
                    <>
                      <Grid container spacing={0}>
                        {customFields.map((field, index) => {
                          if (
                            (field?.fieldOptions && edit?.id && field?.fieldOptions?.showOnView) ||
                            (!edit?.id && field?.fieldOptions?.showOnForm)
                          ) {
                            if (
                              customValues[field.fieldName] === null ||
                              customValues[field.fieldName] === undefined
                            )
                              return null;

                            if (field.fieldName === 'employer' || field.fieldName === 'account') {
                              return (
                                <>
                                  {edit?.id && crmActiveFieldType?.toLowerCase() === 'contact' && (
                                    <Stack direction={'row'} gap={'4px'} alignItems={'center'}>
                                      <Typography variant="body2">Current value :</Typography>
                                      <Typography variant="body1">
                                        #{contact?.account?.name}
                                      </Typography>
                                    </Stack>
                                  )}
                                  <Form.Field.AutoComplete
                                    multiple={false}
                                    fullWidth
                                    disabled={
                                      defaultAccount?.name &&
                                      crmActiveField === 'jobadder' &&
                                      crmActiveFieldType?.toLowerCase() === 'contact'
                                    }
                                    options={
                                      edit?.id && crmActiveFieldType?.toLowerCase() === 'contact'
                                        ? [defaultAccount || {}]
                                        : newEmployerLookup?.name
                                        ? [newEmployerLookup]
                                        : []
                                    }
                                    addNew={
                                      edit?.id && crmActiveFieldType?.toLowerCase() === 'contact'
                                    }
                                    addNewLabel={'Add Account to CRM'}
                                    variant="outlined"
                                    remoteMethod={(val) => {
                                      return edit?.id &&
                                        crmActiveFieldType?.toLowerCase() === 'contact'
                                        ? fetchCRMAccountsLookup(
                                            crmActiveField,
                                            val || contact?.account?.name,
                                          )
                                        : fetchAccountsLookup(val);
                                    }}
                                    name="account"
                                    label={'Employer'}
                                    required={field?.fieldOptions?.required}
                                    loading={true}
                                    placeholder={
                                      edit?.id && crmActiveFieldType?.toLowerCase() === 'contact'
                                        ? 'Search'
                                        : 'Employer'
                                    }
                                    optLabel="name"
                                    optValue="id"
                                    allowAdd={true}
                                    onNewAdd={setOpenNewEmployerModal}
                                    key={field.id}
                                  />
                                </>
                              );
                            }

                            if (field.fieldName === 'country') {
                              return (
                                <Form.Field.Select
                                  fullWidth
                                  options={countries}
                                  variant="outlined"
                                  name="country"
                                  label={'Country'}
                                  placeholder="Select Country"
                                  optLabel="country"
                                  optValue="country"
                                  key={field.id}
                                />
                              );
                            }

                            if (field.fieldName === 'Timezone') {
                              return (
                                <Form.Field.Select
                                  fullWidth
                                  options={timezones}
                                  variant="outlined"
                                  name="Timezone"
                                  label={'Timezone'}
                                  placeholder="Select Timezone"
                                  optLabel="jstimeZone"
                                  optValue="jstimeZone"
                                  key={field.id}
                                />
                              );
                            }

                            if (field.fieldType === 'textEditor')
                              return (
                                <Grid key={`sequence-custom-field-${index}`} item xs={12}>
                                  <Form.Field.TextEditor
                                    name={field.fieldName}
                                    label={field.label}
                                    isSetup={false}
                                    fullWidth
                                    showFontStyle={false}
                                    showFontEditor={false}
                                    showAlignProps={false}
                                    showListProps={false}
                                    showAttachments={false}
                                  />
                                </Grid>
                              );

                            // if (field.fieldType === 'upload') {
                            //   return (
                            //     <>
                            //       <Form.Field.File
                            //         name={field.fieldName}
                            //         label={field.lable}
                            //         multiple={false}
                            //       />
                            //     </>
                            //   );
                            // }

                            return (
                              <Grid key={`sequence-custom-field-${field.id}`} item xs={12}>
                                <Form.Field.CustomField
                                  attribute={field}
                                  key={`sequence-custom-field-input-${field.id}`}
                                  name={field.fieldName}
                                  label={field.label}
                                  formData={values}
                                  sx={{ maxWidth: '452px' }}
                                />
                              </Grid>
                            );
                          }
                          return null;
                        })}
                      </Grid>
                    </>
                  ) : null}
                </Box>

                <Box display="flex" justifyContent="flex-end" width="100%" mt={2}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      props.resetForm();
                      onCancel();
                    }}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                  <Spacer basis={2} />
                  <Button variant="contained" color="secondary" type="submit" loading={loading}>
                    {!edit?.id ? 'Save' : 'Update'}
                  </Button>
                </Box>
                <Spacer x={3} y={3} />
              </Box>
            </form>
          );
        }}
      </Form>
      <Matches
        contact={contact}
        open={matches?.length}
        matches={matches}
        CRMField={CRMField}
        syncContact={handleSyncContact}
        onClose={handleCloseMatches}
      />
      <Backdrop
        sx={{
          position: 'absolute',
          backdropFilter: 'blur(2px)',
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openNewEmployerModal && edit?.id && crmActiveFieldType?.toLowerCase() === 'contact'}
        onClick={handleBackdropClick}
      >
        <Card
          className="backdrop-content"
          sx={{ width: 'calc(85% - 80px)', backboxcdropFilter: 'blur(3px)' }}
        >
          <Stack sx={{ padding: '20px' }}>
            <Typography variant="subtitle2">Add Account to CRM</Typography>
            <Form
              onSubmit={handleAddEmployerSubmit}
              initialValues={{ employer: openNewEmployerModal || '' }}
              enableReinitialize={true}
              noValidate
            >
              {({ values, ...props }) => {
                return (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      props.submitForm();
                      return false;
                    }}
                    style={{ height: '100%' }}
                    noValidate
                  >
                    <Form.Field.Input
                      fullWidth
                      variant="outlined"
                      name={'employer'}
                      label={'Company Name'}
                    />
                    <Box display="flex" justifyContent="flex-end" width="100%" mt={2}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          props.resetForm();
                          formRef?.current?.setFieldValue(`account`, '');
                          setOpenNewEmployerModal(null);
                        }}
                      >
                        Cancel
                      </Button>
                      <Spacer basis={2} />
                      <Button
                        variant="contained"
                        loading={addingNewEmployerLoading}
                        color="secondary"
                        type="submit"
                      >
                        Save
                      </Button>
                    </Box>
                  </form>
                );
              }}
            </Form>
          </Stack>
        </Card>
      </Backdrop>
    </React.Fragment>
  );
}

const getWorkEmail = (currentUser) => {
  const workEmail = currentUser?.emails?.filter(
    (k) => k.type === 'work' && !k.isDeleted && k.isPrimary,
  );
  return workEmail?.length ? workEmail[0]?.email : '';
};

const getPersonalEmail = (currentUser) => {
  const personalEmail = currentUser?.emails?.filter(
    (k) => k.type === 'personal' && !k.isDeleted && k.isPrimary,
  );
  return personalEmail?.length ? personalEmail[0]?.email : '';
};

export const getAddress = (contact, key) => {
  return contact?.address && contact?.address.length ? contact?.address[0]?.[key] || '' : '';
};

const dontShowEmailFieldsOnEdit = (fieldName, isEdit) => {
  return (fieldName === 'personalEmail' || fieldName === 'workEmail') && isEdit;
};

export default ContactForm;
