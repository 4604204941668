import React from 'react';
import PropTypes from 'prop-types';
import EmailIcon from '@mui/icons-material/Email';
import { EventCard } from '../eventsStyles';
import images from 'src/config/images';

const EmailCard = ({ event, ...props }) => {
  return (
    <EventCard
      title="Reminder"
      icon={<img src={images.sequence.AddTaskFilled} style={{ width: '24px' }} />}
      showEdit={true}
      showDelete={true}
      {...props}
    />
  );
};

EmailCard.propTypes = {
  event: PropTypes.object,
};

export default EmailCard;
