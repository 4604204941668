import styled from 'styled-components';
import { ButtonBase } from '@mui/material';
import { TimelineDot } from '@mui/lab';
import images from 'src/config/images';
import AddIcon from '@mui/icons-material/Add';

import { TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent } from '@mui/lab';

export const RoundButton = styled(ButtonBase)`
  width: 36px;
  height: 36px;
  border-radius: 36px;
`;

export const MuiTimelineDot = styled(TimelineDot).attrs({
  color: 'secondary',
})`
  margin: 0 !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
`;

export const TimelineButton = ({ onClick }) => {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineConnector />
        <RoundButton onClick={onClick}>
          <MuiTimelineDot>
            <AddIcon />
          </MuiTimelineDot>
        </RoundButton>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent></TimelineContent>
    </TimelineItem>
  );
};

export const TimelineFiller = ({ onClick }) => {
  return <div style={{ height: 50 }} />;
};

export const EmptyIcon = styled.img.attrs({
  src: images.sequence.SequenceEmpty,
})`
  width: 160px;
`;

export const EventContainer = styled.div`
  display: flex;
  width: ${(props) => (props.fullWidth ? '100%' : '50%')};
  margin: 0 auto;
`;
