import React from 'react';
import { Container, Typography, Card, CardContent, Paper, Grid } from '@mui/material';
import { Spacer } from 'src/components/shared';

const ContactCard = ({ title, value }) => {
  return (
    <>
      <Spacer x={1} y={1} />
      <Paper elevation={0} sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04)', padding: '10px' }}>
        <Typography variant="body2" color="textSecondary">
          {title}
        </Typography>
        <Spacer x={1} y={1} />
        <Typography variant="h1" color={'secondary'} textAlign="right" sx={{ fontSize: 32 }}>
          {value}
        </Typography>
      </Paper>
      <Spacer x={1} y={1} />
    </>
  );
};

function ContactsReport({ contact }) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ContactCard title="Contacts in SR" value={contact.totalContacts} />
        </Grid>
        <Grid item xs={6}>
          <ContactCard title="Contacts enrolled in sequence" value={contact.totalEnrollments} />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ContactCard title="Contacts interested" value={contact.intrestedContacts} />
        </Grid>
        <Grid item xs={6}>
          <ContactCard title="Contacts not interested" value={contact.notIntrestedContacts} />
        </Grid>
      </Grid>
    </>
  );
}

export default ContactsReport;
