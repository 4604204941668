import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Container,
  Grid,
  Link,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { Tabs } from 'src/components/App';

import { fetchTenantSettings, saveIntegrations, updateSettings } from '../../actions/adminActions';
import DataProviders from '../../components/DataCredit/DataProviders';
import { TabsSkeleton } from '../../components/Lookups/Skeleton';
import { configTabsTypes } from '../../config';
import { useDataProviders } from '../../utils/dataProviderStore';
import EditIcon from '@mui/icons-material/Edit';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WaterFallModals from '../../components/DataCredit/WaterFallModals';
import { styled } from '@mui/system';
import { OpenInNew } from '@mui/icons-material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { fetchTeamsLookup } from '../../api/adminApi';

const WaterfallCard = styled(Stack)({
  columnGap: '1rem',
  flexGrow: 0,
  color: '#fff',
  flexShrink: 0,
  borderRadius: '5px',
  flexBasis: '20%',
  flexDirection: 'row',
  overflow: 'hidden',
  backgroundColor: '#031729',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'normal',
  justifyContent: 'flex-start',
  padding: '1rem 1.5rem 1rem 1rem',
});
function Waterfall(props) {
  const [currentTab, setCurrentTab] = useState(Object.keys(configTabsTypes)?.[0]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [popup, setPopup] = useState(false);
  const [teams, setTeams] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [selected, setSelected] = useState({});

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const selectOption = (type) => {
    setPopup(type);
    handleClose();
  };

  const {
    // config,
    loading,
    waterfall: waterfallData,
    // fetchEnrichConfig,
    fetchWaterFall,
    // createWaterFall,
    // updateEnrichConfig,
    waterfalls,
    selectedWaterfall,
    selectWaterfall,
  } = useDataProviders();

  const waterfall = Object.keys(waterfallData)?.reduce((acc, key) => {
    if (['mobileFlow', 'personalEmailFlow', 'workEmailFlow'].includes(key))
      acc[key] = (waterfallData[key] || [])?.sort((a, b) => a?.order - b?.order);
    return acc;
  }, {});

  useEffect(() => {
    const fetchData = async () => {
      fetchWaterFall();
      const teams = await fetchTeamsLookup('');
      setTeams(teams);
    };
    fetchData();
  }, []);

  const onTabChange = (tab) => {
    setCurrentTab(tab.id);
  };

  const tabs = useMemo(() => {
    if (waterfall && !isEmpty(waterfall)) {
      const tab = Object.keys(waterfall)
        .filter((item) => {
          return configTabsTypes[item];
        })
        .map((key) => ({
          id: key,
          name: configTabsTypes[key],
        }));
      return tab;
    }
    return [];
  }, [waterfall]);

  const tab = useMemo(() => {
    return tabs.filter((item) => item.id === currentTab)[0];
  }, [tabs, currentTab]);

  const changeContract = (item) => {
    setSelected({ ...item, tab });
    setPopup('change-contract-alert');
  };

  const isDefaultWaterfall = useMemo(
    () => selectedWaterfall.type === 'default',
    [selectedWaterfall],
  );

  return (
    <Stack direction={'column'} sx={{ width: '100%' }}>
      <WaterFallModals
        props={props}
        open={popup}
        setOpen={setPopup}
        onCloseSelection={() => setSelected({})}
        selected={selected}
      />

      <Grid container>
        <Grid item xs={12}>
          <Container sx={{ boxSizing: 'border-box', padding: '0!important', mx: 0 }}>
            <Stack
              direction={'row'}
              overflow={'auto'}
              spacing={2}
              boxSizing={'border-box'}
              pb={1}
              sx={{
                '&::-webkit-scrollbar-track': {
                  '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
                  backgroundColor: '#F5F5F5',
                },
                '&::-webkit-scrollbar': {
                  height: '5px',
                  backgroundColor: '#F5F5F5',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#000000',
                  border: '2px solid #555555',
                },
              }}
            >
              {waterfalls?.map(({ name, status, type, id }) => {
                return (
                  <WaterfallCard
                    sx={{
                      backgroundColor: selectedWaterfall?.id === id ? '#0b3151' : '#031729',
                      width: '120px',
                    }}
                    component={CardActionArea}
                    onClick={() => selectWaterfall(id)}
                  >
                    <Tooltip
                      arrow
                      title={
                        type === 'default'
                          ? 'This is a SuperReach default waterfall'
                          : 'This is a customised data waterfall'
                      }
                      placement="top"
                    >
                      <InfoOutlinedIcon
                        style={{
                          fill: '#a3a3a3',
                          position: 'absolute',
                          right: '10px',
                          top: '10px',
                          fontSize: '14px',
                        }}
                      />
                    </Tooltip>
                    <img
                      style={{ borderRadius: '6px', height: 'fit-content', width: 50 }}
                      src={require('../../../../assets/images/superReachWaterfall.svg').default}
                    />
                    <Stack gap={1}>
                      <Typography fontSize={16} color="white">
                        {(type === 'default' ? 'Default' : name) || '-'}
                      </Typography>
                      <Chip
                        size="small"
                        label={status === 'active' ? 'Active' : 'Inactive'}
                        sx={{
                          width: 'min-content',
                          color: status === 'active' ? '#69F0AE' : '#FFA726',
                          backgrounfColor: status === 'active' ? '#66BB6A1F' : '#FFA7261F',
                          borderColor: status === 'active' ? '#69F0AE' : '#FFA72680',
                          '& .MuiChip-label': {
                            padding: '0 12px',
                          },
                        }}
                        variant="outlined"
                      />
                    </Stack>
                  </WaterfallCard>
                );
              })}

              <WaterfallCard
                sx={{
                  backgroundColor: '#1976D20A',
                  color: '#00000099',
                  width: '200px',
                  border: '2px dashed #ccc',
                  columnGap: '.5rem',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                component={CardActionArea}
                onClick={() => setPopup('create-waterfall')}
              >
                <AddRoundedIcon sx={{ fontSize: '25px' }} />
                <Typography fontSize={16}>Add a waterfall</Typography>
              </WaterfallCard>
            </Stack>
          </Container>
        </Grid>
      </Grid>

      <Stack mt={3}>
        <Stack direction="row" justifyContent={'space-between'} alignItems={'start'}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            gap={1}
            marginBottom={1}
            divider={
              <Typography color="#00000061">
                <FiberManualRecordIcon sx={{ fontSize: 6 }} />
              </Typography>
            }
          >
            <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
              <Typography color="#000000bb" fontSize={16} fontWeight={500} p={'6px 8px 0'}>
                {isDefaultWaterfall ? 'Default Waterfall' : selectedWaterfall?.name}
              </Typography>
              {!isDefaultWaterfall && (
                <EditIcon
                  onClick={() => setPopup('edit-waterfall')}
                  sx={{
                    color: '#00000060',
                    cursor: 'pointer',
                    '&:hover': {
                      color: '#000000bb',
                    },
                  }}
                  fontSize="24px"
                />
              )}
            </Stack>
            <Tooltip
              arrow
              title={
                isDefaultWaterfall
                  ? 'Default waterfall in use'
                  : 'The waterfall has been customised'
              }
              placement="top"
            >
              <Typography color="#0000004D" fontSize={13} p={'6px 8px 0'}>
                {isDefaultWaterfall ? 'Default' : 'Customised'} waterfall
              </Typography>
            </Tooltip>
            {selectedWaterfall?.sharedWithTeams?.length && (
              <Tooltip
                arrow
                title={
                  (teams || [])?.length === (selectedWaterfall?.sharedWithTeams || [])?.length
                    ? 'Assigned to All teams'
                    : ''
                }
                placement="top"
              >
                <Typography color="#0000004D" fontSize={13} p={'6px 8px 0'}>
                  Assigned to{' '}
                  {(teams || [])?.length === (selectedWaterfall?.sharedWithTeams || [])?.length
                    ? 'All Teams'
                    : selectedWaterfall?.sharedWithTeams
                        ?.reduce((acc, team) => {
                          acc.push(team?.name || '');
                          return acc;
                        }, [])
                        .join(', ')}
                </Typography>
              </Tooltip>
            )}
          </Stack>
          <Stack>
            <Button
              size="small"
              id="basic-button"
              variant="contained"
              color="secondary"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
              sx={{
                pointerEvents: 'auto',
                background: '#1976d2',
                textTransform: 'initial',
                '&.MuiButtonBase-root': {
                  padding: '0 0 0 10px',
                  transition: '250ms ease-in-out',
                  '& > *': { transition: '250ms ease-in-out' },
                },
                '&.Mui-disabled': { color: 'white' },
                '.MuiButton-endIcon': {
                  background: '#1565c0',
                  padding: '6px',
                  borderBottomRightRadius: '5px',
                  borderTopRightRadius: '5px',
                },
              }}
            >
              Actions
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem
                onClick={() =>
                  selectOption(
                    selectedWaterfall?.status === 'active'
                      ? 'turn-off-waterfall'
                      : 'turn-on-waterfall',
                  )
                }
              >
                <ListItemText>
                  Make waterfall {selectedWaterfall?.status === 'active' ? 'inactive' : 'active'}
                </ListItemText>
              </MenuItem>
              <MenuItem onClick={() => selectOption('team-management')}>
                <ListItemText>Team management</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => selectOption('restore-waterfall')}>
                <ListItemText>Restore default waterfall</ListItemText>
              </MenuItem>
            </Menu>
          </Stack>
        </Stack>
        <Stack direction={'row'}>
          <Typography color="textSecondary" fontSize={14} p={'6px 8px 0'}>
            {isDefaultWaterfall
              ? 'This board shows you the data providers included in the default SuperReach waterfall.'
              : 'This board shows you the providers you have selected for your own bespoke waterfall.'}
          </Typography>
          {showMore ? (
            <Typography color="textSecondary" fontSize={14} pt="6px">
              The order of the waterfall is shown in the list below.
            </Typography>
          ) : (
            <Typography
              sx={{ cursor: 'pointer !important' }}
              color="secondary"
              fontSize={14}
              pt={'6px'}
              onClick={() => setShowMore(true)}
            >
              Show more...
            </Typography>
          )}
          {showMore && (
            <Typography
              sx={{ cursor: 'pointer !important' }}
              color="secondary"
              fontSize={14}
              pt={'6px'}
              onClick={() => setShowMore(false)}
            >
              &nbsp;&nbsp;Show less
            </Typography>
          )}
        </Stack>
      </Stack>

      {loading?.waterfall === true ? (
        <TabsSkeleton />
      ) : (
        <>
          <Tabs
            tabs={tabs}
            onChange={onTabChange}
            value={currentTab}
            tabLabel={'name'}
            sx={{
              height: 'auto',
              width: '100%',
              '& .MuiTabs-flexContainer': { gap: '8px', padding: '0 6px' },
            }}
          />

          <DataProviders
            {...{
              tab,
              changeContract,
              props,
              selected,
              updateSettings: props?.updateSettings,
              tenantSettings: props.tenantSettings,
            }}
          />
        </>
      )}
    </Stack>
  );
}

const mapStateToProps = (state) => ({
  tenantSettings: state?.admin?.admin?.tenantSettings?.data?.[0] || {},
  settingValue: state.admin.admin.tenantSettings,
  integrations: state.admin.admin.integrations,
  loading: state.admin.admin.integrations.loading,
});
const mapDispatchToProps = (dispatch) => ({
  fetchTenantSettings: () => dispatch(fetchTenantSettings()),
  saveIntegrations: (data) => dispatch(saveIntegrations(data)),
  updateSettings: (data, id) => dispatch(updateSettings(data, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Waterfall);
