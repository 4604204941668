import React from 'react';
import { Box, Chip, Typography, CardContent, Card } from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useLmsReportsProvider } from '../../utils/reportsStore';
import randomColor from 'randomcolor';

function Chart() {
  const { graphData } = useLmsReportsProvider();

  return (
    <Card>
      <CardContent sx={{ height: 'calc(100% - 32px)' }}>
        <Box display="flex" width="100%" height="300px" mt={2}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart width="100%" height={300} data={graphData}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="allUsers"
                name="All users"
                stroke={randomColor({ luminosity: 'light', seed: 'allUsers' })}
              />
              <Line
                type="monotone"
                dataKey="averageScore"
                name="Average Score"
                stroke={randomColor({ luminosity: 'light', seed: 'score' })}
              />
              <Line
                type="monotone"
                dataKey="roleplays"
                name="Role-plays"
                stroke={randomColor({ luminosity: 'light', seed: 'roleplay' })}
              />
              <Line
                type="monotone"
                dataKey="sessionsViewed"
                name="Sessions viewed"
                stroke={randomColor({ luminosity: 'light', seed: 'views' })}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
}

export default Chart;
