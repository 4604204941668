import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Tooltip, Typography, Box, ButtonBase, Checkbox } from '@mui/material';
import { AppGrid } from 'src/components/App';
import { Button, Spacer, Modal } from 'src/components/shared';

import TemplatesGridActions from './TemplatesGridActions';
import TemplateActionDrawer from './TemplateActionDrawer';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';
import CloneTemplateForm from './CloneTemplateForm';
import { templateBulkAction, templateLinkedinType } from './config';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { useStyles } from '../../containers/integrations/styles';
import { HtmlTooltip } from './styles';
import { capitalizeFirstLetter } from 'src/utils/helper';

function TemplatesGrid({
  drawerToggle,
  templateType,
  templates,
  loading,
  templateBulkUpdate,
  globalConfig,
  sequenceAccessTypeList,
  sequenceEmailTypeList,
  ...props
}) {
  const classes = useStyles();
  const { data = [], filters, paging, sort = {} } = templates;
  const [rowsSelected, setRowsSelected] = useState([]);
  const [bulkAction, setBulkAction] = useState('');
  const [loadType, setLoadType] = useState(templateType);
  const [showCloneForm, setShowCloneForm] = useState(false);
  const [cloneTemplate, setCloneTemplate] = useState({});
  const currentUser = useSelector((state) => state.auth.user);
  const templateBasicType =
    props.location.pathname === '/templates/linkedin' ? 'linkedin' : 'email';

  useEffect(() => {
    if (templateType) {
      setLoadType(templateType);
    }
  }, [templateType]);

  const isDisplay = () => {
    if (templateType !== 'linkedin') return true;
    else return false;
  };
  const isMessageTypeDisplay = () => {
    if (templateType !== 'linkedin') return false;
    else return true;
  };

  const handleCloneTemplate = (template) => {
    setShowCloneForm(true);
    setCloneTemplate(template);
  };

  const addCategoryPayload = (value) => {
    if (globalConfig.templateCategoryList && globalConfig.templateCategoryList.length) {
      const category = globalConfig.templateCategoryList?.find((i, key) => {
        if (value === i.value) {
          return i.value;
        }
      });
      return category?.label;
    }
  };

  const templatesColumns = useMemo(() => [
    {
      name: 'view',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        draggable: false,
        allowToggle: false,
        viewColumns: false,
        setCellHeaderProps: (value) => ({
          style: { width: 22 },
        }),
        setCellProps: () => ({
          style: { width: 22 },
        }),
        customBodyRenderLite: (dataIndex) => {
          const row = data[dataIndex];
          return (
            <Box justifyContent="center" display="flex">
              <Tooltip title="View Template">
                <VisibilityIcon
                  fontSize={'small'}
                  style={{ color: 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }}
                  onClick={() => {
                    drawerToggle({ edit: row, open: true });
                  }}
                />
              </Tooltip>
            </Box>
          );
        },
      },
    },
    {
      label: 'Template name',
      name: 'name',
      options: {
        filter: true,
        sort: true,
        draggable: false,
        setCellHeaderProps: (value) => ({
          style: { width: 220 },
        }),
        setCellProps: () => ({
          style: { width: 220 },
        }),
        customBodyRenderLite: (dataIndex) => {
          const rData = data[dataIndex];
          return (
            <ButtonBase
              sx={{ textAlign: 'initial' }}
              className="text-truncate td-anchor-content-ellipsis"
              onClick={() => {
                drawerToggle({ edit: rData, open: true });
              }}
            >
              <Typography variant="body2" color="secondary">
                {`${capitalizeFirstLetter(rData.name)}`}
              </Typography>
            </ButtonBase>
          );
        },
      },
    },

    {
      label: 'Subject',
      name: 'subject',
      options: {
        filter: true,
        sort: true,
        draggable: true,
        setCellHeaderProps: (value) => ({
          style: { width: 400, maxWidth: 400 },
        }),
        setCellProps: () => ({
          style: { width: 400, maxWidth: 400 },
        }),
        customBodyRenderLite: (dataIndex) => {
          const rData = data[dataIndex];
          // return <div>{capitalizeFirstLetter(rData?.subject)}</div>;
          const subject = rData?.subject || '';
          return (
            <HtmlTooltip title={<div dangerouslySetInnerHTML={{ __html: subject }} />}>
              <Typography
                variant="body2"
                noWrap
                color="textPrimary"
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: subject }} />
              </Typography>
            </HtmlTooltip>
          );
        },
      },
    },

    {
      label: 'Category',
      name: 'category',
      options: {
        display: isDisplay(),
        filter: true,
        sort: true,
        draggable: true,
        setCellHeaderProps: (value) => ({
          style: { minWidth: 40 },
        }),
        setCellProps: () => ({
          style: { minWidth: 40 },
        }),
        customBodyRenderLite: (dataIndex) => {
          const rData = data[dataIndex];
          return <div>{addCategoryPayload(rData?.category)}</div>;
        },
      },
    },

    {
      label: 'Message type',
      name: 'type',
      options: {
        display: isMessageTypeDisplay(),
        filter: true,
        sort: true,
        draggable: true,
        setCellHeaderProps: (value) => ({
          style: { minWidth: 40 },
        }),
        setCellProps: () => ({
          style: { minWidth: 40 },
        }),
        customBodyRenderLite: (dataIndex) => {
          const rData = data[dataIndex];
          const type =
            rData?.type === 'linkedinConnection'
              ? 'Connection Request'
              : rData?.type === 'linkedinMessage'
              ? 'Message'
              : rData?.type === 'linkedinMail'
              ? 'InMail'
              : '-';
          return <div>{capitalizeFirstLetter(type)}</div>;
        },
      },
    },
    {
      label: 'Owner',
      name: 'owner',
      options: {
        filter: true,
        sort: true,
        draggable: true,
        setCellHeaderProps: (value) => ({
          style: { minWidth: 30 },
        }),
        setCellProps: () => ({
          style: { minWidth: 30 },
        }),
        customBodyRenderLite: (dataIndex) => {
          const rData = data[dataIndex];
          return (
            <div>
              {capitalizeFirstLetter(rData?.owner?.fname)}{' '}
              {capitalizeFirstLetter(rData?.owner?.lname)}
            </div>
          );
        },
      },
    },
    {
      label: 'Permission',
      name: 'permissionType',
      options: {
        filter: true,
        sort: true,
        draggable: true,
        setCellHeaderProps: (value) => ({
          style: { minWidth: 45 },
        }),
        setCellProps: () => ({
          style: { minWidth: 45 },
        }),
        customBodyRenderLite: (dataIndex) => {
          const rData = data[dataIndex];
          return <div>{capitalizeFirstLetter(rData?.permissionType)}</div>;
        },
      },
    },
    {
      label: 'Status',
      name: 'status',
      options: {
        filter: true,
        sort: true,
        draggable: true,
        setCellHeaderProps: (value) => ({
          style: { minWidth: 15 },
        }),
        setCellProps: () => ({
          style: { minWidth: 15 },
        }),
        customBodyRenderLite: (dataIndex) => {
          const rData = data[dataIndex];
          return (
            <Box
              className={`${
                rData?.status === 'active'
                  ? classes.gridContentRowDataActive
                  : classes.gridContentRowDataDeActive
              }`}
            >
              <Button
                sx={{
                  color: '#FFFFFF',
                  width: '80%',
                  background: rData?.status === 'active' ? '#66BB6A' : '#EF5350',
                }}
              >
                {rData?.status === 'active' ? 'Active' : 'Deactive'}
              </Button>
            </Box>
          );
        },
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        draggable: false,
        allowToggle: false,
        viewColumns: false,
        setCellHeaderProps: (value) => ({
          style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
        }),
        setCellProps: () => ({
          style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
        }),
        customBodyRender: (value) => {
          const row = data.find((task) => task.id === value);
          return (
            <TemplatesGridActions
              drawerToggle={drawerToggle}
              setShowCloneForm={setShowCloneForm}
              showCloneForm={showCloneForm}
              handleCloneTemplate={handleCloneTemplate}
              status={row?.status}
              row={row}
              {...props}
            />
          );
        },
      },
    },
  ]);

  const sequenceFilters = [
    {
      key: 'assignedTo',
      title: 'Owner',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: true,
      searchable: true,
      isPrimary: true,
      remoteMethod: async (value) => {
        return fetchUsersLookup(value);
      },
    },
    templateBasicType === 'email'
      ? {
          key: 'category',
          title: 'Category',
          type: 'dropdown',
          remote: true,
          optLabel: 'value',
          optValue: 'id',
          cancellable: true,
          searchable: true,
          isPrimary: true,
          remoteMethod: async (value) => {
            return globalConfig.templateCategoryList?.map((i, key) => ({
              id: i.value,
              value: i.label,
            }));
          },
        }
      : {
          key: 'type',
          title: 'Type',
          type: 'dropdown',
          remote: true,
          optLabel: 'value',
          optValue: 'id',
          cancellable: true,
          searchable: true,
          isPrimary: true,
          remoteMethod: async (value) => {
            return templateLinkedinType?.map((i, key) => {
              console.log(i);
              return {
                id: i.value,
                value: i.label,
              };
            });
          },
        },
  ];

  const handlePerPageChange = (data1, data2) => {
    console.log('handlePerPageChange:');
    console.log('data1', data1);
    console.log('data2', data2);
  };

  const onSort = (sort) => {
    props.fetchTemplates(
      paging,
      filters,
      {
        queryType: props.location.pathname === '/templates/linkedin' ? 'linkedin' : 'email',
      },
      sort,
    );
  };

  const onFilterChange = (key, value) => {
    if (value && typeof value !== 'undefined') {
      if (key === '_search') {
        props.fetchTemplates(
          {
            ...paging,
            pageNo: 0,
          },
          { ...filters, [key]: value },
          {
            queryType: props.location.pathname === '/templates/linkedin' ? 'linkedin' : 'email',
          },
          sort,
        );
      } else {
        props.fetchTemplates(
          paging,
          { ...filters, [key]: value },
          {
            queryType: props.location.pathname === '/templates/linkedin' ? 'linkedin' : 'email',
          },
          sort,
        );
      }
    } else {
      delete filters[key];
      props.fetchTemplates(
        paging,
        filters,
        { queryType: props.location.pathname === '/templates/linkedin' ? 'linkedin' : 'email' },
        sort,
      );
    }
  };

  const handleTableChange = ({ pageNo, perPage, loadType, sortOrder }) => {
    if (sortOrder && loadType !== '' && sortOrder !== null) {
    } else if (filters && filters._search !== undefined) {
      props.fetchTemplates(
        {
          pageNo,
          perPage,
        },
        {
          _search: filters._search,
        },
        { queryType: props.location.pathname === '/templates/linkedin' ? 'linkedin' : 'email' },
        sort,
      );
    } else {
      delete filters._search;
      props.fetchTemplates(
        {
          pageNo,
          perPage,
        },
        filters,
        { queryType: props.location.pathname === '/templates/linkedin' ? 'linkedin' : 'email' },
        sort,
      );
    }
  };

  const handleColumnChange = (data1, data2) => {};

  const CustomCheckbox = useCallback(
    (props) => {
      let flag = true;
      const { data } = props;

      let index = props['data-index'];

      if (index === null && props['data-description'] === 'row-select-header') flag = false; // skip header

      if (index === null && props['data-description'] === 'row-select') index = 0; // first index

      const currentRowData = index >= 0 ? data[index] : {};
      if (currentRowData?.owner?.id === currentUser.id) flag = false;

      return <Checkbox {...props} disabled={flag} onClick={props.onChange} />;
    },
    [currentUser],
  );

  const onBulkAction = (key) => {
    setBulkAction(key);
  };

  const closeBulkAction = (key) => {
    setBulkAction(undefined);
    setRowsSelected([]);
  };
  const selectTeams = useMemo(() => {
    return data?.filter((item, index) => rowsSelected.includes(index));
  }, [rowsSelected, data]);
  return (
    <>
      <AppGrid
        selectableRows="multiple"
        components={{
          Checkbox: (props) => <CustomCheckbox {...props} data={data} />,
        }}
        columns={templatesColumns.map((col, index) => {
          return {
            ...col,
            options: templatesColumns?.columns?.length
              ? { ...col.options, ...templatesColumns?.columns[index] }
              : col.options,
          };
        })}
        data={data}
        onSort={onSort}
        onTableRowPerPageChange={handlePerPageChange}
        onTableChange={handleTableChange}
        onColumnChange={() => handleColumnChange(false)}
        filters={sequenceFilters}
        onFilterChange={onFilterChange}
        appliedFilters={filters}
        loading={loading.templates}
        bulkActions={templateBulkAction(templateBasicType)}
        onBulkAction={onBulkAction}
        options={{
          sortOrder: {
            name: sort?.name || 'none',
            direction: sort?.direction || 'none',
          },
          serverSide: true,
          pagination: true,
          page: paging.pageNo,
          rowsPerPage: paging.perPage,
          count: paging.count,
          selectableRows: 'multiple',
          searchPlaceholder: 'Search templates',
          searchText: filters?._search ? filters._search : '',
          searchOpen: true,
          search: true,
          filter: false,
          download: false,
          viewColumns: false,
          selectToolbarPlacement: 'replace',
          renderCustomFilterActions: () => {
            return (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  drawerToggle({ edit: null, open: true });
                }}
              >
                Create template
              </Button>
            );
          },
          onRowSelectionChange: (selected, all) => {
            setRowsSelected(
              all
                .filter((row) => {
                  return data[row.dataIndex].owner.id === currentUser.id;
                })
                .map((row) => row.dataIndex),
            );
          },
          rowsSelected: rowsSelected,
        }}
      />
      <Modal
        title="Clone Template"
        size="xs"
        open={showCloneForm}
        onClose={() => {
          setShowCloneForm(false);
          setCloneTemplate({});
        }}
      >
        <CloneTemplateForm
          temp={cloneTemplate}
          drawerToggle={drawerToggle}
          queryType={
            props.location.pathname.split('/admin/')[1] === 'templates' ? 'email' : 'linkedin'
          }
          setShowCloneForm={setShowCloneForm}
          {...props}
        />
      </Modal>
      <TemplateActionDrawer
        action={bulkAction}
        templateType={
          props.location.pathname.split('/admin/')[1] === 'templates' ? 'email' : 'linkedin'
        }
        onClose={closeBulkAction}
        templateBulkUpdate={templateBulkUpdate}
        selectTemplates={selectTeams}
        loadType={loadType}
        globals={globalConfig}
        sequenceAccessTypeList={sequenceAccessTypeList}
        sequenceEmailTypeList={sequenceEmailTypeList}
      />
    </>
  );
}

export default TemplatesGrid;
