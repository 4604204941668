export const FETCH_AUTOMATIONS = 'FETCH_AUTOMATIONS';
export const SET_AUTOMATIONS = 'SET_AUTOMATIONS';
export const POST_AUTOMATION = 'POST_AUTOMATION';
export const PUT_AUTOMATION = 'PUT_AUTOMATION';
export const DELETE_AUTOMATION = 'DELETE_AUTOMATION';

export const FETCH_RESELLER_REPORT = 'FETCH_RESELLER_REPORT';
export const SET_RESELLER_REPORT = 'SET_RESELLER_REPORT';
export const FETCH_RESELLER_REPORT_SUMMARY = 'FETCH_RESELLER_REPORT_SUMMARY';
export const SET_RESELLER_REPORT_SUMMARY = 'SET_RESELLER_REPORT_SUMMARY';

export const FETCH_RESELLER_TENANT_REPORT = 'FETCH_RESELLER_TENANT_REPORT';
export const SET_RESELLER_TENANT_REPORT = 'SET_RESELLER_TENANT_REPORT';
export const FETCH_RESELLER_TENANT_REPORT_SUMMARY = 'FETCH_RESELLER_TENANT_REPORT_SUMMARY';
export const SET_RESELLER_TENANT_REPORT_SUMMARY = 'SET_RESELLER_TENANT_REPORT_SUMMARY';

export const FETCH_RESELLER_USER_REPORT = 'FETCH_RESELLER_USER_REPORT';
export const SET_RESELLER_USER_REPORT = 'SET_RESELLER_USER_REPORT';
export const FETCH_RESELLER_USER_REPORT_SUMMARY = 'FETCH_RESELLER_USER_REPORT_SUMMARY';
export const SET_RESELLER_USER_REPORT_SUMMARY = 'SET_RESELLER_USER_REPORT_SUMMARY';
export const SET_RESELLER_FILTERS = 'SET_RESELLER_FILTERS';
