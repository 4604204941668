import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TemplatesView from './TemplatesView';

import {
  fetchTemplates,
  saveTemplates,
  putTemplates,
  updateTemplateStatus,
  templateBulkUpdate,
} from '../../actions/adminActions';

export const TemplatesContainer = (props) => {
  const [path, setPath] = useState('');
  useEffect(() => {
    if (path && path !== '') {
      props.fetchTemplates(props.templates.paging, props.templates.filters, {
        queryType: path,
      });
    }
  }, [path]);

  useEffect(() => {
    setPath(props.location.pathname === '/templates/linkedin' ? 'linkedin' : 'email');
  }, [props.location.pathname]);

  return <TemplatesView templateType={path} {...props} />;
};
const mapStateToProps = (state) => {
  return {
    templates: state.admin.admin.templates,
    user: state.auth.user,
    globalConfig: state.app.globals,
    showContactForm: state.app.showContactForm,
    loading: state.admin.admin.loading,
    type: state.admin.admin.type,
    total: state.admin.admin.templates?.paging.count,
    sequenceAccessTypeList: state.app.globals.sequenceAccessTypeList,
    sequenceEmailTypeList: state.app.globals.sequenceEmailTypeList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchTemplates: (paging, filters, queryType, sort) =>
    dispatch(fetchTemplates(paging, filters, queryType, sort)),
  saveTemplates: (data) => dispatch(saveTemplates(data)),
  updateTemplateStatus: (rowData) => {
    dispatch(updateTemplateStatus(rowData));
  },
  putTemplates: (id, rowData) => {
    dispatch(putTemplates(id, rowData));
  },
  templateBulkUpdate: (template, payload, loadType) => {
    return new Promise((resolve, reject) => {
      dispatch(templateBulkUpdate(template, payload, loadType, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesContainer);
