import React, { useState } from 'react';
import { Typography, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { getStepIcon, getStepTitle, getCardBgColor } from '../../../utils/sequenceHelper';

import LinkedIn from './LinkedIn';
import General from './General';
import Wait from './Wait';
import Email from './Email';

import { styled } from '@mui/system';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme, action }) => ({
  ...(action !== 'close' && {
    borderBottom: `none`,
    boarderRadius: '10px',
    '&.Mui-expanded': {
      borderBottom: `none`,
    },
  }),
  '&:before': {
    display: 'none',
  },
}));

function Card({ event, index, children, formProps, waitTime }) {
  const [expanded, setExpanded] = useState(true);

  const linkedinTypes = ['linkedin', 'linkedinConnection', 'linkedinMessage', 'linkedinMail'];
  const readonlyTypes = ['linkedinViewProfile', 'selectEmailTemplate', 'endsquence'];
  const generalTypes = ['general', 'call', 'sms'];

  return (
    <Accordion
      disableGutters
      sx={{ margin: '12px 0px' }}
      expanded={expanded}
      onChange={() => {
        setExpanded(!expanded);
      }}
    >
      <MuiAccordionSummary
        expandIcon={!readonlyTypes.includes(event.eventType) ? <ExpandMoreIcon /> : null}
        sx={{ background: getCardBgColor(event.eventType), margin: 0 }}
      >
        <Stack direction="row" spacing={1}>
          <Typography color="textSecondary">Step {index + 1}</Typography>
          <Typography color="textSecondary">-</Typography>

          <Typography color="textSecondary">Day {Math.floor(waitTime / 86400) + 1}</Typography>
          <div style={{ width: 1 }} />
          {getStepIcon(event.eventType)}
          <Typography color="textSecondary">{getStepTitle(event.eventType)}</Typography>
        </Stack>
      </MuiAccordionSummary>

      {!readonlyTypes.includes(event.eventType) && (
        <MuiAccordionDetails sx={{ padding: 0 }}>
          {linkedinTypes.includes(event.eventType) ? (
            <LinkedIn event={event} formProps={formProps} />
          ) : null}
          {generalTypes.includes(event.eventType) ? <General event={event} /> : null}
          {event.eventType === 'wait' ? <Wait event={event} /> : null}
          {event.eventType === 'email' ? <Email event={event} formProps={formProps} /> : null}
        </MuiAccordionDetails>
      )}
    </Accordion>
  );
}

export default Card;
