import { Grid, Modal } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, Button } from '@mui/material';
import useStyles from './style';
import { Form } from 'src/components/shared';
import * as Yup from 'yup';
import { templates, updateTemplate } from '../../api/competitionApi';
import { useSelector } from 'react-redux';
import toast from 'src/utils/toast';

function CompetitionTemplate({
  open = false,
  handleClose = () => {},
  invite = false,
  update = false,
  result = false,
}) {
  const classes = useStyles();
  const [content, setContent] = React.useState('');
  const settings = useSelector((state) => (open ? state?.app?.tenant?.tenant_settings?.[0] : ''));

  const template = {
    resultTemplate: settings?.competitionResultTemplate,
    updateTemplate: settings?.competitionUpdateTemplate,
    inviteTemplate: settings?.competitionInviteTemplate,
  };

  const id = invite
    ? template?.inviteTemplate
    : update
    ? template?.updateTemplate
    : template?.resultTemplate;
  React.useEffect(() => {
    fetchTemplateContent();
  }, [open]);
  const fetchTemplateContent = async () => {
    try {
      if (open === true) {
        const res = await templates(id);
        setContent(res?.template?.content);
      }
    } catch (error) {}
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Form
        initialValues={{
          content: content || '',
        }}
        validationSchema={Yup.object().shape({})}
        enableReinitialize={true}
        onSubmit={async (values, form) => {
          const payload = {
            content: values?.content,
          };
          try {
            const res = await updateTemplate(id, payload);
            if (res) {
              toast.success('Template updated');
              handleClose();
              dispatch(fetchTenantConfig());
            }
          } catch (error) {}
        }}
      >
        {({ values, ...formProps }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formProps.submitForm();
                return false;
              }}
              noValidate
            >
              <Box className={classes.templatePreview + ' Inter-font-family'}>
                <Box display={'flex'} justifyContent={'space-between'} mt={2} ml={3} mr={3} mb={3}>
                  <Typography className={classes.title}>
                    Edit {invite ? 'invite' : update ? 'update' : 'result'} email template
                  </Typography>
                  <CloseIcon onClick={handleClose} className={classes.close} />
                </Box>
                <hr className={classes.lineColor} />
                <Grid container p={3}>
                  <Typography className={classes.header}>Template content</Typography>
                  <Grid item xl={12} lg={12} md={12}>
                    <Form.Field.TextEditor
                      toolbarLocation="bottom"
                      fullWidth
                      variant="outlined"
                      height={420}
                      name="content"
                      competitiveLearning={true}
                      showPlaceholder={true}
                    />
                  </Grid>
                  <Grid mt={4} item xl={12} lg={12} md={12} display={'flex'} justifyContent={'end'}>
                    <Button className={classes.cancelButton} onClick={handleClose}>
                      {'Cancel'}
                    </Button>
                    <Button
                      sx={{ marginLeft: '10px' }}
                      className={classes.saveButton}
                      type="submit"
                      variant="contained"
                    >
                      {'Update'}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
}

export default CompetitionTemplate;
