const noteHeader = [
  {
    id: 1,
    status: 'active',
    type: 'default',
    label: 'Session name',
    fieldName: 'name',
    fieldType: 'text',
    inContact: false,
    inUser: false,
    inTask: false,
    inSequence: true,
    scope: ['sequences'],
    fieldOptions: { showOnGrid: true, showOnForm: true },
    gridParams: { sequences: { sortOrder: 0 } },
    bhConfig: null,
    lookup: null,
  },
  {
    id: 2,
    status: 'active',
    type: 'default',
    label: 'Note',
    fieldName: 'noteContent',
    fieldType: 'formula',
    inContact: false,
    inUser: false,
    inTask: false,
    inSequence: true,
    scope: ['sequences'],
    fieldOptions: { showOnGrid: true, showOnForm: false },
    gridParams: { sort: 'false', sequences: { sortOrder: 2 } },
    bhConfig: null,
    lookup: null,
  },
  {
    id: 3,
    status: 'active',
    type: 'default',
    label: 'Added by',
    fieldName: 'addedBy',
    fieldType: 'formula',
    inContact: false,
    inUser: false,
    inTask: false,
    inSequence: true,
    scope: ['sequences'],
    fieldOptions: { showOnGrid: true, showOnForm: false },
    gridParams: { sort: 'false', sequences: { sortOrder: 2 } },
    bhConfig: null,
    lookup: null,
  },
];

export default noteHeader;
