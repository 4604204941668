import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@mui/material';
import RegistrationForm from '../../components/Register/RegistrationForm';
import PersonalDetailsForm from '../../components/Register/PersonalDetailsForm';

export default function RegisterView(props) {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});

  const onSubmit = async (data, form) => {
    const payload = {
      ...data,
      ...formData,
    };
    return props.register(payload);
  };

  const handleEmailChange = (data) => {
    setFormData(data);
    setStep(2);
  };

  return (
    <React.Fragment>
      <Typography variant="h1" align="center">
        {step === 1 ? 'Signup' : 'Personal Details'}
      </Typography>
      <div style={{ height: 10 }} />
      <div style={{ maxWidth: 400, margin: '0 auto' }}>
        {step === 1 ? <RegistrationForm onSubmit={handleEmailChange} /> : null}
        {step === 2 ? <PersonalDetailsForm onSubmit={onSubmit} /> : null}
      </div>
    </React.Fragment>
  );
}
