import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Typography, Box, List, Divider, Paper, FormHelperText } from '@mui/material';
import { DropContainer, Dropper, PreviewContainer, Preview } from './styles';
import File from './File';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import Cropper from '../Avatar/Cropper';

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  multiple: PropTypes.bool,
  crop: PropTypes.bool,
  skipCrop: PropTypes.bool,
  aspectRatio: PropTypes.any,
  folder: PropTypes.string,
  fileName: PropTypes.string,
  onUploadStart: PropTypes.func,
  label: PropTypes.string,
  icon: PropTypes.any,
  width: PropTypes.any,
};

const defaultProps = {
  className: undefined,
  value: undefined,
  multiple: false,
  folder: '',
  fileName: '',
  onUploadStart: () => {},
  label: "Drag 'n' drop some files here, or click to select files",
  width: 100,
  crop: false,
  skipCrop: false,
  aspectRatio: 1,
};

const FileDropper = ({
  name,
  value,
  multiple,
  onChange,
  accept,
  clearFiles,
  folder,
  fileName,
  onUploadStart,
  label,
  width,
  crop,
  aspectRatio,
  skipCrop,
  ...props
}) => {
  const [files, setFiles] = React.useState([]);
  const [isMount, setIsMount] = React.useState(true);
  const [cropImage, setCropImage] = React.useState(undefined);

  React.useEffect(() => {
    if (JSON.stringify(value) !== '{}' && value) {
      setFiles([value]);
    }
  }, [value]);

  React.useEffect(() => {
    onChange(multiple ? files : files[0], null);
  }, [files]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: accept || 'image/*',
    multiple: multiple,
    onDrop: (acceptedFiles) => {
      if (!acceptedFiles.length) return null;
      handleFileChanges(acceptedFiles);
    },
  });

  const handleFileChanges = async (acceptedFiles) => {
    onUploadStart();
    if (multiple) {
      await setFiles([...files, ...acceptedFiles]);
    } else {
      if (crop) {
        setCropImage(URL.createObjectURL(acceptedFiles[0]));
      } else {
        await setFiles([...acceptedFiles]);
      }
    }
  };

  const handleClose = () => {
    try {
      setCropImage(undefined);
    } catch (error) {}
  };

  const handleSubmit = async (file) => {
    try {
      await setFiles([file]);
      handleClose();
    } catch (error) {}
  };

  const onUpload = async (file, res) => {
    await setFiles((curr) =>
      curr.map((fw) => {
        if (fw === file) {
          return {
            ...file,
            ...res.upload,
          };
        }
        return fw;
      }),
    );
  };

  const onDelete = async (file) => {
    if (multiple && files.length > 0) await setFiles((curr) => curr.filter((fw) => fw !== file));
    else setFiles([]);
  };

  if (multiple && multiple.length) {
  }

  return (
    <React.Fragment>
      <DropContainer>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          {!multiple && files.length > 0 ? (
            <File
              file={files[0]}
              onUpload={onUpload}
              onDelete={onDelete}
              folder={folder}
              fileName={fileName}
              multiple={multiple}
            />
          ) : (
            <Dropper>
              <Box mb={1}>
                <CloudUploadIcon sx={{ fill: 'rgba(0, 0, 0, 0.26)', fontSize: '2rem' }} />
              </Box>
              <Typography variant="body2" color="textSecondary">
                {label}
              </Typography>
            </Dropper>
          )}
        </div>
        {files.length > 0 && multiple === true && (
          <Box mx={2} mb={2}>
            <Paper variant="outlined">
              <List dense={false} disablePadding={true}>
                {files.map((file, index) => {
                  return (
                    <React.Fragment
                      key={`dropper-${file?.lastModified ? file?.lastModified : index}`}
                    >
                      <File
                        file={file}
                        onUpload={onUpload}
                        onDelete={onDelete}
                        folder={folder}
                        fileName={fileName}
                      />
                      {files.length > 1 && files.length - 1 !== index && <Divider />}
                    </React.Fragment>
                  );
                })}
              </List>
            </Paper>
          </Box>
        )}
      </DropContainer>
      {cropImage && typeof cropImage !== 'undefined' && (
        <Cropper
          cropShape="rect"
          image={cropImage}
          aspectRatio={aspectRatio}
          handleSubmit={handleSubmit}
          onClose={handleClose}
          loading={false}
          allowSkip={skipCrop}
        />
      )}
    </React.Fragment>
  );
};

FileDropper.propTypes = propTypes;
FileDropper.defaultProps = defaultProps;

export default FileDropper;
