import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Pagination,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SequenceItem from './SequenceItem';
import { time_ago } from '../ContactTasks/helper';
import { ButtonCircle } from './styles';
import { Box } from '@mui/material';
import ContactSequenceSkeleton from './ContactSequenceSkeleton';
import { Empty } from 'src/components/App';
function Sequence(props) {
  const { data = [], paging = {} } = props.sequenceEnrollments || {};
  const contactId = props.match.params.id;
  const handleChange = (event, value) => {
    props.fetchSequenceEnrollments(contactId, '', { pageNo: value - 1, perPage: 10 });
  };
  const [expanded,setExpanded] = useState(true)

  return (
    <>
      {props.isLoadingContactSequence ? (
        <ContactSequenceSkeleton />
      ) : (
        <>
          <Accordion expanded={expanded} onClick={() => setExpanded(!expanded)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Sequences<ButtonCircle>{paging.count}</ButtonCircle>
              </Typography>
            </AccordionSummary>

            {data?.length ? (
              <AccordionDetails>
                {data?.map((tasks, index) => (
                  <SequenceItem
                    key={index}
                    type={tasks.sequence?.status || ''}
                    title={tasks.sequence.name || ''}
                    date={tasks.sequence?.createdAt || undefined}
                    id={tasks.sequence.id}
                  />
                ))}
                {data?.length ? (
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent={'center'}
                    mt={2}
                  >
                    <Pagination
                      count={Math.ceil(paging.count / paging.perPage)}
                      page={paging.pageNo + 1}
                      onChange={handleChange}
                    />
                  </Box>
                ) : (
                  <Empty title="No Enrollments found!" />
                )}
              </AccordionDetails>
            ) : (
              <Empty title="No sequences found" />
            )}
          </Accordion>
        </>
      )}
    </>
  );
}
Sequence.propTypes = {};

export default Sequence;
