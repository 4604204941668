import api from 'src/api';

export const fetchTags = () => {
  return api(`/tag`);
};

export const updateTagStatus = (rowData) => {
  const payload = {
    status: rowData.status,
  };
  return api(`/admin/tags/${rowData.tagId}`, payload, 'put');
};



