import React, { useEffect, useState } from 'react';
import { Box, Typography, Avatar, ButtonBase } from '@mui/material';
import { deepOrange, lightBlue } from '@mui/material/colors';
import LayersIcon from '@mui/icons-material/Layers';

const Sequence = ({ option, data, handleClick, onClose }) => {
  const [color, setColor] = useState('success');
  const [status, setStatus] = useState('');

  useEffect(() => {
    switch (data.status) {
      case 'unpublished':
        setColor('rgba(0,0,0,0.25)');
        setStatus('Unpublished Sequence');
        break;

      case 'published':
      default:
        setColor('success.main');

        setStatus('Active Sequence');
        break;
    }
  }, [data]);

  return (
    <ButtonBase
      onClick={() => {
        handleClick(data);
        onClose();
      }}
    >
      <Box display="flex" flexDirection="row" p={1} ml={1}>
        <Avatar
          variant="circle"
          sx={{ bgcolor: lightBlue[500], width: '36px', height: '36px', marginTop: '4px' }}
        >
          <LayersIcon sx={{ fontSize: '1.25rem' }} />
        </Avatar>
        <Box ml={2} maxWidth={300} display="flex" alignItems="flex-start" flexDirection="column">
          <Typography noWrap variant="body1">
            {data.name}
          </Typography>
          <Typography noWrap variant="body2" color={color}>
            {status}
          </Typography>
        </Box>
      </Box>
    </ButtonBase>
  );
};

export default Sequence;
