import React from 'react';
import PropTypes from 'prop-types';
import { AvatarContainer, AvatarPreview, AvatarImage } from './style';
import Avatar from '../../Avatar';

import { fileUpload } from 'src/modules/app/api/appApis';
import toast from 'src/utils/toast';
import Cropper from './Cropper';
import Button from '../../Button';

function AvatarPicker({ id, value, cropShape, aspectRatio, alt, editButtonTitle, ...props }) {
  const [avatar, setAvatar] = React.useState('');
  const [image, setImage] = React.useState(undefined);
  const [uploading, setUploading] = React.useState(false);

  const inputFileRef = React.useRef();

  React.useEffect(() => {
    if (
      (typeof value === 'string' || value instanceof String) &&
      value &&
      value != null &&
      value !== ''
    ) {
      setAvatar(value);
    }
  }, [value]);

  const handleClose = () => {
    setImage(undefined);
  };

  const handleUpload = (file) => {
    return new Promise(async (resolve, reject) => {
      try {
        setUploading(true);
        const res = await fileUpload(undefined, file);
        setUploading(false);
        setAvatar(res.upload.url);
        props.onChange(res.upload.url);
        resolve(res);
        handleClose();
      } catch (error) {}
    });
  };

  const onFileSelect = async (e) => {
    const file = e.target?.files[0];
    if (file) {
      let res;
      try {
        if (props.crop) {
          setImage(URL.createObjectURL(file));
        } else {
          res = await handleUpload(file);
        }
      } catch (error) {
        toast.error('Error uploading the image. Please try again.');
      }
    }
  };

  const handleSubmit = async (file) => {
    try {
      const res = await handleUpload(file);
    } catch (error) {}
  };

  return (
    <AvatarContainer>
      <input
        id={id}
        type="file"
        style={{ display: 'none' }}
        accept="image/*"
        onChange={(e) => onFileSelect(e)}
        ref={inputFileRef}
      />
      {avatar && avatar !== '' ? (
        <AvatarPreview>
          <label htmlFor={id} style={{ display: 'inline-block' }}>
            <AvatarImage src={avatar} />
          </label>
        </AvatarPreview>
      ) : (
        <label htmlFor={id} style={{ display: 'inline-block' }}>
          <Avatar name={alt} size={120} />
        </label>
      )}
      {image && typeof image !== 'undefined' && (
        <Cropper
          cropShape={cropShape}
          image={image}
          aspectRatio={aspectRatio}
          handleSubmit={handleSubmit}
          onClose={handleClose}
          loading={uploading}
        />
      )}
      {props.showEditButton && (
        <Button
          onClick={() => {
            inputFileRef.current.click();
          }}
          {...props.editButtonProps}
        >
          {editButtonTitle}
        </Button>
      )}
    </AvatarContainer>
  );
}

AvatarPicker.propTypes = {
  crop: PropTypes.bool,
  aspectRatio: PropTypes.any,
  cropShape: PropTypes.string,
  alt: PropTypes.string,
  showEditButton: PropTypes.bool,
  editButtonTitle: PropTypes.string,
  editButtonProps: PropTypes.any,
};

AvatarPicker.defaultProps = {
  crop: false,
  aspectRatio: 1,
  cropShape: 'rect',
  alt: '',
  showEditButton: false,
  editButtonTitle: 'Upload',
  editButtonProps: {},
};

export default AvatarPicker;
