import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import InboxView from './InboxView';
import { Tabs, PageTitle, Pagination } from 'src/components/App';
import { inboxTabs } from '../../config';

import {
  clearTaskReplies,
  fetchInbox,
  fetchTaskReplies,
  deleteTaskReply,
} from '../../actions/taskActions';
import { clearContactData, putEnrollment } from 'src/modules/contacts/actions/contactActions';

export const InboxContainer = (props) => {
  const [currentTab, setCurrentTab] = useState('inbox');

  useEffect(() => {
    props.clearTaskReplies();
    props.clearContactData();
    return () => {
      props.clearTaskReplies();
      props.clearContactData();
    };
  }, []);

  const handlePageChange = ({ page, perPage }) => {
    props.fetchInbox(props.inbox.filters, { pageNo: page, perPage });
  };

  const paging = props.inbox.paging;

  return (
    <>
      <PageTitle title="Inbox" />
      <Tabs
        tabs={inboxTabs}
        value={currentTab}
        onChange={() => {}}
        justifyContent="space-between"
        customRender={() => (
          <Pagination
            count={paging.count}
            page={paging.pageNo}
            onChange={handlePageChange}
            perPage={paging.perPage}
          />
        )}
      />
      <InboxView {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.tasks.loading,
  inbox: state.tasks.inbox,
  taskReplies: state.tasks.taskReplies,
  contact: state.contacts.contact,
  contactLoading: state.contacts.loading.contact,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  clearTaskReplies: () => dispatch(clearTaskReplies()),
  clearContactData: () => dispatch(clearContactData()),
  putEnrollment: (id, enrollment) => dispatch(putEnrollment(id, enrollment)),
  fetchInbox: (filters, paging) => dispatch(fetchInbox(filters, paging)),
  fetchTaskReplies: (taskId) => dispatch(fetchTaskReplies(taskId)),
  deleteTaskReply: (replyId) => {
    return new Promise((resolve, reject) => {
      dispatch(deleteTaskReply(replyId, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InboxContainer);
