import styled from 'styled-components';
import { List, ListItem, LinearProgress } from '@mui/material';

export const UploadWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const FilesWrapper = styled(List).attrs({
  disablePadding: true,
})`
  margin-top: 10px !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
`;

export const FileItem = styled(ListItem).attrs({})`
  position: relative;
  opacity: ${(props) => (props.loading ? '0.40' : '1')} !important;
`;

export const Progress = styled(LinearProgress).attrs({
  variant: 'determinate',
})`
  /* position: absolute; */
  bottom: 0px;
  left: 0;
  width: 100%;
`;
