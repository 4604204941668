import React from 'react';
import { Box, ButtonBase, Grid, Typography } from '@mui/material';
import { Avatar, Spacer } from 'src/components/shared';
import images from 'src/config/images';
import { IntroTitle, IntroDescription, useStyles } from './styles';
import { useIntercom } from 'react-use-intercom';

function Intro({ user, ...props }) {
  const { boot, shutdown, hide, show, update } = useIntercom();

  const classes = useStyles();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography variant="h1">SuperReach Help and Support  </Typography>
          <Spacer x={2} y={2} />
          <Box mt={1}>
            <>
              <Typography color="textSecondary">
                Ensuring that you get the most out of the tools and services that we provide is a
                SuperReach top priority. We've kept it simple:
              </Typography>
              <Spacer x={1} y={1} />
              <Typography color="textSecondary">
                If you aren't sure how to do something on the platform please check out our{' '}
                <a href="https://help.superreach.com/" target="_blank">
                  <Typography component="span" color="secondary">
                    Help centre.
                  </Typography>
                </a>
              </Typography>
              <Spacer x={1} y={1} />
              <Typography color="textSecondary">
                If you want some human help, don't fear, you can{' '}
                <ButtonBase
                  sx={{ marginRight: 0.6 }}
                  onClick={async () => {
                    try {
                      await boot();
                      await show();
                    } catch (error) {}
                  }}
                >
                  <Typography component="span" color="secondary">
                    message the team here{' '}
                  </Typography>
                </ButtonBase>
                too!
              </Typography>
            </>
          </Box>
        </Grid>
        <Grid item xs={4} display="flex" alignItems="flex-end" justifyContent="flex-end">
          <img src={images.onboarding.supportIcon} width={200} />
        </Grid>
      </Grid>
    </>
  );
}

export default Intro;
