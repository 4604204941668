import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Button, Spacer } from '../../../../components/shared';
import AddIcon from '@mui/icons-material/Add';
import { Tabs } from '../../../../components/App';
import { Drawer } from 'src/components/shared';
import { contactsTabs } from '../../config';
import ContactsGrid from '../../components/Contacts/ContactsGrid';
import ContactForm from '../../components/Form/ContactForm';

function ContactsView({ fields, contacts, ...props }) {
  const [currentTab, setCurrentTab] = useState('allContacts');
  const [drawerOpen, setDrawerOpen] = useState({ edit: null, open: false });
  const [tabIndex, setTabIndex] = useState(0);
  const [tab, setTab] = useState({});

  const onTabChange = (tab) => {
    setCurrentTab(tab.id);
  };

  return (
    <>
      <Tabs tabs={contactsTabs} onChange={onTabChange} value={currentTab} />
      <Spacer y={0.5} />
      <Box px={2}>
        <ContactsGrid
          contacts={contacts}
          fields={fields}
          edit={drawerOpen.edit}
          drawerToggle={setDrawerOpen}
          {...props}
        />
      </Box>
    </>
  );
}

export default ContactsView;
