import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Grid, Box, Typography, Alert, FormHelperText } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import { Popover } from 'src/components/App';
import { taskAutoCloseOptions } from '../../../config';

const TaskForm = ({ onSubmit, loading, event, handleClose, ...props }) => {
  const popoverRef = useRef(null);

  const handleSubmit = async (values) => {
    const data = JSON.parse(JSON.stringify(values));
    data.subject = data.description;
    onSubmit(data);
  };

  return (
    <Form
      initialValues={{
        eventType: 'general',
        name: event?.name || '',
        description: event?.description || '',
        saveAsNew: false,
        isAutoClose: event?.isAutoClose || false,
        autoCloseDuration: event?.autoCloseDuration ? parseInt(event?.autoCloseDuration) : 0,
      }}
      validationSchema={Yup.object().shape({
        // name: Yup.string().required('Please enter subject!'),
        description: Yup.string().required('Please enter description!'),
        autoCloseDuration: Yup.number()
          .integer()
          .test({
            message: () => 'Please choose Auto-Close duration!',
            test: (values, { options }) => {
              if (!values && options.parent.isAutoClose === false) return true;
              return values && values > 0;
            },
          }),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ values, ...formProps }) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formProps.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
              height="100%"
              width="100%"
            >
              <Box width="100%">
                {/* <Form.Field.Input fullWidth variant="outlined" name="name" label="Subject" /> */}

                <Spacer x={2} y={2} />

                <Form.Field.TextEditor
                  fullWidth
                  variant="outlined"
                  name="description"
                  label="Description"
                />

                <Box display="flex" alignItems="center" mt={2}>
                  <Box ml={2} mr={-2} mt={-1.5}>
                    <Form.Field.Switch name="isAutoClose" label={''} />
                  </Box>
                  <Typography variant="body2" color="textSecondary">
                    Automatically mark this step as complete if not completed in <br />
                    <Popover
                      trigger="click"
                      width={240}
                      Child={
                        <Typography
                          color={values.isAutoClose ? 'secondary' : 'textSecondary'}
                          variant="body2"
                          sx={{ display: 'inline' }}
                        >
                          {values?.autoCloseDuration && values?.autoCloseDuration > 0
                            ? taskAutoCloseOptions.filter(
                                (item) => item.value === values?.autoCloseDuration,
                              )[0]?.label
                            : 'Select Days'}
                        </Typography>
                      }
                      isPadding={true}
                      ref={popoverRef}
                      disabled={!values.isAutoClose}
                    >
                      <Form.Field.Select
                        options={taskAutoCloseOptions}
                        fullWidth={true}
                        variant="outlined"
                        name="autoCloseDuration"
                        optLabel="label"
                        optValue="value"
                        showNone={false}
                        onChange={(val) => {
                          popoverRef.current.close();
                        }}
                      />
                    </Popover>
                    &nbsp;after the due date.
                  </Typography>
                </Box>
                {formProps?.errors?.autoCloseDuration &&
                  formProps?.errors?.autoCloseDuration !== '' && (
                    <Box ml={6.5} mt={1}>
                      <FormHelperText error={true}>
                        {formProps?.errors?.autoCloseDuration}
                      </FormHelperText>
                    </Box>
                  )}
                <Spacer x={2} y={2} />
                <Alert severity="info" style={{ visibility: 'hidden' }}>
                  To ensure that this task, if not complete, does not stop your sequence from
                  progressing, enable the above setting.
                </Alert>
              </Box>

              <Box mt={2} display="flex" justifyContent="flex-end" width="100%">
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={loading}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Spacer basis={2} />
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
};

export default TaskForm;
