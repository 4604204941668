import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { validateIntegration } from '../../api/adminApi';
import {
  fetchIntegrations,
  saveIntegrations,
  disconnectIntegration,
} from '../../actions/adminActions';
import IntegrationsView from './IntegrationsView';
import { getQueryParams } from 'src/modules/app/utils/helper';
import { useHistory } from 'react-router';

const IntegrationsContainer = (props) => {
  const history = useHistory();
  useEffect(() => {
    props.fetchIntegrations();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const params = getQueryParams();
      const query = {};
      Object.keys(params)?.forEach((ele) =>
        ['code', 'state'].includes(ele) ? (query[ele] = params[ele]) : null,
      );
      if (Object.keys(query).length) {
        const {
          tenantIntegration: { id, name },
        } = await validateIntegration(query);
        history.replace(`/admin/integrations/${name}/${id}`);
      }
    };
    fetchData();
  }, [getQueryParams()]);

  return <IntegrationsView {...props} />;
};

const mapStateToProps = (state) => {
  return {
    integrations: state.admin.admin.integrations,
    loading: state.admin.admin.integrations.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchIntegrations: () => dispatch(fetchIntegrations()),
  saveIntegrations: (data) => dispatch(saveIntegrations(data)),
  disconnectIntegration: (id, status) => dispatch(disconnectIntegration(id, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationsContainer);
