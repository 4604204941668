import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Box, List, ListItem, ListItemText, ListItemButton } from '@mui/material';
import { NavItemLink } from './styles';

function NavItem({ parentText = '', currentPath = '', subItems = [], ...props }) {
  const history = useHistory();
  return (
    <Box display={'flex'} flexDirection={'column'} mt={2}>
      <Typography variant="body2" color="#ffffff" fontWeight={800}>
        {parentText}
      </Typography>

      <List dense disablePadding>
        {subItems.map((k, index) => (
          <NavItemLink
            key={`sub-sub${k.id}-${index}`}
            onClick={() => {
              history.push(`/lms/${k.contentType}/${k.id}`);
            }}
            sx={{ padding: '0px 0px' }}
          >
            <ListItem disablePadding>
              <ListItemText
                sx={{ marginBottom: 0 }}
                primary={
                  <Typography
                    color={currentPath === k.id ? 'secondary' : 'textSecondary'}
                    noWrap
                    variant="caption"
                    sx={{ maxWidth: '160px' }}
                  >
                    {k.title}
                  </Typography>
                }
              />
            </ListItem>
          </NavItemLink>
        ))}
      </List>
    </Box>
  );
}

export default NavItem;
