import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ custom }) => ({
  headerSubTitle: {
    color: '#646464',
    letterSpacing: '0.17px',
    lineHeight: '143%',
  },
  tagCategoryBtn: {
    bottom: '-55px',
    position: 'relative',
    display: 'grid',
    justifyContent: 'end',
    marginTop: '-30px',
    zIndex: 1,
  },
}));
