import React, { useEffect, useState } from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { updateUserStatus } from '../../actions/adminActions';
// import success from 'src/utils/toast';

const UserGridAction = ({ drawerToggle, ...props }) => {
  const [anchorElement, setAnchorElement] = useState(null);

  const menuId = 'action-menu-id';

  const handleActionsMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = (type) => () => {
    setAnchorElement(null);
    const rowData = { id: props.row.id, status: props.row.status };
    switch (type) {
      case 'editPopUp': {
        drawerToggle({ edit: props.row, open: true });

        break;
      }
      case 'updateUserStatus': {
        props.updateUserStatus(rowData);
        break;
      }
      case 'resetPassword': {
        // props.handleClonePopupOpen(props.temp_id);
        break;
      }
      default:
        break;
    }
  };

  const menuItem = [
    {
      title: 'Edit',
      itemType: 'editPopUp',
    },
    {
      title: props.row?.status === 'active' ? 'Inactive' : 'Active',
      itemType: 'updateUserStatus',
    },
    /* {
      title: 'Reset password',
      itemType: 'resetPassword',
    }, */
  ];

  return (
    <React.Fragment>
      <Typography aria-controls={menuId} aria-haspopup="true" onClick={handleActionsMenuOpen}>
        <MoreVertIcon style={{ fill: '#5780ab' }} />
      </Typography>
      <Menu
        anchorEl={anchorElement}
        id={menuId}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleMenuClose()}
      >
        {menuItem.map((mItem, index) => (
          <MenuItem key={mItem.itemType + index} onClick={handleMenuClose(mItem.itemType)}>
            {mItem.title}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default UserGridAction;
