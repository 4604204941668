import React, { useEffect } from 'react';
import Reports from '../components/Reports';
import ReportDetails from '../components/ReportDetails';
import { withReportsStore } from '../utils/reportsStore';

function LMSDashboardContainer(props) {
  if (props.match.path === '/lms/dashboard/:id') {
    return <ReportDetails {...props} />;
  }
  return <Reports {...props} />;
}

export default withReportsStore(LMSDashboardContainer);
