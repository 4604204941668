import * as authTypes from './authTypes';

// export const toggleSidebar = () => ({ type: authTypes.TOGGLE_SIDEBAR });
export const login = (data, resolve, reject) => ({
  type: authTypes.LOGIN,
  data,
  resolve,
  reject,
});

export const register = (data, resolve, reject) => ({
  type: authTypes.REGISTER,
  data,
  resolve,
  reject,
});

export const registerSetPassword = (uid, data, resolve, reject) => ({
  type: authTypes.REGISTER_SET_PASSWORD,
  uid,
  data,
  resolve,
  reject,
});

export const logout = (data, resolve, reject) => ({
  type: authTypes.LOGOUT,
  data,
});

export const restoreSession = (newUser = false, redirect) => ({
  type: authTypes.RESTORE_SESSION,
  newUser,
  redirect,
});

export const showWelcomeModal = (welcomeModal = false, newUser = false) => ({
  type: authTypes.SHOW_WELCOME_MODAL,
  payload: { welcomeModal, newUser },
});

export const setAuth = (data) => ({ type: authTypes.SET_AUTH, data });
export const userLoginError = (error) => ({ type: authTypes.USER_LOGIN_ERROR, error });
export const setEmailPlugIn = (data) => ({
  type: authTypes.SET_EMAIL_PLUGIN,
  data,
});
export const setProfile = (data) => ({ type: authTypes.SET_PROFILE, data });

export const forgotPassword = (payload, resolve, reject) => ({
  type: authTypes.FORGOT_PASSWORD,
  payload,
  resolve,
  reject,
});
export const resetPassword = (payload, resolve, reject) => ({
  type: authTypes.RESET_PASSWORD,
  payload,
  resolve,
  reject,
});

export const putUser = (payload, resolve, reject) => ({
  type: authTypes.UPDATE_CURRENT_USER,
  payload,
  resolve,
  reject,
});
export const setCurrentUser = (payload, resolve, reject) => ({
  type: authTypes.SET_CURRENT_USER,
  payload,
  resolve,
  reject,
});
export const setUser = (payload, resolve, reject) => ({
  type: authTypes.SET_USER_ID,
  payload,
  resolve,
  reject,
});
export const setEmailNLToken = (payload, resolve, reject) => ({
  type: authTypes.SET_EMAIL_TOKEN,
  payload,
  resolve,
  reject,
});
export const fetchUsers = (id, resolve, reject) => ({
  type: authTypes.GET_CURRENT_USER,
  id,
  resolve,
  reject,
});

export const setNLToken = (code, userid, resolve, reject) => ({
  type: authTypes.SET_NL_TOKEN,
  code,
  userid,
  resolve,
  reject,
});

export const disconnectIntegration = (id, status, resolve, reject) => ({
  type: authTypes.DISCONNECT_EMAIL_INTEGRATION,
  id,
  status,
  resolve,
  reject,
});

export const uploadImage = (file, resolve, reject) => ({
  type: authTypes.UPLOAD_IMAGE,
  file,
  resolve,
  reject,
});
export const deleteProfileImage = (id, resolve, reject) => ({
  type: authTypes.DELETE_UPLOAD_IMAGE,
  id,
  resolve,
  reject,
});
export const setUploadImage = (payload, resolve, reject) => ({
  type: authTypes.SET_IMAGE_UPLOAD,
  payload,
  resolve,
  reject,
});
