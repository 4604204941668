import React from 'react';
import { Stack, Typography } from '@mui/material';
import images from 'src/config/images';
import { Spacer } from 'src/components/shared';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showWelcomeModal } from 'src/modules/auth/actions/authActions';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';

const LoginDashboardView = ({ userData, newUser = false }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <Stack position={'fixed'} top={'0'} bottom={'0'} left={'0'} right={'0'} zIndex={10000}>
      <Stack
        color={'white'}
        width={'100vw'}
        height={'100vh'}
        alignItems={'center'}
        justifyContent={'center'}
        bgcolor={'#021424'}
      >
        <Stack width={'100%'} maxWidth={'900px'}>
          <Stack justifyContent={'space-between'} direction={'row'}>
            <Stack gap={'16px'}>
              <Typography fontSize={'32px'} fontWeight={800} color="#F1E579">
                Welcome {!newUser && 'back'} to SuperReach, {userData?.fname} {userData?.lname}!
              </Typography>
              <Typography fontSize={'20px'} fontWeight={500}>
                What would you like to do today?
              </Typography>
            </Stack>
            <Stack>
              <img src={images.app.logoWhite} style={{ height: '30px' }} />
            </Stack>
          </Stack>
          <Spacer y={5} />
          <Stack direction={'row'} gap={'36px'}>
            <Stack width={'calc(50% - 18px)'}>
              <Stack
                onClick={() => {
                  history.push('/lms');
                  dispatch(showWelcomeModal(false));
                }}
                sx={{
                  background: '#193247',
                  borderRadius: '10px',
                  padding: '10px',
                  cursor: 'pointer',
                }}
              >
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography
                    sx={{
                      color: 'white !important',
                      fontSize: '22px',
                      fontWeight: 500,
                      padding: '6px 12px',
                    }}
                  >
                    LMS
                  </Typography>
                  <Stack alignItems="center" aspectRatio="1/1" borderRadius="6px" border={'2px solid white'}>
                    <KeyboardArrowRightRoundedIcon />
                  </Stack>
                </Stack>

                <Stack padding={'16px'} gap={'16px'}>
                  <Stack>
                    <img src={images.loginDashboard.LMS1} style={{ borderRadius: '10px' }} />
                  </Stack>
                  <Stack direction={'row'} gap={'16px'}>
                    <img
                      src={images.loginDashboard.LMS2}
                      style={{ width: 'calc(50% - 8px)', borderRadius: '10px' }}
                    />
                    <img
                      src={images.loginDashboard.LMS3}
                      style={{ width: 'calc(50% - 8px)', borderRadius: '10px' }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack width={'calc(50% - 18px)'}>
              <Stack
                onClick={() => {
                  history.push('/tasks');
                  dispatch(showWelcomeModal(false));
                }}
                sx={{
                  background: '#193247',
                  borderRadius: '10px',
                  padding: '10px',
                  cursor: 'pointer',
                }}
              >
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography
                    sx={{
                      color: 'white !important',
                      fontSize: '22px',
                      fontWeight: 500,
                      padding: '6px 12px',
                    }}
                  >
                    Outreach
                  </Typography>
                  <Stack alignItems="center" aspectRatio="1/1" borderRadius="6px" border={'2px solid white'}>
                    <KeyboardArrowRightRoundedIcon />
                  </Stack>
                </Stack>

                <Stack padding={'16px'} gap={'16px'}>
                  <Stack>
                    <img src={images.loginDashboard.Outreach1} style={{ borderRadius: '10px' }} />
                  </Stack>
                  <Stack direction={'row'} gap={'16px'}>
                    <img
                      src={images.loginDashboard.Outreach2}
                      style={{ width: 'calc(50% - 8px)', borderRadius: '10px' }}
                    />
                    <img
                      src={images.loginDashboard.Outreach3}
                      style={{ width: 'calc(50% - 8px)', borderRadius: '10px' }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LoginDashboardView;
