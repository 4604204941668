import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormHelperText, Box, Typography, useTheme } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { trim } from 'lodash';
import { fileUpload } from 'src/modules/app/api/appApis';

const apiKey = 'w4kzxwiw7n9w5n5rxt1gv2w3oq30nfwwhyi6r01zmm7rprri';

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  characterLimit: PropTypes.number,
  showFontStyle: PropTypes.bool,
  showPlaceholder: PropTypes.bool,
  showFontEditor: PropTypes.bool,
  showAlignProps: PropTypes.bool,
  showListProps: PropTypes.bool,
  toolbarLocation: PropTypes.string,
  textColor: PropTypes.string,
  competitiveLearning: PropTypes.bool,
  showAttachments: PropTypes.bool,
  getChildEditorRef: PropTypes.func,
  ref: PropTypes.any,
  isSetup: PropTypes.bool,
  removeSpans: PropTypes.bool,
  pasteAsText: PropTypes.bool,
  height: PropTypes.number,
};

const defaultProps = {
  className: 'custom_input',
  competitiveLearning: false,
  value: undefined,
  textColor: '',
  onChange: () => {},
  loading: false,
  disabled: false,
  characterLimit: 0,
  showFontStyle: true,
  showPlaceholder: true,
  showFontEditor: true,
  showAlignProps: true,
  showListProps: true,
  showAttachments: true,
  toolbarLocation: null,
  getChildEditorRef: () => {},
  ref: null,
  isSetup: true,
  removeSpans: true,
  pasteAsText: false,
  height: 300,
};

const TextEditor = ({
  className,
  onChange,
  value,
  handleBlur = () => {},
  error,
  helperText,
  characterLimit,
  getChildEditorRef,
  ref,
  isSetup,
  textColor,
  removeSpans,
  pasteAsText,
  height,
  competitiveLearning,
  disabled,
  ...props
}) => {
  let replacementVariables = useSelector((state) => state.app.globals.replacementVariables);
  let competitiveLearningVariables = useSelector(
    (state) => state.app.globals.competitionSettings?.replacementVariables,
  );
  const editorRef = React.useRef();

  const theme = useTheme();
  const isDark = theme.palette.type == 'dark';

  const contactVariables = useMemo(() => {
    if (replacementVariables && replacementVariables.length) {
      return replacementVariables.filter((item) => item.text.includes('Contact'));
    }
    return [];
  }, [replacementVariables]);
  const senderVariables = useMemo(() => {
    if (replacementVariables && replacementVariables.length) {
      return replacementVariables.filter((item) => item.text.includes('Sender'));
    }
    return [];
  }, [replacementVariables]);

  const otherVariables = useMemo(() => {
    if (replacementVariables && replacementVariables.length) {
      return replacementVariables.filter(
        (item) => !item.text.includes('Sender') && !item.text.includes('Contact'),
      );
    }
    return [];
  }, [replacementVariables]);

  const [characters, setCharacters] = useState(0);
  const { unsubscribe = false } = props;

  const handleEditorChange = (value) => {
    if (removeSpans === true) {
      value = value.replace(/<\/?span[^>]*>/g, '');
    }
    onChange(value);
  };

  useEffect(() => {
    const handler = (e) => {
      const { target } = e;
      if (target.closest('.tox-tinymce-aux, .moxman-window, .tam-assetmanager-root') !== null) {
        // e.stopPropagation();
        e.stopImmediatePropagation();
        if (target.closest('.tox-textfield') && target.closest('.tox-textfield').length) {
          target.closest('.tox-textfield').focus();
        } else if (target && target.classList.contains('tox-textfield') && target.type === 'url') {
          target.focus();
        }
      }
    };
    document.addEventListener('focusin', handler);
    return () => document.removeEventListener('focusin', handler);
  }, []);

  React.useEffect(() => {
    if (getChildEditorRef) {
      getChildEditorRef(editorRef);
    }
  }, [editorRef]);

  useEffect(() => {
    if (value && value !== '' && characterLimit > 0) {
      setCharacters(value.length);

      if (value.length > characterLimit) {
        const newValue = value.substring(0, characterLimit);
        handleEditorChange(newValue);
        // onChange(newValue);
      }
    }
  }, [characterLimit, value]);

  const tools = useMemo(() => {
    const toolStr = [];
    if (props.showFontStyle === true) toolStr.push('bold italic underline');
    if (props.showPlaceholder === true) toolStr.push('placeholder');
    if (props.showFontEditor === true) toolStr.push('fontselect fontsizeselect forecolor');
    if (props.showAlignProps === true)
      toolStr.push('alignleft aligncenter alignright alignjustify');
    if (props.showListProps === true) toolStr.push('bullist numlist outdent indent');
    if (props.showAttachments === true) toolStr.push('link image code');
    return toolStr.join(' | ');
  }, [
    props.showFontStyle,
    props.showPlaceholder,
    props.showFontEditor,
    props.showAlignProps,
    props.showListProps,
    props.showAttachments,
  ]);

  var characterLimitHtml = characterLimit > 0 ? characterLimit + 100 : 0;
  const allowed_keys = [8, 13, 16, 17, 18, 20, 33, 34, 35, 36, 37, 38, 39, 40, 46];

  if (props.showPlaceholder === true && (!replacementVariables || !replacementVariables.length))
    return null;

  const setup = function (editor) {
    let toggleState = false;

    editor.ui.registry.addNestedMenuItem('nesteditem', {
      text: 'My nested menu item',
      getSubmenuItems: () => replacementVariables,
    });

    editor.ui.registry.addMenuButton('placeholder', {
      text: 'Personalise',
      class: 'PersonalizeButtonEditor',
      //icon: 'info',
      // tooltip: 'It is a personalize content variable',
      onAction: function () {
        //editor.insertContent('<p>You clicked the main button</p>');
      },
      onItemAction: function (api, value) {
        editor.insertContent(value);
      },
      fetch: function (callback) {
        const items = [
          competitiveLearning === true
            ? {
                type: 'nestedmenuitem',
                text: 'Competitive Learning',
                getSubmenuItems: () =>
                  competitiveLearningVariables.map((item) => ({
                    ...item,
                    type: 'menuitem',
                    onAction: () => editor.insertContent(item.value),
                  })),
              }
            : '',
          {
            type: 'nestedmenuitem',
            text: 'Contact',
            getSubmenuItems: () =>
              contactVariables.map((item) => ({
                ...item,
                type: 'menuitem',
                onAction: () => editor.insertContent(item.value),
              })),
          },
          {
            type: 'nestedmenuitem',
            text: 'Sender',
            getSubmenuItems: () =>
              senderVariables.map((item) => ({
                ...item,
                type: 'menuitem',
                onAction: () => editor.insertContent(item.value),
              })),
          },
          ...otherVariables.map((item) => ({
            ...item,
            type: 'menuitem',
            onAction: () => editor.insertContent(item.value),
          })),
        ];
        callback(items);
      },
    });

    editor.ui.registry.addButton('unsubscribe', {
      text: '<svg version="1.0" width="22" height="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#757575" stroke="none"><path d="M620 4464 c-215 -46 -400 -232 -445 -449 -22 -105 -22 -2806 0 -2910 41 -196 199 -371 393 -437 l67 -23 1892 -3 c2076 -3 1965 -6 2095 57 141 67 260 203 308 351 l25 75 0 1435 0 1435 -23 73 c-61 193 -239 355 -437 397 -102 21 -3775 21 -3875 -1z m3822 -314 c63 -18 129 -70 162 -130 28 -49 31 -65 34-155 l3 -100 -1040 -667 -1040 -668 -1041 668 -1041 667 3 100 c3 90 6 106 34 155 32 58 99 112 158 129 45 13 3721 14 3768 1z m-2944 -1417 l1007 -648 55 0 56 0 1007 648 c554 356 1009 647 1012 647 3 0 4 -501 3 -1112 l-3 -1113 -22-41 c-28 -53 -74 -99 -127 -127 l-41 -22 -1864 -3 c-1248 -1 -1876 1 -1901 8-64 17 -130 70 -164 130 l-31 55 -3 1113 c-1 611 0 1112 3 1112 4 0 459 -291 1013 -647z"/></g></svg>',
      tooltip: 'Unsubscribe',
      onAction: function () {
        const selectedContent = editor.selection.getContent();
        editor.insertContent(
          `<a style="text-decoration: unset;" href='##unsubscribe##' target='_blank'>${
            selectedContent || 'unsubscribe'
          }</a>`,
        );
      },
    });

    editor.on('KeyDown', function (ed, evt) {
      if (characterLimit && characterLimit > 0) {
        try {
          const chars_without_html = trim(editor.getContent().replace(/(<([^>]+)>)/gi, '')).length;
          const chars_with_html = editor.getContent().length;
          let key = ed.keyCode;
          setCharacters(chars_without_html);
          if (allowed_keys.indexOf(key) != -1) {
            return;
          }
          if (chars_with_html > characterLimit + characterLimitHtml) {
            ed.stopPropagation();
            ed.preventDefault();
          } else if (chars_without_html > characterLimit - 1 && key != 8 && key != 46) {
            ed.stopPropagation();
            ed.preventDefault();
            let trimmedContent = editor.getContent();
            trimmedContent = editor.getContent().substring(0, characterLimit);
            setCharacters(trimmedContent.length);
            editor.setContent(trimmedContent);
          }
        } catch (error) {}
      }
    });
  };

  return (
    <Box sx={{ '& .tox-tinymce': { borderRadius: '4px' } }}>
      <Box display="flex" justifyContent="space-between" p={'0 8px 8px'}>
        <Typography color="textSecondary" variant="body2">
          {props?.label}
        </Typography>
        <Box>
          {characterLimit > 0 ? (
            <Typography>
              {characters}/{characterLimit}
            </Typography>
          ) : null}
        </Box>
      </Box>
      <Editor
        ref={editorRef}
        apiKey={apiKey}
        className={className}
        value={value}
        onBlur={handleBlur}
        onInit={(evt, editor) => (editorRef.current = editor)}
        disabled={disabled}
        init={{
          height: height,
          forced_root_block: false,
          ...(isDark && {
            skin: 'oxide-dark',
            content_css: 'dark',
          }),
          // valid_styles: { '*': 'font-weight,font-style,text-decoration' },
          // valid_elements: 'a[href|title|target],del,b,strong,del,i,blockquote,p,br,em,ul,li,ol',
          formats: {
            forecolor: {
              inline: 'div',
              classes: 'forecolor',
              styles: { display: 'inline', color: '%value' },
            },
            underline: {
              inline: 'u',
            },
            fontname: {
              block: 'p',
              styles: { fontFamily: '%value' },
              remove: 'all',
            },
            fontsize: {
              block: 'p',
              styles: { fontSize: '%value' },
              remove: 'all',
            },
          },
          // menubar: false,
          statusbar: false,
          placeholder: props.placeHolder || '',
          menubar: false,
          content_style: `
            @import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
            body { 
              font-family: Roboto;
               ${textColor ? 'color:' + textColor : ''};
              background-color: ${isDark ? '#021424' : '#ffffff'};
            }
            .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
              font-weight: 400;
              font-size: 16px;
              line-height:24px;
              letter-spacing: 0.15px;
            }
          `,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code',
          ],
          paste_as_text: pasteAsText,
          selector: 'textarea',
          toolbar: tools || '',
          toolbar_location: props?.toolbarLocation ? props?.toolbarLocation : 'top',
          images_upload_handler: async (blobInfo, success, failure, progress) => {
            return new Promise(async (resolve, reject) => {
              try {
                const upload = await fileUpload('', blobInfo.blob(), (event) => {
                  progress(Math.round((100 * event.loaded) / event.total));
                });
                const url = upload.upload.url;
                resolve(url);
                success(url);
              } catch (e) {
                reject(e);
                failure(e);
              }
            });
          },
          setup: isSetup ? setup : () => {},
        }}
        onEditorChange={handleEditorChange}
      />
      {error === true ? <FormHelperText error={true}>{helperText}</FormHelperText> : null}
    </Box>
  );
};

TextEditor.propTypes = propTypes;
TextEditor.defaultProps = defaultProps;

export default TextEditor;
