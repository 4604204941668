export const PendingCircle = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 5L2.7 5.98163C3.04898 5.37551 3.47755 4.82041 3.97959 4.31837C4.71837 3.57959 5.57755 3 6.53469 2.59592C7.52449 2.17755 8.57551 1.96531 9.66122 1.96531C10.7469 1.96531 11.8 2.17755 12.7878 2.59592C13.7449 3 14.6041 3.57959 15.3429 4.31837C16.0816 5.05714 16.6612 5.91633 17.0653 6.87347C17.4857 7.86123 17.698 8.91429 17.698 10C17.698 11.0857 17.4857 12.1388 17.0673 13.1265C16.6633 14.0837 16.0837 14.9429 15.3449 15.6816C14.6061 16.4204 13.7469 17 12.7898 17.4041C11.8 17.8224 10.749 18.0347 9.66327 18.0347C8.96327 18.0347 8.27143 17.9449 7.60204 17.7694C6.9551 17.598 6.32857 17.3449 5.74286 17.0163C4.58776 16.3694 3.59796 15.4367 2.88571 14.3184C2.82245 14.2184 2.76122 14.1184 2.70204 14.0163L1 15C1.07347 15.1286 1.15102 15.2531 1.22857 15.3776C3.00612 18.1571 6.11837 20 9.66122 20C15.1837 20 19.6612 15.5224 19.6612 10C19.6612 4.47755 15.1837 0 9.66122 0C5.96122 0 2.72857 2.0102 1 5Z"
        fill="#2E7D32"
      />
    </svg>
  );
};
