import React from 'react';
import Session from 'src/components/lms/Session';
import Slider from 'src/components/lms/Slider';
import { LMSCard } from 'src/components/lms';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import useMediaQuery from 'src/components/hooks/useMediaQuery';
import { useLmsHomeProvider } from '../../utils/lmsStore';

function RecommendedSessions({ title }) {
  const { isMobile } = useMediaQuery();
  const { homePage } = useLmsHomeProvider();

  const sessions = homePage.component.length
    ? homePage.component.filter((item) => item.name === 'recommendedSessions')[0].params
    : [];

  const sliderProps = {
    slidesToShow: 4,
    ...(isMobile && {
      centerMode: true,
      centerPadding: '30px',
      arrows: true,
      dots: true,
      fade: true,
    }),
    padding: 16,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (!sessions.length) return null;

  return (
    <LMSCard icon={<ThumbUpAltOutlinedIcon sx={{ width: 24, height: 24 }} />} title={title}>
      <Slider {...sliderProps}>
        {sessions.map((session, index) => (
          <Session session={session} key={`recommended-session-${index}`} />
        ))}
      </Slider>
    </LMSCard>
  );
}

export default RecommendedSessions;
