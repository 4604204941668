import React, { useEffect, useState, useMemo } from 'react';
import * as Yup from 'yup';
import { Box, Typography, Alert, Grid } from '@mui/material';
import { Modal, Drawer, Button, Spacer, Form } from 'src/components/shared';
import {
  fetchCountryLookup,
  fetchTeamLookup,
  fetchTimezoneLookup,
  fetchUsersLookup,
} from 'src/modules/users/api/usersApi';

import moment from 'moment';

function UserActionDrawer({
  action,
  onClose,
  officeBulkUpdate,
  selectedOffices,
  globals,
  ...props
}) {
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);

  const teamsStatus = useMemo(() => {
    return globals?.teamStatusList || [];
  });

  useEffect(() => {
    switch (action) {
      case 'changeOfficeStatus':
        setTitle(`Change status`);
        break;

      case 'changedCountry':
        setTitle(`Change country`);
        break;

      case 'changedTimezone':
        setTitle(`Change timezone`);
        break;

      default:
        break;
    }
  }, [action]);

  if (!action || action == '') return null;

  const handleSubmit = async (payload) => {
    try {
      setLoading(true);
      await officeBulkUpdate(selectedOffices, payload);
      setLoading(false);
      onClose();
    } catch (error) {
      setLoading(false);
    }
  };

  const markAllCompleted = () => {
    const data = {
      status: 'completed',
    };
    handleSubmit(data);
  };

  const getContent = () => {
    switch (action) {
      case 'changeOfficeStatus':
        return (
          <Form
            initialValues={{
              status: 'active',
            }}
            validationSchema={Yup.object().shape({
              status: Yup.string().required('Please choose a status.'),
            })}
            onSubmit={(values, form) => {
              handleSubmit({ status: values.status.toLowerCase() });
            }}
          >
            {(props) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.submitForm();
                    return false;
                  }}
                  noValidate
                  style={{ height: '100%' }}
                >
                  <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Form.Field.Select
                      fullWidth
                      options={teamsStatus}
                      variant="outlined"
                      name="status"
                      label="Status"
                      optLabel="label"
                      optValue="value"
                      tip="Change status for all selected offices."
                      showNone={false}
                    />

                    <Box display={'flex'} spacing={2} mt={2} justifyContent="flex-end">
                      <Button variant="contained" color="secondary" type="submit" loading={loading}>
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </form>
              );
            }}
          </Form>
        );
        break;

      case 'changedCountry':
        return (
          <Form
            initialValues={{
              country: {},
            }}
            validationSchema={Yup.object().shape({
              country: Yup.object().test('country', 'Please select a country.', function (item) {
                return item?.id && item?.id !== '';
              }),
            })}
            onSubmit={(values, form) => {
              const data = JSON.parse(JSON.stringify(values));
              data.country = values.country.country;
              handleSubmit(data);
            }}
          >
            {(props) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.submitForm();
                    return false;
                  }}
                  noValidate
                  style={{ height: '100%' }}
                >
                  <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Form.Field.AutoComplete
                      fullWidth
                      options={[]}
                      variant="outlined"
                      remoteMethod={(search) => {
                        return fetchCountryLookup(search);
                      }}
                      name="country"
                      label="Country"
                      optLabel="name"
                      optValue="id"
                      tip="Change country for all selected offices."
                    />

                    <Box display={'flex'} spacing={2} mt={2} justifyContent="flex-end">
                      <Button variant="contained" color="secondary" type="submit" loading={loading}>
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </form>
              );
            }}
          </Form>
        );
        break;

      case 'changedTimezone':
        return (
          <Form
            initialValues={{
              timezone: '',
            }}
            validationSchema={Yup.object().shape({
              timezone: Yup.object().test('timezone', 'Please select a timezone.', function (item) {
                return item?.id && item?.id !== '';
              }),
            })}
            onSubmit={(values, form) => {
              const data = JSON.parse(JSON.stringify(values));
              data.timezone = data.timezone.timeZone;
              handleSubmit(data);
            }}
          >
            {(props) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.submitForm();
                    return false;
                  }}
                  noValidate
                  style={{ height: '100%' }}
                >
                  <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Form.Field.AutoComplete
                      fullWidth
                      options={[]}
                      variant="outlined"
                      remoteMethod={(search) => {
                        return fetchTimezoneLookup(search);
                      }}
                      name="timezone"
                      label="Timezone"
                      optLabel="name"
                      optValue="id"
                      tip="Change timezone for all selected offices."
                    />

                    <Box display={'flex'} spacing={2} mt={2} justifyContent="flex-end">
                      <Button variant="contained" color="secondary" type="submit" loading={loading}>
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </form>
              );
            }}
          </Form>
        );
        break;

      default:
        break;
    }
  };

  return (
    <Modal
      title={title}
      subtitle={`${selectedOffices.length} ${
        selectedOffices.length > 1 ? 'offices' : 'office'
      } selected.`}
      open={action && typeof action !== 'undefined'}
      onClose={onClose}
      size="xs"
    >
      {getContent()}
    </Modal>
  );
}

export default UserActionDrawer;
