import React, { useState } from 'react';
import { Typography, Box } from '@mui/material';

import { withCustomVariablesStore, useCustomVariables } from '../../utils/customVariableStore';

import CustomVariablesGrid from './CustomVariablesGrid';
import CustomVariableForm from './CustomVariableForm';

function CustomVariables() {
  const [showModal, setShowModal] = useState(false);
  const [editVariable, setEditVariable] = useState(undefined);

  const handleClose = () => {
    setEditVariable(undefined);
    setShowModal(false);
  };

  const showAddForm = (variable) => {
    setShowModal(true);
    setEditVariable(variable);
  };

  return (
    <>
      <Typography color="textSecondary">
        Add your own custom variables to personalise your outreach faster. Shared values cannot be
        edited.
      </Typography>
      <CustomVariablesGrid showAddForm={showAddForm} handleClose={handleClose} />
      <CustomVariableForm variable={editVariable} open={showModal} handleClose={handleClose} />
    </>
  );
}

export default withCustomVariablesStore(CustomVariables);
