import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import styled from 'styled-components';
const bg = require(`src/assets/lms/CourseBG.svg`).default;
import darkTheme from 'src/config/darkTheme';

import { ReactComponent as Assigned } from 'src/assets/lms/icons/assigned.svg';

export const AssignedIcon = () => (
  <Assigned style={{ color: darkTheme.palette.secondary.main, width: 20, height: 20 }} />
);

export const Wrapper = styled(ButtonBase)`
  aspect-ratio: 0.82/1;
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
`;

export const TopPill = styled.div`
  width: 80%;
  height: 12px;
  border-radius: 8px 8px 0px 0px;
  background-color: #222222;
  margin: 0 auto;
`;

export const Background = styled.div`
  margin-top: 4px;
  border-radius: 8px;
  background-color: #222222;
  height: 100%;
  /* background-image: url(${bg}); */
  background-image: ${(props) => props?.image || ''}
  background-size: contain;
  background-repeat: no-repeat;

  /* height: calc(100% - 16px); */
`;

export const Content = styled.div`
  position: relative;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 16px;
  z-index: 99;
`;

export const Gradient = styled.div`
  width: 100%;
  height: 120px;
  position: absolute;
  bottom: 0;
  background: var(--Dark-Gradient, linear-gradient(180deg, rgba(9, 13, 17, 0) 0%, #090d11 100%));
  z-index: 0;
  border-radius: 0px 0px 8px 8px;
`;

export const Title = styled(Typography)`
  color: #817e7e;
  text-align: center;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 800 !important;
`;

export const Type = styled(Typography)`
  color: ${darkTheme.palette.secondary.main};
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
`;
