import React, { useMemo } from 'react';
import { Box, Typography, Paper, Tooltip } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Avatar, Spacer } from 'src/components/shared';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ReplyIcon from '@mui/icons-material/Reply';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { styled } from '@mui/system';
import { dateTimeFormat } from 'src/config';
import Moment from 'react-moment';
import moment from 'moment';
import { Button } from 'src/components/shared';
import { SendEmailForm } from 'src/modules/contacts/components/ContactActions';

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme, action }) => ({
  '&:before': {
    display: 'none',
  },
}));

export const AccordionSummary = styled(({ action, onClose, icon, ...props }) => (
  <MuiAccordionSummary
    expandIcon={
      <Box className="arrowIconContainer">
        <ArrowForwardIosSharpIcon />
      </Box>
    }
    {...props}
  />
))(({ theme, action }) => ({
  '&.MuiAccordionSummary-root': {
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&.Mui-expanded': {
      borderBottom: `none`,
    },
  },
  '& .MuiAccordionSummary-expandIconWrapper, & .MuiAccordionSummary-expandIconWrapper .MuiButtonBase-root':
    {
      transform: 'rotate(0deg)',
      color: 'rgba(0, 0, 0, 0.6);',
    },
  '& .MuiAccordionSummary-expandIconWrapper, & .MuiAccordionSummary-expandIconWrapper .MuiButtonBase-root .customIconContainer':
    {
      transform: 'rotate(0deg)',
    },
}));

const ReplyItem = ({
  data,
  expanded,
  setExpanded,
  openReplyForm,
  sendContactEmail = () => {},
  putContactEmail = () => {},
  putTaskReply = () => {},
  ...props
}) => {
  const {
    id,
    sender,
    user,
    contact,
    content,
    subject,
    message,
    status,
    task,
    template,
    type,
    from,
  } = data;

  const isExpanded = useMemo(() => {
    return Boolean(expanded === id);
  }, [id, expanded]);

  let senderName;
  const isRecieve = () => status === 'received' && type === 'reply';

  senderName = isRecieve()
    ? [contact.fname, contact.lname].join(' ')
    : [sender?.fname, sender?.lname].join(' ');

  return (
    <Accordion
      expanded={isExpanded || isPending(status)}
      onChange={() => {
        setExpanded(isExpanded ? '' : id);
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel4bh-content"
        id="panel4bh-header"
      >
        {!isExpanded && !isPending(status) ? (
          <>
            <Box
              display={'flex'}
              flexDirection="row"
              justifyContent={'space-between'}
              width={'100%'}
            >
              <Box display="flex" gap={'10px'}>
                <Typography>{senderName}</Typography>
                <Box display={'flex'} flexDirection={'column'}>
                  <Typography color="textSecondary" component="span">
                    {`<${!isRecieve() ? sender?.email : from}>`}
                  </Typography>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                    }}
                  >
                    {subject}
                  </Typography>
                </Box>
              </Box>

              <Typography sx={{ color: 'text.secondary' }} variant="body2">
                {moment(data.createdAt).fromNow()}
              </Typography>
            </Box>
          </>
        ) : (
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box display="flex" flex={1} alignItems="flex-start">
              <Box>
                <Spacer x={1} y={1} />
                <Avatar name={senderName} size={40} />
              </Box>
              <Spacer x={2} y={2} />
              <Box display="flex" flexDirection="column">
                <Box display="flex">
                  <Typography
                    color="primary"
                    component="span"
                    style={{ color: isExpanded ? '#1976D2' : 'rgba(0, 0, 0, 0.6)' }}
                  >
                    {senderName}
                  </Typography>
                  <Spacer x={1} y={1} />
                  <Typography color="textSecondary" component="span">
                    {`<${!isRecieve() ? sender?.email : from}>`}
                  </Typography>
                </Box>
                {message && message.to && message.to.length ? (
                  <>
                    {message.to.map((toUser) => {
                      return (
                        <>
                          <Tooltip title={toUser.email}>
                            <Typography>{toUser.name}</Typography>
                          </Tooltip>
                        </>
                      );
                    })}
                  </>
                ) : null}

                <Typography color="textSecondary" component="span">
                  <Moment format={dateTimeFormat}>{data.createdAt}</Moment>
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </AccordionSummary>

      <>
        <MuiAccordionDetails>
          {message && message?.body ? (
            <div
              style={{
                color: 'rgba(0, 0, 0, 0.6)',
                padding: '1%',
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: message.body }} />
            </div>
          ) : isPending(status) ? (
            <SendEmailForm
              contact={contact}
              sendContactEmail={sendContactEmail}
              putContactEmail={putContactEmail}
              putTaskReply={putTaskReply}
              type={type}
              id={id}
              task={props.task}
              templateData={{
                ...template,
                taskId: task.id,
                subject: subject,
                content: content,
                contactId: contact.id,
                currentUserId: user?.id,
                currentUserEmail: user?.email,
                sender: sender,
              }}
              formProps={{
                submitButtonText: 'Update',
                showCancelButton: false,
                dontShowEmailField: true,
              }}
            />
          ) : (
            <div style={{ color: 'rgba(0, 0, 0, 0.6)', padding: '1%' }}>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          )}
          {!isPending(status) ? (
            <Box display="flex" mt={5} alignItems="center" justifyContent="flex-end" flex={1}>
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                startIcon={<ReplyIcon />}
                onClick={() => {
                  openReplyForm('reply', data);
                }}
              >
                Reply
              </Button>
              <Spacer basis={1} />
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                startIcon={<ReplyAllIcon />}
                onClick={() => {
                  openReplyForm('replyAll', data);
                }}
              >
                Reply All
              </Button>
              <Spacer basis={1} />
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                startIcon={<ReplyIcon sx={{ transform: 'scaleX(-1)' }} />}
                onClick={() => {
                  openReplyForm('forward', data);
                }}
              >
                Forward
              </Button>
            </Box>
          ) : null}
        </MuiAccordionDetails>
      </>
    </Accordion>
  );
};
const isPending = (status) => status === 'pending' || status === 'failed';

export default ReplyItem;
