import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Stack } from '@mui/material';
import { PageTitle, Tabs } from 'src/components/App';
import { Spacer } from 'src/components/shared';
import Summary from './Summary';
import Management from './Management';
import Waterfall from './Waterfall';
import { dataManagementTabs } from '../../config';
import { withDataProviders } from '../../utils/dataProviderStore';

export const DataCreditContainer = (props) => {
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);

  const [tab, setTab] = useState('');

  useEffect(() => {
    if (user && !user.isAdmin) {
      history.replace('/boards');
    }
  }, [user]);

  useEffect(() => {
    const { tab } = props.match.params;
    if (tab && typeof tab !== 'undefined') {
      setTab(tab);
    } else {
      setTab('summary');
      history.replace('data/summary');
    }
  }, [props.match.params]);

  const onTabChange = (tab) => {
    history.push(`/admin/data/${tab.id}`);
  };

  return (
    <Container maxWidth="xl" disableGutters>
      <Stack padding="12px 18px">
        <PageTitle title="Data Credit Management" />

        <Tabs
          tabs={dataManagementTabs}
          onChange={onTabChange}
          value={tab}
          tabLabel="name"
          tabValue="id"
          sx={{
            '&.MuiTabs-root': {
              minHeight: '40px',

              '& .MuiButtonBase-root': {
                fontWeight: 500,
                fontSize: '16px',
                padding: '0 12px',
                margin: '0 4px',
              },
            },
          }}
          // showNewTabButton={tab !== 'management'}
          // startIcon={null}
          // endIcon={<OpenInNewIcon />}
          // buttonLabel="Buy Credits"
          // handleNewTab={() => {}}
          // justifyContent="space-between"
        />

        <Spacer y={2} />

        {tab === 'summary' && <Summary {...props} />}
        {tab === 'management' && <Management {...props} />}
        {tab === 'waterfall' && <Waterfall {...props} />}
      </Stack>
    </Container>
  );
};

export default withDataProviders(DataCreditContainer);
