export const dashboardTabs = [
  {
    id: 'dashboards',
    name: 'Dashboards',
  },
  {
    id: 'myDashboards',
    name: 'My Dashboards',
  },
];

export const dashboardTabs1 = [
  {
    id: 'sequences',
    name: 'Sequences',
  },
  // {
  //   id: 'emails',
  //   name: 'Emails',
  // },
  // {
  //   id: 'tasks',
  //   name: 'Tasks',
  // },
  // {
  //   id: 'liveActivity',
  //   name: 'Live Activity',
  // },
];

export const boardTypes = [
  {
    value: 'widgets',
    label: 'Widget',
  },
  {
    value: 'swimlanes',
    label: 'Swimlane',
  },
];

export const filterTabTypes = [
  {
    id: 'simpleFilter',
    name: 'Filter',
  },
  {
    id: 'advancedFilter',
    name: 'Advanced Filter',
  },
];

export const templateCategories = [
  {
    value: 'swimLanes',
    label: 'Swim Lanes',
  },
  {
    value: 'activity',
    label: 'Activity',
  },
  {
    value: 'outreachPerformance',
    label: 'Outreach Performance',
  },
];

export const boardCategories = [
  {
    value: 'sequences',
    label: 'Sequences',
  },
  {
    value: 'activity',
    label: 'Activity',
  },
  {
    value: 'task',
    label: 'Task',
  },
  {
    value: 'contact',
    label: 'Contact',
  },
  {
    value: 'call',
    label: 'Call',
  },
  {
    value: 'linkedInAutomation',
    label: 'Linkedin Automation',
  },
  {
    value: 'salesOutreach',
    label: 'Sales Outreach',
  },
  {
    value: 'workflow',
    label: 'Workflow',
  },
  {
    value: 'apiIntegration',
    label: 'API Integration',
  },
];

export const conditionTypes = [
  {
    title: 'And',
    key: 'and',
  },
  {
    title: 'Or',
    key: 'or',
  },
];

export const conditions = [
  {
    title: 'Equals to',
    key: 'eq',
  },
  {
    title: 'Greater then',
    key: 'gte',
  },
  {
    title: 'Less then',
    key: 'lte',
  },
  {
    title: 'Greater then or equals to',
    key: 'gteq',
  },
  {
    title: 'Less then or equals to',
    key: 'lteq',
  },
];

export const filterTypes = {
  fname: {
    title: 'First Name',
    key: 'fname',
    type: 'text',
    allowedOptions: ['eq'],
  },
  lname: {
    title: 'Last Name',
    key: 'lname',
    type: 'text',
  },
  'phones.phone': {
    title: 'Phone',
    key: 'phones.phone',
    type: 'text',
  },
  'emails.email': {
    title: 'Phone',
    key: 'emails.email',
    type: 'text',
  },
  jobTitle: {
    title: 'Job Title',
    key: 'jobTitle',
    type: 'text',
  },
  'workHistory.employer': {
    title: 'Current Employer',
    key: 'workHistory.employer',
    type: 'text',
  },
  createdAt: {
    title: 'Created date',
    key: 'createdAt',
    type: 'date',
  },
  '_open.createdAt_gte': {
    title: 'Open',
    key: '_open.createdAt_gte',
    type: 'date',
  },
  '_reply.createdAt_gte': {
    title: 'Reply',
    key: '_reply.createdAt_gte',
    type: 'date',
  },
  '_click.createdAt_gte': {
    title: 'Click',
    key: '_click.createdAt_gte',
    type: 'date',
  },
};

export const boardAccessTypes = [
  {
    label: 'Public',
    value: 'public',
  },
  {
    label: 'Private',
    value: 'private',
  },
];

export const activityReportsTabs = [
  {
    id: 'summary',
    name: 'Summary',
  },
  {
    id: 'detailed',
    name: 'Detailed',
  },
];

export const notificationTypes = [
  // { label: 'Contact Added', value: 'CONTACT_ADDED', id: 'CONTACT_ADDED' },
  // { label: 'Contact Updated', value: 'CONTACT_UPDATED', id: 'CONTACT_UPDATED' },
  // { label: 'Contact Enrolled', value: 'Enrolled_In_Sequence', id: 'Enrolled_In_Sequence' },
  // { label: 'In progress', value: 'Seq_Inprogress', id: 'Seq_Inprogress' },
  // { label: 'Pause', value: 'Seq_Hold', id: 'Seq_Hold' },
  // { label: 'No interested', value: 'Seq_Notinterested', id: 'Seq_Notinterested' },
  // { label: 'Interested', value: 'Seq_Interested', id: 'Seq_Interested' },
  // { label: 'Email opened', value: 'Email_Open', id: 'Email_Open' },
  // { label: 'Email clicked', value: 'Email_Click', id: 'Email_Click' },
  // { label: 'Email sent', value: 'Email_Sent', id: 'Email_Sent' },
  // { label: 'New email', value: 'newEmail', id: 'newEmail' },
  // { label: 'Sequence completed', value: 'Seq_Completed', id: 'Seq_Completed' },
  // { label: 'Error', value: 'error', id: 'error' },
  { label: 'Email reply', value: 'Email_Reply', id: 'Email_Reply' },
  { label: 'Email bounced', value: 'Email_Bounce', id: 'Email_Bounce' },
  { label: 'Out-of-office reply', value: 'Seq_OutOfOffice', id: 'Seq_OutOfOffice' },
  {
    label: 'Sequence enrolled error',
    value: 'Seq_Error',
    id: 'Seq_Error',
  },
  {
    label: 'Sequence enrolled success',
    value: 'Seq_Completed',
    id: 'Seq_Completed',
  },
  // { label: 'Auto-add success', value: 'Auto_Add_Success', id: 'Auto_Add_Success' },
  // { label: 'Auto-add error', value: 'Auto_Add_Error', id: 'Auto_Add_Error' },
];
