import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Grid, Box } from '@mui/material';
import { Button, Form } from 'src/components/shared';
import { fetchTimezoneLookup, fetchCountryLookup } from '../../api/adminApi';

function OfficeForm({ drawerToggle, editUser, formData, total, loading, ...props }) {
  const [defaultCandidate, setDefaultCandidate] = useState({});

  useEffect(() => {
    if (editUser && editUser?.id) {
      setDefaultCandidate(editUser);
    }
  }, [editUser]);

  const handleSubmit = async (values, form) => {
    const data = { ...values };
    data.status = data.status.value || 'active';
    data.timezone = data.timezone.value || '';
    data.country = data.country.value || '';
    if (editUser && editUser?.id) {
      await props.putOffice(editUser?.id, data);
      drawerToggle({ edit: null, open: false });
    } else {
      await props.saveOffice(data);
      drawerToggle({ edit: null, open: false });
    }
  };

  return (
    <Form
      initialValues={{
        name: defaultCandidate.name || '',
        address1: defaultCandidate.address1 || '',
        address2: defaultCandidate.address2 || '',
        city: defaultCandidate.city || '',
        town: defaultCandidate.town || '',
        postcode: defaultCandidate.postcode || '',
        main_office_phone: defaultCandidate.main_office_phone || '',
        timezone: defaultCandidate?.id
          ? { id: defaultCandidate?.id, value: defaultCandidate?.timezone }
          : {},
        country: defaultCandidate?.id
          ? { id: defaultCandidate?.id, value: defaultCandidate?.country }
          : {},
        status: { id: defaultCandidate?.id, value: defaultCandidate?.status } || '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Please enter office name.'),
        city: Yup.string()
          .required('Please enter city.')
          .matches(/^[a-z A-Z]+$/, 'Only text are allowed for this field.'),
        postcode: Yup.string().required('Please enter Post code.'),
        country: Yup.object().test('country', 'Please choose a country.', function (item) {
          return item?.id && item?.id !== '';
        }),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={false}
      autocomplete="off"
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <Box
              display="flex"
              flex={2}
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-end"
              height="100%"
            >
              <Box width="100%">
                <Form.Field.Input
                  fullWidth
                  rows={6}
                  variant="outlined"
                  name="name"
                  label="Office name"
                />

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="address1"
                      label="Address 1"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="address2"
                      label="Address 2"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="city"
                      label="City"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="town"
                      label="Region "
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="postcode"
                      label="Post Code/Zip/PIN"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Form.Field.AutoComplete
                      options={[]}
                      fullWidth
                      clearOnEscape={true}
                      name="country"
                      placeholder="Country"
                      label="Country"
                      variant="outlined"
                      remoteMethod={(val) => {
                        return fetchCountryLookup(val);
                      }}
                      optValue="id"
                      optLabel="value"
                      multiple={false}
                      inputProps={{
                        autoComplete: 'none',
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Form.Field.Phone
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="main_office_phone"
                      label="Office Number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Form.Field.AutoComplete
                      options={[]}
                      fullWidth
                      clearOnEscape={true}
                      name="timezone"
                      placeholder="Timezone"
                      label="Timezone"
                      variant="outlined"
                      remoteMethod={(val) => {
                        return fetchTimezoneLookup(val);
                      }}
                      optValue="id"
                      optLabel="value"
                      multiple={false}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  display: 'grid',
                  gap: 2,
                  gridTemplateColumns: 'repeat(2, 1fr)',
                }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => drawerToggle({ edit: null, open: false })}
                  loading={loading.offices}
                  disabled={loading.offices}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={loading.offices}
                  disabled={loading.offices}
                >
                  {!defaultCandidate.id ? 'Save' : 'Update'}
                </Button>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}

export default OfficeForm;
