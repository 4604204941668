import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import WatchHistory from '../components/WatchHistory';
import { fetchHistory, deleteHistory } from '../actions/LMSActions';

const LMSWatchHistoryContainer = (props) => {
  return (
    <>
      <WatchHistory {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user.id,
  history: state.lms.history,
  loading: state.lms.loading.history,
});

const mapDispatchToProps = (dispatch) => ({
  fetchHistory: (paging, filters) => dispatch(fetchHistory(paging, filters)),
  deleteHistory: (id) => {
    return new Promise((resolve, reject) => {
      dispatch(deleteHistory(id, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LMSWatchHistoryContainer);
