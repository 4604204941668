import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Chip, Typography, CardContent, CircularProgress } from '@mui/material';
import { InfoIconWrapper, InfoIcon, Content, Title, Value, colors } from './styles';
import { fetchWidgetData } from '../../../actions/boardActions';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { capitalize } from 'lodash';
import { getTaskTypeText } from 'src/modules/tasks/utils/tasksUtils';

function LineChartReport({ data }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWidgetData(data.i));
  }, []);

  const { title, description, key, loading } = data;
  const widgetData = data.data;

  const columns = useMemo(() => {
    if (!widgetData.length) {
      return [];
    }
    return Object.keys(widgetData[0]).filter((item) => item !== 'name');
  }, [widgetData]);

  return (
    <CardContent sx={{ height: 'calc(100% - 32px)' }}>
      <Typography variant="h3">{title}</Typography>

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <Box display="flex" width="100%" height="100%" mt={2}>
          <ResponsiveContainer width="100%" height="94%">
            <LineChart
              width={500}
              height={300}
              data={
                title?.toLowerCase() === 'tasks'
                  ? widgetData?.map((object) => {
                      return Object.keys(object)?.reduce((acc, key) => {
                        acc[key === 'name' ? 'name' : capitalize(key)] =
                          key === 'name' ? getTaskTypeText(object[key]) : object[key];
                        return acc;
                      }, {});
                    })
                  : widgetData
              }
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />

              {columns.length ? (
                <>
                  {columns.map((item, index) => {
                    return (
                      <Line
                        key={`${data.i}-widget-${index}`}
                        type="monotone"
                        dataKey={title?.toLowerCase() === 'tasks' ? capitalize(item) : item}
                        stroke={colors[index]}
                      />
                    );
                  })}
                </>
              ) : null}
            </LineChart>
          </ResponsiveContainer>
        </Box>
      )}
    </CardContent>
  );
}

export default LineChartReport;
