import Users from '../containers/users';
import Teams from '../containers/teams';
import Offices from '../containers/offices';
import Notes from 'src/modules/notes/container/Notes';
import Competitions from 'src/modules/compitions/container/Competitions';
import Templates from '../containers/templates';
import Integrations from '../containers/integrations';
import Integration from '../containers/integration';
import CustomFields from '../containers/customFields';
import Lookups from '../containers/lookups';
import DataCredit from '../containers/DataCredit';

import Settings from '../containers/settings';
import BillingPortal from '../containers/billingPortal';
import BillingContainer from '../containers/billings';
import TenantIntegrationUI from './../containers/integration/index';
import CourseListingContainer from '../containers/course/CourseListingContainer';
import CreateCourseContainer from '../containers/course/CreateCourseContainer';
import RoleplayContainer from '../containers/roleplay';
import RoleplayMinutesView from '../containers/roleplay-minutes';

export default [
  {
    title: 'User',
    component: DataCredit,
    url: '/admin/data',
    exact: true,
    auth: true,
  },
  {
    title: 'User',
    component: DataCredit,
    url: '/admin/data/:tab',
    exact: true,
    auth: true,
  },
  {
    title: 'User',
    component: Users,
    url: '/admin/users',
    exact: true,
    auth: true,
  },
  {
    title: 'User Details',
    component: Users,
    url: '/admin/users/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'Team',
    component: Teams,
    url: '/admin/teams',
    exact: true,
    auth: true,
  },
  {
    title: 'Notes',
    component: Notes,
    url: '/admin/notes',
    exact: true,
    auth: true,
  },
  // {
  //   title: 'Competitions',
  //   component: Competitions,
  //   url: '/admin/competitions',
  //   exact: true,
  //   auth: true,
  // },
  {
    title: 'Templates',
    component: Templates,
    url: '/templates',
    exact: true,
    auth: true,
  },
  {
    title: 'Templates',
    component: Templates,
    url: '/templates/email',
    exact: true,
    auth: true,
  },
  {
    title: 'Templates',
    component: Templates,
    url: '/templates/linkedin',
    exact: true,
    auth: true,
  },
  {
    title: 'Office',
    component: Offices,
    url: '/admin/offices',
    exact: true,
    auth: true,
  },
  {
    title: 'Integration',
    component: Integrations,
    url: '/admin/integrations',
    exact: true,
    auth: true,
  },
  {
    title: 'Integration',
    component: TenantIntegrationUI,
    url: '/admin/integrations/:type/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'Integration',
    component: Integration,
    url: '/admin/integration',
    exact: true,
    auth: true,
  },
  {
    title: 'BillingPortals',
    component: BillingPortal,
    url: '/admin/billings',
    exact: true,
    auth: true,
  },
  {
    title: 'Custom Fields',
    component: CustomFields,
    url: '/admin/fields',
    exact: true,
    auth: true,
  },
  {
    title: 'Central Lookups',
    component: Lookups,
    url: '/admin/fields/lookups',
    exact: true,
    auth: true,
  },
  {
    title: 'Central Lookups',
    component: Lookups,
    url: '/admin/fields/lookups/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'Settings',
    component: Settings,
    url: '/admin/settings',
    exact: true,
    auth: true,
  },
  {
    title: 'Create Course',
    component: CourseListingContainer,
    url: '/admin/course',
    exact: true,
    auth: true,
  },
  {
    title: 'Create Course',
    component: CreateCourseContainer,
    url: '/admin/course/create',
    exact: true,
    auth: true,
  },
  {
    title: 'Create Course',
    component: CreateCourseContainer,
    url: '/admin/course/edit/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'Billing',
    component: BillingContainer,
    url: '/admin/billing/:tab',
    exact: true,
    auth: true,
  },
  {
    title: 'Role-play Minutes',
    component: RoleplayMinutesView,
    url: '/admin/roleplay-minutes',
    exact: true,
    auth: true,
  },
  {
    title: 'Roleplay',
    component: RoleplayContainer,
    url: '/admin/roleplay',
    exact: true,
    auth: true,
  },
];
