import React from 'react';

import { RoleplayReport } from 'src/components/lms';

function RoleplayProgress({ score, session }) {
  if (score?.roleplayScore?.length === 0) {
    return <></>;
  }

  return (
    <RoleplayReport
      scores={score.roleplayScore}
      sessionId={session.id}
      session={session}
      sx={{ backgroundColor: '#0C1721' }}
      // showTitle={false}
      title="Role-play progress"
    />
  );
  //     <Grid className={classes.container} sx={{ position: 'relative' }}>
  //       <Box className={classes.title}>
  //         <Typography className={classes.heading} variant="h3">
  //           Role-play progress
  //         </Typography>{' '}
  //         {/* <img src={Wave} /> */}
  //         {score?.roleplayScore?.length > 10 ? (
  //           <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             width="20"
  //             height="20"
  //             viewBox="0 0 20 20"
  //             fill="none"
  //             onClick={() => setOpen(true)}
  //           >
  //             <path
  //               d="M4.16797 18.3346C3.70964 18.3346 3.31727 18.1714 2.99089 17.845C2.6645 17.5187 2.5013 17.1263 2.5013 16.668V7.27213C2.2513 7.11936 2.04991 6.92144 1.89714 6.67838C1.74436 6.43533 1.66797 6.15408 1.66797 5.83463V3.33464C1.66797 2.8763 1.83116 2.48394 2.15755 2.15755C2.48394 1.83116 2.8763 1.66797 3.33464 1.66797H16.668C17.1263 1.66797 17.5187 1.83116 17.845 2.15755C18.1714 2.48394 18.3346 2.8763 18.3346 3.33464V5.83463C18.3346 6.15408 18.2582 6.43533 18.1055 6.67838C17.9527 6.92144 17.7513 7.11936 17.5013 7.27213V16.668C17.5013 17.1263 17.3381 17.5187 17.0117 17.845C16.6853 18.1714 16.293 18.3346 15.8346 18.3346H4.16797ZM4.16797 7.5013V16.668H15.8346V7.5013H4.16797ZM3.33464 5.83463H16.668V3.33464H3.33464V5.83463ZM7.5013 11.668H12.5013V10.0013H7.5013V11.668Z"
  //               fill="#A2AFBC"
  //             />
  //           </svg>
  //         ) : null}
  //         <CustomTooltip
  //           title={`Average score (last ${
  //             score?.roleplayScore?.length >= 3 ? 3 : score?.roleplayScore?.length
  //           })`}
  //           placement="top"
  //         >
  //           <p>
  //             <span>{getScore()}</span> / 5
  //           </p>
  //         </CustomTooltip>{' '}
  //       </Box>
  //       <Grid>
  //         <div className={classes.scoreContainer} onClick={() => setOpen(true)}>
  //           <div className={classes.score}>
  //             <p className={classes.scoreP}>5</p>
  //             <p className={classes.scoreP}>0</p>
  //           </div>

  //           {score?.roleplayScore?.slice(0, 10)?.map((item, index) => (
  //             <div className={classes.progressBar} key={index}>
  //               <div
  //                 className={`inner ${getClass(item)}`}
  //                 style={{ height: `${(item.score * 20).toFixed(2)}px` }}
  //               >
  //                 <span></span>
  //               </div>
  //             </div>
  //           ))}
  //         </div>
  //       </Grid>
  //     </Grid>
  //     {open && <RolePlayScoreModal item={score} user={user} open={open} onClose={setOpen} />}
  //   </>
  // );
}

export default RoleplayProgress;
