import * as types from '../actions/LMSTypes';
import { RESET_STORE } from 'src/modules/app/actions/appTypes';

const initialState = {
  loading: {
    sessions: false,
    homePage: false,
    session: false,
    notes: false,
    templates: false,
  },
  sessions: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    sort: {},
    data: [],
  },
  list: {
    paging: {
      pageNo: 0,
      perPage: 12,
      count: 0,
    },
    filters: {},
    sort: {},
    data: [],
  },
  history: {
    paging: {
      pageNo: 0,
      perPage: 12,
      count: 0,
    },
    filters: {},
    data: [],
    featured: [],
  },
  templates: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    sort: {},
    data: [],
  },
  notes: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    sort: {},
    data: [],
  },
  courses: [],
  session: {},
  homePage: [],
  homePageFilters: {},
  pillar: {},
  course: {},
  score: {},
};

const LMSReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_HOMEPAGE:
      return {
        ...state,
        loading: {
          ...state.loading,
          homePage: true,
        },
        homePage: [],
        homePageFilters: action?.filters || {},
      };

    case types.SET_HOMEPAGE:
      return {
        ...state,
        loading: {
          ...state.loading,
          homePage: false,
        },
        homePage: [],
      };
    case types.FETCH_SESSIONS:
      return {
        ...state,
        loading: {
          ...state.loading,
          sessions: true,
        },
        sessions: {
          ...state.sessions,
          data: [],
          paging: {
            ...state.sessions?.paging,
            ...action.paging,
          },
          filters: {
            ...state.sessions?.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };

    case types.SET_SESSIONS:
      return {
        ...state,
        loading: {
          ...state.loading,
          sessions: false,
        },
        sessions: {
          ...state.sessions,
          filters: state.sessions?.filters,
          sort: state.sessions?.sort,
          data: action.data,
          paging: {
            ...state.sessions.paging,
            count: action?.total?.value,
          },
        },
      };

    case types.FETCH_COURSES:
      return {
        ...state,
        loading: {
          ...state.loading,
          courses: true,
        },
        courses: [...state.courses],
      };

    case types.SET_COURSES:
      return {
        ...state,
        loading: {
          ...state.loading,
          courses: false,
        },
        courses: action.data,
      };

    case types.FETCH_SESSION:
      return {
        ...state,
        loading: {
          ...state.loading,
          session: true,
        },
        session: {},
      };

    case types.SET_SESSION:
      return {
        ...state,
        loading: {
          ...state.loading,
          session: false,
        },
        session: action.data,
      };
    case types.FETCH_LIST:
      return {
        ...state,
        loading: {
          ...state.loading,
          list: true,
        },
        list: {
          ...state.list,
          data: [],
          paging: {
            ...state.list.paging,
            ...action.paging,
          },
          filters: {
            ...state.list.filters,
            ...action.filters,
          },
        },
      };

    case types.SET_LIST:
      return {
        ...state,
        loading: {
          ...state.loading,
          list: false,
        },
        list: {
          ...state.list,
          data: action.data.favourite,
          paging: {
            ...state.list.paging,
            count: action.data.total?.value || 0,
          },
        },
      };

    case types.FETCH_SESSION_SCORE:
      return {
        ...state,
        loading: {
          ...state.loading,
          session: true,
        },
        score: {
          ...state.score,
        },
      };

    case types.SET_SESSION_SCORE:
      return {
        ...state,
        loading: {
          ...state.loading,
          session: false,
        },
        score: action.data,
      };

    case types.FETCH_PILLAR:
      return {
        ...state,
        loading: {
          ...state.loading,
          pillar: true,
        },
        pillar: {
          ...state.pillar,
        },
      };

    case types.SET_PILLAR:
      return {
        ...state,
        loading: {
          ...state.loading,
          pillar: false,
        },
        pillar: action.data,
      };

    case types.FETCH_COURSE:
      return {
        ...state,
        loading: {
          ...state.loading,
          course: true,
        },
        course: {
          ...state.course,
        },
      };

    case types.SET_COURSE:
      return {
        ...state,
        loading: {
          ...state.loading,
          course: false,
        },
        course: action.data,
      };

    case types.FETCH_HISTORY:
      return {
        ...state,
        loading: {
          ...state.loading,
          history: true,
        },
        history: {
          ...state.history,
          data: [],
          paging: {
            ...state.history.paging,
            ...action.paging,
          },
          filters: {
            ...state.history.filters,
            ...action.filters,
          },
        },
      };

    case types.SET_HISTORY:
      return {
        ...state,
        loading: {
          ...state.loading,
          history: false,
        },
        history: {
          ...state.history,
          data: action.data.history,
          ...(action.data.featured && action.data.featured.length
            ? {
                featured: action.data.featured,
              }
            : {}),
          paging: {
            ...state.history.paging,
            count: action.data.total.value,
          },
        },
      };

    case types.FETCH_TEMPLATES:
      return {
        ...state,
        loading: {
          ...state.loading,
          templates: true,
        },
        templates: {
          ...state.templates,
          data: [],
          paging: {
            ...state.templates?.paging,
            ...action.paging,
          },
          filters: {
            ...state.templates?.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };
    case types.EDIT_TEMPLATE:
      return {
        ...state,
        payload: {
          ...state.payload,
        },
      };
    case types.EDIT_NOTE:
      return {
        ...state,
        payload: {
          ...state.payload,
        },
      };

    case types.SET_TEMPLATES:
      return {
        ...state,
        loading: {
          ...state.loading,
          templates: false,
        },
        templates: {
          ...state.templates,
          data: action.data.templates,
          paging: {
            ...state.templates.paging,
            count: action?.data?.total?.value,
          },
        },
      };
    case types.FETCH_NOTES:
      return {
        ...state,
        loading: {
          ...state.loading,
          notes: true,
        },
        notes: {
          ...state.notes,
          data: [],
          paging: {
            ...state.notes?.paging,
            ...action.paging,
          },
          filters: {
            ...state.notes?.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };
    case types.SET_NOTES:
      return {
        ...state,
        loading: {
          ...state.loading,
          notes: false,
        },
        notes: {
          ...state.notes,
          data: action.data.notes,
          paging: {
            ...state.notes.paging,
            count: action?.data?.total?.value,
          },
        },
      };

    case RESET_STORE:
      return initialState;

    default:
      return state;
  }
};

export default LMSReducer;
