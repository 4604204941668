import React, { useState } from 'react';
import * as Yup from 'yup';
import { Box } from '@mui/material';
import { Form, Button, Spacer } from 'src/components/shared';
import { FormWrapper } from './styles';

function AddPanelForm({ panel, postPanel, putPanel, deletePanel, onClose, setShowDeleteDialog }) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      if (panel?.id && panel?.id !== '') {
        await putPanel(panel?.id, values);
      } else {
        await postPanel(values);
      }
      setLoading(false);
      onClose();
    } catch (error) {}
  };

  return (
    <>
      <Form
        initialValues={{
          name: panel?.name || '',
          tabColor: panel?.tabColor || '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Please enter panel name!'),
          tabColor: Yup.string().required('Please choose panel color!'),
        })}
        onSubmit={handleSubmit}
      >
        {(props) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                props.submitForm();
                return false;
              }}
              noValidate
              style={{ width: '100%' }}
            >
              <Box>
                <Form.Field.Input
                  fullWidth
                  variant="outlined"
                  name="name"
                  size="small"
                  placeholder="panel Name"
                  disabled={loading}
                />
                <Spacer x={1} y={1} />
                <Box>
                  <Form.Field.ColorPicker width={panel?.id ? '100%' : 240} name="tabColor" />
                </Box>
                <Spacer x={1} y={1} />
              </Box>

              <Box
                mt={2}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Box>
                  {panel?.id ? (
                    <Button
                      variant="contained"
                      color="error"
                      onClick={setShowDeleteDialog}
                      disabled={loading}
                    >
                      Delete Panel
                    </Button>
                  ) : null}
                </Box>
                <Box display="flex">
                  <Button variant="outlined" color="primary" onClick={onClose} disabled={loading}>
                    Cancel
                  </Button>
                  <Spacer basis={2} />
                  <Button type="submit" variant="contained" color="primary" loading={loading}>
                    Save
                  </Button>
                </Box>
              </Box>
            </form>
          );
        }}
      </Form>
    </>
  );
}

export default AddPanelForm;
