import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { getAttribute, getLookup } from 'src/modules/app/api/appApis';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { dateFormat } from 'src/config';
import moment from 'moment';

const dontShowFields = [
  'jobTitle',
  'lname',
  'fname',
  'account',
  'title',
  'linkedinProfile',
  'email',
  'address',
  'phone',
  'town',
];

const AttributeItem = ({ attribute, contact }) => {
  let value;
  const [options, setOptions] = useState([]);
  const [childOptions, setChildOptions] = useState([]);
  const [parentAttribute, setParentAttribute] = useState({});
  const title = attribute?.label || '';

  useEffect(() => {
    if (attribute.fieldType === 'lookup' && attribute.lookup && attribute.lookup !== '') {
      getLookup(attribute.lookup).then(async (response) => {
        if (attribute?.parentAttribute && attribute?.parentAttribute !== '') {
          getAttribute(attribute?.parentAttribute).then((res) => {
            setParentAttribute(res);
          });
        }
        let options = response.lookup?.options;
        options = options.filter((item) => item?.enabled === true || false);
        setOptions(options);
      });
    }
  }, [attribute]);

  if (
    attribute.fieldType === 'upload' &&
    Array.isArray(contact[attribute.fieldName]) &&
    contact[attribute.fieldName].length
  )
    value = contact[attribute.fieldName][0].url;
  else if (Array.isArray(contact[attribute.fieldName]) && contact[attribute.fieldName].length) {
    value = options
      .filter((k) => contact[attribute.fieldName].includes(k.value))
      .map((k) => k.label)
      .join(' - ');
  } else {
    value = contact[attribute.fieldName];
  }

  if (attribute.fieldType === 'date') value = value ? moment(value).format(dateFormat) : null;
  if (attribute.fieldName === 'gender' || attribute.fieldName === 'Gender') {
    switch (true) {
      case contact[attribute.fieldName] === 'M':
        value = 'Male';
        break;
      case contact[attribute.fieldName] === 'F':
        value = 'Female';
        break;
      case contact[attribute.fieldName] === 'U':
        value = 'Not Disclosed';
        break;
      default:
        value = contact[attribute.fieldName];
        break;
    }
  }

  return (
    <Box>
      <Typography variant="body2" color="textSecondary" textTransform={'capitalize'}>
        {attribute.label}
      </Typography>
      <Typography color="textPrimary">{value}</Typography>
    </Box>
  );
};

function Attributes({ contact, fields }) {
  const customFields = fields?.filter(
    (field) =>
      field.status === 'active' &&
      field.inContact &&
      (field?.fieldOptions?.showOnForm || field?.fieldOptions?.showOnView) &&
      contact[field.fieldName] &&
      !(
        dontShowFields.includes(field.fieldName) ||
        !contact[field.fieldName] ||
        !contact[field.fieldName]?.length
      ),
  );

  return customFields?.length ? (
    customFields.map((field, index) => (
      <AttributeItem key={`contact-attribute-item-${index}`} attribute={field} contact={contact} />
    ))
  ) : (
    <Box
      style={{
        alignItems: 'center',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
      }}
    >
      <SentimentVeryDissatisfiedIcon sx={{ fontSize: 42 }} color="info" />
      <Typography variant="h3" fontWeight={500} color="grey">
        No custom attribute found
      </Typography>
      <Typography variant="h5" color="textSecondary">
        No custom attribute found. You can create custom fields from admin app
      </Typography>
    </Box>
  );
}

export default Attributes;
