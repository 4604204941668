import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { Button } from 'src/components/shared';
import styled from 'styled-components';

export const ContactRoot = styled(Box).attrs({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'row',
  p: 1,
  ml: 1,
})`
  & .appear-item {
    display: none;
  }
  &:hover .appear-item {
    display: block;
  }
  width: calc(100% - 24px);
  & .sub-text {
    color: #4789c2;
    marginleft: 4px;
    marginright: 4px;
  }
`;

export const ContactActionButton = styled(Button).attrs({
  size: 'small',
  variant: 'outlined',
})`
  /* padding: 0px; */
  padding-top: 0;
  padding-bottom: 0;
`;

const useStyles = makeStyles(() => ({
  globalSearchNoBorder: {
    border: 'none',
  },
  globalSearchRoot: {
    margin: 0,
    marginLeft: '6px',
    width: '300px',
    '& .MuiInputBase-root': {
      padding: 0,
      paddingRight: '16px !important',
      fontSize: '14px',
      background: 'transparent',
      borderRadius: '4px',
      '&:hover': {
        //backgroundColor: '#f5f5f5',
      },
      '&.Mui-focused': {
        // backgroundColor: '#f5f5f5',
      },
      '& svg.MuiSvgIcon-root': {
        marginLeft: '12px',
      },
      '& input': {
        padding: '7.5px 4px !important',
      },
      // '& .MuiOutlinedInput': {
      //   borderColor: 'rgba(0, 0, 0, 0.23)',
      // },
    },
  },
  globalSearchPopper: {
    width: '380px !important',
    overflowX: 'hidden !important',
    boxShadow:
      '0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12)',
  },
  globalSearchListBox: {
    maxHeight: '70vh',
    '& .MuiAutocomplete-option': {
      backgroundColor: '#ffffff',
    },
    '& li': {
      padding: '10px 30px',
    },
  },
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'inline-block',
    textDecoration: 'none',
    width: '240px',
    textAlign: 'left',
  },
  paper: {},
  avatar: {
    marginRight: '15px',
    backgroundColor: 'rgba(189, 189, 189, 1)',
  },
  avatarSeq: {
    marginRight: '15px',
    backgroundColor: 'rgba(227, 242, 253, 1)',
  },
  secondaryText: {
    fontSize: '12px',
    color: 'rgba(0,0,0,0.38)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '250px',
  },
  secondaryTextSuccess: {
    color: '#66BB6A',
  },
  secondaryTextDanger: {
    color: '#f44336',
  },
  primaryText: {
    fontSize: '15px',
    color: 'rgba(0,0,0,0.87)',
  },
}));

export default useStyles;
