import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ custom }) => ({
  headerSubTitle: {
    color: '#646464',
    letterSpacing: '0.17px',
    lineHeight: '143%',
  },
  mainCardIntegrations: {
    height: '100%',
    '& .MuiCardContent-root': {
      padding: 0,
      paddingBottom: 0,
    },
    '& .cursor-pointer': {
      cursor: 'pointer',
    },
  },
  cardContentIntegrationCard: {
    height: '100%',
    position: 'relative',
    maxHeight: 320,
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
  },
  cardImageHeader: {
    marginBottom: 10,
    '& svg': {
      width: 50,
      height: 50,
    },
  },
  integrationDescription: {
    display: 'flex',
    alignItems: 'start',
    color: 'rgba(0, 0, 0, 0.6)',
    textAlign: 'center',
    marginBottom: 10,
  },
  cardHeader: {
    width: '100%',
    position: 'relative',
    marginTop: 18,
    '& .title': {
      fontWeight: 400,
      textAlign: 'center',
    },
    '& .value': {
      fontSize: 13,
      fontWeight: 300,
      color: '#9d7596',
    },
  },
  cardWarning: {
    position: 'absolute',
    right: 0,
    top: -6,
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    gap: 6,
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    background: '#ED6C02',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
  },
  bullhornTitle: {
    marginBottom: 6,
    display: 'flex',
    padding: '10px',
    '& .title': {
      fontWeight: 300,
      letterSpacing: 0.5,
      fontSize: 14,
    },
    '& .value': {
      fontWeight: 200,
      color: 'rgba(0, 0, 0, 0.38)',
    },
  },
  gridContent: {
    marginBottom: 30,
  },
  gridContentRowDataDeActive: {
    display: 'flex',
    alignItems: 'center',
    color: '#ebebeb',
  },
  gridContentRowDataActive: {
    display: 'flex',
    alignItems: 'center',
    color: '#4CAF50',
  },
  cardFooter: {
    bottom: 24,
  },
  gridFooterRowData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .installation': {},
    '& .connect': {
      '& .manage-link': {
        color: '#5780ab',
        textDecoration: 'none',
        backgroundColor: 'transparent',
      },
    },
  },
  connected: {
    color: '#EF5350',
    '& svg': {
      fontSize: '2rem',
    },
    '& svg.power-icon': {
      fontSize: '1.6rem',
    },
  },
  disConnected: {
    color: '#ebebeb',
    '& svg': {
      fontSize: '2rem',
    },
    '& svg.power-icon': {
      fontSize: '1.6rem',
    },
  },
  subTitle: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '14px',
    fontWeight: '400',
    marginBottom: 0,
  },
  connectedBtn: {
    '&:disabled': {
      color: 'white',
      backgroundColor: '#4CAF50',
    },
  },
  connectBtn: {
    color: '#1976D2',
    border: '1px solid #1976D2',
    '&:hover': {
      backgroundColor: '#e6ebf0',
      border: '1px solid #1976D2',
    },
    '& .btn-text': {
      paddingTop: 2,
    },
  },
}));
