import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Header from 'src/components/lms/Header';
import Sidebar from 'src/components/lms/Sidebar';
import { drawerWidth, drawerWidthCollapsed } from 'src/config/learning';
import { Container } from '@mui/material';
import { withLmsStore } from 'src/modules/lms/utils/lmsStore';
import ScrollTop from 'src/components/shared/ScrollTop';

const ResponsiveDrawer = (props) => {
  const { window } = props;

  const [collapsed, setCollapsed] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <ScrollTop />
      <Header
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Sidebar
        window={window}
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        mobileOpen={mobileOpen}
        handleDrawerClose={handleDrawerClose}
        handleDrawerTransitionEnd={handleDrawerTransitionEnd}
      />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { md: `calc(100% - ${collapsed ? drawerWidthCollapsed : drawerWidth}px)` },
          marginTop: 3,
          marginBottom: 4,
          maxWidth: '100vw',
        }}
      >
        <Container maxWidth="lg">
          <Toolbar />
          {props.children}
        </Container>
      </Box>
    </Box>
  );
};

export default withLmsStore(ResponsiveDrawer);
