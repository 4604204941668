import React, { useEffect, useState } from 'react';
import { useHistory, useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Box, Typography, Alert, AlertTitle } from '@mui/material';
import { Spacer } from 'src/components/shared';

import ResetPasswordForm from '../../components/ResetPasswordForm';
import _ from 'lodash';

export default function ResetPasswordView({ location, ...props }) {
  const history = useHistory();
  const [isReset, setIsReset] = useState(false);

  useEffect(() => {
    if (!location?.query.u || !location?.query.retoken) {
      history.replace('/auth/login');
    }
  }, [location]);

  const onSubmit = async (data) => {
    try {
      const payload = {
        userId: location.query.u,
        passwordResetToken: location.query.retoken,
        password: data.password,
      };
      await props.resetPassword(payload);
      setIsReset(true);
      return true;
    } catch (error) {
      setIsReset(false);
      return false;
    }
  };

  const getContent = () => {
    return <Typography component="h1">Login</Typography>;
  };

  return (
    <React.Fragment>
      <Typography variant="h1" align="center">
        Reset password
      </Typography>
      <div style={{ height: 10 }} />
      <div style={{ maxWidth: 400, margin: '0 auto' }}>
        {isReset ? (
          <Box>
            <Spacer x={2} y={2} />
            <Alert severity="success" variant="standard">
              <AlertTitle>Success</AlertTitle>
              Your password has been updated.
            </Alert>
            <Spacer x={2} y={2} />
            <Link to="/auth/login">
              <Typography variant="body2" color="secondary" style={{ cursor: 'pointer' }}>
                Return to Login
              </Typography>
            </Link>
          </Box>
        ) : (
          <>
            <ResetPasswordForm onSubmit={onSubmit} />
          </>
        )}
      </div>
    </React.Fragment>
  );
}
