import { Box, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { includes, map } from 'lodash';
import { Children, Fragment, useEffect, useState } from 'react';
import { Drawer, Form, Modal } from 'src/components/shared';
import SharedButton from 'src/components/shared/Button';
import * as Yup from 'yup';
import { fetchTeamsLookup } from '../../api/adminApi';
import { useDataProviders } from '../../utils/dataProviderStore';
import { dummyApi } from 'src/utils/dummyApi';
import TeamForm from '../Integrations/IntegrationForm';
import { tenantIntegration } from '../../config/integrationData';

function WaterFallModals({ open, setOpen, selected, props, onCloseSelection }) {
  const [loading, setLoading] = useState({ toggle: false });
  const [drawerOpen, setDrawerOpen] = useState({ edit: null, open: false });
  const onClose = () => {
    if ('change-contract-success' === open) onDrawerClose();
    setOpen(false);
  };
  const onDrawerClose = () => {
    setDrawerOpen({ edit: null, open: false });
    onCloseSelection();
  };

  const {
    // config,
    // waterfall: waterfallData,
    // fetchEnrichConfig,
    // fetchWaterFall,
    createWaterFall,
    // updateEnrichConfig,
    waterfalls,
    updateWaterFall,
    resetWaterFall,
    selectedWaterfall,
    updateWaterfallService,
  } = useDataProviders();

  const toggleWaterfall = (isActive) => {
    setLoading((prev) => ({ ...prev, toggle: true }));
    updateWaterFall(selectedWaterfall.id, { status: isActive ? 'active' : 'inactive' }).finally(
      () => {
        setLoading((prev) => ({ ...prev, toggle: false }));
        onClose();
      },
    );
  };

  const getContent = () => {
    switch (open) {
      case 'turn-off-waterfall':
        return (
          <Fragment>
            <Typography>Are you sure you want to make this waterfall inactive?</Typography>
            <br />
            <Typography>
              {selectedWaterfall.type !== 'default'
                ? "Deactivating this custom waterfall will revert the contact information searches to use SuperReach's default waterfall."
                : 'Any teams currently using this waterfall will be automatically reassigned to the SuperReach default waterfall'}
            </Typography>
            <Stack direction={'row'} justifyContent={'end'} spacing={2} mt={3}>
              <SharedButton color="secondary" onClick={onClose} variant="text">
                Cancel
              </SharedButton>
              <SharedButton
                disableElevation
                color="secondary"
                onClick={() => toggleWaterfall(false)}
                variant="contained"
                loading={loading.toggle}
              >
                Yes, continue
              </SharedButton>
            </Stack>
          </Fragment>
        );

      case 'turn-on-waterfall':
        return (
          <Fragment>
            <Typography>Are you sure you want to make this waterfall active?</Typography>
            <Stack direction={'row'} justifyContent={'end'} spacing={2} mt={3}>
              <SharedButton color="secondary" onClick={onClose} variant="text">
                Cancel
              </SharedButton>
              <SharedButton
                disableElevation
                color="secondary"
                onClick={() => toggleWaterfall(true)}
                variant="contained"
                loading={loading.toggle}
              >
                Yes, continue
              </SharedButton>
            </Stack>
          </Fragment>
        );

      case 'team-management':
        return (
          <Box minWidth={500}>
            <Typography variant="body2" color="text.secondary" mb={1}>
              Choose which teams have access to this waterfall.
            </Typography>
            <Form
              initialValues={{
                sharedWithTeams: selectedWaterfall?.sharedWithTeams || [],
              }}
              onSubmit={(values, { setSubmitting }) => {
                updateWaterFall(selectedWaterfall.id, {
                  sharedWithTeams: map(values.sharedWithTeams, 'id'),
                })
                  .then(() => {
                    setOpen('team-management-success');
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              }}
            >
              {({ values, isSubmitting, handleSubmit, isDirty, ...formProps }) => {
                return (
                  <>
                    <form onSubmit={handleSubmit} style={{ height: '100%' }} noValidate>
                      <Form.Field.AutoComplete
                        multiple={true}
                        options={[]}
                        fullWidth
                        variant="outlined"
                        name="sharedWithTeams"
                        label="Select team"
                        checkboxes={true}
                        remoteMethod={async (val) => {
                          const teamLookup = await fetchTeamsLookup(val);
                          const alreadyTeamedUp =
                            Object.keys(
                              waterfalls?.reduce((acc, obj) => {
                                if (
                                  selectedWaterfall?.type !== 'default' &&
                                  obj?.id !== selectedWaterfall.id &&
                                  obj?.type === 'custom' && 
                                    obj.status === "active"
                                )
                                  (obj?.sharedWithTeams || [])?.forEach(
                                    (team) => (acc[team.id] = team.id),
                                  );
                                return acc;
                              }, {}) || {},
                            ) || [];
                          return teamLookup?.filter((team) => !alreadyTeamedUp?.includes(team?.id));
                        }}
                        optLabel="name"
                        optValue="id"
                      />
                      <Stack direction={'row'} justifyContent={'end'} spacing={2} mt={3}>
                        <SharedButton
                          color="secondary"
                          onClick={onClose}
                          variant="text"
                          disabled={isSubmitting}
                          type="button"
                        >
                          Cancel
                        </SharedButton>
                        <SharedButton
                          loading={isSubmitting}
                          disableElevation
                          color="secondary"
                          type="submit"
                          variant="contained"
                        >
                          {isSubmitting ? 'Saving' : 'Save'}
                        </SharedButton>
                      </Stack>
                    </form>
                  </>
                );
              }}
            </Form>
          </Box>
        );

      case 'restore-waterfall':
        return (
          <Fragment>
            <Typography mb={2}>Are you sure you want to restore the default waterfall?</Typography>

            <Typography>By restoring, you will lose your custom edits</Typography>
            <Stack direction={'row'} justifyContent={'end'} spacing={2} mt={3}>
              <SharedButton color="secondary" onClick={onClose} variant="text">
                Cancel
              </SharedButton>
              <SharedButton
                disableElevation
                color="secondary"
                onClick={() => {
                  resetWaterFall(selectedWaterfall.id);
                  onClose();
                }}
                variant="contained"
                loading={loading.toggle}
              >
                Yes, continue
              </SharedButton>
            </Stack>
          </Fragment>
        );

      case 'create-waterfall':
        return (
          <Fragment>
            <Box minWidth={500}>
              <Form
                initialValues={{
                  name: '',
                  sharedWithTeams: [],
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required('Please enter waterfall name!'),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  createWaterFall({
                    ...values,
                    sharedWithTeams: map(values.sharedWithTeams, 'id'),
                  })
                    .then(() => {
                      setOpen('create-waterfall-success');
                    })
                    .finally(() => {
                      setSubmitting(false);
                    });
                }}
              >
                {({ values, isSubmitting, handleSubmit, ...formProps }) => {
                  return (
                    <>
                      <form onSubmit={handleSubmit} style={{ height: '100%' }} noValidate>
                        <Typography variant="h1" mb={1}>
                          Give your waterfall a name
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Choose a name you’ll easily recognise.
                        </Typography>
                        <Form.Field.Input
                          fullWidth
                          variant="outlined"
                          name="name"
                          label="Waterfall name"
                        />

                        <Stack direction={'row'} justifyContent={'end'} spacing={2} mt={3}>
                          <SharedButton
                            color="secondary"
                            onClick={onClose}
                            variant="text"
                            type="button"
                            disabled={isSubmitting}
                          >
                            Cancel
                          </SharedButton>
                          <SharedButton
                            loading={isSubmitting}
                            disableElevation
                            color="secondary"
                            type="submit"
                            variant="contained"
                          >
                            {isSubmitting ? 'Creating' : 'Create waterfall'}
                          </SharedButton>
                        </Stack>
                      </form>
                    </>
                  );
                }}
              </Form>
            </Box>
          </Fragment>
        );

      case 'edit-waterfall':
        return (
          <Fragment>
            <Box minWidth={500}>
              <Form
                initialValues={selectedWaterfall}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required('Please enter waterfall name!'),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  updateWaterFall(
                    selectedWaterfall.id,
                    {
                      name: values?.name,
                      sharedWithTeams: map(values.sharedWithTeams, 'id'),
                    },
                    true,
                  )
                    .then(() => {
                      setOpen('edit-waterfall-success');
                    })
                    .finally(() => {
                      setSubmitting(false);
                    });
                }}
              >
                {({ values, isSubmitting, handleSubmit, ...formProps }) => {
                  return (
                    <>
                      <form onSubmit={handleSubmit} style={{ height: '100%' }} noValidate>
                        <Typography variant="h1" mb={1}>
                          Give your waterfall a name
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Choose a name you’ll easily recognise.
                        </Typography>
                        <Form.Field.Input
                          fullWidth
                          variant="outlined"
                          name="name"
                          label="Waterfall name"
                        />

                        <Typography variant="h1" mb={1} mt={2}>
                          Team Management
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Choose which teams have access to this waterfall.
                        </Typography>
                        <Form.Field.AutoComplete
                          multiple={true}
                          options={[]}
                          fullWidth
                          variant="outlined"
                          name="sharedWithTeams"
                          label="Select teams"
                          checkboxes={true}
                          remoteMethod={async (val) => {
                            const teamLookup = await fetchTeamsLookup(val);
                            const alreadyTeamedUp =
                              Object.keys(
                                waterfalls?.reduce((acc, obj) => {
                                  if (
                                    selectedWaterfall?.type !== 'default' &&
                                    obj?.id !== selectedWaterfall.id &&
                                    obj?.type === 'custom' && 
                                    obj.status === "active"
                                  )
                                    (obj?.sharedWithTeams || [])?.forEach(
                                      (team) => (acc[team.id] = team.id),
                                    );
                                  return acc;
                                }, {}) || {},
                              ) || [];
                            return teamLookup?.filter(
                              (team) => !alreadyTeamedUp?.includes(team?.id),
                            );
                          }}
                          optLabel="name"
                          optValue="id"
                        />

                        <Stack direction={'row'} justifyContent={'end'} spacing={2} mt={3}>
                          <SharedButton
                            color="secondary"
                            onClick={onClose}
                            variant="text"
                            type="button"
                            disabled={isSubmitting}
                          >
                            Cancel
                          </SharedButton>
                          <SharedButton
                            loading={isSubmitting}
                            disableElevation
                            color="secondary"
                            type="submit"
                            variant="contained"
                          >
                            {isSubmitting ? 'Updating' : 'Update'}
                          </SharedButton>
                        </Stack>
                      </form>
                    </>
                  );
                }}
              </Form>
            </Box>
          </Fragment>
        );

      case 'create-waterfall-success':
        return (
          <Fragment>
            <Typography variant="body1" fontWeight={600} color="text.secondary" mt={2}>
              What to do next:
            </Typography>

            <List sx={{ listStyle: 'decimal', pl: 4, mb: 3 }}>
              {Children.toArray(
                map(
                  [
                    "Remove any providers that don't meet your needs",
                    'Drag and drop providers to change the order.',
                    'Add your own contracts to our native integrations.',
                  ],
                  (item) => {
                    return (
                      <ListItem
                        sx={{ display: 'list-item', color: 'text.secondary' }}
                        disablePadding
                      >
                        <ListItemText primary={item} />
                      </ListItem>
                    );
                  },
                ),
              )}
            </List>

            <SharedButton
              variant="contained"
              color="secondary"
              onClick={onClose}
              sx={{ float: 'right' }}
            >
              Get started
            </SharedButton>
          </Fragment>
        );

      case 'change-contract-alert':
        const integrations = props?.integrations?.data?.reduce((acc, obj) => {
          acc[obj?.name] = obj;
          return acc;
        }, {});
        const filteredIntegrations = tenantIntegration.map((obj) => {
          if (Object.keys(integrations).includes(obj?.name)) return integrations[obj?.name];
          else return obj;
        });
        const integration =
          filteredIntegrations?.find(
            ({ name }) => name?.toLowerCase() === selected?.name?.toLowerCase(),
          ) || {};
        let message =
          'Add your own API key to activate your data contract. This will turn off the SuperReach contract.';
        if (
          selected.contract == 'customer' ||
          (integration?.integration?.apikey && integration?.status === 'active')
        ) {
          message = 'Are you sure you want to change the contract type ?';
        }
        return (
          <Fragment>
            <Typography>{message}</Typography>
            <Stack direction={'row'} justifyContent={'end'} spacing={2} mt={3}>
              <SharedButton color="secondary" onClick={onClose} variant="text">
                Cancel
              </SharedButton>
              <SharedButton
                disableElevation
                color="secondary"
                onClick={async () => {
                  if (
                    selected.contract == 'customer' ||
                    (integration?.integration?.apikey && integration?.status === 'active')) {
                    onClose();
                    await updateWaterfallService(selectedWaterfall?.id, {
                      type: selected.tab.id,
                      service: selected.name,
                      status: selected.status,
                      contract: selected.contract == 'customer' ? 'superreach' : 'customer',
                    });
                    setOpen('change-contract-success');
                    return;
                  } else {
                    setDrawerOpen({
                      edit: integration,
                      open: true,
                    });
                    onClose();
                  }
                }}
                variant="contained"
              >
                Yes, continue
              </SharedButton>
            </Stack>
          </Fragment>
        );

      // case 'change-contract-form':
      // return (
      //   <Fragment>
      //     <Box minWidth={500}>
      //       <Form
      //         initialValues={{
      //           apiKey: '',
      //           name: '',
      //           password: '',
      //         }}
      //         onSubmit={(values, { setSubmitting }) => {
      //           const payload = {
      //             type: tab.id,
      //             service: item.name,
      //             status: item.status,
      //           };
      //           // updateWaterfallService(selectedWaterfall?.id, payload)
      //           dummyApi(2000)
      //             .then(() => {
      //               setOpen('change-contract-success');
      //             })
      //             .finally(() => {
      //               setSubmitting(false);
      //             });

      //           // const handleChange = async (item) => {
      //           //   setLoading(true);
      //           //   const payload = {
      //           //     type: tab.id,
      //           //     service: item.name,
      //           //     status: item.status,
      //           //   };
      //           //   await ;
      //           //   setLoading(false);
      //           // };
      //         }}
      //       >
      //         {({ values, isSubmitting, handleSubmit, ...formProps }) => {
      //           return (
      //             <>
      //               <form onSubmit={handleSubmit} style={{ height: '100%' }} noValidate>
      //                 <Typography variant="body2" color="text.secondary">
      //                   Please enter your {selected?.name} contract details to activate.
      //                 </Typography>
      //                 <Form.Field.Input
      //                   fullWidth
      //                   variant="outlined"
      //                   name="apiKey"
      //                   label="API key"
      //                   required
      //                 />
      //                 <Form.Field.Input
      //                   fullWidth
      //                   variant="outlined"
      //                   name="name"
      //                   label="User name"
      //                   required
      //                 />
      //                 <Form.Field.Input
      //                   fullWidth
      //                   variant="outlined"
      //                   name="password"
      //                   label="Password"
      //                   required
      //                 />
      //                 <Stack direction={'row'} justifyContent={'end'} spacing={2} mt={3}>
      //                   <SharedButton
      //                     color="secondary"
      //                     onClick={onClose}
      //                     variant="text"
      //                     type="button"
      //                     disabled={isSubmitting}
      //                   >
      //                     Cancel
      //                   </SharedButton>
      //                   <SharedButton
      //                     loading={isSubmitting}
      //                     disableElevation
      //                     color="secondary"
      //                     type="submit"
      //                     variant="contained"
      //                   >
      //                     {isSubmitting ? 'Saving' : 'Save'}
      //                   </SharedButton>
      //                 </Stack>
      //               </form>
      //             </>
      //           );
      //         }}
      //       </Form>
      //     </Box>
      //   </Fragment>
      // );

      default:
        return null;
    }
  };

  return (
    <Box>
      <Modal
        variant={
          includes(
            [
              'team-management-success',
              'create-waterfall-success',
              'edit-waterfall-success',
              'change-contract-success',
            ],
            open,
          )
            ? 'success'
            : 'standard'
        }
        title={
          {
            'turn-off-waterfall': 'Turn off the waterfall',
            'turn-on-waterfall': 'Turn on the waterfall',
            'team-management': 'Team management',
            'team-management-success': 'Team management updated successfully!',
            'restore-waterfall': 'Restore waterfall',
            'create-waterfall': 'Add a waterfall',
            'edit-waterfall': 'Edit waterfall',
            'create-waterfall-success': 'Success! Your waterfall is ready to be customised!',
            'edit-waterfall-success': 'Success! Your waterfall is updated!',
            'change-contract-alert': 'Change contract',
            'change-contract-form': `${selected?.name} contract`,
            'change-contract-success': `Success! Your ${selected?.name} contract has been updated.`,
          }[open]
        }
        open={open}
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '10px',
            '& > h2': includes(
              [
                'team-management-success',
                'create-waterfall-success',
                'edit-waterfall-success',
                'change-contract-success',
              ],
              open,
            )
              ? {}
              : {
                  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                },
            '& .MuiDialogContent-root': {
              padding: '30px',
            },
          },
        }}
        onClose={onClose}
      >
        {getContent()}
      </Modal>
      <Drawer
        title={`${drawerOpen.edit?.name} Setting`}
        open={drawerOpen.open}
        onClose={onDrawerClose}
      >
        <TeamForm
          editUser={drawerOpen.edit}
          onComplete={async () => {
            await updateWaterfallService(selectedWaterfall?.id, {
              type: selected.tab.id,
              service: selected.name,
              status: selected.status,
              contract: selected.contract == 'customer' ? 'superreach' : 'customer',
            });
            setDrawerOpen({ edit: null, open: false });
            setOpen('change-contract-success');
          }}
          tenantIntegration={tenantIntegration}
          {...props}
          onClose={onDrawerClose}
        />
      </Drawer>
    </Box>
  );
}

export default WaterFallModals;
