import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import { fetchFile } from 'src/modules/app/api/appApis';
import { Spacer, Button } from 'src/components/shared';
import File from './File';

import { FilesGrid } from './styles';

function Attachments({ attachments }) {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (attachments && attachments.length) {
      fetchAttachments();
    }
  }, [attachments]);

  const fetchAttachments = async () => {
    const promises = [];
    setLoading(true);
    for (let i = 0; i < attachments?.length; i++) {
      const attachmentId = attachments[i];
      promises.push(fetchFile(attachmentId));
    }

    try {
      let response = await Promise.all(promises);
      if (response && response.length) {
        const attaches = response.map((file) => ({
          ...file.upload,
        }));
        setLoading(false);
        setFiles(attaches);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const downloadAll = async () => {
    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        setTimeout(() => {
          window.open(file.url, '_blank');
        }, 100);
      }
    } catch (error) {}
  };

  return (
    <>
      <Divider />
      <Spacer x={1} y={1} />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography color="textSecondary" variant="body2" sx={{ color: 'rgba(0,0,0,0.5)' }}>
          {attachments.length} attachments
        </Typography>
        {/* <Button onClick={downloadAll} color="secondary" size="small" startIcon={<DownloadIcon />}>
          Download all
        </Button> */}
      </Stack>

      <FilesGrid>
        {files.map((file, index) => (
          <File file={file} key={`file-${index}`} />
        ))}
      </FilesGrid>
    </>
  );
}

export default Attachments;
