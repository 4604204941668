import React, { useEffect, useMemo, useState } from 'react';
import {
  Wrapper,
  Content,
  ProgressWrapper,
  CourseWrap,
  Gradient,
  GradientTop,
  Experts,
  Expert,
  Title,
  SessionImage,
  AssignedIcon,
} from './styles';
import ProgressBar from '../ProgressBar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { capitalizeFirstLetter } from 'src/utils/helper';
import { ReactComponent as SessionPlaceholder } from 'src/assets/lms/icons/SessionPlaceholder.svg';
import { useLmsHomeProvider } from 'src/modules/lms/utils/lmsStore';
import { useHistory } from 'react-router-dom';
import { fetchSessionCourses } from 'src/modules/lms/api/LMSApis';

function Session({ session }) {
  const history = useHistory();
  const { progress, assigned } = useLmsHomeProvider();

  const [sessionCourses, setSessionCourses] = useState([]);

  const title = capitalizeFirstLetter(session?.title || '');
  const thumbnailImage = session?.thumbnailImage || '';

  const sessionProgress = progress.sessions[session?.id]
    ? progress.sessions[session?.id]
    : undefined;
  const totalVideos = session?.totalVideos || 0;
  const videosWatched = sessionProgress?.stats?.videoWatched || 0;

  useEffect(() => {
    if (session.id) {
      fetchSessionCourses(session.id)
        .then((res) => {
          setSessionCourses(res.courses);
        })
        .catch((err) => {
          setSessionCourses([]);
        });
    }
  }, [session]);

  const handleClick = () => {
    history.push(`/lms/session/${session.id}`);
  };

  const courseName = sessionCourses.length ? sessionCourses[0].title : '';

  const isAssigned = useMemo(() => {
    if (session?.id) {
      return assigned.sessions && assigned.sessions.includes(session.id);
    }
    return false;
  }, [assigned, session]);

  return (
    <Wrapper onClick={handleClick}>
      {totalVideos > 0 && (
        <ProgressWrapper>
          <ProgressBar total={totalVideos} completed={videosWatched} />
        </ProgressWrapper>
      )}

      <Content hasCourse={courseName !== ''}>
        <Gradient />
        <GradientTop />
        {thumbnailImage ? (
          thumbnailImage !== '' && <SessionImage src={thumbnailImage} />
        ) : (
          <Stack alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
            <SessionPlaceholder style={{ width: '40%' }} />
          </Stack>
        )}

        <Title>{title}</Title>
      </Content>

      <CourseWrap>
        {courseName && courseName !== '' ? (
          <Typography
            component="span"
            variant="body2"
            color="textSecondary"
            textAlign="left"
            noWrap
          >
            From{' '}
            <Typography component="span" variant="body2" fontWeight="600" color="textPrimary">
              {courseName}
            </Typography>
          </Typography>
        ) : (
          <Typography
            component="span"
            variant="body2"
            color="textSecondary"
            textAlign="left"
            noWrap
          >
            &nbsp;
          </Typography>
        )}
        {isAssigned && <AssignedIcon />}
      </CourseWrap>
    </Wrapper>
  );
}

export default Session;
