import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Checkbox } from '@mui/material';
import { AppGrid, ConfirmDialog } from 'src/components/App';
import { useEnrolmentColumns } from './useEnrolmentColumns';
import { draftActions } from '../../config';

import { bulkDeleteEnrolments, fetchSequencesLookup } from 'src/modules/sequence/api/sequenceApi';
import { enqueueSequencePersonalize } from 'src/modules/sequence/actions/enrolmentActions';
import { useDispatch } from 'react-redux';

const EnrolmentsGrid = ({ enrolmentDrafts, loading, fetchEnrolmentDrafts, ...props }) => {
  const [rowsSelected, setRowsSelected] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const dispatch = useDispatch();

  const { data, filters, paging } = enrolmentDrafts;

  const filterOptions = [
    {
      key: 'sequence',
      title: 'Sequence',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: true,
      searchable: true,
      isPrimary: true,
      remoteMethod: async (value) => {
        return fetchSequencesLookup(value);
      },
    },
    {
      key: 'error',
      title: 'Missing information',
      type: 'dropdown',
      cancellable: true,
      searchable: true,
      isPrimary: true,
      options: [
        { label: 'Work email missing', value: 'workEmail' },
        { label: 'No contact details missing', value: 'noContactDetails', },
        { label: 'Personal email missing', value: 'personalEmail' },
      ],
    },
    {
      key: 'dueDate',
      title: 'Date added',
      type: 'relativeDateRange',
      options: [
        { id: 'today', name: 'Today' },
        { id: 'yesterday', name: 'Yesterday' },
        { id: 'thisWeek', name: 'This week' },
        { id: 'lastWeek', name: 'Last week' },
        { id: 'thisMonth', name: 'This month' },
        { id: 'lastMonth', name: 'Last month' },
        { id: 'last3Month', name: 'Last 3 months' },
      ],
      isPrimary: true,
    },
  ];

  useEffect(() => {
    fetchEnrolmentDrafts(paging, filters);
  }, []);

  const enrolmentColumns = useEnrolmentColumns(data, props);

  const onBulkAction = (key) => {
    setShowDeleteModal(false);
    if (key === 'delete') {
      setShowDeleteModal(true);
    } else if (key === 'launch') {
      const enrolments = Object.assign(
        {},
        ...selectedEnrolments.map((enrollment) => ({ [enrollment.id]: enrollment })),
      );
      dispatch(enqueueSequencePersonalize(enrolments));
    }
  };

  const selectedEnrolments = useMemo(() => {
    return rowsSelected.length
      ? data.filter((item, index) => rowsSelected.includes(index)).map((k) => k)
      : [];
  }, [rowsSelected, data]);

  const onRowClick = (index, newIndex) => {
    const rData = data[newIndex.rowIndex];
    setRowsSelected(rData);
  };

  const handleTableChange = ({ pageNo, perPage }) => {
    fetchEnrolmentDrafts(
      {
        pageNo,
        perPage,
      },
      filters,
    );
  };

  const onConfirm = async () => {
    try {
      if (selectedEnrolments && selectedEnrolments.length) {
        setDeleting(true);
        const payload = selectedEnrolments.map((item) => ({
          id: item.id,
        }));
        await bulkDeleteEnrolments(payload);
        setDeleting(false);
        setShowDeleteModal(false);
        setRowsSelected([]);
        fetchEnrolmentDrafts(paging, filters);
      }
    } catch (error) {
      toast.error(error?.error?.message || '');
      setShowDeleteModal(false);
      fetchEnrolmentDrafts(paging, filters);
    }
  };

  const onFilterChange = (key, value) => {
    if (value && typeof value !== 'undefined') {
      if (key === '_search') {
        fetchEnrolmentDrafts(
          {
            ...paging,
            pageNo: 0,
          },
          { ...filters, [key]: value },
        );
      } else {
        fetchEnrolmentDrafts(paging, { ...filters, [key]: value });
      }
    } else {
      delete filters[key];
      fetchEnrolmentDrafts(paging, filters);
    }
  };

  return (
    <>
      <AppGrid
        selectableRows="multiple"
        columns={enrolmentColumns.map((col, index) => ({
          ...col,
          options: enrolmentColumns?.columns?.length
            ? { ...col.options, ...enrolmentColumns?.columns[index] }
            : col.options,
        }))}
        data={data}
        onTableChange={handleTableChange}
        onFilterChange={onFilterChange}
        loading={loading?.enrolmentDrafts || false}
        filters={filterOptions}
        appliedFilters={filters}
        bulkActions={draftActions}
        onBulkAction={onBulkAction}
        options={{
          serverSide: true,
          pagination: true,
          page: paging.pageNo,
          rowsPerPage: paging.perPage,
          count: paging.count,
          selectableRows: 'multiple',
          searchPlaceholder: 'Search Drafts',
          searchText: filters?._search ? filters._search : '',
          search: true,
          rowsSelected: rowsSelected,
          onRowClick: onRowClick,
          onRowsSelect: (rowsSelected, allRows) => {
            setRowsSelected(allRows.map((row) => row.dataIndex));
          },
          onRowSelectionChange: (selected, all) => {
            setRowsSelected(all.map((row) => row.dataIndex));
          },
        }}
      />
      <ConfirmDialog
        title={'Remove drafts'}
        open={showDeleteModal}
        onConfirm={onConfirm}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        loading={deleting}
        buttonTitle="Remove"
        buttonProps={{
          color: 'error',
          variant: 'contained',
        }}
      >
        This action will be applied to {selectedEnrolments.length} selected contacts.
      </ConfirmDialog>
    </>
  );
};

export default EnrolmentsGrid;
