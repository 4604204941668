import { makeStyles } from '@mui/styles';
import { themeColors } from '../../config';

const useStyles = makeStyles(({}) => ({
  root: {
    background: 'transparent',
    color: themeColors.text,
    maxWidth: 345,
  },
  menuPaper: {
    backgroundColor: themeColors.bg,
  },
  menuSection: {
    color: themeColors.yellow,
    position: 'absolute',
    top: '3%',
    right: '8%',
    background: themeColors.bg,
    borderRadius: '10px',
  },
  menuInner: {
    backgroundColor: '#021424',
    width: 'fit-content',
  },
  menuLinks: {
    color: '#F1F1F1 !important',
  },
  template: {
    background: '#19324A',
    padding: '20px',
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
  },
  heading: {
    color: '#D9D9D9',
    fontWeight: '500',
    fontSize: '16px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '200px',
  },
  description: {
    fontWeight: '400',
    fontSize: '12px',
    color: '#FFFFFF',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 6,
  },
  draftBtn: {
    border: '1px solid #F1E579',
    color: '#F1E579',
  },
  btnText: {
    marginRight: '2px',
  },
  alignment: {
    fontWeight: '400',
    fontSize: '12px',
    borderRadius: '5px',
    width: '60%',
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'left',
  },
  completedBtn: {
    border: '1px solid #13DEA1',
    color: '#13DEA1',
  },
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '638px',
    background: '#021424 !important',
    borderRadius: '10px',
    p: 4,
    textAlign: 'center',
    paddingTop: '40px',
    paddingBottom: '40px',
  },
  title: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '23px',
    color: '#EDEDED',
  },
  subtitle: {
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#D2D2D2',
  },
  cancelBtn: {
    background: 'rgba(255, 255, 255, 0.1)',
  },
  deleteAlignment: {
    borderRadius: '3px',
    color: '#FFFFFF !important',
    padding: '18px 36px',
    width: '132px',
    height: '49px',
    margin: '4px',
  },
  dltBtn: {
    background: '#EF5350',
  },
}));

export default useStyles;
