import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Link, useHistory } from 'react-router-dom';
import { Box, Typography, Card, CardContent, Divider, Alert } from '@mui/material';
import { Button, Spacer, Drawer, Modal } from 'src/components/shared';
import { Tabs, InlineEdit } from 'src/components/App';

import TaskDueDateForm from '../../components/Task/TaskDueDateForm';
import TaskRepliesSkeleton from '../../components/Task/TaskRepliesSkeleton';
import TaskReplies from '../../components/TaskReplies';
import ContactDetailsForm from 'src/modules/contacts/components/ContactDetails/ContactDetailsForm';
import { capitalizeFirstLetter } from 'src/utils/helper';

import { capitalizeName } from 'src/modules/contacts/utils/contactUtils';
import { getTaskTypeIcon, getPriorityIcon } from '../../utils/tasksUtils';

import { postContactDetails } from 'src/modules/contacts/actions/contactActions';
import moment from 'moment';
import BounceActions from '../../../sequence/actions/BounceActions';
import { fetchContactDetails, fetchEnrichSearch } from 'src/modules/contacts/api/contactApis';
import { bounceWarningMessage } from '../../config';
import toast from 'src/utils/toast';

const TaskErrorView = ({ currentTab, loader, taskError, task, ...props }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [formType, setFormType] = useState(undefined);
  const [formData, setFormData] = useState(undefined);
  const [bounceEmail, setBounceEmail] = useState(taskError.enrolment.selectedEmailId);
  const [errorMessage, setErrorMessage] = useState('');
  const [showDueDateForm, setShowDueDateForm] = useState(false);
  const [showBounceForm, setShowBounceForm] = useState(false);

  const [loadingEnrich, setLoadingEnrich] = useState(false);

  const history = useHistory();
  const taskTypeList = useSelector((state) => state.app.globals.taskTypeList);
  const taskPriorityList = useSelector((state) => state.app.globals.taskPriorityList);
  const { contact, sequence, sequenceStep, message } = taskError;

  const formRef = useRef();

  useEffect(() => {
    setErrorMessage(message);
    // switch (message) {
    //   case 'workEmail':
    //   case 'RequiredworkEmail':
    //     setErrorMessage('Work email is required!');
    //     break;
    //   case 'personalEmail':
    //   case 'RequiredpersonalEmail':
    //     setErrorMessage('Personal email is required!');
    //     break;
    //   case 'homePhone':
    //     setErrorMessage('Home phone number is required!');
    //     break;
    //   case 'workPhone':
    //     setErrorMessage('Work phone number is required!');
    //     break;
    //   case 'personalPhone':
    //     setErrorMessage('Personal phone number is required!');
    //     break;
    //   case 'linkedin':
    //     setErrorMessage('Linkedin account URL is required!');
    //     break;
    //   case 'instagram':
    //     setErrorMessage('Instagram account URL is required!');
    //     break;
    //   case 'twitter':
    //     setErrorMessage('Twitter account URL is required!');
    //     break;
    //   case 'facebook':
    //     setErrorMessage('Facebook account URL is required!');
    //     break;

    //   default:
    //     setErrorMessage('');
    //     break;
    // }
  }, [message]);

  useEffect(() => {
    props.fetchTaskReplies(
      taskError.task.id,
      { ...props.taskReplies.paging, pageNo: 0 },
      props.taskReplies.filters,
    );
  }, [taskError.task.id]);

  const onTabChange = (tab) => {
    try {
      props.setTasksTab(tab.id);
      history.push(`/tasks/errors`);
    } catch (error) {}
  };

  const getPageTitle = useMemo(() => {
    return props.match.params.type == 'inbox'
      ? 'Inbox'
      : props.match.params.type == 'outbox'
      ? 'Outbox'
      : 'Errors';
  }, [props.match.params]);

  const markTaskComplete = async () => {
    setLoading(true);
    await props.putTask(task.id, {
      status: 'completed',
    });
    setLoading(false);
  };

  const closeDueDateForm = () => {
    setShowDueDateForm(false);
  };

  const closeShowBounceForm = () => {
    setShowBounceForm(false);
  };

  const handleInlineEdit = (data) => {
    return props.putTask(taskError?.task?.id, data);
  };

  const icon = getTaskTypeIcon(task['taskType']);

  // let taskType = taskTypeList.filter((item) => item.id == task['taskType'])[0];
  // if (!taskType || typeof taskType == 'undefined' || taskType === '') {
  //   taskType = 'LinkedIn';
  // }

  let taskType = useMemo(() => {
    let type = '';
    if (taskTypeList && taskTypeList.length) {
      let type = taskTypeList.filter((item) => item.id == task.taskType)[0];
      // if (!type || typeof type == 'undefined' || type === '') {
      //   type = { name: 'LinkedIn' };
      // }
    }
    return type;
  }, [taskTypeList, task]);

  const priorityIcon = getPriorityIcon(task['priority']);

  const addContactDetails = (data, formType) => {
    return new Promise((resolve, reject) => {
      dispatch(postContactDetails(task.contact, formType, data, resolve, reject));
    });
  };

  const openForm = (type, errorMessage, value = undefined) => {
    //
    switch (errorMessage) {
      case 'Personal email is required':
        type = 'email';
        break;
      case 'Phone number is required':
        type = 'phone';
        break;
      case 'Email template selection is required':
        type = 'template';
        break;
      default:
    }
    if (type === 'social') {
      if (value && value !== '') {
        window.open(value, '_blank', 'noopener,noreferrer');
      } else {
        setFormType(type);
      }
    } else {
      if (value) {
        setFormData(value);
      }
      setFormType(type);
    }
  };

  const onFormClose = () => {
    setFormData(undefined);
    setFormType(undefined);
  };

  const isBounceError = () => task.status === 'error';

  const modalTitle = useMemo(() => {
    return formType === 'email'
      ? formData?.id
        ? 'Edit email address'
        : 'Add Email address'
      : formType === 'template'
      ? formData?.id
        ? 'Edit Email Template'
        : 'Select Email Template'
      : formData?.id
      ? 'Edit Phone number'
      : 'Add Phone number';
  }, [formType, formData]);

  const onSubmit = async (data) => {
    if (data?.template && data?.template?.id) {
      props.putTask(task.id, { template: data.template.id });
      onFormClose();
    } else {
      try {
        if (formType === 'social') {
          await updateSocialData(data, contact);
        } else {
          if (formData?.id && formData?.id !== '') {
            await editContactDetails(data);
          } else {
            await addContactDetails(data, formType);
          }
        }
        onFormClose();
      } catch (error) {
        return error;
      }
    }
    props.navigateNextTask();
  };

  const findErrors = async (formType) => {
    try {
      setLoadingEnrich(true);
      const type = formRef?.current?.values?.type || '';
      let payload = {};
      switch (true) {
        case formType === 'email':
          payload = {
            type: !type.includes('Email') ? `${type}Email` : type,
            id: contact.id,
          };
          break;

        case formType === 'phone':
          payload = {
            type: type,
            id: contact.id,
          };
          break;
      }
      if (contact.id) {
        let contactDetails = await fetchContactDetails(contact.id);
        contactDetails = contactDetails.contact;
        let linkedin = contactDetails.socialLinks.filter((item) => item.type === 'linkedin');
        if (linkedin && linkedin.length) {
          const pathname = new URL(linkedin[0].link).pathname;
          payload = {
            ...payload,
            link: pathname,
          };
        }

        const searchResult = await fetchEnrichSearch([payload]);
        const workEmail =
          searchResult?.contacts[0]?.emails?.length > 0
            ? searchResult.contacts[0].emails.find((element) => {
                return element.type === 'work';
              })?.email
            : '';

        const personalEmail =
          searchResult?.contacts[0]?.emails?.length > 0
            ? searchResult.contacts[0].emails.find((element) => {
                return element.type === 'personal';
              })?.email
            : '';

        const phone =
          searchResult?.contacts[0]?.phones?.length > 0
            ? searchResult.contacts[0].phones[0]?.phone?.number
            : '';

        const linkedIn = searchResult?.contacts[0]?.linkedin_url || '';

        switch (formType) {
          case 'email':
            setFormData({
              ...formData,
              email: workEmail,
            });
            break;
          case 'personal_email':
            setFormData({
              ...formData,
              email: personalEmail,
            });
            break;
          case 'phone':
            setFormData({
              ...formData,
              phone,
            });
            break;
          case 'linkedin':
            setFormData({
              ...formData,
              linkedin: linkedIn,
            });
            break;
        }
        toast.success(
          `${
            ['email', 'personal_email']?.includes(formType)
              ? workEmail || personalEmail
                ? `${formType === 'email' ? 'Work email' : 'Personal email'} fetched succesfully`
                : `No ${formType === 'email' ? 'work email' : 'personal email'} found`
              : ['phone']?.includes(formType)
              ? phone
                ? 'Phone number fetched succesfully'
                : 'No phone number found'
              : linkedIn
              ? 'LinkedIn fetched succesfully'
              : 'No linkedIn profile found'
          }`,
        );
      }
      setLoader(false);
      setLoadingEnrich(false);
    } catch (error) {
      toast.error(error?.error?.message || 'Error ocurred! Please try again.');
      setLoadingEnrich(false);
    }
  };

  const onNextBounceError = ({ email, type }) => {
    onFormClose();
    setShowBounceForm(true);
    setBounceEmail(email);
  };

  const onSubmitBounceNext = async (type) => {
    await props.setReEnrolment(taskError.enrolment.id, {
      reenroll: type,
      email: bounceEmail,
    });
    await setShowBounceForm(false);
    history.push(`/tasks/errors`);
  };

  return (
    <>
      {/* <Tabs tabs={taskTabs} onChange={onTabChange} value={currentTab} />*/}
      <Spacer y={10} />
      <Card>
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box display="flex" justifyContent="center">
            <Box display="grid" alignItems="center">
              <Typography component="span" variant="h2" color="textPrimary">
                {capitalizeName(task.taskType)} Task Details
              </Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent="center" spacing={4}>
            <Box display="grid" alignItems="center">
              <Typography component="span" color="textSecondary" fontWeight="500">
                STATUS
              </Typography>
              <Box display="grid" alignItems="center">
                {/* <InlineEdit
                  key="status"
                  type="select"
                  value={capitalizeFirstLetter(props.task.status) || '-'}
                  options={taskPriorityList}
                /> */}

                <Spacer x={1} y={1} />
                <Typography component="span" color="textSecondary">
                  {capitalizeFirstLetter(task.status) || '-'}
                </Typography>
              </Box>
            </Box>
            <Spacer x={3} y={1} />
            <Box display="grid" alignItems="center">
              <Typography component="span" color="textSecondary" fontWeight="500">
                PRIORITY
              </Typography>
              <Box display="grid" alignItems="center">
                <Spacer x={1} y={1} />
                {task.priority ? (
                  <InlineEdit
                    fieldKey="priority"
                    type="select"
                    value={task.priority || '-'}
                    options={taskPriorityList}
                    optLabel="name"
                    optValue="id"
                    label="Priority"
                    onSubmit={handleInlineEdit}
                    isBold={false}
                    showAction={false}
                  />
                ) : (
                  <Typography component="span" color="textSecondary">
                    {'-'}
                  </Typography>
                )}
              </Box>
            </Box>
            {task.assignedTo && task.assignedTo?.id ? (
              <>
                {/* <FiberManualRecordIcon sx={{ fontSize: '10px', fill: '#999999' }} /> */}
                <Spacer x={3} y={1} />
                <Box display="grid" alignItems="center">
                  <Typography component="span" color="textSecondary" fontWeight="500">
                    ASSIGNEE
                  </Typography>
                  <Spacer x={3} y={1} />
                  <Typography component="span" color="textSecondary">
                    {`${capitalizeFirstLetter(
                      task.assignedTo?.fname || '-',
                    )} ${capitalizeFirstLetter(task.assignedTo?.lname)}`}
                  </Typography>
                  {/* <Box display="grid" alignItems="center">
                    <Spacer x={3} y={1} />
                    <InlineEdit
                      fieldKey="dueOn"
                      type="date"
                      value={props.task.dueOn}
                      label="Due date"
                      onSubmit={handleInlineEdit}
                      isBold={false}
                    />
                  </Box> */}
                </Box>
              </>
            ) : null}
            <Spacer x={4} y={1} />
            <Box display="grid" alignItems="center">
              <Typography component="span" color="textSecondary" fontWeight="500">
                DUE DATE
              </Typography>
              <Box display="grid" alignItems="center">
                <Spacer x={1} y={1} />
                <InlineEdit
                  fieldKey="scheduledOn"
                  type="date"
                  value={task?.scheduledOn || moment(Date.now())}
                  label="Due date"
                  onSubmit={handleInlineEdit}
                  isBold={false}
                  showAction={false}
                />
              </Box>
            </Box>
          </Box>
        </CardContent>

        <Divider />

        <CardContent>
          <Box display="flex" alignItems="center">
            <Typography color="textSecondary">Sequence : </Typography>
            <Spacer x={1} y={1} />
            <Link to={`/sequence/${sequence.id}`}>
              <Typography color="secondary">{sequence?.name}</Typography>
            </Link>
          </Box>
        </CardContent>

        <Divider />
        {/* {contact && !isNull(contact) && !isEmpty(contact) ? (
          <CardContent>
            <TaskContact contact={contact} />
          </CardContent>
        ) : null} 
        <Divider />*/}

        <CardContent>
          <Alert
            severity="warning"
            style={{ cursor: 'pointer' }}
            onClick={() => openForm('email', errorMessage)}
          >
            {errorMessage}
          </Alert>
        </CardContent>

        {task['taskType'] == 'email' ? (
          <>
            {loader.taskReplies === true ? (
              <TaskRepliesSkeleton />
            ) : (
              <TaskReplies
                showError={false}
                isTaskError={true}
                showForm={true}
                task={{
                  ...task,
                  contact,
                }}
                {...props}
              />
            )}
          </>
        ) : null}
      </Card>

      <Spacer y={3} />
      <Modal
        open={formType === 'email' || formType === 'phone' || formType === 'template'}
        onClose={onFormClose}
        title={isBounceError() ? bounceWarningMessage : modalTitle}
        size="xs"
      >
        <ContactDetailsForm
          formType={formType}
          formData={isBounceError() ? { email: bounceEmail } : formData}
          showOnlyEmail={isBounceError() ? true : false}
          onSubmit={isBounceError() ? onNextBounceError : onSubmit}
          onClose={onFormClose}
          submitButtonText={isBounceError() ? 'Next' : 'Save'}
          findErrors={findErrors}
          loader={loadingEnrich}
          formRef={formRef}
        />
      </Modal>
      <Modal
        open={showDueDateForm}
        onClose={closeDueDateForm}
        backdrop={false}
        title="Change due date"
      >
        <TaskDueDateForm task={task} onClose={closeDueDateForm} putTask={props.putTask} />
      </Modal>
      <Modal
        open={showBounceForm}
        onClose={closeShowBounceForm}
        backdrop={false}
        title={'How you would like to proceed?'}
      >
        <BounceActions onClick={onSubmitBounceNext} />
      </Modal>
    </>
  );
};

export default TaskErrorView;
