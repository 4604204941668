import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import VideoFrame from '../VideoFrame';
import { getIFrameCode } from 'src/modules/lms/utils/helper';
import { sendScore } from 'src/modules/lms/api/LMSApis';
import { useSelector } from 'react-redux';
import dispatchEvent from '../customEvent';

const SessionRoleplay = ({
  rolePlayModal,
  embedURL,
  session,
  competition,
  setRolePlayModal,
  report,
  fetchScore,
}) => {
  const ref = useRef(null);
  const userObj = useSelector((state) => state.auth.user);

  const history = useHistory();

  // to post the score and feedback recieved from the bot
  const postScore = async (data) => {
    let configId = ref.current;
    let newData = { ...data, configId, session: session?.id, user: userObj.id };
    newData.score = newData.score?.toFixed(2);
    delete newData['roleplay_finished'];
    if (competition?.id) {
      newData.competition = competition?.id;
    }
    delete newData['techniques_score'];
    await sendScore(newData);
  };
  const handleClose = () => {
    setRolePlayModal(false);
    let userId = [{ value: userObj.id }];
    let sessionId = [{ value: session.id }];
    fetchScore(userId, sessionId);
    if (history?.location?.search && history?.location?.search !== '') {
      history.replace(history.location.pathname);
    }
  };

  // event handling for the bot
  function eventFunction(event) {
    if (event.origin === `${process.env.REACT_APP_BOT_URL}`) {
      try {
        if (event?.data) {
          let data = JSON.parse(event?.data);
          if (data.type === 'init') {
            ref.current = data.data.prompt_config._id;
            let sessionData = {
              id: session?.id,
              title: session?.title,
              competitions: competition?.id ? [competition] : [],
              report,
              user: {
                id: userObj?.id,
                username: userObj?.id,
                fname: userObj?.fname,
                lname: userObj?.lname,
                title: userObj?.title,
              },
            };
            dispatchEvent('session', sessionData);
          }
          if (data?.type === 'feedback') {
            postScore(data.data);
          }
          if (data.type === 'close') {
            handleClose();
          }
          if (data.type === 'home') {
            handleClose();
            history.replace('/lms');
          }
        }
      } catch (err) {}
    }
    return;
  }

  useEffect(() => {
    // this code is to get messages from the iframe to this screen
    if (ref.current === null) {
      ref.current = window.addEventListener('message', eventFunction, false);
    }
    return () => {
      // remove this event function from window
      window.removeEventListener('message', eventFunction);
      ref.current = null;
    };
  }, [session]);

  if (!embedURL || embedURL == '') {
    return null;
  }

  return (
    <VideoFrame
      isFullScreen
      VideoUrl={getIFrameCode(embedURL, session, competition)}
      open={rolePlayModal}
      handleClose={handleClose}
      handleOpen={() => setRolePlayModal(true)}
    />
  );
};

export default SessionRoleplay;
