import { themeColors } from '../../config';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({ palette }) => ({
  title: {
    color: '#FFD47E',
    marginLeft: '14px',
    marginBottom: '6px',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginLeft: '10px',
    },
    '& svg': {
      marginLeft: 'auto',
      cursor: 'pointer',
    },
    '& p': {
      marginLeft: '10px',
      marginRight: '10px',
      color: '#E6E6E6',
      fontSize: 12,
      fontWeight: 500,
    },
    '& span': {
      color: '#fff',
    },
  },
  heading: {
    color: '#E6E6E6',
    fontSize: '14px',
    fontWeight: 500,
  },
  container: {
    background: themeColors.bg,
    color: 'white',
    padding: '1px',
    paddingTop: '10px',
    borderRadius: '5px',
    // marginBottom: '24px',
    '& button': {
      color: '#7F7F7F',
      background: 'transparent',
      border: '1px solid #7F7F7F',
      fontWeight: '400',
      fontSize: '12px',
      marginLeft: '10px',
      marginBottom: '10px',
    },
  },
  img: {
    padding: '20px',
    width: '90%',
  },
  feedback: {
    position: 'absolute',
    top: '72%',
    background: '#021424',
    width: '135px',
    height: '27px',
    borderRadius: '3px',
    left: '25%',
    display: 'flex',
    padding: '20px',
    textAlign: 'center',
  },
  feedbackText: {
    fontSize: '12px',
    marginLeft: '8%',
    marginTop: '3%',
  },

  progressBar: {
    width: '6px',
    height: '100px',
    cursor: 'pointer',
    margin: '0 5px',
    backgroundColor: 'rgba(0, 0, 0, 1)',
    boxShadow: '0 0 3px #000000 inset, 0 0 2px rgba(255,255,255,0.1)',
    borderRadius: '10px',

    transform: 'rotate(180deg)',
    display: 'inline-block',

    '& .inner': {
      borderRadius: '10px',
      height: '50px',
      position: 'absolute',
      width: '6px',
    },
    '& .red': {
      background: 'rgba(255, 64, 64, 1)',
    },
    '& .orange': {
      background: 'rgba(255, 143, 64, 1)',
    },
    '& .green': {
      background: 'rgba(96, 255, 64, 1)',
    },
    '& span': {
      position: 'absolute',
      font: 'bold  20px/30px sans-serif',
      letterSpacing: ' -6px',
      height: '100px',
      width: '10px',
      opacity: '0.06',
      overflow: 'hidden',
      transform: 'skewY(30deg)',
    },
  },
  score: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginRight: '20px',
  },
  scoreP: {
    margin: '0px',
  },
  scoreContainer: {
    display: 'flex',
    padding: '10px',
    margin: '10px 0',
  },
}));
export default useStyles;
