import React, { useState, useEffect, useMemo } from 'react';
import { Card, CardContent, Typography, Container, Box } from '@mui/material';
import { Button, Spacer } from 'src/components/shared';
import { useWindowDimensions, useViewport } from 'src/components/hooks';
import { Responsive, WidthProvider } from 'react-grid-layout';
const ResponsiveGridLayout = WidthProvider(Responsive);
import images from 'src/config/images';

import SingleCard from './Report/SingleCard';
import Grid from './Report/Grid';
import BarChart from './Report/BarChart';
import LineChart from './Report/LineChart';
import PieChart from './Report/PieChart';
import Iframe from './Report/Iframe';

import { isEqual } from 'lodash';
import { EmptyWrap, EmptyIcon } from './styles';
import AddIcon from '@mui/icons-material/Add';

function Board({
  layouts,
  widgets,
  updateBoardLayout,
  toggleWidgetsModal,
  toggleTemplatesModal,
  ...props
}) {
  // const [boardWidgets, setBoardWidgets] = useState([]);
  const viewport = useViewport();

  useEffect(() => {
    setTimeout(() => {
      props.handleBoardResize(viewport);
    }, 100);
  }, [viewport]);

  // useEffect(() => {
  //   const widgetsArr = Object.keys(widgets).map((key) => {
  //     return widgets[key];
  //   });
  //   setBoardWidgets(widgetsArr);
  // }, [widgets]);

  const boardWidgets = useMemo(() => {
    return Object.keys(widgets).map((key) => {
      return widgets[key];
    });
  }, [widgets, viewport]);

  const generateDOM = () => {
    return _.map(boardWidgets, function (item, i) {
      const data = {
        ...item,
        title:
          item?.key === 'prospectsOnHold'
            ? 'Prospects on Hold'
            : item?.key === 'prospectsInError'
            ? 'Prospects in Error'
            : item?.key === 'prospectInSequence'
            ? 'Prospects Currently in Sequence'
            : item?.key === 'prospectsClicked'
            ? 'Emails Clicked'
            : item?.key === 'prospectsOpened'
            ? 'Emails Opened'
            : item?.key === 'prospectsUnsub'
            ? 'Unsubscribes'
            : item?.key === 'sequencesReport'
            ? 'Sequence Activity'
            : item?.key === 'emailsReport'
            ? 'Email Performance'
            : item?.key === 'activityFeedUserProspect'
            ? 'Live Activity Feed'
            : item?.title,
      };
      return (
        <Card key={item?.i || i}>
          {item?.type === 'singleCard' ? (
            <SingleCard data={data} />
          ) : item?.type === 'grid' ? (
            <Grid data={data} />
          ) : item?.type === 'barChart' ? (
            <BarChart data={data} />
          ) : item?.type === 'lineGraph' ? (
            <LineChart data={data} />
          ) : item?.type === 'pieChart' ? (
            <PieChart data={data} />
          ) : item?.type === 'iframe' ? (
            <Iframe data={data} />
          ) : null}
        </Card>
      );
    });
  };

  const handleBreakPointChange = (event, data) => {};

  const onLayoutChange = (layout, layouts, type) => {
    const comp1 = layout.map((widget) => ({
      w: widget.w,
      h: widget.h,
      x: widget.x,
      y: widget.y,
    }));
    const comp2 = layouts.lg.map((widget) => ({
      w: widget.w,
      h: widget.h,
      x: widget.x,
      y: widget.y,
    }));
    const ditto = isEqual(comp1, comp2);
    if (!ditto) {
      updateBoardLayout(layout, viewport);
    }
  };

  if (!boardWidgets.length) {
    return (
      <EmptyWrap>
        <EmptyIcon src={images.app.emptyBoard} />
        <Typography variant="h3" color="textSecondary">
          Add widget to the board to get started
        </Typography>
        <Spacer x={3} y={3} />
        <Box display="flex">
          <Button
            variant="outlined"
            color="secondary"
            onClick={toggleWidgetsModal}
            startIcon={<AddIcon />}
          >
            Add Widget
          </Button>
          <Spacer x={2} y={2} />
          <Button variant="contained" color="secondary" onClick={toggleTemplatesModal}>
            Use a template
          </Button>
        </Box>
      </EmptyWrap>
    );
  }

  return (
    <>
      <ResponsiveGridLayout
        className="layout"
        layouts={{ lg: boardWidgets }}
        onBreakpointChange={handleBreakPointChange}
        onLayoutChange={onLayoutChange}
        compactType="horizontal"
        verticalCompact={true}
        horizontalCompact={true}
        measureBeforeMount={true}
        // isDraggable
        // isRearrangeable
        // isResizable
        // draggableHandle=".grid-item__title"
        breakpoints={{ lg: 1280, md: 992, sm: 767, xs: 480, xxs: 0 }}
        cols={{ lg: 6, md: 6, sm: 4, xs: 2, xxs: 1 }}
        // cols={6}
        rowHeight={180}
        width={1200}
      >
        {generateDOM()}
      </ResponsiveGridLayout>
    </>
  );
}

export default Board;
