import React from 'react';
import { Box, ButtonBase, Card, CardContent, Typography } from '@mui/material';
import styled from 'styled-components';
import MissingImage from 'src/modules/lms/shared/MissingImage';
import { history } from 'src/store';
import useStyles from './sessionDetail/styles';
import { Spacer } from 'src/components/shared';

function NextSessions({ session }) {
  const otherSessions = session?.otherSessions || [];
  const classes = useStyles();

  const handleClick = (ss) => {
    history.push(`/lms/session/${ss.id}`);
  };

  return (
    <>
      {otherSessions?.length ? (
        <Card className={classes.cardContainer}>
          <CardContent>
            <Typography variant="body2" color="textPrimary">
              Your next sessions
            </Typography>
            <Spacer x={1.5} y={1.5} />
            {otherSessions?.map((ss, index) => (
              <Button key={`next-session-${index}`} mt={1} mb={1} onClick={() => handleClick(ss)}>
                {ss.thumbnailImage && ss.thumbnailImage !== '' ? (
                  <Thumb src={ss.thumbnailImage} />
                ) : (
                  <Placeholder>
                    <MissingImage />
                  </Placeholder>
                )}
              </Button>
            ))}
          </CardContent>
        </Card>
      ) : null}
    </>
  );
}

export default NextSessions;

const Button = styled(ButtonBase)`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  aspect-ratio: 349/209;
  height: auto;
`;

const Thumb = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
`;

const Placeholder = styled.div`
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #193247;
  border-radius: 4px;
  cursor: pointer;
`;
