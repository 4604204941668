import { all, fork, put, call, takeEvery, takeLatest, select, delay } from 'redux-saga/effects';
import * as noteTypes from '../actions/noteTypes';
import * as noteApi from '../api/noteApi';
import * as noteActions from '../actions/noteActions';
import { convertObjectToQuerystring } from 'src/utils/helper';

function* fetchSessionsList({ paging, filters, sort, resolve, reject }) {
  try {
    let filter = {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      ...(filters?._search ? { _search: filters?._search } : {}),
      ...(filters?.addedby ? { createdBy: filters?.addedby?.id } : {}),
      ...(filters?.status ? { published_eq: filters?.status?.value } : {}),
    };
    filter = `?${convertObjectToQuerystring(filter)}`;
    const response = yield call(noteApi.getSessionList, filter);
    yield put(noteActions.setSessionsList(response));
    resolve(true);
  } catch (error) {
    yield put(noteActions.setSessionsList({ session: [], total: { value: 0 } }));
    reject(false);
  }
}

export function* watchSagas() {
  yield takeLatest(noteTypes.FETCH_SESSIONS_LIST, fetchSessionsList);
}

export default function* runSagas() {
  yield all([fork(watchSagas)]);
}
