import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import TaskErrorView from './TaskErrorView';
import { PageTitle } from 'src/components/App';
import {
  fetchTaskReplies,
  fetchTask,
  setTasksTab,
  putTask,
  sendTaskReply,
  navigateNextTask,
  setTaskErrorDetails,
} from '../../actions/taskActions';
import {
  fetchContact,
  fetchContactTask,
  fetchContactNote,
  fetchSequenceEnrollment,
  putContactEmail,
} from 'src/modules/contacts/actions/contactActions';
import { toggleAddTaskForm, toggleContactDrawer } from 'src/modules/app/actions/appActions';
import { ContactSidebar, FixedTitleBar, ContactMainView } from '../task/styles';
import { Box, Typography, Card, CardContent, Divider, Tooltip, Chip, Paper } from '@mui/material';
import TaskContactHead from '../../components/Task/TaskContactHead';
import Contact from 'src/modules/contacts/components/Contact';
import { setReEnrolmentErrors } from 'src/modules/sequence/actions/enrolmentActions';
export const TaskContainer = (props) => {
  const task = props.taskError?.task || {};
  useEffect(() => {
    if (task?.contact) {
      props.fetchContact(task.contact);
      props.fetchContactTask(task.contact);
      props.fetchContactNote(task.contact);
      props.fetchSequenceEnrollment(task.contact);
    }
  }, [task.contact]);

  return (
    <>
      <PageTitle title="Task Error Details" />
      <FixedTitleBar>
        {/* {props.task.contact && !isNull(props.task.contact) && !isEmpty(props.task.contact) ? ( */}
        <CardContent>
          <TaskContactHead
            contact={props.task?.contact}
            isLoading={props.isLoading}
            {...props}
            type="errors"
          />
        </CardContent>
        {/* ) : null} */}
      </FixedTitleBar>
      <ContactSidebar>
        <Contact
          contact={props?.contactnew}
          sequenceEnrollment={props.sequenceEnrollment}
          isLoading={props.isLoading}
          showBack={false}
        />
      </ContactSidebar>
      <ContactMainView>
        <TaskErrorView {...props} task={task} contact={props.contactnew} />
      </ContactMainView>
    </>
  );
};

const mapStateToProps = (state) => ({
  contactnew: state.contacts.contact,
  contactTask: state.contacts.contactTask,
  contactNote: state.contacts.contactNote,
  sequenceEnrollment: state.contacts.sequenceEnrollment,
  isLoading: state.contacts.loading.contact,
  currentTab: state.tasks.currentTab,
  taskReplies: state.tasks.taskReplies,
  user: state.auth.user,
  loader: state.tasks.loading,
  task: state.tasks.taskError,
  taskError: state.tasks.taskError,
});

const mapDispatchToProps = (dispatch) => ({
  fetchContact: (contactId) => dispatch(fetchContact(contactId)),
  fetchContactTask: (contactId) => dispatch(fetchContactTask(contactId)),
  fetchContactNote: (contactId) => dispatch(fetchContactNote(contactId)),
  fetchSequenceEnrollment: (contactId) => dispatch(fetchSequenceEnrollment(contactId)),
  setTaskErrorDetails: (data) => dispatch(setTaskErrorDetails(data)),
  // navigateNextTask: () => dispatch(navigateNextTask('errors')),
  navigateNextTask: () => {
    return new Promise((resolve, reject) => {
      dispatch(navigateNextTask({ view: 'errors', resolve, reject }));
    });
  },
  toggleContactDrawer: (contactId) => dispatch(toggleContactDrawer(contactId)),
  toggleAddTaskForm: (task = {}) => dispatch(toggleAddTaskForm(task)),
  setTasksTab: (tab) => dispatch(setTasksTab(tab)),
  fetchTask: (taskId) => dispatch(fetchTask(taskId)),
  fetchTaskReplies: (taskId, paging, filters) =>
    dispatch(fetchTaskReplies(taskId, paging, filters)),
  putTask: (id, task) => {
    return new Promise((resolve, reject) => {
      dispatch(putTask(id, task, resolve, reject));
    });
  },
  setReEnrolment: (id, data) => {
    return new Promise((resolve, reject) => {
      dispatch(setReEnrolmentErrors(id, data, resolve, reject));
    });
  },
  sendTaskReply: (data) => {
    return new Promise((resolve, reject) => {
      dispatch(sendTaskReply(data, resolve, reject));
    });
  },
  putContactEmail: (contactId, taskId, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(putContactEmail(contactId, taskId, payload, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskContainer);
