import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import { Box, Typography, ButtonBase } from '@mui/material';
import { Spacer, Button } from 'src/components/shared';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export const CardContainer = styled(Paper).attrs({
  elevation: 2,
})`
  width: 100%;
  margin: 0 auto;
`;

export const CardHeader = styled(Box).attrs({})`
  display: flex;
  padding: 8px 20px;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${(props) =>
    props.renderborder === 'true' ? '1px solid rgba(0, 0, 0, 0.12)' : 'none'};
`;

export const CardContent = styled(Box).attrs({})`
  padding: 16px 20px;
`;

export const Left = styled(Box).attrs({})`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const Right = styled(Box).attrs({})`
  display: flex;
  align-items: center;
`;

const iconButtonStyle = {
  // color: 'rgba(0, 0, 0, 0.26)',
  // ':hover': {
  //   color: '#031729',
  // },
  opacity: 0.6,
};

export const EventCard = ({
  icon,
  title,
  subTitle,
  showEdit,
  showDelete,
  customAction,
  ...props
}) => {
  return (
    <CardContainer>
      <CardHeader renderborder={props.children ? 'true' : 'false'}>
        <Left>
          {icon && (
            <>
              {icon}
              <Spacer x={1} />
            </>
          )}
          <Box display="flex">
            <Typography
              align={!icon ? 'center' : 'left'}
              variant="h4"
              color="textPrimary"
              style={{ flex: 1 }}
            >
              {title}
            </Typography>
            {subTitle && subTitle !== '' ? (
              <>
                <Spacer x={1} y={1} />
                <Typography variant="h4" color="textSecondary" variant="body2">
                  {subTitle}
                </Typography>
              </>
            ) : null}
          </Box>
        </Left>
        <Right>
          {customAction ? customAction() : null}
          {showEdit && (
            <Button iconButton onClick={props.onEdit} color="primary">
              <EditIcon style={iconButtonStyle} />
            </Button>
          )}

          {showDelete && (
            <Button iconButton onClick={props.onDelete} color="primary">
              <DeleteIcon style={iconButtonStyle} />
            </Button>
          )}
        </Right>
      </CardHeader>
      {props.children && <CardContent>{props.children}</CardContent>}
    </CardContainer>
  );
};
EventCard.propTypes = {
  showEdit: PropTypes.bool,
  showDelete: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  fullWidth: PropTypes.bool,
  customAction: PropTypes.func,
};
EventCard.defaultProps = {
  showEdit: false,
  showDelete: false,
  onEdit: () => {},
  onDelete: () => {},
  fullWidth: true,
  customAction: () => {},
};
