import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { PageTitle } from 'src/components/App';
import OnboardingView from './OnboardingView';

import { fetchOnboardingStatus, putOnboardingStatus } from '../../actions/appActions';
import { getContactFields } from 'src/modules/app/actions/appSelector';
import {
  toggleAddContactForm,
  toggleAddTaskForm,
  toggleAddSequenceForm,
} from 'src/modules/app/actions/appActions';

export const OnboardingContainer = (props) => {
  useEffect(() => {
    props.fetchOnboardingStatus();
  }, []);

  return (
    <>
      <PageTitle title="Onboarding" />
      <OnboardingView {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  onboarding: state.app.onboarding,
  showContactForm: state.app.showContactForm,
  fields: getContactFields(state),
});

const mapDispatchToProps = (dispatch) => ({
  toggleAddContactForm: () => dispatch(toggleAddContactForm()),
  toggleAddTaskForm: () => dispatch(toggleAddTaskForm()),
  toggleAddSequenceForm: () => dispatch(toggleAddSequenceForm()),

  fetchOnboardingStatus: () => dispatch(fetchOnboardingStatus()),
  putOnboardingStatus: (key, value) => {
    return new Promise((resolve, reject) => {
      dispatch(putOnboardingStatus(key, value, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingContainer);
