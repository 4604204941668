import * as types from '../actions/resellerTypes';
import { RESET_STORE } from 'src/modules/app/actions/appTypes';

const initialState = {
  loading: {
    automations: false,
    resellersReport: false,
    resellersTenantReport: false,
    resellersUsersReport: false,
    resellersReportSummary: false,
    resellersTenantReportSummary: false,
    resellersUsersReportSummary: false,
  },
  automations: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    sort: '',
    data: [],
  },
  resellersReport: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    sort: '',
    data: [],
  },
  resellersTenantReport: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    sort: '',
    data: [],
  },
  resellersUsersReport: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    sort: '',
    data: [],
  },
  resellerFilters: {},
  resellersReportSummary: {},
  resellersTenantReportSummary: {},
  resellersUsersReportSummary: {},
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_RESELLER_FILTERS:
      return {
        ...state,
        resellerFilters: action.filters,
      };

    case types.FETCH_AUTOMATIONS:
      return {
        ...state,
        loading: {
          ...state.loading,
          automations: true,
        },
        automations: {
          ...state.automations,
          data: [],
          paging: {
            ...state.automations.paging,
            ...action.paging,
          },
          filters: {
            ...state.automations.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };

    case types.SET_AUTOMATIONS:
      return {
        ...state,
        loading: {
          ...state.loading,
          automations: false,
        },
        automations: {
          ...state.automations,
          data: action.data.automations,
          paging: {
            ...state.resellersReport.paging,
            count: action.data?.total?.value,
          },
        },
      };

    case types.FETCH_RESELLER_TENANT_REPORT_SUMMARY:
      return {
        ...state,
        loading: {
          ...state.loading,
          resellersTenantReportSummary: true,
        },
        resellersTenantReportSummary: {},
      };

    case types.SET_RESELLER_TENANT_REPORT_SUMMARY:
      return {
        ...state,
        loading: {
          ...state.loading,
          resellersTenantReportSummary: false,
        },
        resellersTenantReportSummary: action.data,
      };

    case types.FETCH_RESELLER_REPORT_SUMMARY:
      return {
        ...state,
        loading: {
          ...state.loading,
          resellersReportSummary: true,
        },
        resellersReportSummary: {},
      };

    case types.SET_RESELLER_REPORT_SUMMARY:
      return {
        ...state,
        loading: {
          ...state.loading,
          resellersReportSummary: false,
        },
        resellersReportSummary: action.data,
      };

    case types.FETCH_RESELLER_USER_REPORT_SUMMARY:
      return {
        ...state,
        loading: {
          ...state.loading,
          resellersUsersReportSummary: true,
        },
        resellersUsersReportSummary: {},
      };

    case types.SET_RESELLER_USER_REPORT_SUMMARY:
      return {
        ...state,
        loading: {
          ...state.loading,
          resellersUsersReportSummary: false,
        },
        resellersUsersReportSummary: action.data,
      };

    case types.FETCH_RESELLER_REPORT:
      return {
        ...state,
        loading: {
          ...state.loading,
          resellersReport: true,
        },
        resellersReport: {
          ...state.resellersReport,
          data: [],
          paging: {
            ...state.resellersReport.paging,
            ...action.paging,
          },
          filters: {
            ...state.resellersReport.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };

    case types.SET_RESELLER_REPORT:
      return {
        ...state,
        loading: {
          ...state.loading,
          resellersReport: false,
        },
        resellersReport: {
          ...state.resellersReport,
          data: action.data.resellers,
          paging: {
            ...state.resellersReport.paging,
            count: action.data?.total?.value,
          },
        },
      };

    case types.FETCH_RESELLER_TENANT_REPORT:
      return {
        ...state,
        loading: {
          ...state.loading,
          resellersTenantReport: true,
        },
        resellersTenantReport: {
          ...state.resellersTenantReport,
          data: [],
          paging: {
            ...state.resellersTenantReport.paging,
            ...action.paging,
          },
          filters: {
            ...state.resellersTenantReport.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };

    case types.SET_RESELLER_TENANT_REPORT:
      return {
        ...state,
        loading: {
          ...state.loading,
          resellersTenantReport: false,
        },
        resellersTenantReport: {
          ...state.resellersTenantReport,
          data: action.data.users,
          paging: {
            ...state.resellersTenantReport.paging,
            count: action.data?.total?.value,
          },
        },
      };

    case types.FETCH_RESELLER_USER_REPORT:
      return {
        ...state,
        loading: {
          ...state.loading,
          resellersUsersReport: true,
        },
        resellersUsersReport: {
          ...state.resellersUsersReport,
          data: [],
          paging: {
            ...state.resellersUsersReport.paging,
            ...action.paging,
          },
          filters: {
            ...state.resellersUsersReport.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };

    case types.SET_RESELLER_USER_REPORT:
      return {
        ...state,
        loading: {
          ...state.loading,
          resellersUsersReport: false,
        },
        resellersUsersReport: {
          ...state.resellersUsersReport,
          data: action.data.contacts,
          paging: {
            ...state.resellersUsersReport.paging,
            count: action.data?.total?.value,
          },
        },
      };

    case RESET_STORE:
      return initialState;

    default:
      return state;
  }
};
export default AppReducer;
