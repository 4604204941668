import React from 'react';
import { Typography, Card, CardContent, Box, Grid, Stack, Skeleton } from '@mui/material';
import { RadialBarChart, PieChart, Pie, Cell, RadialBar, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import { Spacer } from 'src/components/shared';
import { Loader } from 'src/components/App';

const colors = ['#FF9800', '#4789C2', '#9C27B0', '#3949AB', '#2E7D32'];

const ReportCard = ({ total, title, value, percentage, fill, loading, type = 'graph' }) => {
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '125px',
  };
  const textStyles = {
    fontSize: '28px',
  };
  const CustomizedLabel = (props) => {
    return (
      <g>
        <foreignObject x={0} y={0} width={125} height={125}>
          <body>
            <div style={style}>
              <Typography sx={{ ...textStyles, color: fill }}>{percentage || 0}%</Typography>
            </div>
          </body>
        </foreignObject>
      </g>
    );
  };

  if (loading)
    return (
      <Grid item xs={6} md={4} lg={2}>
        <Card
          sx={{
            display: 'flex',
            width: '100%',
            height: '125px',
            background: fill || '#ffffff',
            boxSizing: 'border-box',
          }}
        >
          <Stack gap={1} alignItems="center" justifyContent="center" width="100%">
            <Skeleton animation="wave" height={40} width={'20%'} />
            <Skeleton animation="wave" height={20} width={'60%'} />
          </Stack>
        </Card>
      </Grid>
    );

  if (type === 'text') {
    return (
      <Grid item xs={6} md={4} lg={2}>
        <Card
          sx={{
            display: 'flex',
            width: '100%',
            height: '125px',
            background: fill || '#ffffff',
            boxSizing: 'border-box',
          }}
        >
          <Stack gap={1} alignItems="center" justifyContent="center" width="100%">
            <Typography
              sx={{
                color: fill ? '#ffffff' : 'rgba(0,0,0,0.87)',
                fontSize: '32px',
                fontStyle: 'normal',
              }}
            >
              {value}
            </Typography>
            <Typography variant="body2" sx={{ color: fill ? '#ffffff' : 'rgba(0,0,0,0.87)' }}>
              {title}
            </Typography>
          </Stack>
        </Card>
      </Grid>
    );
  }

  const progressData = [
    {
      name: '',
      value: 100,
    },
    {
      name: 'Progress',
      value: parseInt(percentage),
    },
  ];

  return (
    <Grid item xs={6} md={4} lg={2}>
      <Card
        sx={{
          display: 'flex',
          width: '100%',
          height: '125px',
          background: fill || '#ffffff',
          boxSizing: 'border-box',
        }}
      >
        <Stack gap={1} alignItems="center" justifyContent="center" width="100%">
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            justifyContent="space-between"
            width="80%"
          >
            <ResponsiveContainer style={{ flex: 1 }} width={60} height={60}>
              <PieChart width={60} height={60}>
                <Pie
                  data={progressData}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  innerRadius={20}
                  outerRadius={28}
                  labelLine={false}
                  fill="#00000040"
                  label={false}
                  startAngle={90}
                  endAngle={-270}
                  stroke="none"
                >
                  {progressData
                    .filter((item) => item.name !== 'Progress')
                    .map((entry, index) => (
                      <>
                        <Cell key={`cell-${index}`} fill="#ffffff25" />
                      </>
                    ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            <Stack flex={1}>
              <Typography
                sx={{
                  color: fill ? '#ffffff' : 'rgba(0,0,0,0.87)',
                  fontSize: '32px',
                  fontStyle: 'normal',
                }}
              >
                {percentage}%
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: fill ? '#ffffff50' : 'rgba(0,0,0,0.87)',
                  fontStyle: 'normal',
                }}
              >
                {value}
              </Typography>
            </Stack>
          </Stack>

          <Typography variant="body2" sx={{ color: fill ? '#ffffff' : 'rgba(0,0,0,0.87)' }}>
            {title}
          </Typography>
        </Stack>
      </Card>
    </Grid>
  );
};

export default ReportCard;
