import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { Box, Grid, Typography, Fade, Backdrop } from '@mui/material';
import { useStyles } from './styles';
import { Modal } from 'src/components/shared';
// import { Modal } from 'react-bootstrap';

function StepMedia({ step, onAction, playVideo, setPlayVideo }) {
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);

  useEffect(() => {
    setShowVideoPlayer(playVideo);
  }, [playVideo]);

  const toggleVideoPlayer = () => {
    if (step.action == 'Video') {
      // setPlayVideo(!setPlayVideo);
      setShowVideoPlayer(!showVideoPlayer);
    }
  };

  const classes = useStyles();
  const media = step.thumbnail;
  const video = step.action_source;

  return (
    <>
      <Box>
        <img
          onClick={toggleVideoPlayer}
          src={media}
          style={{ mixBlendMode: 'multiply' }}
          className={classes.stepMediaThumbnail}
          alt="Demo"
        />
      </Box>
      <Modal size="lg" padding={false} open={showVideoPlayer} onClose={toggleVideoPlayer}>
        <div>
          <ReactPlayer
            playing={showVideoPlayer}
            controls={true}
            width="100%"
            height="100%"
            url={video}
            onEnded={() => {
              onAction();
              toggleVideoPlayer();
            }}
          />
        </div>
      </Modal>
      {/* <Modal
        className={classes.videoModal}
        show={showVideoPlayer}
        onHide={toggleVideoPlayer}
      >
        <div>
          <ReactPlayer
            playing={showVideoPlayer}
            controls={true}
            width="100%"
            height="100%"
            url={video}
            onEnded={() => {
              onAction();
              toggleVideoPlayer();
            }}
          />
        </div>
      </Modal> */}
    </>
  );
}

export default StepMedia;
