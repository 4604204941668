import React, { Fragment, useState, useEffect } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Alert,
  Checkbox,
} from '@mui/material';
import { Loader } from 'src/components/App';
import { Button, Spacer, Avatar } from 'src/components/shared';
import { capitalizeName } from '../../utils/contactUtils';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchTenantConfigData } from 'src/modules/app/actions/appActions';

function Matches({
  contact,
  matches,
  match,
  CRMField,
  syncContact,
  onClose,
  closeDrawer,
  integrations,
  ...props
}) {
  const dispatch = useDispatch();
  const [contactMatched, setContactMatched] = useState({});

  useEffect(() => {
    const crmData = integrations?.find((ele) => ele?.name === CRMField);
    if (crmData?.id) dispatch(fetchTenantConfigData(crmData?.id));
  }, []);

  if (props?.loading)
    return (
      <Loader title={`Searching ${CRMField} for matching records. This may take a few seconds.`} />
    );
  else if (matches?.length === 0) onClose(true);

  const onConfirm = async () => {
    onClose(false);
    await syncContact(contact.id, contactMatched);
  };

  const contactmatched = !!(
    contactMatched?.id ||
    contactMatched?.Id ||
    contactMatched?.vid ||
    contactMatched?.candidateId ||
    contactMatched?.contactId
  );

  return (
    <Box display={'flex'} flexDirection={'column'} height={'100%'}>
      <List sx={{ width: '100%' }} dense={true} disablePadding={true}>
        {match && !isEmpty(match) && (
          <Fragment>
            <Alert severity="info" variant="standard">
              Its a direct match! This contact has been synced with the following {CRMField} match.
            </Alert>
            <ListItem
              dense={false}
              disableGutters={false}
              disablePadding={false}
              alignItems="flex-start"
            >
              <ListItemAvatar>
                <Avatar size={40} name={match?.name} src={undefined} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography sx={{ display: 'inline' }} variant="body1" color="textPrimary">
                      {capitalizeName(match?.name)}
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="textSecondary"
                  >
                    <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                      {match?.job_title || match?.current_job_title || ''}
                      {(match?.job_title || match?.current_job_title) &&
                        (match?.company?.name || match?.current_employer) && (
                          <>
                            <span
                              style={{
                                color: '#4789C2',
                                marginLeft: '4px',
                                marginRight: '4px',
                              }}
                            >
                              at
                            </span>
                          </>
                        )}
                      {match?.company?.name || match?.current_employer}
                    </span>
                  </Typography>
                }
              />
            </ListItem>
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => onClose(false)}
              >
                Close
              </Button>
            </Box>
          </Fragment>
        )}

        {matches?.length !== 0 && (
          <Box padding={'14px'} borderTop={'1px solid #d9d9d9'}>
            <Typography sx={{ fontWeight: 600, fontSize: 16 }}>
              We've found potential matches in {CRMField}
            </Typography>
            <Typography sx={{ fontWeight: 400, fontSize: 12, color: '#777' }} variant="body2">
              if you find a correct match, connect the records to seamlesly integrate the profiles.
            </Typography>
            {matches?.map((matchedContact, index) => {
              const name = `${(matchedContact?.name && matchedContact.name + ' ') || ''}${
                (matchedContact?.firstName && matchedContact.firstName + ' ') || ''
              }${(matchedContact?.lastName && matchedContact.lastName + ' ') || ''}${
                (matchedContact?.Name && matchedContact.Name + ' ') || ''
              }${
                (matchedContact?.properties?.firstname?.value &&
                  matchedContact?.properties?.firstname?.value + ' ') ||
                ''
              }${
                (matchedContact?.properties?.lastname?.value &&
                  matchedContact?.properties?.lastname?.value + ' ') ||
                ''
              }`;
              const jobTitle =
                matchedContact?.properties?.jobtitle?.value ||
                matchedContact?.job_title ||
                matchedContact?.Title ||
                matchedContact?.current_job_title ||
                matchedContact?.occupation ||
                matchedContact?.position ||
                '';
              const employer =
                matchedContact?.company?.name ||
                matchedContact?.current_employer ||
                matchedContact?.companyName ||
                matchedContact?.company ||
                matchedContact?.Account?.Name ||
                '';

              const matchedContactId =
                matchedContact?.id ||
                matchedContact?.Id ||
                matchedContact?.vid ||
                matchedContact?.candidateId ||
                matchedContact?.contactId ||
                null;
              const contactMatchedId =
                contactMatched?.id ||
                contactMatched?.Id ||
                contactMatched?.vid ||
                contactMatched?.candidateId ||
                contactMatched?.contactId ||
                null;
              return (
                <Box key={`${CRMField.toLowerCase()}-match-${index}`}>
                  <Spacer x={1} y={1} />
                  <ListItem
                    dense={false}
                    disableGutters={false}
                    disablePadding={false}
                    alignItems="flex-start"
                    sx={{ alignItems: 'center', padding: 0, gap: '4px' }}
                  >
                    <Box sx={{ width: 36 }}>
                      <Checkbox
                        color="info"
                        checked={matchedContactId === contactMatchedId}
                        onClick={() =>
                          matchedContactId === contactMatchedId
                            ? setContactMatched()
                            : setContactMatched(matchedContact)
                        }
                      />
                    </Box>
                    <Box display={'flex'} sx={{ alignItems: 'center' }}>
                      <ListItemAvatar sx={{ margin: 0 }}>
                        <Avatar size={40} name={name} src={undefined} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: 'inline' }}
                              variant="body1"
                              color="textPrimary"
                            >
                              {capitalizeName(name)}
                            </Typography>
                          </React.Fragment>
                        }
                        secondary={
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="textSecondary"
                          >
                            <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                              {jobTitle}
                              {jobTitle && employer && (
                                <>
                                  <span
                                    style={{
                                      color: '#4789C2',
                                      marginLeft: '4px',
                                      marginRight: '4px',
                                    }}
                                  >
                                    at
                                  </span>
                                </>
                              )}
                              {employer}
                            </span>
                          </Typography>
                        }
                      />
                    </Box>
                  </ListItem>
                  <Spacer x={1} y={1} />
                  <Divider />
                </Box>
              );
            })}
          </Box>
        )}
      </List>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        p={'10px'}
        flex={1}
        flexDirection={'column'}
      >
        <Button sx={{ width: '100%' }} color="secondary" onClick={closeDrawer}>
          SKIP
        </Button>
        <Button
          color="info"
          variant="contained"
          sx={{ width: '100%' }}
          onClick={() => (contactmatched ? onConfirm() : onClose(true))}
        >
          {contactmatched ? 'CONNECT RECORDS' : 'ADD RECORDS'}
        </Button>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  integrations: state.admin.admin.integrations.data,
  loading: state.contacts.loading.fetchContact,
});

export default connect(mapStateToProps, {})(Matches);
