import React from 'react';
import { Typography } from '@mui/material';
import { Spacer } from 'src/components/shared';
import { Tr, Td } from './styles';
import DragHandleIcon from '@mui/icons-material/DragHandle';

const FieldCard = ({
  field,
  view,
  draggableProvided,
  deleteAttribute,
  snapshot,
  isDraggable = true,
}) => {
  const renderFields = () => {
    return (
      <>
        <Td sx={{ border: 'none' }} width="10%">
          {isDraggable ? (
            <div {...draggableProvided.dragHandleProps}>
              <DragHandleIcon />
            </div>
          ) : null}
        </Td>
        <Td>
          <Typography color="secondary">{field.label}</Typography>
          <Spacer x={0.25} y={0.25} />
          <Typography type="body2" color="textSecondary">
            ({field.type})
          </Typography>
        </Td>
      </>
    );
  };

  if (!isDraggable) {
    return <Tr>{renderFields()}</Tr>;
  }

  return (
    <Tr
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
      style={{
        ...draggableProvided.draggableProps.style,
        background: snapshot.isDragging ? 'rgba(245,245,245, 0.75)' : 'none',
      }}
    >
      {renderFields()}
    </Tr>
  );
};

export default FieldCard;
