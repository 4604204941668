import React, { useEffect, useState, useMemo } from 'react';
import * as Yup from 'yup';
import { Box, Grid } from '@mui/material';
import { Button, Spacer, Form, Modal } from 'src/components/shared';
import { fetchUsersLookup, fetchTeamLookup } from 'src/modules/users/api/usersApi';
import { templateLinkedinType, templateStatuses } from './config';

function TemplateActionDrawer({
  action,
  onClose,
  templateBulkUpdate,
  selectTemplates,
  loadType,
  globals,
  sequenceAccessTypeList,
  sequenceEmailTypeList,
  ...props
}) {
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);

  const teamsStatus = useMemo(() => {
    if (globals?.teamStatusList && globals?.teamStatusList.length) {
      return globals?.teamStatusList.map((i, key) => ({
        id: i.value,
        value: i.label.charAt(0).toUpperCase() + i.label.slice(1),
      }));
    }
    return [];
  }, [globals]);

  const templateCategoryList = useMemo(() => {
    return globals?.templateCategoryList || [];
  }, [globals]);

  useEffect(() => {
    switch (action) {
      case 'changeStatus':
        setTitle(`Change status`);
        break;

      case 'changeCategory':
        setTitle(`Change category`);
        break;

      case 'changeType':
        setTitle(`Change type`);
        break;

      case 'changePermissionLevel':
        setTitle(`Change access`);
        break;

      case 'changeOwner':
        setTitle(`Change owner`);
        break;

      default:
        break;
    }
  }, [action]);

  if (!action || action == '') return null;

  const handleSubmit = async (payload) => {
    try {
      setLoading(true);
      await templateBulkUpdate(selectTemplates, payload, loadType);
      setLoading(false);
      onClose();
    } catch (error) {
      setLoading(false);
    }
  };

  const getContent = () => {
    switch (action) {
      case 'changeStatus':
        return (
          <Form
            initialValues={{
              status: 'active',
            }}
            validationSchema={Yup.object().shape({
              status: Yup.string().required('Please choose a status.'),
            })}
            onSubmit={(values, form) => {
              const data = JSON.parse(JSON.stringify(values));
              handleSubmit(data);
            }}
          >
            {(props) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.submitForm();
                    return false;
                  }}
                  noValidate
                  style={{ height: '100%' }}
                >
                  <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Form.Field.Select
                      fullWidth
                      options={templateStatuses}
                      variant="outlined"
                      name="status"
                      label="Status"
                      optLabel="label"
                      optValue="value"
                      tip="Change status for all selected templates."
                      showNone={false}
                    />

                    <Box display={'flex'} spacing={2} mt={2} justifyContent="flex-end">
                      <Button variant="outlined" color="secondary" onClick={onClose}>
                        Cancel
                      </Button>
                      <Spacer x={1} y={1} />
                      <Button variant="contained" color="secondary" type="submit" loading={loading}>
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </form>
              );
            }}
          </Form>
        );
        break;

      case 'changeCategory':
        return (
          <Form
            initialValues={{
              category: '',
            }}
            validationSchema={Yup.object().shape({
              category: Yup.string().required('Please select category for templates!'),
            })}
            onSubmit={(values, form) => {
              const data = JSON.parse(JSON.stringify(values));
              handleSubmit(data);
            }}
          >
            {(props) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.submitForm();
                    return false;
                  }}
                  noValidate
                  style={{ height: '100%' }}
                >
                  <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Form.Field.Select
                      fullWidth
                      options={templateCategoryList}
                      variant="outlined"
                      name="category"
                      label="Template Category *"
                      optLabel="label"
                      optValue="value"
                      tip="Change assigned category for all selected templates."
                      showNone={false}
                    />
                    <Box display={'flex'} spacing={2} mt={2} justifyContent="flex-end">
                      <Button variant="outlined" color="secondary" onClick={onClose}>
                        Cancel
                      </Button>
                      <Spacer x={1} y={1} />
                      <Button variant="contained" color="secondary" type="submit" loading={loading}>
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </form>
              );
            }}
          </Form>
        );
        break;

      case 'changeType':
        return (
          <Form
            initialValues={{
              type: '',
            }}
            validationSchema={Yup.object().shape({
              type: Yup.string().required('Please select type for templates!'),
            })}
            onSubmit={(values, form) => {
              const data = JSON.parse(JSON.stringify(values));
              handleSubmit(data);
            }}
          >
            {(props) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.submitForm();
                    return false;
                  }}
                  noValidate
                  style={{ height: '100%' }}
                >
                  <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Form.Field.Select
                      fullWidth
                      options={templateLinkedinType}
                      variant="outlined"
                      name="type"
                      label="Template Type *"
                      optLabel="label"
                      optValue="value"
                      tip="Change assigned type for all selected templates."
                      showNone={false}
                    />
                    <Box display={'flex'} spacing={2} mt={2} justifyContent="flex-end">
                      <Button variant="outlined" color="secondary" onClick={onClose}>
                        Cancel
                      </Button>
                      <Spacer x={1} y={1} />
                      <Button variant="contained" color="secondary" type="submit" loading={loading}>
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </form>
              );
            }}
          </Form>
        );
        break;

      case 'changePermissionLevel':
        return (
          <Form
            initialValues={{
              permissionType: '',
              sharedWithUsers: [],
              sharedWithTeams: [],
            }}
            validationSchema={Yup.object().shape({
              permissionType: Yup.string().required('Select who has access to this template.'),
              sharedWithUsers: Yup.array().test({
                message: () => 'Please choose at least one user.',
                test: (value, values) => {
                  const permissionType = values.parent.permissionType;
                  if (permissionType === 'private') {
                    return value.length > 0;
                  }
                  return true;
                },
              }),
              sharedWithTeams: Yup.array().test({
                message: () => 'Please choose at least one team.',
                test: (value, values) => {
                  const permissionType = values.parent.permissionType;
                  if (permissionType === 'team') {
                    return value.length > 0;
                  }
                  return true;
                },
              }),
            })}
            onSubmit={(values, form) => {
              const data = JSON.parse(JSON.stringify(values));
              const payload = {
                ...(data.permissionType !== ''
                  ? { permissionType: data.permissionType }
                  : { permissionType: 'organisation' }),
                ...(data?.sharedWithUsers.length > 0
                  ? { sharedWithUsers: data.sharedWithUsers.map((val) => val.id) }
                  : { sharedWithUsers: [] }),

                ...(data?.sharedWithTeams.length > 0
                  ? { sharedWithTeams: data.sharedWithTeams.map((val) => val.id) }
                  : { sharedWithTeams: [] }),
              };
              handleSubmit(payload);
            }}
          >
            {({ values, ...formProps }) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    formProps.submitForm();
                    return false;
                  }}
                  noValidate
                  style={{ height: '100%' }}
                >
                  <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Form.Field.Select
                          options={sequenceAccessTypeList || []}
                          fullWidth
                          variant="outlined"
                          name="permissionType"
                          label="Permission Type"
                          optLabel="label"
                          optValue="value"
                          showNone={false}
                          tip="Select who has access to this template."
                        />
                        {values.permissionType === 'private' ? (
                          <Form.Field.AutoComplete
                            multiple={true}
                            options={[]}
                            fullWidth
                            variant="outlined"
                            name="sharedWithUsers"
                            label="Select users"
                            remoteMethod={(val) => {
                              return fetchUsersLookup(val);
                            }}
                            checkboxes={true}
                            optLabel="name"
                            optValue="id"
                          />
                        ) : null}

                        {values.permissionType === 'team' ? (
                          <Form.Field.AutoComplete
                            multiple={true}
                            options={[]}
                            fullWidth
                            variant="outlined"
                            name="sharedWithTeams"
                            label="Select Teams"
                            checkboxes={true}
                            remoteMethod={(val) => {
                              return fetchTeamLookup(val);
                            }}
                            optLabel="name"
                            optValue="id"
                          />
                        ) : null}
                      </Grid>
                    </Grid>
                    <Box display={'flex'} spacing={2} mt={2} justifyContent="flex-end">
                      <Button variant="outlined" color="secondary" onClick={onClose}>
                        Cancel
                      </Button>
                      <Spacer x={1} y={1} />
                      <Button variant="contained" color="secondary" type="submit" loading={loading}>
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </form>
              );
            }}
          </Form>
        );
        break;

      case 'changeOwner':
        return (
          <Form
            initialValues={{
              user: {},
            }}
            validationSchema={Yup.object().shape({
              user: Yup.object().test({
                message: () => 'Please choose at least one owner.',
                test: (values) => {
                  if (!values) return false;
                  return Object.keys(values).length !== 0;
                },
              }),
            })}
            onSubmit={(values, form) => {
              const data = JSON.parse(JSON.stringify(values));
              if (values?.user.id) {
                data.owner = values?.user.id;
                delete data.user;
                handleSubmit(data);
              }
            }}
          >
            {(props) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.submitForm();
                    return false;
                  }}
                  noValidate
                  style={{ height: '100%' }}
                >
                  <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Form.Field.AutoComplete
                      multiple={false}
                      options={[]}
                      fullWidth
                      variant="outlined"
                      name="user"
                      label="Select Owner"
                      remoteMethod={(val) => {
                        return fetchUsersLookup(val);
                      }}
                      optLabel="name"
                      optValue="id"
                      tip="Change assigned owner for all selected templates."
                    />

                    <Box display={'flex'} spacing={2} mt={2} justifyContent="flex-end">
                      <Button variant="outlined" color="secondary" onClick={onClose}>
                        Cancel
                      </Button>
                      <Spacer x={1} y={1} />
                      <Button variant="contained" color="secondary" type="submit" loading={loading}>
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </form>
              );
            }}
          </Form>
        );
        break;

      default:
        break;
    }
  };
  return (
    <>
      <Modal
        title={title}
        open={action && typeof action !== 'undefined'}
        onClose={onClose}
        size="xs"
      >
        {getContent()}
      </Modal>
    </>
  );
}

export default TemplateActionDrawer;
