import React, { useState, useEffect, useRef } from 'react';

import Slider from 'react-slick';
import styled from 'styled-components';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export const HistorySlider = ({ children }) => {
  const slider1 = useRef(null);
  const [slidesToShow, setSlidesToShow] = React.useState(1);

  const Next = (props) => {
    const { className, style, onClick } = props;
    return (
      <NextButton style={{ display: 'block' }} onClick={onClick}>
        <ArrowBackIosNewIcon />
      </NextButton>
    );
  };

  const Prev = (props) => {
    const { className, style, onClick } = props;
    return (
      <PrevButton style={{ display: 'block' }} onClick={onClick}>
        <ArrowForwardIosIcon />
      </PrevButton>
    );
  };

  return (
    <Slider
      ref={slider1}
      dots={false}
      arrows={true}
      slidesToShow={slidesToShow}
      nextArrow={<Prev />}
      prevArrow={<Next />}
    >
      {children}
    </Slider>
  );
};

const NextButton = styled.div`
  position: absolute;
  left: 8px;
  top: calc(50% - 16px);
  z-index: 99;
  width: 32px;
  height: 32px;
  background-color: rgba(12, 23, 33, 0.1);
  border-radius: 32px;
  & svg {
    font-size: 20px;
    top: calc(50% - 10px);
    position: relative;
    left: calc(50% - 10px);
    /* fill: rgba(12, 23, 33, 1); */
  }
`;
const PrevButton = styled.div`
  position: absolute;
  right: 8px;
  top: calc(50% - 16px);
  z-index: 99;
  width: 32px;
  height: 32px;
  background-color: rgba(12, 23, 33, 0.1);
  border-radius: 32px;
  & svg {
    font-size: 20px;
    top: calc(50% - 10px);
    position: relative;
    left: calc(50% - 10px);
    /* fill: rgba(12, 23, 33, 1); */
  }
`;
