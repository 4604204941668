import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Divider,
  CardHeader,
  Stack,
  Box,
  Container,
} from '@mui/material';
import { Spacer, Button } from 'src/components/shared';
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
import { fetchTemplateDetails } from 'src/modules/admin/api/adminApi';
import { EmailHeader, EmailChip, HoverButton } from '../Inbox/Messages/styles';
import Skeleton from '../Inbox/Messages/Skeleton';
import EditEmail from './EditEmail';
import { capitalizeFirstLetter } from 'src/utils/helper';
import moment from 'moment';
import { dateTimeFormat } from 'src/config';
import { testPopulateTemplate } from 'src/modules/app/api/appApis';
import { Email } from '../../components/Task';

import Attachments from '../Inbox/Messages/Attachments';
function Message({ task, toggleNoteForm, user, ...props }) {
  const [showActions, setShowActions] = useState(false);
  const [currentTask, setCurrentTask] = useState({});
  const [template, setTemplate] = useState({});
  const [templateLoading, setTemplateLoading] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');

  useEffect(() => {
    setCurrentTask(task);
    setTemplate({});
    setSubject('');
    setContent('');
    if (task?.id && task?.template && task?.template !== '') {
      setTemplateLoading(true);
      fetchTemplateDetails(task.template)
        .then((res) => {
          setTemplateLoading(false);
          setTemplate(res.template);
          setSubject(res.template.subject);
          setContent(res.template.content);
          populateContent(res.template);
        })
        .catch((err) => {
          setTemplateLoading(false);
        });
    }
  }, [task?.id]);

  const populateContent = (template) => {
    testPopulateTemplate(template.subject, template.content, task.contact.id, user.id)
      .then((response) => {
        // setContentPopulated(response.content);
        // setSubjectPopulated(response.subject);
        // setErrors(response.errors);
        setSubject(response.subject);
        setContent(response.content);
      })
      .catch((error) => {
        console.log('error : ', error);
      });
  };

  // const { content, subject } = template;

  let emailType = 'manual';
  if (task?.sequence && task?.sequence?.id) {
    emailType = task?.taskType === 'email' ? 'automated' : 'manual';
  } else {
    emailType = 'manual';
  }
  const color = emailType === 'manual' ? '#EC407A' : '#1976D2';

  const toggleEdit = () => {
    setShowEdit(!showEdit);
  };

  const onSuccess = (updatedTask) => {
    updatedTask.template = updatedTask.template?.id || '';
    setCurrentTask(updatedTask);
    setTemplate({});
    setSubject('');
    setContent('');
    if (updatedTask?.id && updatedTask?.template && updatedTask?.template !== '') {
      refreshData(updatedTask);
      setTemplateLoading(true);
      fetchTemplateDetails(updatedTask.template)
        .then((res) => {
          setTemplateLoading(false);
          setTemplate(res.template);
          setSubject(res.template.subject);
          setContent(res.template.content);
          populateContent(res.template);
        })
        .catch((err) => {
          setTemplateLoading(false);
        });
    }
  };

  const refreshData = (updatedTask) => {
    const data = props.outbox.data.map((item) => {
      if (item.id === updatedTask.id) {
        return updatedTask;
      }
      return item;
    });
    props.setOutbox(data, { total: props.outbox.paging.count });
  };

  return (
    <>
      {templateLoading ? (
        <Skeleton />
      ) : (
        <>
          {currentTask?.taskType === 'selectEmailTemplate' &&
          (!currentTask?.template ||
            currentTask?.template === null ||
            currentTask?.template == '') ? (
            <Container maxWidth="md">
              <Email
                task={currentTask}
                setShowDueDateForm={() => {}}
                noteTypes={[]}
                toggleDeleteModel={() => {}}
                showActions={false}
                {...props}
                onSuccess={onSuccess}
              />
            </Container>
          ) : (
            <Card
              elevation={0}
              sx={{
                border: '1px solid #dddddd',
                position: 'relative',
                overflow: 'visible',
                '& pre': {
                  maxWidth: '800px',
                  overflowWrap: 'break-word',
                  whiteSpace: 'break-spaces',
                },
              }}
              onMouseEnter={() => setShowActions(true)}
              onMouseLeave={() => setShowActions(false)}
            >
              <EmailHeader>
                <EmailChip>
                  <EmailIcon sx={{ fontSize: '18px', fill: color }} />
                  <Spacer x={1} y={1} />
                  <Typography variant="caption" color={color}>
                    {capitalizeFirstLetter(emailType)} email
                  </Typography>
                </EmailChip>
                {showActions && (
                  <Stack direction="row" alignItems="center" mr={2} gap={1}>
                    <HoverButton startIcon={<EditIcon />} size="small" onClick={toggleEdit}>
                      Edit email
                    </HoverButton>
                  </Stack>
                )}
              </EmailHeader>

              <CardContent>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body1" color="textSecondary">
                    {subject}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {moment(currentTask?.scheduledOn || '').format(dateTimeFormat)}
                    &nbsp; ({moment(currentTask?.scheduledOn || '').fromNow()})
                  </Typography>
                </Stack>
                <Spacer x={1} y={1} />
                <Typography component="div" variant="body2" sx={{ color: 'rgba(0,0,0,0.44)' }}>
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                </Typography>

                {template && template?.attachments && template.attachments.length ? (
                  <>
                    <Spacer x={1} y={1} />
                    <Attachments attachments={template.attachments.map((item) => item.id)} />
                  </>
                ) : null}
              </CardContent>
            </Card>
          )}
        </>
      )}

      <Spacer x={5} y={5} />
      {showEdit && (
        <EditEmail
          task={currentTask}
          title="Edit email"
          handleClose={toggleEdit}
          handleClick={props.handleClick}
        />
      )}
    </>
  );
}

export default Message;
