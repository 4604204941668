import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { Button, Form, Modal, Spacer } from 'src/components/shared';
import { isEmpty } from 'lodash';
import { Stack } from '@mui/material';

function CreditRecharge({ open, onClose, initiatePayment, loading }) {
  const plans = useSelector((state) => state.app.globals?.charges || []);
  const tenant = useSelector((state) => state.app.tenant || {});

  const dataPlans = useMemo(() => {
    if (plans && !isEmpty(plans)) {
      return plans.data.plans.map((item) => {
        return {
          ...item,
          name: `${item.credits} credits for $${item.price}`,
        };
      });
    }
  }, [plans]);

  const roleplayPlans = useMemo(() => {
    if (plans && !isEmpty(plans)) {
      return plans.roleplay.plans.map((item) => {
        return {
          ...item,
          name: `${item.credits} roleplay minutes for $${item.price}`,
        };
      });
    }
  }, [plans]);

  const handleSubmit = (values) => {
    initiatePayment(values.plan, undefined, true);
  };

  return (
    <Modal
      open={open}
      sx={{
        '& .MuiDialogTitle-root': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
      }}
      title={
        <Stack gap="4px">
          <Typography fontSize="18px" fontWeight="500">
            {open === 'roleplay' ? 'Buy Role-play Minutes' : 'Buy Credits'}
          </Typography>
        </Stack>
      }
      onClose={() => onClose()}
    >
      <Spacer x={2} y={2} />
      <Typography variant="body2" color="textSecondary">
        {open === 'roleplay'
          ? 'Add role-play minutes to your company account to keep using Ai role-plays. '
          : 'Add data credits to your company account to enrich prospect profiles.'}
      </Typography>
      <Spacer x={2} y={2} />
      {open === 'data' && (
        <>
          <Typography variant="body1" fontWeight="500" color="textPrimary">
            Current credit balance
          </Typography>
          <Spacer x={0.5} y={0.5} />
          <Typography variant="body1" color="textSecondary">
            {tenant?.credits > 0 ? tenant?.credits : 0} credits remaining
          </Typography>
        </>
      )}
      {open === 'roleplay' && (
        <>
          <Stack direction="flex" justifyContent="space-between">
            <Typography variant="body1" fontWeight="500" color="textPrimary">
              Current minute balance
            </Typography>
            <Typography variant="body1" color="textPrimary">
              1 minute costs $0.5
            </Typography>
          </Stack>
          <Spacer x={0.5} y={0.5} />
          <Typography variant="body1" color="textSecondary">
            {tenant?.roleplayMinutes || 0} minutes remaining
          </Typography>
        </>
      )}
      <Spacer x={1} y={1} />
      <Form
        initialValues={{
          plan: '',
        }}
        validationSchema={Yup.object().shape({
          plan: Yup.string().required('Please choose a plan!'),
        })}
        onSubmit={handleSubmit}
      >
        {(formProps) => {
          return (
            <>
              {open === 'roleplay' && (
                <Form.Field.Select
                  options={roleplayPlans}
                  optValue="planId"
                  optLabel="name"
                  variant="outlined"
                  label="Add minutes"
                  placeholder="Choose minute batch..."
                  name="plan"
                  fullWidth
                />
              )}

              {open === 'data' && (
                <Form.Field.Select
                  options={dataPlans}
                  optValue="planId"
                  optLabel="name"
                  variant="outlined"
                  label="Add credits"
                  placeholder="Choose credit batch..."
                  name="plan"
                  fullWidth
                />
              )}

              <Spacer x={1} y={1} />
              <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={formProps.submitForm}
                  loading={loading}
                >
                  Confirm
                </Button>
              </Box>
            </>
          );
        }}
      </Form>
    </Modal>
  );
}

export default CreditRecharge;
