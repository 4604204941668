import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';

import { Box, Typography, Grid, Stack, ButtonBase } from '@mui/material';
import { Form, Button, Spacer, Stepper } from 'src/components/shared';
import { boardAccessTypes, boardCategories, boardTypes } from '../../../config';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { colors, ColorPicker } from './styles';

import { fetchUsersLookup } from 'src/modules/users/api/usersApi';
import { fetchTeamsLookup } from 'src/modules/admin/api/adminApi';

function BoardForm({
  editBoard,
  onClose,
  postBoard,
  putBoard,
  setDeleteBoard,
  setActiveStep,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);

  const randomColor = colors[0];

  const sequenceAccessTypeList = useSelector((state) => state.app.globals.sequenceAccessTypeList);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    setUsers([{ ...user, name: `${user.fname} ${user.lname}` }]);
  }, [user]);

  const withConcatenatedName = (items) => {
    return items ? items.map((k) => ({ ...k, name: `${k.fname} ${k.lname}` })) : null;
  };

  return (
    <Form
      initialValues={{
        name: editBoard?.name || '',
        accessType: editBoard?.accessType || 'private',
        color: editBoard?.tabColor || randomColor,
        category: editBoard?.category || '',
        type: editBoard?.type || 'widgets',
        description: editBoard?.description || '',
        sharedWithUsers: withConcatenatedName(editBoard?.sharedWithUsers) || users,
        sharedWithTeams: editBoard?.sharedWithTeams || [],
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Please enter board name!'),
        accessType: Yup.string().required('Please choose access type!'),
        category: Yup.string().required('Please choose category!'),
      })}
      enableReinitialize={true}
      onSubmit={async (values, form) => {
        try {
          let data = JSON.parse(JSON.stringify(values));
          data.tabColor = data.color;
          delete data.color;
          data.sharedWithUsers = data.sharedWithUsers.map((k) => k.id);
          data.sharedWithTeams = data.sharedWithTeams.map((k) => k.id);
          setLoading(true);
          if (editBoard?.id && editBoard?.id !== '') {
            await putBoard(editBoard?.id, data);
            setLoading(false);
            onClose();
          } else {
            await postBoard(data);
            setLoading(false);
            setActiveStep(1);
          }
        } catch (error) {}
      }}
    >
      {({ values, ...formProps }) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formProps.submitForm();
              return false;
            }}
            noValidate
          >
            <Grid container spacing={2}>
              <Grid item xs={2} />
              <Grid item xs={8}>
                <Form.Field.Input
                  fullWidth
                  variant="outlined"
                  name="name"
                  label="Board Name"
                  disabled={loading}
                />

                <Form.Field.Select
                  fullWidth
                  variant="outlined"
                  options={boardCategories}
                  name="category"
                  disabled={loading}
                  label="Category"
                />

                <Form.Field.Input
                  fullWidth
                  variant="outlined"
                  name="description"
                  label="Description"
                  disabled={loading}
                  multiline
                />

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Form.Field.Select
                      fullWidth
                      variant="outlined"
                      options={sequenceAccessTypeList}
                      showNone={false}
                      name="accessType"
                      disabled={loading}
                      label="Board Access"
                      onChange={(val) => {
                        switch (val) {
                          case 'private':
                            formProps.setFieldValue('sharedWithUsers', []);
                            break;
                          case 'team':
                            formProps.setFieldValue('sharedWithTeams', []);
                            break;
                          case 'organisation':
                          default:
                            formProps.setFieldValue('sharedWithUsers', []);
                            formProps.setFieldValue('sharedWithTeams', []);
                            break;
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {values.accessType === 'private' ? (
                      <Form.Field.AutoComplete
                        multiple={true}
                        options={users}
                        fullWidth
                        variant="outlined"
                        name="sharedWithUsers"
                        checkboxes={true}
                        label="Select users"
                        remoteMethod={(val) => {
                          return fetchUsersLookup(val);
                        }}
                        optLabel="name"
                        optValue="id"
                      />
                    ) : null}

                    {values.accessType === 'team' ? (
                      <Form.Field.AutoComplete
                        multiple={true}
                        options={teams}
                        fullWidth
                        variant="outlined"
                        name="sharedWithTeams"
                        checkboxes={true}
                        label="Select Teams"
                        remoteMethod={(val) => {
                          return fetchTeamsLookup(val);
                        }}
                        optLabel="name"
                        optValue="id"
                      />
                    ) : null}
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  {!editBoard?.id ? (
                    <Grid item xs={12}>
                      <Form.Field.Radio
                        fullWidth
                        variant="outlined"
                        options={boardTypes}
                        name="type"
                        color="secondary"
                        onChange={(val) => {
                          props.setBoardType(val);
                        }}
                        label="Board type :"
                        sx={{ ml: 1.5, display: 'flex' }}
                        labelProps={{
                          border: '1px solid #dddddd',
                          padding: '2px 12px 2px 4px ',
                          borderRadius: '4px',
                          // flex: 1,
                          fontSize: '14px',
                        }}
                      />
                    </Grid>
                  ) : null}

                  <Grid item xs={12}>
                    <Box mt={1}>
                      <Form.Field.ColorPicker variant="inline" name="color" width={384} />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box
                      mt={2}
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="flex-end"
                    >
                      <Box>
                        {editBoard?.id && editBoard?.id !== '' ? (
                          <Button
                            variant="contained"
                            color="error"
                            disabled={loading}
                            onClick={() => {
                              setDeleteBoard(editBoard);
                            }}
                          >
                            Delete Board
                          </Button>
                        ) : null}
                      </Box>
                      <Box display="flex">
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={onClose}
                          disabled={loading}
                        >
                          Cancel
                        </Button>
                        <Spacer basis={2} />
                        <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                          loading={loading}
                        >
                          {!editBoard?.id ? 'Next' : 'Save'}
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Form>
  );
}

export default BoardForm;
