import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, Grid, Divider, Card, CardContent, Chip } from '@mui/material';
import { Spacer } from 'src/components/shared';
import { Paging, BackButton } from 'src/components/App';

import { MainGrid, Left, Right, PaperWrap, PlusIcon } from './styles';
import { fetchWidgets } from '../../../actions/boardActions';
import Categories from './Categories';
import Item from './Item';
import Skeleton from './Skeleton';
import RefreshIcon from '@mui/icons-material/Refresh';

const widgetCategories = [
  { value: 'sequence', label: 'Sequence' },
  { value: 'activity', label: 'Activity' },
  { value: 'tasks', label: 'Tasks' },
  { value: 'emails', label: 'Email' },
];

const Templates = ({ boardCategories, defaultAction, templateCategories, loading, ...props }) => {
  const dispatch = useDispatch();
  const widgets = useSelector((state) => state.boards.widgets);
  const boardWidgets = useSelector((state) => state.boards.boardWidgets);
  const boardWidgetKeys = useMemo(() => {
    return Object.keys(boardWidgets);
  }, [boardWidgets]);
  const { data, filters, paging } = widgets;

  useEffect(() => {
    if (!data.length) {
      fetchData(filters, paging);
    }
  }, []);

  const fetchData = (filters, paging) => {
    dispatch(fetchWidgets(filters, paging));
  };

  const handlePageChange = (page) => {
    dispatch(fetchWidgets(filters, { ...paging, pageNo: page }));
  };

  const onFilter = ({ label, value }) => {
    let isNew = true;
    if (filters.category === value) {
      delete filters.category;
      isNew = false;
    }
    dispatch(
      fetchWidgets(
        {
          ...filters,
          ...(isNew && { category: value }),
        },
        {
          ...paging,
          pageNo: 0,
        },
      ),
    );
  };

  return (
    <Box>
      <MainGrid>
        <Left>
          <Categories
            onChange={onFilter}
            title="Browse by category"
            categories={widgetCategories}
            value={filters?.category || ''}
          />
        </Left>

        <Right>
          <Box display="flex" justifyContent="space-between">
            <Box>
              {/* {defaultAction !== 'widgets' ? (
                <>
                  <BackButton onClick={props.resetAction} />
                  <Spacer x={1} y={1} />
                </>
              ) : null} */}

              <Typography variant="h2">Select widgets for your dashboard</Typography>
            </Box>
            <Chip
              icon={<RefreshIcon />}
              label="Refresh"
              color="secondary"
              variant="outlined"
              size="small"
              onClick={() => fetchData(filters, { ...paging, pageNo: 0 })}
            />
          </Box>
          <Spacer x={2} y={2} />

          {loading.widgets === true ? (
            <Skeleton />
          ) : (
            <Grid container spacing={2}>
              {data.length ? (
                <>
                  {data.map((item, index) => {
                    return (
                      <Grid item xs={4} key={`board-template-${index}`}>
                        <Item
                          data={item}
                          type="widgets"
                          handleBoardTemplateClick={() => {}}
                          addWidget={props.addWidget}
                          removeWidget={props.removeWidget}
                          active={boardWidgetKeys.length && boardWidgetKeys.includes(item.id)}
                          cardProps={{
                            sx: {
                              minHeight: '300px',
                            },
                          }}
                        />
                      </Grid>
                    );
                  })}

                  <Paging
                    count={paging?.count}
                    perPage={paging?.perPage}
                    page={paging?.pageNo}
                    onChange={handlePageChange}
                  />
                </>
              ) : null}
            </Grid>
          )}
        </Right>
      </MainGrid>
    </Box>
  );
};

export default Templates;
