import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Divider, Grid, Stack, Box } from '@mui/material';
import { Form, Modal, Spacer, Button } from 'src/components/shared';
import { RemoveRedEye } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';

import { competitionSettings } from '../../api/competitionApi';

import { fetchTenantConfig } from 'src/modules/app/actions/appActions';

import toast from 'src/utils/toast';
import useStyles from './style';

function CompetitionSettings({
  open = false,
  handleClose = () => {},
  handleInviteModal = () => {},
  handleUpdateModal = () => {},
  handleResultModal = () => {},
  handleInvitePreviewModal = () => {},
  handleUpdatePreviewModal = () => {},
  handleResultPreviewModal = () => {},
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const id = useSelector((state) => (open ? state?.app?.tenant?.tenant_settings?.[0]?.id : ''));
  const settings = useSelector((state) =>
    open ? state?.app?.tenant?.tenant_settings?.[0]?.competitionSettings : '',
  );

  const handleSubmit = async (values) => {
    const payload = {
      competitionSettings: {
        logo: values?.logo?.url,
        useAvatar: values?.avatar,
        inviteTemplate: settings?.competitionInviteTemplate,
        updateTemplate: settings?.competitionUpdateTemplate,
        resultTemplate: settings?.competitionResultTemplate,
        prizes: settings?.prizes,
      },
    };
    try {
      const res = await competitionSettings(id, payload);
      if (res) {
        toast.success('Competition settings updated');
        handleClose();
        dispatch(fetchTenantConfig());
      }
    } catch (error) {}
  };

  const logo = settings?.logo
    ? {
        url: settings?.logo,
        id: 'ac',
        uuid: 'bc',
        type: 'image/png',
      }
    : {};

  return (
    <Modal open={open} onClose={handleClose} title="Competition Settings" size="md">
      <Form
        initialValues={{
          logo: logo,
          avatar: settings?.useAvatar || '',
        }}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ values, ...formProps }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formProps.submitForm();
                return false;
              }}
              noValidate
            >
              <>
                <Grid container spacing={2} alignItems="flex-end">
                  <Grid item xs={7}>
                    <Typography>Company Logo</Typography>
                    <Spacer x={2} y={2} />
                    <Box sx={{ position: 'relative', minHeight: '200px' }}>
                      <Form.Field.FileDropper multiple={false} name="logo" label="Upload logo" />
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="caption" color="textSecondary">
                      Image size 160x56
                    </Typography>
                  </Grid>
                </Grid>
                <Spacer x={3} y={3} />
                <Divider />
                <Spacer x={3} y={3} />
                <Typography variant="body1">Manage Competition Emails</Typography>
                <Spacer x={2} y={2} />

                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="body2" color="textPrimary">
                    Invite email
                  </Typography>
                  <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
                    <Button
                      color="secondary"
                      startIcon={<RemoveRedEye />}
                      onClick={handleInvitePreviewModal}
                    >
                      Preview
                    </Button>
                    <Button color="secondary" startIcon={<EditIcon />} onClick={handleInviteModal}>
                      Edit
                    </Button>
                  </Stack>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" mt={0.5}>
                  <Typography variant="body2" color="textPrimary">
                    Update email
                  </Typography>
                  <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
                    <Button
                      color="secondary"
                      startIcon={<RemoveRedEye />}
                      onClick={handleUpdatePreviewModal}
                    >
                      Preview
                    </Button>
                    <Button color="secondary" startIcon={<EditIcon />} onClick={handleUpdateModal}>
                      Edit
                    </Button>
                  </Stack>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" mt={0.5}>
                  <Typography variant="body2" color="textPrimary">
                    Results email
                  </Typography>
                  <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
                    <Button
                      color="secondary"
                      startIcon={<RemoveRedEye />}
                      onClick={handleResultPreviewModal}
                    >
                      Preview
                    </Button>
                    <Button color="secondary" startIcon={<EditIcon />} onClick={handleResultModal}>
                      Edit
                    </Button>
                  </Stack>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={2} mt={2}>
                  <Button color="secondary" onClick={handleClose} size="small">
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" color="secondary" size="small">
                    Save
                  </Button>
                </Stack>
              </>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
}

export default CompetitionSettings;
