import { makeStyles } from '@mui/styles';
import { themeColors } from '../../config';

const useStyles = makeStyles(({}) => ({
  root: {
    background: 'transparent',
    color: themeColors.text,
    maxWidth: 345,
  },
  hoverScreen: {
    position: 'absolute',
    background: 'rgba(12, 23, 33, 0.60)',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  iconsBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      background: themeColors.bg2,
      margin: '0px 0px 8px 8px',
      '&:hover': {
        background: themeColors.bg2,
      },
    },
  },
  statusIcon: {
    fontSize: '16px',
  },
  watchedFrame: {
    width: '109px',
    height: '14px',
    padding: '6px 6px 8px 8px',
    display: 'flex',
    bottom: '1%',
    right: '0%',
    position: 'absolute',
    background: themeColors.greenText,
    color: themeColors.bg,
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 5,
    fontSize: '12px',
  },
  visibilityIcon: {
    color: themeColors.greenText,
  },
  starIcon: {
    color: themeColors.yellow,
  },
  menuPaper: {
    backgroundColor: themeColors.bg,
  },
  menuSection: {
    color: 'rgba(255,255,255,0.6)',
    position: 'absolute',
    top: '12px',
    right: '12px',
    // background: themeColors.bg3,
    borderRadius: '24px',
    width: '24px',
    height: '24px',
    cursor: 'pointer',
  },
  menuInner: {
    backgroundColor: '#021424',
    width: 'fit-content',
    // height: '129px'
  },
  menuLinks: {
    color: '#F1F1F1 !important',
  },
  buttonSessionCount: {
    position: 'absolute',
    bottom: 20,
    left: 10,
    borderRadius: '5px',
    opacity: 0.8,
    background: '#082035',
    color: '#FFFFFF',
    cursor: 'default',
  },
  image: {
    objectFit: 'fill',

    width: '100%',
    '@media (max-width: 1400px)': {
      //width: '100% !important',
    },
  },
  imageResponsive: {
    '@media (max-width: 1400px)': {
      width: '100% !important',
    },
  },

  largeTileImage: {
    // width: '526px',
    height: '561px',
    // maxWidth: '526px',
    maxHeight: '561px',
    borderRadius: '5px',
    width: '100%',
  },
  longTileImage: {
    // width: '526px',
    height: '248px',
    // maxWidth: '526px',
    maxHeight: '248px',
    borderRadius: '5px',
  },
  tallTileImage: {
    // width: '248px',
    height: '296px',
    // maxWidth: '248px',
    maxHeight: '296px',
    borderRadius: '3px',
    width: '100%',
  },
  thumbnailImage: {
    width: '268px',
    height: '144px',
    maxWidth: '268px',
    maxHeight: '144px',
    borderRadius: '3px',
  },
  sessionThumbnailImage: {
    width: '236px',
    height: '144px',
    maxWidth: '256px',
    maxHeight: '144px',
    borderRadius: '3px',
  },
  searchResultImage: {
    width: '283px',
    height: '176px',
    borderRadius: '3px',
    maxHeight: '176px',
    maxWidth: '283px',
  },
  otherImage: {
    width: '232px',
    display: 'block',
    width: '100%',
    height: '153px',
    maxWidth: '256px',
    maxHeight: '153px',
    borderRadius: '3px',
    marginBottom: '5%',
  },
  sessionPreview: {
    width: '591px',
    height: '353px',
    maxWidth: '591px',
    maxHeight: '353px',
    borderRadius: '6px',
  },
  philosophyImage: {
    width: '1090px',
    height: '253px',
    maxWidth: '1146px',
    maxHeight: '253px',
    borderRadius: '3px',
  },
  headerImage: {
    width: '1090px',
    height: '336px',
    maxWidth: '1146px',
    maxHeight: '336px',
    borderRadius: '10px',
  },
  noImageContainer: {
    backgroundColor: '#193247',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },

  videoPreviewImage: {
    width: '590px',
    height: '354px',
    maxWidth: '590px',
    maxHeight: '354px',
    borderRadius: '6px',
  },
}));

export default useStyles;
