import React, { useEffect, useState } from 'react';
import { Box, Typography, Avatar, ButtonBase } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { deepOrange, green } from '@mui/material/colors';

const Template = ({ option, data, handleClick, onClose }) => {
  return (
    <ButtonBase
      onClick={() => {
        handleClick(data);
        onClose();
      }}
      sx={{ width: '100%', justifyContent: 'flex-start' }}
    >
      <Box display="flex" flexDirection="row" p={1} ml={1}>
        <Avatar
          size={26}
          variant="circle"
          sx={{ bgcolor: green[500], width: '36px', height: '36px', marginTop: '4px' }}
        >
          <EmailIcon sx={{ fontSize: '1.25rem' }} />
        </Avatar>
        <Box ml={2} maxWidth={300} display="flex" alignItems="flex-start" flexDirection="column">
          <Typography noWrap variant="body1">
            {data.name}
          </Typography>
          <Typography noWrap variant="body2" color="textSecondary">
            {data.subject}
          </Typography>
        </Box>
      </Box>
    </ButtonBase>
  );
};

export default Template;
