export const data = [
  {
    id: 1,
    name: 'Point 01',
    content: '',
    permission: 'Demo Field',
    status: 'active',
    permission: 'System',
  },
  {
    id: 2,
    name: 'Industry stats 01',
    content: 'Only 7% of companies have fully implemented their digital transformations.',
    permission: '',
    status: 'inactive',
    permission: 'Private',
  },
  {
    id: 3,
    name: 'Industry stats 02',
    content: 'Only 7% of companies have fully implemented their digital transformations.',
    permission: '',
    status: 'active',
    permission: 'System',
  },
];
