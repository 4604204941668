import * as sequenceTypes from './sequenceTypes';

export const clearSeqData = () => ({
  type: sequenceTypes.CLEAR_SEQUENCE_DATA,
});

export const fetchSequenceStats = (sequenceId) => ({
  type: sequenceTypes.FETCH_SEQUENCE_STATS,
  sequenceId,
});

export const setSequenceStats = (steps, enrollmentsStats) => ({
  type: sequenceTypes.SET_SEQUENCE_STATS,
  steps,
  enrollmentsStats,
});

export const enrollContactsToSequence = (sequenceId, contacts, resolve, reject) => ({
  type: sequenceTypes.ENROLL_CONTACTS_TO_SEQUENCE,
  sequenceId,
  contacts,
  resolve,
  reject,
});

export const sequenceBulkAction = (action, sequences, payload, resolve, reject) => ({
  type: sequenceTypes.SEQUENCE_BULK_ACTIONS,
  action,
  sequences,
  payload,
  resolve,
  reject,
});

export const setSequenceTab = (tab) => ({
  type: sequenceTypes.SET_SEQUENCE_TAB,
  tab,
});

export const handleEventsReorder = (sequenceId, event, eventOrder) => ({
  type: sequenceTypes.HANDLE_EVENTS_REORDER,
  sequenceId,
  event,
  eventOrder,
});

export const postSequenceEvent = (sequenceId, payload, resolve, reject) => ({
  type: sequenceTypes.POST_SEQUENCE_EVENT,
  sequenceId,
  payload,
  resolve,
  reject,
});

export const putSequenceEvent = (sequenceId, stepId, payload, resolve, reject) => ({
  type: sequenceTypes.PUT_SEQUENCE_EVENT,
  sequenceId,
  stepId,
  payload,
  resolve,
  reject,
});

export const deleteSequenceEvent = (sequenceId, stepId, resolve, reject) => ({
  type: sequenceTypes.DELETE_SEQUENCE_EVENT,
  sequenceId,
  stepId,
  resolve,
  reject,
});

export const fetchSequence = (seqId) => ({
  type: sequenceTypes.FETCH_SEQUENCE,
  seqId,
});

export const setSequence = (sequence = {}) => ({
  type: sequenceTypes.SET_SEQUENCE,
  sequence,
});

export const cloneSequence = (sequenceId, payload, resolve, reject) => ({
  type: sequenceTypes.CLONE_SEQUENCE,
  sequenceId,
  payload,
  resolve,
  reject,
});

export const postSequence = (sequence, resolve, reject) => ({
  type: sequenceTypes.POST_SEQUENCE,
  sequence,
  resolve,
  reject,
});

export const putSequence = (sequenceId, sequence, resolve, reject) => ({
  type: sequenceTypes.PUT_SEQUENCE,
  sequenceId,
  sequence,
  resolve,
  reject,
});

export const deleteSequence = (seqId, resolve, reject) => ({
  type: sequenceTypes.DELETE_SEQUENCE,
  seqId,
  resolve,
  reject,
});

export const fetchSequences = (paging, filters, tab, sort) => ({
  type: sequenceTypes.FETCH_SEQUENCES,
  paging,
  filters,
  tab,
  sort,
});

export const setSequences = (sequences, total) => ({
  type: sequenceTypes.SET_SEQUENCES,
  sequences,
  total,
});

export const fetchSequenceContacts = (paging, filters, seqId, minimum = false, sort) => ({
  type: sequenceTypes.FETCH_SEQUENCE_CONTACTS,
  paging,
  filters,
  seqId,
  minimum,
  sort,
});

export const setSequencesContacts = (data) => ({
  type: sequenceTypes.SET_SEQUENCE_CONTACTS,
  data,
});

export const fetchSequenceEvents = (sequenceId) => ({
  type: sequenceTypes.FETCH_SEQUENCE_EVENTS,
  sequenceId,
});

export const setSequenceEvents = (events) => ({
  type: sequenceTypes.SET_SEQUENCE_EVENTS,
  events,
});

export const fetchSequencesList = (contactId, audienceType) => ({
  type: sequenceTypes.FETCH_SEQUENCES_LIST,
  contactId,
  audienceType,
});

export const setSequencesList = (data) => ({
  type: sequenceTypes.SET_SEQUENCES_LIST,
  data,
});

export const isUnsubscribeCheck = (contactId, audienceType) => ({
  type: sequenceTypes.IS_UNSUBSCRIBE_CHECK,
  contactId,
  audienceType,
});

export const isUnsubscribeSet = (data) => ({
  type: sequenceTypes.IS_UNSUBSCRIBE_SET,
  data,
});
