import styled from 'styled-components';
import { List } from '@mui/material';
import { Button } from 'src/components/shared';

export const EmptyWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  flex-direction: column;
`;

export const EmptyIcon = styled.img`
  width: 320px;
  opacity: 0.5;
  margin-bottom: 24px;
`;

export const MenuList = styled(List).attrs({
  dense: true,
})``;

export const MenuContainer = styled.div`
  max-height: 50vh;
  overflow: auto;
`;

export const AddButton = styled(Button).attrs({
  fullWidth: true,
  color: 'secondary',
  size: 'large',
})`
  background: rgba(0, 0, 0, 0.04);
  margin-bottom: -8px;
`;
