import React, { Fragment } from 'react';
import { Col, Row, Span, useStyles } from './styles';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DoneIcon from '@mui/icons-material/Done';
import LaunchIcon from '@mui/icons-material/Launch';
import { Button, Drawer, Form, Modal, Spacer } from 'src/components/shared';
import PersonIcon from '@mui/icons-material/Person';
import { useDispatch, connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import ShelfTable from './ShelfTable';
import {
  fetchCRMConfig,
  RefreshSyncData,
  fetchSrNotes,
  fetchCRMNotes,
  fetchTenantConfigData,
  saveTenantConfig,
  updateTenantConfig,
  MappedNote,
  AddNoteAction,
  postAttribute,
} from 'src/modules/app/actions/appActions';
import images from 'src/config/images';
import Timeline from './Timeline';
import { Box, Card, Checkbox, Stack, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import toast from 'src/utils/toast';
import { cloneDeep, isObject, isEqual, isArray } from 'lodash';
import { disconnectIntegration } from '../../actions/adminActions';
import CustomFieldForm from '../../components/CustomFields/CustomFieldForm';

const TitleBar = ({ title, tooltip }) => {
  return (
    <Box display="flex" alignItems="center">
      <Typography variant="h4">{title}</Typography>
      {tooltip && tooltip !== '' ? (
        <>
          <Spacer y={1} x={1} />
          <Tooltip title={tooltip}>
            <InfoIcon style={{ fill: 'rgba(0, 0, 0, 0.26)', width: 22 }} />
          </Tooltip>
        </>
      ) : null}
    </Box>
  );
};

export const titleCase = (text) => text?.charAt(0).toUpperCase() + text?.substr(1).toLowerCase();
const TenantIntegrationUI = ({
  integrations,
  crmTenantData,
  crmFields,
  srNotes,
  crmNotes,
  modules,
  postAttribute,
  disconnectIntegration,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const integrationData = integrations?.data?.find((item) => {
    return item.name.toLowerCase().includes(params?.type?.toLowerCase());
  });
  const shelf = {
    ...(!['Salesforce', 'Hubspot']?.includes(integrationData?.name)
      ? { candidate: 'Candidates' }
      : {}),
    contact: 'Contacts',
    ...(integrationData?.name === 'Bullhorn' ? { lead: 'Leads' } : {}),
  };
  const crmKeys = [
    ...Object.keys(shelf)?.map(titleCase),
    ...(['Bullhorn', 'JobAdder'].includes(integrationData?.name) ? ['Note'] : []),
  ];
  const [openedShelf, setOpenedShelf] = React.useState(Object.keys(shelf)[0]);
  const [crmData, setCRMData] = React.useState({});
  const [appliedNotes, setAppliedNotes] = React.useState([]);
  const [integrationStep, setIntegrationStep] = React.useState(2);
  // const [feildMappingStep, setFieldMappingStep] = React.useState(1);
  // const [isNotesPanelAccessed, setIsNotesPanelAccessed] = React.useState(false);
  const [openDisconnectModal, setOpenDisconnectModal] = React.useState(false);
  const [isMappedNoteTooltipOpened, setIsMappedNoteTooltipOpened] = React.useState(false);
  const [customAttributeDrawer, setCustomAttributeDrawer] = React.useState(false);
  const classes = useStyles();
  const integrationType = integrationStep !== 4 ? openedShelf : 'note';
  const status = {
    CONNECT: 'Connect',
    CONNECTED: 'Connected',
    DISCONNECT: 'Disconnect',
  };
  const timelineSteps = [
    'Connect your CRM',
    'Field Mapping',
    'Auto-add settings',
    ...(['Bullhorn', 'JobAdder'].includes(integrationData?.name) ? ['Note Mapping'] : []),
  ];
  const onAddField = () => setCustomAttributeDrawer(true);

  const handleAddNoteAction = () => {
    dispatch(AddNoteAction(integrationData?.id, appliedNotes));
  };

  const handleRefreshSync = () => {
    if (integrationStep === 4) {
      if (integrationData?.name !== 'Vincere') {
        dispatch(fetchCRMNotes(integrationData?.name?.toLowerCase()));
        dispatch(fetchSrNotes());
      }
      return;
    }
    dispatch(RefreshSyncData(integrationData?.id));
  };

  React.useEffect(() => {
    if (integrationData?.status === 'inactive') return history.push('/admin/integrations');
    if (['Bullhorn', 'JobAdder'].includes?.(integrationData?.name)) {
      dispatch(fetchCRMNotes(integrationData?.name?.toLowerCase()));
      dispatch(fetchSrNotes());
    }
    dispatch(fetchCRMConfig(integrationData?.name?.toLowerCase()));
    dispatch(fetchTenantConfigData(integrationData?.id));
  }, []);

  React.useEffect(() => {
    history.block(() => {
      if (
        crmTenantData?.id &&
        isDiffInArray(
          crmData?.[titleCase(integrationType)],
          crmTenantData?.config?.[titleCase(integrationType)],
        )
      ) {
        toast.error('Please save your field mappings before you leave.');
        return false;
      } else return true;
    });
    return () => {
      history.block(() => {
        if (
          crmTenantData?.id &&
          isDiffInArray(
            crmData?.[titleCase(integrationType)],
            crmTenantData?.config?.[titleCase(integrationType)],
          )
        ) {
          toast.error('Please save your field mappings before you leave.');
          return false;
        } else return true;
      });
    };
  }, [history, crmData]);

  React.useEffect(() => {
    async function fetchData() {
      const isDefaultRender = !crmTenantData?.id;
      const defaultTableData = {
        ...crmFields?.[integrationData?.name?.toLowerCase()]?.defaultMappings,
        note: srNotes?.map((ele) => {
          return { srKey: ele.value, status: false, crmKey: '' };
        }),
      };
      const initialTableData = crmTenantData?.config;
      let CRMData = {};
      let data = isDefaultRender ? defaultTableData : initialTableData;
      Object.keys(data)?.map((key) => (CRMData[titleCase(key)] = data[key]));
      setCRMData(CRMData);
    }
    if (crmFields && crmTenantData) fetchData();
  }, [crmFields, crmTenantData, srNotes]);

  const settingsDiff = (newSettings, originalSettings, ignoreList = []) => {
    let _newSettings = cloneDeep(newSettings);
    let _originalSettings = cloneDeep(originalSettings);
    ignoreList.forEach((ignore_attribute) => {
      delete _newSettings?.[ignore_attribute];
      delete _originalSettings?.[ignore_attribute];
    });
    let changedSettings = {};
    Object.keys(_newSettings).forEach((setting) => {
      let newSetting = _newSettings[setting];
      let originalSetting = _originalSettings[setting];

      if (isObject(newSetting)) {
        if (!isEqual(originalSetting, newSetting)) changedSettings[setting] = newSetting;
      } else if (isArray(newSetting)) {
        if (!isEqual(originalSetting, newSetting)) changedSettings[setting] = newSetting;
      } else if (originalSetting !== newSetting) {
        changedSettings[setting] = newSetting;
      }
    });
    return { changed: Object.keys(changedSettings).length > 0, changedSettings };
  };

  const isDiffInArray = (arr = [], newArr = []) => {
    if (arr.length !== newArr.length) return 'new';
    const arrObj = {};
    const newArrObj = {};
    arr.forEach((ele) => (arrObj[ele?.srKey] = ele));
    newArr.forEach((ele) => (newArrObj[ele?.srKey] = ele));
    const differnet = Object.keys(arrObj).find((srKey) => {
      if (settingsDiff(arrObj[srKey], newArrObj[srKey], ['sortOrder'])?.changed) return true;
    });
    return differnet;
  };

  const submitAddSettings = (values) => {
    const updatedData = {
      tenantIntegration: integrationData?.id,
      ...values,
    };

    dispatch(
      !crmTenantData?.id
        ? saveTenantConfig({ data: updatedData })
        : updateTenantConfig({
            id: crmTenantData?.id,
            data: updatedData,
          }),
    );
  };

  const saveTenantIntegrations = async () => {
    if (!openedShelf) return null;
    const defautData = {};

    if (integrationStep !== 3)
      Object.keys(crmData)?.forEach((type) => {
        type = titleCase(type);
        if (!crmKeys.includes(type)) return null;
        defautData[type] = (crmData[type] || []).map(
          ({
            crmKey = '',
            crmType = '',
            srKey = '',
            srType = '',
            fixed = false,
            status = false,
            mandatory = false,
          }) => {
            return type === 'Note'
              ? {
                  crmKey,
                  srKey,
                  status,
                }
              : {
                  crmKey,
                  crmType,
                  fixed,
                  mandatory,
                  srKey,
                  srType,
                  status: status || fixed,
                };
          },
        );
        // .filter((ele) => (type === 'Note' ? true : ele.status));
      });

    const updatedData = {
      tenantIntegration: integrationData?.id,
      config: defautData,
    };

    dispatch(
      !crmTenantData?.id
        ? saveTenantConfig({
            data: updatedData,
          })
        : updateTenantConfig({
            id: crmTenantData?.id,
            data: updatedData,
          }),
    );
  };

  const handleDisconnect = () => {
    disconnectIntegration(integrationData?.id, integrationData?.status);
    history.push('/admin/integrations');
  };

  const openIntegGuideUrl = () => {
    let url = '';
    if (integrationData?.name === 'Bullhorn')
      url = 'https://help.superreach.com/en/articles/7890926-setting-up-your-bullhorn-integration';
    else if (integrationData?.name === 'Vincere')
      url = 'https://help.superreach.com/en/articles/7842395-setting-up-your-vincere-integration';
    else if (integrationData?.name === 'Salesforce')
      url =
        'https://help.superreach.com/en/articles/7842395-setting-up-your-salesforce-integration';
    else if (integrationData?.name === 'Hubspot')
      url = 'https://help.superreach.com/en/articles/7842395-setting-up-your-hubspot-integration';
    else if (integrationData?.name === 'JobAdder')
      url = 'https://help.superreach.com/en/articles/7843690-setting-up-your-jobadder-integration';
    else url = 'https://help.superreach.com';
    window.open(url, '_blank');
  };

  const openCustomMappingUrl = () => {
    let url = '';
    if (integrationData?.name === 'Bullhorn')
      url = 'https://help.superreach.com/en/articles/7837752-learn-about-custom-mapping';
    else if (integrationData?.name === 'Vincere')
      url = 'https://help.superreach.com/en/articles/7837752-learn-about-custom-mapping';
    else if (integrationData?.name === 'Salesforce')
      url = 'https://help.superreach.com/en/articles/7837752-learn-about-custom-mapping';
    else if (integrationData?.name === 'Hubspot')
      url = 'https://help.superreach.com/en/articles/7837752-learn-about-custom-mapping';
    else if (integrationData?.name === 'JobAdder')
      url = 'https://help.superreach.com/en/articles/7837752-learn-about-custom-mapping';
    else url = 'https://help.superreach.com/en/articles/7837752-learn-about-custom-mapping';

    window.open(url, '_blank');
  };

  const onStepChange = (step) => {
    setIntegrationStep(step);
  };

  const noteCrmObject =
    integrationStep === 4
      ? crmData?.[titleCase(integrationType)]?.reduce((acc, item) => {
          acc[item?.crmKey] = item;
          return acc;
        }, {})
      : {};

  return (
    <Col className={classes.IntegrationWrapper}>
      <Row onClick={() => history.push('/admin/integrations')} className={classes.GoBackWrapper}>
        <NavigateBeforeIcon className={classes.navgateBack} /> Back
      </Row>
      <Col className={classes.IntegrationContainer}>
        <Col className={classes.Header}>
          <Row className={classes.headerTitle}>
            <Row style={{ alignItems: 'center', gap: 10 }}>
              <img
                src={images.integration[integrationData?.name?.toLowerCase()]}
                style={{ width: 40 }}
              />
              <Span>Manage your {integrationData?.name} Integration</Span>
            </Row>
            <Row style={{ gap: 24, alignItems: 'center' }}>
              <Button className={classes.primaryButton}>
                <DoneIcon />
                {status.CONNECTED}
              </Button>
              <Button onClick={() => setOpenDisconnectModal(true)} className={classes.ghostButton}>
                {status.DISCONNECT}
              </Button>
            </Row>
          </Row>
          <Span className={classes.headerDescription}>
            Manage the integration between SuperReach and {integrationData?.name} to ensure you sync
            your data, your way, and maintain your own internal best practices. There are three easy
            steps to success, and if you need any further support please refer to the
            <Button className={classes.linkButton} onClick={openIntegGuideUrl}>
              {integrationData?.name} integration guide. <LaunchIcon />
            </Button>
          </Span>
        </Col>

        <Timeline steps={timelineSteps} current={integrationStep} onStepChange={onStepChange} />

        <Row className={classes.ContentWrapper}>
          {integrationStep === 2 && (
            <Fragment>
              <Col className={'leftSide'}>
                {Object.keys(shelf)?.map((fieldName, index) => {
                  return (
                    <Row
                      onClick={() => {
                        if (
                          isDiffInArray(
                            crmTenantData?.id && crmData?.[titleCase(integrationType)],
                            crmTenantData?.config?.[titleCase(integrationType)],
                          )
                        )
                          return toast.error('Please save your field mappings before you leave.');
                        setOpenedShelf(fieldName);
                        // setFieldMappingStep(index + 1);
                      }}
                      className={`${openedShelf === fieldName && 'active'} wrapper`}
                    >
                      <PersonIcon />
                      <Span>{shelf[fieldName]}</Span>
                    </Row>
                  );
                })}
              </Col>
              <Span className="divider"></Span>
            </Fragment>
          )}

          <Col className={'rightSide'}>
            {integrationStep !== 3 && (
              <Row className="header-wrapper">
                <Span className="title">
                  {integrationStep !== 4 ? shelf[openedShelf] : 'Note'} Field Mapping
                </Span>
                <Row className={'btn-group'}>
                  <Button onClick={handleRefreshSync} className={classes.linkButton}>
                    <Row style={{ gap: 6 }}>
                      <Tooltip
                        placement="top"
                        arrow
                        title={
                          <Span
                            style={{
                              whiteSpace: 'pre-line',
                              fontSize: 12,
                            }}
                          >
                            {`Click to check for
                          new fields on ${titleCase(integrationData?.name)}`}
                          </Span>
                        }
                      >
                        <Span>Refresh sync </Span>
                      </Tooltip>
                    </Row>
                  </Button>
                  {integrationStep !== 4 && (
                    <Button className={classes.linkButton} onClick={onAddField}>
                      <ControlPointIcon />
                      <Span>Add custom field</Span>
                    </Button>
                  )}
                </Row>
              </Row>
            )}

            {integrationStep !== 3 && (
              <Span className="description">
                Map your {integrationType} fields with SuperReach for a seamless integration
                experience. We’ve added default mappings to accelerate your setup process. For a
                deeper integration, you can add additional fields and create a custom mapping.{' '}
                <Button className={classes.linkButton} onClick={openCustomMappingUrl}>
                  Learn about custom mapping <LaunchIcon />
                </Button>
              </Span>
            )}

            {integrationStep !== 3 && (
              <>
                <Row style={{ justifyContent: 'space-between', gap: 36 }}>
                  <ShelfTable
                    onDataChange={(type, value) =>
                      setCRMData((crmData) => {
                        return { ...crmData, [titleCase(type)]: value };
                      })
                    }
                    type={integrationType}
                  />

                  {integrationStep === 4 && (
                    <Col style={{ flex: 1, maxWidth: 350 }}>
                      <Box
                        style={{
                          padding: '10px 20px',
                          fontSize: 14,
                          display: 'flex',
                          alignItems: 'center',
                          fontWeight: 500,
                          border: '1px solid #dcdcdc',
                        }}
                      >
                        <Tooltip
                          open={isMappedNoteTooltipOpened}
                          arrow
                          onOpen={() => setIsMappedNoteTooltipOpened(true)}
                          describeChild
                          classes={{ tooltip: classes.noteTypeTooltip }}
                          placement="left-start"
                          title={
                            <Col className="tooltipWrapper">
                              <Row style={{ gap: 12 }}>
                                <InfoOutlinedIcon style={{ fill: 'white', fontSize: 32 }} />
                                <Span style={{ fontSize: 28 }}>
                                  {integrationData?.name} notes not mapped
                                </Span>
                              </Row>
                              <Span
                                style={{
                                  whiteSpace: 'pre-line',
                                  fontSize: 20,
                                  color: 'white',
                                }}
                              >
                                {`The note actions shown here, exist in ${integrationData?.name}, but have not been mapped to a SR note. If there are no suitable note types on SR, click to add to SR and we will match them on ${integrationData?.name}.

                              Please remember to check the SR notes before choosing to add these notes to SR to avoid duplication.

                              Not all note actions need to be active or mapped. However, any that are not active or mapped cannot be used in the integration.`}
                              </Span>
                              <Row style={{ justifyContent: 'flex-end' }}>
                                <Button
                                  style={{
                                    background: 'white',
                                    borderRadius: 6,
                                    fontSize: 16,
                                    fontWeight: 500,
                                    padding: '8px 20px',
                                  }}
                                  onClick={() => setIsMappedNoteTooltipOpened(false)}
                                >
                                  Got it!
                                </Button>
                              </Row>
                            </Col>
                          }
                        >
                          <Row style={{ gap: 8, padding: '0 10px', alignItems: 'center' }}>
                            <Span>{integrationData?.name} notes not mapped</Span>
                            <InfoIcon style={{ fill: 'rgba(0, 0, 0, 0.26)', width: 24 }} />
                          </Row>
                        </Tooltip>
                      </Box>
                      <Box
                        style={{ padding: '10px 20px', fontSize: 14, border: '1px solid #dcdcdc' }}
                      >
                        <Col>
                          {crmNotes
                            ?.filter((ele) => !noteCrmObject?.[ele.value])
                            ?.map((ele) => {
                              return (
                                <Row style={{ alignItems: 'center' }}>
                                  <Checkbox
                                    onClickCapture={() => {
                                      const notes = appliedNotes.includes(ele.value)
                                        ? appliedNotes?.filter((item) => item !== ele.value)
                                        : [...appliedNotes, ele.value];
                                      setAppliedNotes(notes);
                                      dispatch(MappedNote(integrationData?.id, notes));
                                    }}
                                    checked={appliedNotes.includes(ele.value)}
                                    className={classes.Checkbox}
                                  />
                                  <Span>{ele.label}</Span>
                                </Row>
                              );
                            })}
                        </Col>
                        <Row style={{ justifyContent: 'flex-end', marginTop: 12 }}>
                          <Button onClick={handleAddNoteAction} className={classes.linkButton}>
                            Add Note Action
                          </Button>
                        </Row>
                      </Box>
                    </Col>
                  )}
                </Row>
                <Row style={{ justifyContent: 'right' }}>
                  <Button className={classes.secondaryButton} onClick={saveTenantIntegrations}>
                    Save
                  </Button>
                </Row>
              </>
            )}

            {integrationStep === 3 && (
              <Form
                initialValues={{
                  crmCreateCompany: crmTenantData?.crmCreateCompany || false,
                  crmAddWithoutMandatoryFields:
                    crmTenantData?.crmAddWithoutMandatoryFields || false,
                  crmCheckDuplicatesInAllTypes:
                    crmTenantData?.crmCheckDuplicatesInAllTypes || false,
                }}
                enableReinitialize={true}
                onSubmit={submitAddSettings}
              >
                {({ values, ...formProps }) => {
                  return (
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        formProps.submitForm();
                        return false;
                      }}
                      noValidate
                    >
                      <Stack gap={'24px'}>
                        <Typography>
                          Manage the syncing behaviour when automatically adding contact profiles to{' '}
                          {integrationData?.name}.
                        </Typography>
                        <Card padding={'8px'}>
                          <Stack padding={'16px'} backgroundColor={'#1976D20A'}>
                            <Typography fontSize="18px" fontWeight={500}>
                              Profile Type Doesn’t Match
                            </Typography>
                          </Stack>
                          <Stack padding={'0 16px 16px'}>
                            <Form.Field.Radio
                              name="crmCheckDuplicatesInAllTypes"
                              color="secondary"
                              options={[
                                {
                                  value: true,
                                  label: (
                                    <TitleBar
                                      title={
                                        <Typography fontSize="16px" color="black" fontWeight={500}>
                                          Connect with the existing CRM record
                                        </Typography>
                                      }
                                      tooltip="We will connect the new contact profile 
                                    to the existing CRM record, regardless 
                                    of type. (Contact, Candidate etc.)"
                                    />
                                  ),
                                },
                                {
                                  value: false,
                                  label: (
                                    <TitleBar
                                      title={
                                        <Typography fontSize="16px" color="black" fontWeight={500}>
                                          Create a new CRM record
                                        </Typography>
                                      }
                                      tooltip="We will create a new CRM contact record based 
                                    on your settings (Contact, Candidate etc). 
                                    Be aware that this may result in duplicates in the CRM."
                                    />
                                  ),
                                },
                              ]}
                              value={values?.crmCheckDuplicatesInAllTypes}
                              row={false}
                            />
                          </Stack>
                        </Card>
                        <Card padding={'8px'}>
                          <Stack padding={'16px'} backgroundColor={'#1976D20A'}>
                            <Typography fontSize="18px" fontWeight={500}>
                              Company Doesn’t Match
                            </Typography>
                          </Stack>
                          <Stack padding={'12px 36px 20px'}>
                            <Form.Field.Switch
                              name="crmCreateCompany"
                              label={
                                <TitleBar
                                  title={
                                    <Typography fontSize="16px" color="black" fontWeight={500}>
                                      Create new company in CRM
                                    </Typography>
                                  }
                                  tooltip="We will generate a new company in your CRM based 
                                    on the SuperReach record. Be aware that this may 
                                    result in duplicate company records in the CRM."
                                />
                              }
                            />
                          </Stack>
                        </Card>
                        <Card padding={'8px'}>
                          <Stack padding={'16px'} backgroundColor={'#1976D20A'}>
                            <Typography fontSize="18px" fontWeight={500}>
                              Mandatory Fields
                            </Typography>
                            <Stack direction="row">
                            <Typography>
                              Please note that we cannot ignore system mandatory fields.
                            </Typography>
                            <TitleBar
                              title={<Typography />}
                              tooltip="JobAdder and Vincere require an email address to add a contact, therefore if this field is missing, SuperReach won't be able to create that contact automatically."
                            />
                            </Stack>
                          </Stack>
                          <Stack padding={'12px 36px 20px'}>
                            <Form.Field.Switch
                              name="crmAddWithoutMandatoryFields"
                              label={
                                <TitleBar
                                  title={
                                    <Typography fontSize="16px" color="black" fontWeight={500}>
                                      Ignore mandatory fields
                                    </Typography>
                                  }
                                  tooltip="Overwrite the custom mandatory field rules to automatically add contact profiles to your CRM."
                                />
                              }
                            />
                          </Stack>
                        </Card>
                        <Stack alignItems={'end'}>
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{ width: 'fit-content' }}
                            type="submit"
                          >
                            Save
                          </Button>
                        </Stack>
                      </Stack>
                    </form>
                  );
                }}
              </Form>
            )}
          </Col>
        </Row>
      </Col>
      <Modal
        title={'Disconnect - ' + integrationData?.name}
        size="xs"
        open={openDisconnectModal}
        onClose={() => setOpenDisconnectModal(false)}
      >
        <Col style={{ gap: 8 }}>
          <Span>
            Are you sure you want to disconnect? All services with {integrationData?.name} will
            stop.
          </Span>
          <Row style={{ justifyContent: 'end' }}>
            <Button onClick={handleDisconnect} className={classes.secondaryButton}>
              Confirm
            </Button>
          </Row>
        </Col>
      </Modal>
      <Drawer
        open={customAttributeDrawer}
        title={'Create custom attribute'}
        onClose={() => setCustomAttributeDrawer(false)}
      >
        <CustomFieldForm
          // {...props}
          postAttribute={postAttribute}
          modules={modules}
          module="contacts"
          onClose={() => setCustomAttributeDrawer(false)}
        />
      </Drawer>
    </Col>
  );
};

const mapStateToProps = (state) => ({
  integrations: state.admin.admin.integrations,
  crmFields: state.app.crmFields,
  srNotes: state.app.srNotes,
  crmNotes: state.app.crmNotes,
  crmTenantData: state.app.crmTenantData,
  modules: state.app.modules,
});

const mapDispatchToProps = (dispatch) => ({
  disconnectIntegration: (id, status) => dispatch(disconnectIntegration(id, status)),
  postAttribute: (payload) => {
    return new Promise((resolve, reject) => {
      dispatch(postAttribute(payload, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TenantIntegrationUI);
