import api from 'src/api';
import { convertObjectToQuerystring } from 'src/utils/helper';

export const fetchEventsDetails = (filters) => {
  let filter = `?${convertObjectToQuerystring(filters)}`;
  return api(`/calenderevent${filter}`, null, 'get');
};

export const updateEventsDetails = (id, data) => {
  return api(`/calenderevent/${id}`, data, 'put');
};

export const addEventsDetails = (data) => {
  return api(`/calenderevent`, data, 'post');
};
