import React, { useState } from 'react';
import * as Yup from 'yup';
import { Typography, Box } from '@mui/material';
import { Form, Button, Spacer } from 'src/components/shared';

function CloneBoardForm({ board, onClose, cloneBoard, postBoard }) {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Form
        initialValues={{
          board: board,
          name: `${board.name} (copy)`,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Please enter board name!'),
        })}
        onSubmit={async (values, form) => {
          try {
            setLoading(true);
            const newBoard = {
              name: values.name,
              isDeleted: false,
              description: values.board.name,
              panels: values.board.panels,
              sharedWithTeams: values.board.sharedWithTeams.map((k) => k.id),
              sharedWithUsers: values.board.sharedWithUsers.map((k) => k.id),
              tabColor: values.board.tabColor,
              type: values.board.type,
              widgets: values.board.widgets.length
                ? values.board.widgets.map((item) => item.id)
                : [],
              widgetsConfig: values.board.widgetsConfig,
              category: values.board.category,
              accessType: values.board.accessType,
              accessType: values.board.accessType,
              pinned: values.board.pinned,
            };
            await cloneBoard(newBoard);
            setLoading(false);
            onClose();
          } catch (error) {
            setLoading(false);
          }
        }}
        enableReinitialize={true}
      >
        {({ values, ...formProps }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formProps.submitForm();
                return false;
              }}
              style={{ height: '100%' }}
              noValidate
            >
              <Form.Field.AutoComplete
                options={[board]}
                disabled={true}
                name="board"
                variant="outlined"
                label="Board"
                fullWidth={true}
                optLabel="name"
                optValue="id"
              />
              <Box my={1} mx={4}>
                <Typography color="textSecondary" variant="body2" textAlign="center">
                  This will copy all the widgets, panels with all the settings to the new board.
                </Typography>
              </Box>
              <Form.Field.Input
                name="name"
                variant="outlined"
                label="New Board Name"
                fullWidth={true}
              />
              <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2}>
                <Button variant="outlined" color="secondary" onClick={onClose} disabled={loading}>
                  Cancel
                </Button>
                <Spacer basis={2} />
                <Button type="submit" variant="contained" color="secondary" loading={loading}>
                  Save
                </Button>
              </Box>
            </form>
          );
        }}
      </Form>
    </>
  );
}

export default CloneBoardForm;
