import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Stack } from '@mui/material';
import { Spacer } from 'src/components/shared';
import { useDataProviders } from '../../utils/billingStore';
import toast from 'src/utils/toast';

import CreditRecharge from '../../components/Billing/CreditRecharge';
import SubscriptionCard from '../../components/Billing/SubscriptionCard';

const cbInstance = Chargebee.init({
  site: process.env.REACT_APP_CHARGEBEE_ID,
  isItemsModel: true,
});

function BillingSubscriptions() {
  const [loading, setLoading] = useState(undefined);

  const tenant = useSelector((state) => state.app.tenant);
  const user = useSelector((state) => state.auth.user);

  const hasLmsAccess = useMemo(() => {
    return user?.lmsmonthly === true || user?.lmsannual === true;
  }, [user]);

  const hasOutreachAccess = useMemo(() => {
    if (!user?.outreachAnnual && !user?.outreachMonthly && !hasLmsAccess) return true;
    return user?.outreachAnnual === true || user?.outreachMonthly === true;
  }, [user]);

  const chargeBeeId = tenant?.chargeBeeId || '';
  const [showCreditRecharge, setShowCreditRecharge] = useState(undefined);

  const { getAllSubscriptions, getSubscriptions, allSubscriptions, subscriptions, getHostedPage } =
    useDataProviders();

  useEffect(() => {
    getAllSubscriptions();
  }, []);

  useEffect(() => {
    getSubscriptions(chargeBeeId);
  }, [chargeBeeId]);

  const initiatePayment = async (planId, subscription, isCharge) => {
    try {
      setShowCreditRecharge(undefined);
      cbInstance.openCheckout({
        hostedPage: function () {
          return new Promise(function (resolve, reject) {
            setLoading(planId);
            const quantity = subscription?.quantity || 0;
            getHostedPage(planId, subscription, isCharge, quantity)
              .then((res) => {
                setLoading(undefined);
                resolve(res);
              })
              .catch((err) => {
                setLoading(undefined);
                toast.error(err?.error?.message || 'An error ocurred! Please try again.');
              });
          });
        },
        close: function () {
          window.location.reload();
        },
      });
    } catch (error) {
      toast.error(error?.message || 'An error ocurred! Please try again.');
    }
  };

  const toggleRechargeModal = (type) => {
    if (type && type !== '') {
      setShowCreditRecharge(type);
    } else {
      setShowCreditRecharge(false);
    }
  };

  const subscriptionOrder = ['Outreach', 'LMS', 'Data Credits', 'Roleplay Minutes'];

  return (
    <>
      <Stack sx={{ width: 'clamp(250px, 50%, 550px)', gap: '30px' }}>
        {allSubscriptions
          ?.sort((a, b) => {
            const typeA = a?.title;
            const typeB = b?.title;
            return subscriptionOrder.indexOf(typeA) - subscriptionOrder.indexOf(typeB);
          })
          ?.flatMap((plan, index) => {
            if (plan.type === 'one-time' && plan?.recharge === 'data' && !hasOutreachAccess)
              return [];
            else if (plan.type === 'one-time' && plan?.recharge === 'roleplay' && !hasLmsAccess)
              return [];
            else
              return (
                <Grid item xs={5}>
                  <SubscriptionCard
                    subscriptions={subscriptions}
                    key={`subscription-card-${index}`}
                    plan={plan}
                    initiatePayment={initiatePayment}
                    loading={loading}
                    tenant={tenant}
                    toggleRechargeModal={toggleRechargeModal}
                  />
                </Grid>
              );
          })}
      </Stack>
      <Spacer x={4} y={4} />
      <CreditRecharge
        open={showCreditRecharge}
        onClose={toggleRechargeModal}
        initiatePayment={initiatePayment}
        loading={loading}
      />
    </>
  );
}

export default BillingSubscriptions;
