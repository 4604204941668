import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { Spacer, Button } from 'src/components/shared';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDataProviders } from 'src/modules/admin/utils/billingStore';
import toast from 'src/utils/toast';

const cbInstance = Chargebee.init({
  site: process.env.REACT_APP_CHARGEBEE_ID,
  isItemsModel: true,
});

function ConfirmPlan({ tenant, user, onNext, verification }) {
  const { getHostedPage } = useDataProviders();

  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   cbInstance.setCheckoutCallbacks(function (cart) {
  //     return {
  //       loaded: function () {
  //         console.log('checkout opened');
  //       },
  //       close: function () {
  //         onNext();
  //       },
  //       success: function (hostedPageId) {
  //         console.log('checkout SUCCESS');
  //       },
  //       step: function (value) {
  //         console.log('step : ', value);
  //       },
  //     };
  //   });
  // }, []);

  const { chargeBeePlans } = tenant;
  if (!chargeBeePlans || !chargeBeePlans.length) {
    return null;
  }

  const plan = chargeBeePlans[0];

  const initiatePayment = () => {
    try {
      cbInstance.openCheckout({
        hostedPage: function () {
          return new Promise(function (resolve, reject) {
            setLoading(true);
            getHostedPage(
              plan.planId,
              null,
              false,
              parseInt(plan.seats) || 1,
              plan?.discount || undefined,
            )
              .then((res) => {
                setLoading(false);
                resolve(res);
              })
              .catch((err) => {
                setLoading(false);
                toast.error(err?.error?.message || 'An error ocurred! Please try again.');
              });
          });
        },
        error: () => {
          toast.error('An error ocurred! Please try again.');
        },
        success: function () {
          onNext();
        },
        close: function () {},
      });
    } catch (error) {
      toast.error(error?.message || 'An error ocurred! Please try again.');
    }
  };

  const initiatePaymentBack = async () => {
    try {
      if (verification?.billingSetupRequired === true) {
        const cart = cbInstance.getCart();
        const customer = {
          // id: tenant.id,
          first_name: user.fname,
          last_name: user.lname,
          email: user?.email,
          billing_address: {
            email: user?.email,
            first_name: user.fname,
            last_name: user.lname,
          },
        };
        cart.setCustomer(customer);
        const product = cbInstance.initializeProduct(plan.planId, plan.seats);
        // if (plan?.discount && plan?.discount !== '') {
        //   product.addCoupon(plan?.discount);
        // }

        cart.replaceProduct(product);
        cart.proceedToCheckout();
      } else {
        onNext();
      }
    } catch (error) {}
  };

  return (
    <Box mt={8} flex={1} display="flex" justifyContent="center" alignItems="center" width="100%">
      <Card sx={{ maxWidth: 500, flex: 1, background: '#242729' }}>
        <CardMedia
          component="img"
          // height="194"
          image={plan.image}
        />
        <CardContent>
          <Stack direction="row" justifyContent="space-between">
            <Typography sx={{ fontWeight: '500' }}>{plan.title}</Typography>
            <Typography>{plan.seats} X Licenses</Typography>
          </Stack>
          <Stack direction="column" alignItems="flex-end" mt={0.5}>
            <Typography variant="body2" color="textSecondary">
              £{plan.price}/{plan.type === 'monthly' ? 'mo' : 'yr'} per license
            </Typography>
            {plan.type === 'monthly' ? (
              <Typography variant="body2" color="textSecondary">
                Monthly contract
              </Typography>
            ) : (
              <Typography variant="body2" color="textSecondary">
                12 month contract (Paid Annually)
              </Typography>
            )}
          </Stack>
          <Spacer x={2} y={2} />
          <Divider sx={{ borderColor: '#FFFFFF1F' }} />
          <Spacer x={2} y={2} />
          <Stack direction="row" justifyContent="space-between">
            <Typography color="textSecondary">Total due:</Typography>
            <Typography>£{plan.total_price || 0}</Typography>
          </Stack>
          <Spacer x={2} y={2} />
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            fullWidth
            size="large"
            endIcon={<ArrowForwardIcon />}
            onClick={initiatePayment}
            loading={loading}
          >
            Next
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
}

export default ConfirmPlan;
