import React, { useRef } from 'react';
import { Grid, Box, Typography, Stack, Chip } from '@mui/material';
import * as Yup from 'yup';
import { Button, Form, Spacer } from 'src/components/shared';
import AddIcon from '@mui/icons-material/Add';
import { Drawer } from 'src/components/shared';
import { useState } from 'react';
import HoliDayForm from './HoliDayForm';
import { useEffect } from 'react';
import HolidayGrid from './HolidayGrid';

export default function HoliDay({ holidays, id, ...props }) {
  const [drawerOpen, setDrawerOpen] = useState({ edit: null, open: false });
  const [search, setNewSearch] = useState('');
  const [holidaysUserData, setHolidaysUserData] = useState(holidays || []);

  useEffect(() => {
    if (search) {
      const filtered = holidaysUserData.map((day) => ({
        ...day,
        hide: day.event.toLowerCase().includes(search.toLowerCase()) ? false : true,
      }));
      setHolidaysUserData(filtered);
    } else {
      const unfiltered = holidaysUserData.map((day) => ({
        ...day,
        hide: false,
      }));
      setHolidaysUserData(unfiltered);
    }
  }, [search]);

  const onCheckClick = async (event, index) => {
    //const checked = event.target.checked;
    const updatedData = holidaysUserData.map(({ hide, ...k }, i) =>
      i === index ? { ...k, isActive: !k.isActive } : k,
    );
    await props.updateSettings({ holidays: updatedData }, id);
  };

  const onUpdateData = async (data, index) => {
    const updatedData = holidaysUserData.map(({ hide, ...k }, i) =>
      i === index ? { ...k, ...data } : k,
    );
    setHolidaysUserData(updatedData);
    await props.updateSettings({ holidays: updatedData }, id);
  };

  const onDelete = async (index) => {
    const updatedData = holidaysUserData.filter((k, i) => i !== index);
    setHolidaysUserData(updatedData);
    await props.updateSettings({ holidays: updatedData }, id);
  };

  const onAddData = async (data) => {
    await props.updateSettings({ holidays: [...holidaysUserData, data] }, id);
  };

  const onFilterChange = (key, value) => {
    if (key === '_search') {
      setNewSearch(value);
    }
  };

  return (
    <Box key={id} style={{ width: '100%' }}>
      <HolidayGrid
        holidays={holidaysUserData?.filter((k) => !k.hide)}
        onClickAddHoliday={() => {
          setDrawerOpen({
            edit: { name: 'Add Holidays By Date and Title' },
            open: true,
          });
        }}
        onFilterChange={onFilterChange}
        onEdit={(index) => {
          setDrawerOpen({
            edit: { ...holidaysUserData[index], index, name: 'Edit Holiday' },
            open: true,
          });
        }}
        onDelete={onDelete}
        onCheckClick={onCheckClick}
      />
      <Drawer
        title={`${drawerOpen.edit?.name}`}
        open={drawerOpen.open}
        onClose={() => {
          setDrawerOpen({ edit: null, open: false });
        }}
      >
        <HoliDayForm
          id={id}
          drawerOpen={setDrawerOpen}
          holiday={drawerOpen}
          onUpdateData={onUpdateData}
          onAddData={onAddData}
          {...props}
        />
      </Drawer>
    </Box>
  );
}
