import React, { useState, useMemo, useEffect } from 'react';
import { Stack } from '@mui/material';
import { Modal, Button, Form } from 'src/components/shared';
import Card from './Card';

import { updateTemplate } from 'src/modules/admin/api/adminApi';
import { updateTask } from 'src/modules/tasks/api/taskApis';

import toast from 'src/utils/toast';
import { editSequenceStep } from 'src/modules/sequence/api/sequenceApi';
import { fetchFile } from 'src/modules/app/api/appApis';

function SequencePreview({ sequence, sequenceEvents, onClose, ...props }) {
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [waitTimes, setWaitTimes] = useState([]);
  const events = useMemo(() => {
    return Object.values(sequenceEvents);
  }, [sequenceEvents]);

  const liTypes = [
    'linkedin',
    'linkedinConnection',
    'linkedinMessage',
    'linkedinMail',
    'linkedinViewProfile',
  ];

  useEffect(() => {
    fetchInitialValues();
    calculateWaitTimes();
  }, []);

  const calculateWaitTimes = () => {
    const data = Object.values(sequenceEvents);

    const res = [];
    let waitTime = 0;
    if (data && data.length) {
      for (let i = 0; i < data.length; i++) {
        const event = data[i];
        if (event.eventType === 'wait') {
          switch (event.unit) {
            case 'seconds':
              waitTime += event.waitTime;
              break;
            case 'minutes':
              waitTime += event.waitTime * 60;
              break;
            case 'hours':
              waitTime += event.waitTime * 60 * 60;
              break;
            case 'days':
              waitTime += event.waitTime * 60 * 60 * 24;
              break;
            case 'weeks':
              waitTime += event.waitTime * 60 * 60 * 24 * 7;
              break;
            case 'months':
              waitTime += event.waitTime * 60 * 60 * 24 * 30;
              break;
            case 'years':
              waitTime += event.waitTime * 60 * 60 * 24 * 365;
              break;
          }
        }
        res.push(waitTime);
      }
      setWaitTimes(res);
    }
  };

  const fetchInitialValues = async () => {
    try {
      const data = Object.values(sequenceEvents);
      let res = {};
      if (data && data.length) {
        for (let i = 0; i < data.length; i++) {
          const item = data[i];
          switch (true) {
            case item.eventType === 'general' ||
              item.eventType === 'sms' ||
              item.eventType === 'call':
              res[item.id] = {
                taskId: item.id,
                description: item.description,
                eventType: item.eventType,
              };
              break;

            case liTypes.includes(item.eventType):
              res[item.id] = {
                eventType: item.eventType,
                taskId: item.id,
                templateId: item?.templates?.id || '',
                template: item?.templates || {},
                subject: item?.templates?.subject || '',
                content: item?.templates?.content || '',
              };
              break;

            case item.eventType === 'wait':
              res[item.id] = {
                eventType: item.eventType,
                taskId: item.id,
                waitTime: item.waitTime,
                unit: item.unit,
              };
              break;

            case item.eventType === 'email':
              let tempData = {};
              for (let x = 0; x < item.emailTemplates.length; x++) {
                const templateItem = item.emailTemplates[x];
                let attachments = [];
                if (templateItem.attachments && templateItem.attachments.length) {
                  const promises = [];
                  for (let k = 0; k < templateItem.attachments.length; k++) {
                    promises.push(fetchFile(templateItem.attachments[k]));
                  }
                  attachments = await Promise.all(promises);
                  attachments = attachments.map((item) => item.upload);
                }
                tempData[templateItem.id] = {
                  template: templateItem,
                  templateId: templateItem.id,
                  content: templateItem.content,
                  subject: templateItem.subject,
                  attachments: attachments,
                };
              }

              res[item.id] = {
                eventType: item.eventType,
                taskId: item.id,
                emailTemplates: tempData,
              };
              break;

            default:
              break;
          }
        }
        setInitialValues(res);
      }
    } catch (error) {}
  };

  const handleSubmit = async (values) => {
    try {
      const data = Object.values(values);
      const promises = [];
      if (data && data.length) {
        setLoading(true);
        for (let i = 0; i < data.length; i++) {
          const item = data[i];
          switch (true) {
            case item.eventType === 'wait':
              promises.push(
                editSequenceStep(sequence.id, item.taskId, {
                  waitTime: item.waitTime,
                  unit: item.unit,
                }),
              );
              break;

            case item.eventType === 'email':
              const emailTemplates = Object.values(item.emailTemplates);
              for (let j = 0; j < emailTemplates.length; j++) {
                const emailTemplate = emailTemplates[j];
                promises.push(
                  updateTemplate(emailTemplate.template.id, {
                    content: emailTemplate.content,
                    subject: emailTemplate.subject,
                    ...(emailTemplate.attachments && emailTemplate.attachments.length
                      ? {
                          attachments: emailTemplate.attachments.map((item) => item.id),
                        }
                      : {}),
                  }),
                );
              }
              promises.push(
                editSequenceStep(sequence.id, item.taskId, {
                  emailTemplates: emailTemplates.map((item) => item.template.id),
                }),
              );
              break;

            case ['linkedin', 'linkedinConnection', 'linkedinMessage', 'linkedinMail'].includes(
              item.eventType,
            ):
              promises.push(
                updateTemplate(item.template.id, {
                  content: item.content,
                  subject: item.subject,
                }),
              );
              promises.push(
                editSequenceStep(sequence.id, item.taskId, {
                  templates: item.template.id,
                }),
              );
              break;

            case item.eventType === 'general' ||
              item.eventType === 'sms' ||
              item.eventType === 'call':
              promises.push(
                editSequenceStep(sequence.id, item.taskId, {
                  description: item.description,
                }),
              );
              break;

            default:
              break;
          }
        }
        const res = await Promise.all(promises);
        toast.success('Sequence steps updated.');
        setLoading(false);
        onClose();
        props.fetchSequenceEvents(sequence.id);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.error?.message || 'Error ocurred! Please try again.');
      props.fetchSequenceEvents(sequence.id);
    }
  };

  let waitTime = 0;

  return (
    <Form initialValues={initialValues} enableReinitialize={true} onSubmit={handleSubmit}>
      {(props) => {
        return (
          <Modal
            title={`Sequence Preview - ${sequence.name}`}
            size="lg"
            open={true}
            onClose={() => {}}
            style={{ zIndex: 1199 }}
            customActions={() => {
              return (
                <Stack direction="row" spacing={1.5}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={onClose}
                    disabled={loading}
                  >
                    Close
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={props.submitForm}
                    loading={loading}
                  >
                    Save
                  </Button>
                </Stack>
              );
            }}
          >
            {events && events.length
              ? events.map((event, index) => {
                  return (
                    <Card
                      event={event}
                      index={index}
                      key={`card-${index}`}
                      formProps={props}
                      waitTime={waitTimes[index]}
                    />
                  );
                })
              : null}
          </Modal>
        );
      }}
    </Form>
  );
}

export default SequencePreview;
