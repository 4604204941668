import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from '@mui/material';
import Button from '../Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { useStyles } from './styles';

const SplitButton = ({
  options,
  variant,
  color,
  size,
  onClick,
  fullWidth,
  loading,
  disabled,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const classes = useStyles();

  const handleClick = () => {
    onClick(options[selectedIndex]);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant={variant}
        color={color}
        ref={anchorRef}
        aria-label="split button"
        size={size}
        fullWidth={fullWidth}
      >
        <Button
          color={color}
          onClick={handleClick}
          fullWidth={fullWidth}
          size={size}
          loading={loading}
          disabled={disabled}
        >
          {options[selectedIndex]?.title}
        </Button>
        <Button
          color={color}
          size={size}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
          sx={{ maxWidth: '54px' }}
          disabled={loading || disabled}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.menuItems}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              zIndex: 999999,
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper className={classes.menuPaper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" className={classes.menuList}>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.key}
                      disabled={option?.disabled}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

SplitButton.propTypes = {
  options: PropTypes.array.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

SplitButton.defaultProps = {
  options: [],
  variant: 'contained',
  color: 'primary',
  size: 'medium',
  fullWidth: false,
  loading: false,
  disabled: false,
};

export default SplitButton;
