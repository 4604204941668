import styled from 'styled-components';
import images from 'src/config/images';
import { Typography, Container } from '@mui/material';
import { themeColors } from 'src/modules/lms/config';
import Tab from '@mui/material/Tab';
const size = {
  mobile: '320px',
  tablet: '768px',
  laptop: '1024px',
  desktop: '2560px',
};
export const AppContainer = styled.div`
  display: flex;
  background-color: ${themeColors.mainBg};
  overflow: hidden;
  position: relative;
`;

export const MainContainer = styled.div`
  display: flex;
  flex: 1;
  min-height: 100vh;
  position: relative;
  flex-direction: column;
`;

export const AppContent = styled(Container).attrs({
  maxWidth: false,
})`
  margin-top: 50px;
  padding: 12px 25px 0px 25px;
`;

export const DashContent = styled(Container).attrs({
  maxWidth: false,
})`
  margin-top: 50px;
  padding: 0px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const LoadingLogo = styled.img.attrs((props) => ({
  src: images.app.logo,
  className: 'loadingAnimation',
}))`
  width: 275px;
  margin-bottom: 60px;
`;

export const AuthContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Logo = styled.img.attrs((props) => ({
  src: images.app.logo,
}))`
  width: 225px;
  margin-bottom: 60px;
`;

export const AuthBg = styled.img.attrs((props) => ({
  src: images.auth.loginBg,
}))`
  position: fixed;
  z-index: -1;
  margin-top: 2rem;
  bottom: 0;
  left: 0;
  width: 100%;
  @media only screen and (max-width: 700px) {
    display: none;
  }
  @media only screen and (min-width: 700px) and (max-width: 1400px) {
    /* position: initial; */
  }
  max-height: 20%;
`;

export const CopyRight = styled(Typography)`
  position: fixed;
  z-index: -1;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.38);
  @media only screen and (min-width: 600px) and (max-width: 1400px) {
    /* position: initial; */
  }
`;

export const TabItem = styled(Tab)`
  color: ${themeColors.grey};
  font-size: 14px;
  font-weight: 600;
  padding: 0px;
  margin-right: 40px;
  text-transform: none;
  &.css-1481xnn-MuiButtonBase-root-MuiTab-root.Mui-selected,
  &.Mui-selected {
    color: ${themeColors.yellow} !important;
    // border-bottom-width: 2px;
    // color: ${themeColors.yellow};
  }
`;
