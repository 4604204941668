import { styled } from '@mui/system';
import { Typography, ButtonBase, Box } from '@mui/material';
import { Button } from 'src/components/shared';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';

export const PaperContainer = styled('div')(({ theme }) => ({
  width: '600px',
  minHeight: 'calc(100% - 300px)',
  padding: '00px 16px',
  zIndex: 999999,
}));

export const MenuPaper = ({ children, handleClear }) => {
  return (
    <PaperContainer>
      <Box display="flex" alignItems="center" width="100%" justifyContent="space-between">
        <Typography variant="h4">Filter By</Typography>
        <Button color="secondary" onClick={handleClear}>
          Clear all
        </Button>
      </Box>
      {children}
    </PaperContainer>
  );
};

const buttonStyle = {
  width: '30px',
  height: '30px',
  border: '1.17857px solid #E1E1E1',
  borderRadius: '4px',
  marginRight: '10px',
  marginTop: '6px',
};

const collapseButtonStyle = {
  width: '30px',
  height: '30px',
  marginRight: '10px',
  marginTop: '6px',
  background: '#082037',
  color: '#fff',
  position: 'absolute',
  top: '10px',
  zIndex: 999,
};

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

export const CollapseButton = ({ isOpen, ...props }) => {
  return (
    <ButtonBase
      {...props}
      sx={{
        ...collapseButtonStyle,
        ...{
          transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)',
          borderRadius: '10px 0px 0px 10px',
          right: isOpen ? '-9px' : '-39px',
        },
      }}
    >
      <KeyboardArrowLeftIcon />
    </ButtonBase>
  );
};

export const PhoneButton = ({ ...props }) => {
  return (
    <ButtonBase {...props} sx={{ ...buttonStyle }}>
      <LocalPhoneIcon sx={{ fontSize: '20px', color: 'rgba(0, 0, 0, 0.54)' }} />
    </ButtonBase>
  );
};

export const EmailButton = ({ ...props }) => {
  return (
    <ButtonBase {...props} sx={{ ...buttonStyle }}>
      <EmailIcon sx={{ fontSize: '20px', color: 'rgba(0, 0, 0, 0.54)' }} />
    </ButtonBase>
  );
};

export const InfoButton = ({ ...props }) => {
  return (
    <ButtonBase
      {...props}
      sx={{
        backgroundColor: '#082037',
        fill: '#ffffff',
        // position: 'absolute',
        // right: '24px',
        // top: '-6px',
        color: '#ffffff',
        borderRadius: '50%',
      }}
    >
      <InfoIcon sx={{ fontSize: '24px' }} />
    </ButtonBase>
  );
};

export const DeleteButton = ({ ...props }) => {
  return (
    <ButtonBase
      {...props}
      sx={{
        fill: 'rgba(0, 0, 0, 0.54)',
        position: 'absolute',
        right: '6px',
        top: '6px',
        // color: '#ffffff',
        borderRadius: '50%',
      }}
    >
      <CloseIcon sx={{ fontSize: '20px' }} />
    </ButtonBase>
  );
};

export const List = styled('div')(({ theme }) => ({
  height: '100%',
  padding: '0 12px',
  maxHeight: 'calc(100vh - 218px)',
  overflow: 'auto',
}));

export const SlidePanelHandle = styled('div')(({ theme }) => ({
  display: 'flex',
  background: '#C9C9C9',
  position: 'relative',
  borderRight: '1px solid #B0BEC5',
  position: 'fixed',
  zIndex: '1000',
}));
export const SlidePanelText = styled(Typography)(({ theme }) => ({
  writingMode: 'vertical-lr',
  textOrientation: 'upright',
  textTransform: 'uppercase',
  fontSize: '14px',
  margin: '4px',
  cursor: 'pointer',
  marginTop: '14px',
}));

export const SlidePanel = styled('div')(({ theme }) => ({
  width: '284px',
  marginTop: '16px',
}));

export const ProfileActionsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& button svg': {
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main,
    fontSize: '1em',
  },
  '& button': {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '& svg': {
        color: '#ffffff',
        fill: '#ffffff',
      },
    },
  },
}));

export const ProfileWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
}));

export const JobTitle = styled(Typography)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.6)',
  '& span': {
    color: theme.palette.primary.light,
  },
  fontSize: '14px',
}));

export const JobLocation = styled(Typography)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.38)',
  fontSize: '14px',
}));

export const SequenceInfo = styled(Typography)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.54)',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  lineHeight: 2,
}));

export const Left = styled('div')(({ theme }) => ({}));

export const Right = styled('div')(({ theme }) => ({
  width: '100%',
  '& .editButton .MuiSvgIcon-root': {
    fontSize: '1.25em',
    color: 'rgba(0,0,0,0.54)',
  },
}));

export const SeqStatus = styled('div')(({ theme, status }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '8px',
  // backgroundColor: status === 'active' ? '#66BB6A' : theme.palette.grey[500],
  ...(status === 'active' && {
    backgroundColor: '#66BB6A',
  }),
  ...(status === 'never' && {
    backgroundColor: '#ffffff',
    border: '2px solid #999999',
  }),
  ...(status === 'interested' && {
    backgroundColor: theme.palette.primary.main,
  }),
  ...(status === 'notinterested' && {
    backgroundColor: theme.palette.grey[500],
  }),
}));
