import React, { useMemo, useState } from 'react';
import { ButtonBase, Stack, Tooltip, Typography } from '@mui/material';
import { AppGrid } from 'src/components/App';
import { Avatar, Button, Form, Modal, Spacer } from 'src/components/shared';
import { userBulkActions } from './config';
import UserActionDrawer from './UserActionDrawer';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useColumns } from './useColumns';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LockIcon from '@mui/icons-material/Lock';
import {
  getSubscriptionTitle,
  planSortOrder,
  subscriptionPeriod,
  subscriptionTypeOrder,
} from '../../containers/users/UsersView';
import { capitalizeName } from 'src/modules/contacts/utils/contactUtils';
import { updateUserData } from '../../api/adminApi';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTenantConfig } from 'src/modules/app/actions/appActions';
import toast from 'src/utils/toast';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { capitalizeFirstLetter } from 'src/utils/helper';

function UsersGrid({ drawerToggle, users, loading, plansData, userBulkUpdate, ...props }) {
  const dispatch = useDispatch();
  const { data = [], filters, paging, sort = 'status:asc' } = users;
  const currentUser = useSelector((state) => state.auth.user);
  const columns = useColumns(data, drawerToggle, props);

  const [rowsSelected, setRowsSelected] = useState([]);
  const [bulkAction, setBulkAction] = useState('');
  const [openModalType, setOpenModalType] = useState('');
  const [openSubscriptionTypeModal, setOpenSubscriptionTypeModal] = useState('');
  const [selectedSubscriptionPeriod, setSelectedSubscriptionPeriod] = useState('');
  const [changedUserSubscription, setChangedUserSubscription] = useState({});
  const [changedUserLMSCategories, setChangedUserLMSCategories] = useState({});
  const [modalTypeButttonLoading, setModalTypeButttonLoading] = useState(false);

  const handleTableChange = ({ pageNo, perPage, searchText, sortOrder }) => {
    props.fetchUsers(
      {
        pageNo,
        perPage,
      },
      filters,
      sort,
    );
  };

  const onSort = (sort) => {
    props.fetchUsers(paging, filters, sort);
  };

  const onFilterChange = (key, value) => {
    if (value && typeof value !== 'undefined') {
      if (key === '_search') {
        props.fetchUsers(
          {
            ...paging,
            pageNo: 0,
          },
          { ...filters, [key]: value },
          sort,
        );
      } else {
        props.fetchUsers(paging, { ...filters, [key]: value }, sort);
      }
    } else {
      delete filters[key];
      props.fetchUsers(paging, filters, sort);
    }
  };

  const onBulkAction = (key) => {
    setBulkAction(key);
  };

  const closeBulkAction = (key) => {
    setBulkAction(undefined);
    setRowsSelected([]);
  };

  const selectedUsers = useMemo(() => {
    return data.filter((item, index) => rowsSelected.includes(index));
  }, [rowsSelected, data]);

  const updatedBulkActions = () => {
    return [
      ...userBulkActions?.flatMap((action) => {
        const externalActions = {};
        if (action.key === 'removeLicence') {
          externalActions.onClick = () => {
            setOpenModalType(action.key);
          };
        } else if (action.key === 'assignLicence') {
          if (selectedUsers?.some((item) => item.status === 'inactive')) {
            externalActions.disabled = true;
            externalActions.tip =
              'Licences cannot be assigned when a mix of both active and inactive users is selected.';
          }
          externalActions.onClick = () => {
            setOpenModalType(action.key);
          };
        } else if (action.key === 'resendInvite') {
          externalActions.onClick = () => {
            setOpenModalType(action.key);
          };
        } else if (action.key === 'markAsInactive') {
          externalActions.onClick = () => {
            setOpenModalType(action.key);
          };
        }
        if (action.isNotPrimary) return [];
        else return { ...action, ...externalActions };
      }),
      {
        key: 'extra-actions',
        type: 'extra-actions',
        menuItem: userBulkActions?.filter?.((action) => action.isNotPrimary),
      },
    ];
  };

  const closeSubscriptionPeriodSelectModal = () => {
    setOpenSubscriptionTypeModal('');
    setSelectedSubscriptionPeriod('');
  };

  const selectedUser = openSubscriptionTypeModal?.split('_')?.[0] ?? '';
  const selectedUserSubscriptionType = openSubscriptionTypeModal?.split('_')?.[1] ?? '';
  const submitModalType = async () => {
    try {
      setModalTypeButttonLoading(true);
      let users = 0;
      await Promise.all(
        selectedUsers?.map(async (user) => {
          if (openModalType === 'markAsInactive' && user.id === currentUser.id)
            return toast.error("User can't marked self as inactive");

          if (
            ['removeLicence', 'assignLicence']?.includes(openModalType) &&
            !changedUserSubscription[user.id]
          )
            return null;
          users += 1;

          await updateUserData({
            id: user.id,
            data:
              openModalType === 'resendInvite'
                ? { inviteEmail: true }
                : openModalType === 'markAsInactive'
                ? { status: 'inactive' }
                : openModalType === 'assignLicence'
                ? {
                    ...Object.keys(changedUserSubscription[user.id])?.reduce((acc, key) => {
                      if (key === 'lmsType') {
                        acc = {
                          ...acc,
                          ...(changedUserSubscription[user.id].lmsType === 'monthly'
                            ? { lmsmonthly: true }
                            : {}),
                        };
                        acc = {
                          ...acc,
                          ...(changedUserSubscription[user.id].lmsType === 'yearly'
                            ? { lmsannual: true }
                            : {}),
                        };
                      } else if (key === 'outreachType') {
                        acc = {
                          ...acc,
                          ...(changedUserSubscription[user.id].outreachType === 'monthly'
                            ? { outreachMonthly: true }
                            : {}),
                        };
                        acc = {
                          ...acc,
                          ...(changedUserSubscription[user.id].outreachType === 'yearly'
                            ? { outreachAnnual: true }
                            : {}),
                        };
                      }
                      return acc;
                    }, {}),
                    ...(changedUserLMSCategories[user.id]
                      ? { lmsCategories: changedUserLMSCategories[user.id] }
                      : {}),
                  }
                : openModalType === 'removeLicence'
                ? Object.keys(changedUserSubscription[user.id] ?? {})?.reduce((acc, key) => {
                    if (key === 'lmsType') {
                      acc = {
                        ...acc,
                        ...(changedUserSubscription[user.id].lmsType === 'monthly'
                          ? { lmsmonthly: false }
                          : {}),
                      };
                      acc = {
                        ...acc,
                        ...(changedUserSubscription[user.id].lmsType === 'yearly'
                          ? { lmsannual: false }
                          : {}),
                      };
                    } else if (key === 'outreachType') {
                      acc = {
                        ...acc,
                        ...(changedUserSubscription[user.id].outreachType === 'monthly'
                          ? { outreachMonthly: false }
                          : {}),
                      };
                      acc = {
                        ...acc,
                        ...(changedUserSubscription[user.id].outreachType === 'yearly'
                          ? { outreachAnnual: false }
                          : {}),
                      };
                    }
                    return acc;
                  }, {})
                : {},
          });
        }),
      );
      if (users > 0)
        toast.success(
          `Success! Access has been updated.\n
          ${
            openModalType === 'resendInvite'
              ? 'An email will be sent shortly to inform the user about the changes.'
              : ''
          }`,
          'bl',
        );
      else toast.warning('No action performed');
      handleTableChange({ pageNo: 0, perPage: 10 });
      dispatch(fetchTenantConfig());
    } catch (error) {
      toast.error('Something failed!', 'tc');
    } finally {
      closeSubscriptionModal();
    }
  };

  const subscriptionDetail = (subscriptionType, includeUnsavedSubscription, openModalType) => {
    if (!planSortOrder?.includes(subscriptionType)) return {};
    const subscriptionPlan = plansData[subscriptionType];
    let total = 0;
    let unsavedSubscriptionUsed = Object.values(changedUserSubscription)?.reduce((acc, obj) => {
      if (subscriptionTypeOrder.includes(obj[subscriptionType + 'Type']))
        acc = acc + (openModalType === 'removeLicence' ? -1 : 1);
      return acc;
    }, 0);
    let used = includeUnsavedSubscription ? unsavedSubscriptionUsed : 0;
    Object.values(subscriptionPlan?.plans)?.forEach((plan) => {
      total = total + (plan.quantity || 0);
      used = used + (plan.used || 0);
    });
    const available = total - used;
    return {
      available,
      total,
      used,
      unsavedSubscriptionUsed,
    };
  };

  const closeSubscriptionModal = () => {
    setModalTypeButttonLoading(false);
    setOpenModalType('');
    setRowsSelected([]);
    setChangedUserSubscription({});
    setChangedUserLMSCategories({});
  };

  return (
    <>
      <AppGrid
        selectableRows="multiple"
        columns={columns.map((col, index) => ({
          ...col,
          options: columns?.columns?.length
            ? { ...col.options, ...columns?.columns[index] }
            : col.options,
        }))}
        onSort={onSort}
        data={data}
        onTableChange={handleTableChange}
        loading={loading.users}
        onFilterChange={onFilterChange}
        appliedFilters={filters}
        bulkActions={updatedBulkActions()}
        onBulkAction={onBulkAction}
        options={{
          sortOrder: {
            name: sort?.name || 'none',
            direction: sort?.direction || 'none',
          },
          serverSide: true,
          pagination: true,
          page: paging.pageNo,
          rowsPerPage: paging.perPage,
          count: paging.count,
          selectableRows: 'multiple',
          searchPlaceholder: 'Search Users',
          searchText: filters?._search ? filters._search : '',
          searchOpen: true,
          search: true,
          renderCustomFilterActions: () => {
            return (
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                // startIcon={<AddIcon />}
                onClick={() => {
                  drawerToggle({ edit: null, open: true });
                }}
              >
                <Typography variant="body2">Create User</Typography>
              </Button>
            );
          },
          onRowSelectionChange: (selected, all) => {
            setRowsSelected(all.map((row) => row.dataIndex));
          },
          rowsSelected: rowsSelected,
        }}
      />
      <UserActionDrawer
        action={bulkAction && bulkAction !== 'delete' ? bulkAction : false}
        onClose={closeBulkAction}
        userBulkUpdate={userBulkUpdate}
        selectedUsers={selectedUsers}
      />
      <Modal
        size={['removeLicence', 'assignLicence']?.includes(openModalType) ? 'md' : 'xs'}
        title={
          openModalType === 'removeLicence'
            ? 'Remove licences'
            : openModalType === 'assignLicence'
            ? 'Assign licences'
            : openModalType === 'resendInvite'
            ? 'Resend Invitation'
            : openModalType === 'markAsInactive'
            ? 'Mark as Inactive'
            : ''
        }
        open={openModalType !== ''}
        onClose={closeSubscriptionModal}
      >
        {['removeLicence', 'assignLicence']?.includes(openModalType) ? (
          <LicenceCode
            {...{
              openModalType,
              plansData,
              planSortOrder,
              selectedUsers,
              subscriptionDetail,
              subscriptionPeriod,
              changedUserSubscription,
              submitModalType,
              closeSubscriptionModal,
              changedUserLMSCategories,
              modalTypeButttonLoading,
              setChangedUserSubscription,
              setOpenSubscriptionTypeModal,
              setSelectedSubscriptionPeriod,
            }}
          />
        ) : ['resendInvite', 'markAsInactive']?.includes(openModalType) ? (
          <Stack gap={1}>
            <Typography>
              {openModalType === 'resendInvite'
                ? `An invite will be resent to ${selectedUsers.length} user${
                    selectedUsers.length === 1 ? '' : 's'
                  }.`
                : 'Selected users will be marked as inactive and will no longer have access to SuperReach. Please confirm that you wish to continue.'}
            </Typography>
            <Stack gap={1} direction={'row'} justifyContent={'flex-end'}>
              <Button
                color="secondary"
                disabled={modalTypeButttonLoading}
                onClose={closeSubscriptionModal}
              >
                Close
              </Button>
              <Button
                variant="contained"
                color="secondary"
                loading={modalTypeButttonLoading}
                onClick={submitModalType}
              >
                Confirm
              </Button>
            </Stack>
          </Stack>
        ) : (
          <></>
        )}
      </Modal>

      {openSubscriptionTypeModal && (
        <Modal open={openSubscriptionTypeModal} onClose={closeSubscriptionPeriodSelectModal}>
          <Stack gap={2}>
            <Typography>Select the users subscription type.</Typography>
            <Stack gap={'8px'}>
              <Typography fontWeight={'500'} fontSize={'18px'}>
                {getSubscriptionTitle(selectedUserSubscriptionType)}
              </Typography>

              <Stack
                direction={selectedUserSubscriptionType === 'lms' ? 'column' : 'row'}
                gap={'20px'}
              >
                {subscriptionTypeOrder
                  .sort(
                    (a, b) => subscriptionTypeOrder.indexOf(a) - subscriptionTypeOrder.indexOf(b),
                  )
                  .map((planPeriod) => {
                    const planObj =
                      plansData?.[selectedUserSubscriptionType]?.plans?.[planPeriod] ?? {};
                    const total = planObj?.quantity || 0;
                    const used = planObj?.used || 0;
                    const available = total - used;
                    const isSelected = planPeriod === selectedSubscriptionPeriod;
                    const isDisabled = !(available > 0);
                    return (
                      <ButtonBase
                        // disabled={isDisabled}
                        onClick={() =>
                          !isDisabled &&
                          setSelectedSubscriptionPeriod((prevValue) =>
                            prevValue === planPeriod ? '' : planPeriod,
                          )
                        }
                        key={_}
                        sx={{
                          flex: 1,
                          flexDirection: 'column',
                          cursor: 'pointer',
                          gap: '6px',
                          padding: '14px',
                          borderRadius: '4px',
                          border: isDisabled ? '1px solid #00000042' : '1px solid #1976D2',
                          backgroundColor: isSelected ? '#1976D2 !important' : 'transparent',
                          '&:hover': {
                            backgroundColor: isDisabled ? 'transparent' : '#1976D21F',
                          },
                        }}
                      >
                        <Stack width={'100%'} direction={'row'} justifyContent={'space-between'}>
                          <Typography
                            color={isSelected ? '#FFFFFF' : isDisabled ? '#00000099' : '#000000DE'}
                            fontWeight={500}
                            fontSize={'16px'}
                          >
                            {capitalizeFirstLetter(planPeriod)}
                          </Typography>
                          <Tooltip
                            title={
                              <Stack>
                                <Stack
                                  direction={'row'}
                                  gap="24px"
                                  justifyContent={'space-between'}
                                >
                                  <Typography>Purchased :</Typography>
                                  <Typography>{planObj?.quantity}</Typography>
                                </Stack>
                                <Stack
                                  direction={'row'}
                                  gap="24px"
                                  justifyContent={'space-between'}
                                >
                                  <Typography>Available :</Typography>
                                  <Typography>{available}</Typography>
                                </Stack>
                              </Stack>
                            }
                            arrow
                            variant={'dense'}
                            placement={'bottom'}
                            key={planObj.id}
                          >
                            <InfoOutlinedIcon
                              style={{
                                fill: isSelected
                                  ? '#FFFFFF'
                                  : isDisabled
                                  ? '#00000099'
                                  : '#000000DE',
                              }}
                            />
                          </Tooltip>
                        </Stack>
                        <Stack width={'100%'}>
                          <Typography
                            color={isSelected ? '#FFFFFFB2' : '#00000099'}
                            textAlign={'start'}
                            fontWeight={500}
                            fontSize={'14px'}
                          >
                            {available} available licences
                          </Typography>
                        </Stack>
                      </ButtonBase>
                    );
                  })}
              </Stack>

              {selectedSubscriptionPeriod && selectedUserSubscriptionType === 'lms' && (
                <Stack marginTop={'8px'} gap={'8px'}>
                  <Stack direction={'row'}>
                    <Stack width={'40px'}>
                      <LockIcon style={{ fill: '#d1d1d1' }} />
                    </Stack>
                    <Stack>
                      <Typography color={'#000000DE'} fontWeight={500}>
                        Learning content access:
                      </Typography>
                      <Typography color={'#CF0175'}>
                        Select the content this user can access.
                      </Typography>
                    </Stack>
                  </Stack>

                  {plansData[selectedUserSubscriptionType]?.categories?.map((category) => {
                    return (
                      <Stack direction={'row'} key={category.id}>
                        <Stack width={'40px'} />
                        <Form
                          initialValues={{ ...(changedUserLMSCategories[selectedUser] ?? {}) }}
                          enableReinitialize={true}
                        >
                          <Form.Field.Switch
                            name={category.id}
                            helperText={category.description}
                            label={category.name}
                            sx={{
                              marginTop: '0',
                              width: '100%',
                              fieldset: {
                                width: '100%',
                              },
                              '& .MuiFormGroup-root': {
                                label: {
                                  width: '100%',
                                  flexDirection: 'row-reverse',
                                  margin: '0 !important',
                                  justifyContent: 'space-between',
                                  '& > span': {
                                    color: '#000000DE',
                                    fontWeight: '500',
                                    fontSize: '18px',
                                  },
                                },
                                '& .MuiFormHelperText-root': {
                                  fontWeight: '500 !important',
                                  fontSize: '14px !important',
                                  margin: '0 !important',
                                  color: '#00000099',
                                },
                              },
                            }}
                            onChange={(val) => {
                              setChangedUserLMSCategories((prevValue) => {
                                prevValue[selectedUser] = {
                                  ...(prevValue?.[selectedUser] ?? {}),
                                  [category.id]: val,
                                };
                                return { ...prevValue };
                              });
                            }}
                          />
                        </Form>
                      </Stack>
                    );
                  })}
                </Stack>
              )}

              <Spacer y={5} />

              <Stack justifyContent={'flex-end'} direction={'row'} gap={'16px'}>
                <Button onClick={closeSubscriptionPeriodSelectModal} color="secondary">
                  Close
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    if (selectedSubscriptionPeriod)
                      setChangedUserSubscription((prevValue) => {
                        prevValue[selectedUser] = {
                          ...(prevValue[selectedUser] ?? {}),
                          [selectedUserSubscriptionType + 'Type']: selectedSubscriptionPeriod,
                        };
                        return prevValue;
                      });
                    else {
                      setChangedUserSubscription((prevValue) => {
                        if (prevValue?.[selectedUser]) {
                          if (Object.keys(prevValue?.[selectedUser] ?? {}).length === 1)
                            delete prevValue[selectedUser];
                          else
                            delete prevValue[selectedUser][selectedUserSubscriptionType + 'Type'];
                        }
                        return prevValue;
                      });
                    }
                    closeSubscriptionPeriodSelectModal();
                  }}
                >
                  Continue &emsp; <ArrowForwardIcon />
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Modal>
      )}
    </>
  );
}

export default UsersGrid;

const LicenceCode = ({
  openModalType,
  plansData,
  planSortOrder,
  selectedUsers,
  subscriptionPeriod,
  subscriptionDetail,
  changedUserSubscription,
  submitModalType,
  closeSubscriptionModal,
  modalTypeButttonLoading,
  changedUserLMSCategories,
  setChangedUserSubscription,
  setOpenSubscriptionTypeModal,
  setSelectedSubscriptionPeriod,
}) => {
  const filteredUsers = selectedUsers?.flatMap((user) => {
    const subscribedToPlans = Object.keys(plansData)
      ?.sort((a, b) => planSortOrder.indexOf(a) - planSortOrder.indexOf(b))
      ?.reduce((acc, subscriptionType) => {
        const period = Object.keys(plansData[subscriptionType]?.plans)?.find(
          (planPeriod) => user[subscriptionPeriod[subscriptionType + capitalizeName(planPeriod)]],
        );
        if (period) acc[subscriptionType] = period;
        return acc;
      }, {});

    const hasLicence = Object.keys(subscribedToPlans)
      ?.sort((a, b) => planSortOrder.indexOf(a) - planSortOrder.indexOf(b))
      ?.reduce((acc, subscriptionType) => {
        const period = subscribedToPlans[subscriptionType];
        if (period) {
          if (!acc.includes('Current licences: ')) acc = 'Current licences: ';
          acc += `${acc !== 'Current licences: ' ? ', ' : ''}${getSubscriptionTitle(
            subscriptionType,
          )} ${capitalizeName(period)}`;
        }

        return acc;
      }, 'No licence');

    if (openModalType === 'removeLicence' && hasLicence === 'No licence') return [];
    if (
      openModalType === 'assignLicence' &&
      !Object.keys(plansData)?.find((plan) => !subscribedToPlans[plan])
    )
      return [];
    return user;
  });
  return (
    <Stack gap={2} marginTop={1}>
      <Stack gap={0.8}>
        <Typography>Licences remaining</Typography>
        <Stack direction="row" gap={2}>
          {Object.keys(plansData || {})
            ?.sort((a, b) => planSortOrder.indexOf(a) - planSortOrder.indexOf(b))
            ?.map((subscriptionType) => {
              const { total, available } = subscriptionDetail(
                subscriptionType,
                false,
                openModalType,
              );

              return (
                <Stack
                  justifyContent={'center'}
                  padding="12px 60px"
                  // width="calc(50% - 10px)"
                  border={'1px solid #00000042'}
                  borderRadius={'4px'}
                  direction={'row'}
                  alignItems={'center'}
                  gap={'7px'}
                >
                  <Typography>{getSubscriptionTitle(subscriptionType)}</Typography>
                  <span>|</span>
                  <Typography fontWeight={500}>
                    {available} of {total}
                  </Typography>
                </Stack>
              );
            })}
        </Stack>
      </Stack>
      <Stack gap={'2px'}>
        <Typography>
          Editing {filteredUsers.length} user{filteredUsers.length > 1 ? 's' : ''}:
        </Typography>
        {openModalType === 'removeLicence' && (
          <Typography color="#CF0175">Deselect to remove a licence.</Typography>
        )}
      </Stack>
      <Stack
        border="1px solid #00000042"
        borderRadius={'4px'}
        divider={<Stack borderBottom={'1px solid #00000042'} width="100%" />}
      >
        {filteredUsers?.map((user) => {
          const subscribedToPlans = Object.keys(plansData)
            ?.sort((a, b) => planSortOrder.indexOf(a) - planSortOrder.indexOf(b))
            ?.reduce((acc, subscriptionType) => {
              const period = Object.keys(plansData[subscriptionType]?.plans)?.find(
                (planPeriod) =>
                  user[subscriptionPeriod[subscriptionType + capitalizeName(planPeriod)]],
              );
              if (period) acc[subscriptionType] = period;
              return acc;
            }, {});

          const hasLicence = Object.keys(subscribedToPlans)
            ?.sort((a, b) => planSortOrder.indexOf(a) - planSortOrder.indexOf(b))
            ?.reduce((acc, subscriptionType) => {
              const period = subscribedToPlans[subscriptionType];
              if (period) {
                if (!acc.includes('Current licences: ')) acc = 'Current licences: ';
                acc += `${acc !== 'Current licences: ' ? ', ' : ''}${getSubscriptionTitle(
                  subscriptionType,
                )} ${capitalizeName(period)}`;
              }

              return acc;
            }, 'No licence');

          return (
            <Stack
              padding={'12px'}
              direction="row"
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Stack direction="row" gap="14px" alignItems={'center'}>
                <Avatar name={user?.fname + ' ' + user?.lname} />
                <Stack gap={'2px'}>
                  <Typography color={'#000000DE'}>{user?.fname + ' ' + user?.lname}</Typography>
                  {openModalType === 'assignLicence' && (
                    <Typography
                      padding={'3px 8px'}
                      borderRadius={'4px'}
                      color={'#000000DE'}
                      fontWeight={500}
                      fontSize={'12px'}
                      sx={{ background: '#1976D214', width: 'fit-content' }}
                    >
                      {hasLicence}
                    </Typography>
                  )}
                </Stack>
              </Stack>
              {openModalType === 'removeLicence' && (
                <Stack direction="row" gap="12px">
                  {Object.keys(subscribedToPlans)
                    ?.sort((a, b) => planSortOrder.indexOf(a) - planSortOrder.indexOf(b))
                    ?.flatMap((subscriptionType) => {
                      const period = subscribedToPlans[subscriptionType];
                      const isSelected =
                        changedUserSubscription?.[user.id]?.[subscriptionType + 'Type'] === period;
                      if (!period) return [];
                      return (
                        <ButtonBase
                          sx={{
                            display: 'flex',
                            padding: '8px 16px',
                            border: '1px solid #00000042',
                            alignItems: 'center',
                            borderRadius: '4px',
                            gap: '10px',
                            '&:hover': {
                              background: '#D32F2F1F',
                            },
                            ...(isSelected
                              ? {
                                  border: '1px solid #D32F2F',
                                  background: '#D32F2F1F',

                                  '.MuiTypography-root': {
                                    color: '#D32F2F !important',
                                  },
                                  svg: {
                                    fill: '#D32F2F',
                                  },
                                }
                              : {}),
                          }}
                          onClick={() => {
                            setChangedUserSubscription((prevValue) => {
                              if (isSelected) {
                                if (Object.keys(prevValue[user.id] || {}).length === 1)
                                  delete prevValue[user.id];
                                else delete prevValue[user.id][subscriptionType + 'Type'];
                              } else
                                prevValue[user.id] = {
                                  ...(prevValue[user.id] ?? {}),
                                  [subscriptionType + 'Type']: period,
                                };
                              return { ...prevValue };
                            });
                          }}
                        >
                          <Stack gap={'3px'}>
                            <Typography fontSize={14} color={'#000000DE'}>
                              {getSubscriptionTitle(subscriptionType)} {capitalizeName(period)}
                            </Typography>
                          </Stack>
                          <RemoveCircleOutlineIcon sx={{ fill: '#00000099' }} />
                        </ButtonBase>
                      );
                    })}
                </Stack>
              )}
              {openModalType === 'assignLicence' && (
                <Stack direction="row" gap="12px">
                  {Object.keys(changedUserSubscription[user.id] ?? {})
                    ?.sort(
                      (a, b) =>
                        planSortOrder.indexOf(a.split('Type')[0]) -
                        planSortOrder.indexOf(b.split('Type')[0]),
                    )
                    ?.map((subscriptionType) => {
                      const period = changedUserSubscription[user.id][subscriptionType];
                      const categories = Object.keys(
                        changedUserLMSCategories[user.id] ?? {},
                      )?.reduce((acc, lmsCategory) => {
                        if (changedUserLMSCategories[user.id][lmsCategory])
                          acc += `${acc === '' ? '' : ' | '}${capitalizeName(lmsCategory)}`;
                        return acc;
                      }, '');
                      return (
                        <ButtonBase
                          sx={{
                            display: 'flex',
                            background: '#1976D21F',
                            padding: '8px 16px',
                            border: '1px solid #1976D2',
                            alignItems: 'center',
                            borderRadius: '4px',
                            direction: 'row',
                            gap: '10px',
                          }}
                          onClick={() => {
                            setOpenSubscriptionTypeModal(
                              user.id + '_' + subscriptionType.split('Type')[0],
                            );
                            setSelectedSubscriptionPeriod(period);
                          }}
                        >
                          <Stack gap={'3px'}>
                            <Typography fontSize={14} color={'#000000DE'}>
                              {getSubscriptionTitle(subscriptionType?.split('Type')?.[0])}{' '}
                              {capitalizeName(period)}
                            </Typography>
                            {categories && (
                              <Typography fontSize={12} fontWeight={500} color={'#00000099'}>
                                {categories}
                              </Typography>
                            )}
                          </Stack>
                          <CheckCircleIcon sx={{ fill: '#1976D2' }} />
                        </ButtonBase>
                      );
                    })}
                  {Object.keys(plansData)
                    ?.sort((a, b) => planSortOrder.indexOf(a) - planSortOrder.indexOf(b))
                    ?.flatMap((subscriptionType) => {
                      const { available } = subscriptionDetail(subscriptionType, true);
                      if (
                        subscriptionTypeOrder.includes(
                          changedUserSubscription?.[user.id]?.[subscriptionType + 'Type'],
                        )
                      )
                        return [];
                      const period = subscribedToPlans[subscriptionType];
                      return period ? (
                        <></>
                      ) : (
                        <ButtonBase
                          sx={{
                            display: 'flex',
                            padding: '8px 16px',
                            border: '1px solid #00000042',
                            alignItems: 'center',
                            borderRadius: '4px',
                            direction: 'row',
                            gap: '10px',
                          }}
                          disabled={available <= 0}
                          onClick={() => {
                            setOpenSubscriptionTypeModal(user.id + '_' + subscriptionType);
                          }}
                        >
                          <Stack gap={'3px'}>
                            <Typography fontSize={14} color={'#00000099'}>
                              {getSubscriptionTitle(subscriptionType)}
                            </Typography>
                          </Stack>
                          <AddCircleOutlineIcon sx={{ fill: '#00000099' }} />
                        </ButtonBase>
                      );
                    })}
                </Stack>
              )}
            </Stack>
          );
        })}
      </Stack>
      <Spacer y={10} />
      <Stack justifyContent={'flex-end'} direction={'row'} gap={'16px'}>
        <Button disabled={modalTypeButttonLoading} onClick={closeSubscriptionModal}>
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          loading={modalTypeButttonLoading}
          onClick={submitModalType}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};
