import React from 'react';
import * as lmsApi from '../api/LMSApis';
import { useSelector } from 'react-redux';

const lmsHomeContext = React.createContext({});

export const useLmsHomeProvider = () => {
  const {
    competitions,
    fetchCompetitions,
    slidesDataByKey,
    completedSlides,
    fetchSlidesData,
    fetchSavedList,
    assignedSessions,
    assignedCourses,
    initialLmsContext,
    saved,
    summary,
    progress,
    homePage,
    assigned,
  } = React.useContext(lmsHomeContext);
  return {
    competitions,
    fetchCompetitions,
    slidesDataByKey,
    completedSlides,
    fetchSlidesData,
    fetchSavedList,
    assignedSessions,
    assignedCourses,
    initialLmsContext,
    saved,
    summary,
    progress,
    homePage,
    assigned,
  };
};

export const LmsStore = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  const [competitions, setCompetitions] = React.useState([]);
  const [slidesDataByKey, setSlidesDataByKey] = React.useState({});
  const [completedSlides, setCompletedSlides] = React.useState(0);
  const [initialLmsContext, setInitialLmsContext] = React.useState(false);
  const [assigned, setAssigned] = React.useState({
    sessions: [],
    courses: [],
  });
  const [assignedSessions, setAssignedSessions] = React.useState([]);
  const [assignedCourses, setAssignedCourses] = React.useState([]);
  const [saved, setSaved] = React.useState([]);
  const [progress, setProgress] = React.useState({
    sessions: {},
    courses: {},
  });

  const [homePage, setHomePage] = React.useState({});

  const [summary, setSummary] = React.useState({
    inProgress: 0,
    assignedToMe: 0,
    saved: 0,
    competitions: 0,
    completed: 0,
  });

  React.useEffect(() => {
    const fetchData = async () => {
      await fetchSlidesData();
      fetchCompetitions();
      fetchAssigned();
      fetchSavedList();
      fetchSummary();
      fetchProgress();
      fetchHomepage();
      setInitialLmsContext();
    };
    fetchData();
  }, []);

  const fetchHomepage = async () => {
    try {
      const res = await lmsApi.fetchPages();
      setHomePage(res.pages.filter((page) => page.name === 'homepage')[0]);
    } catch (error) {}
  };

  const fetchProgress = async () => {
    try {
      const res = await lmsApi.fetchAllProgress();
      const progressObj = res.progress.reduce(
        (acc, item) => {
          if (item.session && item.session !== '') {
            acc.sessions[item.session] = {
              stats: item.stats,
              sessionVideos: item.sessionVideos,
              status: item.status,
            };
          }
          if (item.course && item.course !== '') {
            acc.courses[item.course] = {
              // stats: item.stats,
              // sessionVideos: item.sessionVideos,
              // status: item.status,
            };
          }
          return acc;
        },
        { sessions: {}, courses: {} },
      );
      setProgress(progressObj);
    } catch (error) {}
  };

  const fetchSummary = async () => {
    try {
      const res = await lmsApi.getLmsSummaryCount();
      setSummary(res.count);
    } catch (error) {}
  };

  const fetchSavedList = async () => {
    try {
      const payload = {
        _from: 0,
        _size: 4,
      };
      const res = await lmsApi.fetchList(payload);
      setSaved(res.favourite);
    } catch (error) {
      setSaved([]);
    }
  };

  const fetchAssigned = async () => {
    try {
      const payload = {
        user_eq: user.id,
      };
      const res = await lmsApi.fetchAssigned(payload);
      let assignedObj = {
        sessions: [],
        courses: [],
      };
      let sessions = [],
        courses = [];
      for (const i in res.tasks) {
        if (res.tasks[i].session && res.tasks[i].session?.id) {
          sessions.push(res.tasks[i].session);
          assignedObj.sessions.push(res.tasks[i].session?.id);
        }
        if (res.tasks[i].course && res.tasks[i].course?.id) {
          courses.push(res.tasks[i].course);
          assignedObj.courses.push(res.tasks[i].course?.id);
        }
      }
      setAssignedSessions(sessions);
      setAssignedCourses(courses);
      setAssigned(assignedObj);
    } catch (error) {}
  };

  const fetchCompetitions = async () => {
    try {
      const res = await lmsApi.getCompetitions();
      setCompetitions(res?.favourite ?? []);
    } catch (error) {}
  };

  const fetchSlidesData = async (updatedUser) => {
    const updatedUserData = updatedUser ?? user;

    try {
      const response = await lmsApi.fetchGetStartedData();
      const slidesDataByKey = response?.onboarding?.reduce((acc, slide) => {
        if (slide.key) acc[slide.key] = slide;
        return acc;
      }, {});
      setSlidesDataByKey(slidesDataByKey);
      setCompletedSlides(
        Object.keys({ ...updatedUserData?.lmsOnboarding } ?? {})?.filter(
          (key) => slidesDataByKey[key] && updatedUserData?.lmsOnboarding[key],
        ).length ?? 0,
      );
    } catch (error) {}
  };

  return (
    <lmsHomeContext.Provider
      value={{
        competitions,
        fetchCompetitions,
        slidesDataByKey,
        completedSlides,
        fetchSlidesData,
        fetchSavedList,
        assignedSessions,
        assignedCourses,
        initialLmsContext,
        saved,
        summary,
        progress,
        homePage,
        assigned,
      }}
    >
      {children}
    </lmsHomeContext.Provider>
  );
};

export const withLmsStore = (Component) => (props) =>
  (
    <LmsStore>
      <Component {...props} />
    </LmsStore>
  );
