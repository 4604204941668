import { Box, ButtonBase, Typography } from '@mui/material';
import * as React from 'react';
import SessionPreview from '../../components/sessionPreview/SessionPreview';
import { themeColors } from '../../config';
import CoursePreview from '../coursePreview/CoursePreview';
import Skeleton from '../Skeleton/Skeleton';
import ActionSessionMenu from './ActionSessionMenu';
import Button from '@mui/material/Button';
import useStyles from './styles';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import VideoFrame from '../../components/LMSSession/VideoFrame';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import images from 'src/config/images';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import MissingImage from '../MissingImage';
import styled from 'styled-components';
import toast from 'src/utils/toast';
import { addList, deleteList } from '../../api/LMSApis';

export default function CardMedia({
  isWatched = false,
  containerStyle = {},
  id,
  src,
  contentType,
  dontShowAction = false,
  data = null,
  from,
  loading,
  maxSize = '',
  type,
  isClickable = false,
  makeImageResponsive = false,
  status = false,
  ...props
}) {
  const classes = useStyles();
  const history = useHistory();
  let style = {};
  const [hover, setHover] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [videoModalOpen, setVideoModalOpen] = React.useState(false);
  const [fav, setFav] = React.useState(data?.isFavourite);
  const [favId, setFavId] = React.useState(data?.favouriteId);
  const [play, setPlay] = React.useState(data?.videos?.length ? true : false);
  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };
  const addInFavList = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    try {
      if (id) {
        let payload =
          contentType === 'session'
            ? {
                session: id,
              }
            : { course: id };
        let res = await addList(payload);
        setFavId(res?.favourite?.id);
        if (res) {
          if (res?.message) {
            toast.info('Already added to My list');
          } else {
            toast.success('Item added to My List');
          }
          setFav(true);
        } else {
          toast.error('Item not added to My List');
        }
      } else {
        toast.warning('Course/Session not found');
      }
    } catch (error) {
      toast.error('Item not added to the My list');
    }
  };
  const removeFromList = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    try {
      if (favId) {
        let res = await deleteList(favId);
        if (res) {
          toast.info('Item removed from My list');
          setFav(false);
        } else {
          toast.error('Item not removed from My list');
        }
      }
    } catch (error) {
      toast.error('Error ocurred! Please try again.');
    }
  };
  useEffect(() => {
    setHover(false);
  }, [modalOpen]);

  if (isWatched)
    style = {
      ...props?.style,
      outline: 'solid ' + themeColors.greenText,
      borderRadius: 10,
    };
  else
    style = {
      ...props?.style,
    };

  if (loading) return <Skeleton animation="wave" height={300} width={250} />;

  return (
    <>
      <Box
        position={'relative'}
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
        display={'flex'}
        justifyContent={'center'}
        style={containerStyle}
      >
        {hover && !dontShowAction ? (
          <>
            <ActionSessionMenu
              handleOnPreview={() => setModalOpen(true)}
              handleOnVideoFrame={() => {
                setVideoModalOpen(true);
              }}
              handleOnFav={(event) => {
                if (fav) {
                  removeFromList(event);
                } else {
                  addInFavList(event);
                }
              }}
              from={from}
              id={id}
              contentType={contentType}
              setHover={setHover}
              data={data}
              fav={fav}
              play={play}
            />
          </>
        ) : null}
        {src ? (
          <img
            {...props}
            src={src}
            loading="lazy"
            className={`${type ? classes[type] : ''} ${classes.image} ${
              makeImageResponsive ? classes.imageResponsive : ''
            }`}
            onClick={() => {
              if (isClickable) {
                setModalOpen(true);
              } else {
                if (id) {
                  history.push(`/lms/${contentType}/${id}`);
                }
              }
            }}
          />
        ) : (
          <Box
            className={`${type ? classes[type] : ''} ${classes.image} ${
              makeImageResponsive ? classes.imageResponsive : ''
            } ${classes.noImageContainer}`}
            onClick={() => {
              if (isClickable) {
                setModalOpen(true);
              } else {
                if (id) {
                  history.push(`/lms/${contentType}/${id}`);
                }
              }
            }}
          >
            <MissingImage />
          </Box>
        )}

        {!isWatched ? null : <WatchedFrame />}

        {contentType === 'session' ? (
          <SessionPreview
            data={data}
            open={modalOpen}
            handleClose={() => setModalOpen(false)}
            handleOpen={() => setModalOpen(true)}
          />
        ) : (
          <CoursePreview
            open={modalOpen}
            handleClose={() => setModalOpen(false)}
            handleOpen={() => setModalOpen(true)}
            data={data}
          />
        )}
        <VideoFrame
          VideoUrl={data?.videoUrl}
          open={videoModalOpen}
          handleClose={() => setVideoModalOpen(false)}
          handleOpen={() => setVideoModalOpen(true)}
        />
      </Box>
      {status ? (
        <Box display={'flex'} mt={1}>
          <GraphicEqIcon
            className={classes.statusIcon}
            style={{ color: hover ? 'white' : themeColors.fadedText }}
          />
          <Typography variant={'h6'} style={{ color: hover ? 'white' : themeColors.fadedText }}>
            22|3.5
          </Typography>
          <Typography
            mr={1}
            variant={'h6'}
            style={{ color: hover ? themeColors.yellow : themeColors.fadedText }}
          >
            /5
          </Typography>
          <EmojiEventsOutlinedIcon
            className={classes.statusIcon}
            style={{ color: hover ? 'white' : themeColors.fadedText }}
          />
          <Typography
            variant={'h6'}
            style={{ color: hover ? themeColors.greenText : themeColors.fadedText }}
          >
            18/
          </Typography>
          <Typography variant={'h6'} style={{ color: hover ? 'white' : themeColors.fadedText }}>
            40|3.5
          </Typography>
          <Typography
            variant={'h6'}
            mr={1}
            style={{ color: hover ? themeColors.yellow : themeColors.fadedText }}
          >
            /5
          </Typography>
        </Box>
      ) : null}
    </>
  );
}

const WatchedFrame = () => (
  <Box
    style={{
      background: themeColors.greenText,
      color: themeColors.bg,
      borderTopLeftRadius: 10,
      borderBottomRightRadius: 5,
    }}
    width={110}
    height={14}
    padding={1}
    display={'flex'}
    bottom={'1%'}
    right={'0%'}
    position={'absolute'}
  >
    <CheckCircleIcon fontSize="20px" />
    {/* <ProgressIcon/> */}
    <Typography fontSize={'12px'} fontWeight={600} ml={1}>
      Watched
    </Typography>
    <Typography fontSize={'12px'} ml={1}>
      1 of 2
    </Typography>
  </Box>
);

const ProgressIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 14 14" fill="none">
      <g clipPath="url(#clip0_3200_61746)">
        <path
          d="M5.83339 12.1199C5.32566 12.0048 4.83803 11.8142 4.38672 11.5547"
          stroke="#0C1721"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.16797 1.87891C9.32773 2.14378 10.3632 2.79456 11.1048 3.72469C11.8465 4.65483 12.2504 5.8092 12.2504 6.99882C12.2504 8.18844 11.8465 9.34282 11.1048 10.273C10.3632 11.2031 9.32773 11.8539 8.16797 12.1187"
          stroke="#0C1721"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.67278 9.96903C2.35458 9.50672 2.1129 8.99619 1.95703 8.45703"
          stroke="#0C1721"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.82422 6.12505C1.91755 5.57089 2.09722 5.04589 2.34922 4.56464L2.4478 4.38672"
          stroke="#0C1721"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.02734 2.66991C4.57314 2.29418 5.18545 2.02574 5.83159 1.87891"
          stroke="#0C1721"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3200_61746">
          <rect width="24" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
