import React, { useRef, useState } from 'react';
import { MenuList, MenuItem, ListItemIcon, Box, Typography } from '@mui/material';
import { Form, Button } from 'src/components/shared';
import { Popover } from 'src/components/App';
import * as Yup from 'yup';
import { tenantResellersLookup } from 'src/modules/reseller/api/resellerApis';
import { fetchTeamsLookup, fetchUsersLookup } from 'src/modules/admin/api/adminApi';
import { capitalizeFirstLetter } from 'src/utils/helper';
import { FormWrapper, FormButton, FormText } from './styles';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PersonIcon from '@mui/icons-material/Person';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import GroupIcon from '@mui/icons-material/Group';

const AutomationForm = ({ postAutomation, putAutomation, dataToUpdate, closeModal }) => {
  const [loading, setLoading] = useState(false);
  const popoverResellerRef = useRef(null);
  const popoverAssignToRef = useRef(null);
  const popoverAssignRef = useRef(null);
  const handleSubmit = async (values, form) => {
    try {
      setLoading(true);
      let data = JSON.parse(JSON.stringify(values));

      if (data.assignTo === 'user') delete data.assignedTeams;
      if (data.assignTo === 'team') delete data.assignedUser;

      if (data?.resellerTenant && data?.resellerTenant?.id) {
        data.resellerTenant = data.resellerTenant.id;
      }
      if (data?.assignedTeams && data?.assignedTeams?.id) {
        data.assignedTeams = data.assignedTeams.id;
      }
      if (data?.assignedUser && data?.assignedUser?.id) {
        data.assignedUser = data.assignedUser.id;
      }

      if (dataToUpdate?.id) await putAutomation(dataToUpdate?.id, data);
      else await postAutomation(data);
      setLoading(false);
      closeModal();
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <FormWrapper>
      <Form
        initialValues={
          dataToUpdate?.id
            ? {
                resellerTenant: dataToUpdate?.resellerTenant,
                type: 'contactEnrolled',
                assignTo: dataToUpdate?.assignedTeams?.id ? 'team' : 'user',
                assignedTeams: dataToUpdate?.assignedTeams || {},
                assignedUser: dataToUpdate?.assignedUser?.id
                  ? {
                      ...dataToUpdate?.assignedUser,
                      name:
                        dataToUpdate?.assignedUser?.fname + ' ' + dataToUpdate?.assignedUser?.lname,
                    }
                  : {},
              }
            : {
                resellerTenant: {},
                type: 'contactEnrolled',
                assignTo: '',
                assignedTeams: {},
                assignedUser: {},
              }
        }
        validationSchema={Yup.object().shape({
          resellerTenant: Yup.object().test({
            message: () => 'This field is required!',
            test: (values) => {
              if (!values) return false;
              return Object.keys(values).length !== 0; // empty {}
            },
          }),
          assignedTeams: Yup.object().test({
            message: () => 'This field is required!',
            test: (values, { options }) => {
              if (options.parent.assignTo === 'user') return true;
              if (!values) return false;
              return Object.keys(values).length !== 0; // empty {}
            },
          }),
          assignedUser: Yup.object().test({
            message: () => 'This field is required!',
            test: (values, { options }) => {
              if (options.parent.assignTo === 'team') return true;
              if (!values && options.parent.assignTo === 'user') return false;
              return Object.keys(values).length !== 0; // empty {}
            },
          }),
        })}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ values, ...formProps }) => {
          const selectString = values?.assignedTeams?.id
            ? values?.assignedTeams?.name
            : values?.assignedUser?.id
            ? values?.assignedUser?.name ||
              values?.assignedUser?.fname + ' ' + values?.assignedUser?.lname
            : values.assignTo !== ''
            ? `Select ${capitalizeFirstLetter(values?.assignTo)}`
            : 'Select';

          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                // formProps.submitForm();
                handleSubmit(values);
                return false;
              }}
              noValidate
            >
              <FormText>
                When&nbsp;
                <Popover
                  trigger="click"
                  width={240}
                  Child={
                    <FormButton>
                      {values?.resellerTenant?.id
                        ? values?.resellerTenant?.name
                        : 'Select Reseller'}
                    </FormButton>
                  }
                  isPadding={true}
                  ref={popoverResellerRef}
                >
                  <Form.Field.AutoComplete
                    options={[]}
                    fullWidth={true}
                    variant="outlined"
                    showAvatar={false}
                    onSelectCustomFn={() => popoverResellerRef.current.close()}
                    remoteMethod={(val) => {
                      return tenantResellersLookup('', val);
                    }}
                    optLabel="name"
                    optValue="id"
                    optAvatar="imageUrl"
                    name="resellerTenant"
                  />
                </Popover>
                &nbsp; enrol contact in a sequence,
              </FormText>
              <Box mt={1} mb={1}>
                <ArrowDownwardIcon sx={{ fill: 'rgba(0,0,0,0.6)' }} fontSize="large" />
              </Box>

              <FormText>
                Assign to&nbsp;
                <Popover
                  trigger="click"
                  isPadding={true}
                  Child={
                    <FormButton>
                      {values.assignTo !== '' ? capitalizeFirstLetter(values?.assignTo) : 'Select'}
                    </FormButton>
                  }
                  ref={popoverAssignToRef}
                >
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        formProps.setFieldValue('assignTo', 'team');
                        formProps.setFieldValue('assignedUser', {});
                        popoverAssignToRef.current.close();
                      }}
                    >
                      <ListItemIcon>
                        <GroupIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Team</Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        formProps.setFieldValue('assignTo', 'user');
                        formProps.setFieldValue('assignedTeams', {});
                        popoverAssignToRef.current.close();
                      }}
                    >
                      <ListItemIcon>
                        <PersonIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">User</Typography>
                    </MenuItem>
                  </MenuList>
                </Popover>
                {values.assignTo !== '' ? (
                  <>
                    &nbsp;
                    <ArrowForwardIcon />
                    &nbsp;
                    <Popover
                      trigger="click"
                      isPadding={true}
                      width={240}
                      Child={<FormButton>{selectString}</FormButton>}
                      ref={popoverAssignRef}
                    >
                      {values.assignTo === 'team' ? (
                        <Form.Field.AutoComplete
                          options={[]}
                          fullWidth={true}
                          variant="outlined"
                          remoteMethod={(val) => {
                            return fetchTeamsLookup(val);
                          }}
                          optLabel="name"
                          optValue="id"
                          name="assignedTeams"
                          onSelectCustomFn={() => popoverAssignRef.current.close()}
                        />
                      ) : null}

                      {values.assignTo === 'user' ? (
                        <Form.Field.AutoComplete
                          options={[]}
                          fullWidth={true}
                          variant="outlined"
                          remoteMethod={(val) => fetchUsersLookup(val)}
                          optLabel="name"
                          optValue="id"
                          name="assignedUser"
                          onSelectCustomFn={() => popoverAssignRef.current.close()}
                        />
                      ) : null}
                    </Popover>
                  </>
                ) : null}
              </FormText>

              <Box mt={4}>
                <Button type="submit" color="secondary" variant="contained" loading={loading}>
                  {dataToUpdate?.id ? 'Update' : 'Create'} Automation
                </Button>
              </Box>
            </form>
          );
        }}
      </Form>
    </FormWrapper>
  );
};

export default AutomationForm;
