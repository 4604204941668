import React, { useState } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Stack, Alert, ButtonBase } from '@mui/material';
import { getTaskTypeText, getTaskTypeIcon } from '../../utils/tasksUtils';
import { Form, Button, Spacer } from 'src/components/shared';
import toast from 'src/utils/toast';
import { postContactNote } from 'src/modules/contacts/actions/contactActions';
import { updateTemplate } from 'src/modules/admin/api/adminApi';
import { updateTask } from 'src/modules/tasks/api/taskApis';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteIcon from '@mui/icons-material/Delete';

function LinkedIn({ task, setShowDueDateForm, noteTypes, ...props }) {
  const [loading, setLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const template = task?.template || {};

  const user = useSelector((state) => state.auth.user);
  const contactSocialLinks = useSelector((state) => state.contacts.contact.socialLinks);
  const { contact } = task;

  const handleSubmit = async (values, form) => {
    try {
      setLoading(true);
      await updateTemplate(template.id, {
        subject: values.subject,
        content: values.content,
      });
      const payload = {
        customNoteEnabled: values?.addNote,
        customNoteText: values.noteContent,
        customNoteType: values.noteType,
      };
      await updateTask(task.id, payload);
      toast.success('Task updated successfully');
      setLoading(false);
    } catch (error) {
      toast.error('Error updating task');
      setLoading(false);
    }
  };

  const handleChrome = async () => {
    try {
      let linkedIn = '';
      if (contactSocialLinks.length) {
        linkedIn = contactSocialLinks.find(
          (item) =>
            (item.type === 'linkedin' && item?.link) ||
            (item.type === 'linkedinRecruiter' && item?.link) ||
            (item.type === 'linkedinSalesNavigator' && item?.link),
        ).link;
      }
      if (linkedIn && linkedIn !== '') {
        window.open(`${linkedIn}?srtask=${task.id}`, '_blank').focus();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    props.toggleDeleteModel();
  };

  return (
    <Form
      initialValues={{
        subject: template?.subject || '',
        content: template?.content || '',
        addNote: task?.customNoteEnabled || false,
        noteType: task?.customNoteType || '',
        noteContent: task?.customNoteText || '',
      }}
      validationSchema={Yup.object().shape({})}
      onSubmit={async (values, form) => {
        handleSubmit(values, form);
        form.prevent;
      }}
      enableReinitialize={true}
    >
      {({ values, ...formProps }) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formProps.submitForm();
              return false;
            }}
            noValidate
          >
            <Alert
              severity="info"
              variant="standard"
              sx={{
                position: 'absolute',
                top: '-78px',
                left: '-32px',
                width: 'calc(100% + 32px)',
                padding: '16px',
              }}
              action={
                <Button color="secondary" variant="contained" size="small" onClick={handleChrome}>
                  Launch chrome extension
                </Button>
              }
            >
              This task must be executed in the extension.
            </Alert>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginTop: '80px' }}
            >
              <Stack direction="row" gap={1} alignItems="center">
                {getTaskTypeIcon(task.taskType)}
                <Typography component="span" variant="h3" color="textPrimary">
                  {getTaskTypeText(task.taskType)}
                </Typography>
              </Stack>
              <Stack direction="row">
                <Button iconButton onClick={setShowDueDateForm}>
                  <AccessTimeIcon />
                </Button>
                <Button iconButton loading={deleting} onClick={handleDelete}>
                  <DeleteIcon />
                </Button>

                <Spacer x={1} />
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  size="small"
                  loading={loading}
                >
                  Save
                </Button>
              </Stack>
            </Stack>

            <Typography component="div" color="textPrimary">
              Click{' '}
              <ButtonBase
                onClick={() => {
                  setShowDetails(!showDetails);
                }}
              >
                <Typography color="secondary">here</Typography>
              </ButtonBase>{' '}
              to {showDetails ? 'hide' : 'show'} message details
            </Typography>

            {showDetails && (
              <Box mt={2}>
                {task.taskType === 'linkedinMail' && (
                  <Form.Field.Input
                    name="subject"
                    label="Subject"
                    variant="outlined"
                    fullWidth={true}
                  />
                )}
                <Form.Field.TextEditor
                  name="content"
                  showFontStyle={false}
                  showFontEditor={false}
                  showAlignProps={false}
                  showListProps={false}
                  showAttachments={false}
                  showPlaceholder={false}
                />
              </Box>
            )}

            <Box ml={2} mt={3} mb={1}>
              <Form.Field.Switch name="addNote" label="Add Note" />
            </Box>
            {values.addNote === true && (
              <Box maxWidth={400}>
                <Form.Field.Select
                  options={noteTypes || []}
                  fullWidth
                  variant="outlined"
                  name="noteType"
                  label="Note Type"
                  optLabel="label"
                  optValue="value"
                  showNone={false}
                />

                <Form.Field.Input
                  fullWidth
                  variant="outlined"
                  name="noteContent"
                  label="Content"
                  multiline={true}
                  maxRows={4}
                  minRows={4}
                />
              </Box>
            )}
          </form>
        );
      }}
    </Form>
  );
}

export default LinkedIn;
