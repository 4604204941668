import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import useStyles from './style';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import CreateCompetitionForm from './CreateCompetitionForm';

function CreateCompetition({
  handleCompetitionUpdate,
  competition,
  open = false,
  schedule = false,
  session = '',
  handleClose = () => {},
  ...props
}) {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.Preview + ' Inter-font-family'}>
        <Box display={'flex'} justifyContent={'space-between'} mt={2} ml={3} mr={3} mb={1}>
          <Typography className={classes.title}>
            {competition ? 'Edit a competition ' : ' Create a competition'}
          </Typography>

          <CloseIcon onClick={handleClose} className={classes.close} />
        </Box>
        <hr className={classes.lineColor} />
        <Box ml={3} mr={3} mt={3}>
          <CreateCompetitionForm
            handleCompetitionUpdate={handleCompetitionUpdate}
            competition={competition}
            handleClose={handleClose}
            schedule={schedule}
            session={competition ? '' : session}
            {...props}
          />
        </Box>
      </Box>
    </Modal>
  );
}

export default CreateCompetition;
