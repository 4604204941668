import React from 'react';
import { isArray } from 'lodash';

import FilterPickers from './FilterPickers';

const ToolBarPickers = ({
  myFilterData = [],
  handleChange = () => {},
  className = '',
  myFilters,
  drawerFilter,
}) => {
  const setSelectedPickers = (filterData, type) => {
    if (type === 'moreFilter') {
      let removeFilter = (myFilterData || []).slice(0, myFilterData.length - 1);
      if (myFilterData.length == 1) {
        removeFilter = myFilterData;
      }
      if (myFilterData.length > 1) {
        if (
          Object.keys(myFilters).find(
            (e) => !removeFilter.find((f) => f.type == e),
          )
        )
          return Object.keys(myFilters).filter(
            (e) => !removeFilter.find((f) => f.type == e),
          );
        else return [];
      }

      if (
        Object.keys(myFilters).find((e) =>
          removeFilter.find((f) => f.type !== e),
        )
      )
        return Object.keys(myFilters).filter((e) =>
          removeFilter.find((f) => f.type !== e),
        );
      else return [];
    } else {
      return filterData || [];
    }
  };

  return (
    <>
      {myFilterData.map((filter, index) => (
        <FilterPickers
          key={`option-filter-items-${filter.name}-${index}`}
          allOptions={isArray(filter.data) ? filter.data : []}
          optValue={filter.optionValue}
          optLabel={filter.optionLabel}
          title={filter.title}
          name={filter.name}
          type={filter.type}
          selectedPickers={setSelectedPickers(
            myFilters[filter.type]?.filter,
            filter.type,
          )}
          isSingle={filter.isSingle}
          filter={filter}
          selectedFilter={myFilters[filter.type]}
          handleFilterChange={handleChange}
          className={`${className} ${filter.title.split(' ').join('')}`}
          drawerFilter={drawerFilter}
        />
      ))}
    </>
  );
};

export default ToolBarPickers;
