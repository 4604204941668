import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, Rating, Grid } from '@mui/material';
import { BackButton, Empty } from 'src/components/App';
import { Spacer, Button } from 'src/components/shared';
import { MainGrid, Left, Right, PaperWrap, PlusIcon } from './styles';

import Categories from './Categories';
import AddIcon from '@mui/icons-material/Add';
import Item from './Item';

function TemplateDetails({ templateCategories, boardTemplate, resetAction, useTemplate, onClose }) {
  const [loading, setLoading] = useState(false);

  const handleUseTemplate = async () => {
    setLoading(true);
    await useTemplate(boardTemplate);
    setLoading(false);
    onClose();
  };

  return (
    <Box>
      <MainGrid>
        <Left>
          <Categories title="Browse by category" categories={templateCategories} />
        </Left>

        <Right>
          <Box>
            <BackButton onClick={resetAction} />
            <Spacer x={1} y={1} />
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Box>
                <Typography variant="h2">{boardTemplate.name}</Typography>
                <Spacer x={1} y={1} />
                <Rating name="0 Ratings" value={0} readOnly />
                <Spacer x={0.5} y={0.5} />
                <Typography variant="body2">{boardTemplate.description}</Typography>
              </Box>
              <Box>
                <Button
                  color="secondary"
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleUseTemplate}
                  loading={loading}
                >
                  Use this template
                </Button>
              </Box>
            </Box>

            <Box mt={2}>
              {boardTemplate.widgets.length ? (
                <Grid container spacing={2}>
                  {boardTemplate.widgets.map((item, index) => {
                    return (
                      <Grid item xs={4} key={`board-template-${index}`}>
                        <Item
                          data={item}
                          type="widget"
                          handleBoardTemplateClick={props.handleBoardTemplateClick}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <Empty title="No widgets found!" />
              )}
            </Box>
          </Box>
        </Right>
      </MainGrid>
    </Box>
  );
}

export default TemplateDetails;
