import React from 'react';
import { connect } from 'react-redux';
import { PageTitle } from 'src/components/App';
import CustomFieldsView from './CustomFieldsView';
import {
  postAttribute,
  putAttribute,
  deleteAttribute,
  handleAttributeDragDrop,
} from 'src/modules/app/actions/appActions';
import { deleteLookupOption } from '../../actions/adminActions';

export const CustomFieldsContainer = (props) => {
  return (
    <>
      <PageTitle title="Manage fields" />
      <CustomFieldsView {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({
  attributes: state.app.attributes,
  unassignedFields: state.app.unassignedFields,
  modules: state.app.modules,
});

const mapDispatchToProps = (dispatch) => ({
  handleAttributeDragDrop: (module, data) => dispatch(handleAttributeDragDrop(module, data)),
  postAttribute: (payload) => {
    return new Promise((resolve, reject) => {
      dispatch(postAttribute(payload, resolve, reject));
    });
  },
  putAttribute: (attributeId, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(putAttribute(attributeId, payload, resolve, reject));
    });
  },
  deleteAttribute: (attributeId) => {
    return new Promise((resolve, reject) => {
      dispatch(deleteAttribute(attributeId, resolve, reject));
    });
  },
  deleteLookupOption: (optionId) => {
    return new Promise((resolve, reject) => {
      dispatch(deleteLookupOption(optionId, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldsContainer);
