import React from 'react';
import PropTypes from 'prop-types';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from 'src/components/shared/Button';
import { Stack } from '@mui/material';

const LMSCard = React.forwardRef(({ icon, title, action, children, sx = {} }, ref) => (
  <Card sx={sx} ref={ref}>
    <CardHeader
      title={
        <Stack direction="row" gap={1}>
          {icon && icon}
          <Typography>{title}</Typography>
        </Stack>
      }
      {...(action && { action })}
      sx={{ padding: '20px', paddingBottom: 0 }}
    ></CardHeader>
    <CardContent sx={{ padding: '20px' }}>{children}</CardContent>
  </Card>
));

LMSCard.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  action: PropTypes.any,
};

LMSCard.defaultProps = {
  title: '',
  action: undefined,
  icon: undefined,
};

export default LMSCard;
