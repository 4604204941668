import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormControl, TextField, CircularProgress, Autocomplete, IconButton } from '@mui/material';
import { fetchTasks } from 'src/modules/tasks/actions/taskActions';
import api from 'src/api';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';
import Course from './Course';
import Session from './Session';
import Pillar from './Pillar';
import useStyles from './styles';
import { themeColors } from '../../config';
import CloseIcon from '@mui/icons-material/Close';

function GlobalSearch({ tasks, placeholder, ...props }) {
  const [options, setOptions] = useState([]);
  const [defaultVal, setDefaultVal] = useState([]);
  const [search, setSearch] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [searchValue, setSearchValue] = useState(false);
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [page, setPage] = useState(0);
  const perPage = 25;
  const history = useHistory();
  const classes = useStyles();

  const onType = useCallback(
    debounce(async (e) => {
      try {
        const { value } = e.target;
        if (value && value !== '' && value.length > 3) {
          setIsEmpty(false);
          setSearch(value);
          setSearchValue(true);
          setOptions([]);
          setLoading(true);
          setDefaultVal({
            name: value,
            id: 0,
          });
          const data = await getRemoteData(value);
          if (data.length == 0) {
            setIsEmpty(true);
          }
          setOptions(data.filter((k) => k.contentType !== 'pillar'));

          setLoading(false);
        }
      } catch (error) {}
    }, 300),
    [],
  );

  const getRemoteData = async (val) => {
    return new Promise(async (resolve, reject) => {
      try {
        setHasMoreResults(false);
        const _from = page * perPage;
        const response = await api(
          `/lms/globalsearch?_search=${val}&_from=${_from}&_size=${perPage}`,
          null,
          'get',
        );
        if (response.results.length == perPage) {
          setPage(page + 1);
          setHasMoreResults(true);
        }
        resolve(response.results.filter((k) => k.contentType !== 'expert'));
      } catch (error) {
        reject(error);
      }
    });
  };

  const loadMoreResults = async () => {
    if (hasMoreResults) {
      setLoading(true);
      const response = await getRemoteData(search);
      setOptions([...options, ...response]);
      setLoading(false);
    }
  };

  const handleClick = (option) => {
    history.push(`/lms/${option.contentType}/${option.id}`);
  };
  const handleTaskOpen = (option, tabType) => {
    if (option.itemType === 'contact') {
      option.name = `${option.fname} ${option.lname} ${option.jobTitle}`;
      props.fetchTasks(
        tasks.paging,
        { ...tasks.filters, contact: option },
        tasks.sort,
        tabType,
        'all',
      );
      history.push(`/tasks/${tabType}`);
      // history.push(`/tasks/${tabType}/${option.id}`);
    } else if (option.itemType == 'sequence') {
      history.push(`/sequence/${option.id}`);
    }
  };

  const onClose = () => {
    setOptions([]);
    setDefaultVal({});
    setSearch('');
    setSearchValue(false);
  };

  const optLabel = 'name';
  const optValue = 'id';

  return (
    <FormControl fullWidth={true} margin="normal" className={classes.globalSearchRoot}>
      <Autocomplete
        multiple={false}
        noOptionsText={
          loading ? (
            <span style={{ color: themeColors.greyText }}>Please wait...</span>
          ) : isEmpty ? (
            <span style={{ color: themeColors.greyText }}>No results found!</span>
          ) : (
            <span style={{ color: themeColors.greyText }}>Start typing...</span>
          )
        }
        freeSolo={loading ? false : isEmpty ? false : true}
        value={defaultVal}
        disabled={false}
        options={options}
        getOptionLabel={(option) => search || ''}
        isOptionEqualToValue={(option, value) => {
          return option[optValue] === value[optValue];
        }}
        classes={{
          popper: classes.globalSearchPopper,
          listbox: classes.globalSearchListBox,
          paper: classes.paper,
        }}
        renderOption={(option, data) => {
          switch (data.contentType) {
            case 'session':
              return (
                <Session
                  key={`global-search-session-${data.id}`}
                  option={option}
                  data={data}
                  handleClick={handleClick}
                  onClose={onClose}
                />
              );
              break;

            case 'pillar':
              return (
                <Pillar
                  key={`global-search-pillar-${data.id}`}
                  option={option}
                  data={data}
                  handleClick={handleClick}
                  onClose={onClose}
                />
              );
              break;

            case 'course':
              return (
                <Course
                  key={`global-search-course-${data.id}`}
                  option={option}
                  data={data}
                  handleClick={handleClick}
                  handleTaskOpen={handleTaskOpen}
                  onClose={onClose}
                />
              );
              break;
          }
        }}
        onClose={onClose}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        ListboxProps={{
          onScroll: (event) => {
            const listBoxNode = event.currentTarget;
            if (listBoxNode.scrollTop + listBoxNode.clientHeight === listBoxNode.scrollHeight) {
              loadMoreResults();
            }
          },
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              value={search}
              placeholder={placeholder}
              variant="outlined"
              fullWidth={true}
              onChange={(e) => {
                e.persist();
                onType(e);
              }}
              onKeyUp={(event) => {
                setSearchKeyword(event.target.value);
                if (event.key === 'Enter') {
                  history.push(`/lms/search/${searchKeyword}`);
                }
              }}
              disabled={false}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <React.Fragment>
                    <SearchIcon
                      sx={{ fill: 'rgb(191, 191, 191)', width: '18px', height: '18px' }}
                    />
                  </React.Fragment>
                ),
                endAdornment: (
                  <React.Fragment>
                    {searchValue ? (
                      <IconButton onClick={onClose}>
                        <CloseIcon style={{ color: 'white' }} />
                      </IconButton>
                    ) : null}
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  </React.Fragment>
                ),
                classes: { notchedOutline: classes.globalSearchNoBorder },
              }}
            />
          );
        }}
        name={'globalSearch'}
      />
    </FormControl>
  );
}

const mapStateToProps = (state) => ({
  tasks: state.tasks.tasks,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTasks: (paging, filters, sort, type, currentTab) =>
    dispatch(fetchTasks(paging, filters, sort, type, currentTab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearch);
