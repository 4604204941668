import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Grid, Container } from '@mui/material';
import { Button } from 'src/components/shared';
import ReportCard from '../../components/Dashboard/ReportCard';
import UserReportGrid from '../../components/Dashboard/UserReportGrid';

import { getUserById } from 'src/modules/auth/api/authApis';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FilterBar } from 'src/components/App';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import moment from 'moment';

const ResellersDashboard = ({
  tenantId,
  userId,
  resellerFilters,
  setResellerFilters,
  resellersUsersReportSummary,
  resellersUsersReport,
  loading,
  ...props
}) => {
  const history = useHistory();
  const [user, setUser] = useState({});

  useEffect(() => {
    fetchUser(userId);
  }, [userId]);

  const fetchUser = async (userId) => {
    try {
      const data = await getUserById(userId);
      setUser(data.user);
    } catch (error) {
      setUser({});
    }
  };

  useEffect(() => {
    fetchSummary();
  }, [resellerFilters]);

  const fetchSummary = () => {
    props.fetchResellerUserReportSummary(tenantId, userId, resellerFilters);
  };

  const isFilterApplied = Object.keys(resellerFilters?.createdAtRange || {})?.length;

  return (
    <Container maxWidth="xl">
      <Box mt={2}>
        <Box mb={2} flexDirection="row" display="flex" alignItems="center">
          <FilterBar
            filters={[{ key: 'createdAtRange', title: 'Filter By Date', type: 'dateRange' }]}
            appliedFilters={resellerFilters}
            onChange={(key, value) => setResellerFilters({ [key]: value })}
          />
          {!!isFilterApplied && (
            <Button
              variant="text"
              disableElevation={true}
              onClick={() => setResellerFilters({})}
              startIcon={<ClearAllIcon />}
              sx={{
                height: 'fit-content',
                color: 'white',
                textTransform: 'none',
                fontWeight: 400,
                marginLeft: '12px',
                backgroundColor: '#1976D2',
                padding: '6px 8px',
                '&:hover': {
                  background: '#1976D2',
                },
              }}
            >
              <Typography noWrap sx={{ maxWidth: '144px' }} variant="body2">
                Clear Filter
              </Typography>
            </Button>
          )}
        </Box>
        <Box display="flex" alignItems="center" mb={1}>
          <Button
            iconButton={true}
            onClick={() => {
              history.replace(`/admin/resellers/dashboard/tenant/${tenantId}`);
            }}
            color="secondary"
          >
            <ArrowBackIcon color="secondary" />
          </Button>
          <Typography>
            {user?.fname} {user?.lname}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <ReportCard
            type="text"
            loading={loading.resellersUsersReportSummary}
            fill="rgba(255, 171, 0, 1)"
            title="Referrals Received"
            value={resellersUsersReportSummary?.noOfContactsAssigned || 0}
          />
          {/* <ReportCard
            type="text"
            loading={loading.resellersUsersReportSummary}
            fill="rgba(255, 23, 68, 1)"
            title="Duplicates Received"
            value={resellersUsersReportSummary?.noOfContactsAssigned || 0}
          /> */}

          <ReportCard
            fill="#1976D2"
            title="Referrals Sequenced"
            loading={loading.resellersUsersReportSummary}
            value={resellersUsersReportSummary?.noOfContactsSequenced || 0}
            percentage={Math.ceil(
              (resellersUsersReportSummary?.noOfContactsSequenced * 100) /
                resellersUsersReportSummary?.noOfContactsAssigned,
            )}
          />

          <ReportCard
            fill="#00897B"
            title="Not Sequenced"
            loading={loading.resellersUsersReportSummary}
            value={resellersUsersReportSummary?.noOfContactsNotSequenced || 0}
            percentage={Math.ceil(
              (resellersUsersReportSummary?.noOfContactsNotSequenced * 100) /
                resellersUsersReportSummary?.noOfContactsAssigned,
            )}
          />

          {/* <ReportCard
            loading={loading.resellersUsersReportSummary}
            type="text"
            fill="#1976D2"
            title="Number of Resellers"
            value={resellersUsersReportSummary?.noOfResellers || 0}
          /> */}
        </Grid>
        <UserReportGrid
          loading={loading}
          tenantId={tenantId}
          userId={userId}
          resellerFilters={resellerFilters}
          fetchResellerUserReport={props.fetchResellerUserReport}
          resellersUsersReport={resellersUsersReport}
        />
      </Box>
    </Container>
  );
};

export default ResellersDashboard;
