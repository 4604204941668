import React, { useState, useEffect, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { Tooltip, Typography, Box, Stack } from '@mui/material';
import { Spacer, Menu, Button, Form } from 'src/components/shared';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { green, red, amber } from '@mui/material/colors';
import noteGridHeader from './NoteGridHeader';

import InfoIcon from '@mui/icons-material/Info';
import { getPercentage } from '../../../sequence/utils/sequenceUtils';

export function useNoteColumns(
  sessions,
  fields,
  onEdit,
  onDelete,
  toggleContactDrawer,
  handleNoteEdit,
  handleSessionStatusChange,
) {
  const [columns, setColumns] = useState([]);
  // const enabledColumns = useMemo(() => {
  //   return fields?.filter(
  //     (field) => field?.fieldOptions?.showOnGrid && field?.fieldOptions?.showOnGrid === true,
  //   );
  // }, [fields]);

  const history = useHistory();

  const openSequenceDetails = (id) => {
    if (id && id !== '') {
      let path = `/sequence/${id}/steps`;
      history.push(path);
    }
  };

  useEffect(() => {
    let data = noteGridHeader.map((item) => {
      return {
        name: item.fieldName,
        label: item.label,
        options: {
          ...(item.gridParams &&
            item.gridParams?.sessions && {
              ...item.gridParams.sessions,
            }),
          ...(item.type === 'default' && {
            customBodyRenderLite: (index) => {
              const session = sessions[index];
              const noteContent = session?.notes[0]?.content;
              const name = session?.notes[0]
                ? session?.notes[0]?.createdBy?.fname + ' ' + session?.notes[0]?.createdBy?.lname
                : '';

              if (!session?.id) return '';
              switch (item.fieldName) {
                case 'name':
                  return (
                    <Tooltip title={session?.name || ''} arrow>
                      <Link
                        to={`/sequence/${session.id}`}
                        sx={{ textDecoration: 'none', cursor: 'pointer' }}
                      >
                        <Typography variant="body2" color="black">
                          {session?.title}
                        </Typography>
                      </Link>
                    </Tooltip>
                  );
                  break;

                case 'noteContent':
                  return noteContent || '';
                  break;

                case 'addedBy':
                  return name || '';
                  break;

                default:
                  return typeof session[item.fieldName] !== 'undefined' && session[item.fieldName]
                    ? session[item.fieldName]
                    : '';
                  break;
              }
            },
          }),
        },
      };
    });

    data = [
      {
        name: 'status',
        label: 'Status',
        options: {
          filter: false,
          sort: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 60, width: 60, maxWidth: 60, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 60, width: 60, maxWidth: 60, padding: 0 },
          }),
          customBodyRenderLite: (index) => {
            const session = sessions[index];
            return (
              <Box ml={1} mt={-1}>
                <Form
                  initialValues={{ published: session?.published === true }}
                  enableReinitialize={true}
                >
                  <Form.Field.Switch
                    name="published"
                    onChange={(value) => {
                      handleSessionStatusChange(
                        session.id,
                        value === true ? true : false,
                      );
                    }}
                    color="secondary"
                  />
                </Form>
              </Box>
            );
          },
        },
      },
      ...data,
    ];

    data.push({
      name: 'id',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        draggable: false,
        allowToggle: false,
        viewColumns: false,
        setCellHeaderProps: (value) => ({
          style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
        }),
        setCellProps: () => ({
          style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
        }),
        customBodyRender: (value) => {
          const session = sessions.find((tData) => tData.id === value);
          const note = session?.notes[0]
          return (
            <Menu
              options={[
                {
                  label: 'Edit Note',
                  icon: <EditIcon />,
                  // onClick: () => {
                  //   onEdit(sequence);
                  // },
                  onClick: () =>  {handleNoteEdit(note)},
                },
              ]}
            />
          );
        },
      },
    });
    setColumns(data);
  }, [noteGridHeader, sessions]);

  return columns;
}
