import React, { useState, useMemo, useRef } from 'react';
import { Container, Box, Card, CardContent, Typography } from '@mui/material';
import { Spacer, Form, Menu } from 'src/components/shared';
import { ConfirmDialog } from 'src/components/App';

import { Title, AutomateCard, ContentWrapper } from './styles';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';

const AutomationCard = ({ data, ...props }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const formRef = useRef();
  const { resellerTenant, type, assignedUser, assignedTeams, createdBy, createdAt, status } = data;

  const handleDialogSubmit = async () => {
    try {
      setIsDeleting(true);
      await props.deleteAutomation(data.id);
      setIsDeleting(false);
      setIsDialogOpen(false);
    } catch (error) {
      setIsDeleting(false);
    }
  };

  const assignToStr = useMemo(() => {
    const team = assignedTeams || {};

    switch (true) {
      case team && team?.id && team?.id !== '':
        return (
          <span>
            Team : <span className="success">{team.name}</span>
          </span>
        );

      case assignedUser && assignedUser?.id && assignedUser?.id !== '':
        return (
          <span>
            User :{' '}
            <span className="success">
              {assignedUser.fname} {assignedUser.lname}
            </span>
          </span>
        );
    }
  }, [assignedUser, assignedTeams]);

  const handleToggle = async (val) => {
    try {
      const res = await props.putAutomation(data.id, {
        status: val === true ? 'active' : 'inactive',
      });
    } catch (error) {
      formRef.current.setFieldValue('checked', !formRef.current.values.checked);
    }
  };

  return (
    <>
      <AutomateCard>
        <CardContent>
          <ContentWrapper>
            {/* <NotificationsIcon fontSize="small" style={{ fill: '#a3a3a3' }} />
            <Spacer x={1} y={1} />
            <FormatListBulletedIcon fontSize="small" style={{ fill: '#a3a3a3' }} /> */}
            <Spacer x={1} y={1} />
            <Title>
              When <span className="black">{resellerTenant.name}</span> enrol contact in a sequence,
              Assign to {assignToStr}
            </Title>
            <Box display="flex" flex={1} justifyContent="flex-end">
              <Form
                initialValues={{
                  checked: status === 'active',
                }}
                enableReinitialize={true}
                innerRef={formRef}
              >
                <Box mt={-1.8} ml={2.25} mr={-2.25}>
                  <Form.Field.Switch name="checked" onChange={handleToggle} />
                </Box>
              </Form>

              <Menu
                color="secondary"
                buttonStyles={{ padding: '0px' }}
                iconButton={true}
                options={[
                  {
                    label: 'Edit',
                    icon: <EditIcon />,
                    onClick: () => {
                      props?.updateAutomation(data);
                    },
                  },
                  {
                    label: 'Delete',
                    icon: <DeleteIcon />,
                    onClick: () => {
                      // props.deleteAutomation()
                      setIsDialogOpen(true);
                    },
                  },
                ]}
              />
            </Box>
          </ContentWrapper>
          <Box display="flex" flex={1} justifyContent="flex-end" mt={0.5} mr={1.5}>
            <Typography component="span" color="textSecondary" variant="body2">
              Created by &nbsp;
              <Typography component="span" color="secondary" variant="body2">
                {createdBy?.fname} {createdBy?.lname}
              </Typography>
              &nbsp;&nbsp;|&nbsp;&nbsp;
            </Typography>

            <Typography component="span" color="textSecondary" variant="body2">
              Updated {moment(createdAt).fromNow()}
            </Typography>
          </Box>
        </CardContent>
      </AutomateCard>
      <ConfirmDialog
        title="Delete Automation?"
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        onConfirm={handleDialogSubmit}
        loading={isDeleting}
      >
        <Typography variant="body1" color="textSecondary">
          Are you sure you want to delete this automation?
        </Typography>
      </ConfirmDialog>
    </>
  );
};

export default AutomationCard;
