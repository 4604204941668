import { Box } from '@mui/material';
import * as React from 'react';
import { themeColors } from '../../config';
import CardMedia from '../cardMedia';

export default function PhilosophySection({ imageUrl, ...props }) {
  return (
    <Box height={150} sx={{ background: themeColors.bg3,}}>
      <CardMedia
        src={imageUrl}
        style={{ height: 150 }}
        maxSize={'philosophyImage'}
        dontShowAction={true}
        isClickable={false}
        type={'philosophyImage'}
      />
    </Box>
  );
}
