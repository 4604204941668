import React from 'react';
import { Avatar, Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { Form } from 'src/components/shared';
import * as Yup from 'yup';
import useStyles from './style';
import api from 'src/api';
import toast from 'src/utils/toast';
import { createNote, getSession } from 'src/modules/lms/api/LMSApis';
import { useDispatch } from 'react-redux';
import { editNote } from 'src/modules/lms/actions/LMSActions';

function CreateNoteForm({ handleNoteUpdate, handleClose, note, ...props }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectedSession, setSelectedSession] = React.useState({});
  const [chipValues, setChipValues] = React.useState([]);
  const findEditData = async () => {
    if (note) {
      setSelectedSession({
        label: note?.name,
        value: note?.session,
      });
      //Get Shared Users list for Chips
      const users = [];
      note?.sharedWithUsers?.map((user, index) => {
        let userData = {
          label: user?.fname + ' ' + user?.lname,
          value: user?.id,
        };
        users.push(userData);
      });
      setChipValues(users);
    }
  };
  React.useEffect(() => {
    findEditData();
  }, [note]);

  const CloseIcon = () => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM17 15.59L15.59 17L12 13.41L8.41 17L7 15.59L10.59 12L7 8.41L8.41 7L12 10.59L15.59 7L17 8.41L13.41 12L17 15.59Z"
          fill="black"
          fillOpacity="0.26"
        />
      </svg>
    );
  };
  const chipDelete = (chipToDelete) => () => {
    setChipValues((oldValues) => oldValues.filter((chip) => chip !== chipToDelete));
  };
  const handleChange = (e) => {
    if (Object.keys(e).length) {
      setChipValues((oldValues) => [...oldValues, e]);
    }
  };
  const sessionOptions = async (val) => {
    try {
      let data = await api(`/lms/session?_search=${val}&_size=${300}`, null, 'get');
      let optionList = [];
      data?.sessions?.map((session, index) => {
        const option = {
          label: session?.title,
          value: session?.id,
        };
        optionList.push(option);
      });
      return optionList;
    } catch (error) {}
  };
  const userOptions = async (val) => {
    try {
      let data = await api(`/user?_search=${val}&_size=${300}`, null, 'get');
      let optionList = [];
      const filteredUser = data?.users?.filter((user) => {
        const users = [];
        chipValues.map((usr) => {
          users.push(usr.value);
        });
        if (users.includes(user.id)) {
          return;
        } else {
          return user;
        }
      });
      filteredUser.map((el) => {
        const option = {
          label: el.fname + ' ' + el?.lname,
          value: el?.id,
        };
        optionList.push(option);
      });
      return optionList;
    } catch (error) {}
  };

  return (
    <Form
      initialValues={{
        session: selectedSession,
        noteContent: note?.content || '',
        user: '',
        sharedWithUsers: '',
      }}
      validationSchema={Yup.object().shape({
        // name: Yup.string().required('Please enter board name!'),
        noteContent: Yup.string().required('Please enter note details'),
        session: Yup.object().test({
          message: () => 'Please choose a session.',
          test: (value, values) => {
            if (value.label) return true;
            return false;
          },
        }),
      })}
      enableReinitialize={true}
      onSubmit={async (values, form) => {
        const users = [];
        chipValues.map((user, i) => {
          users.push(user.value);
        });

        const payload = {
          name: values?.session?.label,
          content: values?.noteContent,
          source: 'admin',
          session: values?.session?.value,
          user: props?.user?.id,
          sharedWithUsers: users,
        };
        if (note) {
          dispatch(editNote(payload, note.id));
          handleClose();
          handleNoteUpdate();
        } else {
          try {
            const res = await createNote(payload);
            if (res) {
              toast.success('Note Created');
              handleClose();
              handleNoteUpdate();
            }
          } catch (error) {}
        }
      }}
    >
      {({ values, ...formProps }) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formProps.submitForm();
              return false;
            }}
            noValidate
          >
            <Grid container display={'block'}>
              <Grid item xs={12} lg={12} xl={12}>
                <Form.Field.AutoComplete
                  multiple={false}
                  options={[]}
                  optLabel="label"
                  optValue="value"
                  fullWidth
                  name="session"
                  placeholder="Select Sessions "
                  label="Select Sessions"
                  variant="outlined"
                  remoteMethod={(val) => {
                    return sessionOptions(val);
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={12} xl={12} mt={1}>
                <Form.Field.Textarea
                  size="small"
                  rows={4}
                  columns={10}
                  variant="outlined"
                  name="noteContent"
                  placeholder="Note details here"
                  style={{
                    height: '160px',
                    borderRadius: '3px',
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                    fontSize: '16px',
                    padding: '2.5px',
                    fontFamily: 'Roboto,sans-serif',
                    resize: 'none',
                  }}
                />
              </Grid>
              <Grid item mt={2} mb={2}>
                <Typography className={classes.informationTitle}>
                  Manage visibility (optional)
                </Typography>
                <Typography className={classes.informationSubtitle}>
                  Notes will be visible to all logged-in users by default. If you want share this
                  note with specific users, please choose your users here.
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12} xl={12}>
                <Form.Field.AutoComplete
                  multiple={false}
                  options={[]}
                  optLabel="label"
                  optValue="value"
                  fullWidth
                  name="user"
                  placeholder="Select Users "
                  label="Select User"
                  variant="outlined"
                  remoteMethod={(val) => {
                    return userOptions(val);
                  }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xl={12} lg={12} mt={4} mb={4}>
                <Stack direction="row" sx={{ overflow: 'auto' }} mt={1} spacing={1}>
                  {chipValues &&
                    chipValues.map((btn, key) => (
                      <Stack direction="row" spacing={2}>
                        <Box className={classes.chip}>
                          <Box>
                            <Avatar sx={{ width: 36, height: 36, margin: '6px' }} />
                          </Box>
                          <Typography>{btn.label}</Typography>
                          <IconButton onClick={chipDelete(btn)}>
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </Stack>
                    ))}
                </Stack>
              </Grid>

              <Grid item xl={12} lg={12} mt={4} mb={4} display={'flex'} justifyContent={'right'}>
                <Button color="secondary" onClick={handleClose}>
                  {'Cancel'}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  //   loading={loading}
                >
                  {'Save note'}
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Form>
  );
}

export default CreateNoteForm;
