import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Chip, Typography, CardContent, CircularProgress } from '@mui/material';
import { InfoIconWrapper, InfoIcon, Content, Title, Value, colors } from './styles';
import { fetchWidgetData } from '../../../actions/boardActions';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const AppBarChart = ({ data }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWidgetData(data.i));
  }, []);

  const { title, description, key, loading } = data;
  const widgetData = data.data;

  const columns = useMemo(() => {
    if (!widgetData.length) {
      return [];
    }
    return Object.keys(widgetData[0]).filter((item) => item !== 'name');
  }, [widgetData]);

  return (
    <CardContent sx={{ height: 'calc(100% - 32px)' }}>
      <Typography variant="h3">{title}</Typography>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <Box display="flex" width="100%" height="100%" mt={2}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={widgetData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              barSize={40}
            >
              <XAxis dataKey="name" scale="point" padding={{ left: 40, right: 40 }} />
              <YAxis />
              <Tooltip />
              <Legend />
              <CartesianGrid strokeDasharray="3 3" />
              {columns.length ? (
                <>
                  {columns.map((item, index) => {
                    return (
                      <Bar
                        key={`${data.i}-widget-${index}`}
                        dataKey={item}
                        fill={colors[index]}
                        background={{ fill: `${colors[index]}05` }}
                      />
                    );
                  })}
                </>
              ) : null}
            </BarChart>
          </ResponsiveContainer>
        </Box>
      )}
    </CardContent>
  );
};

export default AppBarChart;
