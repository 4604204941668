import { makeStyles } from '@mui/styles';
import { themeColors } from '../../config';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    margin: 0,
    height: '60px',
    left: '231px',
    width: 'calc(100% - 231px)',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  rootBoards: {
    margin: 0,
    height: '50px',
    left: '230px',
    width: 'calc(100% - 230px)',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  rootDashboard: {
    margin: 0,
    height: '50px',
    left: '300px',
    width: 'calc(100% - 300px)',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchPopper: {
    width: '400px !important',
    marginLeft: '100px',
    overflowX: 'hidden',
  },
  toolbarStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 12px 0 6px',
  },
  userButton: {
    textTransform: 'none',
    color: themeColors.text,
    padding: '8px 12px',
  },
  button: {
    minWidth: 0,
  },
  drawer: {
    background: '#000',
    width: '150%',
    padding: '5px 12px',
  },
  menuPaper: {
    '& .MuiListItemIcon-root': {
      minWidth: '40px',
    },
  },
  topLinks: {
    fontSize: '16px',
    fontWeight: 400,
    color: themeColors.text,
    letterSpacing: 0.15,
    font: 'inter',
    marginLeft: '34px',
  },
  badge: {
    '& .css-u9yflb-MuiBadge-badge': {
      left: '8px',
      height: '15px',
      width: '15px',
      background: '#EF5089 !important',
      color: 'white',
      minWidth: '0px !important',
    },
  },
  CompetitionHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '50px',
  },
}));

export default useStyles;
