import Tasks from '../container/tasks';
import Task from '../container/task';
import TaskLegacy from '../container/task/TaskLegacy';
import ErrorContainer from '../container/task/ErrorContainer';
import TaskError from '../container/taskError';
import OutboxContainer from '../container/outbox/OutboxContainer';
import InboxContainer from '../container/inbox';
import SentContainer from '../container/sent';

export default [
  {
    title: 'Tasks',
    component: Tasks,
    url: '/tasks',
    exact: true,
    auth: true,
  },
  {
    title: 'Tasks',
    component: Tasks,
    url: '/tasks/:type',
    exact: true,
    auth: true,
  },
  {
    title: 'Task Error',
    component: ErrorContainer,
    url: '/task/errors/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'Task',
    component: Task,
    url: '/task/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'Task',
    component: TaskLegacy,
    url: '/tasks/:type/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'Inbox',
    component: InboxContainer,
    url: '/inbox',
    exact: true,
  },
  {
    title: 'Sent',
    component: SentContainer,
    url: '/sent',
    exact: true,
  },
  {
    title: 'Outbox',
    component: OutboxContainer,
    url: '/outbox',
    exact: true,
  },
];
