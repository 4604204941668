import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';

export const TabsWrapper = styled.div``;

export const TabItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
`;

export const TabsSkeleton = () => {
  return (
    <TabsWrapper>
      <TabItem>
        <Skeleton animation="wave" height={24} width={'80%'} />
      </TabItem>
      <TabItem>
        <Skeleton animation="wave" height={24} width={'60%'} />
      </TabItem>
      <TabItem>
        <Skeleton animation="wave" height={24} width={'40%'} />
      </TabItem>
      <TabItem>
        <Skeleton animation="wave" height={24} width={'60%'} />
      </TabItem>
    </TabsWrapper>
  );
};
