import React from 'react';
import { Grid, Box, Typography, Paper, Link, Stack } from '@mui/material';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Button, Form } from 'src/components/shared';
import { useState } from 'react';

export default function EmailLimits({ emailLimit, id, ...props }) {
  const handleSubmit = async (values, form) => {
    const data = { ...values };
    data.id = id || '';
    await props.updateSettings(data);
  };

  return (
    <Form
      initialValues={{
        emailLimit: emailLimit || '',
      }}
      validationSchema={Yup.object().shape({
        emailLimit: Yup.number()
          .positive("A emailLimit  can't start with a minus")
          .integer("A emailLimit can't include a decimal point")
          .min(4)
          .required('A emailLimit is required'),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={false}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <Grid container>
              <Grid item xs={8}>
                <Form.Field.Input
                  fullWidth
                  size="small"
                  rows={4}
                  variant="outlined"
                  name="emailLimit"
                  label="No. of Emails"
                />
              </Grid>
              <Grid item xs={4} p={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={false}
                  disabled={false}
                >
                  UPDATE
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Form>
  );
}
