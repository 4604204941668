import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Typography, Box, Grid, ButtonBase } from '@mui/material';
import { Modal, Spacer, Drawer } from 'src/components/shared';
import images from 'src/config/images';

import CloneSequenceForm from './CloneSequenceForm';
import CreateSequenceForm from './CreateSequenceForm';

import { toggleAddSequenceForm } from 'src/modules/app/actions/appActions';
import { cloneSequence, postSequence } from 'src/modules/sequence/actions/sequenceActions';

const SequenceForm = ({ ...props }) => {
  const [showDefaultModal, setShowDefaultModal] = useState(true);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showCloneForm, setShowCloneForm] = useState(false);

  useEffect(() => {
    setShowDefaultModal(true);
  }, []);

  const switchForm = (type) => {
    setShowDefaultModal(false);
    if (type === 'create') {
      setShowCloneForm(false);
      setShowCreateForm(true);
    } else {
      setShowCreateForm(false);
      setShowCloneForm(true);
    }
  };

  const onClose = () => {
    setShowDefaultModal(true);
    setShowCreateForm(false);
    setShowCloneForm(false);
    props.toggleAddSequenceForm();
  };

  const onCreateSequence = (sequence) => {
    return props.postSequence(sequence);
  };

  return (
    <>
      <Modal size="sm" title="Create sequence" open={showDefaultModal} onClose={onClose}>
        <Spacer y={2} />
        <Box p={2} ml={4} mr={4} mt={2} mb={4}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ButtonBase
                onClick={() => {
                  switchForm('clone');
                }}
              >
                <Box
                  display={'flex'}
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                >
                  <img src={images.sequence.CloneSeqIcon} style={{ height: 64 }} />
                  <Spacer basis={4} />
                  <Typography variant="body1" color="secondary">
                    Use existing sequence
                  </Typography>
                  <Spacer basis={1} />
                  <Typography variant="body2" color="textSecondary">
                    Create a sequence from an existing template.
                  </Typography>
                </Box>
              </ButtonBase>
            </Grid>
            <Grid item xs={6}>
              <ButtonBase
                onClick={() => {
                  switchForm('create');
                }}
              >
                <Box
                  display={'flex'}
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                >
                  <img src={images.sequence.AddSeqIcon} style={{ height: 64 }} />
                  <Spacer basis={4} />
                  <Typography variant="body1" color="secondary">
                    Create new sequence
                  </Typography>
                  <Spacer basis={1} />
                  <Typography variant="body2" color="textSecondary">
                    Create a sequence from scratch. Specify the steps and settings required for a
                    new sequence.
                  </Typography>
                </Box>
              </ButtonBase>
            </Grid>
          </Grid>
          <Spacer y={2} />
        </Box>
      </Modal>

      <Modal title="Clone Sequence" size="xs" open={showCloneForm} onClose={onClose}>
        <CloneSequenceForm cloneSequence={props.cloneSequence} />
      </Modal>

      <Drawer
        title="Create sequence"
        open={showCreateForm}
        onClose={onClose}
        showCloseConfirmation={true}
      >
        <CreateSequenceForm
          sequenceAccessTypeList={props.sequenceAccessTypeList}
          sequenceEmailTypeList={props.sequenceEmailTypeList}
          user={props.user}
          onClose={onClose}
          onSubmit={onCreateSequence}
        />
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => ({
  sequenceAccessTypeList: state.app.globals.sequenceAccessTypeList,
  sequenceEmailTypeList: state.app.globals.sequenceEmailTypeList,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  cloneSequence: (sequenceId, sequenceName) => {
    return new Promise((resolve, reject) => {
      dispatch(cloneSequence(sequenceId, sequenceName, resolve, reject));
    });
  },
  postSequence: (data) => {
    return new Promise((resolve, reject) => {
      dispatch(postSequence(data, resolve, reject));
    });
  },
  toggleAddSequenceForm: () => dispatch(toggleAddSequenceForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SequenceForm);
