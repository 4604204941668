export const layoutsData = {
  lg: [
    {
      i: 'a',
      x: 0,
      y: 0,
      w: 1,
      h: 1,
      minW: 1,
      maxW: 1,
      minH: 1,
      maxH: 1,
      title: 'Clicked',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,',
      progress: 20,
      type: 'chip',
    },
    {
      i: 'b',
      x: 1,
      y: 0,
      w: 1,
      h: 1,
      minW: 2,
      maxW: 4,
      title: 'Opened',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,',
      progress: 66,
      type: 'progress',
    },
    {
      i: 'c',
      x: 2,
      y: 0,
      w: 1,
      h: 1,
      title: 'Prospects Enrolled',
      progress: -25,
      type: 'chip',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,',
    },
    {
      i: 'd',
      x: 3,
      y: 0,
      w: 1,
      h: 1,
      title: 'Replied',
      progress: 90,
      type: 'chip',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,',
    },
    {
      i: 'z',
      x: 1,
      y: 0,
      w: 1,
      h: 1,
      title: 'Interested',
      progress: -44,
      type: 'chip',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,',
    },
  ],
};
