import React from 'react';
import * as Yup from 'yup';
import { Grid, Box } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import { sequenceWaitUnitOptions } from '../../../config';

function WaitTimeForm({ onSubmit, loading, event, handleClose, ...props }) {
  const handleSubmit = (values) => {
    onSubmit(values);
  };

  return (
    <Form
      initialValues={{
        eventType: 'wait',
        waitTime: event?.waitTime || 5,
        unit: event?.unit || 'days',
      }}
      validationSchema={Yup.object().shape({
        unit: Yup.string().required('Please select Wait Period!'),
        waitTime: Yup.number()
          .integer()
          .moreThan(0, 'Wait time must be greater than 0')
          .required('Please enter Wait time!'),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
              height="100%"
              width="100%"
            >
              <Box width="100%">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Form.Field.Select
                      options={sequenceWaitUnitOptions}
                      fullWidth
                      variant="outlined"
                      name="unit"
                      label="Select Wait Period"
                      showNone={false}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Form.Field.Number
                      fullWidth
                      showCounter={true}
                      variant="outlined"
                      name="waitTime"
                      label="Wait Time"
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mt={2} display="flex" justifyContent="flex-end" width="100%">
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={loading}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Spacer basis={2} />
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}

export default WaitTimeForm;
