import React from 'react';
import { Box, Card, CardContent } from '@mui/material';
import { Skeleton } from '@mui/lab';
import { Avatar, Spacer } from 'src/components/shared';
import {
  ProfileWrapper,
  AvatarWrapper,
  DetailsWrapper,
  List,
  PanelHeader,
  DropList,
} from './styles';

export const UserCardSkeleton = () => {
  return (
    <Card>
      <CardContent sx={{ padding: 1.5, overflow: 'hidden', paddingBottom: '12px !important' }}>
        <ProfileWrapper>
          <AvatarWrapper>
            <Avatar size={48} src={undefined} name={undefined} />
          </AvatarWrapper>
          <DetailsWrapper>
            <Skeleton animation="wave" height={24} width={'40%'} />
            <Skeleton animation="wave" height={18} width={'100%'} />
          </DetailsWrapper>
        </ProfileWrapper>
      </CardContent>
    </Card>
  );
};

function PanelSkeleton() {
  return (
    <>
      <List>
        <PanelHeader>
          <Skeleton animation="wave" height={24} width={'28%'} />
          <Spacer x={0.5} y={0.5} />
          <Skeleton animation="wave" height={16} width={'50%'} />
        </PanelHeader>
        <DropList>
          <UserCardSkeleton />
          <Spacer x={1} y={1} basis={2} />
          <UserCardSkeleton />
        </DropList>
      </List>
      <List>
        <PanelHeader>
          <Skeleton animation="wave" height={24} width={'28%'} />
          <Spacer x={0.5} y={0.5} />
          <Skeleton animation="wave" height={16} width={'50%'} />
        </PanelHeader>
        <DropList>
          <UserCardSkeleton />
        </DropList>
      </List>
      <List>
        <PanelHeader>
          <Skeleton animation="wave" height={24} width={'28%'} />
          <Spacer x={0.5} y={0.5} />
          <Skeleton animation="wave" height={16} width={'50%'} />
        </PanelHeader>
        <DropList>
          <UserCardSkeleton />
        </DropList>
      </List>
    </>
  );
}

export default PanelSkeleton;
