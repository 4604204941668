import React from 'react';
import { Stack } from '@mui/material';
import { IntroTitle, IntroDescription, useStyles } from './styles';

function Intro({ user, ...props }) {
  return (
    <Stack>
      <IntroTitle variant="h1">SuperReach Success</IntroTitle>
      <IntroDescription>Unlock your full potential with SuperReach!</IntroDescription>
    </Stack>
  );
}

export default Intro;
