import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Typography, Box, Stack, Chip, ButtonBase } from '@mui/material';
import { Menu, Button } from 'src/components/shared';

import Moment from 'react-moment';
import { dateFormat } from 'src/config';
import { getTaskTypeIcon, getTaskTypeText } from '../../utils/tasksUtils';
import { getContactName } from 'src/modules/contacts/utils/contactUtils.js';

import { putTask } from '../../actions/taskActions';
import { HtmlTooltip } from './styles';
import { green, red, blue } from '@mui/material/colors';

import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { toggleContactDrawer } from 'src/modules/app/actions/appActions';
import { bindTrigger } from 'material-ui-popup-state/hooks';

import styled from 'styled-components';
import { capitalizeFirstLetter } from 'src/utils/helper';
export const StatsText = styled(Typography).attrs({
  variant: 'body2',
})`
  color: ${(props) =>
    props?.count === 0
      ? 'rgba(0, 0, 0, 0.87)'
      : props?.count > 50
      ? green[500]
      : red[500]} !important;
`;

export function useTaskColumns(tasks, onEdit, onDelete, type, loadSequenceStaging) {
  const [columns, setColumns] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setColumns([
      {
        label: 'Task type',
        name: 'taskType',
        options: {
          sort: false,
          setCellHeaderProps: (value) => ({
            style: { width: 136 },
          }),
          setCellProps: () => ({
            style: { width: 136 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = tasks[dataIndex];
            const icon = getTaskTypeIcon(rData?.taskType);
            const type = getTaskTypeText(rData?.taskType || '');
            return (
              <Button
                onClick={() => {
                  history.push(`/task/${rData.id}`);
                }}
                sx={{ '& .MuiButton-startIcon': { marginLeft: 0, '& svg': { fontSize: '24px !important' } } }}
                startIcon={icon}
              >
                <Typography variant="body2" whiteSpace={'pre'} color="textSecondary">
                  {type}
                </Typography>
              </Button>
            );
          },
        },
      },
      {
        label: 'Contact name',
        name: 'contact',
        options: {
          filter: true,
          sort: false,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 70 },
          }),
          setCellProps: () => ({
            style: { minWidth: 70 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const task = tasks[dataIndex];
            return (
              <Stack direction="column" justifyContent="flex-start" alignItems="flex-start">
                <ButtonBase
                  onClick={() => {
                    dispatch(toggleContactDrawer(task.contact.id));
                  }}
                >
                  <Typography variant="body2">{getContactName(task?.contact || {})}</Typography>
                </ButtonBase>
                {task?.sequence && task?.sequence?.name !== '' && (
                  <ButtonBase
                    onClick={() => {
                      window.open(`/sequence/${task?.sequence?.id}`, '_blank');
                    }}
                  >
                    <Typography variant="body2" color="secondary">
                      {task?.sequence?.name}
                    </Typography>
                  </ButtonBase>
                )}
              </Stack>
            );
          },
        },
      },
      {
        label: 'Task detail',
        name: 'description',
        options: {
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const task = tasks[dataIndex];
            var description = task?.description ? task.description.replace(/<img[^>]*>/g, '') : '';
            return (
              <Box
                display="flex"
                sx={{
                  cursor: 'pointer',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '11rem',
                }}
                onClick={() => {
                  history.push(`/task/${task.id}`);
                }}
                minWidth={80}
              >
                {task?.description ? (
                  <HtmlTooltip title={<div dangerouslySetInnerHTML={{ __html: description }} />}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      noWrap
                      component="span"
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                      }}
                    >
                      <div dangerouslySetInnerHTML={{ __html: description }} />
                    </Typography>
                  </HtmlTooltip>
                ) : null}
              </Box>
            );
          },
        },
      },
      {
        label: 'Sequence Stage',
        name: 'stage',
        options: {
          sort: false,
          setCellHeaderProps: (value) => ({
            style: { width: 120 },
          }),
          setCellProps: () => ({
            style: { width: 120 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const task = tasks[dataIndex];
            if (task?.enrollment && task?.enrollment !== '') {
              const label = (
                <Stack direction="row">
                  <Typography color="textSecondary">
                    {task?.taskOrderInSequence + 1}/{task?.totalTasksInEnrollment || 0}
                  </Typography>{' '}
                  <ArrowDropDownIcon sx={{ color: 'rgba(0,0,0,0.4)' }} />
                </Stack>
              );
              return (
                <>
                  <ButtonBase
                    onClick={(e) => {
                      e.persist();
                      loadSequenceStaging(e, task);
                    }}
                  >
                    <Chip label={label} />
                  </ButtonBase>
                </>
              );
            }
            return (
              <Typography variant="body2" color="textSecondary">
                -
              </Typography>
            );
          },
        },
      },
      {
        label: 'Status',
        name: 'status',
        options: {
          sort: false,
          setCellHeaderProps: (value) => ({
            style: { width: 80 },
          }),
          setCellProps: () => ({
            style: { width: 80 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const task = tasks[dataIndex];
            return (
              <Typography variant="body2" color="textSecondary">
                {capitalizeFirstLetter(task?.status || '')}
              </Typography>
            );
          },
        },
      },
      {
        label: 'Due date',
        name: 'scheduledOn',
        options: {
          sort: true,
          setCellHeaderProps: (value) => ({
            style: { width: 120 },
          }),
          setCellProps: () => ({
            style: { width: 120 },
          }),
          customBodyRenderLite: (dataIndex) => {
            let type = '';
            const task = tasks[dataIndex];
            const dueDate = new Date(task?.scheduledOn);
            let count = 0;
            if (dueDate.toDateString() === new Date().toDateString()) {
              count = 0;
            } else {
              count = dueDate - new Date();
            }

            return (
              <Button
                onClick={() => {
                  history.push(`/task/${task.id}`);
                }}
              >
                <StatsText
                  count={count || 0}
                  variant="body2"
                  color="textSecondary"
                  component="span"
                  sx={{ marginLeft: '-10px' }}
                >
                  <Moment format={dateFormat}>{dueDate}</Moment>
                </StatsText>
              </Button>
            );
          },
        },
      },
      {
        name: 'taskId',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          allowToggle: false,
          viewColumns: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 45, width: 45, maxWidth: 45, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 45, width: 45, maxWidth: 45, padding: 0 },
          }),
          customBodyRenderLite: (index) => {
            const task = tasks[index];
            return (
              <Menu
                options={[
                  {
                    label: 'Edit',
                    icon: <EditIcon />,
                    onClick: () => {
                      onEdit(task);
                    },
                  },
                  {
                    label: 'Show task details',
                    icon: <InfoIcon />,
                    onClick: () => {
                      history.push(`task/${task.id}`);
                    },
                  },
                  {
                    label: 'Mark as complete',
                    icon: <CheckCircleIcon />,
                    onClick: () => {
                      return new Promise((resolve, reject) => {
                        dispatch(
                          putTask(
                            task.id,
                            {
                              status: 'completed',
                            },
                            resolve,
                            reject,
                          ),
                        );
                      });
                    },
                  },
                ]}
              />
            );
          },
        },
      },
    ]);
  }, [tasks]);

  return columns;
}
