import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

function index(props) {
  useEffect(() => {
    console.log('PROPS : ', props);
  }, [props]);

  if (!props?.match?.params?.id) {
    return null;
  }

  return <Redirect to={`/task/${props.match.params.id}`} />;
}

export default index;
