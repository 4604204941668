import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Box, Typography, Alert } from '@mui/material';
import { Spacer } from 'src/components/shared';

import ForgotPasswordForm from '../../components/ForgotPasswordForm';
import toast from 'src/utils/toast';
import _ from 'lodash';

export default function LoginView(props) {
  const history = useHistory();
  const [email, setEmail] = useState(undefined);

  const onSubmit = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        await props.forgotPassword(data);
        setEmail(data.email);
        toast.success(
          'We have sent you instructions to reset your password if there is an account for this email address. Check your spam folder if you do not receive it.',
        );
        return resolve(true);
      } catch (error) {
        setEmail(undefined);
        toast.success(
          'We have sent you instructions to reset your password if there is an account for this email address. Check your spam folder if you do not receive it.',
        );
        return reject(false);
      }
    });
  };

  const getContent = () => {
    return <Typography component="h1">Login</Typography>;
  };

  return (
    <React.Fragment>
      <Typography variant="h1" align="center">
        Reset password
      </Typography>
      <div style={{ height: 10 }} />
      <div style={{ maxWidth: 400, margin: '0 auto' }}>
        {email ? (
          <Box>
            <Spacer x={2} y={2} />
            <Alert severity="success" variant="standard">
              We’ve sent an email to {email} with password reset instructions.
            </Alert>
            <Spacer x={2} y={2} />
            <Typography variant="body2" color="textSecondary">
              If you have not received an email, please check your Spam folder.
            </Typography>
            <Spacer x={1} y={1} />
            <Link to="/auth/login">
              <Typography variant="body2" color="secondary" style={{ cursor: 'pointer' }}>
                Return to Login
              </Typography>
            </Link>
          </Box>
        ) : (
          <>
            <ForgotPasswordForm onSubmit={onSubmit} />
          </>
        )}
      </div>
    </React.Fragment>
  );
}
