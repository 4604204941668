import React, { useState, useEffect } from 'react';
import { Typography, Chip, ButtonBase } from '@mui/material';
import moment from 'moment';
import { dateFormat } from 'src/config/index';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button } from 'src/components/shared';

import { capitalizeName } from 'src/modules/contacts/utils/contactUtils';
import { Stack } from '@mui/material';

export function useColumns(data, fetchInvoice, payNowInvoice) {
  const [columns, setColumns] = useState([]);

  const downloadInvoice = async (invoice) => {
    try {
      const res = await fetchInvoice(invoice.id);
      const url = res.invoice.download_url;
      window.open(url, '_blank');
    } catch (error) {}
  };

  useEffect(() => {
    if (!data || !data.length) {
      setColumns([]);
    } else {
      setColumns([
        {
          label: ' ',
          name: ' ',
          options: {
            filter: true,
            sort: true,
            draggable: true,
            setCellHeaderProps: (value) => ({
              style: { maxWidth: '16px', minWidth: '16px' },
            }),
            setCellProps: () => ({
              style: { maxWidth: '16px', minWidth: '16px' },
            }),
            customBodyRenderLite: (dataIndex) => {
              const invoice = data[dataIndex];
              return (
                <>
                  <Button
                    iconButton
                    onClick={() => {
                      downloadInvoice(invoice);
                    }}
                  >
                    <VisibilityIcon />
                  </Button>
                </>
              );
            },
          },
        },
        {
          label: 'Invoice ID',
          name: 'id',
          options: {
            filter: true,
            sort: true,
            draggable: true,
            customBodyRenderLite: (dataIndex) => {
              const invoice = data[dataIndex];
              return (
                <ButtonBase
                  onClick={() => {
                    downloadInvoice(invoice);
                  }}
                >
                  <Typography variant="body2" color="secondary">
                    #{invoice.id}
                  </Typography>
                </ButtonBase>
              );
            },
          },
        },
        {
          label: 'Invoice Date',
          name: 'invoiceDate',
          options: {
            filter: true,
            sort: true,
            draggable: true,
            customBodyRenderLite: (dataIndex) => {
              const invoice = data[dataIndex];
              return (
                <Typography variant="body2" color="textPrimary">
                  {moment(invoice.invoiceDate).format(dateFormat)}
                </Typography>
              );
            },
          },
        },
        {
          label: 'Billed Amount',
          name: 'billedAmount',
          options: {
            filter: true,
            sort: true,
            draggable: true,
            customBodyRenderLite: (dataIndex) => {
              const invoice = data[dataIndex];
              return (
                <Typography variant="body2" color="textPrimary">
                  ${invoice.billedAmount}
                </Typography>
              );
            },
          },
        },
        {
          label: 'Status',
          name: 'status',
          options: {
            filter: true,
            sort: true,
            draggable: true,
            customBodyRenderLite: (dataIndex) => {
              const invoice = data[dataIndex];

              let status = invoice.status;
              status = status.replace('_', ' ');
              status = capitalizeName(status);

              const color = invoice.status === 'paid' ? 'success' : 'error';

              return (
                <Stack direction={'row'} gap={'60px'}>
                  <Chip label={status} color={color} />
                  {invoice.status === 'payment_due' && (
                    <Button color="secondary" variant="contained" onClick={() => payNowInvoice()}>
                      Pay Now
                    </Button>
                  )}
                </Stack>
              );
            },
          },
        },
      ]);
    }
  }, [data]);

  return columns;
}
