import { createContext, useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
const billingContext = createContext({});

import {
  getInvoices,
  getInvoice,
  fetchCustomer,
  putCustomer,
  fetchAllSubscriptions,
  fetchSubscriptions,
  checkout,
  checkoutCharge,
  payInvoice,
} from '../api/billingApi';
import { isEmpty } from 'lodash';
import { getRelativeDateRange } from 'src/utils/dateUtils';

export const useDataProviders = () => {
  const {
    invoices,
    fetchInvoices,
    fetchInvoice,
    payNowInvoice,
    loading,
    customer,
    getCustomer,
    card,
    updateCustomer,
    getAllSubscriptions,
    getSubscriptions,
    allSubscriptions,
    subscriptions,
    getHostedPage,
    appliedFilters,
    setAppliedFilters,
  } = useContext(billingContext);
  return {
    invoices,
    fetchInvoices,
    fetchInvoice,
    payNowInvoice,
    loading,
    customer,
    getCustomer,
    card,
    updateCustomer,
    getAllSubscriptions,
    getSubscriptions,
    allSubscriptions,
    subscriptions,
    getHostedPage,
    appliedFilters,
    setAppliedFilters,
  };
};

export const BillingStore = ({ children }) => {
  const [invoices, setInvoices] = useState([]);
  const [customer, setCustomer] = useState({});
  const [card, setCard] = useState({});
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [subscriptions, setSubscriptions] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({
    paging: { pageNo: 0, perPage: 10 },
    filters: {},
  });
  const [loading, setLoading] = useState({
    invoices: false,
    customer: false,
  });

  useEffect(() => {
    if (!location.pathname.includes('/auth/setup/password')) fetchInvoices();
  }, [appliedFilters]);

  const tenant = useSelector((state) => state.app.tenant);
  const fetchInvoices = async () => {
    try {
      setLoading({
        ...loading,
        invoices: true,
      });
      const { paging, filters } = appliedFilters;
      let filter = {
        _from: paging.pageNo * paging.perPage,
        _size: paging.perPage,
        ...(filters?._search && !isEmpty(filters?._search) ? { _search: filters?._search } : {}),
        ...(filters?.status && !isEmpty(filters?.status) ? { status: filters?.status.value } : {}),
      };

      if (filters?.createdAtRange && !isEmpty(filters?.createdAtRange)) {
        const dateRange = getRelativeDateRange(filters?.createdAtRange);
        filter = {
          ...filter,
          createdAt_gte: dateRange.start,
          createdAt_lte: dateRange.end,
        };
      }

      const res = await getInvoices(filter);
      setInvoices(res.invoices);
      setLoading({
        ...loading,
        invoices: false,
      });
    } catch (error) {
      setInvoices([]);
    }
  };

  const fetchInvoice = (id) => {
    return getInvoice(id);
  };

  const payNowInvoice = (id) => {
    return payInvoice(id);
  };

  const getCustomer = async (chargeBeeId) => {
    try {
      setLoading({
        ...loading,
        customer: true,
      });
      const customer = await fetchCustomer(chargeBeeId);
      setCustomer(customer.customer);
      setCard(customer.card);
      setLoading({
        ...loading,
        customer: false,
      });
    } catch (error) {}
  };

  const updateCustomer = async (chargeBeeId, data) => {
    try {
      const customer = await putCustomer(chargeBeeId, data);
      setCustomer(customer.customer);
      setCard(customer.card);
      return customer;
    } catch (error) {}
  };

  const getAllSubscriptions = async () => {
    try {
      const res = await fetchAllSubscriptions();
      setAllSubscriptions([
        ...res.plans,
        {
          ...res.charges.data,
          recharge: 'data',
          type: 'one-time',
        },
        {
          ...res.charges.roleplay,
          recharge: 'roleplay',
          type: 'one-time',
        },
      ]);
    } catch (error) {}
  };

  const getSubscriptions = async (chargeBeeId) => {
    try {
      const res = await fetchSubscriptions(chargeBeeId);
      setSubscriptions(res);
    } catch (error) {}
  };

  const getHostedPage = async (
    planId,
    subscription,
    isCharge,
    quantity = 1,
    discount = undefined,
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (isCharge) {
          const payload = {
            chargeId: planId,
            customerId: tenant.id,
            ...(discount ? { discount } : {}),
          };
          const res = await checkoutCharge(payload, subscription?.id || undefined);
          resolve(res);
        } else {
          const payload = {
            item_price_id: planId,
            quantity: quantity,
            ...(discount ? { discount } : {}),
          };
          const res = await checkout(payload, subscription?.id || undefined);
          resolve(res);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  return (
    <billingContext.Provider
      value={{
        invoices,
        fetchInvoices,
        fetchInvoice,
        payNowInvoice,
        loading,
        customer,
        card,
        getCustomer,
        updateCustomer,
        getAllSubscriptions,
        getSubscriptions,
        allSubscriptions,
        subscriptions,
        appliedFilters,
        setAppliedFilters,
        getHostedPage,
      }}
    >
      {children}
    </billingContext.Provider>
  );
};

export const withBillingStore = (Component) => (props) =>
  (
    <BillingStore>
      <Component {...props} />
    </BillingStore>
  );
