import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Alert } from '@mui/material';
import { Button, Form } from 'src/components/shared';
import { fetchTemplatesLookup } from 'src/modules/app/api/appApis';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';
import { fetchTemplateDetails } from 'src/modules/admin/api/adminApi';
import { debounce } from 'lodash';
import {
  fetchPopulateEnqueueTemplate,
  setPersonalizeStep,
} from '../../../actions/enrolmentActions';
import { getInputReplacementVariables } from 'src/modules/app/actions/appSelector';
import { useState } from 'react';

function SelectEmail({ task, taskId, contact, formRef }) {
  const dispatch = useDispatch();
  const [editorRef, setEditorRef] = useState(null);

  const replacementVariables = useSelector((state) => getInputReplacementVariables(state));

  const moveCursorToEnd = () => {
    try {
      editorRef.current.selection.select(editorRef.current.getBody(), true);
      editorRef.current.selection.collapse(false);
      editorRef.current.focus();
    } catch (error) {}
  };

  let values = formRef.current ? formRef.current.values : {};
  values = values[taskId] || {};
  const showFields = useMemo(() => {
    return values?.templateId && values?.templateId?.id && values?.templateId?.id !== '';
  }, [values]);

  const onChange = async (val, type) => {
    if (formRef.current) {
      let data = formRef.current.values;
      data = data[taskId];
      const templateId = task?.template?.id || '';
      await populateTemplate(contact.id, templateId, data.subject, data.content);
      if (type === 'content') {
        moveCursorToEnd();
      }
    }
  };

  const getChildEditorRef = (editorRef) => {
    if (editorRef && editorRef !== null) {
      setEditorRef(editorRef);
    }
  };

  const handleChange = useCallback(
    debounce((newValue, type) => onChange(newValue, type), 1000),
    [editorRef],
  );

  useEffect(() => {
    const templateId = task?.template?.id || '';
    let data = formRef.current.values;
    data = data[taskId];
    if (templateId && templateId !== '' && contact && contact.id && contact.id !== '') {
      populateTemplate(contact.id, templateId, data.subject, data.content);
    }
  }, [contact]);

  const errorString = useMemo(() => {
    if (task?.errors?.[contact?.id]?.length) {
      return `Please remove ${task?.errors?.[contact?.id]?.join(', ')} to enable your sending.`;
    }
    return '';
  }, [task]);

  const defOptions = useMemo(() => {
    return task?.template ? [task?.template] : [];
  }, [task]);

  const populateTemplate = (contactId, templateId, subject, content) => {
    return new Promise((resolve, reject) => {
      dispatch(
        fetchPopulateEnqueueTemplate(
          taskId,
          contactId,
          templateId,
          subject,
          content,
          resolve,
          reject,
        ),
      );
    });
  };

  const handleTemplateChange = async (data) => {
    try {
      const templateId = data.id;
      const template = await fetchTemplateDetails(templateId);
      dispatch(
        setPersonalizeStep(taskId, {
          template: template.template,
        }),
      );
      if (templateId && templateId !== '' && contact && contact.id !== '') {
        populateTemplate(contact.id, templateId, data?.subject, data?.content);
      }
    } catch (error) {}
  };

  return (
    <Box mb={2}>
      <Form.Field.AutoComplete
        options={defOptions}
        fullWidth
        // disableClearable={true}
        clearOnEscape={true}
        name={`${taskId}.templateId`}
        placeholder="Select template"
        remoteMethod={(val) => {
          return fetchTemplatesLookup(val, 'email');
        }}
        onChange={handleTemplateChange}
        optLabel="name"
        optValue="id"
        variant="outlined"
      />

      {showFields ? (
        <>
          {errorString && errorString !== '' ? (
            <Alert severity="error" sx={{ padding: '4px 12px', marginTop: '8px' }}>
              {errorString}
            </Alert>
          ) : null}

          {/* <Form.Field.AutoComplete
            multiple={false}
            options={[formRef?.current?.values[taskId]?.sender]}
            fullWidth
            variant="outlined"
            name={`${taskId}.sender`}
            label="Choose Email Sender"
            remoteMethod={(val) => {
              return fetchUsersLookup(val);
            }}
            checkboxes={false}
            optLabel="name"
            optValue="id"
          /> */}

          <Form.Field.Input
            variant="outlined"
            name={`${taskId}.subject`}
            label="Subject"
            fullWidth={true}
            showPicker={true}
            onChange={(val) => handleChange(val, 'subject')}
            options={replacementVariables}
            optValue="value"
            optLabel="text"
            emojiPicker={true}
          />
          <Form.Field.TextEditor
            name={`${taskId}.content`}
            onChange={(val) => handleChange(val, 'content')}
            getChildEditorRef={getChildEditorRef}
          />
          <Form.Field.File name={`${taskId}.attachments`} maxSize={8} />
        </>
      ) : null}
    </Box>
  );
}

export default SelectEmail;
