export const parseOptionsFromString = (string) => {
  const hasNewLines = (string.match(/\n/g) || []).length > 0;
  const hasCommas = string.includes(',');
  let options = [];

  if (string && string !== '') {
    if (hasCommas) {
      options = string.split(',');
    } else {
      options = string.split('\n');
    }
  }

  if (options && options.length) {
    options = options.map((item) => ({
      option: item.trim(),
      label: item.trim(),
      enabled: true,
    }));
  } else {
    options = [];
  }
  return options;
};

export const parseOptionsFromStringField = (string) => {
  const hasCommas = string.includes(',');
  let options = [];
  if (string && string !== '') {
    if (hasCommas) {
      string = string.split(',').join('\n');
    }
    options = string.split('\n');
    if (options && options.length) {
      options = options.filter(item => !!item).map((item) => ({
        label: item?.toLowerCase(),
        value: item?.toLowerCase(),
      }));
    } else {
      options = [];
    }
  }
  return options;
};
