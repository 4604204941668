export const ProfileTabs = [
  {
    id: 'details',
    name: 'Details',
  },
  {
    id: 'account',
    name: 'Account',
  },
  {
    id: 'email',
    name: 'Email',
  },
  {
    id: 'outreachSettings',
    name: 'Outreach settings',
  },
  // {
  //   id: 'customVariables',
  //   name: 'Custom Variables',
  // },
  {
    id: 'notification',
    name: 'Notification',
  },
  {
    id: 'security',
    name: 'Security',
  },
  {
    id: 'signature',
    name: 'Signature',
  },
];

export const ProfileTabsLms = [
  {
    id: 'details',
    name: 'Details',
  },
  {
    id: 'account',
    name: 'Account',
  },
  {
    id: 'aiLearning',
    name: 'Ai Learning Settings',
  },
  {
    id: 'notification',
    name: 'Notification',
  },
  {
    id: 'security',
    name: 'Security',
  },
];
