import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import { EventCard } from '../eventsStyles';
import { Box, Typography } from '@mui/material';
import { Spacer } from 'src/components/shared';

const LinkedinCard = ({ event, ...props }) => {
  const title = useMemo(() => {
    switch (event.eventType) {
      case 'linkedinConnection':
        return 'LinkedIn Connect';

      case 'linkedinViewProfile':
        return 'LinkedIn - View profile';

      case 'linkedinMail':
        return 'LinkedIn InMail';

      case 'linkedinMessage':
        return 'LinkedIn Message';

      default:
        return 'LinkedIn Message';
    }
  }, [event]);

  return (
    <EventCard
      title={title}
      icon={<LinkedInIcon style={{ color: '#1282B2' }} />}
      showEdit={true}
      showDelete={true}
      {...props}
    />
  );
};

LinkedinCard.propTypes = {
  event: PropTypes.object,
};

export default LinkedinCard;
