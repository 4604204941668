import { all, fork, put, call, select, takeLatest, takeEvery } from 'redux-saga/effects';
import * as appTypes from '../actions/appTypes';
import * as boardApi from '../api/boardApi';
import * as boardActions from '../actions/boardActions';
import * as appActions from '../actions/appActions';
import toast from 'src/utils/toast';
import { isEmpty, sortBy } from 'lodash';
import { push, replace } from 'connected-react-router';
import { parseWidgetsData } from '../utils/boardUtils';
import moment from 'moment';
import { getRelativeDateRange } from 'src/utils/dateUtils';

export const getBoards = (state) => state.boards.boards;
export const getActiveBoard = (state) => state.boards.activeBoard;
export const getBoardWidgets = (state) => state.boards.boardWidgets;
export const getBoardFilters = (state) => state.boards.boardFilters;

function* fetchBoards({ viewport }) {
  try {
    const res = yield call(boardApi.fetchBoards);
    const boards = Object.assign({}, ...res.boards.map((board) => ({ [board.id]: board })));
    yield put(boardActions.setBoards(boards));
    let activeBoard = '';
    const pinnedBoard = res.boards.filter((board) => {
      return board.pinned === true;
    });
    if (pinnedBoard && pinnedBoard.length) {
      activeBoard = pinnedBoard[0].id;
    }
    const currentActiveBoard = yield select(getActiveBoard);
    const isActiveBoardPinned = pinnedBoard.filter((board) => board.id == currentActiveBoard);
    if (currentActiveBoard && currentActiveBoard !== '' && isActiveBoardPinned.length) {
    } else {
      yield put(boardActions.setActiveBoard(activeBoard, viewport));
    }
  } catch (error) {}
}

function* postBoard({ board, resolve, reject }) {
  try {
    const response = yield call(boardApi.createBoard, board);
    let boards = yield select(getBoards);
    boards = JSON.parse(JSON.stringify(boards));
    boards[response.board.id] = response.board;
    yield put(boardActions.setBoards(boards));
    // yield put(boardActions.setActiveBoard(response.board.id));
    yield put(push(`/boards/${response.board.id}`));
    toast.success('New board added');
    resolve(response.board);
  } catch (error) {
    toast.error(error?.message ? error?.message : 'Error ocurred please try again!');
    reject(error);
  }
}

function* cloneBoard({ boardId, board, resolve, reject }) {
  try {
    const response = yield call(boardApi.cloneBoard, boardId, board);

    let boards = yield select(getBoards);
    boards = JSON.parse(JSON.stringify(boards));
    boards[response.board.id] = response.board;
    yield put(boardActions.setBoards(boards));
    yield put(push(`/boards/${response.board.id}`));
    toast.success('Board cloned successfully');
    resolve(response.board);
  } catch (error) {
    reject(error);
  }
}

function* deleteBoard({ boardId, resolve, reject }) {
  try {
    const res = yield call(boardApi.deleteBoard, boardId);
    let boards = yield select(getBoards);
    boards = JSON.parse(JSON.stringify(boards));
    delete boards[boardId];
    yield put(boardActions.setBoards(boards));
    yield put(boardActions.setBoardWidgets({}));
    const newActive = Object.keys(boards)[0];
    if (newActive && newActive !== '') {
      yield put(push(`/boards/${newActive}`));
    }
    toast.success('Board Deleted!');
    resolve(true);
  } catch (error) {
    reject(error);
  }
}

function* putBoard({ boardId, board, resolve, reject }) {
  try {
    const response = yield call(boardApi.updateBoard, boardId, board);
    let boards = yield select(getBoards);
    boards = JSON.parse(JSON.stringify(boards));
    boards[boardId] = response.board;
    yield put(boardActions.setBoards(boards));
    if (!response.board?.pinned || response.board.pinned === false) {
      const pinnedBoards = Object.values(boards).filter((board) => board.pinned === true);
      if (pinnedBoards?.length) {
        const newBoardId = pinnedBoards[0].id;
        yield put(replace(`/boards/${newBoardId}`));
      } else {
        yield put(replace(`/boards`));
        yield put(boardActions.setActiveBoard(''));
      }
    }
    toast.success('Board updated');
    resolve(true);
  } catch (error) {
    toast.error(error?.message ? error?.message : 'Error ocurred please try again!');
    reject(error);
  }
}

function* fetchBoardTemplates({ filters, paging }) {
  try {
    let filter = {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      ...filters,
    };
    const res = yield call(boardApi.fetchBoardTemplates, filter);
    yield put(boardActions.setBoardTemplates({ ...res }));
  } catch (error) {}
}

function* fetchWidgets({ filters, paging }) {
  try {
    let filter = {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      ...filters,
    };
    const res = yield call(boardApi.fetchWidgets, filter);
    yield put(boardActions.setWidgets({ ...res }));
  } catch (error) {}
}

function* addWidgetsToBoard({ payload, viewport, resolve, reject }) {
  try {
    const boardId = yield select(getActiveBoard);
    const data = [
      {
        id: payload.id,
        config: {
          w: payload?.minW > 0 ? payload?.minW : 1,
          h: payload?.minH > 0 ? payload?.minH : 1,
        },
      },
    ];
    const res = yield call(boardApi.addWidgetsToBoard, boardId, { data, viewport });
    const existing = yield select(getBoardWidgets);
    const widgetsData = yield call(
      parseWidgetsData,
      res.widgets,
      res.widgetsConfig[viewport],
      existing,
    );
    let boards = yield select(getBoards);
    boards[boardId] = {
      ...boards[boardId],
      ...res,
      widgetsConfig: {
        ...boards[boardId].widgetsConfig,
        viewports: res.widgetsConfig,
      },
    };
    yield put(boardActions.setBoardWidgets(widgetsData));
    yield put(boardActions.setBoards(boards));
    toast.success('Widget added to board');
    resolve(true);
  } catch (error) {
    reject(error);
  }
}

function* removeWidgetsFromBoard({ widgetId, resolve, reject }) {
  try {
    const boardId = yield select(getActiveBoard);
    let widgetsData = yield select(getBoardWidgets);
    widgetsData = JSON.parse(JSON.stringify(widgetsData));
    const response = yield call(boardApi.removeWidgetFromBoard, boardId, widgetId);
    delete widgetsData[widgetId];
    yield put(boardActions.setBoardWidgets(widgetsData));
  } catch (error) {}
}

function* useTemplateForBoard({ template, resolve, reject }) {
  try {
    const boardId = yield select(getActiveBoard);
    const res = yield call(boardApi.useBoardTemplate, boardId, template.id);
    const widgetsData = yield call(parseWidgetsData, res.widgets, res.widgetsConfig);
    let boards = yield select(getBoards);
    boards[boardId] = {
      ...boards[boardId],
      ...res,
      widgetsConfig: {
        ...boards[boardId].widgetsConfig,
        viewports: res.widgetsConfig,
      },
    };
    yield put(boardActions.setBoardWidgets(widgetsData));
    yield put(boardActions.setBoards(boards));
    resolve(true);
  } catch (error) {
    reject(error);
  }
}

function* updateBoardLayout({ widgets, viewport }) {
  try {
    const boardId = yield select(getActiveBoard);
    const payload = widgets.map((widget) => {
      return {
        id: widget.i,
        config: {
          w: widget.w,
          h: widget.h,
          x: widget.x,
          y: widget.y,
        },
      };
    });
    const res = yield call(boardApi.addWidgetsToBoard, boardId, {
      data: payload,
      viewport,
    });

    const existing = yield select(getBoardWidgets);
    const widgetsData = yield call(
      parseWidgetsData,
      res.widgets,
      res.widgetsConfig[viewport],
      existing,
      viewport,
    );

    let boards = yield select(getBoards);
    boards[boardId] = {
      ...boards[boardId],
      ...res,
      widgetsConfig: {
        ...boards[boardId].widgetsConfig,
        viewports: res.widgetsConfig,
      },
    };

    yield put(boardActions.setBoardWidgets(widgetsData));
    yield put(boardActions.setBoards(boards));
  } catch (error) {}
}

function* fetchPinnedBoards({}) {
  try {
    let boards = yield call(boardApi.fetchBoards, { pinned: true });
    boards = boards.boards;
    yield put(boardActions.setPinnedBoards(boards));
  } catch (error) {}
}

function* setActiveBoard({ boardId, viewport }) {
  try {
    let boards = yield select(getBoards);
    const filters = yield select(getBoardFilters);
    const today = new Date();
    let lastMonth = new Date(new Date().setMonth(today.getMonth() - 1));

    if (!filters?.createdAtRange || isEmpty(filters?.createdAtRange)) {
      yield put(
        boardActions.setBoardFilters({
          ...filters,
          createdAtRange: {
            endDate: moment(today).format('YYYY-MM-DD'),
            startDate: moment(lastMonth).format('YYYY-MM-DD'),
          },
        }),
      );
    }

    const board = boards[boardId];
    const configs = board?.widgetsConfig?.viewports || {};

    if (board?.type === 'swimlanes') {
      yield put(appActions.fetchPanels(board.id));
    } else {
      const existing = yield select(getBoardWidgets);
      const widgets = yield call(
        parseWidgetsData,
        board?.widgets || {},
        configs[viewport],
        existing,
      );
      yield put(boardActions.setBoardWidgets(widgets));
    }
  } catch (error) {}
}

function* handleBoardResize({ viewport }) {
  try {
    const boardId = yield select(getActiveBoard);
    let boards = yield select(getBoards);
    const board = boards[boardId];

    if (board?.type !== 'swimlanes') {
      const existing = yield select(getBoardWidgets);
      const hasViewports =
        board.widgetsConfig.viewports && Object.keys(board.widgetsConfig.viewports).length > 0
          ? true
          : false;

      const widgetConfig = board.widgetsConfig?.viewports[viewport]
        ? board.widgetsConfig.viewports[viewport]
        : hasViewports
        ? board.widgetsConfig?.viewports[Object.keys(board.widgetsConfig?.viewports)[0]]
        : [];

      const widgets = yield call(parseWidgetsData, board.widgets, widgetConfig, existing);

      yield put(boardActions.setBoardWidgets(widgets));
    }
  } catch (error) {}
}

function* fetchWidgetData({ widgetId }) {
  try {
    const boardId = yield select(getActiveBoard);
    let filters = yield select(getBoardFilters);
    filters = filters[boardId];

    let filter = {
      ...(filters && filters?.teams && filters?.teams.length
        ? {
            teamIds: filters?.teams.map((team) => team.id),
          }
        : {}),
      ...(filters && filters?.users && filters?.users.length
        ? {
            userIds: filters?.users.map((team) => team.id),
          }
        : {}),
      // ...(filters && filters?.team?.users && filters?.team?.users.length
      //   ? {
      //       userIds: filters?.team?.users.map((user) => user.id),
      //     }
      //   : {}),
      // ...(filters && filters?.team?.teamId && filters?.team?.teamId.length
      //   ? {
      //       teamIds: filters?.team?.teamId.map((team) => team.id),
      //     }
      //   : {}),
    };

    const createdAt = filters?.createdAtRange || {};
    if (createdAt && !isEmpty(createdAt)) {
      const dateRange = getRelativeDateRange(createdAt);
      filter = {
        createdAt_gte: dateRange.start,
        createdAt_lte: dateRange.end,
        ...filter,
      };
    }

    const res = yield call(boardApi.fetchWidgetData, boardId, widgetId, filter);
    yield put(boardActions.setWidgetData(widgetId, res.response));
  } catch (error) {}
}

function* setBoardFilters({ filters }) {
  try {
    const widgets = yield select(getBoardWidgets);
    yield all(Object.keys(widgets).map((widgetId) => put(boardActions.fetchWidgetData(widgetId))));
  } catch (error) {}
}

export function* watchSagas() {
  yield takeLatest(appTypes.FETCH_BOARDS, fetchBoards);
  yield takeLatest(appTypes.POST_BOARD, postBoard);
  yield takeLatest(appTypes.CLONE_BOARD, cloneBoard);
  yield takeLatest(appTypes.PUT_BOARD, putBoard);
  yield takeLatest(appTypes.DELETE_BOARD, deleteBoard);
  yield takeLatest(appTypes.FETCH_WIDGETS, fetchWidgets);
  yield takeLatest(appTypes.FETCH_BOARD_TEMPLATES, fetchBoardTemplates);
  yield takeLatest(appTypes.ADD_WIDGETS_TO_BOARD, addWidgetsToBoard);
  yield takeLatest(appTypes.REMOVE_WIDGET_FROM_BOARD, removeWidgetsFromBoard);
  yield takeLatest(appTypes.USE_TEMPLATE_FOR_BOARD, useTemplateForBoard);
  yield takeLatest(appTypes.SET_ACTIVE_BOARD, setActiveBoard);
  yield takeLatest(appTypes.UPDATE_BOARD_LAYOUT, updateBoardLayout);
  yield takeLatest(appTypes.FETCH_PINNED_BOARDS, fetchPinnedBoards);
  yield takeEvery(appTypes.FETCH_WIDGET_DATA, fetchWidgetData);
  yield takeEvery(appTypes.SET_BOARD_FILTERS, setBoardFilters);
  yield takeEvery(appTypes.HANDLE_DASHBOARD_RESIZE, handleBoardResize);
}

export default function* runSagas() {
  yield all([fork(watchSagas)]);
}
