import styled from 'styled-components';

export const Title = styled.div`
  p {
    margin: 0;
    a {
      color: #1976d2;
      cursor: pointer;
    }
  }
`;
