import { makeStyles } from '@mui/styles';
import { themeColors } from 'src/modules/lms/config';
const useStyles = makeStyles(({ palette }) => ({
  Preview: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '880px',
    maxHeight: '644px',
    height:'fitContent',
    overflow: 'auto',
    background: themeColors.popUpBlue,
    borderRadius: '10px',
    '&:focus-visible ': {
      outline: 'none !important',
    },
    '& button': {
      textTransform: 'none',
    },
    '&::-webkit-scrollbar': {
      width: 4,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: 4,
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#0000002E',
      borderRadius: 6,
    },
  },
  innerForm: {
    maxHeight: '450px',
    height:'fitContent',
    padding: '10px',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      width: 4,
      height:0,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: 4,
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#1B3C59',
      borderRadius: 6,
    },
  },
  validation: {
    backgroundColor: '#f7b9b552',
    color: '#660505',
    width: '100%',
    fontSize: '14px',
    borderRadius: '4px',
    '& svg': {
      color: '#D32F2F',
      marginLeft: '12xp',
    },
  },
  addIcon: {
    padding: '0px',
    marginRight: '4px',
    '& svg': {
      fontSize: 'small',
    },
  },
  radioLabels: {
    color: '#5B6777',
    fontSize: '14px',
    fontWeight: 500,
  },
  radioButton: {
    '& .Mui-checked': {
      color: themeColors.yellow,
    },
  },
  caption: {
    display: 'flex',
    alignItems: 'end',
    height: '105px',
    marginLeft: '10px',
    color: '#5B6777',
    fontStyle: 'italic',
  },
  instructions: {
    marginLeft: '2%',
    color: themeColors.popUpText,
    fontStyle: 'italic',
    fontSize: '10px',
    display: 'flex',
    marginTop: '4px',
  },
  fields: {
    '& .MuiOutlinedInput-root': {
      '&:-webkit-autofill': {
        boxShadow: '0 0 0 100px yellow inset',
        textFillColor: '#fff',
        caretColor: '#fff',
        borderRadius: 'inherit',
      },
      '&:hover fieldset': {
        borderColor: themeColors.yellow, // Change to the desired hover color
      },
      '&.Mui-focused fieldset': {
        borderColor: themeColors.popUpText, // Change to the desired focus color
      },
    },
    '& input': {
      fontSize: '12px',
      height: '33px !important',
      background: themeColors.popUpFields,
      color: themeColors.popUpText,
    },

    '& .MuiInputBase-root': {
      border: 'none',
      background: themeColors.popUpFields,
      '& svg.MuiSvgIcon-root': {
        marginLeft: '12px',
      },
      '& svg': {
        color: themeColors.text,
      },
      '& .MuiOutlinedInput': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '& label': {},
    },
  },
  select: {
    marginTop:'-8px !important',
    '& label': {
      color: '#CCCCCC !important',
    },
    '& input': {
      color: themeColors.popUpText,
      fontSize: '12px',
      background: themeColors.popUpFields,
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: themeColors.yellow, // Change to the desired hover color
      },
      '&.Mui-focused fieldset': {
        borderColor: themeColors.popUpText, // Change to the desired focus color
      },
    },

    '& .MuiInputBase-root': {
      border: 'none',
      background: themeColors.popUpFields,
      '& svg': {
        color: themeColors.popUpText,
      },
      '& .MuiOutlinedInput': {
        borderColor: '1px solid #5983A8',
      },
      '& label': {
        color: '#CCCCCC !important',
      },
    },
  },
  checkBox: {
    color: themeColors.popUpText,
    '&.Mui-checked': {
      color: themeColors.yellow,
    },
    '&.Mui-disabled': {
      color: themeColors.greyHeader,
    },
  },
  saveButton: {
    color: 'black',
    backgroundColor: themeColors.yellow,
    '&:hover': {
      backgroundColor: themeColors.yellow,
    },
  },
  timePicker: {
    '& div ': {
      fontSize: '12px !important',
      font: 'menu !important',
    },
  },
  draftButton: {
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: themeColors.yellow,
    color: themeColors.yellow,
    '&:hover': {
      backgroundColor: 'transparent',
      border: '1px solid',
      borderColor: themeColors.yellow,
      color: themeColors.yellow,
    },
  },
  cancelButton: {
    color: themeColors.popUpText,
  },
  chip: {
    display: 'flex',
    borderRadius: '30px',
    alignItems: 'center',
    background: themeColors.popUpChips,
    color: themeColors.popUpText,
    '&:hover': {
      background: 'rgba(255, 212, 126, 0.20)',
    },
  },

  close: {
    color: themeColors.popUpText,
    width: '4%',
    paddingTop: '1%',
  },
  searchIcon: {
    color: 'rgba(0, 0, 0, 0.26)',
  },
  title: {
    color: themeColors.popUpText,
    fontSize: '18px',
    fontWeight: '500',
  },
  labels: {
    fontWeight: '700',
    fontSize: '14px',
    color: themeColors.popUpText,
  },
  lineColor: {
    border: '1px solid #1B3C59',
  },
  informationTitle: {
    color: 'rgba(91, 103, 119, 1)',
    fontWeight: '700',
    fontSize: '14px',
    letterSpacing: '0.3px',
  },
  informationSubtitle: {
    color: 'rgba(91, 103, 119, 1)',
    fontWeight: '400',
    fontSize: '12px',
    letterSpacing: '0.3px',
  },
  FileDropper: {
    position: 'relative',
    width: '400px',
    height: '100px',
    paddingLeft: '0 !important',
  },
  noimagecompetition: {
    marginTop: '5.5%',
    height: '90px',
    width: '160px',
    borderRadius: '2px',
    backgroundColor: '#193247',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
  previewButton: {
    fontWeight: 500,
    color: themeColors.yellow,
    fontSize: '14px',
    cursor: 'pointer',
  },
  userStack: {
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: 4,
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#0000002E',
      borderRadius: 6,
    },
  },
}));
export default useStyles;
