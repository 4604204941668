import SendIcon from '@mui/icons-material/Send';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import ScheduleIcon from '@mui/icons-material/Schedule';

import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SmsIcon from '@mui/icons-material/Sms';
import CallIcon from '@mui/icons-material/Call';
import TaskIcon from '@mui/icons-material/Task';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

export const linkedinTaskTypes = [
  { label: 'LinkedIn Connection', value: 'linkedinConnection' },
  { label: 'LinkedIn Message', value: 'linkedinMessage' },
  { label: 'LinkedIn Mail', value: 'linkedinMail' },
  { label: 'LinkedIn View Profile', value: 'linkedinViewProfile' },
];

export const errorTypes = [
  { value: 'workEmail', label: 'Work Email missing' },
  { value: 'personalEmail', label: 'Personal Email missing' },
  { value: 'bouncedEmail', label: 'Bounced Email' },
  // { value: 'phone', label: 'Phone number missing' },
  // { value: 'homePhone', label: 'Home Phone missing' },
  // { value: 'workPhone', label: 'Work Phone missing' },
  // { value: 'personalPhone', label: 'Personal Phone missing' },
  { value: 'linkedin', label: 'LinkedIn URL missing' },
  // { value: 'instagram', label: 'Instagram URL missing' },
  // { value: 'twitter', label: 'Twitter URL missing' },
  // { value: 'facebook', label: 'Facebook URL missing' },
  // { value: 'enrolled', label: 'Already enrolled' },
  // { value: 'inboxDisconnected', label: 'Inbox disconnected' },
  { value: 'selectEmailTemplate', label: 'Select email eemplate' },
  { value: 'content', label: 'Content' },
];

export const stepTypes = [
  { value: 'email', label: 'Email' },
  { value: 'selectEmailTemplate', label: 'Select email template' },
  // { value: 'sms', label: 'SMS' },
  // { value: 'wait', label: 'Wait' },
  // { value: 'linkedin', label: 'Linkedin' },
  { value: 'linkedinMessage', label: 'LinkedIn message' },
  // { value: 'linkedinViewProfile', label: 'LinkedIn view profile' },
  { value: 'linkedinMail', label: 'LinkedIn mail' },
  { value: 'linkedinConnection', label: 'LinkedIn connection' },
  // { value: 'call', label: 'Call' },
  // { value: 'general', label: 'General' },
];

export const seqStatusTypes = [
  // { value: 'unpublished', label: 'Unpublished' },
  { value: 'published', label: 'Published' },
  // { value: 'archived', label: 'Archived' },
];

const iconStyles = {
  fontSize: '20px',
  top: '5px',
  position: 'relative',
  left: 0,
  paddingRight: '4px',
};

const fontStyle = {};
export const inboxTabs = [
  {
    id: 'inbox',
    name: (
      <div>
        <MailOutlineIcon sx={iconStyles} /> Email replies
      </div>
    ),
  },
];

export const outboxTabs = [
  {
    id: 'inbox',
    name: (
      <div>
        <MailOutlineIcon sx={iconStyles} /> Scheduled emails
      </div>
    ),
  },
];

export const sentTabs = [
  {
    id: 'sent',
    name: (
      <div>
        <MailOutlineIcon sx={iconStyles} /> Sent emails
      </div>
    ),
  },
];

export const tasksTabs = [
  {
    id: 'all',
    name: <div style={{ paddingTop: '10px' }}>All</div>,
  },
  {
    id: 'linkedin',
    name: (
      <div>
        <LinkedInIcon sx={iconStyles} /> LinkedIn
      </div>
    ),
  },
  {
    id: 'email',
    name: (
      <div>
        <EmailIcon sx={iconStyles} /> Select Email Template
      </div>
    ),
  },
  {
    id: 'sms',
    name: (
      <div>
        <SmsIcon sx={iconStyles} /> Manual SMS
      </div>
    ),
  },
  {
    id: 'call',
    name: (
      <div>
        <CallIcon sx={iconStyles} /> Scheduled Calls
      </div>
    ),
  },
  {
    id: 'general',
    name: (
      <div>
        <TaskIcon sx={iconStyles} /> To Dos
      </div>
    ),
  },
  // {
  //   id: 'replies',
  //   name: (
  //     <div>
  //       <ReplyAllIcon sx={iconStyles} /> Replies
  //     </div>
  //   ),
  // },
  {
    id: 'errors',
    name: (
      <div>
        <ErrorIcon sx={iconStyles} /> Sequence Errors
      </div>
    ),
  },
  {
    id: 'drafts',
    name: (
      <div>
        <PendingIcon sx={iconStyles} /> Drafts
      </div>
    ),
  },
];

export const outboxBulkActions = [
  {
    tip: 'Send Now',
    key: 'send',
    icon: <SendIcon />,
  },
  {
    tip: `Pause until`,
    key: 'scheduledOn',
    icon: <ScheduleIcon />,
  },
];

export const errorBulkActions = [
  {
    title: 'Mark Task Done',
    key: 'markTaskComplete',
  },
];

export const taskBulkActions = [
  {
    title: 'Launch tasks',
    key: 'launchTasks',
  },
  {
    title: 'Mark Task Done',
    key: 'markTaskComplete',
  },
  {
    title: 'Delete',
    key: 'delete',
    color: 'error',
  },
];

export const draftActions = [
  {
    title: 'Launch enrolment process',
    key: 'launch',
  },
  {
    title: 'Remove draft',
    key: 'delete',
    color: 'error',
  },
];

export const taskBulkActionsList = {
  MARK_TASK_COMPLETE: 'markTaskComplete',
  CHANGE_DUE_DATE: 'changeDueDate',
  CHANGE_ASSIGNED: 'changeAssigned',
};

export const waitTimeTypes = [
  {
    label: '3 Days',
    value: '3days',
  },
  {
    label: 'In a week',
    value: 'week',
  },
  {
    label: '10 Days',
    value: '10days',
  },
  {
    label: 'In a month',
    value: 'month',
  },
];

export const reminderTypes = [
  {
    label: 'Follow up',
    value: 'call',
  },
  {
    label: 'Send an email',
    value: 'email',
  },
  {
    label: 'Send SMS',
    value: 'sms',
  },
  {
    label: 'Send linkedin message',
    value: 'linkedin',
  },
];

export const taskTabs = [
  {
    id: 'all',
    name: 'All',
  },
  {
    id: 'email',
    name: 'Emails',
  },
  {
    id: 'linkedin',
    name: 'LinkedIn',
  },
  {
    id: 'call',
    name: 'Calls',
  },
  // {
  //   id: 'sms',
  //   name: 'SMS',
  // },
  {
    id: 'general',
    name: 'Reminder',
  },
];

export const taskErrorTypes = {
  INBOX_DISCONNECTED: 'inboxDisconnected',
  WORK_EMAIL: 'workEmail',
  PERSONAL_EMAIL: 'personalEmail',
  HOME_PHONE: 'homePhone',
  WORK_PHONE: 'workPhone',
  PERSONAL_PHONE: 'personalPhone',
  LINKEDIN: 'linkedin',
  INSTAGRAM: 'instagram',
  TWITTER: 'twitter',
  FACEBOOK: 'facebook',
  ENROLLED: 'enrolled',
  PHONE: 'phone',
  SELECT_EMAIL_TEMPLATE: 'selectEmailTemplate',
};

import AddLinkIcon from '@mui/icons-material/AddLink';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Typography } from '@mui/material';
export const bounceActionList = {
  NEW: {
    label: 'Re-enroll',
    value: 'new',
    icon: <AddLinkIcon />,
    tip: 'Re-enroll from the start of the sequence.',
  },
  CURRENT: {
    label: 'Resume',
    value: 'current',
    icon: <RestartAltIcon />,
    tip: 'Resume the sequence from the step that failed.',
  },
  NEXT: {
    label: 'Continue',
    value: 'next',
    icon: <ArrowRightIcon />,
    tip: 'Restart sequence from the next step.',
  },
};
export const bounceWarningMessage = `The contact's email has bounced. Please add a different email address to resume your outreach.`;
