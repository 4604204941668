import { all, fork, put, call, select, takeLatest, takeEvery } from 'redux-saga/effects';
import * as appTypes from '../actions/appTypes';
import * as appApis from '../api/appApis';
import * as appActions from '../actions/appActions';
import toast from 'src/utils/toast';
import { isEmpty } from 'lodash';
import {
  fetchContact,
  fetchContactNote,
  fetchSequenceEnrollment,
} from 'src/modules/contacts/actions/contactActions';
import { fetchIntegrations, setTenantSettings } from 'src/modules/admin/actions/adminActions';
import { fetchTenantSettings } from 'src/modules/admin/api/adminApi';

import {
  parseModuleAttributes,
  parseOnboardingData,
  parseOnboardingDataUpdate,
} from '../utils/helper';

export const getUser = (state) => state.auth.user;
export const getOnboarding = (state) => state.app.onboarding;
export const getAttributes = (state) => state.app.attributes;
export const getModules = (state) => state.app.modules;
export const getUnassignedFields = (state) => state.app.unassignedFields;

function* fetchTenantConfig() {
  try {
    const user = yield select(getUser);
    const tenant = user.tenants && user.tenants.length ? user.tenants[0] : undefined;
    if (tenant) {
      const response = yield call(appApis.fetchTenantConfig, tenant.id);
      const { modules, unassignedFields } = yield call(
        parseModuleAttributes,
        response.tenant.attributes,
      );
      yield put(appActions.setAttributes(response.tenant.attributes, modules, unassignedFields));
      yield put(appActions.setTenantConfig(response.tenant));
    } else {
      yield put(appActions.setTenantConfig({}));
    }
  } catch (error) {
    yield put(appActions.setTenantConfig({}));
  }
}

function* fetchCRMFeilds({ crmName }) {
  try {
    const data = yield call(appApis.fetchTenantFields, crmName);
    yield put(appActions.setTenantFileds(data));
  } catch (error) {}
}

function* fetchCRMNotes({ crmName }) {
  try {
    const notes = yield call(appApis.fetchTypesNotes);
    const data = yield call(appApis.fetchCRMNotes, crmName);
    const combinedArray = [...(data?.notes || []), ...(notes?.noteTypes || [])];
    const uniqueSet = new Set(combinedArray.map(JSON.stringify));
    const uniqueArray = Array.from(uniqueSet).map(JSON.parse);
    yield put(appActions.setCRMNotes(uniqueArray));
  } catch (error) {
    yield put(appActions.setCRMNotes([]));
  }
}

function* fetchSrNotes() {
  try {
    const data = yield call(appApis.fetchSrNotes);
    yield put(appActions.setSrNotes(data));
  } catch (error) {}
}

function* fetchTenantConfigData({ data }) {
  try {
    yield put(appActions.setAppDataLoading(true));
    const result = yield call(appApis.fetchTenantData, data);
    if (result) yield put(appActions.setTenantConfigData(result?.crmConfig[0] || {}));
  } catch (error) {
    toast.error('Failed to load tenant config', 'tc');
  } finally {
    yield put(appActions.setAppDataLoading(false));
  }
}

function* RefreshSyncData({ data }) {
  try {
    const result = yield call(appApis.RefreshSyncData, data);
    if (result?.message === 'success') toast.success('Synced successfully', ' tc');
  } catch (error) {
    toast.error('Failed to sync', 'tc');
  }
}

function* MappedNote({ id, notes }) {
  try {
    yield call(appApis.mappedNote, id, notes);
  } catch (error) {
    toast.error('Failed to add', 'tc');
  }
}

function* AddNoteAction({ id, note }) {
  try {
    const result = yield call(appApis.addNoteAction, id, note);
    if (result?.noteTypes?.length) toast.success('Added successfully', ' tc');
  } catch (error) {
    toast.error('Failed to add note action', 'tc');
  }
}

function* saveTenantConfig({ data }) {
  try {
    yield put(appActions.setAppDataLoading(true));
    const resp = yield call(appApis.saveTenantConfig, data?.data);
    const result = yield call(appApis.fetchTenantData, data?.data?.tenantIntegration);
    if (resp) {
      toast.success('Tenant config updated successfully', 'tc');
      yield put(appActions.setTenantConfigData(result?.crmConfig[0] || {}));
    }
  } catch (error) {
    toast.error('Failed to update tenant config', 'tc');
  } finally {
    yield put(appActions.setAppDataLoading(false));
  }
}

function* updateTenantConfig({ id, data }) {
  // let usersObj = yield select(getAllUsers);
  try {
    yield put(appActions.setAppDataLoading(true));
    const resp = yield call(appApis.updateTenantConfig, id, data);
    const result = yield call(appApis.fetchTenantData, data?.tenantIntegration);
    if (resp) {
      toast.success('Tenant config updated successfully', 'tc');
      yield put(appActions.setTenantConfigData(result?.crmConfig[0] || {}));
    }
  } catch (error) {
    toast.error('Failed to update tenant config', 'tc');
  } finally {
    yield put(appActions.setAppDataLoading(false));
  }
}

function* fetchGlobals() {
  try {
    const config = yield call(appApis.fetchGlobals);
    const settings = yield call(fetchTenantSettings);
    yield put(setTenantSettings(settings));
    yield put(appActions.setGlobals(config.data[0]));
  } catch (error) {
    yield put(appActions.setGlobals({}));
  }
}

function* fetchTaskCount() {
  const user = yield select(getUser);
  try {
    const config = yield call(appApis.fetchTaskCount, user);
    yield put(appActions.setTaskCounts(config));
  } catch (error) {
    yield put(appActions.setGlobals({}));
  }
}

function* toggleContactDrawer({ contactId }) {
  if (contactId && typeof contactId !== 'undefined' && contactId !== '') {
    yield put(fetchContact(contactId));
    yield put(fetchSequenceEnrollment(contactId));
    yield put(fetchContactNote(contactId));
    yield put(fetchIntegrations());
  }
}

function* fetchReplacementVariables() {
  try {
    const data = yield call(appApis.fetchReplacementVariables);
    yield put(appActions.setReplacementVariables(data));
  } catch (error) {
    yield put(appActions.setReplacementVariables([]));
  }
}

function* postAttribute({ payload, resolve, reject }) {
  try {
    if (payload.fieldType === 'lookup' && (!payload.lookup || payload.lookup == '')) {
      let lookup = yield call(appApis.createLookup, {
        name: payload.label,
        type: 'multiple',
      });
      lookup = lookup.lookup;
      let options = payload.options;
      options = options.map((option) => {
        return {
          ...option,
          value: option.label,
        };
      });
      yield call(appApis.postLookupItems, lookup.id, options);
      payload.lookup = lookup.id;
    }

    const res = yield call(appApis.createAttribute, payload);
    let attributes = yield select(getAttributes);
    attributes = JSON.parse(JSON.stringify(attributes));
    attributes.push(res.attribute);
    if (
      payload.fieldType === 'lookup' &&
      payload.lookup &&
      payload.lookup !== '' &&
      payload?.nestedName &&
      payload.nestedName !== ''
    ) {
      const data = {
        label: payload.nestedName,
        fieldName: payload.nestedName,
        fieldType: 'lookup',
        scope: 'contacts',
        inContact: true,
        lookup: payload.lookup,
        fieldOptions: {
          showOnGrid: false,
          showOnForm: payload.fieldOptions.showOnForm,
          required: payload.fieldOptions.required,
          multiple: payload.fieldOptions.multiple,
        },
        scope: payload.scope,
        parentAttribute: res.attribute.id,
      };
      const res1 = yield call(appApis.createAttribute, data);
      attributes.push(res1.attribute);
    }

    toast.success('Custom attribute added.');
    const { modules, unassignedFields } = yield call(parseModuleAttributes, attributes);
    yield put(appActions.setAttributes(attributes, modules, unassignedFields));
    resolve(res);
  } catch (error) {
    reject(error);
  }
}

function* putAttribute({ attributeId, payload, resolve, reject }) {
  if (payload.fieldType === 'lookup') {
    let options = payload.options;
    options = options?.filter((option) => !option.id);
    if (options?.length) yield call(appApis.postLookupItems, payload.lookupId, options);
  }

  delete payload.fieldType;
  delete payload.lookupId;
  try {
    let attributes = yield select(getAttributes);
    const response = yield call(appApis.updateAttribute, attributeId, payload);
    toast.success('Custom attribute updated.');
    attributes = attributes.map((att) => {
      if (att.id === attributeId) {
        return response.attribute[0];
      } else {
        return att;
      }
    });
    const { modules, unassignedFields } = yield call(parseModuleAttributes, attributes);
    yield put(appActions.setAttributes(attributes, modules, unassignedFields));
    resolve(response);
  } catch (error) {
    reject(error);
  }
}

function* deleteAttribute({ attributeId, resolve, reject }) {
  try {
    let attributes = yield select(getAttributes);
    const response = yield call(appApis.updateAttribute, attributeId, { status: 'deleted' });
    toast.success('Custom attribute deleted.');

    const { modules, unassignedFields } = yield call(
      parseModuleAttributes,
      attributes?.filter((k) => k.id !== attributeId),
    );
    yield put(appActions.setAttributes(attributes, modules, unassignedFields));
    resolve(response);
  } catch (error) {
    reject(error);
  }
}

function* handleAttributeDragDrop({ module, data }) {
  try {
    let activeFields = yield select(getModules);
    let sortedEvents = [];
    const oldIndex = data.source.index;
    const newIndex = data.destination.index;

    const direction = newIndex - oldIndex;
    activeFields = activeFields[module];
    const field = activeFields.filter((item) => item.id === data.draggableId)[0];

    let payload = {};
    switch (true) {
      case data.source.droppableId === 'gridFields' &&
        data.destination.droppableId === 'gridFields':
        payload = {
          fieldOptions: field.fieldOptions,
          gridParams: {
            ...field.gridParams,
            [module]: {
              sortOrder: data.destination.index,
            },
          },
        };

        sortedEvents = [
          ...activeFields
            .filter((item) => {
              if (!item.gridParams) return false;
              const sortOrder = item?.gridParams[module]?.sortOrder;
              return direction > 0
                ? sortOrder <= newIndex && item.id !== field.id
                : sortOrder < newIndex && item.id !== field.id;
            })
            .map((item) => {
              const sortOrder = item.gridParams[module].sortOrder;

              return {
                ...item,
                gridParams: {
                  ...item.gridParams,
                  [module]: {
                    sortOrder:
                      direction > 0 && sortOrder > oldIndex && sortOrder <= newIndex
                        ? sortOrder - 1
                        : sortOrder,
                  },
                },
              };
            }),
          ...activeFields
            .filter((item) => {
              if (!item.gridParams) return false;
              const sortOrder = item?.gridParams[module]?.sortOrder;

              return direction > 0
                ? sortOrder > newIndex && item.id !== field.id
                : sortOrder >= newIndex && item.id !== field.id;
            })
            .map((item) => {
              const sortOrder = item.gridParams[module].sortOrder;
              return {
                ...item,
                gridParams: {
                  ...item.gridParams,
                  [module]: {
                    sortOrder:
                      direction < 0 && sortOrder < oldIndex && sortOrder >= newIndex
                        ? sortOrder + 1
                        : sortOrder,
                  },
                },
              };
            }),
        ];

        break;

      case data.source.droppableId === 'formFields' &&
        data.destination.droppableId === 'formFields':
        // field = activeFields[data.source.index];
        payload = {
          fieldOptions: {
            ...field.fieldOptions,
            [module]: {
              sortOrder: data.destination.index,
            },
          },
        };

        const sortedFieldsFromTopToBottom = activeFields.filter((item) => {
          if (!item.fieldOptions) return false;

          const sortOrder = item?.fieldOptions[module]?.sortOrder;
          return direction > 0
            ? sortOrder <= newIndex && item.id !== field.id
            : sortOrder < newIndex && item.id !== field.id;
        });

        const sortedFieldsFromBottomToTop = activeFields.filter((item) => {
          if (!item.fieldOptions) return false;

          const sortOrder = item?.fieldOptions[module]?.sortOrder;

          return direction > 0
            ? sortOrder > newIndex && item.id !== field.id
            : sortOrder >= newIndex && item.id !== field.id;
        });

        sortedEvents = [
          ...sortedFieldsFromTopToBottom.map((item) => {
            const sortOrder = item.fieldOptions[module].sortOrder;

            return {
              ...item,
              fieldOptions: {
                ...item.fieldOptions,
                [module]: {
                  sortOrder:
                    direction > 0 && sortOrder > oldIndex && sortOrder <= newIndex
                      ? sortOrder - 1
                      : sortOrder,
                },
              },
            };
          }),
          ...sortedFieldsFromBottomToTop.map((item) => {
            const sortOrder = item.fieldOptions[module].sortOrder;
            return {
              ...item,
              fieldOptions: {
                ...item.fieldOptions,
                [module]: {
                  sortOrder:
                    direction < 0 && sortOrder < oldIndex && sortOrder >= newIndex
                      ? sortOrder + 1
                      : sortOrder,
                },
              },
            };
          }),
        ];
        break;
    }
    const updatedfield = { ...field, ...payload };
    const updatedAttributes = [...sortedEvents, updatedfield];
    // re-united incase of not picked up nulls during filter
    const mergedAttributes = activeFields.map((t1) => ({
      ...t1,
      ...updatedAttributes.find((t2) => t2.id === t1.id),
    }));

    if (!isEmpty(payload)) {
      const { modules, unassignedFields } = yield call(parseModuleAttributes, mergedAttributes);
      yield put(appActions.setAttributes(mergedAttributes, modules, unassignedFields));
      const response = yield call(appApis.updateAttribute, field.id, payload);
    }
  } catch (error) {}
}

function* fetchOnboardingStatus() {
  try {
    const user = yield select(getUser);
    const res = yield call(appApis.fetchOnboardingData, user.id);

    const helpCenterData = yield call(parseOnboardingData, res);

    yield put(
      appActions.setOnboardingStatus({
        ...res.onboarding,
        ...helpCenterData,
      }),
    );
  } catch (error) {}
}

function* putOnboardingStatus({ key, value }) {
  try {
    let onboarding = yield select(getOnboarding);
    const user = yield select(getUser);
    let payload = value;
    if (key === 'helpCenter') {
      payload = {
        ...onboarding?.outReach,
        ...onboarding?.successTips,
        ...value,
      };
    }
    const res = yield call(appApis.putOnboardingData, user.id, key, payload);
    onboarding = JSON.parse(JSON.stringify(onboarding));
    const data = yield call(parseOnboardingDataUpdate, onboarding, key, value);
    yield put(appActions.setOnboardingStatus(data));
  } catch (error) {}
}

export function* watchSagas() {
  yield takeLatest(appTypes.FETCH_GLOBALS, fetchGlobals);
  yield takeLatest(appTypes.FETCH_TASK_COUNTS, fetchTaskCount);
  yield takeLatest(appTypes.FETCH_TENANT_CONFIG, fetchTenantConfig);
  yield takeLatest(appTypes.FETCH_CRM_FIELDS, fetchCRMFeilds);
  yield takeLatest(appTypes.FETCH_CRM_NOTES, fetchCRMNotes);
  yield takeLatest(appTypes.FETCH_SR_NOTES, fetchSrNotes);
  yield takeLatest(appTypes.TOGGLE_CONTACT_DRAWER, toggleContactDrawer);
  yield takeLatest(appTypes.FETCH_REPLACEMENT_VARIABLES, fetchReplacementVariables);

  yield takeLatest(appTypes.POST_ATTRIBUTE, postAttribute);
  yield takeEvery(appTypes.PUT_ATTRIBUTE, putAttribute);
  yield takeLatest(appTypes.DELETE_ATTRIBUTE, deleteAttribute);
  yield takeLatest(appTypes.HANDLE_ATTRIBUTE_DRAG_DROP, handleAttributeDragDrop);

  yield takeLatest(appTypes.FETCH_ONBOARDING_STATUS, fetchOnboardingStatus);
  yield takeLatest(appTypes.PUT_ONBOARDING_STATUS, putOnboardingStatus);
  yield takeLatest(appTypes.UPDATE_TENANT_CONFIG, updateTenantConfig);
  yield takeLatest(appTypes.SAVE_TENANT_CONFIG, saveTenantConfig);
  yield takeLatest(appTypes.FETCH_TENANT_DATA, fetchTenantConfigData);
  yield takeLatest(appTypes.REFRESH_SYNC_TENANT_DATA, RefreshSyncData);
  yield takeLatest(appTypes.MAPPED_NOTE, MappedNote);
  yield takeLatest(appTypes.ADD_NOTE_ACTION, AddNoteAction);
}

export default function* runSagas() {
  yield all([fork(watchSagas)]);
}
