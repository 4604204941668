import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, ButtonBase, Grid } from '@mui/material';
import { Button, Spacer } from 'src/components/shared';
import { BackButton, Empty } from 'src/components/App';
import { RoleplayReport } from 'src/components/lms';

import { useLmsReportsProvider } from '../../utils/reportsStore';
import Skeleton from './Skeleton';

function ReportDetails(props) {
  const { fetchUserDetails, userDetails, loading } = useLmsReportsProvider();

  const history = useHistory();

  useEffect(() => {
    const id = props.match.params.id;
    fetchUserDetails(id);
  }, [props.match]);

  return (
    <>
      <BackButton
        text="Back to summary"
        onClick={() => {
          history.push(`/lms/dashboard`);
        }}
      />
      <Spacer x={2} y={2} />
      {loading?.details ? (
        <Skeleton />
      ) : (
        <>
          {Object.values(userDetails).length ? (
            <Grid container spacing={2}>
              {Object.keys(userDetails).map((key) => {
                const scores = userDetails[key];
                const session = scores && scores.length ? scores[0]?.session : undefined;
                return (
                  <Grid item xs={12} md={4} key={key}>
                    <RoleplayReport scores={scores} sessionId={key} session={session} />
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Empty title="No reports found!" />
          )}
        </>
      )}
    </>
  );

  return <div>ReportDetails</div>;
}

export default ReportDetails;
