import React from 'react';
import { Box, Alert, Stack, Typography, Link, Tooltip } from '@mui/material';

function EnrolmentErrors({ errors, isAlreadyEnrolled }) {
  if ((!errors || !errors.length) && !isAlreadyEnrolled) return null;

  return (
    <Box>
      {isAlreadyEnrolled ? (
        <Alert
          severity={isAlreadyEnrolled ? 'error' : 'warning'}
          sx={{
            padding: '4px 12px',
            '.MuiAlert-icon': { color: isAlreadyEnrolled ? '#D32F2F' : '#663c00' },
            '.MuiAlert-message': { width: '100%' },
          }}
        >
          <Typography>Sorry! Only one enrolment is permitted.</Typography>
        </Alert>
      ) : (
        <Stack direction={'column'} gap={1}>
          {errors.map((error, index) => {
            return (
              <Alert
                severity={isAlreadyEnrolled ? 'error' : 'warning'}
                sx={{
                  padding: '4px 12px',
                  '.MuiAlert-icon': { color: isAlreadyEnrolled ? '#D32F2F' : '#663c00' },
                  '.MuiAlert-message': { width: '100%' },
                }}
              >
                <Typography fontSize={13}>{error.message}</Typography>
              </Alert>
            );
          })}
        </Stack>
      )}
    </Box>
  );
}

export default EnrolmentErrors;
