import React, { useImperativeHandle, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Popover, Card, CardContent, Box } from '@mui/material';

const WithPopover = React.forwardRef(
  (
    {
      Child,
      children,
      anchorOrigin,
      transformOrigin,
      trigger,
      width,
      height,
      isPadding,
      disablePortal,
      disabled,
      ...props
    },
    ref,
  ) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    useImperativeHandle(ref, () => ({
      close: handlePopoverClose,
    }));

    const childProps = useMemo(() => {
      if (disabled) return {};
      if (trigger === 'click') {
        return {
          onClick: (event) => handlePopoverOpen(event),
        };
      } else {
        return {
          onMouseEnter: (event) => handlePopoverOpen(event),
          onMouseLeave: () => handlePopoverClose(),
        };
      }
    }, [trigger, disabled]);

    return (
      <>
        {React.cloneElement(Child, { ...childProps })}
        <Popover
          id="mouse-over-popover"
          open={open}
          disablePortal={disablePortal}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handlePopoverClose}
          {...props}
        >
          {isPadding ? (
            <Card>
              <CardContent style={{ width, height }}>{children}</CardContent>
            </Card>
          ) : (
            <Box style={{ width, height }}>{children}</Box>
          )}
        </Popover>
      </>
    );
  },
);

WithPopover.propTypes = {
  trigger: PropTypes.oneOf(['click', 'hover']),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // isPadding: PropTypes.bool,
  disabled: PropTypes.bool,
};

WithPopover.propTypes = {
  trigger: 'click',
  width: 'auto',
  height: 'auto',
  isPadding: false,
  disabled: false,
};

export default WithPopover;
