import React from 'react';
import { Box, Typography, Grid, Divider, Card, CardContent, ButtonBase } from '@mui/material';
import { Spacer } from 'src/components/shared';
import { MainGrid, Left, Right, PaperWrap, PlusIcon, Thumb } from './styles';
import { Skeleton } from '@mui/lab';

const Item = ({ data, type, ...props }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Card>
          <ButtonBase
            sx={{ width: '100%', display: 'block', textAlign: 'unset' }}
            onClick={() => {}}
            disabled
          >
            <CardContent>
              <PaperWrap elevation={0}>
                <Skeleton animation="wave" height="80%" width="100%" />
              </PaperWrap>

              <Skeleton animation="wave" height={24} width="60%" />
              <Spacer x={0.5} y={0.5} />
              <Skeleton animation="wave" height={16} width="100%" />
              <Skeleton animation="wave" height={16} width="40%" />
            </CardContent>
          </ButtonBase>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card>
          <ButtonBase
            sx={{ width: '100%', display: 'block', textAlign: 'unset' }}
            onClick={() => {}}
            disabled
          >
            <CardContent>
              <PaperWrap elevation={0}>
                <Skeleton animation="wave" height="80%" width="100%" />
              </PaperWrap>

              <Skeleton animation="wave" height={24} width="60%" />
              <Spacer x={0.5} y={0.5} />
              <Skeleton animation="wave" height={16} width="100%" />
              <Skeleton animation="wave" height={16} width="40%" />
            </CardContent>
          </ButtonBase>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card>
          <ButtonBase
            sx={{ width: '100%', display: 'block', textAlign: 'unset' }}
            onClick={() => {}}
            disabled
          >
            <CardContent>
              <PaperWrap elevation={0}>
                <Skeleton animation="wave" height="80%" width="100%" />
              </PaperWrap>

              <Skeleton animation="wave" height={24} width="60%" />
              <Spacer x={0.5} y={0.5} />
              <Skeleton animation="wave" height={16} width="100%" />
              <Skeleton animation="wave" height={16} width="40%" />
            </CardContent>
          </ButtonBase>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Item;
