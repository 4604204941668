import React, { useEffect, useState } from 'react';
import SummaryReportCards from './SummaryReportCards';
import { fetchContactsSummary } from 'src/modules/contacts/api/contactApis';
import GridRatings from './Grid';

function SequenceSummary({ enrollmentsStats, ...props }) {
  const { sequencesContacts, fetchSequenceContacts, sequence } = props;

  const uniqueOpen = 0;

  return (
    <>
      <SummaryReportCards sequence={sequence} />
      <GridRatings
        sequence={props.sequence}
        seqId={props.seqId}
        openRate={uniqueOpen}
        fetchSequenceContacts={fetchSequenceContacts}
        sequencesContacts={sequencesContacts}
        contactsLoading={props.contactsLoading}
        {...props}
      />
    </>
  );
}

export default SequenceSummary;
