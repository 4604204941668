import React, { useEffect } from 'react';
import { Box, Typography, Grid, Container, Button } from '@mui/material';
import ReportCard from '../../components/Dashboard/ReportCard';
import ReportGrid from '../../components/Dashboard/ReportGrid';
import { FilterBar } from 'src/components/App';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import moment from 'moment';

const ResellersDashboard = ({
  resellersReportSummary,
  resellersReport,
  loading,
  resellerFilters,
  setResellerFilters,
  ...props
}) => {
  useEffect(() => {
    fetchSummary();
  }, [resellerFilters]);

  const fetchSummary = () => {
    props.fetchResellerReportSummary(resellerFilters);
  };

  const isFilterApplied = Object.keys(resellerFilters?.createdAtRange || {})?.length;

  return (
    <Container maxWidth="xl">
      <Box mt={2}>
        <Box mb={2} flexDirection="row" display="flex" alignItems="center">
          <FilterBar
            filters={[{ key: 'createdAtRange', title: 'Filter By Date', type: 'dateRange' }]}
            appliedFilters={resellerFilters}
            onChange={(key, value) => setResellerFilters({ [key]: value })}
          />
          {!!isFilterApplied && (
            <Button
              variant="text"
              disableElevation={true}
              onClick={() => setResellerFilters({})}
              startIcon={<ClearAllIcon />}
              sx={{
                height: 'fit-content',
                color: 'white',
                textTransform: 'none',
                fontWeight: 400,
                backgroundColor: '#1976D2',
                padding: '6px 8px',
                marginLeft: '12px',
                '&:hover': {
                  background: '#1976D2',
                },
              }}
            >
              <Typography noWrap sx={{ maxWidth: '144px' }} variant="body2">
                Clear Filter
              </Typography>
            </Button>
          )}
        </Box>
        <Grid container spacing={2} sx={{ '& > div': { display: 'flex' } }}>
          <ReportCard
            loading={loading.resellersReportSummary}
            type="text"
            fill="rgba(255, 171, 0, 1)"
            title="Total Resellers"
            value={resellersReportSummary?.totalResellers || 0}
          />
          <ReportCard
            loading={loading.resellersReportSummary}
            type="text"
            fill="rgba(197, 17, 98, 1)"
            title="Total Referrals"
            value={resellersReportSummary?.totalEnrollments || 0}
          />
          <ReportCard
            loading={loading.resellersReportSummary}
            type="text"
            fill="rgba(0, 121, 107, 1)"
            title="Total Follow ups"
            value={resellersReportSummary?.partnerUsersAssigned || 0}
          />
        </Grid>
        <ReportGrid
          loading={loading}
          fetchResellerReport={props.fetchResellerReport}
          resellerFilters={resellerFilters}
          resellersReport={resellersReport}
        />
      </Box>
    </Container>
  );
};

export default ResellersDashboard;
