import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'src/components/shared';
import Message from './Message';
import LeaveNote from 'src/modules/contacts/components/ContactActions/LeaveNote';
import { postContactNote } from 'src/modules/contacts/actions/contactActions';

import Skeleton from './Skeleton';

function Messages({ taskReplies, messagesLoading, ...props }) {
  const [showNoteForm, setShowNoteForm] = useState(false);

  const dispatch = useDispatch();

  const contact = useSelector((state) => state.contacts.contact);
  const user = useSelector((state) => state.auth.user);
  const taskPriorityList = useSelector((state) => state.app.globals?.taskPriorityList);
  const taskTypeList = useSelector((state) => state.app.globals?.taskTypeList);

  const { data } = taskReplies;
  // if (!data || !data.length) return null;

  const toggleNoteForm = () => {
    setShowNoteForm(!showNoteForm);
  };
  const onSubmit = (data) => {
    dispatch(postContactNote(data));
  };

  return (
    <>
      {messagesLoading ? (
        <Skeleton />
      ) : (
        <>
          {data && data.length
            ? data.map((message, index) => (
                <Message
                  key={`inbox-message-${index}`}
                  message={message}
                  {...props}
                  toggleNoteForm={toggleNoteForm}
                />
              ))
            : null}
        </>
      )}

      <Modal open={showNoteForm} title="Add note" onClose={toggleNoteForm} size="xs">
        <LeaveNote
          contact={contact}
          onClose={toggleNoteForm}
          taskPriorityList={taskPriorityList}
          taskTypeList={taskTypeList}
          user={user}
          postContactNote={onSubmit}
          fetchContactTasks={() => {}}
          showDate={false}
          showPriority={false}
        />
      </Modal>
    </>
  );
}

export default Messages;
