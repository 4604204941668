import React from 'react';
import iconConfig from './icons';

const MyDynamicIcon = ({ name, ...props }) => {
  const IconComponent = iconConfig[name];
  if (!IconComponent) return null;

  return <IconComponent {...props} />;
};

export default MyDynamicIcon;
