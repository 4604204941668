import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Spacer, Button } from 'src/components/shared';
import images from 'src/config/images';
import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';

export const EmptyWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  flex-direction: column;
`;

export const EmptyIcon = styled.img`
  width: 320px;
  opacity: 0.5;
  margin-bottom: 24px;
`;

const EmptyBoard = ({ handleAddBoard }) => {
  return (
    <Container maxWidth="sm">
      <EmptyWrap>
        <EmptyIcon src={images.app.emptyBoard} />
        <Typography variant="h1">Start by adding your first Board.</Typography>
        <Spacer x={2} y={2} />
        <Typography variant="body2" color="textSecondary" textAlign="center">
          Create a new Board and you'll be able to choose between Widget or Swimlane board, which
          you can customise to your liking.
        </Typography>
        <Spacer x={4} y={4} />
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          color="secondary"
          onClick={handleAddBoard}
        >
          Board
        </Button>
      </EmptyWrap>
    </Container>
  );
};

export default EmptyBoard;
