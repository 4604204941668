import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button, LinearProgress, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const useStyles = makeStyles(() => ({
  stepWrapper: {},
  buttons: {
    padding: '0px',
    backgroundColor: '#4789C2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    '& svg': {
      height: '16px',
      width: '16px',
      fill: '#fff',
    },
    '&.Mui-disabled': {
      opacity: '0.5',
      cursor: 'not-allowed !important',
      backgroundColor: '#999',
    },
  },
  buttonLeft: {
    '& svg': {
      marginLeft: 4,
    },
  },
  stepText: {
    color: '#80ABCD',
    fontSize: '14px',
    fontWeight: '400',
    flex: 1,
    padding: '0px 7px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    '& span': {
      color: 'rgba(0, 0, 0, 0.38)',
      display: 'inline-block',
      marginLeft: '10px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  stepTextCenter: {
    color: '#80ABCD',
    fontSize: '14px',
    fontWeight: '400',
    flex: 1,
    padding: '0px 7px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    '& span': {
      color: 'rgba(0, 0, 0, 0.38)',
      display: 'inline-block',
      marginLeft: '10px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  progress: {
    backgroundColor: '#1E88E540',
    borderRadius: '4px',
    '& .MuiLinearProgress-bar': {
      background: '#1E88E5',
    },
  },
}));

function Stepper({ step, total, prevDisabled, nextDisabled, onPrevClick, onNextClick, title }) {
  const classes = useStyles();
  const progress = Math.ceil((100 * step) / total);

  return (
    <Box className={classes.stepWrapper}>
      <Box
        className={classes.stepWrapper}
        display="flex"
        flexDirection={'row'}
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <IconButton
          className={`${classes.buttons} ${classes.buttonLeft}`}
          disabled={prevDisabled}
          onClick={onPrevClick}
          color="secondary"
          size="large"
        >
          <ArrowBackIosIcon />
        </IconButton>
        <Typography className={title !== '' ? classes.stepText : classes.stepTextCenter}>
          {step}
          {total > 1 ? `/${total}` : ''}
          <span>{title && title !== '' ? title : ''}</span>
        </Typography>
        <IconButton
          className={classes.buttons}
          disabled={nextDisabled}
          onClick={onNextClick}
          size="large"
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

Stepper.propTypes = {
  step: PropTypes.number,
  total: PropTypes.number,
  prevDisabled: PropTypes.bool,
  nextDisabled: PropTypes.bool,
  onPrevClick: PropTypes.func,
  onNextClick: PropTypes.func,
};
Stepper.defaultTypes = {
  step: 1,
  total: 1,
  prevDisabled: false,
  nextDisabled: false,
  onPrevClick: () => {},
  onNextClick: () => {},
};

export default Stepper;
