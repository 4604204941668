import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import useStyles from './styles';
import { Form } from 'src/components/shared';
import TemplateCard from '../../shared/templateCard';

function Template({ templates, ...props }) {
  const classes = useStyles();
  const { data } = templates;
  const options = [
    { label: 'Active', value: 'active' },
    { label: 'Deactive', value: 'deactive' },
    { label: 'Normal', value: 'normal' },
  ];
  return (
    <Box>
      <Grid container spacing={2} mb={5}>
        <Grid item xs={12} className={classes.container}>
          <Grid mr={2} ml={2} display={'flex'} justifyContent={'space-between'}>
            <Grid item xs={6}>
              <Typography className={classes.title}>My Templates</Typography>
            </Grid>
            <Grid item xs={3} className={classes.filter}>
              <Form
                initialValues={{
                  textEditor: '',
                }}
                enableReinitialize={false}
                onSubmit={() => {}}
                className={classes.filter}
              >
                <Form.Field.Select
                  className={classes.filter}
                  options={options}
                  variant="outlined"
                  fullWidth
                  name="fieldType"
                  label="Sort by - Relevancy"
                />
              </Form>
            </Grid>
          </Grid>
          <Grid container>
            {data?.map((item,i) => (
              <TemplateCard key={i} data={item} edit={true} share={true} deletion={true}   id={item.id} deleteTemplate={props.deleteTemplate} />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Template;
