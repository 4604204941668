import styled from 'styled-components';
import images from 'src/config/images';

export const AuthBgSetup = styled.img.attrs((props) => ({
  src: images.auth.authBG,
}))`
  z-index: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 16vw;
`;

export const NoImage = styled.div`
  background-color: #193247;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 360px;
  border-radius: 4px;
`;
