import React from 'react';
import { Grid, Box, Typography, Paper, Link, Stack } from '@mui/material';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Button, Form } from 'src/components/shared';
import { useState } from 'react';

export default function OutOffice({ defaultBcc, id, ...props }) {
  const handleSubmit = async (values, form) => {
    const data = { ...values };
    data.id = id || '';
    await props.updateSettings(data);
  };

  return (
    <>
      <Form
        initialValues={{
          defaultBcc: defaultBcc || '',
        }}
        validationSchema={Yup.object().shape({})}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {(props) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                props.submitForm();
                return false;
              }}
              style={{ height: '100%' }}
              noValidate
            >
              <Box>
                <Grid container>
                  <Grid item xs={6}>
                    <Form.Field.Input
                      fullWidth
                      size="small"
                      rows={4}
                      variant="outlined"
                      name="defaultBcc"
                      label="BCC Email Address"
                    />
                  </Grid>
                  <Grid item xs={6} p={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      // loading={loading}
                      // disabled={loading}
                    >
                      UPDATE
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          );
        }}
      </Form>
    </>
  );
}
