import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchList, setList, deleteList } from '../actions/LMSActions';
import ListSection from '../components/LMSList/ListSection';

const LMSListContainer = (props) => {
  return <ListSection myList={props.list} {...props} />;
};

const mapStateToProps = (state) => {
  return {
    list: state.lms.list,
    user: state.auth.user,
    loading: state.lms.loading.list,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setList: (list) => dispatch(setList(list)),
  fetchList: (paging, filters) => dispatch(fetchList(paging, filters)),
  deleteList: (id) => {
    return new Promise((resolve, reject) => {
      dispatch(deleteList(id, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LMSListContainer);
