import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  lmsInProgressPageWrapper: {
    gap: '32px',
  },
  loadingWrapper: {
    alignItems: 'center',
    height: '75vh',
    justifyContent: 'center',
    width: '100%',
  },
  container: {
    background: '#0C1721',
    borderRadius: '8px',
    padding: '20px',
    gap: '32px',
  },
  containerHeader: {
    flexDirection: 'row',
    gap: '12px',
  },
  headerTitle: {
    fonSize: '20px',
    fontWeight: 600,
  },
  bannerActionButtonsWrapper: {
    flexDirection: 'row',
    gap: '16px',
  },
  sessionsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(236px, 1fr))',
    gap: '24px',
    margin: '24px 0',
  },
  cardSessionFooter: {
    flexDirection: 'row',
    gap: '4px',
    '& .title, .sub-title': {
      fontSize: '13px',
      lineHeight: '24.5px',
    },
    '& .title': {
      fontWeight: 700,
    },
  },
  emptyContainer: {
    alignItems: 'center',
    gap: '24px',
  },
  emptyContent: {
    alignItems: 'center',
    gap: '12px',
  },
  emptyContentHeader: {
    fontSize: '20px',
    fontWeight: 600,
  },
  viewContentBtn: {
    color: 'white',
    width: 'fit-content',
    padding: 0,
  },
}));

export default useStyles;
