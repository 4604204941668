import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  Stack,
  InputAdornment,
  CircularProgress,
  ButtonBase,
} from '@mui/material';

import CardMedia from '../../shared/cardMedia';
import useStyles from './style';
import { Form } from 'src/components/shared';
import { FilterBar } from 'src/components/App';
import SearchFilter from './SearchFilter';
import { useEffect } from 'react';
import { truncate } from '../../utils/helper';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { getTagsLookup } from '../../api/LMSApis';

import { globalSearch } from '../../api/LMSApis';
import NullResultLarge from '../NullResults/NullResultLarge';

export default function SearchResult({ search }) {
  const [filters, setFilters] = React.useState({
    search: search,
  });
  const [paging, setPaging] = React.useState({
    page: 0,
    perPage: 25,
    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [filters]);

  const clearTags = () => {};

  const onFilterChange = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  const clearSearch = () => {
    setFilters({
      ...filters,
      search: '',
    });
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const payload = {
        ...(filters?.tags && filters?.tags.length
          ? { tags: filters?.tags.map((tag) => tag.id) }
          : {}),
        ...(filters?.search &&
          filters?.search !== '' && {
            _search: filters?.search,
          }),
        from: paging.page * paging.perPage,
        size: paging.perPage,
      };
      const res = await globalSearch(payload);
      setData(res.results);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setData([]);
    }
  };

  const searchFilters = [
    {
      key: 'tags',
      title: 'All tags',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: false,
      searchable: true,
      isPrimary: true,
      checkbox: true,
      multiple: true,
      apply: true,
      customHeader: true,
      onClear: clearTags,
      remoteMethod: async (value) => {
        return getTagsLookup(value);
      },
    },
  ];

  const onClose = () => {};

  return (
    <>
      <Box mb={3}>
        <Stack direction="row" alignItems="center" gap={2} justifyContent="flex-start">
          <Form initialValues={{ search: filters.search }} enableReinitialize={true}>
            <Form.Field.InputDebounced
              fullWidth
              size="small"
              variant="outlined"
              name="search"
              onChange={(search) => {
                setFilters({
                  ...filters,
                  search: search,
                });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <SearchIcon style={{ color: 'grey', marginRight: 8 }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <React.Fragment>
                    {filters.search ? (
                      <InputAdornment>
                        <ButtonBase onClick={clearSearch}>
                          <CloseIcon style={{ color: 'grey' }} />
                        </ButtonBase>
                      </InputAdornment>
                    ) : null}
                  </React.Fragment>
                ),
              }}
              sx={{ maxWidth: '240px', border: 'none' }}
            />
          </Form>
          <Box mt={1}>
            <FilterBar filters={searchFilters} appliedFilters={filters} onChange={onFilterChange} />
          </Box>
        </Stack>
      </Box>

      {loading ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <>
          {filters.search.length > 0 ? (
            <Box mb={2}>
              {data?.length > 0 ? data?.length : 'No'} results for{' '}
              {filters.search ? `"${filters.search}"` : ' ...'}
            </Box>
          ) : null}
          {data?.length > 0 ? (
            <SearchItem itemData={data} />
          ) : (
            <NullResultLarge searchData={filters.search} />
          )}
        </>
      )}
    </>
  );
}

const SearchItem = ({ itemData }) => {
  const classes = useStyles();
  return (
    <>
      {itemData.map((item, i) => (
        <Grid key={i} container spacing={1} className={classes.container}>
          <Grid item xs={3}>
            <CardMedia
              src={item?.thumbnailImage}
              alt={item?.title}
              data={item}
              contentType={item.contentType}
              isClickable={true}
              dontShowAction={true}
              type={'searchResultImage'}
            ></CardMedia>
          </Grid>
          <Grid item xs={6} ml={3}>
            <Typography className={classes.title} mb={1}>
              {item?.title}
            </Typography>
            {item?.tags?.map((tag, i) =>
              tag?.name ? (
                <Button key={i} className={classes.Button}>
                  {tag?.name}
                </Button>
              ) : null,
            )}
            <Typography
              className={classes.description}
              dangerouslySetInnerHTML={{
                __html:
                  item.contentType === 'pillar'
                    ? truncate(item?.synopsis, `/lms/${item?.contentType}/${item?.id}`)
                    : truncate(item?.overviewText, `/lms/${item?.contentType}/${item?.id}`),
              }}
            />
          </Grid>
        </Grid>
      ))}
    </>
  );
};

const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};
