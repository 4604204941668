import styled from 'styled-components';
import { Typography, Card, C } from '@mui/material';

export const FormText = styled(Typography).attrs({
  component: 'div',
  variant: 'body1',
})`
  font-size: 22px;
  color: rgba(0, 0, 0, 0.6);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
`;

export const Title = styled(Typography).attrs({
  variant: 'body1',
})`
  color: rgba(0, 0, 0, 0.6);
  font-size: 22px;
  & span.black {
    color: rgba(0, 0, 0, 0.87);
  }
  & span.success {
    color: #4caf50;
  }
`;

export const AutomateCard = styled(Card).attrs({})`
  margin-top: 12px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 16px;
`;

export const FormWrapper = styled.div`
  padding: 20px 30px;
`;

export const FormButton = styled(Typography).attrs({
  variant: 'body1',
})`
  text-decoration: underline;
  cursor: pointer;
  display: inline;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.87);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
