import React from 'react';
import { Box, Card, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';

const cardStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 10,
    zIndex: 999,
    position: 'relative',
  },
  media: {
    paddingTop: '50%',
  },
  mediaViewMorePlaceholder: {
    paddingTop: '50%',
  },
}));

const data = ['', '', ''];

/**
 * Onboarding View skeleton loader component
 */
function OnboardingLoader() {
  const classes = cardStyles();
  return (
    <div className={classes.root}>
      <Skeleton animation="wave" width="22%" height={32} />
      <Box mt={2} mb={4}>
        {data.map((item, index) => {
          return (
            <Box key={`OnboardingLoader-${index}`} marginBottom="30px">
              <Card>
                <Box p={2} display="flex" justifyContent="space-between">
                  <Box display="flex">
                    <Skeleton animation="wave" height={32} width={32} style={{ marginRight: 10 }} />
                    <Skeleton animation="wave" height={32} width={200} />
                  </Box>
                  <Skeleton animation="wave" height={32} width={150} />
                </Box>
              </Card>
            </Box>
          );
        })}
      </Box>
    </div>
  );
}

export default OnboardingLoader;
