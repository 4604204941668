import React from 'react';
import { Checkbox } from '@mui/material';

function CheckFilters({ label, filterData, isChecked, filterItems, setFiltersItems }) {
  return (
    <Checkbox
      {...label}
      checked={isChecked}
      onChange={() =>
        setFiltersItems(
          isChecked
            ? filterItems.filter(
                (item) => !(item.value === filterData.value && item.id === filterData.id),
              )
            : [...filterItems, filterData],
        )
      }
    />
  );
}

export default React.memo(CheckFilters);
