import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { Form } from 'src/components/shared';
import { fetchTemplatesLookup } from 'src/modules/sequence/api/sequenceApi';

import { fetchFile } from 'src/modules/app/api/appApis';

function Email({ event, formProps }) {
  const emailTemplates = event.emailTemplates;

  const fetchAttachments = async (attachments) => {
    const promises = [];
    for (let i = 0; i < attachments?.length; i++) {
      const attachmentId = attachments[i];
      promises.push(fetchFile(attachmentId));
    }

    try {
      let response = await Promise.all(promises);
      if (response && response.length) {
        const attaches = response.map((file) => ({
          ...file.upload,
        }));
        return attaches;
      }
      return [];
    } catch (error) {
      return [];
    }
  };

  return (
    <Grid
      container
      sx={
        {
          // borderRight: '1px solid rgba(0,0,0,0.12)',
        }
      }
    >
      {emailTemplates.map((template, index) => {
        return (
          <Grid
            key={`sequence-preview=email-${index}-${template.id}`}
            item
            xs={12 / emailTemplates.length}
          >
            <Box
              sx={{
                borderRight: '1px solid rgba(0,0,0,0.12)',
                borderLeft: '1px solid rgba(0,0,0,0.12)',
                borderBottom: '1px solid rgba(0,0,0,0.12)',
                marginLeft: '-1px',
                left: '1px',
                // width: 'calc(100% + 1px)',
                '& .tox-tinymce': {
                  borderRadius: '0 !important',
                  borderLeft: 'none',
                  borderRight: 'none',
                  borderTop: 'none',
                },
              }}
            >
              <Form.Field.AutoComplete
                multiple={false}
                fullWidth
                options={[template] || []}
                name={`${event.id}.emailTemplates.${template.id}.template`}
                optLabel="name"
                optValue="id"
                placeholder="Template"
                remoteMethod={(val) => {
                  return fetchTemplatesLookup(val, 'email');
                }}
                size="small"
                sx={{
                  marginTop: '-8px',
                  marginBottom: '-17px',
                }}
                InputProps={{ sx: { padding: '6px 12px' } }}
                onChange={async (val) => {
                  if (val && val?.id && val?.id !== '') {
                    formProps.setFieldValue(
                      `${event.id}.emailTemplates.${template.id}.subject`,
                      val.subject,
                    );
                    formProps.setFieldValue(
                      `${event.id}.emailTemplates.${template.id}.content`,
                      val.content,
                    );
                    formProps.setFieldValue(
                      `${event.id}.emailTemplates.${template.id}.attachments`,
                      [],
                    );
                    const files = await fetchAttachments(val.attachments);
                    formProps.setFieldValue(
                      `${event.id}.emailTemplates.${template.id}.attachments`,
                      files,
                    );
                  }
                }}
              />

              <Form.Field.Input
                fullWidth
                name={`${event.id}.emailTemplates.${template.id}.subject`}
                placeholder="Subject"
                size="small"
                input
                showPicker={true}
                emojiPicker={true}
                InputProps={{
                  sx: {
                    padding: '6px 12px',
                  },
                }}
              />

              <Box sx={{ marginTop: -2 }}>
                <Form.Field.TextEditor
                  placeHolder="Content"
                  name={`${event.id}.emailTemplates.${template.id}.content`}
                  toolbarLocation="bottom"
                  sx={{
                    borderColor: '#cccccc',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                  }}
                />
              </Box>

              <Box px={2}>
                <Form.Field.File name={`${event.id}.emailTemplates.${template.id}.attachments`} />
              </Box>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default Email;
