import styled from 'styled-components';
import { Button } from 'src/components/shared';

export const HoverButton = styled(Button).attrs({
  size: 'small',
})`
  color: #26a69a;
  &:hover {
    color: #1976d2;
  }
`;

export const EmailHeader = styled.div`
  position: absolute;
  top: -28px;
  left: -1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const EmailChip = styled.div`
  padding: 4px 16px;
  background-color: #ffffff;
  border-radius: 4px 4px 0px 0px;
  border-top: 1px solid var(--light-other-divider, rgba(0, 0, 0, 0.12));
  border-right: 1px solid var(--light-other-divider, rgba(0, 0, 0, 0.12));
  border-left: 1px solid var(--light-other-divider, rgba(0, 0, 0, 0.12));
  background: var(--light-primary-contrast, #fff);
  display: flex;
  flex-direction: row;
  align-items: center;
`;
