import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CreateTemplate from '../components/CreateTemplate/CreateTemplate';

export const LMSCreateTemplateContainer = (props) => {
  const sessionId = props.match.params.id;

  return (
    <>
      <CreateTemplate/>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LMSCreateTemplateContainer);
