import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import PillarBannerSection from './PillarBannerSection';
import PillarDetailsSection from './PillarDetailSection';
import { useLayoutEffect } from 'react';

const PillarDetail = (props) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Box>
      <PillarBannerSection
        bannerImage={props.pillar?.headerImage}
        id={props.pillar?.id}
        contentType={props.pillar?.contentType}
        title={props.pillar?.title}
        data={props.pillar}
        loading={props.loading}
      />
      <PillarDetailsSection pillarData={props.pillar} loading={props.loading} />
    </Box>
  );
};

export default PillarDetail;
