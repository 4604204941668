import { makeStyles } from '@mui/styles';
import { themeColors } from 'src/modules/lms/config';

const useStyles = makeStyles(({ palette }) => ({
  container: {
    background: '#082035',
    borderRadius: '3px',
    padding: '46px 46px 46px 46px',
  },
  filter: {
    '& .css-1t1f3n5-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root':{
      color:"white !important"
    },
    '& svg': {
      color: '#E3E3E3 !important',
    },
    '& label': {
      color: '#E3E3E3 !important',
    },
    '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
      height: '50px',

      borderColor: ' rgba(239, 239, 239, 0.23) !important',
    },
  },
  title: {
    fontWeight: '400',
    fontSize: '40px',
    lineHeight: '48px',
    color: '#FFFFFF',
  },
}));

export default useStyles;
