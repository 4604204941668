import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import TeamsView from './TeamsView';

import {
  fetchTeams,
  saveTeams,
  updateTeamStatus,
  putTeam,
  teamBulkUpdate,
} from '../../actions/adminActions';

export const TeamsContainer = (props) => {
  useEffect(() => {
    if (!props.teams.length) {
      props.fetchTeams(props.teams.paging, props.teams.filters, props.teams.sort);
    }
  }, []);
  return <TeamsView {...props} />;
};

const mapStateToProps = (state) => {
  return {
    teams: state.admin.admin.teams,
    loading: state.admin.admin.loading,
    users: state.admin.admin.users,
    total: state.admin.admin.teams.paging.count,
    globals: state.app.globals,
    userTotal: state.admin.admin.users?.paging.count,
    teamTotal: state.admin.admin.teams?.paging.count,
  };
};

const mapDispatchToProps = (dispatch) => ({
  putTeam: (id, rowData) => dispatch(putTeam(id, rowData)),
  fetchTeams: (paging, filters, sort) => dispatch(fetchTeams(paging, filters, sort)),
  saveTeams: (data) => dispatch(saveTeams(data)),
  updateTeamStatus: (rowData) => {
    dispatch(updateTeamStatus(rowData));
  },
  teamBulkUpdate: (teams, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(teamBulkUpdate(teams, payload, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsContainer);
