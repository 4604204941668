const competitionGridHeader = [
  {
    id: 1,
    status: 'active',
    type: 'default',
    label: 'Competition name',
    fieldName: 'name',
    fieldType: 'text',
    inContact: false,
    inUser: false,
    inTask: false,
    inSequence: true,
    scope: ['competitions'],
    fieldOptions: { showOnGrid: true, showOnForm: true },
    gridParams: { competitions: { sortOrder: 0 } },
    bhConfig: null,
    lookup: null,
  },
];

export default competitionGridHeader;
