import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import { Spacer } from 'src/components/shared';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 200px;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

function ContactSequenceSkeleton({ showButtons = true }) {
  return (
    <>
      <Accordion expanded={true}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          {' '}
          <Typography>
            <SummaryWrapper>
              <Skeleton animation="wave" height={28} width={'40%'} />
              <Skeleton
                variant="circular"
                width={20}
                height={20}
                sx={{ marginLeft: 2, marginTop: 0.5 }}
              />
            </SummaryWrapper>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ContentWrapper>
            <Skeleton animation="wave" height={100} width={'100%'} />
          </ContentWrapper>
          {showButtons ? (
            <>
              <Spacer x={3} y={3} />
              <PaginationWrapper>
                <Skeleton variant="circular" width={40} height={40} />
                <Spacer basis={2} />
                <Skeleton variant="circular" width={40} height={40} />
                <Spacer basis={2} />
                <Skeleton variant="circular" width={40} height={40} />
                <Spacer basis={2} />
                <Skeleton variant="circular" width={40} height={40} />
              </PaginationWrapper>
            </>
          ) : null}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

ContactSequenceSkeleton.propTypes = {
  showButtons: PropTypes.bool,
};

ContactSequenceSkeleton.defaultProps = {
  showButtons: true,
};

export default ContactSequenceSkeleton;
