import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Box } from '@mui/material';
import { Button, Form, Modal } from 'src/components/shared';
import {
  fetchOfficeLookup,
  fetchTeamLookup,
  fetchTimezoneLookup,
} from 'src/modules/users/api/usersApi';

function UserActionDrawer({ action, onClose, selectedUsers, userBulkUpdate }) {
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);

  const permissionTypes = [
    { value: 'Admin', label: 'Admin' },
    { value: 'Manager', label: 'Manager' },
    { value: 'Standard', label: 'Standard' },
  ];

  useEffect(() => {
    switch (action) {
      case 'selectTeam':
        setTitle(`Change team`);
        break;

      case 'selectOffice':
        setTitle(`Change office`);
        break;

      case 'selectPermission':
        setTitle(`Change permission`);
        break;

      case 'selectTimezone':
        setTitle(`Change timezone`);
        break;

      default:
        break;
    }
  }, [action]);

  if (!action || action == '') return null;

  const handleSubmit = async (payload) => {
    try {
      setLoading(true);
      await userBulkUpdate(selectedUsers, payload);
      setLoading(false);
      onClose();
    } catch (error) {
      setLoading(false);
    }
  };

  const markAllCompleted = () => {
    const data = {
      status: 'completed',
    };
    handleSubmit(data);
  };

  const getContent = () => {
    switch (action) {
      case 'selectTeam':
        return (
          <Form
            initialValues={{
              team: {},
            }}
            validationSchema={Yup.object().shape({
              team: Yup.object().test('team', 'Please select a team.', function (item) {
                return item?.id && item?.id !== '';
              }),
            })}
            onSubmit={(values, form) => {
              handleSubmit({ team: [values.team.id] });
            }}
          >
            {(props) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.submitForm();
                    return false;
                  }}
                  noValidate
                  style={{ height: '100%' }}
                >
                  <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Form.Field.AutoComplete
                      multiple={false}
                      fullWidth
                      options={[]}
                      variant="outlined"
                      remoteMethod={(search) => {
                        return fetchTeamLookup(search);
                      }}
                      name="team"
                      label="Team"
                      optLabel="name"
                      optValue="id"
                      tip="Change assigned team for all selected users."
                    />

                    <Box display={'flex'} spacing={2} mt={2} justifyContent="flex-end">
                      <Button variant="contained" color="secondary" type="submit" loading={loading}>
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </form>
              );
            }}
          </Form>
        );
        break;

      case 'selectOffice':
        return (
          <Form
            initialValues={{
              office: {},
            }}
            validationSchema={Yup.object().shape({
              office: Yup.object().test('office', 'Please select a office.', function (item) {
                return item?.id && item?.id !== '';
              }),
            })}
            onSubmit={(values, form) => {
              handleSubmit({ office: [values.office.id] });
            }}
          >
            {(props) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.submitForm();
                    return false;
                  }}
                  noValidate
                  style={{ height: '100%' }}
                >
                  <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Form.Field.AutoComplete
                      multiple={false}
                      fullWidth
                      options={[]}
                      variant="outlined"
                      remoteMethod={(search) => {
                        return fetchOfficeLookup(search);
                      }}
                      name="office"
                      label="Office"
                      optLabel="name"
                      optValue="id"
                      tip="Change assigned offices for all selected users."
                    />

                    <Box display={'flex'} spacing={2} mt={2} justifyContent="flex-end">
                      <Button variant="contained" color="secondary" type="submit" loading={loading}>
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </form>
              );
            }}
          </Form>
        );
        break;

      case 'selectPermission':
        return (
          <Form
            initialValues={{
              accessLevel: '',
            }}
            validationSchema={Yup.object().shape({
              accessLevel: Yup.string().required('Please select permission!'),
            })}
            onSubmit={(values, form) => {
              handleSubmit({ acl: { accessLevel: values.accessLevel } });
            }}
          >
            {(props) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.submitForm();
                    return false;
                  }}
                  noValidate
                  style={{ height: '100%' }}
                >
                  <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Form.Field.Select
                      fullWidth
                      options={permissionTypes}
                      variant="outlined"
                      name="accessLevel"
                      label="Permission Level"
                      optLabel="label"
                      optValue="value"
                      showNone={false}
                    />

                    <Box display={'flex'} spacing={2} mt={2} justifyContent="flex-end">
                      <Button variant="contained" color="secondary" type="submit" loading={loading}>
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </form>
              );
            }}
          </Form>
        );
        break;

      case 'selectTimezone':
        return (
          <Form
            initialValues={{
              timezone: {},
            }}
            validationSchema={Yup.object().shape({
              timezone: Yup.object().test('office', 'Please select a timezone.', function (item) {
                return item?.id && item?.id !== '';
              }),
            })}
            onSubmit={(values, form) => {
              const data = JSON.parse(JSON.stringify(values));
              data.timezone = data.timezone.timeZone;
              handleSubmit(data);
            }}
          >
            {(props) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.submitForm();
                    return false;
                  }}
                  noValidate
                  style={{ height: '100%' }}
                >
                  <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Form.Field.AutoComplete
                      multiple={false}
                      fullWidth
                      options={[]}
                      variant="outlined"
                      remoteMethod={(search) => {
                        return fetchTimezoneLookup(search);
                      }}
                      name="timezone"
                      label="Timezone"
                      optLabel="name"
                      optValue="id"
                      tip="Change timezone for all selected users."
                    />

                    <Box display={'flex'} spacing={2} mt={2} justifyContent="flex-end">
                      <Button variant="contained" color="secondary" type="submit" loading={loading}>
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </form>
              );
            }}
          </Form>
        );
        break;

      default:
        break;
    }
  };

  return (
    <Modal
      title={title}
      subtitle={`${selectedUsers.length} ${selectedUsers.length > 1 ? 'users' : 'user'} selected`}
      open={action && typeof action !== 'undefined'}
      onClose={onClose}
      size="xs"
    >
      {getContent()}
    </Modal>
  );
}

export default UserActionDrawer;
