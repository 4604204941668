import { all, fork, put, call, select, takeLatest, takeEvery } from 'redux-saga/effects';
import * as appTypes from '../actions/appTypes';
import * as appApis from '../api/appApis';
import * as appActions from '../actions/appActions';
import { fetchContacts, fetchContactsElastic } from 'src/modules/contacts/api/contactApis';
import toast from 'src/utils/toast';
import { sortBy, isEmpty } from 'lodash';

export const getDashboards = (state) => state.dashboard.dashboards;
export const getDashboardContacts = (state) => state.dashboard.contacts.data;
export const getPanels = (state) => state.dashboard.panels;
export const getActiveDashboard = (state) => state.dashboard.activeDashboard;
export const getActiveBoard = (state) => state.boards.activeBoard;

function* fetchDashboards({}) {
  try {
    const res = yield call(appApis.fetchDashboards);
    let dashboards = {};
    if (res.dashboards && res.dashboards.length) {
      for (let i = 0; i < res.dashboards.length; i++) {
        dashboards[res.dashboards[i].id] = res.dashboards[i];
      }
    }
    yield put(appActions.setDashboards(dashboards));
    const active = yield select(getActiveBoard);
    if (!active || active == '' || !Object.keys(dashboards).includes(active)) {
      const activeDashboardId = dashboards[Object.keys(dashboards)[0]].id;
      yield put(appActions.setActiveDashboard(activeDashboardId));
    }
  } catch (error) {}
}

function* fetchPanels({ dashboardId }) {
  try {
    let panels = {};
    const res = yield call(appApis.fetchPanels, dashboardId);
    if (res.panels && res.panels.length) {
      panels = Object.assign(
        {},
        ...res.panels.map((panel) => ({ [panel.id]: { ...panel, contacts: [], loading: true } })),
      );
    }
    yield put(appActions.setPanels(panels));
  } catch (error) {}
}

function* postBoard({ payload, resolve, reject }) {
  try {
    const response = yield call(appApis.createDashboard, payload);
    let dashboards = yield select(getDashboards);
    dashboards = JSON.parse(JSON.stringify(dashboards));
    dashboards[response.dashboard.id] = response.dashboard;
    yield put(appActions.setDashboards(dashboards));
    yield put(appActions.setActiveDashboard(response.dashboard.id));
    toast.success('Dashboard created!');
    resolve(true);
  } catch (error) {
    reject(error);
  }
}

function* putBoard({ boardId, payload, resolve, reject }) {
  try {
    const res = yield call(appApis.updateDashboard, boardId, payload);
    let dashboards = yield select(getDashboards);
    dashboards = JSON.parse(JSON.stringify(dashboards));
    dashboards[boardId] = res.dashboard;
    toast.success('Dashboard updated!');
    yield put(appActions.setDashboards(dashboards));
    resolve(res);
  } catch (error) {
    reject(error);
  }
}

function* putPanel({ panelId, payload, resolve, reject }) {
  try {
    const res = yield call(appApis.editPanel, panelId, payload);
    let panels = yield select(getPanels);
    panels = JSON.parse(JSON.stringify(panels));
    panels[panelId] = {
      ...panels[panelId],
      ...res.panel,
    };
    toast.success('Panel updated!');
    yield put(appActions.setPanels(panels));
    resolve(res);
  } catch (error) {
    reject(error);
  }
}

function* postPanel({ payload, resolve, reject }) {
  try {
    const dashboardId = yield select(getActiveBoard);
    const response = yield call(appApis.createPanel, dashboardId, payload);

    let panels = yield select(getPanels);
    panels = JSON.parse(JSON.stringify(panels));
    panels[response.panel.id] = {
      ...response.panel,
      contacts: [],
    };
    toast.success('Panel created!');
    yield put(appActions.setPanels(panels));
    resolve(true);
  } catch (error) {
    reject(error);
  }
}

function* fetchDashboardContacts({ paging, filters, resolve, reject }) {
  try {
    const newFilters = {};
    if (!isEmpty(filters)) {
      newFilters._where = {};
      if (filters._where && filters._where.length) {
        newFilters._where = [];

        filters._where.map((filter, index) => {
          if (filter.filters && filter.filters.length) {
            newFilters._where[index] = {
              _or: [],
            };
            for (let i = 0; i < filter.filters.length; i++) {
              const filterItem = filter.filters[i];
              if (!isEmpty(filterItem.column) && !isEmpty(filterItem.value)) {
                let key;
                switch (filterItem.condition) {
                  case 'eq':
                    key = filterItem.column;
                    break;
                  default:
                    key = `${filterItem.column}_${filterItem.condition}`;
                    break;
                }
                if (!newFilters._where[index]._or.length) {
                  newFilters._where[index]._or.push({
                    [key]: filterItem.value,
                  });
                } else {
                  newFilters._where[index]._or[0] = {
                    ...newFilters._where[index]._or[0],
                    [key]: filterItem.value,
                  };
                }
              }
            }
          }
        });
      }
    }

    let filter = {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      ...newFilters,
    };
    const response = yield call(fetchContactsElastic, filter);
    let contacts = {};
    if (response.contacts && response.contacts.length) {
      contacts = Object.assign(
        {},
        ...response.contacts.map((contact) => ({ [contact.id]: contact })),
      );
    }

    yield put(appActions.setDashboardContacts(contacts, response.total));
    resolve(true);
  } catch (error) {
    reject(error);
  }
}

function* fetchPanelContacts({ panelId }) {
  try {
    let contacts = yield call(appApis.getPanelContacts, panelId);
    contacts = contacts.panelContacts;
    contacts = _.sortBy(contacts, 'sortOrder');
    yield put(appActions.setPanelContacts(panelId, contacts));
  } catch (error) {}
}

function* handleDashboardDragDrop({ data }) {
  try {
    let newPanelId, oldPanelId, oldPanel, newPanel;
    let contactId = data.draggableId.split('-')[1];
    let panels = yield select(getPanels);
    const contacts = yield select(getDashboardContacts);
    if (data?.destination && data.destination.droppableId !== 'sidebar') {
      newPanelId = data.destination.droppableId;
    }
    if (data?.source && data.source.droppableId !== 'sidebar') {
      oldPanelId = data.source.droppableId;
    }
    if (newPanelId !== oldPanelId) {
      newPanel = panels[newPanelId];
      let newContacts = newPanel.contacts;
      const exist = newContacts.filter((item) => item?.contact?.id === contactId).length > 0;
      if (!exist) {
        if (newPanelId) {
          if (oldPanelId) {
            oldPanel = panels[oldPanelId];
            let oldContacts = oldPanel.contacts;
            const contactToMove = oldContacts.filter((item) => item?.contact?.id === contactId)[0];
            newContacts.push({ contact: contactToMove.contact, sortOrder: data.destination.index });
          } else {
            newContacts.push({ contact: contacts[contactId], sortOrder: data.destination.index });
          }
          newContacts = _.sortBy(newContacts, 'sortOrder');
          yield put(appActions.setPanelContacts(newPanelId, newContacts));
          const response = yield call(
            appApis.addContactToPanel,
            newPanelId,
            contactId,
            data.destination.index,
          );
          newContacts = newContacts.map((item) => {
            if (!item.id && item?.contact?.id == contactId) {
              return response.contactsPanel;
            } else {
              return item;
            }
          });
          yield put(appActions.setPanelContacts(newPanelId, newContacts));
        }
        if (oldPanelId) {
          oldPanel = panels[oldPanelId];
          let oldContacts = oldPanel.contacts;
          oldContacts = oldContacts.filter((item) => item?.contact?.id !== contactId);
          yield put(appActions.setPanelContacts(oldPanelId, oldContacts));
          yield call(appApis.removeContactFromPanel, oldPanelId, contactId);
        }
      } else {
        toast.warning('Contact already exist!');
      }
    } else {
      newPanel = panels[newPanelId];
      let letPanelContactId = '';
      if (data.destination.index !== data.source.index) {
        let newContacts = newPanel.contacts.map((item) => {
          if (item?.contact?.id === contactId) {
            letPanelContactId = item.id;
          }
          return {
            ...item,
            ...(item?.contact?.id === contactId && {
              sortOrder: data.destination.index,
            }),
            ...(item.sortOrder >= data.destination.index &&
              item?.contact?.id !== contactId && {
                sortOrder: item.sortOrder + 1,
              }),
          };
        });
        newContacts = _.sortBy(newContacts, 'sortOrder');
        yield put(appActions.setPanelContacts(newPanelId, newContacts));
        const res = yield call(appApis.updatePanelContact, newPanelId, letPanelContactId, {
          sortOrder: data.destination.index,
        });
      }
    }
  } catch (error) {}
}

function* removeContactFromPanel({ panelId, contactId }) {
  try {
    let panels = yield select(getPanels);
    const panel = panels[panelId];
    let contacts = panel.contacts;
    contacts = contacts.filter((contact) => contact?.contact?.id !== contactId);
    yield put(appActions.setPanelContacts(panelId, contacts));
    yield call(appApis.removeContactFromPanel, panelId, contactId);
  } catch (error) {}
}

function* deletePanel({ panelId, resolve, reject }) {
  try {
    yield call(appApis.deletePanel, panelId);
    let panels = yield select(getPanels);
    panels = JSON.parse(JSON.stringify(panels));
    delete panels[panelId];
    yield put(appActions.setPanels(panels));
    toast.success('Panel deleted!');
    resolve(true);
  } catch (error) {
    reject(false);
  }
}

function* deleteBoard({ boardId, resolve, reject }) {
  try {
    yield call(appApis.deleteDashboard, boardId);
    let dashboards = yield select(getDashboards);
    dashboards = JSON.parse(JSON.stringify(dashboards));
    delete dashboards[boardId];
    yield put(appActions.setDashboards(dashboards));

    if (Object.keys(dashboards).length) {
      const activeDashboardId = dashboards[Object.keys(dashboards)[0]].id;
      yield put(appActions.setActiveDashboard(activeDashboardId));
    } else {
      yield put(appActions.setActiveDashboard(''));
      yield put(appActions.setPanels({}));
    }
    resolve(true);
  } catch (error) {}
}

function* handleAddContactToPanel({ panelId, contact, resolve, reject }) {
  try {
    const res = yield call(appApis.addContactToPanel, panelId, contact?.id, 0);
    let panels = yield select(getPanels);
    panels = JSON.parse(JSON.stringify(panels));
    panels[panelId].contacts.push(res.contactsPanel);
    toast.success('Contact added to panel!');
    yield put(appActions.setPanels(panels));
    resolve(true);
  } catch (error) {
    reject(error);
  }
}

function* panelBulkAddUpdate({ data, resolve, reject }) {
  try {
    const boardId = yield select(getActiveBoard);
    let panelsData = yield select(getPanels);
    console.log('panels : ', panelsData);

    const updateStages = data.stages.filter((item) => !item.id.includes('new-stage-'));
    const stagesObj = {};
    data.stages.forEach((ele) => {
      stagesObj[ele.id] = ele;
    });
    const deleteStages = Object.keys(panelsData).filter(
      (item) => !Object.keys(stagesObj)?.includes(item),
    );
    const newStages = data.stages
      .filter((item) => item.id.includes('new-stage-'))
      .map((item) => ({
        name: item.name,
        sortOrder: item.sortOrder,
        tabColor: item.tabColor,
      }));

    if (deleteStages && deleteStages.length) {
      for (const id of deleteStages) {
        yield call(appApis.deletePanel, id);
      }
    }
    let updated = [];
    if (updateStages && updateStages.length) {
      updated = yield call(appApis.panelBulkUpdate, updateStages);
      updated = updated.updated;
    }
    let added = [];
    if (newStages && newStages.length) {
      added = yield call(appApis.panelBulkAdd, boardId, newStages);
      added = added.added;
    }

    console.log('updated: ', updated);
    console.log('deleted: ', deleteStages);
    console.log('added: ', added);

    let stages = [...updated, ...added];
    stages = sortBy(stages, 'sortOrder');
    console.log('stages : ', stages);
    let panels = [];
    if (stages && stages.length) {
      panels = Object.assign(
        {},
        ...stages.map((panel) => ({
          [panel.id]: {
            ...panel,
            ...(panelsData[panel.id]
              ? panelsData[panel.id]
              : {
                  contacts: [],
                  loading: true,
                }),
            name: panel.name,
            sortOrder: panel.sortOrder,
            tabColor: panel.tabColor,
          },
        })),
      );
    }
    yield put(appActions.setPanels(panels));
    resolve(true);
  } catch (error) {
    reject(error);
  }
}

export function* watchSagas() {
  yield takeLatest(appTypes.FETCH_DASHBOARDS, fetchDashboards);
  yield takeLatest(appTypes.POST_DASHBOARD, postBoard);
  yield takeLatest(appTypes.PUT_DASHBOARD, putBoard);
  yield takeLatest(appTypes.DELETE_DASHBOARD, deleteBoard);
  yield takeLatest(appTypes.DELETE_PANEL, deletePanel);
  yield takeLatest(appTypes.POST_PANEL, postPanel);
  yield takeLatest(appTypes.PUT_PANEL, putPanel);
  yield takeLatest(appTypes.FETCH_PANELS, fetchPanels);
  yield takeLatest(appTypes.FETCH_DASHBOARD_CONTACTS, fetchDashboardContacts);
  yield takeLatest(appTypes.HANDLE_DASHBOARD_DRAG_DROP, handleDashboardDragDrop);
  yield takeEvery(appTypes.FETCH_PANEL_CONTACTS, fetchPanelContacts);
  yield takeEvery(appTypes.REMOVE_CONTACT_FROM_PANEL, removeContactFromPanel);
  yield takeEvery(appTypes.HANDLE_ADD_CONTACT_TO_PANEL, handleAddContactToPanel);
  yield takeEvery(appTypes.PANEL_BULK_ADD_UPDATE, panelBulkAddUpdate);
}

export default function* runSagas() {
  yield all([fork(watchSagas)]);
}
