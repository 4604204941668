import React from 'react';
import PropTypes from 'prop-types';
import Search from './Search';
import Dropdown from './Dropdown';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { v4 as uuidv4 } from 'uuid';

function FilterBar({ showSearch, filters, onChange, value }) {
  const id = 'lms-filter-item';

  const handleSearch = (value) => {
    onChange('_search', value);
  };

  const handleFilter = (key, value) => {
    onChange(key, value);
  };

  return (
    <Grid container spacing={2}>
      {showSearch && (
        <Grid item xs={12} md={5} lg={5}>
          <Search onChange={handleSearch} value={value?._search || ''} />
        </Grid>
      )}

      <Grid item xs={12} md={5} lg={5}>
        <Stack flex={1} direction="row" spacing={2} mt={0.5}>
          {filters.map((filter, index) => (
            <Dropdown
              filter={filter}
              key={`${id}-${index}`}
              id={id}
              optLabel={filter?.optLabel || 'label'}
              optValue={filter?.optValue || 'value'}
              onChange={handleFilter}
              value={value[filter.key] || undefined}
            />
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
}

FilterBar.propTypes = {
  showSearch: PropTypes.bool,
  onChange: PropTypes.func,
  filters: PropTypes.array,
  value: PropTypes.any,
};

FilterBar.defaultProps = {
  showSearch: false,
  onChange: () => {},
  filters: [],
  value: {},
};

export default FilterBar;
