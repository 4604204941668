import React, { useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ButtonBase from '@mui/material/ButtonBase';
import images from 'src/config/images';
import styled from 'styled-components';
import { VideoModal } from 'src/components/App';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';

function Item({ data = {}, onComplete = () => null }) {
  const ref = useRef();
  const user = useSelector((state) => state.auth.user);
  const [viewVideoModal, setViewVideoModal] = React.useState(null);
  ref.current = viewVideoModal;

  const eventFunction = (event) => {
    if (event?.origin === 'https://srvplr.superreach.com' && event?.data?.event === 'videoEnded') {
      onComplete(ref.current);
      setViewVideoModal(null);
    }
  };

  useEffect(() => {
    if (data.video) window.addEventListener('message', eventFunction, false);
    return () => window.removeEventListener('message', eventFunction);
  }, [data.video]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={5}>
        <VideoThumbContainer>
          {data?.thumbnail ? <img className="thumbnail" src={data.thumbnail} /> : null}
          <VideoThumb>
            <ButtonBase className="playPlaceholder" onClick={() => setViewVideoModal(data.id)}>
              <img src={images.icons.playButton} />
            </ButtonBase>
          </VideoThumb>
        </VideoThumbContainer>
        {viewVideoModal && (
          <VideoModal
            video={{ id: '1', videoUrl: data.video }}
            onClose={() => setViewVideoModal(null)}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={7}>
        <Stack gap={'16px'}>
          <Typography fontWeight={600} fontSize={'20px'}>
            {(data.title ?? '')
              .replace(/%recipient\.fname%/g, user.fname)
              .replace(/%recipient\.lname%/g, user.lname)
              .replace(/%recipient\.name%/g, `${user.fname} ${user.lname}`)}
          </Typography>
          {/* <Typography variant="div" color="textSecondary">
            <div dangerouslySetInnerHTML={{ __html: data.content }} />
          </Typography> */}
          {data.content.split('<br/>').map((line, index) => (
            <Typography color="textSecondary" key={`desc-${index}`}>
              {parse(line)}
            </Typography>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
}

export default Item;

const VideoThumbContainer = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
  border-radius: 12px;
  aspect-ratio: 256/144;
  height: auto;
  width: 100%;
  & img {
    border-radius: 8px;
  }
  .refreshButton {
    display: none;
    width: 44px;
    height: 44px;
    background-color: #ffffff;
    border-radius: 44px;
    z-index: 999;
    position: absolute;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    align-items: center;
    justify-content: center;
    & .MuiSvgIcon-root {
      width: 1.2em;
      height: 1.2em;
      fill: #000000;
    }
  }
  & .MuiLinearProgress-root {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  & .playIcon {
    display: none;
    position: absolute;
    top: 0;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    z-index: 10;
    width: 48px;
    height: 48px;
    z-index: 999;
    color: #ffffff;
  }
  & .backdrop {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(12, 23, 33, 60);
    border-radius: 4px;
    z-index: 10;
    opacity: 0.4;
    border-radius: 0;
    /* z-index: 9; */
  }
  &:hover .backdrop,
  &:hover .playIcon {
    display: block;
  }
  &:hover .refreshButton {
    display: flex;
  }
  & .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const VideoThumb = styled.div`
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #193247;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  & .playPlaceholder {
    border-radius: 50%;
  }
`;
