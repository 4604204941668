import React from 'react';
import PropTypes from 'prop-types';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { uniqueId } from 'lodash';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function AppBreadcrumbs({ steps }) {
  const uniqueKey = uniqueId('breadcrumbs-');
  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      {steps.map((step, index) => {
        if (step.route) {
          return (
            <RouterLink to={step.route} key={`${uniqueKey}-${index}}`}>
              {/* <Link color="textSecondary" href="/"> */}
              <Typography color="secondary">{step.title}</Typography>
              {/* </Link> */}
            </RouterLink>
          );
        } else {
          return (
            <Typography key={`${uniqueKey}-${index}}`} color="textPrimary" component="span">
              {step.title}
            </Typography>
          );
        }
      })}
    </Breadcrumbs>
  );
}

AppBreadcrumbs.propTypes = {
  steps: PropTypes.array,
};

AppBreadcrumbs.defaultProps = {
  steps: [],
};

export default AppBreadcrumbs;
