import { Button, Grid, Typography, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CardMedia from '../../shared/cardMedia';
import useStyles from '../TwoImageSideSection/style';

export const TwoImageSideSection = ({ text, data = [] }) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.coursesText}>
        <Typography variant="p">{text}</Typography>
      </Box>
      {data.filter((k)=>k.published).map((k, i) => (
        <CardMedia
          key={i}
          src={k?.thumbnailImage}
          className={classes.courseImg2}
          title={k.title}
          contentType={k.contentType}
          id={k.id}
          data={k}
          type={'otherImage'}
          dontShowAction={true}
        />
      ))}
    </>
  );
};
export default TwoImageSideSection;
