import React, { Fragment } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import { Row, Span, useStyles } from './styles';

const Timeline = ({ current, steps, onStepChange }) => {
  const classes = useStyles();

  return (
    <Row className={classes.TimelineWrapper}>
      {steps.map((step, index) => {
        return (
          <Fragment key={index}>
            <Row onClick={() => index && onStepChange(index+1)} className="timeline" style={{ cursor: index ? 'pointer' : 'inherit'}}>
              <Row className={`step-no`}>
                {index + 1}
              </Row>
              <Span className={`step-name`}>{step}</Span>
            </Row>
            {index < steps.length - 1 && (
              <Row style={{ background: '#E0E0E0' }} className="divider"></Row>
            )}
          </Fragment>
        );
      })}
    </Row>
  );
};

export default Timeline;
