import React, { useState } from 'react';
import { BarItem, BarFill } from './styles';
import { Modal } from 'src/components/shared';

function Bar({ score, size, session }) {
  const [showDetails, setShowDetails] = useState(false);
  const scoreVal = score.score;
  let height = (100 * score.score) / 5;
  let color = '#FF4040';
  switch (true) {
    case scoreVal > 3:
      color = '#60FF40';
      break;

    case scoreVal > 2 && scoreVal <= 3:
      color = '#FF8F40';
      break;
  }

  const html = score?.feedback.replace(/\n/g, '<br />') || '';

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <>
      <BarItem size={size} onClick={toggleDetails}>
        <BarFill size={size} color={color} height={height} />
      </BarItem>

      <Modal title={session?.title || ''} open={showDetails} onClose={toggleDetails}>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Modal>
    </>
  );
}

export default Bar;
