import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../shared/Modal';
import { Box, Typography, Stack } from '@mui/material';
import { Button } from 'src/components/shared';
import images from 'src/config/images';

import Accept from './Accept';

import { updateUser } from 'src/modules/auth/api/authApis';
import { setCurrentUser } from 'src/modules/auth/actions/authActions';

function PrivacyPolicy() {
  const [showPrivacy, setShowPrivacy] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const privacyPolicyAccepted = user?.privacyPolicyAccepted || false;
  const termsAccepted = user?.termsAccepted || false;

  const toggle = () => {
    setShowPrivacy(!showPrivacy);
  };

  const showPrivacyPreview = useMemo(() => {
    return !privacyPolicyAccepted && !termsAccepted;
  }, [privacyPolicyAccepted, termsAccepted]);

  const onSubmit = async (val) => {
    try {
      const res = await updateUser({
        id: user.id,
        data: {
          privacyPolicyAccepted: true,
          termsAccepted: true,
        },
      });
      dispatch(setCurrentUser({ userData: res }));
      toggle();
    } catch (error) {}
  };

  if (showPrivacyPreview && !showPrivacy) {
    return (
      <Modal
        open={true}
        customActions={() => {}}
        size="xs"
        sx={{
          zIndex: 999999,
          '& .MuiPaper-root': {
            backgroundColor: '#090D11',
            color: '#ffffff',
          },
        }}
      >
        <Stack direction="column" gap={3} alignItems="center">
          <img src={images.app.terms} style={{ width: '120px' }} />
          <Typography variant="h1" color="#FFD47E" textAlign="center">
            Update Privacy Policy
          </Typography>
          <Typography variant="body1" color="#ffffff" textAlign="center">
            We have updated our Privacy Policy. Please read and agree to the same to continue.
          </Typography>

          <Button
            variant="contained"
            size="large"
            onClick={toggle}
            sx={{ backgroundColor: '#FFD47E', color: '#090D11' }}
            fullWidth={true}
          >
            Read and Accept
          </Button>

          <Box />
        </Stack>
      </Modal>
    );
  }

  if (showPrivacy) {
    return <Accept toggle={toggle} onSubmit={onSubmit} />;
  }

  return null;
}

export default PrivacyPolicy;
