export const resellerTabs = [
  {
    id: 'dashboard',
    name: 'Reseller Summary',
  },
  {
    id: 'referral',
    name: 'Referral Management',
  },
];
