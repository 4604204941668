import { all, fork, put, call, takeEvery, takeLatest, select, delay } from 'redux-saga/effects';
import * as competitionTypes from '../actions/competitionTypes';
import * as competitionApi from '../api/competitionApi';
import * as competitionActions from '../actions/competitionActions';
import { convertObjectToQuerystring } from 'src/utils/helper';
import toast from 'src/utils/toast';
import moment from 'moment';

const getCompetitionList = (state) => state.competitions?.competitionsList;

function* fetchCompetitionsList({ paging, filters, sort, resolve, reject }) {
  try {
    let filter = {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      ...(filters?._search ? { _search: filters?._search } : {}),
      ...(filters?.sessionName ? { session_eq: filters?.sessionName?.id } : {}),
      ...(filters?.status ? { status_eq: filters?.status?.value } : {}),
    };
    filter = `?${convertObjectToQuerystring(filter)}`;
    const response = yield call(competitionApi.getCompetitionList, filter);
    yield put(competitionActions.setCompetitionsList(response));
    resolve(true);
  } catch (error) {
    yield put(competitionActions.setCompetitionsList({ competition: [], total: { value: 0 } }));
    reject(false);
  }
}

function* editCompetition({ payload, competitionId, resolve, reject }) {
  try {
    const response = yield call(competitionApi.editCompetition, payload, competitionId);
    const { paging, filters } = yield select(getCompetitionList);
    if (response?.error && response?.error !== '') {
      yield put(competitionActions.fetchCompetitionsList(paging, filters, {}, resolve, reject));
    } else {
      if (Object.keys(response).length) {
        toast.success('Competition edited');
      } else {
        toast.error('Competition is not edited');
      }
      yield put(competitionActions.fetchCompetitionsList(paging, filters, {}, resolve, reject));
    }
  } catch (error) {}
}

function* postCompetition({ data, resolve, reject }) {
  try {
    const competitions = yield select(getCompetitionList);
    const invite = data.invite;
    const inviteSendDate = moment(data.inviteSendDate).format('YYYY-MM-DD');
    const inviteSendTime = data.inviteSendTime;

    delete data.invite;
    delete data.inviteSendDate;
    delete data.inviteSendTime;

    let payload = {
      ...data,
      name: data.session.title,
      users: data.users.map((item) => item.id),
      session: data.session.id,
    };

    switch (invite) {
      case 'tomorrowMorning':
        payload = {
          ...payload,
          inviteSendDate: moment().add(1, 'days').hours(9).startOf('hour').toISOString(),
        };
        break;

      case 'nextMonday':
        var d = new Date();
        d.setDate(d.getDate() + ((1 + 7 - d.getDay()) % 7 || 7));
        payload = {
          ...payload,
          inviteSendDate: moment(d).hours(9).startOf('hour').toISOString(),
        };
        break;

      case 'custom':
        payload = {
          ...payload,
          inviteSendDate: new Date(inviteSendDate + ' ' + inviteSendTime).toISOString(),
        };
        break;

      default:
      case 'immediately':
        payload = {
          ...payload,
          inviteImmediately: true,
        };
        break;
    }

    const res = yield call(competitionApi.createCompetition, payload);
    if (competitions.paging.pageNo === 0) {
      yield put(
        competitionActions.setCompetitionsList({
          competition: [res.competition[0], ...competitions.data],
          total: { value: competitions.paging.count + 1 },
          live: { value: competitions.paging.live },
        }),
      );
    }
    toast.success('Competition created successfully!');
    resolve(true);
  } catch (error) {
    toast.error(error?.error?.message || 'Error ocurred! Please try again.');
    reject(false);
  }
}

function* putCompetition({ id, data, resolve, reject }) {
  try {
    const competitions = yield select(getCompetitionList);
    const invite = data.invite;
    const inviteSendDate = data.inviteSendDate;
    const inviteSendTime = data.inviteSendTime;

    delete data.invite;
    delete data.inviteSendDate;
    delete data.inviteSendTime;

    const payload = {
      ...data,
      name: data.session.title,
      users: data.users.map((item) => item.id),
      session: data.session.id,
      inviteImmediately: invite === 'immediately' ? true : false,
    };

    const res = yield call(competitionApi.editCompetition, id, payload);
    yield put(
      competitionActions.setCompetitionsList({
        competition: competitions.data.map((item) => {
          if (item.id == id) {
            return res.competition;
          }
          return item;
        }),
        total: { value: competitions.paging.count },
        live: { value: competitions.paging.live },
      }),
    );
    toast.success('Competition updated successfully!');
    resolve(true);
  } catch (error) {
    reject(false);
    toast.error(error?.error?.message || 'Error ocurred! Please try again.');
  }
}

export function* watchSagas() {
  yield takeLatest(competitionTypes.FETCH_COMPETITIONS_LIST, fetchCompetitionsList);
  yield takeLatest(competitionTypes.EDIT_COMPETITIONS_LIST, editCompetition);
  yield takeLatest(competitionTypes.POST_COMPETITION, postCompetition);
  yield takeLatest(competitionTypes.PUT_COMPETITION, putCompetition);
}

export default function* runSagas() {
  yield all([fork(watchSagas)]);
}
