import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PageTitle, Tabs, FilterBar } from 'src/components/App';
import { resellerTabs } from '../../config/index';

import { withResellerStore, useResellerSummary } from '../../utils/resellerStore';
import ReferralsGrid from '../../components/Referrals/ReferralsGrid';
import ReferralSummary from '../../components/Referrals/ReferralSummary';
import ReferralChart from '../../components/Referrals/ReferralChart';
import { Spacer } from 'src/components/shared';
import { Container } from '@mui/material';

export const ResellersContainer = (props) => {
  const { referrals, fetchResellerSummary, fetchResellerSummaryStats } = useResellerSummary();
  // const {} = referrals;

  const filters = [
    {
      key: 'createdAtRange',
      title: 'Filter By Date',
      type: 'relativeDateRange',
      custom: true,
      options: [
        { id: 'today', name: 'Today' },
        { id: 'yesterday', name: 'Yesterday' },
        { id: 'thisWeek', name: 'This week' },
        { id: 'lastWeek', name: 'Last week' },
        { id: 'thisMonth', name: 'This month' },
        { id: 'lastMonth', name: 'Last month' },
        { id: 'last3Month', name: 'Last 3 months' },
      ],
      isPrimary: true,
    },
  ];

  useEffect(() => {
    fetchResellerSummary(referrals.paging);
    fetchResellerSummaryStats();
  }, []);

  const onTabChange = (tab) => {
    props.history.push(`/admin/resellers/${tab.id}`);
  };

  const onChange = (key, value) => {
    fetchResellerSummary(referrals.paging, {
      [key]: value,
    });
    fetchResellerSummaryStats({
      [key]: value,
    });
  };

  return (
    <>
      <PageTitle title="Resellers" />
      <Tabs tabs={resellerTabs} onChange={onTabChange} value={'referral'} />
      <Spacer x={1} y={1} />
      <Container maxWidth="xl">
        <FilterBar
          showSearch={false}
          filters={filters}
          appliedFilters={referrals?.filters || {}}
          onChange={onChange}
        />
        <Spacer x={1} y={1} />
        <ReferralSummary />
        <ReferralsGrid />
        <Spacer x={2} y={2} />
        <ReferralChart />
        <Spacer x={4} y={4} />
      </Container>
    </>
  );
};

export default withResellerStore(ResellersContainer);
