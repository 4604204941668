import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import UsersView from './UsersView';

import {
  fetchUserTabs,
  fetchUsers,
  saveUser,
  putUser,
  userBulkDelete,
  updateUserStatus,
  userBulkUpdate,
} from '../../actions/adminActions';

export const UsersContainer = (props) => {
  useEffect(() => {
    if (!props.users?.length) {
      props.fetchUsers(props.users.paging, props.users.filters, props.users.sort);
    }
  }, []);
  return <UsersView userBulkUpdate={props.userBulkUpdate} {...props} />;
};

const mapStateToProps = (state) => {
  return {
    loading: state.admin.admin.loading,
    usersTabs: state.admin.admin.users?.userTabs,
    users: state.admin.admin?.users,
    total: state.admin.admin.users?.paging.count,
    globals: state.app.globals,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUserTabs: () => dispatch(fetchUserTabs()),
  fetchUsers: (paging, filters, sort) => dispatch(fetchUsers(paging, filters, sort)),
  saveUser: (data) => {
    return new Promise((resolve, reject) => {
      dispatch(saveUser(data, resolve, reject));
    });
  },
  updateUserStatus: (rowData) => dispatch(updateUserStatus(rowData)),
  putUser: (rowData) => dispatch(putUser(rowData)),
  userBulkUpdate: (users, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(userBulkUpdate(users, payload, resolve, reject));
    });
  },
  userBulkDelete: (action, users) => {
    return new Promise((resolve, reject) => {
      dispatch(userBulkDelete(action, users, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer);
