import Tags from '../container/tags';

export default [
  {
    title: 'Tag',
    component: Tags,
    url: '/admin/tags',
    exact: true,
    auth: true,
  },
];
