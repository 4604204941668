import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, ButtonBase, Stack, Tooltip, Typography } from '@mui/material';
import { Form, Spacer, Button, Modal } from 'src/components/shared';
import { capitalizeName } from 'src/modules/contacts/utils/contactUtils';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LockIcon from '@mui/icons-material/Lock';

const planSortOrder = ['outreach', 'lms'];
const subscriptionTypeOrder = ['monthly', 'yearly'];
const subscriptionPeriod = {
  outreachMonthly: 'outreachMonthly',
  outreachYearly: 'outreachAnnual',
  lmsMonthly: 'lmsmonthly',
  lmsYearly: 'lmsannual',
};

const UserSubscriptionsForm = ({
  userData,
  plansData,
  licencedValues,
  setLicencedValues = () => null,
}) => {
  const history = useHistory();
  const isAllLicencesUsed = Object.values(plansData)?.reduce((acc, { plans }) => {
    if (acc >= 0)
      Object.values(plans)?.map((plan) => {
        if (plan?.quantity - plan?.used > 0) acc = -1;
        else acc += plan?.quantity;
      });
    return acc;
  }, 0);

  return (
    <Stack
      sx={{
        overflowY: 'auto',
        marginRight: '-20px',
        paddingRight: '20px',
        '&::-webkit-scrollbar': {
          width: 4,
          height: 4,
        },
        '&::-webkit-scrollbar-track': {
          borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#0000002E',
          borderRadius: 6,
        },
      }}
    >
      <Spacer y={2} />

      <Stack>
        <Typography fontSize={'20px'} fontWeight={500}>
          Subscriptions
        </Typography>
        <Typography fontSize={'16px'} fontWeight={400} color="#CF0175">
          Select the users subscription type.
        </Typography>
      </Stack>

      <Spacer y={4} />

      {Object.keys(plansData ?? {})
        ?.sort((a, b) => planSortOrder.indexOf(a) - planSortOrder.indexOf(b))
        ?.flatMap((key, _) => {
          const plan = plansData[key];
          if (!Object.keys(plan.plans).length) return [];
          return (
            <Fragment key={_}>
              <Stack gap={'10px'} width={'100%'}>
                <Typography fontSize={'20px'} fontWeight={500}>
                  {plan.title}
                </Typography>
                <Stack direction="row" gap="16px">
                  {Object.keys(plan.plans)
                    .sort(
                      (a, b) => subscriptionTypeOrder.indexOf(a) - subscriptionTypeOrder.indexOf(b),
                    )
                    .map((planPeriod, _) => {
                      const planObj = plan.plans[planPeriod];
                      const isSubscribed = planObj?.quantity > 0;
                      const total = planObj?.quantity ?? 0;
                      const used = planObj?.used ?? 0;
                      const available = total - used;
                      const isSelected =
                        licencedValues[key + 'Type'] ===
                        subscriptionPeriod[key + capitalizeName(planPeriod)];
                      const isDisabled = !(
                        userData?.[key + 'Type'] ===
                          subscriptionPeriod[key + capitalizeName(planPeriod)] || available > 0
                      );
                      return isSubscribed ? (
                        <Stack gap={'10px'} width={'100%'}>
                          <ButtonBase
                            disabled={isDisabled}
                            onClick={() =>
                              setLicencedValues((prevValues) => {
                                return {
                                  ...prevValues,
                                  [key + 'Type']: !isSelected
                                    ? subscriptionPeriod[key + capitalizeName(planPeriod)]
                                    : null,
                                };
                              })
                            }
                            key={_}
                            sx={{
                              flex: 1,
                              flexDirection: 'column',
                              cursor: 'pointer',
                              gap: '6px',
                              padding: '14px',
                              borderRadius: '4px',
                              border: isDisabled ? '1px solid #00000042' : '1px solid #1976D2',
                              backgroundColor: isSelected ? '#1976D2 !important' : 'transparent',
                              '&:hover': {
                                backgroundColor: '#1976D21F',
                              },
                            }}
                          >
                            <Stack
                              width={'100%'}
                              direction={'row'}
                              justifyContent={'space-between'}
                            >
                              <Typography
                                color={
                                  isSelected ? '#FFFFFF' : isDisabled ? '#00000099' : '#000000DE'
                                }
                                fontWeight={500}
                                fontSize={'16px'}
                              >
                                {planObj.billing_period_unit === 'year' ? 'Yearly' : 'Monthly'}
                              </Typography>
                              <Tooltip
                                title={
                                  <Stack>
                                    <Stack
                                      direction={'row'}
                                      gap="24px"
                                      justifyContent={'space-between'}
                                    >
                                      <Typography>Purchased :</Typography>
                                      <Typography>{planObj?.quantity}</Typography>
                                    </Stack>
                                    <Stack
                                      direction={'row'}
                                      gap="24px"
                                      justifyContent={'space-between'}
                                    >
                                      <Typography>Available :</Typography>
                                      <Typography>{available}</Typography>
                                    </Stack>
                                  </Stack>
                                }
                                arrow
                                variant={'dense'}
                                placement={'bottom'}
                                key={planObj.id}
                              >
                                <InfoOutlinedIcon
                                  style={{
                                    fill: isSelected
                                      ? '#FFFFFF'
                                      : isDisabled
                                      ? '#00000099'
                                      : '#000000DE',
                                  }}
                                />
                              </Tooltip>
                            </Stack>
                            <Stack width={'100%'}>
                              <Typography
                                color={isSelected ? '#FFFFFFB2' : '#00000099'}
                                textAlign={'start'}
                                fontWeight={500}
                                fontSize={'14px'}
                              >
                                {available} remaining seats
                              </Typography>
                            </Stack>
                          </ButtonBase>
                        </Stack>
                      ) : (
                        <></>
                      );
                    })}
                </Stack>
                <Button
                  onClick={() => {
                    history.push('/admin/billing/subscriptions');
                  }}
                  sx={{ padding: '8px', width: 'fit-content' }}
                  color="secondary"
                >
                  Add more licences
                </Button>
                {plan.categories?.length && licencedValues[key + 'Type'] && (
                  <Stack marginTop={'8px'} gap={'8px'}>
                    <Stack direction={'row'}>
                      <Stack width={'40px'}>
                        <LockIcon style={{ fill: '#d1d1d1' }} />
                      </Stack>
                      <Stack>
                        <Typography color={'#000000DE'} fontWeight={500} fontSize={'16px'}>
                          Learning content access:
                        </Typography>
                        <Typography color={'#CF0175'} fontWeight={400} fontSize={'16px'}>
                          Select the content this user can access.
                        </Typography>
                      </Stack>
                    </Stack>

                    {plan.categories?.map((category) => {
                      return (
                        <Stack direction={'row'}>
                          <Stack width={'40px'} />
                          <Form.Field.Switch
                            name={category.id}
                            helperText={category.description}
                            label={category.name}
                            sx={{ ...customRadioButton, marginTop: '0', width: '100%' }}
                            checked={licencedValues.lmsCategories?.[category.id]}
                            onChange={(val) =>
                              setLicencedValues((prevValue) => {
                                return {
                                  ...prevValue,
                                  lmsCategories: {
                                    ...licencedValues.lmsCategories,
                                    [category.id]: val,
                                  },
                                };
                              })
                            }
                          />
                        </Stack>
                      );
                    })}
                  </Stack>
                )}
              </Stack>
              <Spacer y={2.5} />
            </Fragment>
          );
        })}

      <Form.Field.Switch
        name="inviteEmail"
        helperText="Send an email to your user to activate their account now"
        label="Send invitation email"
        sx={customRadioButton}
        checked={licencedValues?.inviteEmail}
        onChange={(val) => {
          setLicencedValues({
            ...licencedValues,
            inviteEmail: val,
          });
        }}
      />

      <Spacer y={4} />

      {isAllLicencesUsed > 0 && (
        <Alert severity="warning" sx={{ marginTop: '20px', '& svg': { color: '#0000008A' } }}>
          <Typography fontSize={'14px'} fontWeight={500} color={'#ED6C02'}>
            {isAllLicencesUsed}/{isAllLicencesUsed} licences used.{' '}
            <Button
              sx={{ padding: '0 !important' }}
              color={'secondary'}
              onClick={() => history.push('/admin/billing/subscriptions')}
            >
              Add more licences
            </Button>{' '}
            to grow your team.
          </Typography>
        </Alert>
      )}

      {!['lmsType', 'outreachType'].some((key) => licencedValues[key]) && (
        <Alert severity="warning" sx={{ marginTop: '20px', '& svg': { color: '#0000008A' } }}>
          No subscription is assigned. The user will be saved as inactive.
        </Alert>
      )}
    </Stack>
  );
};

const customRadioButton = {
  fieldset: {
    width: '100%',
  },
  '& .MuiFormGroup-root': {
    label: {
      width: '100%',
      flexDirection: 'row-reverse',
      margin: '0 !important',
      justifyContent: 'space-between',
      '& > span': {
        color: '#000000DE',
        fontWeight: '600',
        fontSize: '18px',
      },
    },
    '& .MuiFormHelperText-root': {
      fontWeight: '500 !important',
      fontSize: '14px !important',
      margin: '0 !important',
      color: '#00000099',
    },
  },
};
export default UserSubscriptionsForm;
