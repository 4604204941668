import React, { useEffect, useState } from 'react';

import MyProfileView from './myProfileView';
import { PageTitle } from 'src/components/App';

export const MyProfileContainer = ({ ...props }) => {
  return (
    <>
      <PageTitle title="My Profile" />
      <MyProfileView {...props} />
    </>
  );
};

export default MyProfileContainer;
