import React from 'react';
import { connect } from 'react-redux';
import { PageTitle } from 'src/components/App';
import CreateCourse from '../../components/CreateCourse';
import { withCourseStore, useCourse } from '../../utils/CourseStore';

export const CourseListingContainer = (props) => {
  return (
    <>
      <PageTitle title="Create Course" />
      <CreateCourse {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCourseStore(CourseListingContainer));
