import React, { useEffect } from 'react';
import { Box, Container, CircularProgress } from '@mui/material';
import { Modal } from 'src/components/shared';
import { Empty } from 'src/components/App';
import { AutomationCard, AutomationForm } from '../../components/Automations';
import { data } from './data';
import { useState } from 'react';

const ResellersAutomationView = ({
  automations,
  showAutomationForm,
  setShowAutomationForm,
  closeModal,
  fetchAutomations,
  loading,
  ...props
}) => {
  const { filters, paging, data } = automations;
  const [dataToUpdate, setDataToUpdate] = useState({});

  useEffect(() => {
    fetchData({}, {});
  }, []);

  const closeAutomationModal = () => {
    setDataToUpdate({});
    closeModal();
  };

  const fetchData = (filers, paging) => {
    fetchAutomations(filers, paging);
  };

  if (loading?.automations && loading?.automations === true) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
        <CircularProgress color="secondary" size={32} />
      </Box>
    );
  }
  return (
    <Container maxWidth="lg">
      <Box mt={2}>
        {data && data?.length ? (
          <>
            {data.map((item, index) => {
              return (
                <AutomationCard
                  data={item}
                  key={`automation-item-${index}`}
                  updateAutomation={() => {
                    setDataToUpdate(item);
                    setShowAutomationForm(true);
                  }}
                  deleteAutomation={props.deleteAutomation}
                  putAutomation={props.putAutomation}
                />
              );
            })}
          </>
        ) : (
          <Empty
            title="No automations found"
            description="Click here to add create your first automation."
            buttonTitle="Create Automation"
            buttonProps={{
              variant: 'outlined',
              size: 'small',
            }}
            onClick={() => {
              setShowAutomationForm(true);
            }}
          />
        )}

        <Modal open={showAutomationForm} onClose={closeAutomationModal} size="lg">
          <AutomationForm
            closeModal={closeAutomationModal}
            dataToUpdate={dataToUpdate}
            postAutomation={props.postAutomation}
            putAutomation={props.putAutomation}
          />
        </Modal>
      </Box>
    </Container>
  );
};

export default ResellersAutomationView;
