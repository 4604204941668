import ResellersAutomation from '../container/resellersAutomation';
import AutomationsContainer from '../container/resellersAutomation/AutomationsContainer';
import ResellersReferral from '../container/resellersAutomation/ResellersReferral';

export default [
  {
    title: 'Resellers',
    component: ResellersAutomation,
    url: '/admin/resellers/dashboard',
    exact: true,
    auth: true,
  },
  {
    title: 'Referral Management',
    component: ResellersReferral,
    url: '/admin/resellers/referral',
    exact: true,
    auth: true,
  },
  {
    title: 'Automations',
    component: AutomationsContainer,
    url: '/admin/resellers/automation',
    exact: true,
    auth: true,
  },
  {
    title: 'Resellers',
    component: ResellersAutomation,
    url: '/admin/resellers/dashboard/tenant/:tenantId',
    exact: true,
    auth: true,
  },
  {
    title: 'Resellers',
    component: ResellersAutomation,
    url: '/admin/resellers/dashboard/tenant/:tenantId/user/:userId',
    exact: true,
    auth: true,
  },
];
