import { makeStyles } from '@mui/styles';
import previewImage from '../../../../img/previewbackground.png';
const useStyles = makeStyles(({ palette }) => ({
  Preview: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '58%',
    height: 'fit-content',
    background: 'linear-gradient(120deg,#000 -4.89%,#151f2e 88.85%,#232c38 88.86%), #fff',
    borderRadius: '10px',
    '&:focus-visible ': {
      outline: 'none !important',
    },
  },

  close: {
    color: 'rgba(255, 255, 255, 0.54)',
    width: '4%',
    paddingTop: '1%',
  },
  subHeader: {
    fontSize: '14px',
    color: 'rgba(191, 191, 191, 1)',
    '& span': {
      color: '#c0c0c0',
    },
  },
  backtoprogressheader: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: '300',
    marginBottom: '20px',
    color: '#FFF',
    lineHeight: '116.7%',
  },
  backdropbtn: {
    fontSize: 'medium',
    marginRight: '5px',
    marginTop: '2px',
  },
  dialogLinear: {
    '& .MuiDialog-paper': {
      borderRadius: '10px',
      background: 'linear-gradient(120deg,#000 -4.89%,#151f2e 88.85%,#232c38 88.86%), #fff',
      /* 
      background: linear-gradient(104.75deg, #000000 -5.01%, #132b4e 134.12%),
        linear-gradient(0deg, #ffffff, #ffffff); */
    },
  },
  header: {
    fontSize: '40px',
    color: '#FFFFFF',
  },
  header1: {
    color: '#0FE',

    fontFamily: 'Inter',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '40px',
  },
  descriptionBox: {
    backgroundColor: '#082035',
    padding: '20px',
    borderRadius: '4px',
  },
  descriptionBoxOne: {
    backgroundColor: '#082035',
    padding: '20px',
    borderRadius: '4px',
    maxHeight: '500px',
    overflow: 'auto',
  },
  description: {
    fontSize: '16px',
    color: '#BEBEBE',
    maxHeight: '500px',
    overflow: 'auto',
  },
  title: {
    fontSize: '18px',
    color: '#C0C0C0',
  },
  score: {
    fontSize: '28px',
    fontWeight: 500,
    color: '#FFFFFF',
  },
  total: {
    fontSize: '28px',
    fontWeight: 500,
    color: '#944AF1',
  },
  scoreBox: {
    backgroundColor: '#072947',
    height: '70%',
    borderRadius: '4px',
    padding: '20px',
  },
  scoreGraph: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    marginTop: '10%',
    width: '100%',
    borderRadius: '4px',
    // border: '1px solid #CF0175',
    fontSize: '18px',
    fontWeight: '700',
    backgroundColor: '#ffd47e',
    color: '#050e16',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#ffd47e',
      color: '#050e16',
    },
  },
  buttoncompetition: {
    marginTop: '10%',
    width: '100%',
    borderRadius: '4px',
    // border: '1px solid #CF0175',
    fontSize: '18px',
    fontWeight: '700',
    backgroundColor: '#0C3535',
    color: 'white',
    textTransform: 'none',
    border: '1px solid rgba(255, 255, 255, 0.50)',
    '&:hover': {
      backgroundColor: '#0C3535',
      color: 'white',
    },
  },
  hideButton: {
    marginTop: '10%',
    width: '100%',
    borderRadius: '4px',
    // border: '1px solid #CF0175',
    fontSize: '18px',
    fontWeight: '700',
    backgroundColor: '#ffd47e',
    color: '#050e16',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#ffd47e',
      color: '#050e16',
    },
  },
  buttoncompetitionhide: {
    marginTop: '10%',
    width: '100%',
    borderRadius: '4px',
    // border: '1px solid #CF0175',
    fontSize: '18px',
    fontWeight: '700',
    backgroundColor: '#0C3535',
    color: 'white',
    textTransform: 'none',
    border: '1px solid rgba(255, 255, 255, 0.50)',
    '&:hover': {
      backgroundColor: '#0C3535',
      color: 'white',
    },
  },
}));
export default useStyles;
