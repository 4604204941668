import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Box, Stack } from '@mui/material';
import { Modal, Form, Button } from 'src/components/shared';
import { useCustomVariables } from '../../utils/customVariableStore';

import { fetchTeamLookup } from 'src/modules/users/api/usersApi';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';

const editorProps = {
  showFontStyle: false,
  showFontEditor: false,
  showAlignProps: false,
  showListProps: false,
  showAttachments: false,
};

function CustomVariableForm({ variable, open, handleClose }) {
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);

  const [loading, setLoading] = useState(false);
  const { post, put } = useCustomVariables();

  const sequenceAccessTypeList = useSelector((state) => state.app.globals.sequenceAccessTypeList);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    setUsers([{ ...user, name: `${user.fname} ${user.lname}` }]);
  }, [user]);

  console.log('variable : ', variable);

  const handleSubmit = async (data) => {
    try {
      data.sharedWithUsers = data.sharedWithUsers.map((k) => k.id);
      data.sharedWithTeams = data.sharedWithTeams.map((k) => k.id);
      setLoading(true);
      if (variable && variable?.id) {
        await put(variable?.id, data);
      } else {
        await post(data);
      }
      setLoading(false);
      handleClose();
    } catch (error) {}
  };

  return (
    <Modal
      open={open}
      title={variable?.id ? 'Edit Custom Variable' : 'Add Custom Variable'}
      onClose={handleClose}
      size="xs"
    >
      <Form
        initialValues={{
          name: variable?.name || '',
          value: variable?.value || '',
          type: 'custom',
          accessType: variable?.accessType || 'private',
          sharedWithUsers: variable?.sharedWithUsers || users,
          sharedWithTeams: variable?.sharedWithTeams || teams,
        }}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(16, 'Title must be less than 16 characters')
            .required('Please enter name!')
            .matches(/^[aA-zZ0-9\s]+$/, 'Only alphabets are allowed for this field '),
          value: Yup.string().required('Please enter content!'),
          accessType: Yup.string().required('Select who has access to this variable.'),
          sharedWithUsers: Yup.array().test({
            message: () => 'Please choose at least one user.',
            test: (value, values) => {
              const accessType = values.parent.accessType;
              if (accessType === 'private') {
                return value.length > 0;
              }
              return true;
            },
          }),
          sharedWithTeams: Yup.array().test({
            message: () => 'Please choose at least one team.',
            test: (value, values) => {
              const accessType = values.parent.accessType;
              if (accessType === 'team') {
                return value.length > 0;
              }
              return true;
            },
          }),
        })}
      >
        {({ values, ...formProps }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formProps.submitForm();
                return false;
              }}
              noValidate
            >
              <Form.Field.Input name="name" label="Variable Name" fullWidth variant="outlined" />

              <Form.Field.TextEditor name="value" {...editorProps} />

              <Form.Field.Select
                label="Permission"
                fullWidth
                variant="outlined"
                options={sequenceAccessTypeList}
                name="accessType"
                onChange={(val) => {
                  switch (val) {
                    case 'private':
                      formProps.setFieldValue('sharedWithUsers', []);
                      break;
                    case 'team':
                      formProps.setFieldValue('sharedWithTeams', []);
                      break;
                    case 'organisation':
                    default:
                      formProps.setFieldValue('sharedWithUsers', []);
                      formProps.setFieldValue('sharedWithTeams', []);
                      break;
                  }
                }}
              />

              {values.accessType === 'private' ? (
                <Form.Field.AutoComplete
                  multiple={true}
                  options={values?.sharedWithUsers.length > 0 ? values?.sharedWithUsers : users}
                  fullWidth
                  variant="outlined"
                  name="sharedWithUsers"
                  label="Select users"
                  checkboxes={true}
                  remoteMethod={(val) => {
                    return fetchUsersLookup(val);
                  }}
                  optLabel="name"
                  optValue="id"
                />
              ) : null}

              {values.accessType === 'team' ? (
                <Form.Field.AutoComplete
                  multiple={true}
                  options={values?.sharedWithTeams}
                  fullWidth
                  variant="outlined"
                  name="sharedWithTeams"
                  checkboxes={true}
                  label="Select Teams"
                  remoteMethod={(val) => {
                    return fetchTeamLookup(val);
                  }}
                  optLabel="name"
                  optValue="id"
                />
              ) : null}
              <Stack direction="row" spacing={2} justifyContent="flex-end" mt={1}>
                <Button variant="outlined" color="secondary" disabled={loading}>
                  Cancel
                </Button>
                <Button variant="contained" type="submit" color="secondary" loading={loading}>
                  Save
                </Button>
              </Stack>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
}

export default CustomVariableForm;
