import { styled } from '@mui/system';
import { Paper } from '@mui/material';

export const ActivitiesWrapper = styled(Paper)(({ theme }) => ({
  // padding: 16,
}));

export const ActivityItemWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 16,
  gap: 12,
  borderBottom: '2px solid #e3e3e3',
}));

export const ActivityDisplayNameContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 16,
  justifyContent: 'space-between',
  '& .title': {
    fontSize: 14,
  },
  '& .createdAt': {
    fontSize: 12,
    color: 'rgba(0,0,0,0.54)',
  },
}));

export const ActivityType = styled('div')(({ theme }) => ({
  display: 'flex',
  fontSize: 14,
  color: '#80ABCD',
  alignItems: 'center',
  gap: 6,
}));

export const ActivityDeatil = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: 14,
  color: 'rgba(0, 0, 0, 0.60)',
  gap: 8,
  display: '-webkit-box',
}));

export const ActivityIcon = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#E3F2FD',
  width: 22,
  height: 18,
  borderRadius: 4,
  marginRight: 6,
  position: 'relative',
  '&::before': {
    display: 'block',
    content: '""',
    width: 8,
    height: 13,
    background: '#E3F2FD',
    position: 'absolute',
    left: '-3px',
    zIndex: 0,
    transform: 'translate(-50%, -50%)',
    borderRadius: '6px 0 0 6px',
    top: 8,
    borderRight: '2px solid #ffffff',
  },
  '&::after': {
    display: 'block',
    content: '""',
    width: 8,
    height: 13,
    background: '#E3F2FD',
    position: 'absolute',
    right: '-12px',
    zIndex: 0,
    transform: 'translate(-50%, -50%)',
    borderRadius: '0 6px 6px 0',
    top: 8,
    borderLeft: '2px solid #ffffff',
  },
}));

export const ActivityIconWrapper = styled('div')(({ theme }) => ({
  width: 44,
  height: 44,
  marginRight: 20,
  marginLeft: 4,
  marginTop: 4,
}));
