import { isEmpty } from 'lodash';
import api from 'src/api';
import { convertObjectToQuerystring } from 'src/utils/helper';

export const getInvoices = (filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`invoices${filter}`, null, 'get');
};

export const getInvoice = (id) => {
  return api(`invoice/${id}`, null, 'get');
};

export const payInvoice = (id) => {
  return api(`/billing/customer/paynow`, null, 'get');
};

export const fetchCustomer = (chargeBeeId) => {
  return api(`billing/customer/${chargeBeeId}`, null, 'get');
};

export const putCustomer = (chargeBeeId, data) => {
  return api(`billing/customer/billingaddress`, data, 'put');
};

export const fetchAllSubscriptions = () => {
  return api(`billing/subscriptions`, null, 'get');
};

export const fetchSubscriptions = (chargeBeeId) => {
  return api(`billing/customer/subscriptions/${chargeBeeId}`, null, 'get');
};

export const checkout = (payload, subscriptionId) => {
  let urlStr = 'new';
  if (subscriptionId && subscriptionId !== '' && typeof subscriptionId !== 'undefined') {
    urlStr = subscriptionId;
  }
  return api(`billing/checkout/${urlStr}`, payload, 'post');
};

export const checkoutCharge = (payload) => {
  return api(`billing/checkout/charge`, payload, 'post');
};
