import React from 'react';
import styled from 'styled-components';
import darkTheme from 'src/config/darkTheme';
import { fill, uniqueId } from 'lodash';

function ProgressBar({ total, completed, completedIndex = [] }) {
  const key = uniqueId('progress-bar');
  const arr = fill(Array(total), null);

  return (
    <Wrapper total={total}>
      {arr.map((x, i) => (
        <Item
          key={`${key}-${i}`}
          active={completedIndex.length ? completedIndex.includes(i) : i < completed}
        />
      ))}
    </Wrapper>
  );
}

export default ProgressBar;

const Wrapper = styled.div`
  display: flex;
  gap: 6px;
  padding: 16px;
  align-items: center;
  & > div {
    width: ${(props) => `calc(100% / ${props.total})`};
  }
`;

const Item = styled.div`
  background-color: ${(props) =>
    props.active ? darkTheme.palette.secondary.main : 'rgba(255, 212, 126, 0.30)'};
  height: 2px;
  border-radius: 2px;
`;
