import React, { useEffect, useState } from 'react';
import {
  ListItemIcon,
  ListItemSecondaryAction,
  Box,
  Typography,
  FormHelperText,
  Link,
} from '@mui/material';

import { Button } from 'src/components/shared';
import { ConfirmDialog } from 'src/components/App';
import { FileItem, Progress } from './styles';

import { fileUpload, deleteFile } from 'src/modules/app/api/appApis';
import { bytesToSize } from 'src/utils/helper';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';

import toast from 'src/utils/toast';

function File({ file, id, ...props }) {
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onProgress = (event) => {
    setProgress(Math.round((100 * event.loaded) / event.total));
  };

  useEffect(() => {
    if (!file.id) {
      setIsUploading(true);
      fileUpload(id, file, onProgress)
        .then((res) => {
          props.onUpload(file, res);
          setIsUploading(false);
        })
        .catch((err) => {
          setIsUploading(false);
        });
    }
  }, [file]);

  const toggleDeleteModal = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const handleDialogSubmit = async () => {
    setIsDeleting(true);
    await deleteFile(file.id);
    setIsDeleting(false);
    props.onDelete(file.id);
  };

  return (
    <>
      <FileItem loading={isUploading}>
        <ListItemIcon style={{ minWidth: '40px' }}>
          <InsertDriveFileIcon />
        </ListItemIcon>
        <Box>
          {file?.url ? (
            <Link href={file.url} target="_blank" color="secondary">
              <Typography variant="body2">
                {file?.originalFilename ? file?.originalFilename : file?.name || ''}
              </Typography>
            </Link>
          ) : (
            <Typography variant="body2">
              {file?.originalFilename ? file?.originalFilename : file?.name || ''}
            </Typography>
          )}

          <FormHelperText>{bytesToSize(file?.size)}</FormHelperText>
        </Box>
        {file.id && (
          <ListItemSecondaryAction>
            <Button
              iconButton={true}
              edge="end"
              aria-label="Delete file"
              loading={isDeleting}
              onClick={toggleDeleteModal}
            >
              <DeleteIcon />
            </Button>
          </ListItemSecondaryAction>
        )}
      </FileItem>
      {isUploading && <Progress value={progress} />}

      <ConfirmDialog
        title="Delete file"
        open={isDialogOpen}
        onClose={() => {
          toggleDeleteModal();
        }}
        onConfirm={handleDialogSubmit}
      >
        <Typography variant="body1" color="textSecondary">
          Are you sure you want to delete{' '}
          {file?.originalFilename ? file?.originalFilename : file.name}
        </Typography>
      </ConfirmDialog>
    </>
  );
}

export default File;
