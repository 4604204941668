import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { AppGrid } from 'src/components/App';
import { useDashboardStore } from '../../../utils/dashboardStore';
import { ButtonBase, Typography } from '@mui/material';

function SequenceGrid() {
  const { fetchSequenceStats, sequenceStats, loading, filters } = useDashboardStore();
  const { data, paging, sort } = sequenceStats;

  const columns = useMemo(
    () => [
      {
        name: 'name',
        label: 'Sequence Name',
        options: {
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            const row = data[dataIndex];
            return (
              <ButtonBase
                onClick={() => {
                  window.open(`/sequence/${row.id}`, '_blank');
                }}
                sx={{ maxWidth: '250px', whiteSpace: 'break-spaces' }}
              >
                <Typography color="secondary" variant="body2">
                  {row?.name || 0}
                </Typography>
              </ButtonBase>
            );
          },
        },
      },
      {
        name: 'prospectsEnrolled',
        label: 'Prospects Enrolled',
        options: {
          sort: false,
          setCellHeaderProps: (value) => ({
            style: { textAlign: 'center' },
          }),
          setCellProps: () => ({
            style: { textAlign: 'center' },
          }),
        },
      },
      {
        name: 'prospectsContacted',
        label: 'Enrolled Contacted',
        options: {
          sort: false,
          setCellHeaderProps: (value) => ({
            style: { textAlign: 'center' },
          }),
          setCellProps: () => ({
            style: { textAlign: 'center' },
          }),
        },
      },
      {
        name: 'prospectsOpened',
        label: 'Prospects Opened',
        options: {
          sort: false,
          setCellHeaderProps: (value) => ({
            style: { textAlign: 'center' },
          }),
          setCellProps: () => ({
            style: { textAlign: 'center' },
          }),
        },
      },
      {
        name: 'prospectsClicked',
        label: 'Prospects Clicked',
        options: {
          sort: false,
          setCellHeaderProps: (value) => ({
            style: { textAlign: 'center' },
          }),
          setCellProps: () => ({
            style: { textAlign: 'center' },
          }),
        },
      },
      {
        name: 'prospectsReplied',
        label: 'Prospects Replied',
        options: {
          sort: false,
          setCellHeaderProps: (value) => ({
            style: { textAlign: 'center' },
          }),
          setCellProps: () => ({
            style: { textAlign: 'center' },
          }),
        },
      },
      {
        name: 'prospectsInterested',
        label: 'Prospects Interested',
        options: {
          sort: false,
          setCellHeaderProps: (value) => ({
            style: { textAlign: 'center' },
          }),
          setCellProps: () => ({
            style: { textAlign: 'center' },
          }),
        },
      },
    ],
    [data],
  );

  const onSort = (sort) => {
    fetchSequenceStats(paging, sort);
  };

  const handleTableChange = async ({ pageNo, perPage }) => {
    try {
      fetchSequenceStats(
        {
          pageNo,
          perPage,
        },
        sort,
      );
    } catch (error) {}
  };

  useEffect(() => {
    fetchSequenceStats(paging, sort);
  }, [filters]);

  return (
    <AppGrid
      skeletonLoading={true}
      onSort={onSort}
      columns={columns.map((col, index) => ({
        ...col,
        options: columns?.columns?.length
          ? { ...col.options, ...columns?.columns[index] }
          : col.options,
      }))}
      onTableChange={handleTableChange}
      data={data}
      loading={loading.sequenceStats}
      options={{
        sortOrder: sort,
        serverSide: true,
        pagination: true,
        page: paging.pageNo,
        rowsPerPage: paging.perPage,
        count: paging.count,
      }}
    />
  );
}

export default SequenceGrid;
