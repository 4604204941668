import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Paper, Box } from '@mui/material';

import SequenceNavBar from './SequenceNavBar';
import SequenceSteps from './SequenceSteps';
import SettingsForm from './SettingsForm';
import PublishSequence from './PublishSequence';
import { OnboardingContainer, OnboardingContent, OnboardingContentWrapper } from './styles';

import { getSequenceFields } from 'src/modules/app/actions/appSelector';
import {
  postSequenceEvent,
  putSequenceEvent,
  deleteSequenceEvent,
  handleEventsReorder,
  putSequence,
} from '../../actions/sequenceActions';

const SequenceOnboarding = ({ sequence, onClose, sequenceEvents, fields, ...props }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const publishTask = async () => {
    setLoading(true);
    await props.putSequence(sequence.id, {
      status: 'published',
    });
    setLoading(false);
    onClose();
  };

  const moveToNext = () => {
    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    if (activeStep > 2) {
      onClose();
    }
  }, [activeStep]);

  return (
    <OnboardingContainer>
      <SequenceNavBar
        onClose={onClose}
        sequence={sequence}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
      <OnboardingContentWrapper>
        <OnboardingContent maxWidth="md">
          {activeStep == 0 ? (
            <SequenceSteps sequence={sequence} sequenceEvents={sequenceEvents} new {...props} />
          ) : null}

          {activeStep == 1 ? (
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={9}>
                <Paper>
                  <Box p={3} mt={2}>
                    <SettingsForm
                      sequence={sequence}
                      sequenceEvents={sequenceEvents}
                      fields={fields}
                      moveToNext={moveToNext}
                      isOnboarding={true}
                      {...props}
                    />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          ) : null}

          {activeStep == 2 ? (
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={8}>
                <PublishSequence
                  steps={sequenceEvents.length}
                  events={sequenceEvents}
                  loading={loading}
                  days={0}
                  onPublish={publishTask}
                />
              </Grid>
            </Grid>
          ) : null}
        </OnboardingContent>
      </OnboardingContentWrapper>
    </OnboardingContainer>
  );
};

const mapStateToProps = (state) => ({
  integrations: state.admin.admin.integrations,
  loading: state.sequence.loading.sequence,
  sequence: state.sequence.sequence,
  sequenceEvents: state.sequence.sequenceEvents,
  fields: getSequenceFields(state),
  sequenceAccessTypeList: state.app.globals.sequenceAccessTypeList,
  sequenceEmailTypeList: state.app.globals.sequenceEmailTypeList,
});

const mapDispatchToProps = (dispatch) => ({
  putSequence: (sequenceId, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(putSequence(sequenceId, payload, resolve, reject));
    });
  },
  postSequenceEvent: (sequenceId, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(postSequenceEvent(sequenceId, payload, resolve, reject));
    });
  },
  putSequenceEvent: (sequenceId, stepId, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(putSequenceEvent(sequenceId, stepId, payload, resolve, reject));
    });
  },
  deleteSequenceEvent: (sequenceId, stepId) => {
    return new Promise((resolve, reject) => {
      dispatch(deleteSequenceEvent(sequenceId, stepId, resolve, reject));
    });
  },
  handleEventsReorder: (sequenceId, stepId, eventOrder) =>
    dispatch(handleEventsReorder(sequenceId, stepId, eventOrder)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SequenceOnboarding);
