import React, { useEffect, useState } from 'react';
import { Collapse, Box, Typography, Chip } from '@mui/material';
import { isArray, isEmpty } from 'lodash';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import FilterAutoCompleteSelect from './FilterAutoCompleteSelect';
import FilterDatePickers from './DatePicker';
import { dateFormat } from 'src/config';

import useStyles from './style';

const DrawerFilter = ({
  myFilterData,
  handleChange,
  myFilter,
  defaultUnSetFilters = false,
}) => {
  const classes = useStyles();

  const [collapseIn, setCollapseIn] = useState({});
  const [selectedRecord, setSelectedRecord] = useState({});

  useEffect(() => {
    if (isEmpty(myFilter)) {
      setSelectedRecord({});
    } else {
      // selectedRecord[filter.type]
      myFilterData.forEach((filter) => {
        if (defaultUnSetFilters && !myFilter[filter.type]) {
          delete selectedRecord[filter.type];
        }
        if (myFilter[filter.type]?.filter) {
          selectedRecord[filter.type] = filter.isSingle
            ? myFilter[filter.type]?.filter[0]
            : myFilter[filter.type]?.filter;
        }
        if (myFilter[filter.type]?.data) {
          selectedRecord[filter.type] = {
            data: myFilter[filter.type].data,
          };
        }
      });

      setSelectedRecord({
        ...selectedRecord,
      });
    }
  }, [myFilter]);

  const handleDropDownChange = (filter) => (event, tag) => {
    if (filter.isSingle) {
      setSelectedRecord({
        ...selectedRecord,
        [filter.type]: tag,
      });
      if (filter.control) {
        handleChange({
          name: filter.type || filter.name,
          filter: [tag],
          control: filter.control,
        });
      } else {
        handleChange({
          name: filter.type || filter.name,
          filter: [tag],
        });
      }
    } else {
      if (myFilter[filter.type] && myFilter[filter.type].filter) {
        setSelectedRecord({
          ...selectedRecord,
          [filter.type]: [...myFilter[filter.type].filter, tag],
        });
        return handleChange({
          name: filter.type || filter.name,
          filter: [...myFilter[filter.type].filter, tag],
        });
      } else {
        setSelectedRecord({
          ...selectedRecord,
          [filter.type]: tag,
        });
        return handleChange({
          name: filter.type || filter.name,
          filter: [tag],
        });
      }
    }
  };

  const handleDatePicker = (filter) => (data) => {
    handleChange({
      ...data,
      type: filter.type,
      ...filter,
    });
  };

  const deleteSelectedFilter = (filter, filterData) => () => {
    const oldFilterData = selectedRecord[filter.type];

    if (filter.control == 'dateRangePicker') {
      delete selectedRecord[filter.type];

      setSelectedRecord({
        ...selectedRecord,
      });
      return handleChange({
        name: filter.type || filter.name,
        data: {},
      });
    }

    if (isArray(oldFilterData)) {
      setSelectedRecord({
        ...selectedRecord,
        [filter.type]: oldFilterData.filter((e) => e.id !== filterData.id),
      });

      handleChange({
        name: filter.type || filter.name,
        filter: oldFilterData.filter((e) => e.id !== filterData.id),
      });
    } else {
      setSelectedRecord({
        ...selectedRecord,
        [filter.type]: [],
      });

      handleChange({
        name: filter.type || filter.name,
        filter: [],
      });
    }
  };

  const createChipLabel = (mFilter, selectedRecord) => {
    let label = '';
    if (!selectedRecord[mFilter.type]) {
      return;
    }
    if (
      mFilter.control === 'dateRangePicker' &&
      selectedRecord[mFilter.type]?.data[mFilter.from]
    ) {
      label = `${moment(
        selectedRecord[mFilter.type].data[mFilter.from],
        'YYYY-MM-DD',
      ).format(dateFormat)} to ${moment(
        selectedRecord[mFilter.type].data[mFilter.to],
        'YYYY-MM-DD',
      ).format(dateFormat)}`;
    } else {
      label = selectedRecord[mFilter.type].name;
    }

    return label;
  };

  return (
    <>
      {myFilterData.map((mFilter) => (
        <Box className={classes.root} key={`my-filter-${mFilter.type}`}>
          <Box
            style={
              collapseIn[mFilter.type]
                ? { borderBottom: 'none' }
                : { borderBottom: 'none' }
            }
          >
            <Box
              display={'flex'}
              className={classes.filterTitle}
              onClick={() => {
                setCollapseIn({ [mFilter.type]: !collapseIn[mFilter.type] });
              }}
            >
              <Typography variant="body1">{mFilter.title}</Typography>
              {collapseIn[mFilter.type] ? (
                <RemoveIcon className={''} />
              ) : (
                <AddIcon className={''} />
              )}
            </Box>
            {!mFilter.isSingle ? (
              <Box>
                {selectedRecord[mFilter.type] &&
                  (!isArray(selectedRecord[mFilter.type]) ? (
                    <Chip
                      label={createChipLabel(mFilter, selectedRecord)}
                      onDelete={deleteSelectedFilter(
                        mFilter,
                        selectedRecord[mFilter.type],
                      )}
                      className={classes.chip}
                      color="primary"
                      size="small"
                    />
                  ) : (
                    selectedRecord[mFilter.type].map((fType, fTypeIndex) => (
                      <Chip
                        key={`fType-${fType.name}-${fTypeIndex}`}
                        label={fType.name}
                        onDelete={deleteSelectedFilter(mFilter, fType)}
                        className={classes.chip}
                        color="primary"
                        size="small"
                      />
                    ))
                  ))}
              </Box>
            ) : (
              mFilter.control &&
              selectedRecord[mFilter.type] && (
                <Box>
                  <Chip
                    label={createChipLabel(mFilter, selectedRecord)}
                    onDelete={deleteSelectedFilter(
                      mFilter,
                      selectedRecord[mFilter.type],
                    )}
                    className={classes.chip}
                    color="primary"
                    size="small"
                  />
                </Box>
              )
            )}
          </Box>
          <Collapse
            in={collapseIn[mFilter.type]}
            timeout="auto"
            unmountOnExit
            className={classes.collapseWrapper}
          >
            {mFilter.control === 'dateRangePicker' ? (
              <FilterDatePickers
                onChange={handleDatePicker(mFilter)}
                selectedDates={{
                  ...(selectedRecord[mFilter.type]?.data || {}),
                }}
                from={mFilter.from}
                to={mFilter.to}
              />
            ) : (
              <Box className={classes.autoCompleteWrapper}>
                <FilterAutoCompleteSelect
                  handleDropDownChange={handleDropDownChange}
                  mFilter={mFilter}
                  selectedRecord={selectedRecord}
                />
              </Box>
            )}
          </Collapse>
        </Box>
      ))}
    </>
  );
};

export default DrawerFilter;
