import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { PageTitle } from 'src/components/App';
import { fetchReportSummary, fetchActivityReport } from '../../actions/appActions';
import ActiveReportView from './ActiveReportView';

export const ActiveReportContainer = (props) => {
  useEffect(() => {
    props.fetchReportSummary(props.reportsSummary.filter);
  }, []);

  return (
    <>
      <PageTitle title="Activity Reports" />
      <ActiveReportView {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({
  reportsSummary: state.reports.reportsSummary,
  activityReport: state.reports.activityReport,
  loading: state.reports.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchReportSummary: (filter) => dispatch(fetchReportSummary(filter)),
  fetchActivityReport: (paging, filters, sort) =>
    dispatch(fetchActivityReport(paging, filters, sort)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveReportContainer);
