import { RESET_STORE } from 'src/modules/app/actions/appTypes';
import * as noteTypes from '../actions/noteTypes';
const initialState = {
  loading: {
    sessionsList: false,
  },
  sessionsList: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    sort: {},
    filters: {},
    data: [],
    columns: [],
    columnsOrder: [],
  },
};
const NoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case noteTypes.FETCH_SESSIONS_LIST:
      return {
        ...state,
        loading: {
          ...state.loading,
          sessionsList: true,
        },
        sessionsList: {
          ...state.sessionsList,
          paging: {
            ...state.sessionsList.paging,
            ...action.paging,
          },
          data: [],
          filters: {
            ...state.sessionsList.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };
    case noteTypes.SET_SESSIONS_LIST:
      return {
        ...state,
        loading: {
          ...state.loading,
          sessionsList: false,
        },
        sessionsList: {
          ...state.sessionsList,
          data: action.data.session,
          paging: {
            ...state.sessionsList.paging,
            count: action.data.total?.value,
          },
        },
      };
    default:
      return state;
  }
};
export default NoteReducer;
