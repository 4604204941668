import React, { useState, useEffect, useMemo } from 'react';
import { Chip, Box } from '@mui/material';
import { Form, Menu } from 'src/components/shared';
import { capitalizeFirstLetter } from 'src/utils/helper';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export function useColumns(data, putLookupList, openDeleteModal, openEditModal) {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (data && data.length) {
      setColumns([
        {
          label: 'Status',
          name: 'id',
          options: {
            sort: false,
            setCellHeaderProps: (value) => ({
              style: { width: 64 },
            }),
            setCellProps: () => ({
              style: { width: 64 },
            }),
            customBodyRenderLite: (index) => {
              const option = data[index];
              return (
                <Box justifyContent="center" display="flex">
                  <Form
                    initialValues={{
                      enabled: option?.enabled || false,
                    }}
                    enableReinitialize={true}
                  >
                    <Box display="flex" justifyContent="flex-end">
                      {/* <Form.Field.Checkbox
                        name="enabled"
                        color="success"
                        onChange={(val) => {
                          console.log('val : 101 : ', val);
                          putLookupList(option.id, {
                            enabled: val,
                          });
                        }}
                      /> */}
                      <Form.Field.Checkbox
                        name={'enabled'}
                        label=" "
                        onChange={(val) => {
                          console.log('val : 101 : ', val);
                          putLookupList(option.id, {
                            enabled: val,
                          });
                        }}
                        color="secondary"
                      />
                    </Box>
                  </Form>
                </Box>
              );
            },
          },
        },
        {
          label: 'Title',
          name: 'label',
          options: {
            filter: false,
            sort: false,
            draggable: true,
            // setCellHeaderProps: (value) => ({
            //   style: { width: 58 },
            // }),
            // setCellProps: () => ({
            //   style: { width: 58 },
            // }),
          },
        },
        {
          label: 'Field type',
          name: 'type',
          options: {
            filter: false,
            sort: false,
            draggable: true,
            customBodyRenderLite: (index) => {
              const option = data[index];
              const type =
                option?.createdBy && option.createdBy && option.createdBy !== null
                  ? 'Custom'
                  : 'System';
              return (
                <Chip label={type} color={type === 'System' ? 'default' : 'info'} size="small" />
              );
            },
          },
        },
        {
          label: ' ',
          name: 'actions',
          options: {
            setCellHeaderProps: (value) => ({
              style: { width: 36 },
            }),
            setCellProps: () => ({
              style: { width: 36 },
            }),
            customBodyRenderLite: (index) => {
              const option = data[index];
              if (!option?.createdBy) return null;
              if (option.createdBy && option.createdBy !== null) {
                return (
                  <Menu
                    options={[
                      {
                        label: 'Edit',
                        icon: <EditIcon />,
                        onClick: () => {
                          openEditModal(option);
                        },
                      },
                      {
                        label: 'Delete',
                        icon: <DeleteIcon />,
                        onClick: () => {
                          openDeleteModal(option);
                        },
                      },
                    ]}
                  />
                );
              }
              return null;
            },
          },
        },
      ]);
    }
  }, [data]);

  return columns;
}
