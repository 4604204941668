import React from 'react';
import { Typography, Box } from '@mui/material';
import { Spacer } from 'src/components/shared';
import { ActivityItemWrapper, ActivityIconWrapper, ActivityIcon } from './styles';
import SendIcon from '@mui/icons-material/Send';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import TaskIcon from '@mui/icons-material/Task';
import moment from 'moment';
import { getActivityTypeIcon } from '../../utils/contactUtils';
import { getTaskTypeIcon } from 'src/modules/tasks/utils/tasksUtils';

function ActivityItem(props) {
  const { type, note, subtitle, date, title, data } = props;

  const [showMore, setShowMore] = React.useState(false);
  const getIcon = () => {
    switch (type) {
      case 'linkedin':
      case 'linkedinMail':
      case 'linkedinConnection':
      case 'linkedinMessage':
        return (
          <ActivityIcon>
            <LinkedInIcon style={{ fill: '#1282B2', width: 24, height: 24 }} />
          </ActivityIcon>
        );
        break;
      case 'CONTACT_UPDATED':
        return (
          <ActivityIcon>
            <AccountBoxIcon style={{ fill: '#1282B2', width: 24, height: 24 }} />
          </ActivityIcon>
        );
        break;

      case 'call':
        return (
          <ActivityIcon>
            <CallIcon style={{ fill: '#66BB6A', width: 24, height: 24 }} />
          </ActivityIcon>
        );
        break;

      case 'general':
        return (
          <ActivityIcon>
            <TaskIcon style={{ fill: '#1282B2', width: 24, height: 24 }} />
          </ActivityIcon>
        );
        break;

      case 'Meeting Note':
        return (
          <ActivityIcon>
            <NoteAltIcon style={{ fill: '#1282B2', width: 24, height: 24 }} />
          </ActivityIcon>
        );
        break;

      case 'Enrolled_In_Sequence':
        return (
          <ActivityIcon>
            <SendIcon style={{ fill: '#66BB6A', width: 24, height: 24 }} />
          </ActivityIcon>
        );
        break;

      default:
      case 'email':
        return (
          <ActivityIcon>
            <EmailIcon style={{ fill: '#42A5F5', width: 24, height: 24 }} />
          </ActivityIcon>
        );
        break;
    }
  };

  const subtitleAsString = new DOMParser().parseFromString(subtitle, 'text/html').body.textContent;

  return (
    <ActivityItemWrapper>
      <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
        <>
          <Box display="flex" flexDirection="row">
            <ActivityIconWrapper>
              <ActivityIcon>{getActivityTypeIcon(type, '1.4rem')}</ActivityIcon>
            </ActivityIconWrapper>
          </Box>
          <Box display="flex" flexDirection="column" width="100%">
            <Typography sx={{ fontSize: 12, color: 'rgba(0,0,0,0.4)' }}>
              {moment(date).format('MMM Do YYYY | h:mm a')}
            </Typography>
            <Typography color="textPrimary">
              {note}
              <span
                dangerouslySetInnerHTML={{ __html: data?.note || title }}
                className="activity-note-title"
              />
            </Typography>
            {subtitleAsString && (
              <React.Fragment>
                <Spacer x={0.4} y={0.4} />
                <Typography variant="body2" color="textSecondary">
                  {showMore || subtitleAsString?.length < 120 ? (
                    <span dangerouslySetInnerHTML={{ __html: subtitle }} />
                  ) : (
                    <span className={subtitleAsString?.length >= 120 ? 'overflow-ellipsis' : ''}>
                      <span dangerouslySetInnerHTML={{ __html: subtitleAsString }} />
                    </span>
                  )}
                </Typography>
                <Spacer y={1} />
                {subtitleAsString?.length >= 120 && (
                  <Typography
                    color="secondary"
                    variant="body2"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowMore(!showMore)}
                  >
                    Show {showMore ? 'less' : 'more'}
                  </Typography>
                )}
              </React.Fragment>
            )}
          </Box>
        </>
        {/* <Box width="35%"></Box> */}
      </Box>
    </ActivityItemWrapper>
  );
}

export default ActivityItem;
