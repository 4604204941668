import React, { useMemo } from 'react';
import { Alert, Container } from '@mui/material';
import { PageTitle, Tabs } from 'src/components/App';
import { billingTabs } from '../../config';
import { Spacer } from 'src/components/shared';

import BillingDetails from './BillingDetails';
import BillingInvoices from './BillingInvoices';
import BillingSubscriptions from './BillingSubscriptions';

import { withBillingStore } from '../../utils/billingStore';
import { useDispatch, useSelector } from 'react-redux';

export const BillingContainer = (props) => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const activeTab = useMemo(() => {
    return props?.match?.params?.tab || 'details';
  }, [props]);

  const onTabChange = (tab) => {
    props.history.push(`/admin/billing/${tab.id}`);
  };

  const {
    acl: { accessLevel },
    subscriptionStatus,
  } = user ?? {};
  const isAdmin = accessLevel.toLowerCase() === 'admin';
  const isSubscriptionFailed = ['failure', 'needs_attention'].includes(subscriptionStatus);

  return (
    <>
      <PageTitle title={`Billing`} />
      <>
        <Tabs tabs={billingTabs} onChange={onTabChange} value={activeTab} />
        <Spacer y={2} />
        {isAdmin && isSubscriptionFailed && (
          <Container maxWidth="md" sx={{ margin: '0 !important', padding: '0 !important' }}>
            <Alert severity="error">
              It looks like your payment method has failed. To continue accessing your SuperReach
              subscription and unlocking the full potential of our platform, please update your
              payment method.
            </Alert>
            <Spacer y={3.2} />
          </Container>
        )}
        {activeTab === 'details' && <BillingDetails />}
        {activeTab === 'subscriptions' && <BillingSubscriptions />}
        {activeTab === 'invoices' && <BillingInvoices />}
      </>
    </>
  );
};

export default withBillingStore(BillingContainer);
