import React from 'react';
import { Grid } from '@mui/material';
import Card from './Card';
import { useDashboardStore } from 'src/modules/app/utils/dashboardStore';

function Cards() {
  const { sequenceReport } = useDashboardStore();
  return (
    <Grid container spacing={2}>
      <Card
        title="Prospects Enrolled"
        data={sequenceReport?.prospectEnrolled || {}}
        color="#FF8F00"
      />
      <Card
        title="Prospects Contacted"
        data={sequenceReport?.prospectContacted || {}}
        color="#1E88E5"
      />
      <Card title="Prospects Opened" data={sequenceReport?.prospectsOpened || {}} color="#8E24AA" />
      <Card
        title="Prospects Clicked"
        data={sequenceReport?.prospectsClicked || {}}
        color="#00897B"
      />
      <Card
        title="Prospects Replied"
        data={sequenceReport?.prospectsReplied || {}}
        color="#43A047"
      />
      <Card
        title="Prospects Interested"
        data={sequenceReport?.prospectsInterested || {}}
        color="#3949AB"
      />
    </Grid>
  );
}

export default Cards;
