import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { Grid, Box, Typography, Alert } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useStyles } from '../../containers/integrations/styles';
import { useHistory } from 'react-router';
import toast from 'src/utils/toast';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function TeamForm({
  teamLead,
  tenantIntegration,
  drawerToggle = () => null,
  onComplete = () => null,
  editUser,
  formData,
  onClose,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const classes = useStyles();
  const history = useHistory();
  const formRef = useRef();

  const handleSubmit = async (values, form) => {
    const data = {};
    data.type = editUser?.type;
    data.name = editUser?.name;
    data.description = editUser?.description;
    data.id = editUser?.id;
    data.status = editUser?.status;
    data.integration = {};

    if (editUser && editUser.name === 'Bullhorn') {
      data.integration.clientId = values.clientId;
      data.integration.secrete = values.secrete;
      data.integration.data_center = values.data_center;
      data.integration.secrete = values.secrete;
      data.integration.username = values.username;
      data.integration.pass = values.pass;
      delete data.apikey;
    } else if (editUser && editUser.name == 'Vincere') {
      delete data.secrete;
      delete data.data_center;
      delete data.username;
      delete data.pass;
      data.integration.clientId = values.clientId;
      data.integration.apikey = values.apikey;
      data.integration.crmTenantId = values.crmTenantId;
    } else if (editUser && editUser.name === 'Salesforce') {
      delete data.secrete;
      delete data.data_center;
      delete data.username;
      delete data.pass;
      data.integration.key = values.key;
      data.integration.secret = values.secret;
      data.integration.instance_url = values.instance_url;
    } else if (editUser && editUser.name === 'Hubspot') {
      data.integration.access_token = values.access_token;
      data.integration.crmAccountId = values.crmAccountId;
    } else if (editUser && editUser.name === 'JobAdder') {
      data.integration.connect = 'ok';
    } else {
      delete data.secrete;
      delete data.data_center;
      delete data.username;
      delete data.pass;
      delete data.clientId;
      data.integration.apikey = values.apikey;
    }

    if (!!Object.keys(data?.integration || {})?.find((key) => !data?.integration[key]))
      return toast.error(
        `${
          ['Bullhorn', 'Vincere', 'JobAdder', 'Salesforce', 'Hubspot'].includes(editUser.name)
            ? 'Please enter all the details correctly'
            : 'Please enter a valid API Key'
        } to connect to ${editUser.name}`,
      );

    if (data != null) {
      await props.saveIntegrations(data);
      drawerToggle({ edit: editUser, open: false });
      onComplete();
      editUser?.id &&
        ['Bullhorn', 'Vincere', 'Salesforce', 'Hubspot'].includes(editUser.name) &&
        history.push(`/admin/integrations/${editUser.name}/${editUser.id}`);
    }
  };

  return (
    <Form
      initialValues={{
        clientId: editUser?.integration?.clientId || '',
        instance_url: editUser?.integration?.instance_url || '',
        apikey: editUser?.integration?.apikey || '',
        access_token: editUser?.integration?.access_token || '',
        crmAccountId: editUser?.integration?.crmAccountId || '',
        appId: editUser?.integration?.appId || '',
        crmTenantId: editUser?.integration?.crmTenantId || '',
        secrete: currentUser?.secrete || editUser?.integration?.secrete,
        key: editUser?.integration?.key || '',
        secret: currentUser?.secret || editUser?.integration?.secret,
        data_center: editUser?.integration?.data_center || '',
        username: editUser?.integration?.username || '',
        pass: editUser?.integration?.pass || '',
      }}
      validationSchema={Yup.object().shape({
        clientId:
          ['Bullhorn', 'Vincere']?.includes(editUser?.name) &&
          Yup.string().required('Please enter a Client Id to continue.'),
        instance_url:
          ['Salesforce']?.includes(editUser?.name) &&
          Yup.string().required('Please enter a Tenant URL to continue.'),
        apikey:
          [
            'Vincere',
            'Lusha',
            'Uplead',
            'Anymail',
            'Kendo',
            'Apollo',
            'Getanymail',
            'Kaspr',
            'Hunter',
            'ContactOut',
            'RocketReach',
            'Nymeria',
            'PeopleDataLab',
          ]?.includes(editUser?.name) &&
          Yup.string().required('Please enter a Api Key to continue.'),
        access_token:
          ['Hubspot']?.includes(editUser?.name) &&
          Yup.string().required('Please enter a Access Token to continue.'),
        crmAccountId:
          ['Hubspot']?.includes(editUser?.name) &&
          Yup.string().required('Please enter a Account Id to continue.'),
        // appId:
        //   editUser?.integration.hasOwnProperty('appId') &&
        //   Yup.string().required('Please enter a App ID to continue.'),
        crmTenantId:
          ['Vincere']?.includes(editUser?.name) &&
          Yup.string().required('Please enter a Domain to continue.'),
        secrete:
          ['Bullhorn']?.includes(editUser?.name) &&
          Yup.string().required('Please enter a Client Secrete to continue.'),
        key:
          ['Salesforce']?.includes(editUser?.name) &&
          Yup.string().required('Please enter a Consumer Key to continue.'),
        secret:
          ['Salesforce']?.includes(editUser?.name) &&
          Yup.string().required('Please enter a Consumer Secret to continue.'),
        data_center:
          ['Bullhorn']?.includes(editUser?.name) &&
          Yup.string().required('Please enter a Data Center to continue.'),
        username:
          ['Bullhorn']?.includes(editUser?.name) &&
          Yup.string().required('Please enter a Username to continue.'),
        pass:
          ['Bullhorn']?.includes(editUser?.name) &&
          Yup.string().required('Please enter a Password to continue.'),
      })}
      innerRef={formRef}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={false}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              justifyContent="space-between"
              //   alignItems="flex-end"
              height="100%"
            >
              {editUser && editUser.name === 'Bullhorn' && (
                <Box width="100%">
                  {/* <SearchInput searchText={'new'} placeholder="Search Integration" /> */}
                  <Grid container spacing={0}>
                    <Box className={classes.bullhornTitle} sx={{ background: '#d3eefd' }}>
                      <ErrorOutlineIcon sx={{ marginTop: '7px' }} color="secondary" />
                      <Typography className="title" sx={{ padding: '8px' }}>
                        Please read{' '}
                        <a
                          href="https://help.bullhorn.com/s/contactsupport"
                          target="_blank"
                          className={classes.headerLink}
                        >
                          this article
                        </a>{' '}
                        to learn more about integrating BullHorn with SuperReach.
                        <Spacer x={1} y={2} />
                        <Typography variant="body1" className={classes.headerHeading}>
                          Enter the details provided BullHorn below.
                        </Typography>
                      </Typography>
                    </Box>
                    <Spacer y={1.5} />
                    {Object.keys(formRef?.current?.errors || {})?.length !== 0 && (
                      <>
                        <Alert severity="error">
                          One or more fields below require attention. Please read this for more
                          details
                        </Alert>
                        <Spacer y={2.5} />
                      </>
                    )}
                    <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="clientId"
                      label="Client Id"
                    />
                    <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="secrete"
                      label="Client Secrete"
                    />
                    <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="data_center"
                      label="Data Center"
                    />
                    <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="username"
                      label="Username"
                    />
                    <Form.Field.Input
                      fullWidth
                      type="password"
                      rows={4}
                      variant="outlined"
                      name="pass"
                      label="Password"
                    />
                  </Grid>
                </Box>
              )}
              {editUser && editUser.name === 'Vincere' && (
                <Box width="100%">
                  {/* <SearchInput searchText={'new'} placeholder="Search Integration" /> */}
                  <Grid container spacing={0}>
                    <Box className={classes.bullhornTitle} sx={{ background: '#d3eefd' }}>
                      <ErrorOutlineIcon sx={{ marginTop: '7px' }} color="secondary" />
                      <Typography className="title" sx={{ padding: '8px' }}>
                        Please read{' '}
                        <a
                          href="https://www.vincere.io/support"
                          target="_blank"
                          className={classes.headerLink}
                        >
                          this article
                        </a>{' '}
                        to learn more about integrating Vincere with SuperReach.
                        <Spacer x={1} y={2} />
                        <Typography variant="body1" className={classes.headerHeading}>
                          Enter the details provided Vincere below.
                        </Typography>
                      </Typography>
                    </Box>
                    <Spacer y={1.5} />
                    {Object.keys(formRef?.current?.errors || {})?.length !== 0 && (
                      <>
                        <Alert severity="error">
                          One or more fields below require attention. Please read this for more
                          details
                        </Alert>
                        <Spacer y={2.5} />
                      </>
                    )}
                    <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="clientId"
                      label="Client Id"
                    />
                    <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="apikey"
                      label="Api Key"
                    />
                    {/* <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="appId"
                      label="App ID"
                    /> */}
                    <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="crmTenantId"
                      label="Vincere Domain"
                    />
                  </Grid>
                </Box>
              )}
              {editUser && editUser.name === 'JobAdder' && (
                <Box width="100%" display={'flex'} flex={1}>
                  {/* <SearchInput searchText={'new'} placeholder="Search Integration" /> */}
                  <Box display={'flex'} flex={1} spacing={0}>
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        marginTop: '20px',
                        flexDirection: 'column',
                        gap: 4,
                      }}
                    >
                      <Typography variant="text">
                        Click the button below to connect your JobAdder account
                      </Typography>
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        style={{
                          width: 'fit-content',
                          borderRadius: 12,
                          padding: '6px 80px',
                          position: 'relative',
                        }}
                        loading={loading}
                        disabled={loading}
                      >
                        Connect
                        <ArrowForwardIcon
                          sx={{
                            color: 'white',
                            position: 'absolute',
                            right: '20px',
                            fontSize: 20,
                          }}
                        />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}
              {editUser && editUser.name === 'Salesforce' && (
                <Box width="100%">
                  {/* <SearchInput searchText={'new'} placeholder="Search Integration" /> */}
                  <Grid container spacing={0}>
                    <Box className={classes.bullhornTitle} sx={{ background: '#d3eefd' }}>
                      <ErrorOutlineIcon sx={{ marginTop: '7px' }} color="secondary" />
                      <Typography className="title" sx={{ padding: '8px' }}>
                        Please read{' '}
                        <a
                          href="https://help.salesforce.com/s/"
                          target="_blank"
                          className={classes.headerLink}
                        >
                          this article
                        </a>{' '}
                        to learn more about integrating Salesforce with SuperReach.
                        <Spacer x={1} y={2} />
                        <Typography variant="body1" className={classes.headerHeading}>
                          Enter the details provided Salesforce below.
                        </Typography>
                      </Typography>
                    </Box>
                    <Spacer y={1.5} />
                    {Object.keys(formRef?.current?.errors || {})?.length !== 0 && (
                      <>
                        <Alert severity="error">
                          One or more fields below require attention. Please read this for more
                          details
                        </Alert>
                        <Spacer y={2.5} />
                      </>
                    )}
                    <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="key"
                      label="Consumer Key"
                    />
                    <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="secret"
                      label="Consumer Secret"
                    />
                    {/* <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="appId"
                      label="App ID"
                    /> */}
                    <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="instance_url"
                      label="Tenant URL"
                    />
                  </Grid>
                </Box>
              )}
              {editUser && editUser.name === 'Hubspot' && (
                <Box width="100%">
                  {/* <SearchInput searchText={'new'} placeholder="Search Integration" /> */}
                  <Grid container spacing={0}>
                    <Box className={classes.bullhornTitle} sx={{ background: '#d3eefd' }}>
                      <ErrorOutlineIcon sx={{ marginTop: '7px' }} color="secondary" />
                      <Typography className="title" sx={{ padding: '8px' }}>
                        Please read{' '}
                        <a
                          href="https://help.superreach.com/en/articles/8154557-setting-up-your-hubspot-integration"
                          target="_blank"
                          className={classes.headerLink}
                        >
                          this article
                        </a>{' '}
                        to learn more about integrating Hubspot with SuperReach.
                        <Spacer x={1} y={2} />
                        <Typography variant="body1" className={classes.headerHeading}>
                          Enter the details provided Hubspot below.
                        </Typography>
                      </Typography>
                    </Box>
                    <Spacer y={1.5} />
                    {Object.keys(formRef?.current?.errors || {})?.length !== 0 && (
                      <>
                        <Alert severity="error">
                          One or more fields below require attention. Please read this for more
                          details
                        </Alert>
                        <Spacer y={2.5} />
                      </>
                    )}
                    <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="access_token"
                      label="Access Token"
                    />
                    <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="crmAccountId"
                      label="Account Id"
                    />
                  </Grid>
                </Box>
              )}
              {editUser && editUser.name === 'Lusha' && (
                <Box width="100%">
                  <Form.Field.Input
                    fullWidth
                    rows={3}
                    variant="outlined"
                    name="apikey"
                    label="Api Key"
                  />
                </Box>
              )}
              {editUser && editUser.name === 'Uplead' && (
                <Box width="100%">
                  <Form.Field.Input
                    fullWidth
                    rows={3}
                    variant="outlined"
                    name="apikey"
                    label="Api Key"
                  />
                </Box>
              )}
              {editUser && editUser.name === 'Anymail' && (
                <Box width="100%">
                  <Form.Field.Input
                    fullWidth
                    rows={3}
                    variant="outlined"
                    name="apikey"
                    label="Api Key"
                  />
                </Box>
              )}
              {editUser && editUser.name === 'Kendo' && (
                <Box width="100%">
                  <Form.Field.Input
                    fullWidth
                    rows={3}
                    variant="outlined"
                    name="apikey"
                    label="Api Key"
                  />
                </Box>
              )}
              {editUser && editUser.name === 'Apollo' && (
                <Box width="100%">
                  <Form.Field.Input
                    fullWidth
                    rows={3}
                    variant="outlined"
                    name="apikey"
                    label="Api Key"
                  />
                </Box>
              )}
              {editUser && editUser.name === 'Getanymail' && (
                <Box width="100%">
                  <Form.Field.Input
                    fullWidth
                    rows={3}
                    variant="outlined"
                    name="apikey"
                    label="Api Key"
                  />
                </Box>
              )}
              {editUser && editUser.name === 'Kaspr' && (
                <Box width="100%">
                  <Form.Field.Input
                    fullWidth
                    rows={3}
                    variant="outlined"
                    name="apikey"
                    label="Api Key"
                  />
                </Box>
              )}
              {editUser && editUser.name === 'Hunter' && (
                <Box width="100%">
                  <Form.Field.Input
                    fullWidth
                    rows={3}
                    variant="outlined"
                    name="apikey"
                    label="Api Key"
                  />
                </Box>
              )}
              {editUser && editUser.name === 'ContactOut' && (
                <Box width="100%">
                  <Form.Field.Input
                    fullWidth
                    rows={3}
                    variant="outlined"
                    name="apikey"
                    label="Api Key"
                  />
                </Box>
              )}
              {editUser && editUser.name === 'RocketReach' && (
                <Box width="100%">
                  <Form.Field.Input
                    fullWidth
                    rows={3}
                    variant="outlined"
                    name="apikey"
                    label="Api Key"
                  />
                </Box>
              )}
              {editUser && editUser.name === 'Nymeria' && (
                <Box width="100%">
                  <Form.Field.Input
                    fullWidth
                    rows={3}
                    variant="outlined"
                    name="apikey"
                    label="Api Key"
                  />
                </Box>
              )}
              {editUser && editUser.name === 'PeopleDataLab' && (
                <Box width="100%">
                  <Form.Field.Input
                    fullWidth
                    rows={3}
                    variant="outlined"
                    name="apikey"
                    label="Api Key"
                  />
                </Box>
              )}
              {(editUser ? editUser?.name !== 'JobAdder' : true) && (
                <Box width="100%" display="flex" alignItems="flex-end" justifyContent="flex-end">
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={onClose}
                    loading={loading}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                  <Spacer x={1} y={1} />
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    Save
                  </Button>
                </Box>
              )}
            </Box>
          </form>
        );
      }}
    </Form>
  );
}
export default TeamForm;
