import styled from 'styled-components';

export const SequenceWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  /* height: 88px; */
  border: 1px solid rgba(0, 0, 0, 0.12);
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 10px;
`;

export const ButtonCircle = styled.button`
  border-radius: 50%;
  margin-left: 5px;
  width: 22px;
  height: 22px;
  padding: 4px;
  background: black;
  border: 0px solid;
  color: #fff;
  text-align: center;
`;
