import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Typography,
  Menu,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
} from '@mui/material';
import { Form, Spacer } from 'src/components/shared';
import { Empty } from 'src/components/App';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { MenuList, MenuContainer, AddButton } from './styles';

const MenuItem = ({ menu, onClick }) => {
  return (
    <ListItem dense disablePadding>
      <ListItemButton onClick={onClick}>
        <ListItemText primary={menu.name} />
      </ListItemButton>
    </ListItem>
  );
};

function SharedBoardsMenu({ boards, anchorEl, open, onClose, setShowBoardForm, ...props }) {
  const [search, setSearch] = useState('');
  const history = useHistory();
  const [sharedBoards, setSharedBoards] = useState([]);
  const [personalBoards, setPersonalBoards] = useState([]);

  useEffect(() => {
    let data = [];
    Object.keys(boards).map((key) => {
      if (!boards[key]?.pinned || boards[key]?.pinned == false) {
        data.push({
          name: boards[key].name,
          tabColor: boards[key].tabColor,
          id: key,
          accessType: boards[key].accessType,
        });
      }
    });

    if (data && data.length) {
      setPersonalBoards(
        data.filter((item) => {
          return (
            item.accessType === 'private' &&
            (search && search !== ''
              ? item.name.toLowerCase().includes(search.toLocaleLowerCase())
              : true)
          );
        }),
      );
      setSharedBoards(
        data.filter((item) => {
          return (
            item.accessType !== 'private' &&
            (search && search !== ''
              ? item.name.toLowerCase().includes(search.toLocaleLowerCase())
              : true)
          );
        }),
      );
    }
  }, [boards, search]);

  const handleClick = async (menu) => {
    try {
      onClose();
      await props.putBoard(menu.id, {
        pinned: true,
      });
      history.push(`/boards/${menu.id}`);
    } catch (error) {}
  };

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
      <Box px={2} mb={0.5}>
        <Form
          initialValues={{
            search: search,
          }}
          enableReinitialize={true}
        >
          <Form.Field.InputDebounced
            name="search"
            placeholder="Search Boards"
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(value) => {
              setSearch(value);
            }}
          />
        </Form>
      </Box>
      <MenuContainer>
        <Spacer x={1} y={1} />

        {sharedBoards.length ? (
          <>
            <Box px={2}>
              <Typography variant="body2">Shared Boards</Typography>
            </Box>
            <List dense>
              {sharedBoards.map((menu, index) => (
                <MenuItem
                  menu={menu}
                  key={`shared-board-menu-${index}`}
                  onClick={() => {
                    handleClick(menu);
                  }}
                />
              ))}
            </List>
          </>
        ) : null}

        <Spacer x={1} y={1} />

        {personalBoards.length ? (
          <>
            <Box px={2}>
              <Typography variant="body2">Private Boards</Typography>
            </Box>
            <MenuList>
              {personalBoards.map((menu, index) => (
                <MenuItem
                  menu={menu}
                  key={`shared-board-menu-${index}`}
                  onClick={() => {
                    handleClick(menu);
                  }}
                />
              ))}
            </MenuList>
          </>
        ) : null}
      </MenuContainer>
      <AddButton startIcon={<AddIcon />} onClick={() => setShowBoardForm(true)}>
        Create New Board
      </AddButton>
    </Menu>
  );
}

export default SharedBoardsMenu;
