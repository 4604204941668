import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Box, Typography, Card, CardContent, ButtonBase } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import { Avatar, Spacer, Button } from 'src/components/shared';

import { toggleContactDrawer, toggleAddContactForm } from '../../../actions/appActions';

import { isNull, isEmpty } from 'lodash';
import { capitalizeName } from 'src/modules/contacts/utils/contactUtils';

import {
  ProfileWrapper,
  Left,
  Right,
  JobTitle,
  DeleteButton,
  InfoButton,
  PhoneButton,
  EmailButton,
  SeqStatus,
} from './styles';

import EditIcon from '@mui/icons-material/Edit';

function ContactCard({ contact, panelId, index, showEdit, showClone, handleDelete, ...props }) {
  if (isNull(contact) || isEmpty(contact) || !contact) {
    return null;
  }

  const [showActions, setShowActions] = useState(false);
  const dispatch = useDispatch();
  const name = capitalizeName(`${contact?.fname} ${contact?.lname}`);
  let currentEmployer = {};
  if (contact.workHistory && contact.workHistory.length) {
    currentEmployer = contact.workHistory.filter((work) => work.isCurrent === true)[0];
  }

  const jobTitle = contact?.jobTitle || undefined;
  const employer = contact?.employer || undefined;
  const location = contact?.address && contact?.address.length ? contact?.address[0] : {};

  const renderUserCard = () => {
    return (
      <Card sx={{ marginBottom: 1.5, position: 'relative', overflow: 'visible' }} {...props}>
        {showEdit === true && showActions === true ? (
          <>
            <DeleteButton
              onClick={() => {
                handleDelete(contact.id);
              }}
            />
          </>
        ) : null}

        <CardContent sx={{ padding: 1.5, overflow: 'hidden', paddingBottom: '12px !important' }}>
          <ProfileWrapper>
            <Left>
              <Avatar src={contact.imageUrl} name={name} size={36} />
            </Left>
            <Spacer basis={2} />
            <Right>
              <Box display="flex" alignItems="center">
                <Typography variant="h3">{name}</Typography>
                <Spacer x={1} y={1} />
                <SeqStatus
                  theme=""
                  status={
                    contact.stats?.sequences && contact.stats?.sequences > 0 ? 'active' : 'never'
                  }
                />
                {showActions === true ? (
                  <>
                    <Spacer x={0.5} y={0.5} />
                    <ButtonBase
                      onClick={() => {
                        // props.toggleAddContactForm(contact);
                        dispatch(toggleAddContactForm(contact));
                      }}
                    >
                      <EditIcon sx={{ color: 'rgba(0,0,0,0.58)', fontSize: 16 }} />
                    </ButtonBase>
                  </>
                ) : null}
              </Box>
              <Box>
                <JobTitle>
                  {jobTitle} {jobTitle && employer ? 'at ' : ' '}
                  {employer}
                </JobTitle>
              </Box>
              {showEdit === true && showActions === true ? (
                <Box>
                  <PhoneButton
                    onClick={() => {
                      dispatch(toggleContactDrawer(contact.id));
                    }}
                  />
                  <EmailButton
                    onClick={() => {
                      dispatch(toggleContactDrawer(contact.id));
                    }}
                  />
                </Box>
              ) : null}
            </Right>
          </ProfileWrapper>
        </CardContent>
      </Card>
    );
  };

  let draggableId = contact.id.toString();
  if (panelId && panelId !== '') {
    draggableId = `${panelId}-${contact.id.toString()}`;
  }

  return (
    <Draggable draggableId={draggableId} contactId={contact.id.toString()} index={index}>
      {(provided, snapshot) => (
        <>
          <div
            ref={provided.innerRef}
            data-testid="list-issue"
            onMouseEnter={() => setShowActions(true)}
            onMouseLeave={() => setShowActions(false)}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {renderUserCard(provided, snapshot)}
          </div>
          {snapshot.isDragging && showClone && <>{renderUserCard(provided, snapshot)}</>}
        </>
      )}
    </Draggable>
  );
}

ContactCard.propTypes = {
  contact: PropTypes.object,
  index: PropTypes.string,
  showEdit: PropTypes.bool,
  showClone: PropTypes.bool,
};

ContactCard.defaultProps = {
  contact: () => {},
  index: 0,
  showEdit: false,
  showClone: false,
};

export default ContactCard;
