import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import images from 'src/config/images';

import { themeColors } from 'src/modules/lms/config';
import CardMedia from 'src/modules/lms/shared/cardMedia';

const PillarBannerSection = ({ bannerImage, id, title, contentType, loading, data }) => {
  return (
    <Box>
      <Grid container spacing={2} mb={5}>
        <Grid item xs={12} display="flex" justifyContent={'center'} alignContent={'center'}>
          <CardMedia
            src={bannerImage}
            alt={title}
            id={id}
            contentType={contentType}
            data={data}
            loading={loading}
            maxSize={'xl'}
            dontShowAction
            isClickable={false}
            makeImageResponsive={true}
            type={'headerImage'}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PillarBannerSection;
