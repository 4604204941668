import React, { useMemo } from 'react';
import {
  RadialBarChart,
  RadialBar,
  BarChart,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Cell,
} from 'recharts';
import { Typography, Grid } from '@mui/material';
import { Spacer } from 'src/components/shared';
import styled from 'styled-components';

const colors = ['#FF9800', '#4789C2', '#9C27B0', '#3949AB', '#2E7D32'];

const BG = styled.div`
  width: 60px;
  height: 60px;
  background-color: #4789c225;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  margin-top: 16px;
`;

const RadialChart = ({ total, value, title }) => {
  const style = {
    top: 75,
    lineHeight: '24px',
  };

  return (
    <BG>
      <ResponsiveContainer width={100} height={100}>
        <RadialBarChart
          cx="50%"
          cy="50%"
          innerRadius={30}
          outerRadius={45}
          barSize={10}
          startAngle={90}
          endAngle={450}
          data={[
            {
              name: title,
              value: total,
              fill: '#ffffff00',
            },
            {
              name: title,
              value: value,
              fill: '#4789C2',
            },
          ]}
        >
          <RadialBar
            minAngle={100}
            label={{ position: 'center', backgroundColor: '#ff0000' }}
            clockWise
            dataKey="value"
            fill="#ff0000"
          />
          <Legend iconSize={0} layout="vertical" verticalAlign="middle" wrapperStyle={style} />
        </RadialBarChart>
      </ResponsiveContainer>
    </BG>
  );
};

const TaskChart = ({ task }) => {
  const data = useMemo(() => {
    return Object.keys(task.completedTasks)
      .map((key) => {
        if (key !== 'total') {
          return {
            name: key,
            value: task.completedTasks[key],
          };
        }
      })
      .filter((item) => item);
  }, [task]);

  return (
    <>
      <Spacer x={2} y={2} />
      <Grid container>
        <Grid item xs={3} display="flex" alignItems="center" justifyContent="center">
          <RadialChart total={task.totalTasks} value={task.openTasks} title={'Open'} />
        </Grid>
        <Grid item xs={3} display="flex" alignItems="center" justifyContent="center">
          <RadialChart total={task.totalTasks} value={task.overdueTasks} title={'Overdue'} />
        </Grid>
        <Grid item xs={3} display="flex" alignItems="center" justifyContent="center">
          <RadialChart total={task.totalTasks} value={task.pendingTasks} title={'Pending'} /> 
        </Grid>
        <Grid item xs={3} display="flex" alignItems="center" justifyContent="center">
          <RadialChart
            total={task.totalTasks}
            value={task.completedTasks.total}
            title={'Completed'}
          />
           
        </Grid>
         
      </Grid>
      <Spacer x={4} y={4} />
      <ResponsiveContainer width={'100%'} height={300}>
        <BarChart
          width={200}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Bar dataKey="value" fill="#8884d8">
            {data.map((entry, index) => (
              <Cell width={50} key={`cell-${index}`} fill={colors[index]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default TaskChart;
