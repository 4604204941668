import React from 'react';
import { AppGrid } from 'src/components/App';
import { invoices } from '../../config/data';
import InvoicesGrid from '../../components/Billing/InvoicesGrid';

function BillingInvoices() {
  return (
    <>
      <InvoicesGrid data={invoices} />
    </>
  );
}

export default BillingInvoices;
