import { createContext, useState, useContext, useEffect, useRef } from 'react';
import * as lmsApi from '../api/LMSApis';
import { useDispatch } from 'react-redux';

const lmsReportsContext = createContext({});
import usePersistState from 'src/components/hooks/usePersistState';
import { getRelativeDateRange } from 'src/utils/dateUtils';
import { isEmpty } from 'lodash';

import { fetchReportCards, fetchReportGraph, fetchReportSummary } from '../actions/LMSActions';

export const useLmsReportsProvider = () => {
  const {
    filters,
    setFilters,
    tabs,
    tab,
    setTab,
    userSummary,
    fetchUserDetails,
    loading,
    userDetails,
    cards,
    graphData,
  } = useContext(lmsReportsContext);
  return {
    filters,
    setFilters,
    tabs,
    tab,
    setTab,
    userSummary,
    fetchUserDetails,
    loading,
    userDetails,
    cards,
    graphData,
  };
};

export const LMSReportStore = ({ children }) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = usePersistState('lmsReportsFilter', {
    createdAt: { id: 'last30Days', name: 'Last 30 days' },
  });
  const [tab, setTab] = usePersistState('lmsReportsTab', 'summary');

  const [loading, setLoading] = useState({
    summary: false,
    cards: false,
    chart: false,
    details: false,
  });

  const [userSummary, setUserSummary] = useState([]);
  const [cards, setCards] = useState({});
  const [graphData, setGraphData] = useState([]);
  const [userDetails, setUserDetails] = useState([]);

  useEffect(() => {
    fetchSummary()
      .then((res) => {
        setUserSummary(res.score);
        setLoading((prev) => ({
          ...prev,
          summary: false,
        }));
      })
      .catch((err) => {
        setUserSummary([]);
        setLoading((prev) => ({
          ...prev,
          summary: false,
        }));
      });

    fetchCards()
      .then((res) => {
        setCards(res);
      })
      .catch((err) => {
        setCards({});
      });

    fetchGraph()
      .then((res) => {
        setGraphData(res);
      })
      .catch((err) => {
        setGraphData([]);
      });
  }, [filters]);

  const getFilters = () => {
    let data = {
      ...(filters?.team &&
        filters?.team?.users &&
        filters?.team?.users.length && {
          users: filters?.team?.users,
        }),
    };
    if (filters?.createdAt && !isEmpty(filters?.createdAt)) {
      const dateRange = getRelativeDateRange(filters?.createdAt);
      data = {
        ...data,
        createdAt_gte: dateRange.start,
        createdAt_lte: dateRange.end,
      };
    }
    return data;
  };

  const fetchCards = async () => {
    const payload = getFilters();
    return new Promise((resolve, reject) => {
      dispatch(fetchReportCards(payload, resolve, reject));
    });
  };

  const fetchGraph = async () => {
    const payload = getFilters();
    return new Promise((resolve, reject) => {
      dispatch(fetchReportGraph(payload, resolve, reject));
    });

    // try {
    //   const payload = getFilters();
    //   const res = await lmsApi.getReportSummaryGraph(payload);
    //   setGraphData(res.result || []);
    // } catch (error) {
    //   console.log('error : ', error);
    // }
  };

  const fetchUserDetails = async (id) => {
    try {
      setLoading((prev) => ({
        ...prev,
        details: true,
      }));
      const res = await lmsApi.getUserScore(id);
      const grouped = res.roleplayScore.reduce((acc, item) => {
        if (item?.session.id && !acc[item.session.id]) {
          acc[item.session.id] = [];
        }
        acc[item.session.id].push(item);
        return acc;
      }, {});
      setUserDetails(grouped);

      setLoading((prev) => ({
        ...prev,
        details: false,
      }));
    } catch (error) {
      setUserDetails([]);
    }
  };

  const fetchSummary = async () => {
    try {
      setLoading((prev) => ({
        ...prev,
        summary: true,
      }));
      const payload = getFilters();
      return new Promise((resolve, reject) => {
        dispatch(fetchReportSummary(payload, resolve, reject));
      });
    } catch (err) {}
  };

  return (
    <lmsReportsContext.Provider
      value={{
        loading,
        filters,
        setFilters,
        tab,
        setTab,
        userSummary,
        fetchUserDetails,
        userDetails,
        cards,
        graphData,
      }}
    >
      {children}
    </lmsReportsContext.Provider>
  );
};

export const withReportsStore = (Component) => (props) =>
  (
    <LMSReportStore>
      <Component {...props} />
    </LMSReportStore>
  );
