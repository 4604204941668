import React from 'react';
import { connect } from 'react-redux';
import ForgotPasswordView from './ForgotPasswordView';
import { forgotPassword } from '../../actions/authActions';
import { PageTitle } from 'src/components/App';

export const ForgotPasswordContainer = (props) => {
  return (
    <>
      <PageTitle title="Forgot Password" />
      <ForgotPasswordView {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (payload) => {
    return new Promise((resolve, reject) => {
      dispatch(forgotPassword(payload, resolve, reject));
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPasswordContainer);
