import * as types from './tagsTypes';

export const fetchTags = () => ({
  type: types.FETCH_ADMIN_TAGS
});

export const setTags = (data) => ({
  type: types.SET_ADMIN_TAGS,
  data,
});

export const updateTagStatus = (rowData) => ({
  type: types.UPDATE_TAG_STATUS,
  rowData,
});

export const setTagStatus = (data) => ({
  type: types.SET_TAG_STATUS,
  data,
});