import * as React from 'react';
import { Collapse } from '@mui/material';
import { Button } from 'src/components/shared';

import PanelForm from './PanelForm';
import AddIcon from '@mui/icons-material/Add';
import { TransitionGroup } from 'react-transition-group';

import { List, FormWrapper } from './styles';

export default function AddPanel(props) {
  const [showForm, setShowForm] = React.useState(false);

  const handleChange = () => {
    setShowForm((prev) => !prev);
  };

  return (
    <List>
      <FormWrapper>
        <Button startIcon={<AddIcon />} onClick={handleChange} size="large">
          Add Stage
        </Button>
        <TransitionGroup>
          {showForm === true ? (
            <Collapse>
              <FormWrapper>
                <PanelForm onClose={handleChange} {...props} />
              </FormWrapper>
            </Collapse>
          ) : null}
        </TransitionGroup>
      </FormWrapper>
    </List>
  );
}
