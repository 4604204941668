import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Checkbox,
  Radio,
  CircularProgress,
  Typography,
  TableBody as MuiTableBody,
  Box,
} from '@mui/material';

import MUIDataTable, {
  TableBody,
  TableBodyCell,
  TableBodyRow,
} from 'mui-datatables';
import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { ThemeProvider, StyledEngineProvider } from '@mui/system';
import { useDispatch } from 'react-redux';
import { girdLoadingMessages } from 'src/config';
import { sample } from 'lodash';

// import { colorCodeConstant } from 'src/components/Automation/utils/styleConstant';
import { debounceSearchRender } from './SearchInput';

// import GridToolBar from './GridToolBar';
import GridFooter from './GridFooter';
import { getOverrides } from './theme';

const propTypes = {
  selectableRows: PropTypes.string,
};

const defaultProps = {
  selectableRows: 'none',
};

const CustomCheckbox = (props) => {
  let newProps = Object.assign({}, props);
  let classNames = 'MUI-DataTables-Custom-CheckBox';
  if (props.checked == true) {
    classNames += 'checked';
  }

  return <Checkbox {...newProps} className={classNames} />;
};

/*
 * Reusable grid with custom options
 * @param {*} columns columns of the grid
 * @param {*} data as per the column
 * @param {*} options to add parent custom thing
 * @param {*} className
 * @param {*} hideToolBar it will remove toolbar for grid
 * @param {*} paperWidth width controller of the grid
 * @param {*} stickyCol make first or second col position sticky
 * @param {*} otherProps props related other setting
 * @returns
 */
const AppGrid = ({
  columns = [],
  data = [],
  options = {},
  className = '',
  fetchData,
  hideToolBar = false,
  paperWidth = '290px',
  stickyCol = true,
  paperHeight = '280px',
  selectableRows,
  loading = false,
  ...otherProps
}) => {
  const stickyTD = stickyCol ? 1 : 0;
  const sideBarIsOpen = useSelector((state) => state.app.sideBarIsOpen);

  const getMuiTheme = () => {
    return createTheme(
      adaptV4Theme({
        overrides: getOverrides(
          paperWidth,
          sideBarIsOpen,
          paperHeight,
          stickyTD,
          stickyCol,
          hideToolBar,
        ),
      }),
    );
  };

  const dispatch = useDispatch();

  const defaultOptions = {
    selectableRows: 'none',
    filterType: 'dropdown',
    responsive: 'stacked',
    filter: false,
    download: false,
    print: false,
    search: false,
    pagination: false,
    viewColumns: false,
    rowsPerPage: 25,
    customSearchRender: debounceSearchRender(400),
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
        case 'changeRowsPerPage':
        case 'sort':
        case 'search':
          if (!options.serverSide) {
            return;
          }
          let sortOrder =
            Object.keys(tableState.sortOrder).length === 0
              ? null
              : tableState.sortOrder;
          const params = {
            pageNo: tableState.page,
            sortOrder: sortOrder,
            perPage: tableState.rowsPerPage,
            searchText: tableState.searchText,
          };
          fetchData && dispatch(fetchData(params));
          otherProps?.onTableChange(params);
          break;

        case 'viewColumnsChange':
        case 'columnOrderChange':
          const paramColumns = {
            columns: tableState.columns,
            columnsOrder: tableState.columnOrder,
          };
          otherProps?.onColumnChange &&
            otherProps?.onColumnChange(paramColumns);
          break;
        default:
      }
    },
    draggableColumns: {
      enabled: true,
    },

    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      if (!options.pagination) {
        return <></>;
      }
      return (
        <GridFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          textLabels={textLabels}
        />
      );
    },

    // customToolbar: () => {
    //   return <GridToolBar />;
    // },
  };

  const LoadingTableBody = ({ loading, options, columns, ...others }) => {
    // @ts-ignore
    const visibleColCnt = columns.filter((c) => c.display === 'true').length;

    const loadingMessage = useMemo(
      () => sample(girdLoadingMessages),
      [loading],
    );

    return loading ? (
      <MuiTableBody>
        <TableBodyRow options={options}>
          <TableBodyCell
            // @ts-ignore
            colSpan={
              options.selectableRows !== 'none' || options.expandableRows
                ? visibleColCnt + 1
                : visibleColCnt
            }
            options={options}
            colIndex={0}
            rowIndex={0}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt={2}
              mb={2}
              flexDirection="column"
            >
              <CircularProgress size={26} style={{ marginBottom: 16 }} />
              <Typography
                color="textSecondary"
                style={{ color: 'rgba(0, 0, 0, 0.3)' }}
              >
                {/* {sample(girdLoadingMessages)} */}
                {loadingMessage}
              </Typography>
            </Box>
          </TableBodyCell>
        </TableBodyRow>
      </MuiTableBody>
    ) : (
      <TableBody options={options} columns={columns} {...others} />
    );
  };

  const BodyComponent = useMemo(
    () => (props) => <LoadingTableBody loading={loading} {...props} />,
    [loading],
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          data={data}
          columns={columns}
          className={`${className}`}
          options={{
            ...defaultOptions,
            ...options,
            selectableRows: selectableRows,
            columnOrder: !Array(...new Set(options?.columnOrder)).length
              ? undefined
              : Array(...new Set(options?.columnOrder)),
          }}
          {...otherProps}
          components={{
            ...otherProps.components,
            Checkbox: CustomCheckbox,
            TableBody: BodyComponent,
          }}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

AppGrid.propTypes = propTypes;
AppGrid.defaultProps = defaultProps;

export default AppGrid;
