import React, { useEffect, useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { Box, Paper, Typography, Menu } from '@mui/material';
import { Droppable } from 'react-beautiful-dnd';
import { Spacer, Drawer } from 'src/components/shared';
import { Empty } from 'src/components/App';
import { fetchDashboardContacts, removeContactFromPanel } from '../../../actions/appActions';
import {
  List,
  SlidePanel,
  SlidePanelText,
  SlidePanelHandle,
  CollapseButton,
  MenuPaper,
} from './styles';
import ContactCard from './ContactCard';
import Filter from 'src/components/App/AppGrid/Filter';
import SearchInput from './seach';
import ContactFilters from './ContactFilters';
import { UserCardSkeleton } from '../panel/Skeleton';
import { fetchContactsLookup } from 'src/modules/contacts/api/contactApis';

import { Collapse } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';

export const DashboardContacts = ({ contacts, loading, ...props }) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = React.useRef(null);

  const open = Boolean(anchorEl);
  const { data, paging, filters } = contacts;

  useEffect(() => {
    props.setIsOpen(isOpen);
  }, [isOpen]);

  const handleDrawerOpen = (event) => {
    // setShowDrawer(true);
    setAnchorEl(event.currentTarget);
  };

  const slideContactsPanel = () => {
    setIsOpen(!isOpen);
  };

  const handleDrawerClose = () => {
    setShowDrawer(false);
    setAnchorEl(null);
  };

  const drawerFilters = JSON.parse(JSON.stringify(filters));
  delete drawerFilters._search;

  const contactFilters = [
    {
      key: 'createdBy',
      title: 'Created By',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: true,
      searchable: true,
      isPrimary: true,
      remoteMethod: async (value) => {
        return fetchContactsLookup(value);
      },
    },
    {
      key: 'createdAtRange',
      title: 'Created date',
      type: 'dateRange',
    },
  ];

  const observer = useRef();
  useEffect(() => {
    props.fetchDashboardContacts(
      {
        ...paging,
        pageNo: 0,
      },
      filters,
    );
  }, []);

  const lastRecordRef = useCallback(
    (node) => {
      if (loading || dataLoading) return;
      try {
        setDataLoading(true);
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(async (entries) => {
          if (
            entries[0].isIntersecting &&
            Object.keys(data).length >= paging.pageNo + 1 * paging?.perPage &&
            Object.keys(data).length < paging.count
          ) {
            await props.fetchDashboardContacts(
              {
                ...paging,
                pageNo: paging.pageNo + 1,
              },
              filters,
            );
          }
        });
        if (node) observer.current.observe(node);
      } catch (err) {
      } finally {
        setDataLoading(false);
      }
    },
    [loading, paging, dataLoading],
  );

  const onClearFilters = () => {};

  const onFilterChange = (data) => {
    console.log('DATA : ', data);
    props.fetchDashboardContacts(
      {
        ...paging,
        pageNo: 0,
      },
      data,
    );
  };

  return (
    <SlidePanelHandle>
      <TransitionGroup>
        <CollapseButton isOpen={isOpen} onClick={slideContactsPanel} />
        {isOpen === true ? (
          <Collapse in={isOpen} orientation="horizontal">
            <SlidePanel>
              <Box pl={1.25} pr={1.25} mb={1}>
                <Typography variant="h2" sx={{ color: '#ffffff' }}>
                  Notifications
                </Typography>
                <Spacer x={0.5} y={0.5} />
                <Typography variant="body2" sx={{ color: '#ffffff' }}>
                  100 Notifications
                </Typography>
              </Box>
              <SearchInput
                onChange={onFilterChange}
                search={filters?._search}
                loading={loading}
                setShowDrawer={handleDrawerOpen}
                drawerFilters={drawerFilters}
              />
              <Droppable isDropDisabled={true} key={'sidebar'} droppableId={'sidebar'}>
                {(provided, snapshot) => (
                  <List {...provided.droppableProps} ref={provided.innerRef}>
                    {Object.keys(data).length ? (
                      <>
                        {Object.keys(data).map((contactID, index) => {
                          if (Object.keys(data).length === index + 1) {
                            return (
                              <div key={`sidebar-contact-${index}`} ref={lastRecordRef}>
                                <ContactCard
                                  index={index}
                                  contact={data[contactID]}
                                  showClone={true}
                                />
                              </div>
                            );
                          } else {
                            return (
                              <ContactCard
                                index={index}
                                contact={data[contactID]}
                                key={`sidebar-contact-${index}`}
                                panelId="sidebar"
                                showClone={true}
                              />
                            );
                          }
                        })}
                      </>
                    ) : (
                      <>
                        {loading !== true ? (
                          <Paper sx={{ backgroundColor: '#ffffff', padding: 1 }} p={2}>
                            <Empty title="No contacts found" />
                          </Paper>
                        ) : null}
                      </>
                    )}
                    {loading === true ? (
                      <>
                        <UserCardSkeleton />
                        <Spacer x={1.5} y={1.5} />
                        <UserCardSkeleton />
                        <Spacer x={1.5} y={1.5} />
                        <UserCardSkeleton />
                      </>
                    ) : null}
                  </List>
                )}
              </Droppable>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleDrawerClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <MenuPaper handleClear={onClearFilters}>
                  <ContactFilters
                    onClose={handleDrawerClose}
                    onChange={onFilterChange}
                    filters={filters}
                  />
                </MenuPaper>
              </Menu>
            </SlidePanel>
          </Collapse>
        ) : null}
      </TransitionGroup>
      {/* {!isOpen ? <SlidePanelText onClick={slideContactsPanel}>Prospects</SlidePanelText> : null} */}
    </SlidePanelHandle>
  );
};

const mapStateToProps = (state) => ({
  contacts: state.dashboard.contacts,
  loading: state.dashboard.loader.contacts,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDashboardContacts: (paging, filters) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchDashboardContacts(paging, filters, resolve, reject));
    });
  },
  removeContactFromPanel: (panelId, contactId) =>
    dispatch(removeContactFromPanel(panelId, contactId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContacts);
