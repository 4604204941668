import { Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useLmsReportsProvider } from '../../utils/reportsStore';

function Cards() {
  const { cards, graphData } = useLmsReportsProvider();

  const renderCard = (title, value) => {
    return (
      <Card sx={{ flex: 1 }}>
        <CardContent>
          <Typography variant="h3">{title}</Typography>
          <Typography sx={{ fontSize: 40 }} color="secondary">
            {value}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      <Stack direction="row" gap={2}>
        {renderCard('Active learners', cards?.allUsers || 0)}
        {renderCard('Sessions viewed', cards?.sessionsViewed || 0)}
        {renderCard('Role-plays', cards?.roleplays || 0)}
        {renderCard('Average score', cards?.averageScore ? cards?.averageScore.toFixed(2) : 0)}
        {renderCard('Videos watched', cards?.videosWatched || 0)}
      </Stack>
    </>
  );
}

export default Cards;
