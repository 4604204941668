import React from 'react';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import Skeleton from '../Skeleton/Skeleton';

const PageDetailSkeleton = () => {
  return (
    <Box>
      <Grid container spacing={2} mb={5}>
        <Grid item md={12} lg={9}>
          <Box mb={2}>
            <Skeleton animation="wave" height={50} width={'30%'} />
          </Box>
          <Box>
            <Skeleton animation="wave" height={100} width={'30%'} />
          </Box>

          <Box mb={3}>
            <Skeleton animation="wave" height={500} width={'100%'} />
          </Box>
        </Grid>
        <Grid item md={12} lg={3}>
          <Skeleton animation="wave" height={500} width={'100%'} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PageDetailSkeleton;
