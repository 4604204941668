import React, { useEffect, useMemo, useState } from 'react';

import { Stack } from '@mui/material';
import { Button } from 'src/components/shared';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StarIcon from '@mui/icons-material/Star';
import images from 'src/config/images';

import { ReactComponent as Camera } from 'src/assets/icons/camera.svg';
import { ReactComponent as Robot } from 'src/assets/icons/Robot.svg';
import { useSessionProvider } from 'src/modules/lms/utils/sessionStore';
import api from 'src/api';
import { addList, deleteList, getEmbedURL } from 'src/modules/lms/api/LMSApis';
import toast from 'src/utils/toast';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { themeColors } from 'src/modules/lms/config';
import CompetitionProgress from '../../LMSCompetition/CompetitionProgress';
import SessionRoleplay from './SessionRoleplay';
import { isEmpty } from 'lodash';

import { ReactComponent as Guide } from 'src/assets/icons/guide.svg';
import { ReactComponent as ProgressIcon } from 'src/assets/icons/ProgressIcon.svg';

function SessionActionBar({ session, handleViewChange, fetchScore }) {
  const appHistory = useHistory();
  const { competition, reports, videos, progress, view, setShowRolePlay, rolePlayUrl } =
    useSessionProvider();
  const [loading, setLoading] = useState(false);
  const [embedURL, setEmbedURL] = useState('');
  const [rolePlayModal, setRolePlayModal] = useState(false);
  const [isFav, setIsFav] = useState(false);

  useEffect(() => {
    setIsFav(session?.isFavourite);
  }, [session]);
  const [total, setTotal] = useState();

  const status = useMemo(() => {
    const isCompleted = Object.values(progress).filter(
      (item) => item.isFullyWatched || Math.floor(item.total) == Math.floor(item.watchedDuration),
    ).length;
    const inProgress = Object.values(progress).filter((item) => item.watchedDuration).length;
    const total = Object.values(progress).length;
    setTotal(total);

    if (isCompleted == total) {
      return 'Completed';
    } else if (inProgress > 0) {
      return 'In progress';
    } else {
      return 'Session not started';
    }
  }, [progress]);

  //Getting number of videos
  const totalVideos = session?.videos?.length;

  const onClickRolePlay = async () => {
    setShowRolePlay(true);
  };

  //Fav list
  const addInFavList = async (id) => {
    try {
      let payload =
        session.contentType === 'session'
          ? {
              session: id,
            }
          : { course: id };

      let res = await addList(payload);
      if (res) {
        setIsFav(true);
        if (res?.message) {
          toast.info('Already added to My List');
        } else {
          toast.success('Item added to My list');
        }
      } else {
        toast.error('Item not deleted');
      }
    } catch (error) {
      toast.error('Item not added to the My list');
    }
  };

  const removeFromList = async (id) => {
    try {
      let res = await deleteList(id);
      if (res) {
        toast.info('Item removed from My list');
        setIsFav(false);
      } else {
        toast.error('Item not removed from My list');
      }
    } catch (error) {
      toast.error('Error ocurred! Please try again.');
    }
  };
  //Fav list end

  useEffect(() => {
    if (embedURL) setRolePlayModal(true);
  }, [embedURL]);

  useEffect(() => {
    if (appHistory?.location?.search === '?roleplay') {
      onClickRolePlay();
    }
  }, [appHistory]);

  return (
    <>
      <Stack gap={2} direction="row">
        {total ? (
          <Button
            variant="contained"
            size="small"
            startIcon={
              status === 'Completed' ? (
                <CheckCircleIcon style={{ color: themeColors.greenText }} />
              ) : status === 'In progress' ? (
                <ProgressIcon />
              ) : (
                ''
              )
            }
            sx={{
              padding: '4px 10px',
              ...(status === 'Completed' || status === 'In progress'
                ? { color: themeColors.greenText }
                : {}),
            }}
          >
            {status}
          </Button>
        ) : null}

        {isFav === true ? (
          <Button
            size="small"
            variant="contained"
            startIcon={<StarIcon style={{ color: themeColors.yellow }} />}
            sx={{ padding: '4px 10px' }}
            onClick={() => {
              if (isFav) {
                removeFromList(session?.favouriteId);
              } else {
                addInFavList(session.id);
              }
            }}
          >
            Added to list
          </Button>
        ) : (
          <Button
            variant="contained"
            size="small"
            startIcon={<StarBorderIcon />}
            onClick={() => {
              addInFavList(session.id);
            }}
          >
            Add to list
          </Button>
        )}
        {competition ? <CompetitionProgress report={reports} competition={competition} /> : null}
      </Stack>
      <Stack gap={2} direction="row" mt={3.5}>
        {totalVideos >= 1 ? (
          <Button
            startIcon={<Camera style={{}} />}
            // variant="contained"
            onClick={() => {
              handleViewChange('desc');
            }}
            variant={view === 'desc' ? 'outlined' : 'contained'}
            color={view === 'desc' ? 'secondary' : 'primary'}
          >
            {totalVideos > 1 ? 'Expert Videos' : 'Expert Video'}
          </Button>
        ) : null}
        {session?.howToGuidePdf && session?.howToGuidePdf !== '' && (
          <Button
            startIcon={<Guide />}
            onClick={() => {
              handleViewChange('guide');
            }}
            variant={view === 'guide' ? 'outlined' : 'contained'}
            color={view === 'guide' ? 'secondary' : 'primary'}
          >
            How to Guide
          </Button>
        )}
        {session.showRoleplay && rolePlayUrl ? (
          <Button
            startIcon={<Robot style={{ color: '#ffffff' }} />}
            onClick={onClickRolePlay}
            loading={loading}
            variant="contained"
          >
            AI Role-play
          </Button>
        ) : null}

        {session?.showNote ? (
          <Button
            onClick={() => {
              appHistory.push({ pathname: '/lms/createNotes', state: session });
            }}
            startIcon={<img src={images?.LMS?.squareAdd} />}
            variant="contained"
          >
            Add Notes
          </Button>
        ) : null}
      </Stack>
    </>
  );
}

export default SessionActionBar;
