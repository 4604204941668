import api from 'src/api';
import { isEmpty } from 'lodash';
import { convertObjectToQuerystring } from 'src/utils/helper';

export const fetchBoardTemplates = (filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`boardTemplates${filter}`, null, 'get');
};

export const fetchBoards = (filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`board${filter}`, null, 'get');
};

export const cloneBoard = (boardId, payload) => {
  return api(`board/clone/${boardId}`, payload, 'post');
};

export const createBoard = (payload) => {
  return api(`board`, payload, 'post');
};

export const updateBoard = (boardId, payload) => {
  return api(`board/${boardId}`, payload, 'put');
};

export const deleteBoard = (boardId) => {
  return api(`board/${boardId}`, null, 'delete');
};

export const fetchWidgets = (filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`widget${filter}`, null, 'get');
};

export const addWidgetsToBoard = (boardId, payload) => {
  return api(`board/${boardId}/widgets`, payload, 'put');
};

export const fetchWidgetData = (boardId, widgetId, filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`board/${boardId}/widget/${widgetId}${filter}`, null, 'get');
};

export const useBoardTemplate = (boardId, templateId) => {
  return api(`board/${boardId}/template/${templateId}`, null, 'put');
};

export const removeWidgetFromBoard = (boardId, widgetId) => {
  return api(`board/${boardId}/widget/${widgetId}`, null, 'delete');
};
