import React, { useEffect, useState } from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const HolidayGridActions = ({ drawerToggle, onEdit, onDelete, ...props }) => {
  const { row } = props;
  const [anchorElement, setAnchorElement] = useState(null);

  const menuId = 'action-menu-id';

  const handleActionsMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = (type) => () => {
    const rowData = { id: props.row.index, status: props.row.isActive };
    setAnchorElement(null);
    switch (type) {
      case 'delete': {
        onDelete(props.row.index);
        break;
      }
      case 'edit': {
        onEdit(props.row.index);
        break;
      }
      default:
        break;
    }
  };

  const menuItem = [
    {
      title: 'Edit',
      itemType: 'edit',
    },
    {
      title: 'Delete',
      itemType: 'delete',
    },
  ];

  return (
    <React.Fragment>
      <Typography aria-controls={menuId} aria-haspopup="true" onClick={handleActionsMenuOpen}>
        <MoreVertIcon style={{ fill: '#5780ab' }} />
      </Typography>
      <Menu
        anchorEl={anchorElement}
        id={menuId}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleMenuClose()}
      >
        {menuItem.map((mItem, index) => (
          <MenuItem key={mItem.itemType + index} onClick={handleMenuClose(mItem.itemType)}>
            {mItem.title}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default HolidayGridActions;
