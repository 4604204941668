import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import { Spacer } from 'src/components/shared';
import { Divider } from '@mui/material';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

function ContactTasksSkeleton({ showButtons = true }) {
  return (
    <>
      <ContentWrapper>
        <Skeleton animation="wave" height={40} width={'30%'} />

        <Divider />

        <Skeleton animation="wave" height={80} width={'90%'} />
      </ContentWrapper>
      {showButtons ? (
        <>
          <Spacer x={3} y={3} />
          <PaginationWrapper>
            <Skeleton variant="circular" width={40} height={40} />
            <Spacer basis={2} />
            <Skeleton variant="circular" width={40} height={40} />
            <Spacer basis={2} />
            <Skeleton variant="circular" width={40} height={40} />
            <Spacer basis={2} />
            <Skeleton variant="circular" width={40} height={40} />
          </PaginationWrapper>
        </>
      ) : null}
    </>
  );
}

ContactTasksSkeleton.propTypes = {
  showButtons: PropTypes.bool,
};

ContactTasksSkeleton.defaultProps = {
  showButtons: true,
};

export default ContactTasksSkeleton;
