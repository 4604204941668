import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Divider, Stack, Box, List, Badge, Typography } from '@mui/material';
import { Button, Drawer, Spacer } from 'src/components/shared';
import { Empty, Pagination } from 'src/components/App';
import { useHistory } from 'react-router-dom';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import Item from './Item';
import Skeleton from './Skeleton';

import {
  withNotificationsProvider,
  useNotificationsStore,
} from 'src/modules/app/utils/notificationStore';
import { toggleNotificationsDrawer } from 'src/modules/app/actions/appActions';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';

import { notificationTypes } from 'src/modules/app/config';
import FilterBar from '../FilterBar';
import { isEmpty } from 'lodash';

function Notifications() {
  const {
    notifications,
    notificationCount,
    fetchNotifications,
    clearNotifications,
    getNotificationsCount,
  } = useNotificationsStore();
  const { loading, data, filters } = notifications;
  const dispatch = useDispatch();
  const showNotifications = useSelector((state) => state.app.showNotificationsDrawer);
  const paging = notifications?.paging || {};
  const history = useHistory();

  useEffect(() => {
    getNotificationsCount();
  }, []);

  useEffect(() => {
    if (showNotifications) {
      fetchNotifications(notifications.paging);
    }
  }, [showNotifications]);

  const handleClear = () => {
    fetchNotifications(notifications.paging, {});
  };

  const notificationsFilter = [
    {
      key: 'type',
      title: 'Filters',
      type: 'dropdown',
      cancellable: false,
      searchable: true,
      isPrimary: true,
      checkbox: true,
      multiple: true,
      customHeader: true,
      onClear: handleClear,
      onBlur: filters?.type ? () => null : handleClear,
      apply: true,
      options: notificationTypes,
      icon: <FilterListIcon />,
      showEndIcon: false,
      sx: !filters?.type?.length
        ? {
            '& button': {
              backgroundColor: 'transparent !important',
              '&:hover': { backgroundColor: 'rgba(3, 23, 41, 0.04) !important', boxShadow: 'none' },
            },
          }
        : {},
    },
  ];

  const toggleDrawer = () => {
    dispatch(toggleNotificationsDrawer());
  };

  const handleFilterChange = (key, value) => {
    if (key && value && value.length) {
      fetchNotifications(notifications.paging, {
        [key]: value,
      });
    }
  };

  const handleChange = (filter) => {
    fetchNotifications(
      { pageNo: filter?.page > 0 ? filter?.page : 0, perPage: filter.perPage },
      {},
    );
  };

  return (
    <>
      <Button iconButton onClick={toggleDrawer} size="medium">
        <Badge badgeContent={notificationCount} color="secondary">
          <NotificationsNoneOutlinedIcon />
        </Badge>
      </Button>
      {showNotifications && (
        <Drawer
          showCloseConfirmation={false}
          open={true}
          title={
            <Stack direction={'row'} gap={'12px'}>
              <Typography fontWeight={500} fontSize={'18px'}>
                Notifications
              </Typography>
              {/* <Button
                variant="link"
                onClick={() => {
                  history.push('/profile/notification');
                  toggleDrawer();
                }}
                sx={{
                  padding: 0,
                  '&:hover': { background: 'transparent' },
                  '& .text': {
                    fontSize: '12px',
                    fontWeight: '500',
                    marginLeft: '4px',
                    color: '#1976D2',
                  },
                  '& svg': {
                    color: '#1976D2',
                  },
                }}
              >
                <SettingsRoundedIcon fontSize="20px" />
                <Typography className="text">Manage</Typography>
              </Button> */}
            </Stack>
          }
          disabledPadding={true}
          onClose={toggleDrawer}
        >
          <Stack direction="row" justifyContent="space-between" px={3} pb={0.5}>
            {/* <Button startIcon={<FilterListIcon />}>Filters</Button> */}
            <Stack direction="row" gap={2}>
              <FilterBar
                filters={notificationsFilter}
                appliedFilters={filters}
                onChange={handleFilterChange}
              />
              {/* {filters && !isEmpty(filters) ? (
                <Button
                  variant="text"
                  color="secondary"
                  size="small"
                  disableElevation={true}
                  onClick={handleClear}
                  startIcon={<ClearAllIcon />}
                >
                  Clear Filter
                </Button>
              ) : null} */}
            </Stack>
            <Button onClick={clearNotifications} sx={{ color: '#00000099', fontWeight: 400 }}>
              Clear All
            </Button>
          </Stack>
          <Divider />

          <Box px={2} pt={1}>
            {loading ? (
              <Skeleton />
            ) : (
              <>
                {data.length ? (
                  <List dense>
                    {data.map((item, index) => (
                      <Item item={item} key={`notification-${index}`} />
                    ))}
                  </List>
                ) : (
                  <Empty description="You're all caught up" />
                )}
              </>
            )}
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Pagination
              count={paging.count}
              page={paging.pageNo}
              perPage={paging.perPage}
              onChange={handleChange}
              custom
            />
          </Box>
        </Drawer>
      )}
    </>
  );
}

export default withNotificationsProvider(Notifications);
