import * as React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Chip, Grid, Stack } from '@mui/material';
import { Modal, Button } from 'src/components/shared';

import { getSession } from '../../api/LMSApis';
import CardMedia from '../../shared/cardMedia';
import { removeHTML } from '../../utils/helper';

import { AuthBgSetup, NoImage } from 'src/modules/lms/shared/coursePreview/style';
import MissingImage from '../../shared/MissingImage';

export default function SessionPreview({ open = false, handleClose = () => {}, data }) {
  const [tags, setTags] = React.useState([]);
  const getTags = async () => {
    const session = await getSession(data?.id);
    setTags(session?.session?.tags);
  };
  React.useEffect(() => {
    if (open) {
      getTags();
    }
  }, [open]);

  const tagsN = [
    { id: '10', name: 'Demo' },
    { id: '101', name: 'Demo1' },
  ];

  return (
    <Modal
      open={open}
      onClose={handleClose}
      size="lg"
      sx={{
        '& .MuiPaper-root': {
          background: 'linear-gradient(105deg, #000 -5.01%, #132B4E 134.12%)',
          padding: '12px',
          position: 'relative',
        },
        '& .MuiSvgIcon-root': {
          color: 'rgba(255, 255, 255, 0.54)',
        },
      }}
    >
      <>
        <Grid container spacing={4} sx={{ zIndex: 1, position: 'relative' }}>
          <Grid item xs={12} lg={7}>
            <CardMedia
              src={data?.thumbnailImage}
              alt={data?.title}
              id={data?.id}
              dontShowAction
              contentType={data?.contentType}
              isClickable={false}
              type={'sessionPreview'}
            />
          </Grid>
          <Grid item xs={12} lg={5}>
            <Stack direction="column" justifyContent="space-between" height="100%">
              <Stack direction="column" spacing={1}>
                <Typography variant="body2" color="secondary" fontWeight={300}>
                  Session
                </Typography>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  sx={{ fontSize: '26px', fontWeight: '300' }}
                >
                  {data?.title}
                </Typography>

                {tagsN?.length > 0 ? (
                  <Stack gap={1} direction="row" mt={1} flexWrap="wrap">
                    {tagsN?.map((item, i) => (
                      <Chip
                        variant="outlined"
                        key={`course-tag-${item?.id || ''}-${i}`}
                        label={`${item?.name}`}
                        sx={{ color: '#7F7F7F', borderRadius: '4px', borderColor: '#7F7F7F' }}
                      />
                    ))}
                  </Stack>
                ) : null}

                <Typography variant="body2" color="textSecondary" mt={2}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: removeHTML(
                        data?.overviewText,
                        `/lms/${data?.contentType}/${data?.id}`,
                      ),
                    }}
                  />
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" pr={5}>
          <Link to={`/lms/${data?.contentType}/${data?.id}`}>
            <Button
              variant="contained"
              size="large"
              sx={{ backgroundColor: '#125151', color: '#ffffff' }}
            >
              Go to Session
            </Button>
          </Link>
        </Box>
        <AuthBgSetup />
      </>
    </Modal>
  );
}
