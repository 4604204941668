import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import AppReducer from 'src/modules/app/reducer/appReducer';
import AuthReducer from 'src/modules/auth/reducer/authReducer';
import TasksReducer from 'src/modules/tasks/reducer/tasksReducer';
import SequenceReducer from 'src/modules/sequence/reducer/sequenceReducer';
import ContactsReducer from 'src/modules/contacts/reducer/contactsReducer';
import adminReducer from 'src/modules/admin/adminReducer';
import fieldsReducer from 'src/modules/admin/reducer/fieldsReducer';

import dashboardReducer from 'src/modules/app/reducer/dashboardReducer';
import reportsReducer from 'src/modules/app/reducer/reportsReducer';
import boardReducer from 'src/modules/app/reducer/boardReducer';
import enrolmentReducer from 'src/modules/sequence/reducer/enrolmentReducer';
import resellerReducer from 'src/modules/reseller/reducer/resellerReducer';
import LMSReducer from 'src/modules/lms/reducer/LMSReducer';
import NoteReducer from 'src/modules/notes/reducer/noteReducer';
import CompetitionReducer from 'src/modules/compitions/reducer/competitionReducer';
const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    app: AppReducer,
    auth: AuthReducer,
    tasks: TasksReducer,
    sequence: SequenceReducer,
    contacts: ContactsReducer,
    admin: adminReducer,
    dashboard: dashboardReducer,
    reports: reportsReducer,
    boards: boardReducer,
    enrolment: enrolmentReducer,
    reseller: resellerReducer,
    fields: fieldsReducer,
    lms: LMSReducer,
    notes:NoteReducer,
    competitions: CompetitionReducer
  });
export default createRootReducer;
