import React, { useState, useMemo } from 'react';
import { Box, Typography, Grid, Divider, Card, CardContent, CardActionArea } from '@mui/material';
import { Spacer, Button, Form } from 'src/components/shared';
import { MainGrid, Left, Right, PaperWrap, PlusIcon, Thumb, AddButtonWrapper } from './styles';
import AddIcon from '@mui/icons-material/Add';
import WidgetsIcon from '@mui/icons-material/Widgets';

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const CardThumb = ({ data }) => {
  const randomValue = useMemo(() => {
    return randomIntFromInterval(4, 100);
  }, [data.type]);

  return (
    <Card elevation={1} sx={{ width: 140, height: 140 }}>
      <CardContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 'calc(100% - 40px)',
        }}
      >
        <Box>
          <Typography
            variant="body1"
            color="textPrimary"
            textAlign="center"
            sx={{ fontSize: '20px', fontWeight: 'bold' }}
          >
            {randomValue}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            textAlign="center"
            sx={{ fontSize: '12px' }}
          >
            {data.title}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const Item = ({ data, type, active = false, cardProps = {}, ...props }) => {
  const [loading, setLoading] = useState(false);

  const addWidget = () => {
    setLoading(true);
    props.addWidget(data);
    setLoading(false);
  };

  const removeWidget = () => {
    setLoading(true);
    props.removeWidget(data.id);
    setLoading(false);
  };

  const renderContent = () => {
    return (
      <CardContent {...cardProps}>
        <Box display="flex" justifyContent="space-between" flexDirection="column">
          <PaperWrap elevation={0}>
            {type === 'addNew' ? (
              <PlusIcon />
            ) : (
              <>
                {type === 'widgets' && data.type === 'singleCard' ? (
                  <CardThumb data={data} />
                ) : (
                  <>
                    {data?.icon && data?.icon !== '' ? (
                      <Thumb src={data?.icon} />
                    ) : (
                      <WidgetsIcon sx={{ color: '#dddddd', fontSize: '56px' }} />
                    )}
                  </>
                )}
              </>
            )}
          </PaperWrap>

          <Box flex={1} display="flex" justifyContent="space-between" flexDirection="column">
            <Box>
              <Typography>
                {data?.name
                  ? data?.name
                  : data?.key === 'prospectsOnHold'
                  ? 'Prospects on Hold'
                  : data?.key === 'prospectsInError'
                  ? 'Prospects in Error'
                  : data?.key === 'prospectInSequence'
                  ? 'Prospects Currently in Sequence'
                  : data?.key === 'prospectsClicked'
                  ? 'Emails Clicked'
                  : data?.key === 'prospectsOpened'
                  ? 'Emails Opened'
                  : data?.key === 'prospectsUnsub'
                  ? 'Unsubscribes'
                  : data?.key === 'sequencesReport'
                  ? 'Sequence Activity'
                  : data?.key === 'emailsReport'
                  ? 'Email Performance'
                  : data?.key === 'activityFeedUserProspect'
                  ? 'Live Activity Feed'
                  : data?.title}
              </Typography>
              <Spacer x={0.5} y={0.5} />
              <Typography variant="body2" color="textSecondary">
                {data?.description}
              </Typography>
            </Box>
            <Box>
              {type === 'widgets' ? (
                <AddButtonWrapper>
                  <Form
                    initialValues={{
                      active: active,
                    }}
                    enableReinitialize={true}
                  >
                    <Box display="flex" justifyContent="flex-end" mt={-2} mr={-2}>
                      <Form.Field.Switch
                        name="active"
                        color="success"
                        onChange={(val) => {
                          if (val === true) {
                            addWidget();
                          } else {
                            removeWidget();
                          }
                        }}
                        disabled={loading}
                        label={active ? 'Active' : ''}
                        sx={{ color: 'rgba(46, 125, 50, 1)' }}
                      />
                    </Box>
                  </Form>
                </AddButtonWrapper>
              ) : null}
            </Box>
          </Box>
        </Box>
      </CardContent>
    );
  };

  return (
    <Card>
      <Box sx={{ width: '100%', display: 'block', textAlign: 'unset' }}>
        {type !== 'widgets' ? (
          <CardActionArea onClick={props.onClick}>{renderContent()}</CardActionArea>
        ) : (
          <>{renderContent()}</>
        )}
      </Box>
    </Card>
  );
};

export default Item;
