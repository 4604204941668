import React, { useState, useMemo } from 'react';
import { AppGrid, ConfirmDialog } from 'src/components/App';
import { Modal, Spacer, Button } from 'src/components/shared';
import { Box, Typography } from '@mui/material';
import { useSequenceColumns } from './useSequenceColumns';
import CloneSequenceForm from '../../components/Sequence/CreateSequence/CloneSequenceForm';
import { fetchContactsLookup } from 'src/modules/contacts/api/contactApis';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';
import SequenceStatusForm from '../../components/Sequence/SequenceStatusForm';
import WarningIcon from '@mui/icons-material/Warning';
import { sequenceBulkActions } from '../../config';
import AddIcon from '@mui/icons-material/Add';

import { updateSequence } from '../../api/sequenceApi';

import { isEmpty } from 'lodash';

const SequencesGrid = ({ fields, sequences, loading, ...props }) => {
  const [rowsSelected, setRowsSelected] = useState([]);
  const [showCloneForm, setShowCloneForm] = useState(false);
  const [cloneSequence, setCloneSequence] = useState({});
  const [bulkAction, setBulkAction] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { data, paging, filters, sort } = sequences;

  const handleSequenceStatusChange = async (sequenceId, status) => {
    try {
      await updateSequence(sequenceId, { status });
      // props.fetchSequences(paging, filters, sort);
    } catch (error) {}
  };
  //

  const handleCloneSequence = (sequence) => {
    setShowCloneForm(true);
    setCloneSequence(sequence);
  };

  const sequenceColumns = useSequenceColumns(
    data,
    fields,
    props.onEdit,
    props.onDelete,
    props.toggleContactDrawer,
    handleCloneSequence,
    handleSequenceStatusChange,
  );

  const sequenceFilters = [
    {
      key: 'assignedTo',
      title: 'Assigned to',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: true,
      searchable: true,
      isPrimary: true,
      remoteMethod: async (value) => {
        return fetchUsersLookup(value);
      },
    },
    {
      key: 'audienceEmailType',
      title: 'Email type',
      type: 'dropdown',
      remote: false,
      options: props.sequenceEmailTypeList,
      optLabel: 'label',
      optValue: 'value',
      isPrimary: false,
      multiple: true,
    },
    {
      key: 'accessType',
      title: 'Access level',
      type: 'dropdown',
      remote: false,
      options: props.sequenceAccessTypeList,
      optLabel: 'label',
      optValue: 'value',
      isPrimary: false,
      multiple: true,
    },
    {
      key: 'createdAtRange',
      title: 'Created date',
      type: 'dateRange',
    },
  ];

  const handleEditPopupOpen = () => {};

  const changeStatus = () => {};

  const handleCopySequence = () => {};

  const handleEnrollContact = () => {};

  const handleTableChange = ({ pageNo, perPage }) => {
    props.fetchSequences(
      {
        pageNo,
        perPage,
      },
      filters,
    );
  };

  const onFilterChange = (key, value) => {
    if (value && typeof value !== 'undefined') {
      if (key === '_search') {
        props.fetchSequences(
          {
            ...paging,
            pageNo: 0,
          },
          { ...filters, [key]: value },
        );
      } else {
        props.fetchSequences(paging, { ...filters, [key]: value });
      }
    } else {
      delete filters[key];
      props.fetchSequences(paging, filters);
    }
  };

  const onClearFilters = () => {
    Object.keys(filters).forEach(function (filter) {
      if (filter != '_search') delete filters[filter];
    });
    props.fetchSequences(paging, filters);
  };

  const selectedRows = useMemo(
    () => (rowsSelected.length ? data.filter((item, index) => rowsSelected.includes(index)) : []),
    [rowsSelected, data],
  );

  const onBulkAction = (action) => {
    setBulkAction(action);
  };

  const onSort = (sort) => {
    props.fetchSequences(paging, filters, sort);
  };

  const closeBulkAction = () => {
    setBulkAction(undefined);
    setRowsSelected([]);
  };

  const bulkDeleteSequences = async () => {
    setIsDeleting(true);
    await props.sequenceBulkAction(bulkAction, selectedRows, null);
    setIsDeleting(false);
    closeBulkAction();
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    await props.sequenceBulkAction(bulkAction, selectedRows, values);
    setIsLoading(false);
    closeBulkAction();
  };

  return (
    <>
      <AppGrid
        selectableRows="multiple"
        columns={sequenceColumns.map((col, index) => ({
          ...col,
          options: sequenceColumns?.columns?.length
            ? { ...col.options, ...sequenceColumns?.columns[index] }
            : col.options,
        }))}
        data={data}
        onTableChange={handleTableChange}
        onFilterChange={onFilterChange}
        onClearFilters={onClearFilters}
        onColumnChange={() => handleColumnChange(false)}
        onSort={onSort}
        loading={loading}
        filters={sequenceFilters}
        appliedFilters={filters}
        bulkActions={sequenceBulkActions}
        onBulkAction={onBulkAction}
        options={{
          sortOrder: {
            name: sort?.name || 'none',
            direction: sort?.direction || 'none',
          },
          serverSide: true,
          pagination: true,
          page: paging.pageNo,
          rowsPerPage: paging.perPage,
          count: paging.count,
          searchPlaceholder: 'Search Sequences',
          search: true,
          searchText: filters?._search ? filters._search : '',
          rowsSelected: rowsSelected,
          onRowsSelect: (rowsSelected, allRows) => {
            setRowsSelected(allRows.map((row) => row.dataIndex));
          },
          renderCustomFilterActions: () => {
            return (
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                // startIcon={<AddIcon />}
                onClick={() => {
                  props.toggleAddSequenceForm();
                }}
                disableElevation
              >
                <Typography variant="body2">Create sequence</Typography>
              </Button>
            );
          },
        }}
      />

      <Modal
        title="Clone Sequence"
        size="xs"
        open={showCloneForm}
        onClose={() => {
          setShowCloneForm(false);
          setCloneSequence({});
        }}
      >
        <CloneSequenceForm cloneSequence={props.cloneSequence} sequence={cloneSequence} />
      </Modal>

      <Modal
        title="Change status"
        size="xs"
        open={bulkAction === 'changeStatus'}
        onClose={closeBulkAction}
      >
        <SequenceStatusForm
          handleSubmit={handleSubmit}
          cloneSequence={props.cloneSequence}
          sequence={cloneSequence}
          onClose={closeBulkAction}
          isLoading={isLoading}
        />
      </Modal>

      <ConfirmDialog
        title="Delete Sequence(s)"
        open={bulkAction === 'delete'}
        onClose={closeBulkAction}
        onConfirm={bulkDeleteSequences}
        loading={isDeleting}
      >
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <Typography variant="body1" color="textSecondary">
            Are you sure you want to delete selected sequences.
          </Typography>
        </Box>
      </ConfirmDialog>
    </>
  );
};

export default SequencesGrid;
