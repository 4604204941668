import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';
import { Box, ButtonBase, Typography } from '@mui/material';
import { Spacer, Modal, Drawer } from 'src/components/shared';
import { ConfirmDialog } from 'src/components/App';
import ContactCard from '../contacts/ContactCard';
import { UserCardSkeleton } from './Skeleton';
import EditPanelForm from './EditPanelForm';
import PanelForm from './PanelForm';
import AddContactForm from './AddContactForm';

import {
  fetchPanelContacts,
  removeContactFromPanel,
  handleAddContactToPanel,
} from '../../../actions/appActions';
import {
  List,
  Title,
  SubTitle,
  DropList,
  PanelHeader,
  EditButton,
  AddContactButton,
} from './styles';

import EditIcon from '@mui/icons-material/Edit';

function Panel({ panel, putPanel, deletePanel, panelSearch }) {
  const [showActions, setShowActions] = useState(false);
  const [showAddContactsDrawer, setShowAddContactsDrawer] = useState(false);
  const [showAddButton, setShowAddButton] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [editPanel, setEditPanel] = useState({});
  const [deleting, setDeleting] = useState(false);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const dispatch = useDispatch();

  const addContactToPanel = (contact) => {
    return new Promise((resolve, reject) => {
      dispatch(handleAddContactToPanel(panel.id, contact, resolve, reject));
    });
  };

  useEffect(() => {
    dispatch(fetchPanelContacts(panel.id));
  }, [panel.id]);

  const panelContacts = useMemo(() => {
    if (panelSearch && panelSearch !== '') {
      const searchText = panelSearch.toLowerCase();
      return panel.contacts.filter((contact) => {
        return (
          contact.contact?.fname.toLowerCase().includes(searchText) ||
          contact.contact?.lname.toLowerCase().includes(searchText) ||
          contact.contact?.jobTitle.toLowerCase().includes(searchText) ||
          contact.contact?.employer.toLowerCase().includes(searchText) ||
          `${contact.contact?.fname} ${contact.contact?.lname}`.toLowerCase().includes(searchText)
        );
      });
    }
    return panel.contacts;
  }, [panel.contacts, panelSearch]);

  if (panel?.loading && panel.loading === true) {
    return (
      <List color={panel?.tabColor || ''}>
        <PanelHeader
          onMouseEnter={() => setShowActions(true)}
          onMouseLeave={() => setShowActions(false)}
          color={panel?.tabColor || ''}
        >
          <>
            <Title color={panel?.tabColor || ''}>
              {panel.name}
            </Title>
            <SubTitle variant="body2">{panelContacts.length} Prospects</SubTitle>
            {showActions ? (
              <EditButton
                onClick={() => {
                  setShowEditForm(true);
                  setEditPanel(panel);
                }}
              />
            ) : null}
          </>
        </PanelHeader>
        <DropList>
          <UserCardSkeleton />
        </DropList>
      </List>
    );
  }

  const handleClose = () => {
    setShowActions(false);
    setEditPanel({});
    setShowEditForm(false);
  };

  const handleDelete = (contactId) => {
    dispatch(removeContactFromPanel(panel.id, contactId));
  };

  const handleDeletePanel = async () => {
    setDeleting(true);
    await deletePanel(panel.id);
    setDeleting(false);
    handleClose();
  };

  return (
    <>
      <Droppable key={panel.id} droppableId={panel.id}>
        {(provided) => (
          <List
            color={panel?.tabColor || ''}
            onMouseEnter={() => setShowAddButton(true)}
            onMouseLeave={() => setShowAddButton(false)}
          >
            <PanelHeader
              onMouseEnter={() => setShowActions(true)}
              onMouseLeave={() => setShowActions(false)}
              color={panel?.tabColor || ''}
            >
              <>
                <Title color={panel?.tabColor || ''}>
                  {panel.name}
                </Title>
                <SubTitle variant="body2">{panelContacts?.filter(({contact}) => contact?.id).length} Prospects 123</SubTitle>
                {showActions ? (
                  <EditButton
                    onClick={() => {
                      setShowEditForm(true);
                      setEditPanel(panel);
                    }}
                  />
                ) : null}
              </>
            </PanelHeader>

            <DropList {...provided.droppableProps} ref={provided.innerRef}>
              {panelContacts.filter(({contact}) => contact?.id).map((contact, index) => {
                return (
                  <ContactCard
                    showEdit={true}
                    index={index}
                    panelId={panel.id}
                    contact={contact.contact}
                    key={`panel-${panel.id}-contact-${index}`}
                    draggableId={`panel-${panel.id}-contact-${index}`}
                    handleDelete={handleDelete}
                  />
                );
              })}
              {showAddButton ? (
                <AddContactButton
                  onClick={() => {
                    setShowAddContactsDrawer(true);
                  }}
                />
              ) : null}
            </DropList>
          </List>
        )}
      </Droppable>

      <ConfirmDialog
        title="Delete panel"
        open={showDeleteDialog}
        onClose={() => {
          setShowDeleteDialog(false);
        }}
        loading={deleting}
        onConfirm={handleDeletePanel}
      >
        <Typography variant="body1" color="textSecondary">
          Are you sure you want to delete <b>{panel?.name}</b> panel?
        </Typography>
      </ConfirmDialog>

      <Modal title="Edit Panel" size="xs" open={showEditForm} onClose={handleClose}>
        <PanelForm
          onClose={handleClose}
          panel={editPanel}
          putPanel={putPanel}
          deletePanel={deletePanel}
          setShowDeleteDialog={setShowDeleteDialog}
        />
      </Modal>

      <Drawer
        title="Add contact"
        open={showAddContactsDrawer}
        onClose={() => {
          setShowAddContactsDrawer(false);
        }}
      >
        <AddContactForm
          onClose={() => {
            setShowAddContactsDrawer(false);
          }}
          panel={panel}
          addContactToPanel={addContactToPanel}
        />
      </Drawer>
    </>
  );
}

export default Panel;
