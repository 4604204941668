import { makeStyles } from '@mui/styles';
import previewImage from '../../../../img/previewbackground.png';
import { themeColors } from '../../config';
const useStyles = makeStyles(({ palette }) => ({
  CoursePreview: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '78%',
    height: 'fit-content',
    background: 'linear-gradient(104.75deg, #000000 -5.01%, #132B4E 134.12%), #FFFFFF',
    borderRadius: '10px',
    '&:focus-visible ': {
      outline: 'none !important',
    },
  },
  sessionBtn: {},
  imgContainer: {
    backgroundImage: `url(${previewImage})`,
    backgroundPosition: '-62% 73%',
    backgroundSize: '700px 700px',
  },
  gradient: {
    borderRadius: '10px',
    backgroundImage: `linear-gradient(-90.25deg, #132B4E -20px, #000000 950px,transparent 1200px)`,
  },
  tagButton: {
    color: '#7F7F7F',
    background: 'transparent',
    border: '1px solid #7F7F7F',
    fontWeight: '400',
    fontSize: '12px',
    marginLeft: '10px',
    marginBottom: '10px',
    textTransform: 'none',
    height: '28px',
  },
  close: {
    color: 'rgba(255, 255, 255, 0.54)',
    display: 'flex',
    justifyContent: 'right',
    width: '4%',
    paddingTop: '2%',
    paddingRight: '1%',
  },
  button: {
    height: '47px',
    background: '#0F2E49',
    borderRadius: '10px',
    color: themeColors.white,
    border: '1px solid #0F2E49',
    fontWeight: '400',
    fontSize: '16px',
    marginRight: '10px',
    textTransform: 'capitalize',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    width: '200px',
    '&:hover': {
      color: themeColors.yellow,
      border: '1px solid',
      borderColor: themeColors.yellow,
      background: '#0F2E49',
    },
  },
  actionButton: {
    height: '47px',
    width: '180px',
    background: themeColors.greenButton,
    borderRadius: '3px',
    color: '#FFFFFF',
    fontWeight: '700',
    fontSize: '16px',
    textTransform: 'none',
  },
  actionButtonContainer: {
    marginTop: '25%',
  },
  buttonList: {
    display: 'flex',
    justifyContent: 'left',
    marginLeft: '96px',
  },
  Container: {
    padding: '46px 90px 90px 90px',
    display: 'flex',
    justifyContent: 'space-between',
    '&:focus-visible ': {
      outline: 'none !important',
    },
  },
  sessionPreviewText: {
    paddingTop: '90px',
    paddingLeft: '20px',
    paddingRight: '90px',
  },
  header: {
    marginBottom: '20px',
  },
  normalFont: {
    fontWeight: '300',
    fontSize: '14px',
  },
  title: {
    color: '#EDEDED',
    fontWeight: '400',
    fontSize: '26px',
    marginBottom: '10px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'inline-block',
    textDecoration: 'none',
    width: '90%',
  },
  categoryHeader: {
    color: '#FFD47E',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 300,
  },
  description: {
    color: '#D2D2D2',
    width: '100%',
    lineHeight: '21px',
    fontWeight: 300,
    fontSize: '14px',
  },
  sessionPreviewImage: {
    padding: '90px',
  },
}));
export default useStyles;
