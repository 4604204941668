import React from 'react';
import { Button, Spacer } from 'src/components/shared';
import { Stack, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

function Pagination({ count, page, perPage, onChange, custom }) {
  let status = [true, true];
  const start = count > 0 ? page * perPage + 1 : 0;
  let end = page * perPage + perPage;

  if (end > count) {
    status[1] = false;
    end = count;
  }
  if (page == 0) {
    status[0] = false;
  }

  const handlePrev = () => {
    onChange({ page: page - 1, perPage });
  };

  const handleNext = () => {
    onChange({ page: page + 1, perPage });
  };

  return (
    <>
      <Stack direction="row" alignItems="center">
        {custom && (
          <>
            <Button iconButton disabled={!status[0]} onClick={handlePrev}>
              <KeyboardArrowLeftIcon />
            </Button>
            <Spacer x={2} />
          </>
        )}
        <Typography color="textSecondary" variant="body2">
          {start}-{end} of {count}
        </Typography>
        <Spacer x={2} />
        {!custom && (
          <Button iconButton disabled={!status[0]} onClick={handlePrev}>
            <KeyboardArrowLeftIcon />
          </Button>
        )}
        <Button iconButton disabled={!status[1]} onClick={handleNext}>
          <KeyboardArrowRightIcon />
        </Button>
      </Stack>
    </>
  );
}

export default Pagination;
