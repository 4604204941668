import { createContext, useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
const resellerContext = createContext({});

import { getResellerSummary, getResellerSummaryStats } from '../api/resellerApis';
import { isEmpty } from 'lodash';
import { getRelativeDateRange } from 'src/utils/dateUtils';

export const useResellerSummary = () => {
  const { referrals, referralSummary, fetchResellerSummary, fetchResellerSummaryStats } =
    useContext(resellerContext);
  return { referrals, referralSummary, fetchResellerSummary, fetchResellerSummaryStats };
};

export const ResellerStore = ({ children }) => {
  const [referralSummary, setReferralSummary] = useState({
    loading: false,
    stats: [],
    filters: {},
    cards: {
      duplicate: 0,
      notSequenced: 0,
      sequenced: 0,
      total: 0,
    },
  });

  const [referrals, setReferrals] = useState({
    loading: false,
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    data: [],
  });

  const fetchResellerSummaryStats = async (newFilter) => {
    try {
      setReferralSummary((prevState) => ({
        ...prevState,
        loading: true,
        stats: [],
        filters: newFilter,
        cards: {
          duplicate: 0,
          notSequenced: 0,
          sequenced: 0,
          total: 0,
        },
      }));
      const createdAt = newFilter?.createdAtRange || {};
      let filter = {};
      if (createdAt && !isEmpty(createdAt)) {
        const dateRange = getRelativeDateRange(createdAt);
        filter = {
          createdAt_gte: dateRange.start,
          createdAt_lte: dateRange.end,
        };
      }
      const res = await getResellerSummaryStats(filter);
      setReferralSummary((prevState) => ({
        ...prevState,
        loading: false,
        stats: res.stats,
        cards: res.card,
      }));
    } catch (error) {
      setReferralSummary((prevState) => ({
        ...prevState,
        loading: false,
        stats: [],
        cards: {
          duplicate: 0,
          notSequenced: 0,
          sequenced: 0,
          total: 0,
        },
      }));
    }
  };

  const fetchResellerSummary = async (newPage, newFilter) => {
    try {
      setReferrals((prevState) => ({
        ...prevState,
        loading: true,
        data: [],
        filters: newFilter,
        paging: {
          ...prevState.paging,
          pageNo: newPage.pageNo,
          perPage: newPage.perPage,
        },
      }));

      let filter = {
        _from: newPage.pageNo * newPage.perPage,
        _size: newPage.perPage,
        ...(newFilter?.followup
          ? {
              followup: newFilter?.followup.value,
            }
          : {}),
        ...(newFilter?.enrolledBy
          ? {
              enrolledBy: newFilter?.enrolledBy.id,
            }
          : {}),
      };

      const createdAt = newFilter?.createdAtRange || {};
      if (createdAt && !isEmpty(createdAt)) {
        const dateRange = getRelativeDateRange(createdAt);
        filter = {
          createdAt_gte: dateRange.start,
          createdAt_lte: dateRange.end,
          ...filter,
        };
      }
      const res = await getResellerSummary(filter);
      setReferrals((prevState) => ({
        ...prevState,
        data: res.stats,
        loading: false,
        paging: {
          pageNo: newPage.pageNo,
          perPage: newPage.perPage,
          count: res.total,
        },
      }));
    } catch (error) {
      setReferrals((prevState) => ({
        ...prevState,
        paging: {
          pageNo: 0,
          perPage: 10,
          count: 0,
        },
        filters: {},
        data: [],
      }));
    }
  };

  return (
    <resellerContext.Provider
      value={{
        referrals,
        referralSummary,
        fetchResellerSummary,
        fetchResellerSummaryStats,
      }}
    >
      {children}
    </resellerContext.Provider>
  );
};

export const withResellerStore = (Component) => (props) =>
  (
    <ResellerStore>
      <Component {...props} />
    </ResellerStore>
  );
