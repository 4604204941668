import { themeColors } from '../../config';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({ palette }) => ({
  container: {
    background: '#082037',
    borderRadius: '10px',
    padding: '31px 31px 50px 31px',
  },

  form: {
    background: '#19324A',
    padding:'2%',
    borderRadius:'7px',

    '& iframe': {
      backgroundColor: '#19324A !important ',
    },
    '& .tox .tox-toolbar__primary': {
      backgroundColor: '#19324A !important ',
      color: '#D9D9D9 !important',
      border: '1px solid #5F5F5F !important ',
    },
    '& .tox-tinymce': {
      border: 'none !important',
    },
    '& .tox-tbtn--select': {
      color: '#D9D9D9 !important',
    },
    '& .tox .tox-tbtn svg ': {
      fill: '#D9D9D9 !important',
    },
    '.css-wax5jp': {
      backgroundColor: '#19324A !important ',
    },
    '& content': {
      color: '#D9D9D9 !important',
    },
    ' & .tox-tbtn': {
      '&:hover': {
        background: '#082037 !important',
      },
    },
    '& .css-i4bv87-MuiSvgIcon-root': {
      color: 'white !important',
    },
    '& .MuiAutocomplete-input': {
      color: 'white !important',
    },
  },
  heading: {
    fontWeight: '300',
    fontSize: '26px',
    color: themeColors.greyHeadingText,
  },
  paragraph: {
    fontWeight: '400',
    fontSize: '18px',
    color: themeColors.greyParagraphText,
    lineHeight: '28px',
  },
  links: {
    color: themeColors.greyText,
    fontWeight: '300',
    fontSize: '12px',
  },
  button: {
    background: '#CF0175',
    borderRadius: '3.30904px',
    marginRight: '12px',
    marginTop: '30px',
    color: '#ffff',
    textTransform:'capitalize'
  },
}));
export default useStyles;
