export const getOverrides = (
  paperWidth,
  sideBarIsOpen,
  paperHeight,
  stickyTD,
  stickyCol,
  hideToolBar,
) => {
  return {
    MUIDataTable: {
      paper: {
        maxWidth: `calc(100vw - ${
          paperWidth.substr(0, paperWidth.length - 2) -
          (sideBarIsOpen ? 0 : 175)
        }px)`,
        transition: 'max-width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
      responsiveBase: {
        maxHeight: `calc(100vh - ${paperHeight}) !important`,
        border: '1px solid rgba(224, 224, 224, 1)',
        backgroundColor: '#ffffff',
        borderRadius: 4,
      },
    },
    MuiTableRow: {
      hover: {},
      root: {},
    },
    MuiSelected: {},
    MuiTablePagination: {
      caption: {
        fontFamily: ['Roboto', 'sans-serif'].join(','),
      },
      actions: {
        minWidth: 100,
      },
    },
    MuiTableHead: {
      root: {
        [`& th:nth-of-type(${stickyTD})`]: stickyCol
          ? {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: stickyTD === 1 ? 0 : 42,
              // background: colorCodeConstant.white,
              zIndex: 101,
            }
          : {},
      },
    },
    MuiTableBody: {
      root: {
        [`& td:nth-of-type(${stickyTD})`]: stickyCol
          ? {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: stickyTD === 1 ? 0 : 42,
              // background: colorCodeConstant.white,
              zIndex: 100,
            }
          : {},
        '& .mui-row-selected': {
          background: '#ffffff !important',
          [`& td:nth-of-type(${stickyTD})`]: stickyCol
            ? {
                background: '#ffffff',
              }
            : {},
        },
        '& tr': {
          height: 50,
        },
      },
    },
    // MUIDataTableBodyCell: {
    //   root: {
    //     backgroundColor: '#ff0000',
    //     padding: '8px !important',
    //   },
    // },
    MuiTableCell: {
      root: {
        padding: '4px !important',
      },
      head: {
        color: 'rgba(0, 0, 0, 0.38) !important',
        '& .MuiButtonBase-root': {
          color: 'rgba(0, 0, 0, 0.38) !important',
        },
      },
    },
    '& .MUIDataTableHeadCell-sortAction': {
      sortAction: {
        color: 'rgba(0, 0, 0, 0.38) !important',
      },
    },
    MUIDataTableSelectCell: {
      headerCell: {
        backgroundColor: 'blue',
      },
    },
    MUIDataTableToolbar: {
      root: hideToolBar
        ? { display: 'none' }
        : {
            padding: 0,
            '& .MUIDataTableSearch-searchBox': {
              backgroundColor: '#ffffff',
            },
          },
    },
    MUIDataTableToolbarSelect: {
      root: {
        boxShadow: 'none',
        borderRadius: 0,
        border: `none`,
        borderBottom: 'none',
        justifyContent: 'start',
        '& :nth-of-type(1)': {
          '& h6': {
            display: 'none',
          },
        },
      },
    },
    MUIDataTableFooter: {
      root: {},
    },
    MUIDataTableSelectCell: {
      root: {
        width: 42,
        minWidth: 42,
        maxWidth: 42,
        backgroundColor: 'inherit',
      },
      fixedLeft: {},
      checkboxRoot: { '&$checked': { color: 'rgba(0, 0, 0, 0.54)' } },
    },
    MUIDataTableHeadCell: {},
  };
};
