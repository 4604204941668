import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Menu } from 'src/components/shared';
import InfoIcon from '@mui/icons-material/Info';
import PauseIcon from '@mui/icons-material/Pause';
import DoneIcon from '@mui/icons-material/Done';
import MailIcon from '@mui/icons-material/Mail';
import { sequenceEnrollmentStatus } from 'src/modules/contacts/config';
import { useCallback } from 'react';
import ContactDetailsForm from 'src/modules/contacts/components/ContactDetails/ContactDetailsForm';
import { Modal } from 'src/components/shared';
import { setReEnrolmentErrors } from 'src/modules/sequence/actions/enrolmentActions';
import { useState } from 'react';
import BounceActions from 'src/modules/sequence/actions/BounceActions';
import { bounceWarningMessage } from 'src/modules/tasks/config';

const { IN_PROGRESS, COMPLETED, HOLD, FAILED, BOUNCED, ERROR, INTERESTED, NOT_INTERESTED } =
  sequenceEnrollmentStatus;

function MenuAction({ sequence, putEnrollment, disabled }) {
  const user = useSelector((state) => state.auth.user);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [bounceEmail, setBounceEmail] = useState(sequence?.selectedEmailId);
  const [showBounceForm, setShowBounceForm] = useState(false);
  const dispatch = useDispatch();

  const onEmailModalClose = () => setShowEmailModal(false);
  const emailConnected = useMemo(() => {
    if (user?.nlAccessToken && user?.nlAccessToken !== '') {
      return true;
    }
    return false;
  }, [user]);

  const sequenceOptions = [
    ...(emailConnected === true
      ? [
          {
            label: `${sequence.status === IN_PROGRESS ? 'Pause' : 'Resume'}`,
            icon: <PauseIcon />,
            onClick: () => {
              putEnrollment(sequence.id, {
                status: `${sequence.status === IN_PROGRESS ? HOLD : IN_PROGRESS}`,
              });
            },
          },
        ]
      : []),
    {
      label: 'Completed - Interested',
      icon: <DoneIcon />,
      onClick: () => {
        putEnrollment(sequence.id, {
          status: `${sequence.status && INTERESTED}`,
        });
      },
    },
    {
      label: 'Completed - Not Interested',
      icon: <InfoIcon />,
      onClick: () => {
        putEnrollment(sequence.id, {
          status: `${sequence.status && NOT_INTERESTED}`,
        });
      },
    },
  ];
  const bounceOption = [
    {
      label: 'Update selected email address',
      icon: <MailIcon />,
      onClick: () => {
        setShowEmailModal(true);
      },
    },
  ];

  const onNextBounceError = ({ email, type }) => {
    setShowEmailModal(false);
    setShowBounceForm(true);
    setBounceEmail(email);
  };

  const onSubmitBounceNext = async (type) => {
    dispatch(
      setReEnrolmentErrors(sequence.id, {
        reenroll: type,
        email: bounceEmail,
      }),
    );
    await setShowBounceForm(false);
    //history.push(`/tasks/errors`);
  };

  const closeShowBounceForm = () => {
    setShowBounceForm(false);
  };

  const getOptions = useCallback((status) => {
    switch (status) {
      case FAILED:
      case IN_PROGRESS:
      case HOLD:
      case ERROR:
        return sequenceOptions;

      case NOT_INTERESTED:
        return [
          ...sequenceOptions.slice(0, 1),
          {
            label: 'Interested',
            icon: <DoneIcon />,
            onClick: () => {
              putEnrollment(sequence.id, {
                status: `${sequence.status && INTERESTED}`,
              });
            },
          },
        ];

      case INTERESTED:
        return [
          ...sequenceOptions.slice(0, 1),
          {
            label: 'Not Interested',
            icon: <InfoIcon />,
            onClick: () => {
              putEnrollment(sequence.id, {
                status: `${sequence.status && NOT_INTERESTED}`,
              });
            },
          },
        ];

      case BOUNCED:
        return bounceOption;

      case COMPLETED:
      default:
        return sequenceOptions.slice(0, 1);
    }
  });
  return (
    <>
      <Menu
        title=""
        disabled={disabled}
        color="secondary"
        buttonStyles={{ padding: '0px' }}
        iconButton={true}
        options={getOptions(sequence.status)}
      />
      <Modal
        open={showEmailModal}
        onClose={onEmailModalClose}
        title={bounceWarningMessage}
        size="xs"
      >
        <ContactDetailsForm
          formType={'email'}
          formData={{ email: bounceEmail, type: '' }}
          onSubmit={onNextBounceError}
          onClose={onEmailModalClose}
          submitButtonText={'Next'}
          showOnlyEmail
        />
      </Modal>
      <Modal
        open={showBounceForm}
        onClose={closeShowBounceForm}
        backdrop={false}
        title="How you would like to proceed?"
      >
        <BounceActions onClick={onSubmitBounceNext} />
      </Modal>
    </>
  );
}

export default MenuAction;
