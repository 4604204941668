import * as types from '../actions/contactTypes';
import { RESET_STORE } from 'src/modules/app/actions/appTypes';

const initialState = {
  loading: {
    tabs: false,
    contacts: false,
    contact: false,
    contactTask: false,
    contactNote: false,
    contactNotes: false,
    sequenceEnrollments: false,
    sequenceEnrollment: false,
    bullhornMatches: false,
    vincereMatches: false,
    jobAdderMatches: false,
    salesforceMatches: false,
    hubspotMatches: false,
    connectingContact: false,
    fetchContact: false,
  },
  showContactProfile: false,
  contactTabs: [],
  contacts: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    data: [],
    columns: [],
    columnsOrder: [],
  },
  contactOptions: {},
  contact: {},
  contactTask: [],
  contactNote: [],
  contactNotes: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    data: [],
  },
  sequenceEnrollments: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    data: [],
  },
  contactTasks: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    data: [],
  },
  sequenceEnrollment: [],
  selectedContactId: 0,
  bullhornMatches: [],
  bullhornMatch: {},
  vincereMatches: [],
  vincereMatch: {},
  salesforceMatches: [],
  salesforceMatch: {},
  hubspotMatches: [],
  hubspotMatch: {},
  jobAdderMatches: [],
  jobAdderMatch: {},
  selectedContactIds: [],
};

const ContactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_CONTACT_DATA:
      return {
        ...state,
        contact: {},
        contactTasks: {
          paging: {
            pageNo: 0,
            perPage: 10,
            count: 0,
          },
          filters: {},
          data: [],
        },
        contactNotes: {
          paging: {
            pageNo: 0,
            perPage: 10,
            count: 0,
          },
          filters: {},
          data: [],
        },
        sequenceEnrollments: {
          paging: {
            pageNo: 0,
            perPage: 10,
            count: 0,
          },
          filters: {},
          data: [],
        },
        contactTask: [],
        contactNote: [],
      };

    case types.FETCH_BULLHORN_MATCHES:
      return {
        ...state,
        loading: {
          ...state.loading,
          bullhornMatches: true,
        },
        bullhornMatches: {
          matches: [],
          match: {},
        },
        bullhornMatches: [],
        bullhornMatch: {},
      };

    case types.SET_BULLHORN_MATCHES:
      return {
        ...state,
        loading: {
          ...state.loading,
          bullhornMatches: false,
        },
        bullhornMatches: action.matches,
        bullhornMatch: action.match,
      };

    case types.FETCH_VINCERE_MATCHES:
      return {
        ...state,
        loading: {
          ...state.loading,
          vincereMatches: true,
        },
        vincereMatches: {
          matches: [],
          match: {},
        },
        vincereMatches: [],
        vincereMatch: {},
      };

    case types.SET_VINCERE_MATCHES:
      return {
        ...state,
        loading: {
          ...state.loading,
          vincereMatches: false,
        },
        vincereMatches: action.matches,
        vincereMatch: action.match,
      };

    case types.FETCH_SALESFORCE_MATCHES:
      return {
        ...state,
        loading: {
          ...state.loading,
          salesforceMatches: true,
        },
        salesforceMatches: {
          matches: [],
          match: {},
        },
        salesforceMatches: [],
        salesforceMatch: {},
      };

    case types.SET_SALESFORCE_MATCHES:
      return {
        ...state,
        loading: {
          ...state.loading,
          salesforceMatches: false,
        },
        salesforceMatches: action.matches,
        salesForceMatch: action.match,
      };

    case types.FETCH_HUBSPOT_MATCHES:
      return {
        ...state,
        loading: {
          ...state.loading,
          hubspotMatches: true,
        },
        hubspotMatches: {
          matches: [],
          match: {},
        },
        hubspotMatches: [],
        hubspotMatch: {},
      };

    case types.SET_HUBSPOT_MATCHES:
      return {
        ...state,
        loading: {
          ...state.loading,
          hubspotMatches: false,
        },
        hubspotMatches: action.matches,
        hubspotMatch: action.match,
      };

    case types.FETCH_JOBADDER_MATCHES:
      return {
        ...state,
        loading: {
          ...state.loading,
          jobAdderMatches: true,
        },
        jobAdderMatches: {
          matches: [],
          match: {},
        },
        jobAdderMatches: [],
        jobAdderMatch: {},
      };

    case types.SET_JOBADDER_MATCHES:
      return {
        ...state,
        loading: {
          ...state.loading,
          jobAdderMatches: false,
        },
        jobAdderMatches: action.matches,
        jobAdderMatch: action.match,
      };

    case types.FETCH_CONTACT:
      return {
        ...state,
        loading: {
          ...state.loading,
          contact: true,
        },
        contact: {},
      };

    case types.SET_CONTACT:
      return {
        ...state,
        loading: {
          ...state.loading,
          contact: false,
        },
        contact: action.contact,
      };

    case types.SET_CONTACT_CRM_CONNECTION:
      return {
        ...state,
        loading: {
          ...state.loading,
          connectingContact: action.loading,
        },
      };

    case types.SET_FETCH_CONTACT_CRM_CONNECTION:
      return {
        ...state,
        loading: {
          ...state.loading,
          fetchContact: action.loading,
        },
      };

    case types.SET_CONTACT_TASK:
      return {
        ...state,
        loading: {
          ...state.loading,
          contactTask: false,
        },
        contactTask: action.tasks,
      };

    case types.SET_CONTACT_NOTE:
      return {
        ...state,
        loading: {
          ...state.loading,
          contactNote: false,
        },
        contactNote: action.notes,
      };

    case types.FETCH_CONTACT_TASKS:
      return {
        ...state,
        loading: {
          ...state.loading,
          contactTasks: true,
        },
        contactTasks: {
          ...state.contactTasks,
          data: [],
          paging: {
            ...state.contactTasks?.paging,
            ...action.paging,
          },
          filters: {
            ...state.contactTasks?.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };

    case types.SET_CONTACT_TASKS:
      return {
        ...state,
        loading: {
          ...state.loading,
          contactTasks: false,
        },
        contactTasks: {
          ...state.contactTasks,
          data: action.data.tasks,
          paging: {
            ...state.contactTasks.paging,
            count: action.data?.total?.value,
          },
        },
      };

    case types.FETCH_CONTACT_NOTES:
      return {
        ...state,
        loading: {
          ...state.loading,
          contactNotes: true,
        },
        contactNotes: {
          ...state.contactNotes,
          data: [],
          paging: {
            ...state.contactNotes?.paging,
            ...action.paging,
          },
          filters: {
            ...state.contactNotes?.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };

    case types.SET_CONTACT_NOTES:
      return {
        ...state,
        loading: {
          ...state.loading,
          contactNotes: false,
        },
        contactNotes: {
          ...state.contactNotes,
          data: action.data.notes,
          paging: {
            ...state.contactNotes.paging,
            count: action.data?.total?.value,
          },
        },
      };

    case types.FETCH_SEQUENCE_ENROLL:
      return {
        ...state,
        loading: {
          ...state.loading,
          sequenceEnrollment: true,
        },
        sequenceEnrollment: [],
      };

    case types.FETCH_SEQUENCE_ENROLLMENTS:
      return {
        ...state,
        loading: {
          ...state.loading,
          sequenceEnrollments: true,
        },
        sequenceEnrollments: {
          ...state.sequenceEnrollments,
          data: [],
          paging: {
            ...state.sequenceEnrollments?.paging,
            ...action.paging,
          },
          filters: {
            ...state.sequenceEnrollments?.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };

    case types.SET_SEQUENCE_ENROLLMENT:
      return {
        ...state,
        loading: {
          ...state.loading,
          sequenceEnrollment: false,
        },
        sequenceEnrollment: action.enrolments,
      };
    case types.SET_SEQUENCE_ENROLLMENTS:
      return {
        ...state,
        loading: {
          ...state.loading,
          sequenceEnrollments: false,
        },
        sequenceEnrollments: {
          ...state.sequenceEnrollments,
          data: action.data.enrolments,
          paging: {
            ...state.sequenceEnrollments.paging,
            count: action.data?.total?.value,
          },
        },
      };
    case types.UPDATE_CONTACT:
      const updatedRow = state.contacts.data.findIndex((val) => val.id === action.contactId);

      return {
        ...state,
        loading: {
          ...state.loading,
          contacts: false,
        },
        contacts: {
          ...state.contacts,
          data: [
            ...state.contacts.data.slice(0, updatedRow),
            { ...action.contact.contact },
            ...state.contacts.data.slice(updatedRow + 1),
          ],
        },
      };
    case types.FETCH_CONTACTS:
      return {
        ...state,
        loading: {
          ...state.loading,
          contacts: true,
        },
        contacts: {
          ...state.contacts,
          data: [],
          paging: {
            ...state.contacts.paging,
            ...action.paging,
          },
          filters: {
            ...state.contacts.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };

    case types.SET_CONTACTS:
      return {
        ...state,
        loading: {
          ...state.loading,
          contacts: false,
        },
        contacts: {
          ...state.contacts,
          data: action.data.contacts,
          paging: {
            ...state.contacts.paging,
            count: action.data.total.value,
          },
        },
      };

    case types.TOGGLE_CONTACT_PROFILE:
      return {
        ...state,
        showContactProfile: !state.showContactProfile,
        selectedContactId: action.selectedContactId,
      };
    
    case types.SELECTED_CONTACT_IDS:
      return {
        ...state,
        selectedContactIds: action.selectedContactIds,
      };

    case RESET_STORE:
      return initialState;

    default:
      return state;
  }
};

export default ContactsReducer;
