import React, { useEffect, useState } from 'react';
import { Box, Typography, Avatar, ButtonBase } from '@mui/material';
import { deepOrange, lightBlue } from '@mui/material/colors';
import LayersIcon from '@mui/icons-material/Layers';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import useStyles from './styles';

const Session = ({ option, data, handleClick, onClose }) => {
  const classes = useStyles();
  const [color, setColor] = useState('success');
  const [status, setStatus] = useState('');
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  useEffect(() => {
    switch (data.status) {
      case 'unpublished':
        setColor('rgba(0,0,0,0.25)');
        setStatus('Unpublished Sequence');
        break;

      case 'published':
      default:
        setColor('success.main');

        setStatus('Active Sequence');
        break;
    }
  }, [data]);

  return (
    <ButtonBase
      onClick={() => {
        handleClick(data);
        onClose();
      }}
    >
      <Box display="flex" flexDirection="row" p={1} ml={1}>
        <Avatar variant="circle" sx={{ width: '36px', height: '36px', marginTop: '4px' }}>
          <PlayArrowIcon sx={{ fontSize: '1.25rem', color: 'white' }} />
        </Avatar>
        <Box ml={2} maxWidth={300} display="flex" alignItems="flex-start" flexDirection="column">
          <Typography className={classes.title} noWrap variant="body1">
            {data.title}
          </Typography>
          <Typography noWrap variant="body2">
            {capitalizeFirstLetter(data.contentType)}
          </Typography>
        </Box>
      </Box>
    </ButtonBase>
  );
};

export default Session;
