import React, { useState } from 'react';
import LMSCard from 'src/components/lms/LMSCard';
import Icon from 'src/components/lms/Icon';
import Session from 'src/components/lms/Session';
import FilterBar from 'src/components/lms/Filterbar';
import Empty from 'src/components/lms/Empty';
import Pagination from '@mui/material/Pagination';

import RecommendedCourses from 'src/modules/lms/components/home/RecommendedCourses';
import RecommendedSessions from 'src/modules/lms/components/home/RecommendedSessions';

import Grid from '@mui/material/Grid';
import useRequest from 'src/components/hooks/useRequest';
import { getCompletedSessions, getTagsLookup } from '../../api/LMSApis';
import { Spacer } from 'src/components/shared';

import SessionListSkeleton from '../../components/SessionListSkeleton';
import { Box } from '@mui/material';
import { scrollToRef } from 'src/utils/helper';

function Completed() {
  const [applied, setApplied] = useState({});
  const [paging, setPaging] = useState({
    perPage: 10,
    page: 0,
  });

  const wrapperRef = React.useRef();
  const filters = [
    {
      key: 'sort',
      title: 'Sort',
      type: 'dropdown',
      remote: false,
      cancellable: true,
      icon: <Icon name="sort" />,
      options: [
        {
          label: 'Name',
          value: 'name',
        },
        {
          label: 'Created Date',
          value: 'createdAt',
        },
      ],
    },
    {
      key: 'tags',
      title: 'Tags',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: true,
      isPrimary: true,
      icon: <Icon name="tags" />,
      remoteMethod: getTagsLookup,
    },
  ];

  const payload = {
    ...applied,
    ...(applied.sort ? { _sort: applied.sort?.value + ':desc' || '' } : {}),
    _from: paging.page * paging.perPage,
    _size: paging.perPage,
    ...(applied?.tags ? { tags: applied?.tags.id } : {}),
  };

  const [data, { loading, error }] = useRequest(getCompletedSessions, payload);

  const handleFilter = (key, value) => {
    if (value && typeof value !== 'undefined') {
      setApplied({
        ...applied,
        [key]: value,
      });
    } else {
      delete applied[key];
      setApplied({
        ...applied,
      });
    }
  };

  const completedData = data?.progress || [];

  const handlePageChange = (e, page) => {
    setPaging((prevState) => {
      return {
        ...prevState,
        page: page - 1,
      };
    });
    scrollToRef(wrapperRef);
  };
  const total = data?.total?.value || 0;

  return (
    <>
      <LMSCard
        icon={<Icon name="check" style={{ width: '24px', height: '24px' }} />}
        title="Completed"
        ref={wrapperRef}
      >
        <FilterBar
          showSearch={true}
          filters={filters}
          key="completed-filters"
          value={applied}
          onChange={handleFilter}
        />

        <Spacer x={2} y={2} />

        {loading ? (
          <SessionListSkeleton />
        ) : (
          <>
            {completedData.length ? (
              <>
                <Grid container spacing={2}>
                  {completedData.map((session, index) => (
                    <Grid item xs={6} md={6} lg={3} key={`completed-session-${session.id}`}>
                      <Session session={session} />
                    </Grid>
                  ))}
                </Grid>
                <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                  <Pagination
                    count={total ? Math.ceil(total / paging.perPage) : 0}
                    page={paging.page + 1}
                    defaultPage={1}
                    onChange={handlePageChange}
                  />
                </Box>
              </>
            ) : (
              <>
                <Empty
                  title="Nothing to see here."
                  desc="Check out some of our Courses and Sessions to get started!"
                />
              </>
            )}
          </>
        )}
      </LMSCard>
      {!loading && !completedData.length && (
        <>
          <Spacer x={2} y={2} />
          <RecommendedSessions title="Recommended Sessions" />
          <Spacer x={2} y={2} />
          <RecommendedCourses title="Recommended Courses" />
        </>
      )}
    </>
  );
}

export default Completed;
