import React from 'react';
import MissingImage from 'src/modules/lms/shared/MissingImage';
import styled from 'styled-components';

const BannerContainer = styled.div`
  width: 100%;
  height: auto;
  aspect-ratio: 3.312/1;
`;

const BannerImage = styled.img`
  /* max-height: 250px; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

const MissingImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(25, 50, 71);
  border-radius: 4px;
`;

function SessionBanner({ session }) {
  if (session.headerImage && session.headerImage !== '') {
    return (
      <BannerContainer>
        <BannerImage src={session.headerImage} />
      </BannerContainer>
    );
  }
  return (
    <BannerContainer>
      <MissingImageContainer>
        <MissingImage />
      </MissingImageContainer>
    </BannerContainer>
  );
}

export default SessionBanner;
