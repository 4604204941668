import { makeStyles } from '@mui/styles';
import styled from 'styled-components';

export const HistoryWrapper = styled.div`
  & .deleteIcon {
    display: none;
  }
  &:hover {
    background-color: rgba(27, 60, 89, 0.2);
    & .deleteIcon {
      display: inline-flex;
    }
  }
`;

export const HistoryItem = styled.div`
  background: linear-gradient(104.75deg, #000000 -5.01%, #132b4e 134.12%),
    linear-gradient(0deg, #ffffff, #ffffff);
  padding: 48px;
  border-radius: 4px;
  & .slider_img {
    min-height: 300px;
    max-height: 300px;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  & .no_image {
    min-height: 300px;
    max-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .description {
    font-size: 14px;
    padding-top: 12px;
  }
`;

export const Description = styled.div`
  padding: 12px;
`;

const useStyles = makeStyles(({ palette }) => ({
  sliderContainer: {
    padding: '48px 40px',
    height: '352px',
    borderRadius: '10px',
    background: 'linear-gradient(105deg, #000 -5.01%, #132B4E 134.12%), #FFF',
    '& .slider_div': {
      width: '982px',
    },
    '& .slider_content': {
      display: 'flex',
      alignItems: 'flex-start',
      gap: 44,
      paddingRight: '30px',
      flex: '1 0 0',
    },
    '& .slider_img': {
      // width: 590,
      // height: 352,
      // borderRadius: 6,
      width: '100%',
      height: 'auto',
    },
    '& .singleSession_image-div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .slider_text': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      //   gap: 32,
      flex: '1 0 0',
    },
    '& .slider_session': {
      color: '#FFD47E',
      fontSize: 14,
      fontWeight: 300,
      margin: '0',
      marginBottom: '12px',
    },
    '& .slider_h2': {
      margin: '0',
      color: '#EDEDED',
      fontSize: 26,
      fontWeight: 400,
    },
    '& .slider_pills': {
      display: 'flex',
      alignItems: 'flex-start',
      gap: 11,
      margin: '20px 0',
      alignSelf: 'stretch',
      '& .pill': {
        display: 'flex',
        padding: '7px 14px',
        alignItems: 'flex-end',
        gap: 20,
        color: '#7F7F7F',
        fontSize: 12,
        borderRadius: 5,
        border: '1px solid #7F7F7F',
      },
    },
    '& .slider_desc': {
      color: '#FFF',
      fontSize: 14,
      fontWeight: 300,
      margin: '0px',
      lineHeight: '21px',
    },
    '& .slider_btns': {
      display: 'flex',
      alignItems: 'flex-start',
      gap: 16,
      marginTop: '32px',
    },
    '& .slider_btn': {
      cursor: 'pointer',
      display: 'flex',
      padding: '16px 24px',
      alignItems: 'flex-end',
      gap: 24,
      borderRadius: 4,
      background: '#125151',
      color: '#FFF',
      fontSize: 16,
      fontWeight: 600,
      border: 'none',
      outline: 'none',
    },
  },
  watchHistoryContainer: {
    marginTop: '57px',
    '& .heading': {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '39px',
      '& h1': {
        color: '#FFD47E',
        fontSize: '38px',
        fontWeight: '800',
        margin: '0',
      },
    },
    '& .watchHistoryGrid': {
      display: 'flex',
      width: '1128px',
      alignItems: 'flex-start',
      alignContent: 'flex-start',
      gap: '32px',
      flexWrap: 'wrap',
      '& .singleSessionContainer': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'flex-start',
        width: '256px',
        transition: 'all 0.4s',
        '&:hover': {
          background: 'rgba(27, 60, 89, 0.20)',
          '& .trash': {
            display: 'block',
            cursor: 'pointer',
          },
        },
      },
      '& .singleSession_text': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '12px',
      },
      '& .singleSession_image': {
        width: '256px',
        height: '154px',
        borderRaius: '3px',
      },
      '& .singleSession_image-div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '& .singleSession_h2': {
        color: '#FFF',
        fontSize: '20px',
        fontWeight: '300',
        marginBottom: '12px',
        margin: '0',
      },
      '& .singleSession_p': {
        color: ' #C2C2C2',
        fontSize: 14,
        fontWeight: 300,
        lineHeight: '140%',
        margin: '0',
      },
      '& .singleSession_time': {
        padding: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .trash': {
          display: 'none',
        },
        '& p': {
          color: '#808080',
          fontSize: 14,
          fontWeight: 300,
          margin: 0,
        },
      },
    },
  },
  customAutocomplete: {
    width: '162px',
    '& input': {
      color: '#fff',
    },

    '& label.Mui-focused': {
      color: '#BFBFBF',
    },
  },
}));
export default useStyles;

export const Thumbnail = styled.div`
  width: 100%;
  height: 156px;
  background-color: #193247;
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const TrashIcon = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_2762_58396)">
        <path
          d="M3.33398 5.83203H16.6673"
          stroke="#C2C2C2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.33398 9.16797V14.168"
          stroke="#C2C2C2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.666 9.16797V14.168"
          stroke="#C2C2C2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.16602 5.83203L4.99935 15.832C4.99935 16.2741 5.17494 16.698 5.4875 17.0105C5.80006 17.3231 6.22399 17.4987 6.66602 17.4987H13.3327C13.7747 17.4987 14.1986 17.3231 14.5112 17.0105C14.8238 16.698 14.9993 16.2741 14.9993 15.832L15.8327 5.83203"
          stroke="#C2C2C2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 5.83333V3.33333C7.5 3.11232 7.5878 2.90036 7.74408 2.74408C7.90036 2.5878 8.11232 2.5 8.33333 2.5H11.6667C11.8877 2.5 12.0996 2.5878 12.2559 2.74408C12.4122 2.90036 12.5 3.11232 12.5 3.33333V5.83333"
          stroke="#C2C2C2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2762_58396">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Desc = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  line-height: 150%; /* 24px */
  letter-spacing: 0.2px;
  padding-top: 6px;
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 24px 0px 10px 0px;
  }
  & p {
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    letter-spacing: 0.2px;
  }
  & a {
    color: #ffd47e;
  }
`;
