import { Stack, Typography } from '@mui/material';
import React from 'react';
import { Button } from 'src/components/shared';
import images from 'src/config/images';

function Journey() {
  return (
    <Stack direction="column" gap={4} mt={8} alignItems="center">
      <img src={images.auth.journeySuccess} style={{ maxWidth: '220px' }} />
      <Typography color="textSecondary">
        You’re now ready to kick start your journey with SuperReach
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        sx={{ borderRadius: '40px', fontWeight: '600' }}
        onClick={() => {
          // window.open('/tasks');
          location.replace('/onboarding');
        }}
      >
        Get Started
      </Button>
    </Stack>
  );
}

export default Journey;
