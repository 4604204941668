import React, { useMemo } from 'react';
import { Box, Typography, Card, CardContent, CircularProgress } from '@mui/material';

import { useDashboardStore } from '../../../utils/dashboardStore';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { isEmpty } from 'lodash';
import { capitalizeFirstLetter } from 'src/utils/helper';

const AppBarChart = () => {
  const { loading, sequenceReport } = useDashboardStore();
  if (
    (!sequenceReport?.sequenceSummary || isEmpty(sequenceReport?.sequenceSummary)) &&
    !loading?.sequenceReport
  )
    return null;
  const data = sequenceReport?.sequenceSummary || [];
  const columns = useMemo(() => {
    if (!data.length) {
      return [];
    }
    return Object.keys(data[0]).filter((item) => item !== 'name');
  }, [data]);

  const getColor = (key) => {
    key = key.toLowerCase();
    let str = '';
    switch (key) {
      case 'Enrolled':
      case 'enrolled':
        str = '#FF8F00';
        break;

      case 'Contacted':
      case 'contacted':
        str = '#1E88E5';
        break;

      case 'Opened':
      case 'opened':
        str = '#8E24AA';
        break;
      case 'Clicked':
      case 'clicked':
        str = '#00897B';
        break;
      case 'Replied':
      case 'replied':
        str = '#43A047';
        break;
      case 'Interested':
      case 'interested':
        str = '#3949AB';
        break;
    }
    return str;
  };

  return (
    <Card>
      <CardContent>
        {loading?.sequenceReport === true ? (
          <Box display="flex" justifyContent="center" alignItems="center" pt={4}>
            <CircularProgress size={26} style={{ marginBottom: 16 }} color="secondary" />
          </Box>
        ) : (
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              width="100%"
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              barSize={32}
            >
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <CartesianGrid strokeDasharray="3 3" />
              {columns.length
                ? columns.map((item, index) => {
                    return (
                      <Bar
                        key={`${data.i}-widget-${index}`}
                        dataKey={item}
                        name={capitalizeFirstLetter(item)}
                        fill={getColor(item)}
                      />
                    );
                  })
                : null}
            </BarChart>
          </ResponsiveContainer>
        )}
      </CardContent>
    </Card>
  );
};

export default AppBarChart;
