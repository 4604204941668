import * as taskTypes from './taskTypes';

export const setTaskQueue = (ids) => ({
  type: taskTypes.SET_TASKS_QUEUE,
  ids,
});

export const navigateNextTask = ({ view = 'task', reject, resolve }) => ({
  type: taskTypes.NAVIGATE_NEXT_TASK,
  view,
  reject,
  resolve,
});

export const setTaskErrorDetails = (data) => ({
  type: taskTypes.SET_TASK_ERROR_DETAILS,
  data,
});

export const taskBulkUpdate = (tasks, payload, resolve, reject) => ({
  type: taskTypes.TASK_BULK_UPDATE,
  tasks,
  payload,
  resolve,
  reject,
});

export const sendTaskReply = (data, resolve, reject) => ({
  type: taskTypes.POST_TASK_REPLY,
  data,
  resolve,
  reject,
});

export const putTaskReply = (id, data, resolve, reject) => ({
  type: taskTypes.PUT_TASK_REPLY,
  id,
  data,
  resolve,
  reject,
});

export const deleteTaskReply = (id, resolve, reject) => ({
  type: taskTypes.DELETE_TASK_REPLY,
  id,
  resolve,
  reject,
});

export const fetchTaskReplies = (taskId, paging, filters) => ({
  type: taskTypes.FETCH_TASK_REPLIES,
  taskId,
  paging,
  filters,
});

export const clearTaskReplies = (ids) => ({
  type: taskTypes.CLEAR_TASK_REPLIES,
  ids,
});

export const setTaskReplies = (emailMessages, total) => ({
  type: taskTypes.SET_TASK_REPLIES,
  emailMessages,
  total,
});

export const fetchTask = (taskId) => ({
  type: taskTypes.FETCH_TASK,
  taskId,
});

export const setTask = (task) => ({
  type: taskTypes.SET_TASK,
  task,
});

export const fetchTaskOptionsFilters = (tab) => ({
  type: taskTypes.FETCH_TASK_OPTIONS_FILTERS,
  tab,
});
export const setTaskOptionsFilters = (data, filters) => ({
  type: taskTypes.SET_TASK_OPTIONS_FILTERS,
  data,
  filters,
});

export const setTasksTab = (tab) => ({ type: taskTypes.SET_TASKS_TAB, tab });

export const postTask = (task, resolve, reject) => ({
  type: taskTypes.POST_TASK,
  task,
  resolve,
  reject,
});

export const putTask = (taskId, task, resolve, reject) => ({
  type: taskTypes.PUT_TASK,
  taskId,
  task,
  resolve,
  reject,
});

export const deleteTask = (taskId, resolve, reject) => ({
  type: taskTypes.DELETE_TASK,
  taskId,
  resolve,
  reject,
});

export const fetchTasks = (paging, filters, sort, tab) => ({
  type: taskTypes.FETCH_TASKS,
  paging,
  filters,
  sort,
  tab,
});

// export const fetchTasks = (paging, filters, sort, boxType, currentTab, navigateTo = '') => ({
//   type: taskTypes.FETCH_TASKS,
//   paging,
//   filters,
//   sort,
//   boxType,
//   currentTab,
//   navigateTo,
// });

export const setTasks = (tasks, total, sort) => ({
  type: taskTypes.SET_TASKS,
  tasks,
  total,
});

export const fetchTaskErrors = (paging, filters, currentTab) => ({
  type: taskTypes.FETCH_TASK_ERRORS,
  paging,
  filters,
  currentTab,
});

export const setTaskErrors = (errors, total) => ({
  type: taskTypes.SET_TASK_ERRORS,
  errors,
  total,
});

export const setOutbox = (outbox, total) => ({
  type: taskTypes.SET_OUTBOX,
  outbox,
  total,
});

export const fetchOutboxTasks = (contactId, paging, filters, sort) => ({
  type: taskTypes.FETCH_OUTBOX,
  contactId,
  paging,
  filters,
  sort,
});

export const fetchEnrolmentDrafts = (paging, filters, sort) => ({
  type: taskTypes.FETCH_ENROLMENT_DRAFTS,
  paging,
  filters,
  sort,
});

export const setEnrolmentDrafts = (drafts, total) => ({
  type: taskTypes.SET_ENROLMENT_DRAFTS,
  drafts,
  total,
});

export const fetchInbox = (filters, paging) => ({
  type: taskTypes.FETCH_INBOX,
  filters,
  paging,
});

export const setInbox = (data, total) => ({
  type: taskTypes.SET_INBOX,
  data,
  total,
});

export const fetchSent = (filters, paging) => ({
  type: taskTypes.FETCH_SENT,
  filters,
  paging,
});

export const setSent = (data, total) => ({
  type: taskTypes.SET_SENT,
  data,
  total,
});
