import React, { useState } from 'react';
import { Box, Typography, Card, CardContent } from '@mui/material';
import { Button, Spacer, Drawer, Modal } from 'src/components/shared';
import { Tabs } from 'src/components/App';

import ActivityReportSummary from '../../components/Reports/ActivityReportSummary';
import ActivityReportGrid from '../../components/Reports/ActivityReportGrid';

import { activityReportsTabs } from '../../config';

function ActiveReportView({ reportsSummary, ...props }) {
  const [currentTab, setCurrentTab] = useState('summary');

  const onTabChange = (value) => {
    setCurrentTab(value.id);
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h1">Activity Reports</Typography>
      </Box>
      <Spacer y={0.5} />
      <Tabs tabs={activityReportsTabs} onChange={onTabChange} value={currentTab} />
      <Spacer y={3} />
      {currentTab === 'summary' ? (
        <ActivityReportSummary
          fetchReportSummary={props.fetchReportSummary}
          reportsSummary={reportsSummary}
        />
      ) : null}

      {currentTab === 'detailed' ? (
        <ActivityReportGrid {...props} loading={props.loading.activityReport} />
      ) : null}
    </>
  );
}

export default ActiveReportView;
