import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import useStyles from './style';
import Gold from '../../../../assets/images/LMS/Competition/Gold.png';
import Silver from '../../../../assets/images/LMS/Competition/Silver.png';
import Bronze from '../../../../assets/images/LMS/Competition/Bronze.png';
import Avatar from '../../../../assets/images/LMS/Competition/Avatar.png';
import { useEffect } from 'react';
import { useState } from 'react';

export default function LeagueTable({ report, competition }) {
  const [newReports, setnewReports] = useState([]);
  const classes = useStyles();
  const calculateTheScores = () => {
    let newReports = report?.map((it) => {
      let avgScore = (it.totalScore / it.totalRolePlaysDone).toFixed(1).replace(/.0$/, '');
      if (isNaN(avgScore)) {
        avgScore = 0;
      }

      // Filter roleplays within the competition
      const rolePlaysWithinCompetition = it.rolePlays.filter(
        (rp) => rp.isPlayedInWithInCompetition,
      );

      return { ...it, avg: avgScore, rolePlaysWithinCompetition };
    });
    newReports?.sort((a, b) => (a.avg < b.avg ? 1 : -1));

    setnewReports(newReports);
  };

  useEffect(() => {
    calculateTheScores();
  }, [report]);
  const getMedal = (index) => {
    switch (index) {
      case 0:
        return <img src={Gold} className={classes.tableMedal} />;
      case 1:
        return <img src={Silver} className={classes.tableMedal} />;
      case 2:
        return <img src={Bronze} className={classes.tableMedal} />;
      default:
        return <span className={classes.tableSpan}>{`${index + 1}. `}</span>;
    }
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 550 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{ marginLeft: '0px', display: 'block' }}>
              Competitor name
            </TableCell>
            <TableCell align="left">Score</TableCell>
            <TableCell align="left">No. Role-plays</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {newReports?.map((row, index) => (
            <TableRow
              key={row.name}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
              className={classes.topPerformer}
            >
              <TableCell component="th" scope="row" className={classes.userRow} align="center">
                {getMedal(index)} <img src={row.avatar || Avatar} className={classes.tableAvatar} />{' '}
                <span className="username">{row.name}</span>
              </TableCell>
              <TableCell align="left">
                <button
                  className={`${classes.btn_score} ${row.avg < 3.5 && classes.btn_score_red}`}
                >
                  {row.avg}/5
                </button>
              </TableCell>
              <TableCell align="left">
                <span className={classes.green}>{row.rolePlaysWithinCompetition.length}</span>/
                {competition.roleplays}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
