import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import LookupsView from './LookupsView';

import {
  fetchCentralLookups,
  fetchLookupList,
  postCentralLookup,
  putCentralLookup,
  deleteCentralLookup,
  putLookupList,
  postLookupList,
  deleteLookupOption,
  syncLookupWithCrm,
} from '../../actions/adminActions';

export const LookupsContainer = (props) => {
  useEffect(() => {
    props.fetchCentralLookups();
  }, []);

  return <LookupsView {...props} />;
};

const mapStateToProps = (state) => ({
  loading: state.fields.loading,
  lookups: state.fields.lookups,
  lookupList: state.fields.lookupList,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCentralLookups: () => dispatch(fetchCentralLookups()),
  fetchLookupList: (lookupId) => dispatch(fetchLookupList(lookupId)),
  postCentralLookup: (data) => {
    return new Promise((resolve, reject) => {
      dispatch(postCentralLookup(data, resolve, reject));
    });
  },
  putCentralLookup: (lookupId, data) => {
    return new Promise((resolve, reject) => {
      dispatch(putCentralLookup(lookupId, data, resolve, reject));
    });
  },
  deleteCentralLookup: (lookupId) => {
    return new Promise((resolve, reject) => {
      dispatch(deleteCentralLookup(lookupId, resolve, reject));
    });
  },
  putLookupList: (itemId, data) => {
    return new Promise((resolve, reject) => {
      dispatch(putLookupList(itemId, data, resolve, reject));
    });
  },
  postLookupList: (optionId, data) => {
    return new Promise((resolve, reject) => {
      dispatch(postLookupList(optionId, data, resolve, reject));
    });
  },
  deleteLookupOption: (optionId) => {
    return new Promise((resolve, reject) => {
      dispatch(deleteLookupOption(optionId, resolve, reject));
    });
  },
  syncLookupWithCrm: (lookupId) => {
    return new Promise((resolve, reject) => {
      dispatch(syncLookupWithCrm(lookupId, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LookupsContainer);
