import React, { useEffect } from 'react';
import SingleHistory from './SingleHistory';
import { deleteHistory } from '../../api/LMSApis';
import { Grid, Pagination, Stack, Typography, Box } from '@mui/material';
import { Empty, FilterBar } from 'src/components/App';
import { Spacer } from 'src/components/shared';

import WatchHistorySIider from './WatchHistorySIider';
import ListSkeleton from './ListSkeleton';

const WatchHistory = ({ history, ...props }) => {
  useEffect(() => {
    fetchData(paging, filters);
  }, []);

  const { data, paging, filters, featured } = history;

  const fetchData = (paging, filters) => {
    props.fetchHistory(paging, filters);
  };

  const filtersArr = [
    {
      key: 'updatedAt',
      title: 'Show all time',
      custom: true,
      type: 'relativeDateRange',
      options: [
        { id: 'today', name: 'Today' },
        { id: 'yesterday', name: 'Yesterday' },
        { id: 'thisWeek', name: 'This week' },
        { id: 'lastWeek', name: 'Last week' },
        { id: 'thisMonth', name: 'This month' },
        { id: 'lastMonth', name: 'Last month' },
        { id: 'last3Month', name: 'Last 3 months' },
      ],
      isPrimary: true,
    },
  ];

  const handleDelete = async (id) => {
    props.deleteHistory(id);
    // await deleteHistory(id);
  };

  const handleFilterChange = (key, value) => {
    fetchData(
      {
        ...paging,
        pageNo: 0,
      },
      {
        ...filters,
        [key]: value,
      },
    );
    window.scrollTo(0, 0);
  };

  const pages = Math.ceil(paging.count / paging.perPage);
  const page = paging.pageNo + 1;

  const handlePageChange = (e, page) => {
    fetchData(
      {
        ...paging,
        pageNo: page - 1,
      },
      filters,
    );
    window.scrollTo(0, 0);
  };

  return (
    <>
      {featured.length ? <WatchHistorySIider data={featured} /> : null}

      <Stack direction="row" justifyContent="space-between" mt={2}>
        <Typography color="secondary" variant="h1" sx={{ fontSize: '38px' }}>
          My Watch History
        </Typography>
        <FilterBar filters={filtersArr} appliedFilters={filters} onChange={handleFilterChange} />
      </Stack>
      <Spacer x={3} y={3} />
      {props.loading ? (
        <ListSkeleton />
      ) : (
        <>
          {data.length ? (
            <Grid container spacing={2}>
              {data?.map((item) => {
                return (
                  <Grid item xs={12} md={6} lg={3} key={item.id}>
                    <SingleHistory item={item} handleDelete={handleDelete} />
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Empty title="No watch history found!" />
          )}

          <Box mt={2} display="flex" alignItems="center" justifyContent="center">
            <Pagination
              count={pages}
              page={page}
              defaultPage={page}
              perPage={paging.perPage}
              onChange={handlePageChange}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default WatchHistory;
