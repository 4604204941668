import { makeStyles } from '@mui/styles';
import { themeColors } from '../../config';

import styled from 'styled-components';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';

export const LmsDrawer = styled(Drawer)`
  & .MuiPaper-root {
    width: 230px;
    overflow: hidden;
    background-color: #0c1721;
  }
`;

export const NavItemLink = styled.div`
  cursor: pointer;
  & .MuiListItemText-root {
    color: rgba(255, 255, 255, 0.6);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 162px;
  }

  &:hover .MuiListItemText-root .MuiTypography-root {
    color: #ffd47e;
  }
`;

export const LMSList = styled(List)`
  max-height: calc(100vh - 71px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 246px;
`;

export const LMSSidebarWrapper = styled.div`
  width: 224px;
  min-width: 224px;
  background-color: ${themeColors.bg2};
  color: ${themeColors.text};
  border-right: 1px solid rgba(255, 255, 255, 0.12);
  overflow: hidden;
`;

export const ChildMenu = styled(Collapse)`
  margin-left: 46px;
`;

export const LogoWrapper = styled.div`
  display: flex;
  height: 54px;
  padding: 8px 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  & img {
    width: 140px;
  }
`;

// common styling for sidebar
const useStyles = makeStyles(({ custom, transitions, palette, ...theme }) => {
  return {
    boardsMenu: {},
    boardsSubmenu: {
      marginLeft: '16px',
      padding: '06px 16px 06px 20px !important',
    },
    ellipsisStyle: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'inline-block',
      textDecoration: 'none',
      position: 'relative',
      width: '160px',
      color: '#FFFFFF',
      textTransform: 'capitalize',
    },
    watchedFrame: {
      width: '349.64px',
      height: '209.31px',
      border: '4.14522px solid #13DEA1;',
      marginBottom: '22px',
    },
    watchedFrameWatched: {
      width: '120.21px',
      height: '45.6px',
      left: '230px',
      top: '166px',
      background: '#13DEA1',
      position: 'relative',
      borderTopLeftRadius: '10.3631px',
      textAlign: 'center',
      color: ' #141111',
      fontWeight: '700',
    },
    watchedFrameText: {
      padding: '10px',
    },
    sliderDrawer: {
      '-ms-overflow-style': 'none',
      scrollbarWidth: 'none',
    },
    leftBoxIcon: {
      height: 34,
    },
    headerLogo: {
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '0px 20px',
      minHeight: 49,
      height: 49,
      '& img': {
        margin: '0 5px',
      },
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
      '& a': {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      },
    },
    headerLogoCollapsed: {
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px',
      minHeight: 49,
      height: 49,
      '& img': {
        margin: '0 5px',
      },
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
      '& a': {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      },
    },
    root: {
      cursor: 'unset',
      overflow: 'auto',
      overflowX: 'hidden',
      marginTop: 25,
      'scrollbar-width': 'none',
      '-ms-overflow-style': 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '& .MuiListItem-button:hover': {
        fontWeight: '700',
        // color: custom.colorCode.white,
        '& div': {
          // color: custom.colorCode.white,
        },
        '& a': {
          // color: custom.colorCode.white,
        },
      },
      '& .active': {
        // color: custom.colorCode.white,
        // backgroundColor: custom.colorCode.blueDarkShade3,
        fontWeight: '700',
        // '& div': {
        //   color: custom.colorCode.white,
        // },
        // '& a': {
        //   color: custom.colorCode.white,
        // },
        // '& svg': {
        //   color: custom.colorCode.white,
        // },
      },
    },
    sideBarHeading: {
      display: 'flex',
      padding: '5px 30px 0 30px',
      alignItems: 'center',
      '& h2': {
        fontSize: 11,
        // color: custom.colorCode.lightRedColor,
        fontWeight: 400,
        margin: 0,
        textTransform: 'none',
      },
    },
    nested: {
      padding: `6px 0 6px 30px`,
      cursor: 'pointer',
      '& .nav-link': {
        padding: 0,
      },
    },
    navListTitle: {
      fontSize: 16,
      fontWeight: '400',
      // color: custom.colorCode.blueDarkShade1,
      // paddingLeft: 16,
      // paddingBottom: 20,
      padding: '12px 16px 12px 20px',
      // marginBottom: 8,
      '&:hover': {},
      '& .career_portal': {
        color: 'rgba(255, 255, 255, 0.7)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '& a': {
        // color: custom.colorCode.blueDarkShade1,
        padding: 0,
      },
      '&.active img': {
        opacity: 1,
      },
      '&.active .sideText': {
        color: '#ffffff',
      },
      '&.MenuItem-back': {
        border: '1px solid #ffffff',
        borderLeftWidth: 0,
        left: '-1px',
        marginBottom: '20px',
        width: '120px',
        height: '42px',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        marginLeft: '-8px',
        '& a, & img, & p, & svg': {
          color: '#ffffff !important',
        },
        '& .MuiListItemIcon-root': {
          marginRight: '12px',
        },
        color: '#ffffff !important',
        '&.SideBar-close': {
          width: '54px',
        },
        '&.SideBar-close p, &.SideBar-close a': {
          display: 'none',
        },
        // '&:hover': {
        //   backgroundColor: '#ffffff',
        //   '& a, & p, & svg': {
        //     color: '#80ABCD !important',
        //   },
        // },
      },
    },
    navListChildItem: {
      letterSpacing: '0.15px',
      color: 'rgba(255, 255, 255, 0.7)',
      fontSize: 16,
      fontWeight: '400',
      paddingLeft: 16,
      padding: '12px 0 12px 30px',
      '& .career_portal': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '& a': {
        // color: custom.colorCode.blueDarkShade1,
      },
      '&.active': {
        color: '#ffffff',
        // backgroundColor: custom.colorCode.blueDarkShade3,
      },
      '&:hover': {
        // color: custom.colorCode.blueLightShade1,
      },
    },
    navItem: {
      '&:hover': {
        color: '#FFD47E',
        '& svg': {
          color: '#FFD47E',
        },
      },
    },
    collapseWrapper: {
      '& .MuiListItem-button': {
        paddingLeft: 32,
      },
    },
    collapseIcon: {
      height: 20,
      fill: 'rgba(255, 255, 255, 0.56)',
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: transitions?.create('transform', {
        duration: transitions.duration.shortest,
      }),
    },
    collapseIconExpand: {
      transform: 'rotate(-180deg)',
    },
    goBackWrapper: {
      margin: '10px 10px 10px 0',
      border: '1px solid #8ba4be',
      fontSize: 11,
      padding: '5px',
      width: '130px',
      cursor: 'pointer',
      borderLeft: 'none',
      borderTopRightRadius: 3,
      borderBottomRightRadius: 3,
      // color: custom.colorCode.blueDarkShade2,
      '& span': {
        marginRight: 10,
        marginLeft: 5,
        '& i': {
          // color: custom.colorCode.blueLightShade2,
          // fill: custom.colorCode.darkBrownShade1,
        },
      },
    },
    fontRedColor: {
      // color: custom.colorCode.lightRedColor,
    },
    sliderPaper: {
      transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      whiteSpace: 'nowrap',
      width: 230,
      overflow: 'hidden',
      backgroundColor: palette.primary.main,
      zIndex: 1000,
      borderRight: 'none',
    },
    sliderPaperDashboard: {
      transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      whiteSpace: 'nowrap',
      width: 300,
      overflow: 'hidden',
      backgroundColor: palette.primary.main,
      zIndex: 1000,
      borderRight: 'none',
    },
    drawerPaperClose: {
      overflow: 'hidden',
      transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      width: 64,
      whiteSpace: 'nowrap',
      backgroundColor: palette.primary.main,
    },
    listItemIcon: {
      minWidth: 24,
      marginRight: 20,
      '& svg': {
        // color: '#9D9FA6',
        color: 'rgba(255, 255, 255, 0.56)',
      },
      '& img': {
        opacity: 0.56,
      },
    },
    copyRightText: {
      color: 'rgba(255, 255, 255, 0.7)',
      fontSize: '13px',
      fontWeight: 400,
      textAlign: 'center',
    },
    copyRight: {
      paddingBottom: '0px !important',
    },
    copyRightBox: {
      backgroundColor: themeColors.bg,
      width: '210px',
    },
  };
});

export default useStyles;
