import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import appRoutes from 'src/modules/app/routes/appRoutes';
import authRoutes from 'src/modules/auth/routes/authRoutes';
import taskRoutes from 'src/modules/tasks/routes/taskRoutes';
import sequenceRoutes from 'src/modules/sequence/routes/sequenceRoutes';
import contactRoutes from 'src/modules/contacts/routes/contactRoutes';
import adminRoutes from '../modules/admin/routes/adminRoutes';
import tagRoutes from '../modules/admin/tags/routes/tagRoutes';
import resellerRoutes from '../modules/reseller/routes/resellerRoutes';
import LMSRoutes from 'src/modules/lms/routes/LMSRoutes';
import noteRoutes from 'src/modules/notes/routes/noteRoutes';

const routes = [
  ...authRoutes,
  ...appRoutes,
  ...taskRoutes,
  ...sequenceRoutes,
  ...contactRoutes,
  ...adminRoutes,
  ...tagRoutes,
  ...resellerRoutes,
  ...LMSRoutes,
  ...noteRoutes

];

function Router() {
  return (
    <>
      {routes.map(({ title, component: Component, url, exact }) => {
        return (
          <Route
            key={url}
            path={url}
            exact={exact}
            title={title}
            render={(compProps) => <Component {...compProps} title={title} />}
          />
        );
      })}
    </>
  );
}

export default Router;
