import React, { useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Button } from 'src/components/shared';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import { enrollmentSuccess } from '../../actions/enrolmentActions';
import { useDispatch } from 'react-redux';

function Stepper({
  onlyIcons = false,
  steps,
  activeEnrolmentId,
  setActiveEnrolment,
  onNext = () => null,
  nextLabel = 'Next',
  nextIcon = <KeyboardArrowRight />,
  onPrev = () => null,
  prevLabel = 'prev',
  prevIcon = <KeyboardArrowLeft />,
  showCount = true,
  showNextAtLast = false,
  label = '',
  show = true,
}) {
  const [activeStep, setActiveStep] = React.useState(0);
  const dispatch = useDispatch();
  const maxSteps = steps.length;

  useEffect(() => {
    if (
      steps &&
      steps.length &&
      activeEnrolmentId &&
      activeEnrolmentId !== '' &&
      activeStep !== steps.indexOf(activeEnrolmentId)
    ) {
      const index = steps.indexOf(activeEnrolmentId);
      setActiveStep(index);
    }
  }, [activeEnrolmentId]);

  useEffect(() => {
    if (steps && steps.length && steps[activeStep]) {
      const newId = steps[activeStep];
      if (newId !== activeEnrolmentId && activeEnrolmentId) {
        setActiveEnrolment(newId);
      }
    } else if (activeStep > steps.length - 1) dispatch(enrollmentSuccess(true));
  }, [activeStep]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    onNext();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    onPrev();
  };

  if (maxSteps <= 1) return null;

  return (
    <Stack
      sx={{ opacity: show ? 1 : 0 }}
      direction={'row'}
      gap={2}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      {onlyIcons ? (
        <Box
          onClick={handleBack}
          sx={{
            cursor: activeStep === 0 ? 'auto' : 'pointer',
            pointerEvents: activeStep === 0 ? 'none' : 'auto',
            transition: '300ms ease-in-out',
          }}
        >
          <ArrowCircleLeftRoundedIcon
            sx={{ fontSize: '32px !important', color: activeStep === 0 ? '#21212180' : '#212121' }}
          />
        </Box>
      ) : (
        <Button
          size="small"
          sx={{ color: '#00000061', visibility: activeStep !== 0 ? 'visible' : 'hidden' }}
          alignItems="center"
          gap={'5px'}
          onClick={handleBack}
        >
          {prevIcon} {prevLabel}
        </Button>
      )}
      <Typography variant="body1" color={'secondary'}>{`${
        showCount ? `${activeStep + 1}/${maxSteps}` : ''
      } ${label}`}</Typography>
      {onlyIcons ? (
        <Box
          onClick={handleNext}
          sx={{
            cursor: showNextAtLast || activeStep === maxSteps - 1 ? 'auto' : 'pointer',
            pointerEvents: showNextAtLast || activeStep === maxSteps - 1 ? 'none' : 'auto',
            transition: '300ms ease-in-out',
          }}
        >
          <ArrowCircleRightRoundedIcon
            sx={{
              fontSize: '32px !important',
              color: showNextAtLast || activeStep === maxSteps - 1 ? '#21212180' : '#212121',
            }}
          />
        </Box>
      ) : (
        <Button
          size="small"
          sx={{
            color: '#00000061',
            visibility: showNextAtLast || activeStep !== maxSteps - 1 ? 'visible' : 'hidden',
          }}
          onClick={handleNext}
          alignItems="center"
          gap={'5px'}
        >
          {nextLabel} {nextIcon}
        </Button>
      )}
    </Stack>
  );
}

export default Stepper;
