import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { FieldArray } from 'formik';
import { Box, Typography, Grid } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import { handlePanelBulkAddUpdate } from 'src/modules/app/actions/appActions';

const defaultStages = [
  {
    name: '',
    tabColor: '',
  },
];

export const BoardStages = ({ panels, onClose, ...props }) => {
  const [stages, setStages] = useState(defaultStages);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let existingPanels = Object.values(panels).map((item) => ({
      id: item.id,
      name: item.name,
      sortOrder: item.sortOrder,
      tabColor: item.tabColor,
    }));
    existingPanels = _.sortBy(existingPanels, 'sortOrder');
    setStages(existingPanels);
  }, [panels]);

  const ref = useRef(null);

  const handleDragEnd = (data) => {
    const formStages = ref.current.values['stages'];
    const oldIndex = data.source.index;
    const newIndex = data.destination.index;
    const direction = newIndex - oldIndex;
    const panel = formStages[oldIndex];

    setStages([
      ...formStages
        .filter((item) => {
          return direction > 0
            ? item.sortOrder <= newIndex && item?.id !== panel.id
            : item.sortOrder < newIndex && item?.id !== panel.id;
        })
        .map((item) => {
          return {
            ...item,
            sortOrder: direction > 0 ? item.sortOrder - 1 : item.sortOrder,
          };
        }),
      {
        ...panel,
        sortOrder: newIndex,
      },
      ...formStages
        .filter((item) => {
          return direction > 0
            ? item.sortOrder > newIndex && item?.id !== panel.id
            : item.sortOrder >= newIndex && item?.id !== panel.id;
        })
        .map((item) => {
          return {
            ...item,
            sortOrder: direction > 0 ? item.sortOrder : item.sortOrder + 1,
          };
        }),
    ]);
  };

  return (
    <>
      <Form
        innerRef={ref}
        initialValues={{
          stages: stages,
        }}
        validationSchema={Yup.object().shape({})}
        onSubmit={async (values, form) => {
          try {
            setLoading(true);
            await props.handlePanelBulkAddUpdate(values);
            setLoading(false);
            onClose();
          } catch (error) {
            setLoading(false);
          }
        }}
        enableReinitialize={true}
      >
        {({ values, ...formProps }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formProps.submitForm();
                return false;
              }}
              style={{ height: '100%' }}
              noValidate
            >
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable" direction="vertical" key="stages">
                  {(droppableProvided) => (
                    <FieldArray
                      name="stages"
                      render={(arrayHelpers) => (
                        <Box
                          display="flex"
                          alignItem="center"
                          justifyContent="center"
                          flexDirection="column"
                          ref={droppableProvided.innerRef}
                          {...droppableProvided.droppableProps}
                        >
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={1} />
                            <Grid item xs={5}>
                              <Typography color="textSecondary" variant="body2">
                                Stage Name
                              </Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Typography color="textSecondary" variant="body2">
                                Color
                              </Typography>
                            </Grid>
                          </Grid>
                          {values.stages &&
                            values.stages.length > 0 &&
                            values.stages.map((obj, index) => (
                              <Draggable draggableId={`stage-${index}`} index={index}>
                                {(draggableProvided, snapshot) => {
                                  return (
                                    <Grid
                                      container
                                      spacing={2}
                                      alignItems="center"
                                      ref={draggableProvided.innerRef}
                                      {...draggableProvided.draggableProps}
                                      style={{
                                        ...draggableProvided.draggableProps.style,
                                        background: snapshot.isDragging
                                          ? 'rgba(245,245,245, 0.75)'
                                          : 'none',
                                      }}
                                    >
                                      <Grid item xs={1}>
                                        <Button
                                          iconButton={true}
                                          onClick={() => {}}
                                          {...draggableProvided.dragHandleProps}
                                        >
                                          <DragIndicatorIcon />
                                        </Button>
                                      </Grid>
                                      <Grid item xs={5}>
                                        <Form.Field.Input
                                          fullWidth
                                          variant="outlined"
                                          size="small"
                                          name={`stages[${index}].name`}
                                          placeholder="Stage Name"
                                        />
                                      </Grid>
                                      <Grid item xs={1}>
                                        <Form.Field.ColorPicker
                                          variant="popper"
                                          name={`stages[${index}].tabColor`}
                                        />
                                      </Grid>

                                        <Grid item xs={1}>
                                          <Button
                                            iconButton={true}
                                            onClick={() => {
                                              arrayHelpers.remove(index);
                                            }}
                                          >
                                            <CloseIcon />
                                          </Button>
                                        </Grid>
                                    </Grid>
                                  );
                                }}
                              </Draggable>
                            ))}

                          <Box my={2}>
                            <Button
                              color="secondary"
                              onClick={() => {
                                setStages([
                                  ...values.stages,
                                  {
                                    ...defaultStages[0],
                                    id: `new-stage-${values.stages.length + 1}`,
                                    sortOrder: values.stages.length + 1,
                                  },
                                ]);
                              }}
                              startIcon={<AddIcon />}
                            >
                              Add new stage
                            </Button>
                          </Box>
                        </Box>
                      )}
                    />
                  )}
                </Droppable>
              </DragDropContext>
              <Box display="flex">
                <Button disabled={loading} variant="outlined" color="secondary" onClick={onClose}>
                  Cancel
                </Button>
                <Spacer x={1} y={1} />
                <Button type="submit" variant="contained" color="secondary" loading={loading}>
                  Save
                </Button>
              </Box>
            </form>
          );
        }}
      </Form>
    </>
  );
};

const mapStateToProps = (state) => ({
  panels: state.dashboard.panels,
  activeBoard: state.boards.activeBoard,
});

const mapDispatchToProps = (dispatch) => ({
  handlePanelBulkAddUpdate: (data) => {
    return new Promise((resolve, reject) => {
      dispatch(handlePanelBulkAddUpdate(data, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BoardStages);
