import React from 'react';
import { useEffect } from 'react';
import { memo } from 'react';
import { Dropdown, Spacer } from 'src/components/shared';
import { getUsers } from 'src/modules/auth/api/authApis';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';
import ActivitiesFilter from './ActivitiesFilter';

const team = {
  key: 'team',
  title: 'All Team',
  type: 'dropdown',
  remote: true,
  optLabel: 'name',
  optValue: 'id',
  cancellable: false,
  searchable: true,
  isPrimary: true,
  checkbox: true,
  multiple: true,
  groupBy: 'teamId',
  remoteMethod: async (value) => {
    return fetchUsersLookup(value);
  },
};

function FilterItems(props) {
  const [activityFilter, setActivityFilter] = React.useState(props.typeActiveFilters || []);

  const fetchUsers = async (searchTerm) => {
    try {
      const res = await getUsers();
      return res.users;
    } catch (error) {
      return [];
    }
  };

  return (
    <>
      <Dropdown
        title="Activities"
        selected={props.typeActiveFilters}
        options={[]}
        onBlur={() => {
          props.fetchContactNote(props.contact.id, {
            type_eq: activityFilter?.filter(({ value }) => !!value),
          });
        }}
        onChange={(val) => {}}
        onClear={() => {}}
        remoteMethod={(val) => fetchUsers(val)}
        optLabel="fname"
        optValue="id"
        cancellable={false}
        customRenderMenu={() => {
          return <ActivitiesFilter values={activityFilter} onChange={setActivityFilter} />;
        }}
      />
      <Spacer x={1} />
      <Dropdown
        selected={props.usersActiveFilters ? { users: props.usersActiveFilters } : undefined}
        onChange={(value) => {
          props.handleTeamFilter('users_eq', value);
        }}
        fullWidth={true}
        value={props.usersActiveFilters ? { users: props.usersActiveFilters } : []}
        {...team}
      />
    </>
  );
}

export default memo(FilterItems);
