import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  fetchOffices,
  saveOffice,
  putOffice,
  updateOfficeStatus,
  fetchOfficesData,
  officeBulkUpdate,
} from '../../actions/adminActions';
import OfficesView from './OfficesView';

const OfficesContainer = (props) => {
  useEffect(() => {
    if (!props.offices.length) {
      props.fetchOffices(props.offices.paging, props.offices.filters, props.offices.sort);
    }
  }, []);

  return <OfficesView {...props} />;
};
const mapStateToProps = (state) => {
  return {
    offices: state.admin.admin.offices,
    loading: state.admin.admin.loading,
    officesData: state.admin.admin.offices.officesData,
    total: state.admin.admin.offices.paging.count,
    globals: state.app.globals,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchOffices: (paging, filters, sort) => dispatch(fetchOffices(paging, filters, sort)),
  saveOffice: (data) => dispatch(saveOffice(data)),
  updateOfficeStatus: (rowData) => dispatch(updateOfficeStatus(rowData)),
  putOffice: (id, rowData) => dispatch(putOffice(id, rowData)),
  officeBulkUpdate: (teams, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(officeBulkUpdate(teams, payload, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OfficesContainer);
