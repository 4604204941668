import { useMemo } from 'react';
import { ButtonBase, Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/shared';
import { getIntegrations } from 'src/modules/app/actions/appSelector';

import {
  BullhornIcon,
  BullhornIconActive,
  VincereIcon,
  VincereIconActive,
  JobadderIcon,
  JobadderIconActive,
  SalesforceIcon,
  SalesforceIconActive,
  HubspotIcon,
  HubspotIconActive,
} from 'src/modules/app/components/IntegrationIcons';

function IntegrationStatus({ contact }) {
  const integrations = useSelector((state) => getIntegrations(state));

  const hasBullhorn = useMemo(() => {
    return contact?.bullhornId && contact?.bullhornId !== '' && parseInt(contact?.bullhornId) > 0;
  }, [contact]);

  const hasVincere = useMemo(() => {
    return contact?.vincereId && contact?.vincereId !== '' && parseInt(contact?.vincereId) > 0;
  }, [contact]);

  const hasJobadder = useMemo(() => {
    return contact?.jobadderId && contact?.jobadderId !== '' && parseInt(contact?.jobadderId) > 0;
  }, [contact]);

  const hasSalesforce = useMemo(() => {
    return (
      contact?.salesforceId && contact?.salesforceId !== '' && parseInt(contact?.salesforceId) > 0
    );
  }, [contact]);

  const hasHubspot = useMemo(() => {
    return contact?.hubspotId && contact?.hubspotId !== '' && parseInt(contact?.hubspotId) > 0;
  }, [contact]);

  if (integrations.isBullhornActive) {
    return (
      <>
        <ButtonBase
          onClick={() => {
            if (hasBullhorn) {
              window.open(contact.bullhornUrl, '_blank').focus();
            }
          }}
        >
          {hasBullhorn ? <BullhornIconActive /> : <BullhornIcon />}
        </ButtonBase>
      </>
    );
  }

  if (integrations.isJobAdderActive) {
    return (
      <>
        <ButtonBase
          onClick={() => {
            if (hasJobadder) {
              window.open(contact.jobadderUrl, '_blank').focus();
            }
          }}
        >
          {hasJobadder ? <JobadderIconActive /> : <JobadderIcon />}
        </ButtonBase>
      </>
    );
  }

  if (integrations.isVincereActive) {
    return (
      <>
        <ButtonBase
          onClick={() => {
            if (hasVincere) {
              window.open(contact.vincereUrl, '_blank').focus();
            }
          }}
        >
          {hasVincere ? <VincereIconActive /> : <VincereIcon />}
        </ButtonBase>
      </>
    );
  }

  if (integrations.isSalesforceActive) {
    return (
      <>
        <ButtonBase
          onClick={() => {
            if (hasSalesforce) {
              window.open(contact.salesforceUrl, '_blank').focus();
            }
          }}
        >
          {hasSalesforce ? <SalesforceIconActive /> : <SalesforceIcon />}
        </ButtonBase>
      </>
    );
  }

  if (integrations.isHubspotActive) {
    return (
      <>
        <ButtonBase
          onClick={() => {
            if (hasHubspot) {
              window.open(contact.hubspotUrl, '_blank').focus();
            }
          }}
        >
          {hasHubspot ? <HubspotIconActive /> : <HubspotIcon />}
        </ButtonBase>
      </>
    );
  }

  return null;
}

export default IntegrationStatus;
