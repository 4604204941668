import * as types from '../actions/adminTypes';
import { RESET_STORE } from 'src/modules/app/actions/appTypes';

const initialState = {
  loading: {
    lookups: false,
    lookupList: false,
  },
  lookups: {
    data: [],
  },
  lookupList: {
    data: [],
  },
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SYNC_LOOKUP_WITH_CRM:
    case types.FETCH_LOOKUP_LIST:
      return {
        ...state,
        loading: {
          ...state.loading,
          lookupList: true,
        },
        lookupList: {
          data: [],
        },
      };

    case types.SET_LOOKUP_LIST:
      return {
        ...state,
        loading: {
          ...state.loading,
          lookupList: false,
        },
        lookupList: {
          data: action.data,
        },
      };

    case types.FETCH_CENTRAL_LOOKUPS:
      return {
        ...state,
        loading: {
          ...state.loading,
          lookups: true,
        },
        lookups: {
          data: [],
        },
      };

    case types.SET_CENTRAL_LOOKUPS:
      return {
        ...state,
        loading: {
          ...state.loading,
          lookups: false,
        },
        lookups: {
          data: action.data,
        },
      };

    case RESET_STORE:
      return initialState;

    default:
      return state;
  }
};

export default adminReducer;
