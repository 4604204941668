import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Typography, CircularProgress } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import useStyles from './styles';
import { makeStyles } from '@mui/styles';
import { themeColors } from 'src/modules/lms/config';
import { useSelector } from 'react-redux';

function SessionInfoTab({ competitionBtnShow, handleOpen = () => {}, session }) {
  const userAccessLevel = useSelector((state) => state.auth.user);
  const [adminCompetition, setAdminCompetition] = useState(false);
  useEffect(() => {
    if (userAccessLevel?.acl?.accessLevel === 'Admin' && userAccessLevel?.isAdmin === true) {
      setAdminCompetition(true);
    }
  }, []);
  const classes = useStyles();
  const value = 2;

  const CircularProgressStyles = makeStyles(() => ({
    dashedCircle: {
      position: 'relative',
      top: 0,
      left: 0,
      width: '16px',
      height: '16px',
      borderRadius: '50%',
      clip: 'rect(0, auto, auto, 50%)',
      // animation: '$rotate 1.4s linear infinite',
      border: `3px dashed white`, // Customize the dash color and increase the length
    },
    '@keyframes rotate': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  }));

  const DashedCircularProgress = ({ value }) => {
    const classes = CircularProgressStyles();

    return (
      <div className={classes.dashedCircle}>
        <CircularProgress
          variant="determinate"
          value={60}
          size={22}
          thickness={6}
          style={{
            position: 'relative',
            bottom: '3px',
            right: '3px',
            color: themeColors.greenText,
          }}
        />
      </div>
    );
  };
  return (
    <Grid
      className={classes.infoSection}
      item
      xs={12}
      md={12}
      lg={12}
      display={'flex'}
      mb={3}
      justifyContent={'space-around'}
    >
      <Box className={classes.infoBtn}>
        <IconButton>
          <AddIcon />
        </IconButton>
        <Typography fontSize={12} color={'white'}>
          List
        </Typography>
      </Box>
      {adminCompetition ? (
        <Box className={classes.infoBtn}>
          <IconButton onClick={handleOpen}>
            <EmojiEventsOutlinedIcon />
          </IconButton>
          <Typography fontSize={12} color={'white'}>
            Competition
          </Typography>
        </Box>
      ) : null}

      <Box className={classes.infoBtn}>
        <IconButton style={{ fontSize: '0px', padding: '8px' }}>
          {value == 1 ? (
            <VisibilityOffOutlinedIcon />
          ) : value === 2 ? (
            <DashedCircularProgress />
          ) : (
            <VisibilityOutlinedIcon style={{ color: themeColors.greenText }} />
          )}
        </IconButton>
        <Typography
          fontSize={12}
          color={value === 2 || value === 3 ? themeColors.greenText : themeColors.white}
        >
          {value === 1 ? 'Not started' : value === 2 ? '43%' : 'Watched'}
        </Typography>
      </Box>
    </Grid>
  );
}

export default SessionInfoTab;
