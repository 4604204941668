import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useStyles from './style';
import CompetitionTable from './CompetitionTable';
import CompetitionContainer from 'src/modules/compitions/container/Competitions/CompetitionContainer';
import { useSelector } from 'react-redux';

const LMSCompetition = () => {
  // const userAccessLevel = useSelector((state) => state.auth.user);
  // const [adminCompetition, setAdminCompetition] = useState(false);

  // useEffect(() => {
  //   if (userAccessLevel?.acl?.accessLevel === 'Admin' && userAccessLevel?.isAdmin === true) {
  //     setAdminCompetition(true);
  //   }
  // }, []);

  return <CompetitionContainer />;

  return <Box>{adminCompetition ? <CompetitionContainer /> : <CompetitionTable />}</Box>;
};

export default LMSCompetition;
