import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import { PageTitle, Tabs } from 'src/components/App';
import { Button } from 'src/components/shared';

import {
  fetchAutomations,
  postAutomation,
  putAutomation,
  deleteAutomation,
  setResellerFilters,
} from '../../actions/resellerActions';

import ResellersAutomationView from './ResellersAutomationView';

export const ResellersContainer = (props) => {
  const [showAutomationForm, setShowAutomationForm] = useState(false);

  const closeModal = () => {
    setShowAutomationForm(false);
  };

  return (
    <>
      <PageTitle title="Automations" />

      <Box flex={1} display="flex" justifyContent="flex-end" alignItems="flex-end" px={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setShowAutomationForm(true);
          }}
        >
          Create Automation
        </Button>
      </Box>

      <ResellersAutomationView
        {...props}
        automations={props.automations}
        setShowAutomationForm={setShowAutomationForm}
        showAutomationForm={showAutomationForm}
        closeModal={closeModal}
        fetchAutomations={props.fetchAutomations}
        postAutomation={props.postAutomation}
        putAutomation={props.putAutomation}
        deleteAutomation={props.deleteAutomation}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.reseller.loading,
  resellersReport: state.reseller.resellersReport,
  resellersReportSummary: state.reseller.resellersReportSummary,
  resellersTenantReport: state.reseller.resellersTenantReport,
  resellersTenantReportSummary: state.reseller.resellersTenantReportSummary,
  resellersUsersReport: state.reseller.resellersUsersReport,
  resellersUsersReportSummary: state.reseller.resellersUsersReportSummary,
  automations: state.reseller.automations,
  resellerFilters: state.reseller.resellerFilters,
});

const mapDispatchToProps = (dispatch) => ({
  setResellerFilters: (filters) => dispatch(setResellerFilters(filters)),
  fetchAutomations: (filters, paging) => dispatch(fetchAutomations(filters, paging)),
  postAutomation: (payload) => {
    return new Promise((resolve, reject) => {
      dispatch(postAutomation(payload, resolve, reject));
    });
  },
  putAutomation: (automationId, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(putAutomation(automationId, payload, resolve, reject));
    });
  },
  deleteAutomation: (automationId) => {
    return new Promise((resolve, reject) => {
      dispatch(deleteAutomation(automationId, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ResellersContainer);
