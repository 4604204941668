import React from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Box, Stack, Divider, ListItemButton } from '@mui/material';
import { Spacer } from 'src/components/shared';
import { capitalizeName } from 'src/modules/contacts/utils/contactUtils';
import { fetchContact, fetchSequenceEnrollment } from 'src/modules/contacts/actions/contactActions';
import moment from 'moment';
import EmailIcon from '@mui/icons-material/Email';
import { capitalizeFirstLetter } from 'src/utils/helper';

function Item({ task, messageKey, type, selected, ...props }) {
  const dispatch = useDispatch();
  const { contact, sequence, latestReplyByDate } = task;
  const messageObj = task[messageKey] || undefined;

  const name = capitalizeName(`${contact?.fname} ${contact?.lname}`);

  const fetchReplies = () => {
    props.fetchTaskReplies(task.id);
    dispatch(fetchContact(task.contact.id));
    dispatch(fetchSequenceEnrollment(task.contact.id));
  };

  let date = '';
  if (type === 'inbox') {
    date = task?.latestReplyByDate?.createdAt;
  } else {
    date = task?.scheduledOn;
  }
  const today = moment();
  date = moment(date);
  const diff = today.diff(date, 'days');
  let dateStr;
  if (diff > 2) {
    dateStr = `${moment(date).format('MMM D | HH:MM A')}`;
  } else {
    dateStr = `${moment(date).fromNow()} | ${moment(date).format('HH:MM A')}`;
  }

  let emailType = 'manual';
  if (task?.sequence && task?.sequence?.id) {
    emailType = task?.taskType === 'email' ? 'automated' : 'manual';
  } else {
    emailType = 'manual';
  }
  if (type !== 'inbox' && task.status === 'replied') {
    emailType = 'replied';
  }
  const color =
    emailType === 'manual' ? '#EC407A' : emailType === 'replied' ? '#FF9800' : '#1976D2';
  const textColor =
    emailType === 'manual' ? '#EC407A' : emailType === 'replied' ? '#00000099' : '#00000099';

  return (
    <>
      <ListItemButton
        p={2}
        onClick={fetchReplies}
        sx={selected === task.id ? { backgroundColor: 'rgba(25, 118, 210, 0.06)' } : {}}
      >
        <Box width="100%">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center">
              <Typography>{name}</Typography>
            </Stack>

            <Typography variant="caption" color="textSecondary">
              {dateStr}
            </Typography>
          </Stack>
          {sequence && sequence.name && (
            <Typography variant="body2" color="secondary">
              {sequence.name}
            </Typography>
          )}

          {messageObj && (
            <>
              <Spacer y={0.5} x={0.5} />
              <Typography variant="body1" color="textSecondary">
                {messageObj.subject}
              </Typography>
              <Typography variant="body2" sx={{ color: 'rgba(0,0,0,0.4)', width: '240px' }} noWrap>
                {messageObj?.snippet || ''}
              </Typography>
            </>
          )}

          <Box display="flex" justifyContent="flex-end" mt={0.5}>
            <EmailIcon sx={{ fontSize: '18px', fill: color }} />
            <Spacer x={1} y={1} />
            <Typography variant="caption" color={textColor}>
              {capitalizeFirstLetter(emailType)} email
            </Typography>
          </Box>
        </Box>
      </ListItemButton>
      <Divider />
    </>
  );
}

export default Item;
