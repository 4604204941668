import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';
import Alert from '@mui/lab/Alert';
import { Button, Drawer } from 'src/components/shared';
import ContactForm from 'src/modules/contacts/components/Form/ContactForm';
import StepMedia from './StepMedia';
import { useHistory } from 'react-router-dom';

import { putUser } from 'src/modules/auth/actions/authActions';
import SignatureForm from 'src/modules/auth/components/SignatureForm';

import api from 'src/api';

function StepContent({
  step,
  fields,
  markStepComplete,
  toggleSequenceForm,
  toggleTaskForm,
  toggleEnrollForm,
  toggleIntegrationDrawer,
  toggleAddContactForm,
  showContactForm,
  defaultSequence,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  // const [userData, setUserData] = useState({});
  const [drawerOpen, setDrawerOpen] = useState({ edit: null, open: false });
  const history = useHistory();

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.user);

  const updateUser = (data) => {
    dispatch(putUser(data));
  };

  useEffect(() => {
    if (step.action == 'add_signature') {
      getUserData();
    }
  }, [step]);

  const getUserData = () => {
    // const currentUserData = JSON.parse(localStorage.getItem('user'));
    // const userId = currentUserData.id;
    // if (step.isUserUpdate) {
    //   api
    //     .get(`/automation/users/${userId}`)
    //     .then((res) => {
    //       setUserData(res.data);
    //     })
    //     .catch((error) => {
    //       setUserData({});
    //     });
    // }
  };

  const onAction = async () => {
    if (step?.action && step?.action !== '') {
      switch (step?.action) {
        case 'create_sequence':
          toggleSequenceForm();
          break;

        case 'manage_tasks':
          toggleTaskForm(false, step);
          break;

        case 'add_contacts':
          toggleEnrollForm(false, step);
          break;

        case 'connect_mailbox':
          toggleIntegrationDrawer();
          break;

        case 'download_chrome_extension':
          if (step.action_source && step.action_source != null) {
            window.open(step.action_source);
          }
          await markStepComplete(step);
          break;

        default:
          setIsLoading(true);
          await markStepComplete(step);
          setIsLoading(false);
          break;
      }
    } else {
      setIsLoading(true);
      await markStepComplete(step);
      setIsLoading(false);
    }
  };

  const noButtonActions = ['add_signature'];
  const hasThumb = step.thumbnail && step.thumbnail != '';

  return (
    <Box width="100%">
      <Drawer
        title={'Create contact'}
        open={props.showContactForm === true}
        onClose={() => {
          toggleAddContactForm();
        }}
      >
        <ContactForm
          edit={drawerOpen.edit}
          drawerToggle={showContactForm}
          formData={fields}
          {...props}
        />
      </Drawer>
      {step.content && (
        <Grid container spacing={2} style={{ alignItems: 'center' }}>
          <Grid item xs={12} md={hasThumb ? 7 : 12} style={{padding: 0}}>
            <Box mb={2}>
              <div dangerouslySetInnerHTML={{ __html: step.content }} />
              <Box mt={2}>
                {step.action == 'add_signature' && (
                  <SignatureForm editUser={userData} putUser={updateUser} status={false} />
                )}
              </Box>
            </Box>
          </Grid>
          {hasThumb && (
            <Grid item xs={12} md={5}>
              <StepMedia
                step={step}
                onAction={onAction}
                playVideo={playVideo}
                setPlayVideo={setPlayVideo}
              />
            </Grid>
          )}
        </Grid>
      )}
      {!noButtonActions.includes(step.action) && (
        <>
          {step.action ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                if (step.action == 'Video') {
                  setPlayVideo(true);
                } else if (step.action === 'connect_mailbox') {
                  history.push('/profile/email');
                } else if (step.action === 'add_sign') {
                  window.open('/profile/signature', '_blank', 'noopener,noreferrer');
                  // history.push('/profile/signature');
                } else if (step.action === 'add_contacts') {
                  toggleAddContactForm();
                } else {
                  onAction();
                }
              }}
              loading={isLoading}
              // disabled={step.action == 'add_contacts' && !defaultSequence.id}
            >
              {step.action_label}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                if (step.action == 'Video') {
                  setPlayVideo(true);
                } else {
                  onAction();
                }
              }}
              loading={isLoading}
            >
              Mark complete
            </Button>
          )}
        </>
      )}
      {/* {step.action == 'add_contacts' && !defaultSequence.id && (
        <Box mt={2}>
          <Alert severity="warning">
            You need to create a Sequence before you can enroll contacts !
          </Alert>
        </Box>
      )} */}
    </Box>
  );
}

export default StepContent;
