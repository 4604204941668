import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ custom }) => ({
  headerSubTitle: {
    color: '#646464',
    letterSpacing: '0.17px',
    lineHeight: '143%',
  },
  headerTitle: {
    color: '#00000099',
    fontWeight: '500',
    fontSize: '16px',
    letterSpacing: '0.17px',
    lineHeight: '143%',
  },
  headerTitle1: {
    color: '#00000099',
    fontWeight: '400',
    fontSize: '16px',
    letterSpacing: '0.17px',
    lineHeight: '143%',
  },
  mainCardIntegrations: {
    height: '100%',
    '& .MuiCardContent-root': {
      padding: 0,
      paddingBottom: 0,
    },
  },
  cardContentIntegrationCard: {
    height: '100%',
    position: 'relative',
    maxHeight: 254,
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
  },
  cardImageHeader: {
    marginBottom: 10,
    '& svg': {
      width: 50,
      height: 50,
    },
  },
  integrationDescription: {},
  cardHeader: {
    marginBottom: 8,
    '& .title': {
      fontWeight: 400,
    },
    '& .value': {
      fontSize: 13,
      fontWeight: 300,
      color: '#9d7596',
    },
  },
  gridContent: {
    marginBottom: 30,
  },
  gridContentRowDataDeActive: {
    display: 'flex',
    alignItems: 'center',
    color: '#ebebeb',
  },
  gridContentRowDataActive: {
    display: 'flex',
    alignItems: 'center',
    color: '#4CAF50',
  },
  cardFooter: {
    bottom: 24,
    // position: 'absolute',
    width: 'calc(100% - 34px)',
  },
  gridFooterRowData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .installation': {},
    '& .connect': {
      '& .manage-link': {
        color: '#5780ab',
        textDecoration: 'none',
        backgroundColor: 'transparent',
      },
    },
  },
  connected: {
    color: '#EF5350',
    '& svg': {
      fontSize: '2rem',
    },
  },
  disConnected: {
    color: '#ebebeb',
    '& svg': {
      fontSize: '2rem',
    },
  },
  switch: {
    display: 'flex',
    justifyContent: 'end',
  },
}));
