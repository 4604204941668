import images from 'src/config/images';
import { lowerFirstLetter } from 'src/utils/helper';
import styled from 'styled-components';
import Icon from '@mui/material/Icon';

class Integrations {
  integrations = {};

  constructor(integrations) {
    if (integrations?.data && integrations?.data.length) {
      this.integrations = integrations.data.reduce(
        (obj, item) => Object.assign(obj, { [item.name]: item }),
        {},
      );
    }
  }

  get activeIntegrations() {
    return Object.values(this.integrations).filter((item) => item.status === 'active');
  }

  isActive(key) {
    return this.integrations[key]?.status ? this.integrations[key]?.status === 'active' : false;
  }

  get isBullhornActive() {
    return this.isActive('Bullhorn');
  }
  get isVincereActive() {
    return this.isActive('Vincere');
  }
  get isJobAdderActive() {
    return this.isActive('JobAdder');
  }
  get isSalesforceActive() {
    return this.isActive('Salesforce');
  }
  get isHubspotActive() {
    return this.isActive('Hubspot');
  }
}

export default Integrations;
