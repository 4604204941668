import React from 'react';
import { Spacer } from 'src/components/shared';

import GettingStarted from '../../components/GettingStarted';
import HomeCompetitions from '../../components/home/HomeCompetitions';
import HomeInProgress from '../../components/home/HomeInProgress';
import HomeAssigned from '../../components/home/HomeAssigned';
import HomeSaved from '../../components/home/HomeSaved';
import RecommendedCourses from '../../components/home/RecommendedCourses';
import RecommendedSessions from '../../components/home/RecommendedSessions';

import { useLmsHomeProvider } from 'src/modules/lms/utils/lmsStore';

function LMSHome() {
  const { homePage, competitions, assignedCourses, assignedSessions } = useLmsHomeProvider();

  const components = homePage.component || [];

  const renderComponent = (component) => {
    switch (component.name) {
      case 'myCompetitions':
        return (
          <>
            <HomeCompetitions title={component.label} />
            <Spacer x={2} y={2} />
          </>
        );
        break;

      case 'inProgress':
        return (
          <>
            <HomeInProgress title={component.label} />
            <Spacer x={2} y={2} />
          </>
        );
        break;

      case 'assignedToMe':
        return (
          <>
            <HomeAssigned title={component.label} />
            <Spacer x={2} y={2} />
          </>
        );
        break;

      case 'saved':
        return (
          <>
            <HomeSaved title={component.label} />
            <Spacer x={2} y={2} />
          </>
        );
        break;

      case 'recommendedSessions':
        return (
          <>
            <RecommendedSessions title={component.label} />
            <Spacer x={2} y={2} />
          </>
        );
        break;

      case 'recommendedCourse':
        return (
          <>
            <RecommendedCourses title={component.label} />
            <Spacer x={2} y={2} />
          </>
        );
        break;
    }
  };

  return (
    <>
      <GettingStarted />
      <Spacer x={2} y={2} />

      {components.length > 0 &&
        components.map((component, index) => (
          <div key={`homepage-component-${index}`}>{renderComponent(component)}</div>
        ))}
    </>
  );
}

export default LMSHome;
