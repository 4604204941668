import React, { useState, useRef, useEffect } from 'react';
import { TextField, Menu, Box, Typography, ButtonGroup } from '@mui/material';
// import { DateRangePicker } from 'mui-daterange-picker';
import { DateRangePicker } from 'src/components/shared';
import moment from 'moment';
import { Button } from '../../shared';
import { menuStyles } from './styles';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { getDifferenceBetweenDates } from 'src/utils/dateUtils';

function DateRangeFilter({ filter, value, onChange, className, isInitialValue = true }) {
  const [fieldVal, setFieldVal] = useState('');
  const classes = menuStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const inputRef = useRef(null);
  const initialValue =
    new Date(value?.endDate).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0) &&
    new Date(new Date().setMonth(new Date().getMonth() - 1)).setHours(0, 0, 0, 0) ===
      new Date(value?.startDate).setHours(0, 0, 0, 0);

  useEffect(() => {
    if (value && value?.startDate && value?.endDate) {
      setFieldVal(
        `${moment(value.startDate).format('D MMM')} - ${moment(value?.endDate).format('D MMM')}`,
      );
    } else {
      setFieldVal(filter.title);
    }
  }, [value]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFocus = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (value) => {
    onChange({
      startDate: moment(value.startDate).format('YYYY-MM-DD'),
      endDate: moment(value.endDate).format('YYYY-MM-DD'),
    });
    handleClose();
  };

  const onPrevClick = () => {
    const difference = getDifferenceBetweenDates(value.startDate, value.endDate);
    onChange({
      startDate: difference === 0 ? moment(value.startDate).subtract(1, 'days').format('YYYY-MM-DD') : moment(value.startDate).subtract(difference, 'days').format('YYYY-MM-DD'),
      endDate: difference === 0 ? moment(value.startDate).subtract(1, 'days').format('YYYY-MM-DD') : moment(value.startDate).format('YYYY-MM-DD'),
    });
  };

  const onNextClick = () => {
    const difference = getDifferenceBetweenDates(value.startDate, value.endDate);
    
    onChange({
      startDate: difference === 0 ? moment(value.endDate).add(1, 'days').format('YYYY-MM-DD') : moment(value.endDate).format('YYYY-MM-DD'),
      endDate: difference === 0 ? moment(value.endDate).add(1, 'days').format('YYYY-MM-DD') : moment(value.endDate).add(difference, 'days').format('YYYY-MM-DD'),
    });
  };

  return (
    <>
      <Box>
        <ButtonGroup>
          {fieldVal !== filter.title ? (
            <Button
              variant="text"
              onClick={onPrevClick}
              className={className}
              sx={{
                color: !initialValue && isInitialValue ? 'white' : 'rgba(0,0,0,0.6)',
                textTransform: 'none',
                fontWeight: 400,
                backgroundColor:
                  !initialValue && isInitialValue ? '#1976D2' : 'rgba(25, 118, 210, 0.08)',
                padding: '4px 8px',
                marginRight: '0',
              }}
            >
              <KeyboardArrowLeftIcon />
            </Button>
          ) : null}
          <Button
            variant="text"
            onClick={handleFocus}
            endIcon={<ArrowDropDownIcon />}
            className={className}
            sx={{
              color: !initialValue && isInitialValue ? 'white' : 'rgba(0,0,0,0.6)',
              textTransform: 'none',
              fontWeight: 400,
              backgroundColor:
                !initialValue && isInitialValue ? '#1976D2' : 'rgba(25, 118, 210, 0.08)',
              padding: '4px 8px',
            }}
          >
            {fieldVal}
          </Button>
          {fieldVal !== filter.title ? (
            <Button
              variant="text"
              onClick={onNextClick}
              className={className}
              sx={{
                color: !initialValue && isInitialValue ? 'white' : 'rgba(0,0,0,0.6)',
                textTransform: 'none',
                fontWeight: 400,
                backgroundColor:
                  !initialValue && isInitialValue ? '#1976D2' : 'rgba(25, 118, 210, 0.08)',
                padding: '4px 8px',
              }}
            >
              <KeyboardArrowRightIcon />
            </Button>
          ) : null}
        </ButtonGroup>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
      >
        <DateRangePicker
          open={open}
          toggle={handleClose}
          onChange={handleChange}
          wrapperClassName="mui-date-range-picker"
        />
      </Menu>
    </>
  );
}

export default DateRangeFilter;
