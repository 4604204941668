import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

function Home() {
  useEffect(() => {
    Redirect('/dashboard');
  }, []);

  return <Redirect to="/dashboard" />;
}

export default Home;
