import React, { useRef } from 'react';
import { Grid, Box, Typography, Paper, Link, Stack } from '@mui/material';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Button, Form } from 'src/components/shared';
import moment from 'moment';

export default function HoliDayForm({
  id,
  drawerOpen,
  onUpdateData,
  onAddData,
  holiday,
  ...props
}) {
  const portalRef = useRef(null);
  const formRef = useRef();

  const handleSubmit = async (value, form) => {
    const data = { ...value };
    data.eventDate =
      moment(value.eventDate).format('YYYY-MM-DD') || moment(Date.now).format('YYYY-MM-DD') || '';
    data.eventEndDate =
      moment(value.eventEndDate).format('YYYY-MM-DD') ||
      moment(Date.now).format('YYYY-MM-DD') ||
      '';

    if (holiday?.edit && holiday?.edit?.event) {
      onUpdateData(data, holiday.edit.index);
    } else {
      onAddData(data);
      drawerOpen({ edit: null, open: false });
    }
  };

  return (
    <Form
      initialValues={{
        eventDate: holiday?.edit ? holiday?.edit?.eventDate : '',
        eventEndDate: holiday?.edit ? holiday?.edit?.eventEndDate : '',
        event: holiday?.edit ? holiday?.edit?.event : '',
      }}
      validationSchema={Yup.object().shape({
        event: Yup.string().required('Please enter holiday title'),
        eventDate: Yup.date()
          .typeError('Invalid start date')
          .required('Start date is required')
          .max(Yup.ref('eventEndDate'), 'Start date must be before end date')
          .min(moment(), 'Start date cannot be in the past'),
        eventEndDate: Yup.date()
          .typeError('Invalid end date')
          .required('End date is required')
          .min(Yup.ref('eventDate'), 'End date must be after start date')
          .min(moment(), 'End date cannot be in the past'),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={false}
      innerRef={formRef}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <Box
              ref={portalRef}
              display="flex"
              flex={1}
              flexDirection="column"
              justifyContent="space-between"
              height="100%"
            >
              <Box>
                <Grid container>
                  <Grid item xs={12}>
                    <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="event"
                      label="Holiday Title"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Form.Field.Datepicker
                      fullWidth
                      rows={4}
                      variant="inline"
                      name="eventDate"
                      label="Start Date"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Form.Field.Datepicker
                    fullWidth
                    rows={4}
                    variant="inline"
                    name="eventEndDate"
                    label="End Date"
                  />
                </Grid>
              </Box>
              <Box ml={50}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  //   loading={loading}
                  //   disabled={loading}
                >
                  {holiday?.edit && holiday?.edit.edit?.event ? 'UPDATE' : 'SAVE'}
                </Button>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}
