import * as noteTypes from './noteTypes';
export const fetchSessionsList = (
  paging,
  filters,
  sort,
  resolve = () => {},
  reject = () => {},
) => ({
  type: noteTypes.FETCH_SESSIONS_LIST,
  paging,
  filters,
  sort,
  resolve,
  reject,
});
export const setSessionsList = (data) => ({
  type: noteTypes.SET_SESSIONS_LIST,
  data,
});
