import { makeStyles } from '@mui/styles';
import { themeColors } from 'src/modules/lms/config';

const useStyles = makeStyles(({ palette }) => ({
  container: {
    background: '#EF5350',
    borderRadius: '5px',
    height: '44px',
    marginBottom: '27px',
    color: '#F2EADA',
    display: 'flex',
    justifyContent: 'center',
  },
  largeContainer:{
    background: '#082035',
    borderRadius: '3px',
    height: '144px',
    marginBottom: '27px',
    color: '#F2EADA',
    border: '2px solid #EF5350',
    display: 'flex',
    justifyContent: 'center',
  },
  contentAlgin:{
    display:'flex',
    justifyContent:'center',
    padding: '22px 0px 0px 0px',
  },
  message:{
    padding:'20px 0px 0px 20px',
    width:'45%'
  },
  alignment: {
    display:'flex',
    padding: '10px',
  },
  svg:{
    marginRight:'8px'
  }
}));
export default useStyles;
