import React from 'react';
import { Grid, Box, Typography, Paper, Link, Stack, Tooltip } from '@mui/material';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Button, Form, Spacer } from 'src/components/shared';
import { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';

export default function Tracking({ tenantSettings, id, ...props }) {
  const [open, setOpen] = useState(false);

  const handleSubmit = async (values, form) => {
    const data = { ...values };
    data.id = id || '';
    // await props.updateSettings(data);
  };

  const TitleBar = ({ title, tooltip }) => {
    return (
      <Box display="flex" alignItems="center">
        <Typography variant="h4">{title}</Typography>
        {tooltip && tooltip !== '' ? (
          <>
            <Spacer y={1} x={2} />

            <Tooltip title={tooltip}>
              <InfoIcon style={{ fill: 'rgba(0, 0, 0, 0.26)', width: 20, marginTop: 15 }} />
            </Tooltip>
          </>
        ) : null}
      </Box>
    );
  };

  return (
    <>
      <Form
        initialValues={{
          openTracking: tenantSettings.openTracking || false,
          clickTracking: tenantSettings.clickTracking || false,
          updateSettings: props.updateSettings,
        }}
        validationSchema={Yup.object().shape({})}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {(props) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                props.submitForm();
                return false;
              }}
              style={{ height: '80%' }}
              noValidate
            >
              <Grid display="flex" flexDirection="column" container ml={1.5}>
                <Grid spacing={2} display="flex">
                  <Form.Field.Switch
                    fullWidth
                    rows={4}
                    name="openTracking"
                    variant="outlined"
                    onChange={(val) => {
                      props.values.updateSettings({ id, openTracking: val });
                    }}
                    value={props.values.openTracking || false}
                    // disabled={!status}
                    label={'Open tracking'}
                  />
                  <TitleBar tooltip="When enabled, SuperReach will automatically add an invisible pixel to your emails which allows us to track open rates. Depending on the email client a recipient is using, the data may not be 100% accurate." />
                </Grid>
                <Grid spacing={2} display="flex">
                  <Form.Field.Switch
                    fullWidth
                    rows={4}
                    name="clickTracking"
                    variant="outlined"
                    onChange={(val) => {
                      props.values.updateSettings({ id, clickTracking: val });
                      // setOpen(props.values.clickTracking);
                    }}
                    value={props.values.clickTracking || false}
                    // disabled={!status}
                    label={'Click tracking'}
                  />
                  <TitleBar tooltip="When enabled, SuperReach will automatically replace any links with a new URL that allows click tracking. This can effect the deliverability of your emails thus we recommend it is switched off." />
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Form>
    </>
  );
}
