import React from 'react';
import { Redirect } from 'react-router-dom';
import LMSCompetition from '../components/LMSCompetition/index';
import { connect } from 'react-redux';

const LMSCompetitionContainer = (props) => {
  const isAdmin = props?.user?.isAdmin;

  // Redirect to "/lms" if the user is not an admin
  // if (!isAdmin) {
  //   return <Redirect to="/lms" />;
  // }

  // Render LMSCompetition only if the user is an admin
  return <LMSCompetition {...props} />;
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // Add your mapDispatchToProps logic if needed
});

export default connect(mapStateToProps, mapDispatchToProps)(LMSCompetitionContainer);
