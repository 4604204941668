import styled from 'styled-components';
import { Chip } from '@mui/material';
import { Button } from 'src/components/shared';

export const DayChip = styled(Chip).attrs({})`
  border-radius: 4px;
  width: 66px;
  height: 30px;
  align-items: center;
`;
