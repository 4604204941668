import { Card, CardContent, Stack, Typography, Box } from '@mui/material';
import React from 'react';
import { Spacer, Button } from 'src/components/shared';
import images from 'src/config/images';
import styled from 'styled-components';

import { useSessionProvider } from 'src/modules/lms/utils/sessionStore';

function AiCTA({ session, score }) {
  const { setShowRolePlay } = useSessionProvider();

  if (!session.showRoleplay) return null;
  if (score?.roleplayScore && score?.roleplayScore.length) return null;

  return (
    <Box mt={3}>
      <Card>
        <CardContent sx={{ padding: '24px' }}>
          <Spacer x={1} y={1} />
          <Typography color="textPrimary" textAlign="center" variant="body2">
            Don’t be an average Joe. Practice your {session.title} with one of our Ai Avatars.
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-around"
            alignItems="center"
            mt={3}
            mb={1}
          >
            <Camelon src={images.LMS.cameleon} />
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'rgba(18, 81, 81, 1)',
              }}
              size="large"
              onClick={() => {
                setShowRolePlay(true);
              }}
            >
              Role-play now
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}

export default AiCTA;

const Camelon = styled.img`
  max-width: 105px;
  width: 100%;
`;
