import React from 'react';
import Modal from '../../shared/Modal';
import styled from 'styled-components';
import { ButtonBase } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

function VideoModal({ video, onClose }) {
  if (!video?.id) return null;

  return (
    <Frame open={true} title={video.title} onClose={onClose} fullScreen>
      <ButtonBase className="closeButton" onClick={onClose}>
        <CloseIcon />
      </ButtonBase>
      <div dangerouslySetInnerHTML={{ __html: video.videoUrl }} />
    </Frame>
  );
}

export default VideoModal;

const Frame = styled(Modal)`
  & iframe {
    width: 100%;
    height: 400px;
    min-height: 100vh;
    min-width: 100vw;
  }
  & .closeButton {
    position: absolute;
    right: 40px;
    top: 40px;
    z-index: 999999;
  }
  & .MuiDialogTitle-root {
    display: none !important;
  }
  & .MuiPaper-root {
    background-color: transparent !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
`;
