import React from 'react';
import { Typography, Box } from '@mui/material';
import { Button } from 'src/components/shared';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';

export const FilterBlockDiv = styled.div`
  position: relative;
  background-color: #f5f5f5;
  padding: 8px 16px;
  margin: 16px 0px;
  padding-bottom: 12px;
  border-radius: 4px;
  border: 1px solid #dddddd;
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  & button {
    background-color: #ff0000;
    color: #ffffff;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    &:hover {
      background-color: #ff0000;
      color: #ffffff;
    }
  }
`;

export const FilterBlock = ({ children, type = 'and', ...props }) => {
  return (
    <FilterBlockDiv>
      {type === 'or' ? (
        <CloseButtonWrapper>
          <Button iconButton size="small" onClick={props.onRemove}>
            <CloseIcon sx={{ fontSize: '1.15rem' }} />
          </Button>
        </CloseButtonWrapper>
      ) : null}
      <Box mt={0.5}>
        <Typography component="span" color="textSecondary">
          {type === 'and' ? (
            <Typography component="span" color="secondary" borderBottom="1px solid">
              Must
            </Typography>
          ) : (
            <>
              OR have{' '}
              <Typography component="span" color="secondary" borderBottom="1px solid">
                Any
              </Typography>
            </>
          )}{' '}
          this criteria
        </Typography>
      </Box>
      {children}
    </FilterBlockDiv>
  );
};
