import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Box, Typography, FormHelperText, Alert } from '@mui/material';
import { ConfirmDialog, Empty } from 'src/components/App';
import { Form, Button, Spacer } from 'src/components/shared';
import {
  fetchSequencesLookup,
  enrollContactsToSequenceTest,
} from 'src/modules/sequence/api/sequenceApi';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { toggleContactDrawer } from 'src/modules/app/actions/appActions';

function AddToSequenceForm({
  contact,
  addContactToSequence,
  onClose,
  fetchContactNotes = () => null,
}) {
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [errors, setErrors] = useState([]);
  const [sequenceErrors, setSequenceErrors] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const showContactDrawer = useSelector((state) => state.app.showContactDrawer);
  const emailConnected = useMemo(() => {
    if (user?.nlAccessToken && user?.nlAccessToken !== '') {
      return true;
    }
    return false;
  }, [user]);

  function capitalizeFirstLetter(str) {
    const capitalized = str?.charAt(0).toUpperCase() + str?.slice(1);
    return capitalized;
  }

  const fetchSequenceDetails = async (val) => {
    const newSequences = await fetchSequencesLookup(val);
    if (newSequences && newSequences.length > 0) {
      const organisationSequences = [];
      const privateSequences = [];
      const teamSequences = [];
      newSequences.forEach((sequence) => {
        if (sequence.accessType === 'organisation') organisationSequences.push(sequence);
        else if (sequence.accessType === 'private') privateSequences.push(sequence);
        else teamSequences.push(sequence);
      });
      return [...organisationSequences, ...privateSequences, ...teamSequences];
    } else return [];
  };

  const checkErrors = async (sequence) => {
    try {
      setLoading(true);
      const res = await enrollContactsToSequenceTest(sequence.id, [{ id: contact.id }]);
      if (
        res?.enrollmentContacts[0].errors.length > 0 &&
        res?.enrollmentContacts[0].errors[0].message === 'Contact already enrolled'
      ) {
        setErrors(res?.enrollmentContacts[0].errors);
        setSequenceErrors(true);
        setLoading(false);
      } else {
        setErrors(res?.enrollmentContacts[0].errors);
        setSequenceErrors(false);
        setLoading(false);
      }
    } catch (error) {
      setErrors([]);
      setLoading(false);
    }
  };

  if (!emailConnected) {
    return (
      <Empty
        title="Connect Mailbox"
        description="To send emails via SuperReach your first need to connect your mailbox."
        buttonTitle="Connect"
        buttonProps={{
          variant: 'contained',
          color: 'secondary',
          endIcon: <OpenInNewIcon />,
        }}
        onClick={() => {
          onClose();
          if (showContactDrawer === true) {
            dispatch(toggleContactDrawer());
          }
          history.push('/profile/email');
        }}
      />
    );
  }

  return (
    <>
      <Form
        initialValues={{
          sequence: {},
        }}
        validationSchema={Yup.object().shape({})}
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={false}
        onSubmit={async (values, form) => {
          try {
            setLoading(true);
            const data = JSON.parse(JSON.stringify(values));
            await addContactToSequence(data.sequence.id, contact.id);
            await fetchContactNotes(contact?.id);
            setLoading(false);
            onClose();
          } catch (error) {
            setLoading(false);
          }
        }}
      >
        {({ ...formProps }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formProps.submitForm();
                return false;
              }}
              noValidate
              style={{ height: '100%' }}
            >
              <Box
                display={'flex'}
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
              >
                <Box>
                  <Form.Field.AutoComplete
                    multiple={false}
                    fullWidth
                    options={[]}
                    variant="outlined"
                    name="sequence"
                    label="Select Sequence"
                    optLabel="name"
                    optValue="id"
                    groupBy={(option) =>
                      option?.accessType === 'organisation'
                        ? 'Organisation'
                        : option?.accessType === 'private'
                        ? 'Private'
                        : 'Team'
                    }
                    remoteMethod={async (val) => {
                      return await fetchSequenceDetails(val);
                    }}
                    onChange={checkErrors}
                  />
                  <FormHelperText>
                    Select from active sequences you have permission to access.
                  </FormHelperText>
                  <Box mt={2}>
                    {errors && errors.length ? (
                      <>
                        {errors.map((error, index) => {
                          return (
                            <Box mb={1} key={`contact-enrollment-error-${index}`}>
                              <Alert severity="error" variant="standard">
                                {error.message}
                              </Alert>
                            </Box>
                          );
                        })}
                      </>
                    ) : null}
                  </Box>
                </Box>

                <Box display="flex" justifyContent="flex-end">
                  <Spacer x={1} y={1} />
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    loading={loading}
                    disabled={sequenceErrors}
                    onClick={(event) => {
                      event.preventDefault();
                      if (errors.length) {
                        setConfirmModal(true);
                      } else {
                        formProps.submitForm();
                      }
                    }}
                  >
                    Next
                  </Button>
                </Box>
              </Box>

              <ConfirmDialog
                title="Are you sure?"
                open={confirmModal}
                onConfirm={() => {
                  formProps.submitForm();
                }}
                onClose={onClose}
                loading={loading}
              >
                <Typography>
                  Are you sure you want to enrol contact in the sequence with the errors?
                </Typography>
              </ConfirmDialog>
            </form>
          );
        }}
      </Form>
    </>
  );
}

export default AddToSequenceForm;
