import styled from 'styled-components';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CardContent, Typography } from '@mui/material';

export const ProviderLogo = styled.img`
  max-width: 48px;
  max-height: 48px;
`;

export const ProviderCounter = styled.div`
  display: flex;
  width: 36px;
  height: 36px;
  padding: 1px 0px 0px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 70px;
  background: rgba(0, 0, 0, 0.12);
`;

export const InfoColorCard = styled.div`
  width: 16px;
  height: 16px;
  /* left: 279px;
  top: 597px; */
  background: ${(props) => `${props.color}`};
  border-radius: 4px;
`;

import {
  deepOrange,
  deepPurple,
  blue,
  cyan,
  indigo,
  lightGreen,
  lime,
  teal,
} from '@mui/material/colors';

export const colors = [
  deepOrange[400],
  deepPurple[400],
  cyan[400],
  blue[500],
  teal[500],
  indigo[500],
  lightGreen[500],
  lime[300],
];

export const InfoIconWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  justify-content: flex-end;
`;

export const InfoIcon = styled(InfoOutlinedIcon)`
  fill: rgba(0, 0, 0, 0.54);
  color: rgba(0, 0, 0, 0.54);
  /* background: rgba(0, 0, 0, 0.54); */
`;

export const Content = styled(CardContent)`
  height: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* background-color: #f5f5f5; */
`;

export const Title = styled(Typography).attrs({
  color: 'textSecondary',
})`
  font-size: 16px;
`;

export const Value = styled(Typography)`
  font-size: 32px;
  font-weight: bold;
`;
