import React, { useEffect, useMemo, useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Alert } from '@mui/material';
import { Button, Form } from 'src/components/shared';
import { debounce } from 'lodash';
import * as enrolmentActions from '../../../actions/enrolmentActions';
import { getInputReplacementVariables } from 'src/modules/app/actions/appSelector';

import { fetchUsersLookup } from 'src/modules/users/api/usersApi';

function Email({ task, taskId, contact, formRef }) {
  const dispatch = useDispatch();
  const steps = useSelector((state) => state.enrolment.steps);
  const [editorRef, setEditorRef] = useState(null);
  const [user, setUser] = useState({});
  const textEditorRef = useRef(null);
  const replacementVariables = useSelector((state) => getInputReplacementVariables(state));

  const moveCursorToEnd = () => {
    try {
      editorRef.current.selection.select(editorRef.current.getBody(), true);
      editorRef.current.selection.collapse(false);
      editorRef.current.focus();
    } catch (error) {}
  };

  const onChange = async (value, type) => {
    if (formRef.current) {
      let data = formRef.current.values;
      data = data[taskId];
      const templateId = task?.template?.id || '';
      await populateTemplate(contact.id, templateId, data.subject, data.content);
      if (type === 'content') {
        moveCursorToEnd();
      }
    }
  };

  const getChildEditorRef = (editorRef) => {
    if (editorRef && editorRef !== null) {
      setEditorRef(editorRef);
    }
  };

  const handleChange = useCallback(
    debounce((newValue, type) => onChange(newValue, type), 1000),
    [editorRef],
  );

  useEffect(() => {
    const templateId = task?.template?.id || '';
    let data = formRef.current.values;
    data = data[taskId];
    if (templateId && templateId !== '' && contact && contact.id && contact.id !== '') {
      populateTemplate(contact.id, templateId, data.subject, data.content);
    }
  }, [contact]);

  const errorString = useMemo(() => {
    if (task?.errors?.[contact?.id]?.length) {
      return `Please remove ${task?.errors?.[contact?.id]?.join(', ')} to enable your sending.`;
    }
    return '';
  }, [task]);

  const populateTemplate = (contactId, templateId, subject, content) => {
    return new Promise((resolve, reject) => {
      dispatch(
        enrolmentActions.fetchPopulateEnqueueTemplate(
          taskId,
          contactId,
          templateId,
          subject,
          content,
          resolve,
          reject,
        ),
      );
    });
  };

  return (
    <Box mb={2} mt={2}>
      {errorString && errorString !== '' ? (
        <Alert severity="error" sx={{ padding: '4px 12px' }}>
          {errorString}
        </Alert>
      ) : null}

      {/* <Form.Field.AutoComplete
        multiple={false}
        options={[formRef?.current?.values[taskId]?.sender]}
        fullWidth
        variant="outlined"
        name={`${taskId}.sender`}
        label="Choose Email Sender"
        remoteMethod={(val) => {
          return fetchUsersLookup(val);
        }}
        checkboxes={false}
        optLabel="name"
        optValue="id"
        onChange={(val) => {
          formRef.current.setFieldValue(
            `${taskId}.content`,
            formRef?.current?.values[taskId]?.contentOriginal,
          );
          setTimeout(() => {
            onChange('', 'content');
          }, 100);
        }}
      /> */}

      <Form.Field.Input
        variant="outlined"
        name={`${taskId}.subject`}
        label="Subject"
        fullWidth={true}
        onChange={(val) => handleChange(val, 'subject')}
        showPicker={true}
        options={replacementVariables}
        optValue="value"
        optLabel="text"
        emojiPicker={true}
      />
      <Form.Field.TextEditor
        getChildEditorRef={getChildEditorRef}
        name={`${taskId}.content`}
        onChange={(val) => handleChange(val, 'content')}
        ref={textEditorRef}
      />
      <Form.Field.File name={`${taskId}.attachments`} maxSize={8} />
    </Box>
  );
}

export default Email;
