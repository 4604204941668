import {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import api from 'src/api';
import { convertObjectToQuerystring } from 'src/utils/helper';
const variablesContext = createContext({});

import toast from 'src/utils/toast';

export const useCustomVariables = () => {
  const { variables, paging, setPaging, loading, post, put, filters, setFilters } =
    useContext(variablesContext);
  return {
    variables,
    paging,
    setPaging,
    loading,
    post,
    put,
    filters,
    setFilters,
  };
};

export const CustomVariablesProvider = ({ children }) => {
  const [variables, setVariables] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [paging, setPaging] = useState({
    pageNo: 0,
    perPage: 10,
    _total: 0,
  });

  const payload = useMemo(() => {
    return {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      ...filters,
    };
  }, [paging, filters]);

  const cacheKey = JSON.stringify(payload);

  const ref = useRef(
    useMemo(() => {
      return {
        cacheKey,
        isInitial: true,
      };
    }, []),
  );
  const { current: refData } = ref;
  ref.current.isInitial = false;

  useEffect(() => {
    const hasParamsChanged = refData.cacheKey !== cacheKey;
    if (hasParamsChanged) {
      ref.current.cacheKey = cacheKey;
      fetchVariables();
    }
  }, [payload]);

  const fetchVariables = async () => {
    try {
      setLoading(true);
      const query = convertObjectToQuerystring(payload);
      const response = await api(`variable?${query}`, null, 'get');
      setVariables(response.variable);
      setPaging({
        ...paging,
        _total: response.total.value,
      });
      setLoading(false);
    } catch (error) {
      setVariables([]);
      setLoading(false);
      setPaging({
        ...paging,
        _total: 0,
      });
    }
  };

  useEffect(() => {
    fetchVariables(paging);
  }, []);

  const put = async (id, payload) => {
    try {
      const response = await api(`variable/${id}`, payload, 'put');
      const variable = response.variable[0];
      setVariables(
        variables.map((item) => {
          if (item.id === id) return variable;
          return item;
        }),
      );
      setLoading(false);
      return response;
    } catch (error) {}
  };

  const post = async (payload) => {
    try {
      const response = await api(`variable`, payload, 'post');
      const variable = response.variable;
      setVariables([variable, ...variables]);
      toast.success('Variable created successfully');
      return response;
    } catch (error) {}
  };

  return (
    <variablesContext.Provider
      value={{
        variables,
        paging,
        setPaging,
        filters,
        setFilters,
        loading,
        post,
        put,
      }}
    >
      {children}
    </variablesContext.Provider>
  );
};

export const withCustomVariablesStore = (Component) => (props) =>
  (
    <CustomVariablesProvider>
      <Component {...props} />
    </CustomVariablesProvider>
  );
