import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Container, Stack, Typography } from '@mui/material';
import {
  Intro,
  Accordion,
  StepContent,
  Support,
} from '../../components/onboarding';
import { data as onboardingData } from '../../config/onboardingData';
import { isEmpty } from 'lodash';

function OnboardingView({ user, onboarding, ...props }) {
  const [defaultSequence, setDefaultSequence] = useState({});
  let i = 0;

  const history = useHistory();

  const markStepComplete = async (accordionInner) => {
    if (accordionInner?.type && accordionInner?.type === 'externalLink') {
      props.putOnboardingStatus('helpCenter', {
        ...(onboarding?.helpCenter && { ...onboarding?.helpCenter }),
        [accordionInner.key]: true,
      });
    } else {
      props.putOnboardingStatus(accordionInner.key, true);
    }
  };

  const toggleSequenceForm = () => {
    props.toggleAddSequenceForm();
    props.putOnboardingStatus('sequence', true);
  };

  const toggleTaskForm = async (newTaskStatus = false, step = {}) => {
    history.push('tasks/inbox');
    props.putOnboardingStatus('task', true);
  };

  const toggleEnrollForm = async (hasEnrolled, step) => {};

  const toggleIntegrationDrawer = () => {};

  if (isEmpty(onboarding)) {
    return null;
  }

  return (
    <Container maxWidth="md">
      <Stack padding="36px" gap="40px">
        <Intro user={user} />

        {onboardingData && onboardingData.length && (
          <Stack>
            {onboardingData.map((accordionOuter, index1) => {
              const keysArray = Object.values(onboarding[accordionOuter.key]);
              let progress = 0;
              progress =
                (100 * keysArray.filter((value) => value === true).length) / keysArray.length;
              let duration = 0;
              accordionOuter.items.map((item) => {
                duration += parseInt(item.duration);
              });
              duration = Math.ceil(duration / 60);
              i = 0;

              return (
                <Accordion.Outer
                  key={`AccordionOuter-${index1}`}
                  title={accordionOuter.name}
                  isCompleted={progress == 100}
                  icon={accordionOuter.icon_name}
                  progress={progress}
                  steps={accordionOuter.items.length}
                  time={`${duration} min`}
                >
                  {accordionOuter.items && accordionOuter.items.length && (
                    <>
                      {accordionOuter?.content && accordionOuter?.content !== '' ? (
                        <Box mb={1} ml={2}>
                          <Typography color="textSecondary">{accordionOuter?.content}</Typography>
                        </Box>
                      ) : null}

                      {accordionOuter.items.map((accordionInner, index2) => {
                        i++;

                        if (accordionInner?.type && accordionInner?.type == 'externalLink') {
                          return (
                            <Accordion.ExternalLink
                              markStepComplete={markStepComplete}
                              key={`AccordionInner-${index2}`}
                              isCompleted={
                                onboarding[accordionOuter.key][accordionInner.key]
                                  ? onboarding[accordionOuter.key][accordionInner.key]
                                  : false
                              }
                              expand={accordionInner.isExpand}
                              accordionInner={accordionInner}
                              onboardingData={onboardingData}
                              title={accordionInner.title}
                              index={i}
                            />
                          );
                        }
                        return (
                          <Accordion.Inner
                            key={`AccordionInner-${index2}`}
                            isCompleted={
                              onboarding[accordionOuter.key][accordionInner.key]
                                ? onboarding[accordionOuter.key][accordionInner.key]
                                : false
                            }
                            expand={accordionInner.isExpand}
                            accordionInner={accordionInner}
                            onboardingData={onboardingData}
                            title={accordionInner.title}
                            index={i}
                          >
                            <StepContent
                              step={accordionInner}
                              fields={props.fields}
                              markStepComplete={markStepComplete}
                              toggleSequenceForm={toggleSequenceForm}
                              toggleTaskForm={toggleTaskForm}
                              toggleEnrollForm={toggleEnrollForm}
                              toggleIntegrationDrawer={toggleIntegrationDrawer}
                              toggleAddContactForm={props.toggleAddContactForm}
                              showContactForm={props.showContactForm}
                              defaultSequence={defaultSequence}
                            />
                          </Accordion.Inner>
                        );
                      })}
                    </>
                  )}
                </Accordion.Outer>
              );
            })}
          </Stack>
        )}

        <Support />
      </Stack>
    </Container>
  );
}

export default OnboardingView;
