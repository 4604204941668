import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import { Toolbar, Box, Typography } from '@mui/material';

import { Button, Drawer, Spacer } from '../../../shared';

import ToolBarPickers from './ToolbarPickers';
import DrawerFilter from './DrawerFilter';
import DrawerFormHeader from './DrawerFilter/DrawerFormHeader';
import EditColumnPopup from './EditColumns';
// import CustomDrawer from '../../../components/CustomDrawer';

export const defaultToolbarStyles = (theme) => ({
  root: {
    '@media print': {
      display: 'none',
    },
  },
  fullWidthRoot: {},
  left: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
  },
  fullWidthLeft: {
    flex: '1 1 auto',
  },
  actions: {
    flex: '1 1 auto',
    textAlign: 'right',
  },
  fullWidthActions: {
    flex: '1 1 auto',
    textAlign: 'right',
  },
  titleRoot: {},
  titleText: {},
  fullWidthTitleText: {
    textAlign: 'left',
  },
  icon: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  iconActive: {
    color: theme.palette.primary.main,
  },
  filterPaper: {
    maxWidth: '30%',
    minWidth: '300px',
  },
  filterCloseIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 100,
  },
  searchIcon: {
    display: 'inline-flex',
    marginTop: '10px',
    marginRight: '8px',
  },
  [theme.breakpoints.down('md')]: {
    titleRoot: {},
    titleText: {
      fontSize: '16px',
    },
    spacer: {
      display: 'none',
    },
    left: {
      // flex: '1 1 40%',
      padding: '8px 0px',
    },
    actions: {
      // flex: '1 1 60%',
      textAlign: 'right',
    },
  },
  [theme.breakpoints.down('lg')]: {
    left: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      display: 'block',
      '@media print': {
        display: 'none !important',
      },
    },
    left: {
      padding: '8px 0px 0px 0px',
    },
    titleText: {
      textAlign: 'center',
    },
    actions: {
      textAlign: 'center',
    },
  },
});

const RESPONSIVE_FULL_WIDTH_NAME = 'scrollFullHeightFullWidth';

const TableToolbar = ({
  options,
  classes,
  title,
  myFilterData: filterData,
  displayData,
  defaultFilterState,
  visibleFilter = 0,
  filterState,
  showEditCol = false,
  loading,
  defaultUnSetFilters = false,
  actions,
  ...props
}) => {
  const [searchText, setSearchText] = useState('');
  const [activeSearch] = useState(true);
  const [myFilterData, setMyFilterData] = useState(filterData);
  const [myTaskFilter, setMyTaskFilter] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [editTaskPopup, setEditTaskPopup] = useState(false);

  useEffect(() => {
    if (options?.searchText && options?.searchText !== searchText) {
      setSearchText(options?.searchText);
    }
  }, [options.searchText]);

  useEffect(() => {
    const defaultFilters = { ...defaultFilterState, ...filterState };
    if (!isEmpty(defaultFilters)) {
      Object.keys(defaultFilters).filter(
        (e) => e !== 'pageNo' && e !== 'perPage',
      );
      let payload = {};
      const defaultOne = Object.keys(defaultFilters).filter(
        (e) => e !== 'pageNo' && e !== 'perPage',
      );
      defaultOne.forEach((e) => {
        const ha = filterData.find((fData) => e === fData.type)?.data;
        if (e == 'createDate' || e == 'createdDate' || e == 'lastSendDate') {
          let completedDatedata = filterData.find((fData) => e === fData.type);
          if (completedDatedata) {
            payload = {
              ...payload,
              [e]: {
                name: e,
                filter: [defaultFilters[e]],
              },
            };
            return;
          }
        }
        if (!ha) return;
        payload = {
          ...payload,
          [e]: {
            name: e,
            filter: ha.filter((h1) =>
              h1.isSingle
                ? defaultFilters[e] === h1.id
                : defaultFilters[e].includes(h1.id),
            ),
          },
        };
      });

      setMyTaskFilter({
        ...payload,
      });
    }
  }, [defaultFilterState]);

  const handleSearch = (value) => {
    setSearchText(value);
    props.searchTextUpdate(value);
  };

  const hideSearch = () => {
    const { onSearchClose } = options;

    props.setTableAction('onSearchClose');
    if (onSearchClose) onSearchClose();
    props.searchClose();

    setSearchText(null);
  };

  const handleEditTaskClick = () => {
    setEditTaskPopup(!editTaskPopup);
  };

  const handleFilterChange = ({ isSingle, type, control, ...data }) => {
    if (data.name === 'moreFilter') {
      setOpenDrawer(true);
      return;
    }
    let filters = {
      ...myTaskFilter,
    };

    if (data.filter?.length) {
      filters = {
        ...filters,
        [data.name]: {
          ...data,
        },
      };
    } else {
      if (control === 'dateRangePicker') {
        filters[type] = {
          data: { ...data.data },
        };
      } else {
        delete filters[data.name];
      }
    }

    setMyTaskFilter({ ...filters });

    let filterObject = {};
    Object.keys(filters).forEach((e) => {
      if (
        myFilterData.find((fData) => fData.type === e).control ===
        'dateRangePicker'
      ) {
        let filterData = filters[e]?.filter;
        filterObject = {
          ...filterObject,
          // ...filters[e]?.data,
          [e]: filters[e]?.data
            ? filters[e]
            : filterData
            ? filterData[0]
            : filters[e],
        };
      } else {
        filterObject =
          myFilterData.find((fData) => fData.type === e)?.isSingle && isSingle
            ? {
                ...filterObject,
                [e]: filters[e]?.filter
                  ? filters[e]?.filter.map((f) => f.id)
                  : filters[e],
              }
            : {
                ...filterObject,
                [e]: filters[e]?.filter
                  ? filters[e]?.filter.map((f) => f.id)
                  : filters[e],
              };
      }
    });
    props.refetch(filterObject);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const clearAllFilters = () => {
    let checkDefaultFilter = myTaskFilter[defaultUnSetFilters];
    if (defaultUnSetFilters && checkDefaultFilter) {
      let filterObject = {};
      if (checkDefaultFilter) {
        let filters = {
          [checkDefaultFilter.name]: {
            ...checkDefaultFilter,
          },
        };
        setMyTaskFilter({ ...filters });

        Object.keys(filters).forEach((e) => {
          filterObject = {
            ...filterObject,
            [e]: filters[e]?.filter
              ? filters[e]?.filter.map((f) => f.id)
              : filters[e],
          };
        });
      }

      props.refetch(filterObject);
    } else {
      setMyTaskFilter({});
      props.refetch({});
    }
  };

  const numberOfFilterVisible = visibleFilter ? visibleFilter : 3;

  return (
    <Toolbar
      className={
        options.responsive !== RESPONSIVE_FULL_WIDTH_NAME
          ? classes.root
          : classes.fullWidthRoot
      }
      role={'toolbar'}
      aria-label={'Table Toolbar'}
    >
      <Box
        className={
          options.responsive !== RESPONSIVE_FULL_WIDTH_NAME
            ? classes.left
            : classes.fullWidthLeft
        }
        flex={3}
      >
        {activeSearch === true ? (
          options.customSearchRender ? (
            options.customSearchRender(
              searchText,
              handleSearch,
              hideSearch,
              options,
            )
          ) : (
            <div></div>
          )
        ) : (
          typeof title !== 'string' && title
        )}
        <Box display="flex" className={classes.tableFilters}>
          <ToolBarPickers
            myFilterData={
              myFilterData.slice(numberOfFilterVisible).length
                ? [
                    ...myFilterData.slice(0, numberOfFilterVisible),
                    {
                      type: 'moreFilter',
                      title: 'Advanced filters',
                      data: myFilterData.slice(numberOfFilterVisible + 1),
                    },
                  ]
                : myFilterData.slice(0, numberOfFilterVisible)
            }
            myFilters={myTaskFilter}
            handleChange={handleFilterChange}
            className={classes.toolBarSelect}
          />
        </Box>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        flex={1}
        justifyContent="flex-end"
      >
        {showEditCol && !loading && (
          <Button
            variant="text"
            onClick={handleEditTaskClick}
            size="small"
            style={{ color: 'rgba(0,0,0,0.36)' }}
          >
            Edit Columns
          </Button>
        )}
        {options.customToolbar &&
          options.customToolbar({ displayData: displayData })}
        {actions && (
          <>
            <Spacer basis={1} />
            {actions}
          </>
        )}
      </Box>
      <Box>
        <Drawer
          title="Filters"
          open={openDrawer}
          size="small"
          onClose={handleDrawerClose}
          headerAction={() => (
            <Button
              color="primary"
              variant="outlined"
              size="small"
              tabIndex={0}
              onClick={() => {
                clearAllFilters();
              }}
            >
              Clear All
            </Button>
          )}
        >
          <DrawerFormHeader
            handleCloseClick={handleDrawerClose}
            titleText={props.filterDrawerName || 'Filters'}
            formHeaderTitleClassName={classes.drawerFilterHeaderTitle}
          >
            <Box className={classes.drawerFilterWrapper}>
              <DrawerFilter
                myFilterData={myFilterData.slice(numberOfFilterVisible)}
                handleChange={handleFilterChange}
                myFilter={myTaskFilter}
                defaultUnSetFilters={true}
              />
            </Box>
          </DrawerFormHeader>
        </Drawer>

        <EditColumnPopup
          handleToggleDialog={setEditTaskPopup}
          editClosePopup={editTaskPopup}
          columns={props.columns}
          columnOrder={props.columnOrder}
          onColumnChange={props.onColumnChange}
        />
      </Box>
    </Toolbar>
  );
};

export default withStyles(defaultToolbarStyles, {
  name: 'MUIDataTableToolbar',
})(TableToolbar);
