import React, { useState } from 'react';
import { AppGrid } from 'src/components/App';
import { Button, Drawer } from 'src/components/shared';
import { useColumns } from './useColumns';
import LookupOptionsForm from './LookupOptionsForm';
import OptionEditForm from './OptionEditForm';

import { parseOptionsFromString } from '../../utils/helper';

import AddIcon from '@mui/icons-material/Add';

function NestedLookupsGrid({ option, putLookupList }) {
  const { child } = option;
  const [showForm, setShowForm] = useState(false);
  const looksColumns = useColumns(child, putLookupList);

  const handleColumnChange = () => {};

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const handleSubmit = (data) => {
    console.log('data : ', data);
    let options = data.options;
    if (options && options !== '') {
      options = parseOptionsFromString(options);
      console.log('OPTIONS : ', options);
      return putLookupList(option.id, {
        child: options,
      });
    }

    // return postLookupList(activeLookup, data);
  };

  return (
    <>
      <AppGrid
        // selectableRows="multiple"
        columns={looksColumns.map((col, index) => ({
          ...col,
          options: looksColumns?.columns?.length
            ? { ...col.options, ...looksColumns?.columns[index] }
            : col.options,
        }))}
        data={child}
        onSort={null}
        onTableRowPerPageChange={null}
        onTableChange={null}
        onColumnChange={() => handleColumnChange(false)}
        onFilterChange={null}
        appliedFilters={null}
        // hideToolBar={true}
        className="lookupsGrid"
        title={option?.label ? `${option?.label}` : ''}
        titleProps={{
          marginLeft: 1.5,
          fontSize: '14px',
          color: 'rgba(0,0,0,0.6)',
        }}
        options={{
          sortOrder: {
            name: 'none',
            direction: 'none',
          },
          serverSide: true,
          pagination: false,
          //   page: paging.pageNo,
          //   rowsPerPage: paging.perPage,
          //   count: paging.count,
          selectableRows: 'multiple',
          searchPlaceholder: 'Search Teams',
          //   searchText: filters?._search ? filters._search : '',
          searchOpen: true,
          search: false,
          filter: false,
          download: false,
          viewColumns: false,
          selectToolbarPlacement: 'replace',
          // renderCustomFilterActions: () => {
          //   return (
          //     <Button
          //       variant="outlined"
          //       color="secondary"
          //       size="small"
          //       startIcon={<AddIcon />}
          //       onClick={toggleForm}
          //       disableElevation
          //     >
          //       Option
          //     </Button>
          //   );
          // },
        }}
      />

      <Drawer title={'Add Child Options'} open={showForm} onClose={toggleForm}>
        <LookupOptionsForm onClose={toggleForm} handleSubmit={handleSubmit} />
      </Drawer>
    </>
  );
}

export default NestedLookupsGrid;
