import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { Button, Spacer } from 'src/components/shared';
import AddIcon from '@mui/icons-material/Add';
import { Drawer } from 'src/components/shared';
import LockResetIcon from '@mui/icons-material/LockReset';

import ResetForm from './ResetForm';

function ChangePassword({ status, ...props }) {
  const [tabIndex, setTabIndex] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState({ edit: null, open: false });

  return (
    <>
      <div>
        <Typography variant="h4">Password</Typography>
        <Spacer y={4} />
        <Box>
          <Grid
            container
            spacing={1}
            onClick={() => {
             setDrawerOpen({ edit: null, open: true });
            }}
          >
            <LockResetIcon variant="contained" color="primary" size="medium" />
            <Typography
              variant="h4"
              color="primary"
              size="medium"
              ml={2}
              style={{ cursor: 'pointer' }}
            >
              Reset password
            </Typography>
          </Grid>

          <Drawer
            title={'Reset password'}
            open={drawerOpen.open}
            onClose={() => {
              setDrawerOpen({ edit: null, open: false });
            }}
          >
            <ResetForm editUser={drawerOpen.edit} drawerToggle={setDrawerOpen} {...props} />
          </Drawer>
        </Box>
        <Spacer y={2} />
      </div>
    </>
  );
}

export default ChangePassword;
