import React, { useState, useEffect } from 'react';
import { AppGrid } from 'src/components/App';
import { useSequenceContactsColumns } from './useColumns';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';

const SequenceContactsGrid = ({
  sequencesContacts,
  openRate,
  sequence,
  seqId,
  enrollment,
  contactsLoading,
  sequencesStats,
  sequenceStatsLoading,
  ...props
}) => {
  const { data, filters, paging } = sequencesContacts;
  const gridData = sequencesStats;

  const contactColumns = useSequenceContactsColumns(gridData);

  const handleTableChange = ({ pageNo, perPage }) => {
    props.fetchSequenceContacts(
      {
        pageNo,
        perPage,
      },
      filters,
      seqId,
    );
  };

  const onSort = (data) => {};

  const onFilterChange = (key, value) => {
    if (value && typeof value !== 'undefined') {
      if (key === '_search') {
        props.fetchSequenceContacts(
          {
            ...paging,
            pageNo: 0,
          },
          { ...filters, [key]: value },
          seqId,
        );
      } else {
        props.fetchSequenceContacts(paging, { ...filters, [key]: value }, seqId);
      }
    } else {
      delete filters[key];
      props.fetchSequenceContacts(paging, filters, seqId);
    }
  };

  return (
    <>
      <AppGrid
        columns={contactColumns.map((col, index) => ({
          ...col,
          options: contactColumns?.columns?.length
            ? { ...col.options, ...contactColumns?.columns[index] }
            : col.options,
        }))}
        data={gridData}
        onTableChange={handleTableChange}
        onFilterChange={onFilterChange}
        onSort={onSort}
        loading={sequenceStatsLoading}
        // filters={contactFilters} // hiding Enrolled by filter for now
        appliedFilters={filters}
        options={
          {
            // serverSide: false,
            // pagination: false,
            // page: paging.pageNo,
            // rowsPerPage: paging.perPage,
            // count: paging.count,
            // rowsSelected: rowsSelected,
            // selectableRows: 'multiple',
            // searchPlaceholder: 'Search contacts',
            // search: false,
            // searchText: filters?._search ? filters._search : '',
            // onRowsSelect: (rowsSelected, allRows) => {
            //   setRowsSelected(allRows.map((row) => row.dataIndex));
            // },
          }
        }
      />
    </>
  );
};

export default SequenceContactsGrid;
