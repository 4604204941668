import React, { useState } from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Stack,
  ButtonBase,
  Box,
  Link,
  CircularProgress,
} from '@mui/material';
import moment from 'moment';
import { dateTimeFormat } from 'src/config';
import { Title } from './styles';

import EmailIcon from '@mui/icons-material/Email';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonIcon from '@mui/icons-material/Person';
import PostAddIcon from '@mui/icons-material/PostAdd';
import DownloadingIcon from '@mui/icons-material/Downloading';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';

import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AddTaskIcon from '@mui/icons-material/AddTask';
import randomColor from 'randomcolor';

import { useNotificationsStore } from 'src/modules/app/utils/notificationStore';

function Item({ item }) {
  const [showClear, setShowClear] = useState(false);

  const getIcon = (type) => {
    const sx = {
      color: randomColor({ luminosity: 'bright', seed: type }),
    };
    switch (type) {
      case 'Seq_Error':
        return <ErrorOutlineIcon sx={{ color: 'rgba(239, 83, 80, 1)' }} />;

      case 'Seq_Completed':
        return <CheckCircleIcon sx={sx} />;

      case 'Email_Reply':
        return <EmailIcon sx={{ color: 'rgba(25, 118, 210, 1)' }} />;

      case 'Email_Bounce':
        return <EmailIcon sx={{ color: 'rgba(25, 118, 210, 1)' }} />;
      
      case 'Seq_OutOfOffice':
        return <EmailIcon sx={{ color: 'rgba(25, 118, 210, 1)' }} />;

      case 'CONTACT_ADDED':
        return <PersonAddIcon sx={{ color: 'rgba(76, 175, 80, 1)' }} />;

      case 'CONTACT_UPDATED':
        return <PersonIcon sx={{ color: 'rgba(76, 175, 80, 1)' }} />;

      case 'email_Task_Created':
        return <AddTaskIcon sx={sx} />;

      case 'Contact_Removed_From_Sequence':
        return <GroupRemoveIcon sx={{ color: 'rgba(0,0,0,0.36)' }} />;

      case 'Enrolled_In_Sequence':
        return <PostAddIcon sx={{ color: 'rgb(96 106 149)' }} />;

      case 'Seq_Inprogress':
        return <DownloadingIcon sx={sx} />;

      case 'Seq_Hold':
        return <DataThresholdingIcon sx={sx} />;

      case 'Seq_Notinterested':
        return <PlaylistRemoveIcon sx={sx} />;

      case 'Call_Made':
        return <LocalPhoneIcon sx={{ color: 'rgb(97 101 178)' }} />;

      case 'Seq_Interested':
      case 'Email_Open':
      case 'Email_Click':
      case 'Email_Sent':
      case 'newEmail':
        return <EmailIcon sx={{ color: 'rgba(25, 118, 210, 1)' }} />;

      // case 'success':
      case 'error':
        return <ErrorIcon sx={sx} />;
    }
    return null;
  };

  const { clearSingle, clearId } = useNotificationsStore();

  return (
    <ListItem
      sx={{ alignItems: 'flex-start' }}
      onMouseEnter={() => setShowClear(true)}
      onMouseLeave={() => setShowClear(false)}
    >
      <ListItemIcon sx={{ minWidth: '48px', marginTop: '10px' }}>{getIcon(item.type)}</ListItemIcon>
      <ListItemText
        primary={
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {item?.contact ? (
              <Link
                sx={{ textDecoration: 'none' }}
                color="secondary"
                href={item?.link || '/contact/' + item?.contact}
              >
                <Title dangerouslySetInnerHTML={{ __html: item?.notificationText ? item?.notificationText : item.note }} />
              </Link>
            ) : (
              <Title dangerouslySetInnerHTML={{ __html: item?.notificationText ? item?.notificationText : item.note }} />
            )}
            {!item.seen && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle cx="4" cy="4" r="4" fill="#ED6C02" />
              </svg>
            )}
          </Stack>
        }
        secondary={
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {/* <Tooltip title={moment(item.createdAt).format(dateTimeFormat)}> */}
            <Typography variant="body2" color="textSecondary" placement="bottom-start">
              {/* {moment(item.createdAt).fromNow()} */}
              {moment(item.createdAt).format('DD MMMM YYYY | hh:mm:a')}
            </Typography>
            {/* </Tooltip> */}

            {clearId == item.id ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              showClear && (
                <ButtonBase onClick={() => clearSingle(item.id)} disabled={clearId}>
                  <Typography variant="caption" color="secondary">
                    Clear
                  </Typography>
                </ButtonBase>
              )
            )}
          </Stack>
        }
      />
    </ListItem>
  );
}

export default Item;
