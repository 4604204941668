import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { Button, Form } from 'src/components/shared';

import { fetchPopulateEnqueueTemplate } from '../../../actions/enrolmentActions';
import { debounce } from 'lodash';
import { useState } from 'react';
import { useCallback } from 'react';

function Call({ task, taskId, contact, formRef }) {
  const dispatch = useDispatch();
  const [editorRef, setEditorRef] = useState(null);

  const moveCursorToEnd = () => {
    try {
      editorRef.current.selection.select(editorRef.current.getBody(), true);
      editorRef.current.selection.collapse(false);
      editorRef.current.focus();
    } catch (error) {}
  };

  useEffect(() => {
    if (contact && contact.id && contact.id !== '') {
      populateTemplate(contact.id, undefined, '', task?.description);
    }
  }, [contact]);

  const populateTemplate = (contactId, templateId, subject, content) => {
    return new Promise((resolve, reject) => {
      dispatch(
        fetchPopulateEnqueueTemplate(
          taskId,
          contactId,
          templateId,
          subject,
          content,
          resolve,
          reject,
        ),
      );
    });
  };

  const onChange = async (val, type) => {
    if (formRef.current) {
      let data = formRef.current.values;
      data = data[taskId];
      const templateId = task?.template?.id || '';
      await populateTemplate(contact.id, templateId, data.subject, data.description);
      if (type === 'content') {
        moveCursorToEnd();
      }
    }
  };

  const handleChange = useCallback(
    debounce((newValue, type) => onChange(newValue, type), 1000),
    [editorRef],
  );

  const getChildEditorRef = (editorRef) => {
    if (editorRef && editorRef !== null) {
      setEditorRef(editorRef);
    }
  };

  return (
    <Box mb={2} mt={2}>
      <Form.Field.TextEditor
        name={`${taskId}.description`}
        onChange={(val) => handleChange(val, 'content')}
        showFontStyle={false}
        showFontEditor={false}
        showAlignProps={false}
        showListProps={false}
        showAttachments={false}
        getChildEditorRef={getChildEditorRef}
      />
      {/* <Typography>
        <div dangerouslySetInnerHTML={{ __html: task?.description || '-' }} />
      </Typography> */}
    </Box>
  );
}

export default Call;
