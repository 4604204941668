import React, { useEffect, useRef, useState } from 'react';
import { Typography, Grid, Box, Pagination } from '@mui/material';
import { Spacer } from 'src/components/shared';
import toast from 'src/utils/toast';

import Session from './Session';
import Course from './Course';
import ListSkeleton from './ListSkeleton';

import SearchInput from 'src/components/App/AppGrid/SearchInput';
import { Empty } from 'src/components/App';

function ListSection({ myList, loading, ...props }) {
  const { paging, filters, data } = myList;

  useEffect(() => {
    fetchList(paging, filters);
  }, []);

  const fetchList = (paging, filters) => {
    props.fetchList(paging, filters);
  };

  const removeFromList = async (id) => {
    try {
      await props.deleteList(id);
    } catch (error) {}
  };

  const handlePageChange = (event, page) => {
    fetchList(
      {
        ...paging,
        pageNo: page - 1,
      },
      filters,
    );
  };

  const handleSearch = (value) => {
    if (value !== filters.search) {
      fetchList(
        {
          ...paging,
          pageNo: 0,
        },
        {
          ...filters,
          search: value,
        },
      );
    }
  };

  const pages = Math.ceil(paging.count / paging.perPage);
  const page = paging.pageNo + 1;

  return (
    <>
      <Typography color="secondary" variant="h1" sx={{ fontSize: '38px' }}>
        My List
      </Typography>
      <Spacer x={2} y={2} />
      <SearchInput
        placeholder="Search by keyword"
        searchText={filters?.search || ''}
        onSearch={handleSearch}
      />
      <Spacer x={2} y={2} />
      {loading ? (
        <ListSkeleton />
      ) : (
        <>
          <Grid container display={'flex'} spacing={2}>
            {data.length ? (
              <>
                {data?.map((item, index) => {
                  if (item.session && item.session?.id) {
                    return (
                      <Grid
                        key={`my-list-item-session-${item.session.id}`}
                        item
                        xs={12}
                        md={6}
                        lg={3}
                      >
                        <Session item={item} removeFromList={removeFromList} />
                      </Grid>
                    );
                  }
                  if (item.course && item.course?.id) {
                    return (
                      <Grid
                        key={`my-list-item-course-${item.course.id}`}
                        item
                        xs={12}
                        md={6}
                        lg={3}
                      >
                        <Course item={item} removeFromList={removeFromList} />
                      </Grid>
                    );
                  }
                  return null;
                })}
              </>
            ) : (
              <Empty title="No list found!" />
            )}
          </Grid>
          <Box mt={2} display="flex" alignItems="center" justifyContent="center">
            <Pagination
              count={pages}
              page={page}
              defaultPage={page}
              perPage={paging.perPage}
              onChange={handlePageChange}
            />
          </Box>
        </>
      )}
    </>
  );
}

export default ListSection;
