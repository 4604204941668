import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import {
  Grid,
  Box,
  Typography,
  Divider,
  ButtonBase,
  Stack,
  FormHelperText,
  Tooltip,
} from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import { fieldScopes, fieldTypes } from '../../config';
import CloseIcon from '@mui/icons-material/Close';
import { getLookup } from 'src/modules/app/api/appApis';
import { fetchLookupsKLookup } from 'src/modules/admin/api/fieldApi';
import { DayChip } from './styles';
import { parseOptionsFromStringField } from '../../utils/helper';
import { FieldArray } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';

import { checkValueExists, specialCharacterRegex } from 'src/config';

const defaultOptions = [
  {
    label: '',
    value: '',
  },
];

const optionsFields = ['lookup', 'lookup-multiple', 'dropdown'];

const CustomFieldForm = ({ attribute, module, modules, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [lookups, setLookups] = useState([]);
  const [scope, setScope] = useState([]);
  const [options, setOptions] = useState(defaultOptions);
  const [lookup, setLookup] = useState({});
  const fields = modules[module];

  useEffect(() => {
    fetchLookups();
  }, []);

  const fetchLookups = async () => {
    try {
      const res = await fetchLookupsKLookup();
      setLookups(res);
    } catch (error) {
      setLookups([]);
    }
  };

  useEffect(() => {
    if (attribute && attribute?.scope && attribute?.scope.length) {
      setScope(fieldScopes.filter((field) => attribute?.scope.includes(field.value)));
    }
    if (attribute?.fieldType && attribute?.fieldType === 'lookup' && attribute?.lookup) {
      getLookup(attribute?.lookup)
        .then((response) => {
          setLookup(response.lookup);
          if (response?.lookup?.options && response.lookup.options.length) {
            setOptions(response.lookup.options);
          }
        })
        .catch((error) => {
          console.log('error :', error);
        });
    }
  }, [attribute]);

  const handleSubmit = async (values) => {
    setLoading(true);
    const data = JSON.parse(JSON.stringify(values));
    if (data?.lookup && data?.lookup?.id && data?.lookup?.id !== '') {
      data.lookup = data?.lookup?.id;
    } else {
      data.lookup = null;
    }
    if (data.options && data.options !== '') {
      data.options = parseOptionsFromStringField(data.options);
    } else {
      data.options = [];
    }

    switch (true) {
      case data.fieldType === 'lookup-multiple':
        data.fieldType = 'lookup';
        data.fieldOptions = {
          ...data.fieldOptions,
          multiple: true,
        };
        data.multiple = true;
        break;

      case data.fieldType === 'user-multiple':
        data.fieldType = 'lookup';
        data.fieldOptions = {
          ...data.fieldOptions,
          multiple: true,
        };
        break;

      case data.fieldType === 'contact-multiple':
        data.fieldType = 'contact';
        data.fieldOptions = {
          ...data.fieldOptions,
          multiple: true,
        };
        break;
    }

    try {
      if (attribute?.id) {
        data.scope = [data.scope];
        data.lookupId = attribute?.lookup;
        data.gridParams = attribute?.gridParams;
        if (attribute?.fieldOptions[module])
          data.fieldOptions = {
            ...data?.fieldOptions,
            [module]: { sortOrder: attribute?.fieldOptions[module]?.sortOrder },
          };
        delete data.fieldName;
        await props.putAttribute(attribute?.id, data);
        setLoading(false);
      } else {
        let fieldName = data.label.split(' ').join('');
        fieldName = fieldName[0].toLowerCase() + fieldName.slice(1);
        data.fieldName = fieldName;
        if (data.scope == 'contacts') {
          data.inContact = true;
        }
        if (data.scope == 'sequences') {
          data.inSequence = true;
        }
        if (data.scope == 'tasks') {
          data.inTask = true;
        }
        data.scope = [data.scope];
        data.gridParams = {
          [module]: { sortOrder: getLastOrderIndex(fields, 'gridParams', module) + 1 },
        };
        data.fieldOptions = {
          ...data.fieldOptions,
          [module]: { sortOrder: getLastOrderIndex(fields, 'fieldOptions', module) + 1 },
        };
        await props.postAttribute(data);
        setLoading(false);
      }
      props.onClose();
    } catch (error) {
      setLoading(false);
    }
  };
  const isMultiAndDisabled = () => attribute?.fieldOptions?.multiple && attribute?.id;
  return (
    <Form
      initialValues={{
        label: attribute?.label || '',
        fieldName: attribute?.fieldName || '',
        fieldType: attribute?.fieldOptions?.multiple
          ? 'lookup-multiple'
          : attribute?.fieldType || '',
        scope: 'contacts',
        // options: options,
        existingOptions: options,
        fieldOptions: {
          showOnGrid: attribute?.fieldOptions?.showOnGrid || false,
          showOnView: attribute?.fieldOptions?.showOnView || false,
          showOnForm: attribute?.fieldOptions?.showOnForm || false,
          required: attribute?.fieldOptions?.required || false,
          multiple: attribute?.fieldOptions?.multiple || false,
        },
        lookupType: attribute?.lookupType || 'options',
        lookup: lookup,
        options: '',
        nestedName: attribute?.nestedName || '',
      }}
      validationSchema={Yup.object().shape({
        label: Yup.string()
          .required('Please enter Field label')
          .test('label', 'This field cannot contain special character', specialCharacterRegex)
          .test(
            'unique-field-name',
            'Field label already exists.',
            (value) => !!attribute?.id || !checkValueExists(value, fields, 'label'),
          ),
        fieldType: Yup.string().required('Please choose Field type.'),
        scope: Yup.string().required('Please select Field scope.'),
        lookup: Yup.object().test({
          message: () => 'Please choose a central lookup.',
          test: (value, values) => {
            if (!value) return false;
            const lookupType = values.parent.lookupType;
            const fieldType = values.parent.fieldType;
            if (lookupType === 'lookup' && fieldType === 'lookup') {
              return value?.id && value?.id !== '';
            }
            return true;
          },
        }),
        options: Yup.string().test({
          message: () => 'Please enter options.',
          test: (value, values) => {
            const lookupType = values.parent.lookupType;
            const fieldType = values.parent.fieldType;
            if (lookupType !== 'lookup' && fieldType === 'lookup') {
              if (!value || value === '') {
                // return values.createError({
                //   message: 'Please enter options.',
                //   path: 'options',
                // });
                return true;
              }
              const hasNewLines = value && (value.match(/\n/g) || []).length > 0;
              const hasCommas = value.includes(',');
              if (hasNewLines && hasCommas) {
                return values.createError({
                  message: 'Please save your values either comma OR line separated. Not combined.',
                  path: 'options',
                });
              }
            }
            return true;
          },
        }),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ values, ...formProps }) => {
        const hasChild =
          values.lookup?.options && values.lookup?.options.length
            ? values.lookup?.options.filter((item) => item.child && item.child.length > 0).length >
              0
            : false;

        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formProps.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
              height="100%"
              width="100%"
            >
              <Box width="100%">
                <Grid container>
                  <Grid item xs={12}>
                    <Form.Field.Input
                      fullWidth
                      variant="outlined"
                      name="label"
                      label="Field Label"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {attribute?.fieldType !== 'phone' ? (
                      <Form.Field.Select
                        options={fieldTypes}
                        fullWidth
                        variant="outlined"
                        name="fieldType"
                        label="Field Input Type"
                        // disabled={attribute?.id ? true : false}
                      />
                    ) : (
                      <Form.Field.Input
                        variant="outlined"
                        fullWidth
                        name="fieldType"
                        label="Field Input Type"
                        // disabled={attribute?.id ? true : false}
                      />
                    )}
                  </Grid>
                </Grid>

                {optionsFields.includes(values['fieldType']) ||
                (isMultiAndDisabled() && attribute.fieldType === 'lookup') ? (
                  <Box>
                    <Spacer x={2} y={2} />
                    <Divider />
                    <Spacer x={2} y={2} />
                    <Typography variant="body2" color="textSecondary">
                      How do you want to add your lookup values?
                    </Typography>
                    <Spacer x={1.5} y={1.5} />
                    <Stack direction="row" spacing={1}>
                      <ButtonBase
                        onClick={() => {
                          formProps.setFieldValue('lookupType', 'options');
                        }}
                        // disabled={attribute?.id ? true : false}
                      >
                        <DayChip
                          color={values.lookupType === 'options' ? 'secondary' : 'default'}
                          label="Manually"
                          // disabled={attribute?.id ? true : false}
                        />
                      </ButtonBase>
                      <ButtonBase
                        onClick={() => {
                          formProps.setFieldValue('lookupType', 'lookup');
                        }}
                        // disabled={attribute?.id ? true : false}
                      >
                        <DayChip
                          color={values.lookupType === 'lookup' ? 'secondary' : 'default'}
                          label="Central lookup"
                          // disabled={attribute?.id ? true : false}
                        />
                      </ButtonBase>
                    </Stack>
                    {values.lookupType === 'options' && (
                      <>
                        <Spacer x={1} y={1} />
                        <FormHelperText>
                          Add values, one per line or separated by comma
                        </FormHelperText>
                        <Form.Field.Input
                          fullWidth
                          // disabled={attribute?.id ? true : false}
                          variant="outlined"
                          name="options"
                          multiline={true}
                          maxRows={4}
                          minRows={4}
                        />
                      </>
                    )}

                    {(values.lookupType === 'lookup' ||
                      values.lookupType === 'lookup-multiple') && (
                      <>
                        <Spacer x={1.5} y={1.5} />
                        <Form.Field.AutoComplete
                          fullWidth
                          // disabled={attribute?.id ? true : false}
                          variant="outlined"
                          name="lookup"
                          options={lookups}
                          // remoteMethod={(val) => {
                          //   return fetchLookupsKLookup(val);
                          // }}
                          optLabel="name"
                          optValue="id"
                          label="Choose Lookup"
                        />

                        {hasChild && (
                          <Form.Field.Input
                            fullWidth
                            // disabled={attribute?.id ? true : false}
                            variant="outlined"
                            label="Nested Lookup Field name"
                            name="nestedName"
                          />
                        )}
                      </>
                    )}
                    {attribute?.id && values.lookupType !== '' && values.lookupType !== 'lookup' ? (
                      <FieldArray
                        name="existingOptions"
                        render={(arrayHelpers) => (
                          <div>
                            {values.existingOptions &&
                              values.existingOptions.length > 0 &&
                              values.existingOptions.map((obj, index) => (
                                <React.Fragment key={`existingOptions-${index}`}>
                                  <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={10}>
                                      <Form.Field.Input
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        name={`existingOptions[${index}].label`}
                                        placeholder="option"
                                        disabled
                                      />
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Button
                                        iconButton={true}
                                        onClick={async () => {
                                          await props.deleteLookupOption(obj.id);
                                          arrayHelpers.remove(index);
                                        }}
                                      >
                                        <CloseIcon />
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </React.Fragment>
                              ))}
                            <Box my={2}></Box>
                          </div>
                        )}
                      />
                    ) : null}
                  </Box>
                ) : null}

                <Spacer x={2} y={2} />
                <Divider />
                <Spacer x={2} y={2} />

                <Stack direction="column">
                  {/* <Form.Field.Checkbox name="fieldOptions.showOnGrid" label="Show on Grid" /> */}

                  <>
                    <Form.Field.Checkbox
                      name="fieldOptions.showOnView"
                      label={
                        <Box display={'flex'}>
                          <Typography>Show on Contact details view</Typography>
                          <Spacer x={1} y={1} />

                          <Tooltip
                            title={
                              'Select to include this attribute in the contact details screen or deselect to hide it.'
                            }
                          >
                            <InfoIcon style={{ fill: 'rgba(0, 0, 0, 0.26)', width: 22 }} />
                          </Tooltip>
                        </Box>
                      }
                    />
                    <Form.Field.Checkbox
                      name="fieldOptions.showOnForm"
                      onChange={(val) => {
                        if (val) {
                          formProps.setFieldValue('fieldOptions.showOnForm', true);
                          formProps.setFieldValue('fieldOptions.showOnView', true);
                        }
                      }}
                      label={
                        <Box display={'flex'}>
                          <Typography>Show on Add contact view</Typography>
                          <Spacer x={1} y={1} />
                          <Tooltip
                            title={
                              'Select to include this attribute in the add/edit contact form or deselect to hide it.'
                            }
                          >
                            <InfoIcon style={{ fill: 'rgba(0, 0, 0, 0.26)', width: 22 }} />
                          </Tooltip>
                        </Box>
                      }
                    />
                    <Form.Field.Checkbox
                      name="fieldOptions.required"
                      onChange={(val) => {
                        if (val) {
                          formProps.setFieldValue('fieldOptions.showOnForm', true);
                          formProps.setFieldValue('fieldOptions.showOnGrid', true);
                          formProps.setFieldValue('fieldOptions.showOnView', true);
                        }
                      }}
                      label={
                        <Box display={'flex'}>
                          <Typography>Mandatory</Typography>
                          <Spacer x={1} y={1} />
                          <Tooltip
                            title={
                              'Select this checkbox to make this attribute mandatory. Users will be required to provide a value for this field before they can submit the form'
                            }
                          >
                            <InfoIcon style={{ fill: 'rgba(0, 0, 0, 0.26)', width: 22 }} />
                          </Tooltip>
                        </Box>
                      }
                    />
                  </>

                  {/* {values.fieldType === 'lookup' ? (
                    <Form.Field.Checkbox name="fieldOptions.multiple" label="Multiple" />
                  ) : null} */}
                </Stack>
              </Box>

              <Box width="100%" display="flex" alignItems="flex-end" justifyContent="flex-end">
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={props.onClose}
                  loading={loading?.templates}
                  disabled={loading?.templates}
                >
                  Cancel
                </Button>
                <Spacer x={1} y={1} />
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
};

const getLastOrderIndex = (array = [], prop, module) => {
  return Math.max(
    ...array.map((o) => {
      return typeof o[prop]?.[module]?.sortOrder === 'number' ? o[prop]?.[module]?.sortOrder : 0;
    }),
  );
};

export default CustomFieldForm;
