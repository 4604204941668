import React, { useState } from 'react';
import Modal from '../../shared/Modal';
import { Box, Typography, Stack } from '@mui/material';
import { Form, Spacer, Button } from 'src/components/shared';
import images from 'src/config/images';
import { policy } from './data';

function Accept({ toggle, onSubmit }) {
  const renderFooter = () => {
    return (
      <Form
        initialValues={{
          terms: false,
        }}
        onSubmit={onSubmit}
      >
        {(props) => {
          return (
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ width: '100%' }}
              px={2}
              py={1}
            >
              <Form.Field.Checkbox name="terms" label="I agree to SuperReach’s Privacy Policy" />
              <Stack direction="row" gap={2} alignItems="center">
                {/* <Button variant="text" color="secondary" onClick={toggle}>
                  Cancel
                </Button> */}

                <Button
                  variant="contained"
                  color="secondary"
                  onClick={props.submitForm}
                  disabled={!props.values.terms}
                >
                  Accept
                </Button>
              </Stack>
            </Stack>
          );
        }}
      </Form>
    );
  };

  return (
    <Modal
      title="Privacy Policy"
      open={true}
      customActions={() => {}}
      size="md"
      renderActions={renderFooter}
      onClose={toggle}
      sx={{ zIndex: 999999 }}
    >
      <Stack direction="column" gap={3} alignItems="center" mt={-2}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <div dangerouslySetInnerHTML={{ __html: policy() }} />
        </Box>
        <Box />
      </Stack>
    </Modal>
  );
}

export default Accept;
