import React, { useEffect, useState, useMemo } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Drawer, Menu } from 'src/components/shared';

import WatchLaterIcon from '@mui/icons-material/WatchLater';
import EmailIcon from '@mui/icons-material/Email';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import SmsIcon from '@mui/icons-material/Sms';
import LockIcon from '@mui/icons-material/Lock';
import images from 'src/config/images';

import {
  WaitTimeForm,
  LinkedinForm,
  EmailForm,
  CallbackForm,
  TaskForm,
  SmsForm,
} from '../../SequenceEvents';

const stepTypes = [
  {
    title: 'Wait time',
    desc: 'Add a wait time between steps.',
    icon: <WatchLaterIcon style={{ fill: '#78909C', width: 32, height: 32 }} />,
    type: 'wait',
  },
  {
    title: 'Automated email',
    desc: 'Send an automated email.',
    icon: <EmailIcon style={{ fill: '#42A5F5', width: 32, height: 32 }} />,
    type: 'email',
  },
  {
    title: 'Select Email Template',
    desc: 'Choose email at the time of enrolling.',
    icon: <ContactMailIcon style={{ fill: '#E8779D', width: 32, height: 32 }} />,
    type: 'selectEmailTemplate',
    isAction: true,
  },
  {
    title: 'LinkedIn',
    desc: 'Create a LinkedIn task',
    icon: <LinkedInIcon style={{ fill: '#1282B2', width: 32, height: 32 }} />,
    type: 'linkedin',
  },
  {
    title: 'Call',
    desc: 'Add a call step.',
    icon: <PhoneCallbackIcon style={{ fill: '#66BB6A', width: 32, height: 32 }} />,
    type: 'call',
  },
  {
    title: 'SMS',
    desc: 'Add an SMS step.',
    icon: <SmsIcon style={{ fill: '#66BABC', width: 32, height: 32 }} />,
    type: 'sms',
  },
  {
    title: 'To do',
    desc: 'Add a to do step.',
    icon: <img src={images.sequence.AddTaskFilled} style={{ width: 32, height: 32 }} />,
    type: 'general',
  },
  {
    title: 'End the sequence',
    desc: 'End step of sequence',
    icon: <LockIcon style={{ fill: '#EF5350', width: 32, height: 32 }} />,
    type: 'endsquence',
    isAction: true,
  },
];

function AddNew({
  open,
  hasEndSeq,
  onClose,
  onSubmit,
  action,
  event,
  addStepIndex,
  events,
  template,
}) {
  const [formType, setFormType] = useState(undefined);
  const [formLoading, setFormLoading] = useState(undefined);
  const [linkedinType, setLinkedinType] = useState('linkedinMessage');

  useEffect(() => {
    const linkedinTypes = [
      'linkedinConnection',
      'linkedinMail',
      'linkedinViewProfile',
      'linkedinMessage',
    ];
    if (linkedinTypes.includes(formType)) {
      setLinkedinType(formType);
    }
  }, [formType]);

  const handleClose = () => {
    setLinkedinType('');
    setFormType(undefined);
    onClose();
  };

  useEffect(() => {
    if (action === 'edit' && event?.id && event?.id !== '') {
      setFormType(event.eventType);
    }
    if (action === 'addTemplate' && event?.id && event?.id !== '') {
      setFormType('email');
    }
  }, [action, event]);

  const handleSubmit = async (values) => {
    setFormLoading(true);
    await onSubmit(values);
    setFormLoading(false);
    handleClose();
  };

  const getContent = () => {
    switch (formType) {
      case 'wait':
        return (
          <WaitTimeForm
            onSubmit={handleSubmit}
            handleClose={handleClose}
            loading={formLoading}
            event={event}
          />
        );
        break;

      case 'email':
        return (
          <EmailForm
            onSubmit={handleSubmit}
            handleClose={handleClose}
            loading={formLoading}
            event={event}
            action={action}
            template={template}
          />
        );
        break;

      case 'linkedin':
      case 'linkedinConnection':
      case 'linkedinMessage':
      case 'linkedinMail':
      case 'linkedinViewProfile':
        return (
          <LinkedinForm
            onSubmit={handleSubmit}
            handleClose={handleClose}
            loading={formLoading}
            event={event}
            linkedinType={linkedinType}
          />
        );
        break;

      case 'call':
        return (
          <CallbackForm
            onSubmit={handleSubmit}
            handleClose={handleClose}
            loading={formLoading}
            event={event}
          />
        );
        break;

      case 'general':
        return (
          <TaskForm
            onSubmit={handleSubmit}
            handleClose={handleClose}
            loading={formLoading}
            event={event}
          />
        );
        break;

      case 'sms':
        return (
          <SmsForm
            onSubmit={handleSubmit}
            handleClose={handleClose}
            loading={formLoading}
            event={event}
          />
        );
        break;

      case 'endsquence':
      default:
        break;
    }
  };

  const getTitle = () => {
    switch (formType) {
      case 'wait':
        return 'Wait Period';
        break;

      case 'email':
        return 'Email';
        break;

      case 'linkedinConnection':
        return 'LinkedIn Connection';
        break;

      case 'linkedinViewProfile':
        return 'LinkedIn View Profile';
        break;

      case 'linkedinMail':
        return 'LinkedIn InMail';
        break;

      case 'linkedinMessage':
        return 'LinkedIn Message (Manual)';

      case 'linkedin':
        return 'LinkedIn';
        break;

      case 'call':
        return 'Call Back';
        break;

      case 'sms':
        return 'SMS';
        break;

      case 'general':
        return 'Reminder';
        break;

      case 'endsquence':
      default:
        return 'End Sequence';
        break;
    }
  };

  const sendEndSequence = () => {
    const data = {
      eventType: 'endsquence',
    };
    handleSubmit(data);
  };

  const sendSelectEmail = () => {
    const data = {
      eventType: 'selectEmailTemplate',
    };
    handleSubmit(data);
  };

  const onSelect = (step) => {
    if (step.isAction) {
      if (step.type === 'endsquence') {
        sendEndSequence();
      }
      if (step.type === 'selectEmailTemplate') {
        sendSelectEmail();
      }
    } else {
      if (step.type === 'linkedin') {
        setLinkedinType('linkedinMessage');
      }
      setFormType(step.type);
    }
  };

  // const setLinkedinType = (type) => {};
  // const setLinkedinType = (type) => {};

  const menuTitle = useMemo(() => {
    switch (linkedinType) {
      case 'linkedinConnection':
        return 'Connection';
        break;

      case 'linkedinMail':
        return 'InMail';
        break;

      case 'linkedinViewProfile':
        return 'View Profile';
        break;

      default:
      case 'linkedinMessage':
        return 'Message';
        break;
    }
  }, [linkedinType]);

  const isDisabled = (step) => {
    if (step.type === 'endsquence') {
      if (hasEndSeq === true) {
        return true;
      }
      if (Object.values(events).length > addStepIndex + 1) {
        return true;
      }
    }
    return false;
  };

  return (
    <Drawer
      open={open}
      disableEnforceFocus
      title={!formType ? 'Select steps' : getTitle()}
      showBack={!formType || action == 'edit' ? false : true}
      onClose={handleClose}
      disabledPadding={!formType ? true : false}
      onBackClick={() => {
        setFormType(undefined);
      }}
      renderHeaderFunction={() => {
        if (formType !== 'linkedin') return null;
        return (
          <Menu
            iconButton={false}
            title={menuTitle}
            color="secondary"
            buttonStyles={{ padding: 0, textTransform: 'none', fontWeight: 'normal' }}
            options={[
              {
                label: 'Message',
                onClick: () => {
                  setLinkedinType('linkedinMessage');
                },
              },
              {
                label: 'Connection',
                onClick: () => {
                  setLinkedinType('linkedinConnection');
                },
              },
              {
                label: 'Inmail',
                onClick: () => {
                  setLinkedinType('linkedinMail');
                },
              },
              {
                label: 'View Profile',
                onClick: () => {
                  setLinkedinType('linkedinViewProfile');
                },
              },
            ]}
          />
        );
      }}
    >
      {formType ? (
        <>{getContent()}</>
      ) : (
        <List disablePadding={true}>
          {stepTypes.map((step, index) => {
            return (
              <ListItem
                key={`add-step-type-${index}`}
                button
                style={{ paddingLeft: 30, paddingRight: 30 }}
                onClick={() => {
                  onSelect(step);
                }}
                // disabled={hasEndSeq === true && step.type === 'endsquence'}
                disabled={isDisabled(step)}
              >
                <ListItemIcon>{step.icon}</ListItemIcon>
                <ListItemText primary={step.title} secondary={step.desc} />
              </ListItem>
            );
          })}
        </List>
      )}
    </Drawer>
  );
}

export default AddNew;
