import React, { useState, useEffect, useMemo } from 'react';
import { Typography, Grid, Card } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import FieldCard from './FieldCard';
import { FieldsTable, TBody } from './styles';

const tip = (type) =>
  `Drag and drop the fields using the icon on the left to change the order that they appear on the ${
    type === 'form' ? 'add to SuperReach form.' : 'grid'
  } `;

const CustomFieldsOrder = ({
  attributes,
  modules,
  module,
  openFormDrawer,
  type = 'grid',
  ...props
}) => {
  const [fields, setFields] = useState([]);

  const enabledFields = useMemo(() => {
    if (type === 'form') {
      if (fields?.length)
        return fields.filter(
          (field) => field.fieldOptions?.showOnForm === true && field.status !== 'inactive',
        );
      else return [];
    } else {
      return fields;
    }
  }, [fields, type]);

  useEffect(() => {
    let fields1, fields2;
    const clone1 = modules[module] ? JSON.parse(JSON.stringify(modules[module])) : [];
    const clone2 = modules[module] ? JSON.parse(JSON.stringify(modules[module])) : [];
    if (modules && modules[module]) {
      fields1 = clone1.sort(function (a, b) {
        return a?.fieldOptions &&
          a?.fieldOptions[module] &&
          b?.fieldOptions &&
          b?.fieldOptions[module]
          ? a.fieldOptions[module].sortOrder - b.fieldOptions[module].sortOrder
          : -1;
      });

      fields2 = sortnull(clone2, module);
      if (type === 'form') {
        setFields(fields1);
      } else {
        setFields(fields2);
      }
      // setFormFields(fields1);
      // setGridFields(fields2);
    }
  }, [modules, module, type]);

  const handleDragEnd = (data) => {
    const destination = enabledFields[data.destination.index];
    props.handleAttributeDragDrop(module, {
      ...data,
      destination: {
        ...data.destination,
        index: destination.fieldOptions[module]?.sortOrder || data.destination.index,
      },
    });
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card sx={{ boxShadow: 'none' }}>
            <Typography variant="body2" color="textSecondary" mb={'10px'}>
              {tip(type)}
            </Typography>
            <>
              <DragDropContext onDragEnd={handleDragEnd}>
                {enabledFields && enabledFields.length ? (
                  <Droppable
                    droppableId={`${type === 'form' ? 'formFields' : 'gridFields'}`}
                    direction="vertical"
                    key={`${type === 'form' ? 'formFields' : 'gridFields'}`}
                  >
                    {(droppableProvided) => (
                      <div
                        key={`${type === 'form' ? 'formFields' : 'gridFields'}-provider`}
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                      >
                        <FieldsTable>
                          <TBody>
                            {enabledFields.map((field, index) => {
                              return (
                                <Draggable key={field.id} draggableId={field.id} index={index}>
                                  {(draggableProvided, snapshot) => {
                                    return (
                                      <>
                                        <FieldCard
                                          field={field}
                                          draggableProvided={draggableProvided}
                                          onEdit={props.onEdit}
                                          snapshot={snapshot}
                                          view={`${type === 'form' ? 'form' : 'grid'}`}
                                          deleteAttribute={props.deleteAttribute}
                                          putAttribute={props.putAttribute}
                                        />
                                      </>
                                    );
                                  }}
                                </Draggable>
                              );
                            })}
                          </TBody>
                        </FieldsTable>
                      </div>
                    )}
                  </Droppable>
                ) : null}
              </DragDropContext>
            </>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

/**
 *
 * to remove random behaviour
 * due to inconsistent falsy values
 *
 * @param {*} arr
 * @param {*} module
 * @returns sorted arr
 */
function sortnull(arr, module) {
  const sorter = function (a, b) {
    let av, bv;
    if (!a?.gridParams) av = null;
    else if (!module in a.gridParams)
      // key exist
      av = null;
    else if (!a.gridParams[module]?.sortOrder && a.gridParams[module]?.sortOrder !== 0) av = null;
    else av = a.gridParams[module]?.sortOrder;

    if (!b?.gridParams) bv = null;
    else if (!module in b.gridParams)
      // key exist
      bv = null;
    else if (!b.gridParams[module]?.sortOrder && b.gridParams[module]?.sortOrder !== 0) bv = null;
    else bv = b.gridParams[module]?.sortOrder;

    if (av === bv)
      // identical? return 0
      return 0;
    else if (av === null)
      // a is null? last
      return 1;
    else if (bv === null)
      // b is null? last
      return -1;
    return av - bv;
  };
  return arr.sort(sorter);
}

export default CustomFieldsOrder;
