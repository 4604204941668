import React from 'react';
import { AppGrid } from 'src/components/App';

import { useLmsReportsProvider } from '../../utils/reportsStore';

import { useColumns } from './useColumns';

function Users({ loading }) {
  const { userSummary } = useLmsReportsProvider();
  const columns = useColumns(userSummary);
  return (
    <>
      <AppGrid
        selectableRows="none"
        columns={columns}
        data={userSummary}
        loading={loading}
        options={{
          pagination: false,
          serverSide: false,
        }}
      />
    </>
  );
}

export default Users;
