import React from 'react';
import useStyles from './style';
import Avatar from '../../../../assets/images/LMS/Competition/Avatar.png';
import Gold from '../../../../assets/images/LMS/Competition/Gold.png';
import Silver from '../../../../assets/images/LMS/Competition/Silver.png';
import Bronze from '../../../../assets/images/LMS/Competition/Bronze.png';
import { Box, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';

const Performer = ({
  avatar,
  classes,
  name,
  score,
  roleplays,
  medal,
  totalRoleplays,
  hideMedal,
}) => {
  return (
    <Grid container alignItems={'center'} className="topGrid">
      {!hideMedal && (
        <Grid item xs={2}>
          <img
            src={medal === 'gold' ? Gold : medal === 'silver' ? Silver : Bronze}
            className={classes.medal}
          />
        </Grid>
      )}
      <Grid item xs={4}>
        <img src={avatar || Avatar} className={classes.avatar_top} />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h1" className={classes.topGridHeading}>
          {name}
        </Typography>
        <Box
          className={classes.box}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography className={classes.span}>Score</Typography>
          <button className={classes.btn_score}>{score}/5</button>
        </Box>
        <Box
          className={classes.box}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography className={classes.span}>Roleplays</Typography>
          <p className={classes.p}>
            <span className={classes.green}>{roleplays}</span>/{totalRoleplays}{' '}
          </p>
        </Box>
      </Grid>
    </Grid>
  );
};
const TopPerformers = ({ report, competition, type, hideMedal }) => {
  const [newReports, setnewReports] = useState([]);

  const calculateTheScores = () => {
    if (report?.length) {
      let newReports = report?.map((it) => {
        let avgScore = (it.totalScore / it.totalRolePlaysDone).toFixed(1).replace(/.0$/, '');
        if (isNaN(avgScore)) {
          avgScore = 0;
        }

        // Filter roleplays within the competition
        const rolePlaysWithinCompetition = it.rolePlays.filter(
          (rp) => rp.isPlayedInWithInCompetition,
        );

        return { ...it, avg: avgScore, rolePlaysWithinCompetition };
      });
      newReports = newReports?.sort((a, b) => (a.avg < b.avg ? 1 : -1));
      // console.log('NRE', newReports, competition);
      setnewReports(newReports?.slice(0, 3));
    }
  };
  useEffect(() => {
    calculateTheScores();
  }, [report]);
  const classes = useStyles();
  return (
    <div className={classes.topContainer}>
      <Typography variant="h1" className={classes.topHeading}>
        Top performers
      </Typography>
      <div className={'performer_' + type}>
        {newReports?.map((item, index) => (
          <Performer
            classes={classes}
            key={item.id}
            name={item.name}
            score={item.avg}
            avatar={item.avatar}
            roleplays={item.rolePlaysWithinCompetition.length}
            totalRoleplays={competition.roleplays}
            medal={index === 0 ? 'gold' : index === 1 ? 'silver' : 'bronze'}
            hideMedal={hideMedal}
          />
        ))}
      </div>
      {/* // <Performer classes={classes} name="Larissa Foster" score={4} roleplays={25} medal="silver" />
      // <Performer classes={classes} name="Varun Gupta" score={3.9} roleplays={22} medal="bronze" /> */}
    </div>
  );
};

export default TopPerformers;
