import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Grid, Box, Typography, Alert, FormHelperText, MenuItem, Stack } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import { Popover } from 'src/components/App';
import { getInputReplacementVariables } from 'src/modules/app/actions/appSelector';

import { fetchTemplatesLookup } from 'src/modules/app/api/appApis';
import { saveTemplate, fetchTemplateDetails, updateTemplate } from 'src/modules/admin/api/adminApi';
import { fetchTeamLookup, fetchUsersLookup } from 'src/modules/users/api/usersApi';
import { bhCandidateSearch } from 'src/modules/contacts/api/contactApis';
import { getIntegrations } from 'src/modules/app/actions/appSelector';

const EmailForm = ({ onSubmit, action, loading, handleClose, event, ...props }) => {
  const [template, setTemplate] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  // const [defSender, setDefSender] = useState({});
  const [fetchingTemplates, setFetchingTemplates] = useState(false);
  const [description, setDescription] = useState('');
  const [files, setFiles] = useState([]);

  const popoverRef = useRef(null);

  const integrations = useSelector((state) => getIntegrations(state));
  const templateCategoryList = useSelector((state) => state.app.globals.templateCategoryList);
  const replacementVariables = useSelector((state) => getInputReplacementVariables(state));
  const sequenceAccessTypeList = useSelector((state) => state.app.globals?.sequenceAccessTypeList);
  const user = useSelector((state) => state.auth.user);

  // useEffect(() => {
  //   if (event?.sender && event?.sender?.id) {
  //     setDefSender({
  //       ...event?.sender,
  //       name: `${event?.sender.fname} ${event?.sender.lname} (${event?.sender.email})`,
  //     });
  //   } else {
  //     setDefSender({
  //       ...user,
  //       name: `${user.fname} ${user.lname} (${user.email})`,
  //     });
  //   }
  // }, [event?.sender, user]);

  const options = useMemo(() => {
    if (templateCategoryList && templateCategoryList.length) {
      return templateCategoryList;
      // if (integrations.isBullhornActive) {
      //   return templateCategoryList;
      // }
      // return templateCategoryList.filter((item) => item.value !== 'cv-spec');
    }
    return [];
  }, [integrations, templateCategoryList]);

  const existingTemplates = useMemo(() => {
    if (!event?.emailTemplates || event.emailTemplates.length === 0) {
      return [];
    }
    return event.emailTemplates.map((item) => item.id);
  }, [event]);

  useEffect(() => {
    if (template?.attachments && template.attachments.length) {
      // fetchAttachments();
    }
  }, [template?.attachments]);

  useEffect(() => {
    if (template.id) {
      setFetchingTemplates(true);
      fetchTemplateDetails(template.id)
        .then((res) => {
          setFetchingTemplates(false);
          setTemplate(res.template);
          setFiles([...files, ...res.template.attachments]);
        })
        .catch((err) => {
          setFetchingTemplates(false);
          setTemplate([]);
        });
    }
  }, [template.id]);

  const fetchTemplatesDetails = async (val, type) => {
    const newTemplates = await fetchTemplatesLookup(val, type);
    if (newTemplates && newTemplates.length > 0) {
      const sequenceResults = newTemplates
        .sort(
          (a, b) =>
            (a.permissionType === '') - (b.permissionType === '') ||
            a.permissionType - b.permissionType,
        )
        .map((option) => {
          var permissionType =
            option.permissionType === 'team'
              ? 'Team'
              : option.permissionType === 'private'
              ? 'Private'
              : option.permissionType === 'organisation' || option.permissionType === ''
              ? 'Organization'
              : 'Organization';
          return {
            accessType: permissionType,
            ...option,
          };
        });
      return await sequenceResults;
    }
  };

  useEffect(() => {
    setTemplate(props.template);
    setDescription(props.template?.content);
  }, [event, props.template]);

  const handleSubmit = async (values) => {
    const data = JSON.parse(JSON.stringify(values));
    const bhCandidate = data.bhCandidate;
    delete data.bhCandidate;
    const attachments =
      data.attachments && data.attachments.length
        ? JSON.parse(JSON.stringify(data.attachments))
        : [];

    let saveAsNew = data.saveAsNew;
    let sourceTemplate = '';
    let templateType = 'email';

    if (props.template?.id && props.template?.id !== '') {
      if (props.template?.id == data.emailTemplates?.id) {
        await updateTemplate(props.template?.id, {
          // type: 'email',
          subject: data.subject,
          content: data.description,
          category: data.category,
          isPublished: true,
          attachments: attachments.length ? attachments.map((file) => file.id) : [],
        });
      }
    }

    if (data.emailTemplates && data.emailTemplates?.id && data.emailTemplates?.id !== '') {
      if (
        props.template?.id &&
        props.template?.id !== '' &&
        props.template?.id == data.emailTemplates?.id
      ) {
        if (!existingTemplates.includes(data.emailTemplates.id)) {
          data.emailTemplates = [...existingTemplates, data.emailTemplates.id];
        } else {
          data.emailTemplates = [...existingTemplates];
        }
        saveAsNew = false;
      } else {
        if (data.saveAsNew === true) {
          templateType = 'email';
        } else {
          templateType = 'clonedEmail';
          sourceTemplate = data.emailTemplates.id;
        }
        saveAsNew = true;
      }
    } else {
      templateType = data.saveAsNew === true ? 'email' : 'clonedEmail';
      saveAsNew = true;
      data.name = data.name && data.name !== '' ? data.name : data.subject;
    }

    // data.sender = data?.sender && data?.sender?.id ? data.sender.id : null;
    delete data?.sender;

    if (saveAsNew === true) {
      let newTemplate = await saveTemplate({
        type: templateType,
        name: data.name,
        subject: data.subject,
        content: data.description,
        category: data.category,
        isPublished: true,
        ...(data.saveAsNew === false && { personalisedEmail: true }),
        files: attachments.length ? attachments.map((file) => file.id) : [],
        attachments: attachments.length ? attachments.map((file) => file.id) : [],
        sharedWithTeams: data.sharedWithTeams.map((team) => team.id),
        sharedWithUsers: data.sharedWithUsers.map((team) => team.id),
        permissionType: data.permissionType,
        ...(templateType === 'clonedEmail' && { sourceTemplate: data.emailTemplates.id }),
        ...(data.category === 'cv-spec' &&
          bhCandidate?.bhCandidateId !== '' && {
            bhCandidateId: bhCandidate.bhCandidateId,
            bhCandidateUrl: bhCandidate.url,
            bhCandidateName: bhCandidate.name,
          }),
      });

      newTemplate = newTemplate.template;
      if (action === 'addTemplate') {
        data.emailTemplates = [newTemplate.id];
      } else {
        if (!existingTemplates.includes(data.emailTemplates.id)) {
          data.emailTemplates = [...existingTemplates, newTemplate.id];
        }
        const oldIndex = data.emailTemplates.indexOf(props.template?.id);
        if (oldIndex > -1) {
          data.emailTemplates.splice(oldIndex, 1);
        }
        data.templateProps = {
          ...event?.templateProps,
          [newTemplate.id]: {
            status: true,
          },
        };
      }
      delete data.category;
      delete data.subject;
      onSubmit(data);
    } else {
      delete data.category;
      delete data.subject;
      data.attachments = attachments.length ? attachments.map((file) => file.id) : [];
      data.files = data.attachments;
      onSubmit(data);
    }
  };

  return (
    <Form
      initialValues={{
        eventType: 'email',
        name: template?.name || '',
        subject: template?.subject || '',
        emailTemplates: template,
        description: description,
        category: template?.category || '',
        saveAsNew: false,
        attachments: files,
        permissionType: template?.permissionType || '',
        bhCandidate:
          template?.bhCandidateId !== ''
            ? {
                bhCandidateId: template?.bhCandidateId,
                name: template?.bhCandidateName,
                url: template?.bhCandidateUrl,
              }
            : {},
        sharedWithUsers: [],
        sharedWithTeams: [],
        // sender: defSender,
        // isAutoClose: event?.isAutoClose || false,
        autoCloseDuration: event?.autoCloseDuration ? parseInt(event?.autoCloseDuration) : 0,
      }}
      validationSchema={Yup.object().shape({
        description: Yup.string().required('Please enter description!'),
        subject: Yup.string().required('Please enter subject!'),
        name: Yup.string().test({
          message: () => 'Please enter template name!',
          test: (values, { options }) => {
            if (!values && options.parent.saveAsNew === false) return true;
            return values && typeof values !== 'undefined' && values !== '';
          },
        }),
        autoCloseDuration: Yup.number()
          .integer()
          .test({
            message: () => 'Please choose Auto-Close duration!',
            test: (values, { options }) => {
              if (!values) return true;
              return values && values > 0;
            },
          }),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ values, ...formProps }) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formProps.submitForm();
              return false;
            }}
            style={{ minHeight: 'calc(100vh - 98px)' }}
            noValidate
          >
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
              height="100%"
              width="100%"
              sx={{ minHeight: 'calc(100vh - 98px)' }}
            >
              <Box width="100%">
                {props.template?.id != values.emailTemplates?.id || true ? (
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography variant="body2">Template</Typography>
                    <Form.Field.AutoComplete
                      options={[template]}
                      fullWidth
                      // disableClearable={true}
                      clearOnEscape={true}
                      className="inline-form-select"
                      name="emailTemplates"
                      placeholder="Select Existing Template"
                      groupBy={(option) => option?.accessType || ''}
                      getOptionLabel={(option) => option.name || ''}
                      onChange={(val) => {
                        if (val) {
                          setTemplate(val);
                          setDescription(val.content);
                        }
                      }}
                      remoteMethod={(val) => {
                        return fetchTemplatesDetails(val, 'email');
                      }}
                      loading={fetchingTemplates}
                      optLabel="name"
                      optValue="id"
                      multiple={false}
                      disabled={values.saveAsNew}
                    />
                  </Box>
                ) : null}

                {/* <Form.Field.AutoComplete
                  multiple={false}
                  options={[defSender]}
                  fullWidth
                  variant="outlined"
                  name="sender"
                  label="Choose Email Sender"
                  remoteMethod={(val) => {
                    return fetchUsersLookup(val, true);
                  }}
                  checkboxes={false}
                  optLabel="name"
                  optValue="id"
                /> */}

                {/* <Box display="flex" alignItems="center" justifyContent="center" my={1.5}>
                  <Typography color="textSecondary">
                    OR create a new Email template below
                  </Typography>
                </Box> */}

                {values.saveAsNew === true ? (
                  <>
                    <Form.Field.Input
                      fullWidth
                      variant="outlined"
                      name="name"
                      label="Template name"
                    />
                  </>
                ) : null}

                <Form.Field.Input
                  fullWidth
                  variant="outlined"
                  name="subject"
                  label="Subject"
                  showPicker={true}
                  options={replacementVariables}
                  optValue="value"
                  optLabel="text"
                  emojiPicker={true}
                />

                <Spacer x={2} y={2} />

                <Form.Field.TextEditor
                  fullWidth
                  variant="outlined"
                  name="description"
                  label="Description"
                />

                {values.saveAsNew === true ? (
                  <>
                    <Grid container spacing={2} mt={1}>
                      <Grid item xs={6}>
                        <Form.Field.Select
                          options={options}
                          fullWidth
                          variant="outlined"
                          name="category"
                          label="Template Category"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Form.Field.Select
                          options={sequenceAccessTypeList || []}
                          fullWidth
                          variant="outlined"
                          name="permissionType"
                          label="Permission Type"
                          showNone={false}
                          optLabel="label"
                          optValue="value"
                          tip="Select who has access to this template."
                          onChange={(val) => {
                            switch (val) {
                              case 'private':
                                formProps.setFieldValue('sharedWithUsers', []);
                                break;
                              case 'team':
                                formProps.setFieldValue('sharedWithTeams', []);
                                break;
                              case 'organisation':
                              default:
                                formProps.setFieldValue('sharedWithUsers', []);
                                formProps.setFieldValue('sharedWithTeams', []);
                                break;
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {values.category === 'cv-spec' && integrations.isBullhornActive && (
                          <Form.Field.AutoComplete
                            multiple={false}
                            options={[]}
                            fullWidth
                            variant="outlined"
                            name="bhCandidate"
                            label="Select Candidate"
                            remoteMethod={async (val) => {
                              const res = await bhCandidateSearch(val);
                              return res.candidates;
                            }}
                            renderOption={(option, props) => {
                              return (
                                <MenuItem {...props}>
                                  <Box>
                                    <Typography color="textPrimary">{option.name}</Typography>
                                    <Stack direction="row" gap={0.5}>
                                      <Typography color="textSecondary" variant="body2">
                                        {option.jobTitle}
                                      </Typography>
                                      <Typography color="secondary" variant="body2">
                                        at
                                      </Typography>
                                      <Typography color="textSecondary" variant="body2">
                                        {option.employer}
                                      </Typography>
                                    </Stack>
                                  </Box>
                                </MenuItem>
                              );
                            }}
                            optLabel="name"
                            optValue="bhCandidateId"
                          />
                        )}
                      </Grid>
                    </Grid>
                    {values.permissionType === 'private' ? (
                      <Form.Field.AutoComplete
                        multiple={true}
                        options={values?.sharedWithUsers || users}
                        fullWidth
                        variant="outlined"
                        name="sharedWithUsers"
                        label="Select users"
                        remoteMethod={(val) => {
                          return fetchUsersLookup(val);
                        }}
                        checkboxes={true}
                        optLabel="name"
                        optValue="id"
                      />
                    ) : null}

                    {values.permissionType === 'team' ? (
                      <Form.Field.AutoComplete
                        multiple={true}
                        options={values?.sharedWithTeams || teams}
                        fullWidth
                        variant="outlined"
                        name="sharedWithTeams"
                        checkboxes={true}
                        label="Select Teams"
                        remoteMethod={(val) => {
                          return fetchTeamLookup(val);
                        }}
                        optLabel="name"
                        optValue="id"
                      />
                    ) : null}
                  </>
                ) : null}

                <Form.Field.File
                  fullWidth
                  variant="outlined"
                  name="attachments"
                  label="Files"
                  // onChange={(val) => {
                  //   setFiles(val);
                  // }}
                />

                {/* <Box display="flex" alignItems="center">
                  <Box ml={2} mr={-2} mt={-1.5}>
                    <Form.Field.Switch name="isAutoClose" label={''} />
                  </Box>
                  <Typography variant="body2" color="textSecondary">
                    Automatically mark this step as complete if not completed in <br />
                    <Popover
                      trigger="click"
                      width={240}
                      Child={
                        <Typography
                          color={values.isAutoClose ? 'secondary' : 'textSecondary'}
                          variant="body2"
                          sx={{ display: 'inline', cursor: 'pointer' }}
                        >
                          {values?.autoCloseDuration && values?.autoCloseDuration > 0
                            ? taskAutoCloseOptions.filter(
                                (item) => item.value === values?.autoCloseDuration,
                              )[0]?.label || `${values?.autoCloseDuration} hour(s)`
                            : 'Select Days'}
                        </Typography>
                      }
                      isPadding={true}
                      ref={popoverRef}
                      disabled={!values.isAutoClose}
                    >
                      <Form.Field.Select
                        options={taskAutoCloseOptions}
                        fullWidth={true}
                        variant="outlined"
                        name="autoCloseDuration"
                        optLabel="label"
                        optValue="value"
                        showNone={false}
                        onChange={(val) => {
                          popoverRef.current.close();
                        }}
                      />
                    </Popover>
                    &nbsp;after the due date.
                  </Typography>
                  {}
                </Box> */}
                {formProps?.errors?.autoCloseDuration &&
                  formProps?.errors?.autoCloseDuration !== '' && (
                    <Box ml={6.5} mt={1}>
                      <FormHelperText error={true}>
                        {formProps?.errors?.autoCloseDuration}
                      </FormHelperText>
                    </Box>
                  )}
                {/* <Spacer x={2} y={2} /> */}
                {/* <Alert severity="info">
                  To ensure that this task, if not complete, does not stop your sequence from
                  progressing, enable the above setting.
                </Alert> */}
              </Box>

              <Box mt={2} display="flex" justifyContent="space-between" width="100%">
                <Form.Field.Checkbox
                  name="saveAsNew"
                  label="Save as new template"
                  onChange={(val) => {
                    if (val === true && template && template?.id) {
                      formProps.setFieldValue('name', `${template.name} copy`);
                    }
                  }}
                />

                <Box display="flex" justifyContent="flex-end" height="auto">
                  <Button
                    variant="outlined"
                    color="secondary"
                    disabled={loading}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Spacer basis={2} />
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    loading={loading || fetchingTemplates}
                    disabled={loading}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
};

export default EmailForm;
