import React from 'react';
import PropTypes from 'prop-types';
import { Popover, Typography, ButtonBase, Box } from '@mui/material';
import { uniqueId } from 'lodash';
import { Form, Spacer, Button } from 'src/components/shared';
import { capitalizeFirstLetter } from 'src/utils/helper';

import Moment from 'react-moment';
import { dateFormat } from 'src/config';

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const InlineEdit = ({
  fieldKey,
  value,
  type,
  options,
  optLabel,
  optValue,
  label,
  onSubmit,
  isBold,
  showAction = true,
  ...props
}) => {
  const uniqueKey = uniqueId('grid-bulk-actions-');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [showActions, setShowActions] = React.useState(showAction);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = async (values) => {
    handleClose();
    setLoading(true);
    await onSubmit(values);
    setLoading(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? uniqueKey : undefined;

  return (
    <>
      <ButtonBase
        onClick={handleClick}
        // onMouseEnter={() => setShowActions(true)}
        // onMouseLeave={() => setShowActions(true)}
      >
        {type === 'date' ? (
          <Typography variant="body1" color="textSecondary">
            {isBold ? (
              <>
                <Moment format={dateFormat}>{value}</Moment>
              </>
            ) : (
              <>
                <Moment format={dateFormat}>{value}</Moment>
              </>
            )}
          </Typography>
        ) : (
          <Typography variant="body1" color="textSecondary">
            {isBold ? <b>{capitalizeFirstLetter(value)}</b> : <>{capitalizeFirstLetter(value)}</>}
          </Typography>
        )}

        {showActions ? (
          <>
            <ArrowDropDownIcon sx={{ fill: '#999999', fontSize: 24, marginLeft: 0.5 }} />
          </>
        ) : null}
      </ButtonBase>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Form
          initialValues={{
            [fieldKey]: value,
          }}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({ values, ...formProps }) => {
            return (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formProps.submitForm();
                  return false;
                }}
                noValidate
              >
                <Box display="flex" px={2} py={1}>
                  {type === 'autocomplete' ? (
                    <Form.Field.AutoComplete
                      name={fieldKey}
                      variant="outlined"
                      fullWidth
                      onChange={() => {
                        formProps.submitForm();
                      }}
                      {...props}
                    />
                  ) : null}
                  {type === 'input' ? (
                    <Form.Field.Input
                      name={fieldKey}
                      variant="outlined"
                      fullWidth
                      onChange={() => {
                        formProps.submitForm();
                      }}
                      {...props}
                    />
                  ) : null}
                  {type === 'select' ? (
                    <Form.Field.Select
                      name={fieldKey}
                      options={options}
                      variant="outlined"
                      fullWidth
                      optLabel={optLabel}
                      optValue={optValue}
                      sx={{ minWidth: 150 }}
                      onChange={() => {
                        formProps.submitForm();
                      }}
                      {...props}
                    />
                  ) : null}
                  {type === 'editor' ? (
                    <Form.Field.TextEditor name={fieldKey} optValue={optValue} label={label} />
                  ) : null}
                  {type === 'date' ? (
                    <Form.Field.Datepicker
                      fullWidth={true}
                      variant="inline"
                      size="small"
                      inputVariant="standard"
                      name={fieldKey}
                      isStatic={true}
                      onChange={() => {
                        formProps.submitForm();
                      }}
                      {...props}
                    />
                  ) : null}
                </Box>
              </form>
            );
          }}
        </Form>
      </Popover>
    </>
  );
};

InlineEdit.propTypes = {
  fieldKey: PropTypes.string,
  value: PropTypes.any,
  type: PropTypes.oneOf(['input', 'select', 'date', 'time', 'editor']),
  options: PropTypes.array,
  optLabel: PropTypes.string,
  optValue: PropTypes.string,
  label: PropTypes.string,
  onSubmit: PropTypes.func,
  isBold: PropTypes.bool,
};

InlineEdit.defaultProps = {
  fieldKey: '',
  value: '',
  type: 'input',
  options: [],
  optLabel: '',
  optValue: '',
  label: '',
  onSubmit: () => {},
  isBold: true,
};

export default InlineEdit;
