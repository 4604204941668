import React from 'react';
import ReactPlayer from 'react-player';
import Modal from 'src/components/shared/Modal';

const VideoPlayer = ({ view, onClose, video, onComplete }) => {
  return (
    <Modal size="lg" padding={false} open={view} onClose={onClose}>
      {/* <ReactPlayer
        playing={view}
        controls={true}
        width="100%"
        height="100%"
        url={video}
        onEnded={onComplete}
      /> */}

      <div dangerouslySetInnerHTML={{ __html: video }} />
    </Modal>
  );
};

export default VideoPlayer;
