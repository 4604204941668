import { makeStyles } from '@mui/styles';
import { themeColors } from 'src/modules/lms/config';

const useStyles = makeStyles(({ palette }) => ({
  progressbarbackground: {
    background: '#0C1721',
    borderRadius: '6px',
    padding: '12px',
    display: 'flex',
    width: '796px',
    alignItems: 'center',
    gap: '24px',
    '& div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed,  figure, figcaption, footer, header, hgroup,  menu, nav, output, ruby, section, summary, time, mark, audio, video':
      {
        margin: '0',
        // padding: '0',
        border: '0',
        // fontSize: '100%',
        // font: 'inherit',
        fontFamily: 'inherit',
        // color: 'white',
        verticalAlign: 'baseline',
      },
    '& h5': {
      color: 'white !important',
      opacity: '100%',
      display: 'flex',
      alignItems: 'center',
      fontSize: ' 18px',
      fontWeight: '300',
      lineHeight: 'normal',

      letterSpacing: '0px',
      textAlign: 'left',
      maxWidth: '115px',
      /*  whiteSpace: 'nowrap', 
  overflow: 'hidden', 
  textOverflow: 'ellipsis',
  transition: 'all 0.3s ease-in-out', 
  opacity: '100%',
position:"relative",
maxWidth:"105px",
cursor:"pointer",
  '&:hover': {
    whiteSpace: 'normal',
    overflow: 'visible',
    textOverflow: 'unset',
  },*/
    },
    '& p': {
      color: 'white !important',
      opacity: '100%',
      display: 'flex',
      alignItems: 'center',
      fontSize: ' 16px',
      fontWeight: '300',
      lineHeight: 'normal',
      letterSpacing: '0px',

      fontFeatureSettings: `'clig' off, 'liga' off`,
    },

    '& h5 strong': {
      color: 'white',
    },
  },
  roundedcorner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '10px',
    flex: '1 0 0',
    borderRadius: '10px',
    background: '#EBEBD3',
    '& .MuiLinearProgress-bar1Determinate': {
      backgroundColor: '#1BF1B1',
      width: '100%',
      height: '150px',
    },
  },
  trophyback: {
    display: 'flex',
    padding: '6px',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '50px',
    background: '#1BF1B1',
    cursor: 'pointer',
  },
  trophydescription: {
    position: 'absolute',
    borderRadius: '6px',

    background: 'linear-gradient(106deg, #000 -4.89%, #151F2E 88.85%, #232C38 88.86%)',
    display: 'flex',
    padding: '20px',
    flexDirection: 'column',
    justifycontent: 'center',
    top: '58px',
    height: 'fit-content',
    width: '500px',
    zIndex: '1111',
    left: '-40px',

    gap: '20px',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '-12px',
      left: 'calc(11% - 7px)', // Adjust this value to center the triangle
      borderLeft: '7px solid transparent',
      borderRight: '7px solid transparent',
      borderBottom: '12px solid #4D5E70',
      zIndex: 1,
    },
  },
  trophydeschead: {
    color: 'white',
    fontSize: '15px',
    textTransform: 'capitalize',
    fontStyle: 'normal',
    fontweight: '600',
    lineHeight: 'normal',
    fontFeatureSettings: `'clig' off, 'liga' off`,
    flexShrink: '0',
  },
  trophydeschead2: {
    color: 'white',
    fontSize: '14px',
    textTransform: 'capitalize',

    fontWeight: '300',
    maxHeight: 'fit-content', // Set the maximum height you prefer
    overflowY: 'auto',
    lineHeight: 'normal',

    fontFeatureSettings: `'clig' off, 'liga' off`,
    flexShrink: '0',
  },

  progressbarbackground1: {
    background: '#0C1721',
    borderRadius: '6px',
    padding: '3px',
    cursor: 'pointer',
    display: 'flex',
    width: '270px',
    marginLeft: '9px',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    '& p': {
      color: 'white !important',
      opacity: '100%',
      display: 'flex',
      alignItems: 'center',
      fontSize: ' 16px',
      width: '212px',
      flexshrink: '0',
      fontWeight: '300',
      lineHeight: 'normal',
      letterSpacing: '0px',

      fontFeatureSettings: `'clig' off, 'liga' off`,
    },
  },
  graphhead: {
    color: '#FFF',

    fontFeatureSettings: `'clig' off, 'liga' off`,
    fontFamily: 'Inter',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
  },

  btn: {
    background: 'transparent',
    fontSize: '6px',
    textDecoration: 'none',
    textTransform: 'capitalize',
    cursor: 'pointer',
    fontSize: ' 18px',
  },
  progressicon: {
    height: '30px',
    width: '30px',
    flexShrink: '0',
    paddingLeft: '15px',
  },
}));

export default useStyles;
