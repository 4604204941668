import React from 'react';
import Tracking from './Tracking';
import EmailLimits from './EmailLimits';
import OutOffice from './OutOffice';
import BlockedDomains from './BlockedDomains';
import Holidays from './Holliday/index.js';
import CrmBccForm from './CrmBccForm';

export default function SettingsActions({ settingsName, tenantSettings, ...props }) {
  if (tenantSettings) {
    switch (settingsName) {
      case 'emailLimit':
        return (
          <EmailLimits emailLimit={tenantSettings?.emailLimit} id={tenantSettings?.id} {...props} />
        );
      case 'tracking':
        return <Tracking tenantSettings={tenantSettings} id={tenantSettings?.id} {...props} />;
      case 'outOfOffice':
        return (
          <OutOffice outOfOffice={tenantSettings?.outOfOffice} id={tenantSettings?.id} {...props} />
        );
      case 'blockedDomains':
        return (
          <BlockedDomains
            blockedDomains={tenantSettings && tenantSettings?.blockedDomains}
            id={tenantSettings?.id}
            {...props}
          />
        );
      case 'crmbcc':
        return (
          <CrmBccForm defaultBcc={tenantSettings?.defaultBcc} id={tenantSettings?.id} {...props} />
        );
      case 'holidays':
        return <Holidays holidays={tenantSettings?.holidays} id={tenantSettings?.id} {...props} />;
        // case 'emailTracking':
        //   return <>emailTracking</>;
        break;
      default:
    }
  }
}
