import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SearchResult from '../components/searchResult/SearchResult';
import images from 'src/config/images';

export const LMSSessionResultContainer = (props) => {
  const search = props.match.params.search;

  return (
    <>
      <SearchResult search={search}></SearchResult>
    </>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LMSSessionResultContainer);
