import React, { useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { unsubscribeContact } from '../../api/contactApis';
import toast from 'src/utils/toast';
import images from 'src/config/images';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';

const ContactUnsubscribe = () => {
  const params = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const contactId = params?.id || '';
  const unSubEmail = query.get('email') || '';
  const handleSubmit = async () => {
    if (!unSubEmail) return null;
    const result = await unsubscribeContact(contactId, unSubEmail);
    if (result?.message === 'unsubscribed') toast.success('Unsubscribed successfully');
  };
  useEffect(() => {
    if ((contactId, unSubEmail)) handleSubmit();
  }, []);
  return (
    <Stack
      width={'100%'}
      boxSizing={'border-box'}
      minHeight={'100vh'}
      padding={'40px'}
      alignItems={'center'}
      justifyContent={'center'}
      sx={{ background: '#090d11' }}
    >
      <Stack position={'absolute'} bottom={0} left={0}>
        <img src={images.background.unsubscribeBackground} alt="Unsubscribe Background" />
      </Stack>
      <Stack gap={'40px'} alignItems={'center'}>
        <TaskAltRoundedIcon sx={{ fill: '#FFD47E', fontSize: '120px', marginBottom: '20px' }} />
        <Typography
          width="500px"
          fontWeight={700}
          fontSize={'44px'}
          textAlign={'center'}
          color="white"
        >
          Thank you.
          <br /> You’ve successfully unsubscribed
        </Typography>
        <Typography fontWeight={400} fontSize={'18px'} color="white">
          If you change your mind, please get in touch with one of the team.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ContactUnsubscribe;
