export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const RESTORE_SESSION = 'RESTORE_SESSION';
export const SHOW_WELCOME_MODAL = 'SHOW_WELCOME_MODAL';

export const REGISTER = 'REGISTER';
export const REGISTER_SET_PASSWORD = 'REGISTER_SET_PASSWORD';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const SET_AUTH = 'SET_AUTH';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_EMAIL_PLUGIN = 'SET_EMAIL_PLUGIN';

export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const SET_USER_ID = 'SET_USER_ID';

export const SET_NL_TOKEN = 'SET_NL_TOKEN';
export const SET_EMAIL_TOKEN = 'SET_EMAIL_TOKEN';
export const DISCONNECT_EMAIL_INTEGRATION = 'DISCONNECT_EMAIL_INTEGRATION';

export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const DELETE_UPLOAD_IMAGE = 'DELETE_UPLOAD_IMAGE';
export const SET_IMAGE_UPLOAD = 'SET_IMAGE_UPLOAD';
