import React from 'react';
import { Breadcrumbs, Typography, Grid, Button, Stack, Box } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { themeColors } from '../../config';
import { Link } from 'react-router-dom';
import { Form } from 'src/components/shared';
import useStyles from './editTempStyles';
import { fetchTemplates, getTemplate } from '../../api/LMSApis';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { editTemplate } from '../../actions/LMSActions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

function EditTemplate(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const templateId = props.templateId;
  const [template, setTemplate] = React.useState({});
  const fetchSingleTemplate = async () => {
    const singleTemplate = await getTemplate(templateId);
    setTemplate(singleTemplate.template);
    return singleTemplate;
  };
  useEffect(() => {
    fetchSingleTemplate();
  }, []);

  const [fetchingTemplates, setFetchingTemplates] = React.useState(false);
  const classes = useStyles();
  const fetchTemplate = async (val, type) => {
    const newTemplates = await fetchTemplates({ _search: val });
    return newTemplates;
  };

  const currentUrl = props?.location?.pathname;
  const breadcrumbs = [
    <Link underline="hover" key="1" className={classes.links} to="/lms">
      SuperReach Learning
    </Link>,
    <Link to={'/lms'} underline="hover" key="2" className={classes.links}>
      {template.name}
    </Link>,
  ];
  const handleSubmit = async (values, status) => {
    const payload = { content: values.content, source: 'admin', status: status };
    dispatch(editTemplate(payload, templateId));
    history.push(`/lms/MyTemplates`)
  };
  return (
    <Box>
      <Grid container spacing={2} mb={5}>
        <Grid item xs={12}>
          <Box mb={2}>
            <Breadcrumbs
              separator={
                <NavigateNextIcon fontSize="small" style={{ color: themeColors.greyText }} />
              }
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Box>
          <Box mb={3}>
            <Typography fontSize={40} fontWeight={400} style={{ color: '#D2D2D2' }}>
              Edit. Create your intro
            </Typography>
          </Box>
          <Box mb={7}>
            <Typography variant="h1" mb={2} className={classes.heading}>
              {template.name}I
            </Typography>
          </Box>
          <Box>
            <>
              <Form
                initialValues={{
                  content: template?.content,
                  template: [template],
                }}
                enableReinitialize={true}
                onSubmit={() => {}}
              >
                {({ values, ...formProps }) => {
                  return (
                    <>
                      <form onSubmit={() => {}} style={{ height: '100%' }} noValidate>
                        <Box display="flex" flexDirection="row" alignItems="center" width={300}>
                          <Form.Field.AutoComplete
                            options={[]}
                            fullWidth
                            className={classes.form}
                            variant="outlined"
                            clearOnEscape={true}
                            name="template"
                            placeholder="TESTJ"
                            groupBy={(option) => option?.accessType || ''}
                            getOptionLabel={(option) => option.name || ''}
                            onChange={(val) => {
                              if (val) {
                                setTemplate(val);
                                formProps.setFieldValue('content', val.content);
                              }
                            }}
                            remoteMethod={(val) => {
                              return fetchTemplate(val);
                            }}
                            renderOption={(props, option) => (
                              <Box component="li" {...props}>
                                <Grid container spacing={0}>
                                  {option.name}
                                </Grid>
                              </Box>
                            )}
                            loading={fetchingTemplates}
                            optLabel="name"
                            optValue="id"
                          />
                        </Box>
                        <div className={classes.container}>
                          <Box className={classes.form}>
                            <Form.Field.TextEditor fullWidth variant="outlined" name="content" />
                          </Box>
                          <Box display={'flex'} justifyContent={'right'}>
                            <Button
                              className={`${classes.button} ${classes.btnAlignment}`}
                              onClick={() =>
                                handleSubmit(formProps.getFieldProps().value, 'published')
                              }
                            >
                              Save Changes
                            </Button>
                          </Box>
                        </div>
                      </form>
                    </>
                  );
                }}
              </Form>
            </>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default EditTemplate;
