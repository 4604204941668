import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Tabs, PageTitle } from 'src/components/App';
import BoardsView from './BoardsView';
import DashboardView from './DashboardView';
import { dashboardTabs } from '../../config';

import {
  fetchBoards,
  fetchWidgets,
  postBoard,
  cloneBoard,
  putBoard,
  deleteBoard,
  setActiveBoard,
  updateBoardLayout,
  addWidgetsToBoard,
  removeWidgetsFromBoard,
  useTemplateForBoard,
  setBoardFilters,
  handleBoardResize,
  clearBoardFilters,
} from '../../actions/boardActions';
import { postPanel, putPanel, deletePanel, searchPanelContacts } from '../../actions/appActions';

export const BoardsContainer = (props) => {
  const [currentTab, setCurrentTab] = useState('dashboards');

  useEffect(() => {
    if (props.match?.path === '/boards/:boardId') {
      setCurrentTab('myDashboards');
    }
  }, [props.match?.path]);

  const onTabChange = (tab) => {
    setCurrentTab(tab.id);
  };

  return (
    <>
      <PageTitle title="Boards" />

      <Tabs
        tabs={dashboardTabs}
        sx={{
          '& .MuiTabs-indicator': { display: 'none' },
          '& .MuiTab-root': { color: 'rgba(0, 0, 0, .8)' },
        }}
        onChange={onTabChange}
        value={currentTab}
      />
      {currentTab === 'dashboards' ? <DashboardView /> : <BoardsView {...props} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  loader: state.dashboard.loader,
  panelSearch: state.dashboard.panelSearch,
  boards: state.boards.boards,
  panels: state.dashboard.panels,
  boardWidgets: state.boards.boardWidgets,
  activeBoard: state.boards.activeBoard,
  loading: state.boards.loading,
  boardCategories: state.app.globals.boardCategories,
  templateCategories: state.app.globals.templateCategories,
  boardFilters: state.boards.boardFilters,
});

const mapDispatchToProps = (dispatch) => ({
  handleBoardResize: (viewport) => dispatch(handleBoardResize(viewport)),
  setBoardFilters: (filters, boardId) => dispatch(setBoardFilters(filters, boardId)),
  searchPanelContacts: (search) => dispatch(searchPanelContacts(search)),
  fetchBoards: (viewport) => dispatch(fetchBoards(viewport)),
  fetchWidgets: (filters, paging) => dispatch(fetchWidgets(filters, paging)),
  setActiveBoard: (boardId, viewport) => dispatch(setActiveBoard(boardId, viewport)),
  updateBoardLayout: (widgets, viewport) => dispatch(updateBoardLayout(widgets, viewport)),
  addWidgetsToBoard: (payload, viewport) => {
    return new Promise((resolve, reject) => {
      dispatch(addWidgetsToBoard(payload, viewport, resolve, reject));
    });
  },
  removeWidgetsFromBoard: (payload) => {
    return new Promise((resolve, reject) => {
      dispatch(removeWidgetsFromBoard(payload, resolve, reject));
    });
  },

  useTemplateForBoard: (templateId) => {
    return new Promise((resolve, reject) => {
      dispatch(useTemplateForBoard(templateId, resolve, reject));
    });
  },
  postBoard: (payload) => {
    return new Promise((resolve, reject) => {
      dispatch(postBoard(payload, resolve, reject));
    });
  },
  cloneBoard: (boardId, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(cloneBoard(boardId, payload, resolve, reject));
    });
  },
  putBoard: (boardId, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(putBoard(boardId, payload, resolve, reject));
    });
  },
  deleteBoard: (boardId) => {
    return new Promise((resolve, reject) => {
      dispatch(deleteBoard(boardId, resolve, reject));
    });
  },
  postPanel: (payload) => {
    return new Promise((resolve, reject) => {
      dispatch(postPanel(payload, resolve, reject));
    });
  },
  putPanel: (panelId, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(putPanel(panelId, payload, resolve, reject));
    });
  },
  deletePanel: (panelId) => {
    return new Promise((resolve, reject) => {
      dispatch(deletePanel(panelId, resolve, reject));
    });
  },
  clearBoardFilters: (boardId) => dispatch(clearBoardFilters(boardId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BoardsContainer);
