import { all, fork, put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import toast from 'src/utils/toast';
import * as types from '../actions/adminTypes';
import * as actions from '../actions/adminActions';
import * as appActions from '../../app/actions/appActions';
import { isEmpty } from 'lodash';
import * as adminApis from '../api/adminApi';
import { fetchTenantConfig } from './../../app/api/appApis';

const getAllUsers = (state) => state.admin.admin.users;
const getAllTeams = (state) => state.admin.admin.teams;
const getAllTemplates = (state) => state.admin.admin.templates;
const getTenant = (state) => state.app.tenant;
const getAllOffice = (state) => state.admin.admin.offices;
const getTemplateBoxType = (state) =>
  state.router?.location?.pathname === '/templates/linkedin' ? 'linkedin' : 'email';

/* Teams Watch */
function* fetchTeams({ paging, filters, sort }) {
  try {
    let filter = {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      ...filters,
    };
    if (sort && !isEmpty(sort)) {
      filter._sort = `${sort.name}:${sort.direction}`;
    }
    const teams = yield call(adminApis.fetchTeams, filter);
    // let columns = teams.columns ? JSON.parse(teams.columns) : [];
    yield put(actions.setTeams({ ...teams }));
  } catch (error) {
    yield put(actions.setTeams(error));
  }
}
function* saveTeam({ data }) {
  let teamsObj = yield select(getAllTeams);
  try {
    const teams = yield call(adminApis.saveTeamData, data);
    yield put(actions.fetchTeams(teamsObj.paging, teamsObj.filters));
    toast.success('Team  Created!', 'tc');
  } catch (error) {
    toast.error('Failed to create team!', 'tc');
  }
}

function* updateTeamStatus({ rowData }) {
  try {
    const updatedRow = yield call(adminApis.updateTeamStatus, rowData);
    yield put(actions.setTeamStatus({ updatedRow, id: rowData.id }));
    toast.success('Status Updated!', 'tc');
  } catch (error) {
    toast.error('Failed to update team status!', 'tc');
  }
}
function* updateTeam({ id, rowData }) {
  try {
    const updatedRow = yield call(adminApis.updateTeam, id, rowData);
    yield put(actions.putTeamAdmin({ updatedRow, id: rowData.id }));
    toast.success('Team Updated!', 'tc');
  } catch (error) {
    toast.error('Failed to update team!', 'tc');
  }
}

/* Users Watch */

function* fetchUserTabs() {
  try {
    const data = yield call(adminApis.getUserTabs);
    yield put(actions.setUserTabs(data));
  } catch (error) {
    yield put(actions.setUserTabs(error));
  }
}

function* fetchUsers({ paging, filters, sort }) {
  try {
    let filter = {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      ...filters,
    };
    if (sort && !isEmpty(sort)) {
      filter._sort = `${sort.name}:${sort.direction}`;
    }
    const users = yield call(adminApis.fetchUsers, filter);
    let columns = users.columns ? JSON.parse(users.columns) : [];
    yield put(
      actions.setUsers({
        ...users,
        // ...columns,
      }),
    );
  } catch (error) {
    yield put(actions.setUsers(error));
  }
}

function* fetchTenantConfigData(data) {
  try {
    const response = yield call(fetchTenantConfig, data);
    if (response) yield put(appActions.setTenantConfig(response.tenant));
  } catch (error) {}
}

function* saveUser({ data, resolve, reject }) {
  let usersObj = yield select(getAllUsers);
  let tenant = yield select(getTenant);
  try {
    const users = yield call(adminApis.saveUserData, data);
    yield put(actions.fetchUsers(usersObj.paging, usersObj.filters));
    if (users.error?.message) return toast.error(users.error?.message, 'tc');
    yield call(fetchTenantConfigData, tenant.id);
    toast.success('User Created!', 'tc');
    resolve(users);
  } catch (error) {
    yield put(actions.fetchUsers(usersObj.paging, usersObj.filters));
    toast.error(error?.error?.message, 'tc');
    return reject(error);
  }
}

function* updateUser({ rowData }) {
  try {
    let tenant = yield select(getTenant);
    const updatedRow = yield call(adminApis.updateUserData, rowData);
    yield call(fetchTenantConfigData, tenant?.id);
    yield put(actions.putUserAdmin({ updatedRow, id: rowData.id }));
    toast.success('User updated!', 'tc');
  } catch (error) {
    toast.error(error?.error?.message || 'Something went wrong! Please try again.');
  }
}

function* updateUserStatus({ rowData }) {
  try {
    const updatedRow = yield call(adminApis.updateUserStatus, rowData);
    yield put(actions.setUserStatus({ updatedRow, id: rowData.id }));
    toast.success('Status Updated!', 'tc');
  } catch (error) {
    toast.error('Failed to update status!', 'tc');
  }
}

function* userBulkUpdate({ users, payload, resolve, reject }) {
  try {
    const data = users.map((user) => ({
      id: user.id,
      ...payload,
    }));
    yield call(adminApis.userBulkUpdate, data);
    let usersObj = yield select(getAllUsers);
    yield put(actions.fetchUsers(usersObj.paging, usersObj.filters));
    toast.success('Updated successfully!', 'tc');
    resolve(true);
  } catch (error) {
    reject(false);
  }
}
function* teamBulkUpdate({ teams, payload, resolve, reject }) {
  try {
    const data = teams.map((team) => ({
      id: team.id,
      ...payload,
    }));
    yield call(adminApis.teamBulkUpdate, data);
    let usersObj = yield select(getAllTeams);
    yield put(actions.fetchTeams(usersObj.paging, usersObj.filters));
    toast.success('Updated successfully!', 'tc');
    resolve(true);
  } catch (error) {
    reject(false);
  }
}
function* templateBulkUpdate({ template, payload, loadType, resolve, reject }) {
  try {
    const data = template.map((temp) => ({
      id: temp.id,
      ...payload,
    }));
    yield call(adminApis.templateBulkUpdate, data, loadType);
    let templateObj = yield select(getAllTemplates);
    yield put(
      actions.fetchTemplates(templateObj.paging, templateObj.filters, { queryType: loadType }),
    );
    toast.success('Updated successfully!', 'tc');
    resolve(true);
  } catch (error) {
    reject(false);
  }
}
function* officeBulkUpdate({ offices, payload, resolve, reject }) {
  try {
    const data = offices.map((office) => ({
      id: office.id,
      ...payload,
    }));
    yield call(adminApis.officeBulkUpdate, data);
    let officeObj = yield select(getAllOffice);
    yield put(actions.fetchOffices(officeObj.paging, officeObj.filters));
    toast.success('Updated successfully!', 'tc');
    resolve(true);
  } catch (error) {
    reject(false);
  }
}

function* userBulkDelete({ action, users, resolve, reject }) {
  try {
    const userIds = users.map((item) => item.id);

    yield call(adminApis.userBulkDelete, { users: userIds });
    let usersObj = yield select(getAllUsers);
    yield put(actions.fetchUsers(usersObj.paging, usersObj.filters));
    toast.success('User(s) deleted successfully!');
    resolve(true);
  } catch (error) {
    reject(error);
  }
}

/* Offices Watch */

function* fetchOffice({ paging, filters, sort }) {
  try {
    let filter = {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      ...filters,
    };
    if (sort && !isEmpty(sort)) {
      filter._sort = `${sort.name}:${sort.direction}`;
    }
    const offices = yield call(adminApis.fetchOffices, filter);
    yield put(actions.setOffices({ ...offices }));
  } catch (error) {
    yield put(actions.setOffices(error));
  }
}

function* saveOffice({ data }) {
  try {
    const offices = yield call(adminApis.saveOfficeData, data);
    let officeObj = yield select(getAllOffice);
    yield put(actions.fetchOffices(officeObj.paging, officeObj.filters));
    toast.success('Office Created!', 'tc');
  } catch (error) {
    toast.error('Failed to create office!', 'tc');
  }
}

function* updateOffice({ id, rowData }) {
  try {
    const updatedRow = yield call(adminApis.updateOfficesData, id, rowData);
    yield put(actions.putOfficeAdmin({ updatedRow, id: id }));
    toast.success('Office Updated!', 'tc');
  } catch (error) {
    toast.error('Failed to update office!', 'tc');
  }
}

function* updateOfficeStatus({ rowData }) {
  try {
    const updatedRow = yield call(adminApis.updateOfficeStatus, rowData);
    yield put(actions.setOfficeStatus({ updatedRow }));
    toast.success('Status Updated!', 'tc');
  } catch (error) {
    toast.error('Failed to update status!', 'tc');
  }
}

/* Templates Watch */

function* fetchTemplate({ paging, filters, queryType, sort = {} }) {
  try {
    let filter = {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      ...(filters?.assignedTo && filters.assignedTo?.id
        ? { owner_eq: filters.assignedTo?.id }
        : {}),
      ...(filters?.category && filters.category?.id ? { category: filters.category?.id } : {}),
      ...(filters?.type && filters.type?.id ? { type: filters.type?.id } : {}),
      ...(filters?._search && { name: filters._search }),
      ...(queryType.queryType === 'email' && { type_eq: queryType.queryType }),
      ...(queryType.queryType === 'linkedin' && {
        type_neq: 'email,personalisedEmail,personalisedMessage',
      }),
      ...filters,
    };
    if (sort && !isEmpty(sort)) {
      filter._sort = `${sort.name}:${sort.direction}`;
    }
    if (filter.category) {
      filter.category = filter.category.id;
    }
    if (filter.type) {
      filter.type = filter.type.id;
    }
    delete filter?.assignedTo;
    delete filter?._search;
    const templates = yield call(adminApis.fetchTemplate, filter);
    yield put(actions.setTemplates({ ...templates }));
  } catch (error) {
    yield put(actions.setTemplates(error));
  }
}

function* saveTemplate({ data, resolve = () => {}, reject = () => {} }) {
  try {
    let templateObj = yield select(getAllTemplates);
    let templateBoxType = yield select(getTemplateBoxType);
    const res = yield call(adminApis.saveTemplate, data);
    yield put(
      actions.fetchTemplates(
        { ...templateObj.paging, pageNo: 0 },
        templateObj.filters,
        {
          queryType: templateBoxType,
        },
        templateObj.sort,
      ),
    );
    toast.success('Template Created!', 'tc');
    resolve(res);
  } catch (error) {
    toast.error('Failed to create template!', 'tc');
    reject(error);
  }
}

function* updateTemplate({ id, rowData }) {
  try {
    const updatedRow = yield call(adminApis.updateTemplate, id, rowData);
    yield put(actions.setUpdateTemplate({ updatedRow, id: id }));
    toast.success('Template Updated!', 'tc');
  } catch (error) {
    toast.error('Failed to update templates!', 'tc');
  }
}

function* updateTemplateStatus({ rowData }) {
  try {
    const updatedRow = yield call(adminApis.updateTemplatesStatus, rowData);
    yield put(actions.setTemplateStatus({ updatedRow, id: rowData.id }));
    toast.success('Status Updated!', 'tc');
  } catch (error) {
    toast.error('Failed to update status!', 'tc');
  }
}

/* Integration Watch */

function* fetchIntegration() {
  try {
    const integrations = yield call(adminApis.fetchIntegrations);
    yield put(actions.setIntegrations(integrations));
  } catch (error) {
    yield put(actions.setIntegrations(error));
  }
}

function* saveIntegration({ data }) {
  try {
    const integrations = yield call(adminApis.saveIntegrations, data);
    // yield put(actions.changeIntegration({ ...integrations }));
    const redirectUrl =
      integrations?.tenantIntegration?.redirectURL ||
      integrations?.tenantIntegration?.[0]?.redirectURL;
    redirectUrl && window.open(redirectUrl, '_blank');
    yield put(actions.fetchIntegrations());
    toast.success('Integration Connected!', 'tc');
  } catch (error) {
    yield put(actions.changeIntegration(error));
  }
}

function* saveRescheduleEmail({ data }) {
  try {
    const result = yield call(adminApis.saveRescheduleEmail, data);
  } catch (error) {}
}
function* disconnectIntegration({ id, status }) {
  try {
    const integrations = yield call(adminApis.disconnectIntegration, id, status);
    yield put(actions.changeIntegration({ ...integrations }));
    toast.success('Integration Disconnected!', 'tc');
  } catch (error) {
    yield put(actions.changeIntegration(error));
  }
}

/* Tenant Settings Watch */

function* fetchTenantSettings() {
  try {
    const settings = yield call(adminApis.fetchTenantSettings);
    yield put(actions.setTenantSettings(settings));
  } catch (error) {
    yield put(actions.setTenantSettings(error));
  }
}

function* updateTenantSettings({ data, id }) {
  try {
    const settings = yield call(adminApis.updateTenantSettings, data, id);
    yield put(actions.putTenantSettings(settings?.tenantSettings));
    toast.success('Updated successfully!', 'tc');
  } catch (error) {
    yield put(actions.putTenantSettings(error));
  }
}

export function* watchSagasTemplates() {
  yield takeLatest(types.FETCH_ADMIN_TEMPLATES, fetchTemplate);
  yield takeLatest(types.UPDATE_TEMPLATE_STATUS, updateTemplateStatus);
  yield takeLatest(types.SAVE_ADMIN_TEMPLATE, saveTemplate);
  yield takeLatest(types.PUT_ADMIN_TEMPLATE, updateTemplate);
  yield takeLatest(types.TEMPLATE_BULK_UPDATE, templateBulkUpdate);
}

export function* watchSagasOffices() {
  yield takeLatest(types.FETCH_ADMIN_OFFICES, fetchOffice);
  yield takeLatest(types.SAVE_ADMIN_OFFICE, saveOffice);
  yield takeLatest(types.UPDATE_OFFICE_STATUS, updateOfficeStatus);
  yield takeLatest(types.UPDATE_ADMIN_OFFICE, updateOffice);
  yield takeLatest(types.OFFICE_BULK_UPDATE, officeBulkUpdate);
}

export function* watchSagasUsers() {
  yield takeLatest(types.FETCH_ADMIN_USERS_TABS, fetchUserTabs);
  yield takeLatest(types.FETCH_ADMIN_USERS, fetchUsers);
  yield takeLatest(types.SAVE_ADMIN_USER, saveUser);
  yield takeLatest(types.UPDATE_ADMIN_USER_STATUS, updateUserStatus);
  yield takeLatest(types.UPDATE_ADMIN_USER, updateUser);
  yield takeLatest(types.USER_BULK_UPDATE, userBulkUpdate);
  yield takeLatest(types.USER_BULK_DELETE, userBulkDelete);
}

export function* watchSagasTeams() {
  yield takeLatest(types.FETCH_ADMIN_TEAMS, fetchTeams);
  yield takeLatest(types.SAVE_ADMIN_TEAMS, saveTeam);
  yield takeLatest(types.TEAM_BULK_UPDATE, teamBulkUpdate);
  yield takeLatest(types.UPDATE_TEAM_STATUS, updateTeamStatus);
  yield takeLatest(types.UPDATE_ADMIN_TEAM, updateTeam);
}

export function* watchSagasIntegration() {
  yield takeLatest(types.FETCH_ADMIN_INTEGRATIONS, fetchIntegration);
  yield takeLatest(types.SAVE_ADMIN_RESCHEDULE_EMAIL, saveRescheduleEmail);
  yield takeLatest(types.SAVE_ADMIN_INTEGRATIONS, saveIntegration);
  yield takeLatest(types.DISCONNECT_ADMIN_INTEGRATION, disconnectIntegration);
}
export function* watchSagasSettings() {
  yield takeLatest(types.FETCH_SETTING_ADMIN, fetchTenantSettings);
  yield takeLatest(types.UPDATE_SETTING_ADMIN, updateTenantSettings);
}

export default function* runSagas() {
  yield all([
    fork(watchSagasUsers),
    fork(watchSagasTeams),
    watchSagasOffices(watchSagasOffices),
    watchSagasTemplates(),
    fork(watchSagasIntegration),
    fork(watchSagasSettings),
  ]);
}
