import React, { useState, useMemo } from 'react';
import { AppGrid, ConfirmDialog } from 'src/components/App';
import { Modal, Spacer, Button } from 'src/components/shared';
import { Box, Typography } from '@mui/material';
import { useNoteColumns } from './useNoteColumns';
import CloneSequenceForm from '../../../sequence/components/Sequence/CreateSequence/CloneSequenceForm';
import { fetchContactsLookup } from 'src/modules/contacts/api/contactApis';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';
import SequenceStatusForm from '../../../sequence/components/Sequence/SequenceStatusForm';
import WarningIcon from '@mui/icons-material/Warning';
import { sequenceBulkActions } from '../../../sequence/config';
import AddIcon from '@mui/icons-material/Add';

import { updateSequence } from '../../../sequence/api/sequenceApi';

import { isEmpty } from 'lodash';
import CreateNote from '../Note/CreateNote';
import { updateSession } from '../../api/noteApi';

const NotesGrid = ({ fields, sessions, loading, ...props }) => {
  const [rowsSelected, setRowsSelected] = useState([]);
  // const [showCloneForm, setShowCloneForm] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [note, setNote] = useState('');
  // const [bulkAction, setBulkAction] = useState('');
  // const [isDeleting, setIsDeleting] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const { data, paging, filters, sort } = sessions;
  const handleSessionStatusChange = async (sequenceId, published) => {
    try {
      await updateSession(sequenceId, { published });
    } catch (error) {}
  };

  const handleNoteEdit = (note) => {
    setModalOpen(true);
    setNote(note);
  };
  const handleNoteUpdate = () => {
    props.fetchSessions(paging, filters, sort);
  };

  const sessionColumns = useNoteColumns(
    data,
    fields,
    props.onEdit,
    props.onDelete,
    props.toggleContactDrawer,
    handleNoteEdit,
    handleSessionStatusChange,
  );
  const sessionFilters = [
    {
      key: 'addedby',
      title: 'Added by',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: true,
      searchable: true,
      isPrimary: true,
      remoteMethod: async (value) => {
        return fetchUsersLookup(value);
      },
    },
    {
      key: 'status',
      title: 'Status',
      type: 'dropdown',
      remote: false,
      optLabel: 'name',
      optValue: 'value',
      cancellable: true,
      searchable: true,
      isPrimary: true,
      options: [
        { name: 'Active', value: true },
        { name: 'Deactive', value: false },
      ],
    },
  ];

  const handleTableChange = ({ pageNo, perPage }) => {
    props.fetchSessions(
      {
        pageNo,
        perPage,
      },
      filters,
    );
  };

  const onFilterChange = (key, value) => {
    if (value && typeof value !== 'undefined') {
      if (key === '_search') {
        props.fetchSessions(
          {
            ...paging,
            pageNo: 0,
          },
          { ...filters, [key]: value },
        );
      } else {
        props.fetchSessions(paging, { ...filters, [key]: value });
      }
    } else {
      delete filters[key];
      props.fetchSessions(paging, filters);
    }
  };

  const onClearFilters = () => {
    Object.keys(filters).forEach(function (filter) {
      if (filter != '_search') delete filters[filter];
    });
    props.fetchSessions(paging, filters);
  };

  const onSort = (sort) => {
    props.fetchSessions(paging, filters, sort);
  };

  return (
    <>
      <AppGrid
        columns={sessionColumns.map((col, index) => ({
          ...col,
          options: sessionColumns?.columns?.length
            ? { ...col.options, ...sessionColumns?.columns[index] }
            : col.options,
        }))}
        data={data}
        onTableChange={handleTableChange}
        onFilterChange={onFilterChange}
        onClearFilters={onClearFilters}
        onColumnChange={() => handleColumnChange(false)}
        onSort={onSort}
        loading={loading}
        filters={sessionFilters}
        appliedFilters={filters}
        bulkActions={sequenceBulkActions}
        options={{
          sortOrder: {
            name: sort?.name || 'none',
            direction: sort?.direction || 'none',
          },
          serverSide: true,
          pagination: true,
          page: paging?.pageNo,
          rowsPerPage: paging?.perPage,
          count: paging?.count,
          searchPlaceholder: 'Search ',
          search: true,
          searchText: filters?._search ? filters._search : '',
          rowsSelected: rowsSelected,
          onRowsSelect: (rowsSelected, allRows) => {
            setRowsSelected(allRows.map((row) => row.dataIndex));
          },
          renderCustomFilterActions: () => {
            return (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setNote('');
                  setModalOpen(true);
                }}
              >
                Add a note
              </Button>
            );
          },
        }}
      />
      <CreateNote
        note={note}
        open={modalOpen}
        handleNoteUpdate={handleNoteUpdate}
        handleClose={() => setModalOpen(false)}
        handleOpen={() => setModalOpen(true)}
        {...props}
      />
    </>
  );
};

export default NotesGrid;
