import { isEmpty } from 'lodash';
import api from 'src/api';
import { convertObjectToQuerystring } from 'src/utils/helper';
export const getCompetitionList = async (filter) => {
  const response = await api(`/lms/competition${filter}`, null, 'get');
  return response;
};
export const getCompetitions = async () => {
  const response = await api(`/lms/competition`, null, 'get');
  return response;
};
export const editCompetition = (id, payload) => {
  return api(`/lms/competition/${id}`, payload, 'put');
};
export const competitionInvite = (id) => {
  return api(`/lms/competition/invitation/${id}`, 'get');
};
export const createCompetition = (payload) => {
  return api(`/lms/competition`, payload, 'post');
};

export const deleteCompetition = (id) => {
  return api(`/lms/competition/${id}`, null, 'delete');
};
export const competitionSettings = (id, payload) => {
  return api(`/tenantsetting/${id}`, payload, 'put');
};
export const templates = (id) => {
  return api(`/template/${id}`, null, 'get');
};
export const updateTemplate = (id, payload) => {
  return api(`/template/${id}`, payload, 'put');
};
export const getInviteTemplate = (templateId, competitionId, userId) => {
  return api(`lms/competition/populate/${templateId}/${competitionId}/${userId}`, null, 'get');
};

export const fetchSessionsLookup = async (filters) => {
  let filter = '';
  filters = {
    ...filters,
    _from: 0,
    _size: 20,
  };
  if (!isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  const res = await api(`/lms/session${filter}`, null, 'get');
  console.log('res : ', res);
  return res.sessions.map((item) => ({ id: item.id, title: item.title }));
};
