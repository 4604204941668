import styled from 'styled-components';
import { ButtonBase } from '@mui/material';

export const FileWrapper = styled(ButtonBase)`
  width: 200px;
  border-radius: 4px;
  border: 1px solid var(--light-text-primary-shades-4-p, rgba(0, 0, 0, 0.04));
  background: #fafafa;
  padding: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
`;

export const FilesGrid = styled.div`
  display: grid;
  /* grid-template-columns: 200px 200px 200px 200px; */
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 10px;
  padding: 10px;
`;
