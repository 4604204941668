import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  autocomplete: {
    '& .MuiChip-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& .MuiChip-deleteIcon': {
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
}));
export default useStyles;
