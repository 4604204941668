import React from 'react';
import { Box, Typography } from '@mui/material';
import { Button, Spacer, Drawer } from 'src/components/shared';
import NotesGrid from '../../components/Notes/NotesGrid';
import CreateNote from '../../components/Note/CreateNote';
function NotesView({ fields, sequenceTab, sessions, ...props }) {
  const [modalOpen, setModalOpen] = React.useState(false);

  const fetchSessions = (paging, filter, sort = undefined) => {
    props.fetchSessions(paging, filter, sequenceTab, sort);
  };

  const onEdit = (session) => {};

  const onDelete = (session) => {};

  return (
    <>
      <Spacer y={3} />
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography variant="body2">
          Provide guidance, instructions, hints and tips to your team against a learning session.
        </Typography>
      </Box>
      <Spacer y={0.5} />

      <NotesGrid
        fields={fields}
        sessions={sessions}
        onEdit={onEdit}
        onDelete={onDelete}
        {...props}
        fetchSessions={fetchSessions}
      />
    </>
  );
}

export default NotesView;
