import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  LinearProgress,
  Box,
  Icon,
  Button,
  ButtonBase,
  Stack,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useStyles, SRArrow, ExternalLinkWrapper } from './styles';
import images from 'src/config/images';

const OnboardingAccordion = ({ children }) => {
  const outer = React.Children.map(children, (child) =>
    child.type.displayName === 'Outer' ? child : null,
  );

  return <div>{outer}</div>;
};

const Outer = ({ title, isCompleted, icon, progress, ...props }) => {
  const classes = useStyles();

  return (
    <Accordion className={classes.outerAccordionContainer}>
      <AccordionSummary expandIcon={<SRArrow />} className={classes.outerAccordionSummary}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="flex" alignItems="center" flex={1}>
            {icon && (
              <img
                src={images.onboarding[icon]}
                style={{ width: 30 }}
                className={classes.outerAccordionCompletedCheck}
              />
            )}
            <Box ml={3}>
              <Typography variant="h2" className="title">
                {title}
              </Typography>
            </Box>
          </Box>
          <Box flex={2.5} mr={3}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="body1" className={classes.outerAccordionInfoText}>
                {props.steps} steps | About {props.time}
              </Typography>
              {isCompleted ? (
                <Typography
                  variant="body1"
                  className={`${classes.outerAccordionInfoTextComplete} ${classes.outerAccordionInfoText}`}
                >
                  <CheckCircleIcon /> All steps completed
                </Typography>
              ) : (
                <></>
              )}
            </Stack>
          </Box>
        </Box>
        <LinearProgress
          variant="determinate"
          value={progress}
          className={classes.accordionProgress}
        />
      </AccordionSummary>
      <AccordionDetails className={classes.outerAccordionDetails}>
        <Box padding={1}>{props.children}</Box>
      </AccordionDetails>
    </Accordion>
  );
};
Outer.displayName = 'Outer';
OnboardingAccordion.Outer = Outer;

const Inner = ({ title, isCompleted, index, onClick, ...props }) => {
  const classes = useStyles();
  const { icon } = props.accordionInner;

  return (
    <Accordion
      square={true}
      className={`${classes.innerAccordionContainer} ${isCompleted ? classes.completedStep : ''}`}
      expanded={props.expand}
      elevation={0}
      onChange={() => {}}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.innerAccordionSummary}>
        <Icon className="icon" color="secondary" style={isCompleted ? { color: '#26CF9F' } : {}}>
          {icon}
        </Icon>
        <Box m={'0 16px'} flex={1}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography className="title" variant="body1">
              {title}
            </Typography>
            {isCompleted ? (
              <Typography
                variant="body1"
                className={`${classes.outerAccordionInfoTextComplete} ${classes.outerAccordionInfoText}`}
              >
                <CheckCircleIcon /> completed
              </Typography>
            ) : (
              <></>
            )}
          </Stack>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.innerAccordionDetails}>
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
};
Inner.displayName = 'Inner';
OnboardingAccordion.Inner = Inner;

const ExternalLink = ({
  title,
  isCompleted,
  index,
  onClick,
  accordionInner,
  markStepComplete,
  ...props
}) => {
  const { icon, action_source } = accordionInner;

  const handleClick = () => {
    window.open(action_source, '_blank');
    markStepComplete(accordionInner);
  };

  return (
    <ExternalLinkWrapper>
      <Icon color={'secondary'} style={isCompleted ? { color: '#26CF9F' } : {}}>
        {icon}
      </Icon>

      <Box flex={1} ml={2}>
        <ButtonBase onClick={handleClick}>
          <Typography>{title}</Typography>
        </ButtonBase>
      </Box>
      <Button
        onClick={handleClick}
        variant="contained"
        style={
          isCompleted
            ? { background: '#26CF9F', textTransform: 'initial' }
            : { textTransform: 'initial' }
        }
        color={'secondary'}
      >
        {isCompleted ? 'Learning Complete' : 'Mark as complete'}
      </Button>
    </ExternalLinkWrapper>
  );
};
Inner.displayName = 'ExternalLink';
OnboardingAccordion.ExternalLink = ExternalLink;

export default OnboardingAccordion;
