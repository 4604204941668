import React, { useMemo } from 'react';
import { Box, Typography, Card, CardContent, CircularProgress, Stack } from '@mui/material';
import { ResponsiveContainer, RadialBarChart, RadialBar } from 'recharts';

import { InfoColorCard } from './styles';

const InfoCard = ({ title, color }) => {
  return (
    <Stack gap={1} direction="row" alignItems="center">
      <InfoColorCard color={color} />
      <Typography variant="body2" color="textSecondary">
        {title}
      </Typography>
    </Stack>
  );
};

function ProgressChart({ title, total, value }) {
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '200px',
  };
  const textStyles = {
    fontSize: '28px',
  };

  const CustomizedLabel = ({ percentage }) => {
    return (
      <g>
        <foreignObject x={0} y={0} width={200} height={200}>
          <div style={style}>
            <Typography sx={{ ...textStyles, color: '#1976D2' }}>{percentage}%</Typography>
          </div>
        </foreignObject>
      </g>
    );
  };

  const percentage = useMemo(() => {
    if (value > 0 && total > 0) {
      return Math.ceil((100 * value) / total);
    }
    return 0;
  }, [value, total]);

  return (
    <Card>
      <CardContent>
        <Typography variant="h3">{title}</Typography>

        <Box display="flex" width="100%" height="100%">
          <Box display="flex" flex={1} alignItems="center" justifyContent="center" pt={2}>
            <ResponsiveContainer width={200} height={200}>
              <RadialBarChart
                cx="50%"
                cy="50%"
                innerRadius={70}
                outerRadius={100}
                barSize={25}
                startAngle={90}
                endAngle={450}
                data={[
                  {
                    name: 'Demo',
                    value: total,
                    fill: '#ffffff',
                  },
                  {
                    name: 'Demo 1',
                    value: value,
                    fill: '#1976D2',
                    background: '#000000',
                  },
                ]}
              >
                <RadialBar
                  minAngle={200}
                  label={<CustomizedLabel percentage={percentage} />}
                  background={{ fill: '#1976D230' }}
                  fill="#1976D230"
                  clockWise
                  dataKey="value"
                  isAnimationActive={false}
                />
              </RadialBarChart>
            </ResponsiveContainer>
          </Box>
          <Stack flex={0.8} direction="column" gap={1} justifyContent="space-around">
            <Typography component="span">
              <Typography variant="body1" component="span" color="textPrimary">
                {value}
              </Typography>
              <Typography variant="body2" component="span" color="textSecondary">
                /{total} credits
              </Typography>
            </Typography>
            <Stack direction="column" gap={1}>
              <InfoCard title="Used" color="#1976D2" />
              <InfoCard title="Available" color="#1976D230" />
            </Stack>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
}

export default ProgressChart;
