import React, { useMemo, Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Stack, Button, Alert, Backdrop, Card, Box } from '@mui/material';
import { Drawer, Menu, Spacer } from 'src/components/shared';
import SendIcon from '@mui/icons-material/Send';
import Profile from 'src/modules/contacts/components/Contact/Profile';
import * as enrolmentActions from '../../actions/enrolmentActions';
import EnrolmentPersonalizeForm from './EnrolmentPersonalizeForm';
import EnrolmentErrors from './EnrolmentErrors';
import Stepper from './Stepper';
import AppLoader from 'src/components/App/Loader';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { useHistory } from 'react-router-dom';
import { fetchEnrolmentDrafts } from 'src/modules/tasks/actions/taskActions';
import { getIntegrations } from 'src/modules/app/actions/appSelector';
import {
  BullhornIconActive,
  JobadderIconActive,
  VincereIconActive,
  SalesforceIconActive,
  HubspotIconActive,
} from 'src/modules/app/components/IntegrationIcons';

function EnrolmentPersonalize() {
  const dispatch = useDispatch();
  const [abortSequence, setAbortSequence] = useState(false);
  const [nextPrevContactLoading, setNextPrevContactLoading] = useState(true);
  const enrolments = useSelector((state) => state.enrolment.enrolments);
  const [enroledContacts, setEnroledContacts] = useState({});
  const [enroledErrorContacts, setEnroledErrorContacts] = useState({});
  const [isEnrolAll, setIsEnrolAll] = useState(-1);
  const enrollmentSuccess = useSelector((state) => state.enrolment.enrollmentSuccess);
  const activeEnrolmentId = useSelector((state) => state.enrolment.activeEnrolment);
  const contact = useSelector((state) => state.contacts.contact);
  const fetchContact = useSelector((state) => state.contacts.loading.fetchContact);
  const errors = useSelector((state) => state.enrolment.errors);
  const fetchContactError = useSelector((state) => state.enrolment.loading.fetchContactError);
  const loading = useSelector((state) => state.enrolment.loading);
  const contactLoading = useSelector((state) => state.contacts.loading.contact);
  const steps = useSelector((state) => state.enrolment.steps);
  const action = useSelector((state) => state.enrolment.action);
  const enrolmentDrafts = useSelector((state) => state.tasks.enrolmentDrafts);
  const integrations = useSelector(getIntegrations);

  const isOpen = useMemo(() => {
    return enrolments && Object.keys(enrolments).length > 0;
  }, [enrolments]);

  const activeEnrolment = useMemo(() => {
    return enrolments[activeEnrolmentId] || {};
  }, [enrolments, activeEnrolmentId]);

  const sequence = useMemo(() => {
    if (activeEnrolment && activeEnrolment?.seq) return activeEnrolment?.seq;
    else return {};
  }, [activeEnrolment]);

  const onClose = (abort) => {
    if (!enrollmentSuccess && abort) return setAbortSequence(abort);
    const { filters, paging } = enrolmentDrafts;
    setEnroledContacts({});
    setEnroledErrorContacts({});
    dispatch(fetchEnrolmentDrafts(paging, filters));
    dispatch(enrolmentActions.clearPersonalizeSequence());
    setAbortSequence(false);
    setIsEnrolAll(-1);
  };

  const setActiveEnrolment = (enrolmentId) => {
    dispatch(enrolmentActions.setActiveSeqPersonalize(enrolmentId));
  };

  const handleSubmit = async (data = {}, resume = true) => {
    setEnroledContacts((enroledContacts) => {
      enroledContacts[activeEnrolmentId] = true;
      return enroledContacts;
    });
    setNextPrevContactLoading(false);
    if (!activeEnrolment?.alreadyCreated) {
      const res = await new Promise((resolve, reject) => {
        dispatch(enrolmentActions.submitPersonalizeSequence(data, resume, resolve, reject));
      });
      if (res?.enrolments?.[0]?.status === 'error')
        setEnroledErrorContacts({
          ...enroledErrorContacts,
          [activeEnrolmentId]: true,
        });
    } else handleEnrollmentChange(1);
  };

  const onContactChange = () => {};

  const enrolAll = () => {
    const currentIndex = Object.keys(enrolments)?.indexOf(activeEnrolmentId);
    setIsEnrolAll(currentIndex);
    const dataToUpdate = Object.keys(enrolments)?.reduce((acc, enrolmentId, index) => {
      if (currentIndex <= index) acc.push({ id: enrolmentId, status: 'inProgress' });
      return acc;
    }, []);
    dispatch(enrolmentActions.enrolAllContacts(dataToUpdate));
  };

  const handleEnrollmentChange = (val) => {
    setActiveEnrolment(
      Object.keys(enrolments)?.[Object.keys(enrolments).indexOf(activeEnrolmentId) + val],
    );
  };

  const handleBackdropClick = (e) =>
    e?.target?.classList?.contains('backdrop-content') && onClose();

  const loadingScreen = contactLoading || loading?.steps || fetchContact || fetchContactError;

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      size="medium"
      headerGap={false}
      title={
        !enrollmentSuccess &&
        !loadingScreen && (
          <Stack
            width="100%"
            padding="6px 0"
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            {Object.keys(enrolments)?.length > 1 ? (
              <React.Fragment>
                <Typography color="#000000DE" fontSize={16} fontWeight={500}>
                  Enrol:{' '}
                  {activeEnrolmentId ? Object.keys(enrolments)?.indexOf(activeEnrolmentId) + 1 : 1}/
                  {Object.keys(enrolments)?.length} Contacts
                </Typography>
                <Menu
                  iconButton={false}
                  endIcon={false}
                  title={<SendRoundedIcon color="#00000061" />}
                  color="secondary"
                  buttonStyles={{
                    padding: 0,
                    color: 'rgba(0, 0, 0, 0.83)',
                    textTransform: 'none',
                    fontWeight: 'normal',
                    minWidth: 'unset',
                  }}
                  options={[{ label: 'Enrol all prospects immediately', onClick: enrolAll }]}
                />
              </React.Fragment>
            ) : (
              <Typography color="#000000DE" fontSize={16} fontWeight={500}>
                {sequence?.name}
              </Typography>
            )}
          </Stack>
        )
      }
      disabledPadding={true}
    >
      {!enrollmentSuccess ? (
        <Fragment>
          <Stack px={2} gap={2}>
            <Stepper
              showCount={false}
              label={sequence?.name}
              nextLabel="Skip"
              showNextAtLast={true}
              nextIcon={<SkipNextIcon />}
              prevLabel="Back"
              prevIcon={<ArrowBackIosRoundedIcon />}
              steps={Object.keys(enrolments)}
              activeEnrolmentId={activeEnrolmentId}
              setActiveEnrolment={setActiveEnrolment}
              onNext={() => setNextPrevContactLoading(true)}
              onPrev={() => setNextPrevContactLoading(true)}
              show={!loadingScreen}
            />
            {loadingScreen ? (
              <LoadingScreen
                simpleLoading={!fetchContact}
                nextPrevContactLoading={nextPrevContactLoading}
                integrations={integrations}
              />
            ) : (
              <Fragment>
                <Profile
                  type="enrollment"
                  contact={contact}
                  showEdit={false}
                  onEnrol={handleSubmit}
                />
                <EnrolmentErrors
                  errors={errors?.[contact?.id] || []}
                  isAlreadyEnrolled={activeEnrolment?.alreadyCreated}
                />
              </Fragment>
            )}
          </Stack>
          {!loadingScreen && (
            <Stack gap={2}>
              <EnrolmentPersonalizeForm
                contact={contact}
                sequence={sequence}
                steps={steps}
                personaliseEnrollment={false}
                enrolments={enrolments?.[activeEnrolmentId]}
                isAlreadyEnrolled={activeEnrolment?.alreadyCreated}
                onContactChange={onContactChange}
                handleSubmit={handleSubmit}
                action={action}
              />
            </Stack>
          )}

          {abortSequence && (
            <Backdrop
              sx={{
                position: 'absolute',
                backdropFilter: 'blur(2px)',
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={true}
              onClick={handleBackdropClick}
            >
              <Card
                className="backdrop-content"
                sx={{
                  width: 'calc(85% - 80px)',
                  padding: '20px',
                  backboxcdropFilter: 'blur(3px)',
                  position: 'relative',
                }}
              >
                <Stack gap={2}>
                  <Typography fontSize={18} fontWeight={600}>
                    Are you sure you want to abort the{' '}
                    {Object.keys(enrolments)?.length > 1 ? 'multiple' : ''} enrolment process?
                  </Typography>
                  <Typography variant="body1">
                    {Object.keys(enrolments)?.length > 1 ? 'All' : 'This'} prospects will be saved
                    in draft.
                  </Typography>
                  <Stack direction={'row'} gap={2} justifyContent={'flex-end'}>
                    <Button
                      color="secondary"
                      variant="text"
                      style={{ textTransform: 'initial' }}
                      onClick={() => setAbortSequence(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="secondary"
                      variant="text"
                      style={{ textTransform: 'initial' }}
                      onClick={() => onClose()}
                    >
                      Yes
                    </Button>
                  </Stack>
                </Stack>
              </Card>
            </Backdrop>
          )}
        </Fragment>
      ) : (
        <SuccessEnrollmentScreen
          onClose={onClose}
          enroledContacts={enroledContacts}
          enroledErrorContacts={enroledErrorContacts}
          loading={fetchContactError}
          enrolments={enrolments}
          isEnrolAll={isEnrolAll}
        />
      )}
    </Drawer>
  );
}

const LoadingScreen = ({ simpleLoading, nextPrevContactLoading, integrations }) => {
  return nextPrevContactLoading ? (
    <Stack alignItems={'center'} height={'80vh'} gap={5}>
      <Spacer y={5} />
      <img src={require('../../../../img/sequenceLoader.gif')} style={{ width: 200 }} />
      <Typography variant="h2">Your enrolments are being processed..</Typography>
      <Alert severity="info">
        <Typography width="250px" fontSize={14}>
          Do not close this window.
        </Typography>
      </Alert>
    </Stack>
  ) : simpleLoading ? (
    <Stack justifyContent={'center'} alignItems={'center'} height={'75vh'}>
      <AppLoader />
    </Stack>
  ) : (
    <Stack width={'90%'} spacing={2} margin={'40px auto'} alignItems={'center'}>
      <Typography variant="subtitle1">One moment please...</Typography>
      <Stack direction={'row'} spacing={1}>
        <Stack
          boxSizing={'border-box'}
          alignItems={'center'}
          justifyContent={'center'}
          width={50}
          height={50}
          sx={{
            background: '#7371ee',
            borderRadius: '50%',
            padding: '12px',
            '& svg': {
              color: 'white',
              width: '100%',
              height: '100%',
              transform: 'rotate(-30deg)',
            },
          }}
        >
          <SendIcon />
        </Stack>
        <Box
          sx={{
            '& svg': {
              width: 50,
              height: 50,
            },
          }}
        >
          {integrations?.isBullhornActive ? (
            <BullhornIconActive />
          ) : integrations?.isJobAdderActive ? (
            <JobadderIconActive />
          ) : integrations?.isVincereActive ? (
            <VincereIconActive />
          ) : integrations?.isSalesforceActive ? (
            <SalesforceIconActive />
          ) : integrations?.isHubspotActive ? (
            <HubspotIconActive />
          ) : (
            ''
          )}
        </Box>
      </Stack>
      <Typography color="GrayText" variant="caption" textAlign={'center'}>
        We're enrolling your prospects and <br /> checking for profiles on{' '}
        {integrations?.isBullhornActive
          ? 'Bullhorn'
          : integrations?.isJobAdderActive
          ? 'JobAdder'
          : integrations?.isVincereActive
          ? 'Vincere'
          : integrations?.isSalesforceActive
          ? 'Salesforce'
          : integrations?.isHubspotActive
          ? 'Hubspot'
          : ''}
      </Typography>
      <Typography color="GrayText" variant="caption" textAlign={'center'}>
        This will allow to leverage any <br /> contact details already on your CRM!
      </Typography>
    </Stack>
  );
};

const SuccessEnrollmentScreen = ({
  onClose,
  loading,
  enrolments,
  enroledContacts,
  enroledErrorContacts,
  isEnrolAll,
}) => {
  const history = useHistory();
  const errorsCount = Object.keys(enroledErrorContacts || {})?.length;
  const draftsCount =
    Object.keys(enrolments || {})?.length -
    Object.keys(
      (isEnrolAll >= 0
        ? Object.keys(enrolments)?.reduce((acc, enrolmentId, index) => {
            if (isEnrolAll <= index) acc[enrolmentId] = true;
            return acc;
          }, enroledContacts)
        : enroledContacts) || {},
    )?.length;
  const connectedCount = Object.keys(enrolments || {})?.length - errorsCount - draftsCount;
  return loading ? (
    <Stack justifyContent={'center'} alignItems={'center'} height={'75vh'}>
      <AppLoader />
    </Stack>
  ) : (
    <Stack justifyContent={'space-between'} padding={'0 28px'} height={'100%'}>
      <Stack>
        {errorsCount ? (
          <Alert severity="warning" sx={{ '& .MuiAlert-icon ': { color: '#5F2B01' } }}>
            {errorsCount} contact{errorsCount !== 1 && 's'} encountered errors.{' '}
            <Button
              variant="text"
              style={{ textTransform: 'initial', padding: 0 }}
              color="secondary"
              onClick={() => {
                onClose(false);
                history.push('/tasks/errors');
              }}
            >
              Manage in Sequence Errors
            </Button>
          </Alert>
        ) : (
          <Stack></Stack>
        )}
        {!!(errorsCount && draftsCount) && <Spacer y={2} />}
        {draftsCount ? (
          <Alert severity="warning" sx={{ '& .MuiAlert-icon ': { color: '#5F2B01' } }}>
            {draftsCount} contact{draftsCount !== 1 && 's'} couldn't be enroled.{' '}
            <Button
              variant="text"
              style={{ textTransform: 'initial', padding: 0 }}
              color="secondary"
              onClick={() => {
                onClose(false);
                history.push('/tasks/drafts');
              }}
            >
              Manage in Drafts
            </Button>
          </Alert>
        ) : (
          <Stack></Stack>
        )}
      </Stack>
      <Stack gap={2.5}>
        <Stack alignItems={'center'} gap={1}>
          <CheckCircleRoundedIcon sx={{ fontSize: '90px !important', color: '#4fcf5b' }} />
          <Typography>
            Success, you’ve enrolled {connectedCount} contact{connectedCount === 1 ? '' : 's'}!
          </Typography>
        </Stack>
      </Stack>
      <Stack></Stack>
      <Stack direction={'row'} gap={2.5}>
        <Button
          onClick={() => onClose(false)}
          sx={{ width: '100%', textTransform: 'initial' }}
          color="secondary"
          variant="outlined"
        >
          Close
        </Button>
      </Stack>
    </Stack>
  );
};

export default EnrolmentPersonalize;
