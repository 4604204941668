import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Typography,
  Card,
  CardContent,
  CardHeader,
  InputAdornment,
  Stack,
  Box,
} from '@mui/material';
import { Form, Spacer, Button } from 'src/components/shared';
import { useDataProviders } from '../../utils/dataProviderStore';

import { updateTenant } from 'src/modules/app/api/appApis';
import { updateTenantConfig, setTenantConfig } from 'src/modules/app/actions/appActions';
import toast from 'src/utils/toast';

function AccountCreditLimit({ user, limit }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { postCreditAllocation } = useDataProviders();

  const tenant = useSelector((state) => state.app.tenant);

  const handleSubmit = async (data) => {
    try {
      setLoading(true);
      const res = await updateTenant(tenant.id, {
        defaultCreditsLimit: parseInt(data.limit),
      });
      if (Number(data.limit) === res?.tenant?.[0]?.defaultCreditsLimit)
        toast.success('Account credits limit has been updated');
      else toast.error('Account credits limit has not been updated');
      dispatch(setTenantConfig(res.tenant[0]));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardHeader
        sx={{ '& .MuiCardHeader-subheader': { color: 'rgba(0, 0, 0, .87)', fontWeight: 500 } }}
        subheader="Account credit limit"
      />
      <CardContent sx={{ paddingTop: 0 }}>
        <Typography variant="body1" color="rgba(0, 0, 0, .87)">
          Set the maximum you want to spend each month on data.
        </Typography>

        <Form
          initialValues={{
            limit: tenant?.defaultCreditsLimit || 0,
          }}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({ values, ...formProps }) => {
            return (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formProps.submitForm();
                  return false;
                }}
                noValidate
              >
                <Stack direction="row" alignItems="flex-end">
                  <Form.Field.Input
                    name="limit"
                    variant="outlined"
                    size="small"
                    icon={<InputAdornment position="start">$</InputAdornment>}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                  <Box ml={2} mb={1.2}>
                    <Button variant="contained" loading={loading} color="secondary" type="submit">
                      Save
                    </Button>
                  </Box>
                </Stack>
              </form>
            );
          }}
        </Form>
      </CardContent>
    </Card>
  );
}

export default AccountCreditLimit;
