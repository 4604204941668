import styled from 'styled-components';

export const BarGroup = styled.div`
  /* height: 120px;
  min-height: 120px; */

  height: ${(props) => (props.size == 'large' ? '240px' : '120px')};
  min-height: ${(props) => (props.size == 'large' ? '240px' : '120px')};

  display: flex;
  /* flex-wrap: wrap; */
  /* gap: 20px; */
  margin-top: 16px;
`;

export const Bars = styled.div`
  display: inline-flex;
  gap: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  width: calc(100% - 20px);
  padding: 0px 16px;
`;

export const BarLegend = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-left: 4px;
  /* height: 120px; */
  min-height: ${(props) => (props.size == 'large' ? '240px' : '120px')};
  justify-content: space-between;
  & span {
    font-size: ${(props) => (props.size == 'large' ? '14px' : '12px')};
    color: #cbcccc;
    font-weight: 400;
  }
`;

export const BarItem = styled.div`
  background: #000000de;
  width: ${(props) => (props.size == 'large' ? '9px' : '6px')};
  width: ${(props) => (props.size == 'large' ? '9px' : '6px')};
  min-width: ${(props) => (props.size == 'large' ? '9px' : '6px')};
  height: 100%;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
`;

export const BarFill = styled.div`
  background-color: ${(props) => props.color};
  height: ${(props) => props.height}%;
  width: ${(props) => (props.size == 'large' ? '9px' : '6px')};
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 4px;
`;
