import React, { useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import { useHistory } from 'react-router-dom';

import { ChildMenu, MenuItem } from './styles';

import { Stack, Typography } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useLocation } from 'react-router-dom';

import { stripTrailingSlash } from 'src/utils/helper';
import { useLmsHomeProvider } from 'src/modules/lms/utils/lmsStore';

function Item({ menuItem, collapsed }) {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { summary } = useLmsHomeProvider();

  const handleClick = () => {
    setShowMenu(!showMenu);
  };

  const handleClickSub = (item) => {
    history.push(item?.link ?? '/');
  };

  const path = stripTrailingSlash(location.pathname);

  const isActive = path == menuItem.link;
  const className = isActive ? 'active' : '';

  return (
    <>
      <MenuItem className={`${className}`}>
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>{menuItem.icon}</ListItemIcon>
          <ListItemText
            primary={
              <Stack direction="row" justifyContent="space-between">
                <Typography>{menuItem.title}</Typography>
                {menuItem.children && menuItem.children.length ? (
                  <>{showMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}</>
                ) : null}
              </Stack>
            }
          />
        </ListItemButton>
      </MenuItem>
      {menuItem.children && menuItem.children.length ? (
        <Collapse in={showMenu}>
          <ChildMenu collapsed={collapsed || undefined}>
            {menuItem.children.map((childItem, index) => (
              <ListItemButton
                onClick={() => handleClickSub(childItem)}
                key={`${menuItem.link}-${index}`}
              >
                {collapsed && <ListItemIcon>{childItem.icon}</ListItemIcon>}

                {!collapsed && (
                  <ListItemText
                    primary={
                      <Typography component="span" color="textSecondary">
                        {childItem.title} ({summary[childItem.countKey]})
                      </Typography>
                    }
                  />
                )}
              </ListItemButton>
            ))}
          </ChildMenu>
        </Collapse>
      ) : null}
    </>
  );
}

export default Item;
