import styled from 'styled-components';
import { getColorCode } from '../../utils/sequenceHelper';

export const ContactStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  height: 30px;
  width: 120px;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  background-color: ${(props) => getColorCode(props.status).bgColor};
  color: ${(props) => getColorCode(props.status).color};
`;
