export const dateFormat = 'Do MMM YYYY';
export const timeFormat = 'H:mm';

export const dateTimeFormat = 'Do MMM YYYY, HH:mm';

export const isPathMatched = (string, pattern) => {
  if (typeof pattern === 'function') {
    return pattern(string);
  } else if (typeof pattern === 'string') {
    return string === pattern;
  }
  return false;
};

export const regExCheckUnsubscribePage = /^\/contact\/[0-9a-f]{24}\/unsubscribe$/i;
export const standAlonePages = [(path) => regExCheckUnsubscribePage.test(path)];

export const contactRouteMatch = (value) => {
  return /^\/tasks\/[^\/]+$/.test(value);
};

export const taskRouteMatch = (value) => {
  return /^\/task\/[^\/]+$/.test(value);
};

export const specialCharacterRegex = (value) => {
  return !/[^a-zA-Z0-9 ]/.test(value);
};

export const contactNameRegex = (value) => {
  // return true;
  return /^[a-zA-Z\x7f-\xff-']+$/.test(value);
  // return !/^[A-Za-zÀ-ÖØ-öø-ÿ\s-']+$/.test(value);
};

export const checkValueExists = (value, data, key = null) => {
  for (let i = 0; i < data.length; i++) {
    if (
      (key && data[i][key].trim().toLowerCase() === value.trim().toLowerCase()) ||
      (!key && data[i] === value)
    ) {
      return true;
    }
  }
  return false;
};

export const authPages = [
  '/auth/login',
  '/auth/setup/password',
  '/auth/forgot-password',
  '/auth/reset-password',
  '/auth/signup',
  '/auth/verification',
  '/auth/onboarding/password',
];

export const girdLoadingMessages = [
  'we are loading your data',
  "we'll be right with you",
  'getting everything ready',
  'loading your info',
  "we're loading your data",
  'putting everything together',
];

export const instagramRegex = /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com)\/(\w+)/;
export const linkedinRegex = /(?:(?:http|https):\/\/)?(?:www.)?(?:linkedin.com)\/(\w+)/;
export const facebookRegex = /(?:(?:http|https):\/\/)?(?:www.)?(?:facebook.com)\/(\w+)/;
export const twitterRegex = /(?:(?:http|https):\/\/)?(?:www.)?(?:twitter.com)\/(\w+)/;
export const githubRegex = /(?:(?:http|https):\/\/)?(?:www.)?(?:github.com)\/(\w+)/;
export const stackofRegex = /(?:(?:http|https):\/\/)?(?:www.)?(?:stackoverflow.com)\/(\w+)/;
// export const phoneRegex =
//   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;

import api from 'src/api';
export const isEmailExist = async (values, currentEmail) => {
  if (values === currentEmail) return true;
  try {
    let response = await api(`/emails?email=${values}&isDeleted=false`, null, 'get');
    if (response.emails.length > 0) {
      return false;
    } else {
      return true;
    }
  } catch (error) {}
};

export const daysOfWeek = [
  {
    value: 0,
    label: 'Sun',
  },
  {
    value: 1,
    label: 'Mon',
  },
  {
    value: 2,
    label: 'Tue',
  },
  {
    value: 3,
    label: 'Wed',
  },
  {
    value: 4,
    label: 'Thu',
  },
  {
    value: 5,
    label: 'Fri',
  },
  {
    value: 6,
    label: 'Sat',
  },
];
