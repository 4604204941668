import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AppGrid } from 'src/components/App';
import { Tooltip } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { EventCard } from '../eventsStyles';
import { useColumns } from './useColumns';
import { Button, Drawer, Menu } from 'src/components/shared';

import { fetchTemplateDetails, updateTemplate } from 'src/modules/admin/api/adminApi';
import { saveTemplate } from 'src/modules/admin/api/adminApi';
import { sendSeqStepEmailTest } from 'src/modules/sequence/api/sequenceApi';

import toast from 'src/utils/toast';

import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import TemplateForm from 'src/modules/admin/components/Templates/TemplateForm';
import { useMemo } from 'react';

const EmailCard = ({ sequence, event, onAddTemplate, ...props }) => {
  const [templates, setTemplates] = useState(event?.emailTemplates || []);
  const [fetchingTemplates, setFetchingTemplates] = useState(false);
  const [sendingTestEmail, setSendingTestEmail] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState({ edit: null, open: false });
  const [linkedinType, setLinkedinType] = useState('linkedinMessage');

  useEffect(() => {
    if (drawerOpen?.edit?.id) setLinkedinType(drawerOpen?.edit?.type);
  }, [drawerOpen]);

  const handleTemplateSubmit = (data) => {
    return saveTemplate(data);
  };

  const menuTitle = useMemo(() => {
    switch (linkedinType) {
      case 'linkedinConnection':
        return 'Connection';
        break;

      case 'linkedinMail':
        return 'InMail';
        break;

      default:
      case 'linkedinMessage':
        return 'Message';
        break;
    }
  }, [linkedinType]);

  const sendTestEmail = async () => {
    try {
      setSendingTestEmail(true);
      const res = await sendSeqStepEmailTest(sequence.id, event.id);
      toast.success('Test email sent! Please check your inbox.');
      setSendingTestEmail(false);
    } catch (error) {
      toast.error(error?.error?.message || 'Error ocurred! Please try again.');
    }
  };

  const changeEventTemplateStatus = async (templateId, status) => {
    const payload = {
      templateProps: {
        ...event.templateProps,
        [templateId]: {
          ...event.templateProps[templateId],
          status,
        },
      },
    };
    await props.putSequenceEvent(sequence.id, event?.id, payload);
  };

  const deleteTemplate = async (template) => {
    const emailTemplates = event.emailTemplates.filter((item) => template.id !== item.id);
    const payload = {
      emailTemplates: emailTemplates.map((item) => item.id),
    };
    await props.putSequenceEvent(sequence.id, event?.id, payload);
  };

  const editTemplate = (template) => {
    props.onEdit(event, template);
  };

  const cloneTemplate = async (template) => {
    template.name = `${template.name} (copy)`;
    template.subject = template.subject;
    // template.files = template.attachments;
    await fetchTemplateDetails(template.id)
      .then((res) => {
        setFetchingTemplates(false);
        template.files =
          (res.template?.files.length > 0 && res.template?.files.map((file) => file.id)) || [];
      })
      .catch((err) => {
        setFetchingTemplates(false);
      });
    delete template.id;
    let newTemplate = await saveTemplate(template);
    newTemplate = newTemplate.template;

    const payload = {
      emailTemplates: [...event.emailTemplates.map((item) => item.id), newTemplate.id],
      templateProps: {
        ...event.templateProps,
        [newTemplate.id]: {
          status: true,
        },
      },
    };

    return props.putSequenceEvent(sequence.id, event?.id, payload);
  };

  const putSequenceEventOnTemplateCreation = (newTemplate) => {
    const payload = {
      emailTemplates: [...event.emailTemplates.map((item) => item.id), newTemplate.id],
      templateProps: {
        ...event.templateProps,
        [newTemplate.id]: {
          status: true,
        },
      },
    };

    return props.putSequenceEvent(sequence.id, event?.id, payload);
  };

  useEffect(() => {
    if (event?.emailTemplates && event?.emailTemplates.length) {
      setTemplates(JSON.parse(JSON.stringify(event?.emailTemplates)));
    }
  }, [event?.emailTemplates]);

  const columns = useColumns(
    templates,
    event,
    changeEventTemplateStatus,
    deleteTemplate,
    editTemplate,
    cloneTemplate,
    setDrawerOpen,
  );

  return (
    <>
      <EventCard
        title="Automated Email"
        icon={<EmailIcon style={{ color: '#42A5F5' }} />}
        showEdit={false}
        customAction={() => {
          return (
            <>
              <Button
                size="small"
                color="primary"
                sx={{ textTransform: 'none', color: '#4789C2' }}
                startIcon={<AddIcon />}
                onClick={onAddTemplate}
              >
                A/B test
              </Button>
              <Tooltip title="Send test email to self">
                <div>
                  <Button
                    size="small"
                    color="primary"
                    iconButton={true}
                    onClick={sendTestEmail}
                    sx={{ opacity: 0.6 }}
                    loading={sendingTestEmail}
                  >
                    <SendIcon />
                  </Button>
                </div>
              </Tooltip>
            </>
          );
        }}
        showDelete={true}
        {...props}
      >
        {templates && templates.length ? (
          <AppGrid
            columns={columns.map((col, index) => ({
              ...col,
              options: columns?.columns?.length
                ? { ...col.options, ...columns?.columns[index] }
                : col.options,
            }))}
            data={templates}
            loading={false}
            hideToolBar={true}
            options={{
              search: false,
              filter: false,
              download: false,
              viewColumns: false,
            }}
          />
        ) : null}
      </EventCard>
      <Drawer
        title={'Clone Template'}
        open={drawerOpen.open}
        disableEnforceFocus
        onClose={() => {
          setDrawerOpen({ edit: null, open: false });
        }}
        renderHeaderFunction={() => {
          if (drawerOpen.templateType === 'email') return null;
          return (
            <Menu
              iconButton={false}
              title={menuTitle}
              buttonStyles={{ padding: 0, textTransform: 'none', fontWeight: 'normal' }}
              options={[
                {
                  label: 'Message',
                  onClick: () => {
                    setLinkedinType('linkedinMessage');
                  },
                },
                {
                  label: 'Connection',
                  onClick: () => {
                    setLinkedinType('linkedinConnection');
                  },
                },
                {
                  label: 'Inmail',
                  onClick: () => {
                    setLinkedinType('linkedinMail');
                  },
                },
              ]}
            />
          );
        }}
      >
        <TemplateForm
          editUser={drawerOpen.edit}
          templateType={drawerOpen.templateType}
          drawerToggle={setDrawerOpen}
          linkedinType={linkedinType}
          loading={props.loading}
          user={props.user}
          putSequenceEventOnTemplateCreation={putSequenceEventOnTemplateCreation}
          {...props}
          saveTemplates={handleTemplateSubmit}
        />
        {/* <EmailForm template={drawerOpen.edit} event={event} /> */}
      </Drawer>
    </>
  );
};

EmailCard.propTypes = {
  event: PropTypes.object,
};

export default EmailCard;
