import React from 'react';
import Grid from '@mui/material/Grid';
import CompetitionContent from './CompetitionContent';
import CompetitionThumbnail from './CompetitionThumbnail';

import useRequest from 'src/components/hooks/useRequest';

import { fetchCompetitionLeaderBoard } from 'src/modules/lms/api/LMSApis';
import { useSelector } from 'react-redux';

function index({ data }) {
  const user = useSelector((state) => state.auth.user);

  const id = data._id || data.id;
  const [leaderBoard, { loading, error, refetch }] = useRequest(fetchCompetitionLeaderBoard, id);
  const position = leaderBoard?.leaderBoard[user?.id] || undefined;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={5}>
        <CompetitionThumbnail data={data} />
      </Grid>
      <Grid item xs={12} sm={12} md={7}>
        <CompetitionContent data={data} position={position} />
      </Grid>
    </Grid>
  );
}

export default index;
