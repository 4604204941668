import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import { Spacer, Modal } from 'src/components/shared';
import { ConfirmDialog } from 'src/components/App';
import TaskDueDateForm from '../../components/Task/TaskDueDateForm';
import { Email, LinkedIn, Sms, Call } from '../../components/Task';
import { getNoteType } from 'src/modules/contacts/api/contactApis';

const TaskView = ({ currentTab, loader, ...props }) => {
  const [noteTypes, setNoteTypes] = useState([]);
  const [showDueDateForm, setShowDueDateForm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const history = useHistory();
  const { task } = props;

  const toggleDeleteModel = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDelete = async () => {
    try {
      setDeleting(true);
      await props.deleteTask(task.id);
      setDeleting(false);
      toggleDeleteModel();
      history.replace('/tasks');
    } catch (error) {
      setDeleting(false);
    }
  };

  const fetchNoteTypeOptions = async () => {
    const res = await getNoteType();
    setNoteTypes(res.noteTypes);
  };

  useEffect(() => {
    fetchNoteTypeOptions();
  }, []);

  const closeDueDateForm = () => {
    setShowDueDateForm(false);
  };

  if (!task.id) return null;
  const linkedinTypes = [
    'linkedin',
    'linkedinConnection',
    'linkedinMessage',
    'linkedinMail',
    'linkedinViewProfile',
  ];

  return (
    <>
      <Box m={'16px -16px'} position={'relative'}>
        {['email', 'selectEmailTemplate'].includes(task.taskType) && (
          <Email
            task={task}
            setShowDueDateForm={setShowDueDateForm}
            noteTypes={noteTypes}
            toggleDeleteModel={toggleDeleteModel}
            {...props}
          />
        )}
        {linkedinTypes.includes(task.taskType) && (
          <LinkedIn
            task={task}
            setShowDueDateForm={setShowDueDateForm}
            noteTypes={noteTypes}
            toggleDeleteModel={toggleDeleteModel}
            {...props}
          />
        )}
        {task.taskType === 'sms' && (
          <Sms
            task={task}
            setShowDueDateForm={setShowDueDateForm}
            noteTypes={noteTypes}
            toggleDeleteModel={toggleDeleteModel}
            {...props}
          />
        )}
        {task.taskType === 'call' && (
          <Call
            task={task}
            setShowDueDateForm={setShowDueDateForm}
            noteTypes={noteTypes}
            toggleDeleteModel={toggleDeleteModel}
            {...props}
          />
        )}
        {task.taskType === 'general' && (
          <Call
            task={task}
            setShowDueDateForm={setShowDueDateForm}
            noteTypes={noteTypes}
            toggleDeleteModel={toggleDeleteModel}
            {...props}
          />
        )}
      </Box>
      <Spacer y={3} />
      <Modal
        open={showDueDateForm}
        onClose={closeDueDateForm}
        backdrop={false}
        title="Change due date"
      >
        <TaskDueDateForm task={task} onClose={closeDueDateForm} putTask={props.putTask} />
      </Modal>

      <ConfirmDialog
        title={'Delete task'}
        open={showDeleteModal}
        onClose={toggleDeleteModel}
        onConfirm={handleDelete}
        loading={deleting}
        buttonTitle="Delete"
        buttonProps={{
          color: 'error',
          variant: 'contained',
          size: 'small',
        }}
      >
        <Typography variant="body1" color="textSecondary">
          Are you sure you want to delete this task? This action cannot be undone.
        </Typography>
      </ConfirmDialog>
    </>
  );
};

export default TaskView;
