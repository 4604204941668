import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import useStyles from './style';
import AddIcon from '@mui/icons-material/Add';
import ActionCardMenu from './ActionCardMenu';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useCourse } from '../../utils/CourseStore';
import { useHistory } from 'react-router';
import { useEffect } from 'react';

export default function CreateCourse({ ...props }) {
  const classes = useStyles();
  const { courses, deleteCourse, put } = useCourse();
  const history = useHistory();
  const handlePublish = (id, status) => {
    put(id, { published: !status });
  };

  return (
    <>
      <Box>
        <Typography mb={2} className={classes.heading}>
          Create a bespoke experience for your team.
        </Typography>
        <Typography mb={4}>
          Handpick SuperSessions for your business to create a course and assign it to your team to
          complete!
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid mb={2} item xs={2.5}>
          <Box className={classes.Card}>
            <Box
              className={classes.CardContent}
              onClick={() => {
                history.push('/admin/course/create');
              }}
            >
              <AddIcon fontSize="large" className={classes.AddIcon} />
              <Typography mt={3}> Create a Course</Typography>
            </Box>
          </Box>
        </Grid>
        {courses.map((item, index) => {
          return (
            <Grid mb={2} item xs={2.5}>
              <Box
                className={classes.Card}
                sx={{ bgcolor: index % 2 === 0 ? '#F0F4C3' : '#B3E5FC' }}
              >
                <Box className={classes.actionMenu}>
                  <ActionCardMenu
                    id={item?.id}
                    status={item?.published}
                    handleDelete={deleteCourse}
                    handlePublish={handlePublish}
                  />
                </Box>
                <Box className={classes.titleBox}>
                  <Typography className={classes.title}>{item?.title}</Typography>
                </Box>
                {item?.published ? (
                  <Box className={classes.button}>
                    <Button className={classes.live} startIcon={<PlayArrowIcon />}>
                      Live
                    </Button>
                  </Box>
                ) : (
                  <Box className={classes.button}>
                    <Button className={classes.unpublished}>Unpublished</Button>
                  </Box>
                )}
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
