import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  ButtonBase,
  Card,
  CardContent,
  Chip,
  Grid,
  InputAdornment,
  Stack,
  Typography,
  Avatar,
  Box,
} from '@mui/material';
import { Form, Button, Spacer } from 'src/components/shared';

import AddIcon from '@mui/icons-material/Add';
import { fetchSessionsLookup, fetchUsersLookup } from 'src/modules/users/api/usersApi';
import SearchIcon from '@mui/icons-material/Search';
import { useCourse } from '../../utils/CourseStore';

function CreateCourse({ courseData, defaultOptions }) {
  const [showOverview, setShowOverview] = useState(false);
  const [loading, setLoading] = useState(false);
  const lmsContentCategory = useSelector((state) => state.app.globals.lmsContentCategory);

  const [showExceptions, setShowExceptions] = useState(false);

  const history = useHistory();
  const { post, put } = useCourse();

  const handleSubmit = async (data) => {
    const values = JSON.parse(JSON.stringify(data));

    delete values.sessions;
    delete values.users;
    setLoading(true);

    if (courseData?.id) {
      await put(courseData.id, {
        ...values,
        courseSessions: values?.courseSessions
          ?.filter((k) => !k.id.includes('temp'))
          .map((k, i) => ({ id: k.id, pos: i })),
        // published: type !== 'save',
        featuredImageUrl: values?.featuredImageUrl?.url,
        excludedUsers: values?.excludedUsers?.map((k) => k.id),
      });
      setLoading(false);
      history.push(`/admin/course`);
    } else {
      await post({
        ...values,
        courseSessions: values?.courseSessions
          ?.filter((k) => !k.id.includes('temp'))
          .map((k, i) => ({ id: k.id, pos: i })),
        // published: type !== 'save',
        featuredImageUrl: values?.featuredImageUrl?.url,
        excludedUsers: values?.excludedUsers?.map((k) => k.id),
      });
      setLoading(false);
      history.push('/admin/course');
    }
  };

  const toggleOverview = () => {
    setShowOverview(!showOverview);
  };

  const toggleException = () => {
    setShowExceptions(!showExceptions);
  };

  console.log('courseData 101 : ', courseData);

  return (
    <>
      <Typography variant="h2">{courseData?.id ? 'Update course' : 'Create a Course'}</Typography>
      <Spacer x={1} y={1} />
      <Typography variant="body1" color="textSecondary">
        Choose from our library of sessions, devised and created by industry experts.
      </Typography>
      <Spacer x={2} y={2} />
      <Card>
        <CardContent>
          <Form
            initialValues={{
              title: courseData?.title ? courseData.title : '',
              overviewText: courseData?.overviewText ? courseData.overviewText : '',
              sessions: courseData?.sessions || [],
              courseSessions: courseData?.sessions || [],
              submitType: '',
              excludedUsers:
                courseData?.excludedUsers?.map((k) => ({ ...k, name: k.fname + ' ' + k.lname })) ||
                [],
              users:
                courseData?.excludedUsers?.map((k) => ({ ...k, name: k.fname + ' ' + k.lname })) ||
                [],
              categoryType: courseData?.categoryType || 'sales',
              published: courseData?.categoryType || false,
            }}
            validationSchema={Yup.object().shape({
              title: Yup.string().required('Please enter a course name'),
              overviewText: Yup.string().required('Please enter course overview'),
            })}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ values, ...formProps }) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    formProps.submitForm();
                    return false;
                  }}
                  noValidate
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Form.Field.Input
                        name="title"
                        variant="outlined"
                        fullWidth
                        label="Course name"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Form.Field.Select
                        options={lmsContentCategory}
                        optLabel="label"
                        optValue="value"
                        name="categoryType"
                        variant="outlined"
                        fullWidth
                        label="Content category"
                        showNone={false}
                      />
                    </Grid>

                    {/* Description */}
                    <Grid item xs={12}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <ButtonBase onClick={toggleOverview}>
                          <AddIcon sx={{ fill: 'rgba(0,0,0,0.6)', fontSize: '20px' }} />{' '}
                          <Typography variant="body2" fontWeight={700} color="textSecondary">
                            Add Course Overview
                          </Typography>
                        </ButtonBase>
                        <Typography
                          variant="caption"
                          sx={{ fontStyle: 'italic', fontWeight: '400' }}
                        >
                          This content will be shown on the Learning Portal, and is used to explain
                          what your users can expect to learn.
                        </Typography>
                      </Stack>
                      {(showOverview || formProps.errors?.overviewText) && (
                        <Form.Field.Textarea
                          minRows={2}
                          variant="outlined"
                          name="overviewText"
                          placeholder="Course overview"
                          fullWidth
                        />
                      )}
                    </Grid>

                    {/* Sessions */}
                    <Grid item xs={12}>
                      <Typography variant="body2" fontWeight={700} color="textSecondary">
                        Choose your Sessions
                      </Typography>
                      <Form.Field.AutoComplete
                        name="sessions"
                        fullWidth
                        multiple
                        options={[]}
                        variant="outlined"
                        remoteMethod={(val) => {
                          return fetchSessionsLookup(val);
                        }}
                        optLabel="name"
                        optValue="id"
                        placeholder="Select sessions from the Learning Library "
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(val) => {
                          formProps.setFieldValue('courseSessions', val);
                        }}
                      />

                      {values?.courseSessions && values?.courseSessions.length ? (
                        <Stack direction="row" gap={1} flexWrap="wrap">
                          {values?.courseSessions.map((course, index) => (
                            <Chip
                              key={`course-session-${course.id}`}
                              label={course?.title || course?.name || ''}
                              variant="outlined"
                              onDelete={() => {
                                formProps.setFieldValue(
                                  'courseSessions',
                                  values.courseSessions.filter((item) => item.id !== course.id),
                                );
                              }}
                            />
                          ))}
                        </Stack>
                      ) : null}
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="body2" fontWeight={700} color="textSecondary">
                        Who sees your Course?
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        By default your course will be assigned to all users. If you wish to exclude
                        any user from this course, please add your exceptions below.
                      </Typography>

                      <Box mt={2}>
                        <ButtonBase onClick={toggleException}>
                          <AddIcon sx={{ fill: 'rgba(0,0,0,0.6)', fontSize: '20px' }} />{' '}
                          <Typography variant="body2" fontWeight={700} color="textSecondary">
                            Exception
                          </Typography>
                        </ButtonBase>
                        {showExceptions && (
                          <Form.Field.AutoComplete
                            name="users"
                            fullWidth
                            options={[]}
                            multiple
                            variant="outlined"
                            remoteMethod={(val) => {
                              return fetchUsersLookup(val);
                            }}
                            optLabel="name"
                            optValue="id"
                            placeholder="Select users"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(val) => {
                              formProps.setFieldValue('excludedUsers', val);
                            }}
                          />
                        )}
                      </Box>

                      {values?.excludedUsers && values?.excludedUsers.length ? (
                        <Stack direction="row" gap={1} flexWrap="wrap" mt={showExceptions ? 0 : 1}>
                          {values?.excludedUsers.map((user, index) => (
                            <Chip
                              key={`course-session-${user.id}`}
                              label={`${user.fname} ${user.lname}`}
                              variant="outlined"
                              avatar={
                                <Avatar
                                  src={user?.userImage || ''}
                                  alt={`${user.fname} ${user.lname}`}
                                >
                                  {`${user.fname.slice(0, 1)}${user.lname.slice(0, 1)}`}
                                </Avatar>
                              }
                              onDelete={() => {
                                formProps.setFieldValue(
                                  'excludedUsers',
                                  values.excludedUsers.filter((item) => item.id !== user.id),
                                );
                              }}
                            />
                          ))}
                        </Stack>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Stack
                    direction="row"
                    gap={2}
                    alignItems="center"
                    justifyContent="flex-end"
                    mt={2}
                  >
                    <Button color="secondary" disabled={loading}>
                      Cancel
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        formProps.setFieldValue('published', false);
                        formProps.submitForm();
                      }}
                      loading={loading}
                    >
                      Save as Draft
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        formProps.setFieldValue('published', true);
                        formProps.submitForm();
                      }}
                      loading={loading}
                    >
                      Publish
                    </Button>
                  </Stack>
                </form>
              );
            }}
          </Form>
        </CardContent>
      </Card>
    </>
  );
}

export default CreateCourse;
