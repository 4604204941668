import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, Paper, Link, Stack } from '@mui/material';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import { Button, Form } from 'src/components/shared';
import SettingsActions from './SettingsActions';
import { connect } from 'react-redux';
import { settingNames, updateSettings } from '../../actions/adminActions';
import { useDispatch } from 'react-redux';

const SettingsItem = ({ item, isCheckedSetting, ...props }) => {
  // const open = !!isCheckedSetting.value;
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    // textAlign: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    width: '80%',
    // boxShadow: 'none',
    // color: theme.palette.text.secondary,
  }));

  // const handleOnChange = async (e, settings) => {
  //   const propName = settings.settingProp;
  //   const id = props.tenantSettings.id;
  //   await props.updateSettings({
  //     setting: { ...props.tenantSettings.setting, [propName]: !settings.value },
  //     id,
  //   });
  // };

  return (
    <div>
      <Item>
        <Stack spacing={1} p={2} width={'100%'}>
          <Typography variant="h2" sx={{ fontWeight: '500' }}>
            {item.title}
          </Typography>
          <Typography typography="body2" color="textSecondary">
            {item.description}
          </Typography>

          {/* {open && ( */}
          <SettingsActions
            settingsName={item.name}
            tenantSettings={props.tenantSettings}
            deleteEvent={props.deleteEvent}
            {...props}
          />
          {/* )} */}
        </Stack>
        <Stack spacing={1} p={1}>
          {/* <Form.Field.Switch
            rows={4}
            name={item.name || ''}
            variant="outlined"
            onChange={(e) => {
              handleOnChange(e, isCheckedSetting);
            }}
            value={open}
            label="Enable"
          /> */}
        </Stack>
      </Item>
    </div>
  );
};

export default SettingsItem;
