import * as competitionTypes from './competitionTypes';

export const fetchCompetitionsList = (
  paging,
  filters,
  sort,
  resolve = () => {},
  reject = () => {},
) => ({
  type: competitionTypes.FETCH_COMPETITIONS_LIST,
  paging,
  filters,
  sort,
  resolve,
  reject,
});

export const setCompetitionsList = (data) => ({
  type: competitionTypes.SET_COMPETITIONS_LIST,
  data,
});

export const editCompetition = (competitionId, payload, reject, resolve) => {
  return {
    type: competitionTypes.EDIT_COMPETITIONS_LIST,
    competitionId,
    payload,
    resolve,
    reject,
  };
};

export const postCompetition = (data, resolve, reject) => ({
  type: competitionTypes.POST_COMPETITION,
  data,
  resolve,
  reject,
});

export const putCompetition = (id, data, resolve, reject) => ({
  type: competitionTypes.PUT_COMPETITION,
  id,
  data,
  resolve,
  reject,
});
