import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Box, Typography, Alert, FormHelperText } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import { Popover } from 'src/components/App';

import { fetchTemplatesLookup } from 'src/modules/app/api/appApis';
import { saveTemplate, fetchTemplateDetails, updateTemplate } from 'src/modules/admin/api/adminApi';
import { isNull } from 'lodash';

import { taskAutoCloseOptions } from '../../../config';
import { getInputReplacementVariables } from 'src/modules/app/actions/appSelector';

const editorProps = {
  fullWidth: true,
  rows: 4,
  variant: 'outlined',
  name: 'description',
  label: 'Description',
  showPlaceholder: true,
  showFontStyle: false,
  showFontEditor: false,
  showAlignProps: false,
  showListProps: false,
  showAttachments: false,
};

const charLimit = {
  linkedinMessage: 8000,
  linkedinConnection: 300,
  linkedinMail: 1900,
};

const LinkedInForm = ({ onSubmit, loading, event, linkedinType, handleClose, ...props }) => {
  const [template, setTemplate] = useState({});
  const [fetchingTemplates, setFetchingTemplates] = useState(false);
  const [description, setDescription] = useState('');
  const templateCategoryList = useSelector((state) => state.app.globals.templateCategoryList);
  const replacementVariables = useSelector((state) => getInputReplacementVariables(state));
  const popoverRef = useRef(null);
  const descriptionRef = useRef('');
  useEffect(() => {
    setDescription(descriptionRef.current);
  }, [linkedinType]);

  const descriptionOnChange = (val) => {
    descriptionRef.current = val;
  };

  useEffect(() => {
    if (template && template?.id && template?.id !== '') {
      setDescription(template.content);
    }
  }, [template]);

  useEffect(() => {
    if (event && event?.id && event?.id !== '' && event?.templates && event?.templates !== '') {
      if (event?.templates && event?.templates?.id && event?.templates?.id !== '') {
        setTemplate(event?.templates);
      } else {
        setFetchingTemplates(true);
        fetchTemplateDetails(event?.templates)
          .then((res) => {
            setTemplate(res.template);
            setFetchingTemplates(false);
          })
          .catch((err) => {
            setTemplate({});
            setFetchingTemplates(false);
          });
      }
    } else if (event?.description && !isNull(event?.description) && event?.description !== '') {
      setDescription(event.description);
    }
  }, []);

  const handleSubmit = async (values) => {
    const data = JSON.parse(JSON.stringify(values));
    data.templates = data.templates?.id || '';

    let saveAsNew = data.saveAsNew;
    if (
      data.eventType !== 'linkedinViewProfile' ||
      (data.eventType === 'linkedinMail' && data.templates === '')
    ) {
      saveAsNew = true;
    }

    if (data.eventType !== 'linkedinMail') {
      delete data.subject;
    }

    if (data.templates && data.templates !== '') {
      if (template?.id && template?.id !== '' && template?.id == data.templates) {
        await updateTemplate(template?.id, {
          name: data.name,
          subject: data.subject,
          content: data.description,
          category: data.category,
          isPublished: true,
        });
      } else {
        saveAsNew = true;
      }
    } else {
      saveAsNew = true;
    }

    if (saveAsNew === true) {
      let newTemplate = await saveTemplate({
        type: linkedinType || 'linkedin',
        name: data.name,
        subject: data.subject,
        content: data.description,
        category: data.category,
        ...((!data.saveAsNew || data.saveAsNew === false) && { type: 'personalisedMessage' }),
        isPublished: true,
      });
      newTemplate = newTemplate.template;
      data.templates = newTemplate.id;
      delete data.category;
      delete data.subject;
      onSubmit(data);
    } else {
      delete data.category;
      delete data.subject;
      // delete data.templates;
      onSubmit(data);
    }
  };

  const fetchTemplatesDetails = async (val) => {
    const newTemplates = await fetchTemplatesLookup(val, linkedinType);
    if (newTemplates && newTemplates.length > 0) {
      const sequenceResults = newTemplates
        .sort(
          (a, b) =>
            (a.permissionType === '') - (b.permissionType === '') ||
            a.permissionType - b.permissionType,
        )
        .map((option) => {
          var permissionType =
            option.permissionType === 'team'
              ? 'Team'
              : option.permissionType === 'private'
              ? 'Private'
              : option.permissionType === 'organisation' || option.permissionType === ''
              ? 'Organization'
              : 'Organization';

          return {
            accessType: permissionType,
            ...option,
          };
        });
      return await sequenceResults;
    }
  };

  return (
    <Form
      initialValues={{
        eventType: linkedinType || 'linkedin',
        name: template?.name || '',
        subject: template?.subject || '',
        templates: template,
        description: description,
        category: template?.category || '',
        saveAsNew: false,
        isAutoClose: event?.isAutoClose || false,
        autoCloseDuration: event?.autoCloseDuration ? parseInt(event?.autoCloseDuration) : 0,
      }}
      validationSchema={Yup.object().shape({
        ...(linkedinType !== 'linkedinViewProfile'
          ? { description: Yup.string().required('Please enter description!') }
          : {}),
        name: Yup.string().test({
          message: () => 'Please enter template name!',
          test: (values, { options }) => {
            if (!values && options.parent.saveAsNew === false) return true;
            return values && typeof values !== 'undefined' && values !== '';
          },
        }),
        autoCloseDuration: Yup.number()
          .integer()
          .test({
            message: () => 'Please choose Auto-Close duration!',
            test: (values, { options }) => {
              if (!values && options.parent.isAutoClose === false) return true;
              return values && values > 0;
            },
          }),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ values, ...formProps }) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formProps.submitForm();
              return false;
            }}
            style={{ minHeight: 'calc(100vh - 98px)' }}
            noValidate
          >
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
              sx={{ minHeight: 'calc(100vh - 98px)' }}
            >
              <Box>
                {linkedinType !== 'linkedinViewProfile' ? (
                  <Box>
                    {!event?.id || true ? (
                      <>
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <Typography variant="body2">Template</Typography>
                          <Form.Field.AutoComplete
                            options={[template]}
                            fullWidth
                            name="templates"
                            placeholder="Select Existing Template"
                            onChange={(val) => {
                              if (val) {
                                setTemplate(val);
                                setDescription(val.content);
                              }
                            }}
                            className="inline-form-select"
                            remoteMethod={(val) => {
                              return fetchTemplatesDetails(val);
                            }}
                            groupBy={(option) => option?.accessType || ''}
                            getOptionLabel={(option) => option.name || ''}
                            optLabel="name"
                            optValue="id"
                            loading={fetchingTemplates}
                            disabled={values.saveAsNew}
                          />
                        </Box>
                      </>
                    ) : null}

                    {values.saveAsNew === true ? (
                      <>
                        <Form.Field.Input
                          fullWidth
                          variant="outlined"
                          name="name"
                          label="Template name"
                        />
                        <Form.Field.Select
                          options={templateCategoryList}
                          fullWidth
                          variant="outlined"
                          name="category"
                          label="Template Category"
                        />
                      </>
                    ) : null}

                    {linkedinType === 'linkedinMail' ? (
                      <Form.Field.Input
                        fullWidth
                        variant="outlined"
                        name="subject"
                        label="Template Subject"
                        showPicker={true}
                        options={replacementVariables}
                        optValue="value"
                        optLabel="text"
                        emojiPicker={true}
                      />
                    ) : null}

                    <Spacer x={2} y={2} />

                    {charLimit[linkedinType] ? (
                      <Form.Field.TextEditor
                        {...editorProps}
                        characterLimit={charLimit[linkedinType]}
                        onChange={descriptionOnChange}
                        pasteAsText={true}
                      />
                    ) : null}
                  </Box>
                ) : (
                  <Box>
                    <Alert severity="info" variant="standard">
                      This step allows you to view a contacts LinkedIn profile so that you may
                      update further steps of the sequence with necessary details
                    </Alert>
                    <Spacer x={4} y={4} />

                    {linkedinType !== 'linkedinViewProfile' && (
                      <Form.Field.TextEditor {...editorProps} />
                    )}
                  </Box>
                )}

                <Box display="flex" alignItems="center" mt={2}>
                  <Box ml={2} mr={-2} mt={-1.5}>
                    <Form.Field.Switch name="isAutoClose" label={''} />
                  </Box>
                  <Typography variant="body2" color="textSecondary">
                    Automatically mark this step as complete if not completed in <br />
                    <Popover
                      trigger="click"
                      width={240}
                      Child={
                        <Typography
                          color={values.isAutoClose ? 'secondary' : 'textSecondary'}
                          variant="body2"
                          sx={{ display: 'inline' }}
                        >
                          {values?.autoCloseDuration && values?.autoCloseDuration > 0
                            ? taskAutoCloseOptions.filter(
                                (item) => item.value === values?.autoCloseDuration,
                              )[0]?.label
                            : 'Select Days'}
                        </Typography>
                      }
                      isPadding={true}
                      ref={popoverRef}
                      disabled={!values.isAutoClose}
                    >
                      <Form.Field.Select
                        options={taskAutoCloseOptions}
                        fullWidth={true}
                        variant="outlined"
                        name="autoCloseDuration"
                        optLabel="label"
                        optValue="value"
                        showNone={false}
                        onChange={(val) => {
                          popoverRef.current.close();
                        }}
                      />
                    </Popover>
                    &nbsp;after the due date.
                  </Typography>
                </Box>
                {formProps?.errors?.autoCloseDuration &&
                  formProps?.errors?.autoCloseDuration !== '' && (
                    <Box ml={6.5} mt={1}>
                      <FormHelperText error={true}>
                        {formProps?.errors?.autoCloseDuration}
                      </FormHelperText>
                    </Box>
                  )}
                <Spacer x={2} y={2} />
                <Alert severity="info" style={{ visibility: 'hidden' }}>
                  To ensure that this task, if not complete, does not stop your sequence from
                  progressing, enable the above setting.
                </Alert>
              </Box>

              <Box mt={2} display="flex" justifyContent="space-between" width="100%">
                <Box>
                  {!event?.id ? (
                    <Form.Field.Checkbox
                      name="saveAsNew"
                      label="Save as new template"
                      onChange={(val) => {
                        if (val === true) {
                          formProps.setFieldValue(
                            'name',
                            `${template && template?.name ? template.name + ' copy' : ''} `,
                          );
                        }
                      }}
                    />
                  ) : null}
                </Box>

                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="outlined"
                    color="secondary"
                    disabled={loading}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Spacer basis={2} />
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    loading={loading || fetchingTemplates}
                    disabled={loading}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
};

export default LinkedInForm;
