import React, { useState, useEffect } from 'react';
import useStyles from './style';
import { Grid } from '@mui/material';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Legend,
} from 'recharts';
import { da } from 'date-fns/locale';
import { useSelector } from 'react-redux';
const dataSample = [
  { name: '1', uv: 4, pv: 4, amt: 1 },
  { name: '2', uv: 2, pv: 2, amt: 2 },
  { name: '3', uv: 1, pv: 1, amt: 3 },
  { name: '4', uv: 2.78, pv: 4, amt: 2 },
  { name: '5', uv: 1.89, pv: 5, amt: 3 },
  { name: '6', uv: 4, pv: 1, amt: 2 },
  { name: '7', uv: 3.5, pv: 2.5, amt: 1 },
  { name: '8', uv: 4, pv: 4, amt: 2 },
  { name: '9', uv: 1, pv: 4, amt: 3 },
  { name: '10', uv: 3, pv: 3, amt: 1 },
  { name: '11', uv: 2, pv: 2, amt: 2 },
  { name: '12', uv: 4, pv: 2, amt: 3 },
  { name: '13', uv: 2, pv: 4, amt: 1 },
  { name: '14', uv: 3.5, pv: 3.5, amt: 2 },
  { name: '15', uv: 1.5, pv: 1.5, amt: 3 },
  { name: '16', uv: 4.5, pv: 2.5, amt: 1 },
  { name: '17', uv: 2.5, pv: 4.5, amt: 2 },
  { name: '18', uv: 1.5, pv: 3.5, amt: 3 },
  { name: '19', uv: 4, pv: 2, amt: 2 },
  { name: '20', uv: 3, pv: 4, amt: 1 },
];
const CustomLabel = () => (
  <div style={{ backgroundColor: '#00FFEE', padding: '5px', borderRadius: '5px' }}>
    <span style={{ color: '#fff', fontSize: '10px' }}>Minimum performance target</span>
  </div>
);
const calculateDaysLeft = (endDate) => {
  const endDateTime = new Date(endDate).getTime();
  const currentDateTime = new Date().getTime();

  if (endDateTime < currentDateTime) {
    return 0;
  }

  const timeDiff = endDateTime - currentDateTime;
  const daysLeft = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  return daysLeft;
};

// You can continue the pattern for more data points to create more "A" shapes.

const yAxisData = [1, 2, 3, 4, 5];
const colors = ['#F53893', '#FFE766', '#66E6FF', '#8AE58A', '#CC99FF'];

export default function ProgressGraph({ data, competition, reportDatafromlive, reportData }) {
  const userAccessLevel = useSelector((state) => state.auth.user);
  const loggedInUserEmail = userAccessLevel?.email;

  const classes = useStyles();
  const [name, setName] = React.useState({});

  const [dynamicLineX, setDynamicLineX] = useState(competition.minimumRoleplays || 4.5);
  const [graphData, setGraphData] = useState([]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      if (reportDatafromlive) {
        const roleplayNumber = payload[0].payload.name; // Assuming name is the roleplay number
        const scoreValue = payload[0].value;

        return (
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div className={classes.customtooltip1}>
                <p className={classes.customtooltippara1}>Roleplay-{roleplayNumber}</p>
                <p className={classes.customtooltippara1}>Score-{scoreValue}</p>
              </div>
            </Grid>
          </Grid>
        );
      } else {
        const newPayload = payload[0];
        const secondTooltip = payload[1] ? payload[1] : null;
        const ThirdTooltip = payload[2] ? payload[2] : null;

        return (
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div className={classes.customtooltip}>
                <p className={classes.customRole_play}>Role-play {label} scores</p>
                <p className={classes.customtooltippara}>
                  {name[newPayload.name]} - {newPayload.value}
                </p>
                {secondTooltip ? (
                  <p className={classes.customtooltippara}>
                    {name[secondTooltip.name]} - {secondTooltip.value}
                  </p>
                ) : null}
                {ThirdTooltip ? (
                  <p className={classes.customtooltippara}>
                    {name[ThirdTooltip.name]} - {ThirdTooltip.value}
                  </p>
                ) : null}
              </div>
            </Grid>
          </Grid>
        );
      }
    }
    return <></>;
  };

  const createData = () => {
    let dataArray = [];
    let nameArray = {};

    const filteredData =
      reportDatafromlive && reportDatafromlive.length
        ? reportDatafromlive.filter((item) => item.email === loggedInUserEmail)
        : null;

    const sourceData = filteredData || data;

    // Ensure that sourceData is an array
    if (!Array.isArray(sourceData)) {
      // Handle the case where sourceData is not an array
      console.error('Invalid sourceData:', sourceData);
      return;
    }

    // Find the maximum number of roleplays within the competition across all users
    const maxRoleplaysWithinCompetition = Math.max(
      ...sourceData.map(
        (user) => user.rolePlays.filter((rp) => rp.isPlayedInWithInCompetition).length,
      ),
    );

    // Populate dataArray with entries up to the maximum number of roleplays within the competition
    for (let i = 0; i < maxRoleplaysWithinCompetition; i++) {
      dataArray[i] = { name: i + 1 };
    }

    if (sourceData.length) {
      sourceData.forEach((user) => {
        nameArray[user.id] = user.name;
        const userRolePlaysWithinCompetition = user.rolePlays.filter(
          (rp) => rp.isPlayedInWithInCompetition,
        );
        userRolePlaysWithinCompetition.forEach((roleplay, index) => {
          if (!dataArray[index]) {
            dataArray[index] = { name: index + 1 };
          }
          dataArray[index] = {
            ...dataArray[index],
            [`${user.id}`]: roleplay.score,
          };
        });
      });
    }
    setName(nameArray);
    setGraphData(dataArray);
  };

  useEffect(() => {
    createData();
  }, [data, reportDatafromlive, reportData]);
  const renderColorfulLegendText = (value, entry) => {
    const { color } = entry;

    return name[value] ? <span style={{ color }}>{name[value]}</span> : '';
  };
  return (
    <div>
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: '-20px',
          }}
        >
          <span className={classes.scorespan}>Score</span>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={graphData}>
              <XAxis
                dataKey="name"
                tickLine={{ stroke: 'none' }}
                tick={{ fill: '#8CA7BE', textAnchor: 'end', dy: 15, fontSize: '10' }}
                axisLine={{ stroke: 'none' }}
              />

              <YAxis
                domain={[1, 5]}
                ticks={yAxisData}
                tickLine={{ stroke: 'none' }}
                axisLine={{ stroke: 'none' }}
                tick={{ fill: '#8CA7BE', textAnchor: 'end', dx: -20 }}
                orientation="left"
              />
              <CartesianGrid strokeDasharray="0 0" stroke="#1B3C59" vertical={false} />
              <XAxis dataKey="name" />
              <YAxis />

              {graphData?.length ? (
                <Tooltip dot={false} position="top" content={<CustomTooltip />} />
              ) : null}
              {!reportDatafromlive && <Legend formatter={renderColorfulLegendText} />}

              <ReferenceLine
                y={dynamicLineX}
                stroke="#00FFEE"
                strokeDasharray="5 5"
                label={{
                  position: 'top',
                  value: 'Minimum performance target',
                  fill: '#00FFEE',
                  padding: 5,
                  borderRadius: 5,
                  dy: -10,
                  dx: 185,

                  style: {
                    fontSize: '10px',
                    zIndex: 10000,
                  },
                }}
              />
              {/* <Line
                // key={index}
                type="monotone"
                dataKey={`${data[0]?.id}`}
                stroke={0 === 0 ? '#F53893' : '#336596'}
                strokeWidth={2}
                dot={false}
              /> */}
              {/*                    stroke={colors[index] ? colors[index] : '#336596'}*/}
              {graphData?.length &&
                Object.keys(graphData[0]).map((key, index) => {
                  // Skip the 'name' property, which is used for the x-axis
                  if (key !== 'name') {
                    return (
                      <Line
                        key={index}
                        type="monotone"
                        dataKey={key}
                        stroke={colors[index] ? colors[index] : '#336596'}
                        strokeWidth={2}
                        dot={false}
                        connectNulls={!reportDatafromlive}
                      />
                    );
                  }
                  return null;
                })}
            </LineChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item xs={12} className={classes.roleplaygrid}>
          <p className={classes.scorespan2}>Number of roleplays</p>
        </Grid>
      </Grid>
    </div>
  );
}
