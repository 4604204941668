import { all, fork, put, call, takeEvery, takeLatest, select, delay } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import * as enrolmentActions from '../actions/enrolmentActions';
import { fetchSequenceEnrollment, setContact } from 'src/modules/contacts/actions/contactActions';
import { getIntegrations } from 'src/modules/app/actions/appSelector';
import * as sequenceTypes from '../actions/sequenceTypes';
import * as sequenceApi from '../api/sequenceApi';
import { fetchTemplateDetails, saveTemplate } from 'src/modules/admin/api/adminApi';
import { updateTask } from 'src/modules/tasks/api/taskApis';
import { populateTemplateId } from 'src/modules/app/api/appApis';
import {
  bulkEnrollments,
  fetchContactDetails,
  getEnrollment,
} from 'src/modules/contacts/api/contactApis';
import toast from 'src/utils/toast';
import { fetchEnrolmentDrafts } from 'src/modules/tasks/actions/taskActions';

export const getEnrolments = (state) => state.enrolment.enrolments;
export const getActiveEnrolmentId = (state) => state.enrolment.activeEnrolment;
export const getUser = (state) => state.auth.user;
export const getSteps = (state) => state.enrolment.steps;
export const getContact = (state) => state.contacts.contact;
export const getEnrolmentDrafts = (state) => state.tasks.enrolmentDrafts;

function* enqueueSequencePersonalize({ enrolments, index }) {
  try {
    if (!Object.keys(enrolments || {})?.length) {
      const enrolmentDrafts = yield select(getEnrolmentDrafts);
      const { filters, paging } = enrolmentDrafts;
      yield put(fetchEnrolmentDrafts(paging, filters));
      return yield put(enrolmentActions.clearPersonalizeSequence());
    }
    const activeEnrolment = Object.keys(enrolments)[index];
    yield put(enrolmentActions.setActiveSeqPersonalize(activeEnrolment));
  } catch (error) {}
}

function* fetchEnqueueContact({ contactId }) {
  try {
    const contact = yield call(fetchContactDetails, contactId);
    yield put(enrolmentActions.setEnqueueContact(contact.contact));
  } catch (error) {
    yield put(enrolmentActions.setEnqueueContact({}));
  }
}

function* setActiveSequence({ enrolmentId }) {
  try {
    yield put(enrolmentActions.setFetchErrorLoading(true));
    const enrolmentData = yield call(getEnrollment, enrolmentId);
    yield put(enrolmentActions.setEnrolmentData(enrolmentData?.enrollment || {}));
    yield put(enrolmentActions.setFetchErrorLoading(false));
    const enrolment = enrolmentData?.enrollment;
    const contact = Object.keys(enrolment?.contact || {})?.length
      ? enrolment?.contact
      : yield select((state) => state.contacts.contact);
    if (contact?.id) yield put(fetchSequenceEnrollment(contact?.id));
    const sequenceId = enrolment?.seq?.id || enrolment?.sequence?.id;
    // contact detail is not coming properly in enrollment so calling the fetch contact api
    const updatedContact = yield call(fetchContactDetails, contact?.id);
    yield put(setContact(updatedContact?.contact || contact));
    yield put(enrolmentActions.fetchEnrolmentErrors(contact?.id, sequenceId, enrolmentId, true));
    let tasks = enrolment?.tasks || [];
    yield put(enrolmentActions.fetchPersonalizeSteps(tasks));
  } catch (error) {}
}

function* enrolAllContacts({ enrolments }) {
  try {
    yield put(enrolmentActions.setFetchErrorLoading(true));
    yield call(bulkEnrollments, enrolments);
    yield put(enrolmentActions.enrollmentSuccess(true));
    yield put(enrolmentActions.setFetchErrorLoading(false));
  } catch (error) {
    yield put(enrolmentActions.setFetchErrorLoading(false));
  }
}

function* fetchTemplate(task) {
  try {
    let user = yield select(getUser);
    user = {
      ...user,
      name: `${user.fname} ${user.lname} (${user.email})`,
    };
    if (task?.template) {
      const response = yield call(fetchTemplateDetails, task.template);
      return {
        ...task,
        template: {
          ...response.template,
          contentOriginal: response.template?.content || '',
        },
      };
    } else return task;
  } catch (err) {
    return task;
  }
}

function* fetchPersonalizeSteps({ steps }) {
  try {
    const stepsNew = yield all(steps.map((task) => call(fetchTemplate, task)));
    const tasks = Object.assign({}, ...stepsNew.map((task) => ({ [task.id]: task })));
    yield put(enrolmentActions.setPersonalizeSteps(tasks));
  } catch (error) {}
}

function* submitTemplate(data = {}, taskId) {
  try {
    if (typeof data.templateId === 'string') data.sourceTemplate = data.templateId;
    else if (data.templateId?.id) data.sourceTemplate = data.templateId?.id;
    const { taskType, templateId, ...rest } = data;
    const isGeneric = (taskType) =>
      taskType !== 'sms' && taskType !== 'call' && taskType !== 'general';

    // let sender =
    //   data?.sender && data?.sender?.id && data?.sender?.id !== '' ? data?.sender?.id : undefined;

    const response = yield call(saveTemplate, isGeneric(taskType) ? rest : data);
    const newTemplateId = response.template.id;
    yield call(updateTask, taskId, {
      description: data?.content || '',
      template: newTemplateId,
      // ...(sender &&
      //   sender !== '' && {
      //     sender,
      //   }),
    });
    return true;
  } catch (error) {
    return false;
  }
}

function* navigateNextOrClose() {
  try {
    const enrolments = yield select(getEnrolments);
    const activeEnrolment = yield select(getActiveEnrolmentId);
    const enrolmentIds = Object.keys(enrolments);

    if (enrolmentIds && enrolmentIds.length) {
      const currentIndex = enrolmentIds.indexOf(activeEnrolment);
      if (currentIndex + 1 === enrolmentIds.length) {
        yield put(enrolmentActions.enrollmentSuccess(true));
      } else {
        const nextId = enrolmentIds[currentIndex + 1];
        yield put(enrolmentActions.setActiveSeqPersonalize(nextId));
      }
    }
    return true;
  } catch (error) {
    return false;
  }
}

function* submitPersonalizeSequence({ data, resume, resolve, reject }) {
  try {
    const contact = yield select(getContact);
    const selectedEmail =
      contact.emails && contact.emails.length
        ? contact.emails.find((item) => item.id === data.selectedEmail)?.email || ''
        : '';
    const enrolmentId = yield select(getActiveEnrolmentId);
    const sequenceEnrolId = yield select((state) => state.contacts.sequenceEnrollment);
    const activeEnrolment = enrolmentId ? enrolmentId : sequenceEnrolId?.[0]?.id;
    delete data.selectedEmail;
    const taskIds = Object.keys(data);

    const res = yield call(sequenceApi.updateEnrolment, activeEnrolment, {
      selectedEmailId: selectedEmail,
      ...(resume === true && { status: 'inProgress' }),
    });
    if (taskIds && taskIds.length)
      yield all(taskIds.map((taskId) => call(submitTemplate, data?.[taskId], taskId)));

    toast.success('Contact enrolled successfully!', 'tl');
    yield call(navigateNextOrClose);
    resolve(res);
  } catch (error) {
    toast.error('An error ocurred while updating sequence! Please try again.');
    reject(false);
  }
}

function* fetchPopulateEnqueueTemplate({
  taskId,
  contactId,
  templateId,
  subject,
  content,
  resolve,
  reject,
}) {
  try {
    const contact = yield select(getContact);
    const user = yield select(getUser);
    let payload = {
      contact: contact.id,
      sender: user.id,
      templateId: templateId,
      ...(subject &&
        subject !== '' && {
          subject,
        }),
      // ...(content &&
      //   content !== '' && {
      //     templateBody: content,
      //   }),
      templateBody: content && content !== '' ? content : '',
    };

    const data = yield call(populateTemplateId, payload);

    yield put(
      enrolmentActions.setPopulateEnqueueTemplate(
        contact.id,
        taskId,
        data.subject,
        data.content,
        data.errors,
      ),
    );
    resolve(true);
  } catch (error) {
    reject(false);
  }
}

function* fetchEnrolmentErrors({
  contactId,
  sequenceId,
  enrolmentId,
  dontFetchEnrollment = false,
}) {
  try {
    if (!dontFetchEnrollment) {
      const enrolmentData = yield call(getEnrollment, enrolmentId);
      yield put(enrolmentActions.setEnrolmentData(enrolmentData?.enrollment || {}));
    }
    const response = yield call(sequenceApi.getEnrolmentErrors, contactId, sequenceId, enrolmentId);
    yield put(enrolmentActions.setEnrolmentErrors(contactId, response?.errors || {}));
    return response;
  } catch (error) {
    yield put(enrolmentActions.setEnrolmentErrors({}));
  }
}

function* setPersonalizeStep({ taskId, data }) {
  try {
    const enrolments = yield select(getEnrolments);
    const enrolmentId = yield select(getActiveEnrolmentId);

    yield call(updateTask, taskId, {
      template: data.template.id,
    });

    const enrolment = enrolments[enrolmentId];
    const contactId = enrolment.contact.id;
    const sequenceId = enrolment?.seq?.id || enrolment?.sequence?.id;
    yield put(enrolmentActions.fetchEnrolmentErrors(contactId, sequenceId, enrolmentId));
  } catch (error) {}
}

function* reEnrolment({ id, data, resolve = () => {}, reject = () => {} }) {
  try {
    const response = yield call(sequenceApi.reEnrolment, id, data);
    // yield put(replace('/contact'));
    if (response?.error && response?.error !== '') {
      toast.error(response.error);
      resolve(response);
    }
    toast.success('ReEnrolment successful!');

    resolve(response);
  } catch (error) {
    reject(error);
  }
}

export function* watchSagas() {
  yield takeLatest(sequenceTypes.ENQUEUE_SEQUENCE_PERSONALIZE, enqueueSequencePersonalize);
  yield takeLatest(sequenceTypes.FETCH_ENQUEUE_CONTACT, fetchEnqueueContact);
  yield takeLatest(sequenceTypes.FETCH_ENROLMENT_ERRORS, fetchEnrolmentErrors);
  yield takeLatest(sequenceTypes.SET_RE_ENROLMENT_ERROR, reEnrolment);
  yield takeLatest(sequenceTypes.FETCH_PERSONALIZE_STEPS, fetchPersonalizeSteps);
  yield takeLatest(sequenceTypes.SET_ACTIVE_SEQUENCE_PERSONALIZE, setActiveSequence);
  yield takeEvery(sequenceTypes.FETCH_POPULATE_ENQUEUE_TEMPLATE, fetchPopulateEnqueueTemplate);
  yield takeEvery(sequenceTypes.SUBMIT_PERSONALIZE_SEQUENCE, submitPersonalizeSequence);
  yield takeEvery(sequenceTypes.SET_PERSONALIZE_STEP, setPersonalizeStep);
  yield takeEvery(sequenceTypes.ENROLL_ALL_CONTACTS, enrolAllContacts);
}

export default function* runSagas() {
  yield all([fork(watchSagas)]);
}
