import React from 'react';
import { Card, CardContent, Skeleton, Grid, Stack, Box, Typography } from '@mui/material';
import { Spacer } from 'src/components/shared';

function ReportSkeleton() {
  const renderCard = () => {
    return (
      <Card>
        <CardContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Skeleton animation="wave" height={20} width={'60%'} />
            <Stack direction="row" gap={1}>
              <Skeleton animation="wave" height={24} width={24} />
              <Skeleton animation="wave" height={24} width={24} />
            </Stack>
          </Stack>

          <Stack
            direction="row"
            gap={1}
            mt={1}
            alignItems="center"
            sx={{ background: '#0C1721', padding: '4px 8px', borderRadius: '4px' }}
          >
            <Skeleton animation="wave" height={20} width={'60%'} />
          </Stack>
          <Stack direction="row" gap={2} mt={2}>
            <Skeleton animation="wave" height={100} width={6} sx={{ transform: 'scale(1,1)' }} />
            <Skeleton animation="wave" height={100} width={6} sx={{ transform: 'scale(1,1)' }} />
            <Skeleton animation="wave" height={100} width={6} sx={{ transform: 'scale(1,1)' }} />
            <Skeleton animation="wave" height={100} width={6} sx={{ transform: 'scale(1,1)' }} />
            <Skeleton animation="wave" height={100} width={6} sx={{ transform: 'scale(1,1)' }} />
            <Skeleton animation="wave" height={100} width={6} sx={{ transform: 'scale(1,1)' }} />
            <Skeleton animation="wave" height={100} width={6} sx={{ transform: 'scale(1,1)' }} />
            <Skeleton animation="wave" height={100} width={6} sx={{ transform: 'scale(1,1)' }} />
            <Skeleton animation="wave" height={100} width={6} sx={{ transform: 'scale(1,1)' }} />
            <Skeleton animation="wave" height={100} width={6} sx={{ transform: 'scale(1,1)' }} />
          </Stack>
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={1} md={6} lg={4}>
          {renderCard()}
        </Grid>
        <Grid item sm={1} md={6} lg={4}>
          {renderCard()}
        </Grid>
        <Grid item sm={1} md={6} lg={4}>
          {renderCard()}
        </Grid>
      </Grid>
    </>
  );
}

export default ReportSkeleton;
