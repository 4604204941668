import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Button, Spacer } from 'src/components/shared';
import { useStyles } from '../../containers/integrations/styles';
import AddIcon from '@mui/icons-material/Add';
import TeamsGrid from '../../components/Teams/TeamsGrid';
import { Drawer } from 'src/components/shared';
import TeamForm from '../../components/Teams/TeamForm';
import { fetchAllTeams } from '../../api/adminApi';

function TeamsView({ teams, users, total, userTotal, ...props }) {
  const classes = useStyles();
  const currentTab = useRef({});
  const { filters, gridParams } = teams;
  const [drawerTitle, setDrawerTitle] = useState('');
  const [editOptions, setEditOptions] = useState({});
  const [drawerOpen, setDrawerOpen] = useState({ edit: null, open: false });

  const toggleEditTeam = (value, tableMeta, updateValue, selectedContact = 0) => {
    setEditOptions({
      id: tableMeta.rowData[0],
      drawerTitle: 'Edit Teams',
      status: tableMeta.rowData[6],
      toggleOpen: true,
    });
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Drawer
          title={drawerOpen.edit ? 'Edit Team' : 'Create Team'}
          open={drawerOpen.open}
          onClose={() => {
            setDrawerOpen({ edit: null, open: false });
          }}
        >
          <TeamForm
            teamLead={users}
            editUser={drawerOpen.edit}
            drawerToggle={setDrawerOpen}
            loading={props.loading}
            total={total}
            userTotal={userTotal}
            {...props}
          />
        </Drawer>
      </Box>
      <TeamsGrid
        drawerToggle={setDrawerOpen}
        teams={teams}
        teamBulkUpdate={props.teamBulkUpdate}
        updateTeamStatus={props.updateTeamStatus}
        fetchTeams={props.fetchTeams}
        globals={props.globals}
        loading={props.loading}
      />
    </>
  );
}

export default TeamsView;
