export const contactColumns = [
  {
    id: '644a658ce766173654309351',
    status: 'active',
    type: 'default',
    lookupType: 'options',
    label: 'Profile Image',
    fieldName: 'imageUrl',
    fieldType: 'text',
    gridParams: {
      contacts: {
        sort: false,
      },
    },
  },
  {
    id: '644a6594e766173654309373',
    status: 'active',
    type: 'default',
    lookupType: 'options',
    label: 'Contact name',
    fieldName: 'name',
    fieldType: 'formula',
    gridParams: {
      contacts: {
        setCellHeaderProps: (value) => ({
          style: { minWidth: 150, width: 150 },
        }),
        setCellProps: () => ({
          style: { minWidth: 150, width: 150 },
        }),
      },
    },
  },
  {
    id: '644a6588e766173654309341',
    status: 'active',
    type: 'default',
    lookupType: 'options',
    label: 'Job title',
    fieldName: 'jobTitle',
    fieldType: 'text',
  },
  {
    id: '644a6588e766173654309342',
    status: 'active',
    type: 'default',
    lookupType: 'options',
    label: 'Employer',
    fieldName: 'account',
    fieldType: 'lookup',
  },
  {
    id: '644a658ce766173654309353',
    status: 'active',
    type: 'default',
    lookupType: 'options',
    label: 'Sequences',
    fieldName: 'sequences',
    fieldType: 'formula',
    gridParams: {
      contacts: {
        setCellHeaderProps: (value) => ({
          style: { minWidth: 40, width: 40 },
        }),
        setCellProps: () => ({
          style: { minWidth: 40, width: 40 },
        }),
      },
    },
  },
  {
    id: '644a658ce766173654309354',
    status: 'active',
    type: 'default',
    lookupType: 'options',
    label: 'Opens',
    fieldName: 'opens',
    fieldType: 'formula',
    gridParams: {
      contacts: {
        setCellHeaderProps: (value) => ({
          style: { minWidth: 40, width: 40 },
        }),
        setCellProps: () => ({
          style: { minWidth: 40, width: 40 },
        }),
      },
    },
  },
  {
    id: '644a658ce766173654309355',
    status: 'active',
    type: 'default',
    lookupType: 'options',
    label: 'Replies',
    fieldName: 'replies',
    fieldType: 'formula',
  },
  {
    id: '644a658ce766173654309356',
    status: 'active',
    type: 'default',
    lookupType: 'options',
    label: 'Status',
    fieldName: 'status',
    fieldType: 'formula',
  },
];
