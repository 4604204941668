import styled from 'styled-components';
import { Button } from 'src/components/shared';

export const SubmitButton = styled(Button).attrs({
  variant: 'contained',
  color: 'secondary',
  fullWidth: true,
  size: 'large',
  type: 'submit',
})`
  position: absolute;
  bottom: 16px;
  left: 0;
  margin: 0px 16px;
  width: calc(100% - 32px);
`;

export const SubmitButtonWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  left: 0;
  margin: 0px 16px;
  width: calc(100% - 48px);
  display: flex;
  background: #ffffff;
  z-index: 100;
`;
