import * as types from '../actions/taskTypes';
import { RESET_STORE } from 'src/modules/app/actions/appTypes';

const initialState = {
  loading: {
    tasks: false,
    task: false,
    outbox: false,
    taskErrors: false,
    taskReplies: false,
    enrolmentDrafts: false,
    inbox: false,
    sent: false,
  },
  tasks: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    sort: {},
    filters: {},
    data: [],
    columns: [],
    columnsOrder: [],
  },
  outbox: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    sort: {},
    filters: {},
    data: [],
    columns: [],
    columnsOrder: [],
  },
  taskErrors: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    data: [],
    columns: [],
    columnsOrder: [],
  },
  currentTab: 'all',
  task: {},
  taskReplies: {
    taskId: '',
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    data: [],
  },
  taskError: {},
  tasksQueue: [],
  enrolmentDrafts: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    sort: {},
    filters: {},
    data: [],
  },
  inbox: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    sort: {},
    filters: {},
    data: [],
  },
  sent: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    sort: {},
    filters: {},
    data: [],
  },
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SENT: {
      return {
        ...state,
        loading: {
          ...state.loading,
          sent: true,
        },
        sent: {
          ...state.sent,
          paging: {
            ...state.sent.paging,
            ...action.paging,
          },
          data: [],
          filters: {
            ...state.sent.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };
    }

    case types.SET_SENT:
      return {
        ...state,
        loading: {
          ...state.loading,
          sent: false,
        },
        sent: {
          ...state.sent,
          data: action.data,
          paging: {
            ...state.sent.paging,
            count: action.total.value,
          },
        },
      };

    case types.FETCH_INBOX: {
      return {
        ...state,
        loading: {
          ...state.loading,
          inbox: true,
        },
        inbox: {
          ...state.inbox,
          paging: {
            ...state.inbox.paging,
            ...action.paging,
          },
          data: [],
          filters: {
            ...state.inbox.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };
    }

    case types.SET_INBOX:
      return {
        ...state,
        loading: {
          ...state.loading,
          inbox: false,
        },
        inbox: {
          ...state.inbox,
          data: action.data,
          paging: {
            ...state.inbox.paging,
            count: action.total.value,
          },
        },
      };

    case types.FETCH_ENROLMENT_DRAFTS: {
      return {
        ...state,
        loading: {
          ...state.loading,
          enrolmentDrafts: true,
        },
        enrolmentDrafts: {
          ...state.enrolmentDrafts,
          paging: {
            ...state.enrolmentDrafts.paging,
            ...action.paging,
          },
          data: [],
          filters: {
            ...state.enrolmentDrafts.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };
    }

    case types.SET_ENROLMENT_DRAFTS:
      return {
        ...state,
        loading: {
          ...state.loading,
          enrolmentDrafts: false,
        },
        enrolmentDrafts: {
          ...state.enrolmentDrafts,
          data: action.drafts,
          paging: {
            ...state.enrolmentDrafts.paging,
            count: action.total.value,
          },
        },
      };

    case types.SET_TASKS_QUEUE: {
      return {
        ...state,
        tasksQueue: action.ids,
      };
    }

    case types.SET_TASK_ERROR_DETAILS: {
      return {
        ...state,
        taskError: action.data,
      };
    }

    case types.FETCH_TASK_REPLIES:
      return {
        ...state,
        loading: {
          ...state.loading,
          taskReplies: true,
        },
        taskReplies: {
          ...state.taskReplies,
          taskId: action.taskId,
          paging: {
            ...state.taskReplies.paging,
            ...action.paging,
          },
          data: [],
          filters: {
            ...state.taskReplies.filters,
            ...action.filters,
          },
        },
      };

    case types.SET_TASK_REPLIES:
      return {
        ...state,
        loading: {
          ...state.loading,
          taskReplies: false,
        },
        taskReplies: {
          ...state.taskReplies,
          data: action.emailMessages,
          paging: {
            ...state.taskReplies.paging,
            count: action.total.value,
          },
        },
      };

    case types.CLEAR_TASK_REPLIES:
      return {
        ...state,
        loading: {
          ...state.loading,
          taskReplies: false,
        },
        taskReplies: initialState.taskReplies,
      };

    case types.SET_TASKS_TAB:
      return {
        ...state,
        currentTab: action.tab,
      };

    case types.FETCH_TASK:
      return {
        ...state,
        loading: {
          ...state.loading,
          task: true,
        },
        task: {},
      };

    case types.SET_TASK:
      return {
        ...state,
        loading: {
          ...state.loading,
          task: false,
        },
        task: action.task,
      };

    case types.FETCH_TASKS:
      return {
        ...state,
        loading: {
          ...state.loading,
          tasks: true,
        },
        tasks: {
          ...state.tasks,
          paging: {
            ...state.tasks.paging,
            ...action.paging,
          },
          data: [],
          filters: {
            ...state.tasks.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };

    case types.SET_TASKS:
      return {
        ...state,
        loading: {
          ...state.loading,
          tasks: false,
        },
        tasks: {
          ...state.tasks.tasks,
          filters: state.tasks?.filters,
          sort: state.tasks?.sort,
          data: action.tasks,
          paging: {
            ...state.tasks.paging,
            count: action.total.value,
          },
        },
      };

    case types.FETCH_TASK_ERRORS:
      return {
        ...state,
        loading: {
          ...state.loading,
          taskErrors: true,
        },
        taskErrors: {
          ...state.taskErrors,
          paging: {
            ...state.taskErrors.paging,
            ...action.paging,
          },
          data: [],
          filters: {
            ...state.taskErrors.filters,
            ...action.filters,
          },
        },
      };

    case types.FETCH_OUTBOX:
      return {
        ...state,
        loading: {
          ...state.loading,
          outbox: true,
        },
        outbox: {
          ...state.outbox,
          paging: {
            ...state.outbox.paging,
            ...action.paging,
          },
          data: [],
          filters: {
            ...state.outbox.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };

    case types.SET_OUTBOX:
      return {
        ...state,
        loading: {
          ...state.loading,
          outbox: false,
        },
        outbox: {
          ...state.outbox.outbox,
          filters: state.outbox?.filters,
          sort: state.outbox?.sort,
          data: action.outbox,
          paging: {
            ...state.outbox.paging,
            count: action.total.value,
          },
        },
      };

    case types.SET_TASK_ERRORS:
      return {
        ...state,
        loading: {
          ...state.loading,
          taskErrors: false,
        },
        taskErrors: {
          ...state.taskErrors,
          data: action.errors,
          paging: {
            ...state.taskErrors.paging,
            count: action.total.value,
          },
        },
      };

    case RESET_STORE:
      return initialState;

    default:
      return state;
  }
};
export default AppReducer;
