import React, { useEffect, useState } from 'react';
import useStyles from './style';
import { Grid, IconButton, LinearProgress, Stack, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';
import prizeIcon from '../../../../assets/icons/PrizeIcon.png';
import { calculateDaysLeftForRoleplay, calculatePercent, truncate } from '../../utils/helper';

const CompetitionProgress = ({ competition, report }) => {
  const [totalRolePlaysWithinComp, setTotalRolePlaysWithinComp] = useState('');
  const userAccessLevel = useSelector((state) => state.auth.user);
  const loggedInUser = userAccessLevel?.email;
  const filteredReport = report?.filter((item) => item.email === loggedInUser);

  useEffect(() => {
    const rolePlaysDoneByUser = filteredReport
      ?.map((item) => item?.rolePlays)
      ?.flat()
      ?.filter((rolePlay) => rolePlay?.isPlayedInWithInCompetition); // Filter for roleplays within competition

    const numberOfRolePlaysDoneByUser = rolePlaysDoneByUser?.length;
    setTotalRolePlaysWithinComp(numberOfRolePlaysDoneByUser);

    // ... (your existing code)
  }, [filteredReport]);
  const getDaysLeft = (row) => {
    if (!row.startDate || !row.endDate) {
      return { actualLeft: 0, actualPercent: 0 };
    }
    let startDate = moment(row.startDate);
    let today = moment();
    let endDate = moment(row.endDate);
    let totalDays = endDate.diff(startDate, 'days');
    let actualLeft = endDate.diff(today, 'days');
    actualLeft = actualLeft >= 0 ? actualLeft : 0;
    let actualPercent = (actualLeft / totalDays) * 100;
    return { actualLeft, actualPercent };
  };

  const classes = useStyles();
  const actualLeft = calculateDaysLeftForRoleplay(competition?.endDate);

  const CompetitionDescription = () => {
    return (
      <Grid container className={classes.descriptionTooltip}>
        <Grid item xl={3} lg={3} md={3} sm={3} mb={1}>
          <Typography fontWeight={600}>Name</Typography>
        </Grid>
        <Grid item xl={9} lg={9} md={9} sm={9} mb={1}>
          <Typography fontWeight={400}>{competition?.name}</Typography>
        </Grid>
        {competition?.instructions ? (
          <>
            <Grid item xl={3} lg={3} md={3} sm={3} mb={1}>
              <Typography fontWeight={600}>Instructions</Typography>
            </Grid>
            <Grid item xl={9} lg={9} md={9} sm={9} mb={1}>
              <Typography
                fontWeight={400}
                dangerouslySetInnerHTML={{
                  __html: truncate(competition?.instructions),
                }}
              />
            </Grid>
          </>
        ) : null}

        <Grid item xl={3} lg={3} md={3} sm={3} mb={1}>
          <Typography fontWeight={600}>End date</Typography>
        </Grid>
        <Grid item xl={9} lg={9} md={9} sm={9} mb={1}>
          {moment(competition?.endDate).format('Do MMMM YYYY')}
        </Grid>
      </Grid>
    );
  };

  return (
    <Stack
      direction="row"
      className={`${classes.progressContainer} ${
        getDaysLeft(competition).actualPercent > 50 && classes.progressContainer_green
      }`}
    >
      <Tooltip title={<CompetitionDescription />} classes={{ tooltip: classes.customTooltip }}>
        <IconButton className={classes.prizeIcon}>
          <img src={prizeIcon} />{' '}
        </IconButton>
      </Tooltip>
      <span className={classes.progressInfo}>
        {totalRolePlaysWithinComp || '0'}/{competition?.roleplays}
      </span>
      <LinearProgress variant="determinate" value={calculatePercent(competition)} />
      <span className={classes.progressInfo}>{actualLeft}</span>
    </Stack>
  );
};

export default CompetitionProgress;
