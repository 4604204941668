import React, { useEffect, useState, useMemo } from 'react';

import * as Yup from 'yup';
import { Grid, Box } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import { fetchUsersLookup, fetchTeamLookup } from 'src/modules/users/api/usersApi';
import { tenantPartnersLookup } from 'src/modules/reseller/api/resellerApis';
import { fetchSingleUser } from 'src/modules/users/api/usersApi';
import {
  isTenantApprovedPartner,
  isTenantApprovedReseller,
} from 'src/modules/app/actions/appSelector';
import { useSelector } from 'react-redux';
import { specialCharacterRegex } from 'src/config';

function SequenceForm({ sequenceAccessTypeList, sequenceEmailTypeList, user, sequence, ...props }) {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);

  const isReseller = useSelector((state) => isTenantApprovedReseller(state));
  const isPartner = useSelector((state) => isTenantApprovedPartner(state));
  const sequenceCategories = useSelector((state) => state.app.globals?.sequenceCategories);

  const categories = useMemo(() => {
    if (sequenceCategories && sequenceCategories.length) {
      if (isReseller == true && !isPartner) {
        return [{ value: 'reseller', label: 'Reseller' }, ...sequenceCategories];
      } else {
        return sequenceCategories;
      }
    } else {
      return [];
    }
  }, [sequenceCategories, isReseller]);

  useEffect(() => {
    if (sequence?.sharedWithUsers && sequence?.sharedWithUsers.length) {
      fetchDefUsers();
    }
    if (sequence?.sharedWithTeams && sequence?.sharedWithTeams.length) {
      fetchDefTeams();
    }
  }, [sequence]);

  const fetchDefTeams = async () => {
    setLoading(true);
    const promises = [];
    for (let i = 0; i < sequence?.sharedWithTeams.length; i++) {
      const tid = sequence?.sharedWithTeams[i];
      promises.push(fetchTeam(tid));
    }
    let response = await Promise.all(promises);
    setLoading(false);
    if (response && response.length) {
      const teams = response.map((team) => ({
        ...team.team,
        value: team.team?.name || '',
      }));
      setTeams(teams);
    }
  };
  const fetchDefUsers = async () => {
    setLoading(true);
    const promises = [];
    for (let i = 0; i < sequence?.sharedWithUsers.length; i++) {
      const uid = sequence?.sharedWithUsers[i];
      promises.push(fetchSingleUser(uid));
    }

    let response = await Promise.all(promises);
    setLoading(false);
    if (response && response.length) {
      const sharedUsers = response.map((user) => ({
        ...user.user,
        name: `${user.user.title} ${user.user.fname} ${user.user.lname}`,
      }));
      setUsers(sharedUsers);
    }
  };
  useEffect(() => {
    if (sequence) {
      const users = sequence.sharedWithUsers
        ? sequence.sharedWithUsers?.map((user) => ({
            id: user.id,
            name: user.fname,
          }))
        : [];
      setUsers(users);
    } else setUsers([{ ...user, name: `${user.fname} ${user.lname}` }]);
  }, [user, sequence]);

  const handleSubmit = async (values, form) => {
    setLoading(true);
    const data = JSON.parse(JSON.stringify(values));
    if (data?.partner) {
      if (Object.keys(data?.partner).length === 0 || data?.category !== 'reseller')
        delete data.partner; // comes as empty {}
    }

    data.partner = data?.partner && data?.partner?.id ? data?.partner.id : null;
    data.sharedWithTeams = data.sharedWithTeams.map((team) => team.id);
    data.sharedWithUsers = data.sharedWithUsers.map((team) => team.id);
    data.testBatch = 200;
    data.variancePercentage = 15;
    data.winnerPercentage = 75;
    data.allowRestart = false;
    data.trackClick = false;
    if (sequence) {
      await props.onSubmit(data, sequence.id);
    } else {
      await props.onSubmit(data);
    }
    setLoading(false);
  };

  return (
    <Form
      initialValues={{
        name: sequence?.name || '',
        description: sequence?.description || '',
        accessType: sequence?.accessType || 'private',
        audienceEmailType: sequence?.audienceEmailType || '',
        sharedWithUsers: users,
        sharedWithTeams: teams,
        status: 'unpublished',
        category: '',
        partner: {},
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Please enter sequence name.'),
        description: Yup.string().required('Please enter sequence description.'),
        audienceEmailType: Yup.string().required('Please select sequence type.'),
        accessType: Yup.string().required('Please select Sequence access.'),
        category: Yup.string().required('Please choose a category.'),
        partner: Yup.object().test('reseller', 'Please choose a Partner', function (item) {
          if (this.parent.category === 'reseller') {
            return item?.id && item?.id !== '';
          }
          return true;
        }),
        sharedWithUsers: Yup.array().test({
          message: () => 'Please choose at least one user.',
          test: (value, values) => {
            const accessType = values.parent.accessType;
            if (accessType === 'private') {
              return value.length > 0;
            }
            return true;
          },
        }),
        sharedWithTeams: Yup.array().test({
          message: () => 'Please choose at least one team.',
          test: (value, values) => {
            const accessType = values.parent.accessType;
            if (accessType === 'team') {
              return value.length > 0;
            }
            return true;
          },
        }),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      id="sequence-settings-form"
    >
      {({ values, ...formProps }) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formProps.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
              height="100%"
            >
              <Box width="100%">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Form.Field.Input
                      fullWidth
                      multiline
                      variant="outlined"
                      name="name"
                      label="Sequence name"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Form.Field.Select
                      options={sequenceEmailTypeList || []}
                      fullWidth
                      variant="outlined"
                      name="audienceEmailType"
                      label="Email type"
                      optLabel="label"
                      optValue="value"
                    />
                  </Grid>
                </Grid>

                <Form.Field.Input
                  fullWidth
                  multiline
                  variant="outlined"
                  name="description"
                  label="Sequence Description"
                  maxRows={4}
                />

                <Form.Field.Select
                  options={categories}
                  fullWidth
                  variant="outlined"
                  name="category"
                  label="Category"
                  optLabel="label"
                  optValue="value"
                />

                {values?.category === 'reseller' && isReseller && (
                  <Form.Field.AutoComplete
                    multiple={false}
                    options={[]}
                    fullWidth
                    variant="outlined"
                    name="partner"
                    label="Partner"
                    remoteMethod={(val) => {
                      return tenantPartnersLookup('', val);
                    }}
                    optLabel="name"
                    optValue="id"
                  />
                )}

                <Form.Field.Select
                  options={sequenceAccessTypeList || []}
                  fullWidth
                  variant="outlined"
                  name="accessType"
                  label="Sequence access"
                  optLabel="label"
                  optValue="value"
                  tip="Select who has access to this sequence."
                  onChange={(val) => {
                    switch (val) {
                      case 'private':
                        formProps.setFieldValue('sharedWithUsers', []);
                        break;
                      case 'team':
                        formProps.setFieldValue('sharedWithTeams', []);
                        break;
                      case 'organisation':
                      default:
                        formProps.setFieldValue('sharedWithUsers', []);
                        formProps.setFieldValue('sharedWithTeams', []);
                        break;
                    }
                  }}
                />

                {values.accessType === 'private' ? (
                  <Form.Field.AutoComplete
                    multiple={true}
                    options={users}
                    fullWidth
                    variant="outlined"
                    name="sharedWithUsers"
                    label="Select users"
                    remoteMethod={(val) => {
                      return fetchUsersLookup(val);
                    }}
                    checkboxes={true}
                    optLabel="name"
                    optValue="id"
                  />
                ) : null}

                {values.accessType === 'team' ? (
                  <Form.Field.AutoComplete
                    multiple={true}
                    options={teams}
                    fullWidth
                    variant="outlined"
                    name="sharedWithTeams"
                    label="Select Teams"
                    checkboxes={true}
                    remoteMethod={(val) => {
                      return fetchTeamLookup(val);
                    }}
                    optLabel="name"
                    optValue="id"
                  />
                ) : null}
              </Box>

              <Box display="flex" justifyContent="flex-end" alignItems="flex-end" width="100%">
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={loading}
                  onClick={() => {
                    props.onClose();
                  }}
                >
                  Cancel
                </Button>
                <Spacer basis={2} />
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={loading}
                  disabled={loading}
                >
                  {!sequence ? 'Save' : 'Update'}
                </Button>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}

export default SequenceForm;
