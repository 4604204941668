import React, { useState } from 'react';
import * as Yup from 'yup';
import { Box, Typography } from '@mui/material';
import { Form, Button, Spacer } from 'src/components/shared';
import { fetchContactsLookup } from 'src/modules/contacts/api/contactApis';

function AddContactForm({ panel, onClose, addContactToPanel }) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    await addContactToPanel(values.contacts);
    setLoading(false);
    onClose();
  };

  return (
    <Form
      initialValues={{
        name: panel.name,
        contacts: [],
      }}
      validationSchema={Yup.object().shape({})}
      onSubmit={handleSubmit}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            noValidate
            style={{ width: '100%' }}
          >
            <Form.Field.Input
              label="Stage"
              fullWidth
              name="name"
              variant="outlined"
              readOnly={true}
              disabled={true}
            />
            <Form.Field.AutoComplete
              multiple={false}
              id="free-solo-demo"
              options={[]}
              fullWidth
              showAvatar={false}
              variant="outlined"
              remoteMethod={(search) => {
                return fetchContactsLookup(search);
              }}
              name="contacts"
              label="Contacts"
              optLabel="name"
              optValue="id"
            />

            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Button variant="outlined" color="primary" onClick={onClose} disabled={loading}>
                Cancel
              </Button>
              <Spacer basis={2} />
              <Button type="submit" variant="contained" color="primary" loading={loading}>
                Add
              </Button>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}

export default AddContactForm;
