import React, { useEffect, useMemo } from 'react';
import { AppGrid } from 'src/components/App';
import { useDashboardStore } from '../../../utils/dashboardStore';
import { ButtonBase, Typography } from '@mui/material';

function SequenceGrid() {
  const { fetchUserStats, userStats, loading, filters } = useDashboardStore();
  const { data, paging } = userStats;

  const columns = useMemo(
    () => [
      {
        name: 'name',
        label: 'User Name',
        options: {
          sort: false,
          setCellHeaderProps: (value) => ({
            style: { width: 10 },
          }),
          setCellProps: () => ({
            style: { width: 10 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const row = data[dataIndex];
            return (
              <Typography color="secondary" variant="body2">
                {row?.fname || ''} {row?.lname || ''}
              </Typography>
            );
          },
        },
      },
      {
        name: 'prospectsEnrolled',
        label: 'Prospects Enrolled',
        options: {
          sort: false,
          setCellHeaderProps: (value) => ({
            style: { textAlign: 'center' },
          }),
          setCellProps: () => ({
            style: { textAlign: 'center' },
          }),
        },
      },
      {
        name: 'prospectsContacted',
        label: 'Enrolled Contacted',
        options: {
          sort: false,
          setCellHeaderProps: (value) => ({
            style: { textAlign: 'center' },
          }),
          setCellProps: () => ({
            style: { textAlign: 'center' },
          }),
        },
      },
      {
        name: 'prospectsOpened',
        label: 'Prospects Opened',
        options: {
          sort: false,
          setCellHeaderProps: (value) => ({
            style: { textAlign: 'center' },
          }),
          setCellProps: () => ({
            style: { textAlign: 'center' },
          }),
        },
      },
      {
        name: 'prospectsClicked',
        label: 'Prospects Clicked',
        options: {
          sort: false,
          setCellHeaderProps: (value) => ({
            style: { textAlign: 'center' },
          }),
          setCellProps: () => ({
            style: { textAlign: 'center' },
          }),
        },
      },
      {
        name: 'prospectsReplied',
        label: 'Prospects Replied',
        options: {
          sort: false,
          setCellHeaderProps: (value) => ({
            style: { textAlign: 'center' },
          }),
          setCellProps: () => ({
            style: { textAlign: 'center' },
          }),
        },
      },
      {
        name: 'prospectsInterested',
        label: 'Prospects Interested',
        options: {
          sort: false,
          setCellHeaderProps: (value) => ({
            style: { textAlign: 'center' },
          }),
          setCellProps: () => ({
            style: { textAlign: 'center' },
          }),
        },
      },
    ],
    [data],
  );

  useEffect(() => {
    fetchUserStats(paging, filters);
  }, [filters]);

  const handleTableChange = async ({ pageNo, perPage }) => {
    try {
      fetchUserStats({
        pageNo,
        perPage,
      });
    } catch (error) {}
  };

  return (
    <AppGrid
      columns={columns.map((col, index) => ({
        ...col,
        options: columns?.columns?.length
          ? { ...col.options, ...columns?.columns[index] }
          : col.options,
      }))}
      onTableChange={handleTableChange}
      data={data}
      loading={loading.userStats}
      options={{
        serverSide: true,
        pagination: true,
        page: paging.pageNo,
        rowsPerPage: paging.perPage,
        count: paging.count,
      }}
    />
  );
}

export default SequenceGrid;
