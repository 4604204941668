import { makeStyles } from '@mui/styles';
import { themeColors } from 'src/modules/lms/config';
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import { Typography } from '@mui/material';

export const useStyles = makeStyles(({ palette }) => ({
  root: {},
  heading: {
    fontWeight: '300',
    fontSize: '26px',
    color: themeColors.greyHeadingText,
  },
  lastSection: {
    paddingRight: '92px',
  },
  links: {
    color: themeColors.greyText,
    fontWeight: '300',
    fontSize: '12px',
  },
  pillarTitle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'inline-block',
    textDecoration: 'none',
    position: 'relative',
    width: '90%',
  },
  paragraph: {
    fontWeight: '400',
    fontSize: '18px',
    color: themeColors.greyParagraphText,
    lineHeight: '28px',
  },
  buttonSection: {
    background: themeColors.bg3,
    padding: '5px',
    borderRadius: '5px',
  },
  imageSection: {
    background: themeColors.bg3,
    padding: '14px',
    paddingTop: '15px',
    marginTop: '20px',
    borderRadius: '5px',
  },
  courseImg: {
    margin: '6px 14px 14px 14px',
  },
  courseImg2: {
    margin: '16px 14px 14px 14px',
  },
  title: {
    fontSize: '18px',
    fontWeight: '700',
    color: themeColors.yellow,
  },
  searchIcon: {
    color: 'white !important',
  },
  descriptionBox: {
    '& div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed,  figure, figcaption, footer, header, hgroup,  menu, nav, output, ruby, section, summary, time, mark, audio, video':
      {
        margin: '0',
        padding: '0',
        border: '0',
        fontSize: '100%',
        font: 'inherit',
        verticalAlign: 'baseline',
      },
    '& ul': {
      paddingLeft: '40px',
    },
  },
  Search: {
    marginBottom: '30px',
    color: '#FAFAFA !important',
    opacity: '0.6',
    borderRadius: '10px',
    background: '#193247 !important',
    ' & input': {
      color: '#FAFAFA !important',
      borderRadius: '10px',
      background: ' #193247 !important',
    },
    '& label': {
      color: '#FAFAFA !important',
    },
    ' &:focus': {
      background: 'transparent !important',
    },
    descriptionBox: {
      '& p': {
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
  },
}));

export const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  color: themeColors.greyText,
}));
export const StyledYellowHeader = styled(Typography)(({ theme }) => ({
  color: themeColors.yellow,
  fontWeight: 700,
  fontSize: 18,
}));
