import { Box, Typography, Stack, Card, CardContent } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import useStyles from './style';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Spacer } from 'src/components/shared';
import images from 'src/config/images';

const Container = styled(Stack)`
  height: 80px;
  background-color: #061726;
  border-radius: 4px;
`;

const Left = styled.div`
  flex: 1.2;
  align-items: flex-start;
  justify-content: center;
  margin-left: 16px;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 0.8;
  align-items: center;
  /* justify-content: flex-end; */
  justify-content: center;
`;

const CompImage = styled.img`
  max-width: 80%;
  /* border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; */
  border-radius: 4px;
`;

const WinningPrize = ({ competition }) => {
  const prizes = useSelector((state) => state?.app?.globals?.competitionSettings?.prizes);

  const selectedPrize = useMemo(() => {
    if (prizes && prizes.length && competition && competition.prize) {
      return prizes.filter((item) => item.prize === competition.prize)[0];
    }
    return undefined;
  }, [prizes, competition]);

  // useEffect(() => {
  //   PrizeSelection();
  // }, [competition]);

  if (!selectedPrize) {
    return null;
  }

  const getIcon = () => {
    switch (selectedPrize.prize) {
      case 'DinnerOnUs':
        return images.prizes.dinner;
        break;

      case 'BottleOfChampagne':
        return images.prizes.champagne;
        break;

      case 'EarlyFinishOnFriday':
        return images.prizes.earlyFinish;
        break;

      case 'BoosterKPIPoints':
        return images.prizes.boosterPoints;
        break;

      default:
        return images.prizes.amazon;
        break;
    }
  };

  const getText = () => {
    switch (selectedPrize.prize) {
      case 'DinnerOnUs':
      case 'BoosterKPIPoints':
      case 'EarlyFinishOnFriday':
        return (
          <Typography sx={{ fontSize: '28px', fontWeight: '700' }} color="textPrimary">
            {selectedPrize.name}
          </Typography>
        );
        break;

      case 'BottleOfChampagne':
        return (
          <Stack direction="column">
            <Typography sx={{ color: '#13DEA1' }}>Bottle of</Typography>
            <Typography sx={{ fontSize: '28px', fontWeight: '700' }} color="textPrimary">
              Champagne
            </Typography>
          </Stack>
        );
        break;

      case '100amazonVoucher':
      case '50amazonVoucher':
      case '200amazonVoucher':
      default:
        return (
          <Stack direction="column">
            <Typography sx={{ fontSize: '28px', fontWeight: '700' }} color="textPrimary">
              £{selectedPrize.image || 0}
            </Typography>
            <Typography sx={{ color: '#13DEA1' }}>Amazon voucher</Typography>
          </Stack>
        );
        break;
    }
  };

  return (
    <>
      <Typography color="secondary" variant="h2">
        Winning prize
      </Typography>
      <Spacer x={2} y={2} />

      <Card>
        <CardContent sx={{ height: '92px', padding: '0px 20px', paddingBottom: '0px !important' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" height="92px">
            {getText()}
            <img src={getIcon()} style={{ maxHeight: '80px' }} />
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default WinningPrize;
