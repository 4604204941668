import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Box, CircularProgress, Pagination, Typography } from '@mui/material';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';

import HTMLFlipBook from 'react-pageflip';
import { throttle } from 'lodash';

function PdfViewer({ url }) {
  const [numPages, setNumPages] = useState();
  const [initialWidth, setInitialWidth] = useState(null);
  const pdfRef = useRef();

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  useEffect(() => {
    window.addEventListener('resize', throttle(setPdfSize, 3000));
    setPdfSize();
    return () => {
      window.removeEventListener('resize', throttle(setPdfSize, 3000));
    };
  });

  const setPdfSize = () => {
    if (pdfRef && pdfRef.current) {
      if (initialWidth !== pdfRef.current.getBoundingClientRect().width) {
        setInitialWidth(pdfRef.current.getBoundingClientRect().width);
      }
    }
  };

  return (
    <PDFContainer ref={pdfRef} height={initialWidth * 1.41}>
      <Document
        file={{ url }}
        loading={() => {
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress color="secondary" size={24} />
          </Box>;
        }}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        key="static-pdf"
      >
        <HTMLFlipBook width={initialWidth} height={initialWidth * 1.41} maxShadowOpacity={0.5}>
          {Array.apply(null, Array(numPages))
            .map((x, i) => i + 1)
            .map((pageN) => (
              <div>
                <Page pageNumber={pageN} width={initialWidth} key={`pdf-viewer-page-${pageN}`} />
              </div>
            ))}
        </HTMLFlipBook>
      </Document>
    </PDFContainer>
  );
}

export default PdfViewer;

const PDFContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  min-height: ${(props) => `${props?.height}px` || '800px'};
`;
