import { Avatar } from '@mui/material';
import styled from 'styled-components';

import {
  deepOrange,
  deepPurple,
  blue,
  cyan,
  indigo,
  lightGreen,
  lime,
  teal,
} from '@mui/material/colors';

const colors = [
  deepOrange[400],
  deepPurple[400],
  cyan[400],
  blue[500],
  teal[500],
  indigo[500],
  lightGreen[500],
  lime[300],
];

const getColorFromName = (name) => {
  return colors[name.toLocaleLowerCase().charCodeAt(0) % colors.length];
};

export const MuiAvatar = styled(Avatar).attrs({})`
  cursor: pointer;
  width: ${(props) => `${props.size}px`} !important;
  height: ${(props) => `${props.size}px`} !important;
  background-color: ${(props) =>
    props.name ? getColorFromName(props.name) : '#bdbdbd'} !important;
  font-size: ${(props) => `${props.size / 2.2}px`} !important;
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: ${(props) => `${props.size}px`} !important;
`;
