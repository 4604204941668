import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Box, ButtonBase } from '@mui/material';
import { Button, Spacer } from 'src/components/shared';
import { taskErrorTypes } from '../../config';

import { getTaskTypeText, getTaskTypeIcon } from '../../utils/tasksUtils';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { parseUserDisplayName } from 'src/utils/helper';
const { INBOX_DISCONNECTED } = taskErrorTypes;

export function useErrorsColumns(errors, openForm, props) {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setColumns([
      {
        label: 'Task type',
        name: 'taskType',
        options: {
          filter: false,
          sort: false,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 120 },
          }),
          setCellProps: () => ({
            style: { minWidth: 120 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = errors[dataIndex];
            if (!rData) return null;

            const icon =
              rData.type === INBOX_DISCONNECTED
                ? getTaskTypeIcon(rData.type)
                : getTaskTypeIcon(rData?.task?.taskType);
            return (
              <Box display="flex">
                {icon}
                <Spacer x={1} y={1} />
                <Typography variant="body2" color="textSecondary">
                  {rData.type === INBOX_DISCONNECTED
                    ? getTaskTypeText(rData.type)
                    : getTaskTypeText(rData?.task?.taskType)}
                </Typography>
              </Box>
            );
          },
        },
      },
      {
        label: 'Contact name',
        name: 'contact',
        options: {
          filter: true,
          sort: false,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 150 },
          }),
          setCellProps: () => ({
            style: { minWidth: 150 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = errors[dataIndex];
            if (!rData) return null;
            if (rData.contact) {
              return (
                <ButtonBase
                  onClick={() => {
                    props.toggleContactDrawer(rData.contact?.id);
                  }}
                >
                  <Typography color="secondary" variant="body2">
                    {parseUserDisplayName(`${rData.contact.fname} ${rData.contact.lname}` || '')}
                  </Typography>
                </ButtonBase>
              );
            }
            return '-';
          },
        },
      },
      {
        label: 'Sequence',
        name: 'sequence',
        options: {
          filter: true,
          sort: false,
          draggable: false,
          customBodyRender: (value) => {
            if (value?.id && value?.id !== '') {
              return (
                <Link to={`/sequence/${value.id}`}>
                  <Typography color="secondary" variant="body2">
                    {value.name}
                  </Typography>
                </Link>
              );
            }
            return '';
          },
        },
      },
      {
        label: 'Error details',
        name: 'message',
        options: {
          filter: false,
          sort: false,
          draggable: true,
          customBodyRenderLite: (dataIndex) => {
            let type = '';
            const rData = errors[dataIndex];
            if (!rData) return null;
            if (rData.type == 'workEmail') {
              type = 'email';
            } else if (rData.type == 'content') {
              type = 'template';
            }
            switch (rData.message) {
              case 'Personal email is required':
                type = 'personal_email';
                break;
              case 'Work email is required':
                type = 'email';
                break;
              case 'Phone number is required':
                type = 'phone';
                break;
              case 'Email template selection is required':
                type = 'template';
                break;
              case 'Linkedin Url is required':
                type = 'linkedin';
                break;
              default:
            }

            if (rData.contact) {
              return (
                <ButtonBase
                  onClick={() => {
                    openForm(type, rData);
                  }}
                >
                  <Typography color="danger" sx={{ color: '#C62828' }} variant="body2">
                    {rData.message}
                  </Typography>
                </ButtonBase>
              );
            }
            return '-';
          },
        },
      },
      {
        label: ' ',
        name: 'view',
        options: {
          filter: false,
          sort: false,
          draggable: true,
          customBodyRenderLite: (dataIndex) => {
            // const { type } = errors[dataIndex];
            const rData = errors[dataIndex];
            if (!rData) return null;
            const type = '';
            if (type === INBOX_DISCONNECTED) return null; // no actions for this type
            return (
              <Button
                iconButton
                color="primary"
                onClick={() => {
                  props.setTaskErrorDetails(rData);
                }}
              >
                <OpenInNewIcon />
              </Button>
            );
          },
        },
      },
    ]);
  }, [errors]);

  return columns;
}
