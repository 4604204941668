import React from 'react';
import { Typography, Box, Stack, Divider } from '@mui/material';
import { Skeleton } from '@mui/lab';
import { Spacer } from 'src/components/shared';

const SkeletonItem = () => {
  return (
    <>
      <Box p={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center">
            <Skeleton animation="wave" height={24} width={100} />
            <Spacer x={1} y={1} />
            <Typography variant="caption" color="textSecondary">
              <Skeleton animation="wave" height={14} width={60} />
            </Typography>
          </Stack>
          {/* <Skeleton animation="wave" height={14} width={60} /> */}
        </Stack>

        <Skeleton animation="wave" height={16} width={120} />
        <Spacer y={0.5} x={0.5} />
        <Skeleton animation="wave" height={20} width={120} />
        <Skeleton animation="wave" height={14} width={'100%'} />
        <Skeleton animation="wave" height={14} width={'60%'} />
      </Box>
      <Divider />
    </>
  );
};

function InboxSkeleton() {
  return (
    <>
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
    </>
  );
}

export default InboxSkeleton;
