import styled from 'styled-components';
import LinearProgress from '@mui/material/LinearProgress';

export const Progress = styled(LinearProgress).attrs({
  variant: 'determinate',
  color: 'secondary',
})`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
`;

export const PreviewActions = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff70;
  z-index: 1;
  display: none;
  justify-content: center;
  align-items: center;
`;

export const PreviewContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    .actions {
      display: flex;
    }
  }
`;

export const Preview = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

export const ContentCardContainer = styled.div`
  width: 100%;
  max-width: 1500px;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
`;

export const ContentContainer = styled.div`
  width: 100%;
  max-width: 1500px;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
`;

export const DropContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.23);
  cursor: pointer;
  border-radius: 4px;
  .dropzone {
    width: 100%;
    height: 100%;
  }
`;

export const Dropper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FileContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  p {
    padding-left: 10px;
    margin: 0;
  }
  padding: 0px;
  margin-bottom: 6px;
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 4px;
  justify-content: space-between;
  background-color: #f2f2f2;
`;

export const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

export const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

export const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

export const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};
