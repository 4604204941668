import React from 'react';
import { Box, Typography } from '@mui/material';
import randomColor from 'randomcolor';
import styled from 'styled-components';

function CourseBanner({ course }) {
  const color = randomColor({ luminosity: 'light', seed: course.title });

  return (
    <Banner color={color} className="bannerWrapper">
      <Typography className="cap">course</Typography>
      <Typography className="title">{course.title}</Typography>
      <div className="polly">
        <div className="shapePolly" />
        <div className="rect" />
      </div>
    </Banner>
  );
}

export default CourseBanner;

const Banner = styled.div`
  background-color: #0c1721;
  height: 220px;
  display: flex;
  align-items: flex-start;
  position: relative;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 0px 32px;
  /* margin-bottom: 36px; */
  & .cap {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }
  & .title {
    font-size: 60px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%;
    color: ${(props) => props.color};
  }
  & .polly {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
  }
  & .shapePolly {
    clip-path: polygon(0% 100%, 100% 0%, 100% 100%, 0% 100%);
    background-color: ${(props) => props.color};
    width: 120px;
    height: 220px;
  }
  & .rect {
    background-color: ${(props) => props.color};
    width: 90px;
    height: 220px;
    margin-left: -1px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;
