import { makeStyles } from '@mui/styles';
import { themeColors } from 'src/modules/lms/config';

const useStyles = makeStyles(({ palette }) => ({
  title: {
    fontSize: '38px',
    color: themeColors.yellow,
    fontWeight: '800',
  },
  header: {
    marginTop: '12px',
    color: 'white',
    fontSize: '20px',
    fontWeight: '400',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    whiteSpace: 'pre-wrap',
  },
  description: {
    color: themeColors.greyText,
    fontSize: '14px',
    fontWeight: '400',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 4,
    whiteSpace: 'pre-wrap',
  },
  customAutocomplete: {
    width: '162px',
    '& input': {
      color: '#fff',
    },
 
    '& label.Mui-focused': {
      color: '#BFBFBF',
    },
  },
  customAutocompleteOption:{
    width:'120px',
    overflow:'hidden',
    textOverflow:'ellipsis',
    padding:'5%'
  },
  descriptionBox: {
    height: '100px',
  },
  caption: {
    marginTop: '8px',
    color: '#808080',
    fontSize: '14px',
    fontWeight: '400',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  listContainer: {
    display: 'flex',
    width: '1128px',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    gap: '32px',
    flexWrap: 'wrap',
  },
  tickIcon: {
    fontSize: '20px',
    color: themeColors.yellow,
  },
  search: {
    marginBottom: '30px',
    color: '#BFBFBF !important',
    opacity: '0.6',
    borderRadius: '10px',
    background: themeColors.popUpBlue,
    ' & input': {
      height: '8px',
      color: '#BFBFBF !important',
      borderRadius: '10px',
      background: themeColors.popUpBlue,
      marginLeft: '8px',
    },
    ' & input:-webkit-autofill': {
      background: themeColors.popUpBlue,
      borderRadius: '0px 0px 0px 0px',
    },
    ' & fieldset': {
      height: '46px',
      borderRadius: '10px',
      border: 'none !important',
      color: '#BFBFBF !important',
    },
    '& label': {
      color: '#BFBFBF !important',
    },
    ' &:focus': {
      background: 'transparent !important',
      color: '#BFBFBF !important',
    },
    '& label.Mui-focused':{
      color:'white'
    },
    '& svg': {
      color: '#BFBFBF !important',
    },
  },
}));
export default useStyles;
