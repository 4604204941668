import React from 'react';
import Slider from 'src/components/lms/Slider';
import Course from 'src/components/lms/Course';
import useMediaQuery from 'src/components/hooks/useMediaQuery';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import { LMSCard } from 'src/components/lms';
import { useLmsHomeProvider } from '../../utils/lmsStore';

function RecommendedCourses({ title }) {
  const { isMobile } = useMediaQuery();
  const { homePage } = useLmsHomeProvider();

  const courses = homePage.component.length
    ? homePage.component.filter((item) => item.name == 'recommendedCourse')[0].params
    : [];

  const sliderProps = {
    slidesToShow: 3,
    ...(isMobile && {
      centerMode: true,
      centerPadding: '30px',
      arrows: true,
      dots: true,
      fade: true,
    }),
    padding: 16,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (!courses.length) return null;

  return (
    <LMSCard icon={<ThumbUpAltOutlinedIcon sx={{ width: 24, height: 24 }} />} title={title}>
      <Slider {...sliderProps}>
        {courses.map((course, index) => (
          <Course course={course} key={`recommended-course-${course}`} />
        ))}
      </Slider>
    </LMSCard>
  );
}

export default RecommendedCourses;
