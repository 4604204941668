import React from 'react';
import PropTypes from 'prop-types';
import LockIcon from '@mui/icons-material/Lock';
import { EventCard } from '../eventsStyles';

const EndSequenceCard = ({ event, ...props }) => {
  return (
    <EventCard
      title="End of sequence"
      icon={<LockIcon style={{ color: '#EF5350' }} />}
      showEdit={false}
      showDelete={true}
      {...props}
    />
  );
};

EndSequenceCard.propTypes = {
  event: PropTypes.object,
};

export default EndSequenceCard;
