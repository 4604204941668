import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';

const SequenceChart = ({ sequence }) => {
  return (
    <>
      <BarChart
        width={500}
        height={300}
        data={sequence.report}
        layout="vertical"
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        barSize={20}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis type="category" width={150} padding={{ left: 20 }} dataKey="sequenceName" />
        <Bar dataKey="noOfReplies" fill="#4789C2" />
      </BarChart>
    </>
  );
};

export default SequenceChart;
