import React from 'react';
import { Link } from 'react-router-dom';
import { calculateDaysAgo, removeHTML } from '../../utils/helper';
import MissingImage from '../../shared/MissingImage';
import { Stack, Typography } from '@mui/material';
import { TrashIcon, Thumbnail, Desc, HistoryWrapper, Description } from './style';
import { Button } from 'src/components/shared';
import moment from 'moment';

const SingleHistory = ({ item, handleDelete }) => {
  const handleClick = () => {
    handleDelete(item.id);
  };

  return (
    <HistoryWrapper>
      <Link to={`/lms/session/${item?.session?.id}?play=${item.videoId}`}>
        <Thumbnail>
          {item?.thumbnail ? (
            <img src={item?.thumbnail} className="singleSession_image" />
          ) : (
            <div className="singleSession_image singleSession_image-div">
              <MissingImage />
            </div>
          )}
        </Thumbnail>
      </Link>
      <Description>
        <Link to={`/lms/session/${item?.session?.id}?play=${item.videoId}`}>
          <Typography color="textPrimary" variant="body1">
            {item?.session?.title}
          </Typography>
        </Link>
        <Desc
          className="singleSession_p"
          dangerouslySetInnerHTML={{
            __html: removeHTML(item?.session?.overviewText),
          }}
        ></Desc>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt={1}
          minHeight={'36px'}
        >
          <Typography color="textSecondary" variant="body2" sx={{ color: '#808080' }}>
            {/* {calculateDaysAgo(item)} */}
            {moment(item.updatedAt).fromNow()}
          </Typography>
          <Button className="deleteIcon" iconButton onClick={handleClick}>
            <TrashIcon className="trash" />
          </Button>
        </Stack>
      </Description>
    </HistoryWrapper>
  );
};

export default SingleHistory;
