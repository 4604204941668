import { makeStyles } from '@mui/styles';
import theme from 'src/config/theme';
import { themeColors } from 'src/modules/lms/config';
const useStyles = makeStyles(({ palette }) => ({
  settingsButton: {
    fontWeight: 500,
    color: themeColors.yellow,
    fontSize: '14px',
    cursor: 'pointer',
    margin: '8px 0px 0px 24px',
  },
  cancelButton: {
    color: themeColors.popUpText,
  },
  heading: {
    fontSize: '24px',
    fontWeight: '700',
    color: themeColors.white,
    marginBottom: '10px',
  },
  saveButton: {
    color: 'black',
    backgroundColor: themeColors.yellow,
    '&:hover': {
      backgroundColor: themeColors.yellow,
    },
  },
  previewIcon: {
    color: themeColors.yellow,
    cursor: 'pointer',
    marginRight: '-13px',
    marginLeft: '20px',
    marginTop: '11px',
    fontSize: '16px',
  },
  invite: {
    fontSize: '14px',
    color: themeColors.popUpText,
    fontWeight: '500',
  },
  header: {
    fontSize: '14px',
    fontWeight: 700,
    color: themeColors.popUpText,
  },
  Preview: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '820px',
    height: '515px',
    overflow: 'auto',
    background: themeColors.popUpBlue,
    borderRadius: '10px',
    '&:focus-visible ': {
      outline: 'none !important',
    },
    '& button': {
      textTransform: 'none',
    },
  },
  templatePreview: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '813px',
    height: '650px',
    overflow: 'auto',
    background: themeColors.popUpBlue,
    borderRadius: '10px',
    '&:focus-visible ': {
      outline: 'none !important',
    },
    '& button': {
      textTransform: 'none',
    },
  },
  logo: {
    height: '60px',
    width: '120px',
    objectFit: 'contain',
    borderRadius: '4px',
  },
  title: {
    color: themeColors.popUpText,
    fontSize: '18px',
    fontWeight: '500',
  },
  switchLabel: {
    margin: '0%',
    color: themeColors.popUpText,
    '& .MuiFormControlLabel-label ': {
      fontSize: '14px !important',
    },
    '& .Mui-checked+.MuiSwitch-track': {
      backgroundColor: themeColors.greyHeader,
    },
  },
  lineColor: {
    border: '1px solid #1B3C59',
  },
  line: {
    border: '1px solid #BDC8D7',
  },

  close: {
    color: themeColors.popUpText,
    width: '4%',
    paddingTop: '1%',
    cursor: 'pointer',
  },
  FileDropper: {
    position: 'relative',
    height: '76px',
    paddingLeft: '0 !important',
  },
  caption: {
    display: 'flex',
    alignItems: 'end',
    height: '116px',
    marginLeft: '10px',
    color: '#5B6777',
    fontStyle: 'italic',
  },
  previewTextArea: {
    padding: '26px',
    borderRadius: '10px',
    background: '#17324b',
    '& .tox-tinymce': {
      border: 'none !important',
    },
  },
}));

export default useStyles;
