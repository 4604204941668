import React, { useState, useEffect } from 'react';
import { ButtonBase, Tooltip, useTheme } from '@mui/material';
import { Button } from 'src/components/shared';
import { useSelector } from 'react-redux';
import CreateCompetition from 'src/modules/compitions/components/Competition/CreateCompetition';
import { useHistory } from 'react-router-dom';
import { themeColors } from 'src/modules/lms/config';
import staredTrophyIcon from '../../../../../assets/icons/staredTrophyIcon.svg';
import competitionIcon from '../../../../../assets/icons/competition.svg';
import CompetitionForm from 'src/modules/compitions/components/CompetitionForm';

function CompetitionReport({ session, competition }) {
  const history = useHistory();
  const [competitionModalOpen, setCompetitionModalOpen] = useState(false);
  const [competitionBtnShow, setCompetitionBtnShow] = useState(false);
  const userAccessLevel = useSelector((state) => state.auth.user);
  const sessionName = { label: session?.title, value: session?.id };
  useEffect(() => {
    if (
      userAccessLevel?.acl?.accessLevel === 'Admin' ||
      userAccessLevel?.acl?.accessLevel === 'Manager'
    ) {
      setCompetitionBtnShow(true);
    }
  }, []);

  return (
    <>
      {competitionBtnShow && !competition ? (
        session?.showRoleplay ? (
          <Button
            color="secondary"
            startIcon={<img src={staredTrophyIcon} width="24" height="24" />}
            sx={{
              background: themeColors.bg,
              padding: '10px 16px',
              display: 'flex',
              justifyContent: 'normal',
            }}
            fullWidth
            size="large"
            onClick={() => {
              setCompetitionModalOpen(true);
            }}
          >
            Create a competition
          </Button>
        ) : (
          <Tooltip
            title={
              'This session is not eligible for competitions as it does not include a role-play'
            }
            placement="top"
            arrow
          >
            <ButtonBase style={{ width: '100%' }}>
              <Button
                color="secondary"
                startIcon={<img src={staredTrophyIcon} width="24" height="24" />}
                fullWidth
                size="large"
                variant="outlined"
                disabled
              >
                Create a competition
              </Button>
            </ButtonBase>
          </Tooltip>
        )
      ) : (
        <>
          {competition ? (
            <Button
              color="secondary"
              startIcon={<img src={competitionIcon} width="24" height="24" />}
              sx={{
                background: themeColors.bg,
                padding: '10px 16px',
                display: 'flex',
                justifyContent: 'normal',
              }}
              fullWidth
              size="large"
              onClick={() => {
                history.push(`/lms/report/${competition?.id}`);
              }}
            >
              View competition progress report
            </Button>
          ) : (
            <Button
              color="secondary"
              startIcon={<img src={staredTrophyIcon} width="24" height="24" />}
              sx={{
                background: themeColors.bg,
                padding: '10px 16px',
                display: 'flex',
                justifyContent: 'normal',
              }}
              fullWidth
              size="large"
              onClick={() => {
                history.push('/lms/competition');
              }}
            >
              View previous competitions
            </Button>
          )}
        </>
      )}

      {/* <CreateCompetition
        open={competitionModalOpen}
        session={sessionName}
        handleClose={() => {
          setCompetitionModalOpen(false);
        }}
        handleOpen={() => setCompetitionModalOpen(true)}
      /> */}
      <CompetitionForm
        open={competitionModalOpen}
        session={session}
        handleClose={() => {
          setCompetitionModalOpen(false);
        }}
        handleOpen={() => setCompetitionModalOpen(true)}
      />
    </>
  );
}

export default CompetitionReport;
