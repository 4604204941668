import { Avatar } from '@mui/material';
import { Button, Grid, Typography, Box } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { truncate } from '../../utils/helper';
import useStyles from './style';

export const ExpertSection = ({ text, expertData }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.expertSection}>
      <Box className={classes.expertText}>
        <Typography variant="p">{text}</Typography>
      </Box>
      <Grid mt={2}>
        {expertData
          .filter((k) => k.published)
          .map((item, i) => (
            <Box key={i} display={'flex'} justifyContent={'left'} sx={{ padding: '3px' }}>
              <Box>
                <Avatar
                  className={classes.expertImg}
                  alt="Remy Sharp"
                  src={item.profileImage}
                  sx={{ width: 64, height: 64 }}
                />
              </Box>
              <Box className={classes.expertDetails}>
                <Typography className={classes.expertTitle}>
                  {truncate(`${item.fname} ${item.lname}`, null, 30)}
                </Typography>
                <Typography className={classes.expertDesignation}>
                  {truncate(item.jobTitle, null, 20)}
                </Typography>
                <Typography className={classes.expertDesignation}>
                  {truncate(item.companyName, null, 20)}
                </Typography>
              </Box>
            </Box>
          ))}
      </Grid>
    </Grid>
  );
};
export default ExpertSection;
