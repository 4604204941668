import React, { useEffect } from 'react';
import { PageTitle } from 'src/components/App';
import { connect } from 'react-redux';
import SequencesView from './SequencesView';
import {
  fetchSequences,
  setSequenceTab,
  cloneSequence,
  sequenceBulkAction,
  setSequence,
  putSequence,
} from '../../actions/sequenceActions';
import { toggleAddSequenceForm } from 'src/modules/app/actions/appActions';
import { getSequenceFields } from 'src/modules/app/actions/appSelector';

export const SequencesContainer = (props) => {
  return (
    <>
      <PageTitle title={`Sequences`} />
      <SequencesView {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({
  sequenceTab: state.sequence.sequenceTab,
  sequences: state.sequence.sequences,
  loading: state.sequence.loading.sequences,
  sequenceAccessTypeList: state.app.globals.sequenceAccessTypeList,
  sequenceEmailTypeList: state.app.globals.sequenceEmailTypeList,
  user: state.auth.user,
  fields: getSequenceFields(state),
});

const mapDispatchToProps = (dispatch) => ({
  setSequence: (sequence) => dispatch(setSequence(sequence)),
  setSequenceTab: (tab) => dispatch(setSequenceTab(tab)),
  putSequence: (id, data) => {
    return new Promise((resolve, reject) => {
      dispatch(putSequence(id, data, resolve, reject));
    });
  },
  toggleAddSequenceForm: () => dispatch(toggleAddSequenceForm()),
  fetchSequences: (paging, filters, tab, sort) =>
    dispatch(fetchSequences(paging, filters, tab, sort)),
  cloneSequence: (sequenceId, sequenceName) => {
    return new Promise((resolve, reject) => {
      dispatch(cloneSequence(sequenceId, sequenceName, resolve, reject));
    });
  },
  sequenceBulkAction: (action, sequences, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(sequenceBulkAction(action, sequences, payload, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SequencesContainer);
