import { ReactComponent as EmptyIcon } from 'src/assets/lms/icons/empty.svg';
import { ReactComponent as CheckmarkIcon } from 'src/assets/lms/icons/checkmark.svg';
import { ReactComponent as SortIcon } from 'src/assets/lms/icons/sort.svg';
import { ReactComponent as TagIcon } from 'src/assets/lms/icons/tag.svg';
import { ReactComponent as SavedIcon } from 'src/assets/lms/icons/saved.svg';
import { ReactComponent as HelpIcon } from 'src/assets/lms/icons/help.svg';
import { ReactComponent as OutreachIcon } from 'src/assets/lms/icons/outreach.svg';
import { ReactComponent as LockIcon } from 'src/assets/lms/icons/lock.svg';

const iconConfig = {
  empty: EmptyIcon,
  check: CheckmarkIcon,
  sort: SortIcon,
  tags: TagIcon,
  saved: SavedIcon,
  outreach: OutreachIcon,
  help: HelpIcon,
  lock: LockIcon,
};

export default iconConfig;
