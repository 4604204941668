import { createTheme, adaptV4Theme } from '@mui/material/styles';
import styled, { css } from 'styled-components';

export const typography = {
  custom: {
    colorCode: {
      textPrimary: 'rgba(0,0,0,0.87)',
      textSecondary: 'rgba(0,0,0,0.60)',
    },
  },
  fontFamily: ['Roboto', 'sans-serif'].join(','),
  body1: {
    fontSize: '16px',
    fontWeight: 400,
  },
  body2: {
    fontSize: '14px',
    fontWeight: 400,
  },
  h1: {
    fontSize: '20px',
    lineHeight: '120%',
    fontWeight: 500,
  },
  h2: {
    fontSize: '18px',
    lineHeight: '120%',
    fontWeight: 500,
    letterSpacing: '-0.5px',
  },
  h3: {
    fontSize: '16px',
    lineHeight: '123.5%',
    fontWeight: 400,
    letterSpacing: '0.25px',
  },
  h4: {
    fontSize: '16px',
    color: '#000000',
  },
  h5: {
    fontSize: '12px',
    lineHeight: '133.4%',
    fontWeight: 400,
  },
  h6: {
    fontSize: '12px',
    lineHeight: '133.4%',
    fontWeight: 400,
    letterSpacing: '0.15px',
  },
  faded: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.6)',
    letterSpacing: '0.15px',
    lineHeight: '150%',
    fontWeight: 400,
  },
};

export const palette = {
  type: 'light',
  // mode: 'dark',
  primary: {
    main: '#031729',
    dark: '#010C15',
    light: '#132B41',
  },
  secondary: {
    main: '#1976D2',
    dark: '#1565C0',
    light: '#3686D6',
  },
  success: {
    main: 'rgba(46, 125, 50, 1)',
  },
  text: {
    primary: 'rgba(0,0,0,0.87)',
    secondary: 'rgba(0,0,0,0.60)',
    grey1: 'rgba(0,0,0,0.54)',
    grey2: 'rgba(0,0,0,0.30)',
  },
  background: {
    default: '#FAFAFA',
    paper: '#ffffff',
    bg1: 'rgba(25, 118, 210, 0.08)',
  },
};

export const components = {
  MuiTextField: {
    root: {
      marginTop: '8px',
      '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          borderColor: '#1976D2',
          borderWidth: '2px',
        },
        // '&.Mui-focused fieldset': {
        //   borderColor: '#3E68A8',
        // },
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      list: {
        '& li': {
          fontSize: '14px',
          color: 'rgba(0,0,0,0.6)',
          '& svg': {
            width: '24px',
            height: '24px',
            color: 'rgba(0,0,0,0.6)',
            marginRight: '8px',
          },
          '&:hover': {
            color: palette.secondary.main,
            backgroundColor: 'rgba(25, 118, 210, 0.08)',
            '& svg': {
              color: palette.secondary.main,
            },
          },
        },
      },
    },
  },
};

export const overrides = {
  overrides: {
    MuiListItem: {
      root: {},
    },
  },
};

const theme = createTheme({
  palette,
  typography,
  components,
  overrides,
});

export default theme;

export const drawerWidth = 220;
export const drawerCollapseWidth = 55;
