import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { AppGrid } from 'src/components/App';
import { Form, Button } from 'src/components/shared';
import { useContactsColumns } from './useColumns';
import { toggleContactDrawer } from 'src/modules/app/actions/appActions';

function BoardContactsGrid({ panels, ...props }) {
  const [contacts, setContacts] = useState([]);
  const dispatch = useDispatch();
  const contactFields = useSelector((state) => state.app.modules.contacts);

  useEffect(() => {
    const contactsTemp = [];
    Object.values(panels).map((panel) => {
      if (panel?.contacts && panel?.contacts.length) {
        panel?.contacts.map((contact) => {
          contactsTemp.push({
            ...contact.contact,
            panelId: panel.id,
          });
        });
      }
    });
    setContacts(contactsTemp);
  }, [panels]);

  const openContactDrawer = (contactId) => {
    dispatch(toggleContactDrawer(contactId));
  };

  const contactsColumns = useContactsColumns(
    contacts,
    contactFields,
    undefined,
    undefined,
    props,
    openContactDrawer,
    panels,
  );
  if (!contacts.length) return null;

  return (
    <Box mx={3} mt={-1} zIndex={0}>
      <AppGrid
        selectableRows="none"
        columns={contactsColumns}
        data={contacts}
        loading={false}
        options={{
          pagination: true,
        }}
      />
    </Box>
  );
}

export default BoardContactsGrid;
