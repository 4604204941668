import React, { useState, useEffect, useMemo } from 'react';
import { Alert, Container, Grid, Link } from '@mui/material';
import { Spacer, Drawer } from 'src/components/shared';
import { Empty } from 'src/components/App';
import { Intro, LookupTabs, LookupsGrid, LookupForm } from '../../components/Lookups';
import { TabsSkeleton } from '../../components/Lookups/Skeleton';

const LookupsView = ({ lookups, lookupList, loading, ...props }) => {
  const [showForm, setShowForm] = useState(false);
  const [activeLookup, setActiveLookup] = useState('');
  const { data } = lookups;

  const activeLookupObj = useMemo(() => {
    if (data && data.length && activeLookup !== '') {
      return data.filter((item) => item.id === activeLookup)[0];
    }
  }, [activeLookup, data]);

  useEffect(() => {
    const lookupId = props?.match?.params?.id;
    if (lookupId && lookupId !== '') {
      props.fetchLookupList(lookupId);
      setActiveLookup(lookupId);
    }
  }, [props?.match?.params]);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  return (
    <>
      <Intro
        title="Manage Central Lookups"
        description="Save time and increase efficiency by creating and managing central lookups that be used across different fields."
        showButton={true}
        onClick={toggleForm}
      />
      <Spacer x={2} y={2} />
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid
            item
            xl={2}
            xs={2}
            sx={{
              borderRight: '1px solid #e8e8e8',
              minHeight: 'calc(100vh - 178px)',
              // height: 'calc(100vh - 178px)',
              // overflow: 'auto',
            }}
          >
            {loading?.lookups ? (
              <TabsSkeleton />
            ) : (
              <LookupTabs lookups={data} activeLookup={activeLookup} loading={loading} />
            )}
          </Grid>

          <Grid item xs={10} xl={10}>
            {activeLookup && activeLookup !== '' && (
              <>
                <Alert
                  sx={{ color: 'rgb(0, 0, 0)', '& svg': { color: 'rgb(0, 0, 0, .70)' } }}
                  severity="info"
                >
                  Add or manage lookup values from your connected CRM and then click the{' '}
                  <Link sx={{ textDecoration: 'none', fontWeight: 600, cursor: 'pointer' }}>
                    sync button
                  </Link>
                </Alert>
                <LookupsGrid
                  lookups={data}
                  lookupList={lookupList}
                  loading={loading}
                  activeLookup={activeLookup}
                  putCentralLookup={props.putCentralLookup}
                  putLookupList={props.putLookupList}
                  postLookupList={props.postLookupList}
                  deleteLookupOption={props.deleteLookupOption}
                  activeLookupObj={activeLookupObj}
                  syncLookupWithCrm={props.syncLookupWithCrm}
                />
                <Spacer x={2} y={2} />
              </>
            )}
          </Grid>
        </Grid>
      </Container>
      <Drawer title={'Add central lookup'} open={showForm} onClose={toggleForm}>
        <LookupForm
          lookups={data}
          postCentralLookup={props.postCentralLookup}
          onClose={toggleForm}
        />
      </Drawer>
    </>
  );
};

export default LookupsView;
