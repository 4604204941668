import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment';
import { Grid, Box, Typography, Tooltip, Stack } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import { getPriorityIcon, getTaskTypeIcon } from 'src/modules/tasks/utils/tasksUtils';
import { postContactNote, setContactSingleNote } from '../../actions/contactActions';
import { getNoteType, setContactNote } from '../../api/contactApis';

function LeaveNote({
  contact,
  onClose,
  taskPriorityList,
  taskTypeList,
  user,
  postContactNote,
  showDate = true,
  showPriority = true,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [noteTypes, setNoteTypes] = useState([]);

  const priorities = useMemo(() => {
    return taskPriorityList?.map((item) => {
      return {
        ...item,
        icon: getPriorityIcon(item.id),
      };
    });
  }, [taskPriorityList]);

  const fetchNoteTypeOptions = async () => {
    const res = await getNoteType();
    setNoteTypes(res.noteTypes);
  };

  useEffect(() => {
    fetchNoteTypeOptions();
  }, []);

  const handleSubmit = async (values) => {
    let data = { ...values };
    data.assignedTo = data.assignedTo?.id || '';
    data.date = moment(data.dueOn).format('YYYY-MM-DD');
    data.time = data.dueTime;
    data.contact = data.contact?.id;
    delete data?.dueTime;
    delete data?.dueOn;
    try {
      setLoading(true);
      await postContactNote(data);
      setTimeout(async () => {
        await props.fetchContactTasks(data.contact);
      }, 3000);

      setLoading(false);
      onClose(undefined);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Form
      initialValues={{
        name: '',
        type: '',
        note: '',
        priority: '',
        dueOn: null,
        dueTime: null,
        assignedTo: user,
        contact: contact,
      }}
      validationSchema={Yup.object().shape({
        type: Yup.string().required('Please select note type!'),
        note: Yup.string().required('Please enter Note Details!'),
        contact: Yup.object()
          .required('Please choose a Contact to continue.')
          .nullable(true)
          .test(
            'candidate-check',
            'Please choose a Contact to continue.',
            async (value, { createError }) => {
              if (value && value.id && value.id !== '') {
                return true;
              }
              return false;
            },
          ),
        assignedTo: Yup.object()
          .required('Please choose a assigned user to continue.')
          .nullable(true)
          .test(
            'assignedTo-check',
            'Please choose a assigned user to continue.',
            async (value, { createError }) => {
              if (value && value.id && value.id !== '') {
                return true;
              }
              return false;
            },
          ),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={false}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-end"
              height="100%"
            >
              <Box width="100%">
                <Form.Field.Select
                  options={noteTypes || []}
                  fullWidth
                  variant="outlined"
                  name="type"
                  label="Note Type"
                  optLabel="label"
                  optValue="value"
                  showNone={false}
                />

                {/* <Form.Field.Input fullWidth variant="outlined" name="name" label="Title" /> */}

                {/* <Form.Field.TextEditor
                  name="note"
                  label="Note Details"
                  placeholder="Note details here..."
                /> */}
                <Form.Field.Input
                  multiline
                  name="note"
                  fullWidth
                  variant="outlined"
                  placeholder="Note details here..."
                  rows={6}
                />

                {showDate && (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Form.Field.Datepicker
                        fullWidth
                        variant="inline"
                        name="dueOn"
                        label="Follow up Date"
                        disablePast={true}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Form.Field.Timepicker
                        fullWidth
                        variant="outlined"
                        name="dueTime"
                        label="Time"
                      />
                    </Grid>
                  </Grid>
                )}

                {showPriority && (
                  <Form.Field.Select
                    options={priorities || []}
                    fullWidth
                    variant="outlined"
                    name="priority"
                    label="Priority"
                    optLabel="name"
                    optValue="id"
                    showNone={false}
                  />
                )}
              </Box>

              <Stack mt={2} direction="row" gap={2}>
                <Button color="secondary" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Save
                </Button>
              </Stack>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}

export default LeaveNote;
