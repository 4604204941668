import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { PageTitle } from 'src/components/App';
import OutboxView from './OutboxView';
import { fetchOutboxTasks, setOutbox, taskBulkUpdate } from '../../actions/taskActions';
import {
  fetchContact,
  clearContactData,
  fetchSequenceEnrollment,
} from 'src/modules/contacts/actions/contactActions';

const OutboxContainer = (props) => {
  useEffect(() => {
    props.clearContactData();
    return () => {
      props.clearContactData();
    };
  }, []);

  return (
    <>
      <PageTitle title={`Outbox`} />
      <OutboxView {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({
  outbox: state.tasks.outbox,
  loading: state.tasks.loading.outbox,
  user: state.auth.user,
  contact: state.contacts.contact,
  contactLoading: state.contacts.loading.contact,
});

const mapDispatchToProps = (dispatch) => ({
  clearContactData: () => dispatch(clearContactData()),
  fetchContact: (contactId) => dispatch(fetchContact(contactId)),
  fetchSequenceEnrollment: (contactId) => dispatch(fetchSequenceEnrollment(contactId)),
  fetchOutboxTasks: (contactId, paging, filters, sort) =>
    dispatch(fetchOutboxTasks(contactId, paging, filters, sort)),
  taskBulkUpdate: (tasks, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(taskBulkUpdate(tasks, payload, resolve, reject));
    });
  },
  setOutbox: (data, total) => dispatch(setOutbox(data, total)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OutboxContainer);
