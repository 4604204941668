import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, CardContent, CircularProgress } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

import { uniqueId } from 'lodash';
import { fetchWidgetData } from '../../../actions/boardActions';
import { colors, InfoColorCard } from './styles';

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const InfoCard = ({ title, color }) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" mb={1}>
      <InfoColorCard color={color} />
      <Typography variant="body2" color="textSecondary">
        {title}
      </Typography>
    </Box>
  );
};

function PieChartReport({ data }) {
  const dispatch = useDispatch();
  const uniqueKey = uniqueId('pie-chart-report');
  const uniqueKeyItem = uniqueId('pie-chart-report-item');
  const { title, description, key, loading } = data;
  const widgetData = data.data;

  useEffect(() => {
    dispatch(fetchWidgetData(data.i));
  }, []);

  const columns = useMemo(() => {
    if (!widgetData.length) {
      return [];
    }
    return Object.keys(widgetData[0]).filter((item) => item !== 'name');
  }, [widgetData]);

  return (
    <CardContent sx={{ height: 'calc(100% - 32px)' }}>
      <Typography variant="h3">{title}</Typography>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <Box display="flex" width="100%" height="100%">
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
            flexDirection="column"
            ml={1}
            mt={2}
          >
            {widgetData.map((data, index) => (
              <InfoCard title={data.name} color={colors[index]} key={`${uniqueKey}-${index}`} />
            ))}
          </Box>
          <ResponsiveContainer width="70%" height="100%">
            <PieChart width="100%" height="100%">
              <Pie
                data={widgetData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
              >
                {widgetData.map((entry, index) => (
                  <Cell fill={colors[index % colors.length]} key={`${uniqueKeyItem}-${index}`} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Box>
      )}
    </CardContent>
  );
}

export default PieChartReport;
