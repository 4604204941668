export const SET_TASKS_LOADING = 'SET_TASKS_LOADING';
export const SET_TASKS_TAB = 'SET_TASKS_TAB';
export const SET_TASKS_BOX_TYPE = 'SET_TASKS_BOX_TYPE';

export const FETCH_TASKS = 'FETCH_TASKS';
export const SET_TASKS = 'SET_TASKS';
export const POST_TASK = 'POST_TASK';
export const PUT_TASK = 'PUT_TASK';
export const PATCH_TASK = 'PATCH_TASK';
export const DELETE_TASK = 'DELETE_TASK';

export const FETCH_TASK_ERRORS = 'FETCH_TASK_ERRORS';
export const SET_TASK_ERRORS = 'SET_TASK_ERRORS';

export const SET_TASK_OPTIONS_FILTERS = 'SET_TASK_OPTIONS_FILTERS';
export const FETCH_TASK_OPTIONS_FILTERS = 'FETCH_TASK_OPTIONS_FILTERS';

export const START_TASKS = 'START_TASKS';

export const FETCH_TASK = 'FETCH_TASK';
export const SET_TASK = 'SET_TASK';

export const CLEAR_TASK_REPLIES = 'CLEAR_TASK_REPLIES';
export const FETCH_TASK_REPLIES = 'FETCH_TASK_REPLIES';
export const SET_TASK_REPLIES = 'SET_TASK_REPLIES';
export const POST_TASK_REPLY = 'POST_TASK_REPLY';
export const PUT_TASK_REPLY = 'PUT_TASK_REPLY';
export const DELETE_TASK_REPLY = 'DELETE_TASK_REPLY';

export const TASK_BULK_UPDATE = 'TASK_BULK_UPDATE';
export const NAVIGATE_NEXT_TASK = 'NAVIGATE_NEXT_TASK';

export const SET_TASK_ERROR_DETAILS = 'SET_TASK_ERROR_DETAILS';

export const FETCH_OUTBOX = 'FETCH_OUTBOX';
export const SET_OUTBOX = 'SET_OUTBOX';

export const SET_TASKS_QUEUE = 'SET_TASKS_QUEUE';

export const FETCH_ENROLMENT_DRAFTS = 'FETCH_ENROLMENT_DRAFTS';
export const SET_ENROLMENT_DRAFTS = 'SET_ENROLMENT_DRAFTS';

export const FETCH_INBOX = 'FETCH_INBOX';
export const SET_INBOX = 'SET_INBOX';

export const FETCH_SENT = 'FETCH_SENT';
export const SET_SENT = 'SET_SENT';
