import { RESET_STORE } from 'src/modules/app/actions/appTypes';
import * as competitionTypes from '../actions/competitionTypes';
const initialState = {
  loading: {
    competitionsList: false,
  },
  competitionsList: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
      live: 0,
    },
    sort: {},
    filters: {},
    data: [],
    columns: [],
    columnsOrder: [],
  },
};
const CompetitionReducer = (state = initialState, action) => {
  switch (action.type) {
    case competitionTypes.FETCH_COMPETITIONS_LIST:
      return {
        ...state,
        loading: {
          ...state.loading,
          competitionsList: true,
        },
        competitionsList: {
          ...state.competitionsList,
          paging: {
            ...state.competitionsList.paging,
            ...action.paging,
          },
          data: [],
          filters: {
            ...state.competitionsList.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };
    case competitionTypes.SET_COMPETITIONS_LIST:
      return {
        ...state,
        loading: {
          ...state.loading,
          competitionsList: false,
        },
        competitionsList: {
          ...state.competitionsList,
          data: action.data.competition,
          paging: {
            ...state.competitionsList.paging,
            count: action.data.total?.value,
            live: action.data.live?.value,
          },
        },
      };
      case competitionTypes.EDIT_COMPETITIONS_LIST:
        return {
          ...state,
          payload: {
            ...state.payload,
          },
        };
    default:
      return state;
  }
};
export default CompetitionReducer;
