import React from 'react';
import images from 'src/config/images';
import { Typography, Box, Container, Stack } from '@mui/material';
import { Button } from 'src/components/shared';

function Empty({ title, description, buttonTitle, onClick }) {
  return (
    <Container>
      <Stack alignItems="center" direction="column" gap={4} mt={6}>
        <img src={images.app.emptyInbox} />
        <Stack alignItems="center" direction="column" gap={1}>
          <Typography sx={{ fontWeight: 500 }}>{title}</Typography>
          <Typography variant="body2" color="textSecondary">
            {description}
          </Typography>
        </Stack>
        <Button variant="contained" color="secondary" onClick={onClick}>
          {buttonTitle}
        </Button>
      </Stack>
    </Container>
  );
}

export default Empty;
