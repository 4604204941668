import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, ButtonBase } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  // wrapper: {
  //   '& .MuiTypography-root, & .MuiSvgIcon-root': {
  //     color: 'rgba(0,0,0,0.38)',
  //     fill: 'rgba(0,0,0,0.38)',
  //   },
  //   '&:hover': {
  //     '& .MuiTypography-root, & .MuiSvgIcon-root': {
  //       color: 'rgba(0,0,0,0.60)',
  //       fill: 'rgba(0,0,0,0.60)',
  //     },
  //   },
  // },
}));

function BackButton({ text, onClick }) {
  const classes = useStyles();
  return (
    <ButtonBase onClick={onClick}>
      <NavigateBeforeIcon color="textSecondary" />
      <Typography variant="body2" color="textSecondary">
        {text}
      </Typography>
    </ButtonBase>
  );
}

BackButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};

BackButton.defaultProps = {
  onClick: () => {},
  text: 'Back',
};

export default BackButton;
