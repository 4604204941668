import React, { useState } from 'react';
import { Wrapper, NavBar, Content } from './styles';
import { Box, Typography } from '@mui/material';
import { Stepper, Button, Spacer } from 'src/components/shared';

const steps = ['Add Step', 'Settings', 'Launch'];

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

function SequenceNavBar({ sequence, onClose, activeStep, setActiveStep }) {
  return (
    <Wrapper>
      <NavBar>
        <Content>
          <Box display="flex" flex={1}>
            <Button color="secondary" startIcon={<ChevronLeftIcon />} onClick={onClose}>
              <Typography color="secondary">{sequence?.name}</Typography>
            </Button>
          </Box>
          <Box display="flex" flex={1} justifyContent="center">
            <Stepper
              steps={steps}
              activeStep={activeStep}
              onChange={(step) => {
                if (step < activeStep) {
                  setActiveStep(step);
                }
              }}
            />
          </Box>
          <Box display="flex" flex={1} justifyContent="flex-end">
            <Button style={{ color: 'rgba(0,0,0,0.36)' }} onClick={onClose}>
              Cancel
            </Button>
            <Spacer basis={2} />
            <Button
              variant="contained"
              color="secondary"
              onClick={(e) => {
                if (activeStep !== 1) {
                  setActiveStep(activeStep + 1);
                  e.preventDefault();
                } else {
                }
              }}
              {...{
                ...(activeStep === 1 && {
                  type: 'submit',
                  form: 'sequence-settings-form',
                }),
              }}
            >
              Next
            </Button>
          </Box>
        </Content>
      </NavBar>
    </Wrapper>
  );
}

export default SequenceNavBar;
