import { makeStyles } from '@mui/styles';
import { themeColors } from '../../config';

const useStyles = makeStyles(({ palette }) => ({
  headingContainer: {
    display: 'flex',
    justifyContent: 'left',
    '& .leagueHeading': {
      color: '#fff',
      fontSize: '35px',
      fontWeight: '300',
    },
    '& p': { color: '#9DADBA', fontSize: '36px' },
    '& .heading': {
      color: '#F28AE2',
      marginLeft: '20px',
    },
  },
  header: {
    fontSize: '24px',
    fontWeight: '700',
    color: themeColors.white,
    marginBottom: '30px',
  },
  name: {
    color: themeColors.blueWishGrey,
    fontSize: '16px !important',
    fontWeight: '600 !important',
  },
  emptyTitle: {
    color: themeColors.skyBlue,
    fontSize: '24px',
    fontWeight: '400',
    marginTop: '48px',
  },
  btn: {
    backgroundColor: 'rgba(19, 222, 161, 0.12)',
    color: themeColors.greenText,
    outline: 'none',
    border: 'none',
    borderRadius: '30px',
    minWidth: '70px',
    padding: '10px 12px',
    textTransform: 'capitalize',
  },
  completed: { backgroundColor: 'rgba(246, 242, 214, 0.12)', color: '#F6F2D6' },
  live: {},
  draft: { backgroundColor: 'rgba(160, 190, 217, 0.10)', color: '#A0BED9' },
  pendingStart: {
    textTransform: 'none',
    backgroundColor: 'rgba(217, 160, 179, 0.10)',
    color: '#D9A0B3',
  },
  reportCell: {
    color: themeColors.yellow,
    marginRight: '20px',
  },
  leagueCell: {
    color: '#F28AE2',
  },
  reportElement: {
    width: '90px',
    marginLeft: '10px',
    marginRight: '10px',
  },
  tableContainer: {
    padding: '16px',
    borderRadius: '6px',
    backgroundColor: '#082035',
    '& .MuiTableBody-root th,td': {
      padding: '10px 20px',
    },
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    '& .MuiTableCell-body': {
      fontWeight: '300 !important',
    },
    '& .MuiTableCell-head': {
      padding: '10px 20px',
    },
    '& .MuiTableHead-root': {
      backgroundColor: '#031627',
      '& .MuiTableCell-root': {
        color: '#fff !important',
        border: 'none',
      },
    },
    '& .MuiTableBody-root': {
      '& .MuiTableCell-root': {
        color: '#8CA7BE !important',
        borderColor: '#1B3C59',
        fontSize: '16px',
        fontWeight: '400 !important',
      },
    },
  },
  progressContainer: {
    display: 'flex',
    paddingRight: '10px',
    backgroundColor: themeColors.bg,
    alignItems: 'center',
    // justifyContent: 'center',
    borderRadius: '15px 6px 6px 15px',
    color: '#FF6F6C',
    '& .MuiLinearProgress-root': {
      width: '250px',
      backgroundColor: '#406180',
      borderRadius: '10px',
      height: '8px',
      marginRight: '10px',
    },
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#FF6F6C',
    },
  },
  progressInfo: {
    color: 'white',
    marginRight: '10px',
  },
  prizeIcon: {
    backgroundColor: themeColors.greenText,
    marginRight: '10px',
    '& hover': {
      backgroundColor: themeColors.greenText,
    },
  },
  progressContainer_green: {
    color: '#13DEA1',

    '& .MuiLinearProgress-bar': {
      backgroundColor: '#13DEA1',
    },
  },
  topGridHeading: {
    fontWeight: '300',
  },
  topContainer: {
    margin: '20px 0',
    backgroundColor: '#082035',
    color: '#fff',
    padding: '30px 20px',
    // paddingBottom: '30px',
    borderRadius: '6px',
    '& .topGrid': {
      width: '374px',
    },
    '& .performer_row': {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '20px',
      '& .topGrid': {
        borderRight: '1px solid #1B3C59',
        padding: '0 15px',
        '&:last-child': {
          border: 'none',
        },
      },
    },
    '& .performer_column': {
      display: 'flex',
      flexDirection: 'column',
      '& .topGrid': {
        borderBottom: '1px solid #1B3C59',
        padding: '15px 0',
        '&:last-child': {
          border: 'none',
        },
      },
    },
  },
  medal: {
    // marginRight: '10px',
  },
  avatar: {
    // margin: '0 10px',
    // maxWidth: '92px',
    // maxHeight: '92px',
    objectFit: 'center',

    maxWidth: '100%',
    // maxHeight: '100%',
  },
  avatar_top: {
    // margin: '0 10px',
    borderRadius: '50%',
    width: '92px',
    height: '92px',
  },
  span: {
    color: '#8CA7BE',
    fontSize: '14px',
  },
  green: {
    color: themeColors.greenText,
  },
  btn_score: {
    backgroundColor: '#13dea1',
    color: '#082035',
    outline: 'none',
    border: 'none',
    borderRadius: '70px',
    fontSize: '16x',
    padding: '5px',
    width: '64px',
  },
  btn_score_red: {
    backgroundColor: '#EF5350',
  },
  p: { margin: 0, marginRight: '17px' },
  box: {
    margin: '8px 0',
  },
  sidebar: {
    padding: '0 20px',
    // marginLeft: '10px',
  },
  topHeading: {
    color: '#FFD47E',
    fontSize: '18px',
    marginBottom: '5px',
  },
  prizeContainer: {
    backgroundColor: '#061726',
    padding: '24px',
    borderRadius: '6px',
    margin: '10px 0',
  },
  prize: {
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '700',
    fontFamily: 'Inter',
    margin: '0% 6% 0% 0%',
  },
  tableAvatar: {
    height: '40px',
    width: '40px',
    // objectFit: 'center',
    margin: '0 5px',
    borderRadius: '50%',
    marginRight: '16px',
  },
  tableMedal: {
    height: '34px',
    width: '22px',
    margin: '0 5px',
    marginRight: '16px',
  },
  tableSpan: {
    margin: '0 10px',
    marginRight: '22px',
  },
  topPerformer: {
    '&:nth-child(1)': {
      '& .username': {
        color: '#D7ECFF !important',
        fontSize: '20px',
      },
    },
    '&:nth-child(2)': {
      '& .username': {
        fontSize: '18.5px',
      },
    },
    '&:nth-child(3)': {
      '& .username': {
        fontSize: '17px',
      },
    },
  },

  userRow: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
  },

  selectDropdown: { color: 'white!important', backgroundColor: '#031727' },
  menuItem: {
    color: 'white!important',
    '&:hover': {
      backgroundColor: '#3b3f58',
      color: 'white',
    },
  },
  descriptionTooltip: {
    borderRadius: '6px',
    background: 'linear-gradient(106deg, #000 -4.89%, #151F2E 88.85%, #232C38 88.86%)',
    display: 'flex',
    padding: '20px',
    fontSize: '14px',
    height: 'fit-content',
    width: '500px',
  },
  customTooltip: {
    backgroundColor: 'transparent',
  },
  customAutoComplete: {
    '& label': {
      color: '#CCCCCC !important',
    },
    '& .MuiInputBase-root': {
      border: 'none',
      borderRadius:'5px',
      background: themeColors.popUpFields,
      '& svg': {
        color: themeColors.popUpText,
      },
    },
  },
}));
export default useStyles;
