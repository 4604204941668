import React, { useState, useEffect } from 'react';
import { Typography, Link as MuiLink, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

import StarIcon from '@mui/icons-material/Star';
import { capitalizeName } from 'src/modules/contacts/utils/contactUtils';

export function useColumns(data, type, tenantId) {
  const [columns, setColumns] = useState([]);

  const getResellerColumns = () => {
    return [
      {
        name: 'reseller',
        label: 'Reseller Name',
        options: {
          customBodyRenderLite: (index) => {
            const rowData = data[index];
            return (
              <Link to={`/admin/resellers/dashboard/tenant/${rowData?.id}`}>
                <Stack alignItems="center" direction="row" gap={1}>
                  <StarIcon color="secondary" />
                  <Typography
                    variant="body2"
                    color="secondary"
                    sx={{ textDecoration: 'underline' }}
                  >
                    {capitalizeName(rowData?.name)}
                  </Typography>
                </Stack>
              </Link>
            );
          },
        },
      },
      {
        name: 'enrolments',
        label: 'Referrals',
        options: {
          setCellHeaderProps: (value) => ({
            style: { width: 200 },
          }),
          setCellProps: () => ({
            style: { width: 200 },
          }),
          customBodyRenderLite: (index) => {
            const rowData = data[index];
            return <Typography>{rowData?.stats?.totalEnrollments || 0}</Typography>;
          },
        },
      },
      {
        name: 'users',
        label: 'Follow-ups',
        options: {
          setCellHeaderProps: (value) => ({
            style: { width: 200 },
          }),
          setCellProps: () => ({
            style: { width: 200 },
          }),
          customBodyRenderLite: (index) => {
            const rowData = data[index];
            return <Typography>{rowData?.stats?.partnerUsersAssigned || 0}</Typography>;
          },
        },
      },
    ];
  };

  const getTenantColumns = () => {
    return [
      {
        name: 'user',
        label: 'User',
        options: {
          customBodyRenderLite: (index) => {
            const rowData = data[index];
            return (
              <Link to={`/admin/resellers/dashboard/tenant/${tenantId}/user/${rowData?._id}`}>
                <MuiLink variant="body2" color="secondary">
                  {rowData?.title} {rowData?.fname} {rowData?.lname}
                </MuiLink>
              </Link>
            );
          },
        },
      },
      {
        name: 'noOfContactsAssigned',
        label: 'Contacts Assigned',
        options: {
          setCellHeaderProps: (value) => ({
            style: { width: 140 },
          }),
          setCellProps: () => ({
            style: { width: 140 },
          }),
          customBodyRenderLite: (index) => {
            const rowData = data[index];
            return <Typography>{rowData?.stats?.noOfContactsAssigned || 0}</Typography>;
          },
        },
      },
      {
        name: 'noOfContactsEnrolled',
        label: 'Contacts Enrolled',
        options: {
          setCellHeaderProps: (value) => ({
            style: { width: 140 },
          }),
          setCellProps: () => ({
            style: { width: 140 },
          }),
          customBodyRenderLite: (index) => {
            const rowData = data[index];
            return <Typography>{rowData?.stats?.noOfContactsEnrolled || 0}</Typography>;
          },
        },
      },
      // {
      //   name: 'noOfResellers',
      //   label: 'Resellers',
      //   options: {
      //     setCellHeaderProps: (value) => ({
      //       style: { width: 140 },
      //     }),
      //     setCellProps: () => ({
      //       style: { width: 140 },
      //     }),
      //     customBodyRenderLite: (index) => {
      //       const rowData = data[index];
      //       return <Typography>{rowData?.stats?.noOfResellers || 0}</Typography>;
      //     },
      //   },
      // },
    ];
  };

  const getUserColumns = () => {
    return [
      {
        name: 'user',
        label: 'Contact',
        options: {
          customBodyRenderLite: (index) => {
            const rowData = data[index];
            return (
              <MuiLink variant="body2" color="secondary">
                {rowData?.title} {rowData?.fname} {rowData?.lname}
              </MuiLink>
            );
          },
        },
      },
      // {
      //   name: 'reseller',
      //   label: 'Reseller',
      //   options: {
      //     customBodyRenderLite: (index) => {
      //       const rowData = data[index];
      //       return <Typography variant="body2">{rowData?.resellerTenant?.name}</Typography>;
      //     },
      //   },
      // },
      {
        name: 'sequenceName',
        label: 'Partner Sequence',
        options: {
          customBodyRenderLite: (index) => {
            const rowData = data[index];
            return <Typography variant="body2">{rowData?.sequenceName || '-'}</Typography>;
          },
        },
      },

      // {
      //   name: 'noOfContactsAssigned',
      //   label: 'Contacts Assigned',
      //   options: {
      //     setCellHeaderProps: (value) => ({
      //       style: { width: 140 },
      //     }),
      //     setCellProps: () => ({
      //       style: { width: 140 },
      //     }),
      //     customBodyRenderLite: (index) => {
      //       const rowData = data[index];
      //       return <Typography>{rowData?.stats?.noOfContactsAssigned || 0}</Typography>;
      //     },
      //   },
      // },
      // {
      //   name: 'noOfContactsEnrolled',
      //   label: 'Contacts Enrolled',
      //   options: {
      //     setCellHeaderProps: (value) => ({
      //       style: { width: 140 },
      //     }),
      //     setCellProps: () => ({
      //       style: { width: 140 },
      //     }),
      //     customBodyRenderLite: (index) => {
      //       const rowData = data[index];
      //       return <Typography>{rowData?.stats?.noOfContactsEnrolled || 0}</Typography>;
      //     },
      //   },
      // },
      // {
      //   name: 'noOfResellers',
      //   label: 'Resellers',
      //   options: {
      //     setCellHeaderProps: (value) => ({
      //       style: { width: 140 },
      //     }),
      //     setCellProps: () => ({
      //       style: { width: 140 },
      //     }),
      //     customBodyRenderLite: (index) => {
      //       const rowData = data[index];
      //       return <Typography>{rowData?.stats?.noOfResellers || 0}</Typography>;
      //     },
      //   },
      // },
    ];
  };

  useEffect(() => {
    if (type === 'tenant') {
      setColumns(getTenantColumns());
    } else if (type === 'user') {
      setColumns(getUserColumns());
    } else {
      setColumns(getResellerColumns());
    }
  }, [data]);

  return columns;
}
