import React, { useMemo } from 'react';
import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { Spacer } from 'src/components/shared';

import { useResellerSummary } from '../../utils/resellerStore';
import randomcolor from 'randomcolor';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const ReferralChart = () => {
  const { referralSummary } = useResellerSummary();
  const { stats } = referralSummary;

  const data = useMemo(() => {
    return stats.map((item) => ({
      name: `${item.fname} ${item.lname}`,
      value: item.total,
      color: randomcolor({ luminosity: 'bright', seed: `${item.fname} ${item.lname}` }),
    }));
  }, [stats]);

  return (
    <Grid container>
      <Grid item md={7} xs={12}>
        <>
          <Card>
            <CardContent>
              <Typography variant="h3">Distribution of Referrals</Typography>
              <Spacer x={2} y={2} />
              <Stack gap={4} direction="row">
                <ResponsiveContainer width={240} height={240}>
                  <PieChart width={240} height={240}>
                    <Pie
                      data={data}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={120}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {data.map((entry, index) => (
                        <Cell key={`referrals-cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                <Stack direction="column" gap={0.5} flex={1}>
                  {data.map((item, index) => (
                    <Stack
                      gap={1}
                      direction="row"
                      key={`referrals-label-${index}`}
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '8px',
                          background: item.color,
                        }}
                      />
                      <Typography variant="body2" color="textSecondary">
                        {/* {item.name} - {item.value}% */}
                        {item.name}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </>
      </Grid>
    </Grid>
  );
};

export default ReferralChart;
