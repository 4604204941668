import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import { Spacer } from 'src/components/shared';
import { Alert } from '@mui/material';

function RegisterVerification(props) {
  const email = props.router.location?.query?.email || '';

  return (
    <div style={{ maxWidth: 400, margin: '0 auto' }}>
      <Typography variant="h1" align="center">
        Email Verification
      </Typography>
      <Spacer x={4} y={4} />
      <Alert>
        We've sent an email to <b>{email}</b>.Click the link in the email to verify your account.
      </Alert>
      <Spacer x={3} y={3} />
      <Typography variant="body2" color="textSecondary">
        If you have not received an email, please check your Spam folder.
      </Typography>
    </div>
  );
}

const mapStateToProps = (state) => ({
  router: state.router,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterVerification);
