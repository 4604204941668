import React, { useState, useMemo, useEffect } from 'react';
import { AppGrid, ConfirmDialog } from 'src/components/App';
import { Modal, Spacer, Button } from 'src/components/shared';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useCompetitionColumns } from './useCompetitionColumns';
import { fetchSessionsLookup } from 'src/modules/users/api/usersApi';
import CreateCompetition from '../Competition/CreateCompetition';
import CompetitionForm from '../CompetitionForm';
import { competitionInvite, getCompetitions } from '../../api/competitionApi';
import toast from 'src/utils/toast';
import useStyles from './style';
import EmptyState from './EmptyState';
import { useSelector } from 'react-redux';

const CompetitionGrid = ({ fields, competitions, loading, ...props }) => {
  const classes = useStyles();
  const user = useSelector((state) => state.auth.user);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [competition, setCompetition] = useState('');
  const [schedule, setSchedule] = useState(false);
  const { data, paging, filters, sort } = competitions;
  const [competitionListLength, setCompetitionListLength] = useState();

  useEffect(() => {
    competitionList();
  }, []);
  const competitionList = async () => {
    let response = await getCompetitions();
    setCompetitionListLength(response?.total?.value);
  };
  const handleImmediateInviteChange = async (competition) => {
    try {
      let response = await competitionInvite(competition?.id);

      if (response) {
        toast.success('Invite Sent');
        handleCompetitionUpdate();
      }
    } catch (error) {
      toast.error('Invite not sent');
    }
  };

  const handleCompetitionEdit = (competition, sch) => {
    if (sch === true) {
      setSchedule(true);
    } else {
      setSchedule(false);
    }
    setModalOpen(true);
    setCompetition(competition);
  };
  const handleCompetitionUpdate = () => {
    props.fetchCompetitions(paging, filters, sort);
  };

  const CompetitionColumns = useCompetitionColumns(
    data,
    fields,
    props.onEdit,
    props.onDelete,
    props.toggleContactDrawer,
    handleCompetitionEdit,
    handleImmediateInviteChange,
  );
  const sessionFilters = [
    {
      key: 'status',
      title: 'All statuses',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: true,
      isPrimary: true,
      remoteMethod: async (value) => {
        return status();
      },
    },
    {
      key: 'sessionName',
      title: 'All sessions',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: true,
      searchable: true,
      isPrimary: true,
      remoteMethod: async (value) => {
        return fetchSessionsLookup(value);
      },
    },
  ];

  const handleTableChange = ({ pageNo, perPage }) => {
    props.fetchCompetitions(
      {
        pageNo,
        perPage,
      },
      filters,
    );
  };

  const onFilterChange = (key, value) => {
    if (value && typeof value !== 'undefined') {
      if (key === '_search') {
        props.fetchCompetitions(
          {
            ...paging,
            pageNo: 0,
          },
          { ...filters, [key]: value },
        );
      } else {
        props.fetchCompetitions(paging, { ...filters, [key]: value });
      }
    } else {
      delete filters[key];
      props.fetchCompetitions(paging, filters);
    }
  };

  const onClearFilters = () => {
    Object.keys(filters).forEach(function (filter) {
      if (filter != '_search') delete filters[filter];
    });
    props.fetchCompetitions(paging, filters);
  };

  const onSort = (sort) => {
    props.fetchCompetitions(paging, filters, sort);
  };
  const status = () => {
    return [
      {
        name: 'Live',
        value: 'live',
      },
      {
        name: 'Completed',
        value: 'completed',
      },
      {
        name: 'Draft',
        value: 'draft',
      },
      {
        name: 'Pending',
        value: 'pendingStart',
      },
    ];
  };
  return (
    <>
      {competitionListLength < 1 && loading === false ? (
        <EmptyState
          firstCompetition={true}
          handleCompetitionCreationModal={() => {
            setCompetition('');
            setModalOpen(true);
          }}
        />
      ) : (
        <AppGrid
          columns={CompetitionColumns.map((col, index) => ({
            ...col,
            options: CompetitionColumns?.columns?.length
              ? { ...col.options, ...CompetitionColumns?.columns[index] }
              : col.options,
          }))}
          data={data}
          onTableChange={handleTableChange}
          onFilterChange={onFilterChange}
          onClearFilters={onClearFilters}
          onColumnChange={() => handleColumnChange(false)}
          onSort={onSort}
          loading={loading}
          filters={sessionFilters}
          appliedFilters={filters}
          options={{
            sortOrder: {
              name: sort?.name || 'none',
              direction: sort?.direction || 'none',
            },
            serverSide: true,
            pagination: true,
            page: paging?.pageNo,
            rowsPerPage: paging?.perPage,
            count: paging?.count,
            searchText: filters?._search ? filters._search : '',
            rowsSelected: rowsSelected,
            onRowsSelect: (rowsSelected, allRows) => {
              setRowsSelected(allRows.map((row) => row.dataIndex));
            },
            textLabels: {
              body: {
                noMatch: (
                  <EmptyState
                    handleCompetitionCreationModal={() => {
                      setCompetition('');
                      setModalOpen(true);
                    }}
                  />
                ),
              },
            },
            renderCustomFilterActions: () => {
              if (user?.acl?.accessLevel === 'Manager' || user?.acl?.accessLevel === 'Admin') {
                return (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setCompetition('');
                      setModalOpen(true);
                    }}
                    className={classes.createButton}
                  >
                    Create competition
                  </Button>
                );
              }
            },
          }}
        />
      )}

      {/* <CreateCompetition
        competition={competition}
        open={modalOpen}
        schedule={schedule}
        handleCompetitionUpdate={handleCompetitionUpdate}
        handleOpen={() => setModalOpen(true)}
        {...props}
      /> */}

      <CompetitionForm
        open={modalOpen}
        competition={competition}
        handleClose={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};

export default CompetitionGrid;
