export const userBulkActions = [
  {
    title: 'Change Status',
    key: 'changeOfficeStatus',
  },

  {
    title: 'Change Country',
    key: 'changedCountry',
  },
  {
    title: 'Change Timezone',
    key: 'changedTimezone',
  },
];
