import { makeStyles } from '@mui/styles';

// common styling for sidebar
const useStyles = makeStyles(({ custom, ...theme }) => ({
  root: {
    padding: '8px 0px',
  },
  filterTitle: {
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  collapseWrapper: {
    // borderBottom: '1px solid #e0e0e0',
  },
  autoCompleteWrapper: {
    width: '100%',
    padding: '8px 0',
  },
  chip: {
    margin: theme.spacing(0.5),
    // backgroundColor: custom.colorCode.blueLightShade8,
  },
}));

export default useStyles;
