import React, { useEffect, useState } from 'react';
import { IconButton, Avatar } from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  Skeleton,
} from '@mui/lab';
import { uniqueId } from 'lodash';

function ListSkeleton() {
  const key = uniqueId('sequence-steps-skeleton');
  const [data, setData] = useState(['', '', '']);
  return (
    <Timeline>
      {data.map((item, index) => {
        return (
          <div key={`${key}-item1-${index}`}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height="60px"
              style={{ borderRadius: '4px' }}
            />
            <TimelineItem>
              <TimelineSeparator>
                <TimelineConnector />
                <Skeleton
                  variant="circular"
                  animation="wave"
                  width={36}
                  height={36}
                />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent></TimelineContent>
            </TimelineItem>
          </div>
        );
      })}
    </Timeline>
  );
}

export default ListSkeleton;
