import Collapse from '@mui/material/Collapse';
import React from 'react';
import GlobalSearch from '../GlobalSearch';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const MobileSearch = ({ showMobileSearch, toggleMobileSearch }) => {
  return (
    <Collapse in={showMobileSearch}>
      <Wrapper>
        <GlobalSearch />
        <IconButton onClick={toggleMobileSearch}>
          <CloseIcon />
        </IconButton>
      </Wrapper>
    </Collapse>
  );
};

export default MobileSearch;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 12px;
  margin-right: 4px;
`;
