import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { drawerWidth, drawerWidthCollapsed } from 'src/config/learning';

import GlobalSearch from '../GlobalSearch';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import UseMenu from './UseMenu';
import MobileSearch from './MobileSearch';
import useMediaQuery from 'src/components/hooks/useMediaQuery';

function LearningHeader({ collapsed, toggleCollapsed, handleDrawerToggle }) {
  const [showMobileSearch, setShowMobileSearch] = useState(false);
  const { isMobile, isDesktop, isTablet } = useMediaQuery();

  const toggleMobileSearch = () => {
    setShowMobileSearch(!showMobileSearch);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { md: `calc(100% - ${collapsed ? drawerWidthCollapsed : drawerWidth}px)` },
        ml: { md: `${drawerWidth}px` },
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Stack direction="row" alignItems="center" justifyContent="space-between" flex={1}>
          <Typography variant="h1" noWrap component="div" sx={{ fontWeight: 700 }}>
            Learning <span style={{ fontWeight: 400 }}>Platform</span>
          </Typography>
          {!isMobile && <GlobalSearch />}

          <UseMenu toggleMobileSearch={toggleMobileSearch} />
        </Stack>
      </Toolbar>

      {isMobile && (
        <MobileSearch toggleMobileSearch={toggleMobileSearch} showMobileSearch={showMobileSearch} />
      )}
    </AppBar>
  );
}

export default LearningHeader;
