import React, { useState } from 'react';
import { Typography, Box, ButtonGroup, Stack } from '@mui/material';
import { AppGrid, ConfirmDialog } from 'src/components/App';
import { Button, Form, Menu } from 'src/components/shared';
import { useEffect } from 'react';

import { useColumns } from './useColumns';

function CustomFieldsGrid({
  modules,
  attributes,
  openFormDrawer,
  module,
  deleteAttribute,
  onOrderDrawerOpen = () => {},
  ...props
}) {
  const entireData = modules[module];
  const [data, setData] = useState(entireData);
  const [search, setNewSearch] = useState('');
  const [deleteAttributeObj, setDeleteAttributeObj] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    setData(modules[module]);
  }, [modules, module]);

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await deleteAttribute(deleteAttributeObj.id);
      setIsDeleting(false);
      setDeleteAttributeObj({});
    } catch (error) {
      setIsDeleting(false);
    }
  };

  const onDelete = async (attribute) => {
    setDeleteAttributeObj(attribute);
  };

  useEffect(() => {
    if (search) {
      const filtered = modules[module].filter((f) =>
        f.label.toLowerCase().includes(search.toLowerCase()),
      );
      setData(filtered);
    } else {
      setData(entireData);
    }
  }, [search]);

  const onFilterChange = (key, value) => {
    if (key === '_search') {
      setNewSearch(value);
    }
  };

  const onCheckClick = async (field, name, val) => {
    const payload = {
      ...(name == 'status'
        ? {
            [name]: val,
            fieldOptions: field.fieldOptions,
          }
        : {
            fieldOptions: {
              ...field.fieldOptions,
              [name]: val,
            },
          }),
    };
    return props.putAttribute(field.id, payload);
  };

  const attributesColumns = useColumns(data, onCheckClick, onDelete, props.onEdit);

  const fieldFilters = [
    {
      key: 'status',
      title: 'Status',
      type: 'dropdown',
      remote: false,
      options: [
        { id: 'active', name: 'Active' },
        { id: 'inactive', name: 'Inactive' },
      ],
      optLabel: 'name',
      optValue: 'id',
      cancellable: true,
      searchable: false,
      isPrimary: true,
      multiple: false,
    },
  ];

  return (
    <>
      <AppGrid
        //selectableRows={'multiple'}
        columns={attributesColumns.map((col, index) => ({
          ...col,
          options: attributesColumns?.columns?.length
            ? { ...col.options, ...attributesColumns?.columns[index] }
            : col.options,
        }))}
        onFilterChange={(key, val) => onFilterChange(key, val)}
        data={data}
        className="lookupsGrid"
        filters={{}}
        title="Contact Fields"
        titleProps={{
          variant: 'h2',
        }}
        options={{
          sortOrder: {
            name: 'none',
            direction: 'none',
          },
          rowsSelected: [],
          serverSide: false,
          pagination: true,
          searchPlaceholder: 'Search Field',
          search: true,
          searchOpen: true,
          rowsPerPage: 10,
          download: false,
          viewColumns: false,
          selectToolbarPlacement: 'replace',
          renderCustomFilterActions: () => {
            return (
              <Stack direction="row" spacing={1.5}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="medium"
                  disableElevation
                  onClick={() => {
                    onOrderDrawerOpen('form');
                  }}
                >
                  <Typography variant="body2">Change field order</Typography>
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="medium"
                  onClick={() => {
                    openFormDrawer();
                  }}
                >
                  <Typography variant="body2">Add custom field</Typography>
                </Button>
              </Stack>
            );
          },
        }}
      />

      <ConfirmDialog
        title="Delete Field"
        open={deleteAttributeObj?.id && deleteAttributeObj?.id !== '' ? true : false}
        onClose={() => {
          setDeleteAttributeObj({});
        }}
        onConfirm={handleDelete}
        loading={isDeleting}
      >
        <Typography variant="body1" color="textSecondary">
          Are you sure you want to delete <b>{deleteAttributeObj?.label}</b>
        </Typography>
      </ConfirmDialog>
    </>
  );
}

export default CustomFieldsGrid;
