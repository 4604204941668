import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import styled from 'styled-components';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import darkTheme from 'src/config/darkTheme';
import Slider from 'react-slick';
import useMediaQuery from 'src/components/hooks/useMediaQuery';
import { Spacer } from 'src/components/shared';

function AppSlider({ arrows, dots, children, slidesToShow, padding, fade, ...props }) {
  const sliderRef = useRef(null);
  const { isMobile } = useMediaQuery();
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const navigateTo = (index) => {
    sliderRef.current.slickGoTo(index);
  };

  const Next = () => {
    const totalSlides = sliderRef?.current ? sliderRef?.current.props?.children.length : 0;
    return (
      <Arrow
        onClick={() => {
          sliderRef?.current?.slickNext();
        }}
        disabled={currentIndex == totalSlides - 1}
      >
        <ChevronRightIcon />
      </Arrow>
    );
  };

  const Prev = () => {
    return (
      <Arrow
        onClick={() => {
          sliderRef?.current?.slickPrev();
        }}
        disabled={currentIndex == 0}
      >
        <ChevronLeftIcon />
      </Arrow>
    );
  };

  const Dots = () => {
    return (
      <Stack direction="row" gap={1} alignItems="center">
        {children.map((item, index) => (
          <Dot
            key={`slider-${index}`}
            active={currentIndex === index}
            onClick={() => navigateTo(index)}
          />
        ))}
      </Stack>
    );
  };

  return (
    <Wrapper padding={padding} fade={fade}>
      <Slider
        ref={sliderRef}
        slidesToShow={slidesToShow}
        afterChange={(newIndex) => {
          setCurrentIndex(newIndex);
        }}
        slidesPerView="auto"
        {...props}
        dots={false}
        arrows={false}
        infinite={false}
      >
        {children}
      </Slider>
      <Spacer x={2} y={2} />
      {arrows && (
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          {...(isMobile
            ? {
                justifyContent: 'space-between',
              }
            : {
                justifyContent: 'center',
              })}
        >
          <Prev />
          <Dots />
          <Next />
        </Stack>
      )}
    </Wrapper>
  );
}

AppSlider.propTypes = {
  slidesToShow: PropTypes.number,
  padding: PropTypes.number,
  arrows: PropTypes.bool,
  dots: PropTypes.bool,
  fade: PropTypes.bool,
};
AppSlider.defaultProps = {
  slidesToShow: 1,
  padding: 0,
  arrows: false,
  dots: false,
  fade: false,
};

export default AppSlider;

const Wrapper = styled.div`
  position: relative;
  & .slick-track {
    margin-left: 0;
  }
  & .slick-slide > div {
    padding: 0 ${(props) => (props.padding && props.padding > 0 ? props.padding / 2 : 0)}px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -2px;
    width: ${(props) => (props?.fade ? '48px' : '0px')};
    height: 100%;
    background: linear-gradient(90deg, #0c1721 0%, rgba(12, 23, 33, 0) 100%);
    z-index: 99;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -2px;
    width: ${(props) => (props?.fade ? '48px' : '0px')};
    height: 100%;
    background: linear-gradient(90deg, rgba(12, 23, 33, 0) 0%, #0c1721 100%);
    z-index: 100;
  }
`;

const Dot = styled.div`
  background-color: ${(props) =>
    props.active ? darkTheme.palette.secondary.main : `${darkTheme.palette.secondary.main}30`};
  width: 8px;
  height: 8px;
  border-radius: 8px;
  cursor: pointer;
`;

const Arrow = styled(IconButton)`
  padding: 4px;
  z-index: 101;
  & svg {
    color: ${(props) => (props.disabled ? '#ffffff24' : '#ffffff80')};
    fill: ${(props) => (props.disabled ? '#ffffff24' : '#ffffff80')};
  }
`;
