import * as types from '../actions/authTypes';
import { RESET_STORE } from 'src/modules/app/actions/appTypes';

const initialState = {
  token: '',
  isAuthenticated: false,
  isEmailPlugIn: {},
  user: {},
  welcomeModal: false,
  newUser: false,
  status: false,
  profileImage: '',
  error: {
    login: '',
  },
  replacementVariables: [],
  emailReplacementVariables: [],
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGOUT:
      return {
        ...state,
        user: {},
        isAuthenticated: false,
      };

    // case types.GET_CURRENT_USER:
    //   return {
    //     ...state,
    //     user: {},
    //     isAuthenticated: true,
    //   };

    case types.SET_AUTH:
      return {
        ...state,
        user: action.data,
        isAuthenticated: true,
      };

    case types.USER_LOGIN_ERROR:
      return {
        ...state,
        user: { ...state.user, userLoginError: action.error ?? null },
      };

    case types.SET_CURRENT_USER:
      return {
        ...state,
        user: action.payload.userData.user,
        isAuthenticated: true,
      };

    case types.SET_USER_ID:
      return {
        ...state,
        user: { ...state.user, ...action.payload.userData.user },
        isAuthenticated: true,
        status: true,
      };

    case types.SHOW_WELCOME_MODAL:
      return {
        ...state,
        welcomeModal: action.payload.welcomeModal,
        newUser: action.payload.newUser,
      };

    case types.SET_EMAIL_TOKEN:
      return {
        ...state,
        user: { ...state.user, ...action.payload.userData },
        isAuthenticated: true,
        status: true,
      };

    case types.SET_IMAGE_UPLOAD:
      return {
        ...state,
        profileImage: action.payload.imageData.upload.url,
      };
    case types.DELETE_UPLOAD_IMAGE:
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, {
          userImage: '',
        }),
        profileImage: '',
      });

    case types.SET_EMAIL_PLUGIN:
      return {
        ...state,
        isEmailPlugIn: action.data,
      };

    case RESET_STORE:
      return initialState;

    default:
      return state;
  }
};
export default AppReducer;
