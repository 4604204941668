export const teamBulkAction = [
  {
    title: 'Change Status',
    key: 'changeStatus',
  },

  {
    title: 'Change team lead',
    key: 'changedTeamLead',
  },
];
