import React, { useEffect, useState } from 'react';
import useStyles from './style';
import { Breadcrumbs, Typography, Grid, Button, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { themeColors } from 'src/modules/lms/config';
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Stepper from '../IntroStepper/IntroStepper';
import IntroStepper from '../IntroStepper/IntroStepper';
import { Dropdown, Form } from 'src/components/shared';
import Chip from '@mui/material/Chip';
import { Search } from '@mui/icons-material';
import { fetchTemplates, createTemplate } from '../../api/LMSApis';
import { useHistory, useLocation } from 'react-router-dom';
import Step3 from './Step3';
import toast from 'src/utils/toast';

function CreateTemplate(props) {
  let history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [template, setTemplate] = useState({});
  const [newTemplate, setNewTemplate] = useState(null);
  const [fetchingTemplates, setFetchingTemplates] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const fetchTemplate = async (val, type) => {
    const newTemplates = await fetchTemplates({ _search: val });

    return newTemplates;
  };

  const handleSubmit = async (values, status) => {
    const payload = { content: values.content, source: 'admin', status: status };
    try {
      const res = await createTemplate(payload);
      setNewTemplate(res.template);
      history.push(`/lms/MyTemplates`);
      toast.success('Template Created');
    } catch (error) {}
  };

  useEffect(() => {
    if (newTemplate) setActiveStep(2);
  }, [newTemplate]);

  const currentUrl = props?.location?.pathname;
  const breadcrumbs = [
    <Link underline="hover" key="1" className={classes.links} href="/lms">
      SuperReach Learning
    </Link>,
    <Link
      underline="hover"
      key="2"
      className={classes.links}
      href={currentUrl ? currentUrl : '/lms'}
    >
      Creating Interest
    </Link>,
    <Link
      underline="hover"
      key="2"
      className={classes.links}
      href={currentUrl ? currentUrl : '/lms'}
    >
      Session
    </Link>,
    <Link
      underline="hover"
      key="2"
      className={classes.links}
      href={currentUrl ? currentUrl : '/lms'}
    >
      Intro, Engage and Bridge
    </Link>,
  ];
  const Step1 = () => {
    return (
      <>
        <Form
          initialValues={{
            template: [],
          }}
          enableReinitialize={true}
          onSubmit={() => {}}
        >
          {({ values, ...formProps }) => {
            return (
              <>
                <form onSubmit={() => {}} style={{ height: '100%' }} noValidate>
                  <Box display="flex" flexDirection="row" alignItems="center" width={300}>
                    <Form.Field.AutoComplete
                      options={[]}
                      fullWidth
                      className={classes.form}
                      variant="outlined"
                      clearOnEscape={true}
                      name="template"
                      placeholder="Select Existing Template"
                      groupBy={(option) => option?.accessType || ''}
                      getOptionLabel={(option) => option.name || ''}
                      onChange={(val) => {
                        if (val) {
                          setTemplate(val);
                          setActiveStep(1);
                        }
                      }}
                      remoteMethod={(val) => {
                        return fetchTemplate(val);
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          <Grid container spacing={0}>
                            {option.name}
                          </Grid>
                        </Box>
                      )}
                      loading={fetchingTemplates}
                      optLabel="name"
                      optValue="id"
                    />
                  </Box>
                  <Box display={'flex'} justifyContent={'right'}>
                    <Typography
                      color={themeColors.greenText}
                      fontSize={16}
                      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => {
                        if (location.key) {
                          history.goBack();
                        } else {
                          history.push('/contacts');
                        }
                      }}
                    >
                      Skip this step
                    </Typography>
                  </Box>
                </form>
              </>
            );
          }}
        </Form>
      </>
    );
  };
  const Step2 = () => {
    return (
      <>
        {/* <Dropdown className={classes.Dropdown} title={'LinkedIn Template 30'} /> */}

        <Form
          initialValues={{
            content: template.content,
            template: [template],
          }}
          enableReinitialize={true}
          onSubmit={() => {}}
        >
          {({ values, ...formProps }) => {
            return (
              <>
                <form onSubmit={() => {}} style={{ height: '100%' }} noValidate>
                  <Box display="flex" flexDirection="row" alignItems="center" width={300}>
                    <Form.Field.AutoComplete
                      options={[]}
                      fullWidth
                      className={classes.form}
                      variant="outlined"
                      clearOnEscape={true}
                      name="template"
                      placeholder="TESTJ"
                      groupBy={(option) => option?.accessType || ''}
                      getOptionLabel={(option) => option.name || ''}
                      onChange={(val) => {
                        if (val) {
                          setTemplate(val);
                          formProps.setFieldValue('content', val.content);
                          setShowForm(true);
                        }
                      }}
                      remoteMethod={(val) => {
                        return fetchTemplate(val);
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          <Grid container spacing={0}>
                            {option.name}
                          </Grid>
                        </Box>
                      )}
                      loading={fetchingTemplates}
                      optLabel="name"
                      optValue="id"
                    />
                  </Box>
                  <div className={classes.container}>
                    <Box className={classes.form}>
                      <Form.Field.TextEditor fullWidth variant="outlined" name="content" />
                    </Box>
                    <Box display={'flex'} justifyContent={'right'}>
                      {' '}
                      <Button
                        className={`${classes.buttonTrans} ${classes.btnAlignment}`}
                        onClick={() => handleSubmit(formProps.getFieldProps().value, 'draft')}
                      >
                        Save As Draft
                      </Button>
                      <Button
                        className={`${classes.button} ${classes.btnAlignment}`}
                        onClick={() => handleSubmit(formProps.getFieldProps().value, 'published')}
                      >
                        Next
                      </Button>
                    </Box>
                  </div>
                </form>
              </>
            );
          }}
        </Form>
      </>
    );
  };

  const Steps = [Step1(), Step2(), <Step3 template={newTemplate} />];

  return (
    <Box>
      <Grid container spacing={2} mb={5}>
        <Grid item xs={12}>
          <Box mb={2}>
            <Breadcrumbs
              separator={
                <NavigateNextIcon fontSize="small" style={{ color: themeColors.greyText }} />
              }
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Box>
          <Box mb={3}>
            <Typography fontSize={40} fontWeight={400} style={{ color: '#D2D2D2' }}>
              Create your intro
            </Typography>
          </Box>
          <Box mb={7}>
            <Typography variant="h1" mb={2} className={classes.heading}>
              Intro, Engage and Bridge Session
            </Typography>
            <Typography variant="p" className={classes.paragraph}>
              To complete this Super Session, create your own Introductory script template based on
              the tips and guidance provided in the video session. Once completed you can share the
              script with your manager for review and feedback. A copy of your script can be found
              in My Templates.
            </Typography>
          </Box>
          <Box>
            <Stepper stepData={Steps} activeStepData={activeStep} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CreateTemplate;
