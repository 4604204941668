import React from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Stack';
import images from 'src/config/images';

function MissingImage({ title }) {
  return (
    <Stack direction="column" gap={2} alignItems="center" justifyContent="center">
      <img src={images.LMS.imagePlaceHolder} style={{ width: '32px' }} />
      <Typography color="secondary" sx={{ fontSize: '14px', color: 'rgba(255,255,255,0.36)' }}>
        {title}
      </Typography>
    </Stack>
  );
}

MissingImage.propTypes = {
  title: PropTypes.string,
};

MissingImage.defaultProps = {
  title: 'Image Not Found',
};

export default MissingImage;
