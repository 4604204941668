import React, { useState, useEffect, useMemo } from 'react';
import { AccordionDetails, Typography, Box } from '@mui/material';
import ReplyItem, { Accordion, AccordionSummary } from './ReplyItem';
import TaskReplyForm from './TaskReplyForm';
import { Empty } from 'src/components/App';
import { SendEmailForm } from 'src/modules/contacts/components/ContactActions';
import { Drawer } from 'src/components/shared';
import { capitalizeFirstLetter } from 'src/utils/helper';

function TaskReplies({ showForm = false, isTaskError = false, ...props }) {
  const [expanded, setExpanded] = useState('');
  const [replyType, setReplyType] = useState(undefined);
  const [replyEmail, setReplyEmail] = useState({});

  const { user, contact, content, subject, status, template, taskType, sender } = props.task;
  const { data, paging, filters } = props.taskReplies;

  useEffect(() => {
    if (showForm === true) setReplyType('reply');
  }, [showForm]);

  // useEffect(() => {
  //   props.fetchTaskReplies(props.match.params.id, { ...paging, pageNo: 0 }, filters);
  // }, [props.match.params.id]);

  // useEffect(() => {
  //   if (data.length === 0) {
  //     setReplyType('reply');
  //     setReplyEmail({});
  //   }
  // }, [data]);

  const handleChange = (panel) => {
    setExpanded(panel);
  };

  const openReplyForm = (type, email) => {
    setReplyType(type);
    setReplyEmail(email);
  };

  const closeReplyForm = () => {
    setReplyType(undefined);
    setReplyEmail({});
  };

  const isSelectEmailAndPending = () => {
    return taskType === 'selectEmailTemplate' && status === 'pending' && !data?.length;
  };

  if (!data.length) {
    return (
      <>
        {isSelectEmailAndPending() ? (
          <Accordion expanded={true}>
            <Box sx={{ border: '1px solid #dddddd', boarderRadius: '4px', padding: '5px' }}>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Box display="flex" flex={1} alignItems="center" justifyContent={'center'}>
                  <Box>
                    <SendEmailForm
                      contact={contact}
                      sendContactEmail={props.sendContactEmail}
                      putContactEmail={props.putContactEmail}
                      task={props.task}
                      templateData={{
                        ...template,
                        taskId: props.task.id,
                        subject: subject,
                        content: content,
                        contactId: contact.id,
                        currentUserId: user?.id,
                        sender: sender,
                      }}
                      formProps={{
                        submitButtonText: 'Update',
                        showCancelButton: false,
                        dontShowEmailField: true,
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Accordion>
        ) : null}
        {replyType && typeof replyType !== 'undefined' ? (
          // not sure about the below
          <Accordion expanded={true}>
            <TaskReplyForm
              email={replyEmail}
              type={replyType}
              {...props}
              sendTaskReply={props.sendTaskReply}
              closeReplyForm={closeReplyForm}
              includeTemplateData
            />
          </Accordion>
        ) : null}
      </>
    );
  }

  return (
    <Box sx={{ border: '1px solid #dddddd', boarderRadius: '4px' }}>
      {data.map((reply, index) => {
        return (
          <ReplyItem
            key={`task-reply-${reply.id}-index-${index}`}
            data={reply}
            setExpanded={setExpanded}
            expanded={expanded}
            openReplyForm={openReplyForm}
            putContactEmail={props.putContactEmail}
            {...props}
          />
        );
      })}
      {replyType && typeof replyType !== 'undefined' && !isTaskError ? (
        <Drawer title={capitalizeFirstLetter(replyType)} open={replyType} onClose={closeReplyForm}>
          <TaskReplyForm
            email={replyEmail}
            type={replyType}
            {...props}
            sendTaskReply={props.sendTaskReply}
            closeReplyForm={closeReplyForm}
            includeTemplateData
          />
        </Drawer>
      ) : null}
    </Box>
  );
}

export default TaskReplies;
