import Integrations from '../utils/IntegrationsModel';

export const getSequenceFields = (state) => {
  return state.app.modules?.sequences ? state.app.modules?.sequences : [];
};

export const isTenantApprovedReseller = (state) => {
  const tenant = state.app.tenant;
  return tenant?.isReseller || false;
};

export const isTenantApprovedPartner = (state) => {
  const tenant = state.app.tenant;
  return tenant?.isPartner || false;
};

export const getInputReplacementVariables = (state) => {
  const replacementVariables = state.app.globals.replacementVariables;
  if (replacementVariables && replacementVariables.length) {
    return replacementVariables.filter((item) => !item.text.includes('Link'));
  }
  return [];
};

export const getContactFields = (state) => {
  return state.app.modules?.contacts ? state.app.modules?.contacts : [];
};

export const getTaskFields = (state) => {
  return state.app.modules?.tasks ? state.app.modules?.tasks : [];
};

export const getIntegrations = (state) => {
  const integrations = state.admin.admin.integrations ? state.admin.admin.integrations : [];
  return new Integrations(integrations);
};
