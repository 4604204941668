import React, { useMemo } from 'react';
import {
  Wrapper,
  TopPill,
  Background,
  Title,
  Type,
  Content,
  Gradient,
  AssignedIcon,
} from './styles';
import ProgressBar from '../ProgressBar';
import { Stack, Typography } from '@mui/material';
import { Spacer } from 'src/components/shared';
import { capitalizeFirstLetter } from 'src/utils/helper';
import { useHistory } from 'react-router-dom';
import { useLmsHomeProvider } from 'src/modules/lms/utils/lmsStore';

function Course({ course }) {
  const history = useHistory();

  const { assigned } = useLmsHomeProvider();

  const type = capitalizeFirstLetter(course?.categoryType || '');
  const thumbnailImage = course?.thumbnailImage || '';

  const handleClick = () => {
    history.push(`/lms/course/${course.id}`);
  };

  const isAssigned = useMemo(() => {
    return assigned.courses && assigned.courses.includes(course.id);
  }, [assigned, course]);

  return (
    <Wrapper onClick={handleClick}>
      <TopPill />
      <Background image={thumbnailImage}>
        <Gradient />
        <ProgressBar total={4} completed={0} />
        <Content>
          <Typography variant="body2" color="textSecondary" textAlign="left">
            4 SESSION LEFT
          </Typography>

          {/* <Stack alignItems="center">
            <Type>{type}</Type>
            <Title>Fundamentals</Title>
          </Stack> */}

          <Stack>
            <Typography color="textPrimary" sx={{ fontSize: '24px' }} textAlign="left">
              {course?.title}
            </Typography>
            <Spacer x={1} y={1} />
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" color="textSecondary" component="span">
                Course by{' '}
                <Typography variant="body2" color="textPrimary" component="span">
                  <b>SuperReach</b>
                </Typography>
              </Typography>
              {isAssigned && <AssignedIcon />}
            </Stack>
          </Stack>
        </Content>
      </Background>
    </Wrapper>
  );
}

export default Course;
