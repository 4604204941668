import { Box, Checkbox, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppGrid } from 'src/components/App';
import { Button, Modal, Spacer } from 'src/components/shared';

import ContactDetailsForm from 'src/modules/contacts/components/ContactDetails/ContactDetailsForm';

import EditEmail from '../outbox/EditEmail';
import TasksBulkActions from './TasksBulkActions';

import { useErrorsColumns } from './useErrorsColumns';

import {
  postContactDetails,
  updateContactSocialMedia,
} from 'src/modules/contacts/actions/contactActions';

import { errorBulkActions, taskErrorTypes } from '../../config';

import { fetchContactDetails, fetchEnrichSearch } from 'src/modules/contacts/api/contactApis';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';
import toast from 'src/utils/toast';
import { fetchSequencesLookup } from 'src/modules/sequence/api/sequenceApi';

const { INBOX_DISCONNECTED } = taskErrorTypes;
import { seqStatusTypes, stepTypes, errorTypes } from '../../config/index';

const errorsFilters = [
  {
    key: 'errorType_eq',
    title: 'Error Type',
    type: 'dropdown',
    optLabel: 'label',
    cancellable: true,
    isPrimary: true,
    options: errorTypes,
  },
  {
    key: 'stepType_eq',
    title: 'Step Type',
    type: 'dropdown',
    optLabel: 'label',
    optValue: 'value',
    cancellable: true,
    isPrimary: true,
    options: stepTypes,
  },
  {
    key: 'seqStatus_eq',
    title: 'Sequence Status',
    type: 'dropdown',
    optValue: 'value',
    cancellable: true,
    isPrimary: true,
    options: seqStatusTypes,
  },
  {
    key: 'sequence',
    title: 'Sequence Name',
    type: 'dropdown',
    remote: true,
    optLabel: 'name',
    optValue: 'id',
    cancellable: true,
    searchable: true,
    isPrimary: true,
    remoteMethod: async (value) => {
      return fetchSequencesLookup(value);
      // return fetchUsersLookup(value);
    },
  },
  {
    key: 'assignedTo',
    title: 'Enrolled by',
    type: 'dropdown',
    remote: true,
    optLabel: 'name',
    optValue: 'id',
    cancellable: true,
    searchable: true,
    isPrimary: true,
    remoteMethod: async (value) => {
      return fetchUsersLookup(value);
    },
  },
];

const ErrorsGrid = ({ taskErrors, loading, fetchTaskErrors, ...props }) => {
  const [rowsSelected, setRowsSelected] = useState([]);
  const [formType, setFormType] = useState(undefined);
  const [formData, setFormData] = useState(undefined);
  const [loader, setLoader] = useState(false);
  const [bulkAction, setBulkAction] = useState('');
  const [editTask, setEditTask] = useState({});
  const dispatch = useDispatch();

  const { data, filters, paging } = taskErrors;

  useEffect(() => {
    fetchTaskErrors(paging, filters);
  }, []);

  const formRef = useRef();

  const openForm = (type, value = undefined) => {
    if (type === 'template') {
      setEditTask({
        ...value.task,
        contact: {
          id: value.task.contact,
        },
      });
    } else if (type === 'social') {
      if (value && value !== '') {
        window.open(value, '_blank', 'noopener,noreferrer');
      } else {
        setFormType(type);
      }
    } else {
      if (value) {
        setFormData(value);
      }
      setFormType(type);
    }
  };

  const taskColumns = useErrorsColumns(data, openForm, props);

  const addContactDetails = (data, formType) => {
    return new Promise((resolve, reject) => {
      dispatch(postContactDetails(formData.contact.id, formType, data, resolve, reject));
    });
  };

  const addSocialDetails = (data, formType) => {
    if (data.linkedin !== '') {
      const links = { ...data };
      let payload = { linkedin: links.linkedin, facebook: '', instagram: '', twitter: '' };

      return new Promise((resolve, reject) => {
        dispatch(updateContactSocialMedia(formData.contact.id, payload, resolve, reject));
      });
    }
  };

  const onSkipSubmit = (formData, formType) => {
    onFormClose();

    props.putTask(formData.task.id, {
      status: 'completed',
    });
  };

  const findErrors = async (formType) => {
    try {
      setLoader(true);
      const type = formRef?.current?.values?.type || '';
      let payload = {};

      switch (true) {
        case formType === 'email':
          payload = {
            type: !type.includes('Email') ? `${type}Email` : type,
            id: formData.contact.id,
          };
          break;

        case formType === 'phone':
          payload = {
            type: type,
            id: formData.contact.id,
          };
          break;
      }

      if (formData.contact.id) {
        let contactDetails = await fetchContactDetails(formData.contact.id);
        contactDetails = contactDetails.contact;
        let linkedin = contactDetails.socialLinks.filter((item) => item.type === 'linkedin');
        if (linkedin && linkedin.length) {
          const pathname = new URL(linkedin[0].link).pathname;
          payload = {
            ...payload,
            link: pathname,
          };
        }
        const searchResult = await fetchEnrichSearch([payload]);
        const workEmail =
          searchResult?.contacts[0]?.emails?.length > 0
            ? searchResult.contacts[0].emails.find((element) => {
                return element.type === 'work';
              })?.email
            : '';

        const personalEmail =
          searchResult?.contacts[0]?.emails?.length > 0
            ? searchResult.contacts[0].emails.find((element) => {
                return element.type === 'personal';
              })?.email
            : '';

        const phone =
          searchResult?.contacts[0]?.phones?.length > 0
            ? searchResult.contacts[0].phones[0]?.phone?.number
            : '';

        const linkedIn = searchResult?.contacts[0]?.linkedin_url || '';

        switch (formType) {
          case 'email':
            setFormData({
              ...formData,
              email: workEmail,
            });
            break;
          case 'personal_email':
            setFormData({
              ...formData,
              email: personalEmail,
            });
            break;
          case 'phone':
            setFormData({
              ...formData,
              phone,
            });
            break;
          case 'linkedin':
            setFormData({
              ...formData,
              linkedin: linkedIn,
            });
            break;
        }
        toast.success(
          `${
            ['email', 'personal_email']?.includes(formType)
              ? workEmail || personalEmail
                ? `${formType === 'email' ? 'Work email' : 'Personal email'} fetched succesfully`
                : `No ${formType === 'email' ? 'work email' : 'personal email'} found`
              : ['phone']?.includes(formType)
              ? phone
                ? 'Phone number fetched succesfully'
                : 'No phone number found'
              : linkedIn
              ? 'LinkedIn fetched succesfully'
              : 'No linkedIn profile found'
          }`,
        );
      }
      setLoader(false);
    } catch (error) {
      toast.error(error?.error?.message || 'Error ocurred! Please try again.');
      setLoader(false);
    }
  };

  const onFormClose = () => {
    setFormData(undefined);
    setFormType(undefined);
  };

  const onSkipClose = () => {
    setFormData(undefined);
    setFormType(undefined);
  };

  const modalTitle = useMemo(() => {
    return 'Error';
  }, [formType, formData]);

  const modalSkipTitle = useMemo(() => {
    return 'Do you wish to proceed?';
  }, [formType, formData]);

  const onSubmit = async (data) => {
    if (data?.template && data?.template?.id) {
      props.putTask(formData?.task.id, { template: data.template.id });
      onFormClose();
    } else {
      try {
        if (formType === 'linkedin') {
          await addSocialDetails(data, data.type);
        } else {
          await addContactDetails(data, formType);
        }
        onFormClose();
        fetchTaskErrors(paging, filters);
      } catch (error) {
        return error;
      }
    }
  };

  const onBulkAction = (key) => {
    setBulkAction(key);
  };

  const handleClose = (refetch) => {
    setEditTask({});
    if (refetch) {
      fetchTaskErrors(paging, filters);
    }
  };

  const closeBulkAction = (key) => {
    setBulkAction(undefined);
    setRowsSelected([]);
  };

  const selectedTasks = useMemo(() => {
    return rowsSelected.length
      ? data.filter((item, index) => rowsSelected.includes(index)).map((k) => k.task)
      : [];
  }, [rowsSelected, data]);

  const onRowClick = (index, newIndex) => {
    const rData = data[newIndex.rowIndex];
    setRowsSelected(rData);
  };

  const handleTableChange = ({ pageNo, perPage }) => {
    fetchTaskErrors(
      {
        pageNo,
        perPage,
      },
      filters,
    );
  };

  const onFilterChange = (key, value) => {
    if (value && typeof value !== 'undefined') {
      if (key === '_search') {
        fetchTaskErrors(
          {
            ...paging,
            pageNo: 0,
          },
          { ...filters, [key]: value },
        );
      } else {
        fetchTaskErrors(paging, { ...filters, [key]: value });
      }
    } else {
      delete filters[key];
      fetchTaskErrors(paging, filters);
    }
  };

  const CustomCheckbox = useCallback((props) => {
    let flag = true;
    const { data } = props;
    let index = props['data-index'];
    if (index === null && props['data-description'] === 'row-select-header') flag = false; // skip header
    if (index === null && props['data-description'] === 'row-select') index = 0; // first index
    const currentRowData = index >= 0 ? data[index] : {};
    if (currentRowData?.type !== INBOX_DISCONNECTED) flag = false;
    return <Checkbox {...props} disabled={flag} onClick={props.onChange} />;
  }, []);

  return (
    <>
      <Modal open={formType === 'skip'} onClose={onSkipClose} title={modalSkipTitle} size="xs">
        <Typography variant="body2">
          The contact will skip this step and move to the next step in the outreach sequence.
        </Typography>
        <Box mt={2} display="flex" flexDirection="row" justifyContent="flex-end">
          <Button color="primary" onClick={onSkipClose}>
            Back
          </Button>
          <Button color="primary" type="submit" onClick={() => onSkipSubmit(formData, formType)}>
            Confirm
          </Button>
          <Spacer x={2} />
        </Box>
      </Modal>
      <Modal
        open={
          formType === 'email' ||
          formType === 'personal_email' ||
          formType === 'phone' ||
          formType === 'template' ||
          formType === 'linkedin'
        }
        onClose={onFormClose}
        title={modalTitle}
        size="xs"
      >
        <ContactDetailsForm
          formType={formType}
          formData={formData}
          onSubmit={onSubmit}
          onClose={onFormClose}
          rowsSelected={rowsSelected}
          findErrors={findErrors}
          loader={loader}
          formRef={formRef}
        />
      </Modal>
      <AppGrid
        selectableRows="multiple"
        components={{
          Checkbox: (props) => <CustomCheckbox {...props} data={data} />,
        }}
        columns={taskColumns.map((col, index) => ({
          ...col,
          options: taskColumns?.columns?.length
            ? { ...col.options, ...taskColumns?.columns[index] }
            : col.options,
        }))}
        data={data}
        onTableChange={handleTableChange}
        onFilterChange={onFilterChange}
        loading={loading?.taskErrors || false}
        filters={errorsFilters}
        appliedFilters={filters}
        bulkActions={errorBulkActions}
        onBulkAction={onBulkAction}
        options={{
          serverSide: true,
          pagination: true,
          page: paging.pageNo,
          rowsPerPage: paging.perPage,
          count: paging.count,
          selectableRows: 'multiple',
          searchPlaceholder: 'Search Tasks',
          searchText: filters?._search ? filters._search : '',
          search: true,
          rowsSelected: rowsSelected,
          onRowClick: onRowClick,
          onRowsSelect: (rowsSelected, allRows) => {
            setRowsSelected(allRows.map((row) => row.dataIndex));
          },
          onRowSelectionChange: (selected, all) => {
            setRowsSelected(
              all
                .filter((row) => {
                  return data[row.dataIndex].type !== INBOX_DISCONNECTED;
                })
                .map((row) => row.dataIndex),
            );
          },
          // customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
          //   return (
          //     <TasksToolbar
          //       rowsSelected={rowsSelected}
          //       selectedRows={selectedRows}
          //       displayData={displayData}
          //       setSelectedRows={setSelectedRows}
          //       onBulkAction={onBulkAction}
          //     />
          //   );
          // },
        }}
      />
      <TasksBulkActions
        action={bulkAction}
        onClose={closeBulkAction}
        taskBulkUpdate={props.taskBulkUpdate}
        selectedTasks={selectedTasks}
        tab="errors"
      />
      <EditEmail task={editTask} title="Fix template error" handleClose={handleClose} />
    </>
  );
};

export default ErrorsGrid;
