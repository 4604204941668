import React from 'react';
import { Box } from '@mui/material';
import { Form, Button } from 'src/components/shared';

function SequenceStatusForm({ isLoading, sequence, handleSubmit, onClose, ...props }) {
  return (
    <Form
      initialValues={{
        status: sequence.status === 'published' ? true : false,
      }}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <Form.Field.Select
              name="status"
              label="status"
              variant="outlined"
              fullWidth={true}
              options={[
                {
                  label: 'Published',
                  value: 'published',
                },
                {
                  label: 'Unpublished',
                  value: 'unpublished',
                },
              ]}
            />
            <div style={{ height: 12 }} />
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="outlined"
                type="submit"
                color="secondary"
                onClick={onClose}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <div style={{ width: 12, height: 12 }} />
              <Button variant="contained" type="submit" color="secondary" loading={isLoading}>
                Submit
              </Button>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}

export default SequenceStatusForm;
