import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import VideoModal from 'src/components/App/VideoModal';
import images from 'src/config/images';
import useStyles from './style';

const Banner = ({
  key,
  header,
  content,
  videoUrl,
  thumbnail,
  actionButtons,
  onVideoComplete,
  closeVideoOnComplete = true,
}) => {
  const classes = useStyles();
  const ref = React.useRef();

  const [viewVideoModal, setViewVideoModal] = React.useState(null);
  ref.current = viewVideoModal;

  const eventFunction = (eventObject) => {
    if (
      eventObject?.origin === 'https://srvplr.superreach.com' &&
      eventObject?.data?.event === 'videoEnded'
    ) {
      if (isFunction(onVideoComplete)) onVideoComplete(ref.current);
      if (closeVideoOnComplete) setViewVideoModal(null);
    }
  };

  React.useEffect(() => {
    if (videoUrl) window.addEventListener('message', eventFunction, false);
    return () => window.removeEventListener('message', eventFunction);
  }, [videoUrl]);

  return (
    <Grid container spacing={2}>
      {/* Video / Image section */}
      <Grid item xs={12} sm={12} md={5}>
        <Stack className={classes.videoThumbContainer}>
          {isString(thumbnail) ? (
            <img className={classes.thumbnailImage} src={thumbnail} />
          ) : (
            <React.Fragment />
          )}
          {videoUrl && (
            <React.Fragment>
              <Stack className={`${classes.backdrop} backdrop`} />
              <Stack className={`${classes.videoPlayWrapper} video-play-wrapper`}>
                <ButtonBase
                  className={classes.playPlaceholderButtom}
                  onClick={() => setViewVideoModal(key)}
                >
                  <img src={images.icons.playButton} />
                </ButtonBase>
              </Stack>
            </React.Fragment>
          )}
        </Stack>
        {viewVideoModal ? (
          <VideoModal video={{ id: key, videoUrl }} onClose={() => setViewVideoModal(null)} />
        ) : (
          <React.Fragment />
        )}
      </Grid>

      {/* Content section */}
      <Grid item xs={12} sm={12} md={7}>
        <Stack className={classes.contentContainer}>
          <Typography className={classes.contentHeader}>{header}</Typography>
          {isString(content)
            ? content.split('<br/>').map((line, index) => (
                <React.Fragment key={`content-${index}`}>
                  <Typography className={classes.contentDescription}>{line}</Typography>
                </React.Fragment>
              ))
            : content}
          <Stack className={classes.actionContainer}>{actionButtons}</Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Banner;
