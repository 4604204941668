import React from 'react';
import PropTypes from 'prop-types';
import { EventCard } from '../eventsStyles';
import WatchLaterIcon from '@mui/icons-material/WatchLater';

const CallbackCard = ({ event, ...props }) => {
  const title = `Wait ${event.waitTime} ${event.unit}`;

  return (
    <EventCard
      icon={<WatchLaterIcon style={{ fill: '#78909C', width: 24, height: 24 }} />}
      title={title}
      showEdit={true}
      showDelete={true}
      {...props}
    />
  );
};

CallbackCard.propTypes = {
  event: PropTypes.object,
};

export default CallbackCard;
