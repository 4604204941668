import React from 'react';
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { getIntegrations } from 'src/modules/app/actions/appSelector';
import {
  BullhornIconActive,
  HubspotIconActive,
  JobadderIconActive,
  SalesforceIconActive,
  VincereIconActive,
} from 'src/modules/app/components/IntegrationIcons';
import { capitalizeName } from '../../../modules/contacts/utils/contactUtils';
import { Spacer } from 'src/components/shared';
import CloseIcon from '@mui/icons-material/Close';

const Matches = ({
  open = false,
  onClose = () => null,
  syncContact = () => null,
  sx = {},
  contact = {},
  CRMField = '',
  matches = [],
  enrollmentView = false,
  ...props
}) => {
  const integrations = useSelector((state) => getIntegrations(state));

  const [contactMatched, setContactMatched] = React.useState({});

  const onConfirm = async () => {
    syncContact(contact.id, contactMatched);
    onClose();
  };

  const handleBackdropClick = (e) => {
    if (e?.target?.classList?.contains('backdrop-content')) {
      onClose();
    }
  };

  if (!matches?.length) return null;

  return (
    <Backdrop
      sx={{
        position: 'absolute',
        backdropFilter: 'blur(2px)',
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        ...sx,
      }}
      open={open}
      onClick={handleBackdropClick}
    >
      <Card
        className="backdrop-content"
        sx={{ width: 'calc(85% - 80px)', backboxcdropFilter: 'blur(3px)', position: 'relative' }}
      >
        {enrollmentView && (
          <CloseIcon
            onClick={onClose}
            sx={{ cursor: 'pointer', position: 'absolute', color: '#707070', right: 10, top: 10 }}
          />
        )}
        <Stack gap={'10px'} padding={'20px'} borderBottom={'1px solid #d9d9d9'}>
          <Stack direction={'row'} gap={'10px'}>
            <Box width={'60px'}>
              {integrations?.isBullhornActive ? (
                <BullhornIconActive />
              ) : integrations?.isJobAdderActive ? (
                <JobadderIconActive />
              ) : integrations?.isVincereActive ? (
                <VincereIconActive />
              ) : integrations?.isSalesforceActive ? (
                <SalesforceIconActive />
              ) : integrations?.isHubspotActive ? (
                <HubspotIconActive />
              ) : (
                ''
              )}
            </Box>
            <Typography sx={{ fontWeight: 600, fontSize: 16 }}>
              We've found potential matches in {CRMField}
            </Typography>
          </Stack>
          <Typography sx={{ fontWeight: 400, fontSize: 12, color: '#777' }} variant="body2">
            Which profile do you want to connect with {CRMField}?
          </Typography>
        </Stack>
        <Stack
          gap={'4px'}
          padding={'0 20px'}
          borderBottom={'1px solid #d9d9d9'}
          overflow={'auto'}
          maxHeight={'60vh'}
        >
          {matches?.map((matchedContact, index) => {
            const name = `${(matchedContact?.name && matchedContact.name + ' ') || ''}${
              (matchedContact?.firstName && matchedContact.firstName + ' ') || ''
            }${(matchedContact?.lastName && matchedContact.lastName + ' ') || ''}${
              (matchedContact?.Name && matchedContact.Name + ' ') || ''
            }${
              (matchedContact?.properties?.firstname?.value &&
                matchedContact?.properties?.firstname?.value + ' ') ||
              ''
            }${
              (matchedContact?.properties?.lastname?.value &&
                matchedContact?.properties?.lastname?.value + ' ') ||
              ''
            }`;
            const jobTitle =
              matchedContact?.properties?.jobtitle?.value ||
              matchedContact?.job_title ||
              matchedContact?.Title ||
              matchedContact?.current_job_title ||
              matchedContact?.occupation ||
              matchedContact?.position ||
              '';
            const employer =
              matchedContact?.company?.name ||
              matchedContact?.current_employer ||
              matchedContact?.companyName ||
              matchedContact?.company ||
              matchedContact?.Account?.Name ||
              '';

            const matchedContactId =
              matchedContact?.id ||
              matchedContact?.Id ||
              matchedContact?.vid ||
              matchedContact?.candidateId ||
              matchedContact?.contactId ||
              null;
            const contactMatchedId =
              contactMatched?.id ||
              contactMatched?.Id ||
              contactMatched?.vid ||
              contactMatched?.candidateId ||
              contactMatched?.contactId ||
              null;

            return (
              <Box key={`${CRMField.toLowerCase()}-match-${index}`}>
                <Spacer x={1} y={1} />
                <ListItem
                  dense={false}
                  disableGutters={false}
                  disablePadding={false}
                  alignItems="flex-start"
                  sx={{ alignItems: 'center', padding: '4px', margin: 0, gap: '8px' }}
                >
                  <Box sx={{ width: 36 }}>
                    <Checkbox
                      color="info"
                      checked={matchedContactId === contactMatchedId}
                      onClick={() =>
                        matchedContactId === contactMatchedId
                          ? setContactMatched()
                          : setContactMatched(matchedContact)
                      }
                    />
                  </Box>
                  <Box display={'flex'} sx={{ alignItems: 'center' }}>
                    <ListItemAvatar sx={{ margin: 0 }}>
                      <Avatar size={40} name={name} src={undefined} />
                    </ListItemAvatar>
                    <Stack>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: 'inline' }}
                              variant="body1"
                              color="textPrimary"
                            >
                              {capitalizeName(name)}
                            </Typography>
                          </React.Fragment>
                        }
                        secondary={
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="textSecondary"
                          >
                            <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                              {jobTitle}
                              {jobTitle && employer && (
                                <>
                                  <span
                                    style={{
                                      color: '#4789C2',
                                      marginLeft: '4px',
                                      marginRight: '4px',
                                    }}
                                  >
                                    at
                                  </span>
                                </>
                              )}
                              {employer}
                            </span>
                          </Typography>
                        }
                      />
                      <ListItemText
                        secondary={
                          <Stack direction={'row'} gap={'6px'} alignItems={'center'}>
                            <Chip
                              size="small"
                              sx={{
                                backgroundColor: '#4caf50',
                                color: 'white',
                                fontWeight: 500,
                                margin: '-4px 0',
                                fontSize: 12,
                                letterSpacing: '0.8px',
                              }}
                              label={matchedContact?.type}
                            />

                            <Button size="small" color="secondary" onClick={() => matchedContact?.url && window.open(matchedContact?.url, '_blank')}>
                              View on CRM
                            </Button>
                          </Stack>
                        }
                      />
                    </Stack>
                  </Box>
                </ListItem>
                <Spacer x={1} y={1} />
                <Divider />
              </Box>
            );
          })}
        </Stack>
        <Stack sx={{ textTransform: 'initial !important' }} gap={'4px'} padding={'20px'}>
          <Button
            disabled={
              !(
                contactMatched?.id ||
                contactMatched?.Id ||
                contactMatched?.vid ||
                contactMatched?.candidateId ||
                contactMatched?.contactId
              )
            }
            color="info"
            variant="contained"
            sx={{ width: '100%' }}
            onClick={onConfirm}
          >
            Connect And Continue
          </Button>
          <Button sx={{ width: '100%' }} variant="link" onClick={onClose}>
            {enrollmentView ? 'Add a new record' : 'Skip, not the right person'}
          </Button>
        </Stack>
      </Card>
    </Backdrop>
  );
};

export default Matches;
