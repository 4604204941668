import api from 'src/api';
import { convertObjectToQuerystring } from 'src/utils/helper';

export const fetchLookupsKLookup = async () => {
  const response = await api('/lookup', null, 'get');
  return response.lookups;
};

export const fetchLookups = () => {
  return api('/lookup', null, 'get');
};

export const fetchLookupList = (lookupId) => {
  return api(`/lookup/list/${lookupId}`, null, 'get');
};

export const createLookup = (data) => {
  return api('/lookup', data, 'post');
};

export const updateLookup = (lookupId, data) => {
  return api(`/lookup/${lookupId}`, data, 'put');
};

export const addLookupOptionsBulk = (lookupId, data) => {
  return api(`/lookup/bulklist/${lookupId}`, data, 'post');
};

export const deleteLookupOption = (optionId) => {
  return api(`/lookuplist/${optionId}`, null, 'delete');
};

export const updateLookupItem = (id, data) => {
  return api(`/lookuplist/${id}`, data, 'put');
};

export const lookupCrmSync = (lookupId) => {
  return api(`/lookup/crmsync/${lookupId}`, null, 'get');
};
