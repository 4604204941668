import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PageTitle } from 'src/components/App';
import CreateCourse from '../../components/CreateCourse/CreateCourse';
import { withCourseStore, useCourse } from '../../utils/CourseStore';

const defaultOptionsEmpty = [
  {
    title: '',
    value: '',
    id: '1_temp',
  },
  {
    title: '',
    value: '',
    id: '2_temp',
  },
  {
    title: '',
    value: '',
    id: '3_temp',
  },
  {
    title: '',
    value: '',
    id: '4_temp',
  },
];

export const CreateCourseContainer = (props) => {
  const id = props.match.params.id;
  const { get } = useCourse();
  const [courseData, setCourseData] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState(defaultOptionsEmpty);

  useEffect(() => {
    if (id) {
      get(id).then((res) => {
        setCourseData(res.course);
      });
    }
  }, [id]);

  useEffect(() => {
    if (courseData?.sessions?.length) setDefaultOptions(courseData.sessions);
  }, [courseData]);

  return (
    <>
      <PageTitle title="Create Course" />
      <CreateCourse {...props} id={id} courseData={courseData} defaultOptions={defaultOptions} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withCourseStore(CreateCourseContainer));
