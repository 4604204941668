import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';

export const PhoneInputComponent = styled(PhoneInput).attrs({
  containerStyle: {
    width: '100%',
    marginTop: 20,
    height: 58,
  },
  inputStyle: {
    width: '100%',
    height: 58,
  },
})``;
