import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import LMSHeader from '../../../LMSHeader';
import SessionBannerSection from '../../../LMSSession/components/sessionDetail/SessionBannerSection';
import CourseBannerSection from './CourseBannerSection';
import CourseBanner from './CourseBanner';
import CourseDetailsSection from './CourseDetailSection';
import { useLayoutEffect } from 'react';
import { Spacer } from 'src/components/shared';

export const CourseDetail = (props) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  console.log('COURSE : ', props.course);

  return (
    <>
      <Box>
        {!props.loading && (
          <>
            {props.course.source === 'superadmin' ? (
              <CourseBannerSection
                bannerImage={props.course?.headerImage}
                id={props.course?.id}
                contentType={props.course?.contentType}
                title={props.course?.title}
                data={props.course}
                loading={props.loading}
              />
            ) : (
              <>
                <CourseBanner course={props.course} loading={props.loading} />
                <Spacer x={3} y={3} />
              </>
            )}
          </>
        )}

        <CourseDetailsSection courseData={props.course} loading={props.loading} />
      </Box>
    </>
  );
};

export default CourseDetail;
