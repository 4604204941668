import { themeColors } from '../../config';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({ palette }) => ({
  title: {
    color: '#FFD47E',
    marginLeft: '14px',
    marginBottom: '6%',
    fontSize: '16px',
    fontWeight: '700',
  },
  container: {
    background: themeColors.bg3,
    color: 'white',
    padding: '1px',
    paddingTop: '10px',
    borderRadius: '5px',
    '& button': {
      color: '#7F7F7F',
      background: 'transparent',
      border: '1px solid #7F7F7F',
      fontWeight: '400',
      fontSize: '12px',
      marginLeft: '10px',
      marginBottom: '10px',
    },
  },
}));
export default useStyles;
