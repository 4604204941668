import React from 'react';
import { Card, CardContent, Skeleton, Grid, Stack, Box, Typography } from '@mui/material';
import { Spacer } from 'src/components/shared';

function ListSkeleton() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={1} md={6} lg={3}>
          <Skeleton animation="wave" height={180} width={'100%'} />
          <Skeleton animation="wave" height={32} width={'60%'} />
          <Skeleton animation="wave" height={20} width={'100%'} />
          <Skeleton animation="wave" height={20} width={'40%'} />
        </Grid>
        <Grid item sm={1} md={6} lg={3}>
          <Skeleton animation="wave" height={180} width={'100%'} />
          <Skeleton animation="wave" height={32} width={'60%'} />
          <Skeleton animation="wave" height={20} width={'100%'} />
          <Skeleton animation="wave" height={20} width={'40%'} />
        </Grid>
        <Grid item sm={1} md={6} lg={3}>
          <Skeleton animation="wave" height={180} width={'100%'} />
          <Skeleton animation="wave" height={32} width={'60%'} />
          <Skeleton animation="wave" height={20} width={'100%'} />
          <Skeleton animation="wave" height={20} width={'40%'} />
        </Grid>
        <Grid item sm={1} md={6} lg={3}>
          <Skeleton animation="wave" height={180} width={'100%'} />
          <Skeleton animation="wave" height={32} width={'60%'} />
          <Skeleton animation="wave" height={20} width={'100%'} />
          <Skeleton animation="wave" height={20} width={'40%'} />
        </Grid>
      </Grid>
    </>
  );
}

export default ListSkeleton;
