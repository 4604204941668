import React from 'react';
import { Box, Typography, Divider, Grid } from '@mui/material';
import { Button, Spacer } from 'src/components/shared';
import { IntroContainer } from './styles';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import AddIcon from '@mui/icons-material/Add';

function Intro({ title, description, showButton, onClick }) {
  return (
    <>
      <IntroContainer>
        <Grid container spacing={2}>
          <Grid item md={9} xs={12}>
            <Box display="flex" alignItems="center">
              <SettingsSuggestIcon color="primary" sx={{ fontSize: '3em' }} />
              <Spacer x={2} y={2} />
              <Box>
                <Typography variant="h2">{title}</Typography>
                <Spacer x={1} y={1} />
                <Typography variant="body1" color="textSecondary">
                  {description}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {showButton && (
            <Grid item md={3} xs={12} display="flex" alignItems="center" justifyContent="flex-end">
              <Button variant="contained" color="secondary" onClick={onClick}>
                Add central lookup
              </Button>
            </Grid>
          )}
        </Grid>
      </IntroContainer>
      <Divider />
    </>
  );
}

export default Intro;
