import React, { useEffect } from 'react';
import { Slider } from 'src/components/lms';
import Competition from 'src/components/lms/Competition';
import { useLmsHomeProvider } from 'src/modules/lms/utils/lmsStore';
import { LMSCard } from 'src/components/lms';

import { ReactComponent as Award } from 'src/assets/lms/icons/award.svg';

function HomeCompetitions() {
  const { competitions } = useLmsHomeProvider();

  return (
    <LMSCard
      icon={<Award style={{ width: 24, height: 24 }} />}
      title={`My Competitions (${competitions.length})`}
    >
      <Slider dots={true} arrows={true}>
        {competitions?.map((competition) => (
          <Competition key={`my-competition-${competition.id}`} data={competition} />
        ))}
      </Slider>
    </LMSCard>
  );
}

export default HomeCompetitions;
