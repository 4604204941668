import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { AppGrid } from 'src/components/App';
import { Button } from 'src/components/shared';
import Checkbox from '@mui/material/Checkbox';
import HolidayGridActions from './HolidayGridActions';
import { capitalizeFirstLetter } from 'src/utils/helper';
import moment from 'moment';

function HolidayGrid({
  onClickAddHoliday,
  onFilterChange,
  holidays,
  onEdit,
  onDelete,
  onCheckClick,
}) {
  const data = holidays;

  const holidayColumns = useMemo(
    () => [
      {
        label: 'Holiday Name',
        name: 'event',
        options: {
          filter: true,
          sort: true,
          draggable: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 180 },
          }),
          setCellProps: () => ({
            style: { minWidth: 180 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = data[dataIndex];
            return (
              <Typography variant="body2" color="secondary">
                {`${capitalizeFirstLetter(rData?.event)}`}
              </Typography>
            );
          },
        },
      },
      {
        label: 'Start Date',
        name: 'eventDate',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 10 },
          }),
          setCellProps: () => ({
            style: { minWidth: 10 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = data[dataIndex];
            return <div>{moment(rData?.eventDate).format('DD/MM/YYYY')}</div>;
          },
        },
      },
      {
        label: 'End Date',
        name: 'eventEndDate',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 10 },
          }),
          setCellProps: () => ({
            style: { minWidth: 10 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = data[dataIndex];
            return <div>{moment(rData?.eventEndDate).format('DD/MM/YYYY')}</div>;
          },
        },
      },
      {
        label: 'Active',
        name: 'isActive',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 60 },
          }),
          setCellProps: () => ({
            style: { minWidth: 60 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = data[dataIndex];
            const isActive = rData.isActive ? true : false;
            return (
              <Checkbox
                checked={isActive}
                onClick={(e) => {
                  // not sure why e.currentTarget.checked not working
                  onCheckClick(e, dataIndex);
                }}
              />
            );
          },
        },
      },
      {
        name: 'id',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          allowToggle: false,
          viewColumns: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const row = { ...data[dataIndex], index: dataIndex };
            return (
              // <OfficeGridActions
              //   drawerToggle={drawerToggle}
              //   status={row?.status}
              //   row={row}
              //   {...props}
              // />
              <HolidayGridActions row={row} onEdit={onEdit} onDelete={onDelete} />
            );
          },
        },
      },
    ],
    [data],
  );

  return (
    <>
      <AppGrid
        columns={holidayColumns.map((col, index) => ({
          ...col,
          options: holidayColumns?.columns?.length
            ? { ...col.options, ...holidayColumns?.columns[index] }
            : col.options,
        }))}
        onFilterChange={(key, val) => onFilterChange(key, val)}
        data={data}
        //loading={loading.holidays}
        options={{
          sortOrder: {
            event: true,
            eventDate: true,
          },
          serverSide: false,
          pagination: true,
          searchPlaceholder: 'Search Holiday',
          search: true,
          searchOpen: true,
          download: false,
          viewColumns: false,
          selectToolbarPlacement: 'replace',
          renderCustomFilterActions: () => {
            return (
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                // startIcon={<AddIcon />}
                onClick={() => {
                  onClickAddHoliday();
                }}
              >
                <Typography variant="subtitle1">Add Holiday</Typography>
              </Button>
            );
          },
        }}
      />
    </>
  );
}

export default HolidayGrid;
