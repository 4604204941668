import React from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Grid, Typography, Box, Card, CardContent } from '@mui/material';
import { Form, Modal } from 'src/components/shared';

import CloseIcon from '@mui/icons-material/Close';
import { getInviteTemplate, templates } from '../../api/competitionApi';
import ThirdRepublicLogo from '../../../../img/ThirdRepublicLogo.png';
import useStyles from './style';

function CompetitionTemplatePreview({
  open = false,
  handleClose = () => {},
  competitionId = '',
  invite = false,
  update = false,
  result = false,
}) {
  const classes = useStyles();
  const [content, setContent] = React.useState('');
  const settings = useSelector((state) => (open ? state?.app?.tenant?.tenant_settings?.[0] : ''));
  const userId = useSelector((state) => state)?.auth?.user?.id;

  const template = {
    resultTemplate: settings?.competitionResultTemplate,
    updateTemplate: settings?.competitionUpdateTemplate,
    inviteTemplate: settings?.competitionInviteTemplate,
  };

  const id = invite
    ? template?.inviteTemplate
    : update
    ? template?.updateTemplate
    : template?.resultTemplate;
  React.useEffect(() => {
    fetchTemplateContent();
  }, [open]);
  const fetchTemplateContent = async () => {
    if (open === true) {
      if (competitionId) {
        try {
          const res = await getInviteTemplate(id, competitionId, userId);
          setContent(res?.content);
        } catch (error) {}
      } else {
        try {
          const res = await templates(id);
          setContent(res?.template?.content);
        } catch (error) {}
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`${invite ? 'Invite' : update ? 'Update' : 'Result'} email preview`}
    >
      <>
        <Card>
          <CardContent sx={{ backgroundColor: '#16324A', maxHeight: '50vh', overflow: 'auto' }}>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </CardContent>
        </Card>

        <Box mt={2}>
          <img className={classes.logo} src={settings?.competitionSettings?.logo} />
        </Box>
      </>
    </Modal>
  );
}

export default CompetitionTemplatePreview;
