import { all } from 'redux-saga/effects';
import AppSagas from 'src/modules/app/saga/appSagas';
import AuthSagas from 'src/modules/auth/saga/authSagas';
import TaskSagas from 'src/modules/tasks/saga/taskSagas';
import SequenceSaga from 'src/modules/sequence/saga/sequenceSaga';
import ContactSagas from 'src/modules/contacts/saga/contactSagas';
import DashboardSaga from 'src/modules/app/saga/dashboardSaga';
import ReportsSaga from 'src/modules/app/saga/reportsSagas';
import BoardSaga from 'src/modules/app/saga/boardSaga';
import EnrolmentSaga from 'src/modules/sequence/saga/enrolmentSaga';
import ResellerSaga from 'src/modules/reseller/saga/resellerSaga';

import adminSaga from 'src/modules/admin/saga/adminSaga';
import fieldsSaga from 'src/modules/admin/saga/fieldsSaga';
import TagSagas from 'src/modules/admin/tags/saga/tagSagas';
import LMSSagas from 'src/modules/lms/saga/LMSSagas';
import NoteSaga from 'src/modules/notes/saga/noteSaga';
import CompetitionSaga from 'src/modules/compitions/saga/competitionSaga'

function* rootSaga() {
  yield all([
    AppSagas(),
    AuthSagas(),
    TaskSagas(),
    SequenceSaga(),
    ContactSagas(),
    adminSaga(),
    TagSagas(),
    DashboardSaga(),
    ReportsSaga(),
    BoardSaga(),
    EnrolmentSaga(),
    ResellerSaga(),
    fieldsSaga(),
    LMSSagas(),
    NoteSaga(),
    CompetitionSaga(),
  ]);
}

export default rootSaga;
