import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchTags, updateTagStatus } from '../../actions/tagsActions';
import TagsView from './TagsView';

const TagsContainer = (props) => {
  useEffect(() => {
    if (!props.tags.data.length) {
      props.fetchTags();
    }
  }, []);

  return ( <TagsView {...props} /> );
};

const mapStateToProps = (state) => {
  return {
    tags: state.admin.tags.tags,
    loading: state.admin.tags.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchTags: () => dispatch(fetchTags()),
  updateTagStatus: (rowData) => dispatch(updateTagStatus(rowData))
});

export default connect(mapStateToProps, mapDispatchToProps)(TagsContainer);