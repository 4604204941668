import { ReactComponent as Home } from 'src/assets/lms/icons/home.svg';
import { ReactComponent as Recruitment } from 'src/assets/lms/icons/recruitment.svg';
import { ReactComponent as Reporting } from 'src/assets/lms/icons/reporting.svg';
import { ReactComponent as Sales } from 'src/assets/lms/icons/sales.svg';
import { ReactComponent as Teams } from 'src/assets/lms/icons/teams.svg';

import { ReactComponent as Assigned } from 'src/assets/lms/icons/assigned.svg';
import { ReactComponent as Award } from 'src/assets/lms/icons/award.svg';
import { ReactComponent as Completed } from 'src/assets/lms/icons/completed.svg';
import { ReactComponent as Progress } from 'src/assets/lms/icons/progress.svg';
import { ReactComponent as Saved } from 'src/assets/lms/icons/saved.svg';

export const menuItems = [
  {
    title: 'Home',
    link: '/lms',
    icon: <Home />,
    children: [
      {
        title: 'In Progress',
        link: '/lms/progress',
        icon: <Progress />,
        countKey: 'inProgress',
      },
      {
        title: 'Assigned to Me',
        link: '/lms/saved',
        icon: <Assigned />,
        countKey: 'saved',
      },
      {
        title: 'Saved',
        link: '/lms/saved',
        icon: <Saved />,
        countKey: 'assignedToMe',
      },
      {
        title: 'Competition',
        link: '/lms/competition',
        icon: <Award />,
        countKey: 'competitions',
      },
      {
        title: 'Completed',
        link: '/lms/completed',
        icon: <Completed />,
        countKey: 'completed',
      },
    ],
  },
  {
    title: 'Sales',
    link: '/',
    icon: <Sales />,
  },
  {
    title: 'Recruitment',
    link: '/',
    icon: <Recruitment />,
  },
  {
    title: 'Reporting',
    link: '/',
    icon: <Reporting />,
  },
  {
    title: 'Team',
    link: '/',
    icon: <Teams />,
  },
];
