import Notes from '../container/Notes';

export default [
  {
    title: 'Notes',
    component: Notes,
    url: '/notes',
    exact: true,
    auth: true,
  },
];
