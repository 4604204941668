import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Box, ButtonBase } from '@mui/material';
import { toggleContactDrawer } from 'src/modules/app/actions/appActions';
import styled from 'styled-components';
import moment from 'moment';
import { green, red } from '@mui/material/colors';
import { ContactStatus } from './styles';
import { getEnrollmentStatusLabel } from '../../utils/sequenceHelper';
import { dateFormat } from 'src/config';

export const StatsText = styled(Typography).attrs({
  variant: 'body2',
})`
  color: ${(props) =>
    props.count == 0 ? 'rgba(0, 0, 0, 0.87)' : props.count > 50 ? green[500] : red[500]} !important;
`;

export function useSequenceContactsColumns(sequencesContacts, onEdit, onDelete) {
  const [columns, setColumns] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setColumns([
      {
        label: 'Name',
        name: 'contactName',
        options: {
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const rData = sequencesContacts[dataIndex];
            if (!rData?.contactObj.id) return null;
            return (
              <ButtonBase
                onClick={() => {
                  dispatch(toggleContactDrawer(rData.contactObj.id));
                }}
              >
                <Typography variant="body2" color="secondary">
                  {`${rData.contactObj.fname} ${rData.contactObj.lname}`}
                </Typography>
              </ButtonBase>
            );
          },
        },
      },
      {
        label: 'Employer',
        name: 'employer',
        options: {
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const rData = sequencesContacts[dataIndex];
            if (!rData?.contactObj.id) return null;
            return <Typography variant="body2">{`${rData.contactObj?.employer}`}</Typography>;
          },
        },
      },
      {
        label: 'Enrolled by',
        name: 'enrolledBy',
        options: {
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const rData = sequencesContacts[dataIndex];
            if (!rData?.contactObj.id) return null;
            return (
              <Typography variant="body2">
                {`${rData.createdBy?.fname} ${rData.createdBy?.lname}`}
              </Typography>
            );
          },
        },
      },
      {
        label: 'Enrolled date',
        name: 'enrolledDate',
        options: {
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            const rData = sequencesContacts[dataIndex];
            if (!rData?.contactObj.id) return null;
            return (
              <Typography variant="body2">
                {`${moment(new Date(rData.createdAt)).format('DD  MMM, YYYY')}`}
              </Typography>
            );
          },
        },
      },
      {
        label: 'Steps completed',
        name: 'stepsCompleted',
        options: {
          sort: false,
          draggable: false,
          customBodyRenderLite: (dataIndex) => {
            const rData = sequencesContacts[dataIndex];
            if (!rData?.contactObj.id) return null;
            return (
              <Typography variant="body2">
                {rData?.stats?.stepsCompleted}
                {/* {`${rData.tasks.filter((task) => task.status === 'completed').length} `} */}
              </Typography>
            );
          },
        },
      },
      {
        label: 'Opened',
        name: 'openrate',
        options: {
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            const rData = sequencesContacts[dataIndex];
            const opens = rData?.stats?.uniqueOpens || 0;
            const rate = rData?.stats?.uniqueOpenRate;
            const rateStr = ` (${rData?.stats?.uniqueOpenRate || 0}%)`;
            return (
              <StatsText count={rate}>
                {opens}
                {rateStr}
              </StatsText>
            );
          },
        },
      },
      {
        label: 'Last Opened',
        name: 'lastOpened',
        options: {
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            const rData = sequencesContacts[dataIndex];
            const lastOpenTime = rData?.stats?.lastOpenTime || undefined;
            return (
              <Typography variant="body2" color="textSecondary">
                {lastOpenTime ? moment(lastOpenTime).format(dateFormat) : '-'}
              </Typography>
            );
          },
        },
      },
      {
        label: 'Clicked',
        name: 'clicked',
        options: {
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const rData = sequencesContacts[dataIndex];
            const clicks = rData?.stats?.uniqueClicks || 0;
            const rate = rData?.stats?.uniqueClickRate;
            const rateStr = ` (${rData?.stats?.uniqueClickRate || 0}%)`;
            return (
              <StatsText count={rate}>
                {clicks}
                {rateStr}
              </StatsText>
            );
          },
        },
      },
      {
        label: 'Replied',
        name: 'replied',
        options: {
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const rData = sequencesContacts[dataIndex];
            const clicks = rData?.stats?.uniqueReplies || 0;
            const rateStr = ` (${rData?.stats?.uniqueReplyRate || 0}%)`;
            return (
              <StatsText count={0}>
                {clicks}
                {rateStr}
              </StatsText>
            );
          },
        },
      },
      {
        label: 'Status',
        name: 'status',
        options: {
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const rData = sequencesContacts[dataIndex];
            if (!rData?.contactObj.id) return null;
            return (
              <ContactStatus status={rData.status}>
                {getEnrollmentStatusLabel(rData.status)}
              </ContactStatus>
            );
          },
        },
      },
    ]);
  }, [sequencesContacts]);

  return columns;
}
