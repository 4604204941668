import React from 'react';
import { Box, Typography, Grid, Divider, Card, CardContent } from '@mui/material';
import { Spacer } from 'src/components/shared';
import { Link } from './styles';
import CloseIcon from '@mui/icons-material/Close';

function Categories({ categories, title, onChange, value = '' }) {
  return (
    <Box>
      <Typography variant="body1">{title}</Typography>
      <Spacer x={1} y={1} />
      {categories.map((category, index) => {
        return (
          <div key={`category-${index}`}>
            <Link
              onClick={() => {
                onChange(category);
              }}
            >
              <Box display="flex">
                <Typography
                  variant="body2"
                  color={value === category.value ? 'secondary' : 'default'}
                  sx={{ flex: 1 }}
                >
                  {category.label}
                </Typography>
                {value === category.value ? (
                  <CloseIcon color="secondary" sx={{ fontSize: '1.2rem' }} />
                ) : (
                  ''
                )}
              </Box>
            </Link>
            <Spacer x={0.8} y={0.8} />
          </div>
        );
      })}
    </Box>
  );
}

export default Categories;
