import React, { useState } from 'react';
import { Typography, Stack, Card, CardContent, Box, Link, Divider } from '@mui/material';
import styled from 'styled-components';

import images from 'src/config/images';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LanguageIcon from '@mui/icons-material/Language';
import useStyles from './sessionDetail/styles';
import Slider from 'react-slick';
import { Spacer } from 'src/components/shared';

const Expert = ({ expert }) => {
  const name = `${expert.fname} ${expert.lname}`;
  const job = `${expert.jobTitle} ${expert.companyName}`;

  return (
    <Stack direction="row" spacing={2}>
      <Stack direction="column" spacing={0.5}>
        <Typography color="secondary">{name}</Typography>
        <Typography variant="body2" color="textPrimary">
          {job}
        </Typography>

        {(expert?.linkedinUrl || expert?.website) && (
          <Stack direction="row" gap={1} mt={2}>
            {expert?.linkedinUrl && expert?.linkedinUrl !== '' && (
              <Link target="_blank" href={expert?.linkedinUrl}>
                <LinkedInIcon sx={{ fill: 'rgba(255,255,255,0.87)' }} />
              </Link>
            )}

            {expert?.website && expert?.website !== '' && (
              <Link target="_blank" href={expert?.website}>
                <LanguageIcon sx={{ fill: 'rgba(255,255,255,0.6)' }} />
              </Link>
            )}
          </Stack>
        )}

        <Desc dangerouslySetInnerHTML={{ __html: expert?.description }} />
      </Stack>
    </Stack>
  );
};

function SessionExperts({ experts }) {
  const classes = useStyles();
  const [activeSlide, setActiveSlide] = useState(0);

  const sliderRef = React.useRef();

  const handleClick = (index) => {
    if (sliderRef.current) {
      setActiveSlide(index);
      sliderRef.current.slickGoTo(index);
    }
  };

  const handleSlide = (index) => {
    setActiveSlide(index);
  };

  if (!experts || !experts.length) return null;

  return (
    <>
      <Divider />
      <Spacer x={2} y={2} />
      <Typography variant="h2" color="textPrimary">
        Meet the expert
      </Typography>
      <Spacer x={2} y={2} />
      <Card className={classes.cardContainer}>
        <CardContent>
          <Box mt={2} px={2}>
            <Stack direction="row" spacing={2} mb={2}>
              {experts.map((expert, index) => {
                const profilePicture = expert?.profileImage || images.LMS.expertPlaceholder;
                return (
                  <ImageWrapper
                    key={`session-expert-thumbnail-${index}`}
                    onClick={() => {
                      handleClick(index);
                    }}
                    active={activeSlide === index}
                  >
                    <Image src={profilePicture} />
                  </ImageWrapper>
                );
              })}
            </Stack>
            <Slider
              arrows={false}
              dots={false}
              ref={(slider) => (sliderRef.current = slider)}
              afterChange={handleSlide}
            >
              {experts.map((expert, index) => (
                <Expert expert={expert} key={`session-expert-${index}`} />
              ))}
            </Slider>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default SessionExperts;

const Image = styled.img`
  border-radius: 4px;
  width: 66px;
  height: 66px;
  object-fit: cover;
  border-radius: 66px;
`;

const ImageWrapper = styled.div`
  opacity: ${(props) => (props.active ? '1' : '0.5')};
  border: ${(props) => (props.active ? '2px solid #ffd47e' : '2px solid transparent')};
  border-radius: 100%;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Desc = styled.div`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  & p {
    margin: 0;
  }
`;
