import { themeColors } from '../../config';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({ palette }) => ({
  label: {
    fontWeight: '500',
    fontSize: '12px',
    '& .css-hg54b6-MuiStepLabel-label': {
      color: ' #EF5089',
    },
    '& .css-hg54b6-MuiStepLabel-label.Mui-completed': {
      color: ' #13DEA1',
    },
    '& .css-1fhr5nq-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed': {
      color: ' #13DEA1',
    },
    '& .css-1fhr5nq-MuiSvgIcon-root-MuiStepIcon-root.Mui-active ': {
      color: ' #EF5089',
    },
    '& .css-1fhr5nq-MuiSvgIcon-root-MuiStepIcon-root': {
      color: ' #EF5089',
    },
    '& .css-1yqaqpx-MuiTypography-root': {
      color: ' #EF5089',
    },
  },
  Completed: {
    color: ' #13DEA1',
  },
  button: {
    color: ' #EF5089',
  },
}));
export default useStyles;
