import React from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import {
  List,
  ListItem,
  Box,
  Typography,
  Divider,
  ListItemIcon,
  ListItemText,
  Collapse,
  Drawer,
  Stack,
  ListItemButton,
} from '@mui/material';
import { Spacer } from 'src/components/shared';
import { themeColors, myLearningMenuItems } from '../../config';

import FolderIcon from '@mui/icons-material/Folder';
import BarChartIcon from '@mui/icons-material/BarChart';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import HubIcon from '@mui/icons-material/Hub';
import LockIcon from '@mui/icons-material/Lock';

import NavItem from './NavItem';
import {
  LMSSidebarWrapper,
  LogoWrapper,
  ChildMenu,
  LMSList,
  LmsDrawer,
  NavItemLink,
} from './styles';

import moment from 'moment';
import { isEqual } from 'lodash';
import images from 'src/config/images';
import { useSelector } from 'react-redux';

const Sidebar = ({ sales, recruitment, hasLmsAccess, hasOutreachAccess }) => {
  let locations = useLocation();
  const history = useHistory();
  const tail = locations.pathname.split('/').pop();
  const currentPath = locations.pathname;

  const updateActiveState = (arr) => {
    (arr || []).forEach((i) => {
      let currentPAth = locations.pathname.split('/')[1];
      if (currentPAth === 'prospect') {
        currentPAth = `${locations.pathname.split('/')[1]}/${locations.pathname.split('/')[2]}`;
      }
      if (locations.pathname === '/profile/integrations') {
        currentPAth = `${locations.pathname.split('/')[1]}/${locations.pathname.split('/')[2]}`;
      }
      if (isEqual(i.link, locations.pathname) || isEqual(i.link, `/${currentPAth}`)) {
        if (!i.child?.length) {
          i.activeClass = 'active';
        } else {
          updateActiveState(i.child);
        }
      } else {
        if (i.activeClass !== undefined) i.activeClass = '';
        updateActiveState(i.child);
      }
    });
  };

  return (
    <LMSSidebarWrapper>
      <LmsDrawer
        variant="permanent"
        docked={undefined}
        // className={classes.sliderDrawer}
        open={true}
      >
        <LogoWrapper>
          <Link to={'/'}>
            <img src={images.app.logoWhite} alt="" border="0" />
          </Link>
        </LogoWrapper>
        <Divider />
        <LMSList>
          <ListItem>
            <ListItemIcon sx={{ minWidth: '32px' }}>
              <FolderIcon fontSize={'small'} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body2" fontWeight="600">
                  My Learning
                </Typography>
              }
            />
          </ListItem>
          <Box px={2}>
            <Divider />
          </Box>

          <ChildMenu in={true}>
            <List dense disablePadding>
              {myLearningMenuItems.map((item, index) => (
                <NavItemLink key={`learning-${index}`}>
                  <ListItem disablePadding>
                    <ListItemText
                      sx={{ marginBottom: 0 }}
                      onClick={() => {
                        history.push(`${item.link}`);
                      }}
                      primary={
                        <Typography
                          color={currentPath === item.link ? 'secondary' : 'textSecondary'}
                          variant="caption"
                        >
                          {item.title}
                        </Typography>
                      }
                    />
                  </ListItem>
                </NavItemLink>
              ))}
            </List>
          </ChildMenu>

          <Spacer x={1} y={1} />
          <ListItem>
            <ListItemIcon sx={{ minWidth: '32px' }}>
              <BarChartIcon fontSize={'small'} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body2" fontWeight="600">
                  Sales
                </Typography>
              }
            />
          </ListItem>
          <Box px={2}>
            <Divider />
          </Box>

          {sales && sales.length ? (
            <ChildMenu in={true}>
              {sales.map((item, index) => (
                <NavItem
                  key={`lms-child-${item.title}-${index}`}
                  parentText={item.title}
                  subItems={item.content}
                  currentPath={tail}
                />
              ))}
            </ChildMenu>
          ) : null}

          <Spacer x={1} y={1} />

          <ListItem>
            <ListItemIcon sx={{ minWidth: '32px' }}>
              <PersonSearchIcon fontSize={'small'} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body2" fontWeight="600">
                  Recruitment
                </Typography>
              }
            />
          </ListItem>
          <Box px={2}>
            <Divider />
          </Box>
          {recruitment && recruitment.length ? (
            <ChildMenu in={true}>
              {recruitment.map((item, index) => (
                <NavItem
                  key={`lms-child-${item.title}-${index}`}
                  parentText={item.title}
                  subItems={item.content}
                  currentPath={tail}
                />
              ))}
            </ChildMenu>
          ) : null}

          <Spacer x={1} y={1} />

          <ListItemButton
            onClick={() => {
              if (hasOutreachAccess) {
                history.push('/');
              }
            }}
          >
            <ListItemIcon sx={{ minWidth: '32px' }}>
              <HubIcon fontSize={'small'} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Stack direction="row" gap={2}>
                  <Typography variant="body2" fontWeight="600">
                    Outreach
                  </Typography>
                  {!hasOutreachAccess && <LockIcon fontSize={'xs'} />}
                </Stack>
              }
            />
          </ListItemButton>
          <Box px={2}>
            <Divider />
          </Box>
          <Spacer x={4} y={4} />
        </LMSList>
      </LmsDrawer>
    </LMSSidebarWrapper>
  );
};

export default Sidebar;
