import React from 'react';
import { ButtonBase } from '@mui/material';
import Typography from '@mui/material/Typography';

const ReadMore = ({
  text,
  maxChars,
  readMoreText = 'Read more',
  readLessText = 'Read less',
  isHtml = false,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const hasReadMore = text.length > maxChars;
  const content = isExpanded ? text : text.slice(0, maxChars) + '...';

  return (
    <>
      <Typography {...props} component="div">
        {isHtml ? <div dangerouslySetInnerHTML={{ __html: content }} /> : content}

        {hasReadMore && (
          <ButtonBase onClick={() => setIsExpanded(!isExpanded)}>
            <Typography variant="body2" color="secondary">
              {!isExpanded ? readMoreText : readLessText}
            </Typography>
          </ButtonBase>
        )}
      </Typography>
    </>
  );
};

export default ReadMore;
