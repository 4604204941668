import * as types from '../actions/adminTypes';
import { RESET_STORE } from 'src/modules/app/actions/appTypes';

const initialState = {
  loading: {
    tabs: false,
    users: false,
    offices: false,
    teams: false,
    templates: false,
    integrations: false,
  },
  queryType: '',
  templates: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    sort: {},
    filters: {},
    gridParams: {},
    data: [],
    columns: [],
    columnsOrder: [],
  },
  teams: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    sort: {},
    filters: {},
    gridParams: {},
    data: [],
    columns: [],
    columnsOrder: [],
  },
  users: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    sort: {},
    filters: {},
    gridParams: {},
    data: [],
    columns: [],
    columnsOrder: [],
  },
  offices: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    sort: {},
    filters: {},
    data: [],
    columns: [],
    columnsOrder: [],
  },
  integrations: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    gridParams: {},
    data: [],
    columns: [],
    columnsOrder: [],
  },
  tenantSettings: {
    settingName: '',
    open: false,
    data: [],
  },

  userTabs: [],
  user: {},
  team: {},
  template: {},
  office: {},
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_ADMIN_USER:
    case types.SAVE_ADMIN_USER:
    case types.UPDATE_ADMIN_USER_STATUS:
      return {
        ...state,
        loading: {
          ...state.loading,
          users: true,
        },
      };
    case types.SAVE_ADMIN_TEAMS:
    case types.UPDATE_TEAM_STATUS:
    case types.UPDATE_ADMIN_TEAM:
      return {
        ...state,
        loading: {
          ...state.loading,
          teams: true,
        },
      };

    case types.SAVE_ADMIN_OFFICE:
    case types.UPDATE_OFFICE_STATUS:
    case types.UPDATE_ADMIN_OFFICE:
      return {
        ...state,
        loading: {
          ...state.loading,
          offices: true,
        },
      };

    case types.SAVE_ADMIN_TEMPLATE:
    case types.PUT_ADMIN_TEMPLATE:
    case types.UPDATE_TEMPLATE_STATUS:
      return {
        ...state,
        loading: {
          ...state.loading,
          templates: true,
        },
      };

    /* Templates Actions */

    case types.FETCH_ADMIN_TEMPLATES:
      return {
        ...state,
        loading: {
          ...state.loading,
          templates: true,
        },
        templates: {
          ...state.templates,
          data: [],
          paging: {
            ...state.templates.paging,
            ...action.paging,
          },
          filters: {
            ...state.templates.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
        queryType: {
          ...state.queryType,
          type: action.queryType,
        },
      };

    case types.SET_ADMIN_TEMPLATES:
      return {
        ...state,
        loading: {
          ...state.loading,
          templates: false,
        },
        templates: {
          ...state.templates,
          data: action.data.templates,
          paging: {
            ...state.templates.paging,
            count: action.data.total.value,
          },
        },
      };

    case types.SET_ADMIN_TEMPLATE:
      return {
        ...state,
        loading: {
          ...state.loading,
          templates: false,
        },
        templates: {
          ...state.templates,
          data: [action.data, ...state.templates.data],
        },
      };

    case types.UPDATE_ADMIN_TEMPLATE:
    case types.SET_TEMPLATE_STATUS:
      const updatedRowTemplate = state.templates.data.findIndex((val) => val.id === action.data.id);
      return {
        ...state,
        loading: {
          ...state.loading,
          templates: false,
        },
        templates: {
          ...state.templates,
          data: [
            ...state.templates.data.slice(0, updatedRowTemplate),
            { ...action.data.updatedRow.template },
            ...state.templates.data.slice(updatedRowTemplate + 1),
          ],
        },
      };

    /* Teams Actions */

    case types.FETCH_ADMIN_TEAMS:
      return {
        ...state,
        loading: {
          ...state.loading,
          teams: true,
        },
        teams: {
          ...state.teams,
          data: [],
          paging: {
            ...state.teams.paging,
            ...action.paging,
          },
          filters: {
            ...state.teams.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };

    case types.SET_ADMIN_TEAMS:
      return {
        ...state,
        loading: {
          ...state.loading,
          teams: false,
        },
        teams: {
          ...state.teams,
          data: action.data.teams,
          paging: {
            ...state.teams.paging,
            count: action.data.total?.value,
          },
        },
      };

    case types.SET_ADMIN_TEAM:
      return {
        ...state,
        loading: {
          ...state.loading,
          teams: false,
        },
        teams: {
          ...state.teams,
          data: [action.data.team, ...state.teams.data],
        },
      };

    case types.UPDATE_TEAM_ADMIN:
    case types.SET_TEAM_STATUS:
      const updatedRowIndexTeam = state.teams.data.findIndex(
        (val) => val.id === action.rowData.updatedRow.team.id,
      );
      return {
        ...state,
        loading: {
          ...state.loading,
          teams: false,
        },
        teams: {
          ...state.teams,
          data: [
            ...state.teams.data.slice(0, updatedRowIndexTeam),
            { ...action.rowData.updatedRow.team },
            ...state.teams.data.slice(updatedRowIndexTeam + 1),
          ],
        },
      };

    /* Users Action */

    case types.FETCH_ADMIN_USERS_TABS:
      return {
        ...state,
        loading: {
          ...state.loading,
          tabs: true,
        },
        userTabs: [],
      };

    case types.SET_ADMIN_USERS_TABS:
      return {
        ...state,
        loading: {
          ...state.loading,
          tabs: false,
        },
        userTabs: action.tabs,
      };

    case types.FETCH_ADMIN_USERS:
      return {
        ...state,
        loading: {
          ...state.loading,
          users: true,
        },
        users: {
          ...state.users,
          data: [],
          paging: {
            ...state.users.paging,
            ...action.paging,
          },
          filters: {
            ...state.users.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };

    case types.SET_ADMIN_USERS:
      return {
        ...state,
        loading: {
          ...state.loading,
          users: false,
        },
        users: {
          ...state.users,
          data: action.data.users,
          paging: {
            ...state.users.paging,
            count: action.data.total ? action.data.total.value : 0,
          },
        },
      };
    case types.SET_ADMIN_USER:
      return {
        ...state,
        loading: {
          ...state.loading,
          users: false,
        },
        users: {
          ...state.users,
          data: [action.data, ...state.users.data],
        },
      };

    case types.SET_ADMIN_USER_STATUS:
    case types.UPDATE_USER:
      const updatedRowInd = state.users.data.findIndex((val) => val.id === action.rowData.id);
      return {
        ...state,
        loading: {
          ...state.loading,
          users: false,
        },
        users: {
          ...state.users,
          data: [
            ...state.users.data.slice(0, updatedRowInd),
            { ...action.rowData.updatedRow.user },
            ...state.users.data.slice(updatedRowInd + 1),
          ],
        },
      };

    /******* Offices Reducers ***************/

    case types.FETCH_ADMIN_OFFICES:
      return {
        ...state,
        loading: {
          ...state.loading,
          offices: true,
        },
        offices: {
          ...state.offices,
          data: [],
          paging: {
            ...state.offices.paging,
            ...action.paging,
          },
          filters: {
            ...state.offices.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };

    case types.SET_ADMIN_OFFICE:
      return {
        ...state,
        loading: {
          ...state.loading,
          offices: false,
        },
        offices: {
          ...state.offices,
          data: [action.data, ...state.offices.data],
        },
      };

    case types.SET_ADMIN_OFFICES:
      return {
        ...state,
        loading: {
          ...state.loading,
          offices: false,
        },
        offices: {
          ...state.offices,
          data: action.data.offices,
          paging: {
            ...state.offices.paging,
            count: action.data.total.value,
          },
        },
      };

    case types.UPDATE_OFFICE_ADMIN:
    case types.SET_OFFICE_STATUS:
      const updatedRowOffice = state.offices.data.findIndex(
        (val) => val.id === action.rowData.updatedRow.office.id,
      );
      return {
        ...state,
        loading: {
          ...state.loading,
          offices: false,
        },
        offices: {
          ...state.offices,
          data: [
            ...state.offices.data.slice(0, updatedRowOffice),
            { ...action.rowData.updatedRow.office },
            ...state.offices.data.slice(updatedRowOffice + 1),
          ],
        },
      };

    /* Integration Action */

    case types.FETCH_ADMIN_INTEGRATIONS:
      return {
        ...state,
        loading: {
          ...state.loading,
          integrations: true,
        },
        integrations: {
          ...state.integrations,
          data: [],
        },
      };

    case types.SET_ADMIN_INTEGRATIONS:
      const event = state.integrations.data.find(
        (val) => val.id === action.data?.tenantIntegrations[0].id,
      );

      return {
        ...state,
        loading: {
          ...state.loading,
          integrations: false,
        },

        integrations: {
          ...state.integrations,
          data: action.data.tenantIntegrations,
        },
      };
    case types.SET_ADMIN_INTEGRATION:
      return {
        ...state,
        loading: {
          ...state.loading,
          integrations: false,
        },
        integrations: {
          ...state.integrations,
          data: [...state.integrations.data, action.data?.tenantIntegration],
        },
      };
    case types.UPDATE_INTEGRATION_ADMIN:
      const updatedIntegrationStatus = state.integrations.data.findIndex(
        (val) => val.id === action.data?.tenantIntegration[0].id,
      );
      return {
        ...state,
        loading: {
          ...state.loading,
          integrations: false,
        },

        integrations: {
          ...state.integrations,
          data: [
            ...state.integrations.data.slice(0, updatedIntegrationStatus),
            { ...action.data?.tenantIntegration[0] },
            ...state.integrations.data.slice(updatedIntegrationStatus + 1),
          ],
          // data: [...state.integrations.data, action.data?.tenantIntegration],
        },
        // integrations: {
        //   ...state.integrations,
        //   data: state.integrations.data.filter(
        //     // (event) => event.id !== action.data.tenantIntegration[0].id,
        //     (event) => event.id !== action.data.tenantIntegration[0].id,
        //   ),
        // },
      };

    case types.SETTING_NAME_ADMIN:
      return {
        ...state,
        settings: {
          ...state.settingName,
          settingName: action.status.settingsName,
          open: action.status.open,
        },
      };
    case types.SET_SETTING_ADMIN:
      return {
        ...state,
        tenantSettings: {
          ...state.tenantSettings,
          data: action.data.tenantSettings,
        },
      };

    case types.PUT_SETTING_ADMIN:
      return {
        ...state,
        tenantSettings: {
          ...state.tenantSettings,
          data: action.data,
        },
      };

    case RESET_STORE:
      return initialState;

    default:
      return state;
  }
};

export default adminReducer;
