import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import { Button, Spacer, Drawer } from 'src/components/shared';
import { sequenceEnrollmentStatus } from '../../config';

export const ActionButton = styled(Button)(({ theme, type }) => ({
  color: '#ffffff !important',
  fill: '#ffffff !important',
  width: '44px',
  height: '44px',
  borderRadius: '4px',
  '&.Mui-disabled': { opacity: 0.7 },
  ...(type === 'sequence' && {
    backgroundColor: '#7471EE !important',
    '&:hover': {
      backgroundColor: '#7471EE !important',
    },
  }),
  ...(type === 'email' && {
    backgroundColor: '#E2A93A !important',
    '&:hover': {
      backgroundColor: '#E2A93A !important',
    },
  }),
  ...(type === 'note' && {
    backgroundColor: '#26A69A !important',
    '&:hover': {
      backgroundColor: '#26A69A !important',
    },
  }),
  ...(type === 'delete' && {
    backgroundColor: '#E0E0E0 !important',
    '&:hover': {
      backgroundColor: '#E0E0E0 !important',
    },
  }),
  '&:hover': {
    color: '#fff',
  },
  '> svg': {
    width: 30,
    height: 30,
  },
}));

export const ProfileActionsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 12,
  justifyContent: 'space-evenly',
  alignItems: 'center',
  marginTop: '24px',
  // margin: '0px 60px',
  // '& button svg': {
  //   color: theme.palette.primary.main,
  //   fill: theme.palette.primary.main,
  //   fontSize: '1em',
  // },
  // '& button': {
  //   '&:hover': {
  //     backgroundColor: theme.palette.primary.main,
  //     '& svg': {
  //       color: '#ffffff',
  //       fill: '#ffffff',
  //     },
  //   },
  // },
}));

export const ActionButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const ProfileWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
}));

export const ContactWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  overflowY: 'auto',
  height: '100%',
  '&::-webkit-scrollbar': {
    width: 6,
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: 4,
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'lightgray',
    borderRadius: 6,
  },
}));

export const JobTitle = styled(Typography)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.6)',
  fontSize: '14px',
  display: 'inline',
  '& span': {
    color: theme.palette.primary.light,
  },
}));

export const JobLocation = styled(Typography)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.38)',
  fontSize: '14px',
}));

export const SequenceInfo = styled(Typography)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.54)',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  lineHeight: 2,
}));

export const ProfileAction = styled(Typography)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.54)',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  lineHeight: 2,
  justifyContent: 'center',
}));

export const Left = styled('div')(({ theme }) => ({
  marginTop: '8px',
}));

export const Right = styled('div')(({ theme }) => ({
  width: '100%',
  '& .editButton .MuiSvgIcon-root': {
    fontSize: '1.25em',
    color: 'rgba(0,0,0,0.54)',
  },
  '& .edit-icon': {
    fontSize: '14px',
    color: 'rgba(0,0,0,0.58)',
  },
}));

export const SeqStatus = styled('div')(({ theme, status }) => ({
  width: '10px',
  height: '10px',
  borderRadius: '8px',
  // backgroundColor: status === 'active' ? '#66BB6A' : theme.palette.grey[500],
  ...(status === sequenceEnrollmentStatus.BOUNCED && {
    backgroundColor: '#FFA726',
  }),
  ...(status === sequenceEnrollmentStatus.COMPLETED && {
    backgroundColor: '#2E7D32',
  }),
  ...(status === sequenceEnrollmentStatus.HOLD && {
    backgroundColor: '#9C27B0',
  }),
  ...(status === sequenceEnrollmentStatus.IN_PROGRESS && {
    backgroundColor: '#A5D6A7',
  }),
  ...(status === sequenceEnrollmentStatus.UNSUBSCRIBED && {
    backgroundColor: '#ED6C02',
  }),
  ...(status === sequenceEnrollmentStatus.INTERESTED && {
    backgroundColor: '#66BB6A',
  }),
  ...(status === sequenceEnrollmentStatus.NOT_INTERESTED && {
    backgroundColor: '#D32F2F',
  }),
  ...(status === sequenceEnrollmentStatus.FAILED && {
    backgroundColor: '#D32F2F',
  }),
  ...(status === sequenceEnrollmentStatus.ERROR && {
    backgroundColor: '#D32F2F',
  }),
  ...(status === sequenceEnrollmentStatus.NEVER && {
    backgroundColor: '#ffffff',
    border: '2px solid #999999',
  }),
}));
