import React, { useEffect, useState } from 'react';
import {
  Typography,
  Card,
  CardContent,
  CardHeader,
  InputAdornment,
  Stack,
  Box,
  Tooltip,
} from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import { useDataProviders } from '../../utils/dataProviderStore';
import toast from 'src/utils/toast';
import InfoOutlinedIcon from '@mui/icons-material/Info';
import { makeStyles } from '@mui/styles';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';

const useStyles = makeStyles(() => ({
  noteTypeTooltip: {
    maxWidth: 'none',
    // maxWidth: '500px',
    // background: '#082037',
    // padding: '14px',

    '& .tooltipWrapper': {
      background: '#082037',
      margin: -10,
      padding: 20,
      gap: 20,
      borderRadius: 6,
      maxWidth: 700,
    },
    '& .MuiTooltip-arrow': {
      color: '#082037',
      fontSize: 36,
    },
  },
}));

function AccountCreditLimit({ defaultCreditLimit }) {
  const classes = useStyles();
  const [btnLoading, setBtnLoading] = useState(false);
  const { setDefaultCreditAllocation } = useDataProviders();
  const [totalUsers, setTotalUsers] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchUsersLookup('', false, true);
      setTotalUsers(res?.total?.value);
    };
    fetchData();
  });

  const handleSubmit = async (data) => {
    try {
      setBtnLoading(true);
      const response = await setDefaultCreditAllocation(data.limit);
      if (Number(data.limit) === response?.[0]?.defaultCredit)
        toast.success('User credit limit has been updated');
      else toast.error('User credit limit has not been updated');
      setBtnLoading(false);
    } catch (error) {}
  };

  return (
    <Card>
      <CardHeader
        subheader={
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography sx={{ color: 'rgba(0, 0, 0, .87)', fontWeight: 500 }}>
              User credit limit
            </Typography>
            <Typography color={'secondary'} sx={{ fontWeight: 500 }}>
              {totalUsers} users
            </Typography>
          </Stack>
        }
        subheaderTypographyProps={{ color: 'textPrimary' }}
      />
      <CardContent sx={{ paddingTop: 0 }}>
        <Typography variant="body1" color="rgba(0, 0, 0, .87)">
          Set the default limit you want each user to spend each month. You can add exceptions as
          required.
        </Typography>
        <Spacer y={2} />
        <Typography variant="body2" color="textSecondary" alignItems="center" display="flex">
          Default allocation for all users <Spacer x={1} />
          <Tooltip
            arrow
            describeChild
            classes={{ tooltip: classes.noteTypeTooltip }}
            tooltip
            title={
              <Stack className="tooltipWrapper">
                <Typography
                  style={{
                    whiteSpace: 'pre-line',
                    fontSize: 14,
                    color: 'white',
                  }}
                >
                  {`We’ve gone ahead and created a default allocation for your users (your total credit allowance divided evenly across all of your active users).

                  You can change the default allocation or create bespoke allocations to suit the needs of your business.

                  We recommend allocating ~10% more than your budget allows to each user. This ensures that your most active team members never run out of credits.

                  Remember, you will never be charged more than your Account limit.`}
                </Typography>
              </Stack>
            }
          >
            <InfoOutlinedIcon style={{ fill: 'rgba(0, 0, 0, 0.26)', width: 22 }} />
          </Tooltip>
        </Typography>
        <Form
          initialValues={{
            limit: defaultCreditLimit,
          }}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({ values, ...formProps }) => {
            return (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formProps.submitForm();
                  return false;
                }}
                noValidate
              >
                <Stack direction="row" alignItems="flex-end">
                  <Form.Field.Input
                    name="limit"
                    variant="outlined"
                    size="small"
                    icon={<InputAdornment position="start">$</InputAdornment>}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                  <Box ml={2} mb={1.2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      loading={btnLoading}
                    >
                      Save
                    </Button>
                  </Box>
                </Stack>
              </form>
            );
          }}
        </Form>
      </CardContent>
    </Card>
  );
}

export default AccountCreditLimit;
