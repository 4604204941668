import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';

const InfoColorCard = styled.div`
  width: 28px;
  height: 14px;
  left: 279px;
  top: 597px;
  background: ${(props) => `${props.color}`};
  border-radius: 4px;
  margin-bottom: 4px;
`;

const COLORS = ['#1565C0', '#42A5F5', '#B3E5FC', '#013654'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const InfoCard = ({ title, color }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <InfoColorCard color={color} />
      <Typography variant="body2" color="textSecondary">
        {title}
      </Typography>
    </Box>
  );
};

export default function EmailsChart({ email }) {
  const data = useMemo(() => {
    return Object.keys(email).map((key) => ({
      name: key,
      value: email[key],
    }));
  }, [email]);

  return (
    <Box>
      <ResponsiveContainer width="100%" height={340}>
        <PieChart width={400} height={400}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <Box display="flex" justifyContent="space-around">
        <InfoCard title="Clicked" color={COLORS[0]} />
        <InfoCard title="Opened" color={COLORS[1]} />
        <InfoCard title="Replied" color={COLORS[2]} />
        <InfoCard title="Sent" color={COLORS[3]} />
      </Box>
    </Box>
  );
}
