import styled from 'styled-components';

export const FormWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  position: relative;
  border-radius: 4px;
  padding: 12px 10px;
`;

export const Panels = styled.div`
  display: flex;
  overflow: auto;
  position: relative;
  max-height: calc(100vh - 108px);
  padding-left: 30px;
  margin-left: ${(props) => (props.isOpen ? '264px' : '0px')};
  width: ${(props) => (props.isOpen ? 'calc(100vw - 534px)' : 'calc(100vw - 270px)')};
`;

export const PanelsWrapper = styled.div`
  width: ${(props) => (props.type === 'panels' ? 'calc(100vw - 244px)' : '100%')};
  overflow: auto;
  position: relative;
  overflow: hidden;
  display: ${(props) => (props.type === 'panels' ? 'flex' : 'block')};
`;

export const FixedTitleBar = styled.div`
  background-color: #ffffff;
  position: absolute;
  left: 0;
  width: 100%;
`;

export const ColorPicker = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
`;
