import { makeStyles } from '@mui/styles';
import { themeColors } from 'src/modules/lms/config';
const useStyles = makeStyles(({ palette }) => ({
  button: {
    width: '102px',
    borderRadius: '6px',
    textTransform: 'capitalize',
  },
  createButton: {
    backgroundColor: themeColors.purple,
    textTransform:'none',
    color:themeColors.white,
    '&:hover': {
      backgroundColor: themeColors.purple,
    },
  },
  comptName:{
    whiteSpace:'normal',
    color:'#A0BED9',
    marginRight:'10px',
    overflow:'hidden',
    textOverflow:'ellipsis',
    width:'160px'
  },
  live: {
    color:themeColors.greenText,
    background: 'rgba(19, 222, 161, 0.12)',
  },
  draft: {
    color:'#A0BED9',
    background: 'rgba(160, 190, 217, 0.12)',
  },
  completed: {
    color:'#F6F2D6',
    background: 'rgba(246, 242, 214, 0.12)',
  },
  pendingStart: {
    color:'#D9A0B3',
    background: 'rgba(217, 160, 179, 0.12)',
  },
  reportCell: {
    color: themeColors.yellow,
    marginRight: '20px',
  },
  leagueCell: {
    color: '#F28AE2',
  },
  emptyTitle:{
    color:themeColors.skyBlue
  }
}));

export default useStyles;
