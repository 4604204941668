import React, { useState, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, Chip, Typography, Box } from '@mui/material';
import { AppGrid, ConfirmDialog } from 'src/components/App';
import { useStyles } from '../../containers/integrations/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import OfficeGridActions from './OfficeGridActions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import { Button, Spacer } from 'src/components/shared';
import AddIcon from '@mui/icons-material/Add';
import { userBulkActions } from './config';
import OfficeActionDrawer from './OfficeActionDrawer';
import { capitalizeFirstLetter } from 'src/utils/helper';

function TeamsGrid({
  drawerToggle,
  offices,
  loading,
  userBulkUpdate,
  officeBulkUpdate,
  globals,
  ...props
}) {
  const classes = useStyles();
  const [rowsSelected, setRowsSelected] = useState([]);
  const [bulkAction, setBulkAction] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const { data, filters, paging, sort } = offices;

  const officesColumns = useMemo(
    () => [
      {
        name: 'view',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          allowToggle: false,
          viewColumns: false,
          setCellHeaderProps: (value) => ({
            style: { width: 10 },
          }),
          setCellProps: () => ({
            style: { width: 10 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const row = data[dataIndex];
            return (
              <Box justifyContent="center" display="flex">
                <Tooltip title="View Office">
                  <VisibilityIcon
                    fontSize={'small'}
                    style={{ color: 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }}
                    onClick={() => {
                      drawerToggle({ edit: row, open: true });
                    }}
                  />
                </Tooltip>
              </Box>
            );
          },
        },
      },
      {
        label: 'Office name',
        name: 'name',
        options: {
          filter: true,
          sort: true,
          draggable: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 180 },
          }),
          setCellProps: () => ({
            style: { minWidth: 180 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = data[dataIndex];
            return (
              <Link
                to="/admin/offices"
                className="text-truncate td-anchor-content-ellipsis"
                onClick={() => {
                  drawerToggle({ edit: rData, open: true });
                }}
              >
                <Typography variant="body2" color="secondary">
                  {`${capitalizeFirstLetter(rData?.name)}`}
                </Typography>
              </Link>
            );
          },
        },
      },
      {
        label: 'City',
        name: 'city',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 10 },
          }),
          setCellProps: () => ({
            style: { minWidth: 10 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = data[dataIndex];
            return <div>{capitalizeFirstLetter(rData?.city)}</div>;
          },
        },
      },
      {
        label: 'Country',
        name: 'country',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 10 },
          }),
          setCellProps: () => ({
            style: { minWidth: 10 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = data[dataIndex];
            return <div>{capitalizeFirstLetter(rData?.country)}</div>;
          },
        },
      },

      {
        label: 'Users',
        name: 'user',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 90 },
          }),
          setCellProps: () => ({
            style: { minWidth: 90 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = data[dataIndex];
            return <div>{rData.users.length}</div>;
          },
        },
      },
      {
        label: 'Status',
        name: 'status',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 60 },
          }),
          setCellProps: () => ({
            style: { minWidth: 60 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = data[dataIndex];
            return (
              <Box
                className={`${
                  rData?.status === 'active'
                    ? classes.gridContentRowDataActive
                    : classes.gridContentRowDataDeActive
                }`}
              >
                <Button
                  sx={{
                    color: '#FFFFFF',
                    width: '80%',
                    background: rData?.status === 'active' ? '#66BB6A' : '#EF5350',
                  }}
                >
                  {rData?.status === 'active' ? 'Active' : 'Deactive'}
                </Button>
              </Box>
            );
          },
        },
      },
      {
        name: 'id',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          allowToggle: false,
          viewColumns: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          customBodyRender: (value) => {
            const row = data.find((task) => task.id === value);
            return (
              <OfficeGridActions
                drawerToggle={drawerToggle}
                status={row?.status}
                row={row}
                {...props}
              />
            );
          },
        },
      },
    ],
    [data],
  );

  const handlePerPageChange = (data1, data2) => {};

  const onSort = (sort) => {
    props.fetchOffices(paging, filters, sort);
  };

  const handleTableChange = ({ pageNo, perPage, searchText, sortOrder }) => {
    props.fetchOffices(
      {
        pageNo,
        perPage,
      },
      filters,
      sort,
    );
  };

  const onFilterChange = (key, value) => {
    if (value && typeof value !== 'undefined') {
      if (key === '_search') {
        props.fetchOffices(
          {
            ...paging,
            pageNo: 0,
          },
          { ...filters, [key]: value },
          sort,
        );
      } else {
        props.fetchOffices(paging, { ...filters, [key]: value }, sort);
      }
    } else {
      delete filters[key];
      props.fetchOffices(paging, filters, sort);
    }
  };

  const handleColumnChange = (data1, data2) => {};
  const onBulkAction = (key) => {
    setBulkAction(key);
  };
  const closeBulkAction = (key) => {
    setBulkAction(undefined);
    setRowsSelected([]);
  };
  const selectedOffices = useMemo(() => {
    return data.filter((item, index) => rowsSelected.includes(index));
  }, [rowsSelected, data]);

  const bulkDeleteContacts = async () => {
    try {
      setIsDeleting(true);
      await props.contactBulkAction(bulkAction, selectedContacts, {});
      setIsDeleting(false);
      closeBulkAction();
    } catch (error) {
      setIsDeleting(false);
    }
    //
  };

  return (
    <>
      <AppGrid
        selectableRows="multiple"
        columns={officesColumns.map((col, index) => ({
          ...col,
          options: officesColumns?.columns?.length
            ? { ...col.options, ...officesColumns?.columns[index] }
            : col.options,
        }))}
        data={data}
        onSort={onSort}
        onTableRowPerPageChange={handlePerPageChange}
        onTableChange={handleTableChange}
        onColumnChange={() => handleColumnChange(false)}
        onFilterChange={onFilterChange}
        appliedFilters={filters}
        loading={loading.offices}
        bulkActions={userBulkActions}
        onBulkAction={onBulkAction}
        options={{
          sortOrder: {
            name: sort?.name || 'none',
            direction: sort?.direction || 'none',
          },
          serverSide: true,
          pagination: true,
          page: paging.pageNo,
          rowsPerPage: paging.perPage,
          count: paging.count,
          selectableRows: 'multiple',
          searchPlaceholder: 'Search Offices',
          searchText: filters?._search ? filters._search : '',
          searchOpen: true,
          search: true,
          filter: false,
          download: false,
          viewColumns: false,
          selectToolbarPlacement: 'replace',
          renderCustomFilterActions: () => {
            return (
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                // startIcon={<AddIcon />}
                onClick={() => {
                  drawerToggle({ edit: null, open: true });
                }}
              >
                <Typography variant="body2">Create Office</Typography>
              </Button>
            );
          },
          onRowSelectionChange: (selected, all) => {
            setRowsSelected(all.map((row) => row.dataIndex));
          },
          rowsSelected: rowsSelected,
        }}
      />
      <OfficeActionDrawer
        action={bulkAction && bulkAction !== 'delete' ? bulkAction : false}
        onClose={closeBulkAction}
        officeBulkUpdate={officeBulkUpdate}
        selectedOffices={selectedOffices}
        globals={globals}
      />
      <ConfirmDialog
        title="Delete Contact(s)"
        open={bulkAction === 'delete'}
        onClose={closeBulkAction}
        onConfirm={bulkDeleteContacts}
        loading={isDeleting}
        {...props}
      >
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <Typography variant="body1" color="textSecondary">
            Are you sure you want to delete selected users.
          </Typography>
        </Box>
      </ConfirmDialog>
    </>
  );
}

export default TeamsGrid;
