import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Chip, LinearProgress, Tooltip, Typography, CircularProgress } from '@mui/material';
import { Skeleton } from '@mui/lab';
import { InfoIconWrapper, InfoIcon, Content, Title, Value } from './styles';
import { fetchWidgetData } from '../../../actions/boardActions';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function LinearProgressWithLabel({ value }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" color="secondary" value={value} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
}

function SingleCard({ data }) {
  const dispatch = useDispatch();
  const { title, description, key, loading } = data;
  const { value, progress, progressType } = data.data;

  useEffect(() => {
    dispatch(fetchWidgetData(data.i));
  }, []);

  return (
    <Content>
      <InfoIconWrapper>
        <Tooltip title={description}>
          <InfoIcon />
        </Tooltip>
      </InfoIconWrapper>

      <Box alignItems="center" justifyContent="center">
        {loading === true ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Skeleton animation="wave" height={44} width={'60%'} />
          </Box>
        ) : (
          <Value textAlign="center">{value || 0}</Value>
        )}

        <Title textAlign="center">{title}</Title>
      </Box>
      {loading !== true && title !== 'Prospects Enrolled' ? (
        <>
          {progressType === 'chip' ? (
            <>
              {progress >= 0 ? (
                <Chip
                  variant="outlined"
                  size="small"
                  label={`${progress}%`}
                  color="success"
                  // icon={<ArrowUpwardIcon />}
                />
              ) : (
                <Chip
                  variant="outlined"
                  size="small"
                  label={`${progress}%`}
                  color="error"
                  // icon={<ArrowDownwardIcon />}
                />
              )}
            </>
          ) : (
            <LinearProgressWithLabel value={progress || 0} />
          )}
        </>
      ) : (
        <Box height={20} />
      )}
    </Content>
  );
}

export default SingleCard;
