import { Card, Chip } from '@mui/material';
import styled from 'styled-components';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export const DayChip = styled(Chip).attrs({})`
  border-radius: 4px;
  /* width: 66px; */
  cursor: pointer;
  height: 30px;
  align-items: center;
`;

export const CardWrapper = styled(Card)`
  margin-bottom: 10px;
  padding: 6px 10px;
  border: none;
  border-left: ${(props) => (props.type === 'default' ? '4px solid #082037' : 'none')};
  display: flex;
  justify-content: space-between;
  box-shadow: none;
`;

export const FieldsTable = styled(Table)`
  border: none;
`;
export const Tr = styled(TableRow)`
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.12); */
  border: none;
`;
export const Td = styled(TableCell)`
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.12); */
  padding: 4px;
`;
export const TBody = styled(TableBody)``;

export const TableHeader = ({ view }) => {
  return (
    <TableHead>
      <Tr>
        <Th></Th>
        <Th></Th>
      </Tr>
    </TableHead>
  );
};
