import React, { useState } from 'react';
import { Tabs } from 'src/components/App';
import AdvancedFilters from './Filters/AdvancedFilters';
import SimpleFilters from './Filters/SimpleFilters';
import { filterTabTypes } from '../../../config';

function ContactFilters({ onClose, onChange, filters }) {
  const [currentTab, setCurrentTab] = useState('simpleFilter');

  return (
    <>
      {/* <Tabs tabs={filterTabTypes} onChange={(tab) => setCurrentTab(tab.id)} value={currentTab} /> */}
      <AdvancedFilters filters={filters} onClose={onClose} onChange={onChange} />
    </>
  );
}

export default ContactFilters;
