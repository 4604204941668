export const templateStatuses = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
];

export const templateBulkAction = (type = 'email') => {
  if (type === 'email')
    return [
      {
        title: 'Change Status',
        key: 'changeStatus',
      },
      {
        title: 'Change Category',
        key: 'changeCategory',
      },
      {
        title: 'Change Permission Level',
        key: 'changePermissionLevel',
      },
      {
        title: 'Change Owner',
        key: 'changeOwner',
      },
    ];
  else {
    return [
      {
        title: 'Change Status',
        key: 'changeStatus',
      },
      {
        title: 'Change Type',
        key: 'changeType',
      },
      {
        title: 'Change Permission Level',
        key: 'changePermissionLevel',
      },
      {
        title: 'Change Owner',
        key: 'changeOwner',
      },
    ];
  }
};

export const templateLinkedinType = [
  {
    label: 'linkedin message',
    value: 'linkedinMessage',
  },
  {
    label: 'linkedin Mail',
    value: 'linkedinMail',
  },
  {
    label: 'linkedin Connection',
    value: 'linkedinConnection',
  },
];
