import React from 'react';
import { Box, Typography, IconButton, Grid } from '@mui/material';
import CardMedia from '../../shared/cardMedia';

import useStyles from './style';
import { calculateListDaysAgo, removeHTML, truncate } from '../../utils/helper';

import StarIcon from '@mui/icons-material/Star';

function Session({ item, removeFromList }) {
  const classes = useStyles();

  return (
    <>
      <CardMedia
        isClickable={true}
        data={item?.session}
        dontShowAction
        src={
          item?.session?.thumbnailImage
            ? item?.session?.thumbnailImage
            : item?.course?.thumbnailImage
        }
        type={'thumbnailImage'}
        contentType={'session'}
      />
      <Typography className={classes.header}>
        {item?.session?.title ? item?.session?.title : item?.course?.title}
      </Typography>
      {item?.session?.overviewText ? (
        <Box className={classes.descriptionBox}>
          <Typography
            className={classes.description}
            dangerouslySetInnerHTML={{
              __html: removeHTML(item?.session?.overviewText),
            }}
          ></Typography>
        </Box>
      ) : null}

      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography className={classes.caption}>{calculateListDaysAgo(item)}</Typography>
        <IconButton
          onClick={() => {
            removeFromList(item?.id);
          }}
        >
          <StarIcon sx={{ fill: 'rgba(255, 212, 126, 1)' }} />
        </IconButton>
      </Box>
    </>
  );
}

export default Session;
