import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Box, Paper, Grid, Typography, Container, Link } from '@mui/material';
import { TabContext } from '@mui/lab';

import { Button, Spacer, Form } from 'src/components/shared';
import { ConfirmDialog, Tabs } from 'src/components/App';

import DetailsForm from '../../components/DetailsForm';
import AccountsForm from '../../components/AccountsForm';
import ConnectEmail from '../../components/ConnectEmail';
import Signature from '../../components/SignatureForm';
import EmailLimitsIntegration from '../../components/ConnectEmail/EmailLimitsIntegration';
import SchedulingWindow from '../../components/ConnectEmail/SchedulingWindow';
import Security from '../../components/security/Security';
import ChangePassword from '../../components/security/ChangePassword';
import CustomVariables from '../../../app/components/CustomVariables';
import Notification from '../../components/Notification';
import AISettings from '../../components/AISettings';

import { styled } from '@mui/material/styles';
import { ProfileTabs, ProfileTabsLms } from '../../config';

import { saveRescheduleEmail } from './../../../admin/actions/adminActions';
import {
  putUser,
  fetchUsers,
  putAccount,
  uploadImage,
  resetPassword,
  setNLToken,
  disconnectIntegration,
  deleteProfileImage,
} from '../../actions/authActions';
import { fetchUserById } from 'src/modules/admin/api/adminApi';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

function MyProfileView({ contacts, newTab, ...props }) {
  const [tabIndex, setTabIndex] = useState(0);
  const [tab, setTab] = useState('details');
  const currentTab = useRef({});
  const params = useParams();
  const history = useHistory();
  const search = useLocation().search;
  const code = new URLSearchParams(search).get('code');
  const isOtherUser = new URLSearchParams(search).get('otheruser');
  const userid = props.user?.id;
  const [isFirstTime, setIsFirstTime] = useState(false);
  const [otherProfileInfo, setOtherProfileInfo] = useState({});

  useEffect(() => {
    if (code && userid) {
      props.setNLToken(code, userid);
    }
  }, [code, params.id]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (!isFirstTime && !params.id) {
  //       refreshCurrentUserInfo(props.user?.id);
  //     }
  //   }, 3000);
  // }, []);

  useEffect(() => {
    // if (isOtherUser && params.id) {
    if (params.id && params.id !== '') {
      fetchUserById(params.id)
        .then((r) => setOtherProfileInfo(r.user))
        .catch((e) => console.log(e));
    } else if (params.id) {
      // getUserDetailsForAdmin(params.id);
    }
  }, [params.id]);

  const currentUser = useMemo(() => {
    if (props?.match?.params?.id) {
      return otherProfileInfo;
    } else {
      return props.user;
    }
  }, [props?.match, otherProfileInfo, props.user]);

  const hasLmsAccess = useMemo(() => {
    return currentUser?.lmsmonthly === true || currentUser?.lmsannual === true;
  }, [currentUser]);

  const hasOutreachAccess = useMemo(() => {
    if (
      !currentUser?.outreachAnnual &&
      !currentUser?.outreachMonthly &&
      !currentUser?.lmsmonthly &&
      !currentUser?.lmsannual
    ) {
      return true;
    }
    return currentUser?.outreachAnnual === true || currentUser?.outreachMonthly === true;
  }, [currentUser]);

  const tabs = useMemo(() => {
    if (hasOutreachAccess) return ProfileTabs;
    return ProfileTabsLms;
  }, [hasOutreachAccess, hasLmsAccess]);

  const refreshCurrentUserInfo = useCallback((currentUserId) => {
    setIsFirstTime(true);
    props.fetchUsers(currentUserId);
  });

  const getUserDetailsForAdmin = useCallback((userId) => {
    props.fetchUsers(userId);
  });

  useEffect(() => {
    const segments = history?.location?.pathname.split('/');
    const tabId = segments.pop() || segments.pop();
    const index = ProfileTabs.findIndex((item) => item.id === tabId);
    setTabIndex(index);
    setTab(tabId);
  }, [history?.location?.pathname]);

  const onTabChange = (tab, index) => {
    if (params.id) {
      setTab(tab.id);
      setTabIndex(index);
      currentTab.current = tab;
      history.push(`/admin/user/${params.id || props.user?.id}/${tab.id}`);
    } else {
      setTab(tab.id);
      setTabIndex(index);
      currentTab.current = tab;
      history.push(`/profile/${tab.id}`);
    }
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    flexDirection: 'initial',
    textAlign: 'left',
    color: theme.palette.text.secondary,
    gridRow: '1',
    gridColumn: 'span 3',
  }));

  const updateUser = async (payload) => {
    try {
      const res = await props.putUser(payload);
      setOtherProfileInfo(res.user);
    } catch (error) {}
  };
  const isOtherProfileInfoExist = !!Object.keys(otherProfileInfo).length;
  return (
    <>
      <TabContext value={tab}>
        <Tabs tabs={tabs} onChange={onTabChange} value={tab} />
        <Spacer y={2} x={2} />
        <Container disableGutters maxWidth="lg" sx={{ marginLeft: 0 }}>
          {tab === 'details' ? (
            <Item>
              <DetailsForm
                profileImage={isOtherProfileInfoExist ? otherProfileInfo : props.user}
                editUser={currentUser}
                status={props.status}
                userImg={props.profileImage}
                deleteProfileImage={props.deleteProfileImage}
                {...props}
                putUser={updateUser}
                hasLmsAccess={hasLmsAccess}
                hasOutreachAccess={hasOutreachAccess}
              />
            </Item>
          ) : null}

          {tab === 'account' ? (
            <Item>
              <AccountsForm
                editUser={currentUser}
                status={props.status}
                total={props.total}
                officeTotal={props.officeTotal}
                teamsTotal={props.teamsTotal}
                {...props}
                putUser={updateUser}
              />
            </Item>
          ) : null}

          {tab === 'email' ? (
            <>
              <Item>
                {props && (
                  <ConnectEmail
                    editUser={currentUser}
                    status={props.status}
                    {...props}
                    putUser={updateUser}
                  />
                )}
              </Item>
              <Typography style={{ marginTop: '12px', padding: '16px' }}>
                Superreach’s use and transfer of information received from Google APIs to any other
                app will adhere to{' '}
                <Link
                  color="secondary"
                  target="_blank"
                  href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                >
                  Google API Services User Data Policy
                </Link>
                , including the Limited Use requirements.
              </Typography>
            </>
          ) : null}

          {tab === 'outreachSettings'
            ? props && (
                <SchedulingWindow
                  editUser={currentUser}
                  Container={Item}
                  status={props.status}
                  {...props}
                  putUser={updateUser}
                />
              )
            : null}

          {tab === 'customVariables' ? <CustomVariables /> : null}

          {tab === 'notification' ? (
            <Notification
              editUser={currentUser}
              status={props.status}
              {...props}
              putUser={updateUser}
            />
          ) : null}

          {tab === 'security' ? (
            <>
              <Item>
                <ChangePassword
                  {...props}
                  user={isOtherProfileInfoExist ? otherProfileInfo : props.user}
                  editUser={isOtherProfileInfoExist ? otherProfileInfo : props.user}
                  status={props.status}
                  putUser={updateUser}
                />
              </Item>
              {props?.isAdmin ? (
                <>
                  <Spacer x={4} y={4} />
                  <Item>
                    <Security
                      editUser={currentUser}
                      status={props.status}
                      isAdmin={props?.isAdmin}
                      {...props}
                      putUser={updateUser}
                    />
                  </Item>
                </>
              ) : null}
            </>
          ) : null}

          {tab === 'signature' ? (
            <Item>
              <Signature
                editUser={currentUser}
                status={props.status}
                {...props}
                putUser={updateUser}
              />
            </Item>
          ) : null}

          {tab === 'aiLearning' ? (
            <Item>
              <AISettings editUser={currentUser} status={props.status} putUser={updateUser} />
            </Item>
          ) : null}
        </Container>
      </TabContext>
    </>
  );
}

const mapStateToProps = (state) => ({
  globals: state.app.globals,
  status: state.app.status,
  isAdmin: state.auth.user.isAdmin,
  user: state.auth.user,
  profileImage: state.auth.profileImage,
  officeTotal: state.admin.admin.offices?.paging.count,
  teamsTotal: state.admin.admin.teams?.paging.count,
});

const mapDispatchToProps = (dispatch) => ({
  putUser: (userData) => {
    return new Promise((resolve, reject) => {
      dispatch(putUser(userData, resolve, reject));
    });
  },
  resetPassword: (userData) => {
    return new Promise((resolve, reject) => {
      dispatch(resetPassword(userData, resolve, reject));
    });
  },
  fetchUsers: (id) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchUsers(id, resolve, reject));
    });
  },
  uploadImage: (file) => {
    return new Promise((resolve, reject) => {
      dispatch(uploadImage(file, resolve, reject));
    });
  },
  deleteProfileImage: (id) => {
    return new Promise((resolve, reject) => {
      dispatch(deleteProfileImage(id, resolve, reject));
    });
  },
  setNLToken: (code, userid) => {
    return new Promise((resolve, reject) => {
      dispatch(setNLToken(code, userid, resolve, reject));
    });
  },
  disconnectIntegration: (id, status) => {
    return new Promise((resolve, reject) => {
      dispatch(disconnectIntegration(id, status, resolve, reject));
    });
  },
  saveRescheduleEmail: (data) => {
    return new Promise((resolve, reject) => {
      dispatch(saveRescheduleEmail(data, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MyProfileView);
