import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({ palette }) => ({
  Card: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '6px',
    height: '105%',
  },
  CardContent: {
    marginTop: '25%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    cursor:'pointer',
  },
  AddIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  title: {
    fontWeight: '600',
    fontSize: '20px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
  },
  titleBox: {
    padding: '10%',
    minHeight: '110px',
  },
  stepTitle: {
    fontWeight: '500 !important',
    fontSize: '16px !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    letterSpacing: '0.15px !important',
  },
  subTitle: {
    fontWeight: '400',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  publishButton: {
    marginRight: '16px',
  
    color: '  #1976D2',
  },
  saveButton: {
    backgroundColor: '  #1976D2',
  },
  modalContent: {
    '& .MuiDialogContent-root': {
      borderTop: '1px solid rgba(0, 0, 0, 0.12) ',
    },
  },
  FileDropper: {
    position: 'relative',
    marginBottom: '1.8%',
    paddingLeft: '0 !important',
  },
  heading: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: '500',
    fontSize: '18px',
    letterSpacing: '-0.5px',
  },
  actionMenu: {
    display: 'flex',
    justifyContent: 'right',
    marginRight: '4%',
    marginTop: '2%',
  },
  menuSection: {
    color: 'grey',
  },
  menuLinks: {
    color: 'rgba(0, 0, 0, 0.87) !important',
  },
  button: {
    display: 'flex',
    justifyContent: 'right',
    padding: '4%',
    '& button': {
      borderRadius: '6px',
      color: 'white !important',
      textTransform: 'capitalize',
    },
  },
  live: {
    background: '#E53935',
  },
  unpublished: {
    background: 'rgba(0, 0, 0, 0.87)',
  },
}));
export default useStyles;
