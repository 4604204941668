import styled from '@emotion/styled';

export const style = {
  position: 'absolute',
  top: '50%',
  left: '80%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const StyleWrapper = styled.div`
  .fc-button.fc-prev-button,
  .fc-button.fc-next-button,
  .fc-button.fc-button-primary,
  .fc-button.fc-button-primary:hover,
  .fc-button.fc-button-primary:focus {
    background: rgba(25, 118, 210, 0.08);
    background-image: none;
    text-transform: capitalize;
    border: 1px solid #fff;
    color: rgba(0, 0, 0, 0.6);
  }
  .fc .fc-button-primary:not(:disabled):active,
  .fc .fc-button-primary:not(:disabled).fc-button-active {
    background: rgba(25, 118, 210, 0.08);
    border: 1px solid rgba(0, 0, 0, 0.6);
    color: rgba(0, 0, 0, 0.6);
    background-image: none;
    text-transform: capitalize;
    box-shadow: none;
  }
  .fc-button.fc-button-primary:focus {
    box-shadow: none !important;
  }
  .fc-toolbar-title {
    font-size: 16px !important;
  }
  .fc .fc-button-primary:not(:disabled).fc-button-active,
  .fc .fc-button-primary:not(:disabled).fc-button-active:hover {
    background: rgba(25, 118, 210, 0.08);
    border: 1px solid #fff;
    color: rgba(0, 0, 0, 0.6);
  }
  .fc .fc-daygrid-day.fc-day-today {
    background-color: #fff !important;
    /* background-color: var(--fc-today-bg-color, rgba(255, 220, 40, 0.15)); */
  }
  .fc-timegrid-slot {
    background-color: #fff !important;
  }
  .fc-v-event .fc-event-main {
    background: rgb(8, 32, 55) !important;
  }
  .fc-h-event {
    background: rgb(8, 32, 55) !important;
  }
  .fc-timegrid-event,
  .fc-timegrid-more-link {
    font-size: 0.6em !important;
  }
  .fc-daygrid-event {
    font-size: 0.6em;
    font-size: var(--fc-small-font-size, 0.6em);
  }
`;
