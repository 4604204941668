import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, CardMedia, Typography, TextField, IconButton } from '@mui/material';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import parse from 'html-react-parser';
import CloseIcon from '@mui/icons-material/Close';

import images from 'src/config/images';
import { themeColors } from 'src/modules/lms/config';
import { useStyles, StyledYellowHeader, StyledSearchIcon } from './style';
import SessionListingBig from '../../../../shared/sessionListingBig/SessionListingBig';
import { CoverButtonSection } from '../../../CoverButtonSection/CoverButtonSection';
import TwoImageSideSection from '../../../TwoImageSideSection';
import SearchIcon from '@mui/icons-material/Search';
import ExpertSection from '../../../../shared/expertSection/ExpertSection';
import PhilosophySection from 'src/modules/lms/shared/philosophySection/PhilosophySection';
import PageDetailSkeleton from 'src/modules/lms/shared/pageDetailSkeleton/PageDetailSkeleton';

const PillarDetailsSection = ({ pillarData = {}, loading, ...props }) => {
  const [inputHide, setInputHide] = React.useState('none');
  const [iconHide, setIconHide] = React.useState('block');
  const InputField = () => {
    setInputHide('block');
    setIconHide('hidden');
  };
  const [data, setData] = React.useState(pillarData?.sessions);
  useEffect(() => {
    setData(pillarData?.sessions);
  }, [pillarData?.sessions]);
  const [searchKeyword, setSearchData] = React.useState('');
  const handleKeyupSearch = (evt) => {
    let searchValue = evt.target.value;
    setSearchData(searchValue);
    let filteredData = [];
    if (!searchValue) {
      filteredData = pillarData.sessions;
    } else {
      filteredData = pillarData.sessions?.filter((item) => {
        return item?.title.toLowerCase().includes(searchValue.toLowerCase());
      });
    }
    setData(filteredData);
  };
  const currentUrl = props?.location?.pathname;
  const classes = useStyles();
  const breadcrumbs = [
    <Link underline="hover" key="1" className={classes.links} to="/lms">
      SuperReach Learning
    </Link>,
    <Link underline="hover" key="2" className={classes.links} to={currentUrl ? currentUrl : '/lms'}>
      {pillarData?.title}
    </Link>,
  ];
  const onClose = (evt) => {
    let searchValue = evt.target.value;
    setSearchData(searchValue);
    let filteredData = [];
    if (!searchValue) {
      filteredData = pillarData.sessions;
    } else {
      filteredData = pillarData.sessions?.filter((item) => {
        return item?.title.toLowerCase().includes(searchValue.toLowerCase());
      });
    }
    setData(filteredData);
    setSearchData('');
    handleKeyupSearch;
  };

  if (loading) return <PageDetailSkeleton />;

  return (
    <Box>
      <Grid container spacing={2} mb={5}>
        <Grid item md={12} lg={9}>
          <Box mb={2}>
            <Breadcrumbs
              separator={
                <NavigateNextIcon fontSize="small" style={{ color: themeColors.greyText }} />
              }
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Box>
          <Box mb={3}>
            <Typography className={classes.pillarTitle} fontSize={40} fontWeight={400} style={{ color: '#D2D2D2' }}>
              {pillarData?.title}
            </Typography>
          </Box>

          <Box mb={7} sx={{ color: themeColors.greyText }} className={classes.descriptionBox}>
            {parse(pillarData?.synopsis ? pillarData.synopsis : '')}
          </Box>
          {pillarData?.sessions?.filter((k)=>k.published).length?
           (
            <Box sx={{ background: themeColors.bg3, padding: '15px',borderRadius:'3px' }} mb={3}>
              <SessionListingBig
                itemData={data}
                searchKeyword={searchKeyword}
                title={
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <StyledYellowHeader mb={3}>The Sessions</StyledYellowHeader>
                    <StyledSearchIcon visibility={iconHide} onClick={InputField} />
                    <Box display={inputHide}>
                      <TextField
                        name="search"
                        variant="outlined"
                        fullWidth
                        label="Search"
                        className={classes.Search}
                        onKeyUp={handleKeyupSearch}
                        display={inputHide}
                        onChange={(newValue) => {
                          setSearchData(newValue.target.value);
                        }}
                        value={searchKeyword}
                        InputProps={{
                          endAdornment: (
                            <React.Fragment>
                              {searchKeyword ? (
                                <IconButton onClick={onClose}>
                                  <CloseIcon style={{ color: 'white' }} />
                                </IconButton>
                              ) : null}
                            </React.Fragment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                }
                from={{
                  name: pillarData?.title,
                  id: pillarData?.id,
                  contentType: pillarData?.contentType,
                }}
              />
            </Box>
          ):null}
          
        </Grid>

        <Grid item md={12} lg={3}>
          {pillarData?.tags?.length ? (
            <Box className={classes.buttonSection}>
              <CoverButtonSection text="Pillar covers" tags={pillarData?.tags} />
            </Box>
          ) : null}
          {pillarData?.otherPillars?.filter((k)=>k.published).length ? (
            <Box className={classes.imageSection}>
              <TwoImageSideSection data={pillarData.otherPillars} text={'Explore other pillars'} />
            </Box>
          ) : null}

          {pillarData?.expert?.filter((k)=>k.published).length ? (
            <ExpertSection text={'Meet the Experts'} expertData={pillarData?.expert} />
          ) : null}
        </Grid>
      </Grid>
      {pillarData?.philosophyImageUrl ? (
        <PhilosophySection imageUrl={pillarData?.philosophyImageUrl} />
      ) : null}
    </Box>
  );
};
export default PillarDetailsSection;
