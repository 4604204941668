import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { Button, Spacer } from 'src/components/shared';
import { bounceActionList } from 'src/modules/tasks/config';

function BounceActions({ onClick = () => {} }) {
  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h3"></Typography>
      </Box>

      <Box mt={2} display="flex" justifyContent="flex-end" width="100%">
        {Object.keys(bounceActionList).map((key) => (
          <>
            <Tooltip title={bounceActionList[key].tip} key={key}>
              <div>
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  startIcon={bounceActionList[key].icon}
                  key={key}
                  onClick={() => onClick(bounceActionList[key].value)}
                >
                  {bounceActionList[key].label}
                </Button>
              </div>
            </Tooltip>
            <Spacer x={1} y={1} />
          </>
        ))}
      </Box>
    </Box>
  );
}

export default BounceActions;
