import * as types from '../actions/appTypes';
import { RESET_STORE } from 'src/modules/app/actions/appTypes';
import { uniqBy } from 'lodash';

const initialState = {
  loader: {
    dashboards: false,
    panels: false,
    contacts: false,
  },
  activeDashboard: '',
  dashboards: {},
  panels: {},
  panelSearch: '',
  contacts: {
    paging: {
      pageNo: 0,
      perPage: 20,
      count: 0,
    },
    filters: [],
    data: {},
  },
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.POST_BOARD:
      return {
        ...state,
        panels: {},
      };

    case types.FETCH_BOARDS:
      return {
        ...state,
        panels: {},
      };

    case types.SEARCH_PANEL_CONTACTS:
      return {
        ...state,
        panelSearch: action.search,
      };

    case types.SET_PANEL_CONTACTS:
      return {
        ...state,
        panels: {
          ...state.panels,
          [action.panelId]: {
            ...state.panels[action.panelId],
            contacts: action.data,
            loading: false,
          },
        },
      };

    case types.FETCH_DASHBOARD_CONTACTS:
      return {
        ...state,
        loader: {
          ...state.loader,
          contacts: true,
        },
        contacts: {
          ...state.contacts,
          ...(action.paging.pageNo === 0 ? { data: {} } : {}),
          paging: {
            ...state.contacts.paging,
            ...action.paging,
          },
          filters: action.filters,
          // filters: uniqBy([...state.contacts.filters, ...action.filters], (obj) => obj.column),
        },
      };

    case types.SET_DASHBOARD_CONTACTS:
      return {
        ...state,
        loader: {
          ...state.loader,
          contacts: false,
        },
        contacts: {
          ...state.contacts,
          data: {
            ...state.contacts.data,
            ...action.contacts,
          },
          paging: {
            ...state.contacts.paging,
            count: action.total.value,
          },
        },
      };

    case types.SET_ACTIVE_DASHBOARD:
      return {
        ...state,
        activeDashboard: action.dashboardId,
      };

    case types.FETCH_PANELS:
      return {
        ...state,
        loader: {
          ...state.loader,
          panels: true,
        },
        panels: {},
      };

    case types.SET_PANELS:
      return {
        ...state,
        loader: {
          ...state.loader,
          panels: false,
        },
        panels: action.data,
      };

    case types.FETCH_DASHBOARDS:
      return {
        ...state,
        loader: {
          ...state.loader,
          dashboards: true,
        },
        dashboards: {},
      };

    case types.SET_DASHBOARDS:
      return {
        ...state,
        loader: {
          ...state.loader,
          dashboards: false,
        },
        dashboards: action.data,
        // activeDashboard: Object.keys(action.data)[0],
      };

    case RESET_STORE:
      return initialState;

    default:
      return state;
  }
};
export default AppReducer;
