import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Grid, Box } from '@mui/material';
import { Button, Form } from 'src/components/shared';
import Typography from '@mui/material/Typography';
import { getCandidatesLookup, getAssignToLookup } from 'src/modules/users/api/usersApi';

function ChangePassword({ drawerToggle, editUser, status, isAdmin, formData, ...props }) {
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [defaultCandidate, setDefaultCandidate] = useState({});

  const authPermissionList = useMemo(() => {
    if (isAdmin)
      return [
        { id: 1, value: 'Admin' },
        { id: 2, value: 'Manager' },
        { id: 3, value: 'Standard' },
      ];
    else
      return [
        { id: 2, value: 'Manager' },
        { id: 3, value: 'Standard' },
      ];
  }, [isAdmin]);

  const allPermissionList = useMemo(() => {
    return [
      { id: 1, value: 'Admin' },
      { id: 2, value: 'Manager' },
      { id: 3, value: 'Standard' },
    ];
  }, []);

  useEffect(() => {
    if (editUser && editUser?.id) {
      setDefaultCandidate({
        id: editUser.id,
        value: editUser.username,
      });
      setCurrentUser(editUser);
    }
  }, [editUser]);

  const searchCandidates = async (search, lookupType) => {
    let res = [];
    if (lookupType === 'contact') {
      res = await getCandidatesLookup(search, []);
    } else if (lookupType === 'assignedTo') {
      res = await getAssignToLookup(search, 'id');
    } else if (lookupType === 'title') {
      res = await getAssignToLookup(search, 'id');
    }
    return res || [];
  };

  const handleSubmit = async (values, form) => {
    const data = { ...values };
    data.acl = { accessLevel: data.accessLevel.value };
    data.isAdmin = data.accessLevel.value === 'Admin' ? true : false;
    delete data.accessLevel;
    delete data.permissionLevel;
    if (editUser && editUser?.id) {
      const updatedRow = { id: editUser?.id, data: data };
      await props.putUser(updatedRow);
    }
  };

  return (
    <Form
      initialValues={{
        accessLevel: { id: currentUser?.id, value: currentUser.acl?.accessLevel } || '',
        // permissionLevel: { id: currentUser?.id, value: currentUser.acl?.permissionLevel } || '',
      }}
      validationSchema={Yup.object().shape({
        accessLevel: Yup.object()
          .required('Please select accessLevel')
          .test(
            'assignedTo-check',
            'Please choose a Assigned accessLevel to continue.',
            async (value, { createError }) => {
              if (value && value.id && value.id !== '') {
                return true;
              }
              return false;
            },
          ),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={false}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <Box display="flex" flexDirection="column" height="100%">
              <Box>
                <Typography variant="h4" pt={2} gutterBottom>
                  Security
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Form.Field.AutoComplete
                      multiple={false}
                      fullWidth
                      disableClearable
                      clearOnEscape
                      options={isAdmin || !status ? allPermissionList : authPermissionList}
                      variant="outlined"
                      name="accessLevel"
                      label="Access level"
                      optLabel="value"
                      optValue="id"
                      allowAdd={true}
                      // disabled={isAdmin ? false : true}
                    />
                  </Grid>
                </Grid>

                {/* <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Form.Field.Checkbox
                      fullWidth
                      variant="inline"
                      name="isAdmin"
                      label="Assigned Admin Role"
                    />
                  </Grid>
                </Grid> */}
              </Box>

              <Box
                mt={2}
                sx={{
                  textAlign: 'right',
                }}
              >
                <Button variant="contained" color="secondary" type="submit" loading={loading}>
                  Update
                </Button>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}

export default ChangePassword;
