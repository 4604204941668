import React from 'react';
import Session from 'src/components/lms/Session';
import Course from 'src/components/lms/Course';
import Slider from 'src/components/lms/Slider';
import useMediaQuery from 'src/components/hooks/useMediaQuery';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useLmsHomeProvider } from '../../utils/lmsStore';

import { LMSCard } from 'src/components/lms';
import { Button, Spacer } from 'src/components/shared';
import { ReactComponent as Assigned } from 'src/assets/lms/icons/assigned.svg';

function HomeAssigned({ title }) {
  const { isMobile } = useMediaQuery();

  const { assignedCourses, assignedSessions } = useLmsHomeProvider();

  const sliderProps = {
    slidesToShow: 3,
    ...(isMobile && {
      centerMode: true,
      centerPadding: '30px',
      arrows: true,
      dots: true,
      fade: true,
    }),
    padding: 16,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (!assignedCourses.length && !assignedSessions.length) return null;

  return (
    <LMSCard
      icon={<Assigned style={{ width: 24, height: 24 }} />}
      title={title}
      action={
        <Button size="small" color="secondary">
          View all
        </Button>
      }
    >
      {assignedCourses.length > 0 && (
        <>
          <Typography color="textSecondary" variant="body2">
            Courses
          </Typography>
          <Spacer x={2} y={2} />
          <Slider
            {...{
              ...sliderProps,
              slidesToShow: 3,
            }}
          >
            {assignedCourses.map((course, index) => (
              <Course key={`home-assigned-${index}`} course={course} />
            ))}
          </Slider>

          <Spacer x={2} y={2} />
          <Divider />
          <Spacer x={2} y={2} />
        </>
      )}

      {assignedSessions.length > 0 && (
        <>
          <Typography color="textSecondary" variant="body2">
            Sessions
          </Typography>
          <Spacer x={2} y={2} />

          <Slider
            {...{
              ...sliderProps,
              slidesToShow: 4,
            }}
          >
            {assignedSessions.map((session) => (
              <Session key={`assigned-session-${session.id}`} session={session} />
            ))}
          </Slider>
        </>
      )}
    </LMSCard>
  );
}

export default HomeAssigned;
