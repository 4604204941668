import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Button, Spacer } from 'src/components/shared';
import { Typography, Box, FormHelperText, Divider } from '@mui/material';
import { checkValueExists, specialCharacterRegex } from 'src/config';

function LookupForm({ lookup, postCentralLookup, putCentralLookup, onClose, lookups }) {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Form
        initialValues={{
          name: lookup?.name || '',
          options: '',
          pluralName: '',
          singularName: '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required('Please enter title.')
            .test('label', 'This field cannot contain special character', specialCharacterRegex)
            .test(
              'unique-field-name',
              'Field label already exists.',
              (value) => !!lookup?.id || !checkValueExists(value, lookups, 'name'),
            ),
          ...(!lookup?.id && {
            options: Yup.string().test({
              message: () => 'Please enter options.',
              test: (value, values) => {
                // if (!value || value == '') {
                //   return values.createError({
                //     message: 'Please enter options.',
                //     path: 'options',
                //   });
                // }
                try {
                  if (!value || typeof value === 'undefined') {
                    return true;
                  }
                  const hasNewLines = (value.match(/\n/g) || []).length > 0;
                  const hasCommas = value.includes(',');
                  if (hasNewLines && hasCommas) {
                    return values.createError({
                      message:
                        'Please save your values either comma OR line separated. Not combined.',
                      path: 'options',
                    });
                  }
                  return true;
                } catch (error) {}
              },
            }),
          }),
        })}
        onSubmit={async (values, form) => {
          setLoading(true);
          const data = {
            ...values,
            pluralName: values.name,
            singularName: values.name,
          };
          if (lookup?.id) {
            delete data.options;
            await putCentralLookup(lookup?.id, data);
          } else {
            await postCentralLookup(values);
          }
          setLoading(false);
          onClose();
          form.prevent;
        }}
        enableReinitialize={true}
      >
        {(props) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                props.submitForm();
                return false;
              }}
              noValidate
            >
              <Form.Field.Input
                fullWidth
                variant="outlined"
                name="name"
                placeholder="Title"
                disabled={loading}
              />

              {/* <Form.Field.Input
                fullWidth
                variant="outlined"
                name="name"
                placeholder="Title Singular"
                disabled={loading}
              /> */}
              {!lookup?.id && (
                <>
                  <Spacer x={1} y={1} />
                  <Divider />
                  <Spacer x={1} y={1} />
                  <FormHelperText>Add values, one per line or separated by comma</FormHelperText>
                  <Form.Field.Input
                    fullWidth
                    variant="outlined"
                    name="options"
                    disabled={loading}
                    multiline={true}
                    maxRows={4}
                    minRows={4}
                  />
                </>
              )}

              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={loading}
                  size="large"
                >
                  {lookup?.id ? 'Update' : `Add Central Lookup`}
                </Button>
              </Box>
            </form>
          );
        }}
      </Form>
    </>
  );
}

export default LookupForm;
