import React, { useEffect, useMemo } from 'react';
import { AppGrid } from 'src/components/App';
import { useColumns } from './useColumns';

const ReportGrid = ({ tenantId, loading, resellersTenantReport, resellerFilters, ...props }) => {
  const { data, paging, filters } = resellersTenantReport;

  useEffect(() => {
    fetchData(filters, paging);
  }, [resellerFilters]);

  const columns = useColumns(data, 'tenant', tenantId);

  const fetchData = (filters, paging) => {
    props.fetchResellerTenantReport(tenantId, filters, paging, resellerFilters);
  };

  const handleTableChange = ({ pageNo, perPage }) => {
    fetchData(filters, {
      pageNo,
      perPage,
    });
  };

  return (
    <>
      <AppGrid
        selectableRows="none"
        columns={columns}
        data={data}
        onTableChange={handleTableChange}
        loading={loading.resellersTenantReport}
        options={{
          pagination: true,
          serverSide: true,
          page: paging.pageNo,
          rowsPerPage: paging.perPage,
          count: paging.count,
        }}
      />
    </>
  );
};

ReportGrid.propTypes = {};

export default ReportGrid;
