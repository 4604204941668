import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Grid, Container } from '@mui/material';
import { Button } from 'src/components/shared';
import ReportCard from '../../components/Dashboard/ReportCard';
import TenantReportGrid from '../../components/Dashboard/TenantReportGrid';

import { fetchTenantConfig } from 'src/modules/app/api/appApis';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FilterBar } from 'src/components/App';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import moment from 'moment';

const ResellersDashboard = ({
  tenantId,
  resellersTenantReportSummary,
  resellersTenantReport,
  resellerFilters,
  setResellerFilters,
  loading,
  ...props
}) => {
  const [tenant, setTenant] = useState({});
  const history = useHistory();

  useEffect(() => {
    fetchSummary();
  }, [resellerFilters]);

  useEffect(() => {
    fetchTenant(tenantId);
  }, [tenantId]);

  const fetchTenant = async (tenantId) => {
    try {
      const tenant = await fetchTenantConfig(tenantId);
      setTenant(tenant?.tenant);
    } catch (error) {
      setTenant({});
    }
  };

  const fetchSummary = () => {
    props.fetchResellerTenantReportSummary(tenantId, resellerFilters);
  };

  const isFilterApplied = Object.keys(resellerFilters?.createdAtRange || {})?.length;

  return (
    <Container maxWidth="xl">
      <Box mt={2}>
        <Box mb={2} flexDirection="row" display="flex" alignItems="center">
          <FilterBar
            filters={[{ key: 'createdAtRange', title: 'Filter By Date', type: 'dateRange' }]}
            appliedFilters={resellerFilters}
            onChange={(key, value) => setResellerFilters({ [key]: value })}
          />
          {!!isFilterApplied && (
            <Button
              variant="text"
              disableElevation={true}
              onClick={() => setResellerFilters({})}
              startIcon={<ClearAllIcon />}
              sx={{
                height: 'fit-content',
                color: 'white',
                textTransform: 'none',
                fontWeight: 400,
                backgroundColor: '#1976D2',
                padding: '6px 8px',
                marginLeft: '12px',
                '&:hover': {
                  background: '#1976D2',
                },
              }}
            >
              <Typography noWrap sx={{ maxWidth: '144px' }} variant="body2">
                Clear Filter
              </Typography>
            </Button>
          )}
        </Box>
        <Box display="flex" alignItems="center" mb={1}>
          <Button
            iconButton={true}
            onClick={() => {
              history.replace('/admin/resellers/dashboard');
            }}
            color="secondary"
          >
            <ArrowBackIcon color="secondary" />
          </Button>
          <Typography>{tenant?.name}</Typography>
        </Box>
        <Grid container spacing={2}>
          <ReportCard
            type="text"
            fill="rgba(255, 171, 0, 1)"
            title="Referrals Sequenced"
            value={resellersTenantReportSummary?.noOfContactsSequenced || 0}
          />
          <ReportCard
            type="text"
            fill="rgba(197, 17, 98, 1)"
            title="Number of Sequences"
            value={resellersTenantReportSummary?.noOfSequences || 0}
          />
        </Grid>
        <TenantReportGrid
          loading={loading}
          tenantId={tenantId}
          resellerFilters={resellerFilters}
          fetchResellerTenantReport={props.fetchResellerTenantReport}
          resellersTenantReport={resellersTenantReport}
        />
      </Box>
    </Container>
  );
};

export default ResellersDashboard;
