import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Button from 'src/components/shared/Button';
import ReadMore from 'src/components/lms/ReadMore';
import parse from 'html-react-parser';
import moment from 'moment';
import { ReactComponent as Award } from 'src/assets/lms/icons/award.svg';
import { ReactComponent as Roleplays } from 'src/assets/lms/icons/roleplays.svg';
import { ReactComponent as Score } from 'src/assets/lms/icons/score.svg';

import darkTheme from 'src/config/darkTheme';
import { useHistory } from 'react-router-dom';
import { nth } from 'src/utils/helper';
import useMediaQuery from 'src/components/hooks/useMediaQuery';

function CompetitionContent({ data, position }) {
  const history = useHistory();
  const session = data?.session || {};
  const { isMobile } = useMediaQuery();

  const renderScore = (icon, title) => (
    <Stack direction="row" gap={1} alignItems="center">
      {icon}
      <Typography variant="body2" color="secondary">
        {title}
      </Typography>
    </Stack>
  );

  const handleClick = () => {
    history.push(`/lms/session/${session.id}`);
  };

  const boardPosition = position?.position ? `${nth(position?.position)} place` : '';
  return (
    <Stack direction="column" justifyContent="space-between" sx={{ height: '100%' }}>
      <Stack direction="column">
        <Stack direction="row" mb={1.5} justifyContent="space-between">
          <Typography variant="h2">{data.name}</Typography>

          <Typography variant="caption" color="secondary" sx={{ textTransform: 'uppercase' }}>
            {moment(data.endDate).fromNow(true)} left
          </Typography>
        </Stack>

        <Typography component="div" variant="body1" color="textSecondary">
          <ReadMore text={data?.session?.overviewText} maxChars={200} isHtml={true} />
        </Typography>
      </Stack>

      <Grid container sx={{ alignItems: 'center' }} mt={2} spacing={2}>
        <Grid item xs={12} sm={4}>
          <Button variant="contained" color="secondary" fullWidth onClick={handleClick}>
            Go to Session
          </Button>
        </Grid>

        <Grid
          item
          xs={12}
          sm={8}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2,
            '& .MuiTypography-root': {
              fontSize: isMobile ? '12px' : '14px',
            },
          }}
          order={{ xs: 1, sm: 2 }}
        >
          {boardPosition !== '' &&
            renderScore(
              <Award style={{ color: darkTheme.palette.secondary.main }} />,
              boardPosition,
            )}
          {renderScore(
            <Score
              style={{ color: darkTheme.palette.secondary.main, width: '24px', height: '24px' }}
            />,
            `Score : ${data.averageScore ?? 0}`,
          )}
          {renderScore(
            <Roleplays
              style={{ color: darkTheme.palette.secondary.main, width: '22px', height: '22px' }}
            />,
            `${data?.roleplaysCompleted || 0} of ${data?.roleplays || 0} Role-play`,
          )}
        </Grid>
      </Grid>
    </Stack>
  );
}

export default CompetitionContent;
