import React from 'react';
import { Container, Typography, Card, CardContent } from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import { Spacer } from 'src/components/shared';
import { FilterBar } from 'src/components/App';

import EmailsChart from './EmailsChart';
import SequencesChart from './SequencesChart';
import TasksChart from './TasksChart';
import ContactsReport from './ContactsReport';
import Filter from 'src/components/App/AppGrid/Filter';

function ActivityReportSummary({ reportsSummary, ...props }) {
  const { sequence, task, contact, email, filter } = reportsSummary;

  const reportsFilter = [
    {
      key: 'createdAtRange',
      title: 'Filter By Date',
      type: 'dateRange',
      isPrimary: true,
    },
  ];

  const onFilterChange = (key, value) => {
    props.fetchReportSummary({ ...filter, [key]: value });
  };

  const onFilterClear = () => {};

  return (
    <Container maxWidth="lg">
      {/* <Spacer x={1} y={1} /> */}
      <FilterBar filters={reportsFilter} appliedFilters={filter} onChange={onFilterChange} />
      <Spacer x={1} y={1} />

      <Masonry columns={2} spacing={2}>
        <Card>
          <CardContent sx={{ minHeight: 300 }}>
            <Typography color="textSecondary">Emails</Typography>
            <EmailsChart email={email} />
          </CardContent>
        </Card>
        <Card>
          <CardContent sx={{ minHeight: 120 }}>
            <Typography color="textSecondary">Contacts</Typography>
            <ContactsReport contact={contact} />
          </CardContent>
        </Card>
        <Card>
          <CardContent sx={{ minHeight: 300 }}>
            <Typography color="textSecondary">Tasks</Typography>
            <TasksChart task={task} />
          </CardContent>
        </Card>
        <Card>
          <CardContent sx={{ minHeight: 240 }}>
            <Typography color="textSecondary">Sequences</Typography>
            <SequencesChart sequence={sequence} />
          </CardContent>
        </Card>
      </Masonry>
    </Container>
  );
}

export default ActivityReportSummary;
