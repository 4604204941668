import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Button, Spacer } from 'src/components/shared';
import { isEmpty } from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import { Drawer } from 'src/components/shared';
import { fetchAllOffices } from '../../api/adminApi';
import { useStyles } from '../../containers/integrations/styles';
import OfficesGrid from '../../components/Offices/OfficesGrid';
import OfficeForm from '../../components/Offices/OfficeForm';

function OfficesView({ offices, total, ...props }) {
  const classes = useStyles();
  const [editOptions, setEditOptions] = useState({});
  const [drawerOpen, setDrawerOpen] = useState({ edit: null, open: false });

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Drawer
          title={drawerOpen.edit ? 'Edit Office' : 'Create Office'}
          open={drawerOpen.open}
          onClose={() => {
            setDrawerOpen({ edit: null, open: false });
          }}
        >
          <OfficeForm
            editUser={drawerOpen.edit}
            total={total}
            drawerToggle={setDrawerOpen}
            loading={props.loading}
            {...props}
          />
        </Drawer>
      </Box>

      <OfficesGrid
        drawerToggle={setDrawerOpen}
        offices={offices}
        officeBulkUpdate={props.officeBulkUpdate}
        globals={props.globals}
        {...props}
      />
    </>
  );
}

export default OfficesView;
