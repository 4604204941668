import React from 'react';
import { Box } from '@mui/material';
import { Form } from 'src/components/shared';

function LinkedIn({ event }) {
  return (
    <Box sx={{ marginTop: '-8px' }}>
      <Form.Field.TextEditor
        name={`${event.id}.description`}
        showFontStyle={false}
        showFontEditor={false}
        showAlignProps={false}
        showListProps={false}
        showAttachments={false}
        toolbarLocation="bottom"
      />
    </Box>
  );
}

export default LinkedIn;
