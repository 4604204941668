import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Box, ButtonBase } from '@mui/material';
import { Button, Spacer } from 'src/components/shared';
import { taskErrorTypes } from '../../config';

import { getTaskTypeText, getTaskTypeIcon } from '../../utils/tasksUtils';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { capitalizeFirstLetter, parseUserDisplayName } from 'src/utils/helper';
import moment from 'moment';
import { dateFormat } from 'src/config';
const { INBOX_DISCONNECTED } = taskErrorTypes;

export function useEnrolmentColumns(enrolments, props) {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setColumns([
      {
        label: 'Sequence',
        name: 'sequence',
        options: {
          filter: false,
          sort: false,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 120 },
          }),
          setCellProps: () => ({
            style: { minWidth: 120 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = enrolments[dataIndex];
            if (!rData) return null;
            return (
              <ButtonBase
                onClick={() => {
                  window.open(`/sequence/${rData?.seq.id}`, '_blank');
                }}
              >
                <Typography variant="body2" color="secondary">
                  {rData?.seq?.name || ''}
                </Typography>
              </ButtonBase>
            );
          },
        },
      },
      {
        label: 'Contact name',
        name: 'contact',
        options: {
          filter: true,
          sort: false,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 150 },
          }),
          setCellProps: () => ({
            style: { minWidth: 150 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = enrolments[dataIndex];
            if (!rData) return null;
            if (rData.contactObj) {
              return (
                <ButtonBase
                  onClick={() => {
                    props.toggleContactDrawer(rData.contactObj?.id);
                  }}
                >
                  <Typography color="secondary" variant="body2">
                    {parseUserDisplayName(
                      `${rData.contactObj.fname} ${rData.contactObj.lname}` || '',
                    )}
                  </Typography>
                </ButtonBase>
              );
            }
            return '-';
          },
        },
      },
      {
        label: 'Contact details',
        name: 'contactDetails',
        options: {
          filter: true,
          sort: false,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 150 },
          }),
          setCellProps: () => ({
            style: { minWidth: 150 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = enrolments[dataIndex];
            if (!rData) return null;
            if (rData.contactObj) {
              return (
                <Typography
                  color={
                    (rData?.contactDetails || '')?.toLowerCase() === 'no contact details missing'
                      ? '#4fd386'
                      : '#dd6c72'
                  }
                  variant="body2"
                >
                  {rData.contactDetails}
                </Typography>
              );
            }
            return '-';
          },
        },
      },
      {
        label: 'Status',
        name: 'status',
        options: {
          filter: true,
          sort: false,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { width: 100 },
          }),
          setCellProps: () => ({
            style: { width: 100 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = enrolments[dataIndex];
            if (!rData) return null;
            if (rData.contactObj) {
              return (
                <Typography color="textSecondary" variant="body2">
                  {capitalizeFirstLetter(rData.status)}
                </Typography>
              );
            }
            return '-';
          },
        },
      },
      {
        label: 'Date',
        name: 'date',
        options: {
          filter: true,
          sort: false,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { width: 100 },
          }),
          setCellProps: () => ({
            style: { width: 100 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = enrolments[dataIndex];
            if (!rData) return null;
            if (rData.contactObj) {
              return (
                <Typography color="textSecondary" variant="body2">
                  {moment(rData.createdAt).format(dateFormat)}
                </Typography>
              );
            }
            return '-';
          },
        },
      },
      {
        label: ' ',
        name: 'view',
        options: {
          filter: false,
          sort: false,
          draggable: true,
          customBodyRenderLite: (dataIndex) => {
            // const { type } = enrolments[dataIndex];
            const rData = enrolments[dataIndex];
            if (!rData) return null;
            const type = '';
            if (type === INBOX_DISCONNECTED) return null; // no actions for this type
            return null;
          },
        },
      },
    ]);
  }, [enrolments]);

  return columns;
}
