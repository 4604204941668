import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import { getContactStatusColor } from 'src/utils/helper';

export const StatusChip = styled.div`
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding: 6px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  flex: none;
  order: 0;
  align-self: stretch;
`;

export const ContactStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  height: 30px;
  width: 120px;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  background-color: ${(props) => getContactStatusColor(props.status).bgColor};
  color: ${(props) => getContactStatusColor(props.status).color};
`;

export const useStyles = makeStyles(({ custom }) => ({
  chip: {
    borderRadius: 4,
    '&.Inactive': {
      background: '#EF5350',
      color: '#ffffff',
    },
    '&.Active': {
      background: '#66BB6A',
      color: '#ffffff',
    },
    '&.Enrollmenterror, &.Hold/Stop, &.Systemhold': {
      backgroundColor: '#EF5350',
      color: '#ffffff',
    },
    '&.Notinanysequence': {
      backgroundColor: '#2196F3',
      color: '#ffffff',
    },
    '&.Spokentoasreasonssequencehold': {
      backgroundColor: '#CDDC39',
      color: '#ffffff',
    },
    '&.In-Progress': {},
  },
}));
