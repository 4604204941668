import React, { useState, useEffect } from 'react';
import { Chip, Typography, Box } from '@mui/material';
import { Form, Menu } from 'src/components/shared';
import { capitalizeFirstLetter } from 'src/utils/helper';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import { disabledFields } from '../../config';
import { capitalize } from 'lodash';

export function useColumns(data, onCheckClick, onDelete, onEdit) {
  const [columns, setColumns] = useState([]);
  const [waiting, setWaiting] = useState(false);

  const handleCheck = async (field, name, val) => {
    try {
      setWaiting(true);
      await onCheckClick(field, name, val);
      setWaiting(false);
    } catch (error) {}
  };

  useEffect(() => {
    if (!data || !data.length) {
      setColumns([]);
    } else {
      setColumns([
        {
          label: 'Active',
          name: 'status',
          options: {
            filter: false,
            sort: false,
            draggable: false,
            setCellHeaderProps: (value) => ({
              style: { maxWidth: '16px', minWidth: '16px' },
            }),
            setCellProps: () => ({
              style: { maxWidth: '16px', minWidth: '16px' },
            }),
            customBodyRenderLite: (dataIndex) => {
              const rData = data[dataIndex];
              if (!rData?.id) return null;
              const isActive = rData.status === 'active' ? true : false;
              const isDisabled = disabledFields.includes(rData.fieldName);

              return (
                <>
                  <Form
                    initialValues={{
                      active: isActive,
                    }}
                    enableReinitialize={true}
                  >
                    <Box display="flex" justifyContent="center">
                      <Form.Field.Checkbox
                        name="active"
                        color="success"
                        label=" "
                        onChange={(val) => {
                          handleCheck(rData, 'status', val ? 'active' : 'inactive');
                        }}
                        sx={{ color: 'rgba(46, 125, 50, 1)' }}
                        disabled={waiting || isDisabled}
                      />
                    </Box>
                  </Form>
                </>
              );
            },
          },
        },
        {
          label: 'Field name',
          name: 'label',
          options: {
            filter: false,
            sort: true,
            draggable: false,
            setCellHeaderProps: (value) => ({
              style: { minWidth: 90 },
            }),
            setCellProps: () => ({
              style: { minWidth: 90, cursor: 'pointer' },
            }),
            customBodyRenderLite: (dataIndex) => {
              const rData = data[dataIndex];
              if (!rData?.id) return null;
              return (
                <Typography onClick={() => onEdit(rData)} variant="body2" color="secondary">
                  {`${capitalize(rData?.label)}`}
                </Typography>
              );
            },
          },
        },
        // {
        //   label: 'Type',
        //   name: 'type',
        //   options: {
        //     filter: true,
        //     sort: true,
        //     draggable: true,
        //     setCellHeaderProps: (value) => ({
        //       style: { minWidth: 10 },
        //     }),
        //     setCellProps: () => ({
        //       style: { minWidth: 10 },
        //     }),
        //     customBodyRenderLite: (dataIndex) => {
        //       const rData = data[dataIndex];
        //       if (!rData?.type || rData?.type == '') {
        //         return '-';
        //       }
        //       return <Chip label={capitalizeFirstLetter(rData?.type)} size="small" />;
        //     },
        //   },
        // },
        {
          label: 'Field type',
          name: 'fieldType',
          options: {
            filter: false,
            sort: false,
            draggable: false,
            setCellHeaderProps: (value) => ({
              style: { maxWidth: '30px' },
            }),
            setCellProps: () => ({
              style: { maxWidth: '30px' },
            }),
            customBodyRenderLite: (dataIndex) => {
              const rData = data[dataIndex];
              if (!rData?.id) return null;
              if (!rData?.type || rData?.type == '') {
                return '-';
              }
              const type = rData.type === 'default' ? 'System' : 'Custom';
              return (
                <Chip
                  label={type}
                  color={type === 'System' ? 'default' : 'secondary'}
                  size="small"
                />
              );
            },
          },
        },
        {
          label: 'Add view',
          name: 'showOnForm',
          options: {
            sort: false,
            setCellHeaderProps: (value) => ({
              style: { maxWidth: '24px' },
            }),
            setCellProps: () => ({
              style: { maxWidth: '24px' },
            }),
            align: 'center',
            customBodyRenderLite: (dataIndex) => {
              const rData = data[dataIndex];
              if (!rData?.id) return null;
              const showOnForm = rData?.fieldOptions?.showOnForm ? true : false;
              const disabled = rData?.type === 'default' ? true : false;
              const isActive = rData.status === 'active' ? true : false;
              if (showOnForm) return <CheckIcon sx={{ fill: 'rgba(0,0,0,0.87)' }} />;
              return null;
              // return (
              //   <>
              //     <Form
              //       initialValues={{
              //         showOnForm: showOnForm,
              //       }}
              //       enableReinitialize={true}
              //     >
              //       {({ values }) => {
              //         return (
              //           <Box display="flex">
              //             <Form.Field.Checkbox
              //               name={'showOnForm'}
              //               label=" "
              //               onChange={(val) => {
              //                 handleCheck(rData, 'showOnForm', val);
              //               }}
              //               color="secondary"
              //               disabled={disabled || waiting || !isActive}
              //             />
              //           </Box>
              //         );
              //       }}
              //     </Form>
              //   </>
              // );
            },
          },
        },

        {
          label: 'Profile view',
          name: 'showOnView',
          options: {
            sort: false,
            setCellHeaderProps: (value) => ({
              style: { maxWidth: '24px' },
            }),
            setCellProps: () => ({
              style: { maxWidth: '24px' },
            }),
            customBodyRenderLite: (dataIndex) => {
              const rData = data[dataIndex];
              if (!rData?.id) return null;
              const showOnView = rData?.fieldOptions?.showOnView ? true : false;
              const isActive = rData.status === 'active' ? true : false;
              if (showOnView) return <CheckIcon sx={{ fill: 'rgba(0,0,0,0.87)' }} />;
              return null;
              // return (
              //   <Form
              //     initialValues={{
              //       showOnView: showOnView,
              //     }}
              //     enableReinitialize={true}
              //   >
              //     {({ values }) => {
              //       return (
              //         <Box display="flex">
              //           <Form.Field.Checkbox
              //             name={'showOnView'}
              //             label=" "
              //             onChange={(val) => {
              //               handleCheck(rData, 'showOnView', val);
              //             }}
              //             color="secondary"
              //             disabled={waiting || !isActive}
              //           />
              //         </Box>
              //       );
              //     }}
              //   </Form>
              // );
            },
          },
        },
        {
          label: 'Field rules',
          name: 'required',
          options: {
            filter: false,
            sort: false,
            draggable: false,
            setCellHeaderProps: (value) => ({
              style: { maxWidth: '32px' },
            }),
            setCellProps: () => ({
              style: { maxWidth: '32px' },
            }),
            customBodyRenderLite: (dataIndex) => {
              const rData = data[dataIndex];
              if (!rData?.id) return null;
              const required = rData?.fieldOptions?.required ? true : false;
              const isActive = rData.status === 'active' ? true : false;
              if (required)
                return (
                  <Typography variant="body2" color="textSecondary">
                    Mandatory
                  </Typography>
                );
              return (
                <Typography variant="body2" color="textSecondary">
                  None
                </Typography>
              );
            },
          },
        },
        {
          name: 'id',
          label: ' ',
          options: {
            filter: false,
            sort: false,
            draggable: false,
            allowToggle: false,
            viewColumns: false,
            setCellHeaderProps: (value) => ({
              style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
            }),
            setCellProps: () => ({
              style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
            }),
            customBodyRenderLite: (index) => {
              const field = data[index];
              if (!field?.id) return null;

              if (disabledFields.includes(field.fieldName)) return null;

              return (
                <Menu
                  options={[
                    {
                      label: 'Edit Field',
                      icon: <EditIcon />,
                      onClick: () => {
                        onEdit(field);
                      },
                    },
                    ...(field.type !== 'default'
                      ? [
                          {
                            label: 'Delete Field',
                            icon: <DeleteIcon />,
                            onClick: () => {
                              onDelete(field);
                            },
                          },
                        ]
                      : []),
                  ]}
                />
              );
            },
          },
        },
      ]);
    }
  }, [data, waiting]);

  return columns;
}
