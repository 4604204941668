import React from 'react';
import Grid from '@mui/material/Grid';
import SessionSkeleton from 'src/components/lms/Session/Skeleton';

function SessionListSkeleton({ single }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} lg={3} md={6}>
        <SessionSkeleton />
      </Grid>
      <Grid item xs={6} lg={3} md={6}>
        <SessionSkeleton />
      </Grid>
      <Grid item xs={6} lg={3} md={6}>
        <SessionSkeleton />
      </Grid>
      <Grid item xs={6} lg={3} md={6}>
        <SessionSkeleton />
      </Grid>
      <Grid item xs={6} lg={3} md={6}>
        <SessionSkeleton />
      </Grid>
      <Grid item xs={6} lg={3} md={6}>
        <SessionSkeleton />
      </Grid>
      <Grid item xs={6} lg={3} md={6}>
        <SessionSkeleton />
      </Grid>
      <Grid item xs={6} lg={3} md={6}>
        <SessionSkeleton />
      </Grid>
    </Grid>
  );
}

export default SessionListSkeleton;
