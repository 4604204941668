import { StepConnector } from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';

export const ConnectorStyles = withStyles((theme) => {
  return {
    alternativeLabel: {
      top: 22,
    },
    active: {
      '& $line': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    completed: {
      '& $line': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    line: {
      height: 2,
      border: 0,
      backgroundColor: theme.palette.divider,
      borderRadius: 1,
      marginLeft: 10,
      marginRight: 10,
    },
  };
})(StepConnector);

export const IconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[300],
    zIndex: 1,
    color: '#fff',
    width: 24,
    height: 24,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
  },
  completed: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export const StepperStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    padding: 0,
    '& .MuiSvgIcon-root.Mui-active': { color: theme.palette.primary.main },
    '& .MuiSvgIcon-root.Mui-completed': { color: theme.palette.primary.main },
  },
}));
