import React, { useRef, useState } from 'react';
import { Button, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import Form from './Form';
import { Popover } from '../App';

const Options = [
  { label: 'Tomorrow morning', value: 'tomorrowMorning' },
  { label: 'Tomorrow afternoon', value: 'tomorrowAfternoon' },
  { label: 'Monday morning', value: 'mondayMorning' },
  { label: 'Custom date and time', value: 'custom' },
];

const tomorrowMorning = () => {
  return new Date(new Date(new Date().setHours(9, 0, 0, 0)).setDate(new Date().getDate() + 1));
};

const tomorrowAfternoon = () => {
  return new Date(new Date(new Date().setHours(14, 0, 0, 0)).setDate(new Date().getDate() + 1));
};

const nextUpcomingMonday = () =>
  new Date(new Date().setDate(new Date().getDate() + ((1 + 7 - new Date().getDay()) % 7))).setHours(
    0,
    0,
    0,
    0,
  ) === new Date().setHours(0, 0, 0, 0)
    ? new Date(
        new Date(
          new Date().setDate(new Date().getDate() + ((1 + 7 - new Date().getDay()) % 7) + 7),
        ).setHours(9, 0, 0, 0),
      )
    : new Date(
        new Date(
          new Date().setDate(new Date().getDate() + ((1 + 7 - new Date().getDay()) % 7)),
        ).setHours(9, 0, 0, 0),
      );

const ScheduledEmail = ({ onClose, onSubmit, children, emailTime = '', disablePortal = false }) => {
  const [scheduledEmailTime, setScheduledEmailTime] = useState(emailTime ? emailTime : '');
  const [scheduledOnDate, setScheduleOnDate] = useState(null);
  const [scheduledOnTime, setScheduleOnTime] = useState();

  const defaultValue = () => {
    if (emailTime) {
      const existingDate = new Date(emailTime);
      const date = existingDate?.getDate();
      const time = existingDate?.toTimeString();
      const tomorrow = tomorrowMorning()?.getDate();
      const morning = tomorrowMorning()?.toTimeString();
      const upComingMonday = nextUpcomingMonday()?.getDate();
      if (date === tomorrow) {
        if (time > morning) {
          return 'tomorrowAfternoon';
        } else {
          return 'tomorrowMorning';
        }
      } else if (date === upComingMonday && time === morning) {
        return 'mondayMorning';
      } else {
        setScheduleOnDate(existingDate.toLocaleDateString());
        setScheduleOnTime(time);
        setScheduledEmailTime('custom');
        return 'custom';
      }
    } else return '';
  };

  const popoverRef = useRef(null);

  return (
    <Popover disablePortal={disablePortal} className="custom-popover" trigger="click" width={'500px'} Child={children} ref={popoverRef}>
      <Stack>
        <Stack padding={'18px 26px'}>
          <Typography fontSize={'17px'} fontWeight={500}>
            Schedule email
          </Typography>
        </Stack>
        <div style={{ borderBottom: '1px solid #0000001F', width: '100%' }} />
        <Stack padding={'18px 26px'}>
          <RadioGroup
            aria-labelledby="select-time"
            name={'scheduledOn'}
            sx={{ '& .MuiFormControlLabel-label': { width: '100%' } }}
            defaultValue={defaultValue}
            // value={scheduledEmailTime}
            onChange={(_, val) => setScheduledEmailTime(val)}
          >
            {Options.map(({ label, value }) => (
              <FormControlLabel
                value={value}
                control={<Radio color="secondary" />}
                label={
                  <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography variant="body1">{label}</Typography>
                    {value !== 'custom' && (
                      <Typography variant="body1">
                        {value === 'tomorrowMorning'
                          ? `${tomorrowMorning()?.toString()?.split(' ')[2]} ${
                              tomorrowMorning()?.toString()?.split(' ')[1]
                            }, 9:00 AM`
                          : value === 'tomorrowAfternoon'
                          ? `${tomorrowAfternoon()?.toString()?.split(' ')[2]} ${
                              tomorrowAfternoon()?.toString()?.split(' ')[1]
                            }, 2:00 PM`
                          : value === 'mondayMorning'
                          ? `${nextUpcomingMonday()?.toString()?.split(' ')[2]} ${
                              nextUpcomingMonday()?.toString()?.split(' ')[1]
                            }, 9:00 AM`
                          : ''}
                      </Typography>
                    )}
                  </Stack>
                }
              />
            ))}
          </RadioGroup>

          <Stack direction={'flex'} justifyContent={'flex-end'} gap={'18px'}>
            <Form.Field.Datepicker
              fullWidth
              variant="inline"
              name="dueOn"
              value={scheduledOnDate}
              disabled={scheduledEmailTime !== 'custom'}
              label="Date"
              onChange={setScheduleOnDate}
              disablePast={true}
            />
            <Form.Field.Timepicker
              fullWidth
              variant="outlined"
              disabled={scheduledEmailTime !== 'custom'}
              value={scheduledOnTime}
              onChange={setScheduleOnTime}
              name="dueTime"
              label="Time"
            />
          </Stack>

          <Stack mt={2} direction={'flex'} justifyContent={'flex-end'} gap={'18px'}>
            <Button
              variant="text"
              color="secondary"
              sx={{ textTransform: 'initial' }}
              onClick={() => {
                popoverRef?.current?.close();
                setScheduleOnDate({ date: null });
                setScheduledEmailTime('');
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                popoverRef?.current?.close();
                onSubmit(
                  scheduledEmailTime === 'custom'
                    ? new Date(
                        new Date(scheduledOnDate).setHours(
                          scheduledOnTime?.split(':')?.[0],
                          scheduledOnTime?.split(':')?.[1],
                          0,
                        )
                      )
                    : scheduledEmailTime === 'tomorrowMorning'
                    ? tomorrowMorning()
                    : scheduledEmailTime === 'tomorrowAfternoon'
                    ? tomorrowAfternoon()
                    : nextUpcomingMonday(),
                );
              }}
              variant="contained"
              disabled={
                scheduledEmailTime === 'custom'
                  ? !scheduledOnTime || !scheduledOnDate
                  : !scheduledEmailTime
              }
              sx={{ textTransform: 'initial' }}
              color="secondary"
            >
              Schedule email
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Popover>
  );
};

export default ScheduledEmail;
