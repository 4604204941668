import React, { PureComponent } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Button } from 'src/components/shared';
import { Grid, Paper, Typography } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
};

class AlertDialogSlide extends PureComponent {
  state = {
    open: false,
    src: null,
    crop: {
      unit: '%',
      width: 30,
      aspect: 16 / 9,
    },
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => this.setState({ src: reader.result }));

      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.setState({ open: true });
    this.imageRef = image;  
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, 'newFile.jpeg');
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);

        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  render() {
    let fileInput = React.createRef();
    const { crop, croppedImageUrl, src } = this.state;

    return (
      <div>
        <Paper>
          <Button variant="outlined" color="primary" onClick={() => fileInput.current.click()}>
            Upload photo
          </Button>
          <Dialog
            open={this.state.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.TransitionComponenthandleClose}
          >
            {/* <Typography sx={{ padding: '8%', width: '60%' }}>{'Crop Images'}</Typography> */}
            <Grid sx={{ padding: '3%', width: '100%' }}>
              <input
                type="file"
                ref={fileInput}
                style={{ display: 'none' }}
                onChange={this.onSelectFile}
                multiple
              />

              {src && (
                <ReactCrop
                  src={src}
                  crop={crop}
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                />
              )}
              {croppedImageUrl && (
                <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
              )}
            </Grid>
            <DialogActions style={{ padding: '28x' }}>
              <Button onClick={this.handleClose} color="primary">
                close
              </Button>
              <Button onClick={() => fileInput.current.click()}>
                {src === null ? 'Upload photo' : 'Change photo'}
              </Button>
              {src !== null ? (
                <Button onClick={() => this.props.handleUpload(croppedImageUrl)}>Save Photo</Button>
              ) : null}
            </DialogActions>
          </Dialog>
        </Paper>
      </div>
    );
  }
}

export default AlertDialogSlide;
