import styled from 'styled-components';
import { Typography } from '@mui/material';
import { ButtonBase } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export const EditButton = ({ onClick }) => {
  return (
    <ButtonBase onClick={onClick} sx={{ position: 'absolute', right: '12px', top: '16px' }}>
      <EditIcon sx={{ color: '#00000086', fill: '#00000086', fontSize: '20px' }} />
    </ButtonBase>
  );
};

export const AddContactButton = ({ onClick }) => {
  return (
    <ButtonBase
      onClick={onClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '4px',
      }}
    >
      <AddCircleOutlineIcon sx={{ color: '#00000086', fill: '#00000086', fontSize: '20px' }} />
    </ButtonBase>
  );
};

export const FormWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  position: relative;
  border-radius: 4px;
  padding: 12px 10px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  /* margin: 0 5px; */
  min-height: calc(100vh - 174px);
  max-height: calc(100vh - 174px);
  width: 284px;
  min-width: 284px;
  /* border-right: 1px solid rgba(0, 0, 0, 0.12); */
  margin: 6px;
  /* background-color: #f5f5f5; */
  border-top: ${(props) => `5px solid ${props.color ? props.color : 'rgba(0, 0, 0, 0.10)'}`};
  border-radius: 4px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const DropList = styled.div`
  height: 100%;
  flex: 1;
  background-color: #f1f1f1;
  padding: 6px;
  overflow: auto;
`;

export const CardList = styled.div`
  height: 100%;
  padding: 0 5px;
`;

export const Title = styled(Typography).attrs({})`
  /* text-transform: uppercase; */
  text-align: center;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  flex: 1;
  color: ${(props) => `${props.color ? props.color : 'rgba(0,0,0,0.87)'}`};
`;

export const SubTitle = styled(Typography).attrs({
  color: 'textSecondary',
  variant: 'body2',
})`
  text-align: center;
  margin: 0;
`;

export const PanelHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  position: relative;
  border-radius: 4px;
  padding: 12px 10px;
`;

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const AvatarWrapper = styled.div`
  width: 48px;
`;

export const DetailsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;
