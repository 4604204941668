import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Chip, Typography, CardContent } from '@mui/material';
import { InfoIconWrapper, InfoIcon, Content, Title, Value } from './styles';
import { AppGrid } from 'src/components/App';
import { fetchWidgetData } from '../../../actions/boardActions';

import { capitalizeFirstLetter } from 'src/utils/helper';
import { dateFormat, timeFormat, dateTimeFormat } from 'src/config';
import moment from 'moment';

function Grid({ data }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWidgetData(data.i));
  }, []);

  const { title, description, key, loading } = data;
  const widgetData = data.data;

  const columns = useMemo(() => {
    if (!widgetData.length) {
      return [];
    }
    const colData = Object.keys(widgetData[0]);
    return colData.map((item) => {
      return {
        label: capitalizeFirstLetter(item),
        name: item,
        ...(item === 'date' && {
          options: {
            setCellHeaderProps: (value) => ({
              style: { minWidth: '140px' },
            }),
            setCellProps: () => ({
              style: { minWidth: '140px' },
            }),
            customBodyRenderLite: (index) => {
              const rData = widgetData[index];
              let date = rData[item];
              if (date && date !== '') {
                return moment.utc(date).local().format(dateTimeFormat);
              }
              return '-';
            },
          },
        }),
        ...(item === 'activity' && {
          options: {
            customBodyRenderLite: (index) => {
              const rData = widgetData[index];
              return <div dangerouslySetInnerHTML={{ __html: rData?.activity || '' }}></div>;
            },
          },
        }),
      };
    });
  }, [widgetData]);

  return (
    <CardContent sx={{ maxHeight: 'calc(100% - 32px)' }}>
      <Typography variant="h3">{data.title}</Typography>
      <Box mt={-6}>
        <AppGrid
          selectableRows="none"
          columns={columns}
          data={widgetData}
          loading={loading}
          options={{
            pagination: true,
          }}
        />
      </Box>
    </CardContent>
  );
}

export default Grid;
