import React, { useState } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Form, Button } from 'src/components/shared';
import { countryList, currencyList } from 'src/modules/admin/config/countries';

import { useDataProviders } from '../../utils/billingStore';

const inputProps = {
  size: 'small',
  fullWidth: true,
  sx: { margin: 0 },
  variant: 'outlined',
};

function BillingAddressForm({ customer, chargeBeeId, onClose }) {
  const [loading, setLoading] = useState(false);
  const { updateCustomer } = useDataProviders();

  const handleSubmit = async (data) => {
    try {
      setLoading(true);
      await updateCustomer(chargeBeeId, data);
      onClose();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  console.log('customer : ', customer);

  return (
    <>
      <Form
        initialValues={{
          companyName: customer?.company || '',
          line1: customer?.billing_address?.line1 || '',
          state: customer?.billing_address?.state || '',
          city: customer?.billing_address?.city || '',
          zip: customer?.billing_address?.zip || '',
          country: customer?.billing_address?.country || '',
          fname: customer?.first_name || '',
          lname: customer?.last_name || '',
          email: customer?.email || '',
          currency: customer?.preferred_currency_code || '',
          vat: '',
        }}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ values, ...formProps }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formProps.submitForm();
                return false;
              }}
              noValidate
            >
              <Stack direction="row" gap={2}>
                <Box flex={1}>
                  <Typography color="textSecondary">Company name</Typography>
                </Box>
                <Box flex={3}>
                  <Form.Field.Input name="companyName" label="Company Name" {...inputProps} />
                </Box>
              </Stack>

              <Stack direction="row" gap={2} mt={2.4}>
                <Box flex={1}>
                  <Typography color="textSecondary">Billing Address</Typography>
                </Box>
                <Box flex={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Form.Field.Input name="line1" label="Address 1" {...inputProps} />
                    </Grid>
                    <Grid item xs={4}>
                      <Form.Field.Input name="city" label="City" {...inputProps} />
                    </Grid>
                    <Grid item xs={4}>
                      <Form.Field.Input name="state" label="State" {...inputProps} />
                    </Grid>
                    <Grid item xs={4}>
                      <Form.Field.Input name="zip" label="Postcode" {...inputProps} />
                    </Grid>
                    <Grid item xs={4}>
                      <Form.Field.Select
                        options={countryList}
                        optLabel="name"
                        optValue="code"
                        name="country"
                        label="Country"
                        {...inputProps}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Stack>

              <Stack direction="row" gap={2} mt={2.4}>
                <Box flex={1}>
                  <Typography color="textSecondary">Billing Contact</Typography>
                </Box>
                <Box flex={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Form.Field.Input name="fname" label="First Name" {...inputProps} />
                    </Grid>

                    <Grid item xs={4}>
                      <Form.Field.Input name="lname" label="Last Name" {...inputProps} />
                    </Grid>

                    <Grid item xs={4}>
                      <Box
                        sx={{
                          '& .react-tel-input': {
                            height: '40px !important',
                            marginTop: '0px !important',
                            '& .form-control': {
                              height: '40px !important',
                            },
                            '& .selected-flag': {
                              height: '40px !important',
                            },
                          },
                        }}
                      >
                        <Form.Field.Phone name="phone" label="Phone" {...inputProps} />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Stack>

              <Stack direction="row" gap={2} mt={2.4}>
                <Box flex={1}>
                  <Typography color="textSecondary">Email</Typography>
                </Box>
                <Box flex={3}>
                  <Form.Field.Input name="email" label="Email" {...inputProps} />
                </Box>
              </Stack>

              {/* <Stack direction="row" gap={2} mt={2.4}>
                <Box flex={1}>
                  <Typography color="textSecondary">Other Billing Email</Typography>
                </Box>
                <Box flex={3}>
                  <Form.Field.Input
                    name="otherBilling"
                    label="Other billing email"
                    {...inputProps}
                  />
                </Box>
              </Stack> */}

              <Stack direction="row" gap={2} mt={2.4}>
                <Box flex={1}>
                  <Typography color="textSecondary">Currency</Typography>
                </Box>
                <Box flex={3}>
                  <Form.Field.Select
                    options={currencyList}
                    optLabel="name"
                    optValue="code"
                    name="currency"
                    label="Currency"
                    {...inputProps}
                  />
                </Box>
              </Stack>

              <Stack direction="row" gap={2} mt={2.4}>
                <Box flex={1}>
                  <Typography color="textSecondary">Vat</Typography>
                </Box>
                <Box flex={3}>
                  <Form.Field.Input name="vat" label="#Vat" {...inputProps} />
                </Box>
              </Stack>

              <Stack direction="row" gap={2} mt={2.4}>
                <Box flex={1}></Box>

                <Stack flex={3} direction="row" marginLeft={'auto'}>
                  <Stack direction="row" marginLeft={'auto'} gap={2}>
                    <Button
                      color="secondary"
                      variant="outlined"
                      size="medium"
                      disabled={loading}
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      size="medium"
                      loading={loading}
                      disabled={loading}
                    >
                      Save
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </form>
          );
        }}
      </Form>
    </>
  );
}

export default BillingAddressForm;
