import React, { useState } from 'react';
import { Box, Typography, Stack, Chip, Grid } from '@mui/material';
import { Avatar, Spacer, Button } from 'src/components/shared';
import { ContactRoot, ContactActionButton } from './styles';

const Contact = ({ option, data, handleClick, handleTaskOpen, onClose }) => {
  const name = `${data.fname} ${data.lname}`;
  const { jobTitle = '', employer = '' } = data;

  return (
    <ContactRoot>
      <Avatar size={36} src={data.imageUrl} name={name} sx={{ marginTop: '4px' }} />
      <Box ml={2} display="flex" alignItems="flex-start" flexDirection="column">
        <Typography
          noWrap
          variant="body1"
          onClick={() => {
            handleClick(data);
            onClose();
          }}
          sx={{ cursor: 'pointer' }}
        >
          {data.title} {data.fname} {data.lname}
        </Typography>
        <Typography noWrap variant="body2" color="textSecondary">
          <span style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '15px' }}>
            {jobTitle ? `${jobTitle.slice(0, 20)}... ` : ''}
            {jobTitle && employer ? (
              <>
                <span className="sub-text">at</span>
              </>
            ) : (
              ''
            )}
            {employer ? ` ${employer.slice(0, 10)}...` : ''}
          </span>
        </Typography>
      </Box>
    </ContactRoot>
  );
};

export default Contact;
