import React from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import useStyles from './style';

function ActionCardMenu({ id, status, handlePublish = () => {}, handleDelete = () => {} }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function handleScroll() {
    handleClose();
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div>
      <MoreHorizIcon onClick={handleClick} fontSize="medium" className={classes.menuSection} />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        disableScrollLock={true}
      >
        <Box>
          <Link to={`/admin/course/edit/${id}`}>
            <MenuItem className={classes.menuLinks}>Edit</MenuItem>
          </Link>

          <MenuItem
            className={classes.menuLinks}
            onClick={() => {
              handlePublish(id, status);
              handleClose();
            }}
          >
            {status ? 'Unpublish' : 'Publish'}
          </MenuItem>

          <MenuItem
            className={classes.menuLinks}
            onClick={() => {
              handleDelete(id);
              handleClose();
            }}
          >
            Delete
          </MenuItem>
        </Box>
      </Menu>
    </div>
  );
}

export default ActionCardMenu;
