import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { Box, Grid, Typography } from '@mui/material';
import { Modal, Form, Button, Spacer } from 'src/components/shared';
import { ConfirmDialog } from 'src/components/App';
import moment from 'moment';
import toast from 'src/utils/toast';

function OutboxBulkActions({ bulkAction, onClose, selectedTasks, taskBulkUpdate }) {
  const [loading, setLoading] = useState(false);

  const title = useMemo(() => {
    if (bulkAction === 'send') {
      return 'Send now';
    } else if (bulkAction === 'scheduledOn') {
      return `Pause until`;
    }
  }, [bulkAction]);

  if (!bulkAction || bulkAction == '') return null;

  const handleSubmit = async (payload) => {
    try {
      setLoading(true);
      await taskBulkUpdate(selectedTasks, payload);

      switch (bulkAction) {
        case 'Send':
          toast.success('Messages scheduled for send.');
          break;

        case 'scheduledOn':
        default:
          toast.success('Changed scheduled date successfully!');
          break;
      }

      setLoading(false);
      onClose();
    } catch (error) {
      setLoading(false);
    }
  };

  const sendNow = async () => {
    try {
      setLoading(true);
      await taskBulkUpdate(selectedTasks, {
        scheduledOn: new Date(),
        allowForceSend: true,
      });
      setLoading(false);
      onClose();
    } catch (error) {
      setLoading(false);
    }
  };

  if (bulkAction === 'send') {
    return (
      <ConfirmDialog title="Send email" open={true} onClose={onClose} onConfirm={sendNow}>
        Are you sure you want to send {selectedTasks.length} selected emails now?
      </ConfirmDialog>
    );
  }

  return (
    <Modal
      open={true}
      title={title}
      subtitle={`${selectedTasks.length} selected`}
      onClose={onClose}
    >
      {bulkAction === 'scheduledOn' && (
        <Typography color="textSecondary">
          Don't send {selectedTasks.length} {selectedTasks.length > 1 ? 'messages' : 'message'}{' '}
          before
          <Form
            initialValues={{
              scheduledOn: moment().add('days', 5),
              dueTime: '',
            }}
            validationSchema={Yup.object().shape({
              scheduledOn: Yup.date('Invalid Date!').required('Due date is required!'),
            })}
            onSubmit={(values, form) => {
              const data = JSON.parse(JSON.stringify(values));
              data.scheduledOn = moment(data.scheduledOn).format('YYYY-MM-DD');
              data.scheduledOn = new Date(data.scheduledOn + ' ' + data.dueTime).toISOString();
              handleSubmit(data);
            }}
          >
            {(props) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.submitForm();
                    return false;
                  }}
                  noValidate
                  style={{ height: '100%' }}
                >
                  <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Form.Field.Datepicker
                          fullWidth
                          variant="inline"
                          name="scheduledOn"
                          label="Due date"
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Form.Field.Timepicker
                          fullWidth
                          variant="outlined"
                          name="dueTime"
                          label="Due time"
                        />
                      </Grid>
                    </Grid>

                    <Box display={'flex'} spacing={2} mt={2} justifyContent="flex-end">
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={onClose}
                        loading={loading}
                      >
                        Cancel
                      </Button>
                      <Spacer x={1} y={1} />
                      <Button variant="contained" color="secondary" type="submit" loading={loading}>
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </form>
              );
            }}
          </Form>
        </Typography>
      )}
    </Modal>
  );
}

export default OutboxBulkActions;
