import React from 'react';
import { Box } from '@mui/material';
import { Form } from 'src/components/shared';

function Demo() {
  return (
    <Form
      initialValues={{
        files: [],
      }}
    >
      {({ values }) => {
        console.log('values : ', values);
        return (
          <Box height={400} width={400} position="relative">
            <Form.Field.FileDropper multiple={false} name="files" label="Course headline image" />
          </Box>
        );
      }}
    </Form>
  );
}

export default Demo;
