import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Box, Typography, InputAdornment, CircularProgress } from '@mui/material';
import { Form, Button, Spacer } from 'src/components/shared';
import { grey, green, red } from '@mui/material/colors';
import { registerSetPassword } from '../../actions/authActions';

import LockIcon from '@mui/icons-material/Lock';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { checkUserVerificationStatus } from 'src/modules/auth/api/authApis';

const lowerCaseRegex = new RegExp('^(?=.*[a-z])');
const upperCaseRegex = new RegExp('^(?=.*[A-Z])');
const numberRegex = new RegExp('^(?=.*[0-9])');
// const specialCharRegex = new RegExp('^(?=.*[!@#$%^&*])');
const specialCharRegex = new RegExp(/[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/);
const eightChatRegex = new RegExp('^(?=.{8,})');

import styled from 'styled-components';

const PasswordLintAlert = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
`;

import _ from 'lodash';

const PasswordLint = ({ isValid, title }) => {
  return (
    <PasswordLintAlert>
      {isValid ? (
        <CheckCircleIcon
          style={{
            color: green[800],
          }}
        />
      ) : (
        <ErrorOutlineIcon
          style={{
            color: grey[400],
          }}
        />
      )}
      <Spacer x={1} y={1} />
      <Typography color={isValid ? green[800] : 'textSecondary'}>{title}</Typography>
    </PasswordLintAlert>
  );
};

const SetPasswordForm = ({ router, isAuthenticated, ...props }) => {
  const [loading, setLoading] = React.useState(false);
  const [checkingVerification, setCheckingVerification] = React.useState(false);
  const history = useHistory();

  useEffect(() => {
    if (
      router.location?.query?.uid &&
      router.location?.query?.uid !== '' &&
      isAuthenticated === false
    ) {
      setCheckingVerification(true);
      checkUserVerificationStatus(router.location?.query?.uid)
        .then((res) => {
          if (res?.verified && res?.verified === true) {
            history.replace('/auth/login');
          }
          setCheckingVerification(false);
        })
        .catch((err) => {
          console.log('err : ', err);
          setCheckingVerification(false);
        });
    } else {
      setCheckingVerification(false);
    }
  }, [router.location?.query?.uid, isAuthenticated]);

  const userId = router.location?.query?.uid;

  if (checkingVerification) {
    return (
      <React.Fragment>
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <CircularProgress size={24} color="secondary" />
          <Spacer x={2} y={2} />
          <Typography component="h1" color="textSecondary">
            Please wait while we fetch your account details.
          </Typography>
        </Box>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Typography variant="h1" align="center">
        Create Password
      </Typography>
      <div style={{ height: 10 }} />
      <div style={{ maxWidth: 400, margin: '0 auto' }}>
        <Form
          initialValues={{
            password: '',
            confirmPassword: '',
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .required('Please enter your password!')
              .min(8, 'Password must be at least 8 characters long!')
              .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
                'Password must contain 8 Characters, One Uppercase, One Lowercase, One Number and one Special Character',
              ),
            confirmPassword: Yup.string()
              .required('Please enter your confirm password!')
              .oneOf([Yup.ref('password'), null], 'Passwords must match'),
          })}
          onSubmit={async (values, form) => {
            setLoading(true);
            const res = await props.registerSetPassword(userId, values);
            setLoading(false);
            form.prevent;
          }}
        >
          {(props) => {
            return (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  props.submitForm();
                  return false;
                }}
                noValidate
              >
                <Form.Field.Input
                  fullWidth
                  variant="outlined"
                  type="password"
                  name="password"
                  placeholder="Password"
                  disabled={loading}
                  autoComplete="current-password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon style={{ fill: '#d1d1d1' }} />
                      </InputAdornment>
                    ),
                  }}
                />

                <Form.Field.Input
                  fullWidth
                  variant="outlined"
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  disabled={loading}
                  autoComplete="current-password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon style={{ fill: '#d1d1d1' }} />
                      </InputAdornment>
                    ),
                  }}
                />

                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    loading={loading}
                    fullWidth
                    size="large"
                    endIcon={<ArrowForwardIcon />}
                  >
                    Next
                  </Button>
                </Box>

                <Spacer x={2} y={2} />
                <div>
                  <PasswordLint
                    isValid={eightChatRegex.test(props.values.password)}
                    title="At least 8 characters long"
                  />
                  <PasswordLint
                    isValid={lowerCaseRegex.test(props.values.password)}
                    title="One lowercase character"
                  />
                  <PasswordLint
                    isValid={upperCaseRegex.test(props.values.password)}
                    title="One uppercase character"
                  />
                  <PasswordLint
                    isValid={numberRegex.test(props.values.password)}
                    title="One numeric character"
                  />
                  <PasswordLint
                    isValid={specialCharRegex.test(props.values.password)}
                    title="One special character"
                  />
                </div>
              </form>
            );
          }}
        </Form>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  router: state.router,
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => ({
  registerSetPassword: (uid, data) => {
    return new Promise((resolve, reject) => {
      dispatch(registerSetPassword(uid, { ...data, redirect: true }, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SetPasswordForm);
