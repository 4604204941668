import { makeStyles } from '@mui/styles';
import { themeColors } from 'src/modules/lms/config';

const useStyles = makeStyles(({ palette }) => ({
  expertSection: {
    background: themeColors.bg3,
    paddingTop: '10px',
    borderRadius: '5px',
    marginTop: '20px',
    padding: '5px',
  },
  expertText: {
    color: '#FFD47E',
    marginLeft: '14px',
    marginBottom: '6px',
    fontSize: '16px',
    fontWeight: '700',
  },
  expertImg: {
    marginBottom: '16px',
    marginLeft: '16px',
  },
  expertTitle: {
    color: '#F3F3F3',
    fontWeight: '700',
    fontSize: '12px',
  },
  expertDesignation: {
    color: themeColors.lightGrey,
    fontWeight: '300',
    fontSize: '12px',
  },
  expertDetails: {
    width: '112px',
    height: '54px',
    lineHeight: '18px',
    marginLeft: '21px',
    marginTop: '8px',
  },
}));
export default useStyles;
