import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Button, Spacer } from '../../../../../components/shared';
import { isEmpty } from 'lodash';

import TagsGrid from '../../component/Tags/TagsGrid';

import AddIcon from '@mui/icons-material/Add';
import { useStyles } from './styles';

function TagsView({ tags, ...props }) {
  const classes = useStyles();
  const [drawerTitle, setDrawerTitle] = useState('');

  const handleDrawerClose = () => {
    setDrawerTitle('');
  };

  const toggleEditTags = (selectedContact = 0) => {
    setDrawerTitle('Tag Categories');
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography variant="h1">Tags</Typography>
          <Typography variant="body2" className={classes.headerSubTitle}>
            Create, edit and remove tags against your account.
          </Typography>
        </Box>
        {/*<Box display="grid" >*/}
        <Button
          variant="contained"
          color="primary"
          size="medium"
          startIcon={<AddIcon />}
          onClick={() => {}}
        >
          Create Tag
        </Button>
        {/*</Box>*/}
      </Box>
      <Spacer y={1} />
      <Box className={classes.tagCategoryBtn}>
        <Button
          variant="outlined"
          color="primary"
          size="medium"
          startIcon={<AddIcon />}
          onClick={() => toggleEditTags()}
        >
          Tag Categories
        </Button>
      </Box>
      <TagsGrid
        tags={tags}
        loading={props.loading.tags}
        drawerTitle={drawerTitle}
        handleDrawerClose={handleDrawerClose}
        updateTagStatus={props.updateTagStatus}
      />
    </>
  );
}

export default TagsView;
