import React from 'react';
import { Card, CardContent } from '@mui/material';
import { Spacer } from 'src/components/shared';
import { Skeleton } from '@mui/lab';
import { EmailHeader, EmailChip } from './styles';
import EmailIcon from '@mui/icons-material/Email';

function Message() {
  return (
    <>
      <Card
        elevation={0}
        sx={{ border: '1px solid #dddddd', position: 'relative', overflow: 'visible' }}
      >
        <EmailHeader>
          <EmailChip>
            {/* <EmailIcon sx={{ fontSize: '18px' }} />
            <Spacer x={1} y={1} /> */}
            <Skeleton animation="wave" height={16} width={100} />
          </EmailChip>
        </EmailHeader>

        <CardContent>
          <Skeleton animation="wave" height={24} width={100} />
          <Spacer x={1} y={1} />
          <Skeleton animation="wave" height={16} width="100%" />
          <Skeleton animation="wave" height={16} width="25%" />
        </CardContent>
      </Card>
      <Spacer x={5} y={5} />
    </>
  );
}

export default Message;
