import React from 'react';
import { Card, CardContent } from '@mui/material';
import { Box, CircularProgress, ButtonBase, InputAdornment, Stack } from '@mui/material';
import { Form, Button, Spacer } from 'src/components/shared';
import { Empty } from 'src/components/App';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';

import styled from 'styled-components';
import Item from './Item';
import InboxSkeleton from '../InboxSkeleton';

import { isEmpty } from 'lodash';

export const ScrolledList = styled.div`
  max-height: calc(100vh - 256px);
  overflow: auto;
`;

function List({ data, loading, search, messageKey, filters, ...props }) {
  const hasSearch = filters?._search || false;
  delete filters?._search;
  const hasFilters = !isEmpty(filters);

  return (
    <Card>
      <CardContent sx={{ paddingBottom: '8px', paddingRight: '8px' }}>
        <Form initialValues={{ search: search }} enableReinitialize={true}>
          <Stack direction="row" gap={1}>
            <Form.Field.InputDebounced
              name="search"
              variant="outlined"
              fullWidth
              onChange={(val) => {
                props.onSearch(val);
              }}
              placeholder="Search"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ fontSize: '20px' }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : search && search !== '' ? (
                      <ButtonBase
                        onClick={() => {
                          props.onSearch(undefined);
                        }}
                      >
                        <CloseIcon sx={{ fontSize: '20px' }} />
                      </ButtonBase>
                    ) : null}
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: '#ffffff',
                borderRadius: '4px',
                margin: 0,
              }}
            />
            <Button iconButton disabled={loading} onClick={props.refetch}>
              <RefreshIcon />
            </Button>
          </Stack>
        </Form>
      </CardContent>
      {loading ? (
        <InboxSkeleton />
      ) : (
        <>
          {data && data.length ? (
            <ScrolledList>
              {data.map((task, index) => {
                return (
                  <Item
                    task={task}
                    messageKey={messageKey}
                    key={`inbox-item-${index}`}
                    {...props}
                  />
                );
              })}
            </ScrolledList>
          ) : (
            <Box p={2}>
              {hasFilters && !hasSearch && (
                <Empty
                  showIcon={false}
                  description={`No ${
                    props.type === 'sent' ? 'sent emails' : 'email replies'
                  } matched with your filter. Please update your filters.`}
                />
              )}
              {hasSearch && (
                <Empty
                  showIcon={false}
                  title={`No ${
                    props.type === 'sent' ? 'sent emails' : 'email replies'
                  } matched with your search.`}
                  description={`Try using search options such as first or/and last name of the contact, the subject line of an email, text in the body of the email and the email address of the contact.`}
                />
              )}
            </Box>
          )}
        </>
      )}
    </Card>
  );
}

export default List;
