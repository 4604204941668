import React from 'react';
import { Box, Typography } from '@mui/material';
import { Button, Spacer } from 'src/components/shared';
import { EmptyIcon } from './styles';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const EmptyView = ({ onClickAdd }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      maxWidth={440}
      margin="0 auto"
      height="100%"
      justifyContent="center"
      alignItems={'center'}
      pt={10}
    >
      <EmptyIcon />
      <Spacer basis={5} />
      <Typography align="center">
        Start the sequence by adding a step using the button below
      </Typography>
      <Spacer basis={5} />
      <Box>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddCircleOutlineIcon />}
          onClick={onClickAdd}
        >
          Add Sequence Steps
        </Button>
      </Box>
    </Box>
  );
};

export default EmptyView;
