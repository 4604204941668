import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { themeColors } from '../../config';
import useStyles from './styles';
import { Box, ListItemIcon, Modal, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Avatar } from '@mui/material';
import DeleteModal from './DeleteModal';
import { useEffect } from 'react';

export default function ActionTemplateMenu({
  id,
  edit,
  share,
  deletion,
  note,
  setHover = () => {},
  ...props
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [tempId, setTempId] = React.useState('');

  const classes = useStyles();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const DeleteTemplate = () => {
    setTempId(id);
    setModalOpen(true);
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(null);
    setHover(false);
  };
  const EditIcon = () => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="12" fill="#944AF1" />
        <path
          d="M6.74902 15.0632V17.2507H8.93652L15.3882 10.7991L13.2007 8.61156L6.74902 15.0632ZM17.0799 9.10739C17.3074 8.87989 17.3074 8.51239 17.0799 8.28489L15.7149 6.91989C15.4874 6.69239 15.1199 6.69239 14.8924 6.91989L13.8249 7.98739L16.0124 10.1749L17.0799 9.10739Z"
          fill="black"
        />
      </svg>
    );
  };
  const DeleteIcon = () => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="12" fill="#EF5350" />
        <path
          d="M9 15.5C9 16.05 9.45 16.5 10 16.5H14C14.55 16.5 15 16.05 15 15.5V9.5H9V15.5ZM15.5 8H13.75L13.25 7.5H10.75L10.25 8H8.5V9H15.5V8Z"
          fill="black"
        />
      </svg>
    );
  };
  const ShareIcon = () => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="12" fill="#FFE500" />
        <path
          d="M15 14.8956C14.4933 14.8956 14.04 15.1064 13.6933 15.4367L8.94 12.5201C8.97333 12.3584 9 12.1968 9 12.0281C9 11.8594 8.97333 11.6978 8.94 11.5361L13.64 8.64759C14 8.999 14.4733 9.21687 15 9.21687C16.1067 9.21687 17 8.2751 17 7.10843C17 5.94177 16.1067 5 15 5C13.8933 5 13 5.94177 13 7.10843C13 7.27711 13.0267 7.43875 13.06 7.6004L8.36 10.489C8 10.1376 7.52667 9.91968 7 9.91968C5.89333 9.91968 5 10.8614 5 12.0281C5 13.1948 5.89333 14.1365 7 14.1365C7.52667 14.1365 8 13.9187 8.36 13.5673L13.1067 16.491C13.0733 16.6386 13.0533 16.7932 13.0533 16.9478C13.0533 18.0793 13.9267 19 15 19C16.0733 19 16.9467 18.0793 16.9467 16.9478C16.9467 15.8163 16.0733 14.8956 15 14.8956Z"
          fill="black"
        />
      </svg>
    );
  };

  return (
    <div>
      <MoreHorizIcon onClick={handleClick} fontSize="medium" className={classes.menuSection} />
      <Menu
        classes={{ paper: classes.menuPaper }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        disableScrollLock={true}
      >
        <Box className={classes.menuInner}>
          {edit && note ? (
            <Link to={`/lms/CreateNotes/${id}`}>
              <MenuItem className={classes.menuLinks}>
                <ListItemIcon>
                  <EditIcon sx={{ margin: 0 }} />
                </ListItemIcon>{' '}
                Edit
              </MenuItem>
            </Link>
          ) : (
            <Link to={`/lms/template/${id}`}>
              <MenuItem className={classes.menuLinks}>
                <ListItemIcon>
                  <EditIcon sx={{ margin: 0 }} />
                </ListItemIcon>{' '}
                Edit
              </MenuItem>
            </Link>
          )}
          {/* <MenuItem className={classes.menuLinks} onClick={handleClose}>
            <ListItemIcon>
              <HeartIcon />
            </ListItemIcon>
            Save as favourite
          </MenuItem> */}
          {share ? (
            <MenuItem className={classes.menuLinks}>
              <ListItemIcon>
                <ShareIcon />
              </ListItemIcon>
              Share
            </MenuItem>
          ) : null}
          {deletion ? (
            <MenuItem className={classes.menuLinks} onClick={DeleteTemplate}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              Delete
            </MenuItem>
          ) : null}
        </Box>
      </Menu>
      <DeleteModal
        id={tempId}
        note={note}
        onClose={() => {
          setModalOpen(false);
        }}
        modalOpen={modalOpen}
      />
    </div>
  );
}
