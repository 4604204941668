import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Box, Typography, Alert, Grid } from '@mui/material';
import { Drawer, Button, Spacer, Form, Modal } from 'src/components/shared';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';
import moment from 'moment';
import toast from 'src/utils/toast';
import { deleteTask } from '../../api/taskApis';

function TaskActionDrawer({ action, onClose, selectedTasks, taskBulkUpdate, tab }) {
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);

  useEffect(() => {
    switch (action) {
      case 'markTaskComplete':
        setTitle('Mark as Done');
        break;

      case 'changeDueDate':
        setTitle(`Change task's due date`);
        break;

      case 'changeAssigned':
        setTitle(`Mark task's assigned`);
        break;

      case 'delete':
        setTitle(`Delete`);

      default:
        break;
    }
  }, [action]);

  if (!action || action == '') return null;

  const handleSubmit = async (payload) => {
    try {
      setLoading(true);
      if (action === 'delete') {
        if (selectedTasks && selectedTasks.length) {
          let promises = [];
          for (let i = 0; i < selectedTasks.length; i++) {
            promises.push(deleteTask(selectedTasks[i].id));
          }
          await Promise.all(promises);
        }
      } else {
        await taskBulkUpdate(selectedTasks, payload);

        switch (action) {
          case 'markTaskComplete':
            toast.success('Task(s) completed successfully!');
            break;

          case 'changeDueDate':
            toast.success('Due date of selected Task(s) changed successfully!');
            break;

          case 'changeAssigned':
          default:
            toast.success('Task(s) assignment changed successfully!');
            break;
        }
      }

      setLoading(false);
      onClose(true);
    } catch (error) {
      setLoading(false);
    }
  };

  const markAllCompleted = () => {
    const data = {
      status: 'completed',
    };
    handleSubmit(data);
  };

  const deleteTasks = () => {
    // handleSubmit(data);
  };

  const getContent = () => {
    switch (action) {
      case 'markTaskComplete':
        return (
          <Box display={'flex'} flexDirection="column" justifyContent="space-between" height="100%">
            <Box>
              <Typography>
                This action will be applied to {selectedTasks.length} selected tasks.
              </Typography>
              <Spacer x={1} y={1} />
              <Typography>
                Are you sure you want to mark as complete? This action cannot be undone.
              </Typography>
              <Spacer x={2} y={3} />
              <Alert severity="warning" variant="outlined">
                By marking complete, any contact in a sequence will automatically move to their next
                step.
              </Alert>
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={3}>
              <Button variant="outlined" color="secondary" onClick={onClose}>
                Cancel
              </Button>
              <Spacer x={1} y={1} />
              <Button
                variant="contained"
                color="secondary"
                loading={loading}
                onClick={markAllCompleted}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        );
        break;

      case 'delete':
        return (
          <Box display={'flex'} flexDirection="column" justifyContent="space-between" height="100%">
            <Box>
              <Typography>
                This action will be applied to {selectedTasks.length} selected tasks.
              </Typography>
              <Spacer x={1} y={1} />
              <Typography>
                Are you sure you want to delete? This action cannot be undone.
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={3}>
              <Button variant="outlined" color="secondary" onClick={onClose}>
                Cancel
              </Button>
              <Spacer x={1} y={1} />
              <Button
                variant="contained"
                color="error"
                loading={loading}
                onClick={markAllCompleted}
              >
                Delete
              </Button>
            </Box>
          </Box>
        );

      case 'changeDueDate':
        return (
          <Form
            initialValues={{
              scheduledOn: moment().add('days', 5),
              scheduledOn: '',
            }}
            validationSchema={Yup.object().shape({
              scheduledOn: Yup.date('Invalid Date!').required('Due date is required!'),
            })}
            onSubmit={(values, form) => {
              const data = JSON.parse(JSON.stringify(values));
              data.scheduledOn = moment(data.scheduledOn).format('YYYY-MM-DD');
              data.scheduledOn = new Date(data.scheduledOn + ' ' + data.dueTime).toISOString();
              handleSubmit(data);
            }}
          >
            {(props) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.submitForm();
                    return false;
                  }}
                  noValidate
                  style={{ height: '100%' }}
                >
                  <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Form.Field.Datepicker
                          fullWidth
                          variant="inline"
                          name="scheduledOn"
                          label="Due date"
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Form.Field.Timepicker
                          fullWidth
                          variant="outlined"
                          name="dueTime"
                          label="Due time"
                        />
                      </Grid>
                    </Grid>

                    <Box display={'flex'} spacing={2} mt={2} justifyContent="flex-end">
                      <Button variant="outlined" color="secondary" onClick={onClose}>
                        Cancel
                      </Button>
                      <Spacer x={1} y={1} />
                      <Button variant="contained" color="secondary" type="submit" loading={loading}>
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </form>
              );
            }}
          </Form>
        );
        break;

      case 'changeAssigned':
        return (
          <Form
            initialValues={{
              assignedTo: {},
            }}
            validationSchema={Yup.object().shape({
              assignedTo: Yup.object().test(`assignee`, 'Please choose assignee', function (item) {
                return item?.id && item?.id !== '';
              }),
            })}
            onSubmit={(values, form) => {
              const data = JSON.parse(JSON.stringify(values));
              data.assignedTo = data.assignedTo.id;
              handleSubmit(data);
            }}
          >
            {(props) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.submitForm();
                    return false;
                  }}
                  noValidate
                  style={{ height: '100%' }}
                >
                  <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Form.Field.AutoComplete
                      multiple={false}
                      fullWidth
                      showAvatar={false}
                      options={[]}
                      variant="outlined"
                      remoteMethod={(search) => {
                        return fetchUsersLookup(search);
                      }}
                      name="assignedTo"
                      label="Assigned to"
                      optLabel="name"
                      optValue="id"
                      tip="Change assigned user for all selected tasks."
                    />

                    <Box display={'flex'} spacing={2} mt={2} justifyContent="flex-end">
                      <Button variant="outlined" color="secondary" onClick={onClose}>
                        Cancel
                      </Button>
                      <Spacer x={1} y={1} />
                      <Button variant="contained" color="secondary" type="submit" loading={loading}>
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </form>
              );
            }}
          </Form>
        );
        break;

      default:
        break;
    }
  };

  return (
    <Modal title={title} open={action && typeof action !== 'undefined'} onClose={onClose} size="xs">
      {getContent()}
    </Modal>
  );
}

export default TaskActionDrawer;
