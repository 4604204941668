import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link, useLocation } from 'react-router-dom';
import {
  List,
  ListItem,
  Collapse,
  Drawer,
  Box,
  ListItemIcon,
  Typography,
  Tooltip,
  Divider,
  Menu,
} from '@mui/material';
import { toggleSidebar } from 'src/modules/app/actions/appActions';

import moment from 'moment';
import { cloneDeep, isEqual } from 'lodash';
import storage from 'src/utils/storageUtils';
import getMenuItems from 'src/config/menu';
import images from 'src/config/images';
import useStyles from './styles';

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import pckg from '../../../../package.json';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Spacer } from 'src/components/shared';

import { usePopupState, bindMenu, bindHover, bindPopover } from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';

const Sidebar = ({ hasLmsAccess, hasOutreachAccess }) => {
  const classes = useStyles();
  const history = useHistory();
  let locations = useLocation();
  const [path, setPath] = useState(locations.pathname);
  const tenant = useSelector((state) => state.app.tenant);
  const user = useSelector((state) => state.auth.user);
  let [menuItems, setMenuItems] = useState([]);
  const [isDashboard, setIsDashboard] = useState(false);
  const [isPrimaryMenu, setIsPrimaryMenu] = useState(false);

  const popupState = usePopupState({ variant: 'popover', popupId: 'menu-dropdown' });

  const dispatch = useDispatch();
  const { sideBarIsOpen, taskCount } = useSelector((state) => state.app);
  const pinnedBoards = useSelector((state) => state.boards.boards);
  const isReseller = tenant?.isReseller || false;
  const isPartner = tenant?.isPartner || false;
  const isAdmin = user?.isAdmin || false;

  useEffect(() => {
    let menuType = path.split('/')[1];
    if (hasLmsAccess && hasOutreachAccess === false) {
      menuType = 'admin';
    }
    setMenuItems(
      getMenuItems(path, menuType, isReseller, isPartner, isAdmin, hasLmsAccess, hasOutreachAccess),
    );
  }, [tenant, user, hasLmsAccess, hasOutreachAccess]);

  useEffect(() => {
    if (
      // locations.pathname.split('/')[1] !== 'profile' &&
      locations.pathname.split('/')[1] !== 'admin'
    ) {
      setIsPrimaryMenu(true);
    } else {
      setIsPrimaryMenu(false);
    }
  }, [locations]);

  useEffect(() => {
    setIsDashboard(false);
    if (locations.pathname === '/dashboard') {
      // setIsDashboard(true);
    }
    let menuType = locations.pathname.split('/')[1];
    if (hasLmsAccess && hasOutreachAccess === false) {
      menuType = 'admin';
    }

    if (path.split('/')[1] !== locations.pathname.split('/')[1]) {
      if (locations.pathname.split('/')[1] !== 'dashboard') {
        menuItems = getMenuItems(
          locations.pathname,
          menuType,
          isReseller,
          isPartner,
          isAdmin,
          hasLmsAccess,
          hasOutreachAccess,
        );
        setMenuItems([...menuItems]);
        updateActiveState(menuItems);
        setMenuItems(cloneDeep(menuItems));
      } else if (
        locations.pathname.split('/')[1] !== 'profile' &&
        locations.pathname.split('/')[1] !== 'admin'
      ) {
        menuItems = getMenuItems(
          locations.pathname,
          menuType,
          isReseller,
          isPartner,
          isAdmin,
          hasLmsAccess,
          hasOutreachAccess,
        );
        setMenuItems([...menuItems]);
        updateActiveState(menuItems);
        setMenuItems(cloneDeep(menuItems));
      } else {
        menuItems = getMenuItems(
          locations.pathname,
          menuType,
          isReseller,
          isPartner,
          isAdmin,
          hasLmsAccess,
          hasOutreachAccess,
        );
        setMenuItems([...menuItems]);
        updateActiveState(menuItems);
        setMenuItems(cloneDeep(menuItems));
      }
    } else if (menuItems.length) {
      updateActiveState(menuItems);
      setMenuItems(cloneDeep(menuItems));
    }
    setPath(locations.pathname);
  }, [locations.pathname]);

  const updateActiveState = (arr) => {
    (arr || []).forEach((i) => {
      let currentPAth = locations.pathname.split('/')[1];
      if (currentPAth === 'prospect') {
        currentPAth = `${locations.pathname.split('/')[1]}/${locations.pathname.split('/')[2]}`;
      }
      if (locations.pathname === '/profile/integrations') {
        currentPAth = `${locations.pathname.split('/')[1]}/${locations.pathname.split('/')[2]}`;
      }
      if (isEqual(i.link, locations.pathname) || isEqual(i.link, `/${currentPAth}`)) {
        if (!i.child?.length) {
          i.activeClass = 'active';
        } else {
          updateActiveState(i.child);
        }
      } else {
        if (i.activeClass !== undefined) i.activeClass = '';
        updateActiveState(i.child);
      }
    });
  };

  const handleClick = (parent, child) => async (event) => {
    const parentNodeChild = menuItems.find((e) => e.id === parent.id);

    if (parent.id === 'back') {
      const referral = await storage.get('REFERRAL_ROUTE');
      if (referral && referral !== '') {
        await storage.del('REFERRAL_ROUTE');
        history.push(referral);
      } else {
        history.push('/');
      }
      event.preventDefault();
      return false;
    }

    if (parentNodeChild?.child?.length) {
      // event is null then child active will not be added
      // null is expand icon
      if (event) {
        menuItems.forEach((e) => {
          e?.child?.forEach((c) =>
            c.id === (child ? child : parentNodeChild?.child[0]).id
              ? (c.activeClass = 'active')
              : (c.activeClass = ''),
          );
          e.activeClass = '';
        });
      }
      if (!child) {
        const childNode = menuItems.find((e) => e.id === parent.id);
        if (!sideBarIsOpen) {
          if (!childNode.in) {
            childNode.in = !childNode.in;
          }
        } else {
          childNode.in = !childNode.in;
        }
      }
    } else {
      menuItems.forEach((e) => {
        e?.child?.forEach((c) => (c.activeClass = ''));
        parentNodeChild.id === e.id ? (e.activeClass = 'active') : (e.activeClass = '');
      });
    }
    menuItems = cloneDeep(menuItems);
    setMenuItems(cloneDeep(menuItems));
    !sideBarIsOpen && dispatch(toggleSidebar());
  };

  const togglePopup = (event) => {
    // console.log('HELLO WORLD');
    popupState.setAnchorEl(event.target);
    popupState.open();
  };

  const closePopover = (event) => {
    popupState.setAnchorEl(undefined);
    popupState.close();
  };

  return (
    <div
      className="sidebar-wrapper"
      style={sideBarIsOpen ? { width: isDashboard ? 300 : 230, minWidth: 230 } : { width: 64 }}
    >
      <Drawer
        variant="permanent"
        docked={undefined}
        className={classes.sliderDrawer}
        classes={{
          paper: sideBarIsOpen
            ? isDashboard
              ? classes.sliderPaperDashboard
              : classes.sliderPaper
            : classes.drawerPaperClose,
        }}
        open={sideBarIsOpen}
      >
        <Box
          display="flex"
          className={sideBarIsOpen ? classes.headerLogo : classes.headerLogoCollapsed}
        >
          <Link to={!hasOutreachAccess ? '/lms' : '/'}>
            <img
              src={sideBarIsOpen ? images.app.logoWhite : images.app.logoCollapsed}
              alt=""
              border="0"
              className={`img-fluid ${classes.leftBoxIcon}`}
            />
          </Link>
        </Box>
        <SimpleBar
          style={{
            maxHeight: 'calc(100vh - 96px)',
            minHeight: 'calc(100vh - 96px)',
            overflowX: 'hidden',
          }}
        >
          <List component="nav" className={classes.root}>
            {menuItems.map((item, index) => (
              <Box
                key={`menu-list-item-wrapper-${item.id}-${index}`}
                // {...(item.type === 'dropdown' && bindHover(popupState))}
                {...(item.type === 'dropdown' && {
                  onMouseEnter: togglePopup,
                  onMouseLeave: closePopover,
                })}
              >
                <Tooltip
                  title={!sideBarIsOpen ? item.title : ''}
                  arrow
                  placement={sideBarIsOpen ? 'bottom' : 'right'}
                  key={`menu-tooltip-item-${item.id}-${index}`}
                >
                  {item?.type && item?.type === 'divider' ? (
                    <Box sx={{ padding: '4px 0px' }}>
                      <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.23)' }} />
                    </Box>
                  ) : (
                    <ListItem
                      button
                      className={`${classes.navListTitle} MenuItem-${item.id} SideBar-${
                        sideBarIsOpen ? 'open' : 'close'
                      } ${item.activeClass || ''}`}
                      key={`menu-list-item-${item.id}-${index}`}
                      onClick={handleClick(item)}
                      component={Link}
                      to={item.link}
                    >
                      {item.menuIcon && (
                        <ListItemIcon
                          className={`${classes.listItemIcon} ${
                            item?.child?.find((e) => e.activeClass === 'active') && !sideBarIsOpen
                              ? 'active'
                              : ''
                          }`}
                        >
                          {item.menuIcon}
                        </ListItemIcon>
                      )}

                      <Typography variant="body1" className="career_portal sideText">
                        {item.title}
                      </Typography>

                      {item.expand ? (
                        <>
                          {item.in ? (
                            <RemoveCircleOutlineIcon
                              onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                handleClick(item)(null);
                              }}
                              className={
                                sideBarIsOpen && item.in
                                  ? `${classes.collapseIconExpand} ${classes.collapseIcon}`
                                  : classes.collapseIcon
                              }
                            />
                          ) : (
                            <AddCircleOutlineIcon
                              onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                handleClick(item)(null);
                              }}
                              className={
                                sideBarIsOpen && item.in
                                  ? `${classes.collapseIconExpand} ${classes.collapseIcon}`
                                  : classes.collapseIcon
                              }
                            />
                          )}
                        </>
                      ) : (
                        <></>
                      )}

                      {item?.dropdown ? (
                        <KeyboardArrowRightIcon
                          className={
                            sideBarIsOpen && item.in ? classes.collapseIcon : classes.collapseIcon
                          }
                        />
                      ) : null}
                    </ListItem>
                  )}
                </Tooltip>
                {item.type === 'dropdown' ? (
                  <HoverPopover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    classes={{ paper: classes.popOutMenu }}
                  >
                    {Boolean(item?.child?.length) && (
                      <List component="div" disablePadding>
                        {item.child.map((child, cIndex) =>
                          child.expand ? (
                            <Box
                              key={`menu-list-child-item-${child.id}-${cIndex}-menu-collapse-list-child-item`}
                            >
                              {sideBarIsOpen && (
                                <List
                                  component="div"
                                  disablePadding
                                  key={`menu-list-super-child-item-${child.id}-${cIndex}`}
                                >
                                  {Boolean(child.child.length) &&
                                    child.child.map((c) => (
                                      <Tooltip
                                        title={!sideBarIsOpen ? c.title : ''}
                                        arrow
                                        placement={sideBarIsOpen ? 'bottom' : 'right'}
                                        key={`menu-list-child-item-nested-${child.id}-${c.id}-${cIndex}`}
                                      >
                                        <ListItem
                                          button
                                          className={`${classes.nested} ${classes.navListChildItem} ${c.activeClass} childMenuItem`}
                                          component={Link}
                                          to={c.link}
                                          key={`menu-listItem-child-item-nested-${child.id}-${c.id}-${cIndex}`}
                                          style={c.menuIcon ? {} : { paddingLeft: 64 }}
                                        >
                                          {c.menuIcon && (
                                            <ListItemIcon className={classes.listItemIcon}>
                                              {c.menuIcon}
                                            </ListItemIcon>
                                          )}

                                          <Typography
                                            variant="body1"
                                            className="career_portal sideText"
                                          >
                                            {c.title}
                                          </Typography>
                                        </ListItem>
                                      </Tooltip>
                                    ))}
                                </List>
                              )}
                            </Box>
                          ) : (
                            <Tooltip
                              title={!sideBarIsOpen ? child.title : ''}
                              arrow
                              placement={sideBarIsOpen ? 'bottom' : 'right'}
                              key={`menu-tooltip-list-item-wrapper-${child.id}-${cIndex}`}
                            >
                              {child?.type && child?.type === 'helper' ? (
                                <ListItem className={classes.infoItem}>
                                  <Typography variant="body2">{child.title}</Typography>
                                </ListItem>
                              ) : child?.type && child?.type === 'divider' ? (
                                <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.23)' }} />
                              ) : (
                                <ListItem
                                  button
                                  className={`${classes.nested} ${classes.navListChildItem} ${child.activeClass}`}
                                  component={Link}
                                  to={child.link}
                                  key={`menu-list-child-item-${child.id}-${cIndex}`}
                                  style={child.menuIcon ? {} : { paddingLeft: 64 }}
                                  onClick={handleClick(item, child)}
                                  secondaryAction={
                                    <div>
                                      {child.title === 'Due' && (
                                        <Typography
                                          variant="body2"
                                          ml={10.7}
                                          className="career_portal sideText"
                                        >
                                          {taskCount?.inboxCount || 0}
                                        </Typography>
                                      )}
                                      {child.title === 'Upcoming' && (
                                        <Typography
                                          variant="body2"
                                          ml={9}
                                          className="career_portal sideText"
                                        >
                                          {taskCount?.outboxCount || 0}
                                        </Typography>
                                      )}
                                      {child.title === 'Errors' && (
                                        <Typography
                                          variant="body2"
                                          ml={10}
                                          className="career_portal sideText"
                                        >
                                          {taskCount?.Errors || 0}
                                        </Typography>
                                      )}
                                    </div>
                                  }
                                >
                                  {child.menuIcon && (
                                    <ListItemIcon className={classes.listItemIcon}>
                                      {child.menuIcon}
                                    </ListItemIcon>
                                  )}

                                  <Typography variant="body1" className="career_portal sideText">
                                    {child.title}
                                  </Typography>
                                </ListItem>
                              )}
                            </Tooltip>
                          ),
                        )}
                      </List>
                    )}
                  </HoverPopover>
                ) : null}
                {(!item?.type || item?.type !== 'dropdown') && (
                  <Collapse
                    in={sideBarIsOpen && item.in}
                    timeout="auto"
                    unmountOnExit
                    className={classes.collapseWrapper}
                  >
                    {Boolean(item?.child?.length) && (
                      <List component="div" disablePadding>
                        {item.child.map((child, cIndex) =>
                          child.expand ? (
                            <Box
                              key={`menu-list-child-item-${child.id}-${cIndex}-menu-collapse-list-child-item`}
                            >
                              {sideBarIsOpen && (
                                <List
                                  component="div"
                                  disablePadding
                                  key={`menu-list-super-child-item-${child.id}-${cIndex}`}
                                >
                                  {Boolean(child.child.length) &&
                                    child.child.map((c) => (
                                      <Tooltip
                                        title={!sideBarIsOpen ? c.title : ''}
                                        arrow
                                        placement={sideBarIsOpen ? 'bottom' : 'right'}
                                        key={`menu-list-child-item-nested-${child.id}-${c.id}-${cIndex}`}
                                      >
                                        <ListItem
                                          button
                                          className={`${classes.nested} ${classes.navListChildItem} ${c.activeClass} childMenuItem`}
                                          component={Link}
                                          to={c.link}
                                          key={`menu-listItem-child-item-nested-${child.id}-${c.id}-${cIndex}`}
                                          style={c.menuIcon ? {} : { paddingLeft: 64 }}
                                        >
                                          {c.menuIcon && (
                                            <ListItemIcon className={classes.listItemIcon}>
                                              {c.menuIcon}
                                            </ListItemIcon>
                                          )}

                                          <Typography
                                            variant="body1"
                                            className="career_portal sideText"
                                          >
                                            {c.title}
                                          </Typography>
                                        </ListItem>
                                      </Tooltip>
                                    ))}
                                </List>
                              )}
                            </Box>
                          ) : (
                            <Tooltip
                              title={!sideBarIsOpen ? child.title : ''}
                              arrow
                              placement={sideBarIsOpen ? 'bottom' : 'right'}
                              key={`menu-tooltip-list-item-wrapper-${child.id}-${cIndex}`}
                            >
                              {child?.type && child?.type === 'helper' ? (
                                <ListItem className={classes.infoItem}>
                                  <Typography variant="body2">{child.title}</Typography>
                                </ListItem>
                              ) : child?.type && child?.type === 'divider' ? (
                                <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.23)' }} />
                              ) : (
                                <ListItem
                                  button
                                  className={`${classes.nested} ${classes.navListChildItem} ${child.activeClass}`}
                                  component={Link}
                                  to={child.link}
                                  key={`menu-list-child-item-${child.id}-${cIndex}`}
                                  style={child.menuIcon ? {} : { paddingLeft: 64 }}
                                  onClick={handleClick(item, child)}
                                  secondaryAction={
                                    <div>
                                      {child.title === 'Due' && (
                                        <Typography
                                          variant="body2"
                                          ml={10.7}
                                          className="career_portal sideText"
                                        >
                                          {taskCount?.inboxCount || 0}
                                        </Typography>
                                      )}
                                      {child.title === 'Upcoming' && (
                                        <Typography
                                          variant="body2"
                                          ml={9}
                                          className="career_portal sideText"
                                        >
                                          {taskCount?.outboxCount || 0}
                                        </Typography>
                                      )}
                                      {child.title === 'Errors' && (
                                        <Typography
                                          variant="body2"
                                          ml={10}
                                          className="career_portal sideText"
                                        >
                                          {taskCount?.Errors || 0}
                                        </Typography>
                                      )}
                                    </div>
                                  }
                                >
                                  {child.menuIcon && (
                                    <ListItemIcon className={classes.listItemIcon}>
                                      {child.menuIcon}
                                    </ListItemIcon>
                                  )}

                                  <Typography variant="body1" className="career_portal sideText">
                                    {child.title}
                                  </Typography>
                                </ListItem>
                              )}
                            </Tooltip>
                          ),
                        )}
                      </List>
                    )}
                  </Collapse>
                )}

                <Spacer x={1} y={1} />
              </Box>
            ))}
          </List>
          {/* {isPrimaryMenu && sideBarIsOpen && hasOutreachAccess ? (
            <Box mt={3}>
              <PinnedBoards boards={pinnedBoards} sideBarIsOpen={sideBarIsOpen} />
            </Box>
          ) : null} */}
        </SimpleBar>
        <Box>
          {isDashboard ? (
            <></>
          ) : (
            <>
              {sideBarIsOpen && (
                <Box>
                  <List dense disablePadding>
                    <ListItem dense>
                      <Typography variant="subtitle2" className={classes.copyRightText}>
                        v.{pckg.version} © SuperReach {moment().format('YYYY')}
                        <br />
                        All rights reserved.
                      </Typography>
                    </ListItem>
                  </List>
                </Box>
              )}
            </>
          )}
        </Box>
      </Drawer>
    </div>
  );
};

export default Sidebar;
