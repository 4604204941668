import { makeStyles } from '@mui/styles';
import { themeColors } from 'src/modules/lms/config';

const useStyles = makeStyles(({ palette }) => ({
  courseSection3: {
    background: themeColors.bg3,
    paddingTop: '10px',
    borderRadius: '5px',
    marginTop: '20px',
  },
  coursesText: {
    color: '#FFD47E',
    marginBottom: '8%',
    fontSize: '16px',
    fontWeight: '700',
  },
  courseImg: {
    margin: '6px 14px 14px 14px',
  },
  courseImg2: {
    margin: '16px 14px 14px 14px',
  },
}));
export default useStyles;
