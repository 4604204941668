import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Grid, Box, Typography, Alert, FormHelperText } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import { Popover } from 'src/components/App';
import { fetchTemplatesLookup } from 'src/modules/app/api/appApis';
import { saveTemplate, fetchTemplateDetails, updateTemplate } from 'src/modules/admin/api/adminApi';
import { fetchTeamLookup, fetchUsersLookup } from 'src/modules/users/api/usersApi';
import { taskAutoCloseOptions } from '../../../config';

const CallBackForm = ({ onSubmit, loading, event, handleClose, ...props }) => {
  const [template, setTemplate] = useState({});
  const [description, setDescription] = useState('');
  const popoverRef = useRef(null);

  const templateCategoryList = useSelector((state) => state.app.globals.templateCategoryList);
  const sequenceAccessTypeList = useSelector((state) => state.app.globals?.sequenceAccessTypeList);

  const handleSubmit = async (values) => {
    try {
      let data = JSON.parse(JSON.stringify(values));
      let saveAsNew = data.saveAsNew;
      if (data.templates && data.templates?.id && data.templates?.id !== '') {
        data.templates = data.templates?.id;
      }
      if (saveAsNew === true) {
        let newTemplate = await saveTemplate({
          type: 'callback',
          name: data.name,
          content: data.description,
          category: data.category,
          isPublished: true,
          sharedWithTeams: data.sharedWithTeams.map((team) => team.id),
          sharedWithUsers: data.sharedWithUsers.map((team) => team.id),
          permissionType: data.permissionType,
        });
        data.templates = newTemplate.template.id;
      }
      delete data.category;
      delete data.subject;
      delete data.sharedWithTeams;
      delete data.sharedWithUsers;
      delete data.permissionType;
      onSubmit(data);
    } catch (error) {}
  };

  useEffect(() => {
    setDescription(event.description);
  }, [event]);

  return (
    <Form
      initialValues={{
        eventType: 'call',
        // template: template,
        name: template?.name || '',
        description: description,
        // saveAsNew: false,
        // category: template?.category || '',
        // permissionType: template?.permissionType || '',
        // sharedWithUsers: [],
        // sharedWithTeams: [],
        isAutoClose: event?.isAutoClose || false,
        autoCloseDuration: event?.autoCloseDuration ? parseInt(event?.autoCloseDuration) : 0,
      }}
      validationSchema={Yup.object().shape({
        description: Yup.string().required('Please enter description!'),
        autoCloseDuration: Yup.number()
          .integer()
          .test({
            message: () => 'Please choose Auto-Close duration!',
            test: (values, { options }) => {
              if (!values && options.parent.isAutoClose === false) return true;
              return values && values > 0;
            },
          }),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ values, ...formProps }) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formProps.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
              height="100%"
              width="100%"
            >
              <Box width="100%">
                {/* {!event?.id ? (
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography variant="body2">Template</Typography>
                    <Form.Field.AutoComplete
                      options={[template]}
                      fullWidth
                      disableClearable={true}
                      clearOnEscape={true}
                      className="inline-form-select"
                      name="template"
                      placeholder="Select Existing Template"
                      onChange={(val) => {
                        if (val) {
                          setTemplate(val);
                          setDescription(val.content);
                        }
                      }}
                      remoteMethod={(val) => {
                        return fetchTemplatesLookup(val, 'callback');
                      }}
                      optLabel="name"
                      optValue="id"
                      multiple={false}
                      disabled={values.saveAsNew}
                    />
                  </Box>
                ) : null} */}

                {values.saveAsNew === true ? (
                  <>
                    <Form.Field.Input
                      fullWidth
                      variant="outlined"
                      name="name"
                      label="Template name"
                    />
                  </>
                ) : null}

                <Spacer x={2} y={2} />
                <Form.Field.TextEditor
                  fullWidth
                  variant="outlined"
                  name="description"
                  label="Purpose of Call"
                />

                {values.saveAsNew === true ? (
                  <>
                    <Grid container spacing={2} mt={0}>
                      <Grid item xs={6}>
                        <Form.Field.Select
                          options={templateCategoryList}
                          fullWidth
                          variant="outlined"
                          name="category"
                          label="Template Category"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Form.Field.Select
                          options={sequenceAccessTypeList || []}
                          fullWidth
                          variant="outlined"
                          name="permissionType"
                          label="Permission Type"
                          showNone={false}
                          optLabel="label"
                          optValue="value"
                          onChange={(val) => {
                            switch (val) {
                              case 'private':
                                formProps.setFieldValue('sharedWithUsers', []);
                                break;
                              case 'team':
                                formProps.setFieldValue('sharedWithTeams', []);
                                break;
                              case 'organisation':
                              default:
                                formProps.setFieldValue('sharedWithUsers', []);
                                formProps.setFieldValue('sharedWithTeams', []);
                                break;
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                    {values.permissionType === 'private' ? (
                      <Form.Field.AutoComplete
                        multiple={true}
                        options={values?.sharedWithUsers || users}
                        fullWidth
                        variant="outlined"
                        name="sharedWithUsers"
                        label="Select users"
                        remoteMethod={(val) => {
                          return fetchUsersLookup(val);
                        }}
                        optLabel="name"
                        checkboxes={true}
                        optValue="id"
                      />
                    ) : null}

                    {values.permissionType === 'team' ? (
                      <Form.Field.AutoComplete
                        multiple={true}
                        options={values?.sharedWithTeams || teams}
                        fullWidth
                        variant="outlined"
                        name="sharedWithTeams"
                        checkboxes={true}
                        label="Select Teams"
                        remoteMethod={(val) => {
                          return fetchTeamLookup(val);
                        }}
                        optLabel="name"
                        optValue="id"
                      />
                    ) : null}
                  </>
                ) : null}
                <Box display="flex" alignItems="center" mt={2}>
                  <Box ml={2} mr={-2} mt={-1.5}>
                    <Form.Field.Switch name="isAutoClose" label={''} />
                  </Box>
                  <Typography variant="body2" color="textSecondary">
                    Automatically mark this step as complete if not completed in <br />
                    <Popover
                      trigger="click"
                      width={240}
                      Child={
                        <Typography
                          color={values.isAutoClose ? 'secondary' : 'textSecondary'}
                          variant="body2"
                          sx={{ display: 'inline' }}
                        >
                          {values?.autoCloseDuration && values?.autoCloseDuration > 0
                            ? taskAutoCloseOptions.filter(
                                (item) => item.value === values?.autoCloseDuration,
                              )[0]?.label
                            : 'Select Days'}
                        </Typography>
                      }
                      isPadding={true}
                      ref={popoverRef}
                      disabled={!values.isAutoClose}
                    >
                      <Form.Field.Select
                        options={taskAutoCloseOptions}
                        fullWidth={true}
                        variant="outlined"
                        name="autoCloseDuration"
                        optLabel="label"
                        optValue="value"
                        showNone={false}
                        onChange={(val) => {
                          popoverRef.current.close();
                        }}
                      />
                    </Popover>
                    &nbsp;after the due date.
                  </Typography>
                </Box>

                {formProps?.errors?.autoCloseDuration &&
                  formProps?.errors?.autoCloseDuration !== '' && (
                    <Box ml={6.5} mt={1}>
                      <FormHelperText error={true}>
                        {formProps?.errors?.autoCloseDuration}
                      </FormHelperText>
                    </Box>
                  )}
                <Spacer x={2} y={2} />
                <Alert severity="info">
                  To ensure that this task, if not complete, does not stop your sequence from
                  progressing, enable the above setting.
                </Alert>
              </Box>

              <Box mt={2} display="flex" justifyContent="space-between" width="100%">
                {!event?.id ? (
                  <Box>
                    {/* <Form.Field.Checkbox
                      name="saveAsNew"
                      label="Save as new template"
                      onChange={(val) => {
                        if (val === true && template && template?.id) {
                          formProps.setFieldValue('name', `${template.name} copy`);
                        }
                      }}
                    /> */}
                  </Box>
                ) : (
                  <Box></Box>
                )}

                <Box display="flex" justifyContent="flex-end" height="auto">
                  <Button
                    variant="outlined"
                    color="secondary"
                    disabled={loading}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Spacer basis={2} />
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
};

export default CallBackForm;
