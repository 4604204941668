import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PageTitle, Tabs } from 'src/components/App';

import {
  fetchResellerReport,
  fetchResellerReportSummary,
  fetchResellerTenantReport,
  fetchResellerTenantReportSummary,
  fetchResellerUserReport,
  fetchResellerUserReportSummary,
  setResellerFilters,
} from '../../actions/resellerActions';

import ResellersDashboard from './ResellersDashboard';
import ResellersTenantDashboard from './ResellersTenantDashboard';
import ResellersUserDashboard from './ResellersUserDashboard';

import { resellerTabs } from '../../config';

export const ResellersContainer = (props) => {
  const [currentTab, setCurrentTab] = useState('dashboard');
  const [tenantId, setTenantId] = useState('');
  const [userId, setUserId] = useState('');
  const [page, setPage] = useState('');
  const [showAutomationForm, setShowAutomationForm] = useState(false);

  useEffect(() => {
    const path = props.match.path;
    if (path && path === '/admin/resellers/dashboard/tenant/:tenantId') {
      setPage('tenantDashboard');
      setTenantId(props.match?.params?.tenantId);
    } else if (path && path === '/admin/resellers/dashboard/tenant/:tenantId/user/:userId') {
      setPage('userDashboard');
      setTenantId(props.match?.params?.tenantId);
      setUserId(props.match?.params?.userId);
    } else {
      setPage('');
    }
  }, [props.match]);

  const onTabChange = (tab) => {
    console.log('tab : ', tab);
    props.history.push(`/admin/resellers/${tab.id}`);
    // setCurrentTab(tab.id);
  };

  return (
    <>
      <PageTitle title="Resellers" />
      <Tabs tabs={resellerTabs} onChange={onTabChange} value={currentTab} />

      {!page || page == '' ? (
        <ResellersDashboard
          fetchResellerReport={props.fetchResellerReport}
          fetchResellerReportSummary={props.fetchResellerReportSummary}
          resellersReport={props.resellersReport}
          resellersReportSummary={props.resellersReportSummary}
          resellerFilters={props.resellerFilters}
          setResellerFilters={props.setResellerFilters}
          loading={props.loading}
        />
      ) : null}

      {page == 'tenantDashboard' ? (
        <ResellersTenantDashboard
          loading={props.loading}
          tenantId={tenantId}
          resellerFilters={props.resellerFilters}
          setResellerFilters={props.setResellerFilters}
          fetchResellerTenantReport={props.fetchResellerTenantReport}
          fetchResellerTenantReportSummary={props.fetchResellerTenantReportSummary}
          resellersTenantReport={props.resellersTenantReport}
          resellersTenantReportSummary={props.resellersTenantReportSummary}
        />
      ) : null}

      {page == 'userDashboard' ? (
        <ResellersUserDashboard
          loading={props.loading}
          tenantId={tenantId}
          userId={userId}
          resellerFilters={props.resellerFilters}
          setResellerFilters={props.setResellerFilters}
          fetchResellerUserReport={props.fetchResellerUserReport}
          fetchResellerUserReportSummary={props.fetchResellerUserReportSummary}
          resellersUsersReport={props.resellersUsersReport}
          resellersUsersReportSummary={props.resellersUsersReportSummary}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.reseller.loading,
  resellersReport: state.reseller.resellersReport,
  resellersReportSummary: state.reseller.resellersReportSummary,
  resellersTenantReport: state.reseller.resellersTenantReport,
  resellersTenantReportSummary: state.reseller.resellersTenantReportSummary,
  resellersUsersReport: state.reseller.resellersUsersReport,
  resellersUsersReportSummary: state.reseller.resellersUsersReportSummary,
  resellerFilters: state.reseller.resellerFilters,
});

const mapDispatchToProps = (dispatch) => ({
  fetchResellerReport: (filters, paging, resellerFilters) =>
    dispatch(fetchResellerReport(filters, paging, resellerFilters)),
  fetchResellerReportSummary: (resellerFilters) =>
    dispatch(fetchResellerReportSummary(resellerFilters)),
  fetchResellerTenantReport: (tenantId, filters, paging, resellerFilters) =>
    dispatch(fetchResellerTenantReport(tenantId, filters, paging, resellerFilters)),
  fetchResellerTenantReportSummary: (tenantId, resellerFilters) =>
    dispatch(fetchResellerTenantReportSummary(tenantId, resellerFilters)),
  fetchResellerUserReport: (tenantId, userId, filters, paging, resellerFilters) =>
    dispatch(fetchResellerUserReport(tenantId, userId, filters, paging, resellerFilters)),
  fetchResellerUserReportSummary: (tenantId, userId, resellerFilters) =>
    dispatch(fetchResellerUserReportSummary(tenantId, userId, resellerFilters)),

  setResellerFilters: (filters) => dispatch(setResellerFilters(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResellersContainer);
