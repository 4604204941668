import React from 'react';

function Iframe({ data }) {
  return (
    <>
      <iframe src={data.iframeSrc} width="100%" height="100%" />
    </>
  );
}

export default Iframe;
