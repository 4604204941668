import React from 'react';
import api from 'src/api';

export const cacheTest = (asyncValidate) => {
  let _valid = false;
  let _value = '';

  return async (value, { createError, ...otherParams }) => {
    if (value !== _value && (value !== '' || typeof value != 'undefined')) {
      const response = await asyncValidate(value, {
        createError,
        ...otherParams,
      });
      _value = value;
      _valid = response;
      return response;
    }
    return _valid;
  };
};

export const checkUniqueUserEmail = async (value, { createError, formRegistration }) => {
  if (!!value && !/.+@.+\..+/.test(value)) {
    return createError({
      message: 'Enter a valid email address!',
    });
  }
  try {
    const res = await api(`/user/exists?email=${value}`, null, 'get');
    // const res = await api.get(`/api/user/exists?email=${value}`);
    if (res.status === 'FOUND') {
      return createError({
        message: 'Email already taken!',
      });
    }
    return true;
  } catch (error) {
    return createError({
      message: 'Enter a valid email address!',
    });
  }
};

export const emailUniqueApi = (email) => {
  return api(`/emails?email=${email}&isDeleted=false`, '', 'GET');
};

export const checkEmailUnique = async (value, { createError, formRegistration }) => {
  if (!!value && !/.+@.+\..+/.test(value)) {
    return createError({
      message: 'Enter a valid email address!',
    });
  }
  try {
    const res = await api(`/emails?email=${value}&isDeleted=false`, '', 'GET');
    if (res.emails.length > 0) {
      return res?.emails?.[0];
    } else return null;
  } catch (error) {
    return createError({
      message: 'Enter a valid email address!',
    });
  }
};

export const checkPhoneUnique = async (value, contactId) => {
  // if (!!value && !/.+@.+\..+/.test(value)) {
  //   return createError({
  //     message: 'Enter a valid email address!',
  //   });
  // }
  try {
    const res = await api(`contact/${contactId}/phone`, value, 'POST');
    if (res) {
      return res.unique;
    }
  } catch (error) {
    return createError({
      message: 'Enter a valid phone Number!',
    });
  }
};

const isNilOrEmptyString = (value) => value === undefined || value === null || value === '';

export const is = {
  match:
    (testFn, message = '') =>
    (value, fieldValues) =>
      !testFn(value, fieldValues) && message,

  required: () => (value) => isNilOrEmptyString(value) && 'This field is required',

  minLength: (min) => (value) =>
    !!value && value.length < min && `Must be at least ${min} characters`,

  maxLength: (max) => (value) =>
    !!value && value.length > max && `Must be at most ${max} characters`,

  notEmptyArray: () => (value) =>
    Array.isArray(value) && value.length === 0 && 'Please add at least one item',

  email: () => (value) => !!value && !/.+@.+\..+/.test(value) && 'Must be a valid email',

  url: () => (value) =>
    !!value &&
    // eslint-disable-next-line no-useless-escape
    !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(value) &&
    'Must be a valid URL',
};

export const generateErrors = (fieldValues, fieldValidators) => {
  const errors = {};

  // Object.entries(fieldValidators).forEach(([fieldName, validators]) => {
  //   [validators].flat().forEach((validator) => {
  //     const errorMessage = validator(fieldValues[fieldName], fieldValues);
  //     if (errorMessage && !errors[fieldName]) {
  //       errors[fieldName] = errorMessage;
  //     }
  //   });
  // });
  return errors;
};
