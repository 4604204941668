import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';
import Modal from '../../Modal';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Button } from '../..';
import { Stack, Typography } from '@mui/material';

import { CropContainer } from './style';
// import { getCroppedImg, blobToImage } from './utils';
import getCroppedImg from './utils';

function CropperApp({ image, aspectRatio, cropShape, handleSubmit, onClose, loading, allowSkip }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const file = await getCroppedImg(image, croppedAreaPixels, rotation);
      handleSubmit(file);
    } catch (e) {}
  }, [croppedAreaPixels, rotation, image]);

  const skpCrop = async () => {
    let blob = await fetch(image).then((r) => r.blob());
    const fileObj = new File([blob], 'image.png', {
      type: 'image/png',
    });
    handleSubmit(fileObj);
  };

  return (
    <Modal open={true} onClose={onClose}>
      <Box>
        {/* <img src={image} /> */}
        <CropContainer>
          <Cropper
            image={image}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            zoomSpeed={4}
            maxZoom={3}
            zoomWithScroll={true}
            showGrid={true}
            aspect={aspectRatio}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            onRotationChange={setRotation}
            cropShape={cropShape}
          />
        </CropContainer>
        <Stack direction="row" gap={4} alignItems="center" mt={2}>
          <Stack flex={1} direction="row" gap={2} alignItems="center">
            <Typography color="secondary">Zoom : </Typography>
            <Stack flex={1}>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="zoom"
                onChange={(e, zoom) => setZoom(zoom)}
                className="range"
                color="secondary"
              />
            </Stack>
          </Stack>
          <Stack direction="row" gap={1}>
            <Button
              color="secondary"
              variant="text"
              size="small"
              onClick={onClose}
              disabled={loading}
            >
              Cancel
            </Button>
            {allowSkip && (
              <Button
                variant="outlined"
                onClick={skpCrop}
                color="secondary"
                size="small"
                loading={loading}
              >
                Skip Crop
              </Button>
            )}
            <Button
              variant="contained"
              onClick={showCroppedImage}
              color="secondary"
              size="small"
              loading={loading}
            >
              Crop
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}

CropperApp.propTypes = {
  cropShape: PropTypes.string,
  allowSkip: PropTypes.bool,
};

CropperApp.defaultProps = {
  cropShape: 'rect',
  allowSkip: false,
};

export default CropperApp;
