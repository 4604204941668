import styled from 'styled-components';
import { Grid, ButtonBase, Typography, Paper, Box } from '@mui/material';
import images from 'src/config/images';
import AddIcon from '@mui/icons-material/Add';

import {
  deepOrange,
  deepPurple,
  blue,
  cyan,
  indigo,
  lightGreen,
  lime,
  teal,
} from '@mui/material/colors';

export const colors = [
  '#ffffff',
  deepOrange[400],
  deepPurple[400],
  cyan[400],
  blue[500],
  teal[500],
  indigo[500],
  lightGreen[500],
  lime[300],
];

export const ColorPicker = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: ${(props) => (props.selected ? '#dddddd' : props.color)};
  border: ${(props) => (props.selected ? `6px solid ${props.color}` : 'none')};
  padding: 2px;
  box-sizing: border-box;
  cursor: pointer;
`;

export const MainGrid = styled.div`
  min-height: 600px;
  max-height: 600px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: calc(100% - 104px);
  padding: 0px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  z-index: 999999;
  background-color: #ffffff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  max-width: 1160px;
`;

export const Content = styled.div`
  /* margin-top: 60px; */
  /* min-height: 600px;
  overflow-x: hidden;
  overflow-y: auto; */
`;

export const Left = styled.div`
  background: rgba(0, 0, 0, 0.04);
  min-height: 600px;
  max-height: 600px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  position: fixed;
  width: 200px;
  padding: 16px;
`;

export const Right = styled.div`
  min-height: 560px;
  max-height: 560px;
  height: 560px;
  overflow: auto;
  width: calc(100% - 270px);
  margin-left: 230px;
  padding: 20px;
`;

export const PaperWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 150px;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  margin-bottom: 12px;
  cursor: pointer;
  display: flex;
`;

export const PlusIcon = styled(AddIcon)`
  fill: rgba(0, 0, 0, 0.42);
  color: rgba(0, 0, 0, 0.42);
  font-size: 120px;
`;

export const AddButtonWrapper = styled.div`
  /* position: absolute;
  top: 20px;
  right: 16px; */
  margin-top: 10px;
`;

export const Thumb = styled.img``;

export const Link = styled(Box).attrs({
  color: 'textSecondary',
  variant: 'body2',
})`
  cursor: pointer;
  color: ${(props) => (props.active ? '#4789c2' : 'rgba(0,0,0,0.60)')};
  &:hover {
    color: #4789c2;
  }
`;

export const SwimLaneContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
`;

export const SwimLaneIconContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 600px;
  background-color: rgba(0, 0, 0, 0.04);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

export const SwimLaneContent = styled.div`
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

export const SwimLaneIcon = styled.img`
  width: 250px;
  margin-bottom: 40px;
`;
