import { ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { Avatar } from 'src/components/shared';
import { capitalizeName } from '../../utils/contactUtils';

const ContactProfileTabView = ({ contact }) => {
  const { fname, lname, employer, jobTitle, address } = contact;
  // const state = address?.[0]?.state;
  return (
    <ListItem dense={false} disableGutters={false} disablePadding={false} alignItems="flex-start">
      <ListItemAvatar>
        <Avatar size={40} name={`${fname} ${lname}`} src={undefined} />
      </ListItemAvatar>
      <ListItemText
        sx={{ display: 'flex', flexDirection: 'column' }}
        primary={
          <Typography sx={{ display: 'inline' }} variant="body1" color="textPrimary">
            {capitalizeName(`${fname} ${lname}`)}
          </Typography>
        }
        secondary={
          <Typography
            sx={{ display: 'inline' }}
            component="span"
            variant="body2"
            color="textSecondary"
          >
            <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
              {jobTitle ? `${jobTitle}` : ''}
              {jobTitle && employer && (
                <>
                  <span
                    style={{
                      color: '#4789C2',
                      marginLeft: '4px',
                      marginRight: '4px',
                    }}
                  >
                    at
                  </span>
                </>
              )}
              {employer}
            </span>
          </Typography>
        }
      />
    </ListItem>
  );
};

export default ContactProfileTabView;
