import React, { useState } from 'react';
import { Checkbox, FormControlLabel, Stack, Box, Typography } from '@mui/material';
import { filters } from './filters';
import { uniqueId } from 'lodash';
import CheckFilters from './CheckFilters';

const ActivitiesFilter = ({ values: filterItems, onChange: setFiltersItems }) => {
  return (
    <Stack direction="row" spacing={2}>
      <Box p={2} display="flex" flexDirection="row">
        {filters.map((filter, index) => {
          const allChecked = !filter?.child?.find(
            (item) => !filterItems?.find((ele) => ele?.value === item?.value),
          );
          return (
            <Box key={`activity-filters-${index}`}>
              <FormControlLabel
                label={<Typography variant="body2">{filter.label}</Typography>}
                sx={{ fontSize: 12 }}
                control={
                  <Checkbox
                    checked={allChecked}
                    indeterminate={
                      !allChecked &&
                      !!filter?.child?.find((item) =>
                        filterItems?.find((ele) => ele?.value === item?.value),
                      )
                    }
                    onChange={() => {
                      if (allChecked)
                        setFiltersItems(
                          filterItems?.filter(
                            (item) => !filter?.child?.find((ele) => ele?.value === item?.value),
                          ),
                        );
                      else
                        setFiltersItems([
                          ...filterItems,
                          ...filter?.child?.filter(
                            (item) => !filterItems?.find((ele) => ele?.value === item?.value),
                          ),
                        ]);
                    }}
                  />
                }
              />
              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                {filter?.child.map((cFilter, cIndex) => {
                  const label = { inputProps: { 'aria-label': cFilter?.value } };
                  const isChecked = !!filterItems?.find(item => item?.value === cFilter?.value && item.id === cFilter.id)
                  return (
                    <FormControlLabel
                      key={`activity-filters-${index}-child-${cIndex}`}
                      label={<Typography variant="body2">{cFilter.label}</Typography>}
                      control={
                        <CheckFilters
                          label={label}
                          isChecked={isChecked}
                          filterData={cFilter}
                          filterItems={filterItems}
                          setFiltersItems={setFiltersItems}
                        />
                      }
                    />
                  );
                })}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Stack>
  );
};

export default ActivitiesFilter;
