import React, { useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Grid, Container, Card, CardContent, Tooltip } from '@mui/material';
import { Tabs, Breadcrumbs, Empty } from 'src/components/App';
import { Button, Spacer, Drawer, Modal } from 'src/components/shared';
import SequenceOnboarding from '../../components/Sequence/SequenceOnboarding';
import SequenceSteps from '../../components/Sequence/SequenceSteps';
import SettingsForm from '../../components/Sequence/SettingsForm';
import SequenceStatusForm from '../../components/Sequence/SequenceStatusForm';
import SequenceStatusFormSwitch from '../../components/Sequence/SequenceStatusFormSwitch';
import CloneSequenceForm from '../../components/Sequence/CreateSequence/CloneSequenceForm';
import EnrollContactsForm from '../../components/Sequence/EnrollContactsForm';

import CopyAllIcon from '@mui/icons-material/CopyAll';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PreviewIcon from '@mui/icons-material/Preview';

import SequenceContacts from '../../components/SequenceContacts/Grid';
import SequenceSummary from '../../components/SequenceSummary';
import SequencePreview from '../../components/Sequence/SequencePreview';

import { sequenceDetailsTabs } from '../../config';

function SequenceView({ integrations, sequence, fields, sequenceEvents, ...props }) {
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [showCloneForm, setShowCloneForm] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showAddContactsForm, setShowAddContactsForm] = useState(false);

  const [currentTab, setCurrentTab] = useState(sequenceDetailsTabs[0].id);
  const history = useHistory();

  useEffect(() => {
    if (props.location && props.location?.search && props.location?.search == '?onboarding=true') {
      setShowOnboarding(true);
    }
    if (props?.match?.params?.tab && props?.match?.params?.tab !== '') {
      setCurrentTab(props?.match?.params?.tab);
    }
  }, [props]);

  const togglePreview = () => {
    setShowPreview(!showPreview);
  };

  const breadcrumbs = useMemo(
    () => [
      {
        title: 'Sequence',
        route: '/sequences',
      },
      {
        title: sequence?.name || '',
      },
    ],
    [sequence],
  );

  const handleCloneSequence = () => {
    setShowCloneForm(true);
  };

  const onTabChange = (tab) => {
    // setCurrentTab(tab.id);
    history.push(`/sequence/${sequence.id}/${tab.id}`);
  };

  const onSequenceUpdate = (data) => {
    // console.log('DATA : ', data);
  };

  const fetchSequenceContacts = (paging, filters, seqId, sort) => {
    if (seqId) {
      return props.fetchSequenceContacts(paging, filters, seqId, sort);
    }
  };

  const renderTab = () => {
    switch (currentTab) {
      case 'summary':
        return (
          <SequenceSummary
            sequence={sequence}
            seqId={sequence.id}
            fetchSequenceContacts={fetchSequenceContacts}
            sequencesContacts={props.sequencesContacts}
            contactsLoading={props.contactsLoading}
            sequencesStats={props.sequencesStats}
            sequenceStatsLoading={props.sequenceStatsLoading}
            enrollmentsStats={props.enrollmentsStats}
            fetchSequenceStats={props.fetchSequenceStats}
          />
        );
        break;

      case 'people':
        return (
          <SequenceContacts
            seqId={sequence.id}
            fetchSequenceContacts={fetchSequenceContacts}
            sequencesContacts={props.sequencesContacts}
            contactsLoading={props.contactsLoading}
          />
        );
        break;
      case 'steps':
        return (
          <Container maxWidth="md">
            <SequenceSteps
              sequence={sequence}
              sequenceEvents={sequenceEvents}
              {...props}
              loading={props.loadingEvents}
            />
          </Container>
        );
        break;

      case 'settings':
      default:
        return (
          <Container maxWidth="md" mt={2}>
            <Card>
              <CardContent>
                <SettingsForm
                  integrations={integrations}
                  sequence={sequence}
                  sequenceEvents={sequenceEvents}
                  onSubmit={onSequenceUpdate}
                  fields={fields}
                  isOnboarding={false}
                  moveToNext={() => {}}
                  {...props}
                />
              </CardContent>
            </Card>
          </Container>
        );
        break;
    }
  };

  const closeOnboarding = () => {
    // props.fetchSequenceStats(sequence.id);
    // history.replace(`/sequence/${sequence.id}`);
    history.replace(`/sequences`);
    setShowOnboarding(false);
  };

  return (
    <>
      {sequence?.id ? (
        <>
          <Box>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Breadcrumbs steps={breadcrumbs} />
                <Spacer y={0.5} />
                <Typography variant="h1" color="textPrimary">
                  {sequence?.name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                  <SequenceStatusFormSwitch sequence={sequence} putSequence={props.putSequence} />
                  <Box mt={1.5} display="flex">
                    <Tooltip title="Preview sequence steps">
                      <div>
                        <Button
                          iconButton
                          size="small"
                          onClick={togglePreview}
                          color="primary"
                          variant="contained"
                        >
                          <PreviewIcon />
                        </Button>
                      </div>
                    </Tooltip>
                    <Tooltip title="Clone sequence">
                      <div>
                        <Button
                          iconButton
                          size="small"
                          onClick={handleCloneSequence}
                          color="primary"
                          variant="contained"
                        >
                          <CopyAllIcon />
                        </Button>
                      </div>
                    </Tooltip>
                    <Spacer x={0.6} y={0.6} />
                    <Tooltip title="Enrol contacts">
                      <div>
                        <Button
                          iconButton
                          size="small"
                          onClick={() => {
                            setShowAddContactsForm(true);
                          }}
                          color="primary"
                          variant="contained"
                        >
                          <GroupAddIcon />
                        </Button>
                      </div>
                    </Tooltip>
                  </Box>
                  {/* <Button variant="contained" color="secondary">
                Actions
              </Button> */}
                </Box>
              </Grid>
            </Grid>
            <Spacer y={0.5} />
            <Tabs
              tabs={sequenceDetailsTabs?.map((ele) => {
                if (ele.id === 'people')
                  return {
                    ...ele,
                    name: `Contacts (${sequence?.stats?.totalEnrolments || 0})`,
                  };
                else return ele;
              })}
              onChange={onTabChange}
              value={currentTab}
            />
          </Box>
          <Box mt={4} mb={4}>
            {renderTab()}
          </Box>
        </>
      ) : (
        <Empty
          title="No sequence found"
          description="create a new sequence"
          buttonTitle="Create Sequence"
          onClick={props.toggleAddSequenceForm}
        />
      )}

      {showPreview && (
        <SequencePreview
          sequence={sequence}
          sequenceEvents={sequenceEvents}
          onClose={togglePreview}
          fetchSequenceEvents={props.fetchSequenceEvents}
        />
      )}

      <Modal fullScreen={true} open={showOnboarding} onClose={() => {}} style={{ zIndex: 1199 }}>
        <SequenceOnboarding onClose={closeOnboarding} />
      </Modal>

      <Modal
        title="Clone Sequence"
        size="xs"
        open={showCloneForm}
        onClose={() => {
          setShowCloneForm(false);
        }}
      >
        <CloneSequenceForm
          cloneSequence={props.cloneSequence}
          disabled={true}
          sequence={sequence}
        />
      </Modal>

      <Drawer
        open={showAddContactsForm}
        title="Enrol contacts to sequence"
        onClose={() => {
          setShowAddContactsForm(false);
        }}
      >
        <EnrollContactsForm
          sequence={sequence}
          fetchSequenceContacts={fetchSequenceContacts}
          sequencesContacts={props.sequencesContacts}
          fetchSequence={props.fetchSequence}
          onClose={() => {
            setShowAddContactsForm(false);
          }}
        />
      </Drawer>
    </>
  );
}

export default SequenceView;
