import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Box, FormHelperText, Typography } from '@mui/material';
import { Form, Button } from 'src/components/shared';
import { fetchTemplatesLookup } from 'src/modules/sequence/api/sequenceApi';

function CloneTemplateForm({ temp, queryType, setShowCloneForm, drawerToggle, ...props }) {
  const [loading, setLoading] = useState(false);

  const templateType = (linkedinType) => {
    switch (linkedinType) {
      case 'Linkedin Message':
        return 'linkedinMessage';
        break;

      case 'Connection Request':
        return 'linkedinConnection';
        break;

      case 'LinkedinMail':
        return 'linkedinMail';
        break;

      case 'Linkedin':
        return 'linkedin';
        break;

      case 'Callback':
        return 'callback';
        break;

      default:
        return linkedinType;
        break;
    }
  };

  const handleSubmit = async (values) => {
    const data = JSON.parse(JSON.stringify(values));
    setLoading(true);
    const sequence = values.temp;
    data.name = data.sequenceName;
    delete data.temp;
    delete data.name;
    delete data.template.id;
    delete data.template.updatedBy;
    delete data.template.createdAt;

    let clonedData = {
      ...data.template,
      name: data.templateName,
      createdBy: data.template?.createdBy?.id,
      owner: data.template?.owner?.id,
      tenant: data.template?.tenant?.id,
      type: templateType(data.template.type) || '',
      files:
        data.template.files && data.template.files.length
          ? data.template.files.map((item) => item.id)
          : [],
      attachments: data?.template.attachments || [],
    };
    // await props.saveTemplates(clonedData);
    drawerToggle({ edit: clonedData, clone: true, open: true });

    setLoading(false);
    setShowCloneForm(false);
  };

  return (
    <Form
      initialValues={{
        template: temp || '',
        templateName: temp && temp?.id ? `${temp.name} (Copy)` : '',
      }}
      validationSchema={Yup.object().shape({
        template: Yup.object().test('temp', 'Please choose template', function (item) {
          return item?.id && item?.id !== '';
        }),
        templateName: Yup.string().required('Please enter template name!'),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={false}
    >
      {(formProps) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formProps.submitForm();
              return false;
            }}
            noValidate
          >
            <Box>
              <Form.Field.AutoComplete
                multiple={false}
                fullWidth
                options={[]}
                variant="outlined"
                name="template"
                label="Choose Template"
                optLabel="name"
                optValue="id"
                remoteMethod={(val) => {
                  return fetchTemplatesLookup(val, queryType);
                }}
                onChange={(val) => {
                  formProps.setFieldValue('templateName', `${val.name} (Copy)`);
                }}
              />

              <FormHelperText>
                {queryType}
                This will copy steps, settings and email templates only and not contacts.
              </FormHelperText>

              <Form.Field.Input
                fullWidth
                variant="outlined"
                name="templateName"
                label="New template name"
                optLabel="value"
                optValue="id"
              />
            </Box>

            <Box mt={2} display="flex" justifyContent="flex-end" alignItems="flex-end">
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                loading={loading}
                disabled={loading}
              >
                Next
              </Button>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}

CloneTemplateForm.propTypes = {
  temp: PropTypes.object,
};

CloneTemplateForm.defaultProps = {
  // sequence: {},
};

export default CloneTemplateForm;
