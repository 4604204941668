import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Button, Spacer } from 'src/components/shared';
import { Typography, Box, FormHelperText, Divider } from '@mui/material';

function LookupForm({ option, putLookupList, onClose }) {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Form
        initialValues={{
          // label: option.label,
          value: option.value,
        }}
        validationSchema={Yup.object().shape({
          // label: Yup.string().required('Please enter Label.'),
          value: Yup.string().required('Please enter Value.'),
        })}
        onSubmit={async (values, form) => {
          try {
            setLoading(true);
            values.label = values.value;
            await putLookupList(option.id, values);
            setLoading(false);
            onClose();
            form.prevent;
          } catch (error) {}
        }}
      >
        {(props) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                props.submitForm();
                return false;
              }}
              noValidate
            >
              {/* <Form.Field.Input fullWidth variant="outlined" name="label" label="Label" /> */}
              <Form.Field.Input fullWidth variant="outlined" name="value" label="Value" />
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={loading}
                  fullWidth
                  size="large"
                >
                  Update
                </Button>
              </Box>
            </form>
          );
        }}
      </Form>
    </>
  );
}

export default LookupForm;
