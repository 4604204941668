import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Box, FormHelperText, Typography } from '@mui/material';
import { Form, Button } from 'src/components/shared';

import { fetchSequencesLookup } from '../../../api/sequenceApi';

function CloneSequenceForm({ sequence, disabled = false, ...props }) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    const data = JSON.parse(JSON.stringify(values));
    setLoading(true);
    const sequence = values.sequence;
    data.name = data.sequenceName;
    delete data.sequence;
    delete data.sequenceName;
    await props.cloneSequence(sequence.id, data);
    setLoading(false);
  };

  return (
    <Form
      initialValues={{
        sequence: sequence,
        sequenceName: sequence && sequence?.id ? `${sequence.name} (Copy)` : '',
      }}
      validationSchema={Yup.object().shape({
        sequence: Yup.object().test('sequence', 'Please choose sequence', function (item) {
          return item?.id && item?.id !== '';
        }),
        sequenceName: Yup.string().required('Please enter sequence name!'),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={false}
    >
      {(formProps) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formProps.submitForm();
              return false;
            }}
            noValidate
          >
            <Box>
              <Form.Field.AutoComplete
                multiple={false}
                fullWidth
                options={[]}
                variant="outlined"
                name="sequence"
                label="Choose Sequence"
                optLabel="name"
                optValue="id"
                disabled={disabled}
                remoteMethod={(val) => {
                  return fetchSequencesLookup(val);
                }}
                onChange={(val) => {
                  formProps.setFieldValue('sequenceName', `${val.name} (Copy)`);
                }}
              />

              <FormHelperText>
                This will copy steps, settings and email templates only and not contacts.
              </FormHelperText>

              <Form.Field.Input
                fullWidth
                variant="outlined"
                name="sequenceName"
                label="New Sequence name"
                optLabel="value"
                optValue="id"
              />
            </Box>

            <Box mt={2} display="flex" justifyContent="flex-end" alignItems="flex-end">
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                loading={loading}
                disabled={loading}
              >
                Next
              </Button>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}

CloneSequenceForm.propTypes = {
  sequence: PropTypes.object,
};

CloneSequenceForm.defaultProps = {
  // sequence: {},
};

export default CloneSequenceForm;
