import { yellow } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { themeColors } from 'src/modules/lms/config';

const useStyles = makeStyles(({ palette }) => ({
  cardContainer: {
    background: themeColors.bg,
  },
  root: {},
  heading: {
    fontWeight: '300',
    fontSize: '26px',
    color: themeColors.greyHeadingText,
  },
  CircleIcon: {
    fontSize: '10px !important',
    color: themeColors.greyParagraphText,
  },
  sessionBox: {
    marginBottom: '48px !important',
  },
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'inline-block',
    textDecoration: 'none',
    position: 'relative',
    width: '90%',
  },
  lastSection: {
    paddingRight: '92px',
  },
  links: {
    color: themeColors.greyText,
    fontWeight: '300',
    fontSize: '12px',
  },
  paragraph: {
    fontWeight: '400',
    fontSize: '18px',
    color: themeColors.greyParagraphText,
    lineHeight: '28px',
  },
  buttonHover: {
    background: themeColors.bg2,
    color: '#D2D2D2',
    padding: '8px 8px',
    borderRadius: '10px',
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // background: '#0C1721',

    '&:hover': {
      background: themeColors.bg2,
      color: 'white',
      '& .MuiCircularProgress-root': {
        color: 'white',
      },
    },
    '& .MuiCircularProgress-root': {
      color: '#FFD47E',
    },
  },
  columnTitle: {
    color: '##FFD47E',
    marginLeft: '14px',
    marginBottom: '6px',
  },
  backButton: {
    fontSize: '12px',
  },

  showguidedivicon: {
    marginLeft: '-13px',
    color: 'white',
  },
  column: {
    marginTop: '20px',
    paddingBottom: '8px',
    marginBottom: '20px',
  },
  bigColumn: {
    display: 'flex',
    flexDirection: 'column',
    padding: '14px',
  },
  shape: {
    background: themeColors.bg3,
    paddingTop: '10px',
    borderRadius: '5px',
  },
  alignment: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },

  tooltip: {
    '& title': {
      backgroundColor: 'yellow !important',
      color: 'yellow !important',
    },
    '& .makeStyles-tooltip-147 ': {
      backgroundColor: 'yellow !important',
    },
  },
  descriptionBox: {
    background: '#0C1721',
    borderRadius: '3px',
    padding: '20px 40px',
    '& div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed,  figure, figcaption, footer, header, hgroup,  menu, nav, output, ruby, section, summary, time, mark, audio, video':
      {
        margin: '0',
        // padding: '0',
        border: '0',
        // fontSize: '100%',
        // font: 'inherit',
        fontFamily: 'inherit',
        // color: 'white',
        verticalAlign: 'baseline',
      },
    '& h5': {
      color: 'rgba(0, 248, 172, 1) !important',
      opacity: '60%',
      display: 'flex',
      alignItems: 'center',
      fontSize: ' 22px',
      fontWeight: '700',
      lineHeight: '27px',
      letterSpacing: '0px',
      textAlign: 'left',
    },
    '& h1': {
      fontSize: '45px',
      fontWeight: '700',
      lineHeight: '54px',
      letterSpacing: '0px',
    },
    '& ul': {
      paddingLeft: '40px',
    },
    '& h5 strong': {
      color: 'rgba(0, 248, 172, 1) !important',
    },
    '& .svgh5': {
      marginRight: '12px',
      flex: 'none',
    },
  },
  videoList: {
    background: '#242729',
    borderRadius: '3px',
    marginTop: '-9px',
    zIndex: 1,
    display: 'flex',
    width: 'fit-content',
  },
  videoTitle: {
    color: themeColors.yellow,
    fontSize: '14px',
    width: '192px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  videoBox: {
    height: '108px',
    width: '192px',
    borderRadius: '3px',
  },
  playButton: {
    '& svg': {
      position: 'absolute',
      top: '30%',
      marginLeft: '42%',
    },
  },
  playSvg: {
    '& svg': {
      position: 'absolute',
      top: '30%',
      marginLeft: '42%',
    },
  },
  subTitle: {
    color: themeColors.grey,
    fontSize: '10px',
    textTransform: 'capitalize',
    width: '192px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  videolengthTitle: {
    color: themeColors.grey,
    fontSize: '14px',
    textTransform: 'capitalize',
    width: '192px',
    whiteSpace: 'nowrap',
    marginTop: '10px',
    paddingLeft: '16px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  videolengthicon: {
    color: themeColors.grey,
    fontSize: '14px',
    textTransform: 'capitalize',

    marginTop: '10px',
    position: 'absolute',
  },

  RoleplayContainer: {
    position: 'relative',
  },
  header_chip: {
    position: 'absolute',
    top: '-6px',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '30px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '17px',
    letterSpacing: '0px',
    textAlign: 'center',
    color: ' rgba(240, 66, 180, 1)',
    padding: '4px 10px',
    borderRadius: '30px',
    border: '1px solid rgba(217, 42, 157, 0.5)',
    background:
      'linear-gradient(0deg,rgba(217, 42, 157, 0.5),rgba(217, 42, 157, 0.5)),linear-gradient(0deg, rgba(217, 42, 157, 0.3), rgba(217, 42, 157, 0.3))',
  },
  videoFrameContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: 'fit-content',
    background: 'linear-gradient(104.75deg, #000000 -5.01%, #132B4E 134.12%), #FFFFFF',
    borderRadius: '10px',
    '&:focus-visible ': {
      outline: 'none !important',
    },
  },

  videoFrameBox: {
    width: '100%',
    height: '650px',
    display: 'flex',
    justifyContent: 'center',
    '& .iframe': {},
  },
  infoBtn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  infoSection: {
    backgroundColor: themeColors.bg2,
    padding: '12px 24px',
    borderRadius: '5px',
    color: themeColors.lightGrey,
  },
  closeIcon: {
    color: 'rgba(255, 255, 255, 0.54)',
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'right',
    width: '40px',
    height: '40px',
    padding: '6px',
    paddingRight: '12px',
  },
  extramargin: {
    marginTop: '14px!important',
  },
  noimagefoundcontainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: '#193247',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    width: '1106px',
    height: '343.664px',
    flexShrink: '0',
    borderRadius: '10px',
  },
  noimagefoundhead: {
    color: 'white',
    fontSize: 'normal',
    marginTop: '10px',
    fontFamily: 'inter',
  },
  sessionContainer: {
    background: themeColors.bg3,
    borderRadius: '3px',
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingTop: '24px',
  },
  Gridcompbinatiton: {
    background: '#0C1721',
    borderRadius: '6px',

    cursor: 'pointer',
    display: 'flex',
    padding: '6px 12px',
    marginLeft: '0px',
    alignItems: 'center',
    marginBottom: '10px',
    justifyContent: 'center',

    '& p': {
      opacity: '100%',
      display: 'flex',
      alignItems: 'center',
      fontSize: ' 10px',

      color: 'grey',

      flexshrink: '0',
      fontWeight: '300',
      lineHeight: 'normal',
      letterSpacing: '0px',
      textAlign: 'center',
      fontFeatureSettings: `'clig' off, 'liga' off`,
    },
    grids: {
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
}));

export default useStyles;
