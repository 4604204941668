import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { AppGrid } from 'src/components/App';
import { useResellerSummary } from '../../utils/resellerStore';
import { ButtonBase, Typography } from '@mui/material';

import { toggleContactDrawer } from 'src/modules/app/actions/appActions';
import { dateFormat } from 'src/config';
import moment from 'moment';
import { fetchUsersLookup } from 'src/modules/admin/api/adminApi';

function SequenceGrid() {
  const { referrals, fetchResellerSummary } = useResellerSummary();
  const dispatch = useDispatch();
  const { data, paging, loading, filters } = referrals;

  const referralFilters = [
    {
      key: 'enrolledBy',
      title: 'Assigned',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: true,
      searchable: true,
      isPrimary: true,
      remoteMethod: async (value) => {
        return fetchUsersLookup(value);
      },
    },
    {
      key: 'followup',
      title: 'Follow-up status',
      type: 'dropdown',
      cancellable: true,
      searchable: true,
      isPrimary: true,
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
    },
  ];

  const columns = useMemo(
    () => [
      {
        name: 'name',
        label: 'Referred Contact Name',
        options: {
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const row = data[dataIndex];
            return (
              <ButtonBase
                onClick={() => {
                  dispatch(toggleContactDrawer(row.id));
                }}
              >
                <Typography color="secondary" variant="body2">
                  {row?.fname || ''} {row?.lname || ''}
                </Typography>
              </ButtonBase>
            );
          },
        },
      },
      {
        name: 'assigned',
        label: 'Assigned to',
        options: {
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const row = data[dataIndex];
            const assignedTo = row.assignedTo;
            return (
              <Typography color="secondary" variant="body2">
                {assignedTo?.fname || ''} {assignedTo?.lname || ''}
              </Typography>
            );
          },
        },
      },
      {
        name: 'createdAt',
        label: 'Received on',
        options: {
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const row = data[dataIndex];
            return moment(row.createdAt).format(dateFormat);
          },
        },
      },
      {
        name: 'followUp',
        label: 'Follow-up Completed',
      },
      {
        name: 'sequence',
        label: 'Sequence Name',
      },
      {
        name: 'reseller',
        label: 'Reseller',
      },
    ],
    [data],
  );

  const handleTableChange = async ({ pageNo, perPage }) => {
    try {
      fetchResellerSummary(
        {
          pageNo,
          perPage,
        },
        referrals.filters,
      );
    } catch (error) {}
  };

  const onFilterChange = async (key, value) => {
    try {
      fetchResellerSummary(paging, {
        ...referrals.filters,
        [key]: value,
      });
    } catch (error) {}
  };

  return (
    <AppGrid
      columns={columns.map((col, index) => ({
        ...col,
        options: columns?.columns?.length
          ? { ...col.options, ...columns?.columns[index] }
          : col.options,
      }))}
      onTableChange={handleTableChange}
      filters={referralFilters}
      appliedFilters={filters}
      onFilterChange={onFilterChange}
      data={data}
      loading={loading}
      options={{
        serverSide: true,
        pagination: true,
        page: paging.pageNo,
        rowsPerPage: paging.perPage,
        count: paging.count,
      }}
    />
  );
}

export default SequenceGrid;
