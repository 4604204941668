import React from 'react';
import { Typography, Button, Stack, TextField } from '@mui/material';
import { Box } from '@mui/system';
import useStyles from './style';
import { Form } from 'src/components/shared';
import Chip from '@mui/material/Chip';
import { shareTemplate } from '../../api/LMSApis';

function Step3({ template }) {
  const classes = useStyles();
  const [textValue, setTextValue] = React.useState('');
  const [values, setValues] = React.useState([]);

  const chipDelete = (chipToDelete) => () => {
    setValues((oldValues) => oldValues.filter((chip) => chip !== chipToDelete));
  };

  const handleKeyPress = (event) => {
    const val = event.target.value;
    if (event.key === 'Enter') {
      setValues((oldValues) => [...oldValues, val]);
      setTextValue('');
    }
  };

  const onSubmit = async () => {
    await shareTemplate(
      {
        users: values,
      },
      template?.id,
    );
  };

  return (
    <>
      <Typography className={classes.subtitle}>
              Select which team members you’d like to share your script with: 
              </Typography>
      <Box className={classes.alignment}>
        <div>
          <TextField
            id="outlined-basic"
            label="Select"
            variant="outlined"
            fullWidth
            value={textValue}
            className={classes.Search}
            onChange={(e) => setTextValue(e.target.value)}
            onKeyPress={handleKeyPress}
          />

          <Stack direction="row" spacing={1}>
            {values &&
              values.map((btn, key) => (
                <Stack direction="row" spacing={2}>
                  <Chip className={classes.chip} label={btn} onDelete={chipDelete(btn)} />
                </Stack>
              ))}
          </Stack>

          <Box display={'flex'} justifyContent={'right'}>
            <Button className={`${classes.button} ${classes.btnAlignment}`} onClick={onSubmit}>
              Share
            </Button>
          </Box>
        </div>
      </Box>
    </>
  );
}

export default Step3;
