import { all, fork, put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import * as adminTypes from '../actions/adminTypes';
import * as adminActions from '../actions/adminActions';
import * as fieldApi from '../api/fieldApi';
import toast from 'src/utils/toast';
import { parseOptionsFromString } from '../utils/helper';

const getAllLookups = (state) => state.fields.lookups;
const getAllLookupLists = (state) => state.fields.lookupList;
const getCurrentRoute = (state) => state.router?.location;

function* fetchCentralLookups() {
  try {
    const data = yield call(fieldApi.fetchLookups);
    yield put(adminActions.setCentralLookups(data?.lookups));
    const currentRoute = yield select(getCurrentRoute);
    if (
      currentRoute?.pathname &&
      (currentRoute?.pathname == '/admin/fields/lookups/' ||
        currentRoute?.pathname == '/admin/fields/lookups') &&
      data?.lookups &&
      data?.lookups.length
    ) {
      yield put(replace(`/admin/fields/lookups/${data?.lookups[0].id}`));
    }
  } catch (error) {
    yield put(adminActions.setCentralLookups([]));
  }
}

function* fetchLookupList({ lookupId }) {
  try {
    const response = yield call(fieldApi.fetchLookupList, lookupId);
    yield put(adminActions.setLookupList(response.lookupList));
  } catch (error) {
    yield put(adminActions.setLookupList([]));
  }
}

function* postCentralLookup({ data, resolve, reject }) {
  try {
    const lookup = yield call(fieldApi.createLookup, {
      name: data.name,
      pluralName: data.name,
      singularName: data.name,
    });
    const options = parseOptionsFromString(data.options);
    yield call(fieldApi.addLookupOptionsBulk, lookup.lookup.id, options);
    const lookups = yield select(getAllLookups);
    yield put(adminActions.setCentralLookups([...lookups.data, lookup.lookup]));
    yield put(replace(`/admin/fields/lookups/${lookup.lookup.id}`));
    toast.success('New central lookup added successfully!');
    resolve(true);
  } catch (error) {
    toast.error(error?.error?.message || 'Error ocurred! Please try again.');
    reject(error);
  }
}

function* putLookupList({ itemId, data, resolve, reject }) {
  try {
    let response = yield call(fieldApi.updateLookupItem, itemId, data);
    response = response.lookupList;
    let lookups = yield select(getAllLookupLists);
    lookups = lookups.data;
    lookups = lookups.map((item) => {
      if (item.id === itemId) {
        return response;
        // return {
        //   ...item,
        //   label: response.label,
        //   value: response.value,
        //   enabled: response.enabled,
        // };
      }
      return item;
    });
    yield put(adminActions.setLookupList(lookups));
    toast.success(`Option updated!`);
    resolve(true);
  } catch (error) {
    toast.error(error?.error?.message || 'Error ocurred! Please try again.');
    reject(error);
  }
}

function* postLookupList({ optionId, data, resolve, reject }) {
  try {
    const options = parseOptionsFromString(data.options);
    const response = yield call(fieldApi.addLookupOptionsBulk, optionId, options);
    let lookups = yield select(getAllLookupLists);
    lookups = lookups.data;
    lookups = [...lookups, ...response.lookupList];
    yield put(adminActions.setLookupList(lookups));
    toast.success(`${response.lookupList.length} options added!`);
    resolve(true);
  } catch (error) {
    toast.error(error?.error?.message || 'Error ocurred! Please try again.');
    reject(error);
  }
}

function* deleteLookupOption({ optionId, resolve, reject }) {
  try {
    const response = yield call(fieldApi.deleteLookupOption, optionId);
    let lookups = yield select(getAllLookupLists);
    lookups = lookups.data.filter((item) => item.id !== optionId);
    yield put(adminActions.setLookupList(lookups));
    toast.success(`Option deleted successfully!`);
    resolve(true);
  } catch (error) {
    toast.error(error?.error?.message || 'Error ocurred! Please try again.');
    reject(error);
  }
}

function* putCentralLookup({ lookupId, data, resolve, reject }) {
  try {
    const response = yield call(fieldApi.updateLookup, lookupId, data);
    const updatedLookup = response.lookup[0];
    let lookups = yield select(getAllLookups);
    lookups = lookups.data.map((lookup) => {
      if (lookup.id === lookupId) {
        return {
          ...lookup,
          name: updatedLookup.name,
          pluralName: updatedLookup.pluralName,
          singularName: updatedLookup.singularName,
        };
      }
      return lookup;
    });
    yield put(adminActions.setCentralLookups(lookups));
    resolve(true);
  } catch (error) {
    toast.error(error?.error?.message || 'Error ocurred! Please try again.');
    reject(error);
  }
}

function* syncLookupWithCrm({ lookupId, resolve, reject }) {
  try {
    const res = yield call(fieldApi.lookupCrmSync, lookupId);
    yield put(adminActions.setLookupList(res.lookup.options));
    toast.success('Synced successfully.');
    resolve(true);
  } catch (error) {
    toast.error(error?.error?.message || 'Error ocurred! Please try again.');
    reject(error);
  }
}

export function* watchFieldsSaga() {
  yield takeLatest(adminTypes.FETCH_CENTRAL_LOOKUPS, fetchCentralLookups);
  yield takeLatest(adminTypes.POST_CENTRAL_LOOKUP, postCentralLookup);
  yield takeLatest(adminTypes.PUT_CENTRAL_LOOKUP, putCentralLookup);

  yield takeLatest(adminTypes.FETCH_LOOKUP_LIST, fetchLookupList);
  yield takeLatest(adminTypes.PUT_LOOKUP_LIST, putLookupList);
  yield takeLatest(adminTypes.POST_LOOKUP_LIST, postLookupList);
  yield takeLatest(adminTypes.DELETE_LOOKUP_LIST, deleteLookupOption);
  yield takeLatest(adminTypes.SYNC_LOOKUP_WITH_CRM, syncLookupWithCrm);
}

export default function* runSagas() {
  yield all([fork(watchFieldsSaga)]);
}
