import { all, fork, put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import * as tagTypes from '../actions/tagsTypes';
import * as tagActions from '../actions/tagsActions';
import * as tagApis from '../api/tagsApi';

function* fetchTags() {
  try {
    const tags = yield call(tagApis.fetchTags);
    yield put(tagActions.setTags({ ...tags }));
  } catch (error) {
    yield put(tagActions.setTags(error));
  }
}

function* updateTagStatus({ rowData }) {
  try {
    const updatedRow = yield call(tagApis.updateTagStatus, rowData);
    yield put(tagActions.setTagStatus(updatedRow));
  } catch (error) {
    yield put(tagActions.setTagStatus(error));
  }
}

export function* watchSagas() {
  yield takeLatest(tagTypes.FETCH_ADMIN_TAGS, fetchTags);
  yield takeLatest(tagTypes.UPDATE_TAG_STATUS, updateTagStatus);
}

export default function* runSagas() {
  yield all([fork(watchSagas)]);
}
