import React from 'react';
import ReportCard from '../Dashboard/ReportCard';
import { Grid } from '@mui/material';
import { useResellerSummary } from '../../utils/resellerStore';

function ReferralSummary() {
  const { referralSummary } = useResellerSummary();
  const { cards } = referralSummary;

  return (
    <Grid container spacing={2}>
      <ReportCard
        type="text"
        loading={referralSummary?.loading || false}
        fill="rgba(255, 171, 0, 1)"
        title="Referrals Received"
        value={cards?.total || 0}
      />
      <ReportCard
        fill="#1976D2"
        title="Referrals Sequenced"
        loading={referralSummary?.loading || false}
        value={cards?.sequenced || 0}
        percentage={cards?.total ? Math.ceil((cards?.sequenced * 100) / cards?.total) : 0}
      />
      <ReportCard
        fill="#00897B"
        title="Not Sequenced"
        loading={referralSummary?.loading || false}
        value={cards?.notSequenced || 0}
        percentage={cards?.total ? Math.ceil((cards?.notSequenced * 100) / cards?.total) : 0}
      />
    </Grid>
  );

  return <div>ReferralSummary</div>;
}

export default ReferralSummary;
