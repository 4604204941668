import { styled } from '@mui/system';
import { Paper } from '@mui/material';

export const ActivitiesWrapper = styled(Paper)(({ theme }) => ({
  padding: 16,
}));

export const ActivityItemWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  '& .overflow-ellipsis': {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    whiteSpace: 'pre-wrap',
  },
}));

export const ActivityIcon = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#E3F2FD',
  width: 32,
  height: 32,
  borderRadius: 4,
  marginLeft: 6,
  position: 'relative',
  '&::before': {
    display: 'block',
    content: '""',
    width: 8,
    height: 13,
    background: '#E3F2FD',
    position: 'absolute',
    left: '-6px',
    zIndex: 0,
    transform: 'translate(-50%, -50%)',
    borderRadius: '6px 0 0 6px',
    top: 16,
    borderRight: '2px solid #ffffff',
  },
  '&::after': {
    display: 'block',
    content: '""',
    width: 8,
    height: 13,
    background: '#E3F2FD',
    position: 'absolute',
    right: '-16px',
    zIndex: 0,
    transform: 'translate(-50%, -50%)',
    borderRadius: '0 6px 6px 0',
    top: 16,
    borderLeft: '2px solid #ffffff',
  },
}));

export const ActivityIconWrapper = styled('div')(({ theme }) => ({
  width: 44,
  height: 44,
  marginRight: 20,
  marginLeft: 4,
  marginTop: 4,
}));
