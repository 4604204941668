import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormControl, TextField, CircularProgress, Autocomplete } from '@mui/material';
import { fetchTasks } from 'src/modules/tasks/actions/taskActions';
import api from 'src/api';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';
import Contact from './Contact';
import Sequence from './Sequence';
import Template from './Template';
import useStyles from './styles';

function GlobalSearch({ tasks, ...props }) {
  const [options, setOptions] = useState([]);
  const [defaultVal, setDefaultVal] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [page, setPage] = useState(0);
  const perPage = 25;
  const history = useHistory();
  const classes = useStyles();

  const onType = useCallback(
    debounce(async (e) => {
      try {
        const { value } = e.target;
        if (value && value !== '' && value.length > 3) {
          setIsEmpty(false);
          setSearch(value);
          setOptions([]);
          setLoading(true);
          setDefaultVal({
            name: value,
            id: 0,
          });
          const data = await getRemoteData(value);
          if (data.length == 0) {
            setIsEmpty(true);
          }
          setOptions(data);
          setLoading(false);
        }
      } catch (error) {}
    }, 300),
    [],
  );

  const getRemoteData = async (val) => {
    return new Promise(async (resolve, reject) => {
      try {
        setHasMoreResults(false);
        const _from = page * perPage;
        const response = await api(
          `/actions/globalsearch?_search=${val}&_from=${_from}&_size=${perPage}`,
          null,
          'get',
        );
        if (response.results.length == perPage) {
          setPage(page + 1);
          setHasMoreResults(true);
        }
        resolve(response.results);
      } catch (error) {
        reject(error);
      }
    });
  };

  const loadMoreResults = async () => {
    if (hasMoreResults) {
      setLoading(true);
      const response = await getRemoteData(search);
      setOptions([...options, ...response]);
      setLoading(false);
    }
  };

  const handleClick = (option) => {
    if (option.itemType == 'contact') {
      history.push(`/contact/${option.id}`);
    } else if (option.itemType == 'sequence') {
      history.push(`/sequence/${option.id}`);
    }
  };
  const handleTaskOpen = (option, tabType) => {
    if (option.itemType === 'contact') {
      option.name = `${option.fname} ${option.lname} ${option.jobTitle}`;
      props.fetchTasks(
        tasks.paging,
        { ...tasks.filters, contact: option },
        tasks.sort,
        tabType,
        'all',
      );
      history.push(`/tasks/${tabType}`);
      // history.push(`/tasks/${tabType}/${option.id}`);
    } else if (option.itemType == 'sequence') {
      history.push(`/sequence/${option.id}`);
    }
  };

  const onClose = () => {
    setOptions([]);
    setDefaultVal({});
    setSearch('');
  };

  const optLabel = 'name';
  const optValue = 'id';

  return (
    <FormControl fullWidth={true} margin="normal" className={classes.globalSearchRoot}>
      <Autocomplete
        multiple={false}
        noOptionsText={
          loading ? 'Please wait...' : isEmpty ? 'No results found!' : 'Start typing...'
        }
        value={defaultVal}
        disabled={false}
        options={options}
        getOptionLabel={(option) => search || ''}
        isOptionEqualToValue={(option, value) => {
          return option[optValue] === value[optValue];
        }}
        classes={{
          popper: classes.globalSearchPopper,
          listbox: classes.globalSearchListBox,
        }}
        renderOption={(option, data) => {
          switch (data.itemType) {
            case 'sequence':
              return (
                <Sequence
                  key={`global-search-sequence-${data.id}`}
                  option={option}
                  data={data}
                  handleClick={handleClick}
                  onClose={onClose}
                />
              );
              break;

            case 'template':
              return (
                <Template
                  key={`global-search-template-${data.id}`}
                  option={option}
                  data={data}
                  handleClick={handleClick}
                  onClose={onClose}
                />
              );
              break;

            case 'contact':
              return (
                <Contact
                  key={`global-search-contact-${data.id}`}
                  option={option}
                  data={data}
                  handleClick={handleClick}
                  handleTaskOpen={handleTaskOpen}
                  onClose={onClose}
                />
              );
              break;
          }
        }}
        onClose={onClose}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        ListboxProps={{
          onScroll: (event) => {
            const listBoxNode = event.currentTarget;
            if (listBoxNode.scrollTop + listBoxNode.clientHeight === listBoxNode.scrollHeight) {
              loadMoreResults();
            }
          },
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              value={search}
              placeholder="Search"
              variant="outlined"
              fullWidth={true}
              onChange={(e) => {
                e.persist();
                onType(e);
              }}
              disabled={false}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <React.Fragment>
                    <SearchIcon />
                  </React.Fragment>
                ),
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  </React.Fragment>
                ),
                classes: { notchedOutline: classes.globalSearchNoBorder },
              }}
            />
          );
        }}
        name={'globalSearch'}
      />
    </FormControl>
  );
}

const mapStateToProps = (state) => ({
  tasks: state.tasks.tasks,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTasks: (paging, filters, sort, type, currentTab) =>
    dispatch(fetchTasks(paging, filters, sort, type, currentTab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearch);
