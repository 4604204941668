import React from 'react';
import { connect } from 'react-redux';

import CreateNotes from '../components/CreateNotes/CreateNote';

const LMSCreateNotesContainer = (props) => {
  const noteId = props.match.params.id;
  return (
    <>
      <CreateNotes noteId={noteId} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LMSCreateNotesContainer);
