import Contacts from '../container/contacts';
import ContactDetails from '../container/contactDetails';
import ContactUnsubscribe from '../container/contactUnsubscribe';

export default [
  {
    title: 'Contacts',
    component: Contacts,
    url: '/contacts',
    exact: true,
    auth: true,
  },
  {
    title: 'Contact Details',
    component: ContactDetails,
    url: '/contact/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'Contact Details',
    component: ContactUnsubscribe,
    url: '/contact/:id/unsubscribe',
    exact: true,
    auth: true,
  },
];
