import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

function CardItem({ data, title, color }) {
  if (!data || isEmpty(data)) return null;
  const { value, progress } = data;
  const progressData = [
    {
      name: '',
      value: 100,
    },
    {
      name: 'Progress',
      value: parseInt(progress),
    },
  ];

  return (
    <Grid item xs={4} md={2}>
      <Card sx={{ backgroundColor: color }}>
        <CardContent
          sx={{
            padding: '8px',
            paddingBottom: '8px !important',
            height: '100px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
          }}
        >
          <Grid container spacing={1}>
            {progress && typeof progress !== 'undefined' ? (
              <Grid
                item
                xs={6}
                key={`title-${progress}`}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <ResponsiveContainer width={60} height={60}>
                  <PieChart width={60} height={60}>
                    <Pie
                      data={progressData}
                      dataKey="value"
                      cx="50%"
                      cy="50%"
                      innerRadius={20}
                      outerRadius={28}
                      labelLine={false}
                      fill="#00000040"
                      label={false}
                      startAngle={90}
                      endAngle={-270}
                      stroke="none"
                    >
                      {progressData
                        .filter((item) => item.name !== 'Progress')
                        .map((entry, index) => (
                          <>
                            <Cell key={`cell-${index}`} fill="#ffffff25" />
                          </>
                        ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
            ) : null}

            <Grid
              item
              xs={progress && typeof progress !== 'undefined' ? 6 : 12}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant="h1"
                sx={{ color: '#ffffff', fontSize: '32px', fontWeight: 400, lineHeight: '38px' }}
              >
                {value}
              </Typography>
              {progress && typeof progress !== 'undefined' && (
                <Typography variant="body2" sx={{ color: '#ffffff', opacity: 0.6 }}>
                  {progress}%
                </Typography>
              )}
            </Grid>
          </Grid>

          <Typography
            variant="body2"
            textAlign="center"
            sx={{ color: '#ffffff', fontWeight: '400' }}
          >
            {title}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default CardItem;
