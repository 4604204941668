import { makeStyles } from '@mui/styles';
import { themeColors } from 'src/modules/lms/config';

const useStyles = makeStyles(({ custom, transitions, palette, ...theme }) => {
  return {
    container: {
      marginBottom: '47px',

      '& button': {
        color: '#7F7F7F',
        background: 'transparent',
        border: '1px solid #7F7F7F',
        fontWeight: '400',
        fontSize: '12px',
        marginRight: '10px',
        textTransform:'none',
        marginBottom:'1%',
        height:'28px'
      },
    },
    title: {
      color: themeColors.white,
      fontWeight: '400',
      fontSize: '26px',
    },

    description: {
      fontWeight: '300',
      fontSize: '16px',
      lineHeight: '26px',
      color: 'rgba(210, 210, 210, 1)',
    },
    filterContainer: {
      border: '1px solid rgba(239, 239, 239, 0.23)',
      height: '46px',
      borderRadius: '6px',
      color: '#E3E3E3 !important',
    },
    filterLabel: {
      color: '#E3E3E3 !important',
      fontWeight: '400',
      fontSize: '14px',
    },
    filter: {
      '& svg': {
        color: '#E3E3E3 !important',
      },
      '& label': {
        color: '#E3E3E3 !important',
      },
      '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
        height: '50px',
      },
      '& .css-1iledgx-MuiFormControl-root': {
        margin: '2%', 
      }, 
    },
    searchText: {
      fontWeight: '500',
      fontSize: '18px',
      color: '#CCCCCC',
      marginBottom: '24px',
    },
    searchInput: {
      height: '48px',
      borderRadius: 10,
      '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
        fontWeight: '500 !important',
        fontSize: '18px !important',
        color: '#CCCCCC !important',
        borderRadius: '10px !important',
      },
      '& label': {
        fontWeight: '500',
        fontSize: '18px',
        color: '#CCCCCC !important',
      },
      '& .css-foqa0v-MuiInputBase-root-MuiOutlinedInput-root': {
        paddingRight: '0px',
      },
    },
    SearchBox: {
      background: '#193247',
      opacity: '0.6',
      borderRadius: '10px',
      color: '#FFFFFF !important',
      '& svg ': {
        color: '#FFFFFF',
      },
    },
    category: {
      color: '##FFD47E!important',
      fontWeight: '400',
      fontSize: '12px',
      marginTop: '10px',
      display: 'block !important',
      width: '10%',
      textAlign: 'center',
    },
  };
});

export default useStyles;
