import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Typography, TextField, IconButton } from '@mui/material';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import images from 'src/config/images';
import { themeColors } from 'src/modules/lms/config';
import useStyles from './style';
import SessionListingBig from '../../../../shared/sessionListingBig/SessionListingBig';
import { CoverButtonSection } from '../../../CoverButtonSection/CoverButtonSection';
import TwoImageSideSection from '../../../TwoImageSideSection';
import SearchIcon from '@mui/icons-material/Search';
import ExpertSection from '../../../../shared/expertSection/ExpertSection';
import PhilosophySection from 'src/modules/lms/shared/philosophySection/PhilosophySection';
import PageDetailSkeleton from 'src/modules/lms/shared/pageDetailSkeleton/PageDetailSkeleton';
import CloseIcon from '@mui/icons-material/Close';
import { Desc } from './style';

export const CourseDetailsSection = ({ courseData, loading, ...props }) => {
  const currentUrl = props?.location?.pathname;
  const classes = useStyles();
  useEffect(() => {
    setData(courseData?.sessions);
  }, [courseData.sessions]);
  const breadcrumbs = [
    <Link underline="hover" key="1" className={classes.links} to="/lms">
      SuperReach Learning
    </Link>,
    <Link underline="hover" key="2" className={classes.links} to={currentUrl ? currentUrl : '/lms'}>
      {courseData?.title}
    </Link>,
  ];
  const [inputHide, setInputHide] = React.useState('none');
  const [iconHide, setIconHide] = React.useState('block');
  const InputField = () => {
    setInputHide('block');
    setIconHide('hidden');
  };
  const [data, setData] = React.useState(courseData.sessions);
  const [searchKeyword, setSearchData] = React.useState('');

  const handleKeyupSearch = (evt) => {
    const searchValue = evt.target.value;
    setSearchData(searchValue);
    let filteredData = [];
    if (!searchValue) {
      filteredData = courseData?.sessions;
    } else {
      filteredData = courseData?.sessions.filter((item) => {
        return item?.title.toLowerCase().includes(searchValue.toLowerCase());
      });
    }
    setData(filteredData);
  };
  const onClose = (evt) => {
    const searchValue = evt.target.value;
    setSearchData(searchValue);
    let filteredData = [];
    if (!searchValue) {
      filteredData = courseData?.sessions;
    } else {
      filteredData = courseData?.sessions.filter((item) => {
        return item?.title.toLowerCase().includes(searchValue.toLowerCase());
      });
    }
    setData(filteredData);
    setSearchData('');
    handleKeyupSearch;
  };

  let synopsisContent = courseData?.synopsis ? courseData.synopsis : '';

  synopsisContent = synopsisContent ? synopsisContent.replace(/<ul>/g, '<ul>') : '';
  if (loading) return <PageDetailSkeleton />;

  return (
    <Box>
      <Grid container spacing={2} mb={5}>
        <Grid item md={12} lg={9}>
          <Box mb={2}>
            <Breadcrumbs
              separator={
                <NavigateNextIcon fontSize="small" style={{ color: themeColors.greyText }} />
              }
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Box>
          <Box mb={3}>
            <Typography
              className={classes.courseTitle}
              fontSize={40}
              fontWeight={400}
              style={{ color: '#D2D2D2' }}
            >
              {courseData?.title}
            </Typography>
          </Box>

          {courseData.overviewText && courseData.overviewText !== '' && (
            <Desc dangerouslySetInnerHTML={{ __html: courseData.overviewText }} />
          )}

          <Box mb={7} sx={{ color: themeColors.greyText }} className={classes.descriptionBox}>
            {parse(synopsisContent)}
          </Box>

          {courseData?.sessions?.filter((k) => k.published).length ? (
            <Box sx={{ background: themeColors.bg3, padding: '15px', borderRadius: '3px' }} mb={3}>
              <SessionListingBig
                itemData={data}
                searchKeyword={searchKeyword}
                title={
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography className={classes.title} mb={3}>
                      The Sessions
                    </Typography>
                    <SearchIcon
                      className={classes.searchIcon}
                      visibility={iconHide}
                      onClick={InputField}
                    />
                    <Box display={inputHide}>
                      <TextField
                        name="search"
                        variant="outlined"
                        fullWidth
                        label="Search"
                        className={classes.Search}
                        onKeyUp={handleKeyupSearch}
                        display={inputHide}
                        onChange={(newValue) => {
                          setSearchData(newValue.target.value);
                        }}
                        value={searchKeyword}
                        InputProps={{
                          endAdornment: (
                            <React.Fragment>
                              {searchKeyword ? (
                                <IconButton onClick={onClose}>
                                  <CloseIcon style={{ color: 'white' }} />
                                </IconButton>
                              ) : null}
                            </React.Fragment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                }
                from={{
                  name: courseData?.title,
                  id: courseData?.id,
                  contentType: courseData?.contentType,
                }}
              />
            </Box>
          ) : null}
        </Grid>

        <Grid item md={12} lg={3}>
          {courseData?.otherCourses?.filter((k) => k.published).length ? (
            <Box className={classes.imageSection}>
              <TwoImageSideSection data={courseData?.otherCourses} text={'Explore other courses'} />
            </Box>
          ) : null}
          {courseData?.expert?.filter((k) => k.published).length ? (
            <ExpertSection text={'Meet the Experts'} expertData={courseData?.expert} />
          ) : null}
          <Grid mt={3}></Grid>
          {courseData?.tags?.length ? (
            <Box className={classes.buttonSection}>
              <CoverButtonSection text="Course covers" tags={courseData?.tags} />
            </Box>
          ) : null}
        </Grid>
      </Grid>
      {courseData?.philosophyImageUrl ? (
        <PhilosophySection imageUrl={courseData?.philosophyImageUrl} />
      ) : null}
    </Box>
  );
};

export default CourseDetailsSection;
