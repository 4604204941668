import { isEmpty } from 'lodash';
import moment from 'moment';
import {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import api from 'src/api';
import { getRelativeDateRange } from 'src/utils/dateUtils';
import { convertObjectToQuerystring } from 'src/utils/helper';
const dashContext = createContext({});
import toast from 'src/utils/toast';

import usePersistState from 'src/components/hooks/usePersistState';

export const useDashboardStore = () => {
  const {
    filters,
    setFilters,
    fetchSequenceReport,
    fetchSequenceStats,
    fetchUserStats,
    sequenceReport,
    loading,
    sequenceStats,
    userStats,
  } = useContext(dashContext);
  return {
    filters,
    setFilters,
    fetchSequenceReport,
    fetchSequenceStats,
    fetchUserStats,
    sequenceReport,
    loading,
    sequenceStats,
    userStats,
  };
};

export const DashboardProvider = ({ children }) => {
  const [loading, setLoading] = useState({
    sequenceReport: false,
    sequenceStats: false,
    userStats: false,
  });
  const [sequenceReport, setSequenceReport] = useState({});
  const [filters, setFilters] = usePersistState('seqDashboardFilters', {
    createdAt: { id: 'thisMonth', name: 'This month' },
  });
  const [sequenceStats, setSequenceStats] = useState({
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    data: [],
    sort: {},
  });

  const [userStats, setUserStats] = useState({
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    data: [],
  });

  const getFilter = () => {
    const createdAt = filters.createdAt;
    let filter = {};
    if (createdAt && !isEmpty(createdAt)) {
      const dateRange = getRelativeDateRange(createdAt);
      filter = {
        createdAt_gte: dateRange.start,
        createdAt_lte: dateRange.end,
      };
    }
    return filter;
  };

  const fetchSequenceStats = async (newPage, sort) => {
    try {
      setLoading({
        ...loading,
        sequenceStats: true,
      });
      setSequenceStats((prevState) => ({
        ...prevState,
        data: [],
        paging: {
          ...prevState.paging,
          pageNo: newPage.pageNo,
          perPage: newPage.perPage,
        },
        sort,
      }));

      let filter = {
        _from: newPage.pageNo * newPage.perPage,
        _size: newPage.perPage,
        ...getFilter(),
      };

      if (sort && !isEmpty(sort)) {
        filter._sort = `${sort.name}:${sort.direction}`;
      }

      const query = convertObjectToQuerystring(filter);
      const response = await api(`sequence/dashboardStats?${query}`, null, 'get');
      setLoading({
        ...loading,
        sequenceStats: false,
      });

      setSequenceStats((prevState) => ({
        ...prevState,
        data: response.sequences,
        paging: {
          pageNo: newPage.pageNo,
          perPage: newPage.perPage,
          count: response.total.value,
        },
      }));
    } catch (error) {
      setLoading({
        ...loading,
        sequenceStats: false,
      });
      setSequenceStats({
        ...sequenceStats,
        data: [],
        paging: {
          ...sequenceStats.paging,
          count: 0,
        },
      });
    }
  };

  const fetchUserStats = async (newPage) => {
    try {
      setLoading({
        ...loading,
        userStats: true,
      });
      setUserStats({
        ...userStats,
        paging: {
          ...userStats.paging,
          pageNo: newPage.pageNo,
          perPage: newPage.perPage,
        },
      });
      let filter = {
        _from: newPage.pageNo * newPage.perPage,
        _size: newPage.perPage,
        ...getFilter(),
      };
      const query = convertObjectToQuerystring(filter);
      const response = await api(`user/dashboardStats?${query}`, null, 'get');

      setLoading({
        ...loading,
        userStats: false,
      });
      setUserStats({
        ...userStats,
        data: response.users,
        paging: {
          pageNo: newPage.pageNo,
          perPage: newPage.perPage,
          count: response.total.value,
        },
      });
    } catch (error) {
      setLoading({
        ...loading,
        userStats: false,
      });
      setUserStats({
        ...userStats,
        data: [],
        paging: {
          ...userStats.paging,
          count: 0,
        },
      });
    }
  };

  useEffect(() => {
    // fetchSequenceStats(sequenceStats.paging);
    // fetchUserStats(userStats.paging);
    fetchSequenceReport();
  }, [filters]);

  const fetchSequenceReport = async () => {
    try {
      setLoading({
        ...loading,
        // sequenceReport: true,
      });
      let filter = getFilter();
      let query = '';
      if (filter && !isEmpty(filter)) query = convertObjectToQuerystring(filter);
      const response = await api(`board/sequenceReport?${query}`, null, 'get');
      setSequenceReport(response);
      setLoading({
        ...loading,
        sequenceReport: false,
      });
    } catch (error) {
      setSequenceReport({});
      setLoading({
        ...loading,
        sequenceReport: false,
      });
    }
  };

  return (
    <dashContext.Provider
      value={{
        filters,
        setFilters,
        sequenceReport,
        sequenceStats,
        userStats,
        loading,
        fetchSequenceReport,
        fetchSequenceStats,
        fetchUserStats,
      }}
    >
      {children}
    </dashContext.Provider>
  );
};

export const withDashboardProvider = (Component) => (props) =>
  (
    <DashboardProvider>
      <Component {...props} />
    </DashboardProvider>
  );
