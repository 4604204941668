import React, { useEffect } from 'react';
import TasksGrid from '../../components/Tasks/TasksGrid';
import ErrorsGrid from '../../components/Tasks/ErrorsGrid';
import EnrolmentsGrid from '../../components/Enrolments/EnrolmentsGrid';

function TasksView({ tasks, ...props }) {
  const { paging, filters, sort, taskErrors } = tasks;
  const type = props?.match?.params?.type || 'all';

  useEffect(() => {
    if (type !== 'errors') {
      fetchTasks(paging, filters, sort, type);
    }
  }, [type]);

  const fetchTasks = (paging, filters, sort, tab) => {
    if (!filters?.status && !filters?.status?.id) {
      props.fetchTasks(
        paging,
        {
          ...filters,
          status: { id: 'due', name: 'Due' },
        },
        sort,
        tab,
      );
    } else {
      props.fetchTasks(paging, filters, sort, tab);
    }
  };

  return (
    <>
      {type === 'errors' ? (
        <ErrorsGrid {...props} tab={type} />
      ) : type === 'drafts' ? (
        <EnrolmentsGrid {...props} tab={type} />
      ) : (
        <TasksGrid tasks={tasks} {...props} tab={type} />
      )}
    </>
  );
}

export default TasksView;
