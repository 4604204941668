import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import useStyles from './style';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import CreateNoteForm from './CreateNoteForm';

function CreateNote({ handleNoteUpdate, note, open = false, handleClose = () => {},...props}) {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.Preview + ' Inter-font-family'}>
        <Box display={'flex'} justifyContent={'space-between'} mt={2} ml={3} mr={3} mb={3}>
          <Typography className={classes.title}>{note ? 'Edit a note ' : ' Add a note'}</Typography>
          <CloseIcon onClick={handleClose} className={classes.close} />
        </Box>
        <hr className={classes.lineColor} />
        <Box ml={3} mr={3}>
          <CreateNoteForm
            handleNoteUpdate={handleNoteUpdate}
            note={note}
            handleClose={handleClose}
            {...props}
          />
        </Box>
      </Box>
    </Modal>
  );
}

export default CreateNote;
