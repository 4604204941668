import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#25435D',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#25435D',
    color: '#fff',
  },
}));

export default CustomTooltip;
