import * as resellerTypes from './resellerTypes';

// RESELLER AUTOMATIONS ACTIONS

export const fetchAutomations = (filters, paging) => ({
  type: resellerTypes.FETCH_AUTOMATIONS,
  filters,
  paging,
});

export const setResellerFilters = (filters) => ({
  type: resellerTypes.SET_RESELLER_FILTERS,
  filters,
});

export const setAutomations = (data) => ({
  type: resellerTypes.SET_AUTOMATIONS,
  data,
});

export const postAutomation = (payload, resolve, reject) => ({
  type: resellerTypes.POST_AUTOMATION,
  payload,
  resolve,
  reject,
});

export const putAutomation = (automationId, payload, resolve, reject) => ({
  type: resellerTypes.PUT_AUTOMATION,
  automationId,
  payload,
  resolve,
  reject,
});

export const deleteAutomation = (automationId, resolve, reject) => ({
  type: resellerTypes.DELETE_AUTOMATION,
  automationId,
  resolve,
  reject,
});

// RESELLER REPORTS ACTIONS

export const fetchResellerReport = (filters, paging, resellerFilters) => ({
  type: resellerTypes.FETCH_RESELLER_REPORT,
  filters,
  paging,
  resellerFilters,
});

export const fetchResellerTenantReport = (tenantId, filters, paging, resellerFilters) => ({
  type: resellerTypes.FETCH_RESELLER_TENANT_REPORT,
  tenantId,
  filters,
  paging,
  resellerFilters,
});

export const fetchResellerUserReport = (tenantId, userId, filters, paging, resellerFilters) => ({
  type: resellerTypes.FETCH_RESELLER_USER_REPORT,
  tenantId,
  userId,
  filters,
  paging,
  resellerFilters,
});

export const setResellerReport = (data) => ({
  type: resellerTypes.SET_RESELLER_REPORT,
  data,
});

export const setResellerTenantReport = (data) => ({
  type: resellerTypes.SET_RESELLER_TENANT_REPORT,
  data,
});

export const setResellerUserReport = (data) => ({
  type: resellerTypes.SET_RESELLER_USER_REPORT,
  data,
});

// // // // // //

export const fetchResellerReportSummary = (resellerFilters) => ({
  type: resellerTypes.FETCH_RESELLER_REPORT_SUMMARY,
  resellerFilters,
});

export const fetchResellerTenantReportSummary = (tenantId, resellerFilters) => ({
  type: resellerTypes.FETCH_RESELLER_TENANT_REPORT_SUMMARY,
  tenantId,
  resellerFilters,
});

export const fetchResellerUserReportSummary = (tenantId, userId, resellerFilters) => ({
  type: resellerTypes.FETCH_RESELLER_USER_REPORT_SUMMARY,
  tenantId,
  userId,
  resellerFilters,
});

export const setResellerReportSummary = (data) => ({
  type: resellerTypes.SET_RESELLER_REPORT_SUMMARY,
  data,
});

export const setResellerTenantReportSummary = (data) => ({
  type: resellerTypes.SET_RESELLER_TENANT_REPORT_SUMMARY,
  data,
});

export const setResellerUserReportSummary = (data) => ({
  type: resellerTypes.SET_RESELLER_USER_REPORT_SUMMARY,
  data,
});
