import * as types from '../actions/appTypes';
import { RESET_STORE } from 'src/modules/app/actions/appTypes';

const initialState = {
  loading: {
    reportsSummary: false,
    activityReport: false,
  },
  reportsSummary: {
    filter: {},
    sequence: {},
    task: {
      completedTasks: {
        sms: 0,
        callBacks: 0,
        emails: 0,
        general: 0,
        linkedIn: 0,
        total: 204,
      },
      inprogressTasks: 0,
      openTasks: 0,
      overdueTasks: 0,
      pendingTasks: 0,
      totalTasks: 0,
    },
    contact: {
      totalContacts: 0,
      totalEnrollments: 0,
    },
    email: {
      clicked: 0,
      opened: 0,
      replied: 0,
      sent: 0,
    },
  },
  activityReport: {
    paging: {
      pageNo: 0,
      perPage: 20,
      count: 0,
    },
    filters: {},
    data: [],
    sort: {},
  },
};

const ReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ACTIVITY_REPORT:
      return {
        ...state,
        loading: {
          activityReport: true,
        },
        activityReport: {
          ...state.activityReport,
          paging: {
            ...state.activityReport.paging,
            ...action.paging,
          },
          data: [],
          filters: {
            ...state.activityReport.filters,
            ...action.filters,
          },
        },
      };

    case types.SET_ACTIVITY_REPORT:
      return {
        ...state,
        loading: {
          ...state.loading,
          activityReport: false,
        },
        activityReport: {
          ...state.activityReport,
          paging: {
            ...state.activityReport.paging,
            total: action.total,
          },
          data: action.data,
        },
      };

    case types.FETCH_REPORT_SUMMARY:
      return {
        ...state,
        loading: {
          reportsSummary: true,
        },
        reportsSummary: {
          ...state.reportsSummary,
          filter: {
            ...state.reportsSummary.filter,
            ...action.filter,
          },
        },
      };

    case types.SET_REPORT_SUMMARY:
      return {
        ...state,
        loading: {
          reportsSummary: false,
        },
        reportsSummary: {
          ...state.reportsSummary,
          ...action.data,
        },
      };

    default:
      return state;
  }
};
export default ReportsReducer;
