export const SET_SEQUENCES_LOADING = 'SET_SEQUENCES_LOADING';
export const CLEAR_SEQUENCE_DATA = 'CLEAR_SEQUENCE_DATA';

export const SET_SEQUENCE_TAB = 'SET_SEQUENCE_TAB';

export const FETCH_SEQUENCES = 'FETCH_SEQUENCES';
export const SET_SEQUENCES = 'SET_SEQUENCES';
export const CLONE_SEQUENCE = 'CLONE_SEQUENCE';
export const POST_SEQUENCE = 'POST_SEQUENCE';
export const PUT_SEQUENCE = 'PUT_SEQUENCE';
export const PATCH_SEQUENCE = 'PATCH_SEQUENCE';
export const DELETE_SEQUENCE = 'DELETE_SEQUENCE';

export const FETCH_SEQUENCE = 'FETCH_SEQUENCE';
export const SET_SEQUENCE = 'SET_SEQUENCE';

export const FETCH_SEQUENCE_CONTACTS = 'FETCH_SEQUENCE_CONTACTS';
export const SET_SEQUENCE_CONTACTS = 'SET_SEQUENCE_CONTACTS';

export const HANDLE_EVENTS_REORDER = 'HANDLE_EVENTS_REORDER';
export const POST_SEQUENCE_EVENT = 'POST_SEQUENCE_EVENT';
export const PUT_SEQUENCE_EVENT = 'PUT_SEQUENCE_EVENT';
export const DELETE_SEQUENCE_EVENT = 'DELETE_SEQUENCE_EVENT';
export const FETCH_SEQUENCE_EVENTS = 'FETCH_SEQUENCE_EVENTS';
export const SET_SEQUENCE_EVENTS = 'SET_SEQUENCE_EVENTS';

export const SEQUENCE_BULK_ACTIONS = 'SEQUENCE_BULK_ACTIONS';

export const FETCH_SEQUENCES_LIST = 'GET_SEQUENCES_LIST';
export const SET_SEQUENCES_LIST = 'SET_SEQUENCES_LIST';
export const IS_UNSUBSCRIBE_CHECK = 'IS_UNSUBSCRIBE_CHECK';
export const IS_UNSUBSCRIBE_SET = 'IS_UNSUBSCRIBE_SET';
export const GET_SEQUENCES_STEPS = 'GET_SEQUENCES_STEPS';
export const GET_JOB_SPEC_TEMPLATE = 'GET_JOB_SPEC_TEMPLATE';
export const GET_CV_SPEC_TEMPLATE = 'GET_CV_SPEC_TEMPLATE';
export const SUBMIT_ENROLL_SEQUENCE = 'SUBMIT_ENROLL_SEQUENCE';
export const GET_ENROLL_SEQUENCE_STATUS = 'GET_ENROLL_SEQUENCE_STATUS';

export const ENROLL_CONTACTS_TO_SEQUENCE = 'ENROLL_CONTACTS_TO_SEQUENCE';

export const FETCH_SEQUENCE_STATS = 'FETCH_SEQUENCE_STATS';
export const SET_SEQUENCE_STATS = 'SET_SEQUENCE_STATS';

export const ENROLLMENT_SUCCESS = 'ENROLLMENT_SUCCESS';
export const CLEAR_PERSONALIZE_SEQUENCE = 'CLEAR_PERSONALIZE_SEQUENCE';
export const ENQUEUE_SEQUENCE_PERSONALIZE = 'ENQUEUE_SEQUENCE_PERSONALIZE';
export const SELECT_PERSONALIZE_CONTACT = 'SELECT_PERSONALIZE_CONTACT';

export const FETCH_ENQUEUE_CONTACT = 'FETCH_ENQUEUE_CONTACT';
export const SET_ENQUEUE_CONTACT = 'SET_ENQUEUE_CONTACT';

export const FETCH_PERSONALIZE_STEPS = 'FETCH_PERSONALIZE_STEPS';
export const SET_PERSONALIZE_STEPS = 'SET_PERSONALIZE_STEPS';
export const SET_PERSONALIZE_STEP = 'SET_PERSONALIZE_STEP';

export const FETCH_ENROLMENT_ERRORS = 'FETCH_ENROLMENT_ERRORS';
export const SET_RE_ENROLMENT_ERROR = 'SET_RE_ENROLMENT_ERROR';
export const SET_ENROLMENT_ERRORS = 'SET_ENROLMENT_ERRORS';
export const SET_ENROLMENT_DATA = 'SET_ENROLMENT_DATA';
export const SET_ACTIVE_SEQUENCE_PERSONALIZE = 'SET_ACTIVE_SEQUENCE_PERSONALIZE';

export const FETCH_POPULATE_ENQUEUE_TEMPLATE = 'FETCH_POPULATE_ENQUEUE_TEMPLATE';
export const SET_POPULATE_ENQUEUE_TEMPLATE = 'SET_POPULATE_ENQUEUE_TEMPLATE';

export const SUBMIT_PERSONALIZE_SEQUENCE = 'SUBMIT_PERSONALIZE_SEQUENCE';
export const ENROLL_ALL_CONTACTS = 'ENROLL_ALL_CONTACTS';
export const FETCH_ERRORS_LOADING = 'FETCH_ERRORS_LOADING';
