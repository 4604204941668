export default function dispatchEvent(type, data) {
  // const event = new CustomEvent(type, { detail: data });
  // document.body.dispatchEvent(event);
  try {
    let iframe = document.getElementById('botIframe');
    iframe.contentWindow.postMessage(JSON.stringify({ type, data }), '*');
    console.log('DISPATCHING THE EVEVNT');
    // let url =
    //   window.location != window.parent.location ? document.referrer : document.location.href;
    // parent.postMessage(JSON.stringify({ type, data }), url);
  } catch (err) {}
  // document.dispatchEvent(event);
}
