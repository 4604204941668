import React from 'react';
import { Card as MuiCard, CardContent, CardHeader } from '@mui/material';

import useStyles from './styles';

const Card = (props) => {
  const {
    cardContent: { children: cardContentChildren, ...otherCardContent },
    cardHeader: { children: cardHeaderChildren, ...otherCardHeader } = {
      children: null,
    },
    showHeader = false,
    className = '',
    ...restProps
  } = props;

  const classes = useStyles();

  return (
    <MuiCard
      className={`${classes.mainCardSummary} ${className}`}
      {...restProps}
    >
      {showHeader && (
        <CardHeader {...otherCardHeader} className={otherCardHeader.className}>
          {cardHeaderChildren}
        </CardHeader>
      )}
      <CardContent
        {...otherCardContent}
        className={`${classes.cardContentSummary} ${otherCardContent.className}`}
      >
        {cardContentChildren}
      </CardContent>
    </MuiCard>
  );
};

export default Card;
