import React from 'react';
import { FileWrapper } from './styles';
import { Box, FormHelperText, Typography } from '@mui/material';
import { bytesToSize } from 'src/utils/helper';

import images from 'src/config/images';
import { isEmpty } from 'lodash';

function File({ file }) {
  if (!file || isEmpty(file)) return null;
  const ext = file?.url ? file.url.split('.').pop() : '';
  return (
    <FileWrapper
      onClick={() => {
        window.open(file.url, '_blank');
      }}
    >
      {images.file[ext] && <img src={images.file[ext]} />}
      <Box sx={{ flex: 1, alignItems: 'flex-start', maxWidth: '140px' }}>
        <Typography variant="body2" color="textSecondary" noWrap textAlign="left">
          {file?.originalFilename || ''}
        </Typography>
        <FormHelperText textAlign="left" sx={{ color: 'rgba(0,0,0,0.4)' }}>
          {bytesToSize(file?.size)}
        </FormHelperText>
      </Box>
    </FileWrapper>
  );
}

export default File;
