import React, { useMemo } from 'react';
import { Box, Typography, Card, CardContent, CircularProgress } from '@mui/material';
import { Spacer } from 'src/components/shared';
import { colors } from './styles';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts';

const AppBarChart = ({ title, data, credits, loading }) => {
  const columns = useMemo(() => {
    if (!credits.length) {
      return [];
    }
    return Object.keys(credits[0]).filter((item) => item !== 'name');
  }, [credits]);

  return (
    <Card>
      <CardContent>
        <Typography variant="h3">{title}</Typography>
        <Spacer x={2} y={2} />
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress size={26} style={{ marginBottom: 16 }} />
          </Box>
        ) : (
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              width={500}
              height={300}
              data={credits}
              margin={{
                top: 24,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              barSize={32}
            >
              <XAxis dataKey="name" scale="point" padding={{ left: 40, right: 40 }} />
              <YAxis />
              <Tooltip />
              {/* <Legend /> */}
              <CartesianGrid strokeDasharray="3 3" />
              {columns.length ? (
                <>
                  {columns.map((item, index) => {
                    return (
                      <Bar
                        key={`${data.i}-widget-${index}`}
                        dataKey={item}
                        fill={'#13DEA1'}
                        background={{ fill: `#13DEA105` }}
                      >
                        <LabelList
                          dataKey={item}
                          position="top"
                          style={{ fill: 'rgb(102, 102, 102)' }}
                        />
                      </Bar>
                    );
                  })}
                </>
              ) : null}
            </BarChart>
          </ResponsiveContainer>
        )}
      </CardContent>
    </Card>
  );
};

export default AppBarChart;
