import { makeStyles } from '@mui/styles';
import { padding } from '@mui/system';
import previewImage from '../../../../img/previewbackground.png';
const useStyles = makeStyles(({ palette }) => ({
  Preview: {
    position: 'absolute',
    top: '50%',
    left: '54%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: 'fit-content',
    background: 'linear-gradient(120deg,#000 -4.89%,#151f2e 88.85%,#232c38 88.86%), #fff',
    borderRadius: '10px',

    '&:focus-visible ': {
      outline: 'none !important',
    },
  },

  close: {
    color: 'rgba(255, 255, 255, 0.54)',
    width: '4%',
    paddingTop: '1%',
  },
  progressheader: {
    fontSize: '16px',
    color: 'white',
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontFeatureSettings: `'clig' off, 'liga' off`,
    fontWeight: '500',
    '& span': {
      color: '#0FE',
    },
  },
  progressheader1: {
    color: '#0FE',
    fontSize: '17px',
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontFeatureSettings: `'clig' off, 'liga' off`,
    fontWeight: '400',
    lineHeight: 'normal',
    marginLeft: '10px',
  },
  dialogLinear: {
    '& .MuiDialog-paper': {
      borderRadius: '10px',
      background: 'linear-gradient(120deg,#000 -4.89%,#151f2e 88.85%,#232c38 88.86%), #fff',
      /* 
      background: linear-gradient(104.75deg, #000000 -5.01%, #132b4e 134.12%),
        linear-gradient(0deg, #ffffff, #ffffff); */
    },
  },
  header: {
    fontSize: '32px',
    color: '#0FE',
    fontStyle: 'normal',
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontWeight: '700',
  },
  descriptionBox: {
    backgroundColor: '#082035',
    padding: '20px',
    borderRadius: '4px',
  },
  descriptionBoxOne: {
    backgroundColor: '#082035',
    padding: '20px',
    borderRadius: '4px',
    maxHeight: '500px',
    overflow: 'auto',
  },
  description: {
    fontSize: '16px',
    color: '#BEBEBE',
  },
  title: {
    fontSize: '18px',
    color: '#C0C0C0',
  },
  score: {
    fontSize: '28px',
    fontWeight: 500,
    color: '#FFFFFF',
  },
  total: {
    fontSize: '28px',
    fontWeight: 500,
    color: '#944AF1',
  },
  scoreBox: {
    backgroundColor: '#072947',
    height: '70%',
    borderRadius: '4px',
    padding: '20px',
  },
  scoreGraph: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    marginTop: '10%',
    width: '100%',
    borderRadius: '4px',
    // border: '1px solid #CF0175',
    fontSize: '18px',
    fontWeight: '700',
    backgroundColor: '#ffd47e',
    color: '#050e16',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#ffd47e',
      color: '#050e16',
    },
  },
  hideButton: {
    marginTop: '10%',
    width: '100%',
    borderRadius: '4px',
    // border: '1px solid #CF0175',
    fontSize: '18px',
    fontWeight: '700',
    backgroundColor: '#ffd47e',
    color: '#050e16',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#ffd47e',
      color: '#050e16',
    },
  },
  progressbardiv: {
    display: 'inline-flex',
    height: '42px',
    alignItems: 'center',
    gap: '20px',
    flexshrink: '0',
    marginTop: '5px',
  },
  progressbardiv1: {
    display: 'flex',
    width: '420px',
    padding: '15px',
    justifycontent: 'center',
    alignItems: 'center',
    gap: '24px',
    borderRadius: '4px',
    background: '#082035',
  },
  progressbarinmodal: {
    display: 'flex',
    flexDirection: 'column',
    justifycontent: 'center',
    alignItems: 'flex-start',
    gap: '10px',
    flex: '1 0 0',
  },
  progressbarcorners: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '15px',
    flex: '1 0 0',
    height: '6px',
    borderRadius: '10px',
    marginTop: '8px',
    background: '#EBEBD3',
    '& .MuiLinearProgress-bar1Determinate': {
      backgroundColor: '#1BF1B1',
      width: '100%',
    },
  },
  graphdiv: {
    display: 'flex',
    width: '677.333px',
    height: 'fit-content',

    padding: '20.752px',
    flexdirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexShrink: '0',
    borderRadius: '6.301px',
    background: 'linear-gradient(0deg, #0A2033 0%, #0A2033 100%), #040F1A',
  },
  mascotimage: {
    width: '207px',
    height: '240px',
    flexShrink: '0',
    flex: 'none',
    marginTop: '-200px',
    marginLeft: '-8%',

    bottom: '0',
  },

  liveprogressgotitbtn: {
    display: 'inline-flex',
    padding: '12px 24px',
    alignItems: 'flex-end',
    color: 'white',
    borderradius: '3px',
    background: '#125151',
    marginTop: '-50px',
    textTransform: 'none',
  },
  scorespan: {
    transform: 'rotate(-90deg)',
    marginTop: '0px',
    display: 'inline-block',
    color: '#556573',
    marginRight: '-20px',
    fontSize: '11.026px',
  },
  scorespan2: {
    marginTop: '10px',
    display: 'inline-block',
    color: '#556573',
    fontSize: '11.026px',
  },
  customtooltip: {
    backgroundColor: '#0E2B44' /* Set the background color */,
    color: 'white' /* Set the text color */,

    textAlign: 'center',
    borderRadius: '10px',
    padding: '10px 15px 10px 15px',
    '&::before': {
      top: '80%',
      left: '45%',
    },
  },

  customtooltippara: {
    color: '#8CA7BE',
    fontWeight: '400',
    lineHeight: '17.327px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '10px',
    margin: '-5px',
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontFeatureSettings: `'clig' off, 'liga' off`,
  },
  roleplaygrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '0px',
  },
}));
export default useStyles;
