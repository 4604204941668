const sequenceHeader = [
  {
    id: 1,
    status: 'active',
    type: 'default',
    label: 'Sequence name',
    fieldName: 'name',
    fieldType: 'text',
    inContact: false,
    inUser: false,
    inTask: false,
    inSequence: true,
    scope: ['sequences'],
    fieldOptions: { showOnGrid: true, showOnForm: true },
    gridParams: { sequences: { sortOrder: 0 } },
    bhConfig: null,
    lookup: null,
  },
  {
    id: 2,
    status: 'active',
    type: 'default',
    label: 'All contacts',
    fieldName: 'contacts',
    fieldType: 'formula',
    inContact: false,
    inUser: false,
    inTask: false,
    inSequence: true,
    scope: ['sequences'],
    fieldOptions: { showOnGrid: true, showOnForm: false },
    gridParams: { sort: 'false', sequences: { sortOrder: 2 } },
    bhConfig: null,
    lookup: null,
  },
  {
    id: 3,
    status: 'active',
    type: 'default',
    label: 'In progress',
    fieldName: 'inProgress',
    fieldType: 'formula',
    inContact: false,
    inUser: false,
    inTask: false,
    inSequence: true,
    scope: ['sequences'],
    fieldOptions: { showOnGrid: true, showOnForm: false },
    gridParams: { sort: 'false', sequences: { sortOrder: 2 } },
    bhConfig: null,
    lookup: null,
  },
  {
    id: 4,
    status: 'active',
    type: 'default',
    label: 'Open',
    fieldName: 'opened',
    fieldType: 'formula',
    inContact: false,
    inUser: false,
    inTask: false,
    inSequence: true,
    scope: ['sequences'],
    fieldOptions: { showOnGrid: true, showOnForm: false },
    gridParams: { sort: 'false', sequences: { sortOrder: 5 } },
    bhConfig: null,
    lookup: null,
  },
  {
    id: 5,
    status: 'active',
    type: 'default',
    label: 'Clicked',
    fieldName: 'clicked',
    fieldType: 'formula',
    inContact: false,
    inUser: false,
    inTask: false,
    inSequence: true,
    scope: ['sequences'],
    fieldOptions: { showOnGrid: true, showOnForm: false },
    gridParams: { sort: 'false', sequences: { sortOrder: 5 } },
    bhConfig: null,
    lookup: null,
  },
  {
    id: 6,
    status: 'active',
    type: 'default',
    label: 'Replied',
    fieldName: 'replied',
    fieldType: 'formula',
    inContact: false,
    inUser: false,
    inTask: false,
    inSequence: true,
    scope: ['sequences'],
    fieldOptions: { showOnGrid: true, showOnForm: false, required: false },
    gridParams: { sort: 'false', sequences: { sortOrder: 5 } },
    bhConfig: null,
    lookup: null,
  },
  {
    id: 7,
    status: 'active',
    type: 'default',
    label: 'Interested',
    fieldName: 'interested',
    fieldType: 'text',
    inContact: false,
    inUser: false,
    inTask: false,
    inSequence: true,
    scope: ['sequences'],
    fieldOptions: { showOnGrid: true, showOnForm: false },
    gridParams: { sequences: { sortOrder: 9, sort: false, draggable: false } },
    bhConfig: null,
    lookup: null,
  },
];

export default sequenceHeader;
