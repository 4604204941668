import React from 'react';
import styled from 'styled-components';
import { Box, Tabs, Tab } from '@mui/material';
import { Skeleton } from '@mui/lab';
import { makeStyles, withStyles } from '@mui/styles';
import { Avatar, Spacer } from 'src/components/shared';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const AvatarWrapper = styled.div`
  width: 64px;
`;

export const DetailsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  width: 100%;
`;

const StyledTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

const StyledTab = withStyles((theme) => ({
  root: {
    '&:hover': {
      opacity: 1,
    },
    '&$selected': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {},
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function SequenceSkeleton() {
  return (
    <>
      <Container>
        <Box display="flex">
          <Skeleton animation="wave" height={24} width={60} />
          <NavigateNextIcon sx={{ fill: 'rgba(0,0,0,0.6)' }} />
          <Skeleton animation="wave" height={24} width={120} />
        </Box>
      </Container>
      <Skeleton animation="wave" height={30} width={150} />
      <Box mt={2}>
        <StyledTabs indicatorColor="primary" value={0}>
          <StyledTab icon={<Skeleton animation="wave" height={20} width={68} />} />
          <StyledTab icon={<Skeleton animation="wave" height={20} width={58} />} />
          <StyledTab icon={<Skeleton animation="wave" height={20} width={58} />} />
          <StyledTab icon={<Skeleton animation="wave" height={20} width={66} />} />
        </StyledTabs>
      </Box>
    </>
  );
}

export default SequenceSkeleton;
