import { Button, Typography } from '@mui/material';
import { Box, Grid } from '@mui/material';
import * as React from 'react';
import Moment from 'react-moment';
import { dateFormat } from 'src/config';
import ActionTemplateMenu from './ActionTemplateMenu';
import useStyles from './styles';
import parse from 'html-react-parser';

export default function TemplateCard({
  containerStyle = {},
  id,
  dontShowAction = false,
  data = null,
  deleteTemplate = () => {},
  savedOn,
  edit,
  deletion,
  share,
  note,
  ...props
}) {
  const classes = useStyles();
  let style = props?.style;

  const [hover, setHover] = React.useState(false);
  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };
  return (
    <Grid
      item
      xs={4}
      // container
      // spacing={2}
      display="flex"
      justifyContent={'center'}
      height={380}
      position={'relative'}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      {hover && !dontShowAction ? (
        <ActionTemplateMenu
          note={note}
          id={id}
          edit={edit}
          share={share}
          deletion={deletion}
          deleteTemplate={deleteTemplate}
          setHover={setHover}
        />
      ) : null}
      <Box
        className={classes.template}
        mb={4}
        mr={2}
        ml={2}
        sx={{ width: '100%' }}
        // onClick={() => {
        //   window.open(`/lms/CreateNotes/${id}`, "_blank");
        // }}
      >
        <Typography mb={2} className={classes.heading}>
          {data.name ? data.name : 'No heading'}
        </Typography>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          sx={{ height: '85%' }}
        >
          <Typography className={classes.description}>{parse(data.content)}</Typography>
          <Button
            className={`${classes.draftBtn} ${classes.alignment}`}
            sx={{
              marginTop: 'auto',
            }}
          >
            <span className={classes.btnText}>{savedOn}</span>
            <Moment format={dateFormat}>{data.updatedAt}</Moment>
          </Button>
        </Box>
      </Box>
    </Grid>
  );
}
