import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({ palette }) => ({
  Preview: {
    position: 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    width: '713px',
    height:'fitContent',
    background: ' #FFFFFF',
    borderRadius: '10px',
    '&:focus-visible ': {
      outline: 'none !important',
    },
    '& button': {
      textTransform: 'none',
    },
  },
  chip: {
    display:'flex',
    borderRadius:'30px',
    alignItems:'center',
    background: 'rgba(0, 0, 0, 0.08)',
  },
  close: {
    color: 'rgba(0, 0, 0, 0.26)',
    width: '4%',
    paddingTop: '1%',
  },
  title: {
    fontSize: '18px',
    fontWeight: '500',
  },
  lineColor: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  informationTitle: {
    color: 'rgba(91, 103, 119, 1)',
    fontWeight: '700',
    fontSize: '14px',
    letterSpacing: '0.3px',
  },
  informationSubtitle: {
    color: 'rgba(91, 103, 119, 1)',
    fontWeight: '400',
    fontSize: '12px',
    letterSpacing: '0.3px',
  },
}));
export default useStyles;
