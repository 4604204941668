import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Grid, Box } from '@mui/material';
import { Button, Form } from 'src/components/shared';

function TeamForm({ teamLead, drawerToggle, user, formData, ...props }) {
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    if (user && user?.id) {
      setCurrentUser(user);
    }
  }, [user]);

  const handleSubmit = async (values, form) => {
    if (user && user?.id) {
      const updatedRow = { userId: user?.id, password: values.password };
      await props.resetPassword(updatedRow);
      drawerToggle({ edit: null, open: false });
    }
  };

  return (
    <Form
      initialValues={{
        setpassword: currentUser.setpassword || '',
        password: currentUser.password || '',
        setemail: currentUser.setemail || '',
      }}
      validationSchema={Yup.object().shape({
        // setpassword: Yup.string().required('Please enter Set Password!'),
        password: Yup.string().required('Please enter Password!'),
        // setemail: Yup.string().required('Please enter Set Email!'),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={false}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              justifyContent="space-between"
              //   alignItems="flex-end"
              height="100%"
            >
              <Box>
                <Grid>
                  <Form.Field.Input
                    mb={2}
                    fullWidth
                    rows={4}
                    variant="outlined"
                    name="password"
                    label="New Password"
                  />
                </Grid>
              </Box>

              <Box ml={50}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Update
                </Button>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}
export default TeamForm;
