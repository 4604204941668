import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import { Avatar, Spacer } from 'src/components/shared';

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const AvatarWrapper = styled.div`
  width: 50px;
`;

export const ProfileActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DetailsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

function UserProfile({ showButtons }) {
  return (
    <>
      <ProfileWrapper>
        <AvatarWrapper>
          <Avatar size={50} src={undefined} name={undefined} />
        </AvatarWrapper>
        <DetailsWrapper>
          <Skeleton animation="wave" height={24} width={'40%'} />
          <Spacer basis={0.5} />
          <Skeleton animation="wave" height={18} width={'100%'} />
          {/* <Spacer basis={0.25} /> */}
          <Skeleton animation="wave" height={18} width={'60%'} />
        </DetailsWrapper>
      </ProfileWrapper>
      {showButtons ? (
        <>
          <Spacer x={3} y={3} />
          <ProfileActionsWrapper>
            <Skeleton variant="circular" width={40} height={40} />
            <Spacer basis={2} />
            <Skeleton variant="circular" width={40} height={40} />
            <Spacer basis={2} />
            <Skeleton variant="circular" width={40} height={40} />
            <Spacer basis={2} />
            <Skeleton variant="circular" width={40} height={40} />
          </ProfileActionsWrapper>
        </>
      ) : null}
    </>
  );
}

UserProfile.propTypes = {
  showButtons: PropTypes.bool,
};

UserProfile.defaultProps = {
  showButtons: true,
};

export default UserProfile;
