import * as React from 'react';
import Grid from '@mui/material/Grid';
import CardMedia from '../cardMedia';
import useStyles from '../sessionListingBig/style';
import NullResult from '../../components/NullResults/NullResult';

export default function SessionsListing({ itemData, title, from, searchKeyword, ...props }) {
  const classes = useStyles();
  return (
    <>
      {title}
      {itemData?.length > 0 ? (
        <Grid container spacing={1}>
          {itemData.filter((k)=>k.published).map((item, i) => (
            <Grid key={i} item xs={6} md={6} lg={4} display="flex" justifyContent={'center'}>
              <CardMedia
                src={item?.thumbnailImage}
                alt={item?.title}
                id={item?.id}
                contentType={item?.contentType}
                data={item}
                from={from}
                type={'thumbnailImage'}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <NullResult searchData={searchKeyword} />
      )}
    </>
  );
}
