import * as types from '../actions/tagsTypes'

const initialState = {
  loading: {
    tags: false,
  },
  tags: {
    data: [],
    count: 0,
  },
};

const TagsReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.FETCH_ADMIN_TAGS:
      return {
        ...state,
        loading: {
          ...state.loading,
          tags: true,
        },
        tags: {
          ...state.tags,
          data: [],
          count: 0,
        },
      };

    case types.SET_ADMIN_TAGS:
      return {
        ...state,
        loading: {
          ...state.loading,
          tags: false,
        },
        tags: {
          ...state.tags,
          data: action.data.data,
          count: action.data.count,
        },
      };

    case types.SET_TAG_STATUS:
      const updatedRowIndex = state.tags.data.findIndex(val => val.id === action.data.id);
      return {
        ...state,
        tags: {
          ...state.tags,
          data: [...state.tags.data.slice(0, updatedRowIndex), { ...action.data },...state.tags.data.slice(updatedRowIndex + 1)],
        },
      };

    default:
      return state;
  }
};

export default TagsReducer;