import React, { useRef } from 'react';
import { Col, Row, Span, useStyles } from './styles';
import { Dropdown } from 'src/components/shared';
import { Button, Checkbox, CircularProgress, Input, Tooltip, Typography } from '@mui/material';
import { AppGrid } from 'src/components/App';
import { connect } from 'react-redux';
import toast from 'src/utils/toast';
import { useParams } from 'react-router';
import { titleCase } from './';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const ShelfTable = ({
  type,
  attributes,
  crmFields,
  onDataChange,
  crmNotes,
  dataLoading,
  crmTenantData,
  srNotes,
}) => {
  const isNoteType = type === 'note';
  const params = useParams();
  const classes = useStyles();
  const tableRef = useRef(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [attributesObj, setAttributesObj] = React.useState({});
  const [srFields, setSrFields] = React.useState([]);
  const [defaultMapping, setDefaultMapping] = React.useState({});
  const [tableData, setTableData] = React.useState([]);
  const loading = dataLoading || isLoading;

  const typeObj = {
    string: 'text',
    formula: 'formula',
    number: 'number',
    lookup: 'lookup',
  };

  const updateTableData = (key, value, index) => {
    if (typeof tableData?.[index] !== 'object' || value === undefined) return;
    const updatedTableData = tableData;
    if (
      key === 'status' &&
      value === true &&
      (!tableData?.[index]?.crmKey || !tableData?.[index]?.srKey)
    )
      return toast.error('Please enter all values to enable it', 'tc');

    updatedTableData[index] = {
      ...updatedTableData[index],
      ...{
        [key]:
          typeof value === 'string'
            ? value
            : key === 'crmKey'
            ? value?.crmKey
            : key === 'srKey'
            ? value?.fieldName
            : value,
        ...(key === 'srKey'
          ? {
              srType: attributesObj[value?.fieldName]?.fieldType,
              ...(typeObj[tableData?.[index]?.crmType] !==
              attributesObj[value?.fieldName]?.fieldType
                ? { crmKey: '', crmType: '' }
                : {}),
            }
          : {}),
      },
    };
    if (key === 'srKey') {
      const initialTableDataSRKeys = {};
      updatedTableData.forEach((ele) => (initialTableDataSRKeys[ele?.srKey] = ele));
      setSrFields(
        attributes.filter((ele) => !Object.keys(initialTableDataSRKeys)?.includes(ele?.fieldName)),
      );
    }
    setTableData([...updatedTableData]);
  };

  const deleteTableRowData = (index) => {
    const updatedTableData = tableData;
    updatedTableData.splice(index, 1);
    setTableData([...updatedTableData]);
  };

  const handleAddNewRow = () => {
    setTableData([
      ...tableData,
      {
        crmKey: '',
        crmType: '',
        srKey: '',
        srType: '',
        fixed: false,
        status: false,
        mandatory: false,
        viewable: false,
      },
    ]);
    tableRef?.current?.scrollToBottom();
  };

  React.useEffect(() => {
    onDataChange(type, tableData);
  }, [tableData, type]);

  React.useEffect(() => {
    const attributesObj = {};
    attributes.forEach((ele) => (attributesObj[ele?.fieldName] = ele));
    setAttributesObj(attributesObj);
  }, [attributes]);

  React.useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const isDefaultRender = !crmTenantData?.id;
      if (!isNoteType) {
        const isUnsubscribedNote = !!crmTenantData?.config?.Note?.find(
          ({ srKey, status }) => srKey === 'unsubscribe' && status,
        );
        const defaultMapping = {};
        const defaultTableData =
          crmFields?.[params?.type?.toLowerCase()]?.defaultMappings?.[titleCase(type)]?.map(
            (ele) => (defaultMapping[ele?.srKey] = ele),
          ) || [];
        const initialTableData =
          crmTenantData?.config?.[titleCase(type)]?.filter(
            ({ srKey }) => !(srKey === 'unsubscribe' && isUnsubscribedNote),
          ) || [];
        let data = isDefaultRender ? defaultTableData : initialTableData;
        const initialTableDataSRKeys = {};
        data = data?.map((ele) => {
          initialTableDataSRKeys[ele?.srKey] = ele;
          return ele;
        });
        const srFieldsObject = {};
        const srFields = attributes
          .map((ele) => {
            srFieldsObject[ele?.fieldName] = ele;
            return ele;
          })
          .filter(
            (ele) =>
              !Object.keys(initialTableDataSRKeys)?.includes(ele?.fieldName) &&
              ele.status === 'active' &&
              ele.fieldName !== 'imageUrl',
          );
        let sortedData = data?.map((ele, index) => {
          ele = {
            ...ele,
            status: isDefaultRender
              ? (!!ele?.fixed || !!ele?.status) && ele?.crmKey
              : !!ele?.status,
            srKey: !!srFieldsObject?.[ele?.srKey] ? ele.srKey : '',
          };
          if (ele.fixed) {
            return {
              ...ele,
              sortOrder: index,
            };
          } else if (ele.status && !ele.fixed) {
            return {
              ...ele,
              sortOrder: data.length + index,
            };
          } else {
            return {
              ...ele,
              sortOrder: data.length * 2 + index,
            };
          }
        });
        sortedData = sortedData.sort((a, b) => a.sortOrder - b.sortOrder);
        setDefaultMapping(defaultMapping);
        setSrFields(srFields);
        setTableData(sortedData);
      } else {
        const isUnsubscribedOtherConfig = !!Object.keys(crmTenantData?.config)?.find(
          (configKey) =>
            !!configKey !== 'Note' &&
            crmTenantData?.config[configKey]?.find(
              ({ srKey, status }) => srKey === 'unsubscribe' && status,
            ),
        );
        const tableData = isDefaultRender
          ? srNotes.map((ele) => {
              return { srKey: ele.value, status: false, crmKey: '' };
            })
          : crmTenantData?.config?.[titleCase(type)]?.filter(
              ({ srKey }) => !(srKey === 'unsubscribe' && isUnsubscribedOtherConfig),
            ) ?? [];
        setTableData(tableData);
      }
      setIsLoading(false);
    }
    if (crmFields?.[params?.type?.toLowerCase()] && crmTenantData) fetchData();
  }, [crmFields, crmTenantData, type, srNotes]);

  const taskColumns = React.useMemo(
    () => [
      {
        label: '',
        name: '',
        options: {
          empty: true,
          sort: true,
          draggable: false,
          setCellHeaderProps: () => ({
            style: { minWidth: 50 },
          }),
          setCellProps: () => ({
            style: { minWidth: 50 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = tableData[dataIndex];
            const value = rData?.fixed ? (
              rData.mandatory ? (
                <Tooltip
                  placement="left"
                  arrow
                  title={
                    <Span
                      style={{
                        whiteSpace: 'pre-line',
                        fontSize: 12,
                      }}
                    >
                      {`Default field mapping`}
                    </Span>
                  }
                >
                  <LockIcon style={{ color: 'rgba(0, 0, 0, 0.25)' }} />
                </Tooltip>
              ) : (
                <Tooltip
                  placement="left"
                  arrow
                  title={
                    <Span
                      style={{
                        whiteSpace: 'pre-line',
                        fontSize: 12,
                      }}
                    >
                      {`Enable default field mapping`}
                    </Span>
                  }
                >
                  <LockOpenIcon style={{ color: 'rgba(0, 0, 0, 0.25)' }} />
                </Tooltip>
              )
            ) : !!defaultMapping?.[rData?.srKey] &&
              defaultMapping[rData?.srKey]?.crmKey === rData?.crmKey ? (
              <Tooltip
                placement="left"
                arrow
                title={
                  <Span
                    style={{
                      whiteSpace: 'pre-line',
                      fontSize: 12,
                    }}
                  >
                    {`Default recommended field mapping`}
                  </Span>
                }
              >
                <CheckCircleIcon style={{ color: 'rgba(0, 0, 0, 0.5)' }} />
              </Tooltip>
            ) : (
              <Tooltip
                placement="left"
                arrow
                title={
                  <Span
                    style={{
                      whiteSpace: 'pre-line',
                      fontSize: 12,
                    }}
                  >
                    {`Custom field mapping`}
                  </Span>
                }
              >
                <PlaylistAddIcon style={{ color: 'rgba(0, 0, 0, 0.5)' }} />
              </Tooltip>
            );
            return <Row>{value}</Row>;
          },
        },
      },
      {
        label: (
          <Row style={{ gap: 6 }}>
            <Tooltip
              placement="top"
              arrow
              title={
                <Span
                  style={{
                    whiteSpace: 'pre-line',
                    fontSize: 12,
                  }}
                >
                  {`Select which fields you want to activate
                    field mapping for between SR and ${titleCase(params?.type)}`}
                </Span>
              }
            >
              <Span>Active </Span>
            </Tooltip>
          </Row>
        ),
        name: 'status',
        options: {
          sort: true,
          draggable: false,
          setCellHeaderProps: () => ({
            style: { width: 100 },
          }),
          setCellProps: () => ({
            style: { width: 100 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = tableData[dataIndex];
            const value = rData?.status;
            return (
              <Checkbox
                checked={rData?.fixed || value}
                disabled={rData?.fixed}
                className={`${classes.Checkbox} ${rData?.fixed ? 'disabled' : ''}`}
                onClick={() => updateTableData('status', !value, dataIndex)}
              />
            );
          },
        },
      },
      {
        label: `SuperReach ${isNoteType ? ' notes' : ' fields'}`,
        name: 'srKey',
        options: {
          sort: true,
          draggable: false,
          setCellHeaderProps: () => ({
            style: { ...(isNoteType ? { width: 175 } : { minWidth: 150 }) },
          }),
          setCellProps: () => ({
            style: { ...(isNoteType ? { width: 175 } : { minWidth: 150 }) },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = tableData[dataIndex];
            const label = attributesObj?.[rData?.srKey]?.label;
            if (isNoteType)
              return (
                <Typography style={{ padding: '6px 8px' }}>
                  {rData?.srKey
                    ?.split('_')
                    ?.map((ele) => titleCase(ele))
                    ?.join(' ')}
                </Typography>
              );
            else
              return !rData?.fixed ? (
                <Dropdown
                  className={`dropdown ${rData?.fixed ? 'disabled' : null}`}
                  title="Select..."
                  options={srFields}
                  onChange={(e) => updateTableData('srKey', e, dataIndex)}
                  optLabel="label"
                  optValue="srKey"
                  cancellable={false}
                  selected={label ? { label, value: rData?.srKey } : {}}
                  disabled={rData?.fixed}
                />
              ) : (
                <Typography style={{ padding: '6px 8px' }}>
                  {attributesObj?.[rData?.srKey]?.label}
                </Typography>
              );
          },
        },
      },
      {
        label: '',
        name: '',
        key: 'key',
        options: {
          sort: false,
          draggable: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 60 },
          }),
          setCellProps: () => ({
            style: { minWidth: 60 },
          }),
          customBodyRenderLite: (dataIndex) => {
            return (
              <Row style={{ justifyContent: 'center', paddingRight: 20 }}>
                <KeyboardDoubleArrowRightIcon style={{ color: 'rgba(0, 0, 0, 0.5)' }} />
              </Row>
            );
          },
        },
      },
      {
        label: `${params?.type} ${isNoteType ? ' notes' : ' fields'}`,
        name: 'crmKey',
        options: {
          sort: true,
          draggable: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 150 },
          }),
          setCellProps: () => ({
            style: { minWidth: 150 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = tableData[dataIndex];
            const value = rData?.crmKey;
            const crmKeys = {};
            tableData.map((ele) => (crmKeys[ele?.crmKey] = ele.crmKey));
            return (
              <Row>
                {!isNoteType ? (
                  !rData?.fixed ? (
                    <Dropdown
                      className={`dropdown ${rData?.fixed ? 'disabled' : null}`}
                      title="Select..."
                      options={crmFields?.[params?.type?.toLowerCase()]?.defaultFields?.[
                        type
                      ]?.filter(
                        (item) => !crmKeys[item.crmKey],
                        // && typeObj?.[item?.crmType] === rData?.srType,
                      )}
                      onChange={(e) => updateTableData('crmKey', e, dataIndex)}
                      optLabel="crmKey"
                      optValue="crmKey"
                      cancellable={false}
                      selected={value ? { crmKey: value } : {}}
                      disabled={rData?.fixed}
                    />
                  ) : (
                    <Typography style={{ padding: '6px 8px' }}>{value}</Typography>
                  )
                ) : (
                  <Dropdown
                    className={`dropdown ${rData?.fixed ? 'disabled' : ''}`}
                    title="Select..."
                    sx={{
                      width: '100% !important',
                      '& > button > p': {
                        maxWidth: 'none',
                      },
                    }}
                    options={[...crmNotes]}
                    onChange={(e) => updateTableData('crmKey', { crmKey: e?.value }, dataIndex)}
                    optLabel="label"
                    optValue="value"
                    cancellable={false}
                    selected={value ? { label: value, value } : {}}
                    disabled={rData?.fixed}
                  />
                )}
              </Row>
            );
          },
        },
      },
      {
        label: (
          <Row style={{ gap: 6 }}>
            <Tooltip
              placement="top"
              arrow
              title={
                <Span
                  style={{
                    whiteSpace: 'pre-line',
                    fontSize: 12,
                  }}
                >
                  {`Select which fields you want to make mandatory
                    when syncing candidates from SR to ${titleCase(params?.type)}`}
                </Span>
              }
            >
              <Span>Mandatory field</Span>
            </Tooltip>
          </Row>
        ),
        name: 'mandatory',
        options: {
          sort: true,
          draggable: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 80 },
          }),
          setCellProps: () => ({
            style: { minWidth: 80 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = tableData[dataIndex];
            const value = rData?.mandatory;
            return (
              <Checkbox
                disabled={rData?.fixed && defaultMapping[rData?.srKey]?.mandatory}
                checked={value}
                className={`${classes.Checkbox} ${
                  rData?.fixed && defaultMapping[rData?.srKey]?.mandatory ? 'disabled' : ''
                }`}
                onClick={() => updateTableData('mandatory', !value, dataIndex)}
              />
            );
          },
        },
      },
      {
        label: '',
        name: '',
        options: {
          sort: false,
          draggable: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 40 },
          }),
          setCellProps: () => ({
            style: { minWidth: 40 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = tableData[dataIndex];
            if (
              !!defaultMapping?.[rData?.srKey] &&
              defaultMapping[rData?.srKey]?.crmKey === rData?.crmKey
            )
              return null;
            else
              return (
                <CloseIcon
                  style={{ color: 'rgba(0, 0, 0, 0.5)', cursor: 'pointer' }}
                  onClick={() => deleteTableRowData(dataIndex)}
                />
              );
          },
        },
      },
    ],
    [tableData, crmNotes],
  );

  return loading ? (
    <Row style={{ flex: 1, alignItems: 'center', justifyContent: 'center', height: 500 }}>
      <CircularProgress />
    </Row>
  ) : (
    <Col className={classes.PeopleLeadsWrapper}>
      <AppGrid
        className={classes.PLSSTable}
        columns={taskColumns?.filter(
          (ele) => !(['mandatory', ''].includes(ele.name) && ele.key !== 'key' && isNoteType),
        )}
        ref={tableRef}
        data={tableData}
        options={{ customToolbar: null }}
        loading={loading}
        filters={[]}
        paperHeight="px"
      />
      {!isNoteType && (
        <Row
          style={{
            padding: '9px 0',
            marginTop: '10px',
            justifyContent: 'center',
          }}
        >
          <Button
            style={{
              color: '#000000aa',
              textTransform: 'capitalize',
              gap: 7,
            }}
            onClick={handleAddNewRow}
            className={classes.ghostButton}
          >
            <AddCircleOutlineIcon style={{ fontSize: 20 }} /> Add field mapping
          </Button>
        </Row>
      )}
    </Col>
  );
};

const mapStateToProps = (state) => ({
  attributes: state.app.attributes.filter((item) => item?.inContact),
  srNotes: state.app.srNotes,
  crmFields: state.app.crmFields,
  crmNotes: state.app.crmNotes,
  crmTenantData: state.app.crmTenantData,
  dataLoading: state.app.dataLoading,
});

export default connect(mapStateToProps, {})(ShelfTable);
