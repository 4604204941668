export const EmptyIcon = ()=>{
    return(
        <svg width="220" height="220" viewBox="0 0 220 220" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="110" cy="110" r="108" fill="#082035" stroke="#213950" stroke-width="4"/>
        <path d="M153.032 61.1819C153.131 63.5635 155.039 65.4717 157.42 65.5702C157.616 65.5783 157.616 65.8681 157.42 65.8762C155.039 65.9746 153.131 67.8829 153.032 70.2645C153.024 70.4601 152.734 70.4601 152.726 70.2645C152.628 67.8829 150.72 65.9746 148.338 65.8762C148.143 65.8681 148.143 65.5783 148.338 65.5702C150.72 65.4717 152.628 63.5637 152.726 61.1819C152.734 60.9862 153.024 60.9862 153.032 61.1819Z" fill="#99CFFF"/>
        <path d="M86.669 43.2405C86.7672 45.6221 88.6757 47.5305 91.0572 47.6288C91.2529 47.6367 91.2529 47.9267 91.0572 47.9346C88.6757 48.033 86.7672 49.9413 86.669 52.3228C86.661 52.5185 86.3711 52.5185 86.3631 52.3228C86.2647 49.9413 84.3567 48.033 81.9749 47.9346C81.7792 47.9267 81.7792 47.6367 81.9749 47.6288C84.3567 47.5303 86.2647 45.6221 86.3631 43.2405C86.3711 43.0448 86.661 43.0448 86.669 43.2405Z" fill="#FFD47E"/>
        <path d="M134.208 52.5011C134.256 53.6625 135.187 54.5931 136.348 54.641C136.444 54.6449 136.444 54.7862 136.348 54.79C135.187 54.838 134.256 55.7686 134.208 56.9299C134.204 57.0253 134.063 57.0253 134.059 56.9299C134.011 55.7684 133.081 54.838 131.919 54.79C131.824 54.786 131.824 54.6449 131.919 54.641C133.081 54.5929 134.011 53.6625 134.059 52.5011C134.063 52.4059 134.204 52.4059 134.208 52.5011Z" fill="#FFD47E"/>
        <path d="M140.38 144.036C140.428 145.198 141.359 146.128 142.52 146.176C142.615 146.18 142.615 146.321 142.52 146.325C141.359 146.373 140.428 147.304 140.38 148.465C140.376 148.561 140.235 148.561 140.231 148.465C140.183 147.304 139.252 146.373 138.091 146.326C137.996 146.322 137.996 146.18 138.091 146.176C139.253 146.128 140.183 145.198 140.231 144.036C140.235 143.941 140.376 143.941 140.38 144.036Z" fill="#FFD47E"/>
        <path d="M87.4544 148.204C87.5023 149.366 88.433 150.296 89.5943 150.344C89.6897 150.348 89.6897 150.49 89.5943 150.494C88.4328 150.541 87.5023 151.472 87.4544 152.633C87.4505 152.729 87.3092 152.729 87.3052 152.633C87.2574 151.472 86.3266 150.541 85.1653 150.494C85.0699 150.49 85.0699 150.348 85.1653 150.344C86.3268 150.296 87.2574 149.366 87.3052 148.204C87.309 148.109 87.4505 148.109 87.4544 148.204Z" fill="#125151"/>
        <path d="M75.2126 114.082C75.2938 116.052 76.872 117.63 78.8417 117.712C79.0037 117.718 79.0037 117.958 78.8417 117.965C76.872 118.046 75.2938 119.624 75.2126 121.594C75.2059 121.756 74.9663 121.756 74.9596 121.594C74.8784 119.624 73.3002 118.046 71.3304 117.965C71.1685 117.958 71.1685 117.718 71.3304 117.712C73.3002 117.63 74.8784 116.052 74.9596 114.082C74.9663 113.92 75.2059 113.92 75.2126 114.082Z" fill="#FFD47E"/>
        <path d="M150.702 110.669C150.773 112.396 152.157 113.78 153.884 113.851C154.027 113.857 154.027 114.067 153.884 114.073C152.157 114.144 150.773 115.528 150.702 117.256C150.696 117.397 150.486 117.397 150.48 117.256C150.409 115.528 149.025 114.144 147.298 114.073C147.156 114.067 147.156 113.857 147.298 113.851C149.025 113.78 150.409 112.396 150.48 110.669C150.486 110.527 150.696 110.527 150.702 110.669Z" fill="#99CFFF"/>
        <path d="M117.243 38.0798C117.297 39.3744 118.334 40.4117 119.629 40.4653C119.735 40.4697 119.735 40.6273 119.629 40.6316C118.334 40.6852 117.297 41.7224 117.243 43.0172C117.239 43.1236 117.081 43.1236 117.077 43.0172C117.023 41.7226 115.986 40.6852 114.691 40.6316C114.585 40.6273 114.585 40.4697 114.691 40.4653C115.986 40.4117 117.023 39.3746 117.077 38.0798C117.081 37.9734 117.239 37.9734 117.243 38.0798Z" fill="#125151"/>
        <path d="M101.405 63.9271C102.474 63.9271 103.341 63.0602 103.341 61.9909C103.341 60.9216 102.474 60.0547 101.405 60.0547C100.336 60.0547 99.4688 60.9216 99.4688 61.9909C99.4688 63.0602 100.336 63.9271 101.405 63.9271Z" fill="#125151"/>
        <path d="M130.708 132.95C131.777 132.95 132.644 132.083 132.644 131.014C132.644 129.945 131.777 129.078 130.708 129.078C129.638 129.078 128.771 129.945 128.771 131.014C128.771 132.083 129.638 132.95 130.708 132.95Z" fill="#125151"/>
        <path d="M65.7262 65.7141C67.7841 65.7141 69.4524 64.0458 69.4524 61.9879C69.4524 59.93 67.7841 58.2617 65.7262 58.2617C63.6683 58.2617 62 59.93 62 61.9879C62 64.0458 63.6683 65.7141 65.7262 65.7141Z" fill="#99CFFF"/>
        <path d="M91.0408 128.668C92.2704 128.668 93.2672 127.671 93.2672 126.441C93.2672 125.212 92.2704 124.215 91.0408 124.215C89.8112 124.215 88.8145 125.212 88.8145 126.441C88.8145 127.671 89.8112 128.668 91.0408 128.668Z" fill="#99CFFF"/>
        <path d="M127.851 164.715H96.6309V180.073H127.851V164.715Z" fill="#125151"/>
        <path d="M133.401 180.07H91.0879V187.298H133.401V180.07Z" fill="#125151"/>
        <path d="M127.849 164.582H120.102V180.062H127.849V164.582Z" fill="#FFD47E"/>
        <path d="M133.399 180.07H125.775V187.298H133.399V180.07Z" fill="#FFD47E"/>
        <path d="M109.786 131.325C108.881 131.325 108.008 131.232 107.179 131.063C107.197 137.855 106.9 156.425 103.275 164.709H113.233L120.106 164.458C116.011 156.118 115.094 136.5 114.904 130.258C113.422 130.933 111.668 131.325 109.786 131.325Z" fill="#125151"/>
        <path d="M116.585 164.594L120.104 164.466C116.009 156.126 115.092 136.508 114.901 130.266C114.162 130.602 113.355 130.868 112.498 131.051C112.522 137.838 112.937 156.309 116.585 164.594Z" fill="#FFD47E"/>
        <path d="M109.783 122.422C107.908 122.422 106.072 122.087 104.287 121.448C103.381 122.189 102.959 123.421 102.959 124.494C102.959 126.49 103.992 128.076 105.878 129.334C108.859 131.322 113.317 131.479 116.298 129.491C118.183 128.233 119.363 126.435 119.363 124.439C119.363 122.948 118.703 121.569 117.584 120.441C115.089 121.733 112.476 122.422 109.783 122.422Z" fill="#125151"/>
        <path d="M117.587 120.438C116.86 120.814 116.122 121.137 115.376 121.409C116.16 122.408 116.61 123.56 116.61 124.789C116.61 126.785 115.43 128.582 113.544 129.841C112.833 130.315 112.038 130.665 111.199 130.9C113.023 130.919 114.839 130.462 116.3 129.487C118.186 128.229 119.366 126.431 119.366 124.435C119.366 122.944 118.706 121.565 117.587 120.438Z" fill="#FFD47E"/>
        <path d="M83.2973 109.894C88.3262 113.388 92.6206 114.585 95.0552 114.995C93.5242 113.346 92.0675 111.451 90.6995 109.33L90.6959 109.326C88.8911 108.682 86.6449 107.624 84.1382 105.883C78.4997 101.965 74.4581 96.4384 72.1266 89.456C71.5371 87.6911 71.1347 86.0448 70.8598 84.6268L81.0204 84.5551L81.0198 84.5524C80.675 82.9895 80.3613 81.3924 80.0777 79.7662L80.0729 79.7422H67.3301C67.3301 79.7422 67.3299 98.8009 83.2973 109.894Z" fill="#125151"/>
        <path d="M139.499 79.7422L139.494 79.7662C139.211 81.3924 138.897 82.9895 138.552 84.5524L138.551 84.5552L148.712 84.6268C148.437 86.0448 148.035 87.6911 147.445 89.456C145.114 96.4384 141.072 101.965 135.434 105.883C132.927 107.624 130.681 108.682 128.876 109.326L128.872 109.33C127.446 111.54 125.925 113.509 124.323 115.206L124.32 115.209C124.32 115.209 130.382 115.34 138.221 109.894C154.188 98.8011 154.188 79.7422 154.188 79.7422H139.499Z" fill="#FFD47E"/>
        <path d="M124.26 115.278C132.547 106.557 138.685 90.5814 140.64 71.4883H85.204C85.0678 88.3748 89.9193 106.348 99.2265 115.699C106.177 122.683 117.473 122.421 124.26 115.278Z" fill="#125151"/>
        <path d="M132.83 71.4883C133 90.3444 128.798 106.61 121.689 116.11C120.807 117.289 119.806 118.326 118.717 119.219C120.74 118.302 122.627 116.99 124.254 115.278C132.54 106.557 138.679 90.5814 140.633 71.4883H132.83Z" fill="#FFD47E"/>
        <path d="M109.781 132.021C104.115 132.021 99.5059 128.618 99.5059 124.435C99.5059 122.805 100.191 121.254 101.487 119.948C101.7 119.732 102.029 119.68 102.299 119.82C107.179 122.346 112.384 122.345 117.263 119.82C117.532 119.681 117.861 119.733 118.075 119.948C119.371 121.254 120.056 122.805 120.056 124.435C120.056 128.618 115.447 132.021 109.781 132.021ZM102.136 121.292C101.322 122.246 100.895 123.324 100.895 124.435C100.895 127.852 104.881 130.632 109.781 130.632C114.681 130.632 118.667 127.852 118.667 124.435C118.667 123.324 118.24 122.246 117.426 121.292C114.935 122.501 112.365 123.113 109.781 123.113C107.197 123.113 104.627 122.5 102.136 121.292Z" fill="#125151"/>
        <path d="M120.23 165.411H99.3391C99.097 165.411 98.8725 165.285 98.7465 165.079C98.6203 164.872 98.6106 164.615 98.7211 164.399C102.871 156.308 103.789 136.21 103.972 130.244C103.979 130.011 104.102 129.798 104.3 129.675C104.497 129.552 104.743 129.537 104.954 129.633C106.397 130.29 108.067 130.637 109.785 130.637C111.502 130.637 113.172 130.29 114.614 129.633C114.826 129.537 115.072 129.552 115.269 129.675C115.467 129.798 115.59 130.011 115.597 130.244C115.779 136.21 116.696 156.305 120.848 164.399C120.958 164.615 120.948 164.872 120.822 165.079C120.696 165.285 120.472 165.411 120.23 165.411ZM100.435 164.022H119.134C115.456 155.597 114.483 138.019 114.241 131.28C112.857 131.77 111.336 132.026 109.785 132.026C108.233 132.026 106.712 131.77 105.328 131.28C105.086 138.02 104.112 155.599 100.435 164.022Z" fill="#125151"/>
        <path d="M127.853 180.763H91.7161C91.3325 180.763 91.0215 180.452 91.0215 180.068V164.71C91.0215 164.326 91.3325 164.016 91.7161 164.016H127.853C128.237 164.016 128.548 164.326 128.548 164.71V180.068C128.548 180.452 128.237 180.763 127.853 180.763ZM92.4107 179.374H127.158V165.405H92.4107V179.374Z" fill="#125151"/>
        <path d="M133.395 187.995H86.1653C85.7815 187.995 85.4707 187.685 85.4707 187.301V180.074C85.4707 179.69 85.7815 179.379 86.1653 179.379H133.395C133.779 179.379 134.09 179.69 134.09 180.074V187.301C134.09 187.685 133.779 187.995 133.395 187.995ZM86.86 186.606H132.701V180.768H86.86V186.606Z" fill="#125151"/>
        <path d="M109.788 85.3398C109.994 90.3235 113.987 94.3166 118.971 94.5226C113.987 94.7282 109.994 98.7215 109.788 103.705C109.582 98.7215 105.589 94.7282 100.605 94.5226C105.589 94.3166 109.582 90.3235 109.788 85.3398Z" fill="#FFD47E"/>
        <path d="M109.78 123.114C105.911 123.114 102.135 121.767 98.5537 119.109C95.1381 116.574 91.9831 112.909 89.176 108.217C83.5843 98.8687 79.699 85.8514 78.2361 71.5624C78.2159 71.3669 78.2797 71.172 78.4115 71.0261C78.5431 70.8802 78.7307 70.7969 78.9271 70.7969H140.632C140.829 70.7969 141.016 70.8802 141.148 71.0261C141.28 71.172 141.343 71.3669 141.323 71.5624C139.86 85.8514 135.975 98.8687 130.383 108.217C127.576 112.909 124.421 116.574 121.006 119.109C117.425 121.767 113.648 123.114 109.78 123.114ZM79.6988 72.1861C82.8254 100.938 95.4148 121.725 109.78 121.725C124.145 121.725 136.734 100.938 139.86 72.1861H79.6988Z" fill="#125151"/>
        <path d="M124.374 115.904C124.341 115.904 124.318 115.903 124.307 115.903C124.045 115.897 123.809 115.745 123.696 115.51C123.582 115.274 123.611 114.994 123.77 114.787C123.784 114.767 123.803 114.746 123.819 114.728C125.378 113.078 126.882 111.135 128.29 108.953C128.302 108.934 128.316 108.915 128.331 108.896C128.411 108.796 128.522 108.715 128.643 108.671C130.769 107.913 132.92 106.783 135.038 105.311C140.551 101.481 144.504 96.0726 146.787 89.2355C147.214 87.9599 147.574 86.6445 147.863 85.3148L138.547 85.2491C138.339 85.2477 138.143 85.153 138.012 84.9911C137.881 84.8293 137.83 84.6174 137.872 84.4138C138.213 82.8687 138.528 81.2687 138.811 79.646L138.819 79.6042C138.884 79.2801 139.169 79.0469 139.5 79.0469H154.189C154.573 79.0469 154.884 79.3577 154.884 79.7415C154.884 79.9406 154.861 84.6872 152.854 90.872C151 96.586 147.025 104.623 138.618 110.464C131.035 115.733 125.106 115.904 124.374 115.904ZM129.332 109.9C128.275 111.521 127.165 113.014 126.017 114.358C128.297 114.011 132.68 112.898 137.825 109.323C145.915 103.704 149.744 95.9545 151.532 90.4435C153.036 85.8084 153.388 81.955 153.47 80.4363H140.082C139.875 81.593 139.65 82.7437 139.412 83.866L148.718 83.9317C148.925 83.9331 149.12 84.0266 149.251 84.1861C149.382 84.3457 149.434 84.5557 149.395 84.7585C149.072 86.4264 148.638 88.0808 148.105 89.6756C145.725 96.8031 141.596 102.448 135.831 106.452C133.685 107.943 131.5 109.102 129.332 109.9Z" fill="#125151"/>
        <path d="M95.1951 115.903C94.4636 115.903 88.5346 115.733 80.9511 110.464C72.5442 104.623 68.5693 96.586 66.7154 90.872C64.7088 84.6872 64.6855 79.9406 64.6855 79.7415C64.6855 79.3577 64.9965 79.0469 65.3802 79.0469H80.0693C80.4 79.0469 80.685 79.2801 80.7502 79.6042L80.755 79.6282C81.0414 81.2689 81.3564 82.8689 81.6947 84.4022C81.7398 84.6069 81.6904 84.8226 81.5594 84.9863C81.4288 85.1503 81.2315 85.2477 81.0216 85.2493L71.7064 85.315C71.995 86.6447 72.3556 87.9601 72.7815 89.2356C75.0646 96.0728 79.0177 101.482 84.531 105.312C86.6488 106.783 88.8004 107.914 90.9255 108.672C91.0472 108.715 91.1542 108.791 91.2347 108.892C91.2494 108.91 91.2667 108.934 91.2794 108.954C92.6869 111.135 94.1911 113.078 95.7502 114.728C95.7667 114.746 95.7822 114.764 95.7969 114.783C95.9558 114.991 95.9856 115.272 95.8725 115.508C95.7591 115.744 95.5242 115.897 95.2626 115.903C95.2517 115.903 95.2287 115.903 95.1951 115.903ZM66.1004 80.4361C66.1836 81.9625 66.5388 85.8441 68.0521 90.4895C69.8425 95.9858 73.6712 103.715 81.7442 109.323C86.8886 112.897 91.2717 114.011 93.552 114.357C92.4045 113.013 91.2943 111.52 90.2375 109.899C88.0694 109.102 85.8841 107.943 83.7385 106.452C77.9739 102.447 73.8443 96.8029 71.4643 89.6753C70.9314 88.0806 70.4978 86.4262 70.1743 84.7583C70.1352 84.5557 70.188 84.3457 70.319 84.1859C70.45 84.0262 70.645 83.9329 70.8516 83.9315L80.1576 83.8658C79.9211 82.749 79.6964 81.5995 79.4876 80.4361H66.1004Z" fill="#125151"/>
        </svg>
        
    )
}