import React, { useState, useEffect } from 'react';
import { Chip, Typography, Box } from '@mui/material';
import { Form, Menu } from 'src/components/shared';
import { capitalizeFirstLetter } from 'src/utils/helper';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { HtmlTooltip } from './styles';

export function useColumns(data, showAddForm, put) {
  const [columns, setColumns] = useState([]);
  const [waiting, setWaiting] = useState(false);

  const handleCheck = async (variable, name, val) => {
    try {
      setWaiting(true);
      await put(variable.id, {
        [name]: val,
      });
      setWaiting(false);
    } catch (error) {}
  };

  useEffect(() => {
    if (!data || !data.length) {
      setColumns([]);
    } else {
      setColumns([
        {
          label: 'Status',
          name: 'status',
          options: {
            filter: true,
            sort: true,
            draggable: true,
            setCellHeaderProps: (value) => ({
              style: { maxWidth: '24px', minWidth: '24px' },
            }),
            setCellProps: () => ({
              style: { maxWidth: '24px', minWidth: '24px' },
            }),
            customBodyRenderLite: (dataIndex) => {
              const rData = data[dataIndex];
              if (!rData?.id) return null;

              const isActive = rData.status === 'active' ? true : false;
              return (
                <>
                  <Form
                    initialValues={{
                      active: isActive,
                    }}
                    enableReinitialize={true}
                  >
                    <Box display="flex" justifyContent="center">
                      <Form.Field.Checkbox
                        name="active"
                        color="success"
                        label=" "
                        onChange={(val) => {
                          handleCheck(rData, 'status', val ? 'active' : 'inactive');
                        }}
                        sx={{ color: 'rgba(46, 125, 50, 1)' }}
                        disabled={waiting}
                      />
                    </Box>
                  </Form>
                </>
              );
            },
          },
        },
        {
          label: 'Name',
          name: 'name',
          options: {
            filter: true,
            sort: true,
            draggable: false,
            setCellHeaderProps: (value) => ({
              style: { minWidth: 90 },
            }),
            setCellProps: () => ({
              style: { minWidth: 90, cursor: 'pointer' },
            }),
          },
        },
        {
          label: 'Content',
          name: 'value',
          options: {
            filter: true,
            sort: true,
            draggable: false,
            setCellHeaderProps: (value) => ({
              style: { maxWidth: 400 },
            }),
            setCellProps: () => ({
              style: { maxWidth: 400, cursor: 'pointer' },
            }),
            customBodyRenderLite: (dataIndex) => {
              const rData = data[dataIndex];
              return (
                <HtmlTooltip
                  title={<div dangerouslySetInnerHTML={{ __html: rData?.value || '' }} />}
                >
                  <Typography
                    variant="body2"
                    noWrap
                    color="textPrimary"
                    sx={{
                      display: '-webkit-box',
                      overflow: 'hidden',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: rData?.value || '' }} />
                  </Typography>
                </HtmlTooltip>
              );
            },
          },
        },
        {
          label: 'Permission',
          name: 'accessType',
          options: {
            filter: true,
            sort: true,
            draggable: false,
            setCellHeaderProps: (value) => ({
              style: { minWidth: 90 },
            }),
            setCellProps: () => ({
              style: { minWidth: 90, cursor: 'pointer' },
            }),
          },
        },
        {
          name: 'id',
          label: ' ',
          options: {
            filter: false,
            sort: false,
            draggable: false,
            allowToggle: false,
            viewColumns: false,
            setCellHeaderProps: (value) => ({
              style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
            }),
            setCellProps: () => ({
              style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
            }),
            customBodyRenderLite: (index) => {
              const variable = data[index];
              if (!variable?.id) return null;

              return (
                <Menu
                  options={[
                    {
                      label: 'Edit Variable',
                      icon: <EditIcon />,
                      onClick: () => {
                        showAddForm(variable);
                      },
                    },
                  ]}
                />
              );
            },
          },
        },
      ]);
    }
  }, [data, waiting]);

  return columns;
}
