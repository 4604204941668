import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { FilterBar, Tabs } from 'src/components/App';
import { useDashboardStore } from '../../../utils/dashboardStore';
import SequenceGrid from './SequenceGrid';
import UserGrid from './UserGrid';
import Cards from './Cards';
import BarChart from './BarChart';
import { Spacer } from 'src/components/shared';

function Sequences() {
  const { filters, setFilters } = useDashboardStore();
  const [currentTab, setCurrentTab] = useState('sequences');

  const filtersArr = [
    {
      key: 'createdAt',
      title: 'Filter By Date',
      custom: true,
      type: 'relativeDateRange',
      options: [
        { id: 'today', name: 'Today' },
        { id: 'yesterday', name: 'Yesterday' },
        { id: 'thisWeek', name: 'This week' },
        { id: 'lastWeek', name: 'Last week' },
        { id: 'thisMonth', name: 'This month' },
        { id: 'lastMonth', name: 'Last month' },
        { id: 'last3Month', name: 'Last 3 months' },
      ],
      isPrimary: true,
    },
  ];

  useEffect(() => {
    const isFilterApplied = filters?.createdAt?.id && filters?.createdAt?.id !== 'thisMonth';
    if (!isFilterApplied)
      setFilters({
        createdAt: { id: 'thisMonth', name: 'This month' },
      });
  }, []);

  const handleTabChange = (tab) => {
    setCurrentTab(tab.id);
  };

  const handleFilterChange = (key, value) => {
    if (value && typeof value !== 'undefined') {
      setFilters({
        [key]: value,
      });
    } else {
      const newFilter = JSON.parse(JSON.stringify(filters));
      delete newFilter[key];
      setFilters(newFilter);
    }
  };

  return (
    <>
      <FilterBar filters={filtersArr} appliedFilters={filters} onChange={handleFilterChange} />
      <Spacer x={1} y={1} />
      <Cards />
      <Spacer x={2} y={4} />
      <BarChart />
      <Spacer x={2} y={2} />
      <Box mb={-8.25}>
        <Tabs
          tabs={[
            {
              id: 'sequences',
              name: 'Sequences',
            },
            {
              id: 'users',
              name: 'User',
            },
          ]}
          value={currentTab}
          onChange={handleTabChange}
          sx={{ zIndex: 999999 }}
        />
      </Box>
      {currentTab === 'sequences' && <SequenceGrid />}
      {currentTab === 'users' && <UserGrid />}
    </>
  );
}

export default Sequences;
