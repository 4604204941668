import React from 'react';
import { Typography, Card, CardContent, Box, Divider, Grid } from '@mui/material';
import {
  RadialBarChart,
  RadialBar,
  BarChart,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Cell,
} from 'recharts';
import styled from 'styled-components';
import { Spacer } from 'src/components/shared';

const colors = ['#FF9800', '#4789C2', '#9C27B0', '#3949AB', '#2E7D32'];

const ReportCard = ({ total, title, value, percentage, fill, type = 'graph' }) => {
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '125px',
  };
  const textStyles = {
    fontSize: '28px',
  };

  const CustomizedLabel = (props) => {
    const { x, y, stroke, value } = props;

    return (
      <g>
        <foreignObject x={0} y={0} width={125} height={125}>
          <div style={style}>
            <Typography sx={{ ...textStyles, color: fill }}>{percentage}%</Typography>
          </div>
        </foreignObject>
      </g>
    );
  };

  if (type === 'text') {
    return (
      <Grid item xs={6} md={4} lg={2}>
        <Card>
          <CardContent>
            <Box>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="body2">{title}</Typography>
              </Box>
              <Spacer x={1} y={2} />
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ height: '125px' }}
              >
                <Typography sx={{ fontSize: '28px' }}>{value}</Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  return (
    <Grid item xs={6} md={4} lg={2}>
      <Card>
        <CardContent>
          <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="body2">{title}</Typography>
              <Typography variant="body2">{value}</Typography>
            </Box>
            <Spacer x={1} y={2} />
            <Box display="flex" justifyContent="center" alignItems="center">
              <ResponsiveContainer width={125} height={125}>
                <RadialBarChart
                  cx="50%"
                  cy="50%"
                  innerRadius={48}
                  outerRadius={60}
                  barSize={5}
                  startAngle={90}
                  endAngle={450}
                  data={[
                    {
                      name: 'Demo',
                      value: total,
                      fill: '#ffffff',
                    },
                    {
                      name: 'Demo 1',
                      value: percentage,
                      fill: fill,
                      background: '#000000',
                    },
                  ]}
                >
                  <RadialBar
                    minAngle={125}
                    label={<CustomizedLabel />}
                    background
                    clockWise
                    dataKey="value"
                    isAnimationActive={false}
                  />
                </RadialBarChart>
              </ResponsiveContainer>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

function SummaryReportCards({ sequence }) {
  const { stats } = sequence;

  return (
    <Grid container spacing={2}>
      <ReportCard type="text" fill="#1976D2" title="Enrolled" value={stats?.totalEnrolments || 0} />
      <ReportCard
        fill="#1976D2"
        title="In progress"
        value={stats?.inProgressEnrollments || 0}
        percentage={stats?.inProgressRate || 0}
        total={100}
      />
      <ReportCard
        fill="#42C340"
        title="Opened"
        value={stats?.uniqueOpens || 0}
        percentage={stats?.uniqueOpenRate || 0}
        total={100}
      />
      <ReportCard
        fill="#42C340"
        title="Clicked"
        value={stats?.uniqueClicks || 0}
        percentage={stats?.uniqueClickRate || 0}
        total={100}
      />
      <ReportCard
        fill="#E69904"
        title="Replied"
        value={stats?.uniqueReplies || 0}
        percentage={stats?.uniqueReplyRate || 0}
        total={100}
      />
      <ReportCard
        fill="#033EAC"
        title="Interested"
        value={stats?.interested || 0}
        percentage={stats?.interestedRate || 0}
        total={100}
      />
    </Grid>
  );
}

export default SummaryReportCards;
