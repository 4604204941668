import styled from 'styled-components';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  IntegrationWrapper: {
    backgroundColor: '#ffffff',
    gap: 24,
    minHeight: 'calc(100vh - 62px)',
    padding: '10px',
    boxSizing: 'border-box',
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: 4,
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'lightgray',
      borderRadius: 6,
    },
  },

  GoBackWrapper: {
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.38)',
    cursor: 'pointer',
    fontSize: 14,
    gap: 2,
  },

  IntegrationContainer: {
    gap: 32,
    flex: 1,
    width: 'min(100%, 1200px)',
    margin: '0 auto',
  },

  navgateBack: {
    width: 20,
  },

  headerTitle: {
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 18,
    fontWeight: 500,
    gap: 36,
  },

  headerDescription: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 14,
  },

  Header: {
    gap: 20,
  },

  ContentWrapper: {
    borderTop: '1px solid #dcdcdc',
    flex: 1,

    '& .leftSide': {
      width: 200,
      gap: 16,
      padding: '32px 0',
      height: 'fit-content',

      '& .wrapper': {
        padding: '10px 20px',
        gap: 12,
        alignItems: 'center',
        cursor: 'pointer',
        fontSize: 14,
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.4)',
        borderRight: '1px solid transparent',

        '&.active': {
          color: '#1976D2 !important',
          background: '#1976D210 !important',
          borderRight: '1px solid #1976D2 !important',
        },

        '&:hover': {
          background: '#1976D208',
        },

        '&>svg': {
          width: 24,
        },
      },
    },

    '& .divider': {
      borderLeft: '1px solid #dcdcdc',
    },

    '& .rightSide': {
      flex: 1,
      padding: 32,
      gap: 24,
      boxSizing: 'border-box',

      '& .header-wrapper': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 16,

        '& .title': {
          fontSize: 18,
          fontWeight: 600,
          lineHeight: '26px',
          color: 'rgba(0, 0, 0, 0.8)',
        },

        '& .btn-group': {
          gap: 24,
        },
      },

      '& .description': {
        fontSize: 14,
        coor: 'rgba(0, 0, 0, 0.6)',
      },
    },
  },

  PeopleLeadsWrapper: {
    flex: 1,
    '& .dropdown': {
      fontSize: 16,
      width: 150,
      '&.disabled': {
        '& button': {
          opacity: '0.55 !important',
        },
      },
      '& button': {
        backgroundColor: 'transparent !important',
        borderRadius: 5,
        justifyContent: 'flex-start',
        gap: 8,
        color: 'black !important',
        minWidth: 180,
        '& > p': {
          fontSize: 16,
        },
        '&.text-muted': {
          color: 'rgba(0,0,0,0.4) !important',
        },
      },
    },
  },

  noteTypeTooltip: {
    maxWidth: 'none',
    '& .tooltipWrapper': {
      background: '#082037',
      margin: -10,
      padding: 40,
      gap: 20,
      borderRadius: 6,
      maxWidth: 700,
    },
    '& .MuiTooltip-arrow': {
      color: '#082037',
      fontSize: 36,
    },
  },

  TimelineWrapper: {
    alignItems: 'center',
    gap: 12,

    '& .timeline': {
      alignItems: 'center',
      gap: 9,

      '& .step-no': {
        borderRadius: '50%',
        backgroundColor: '#1976D2',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        width: 24,
        fontSize: 14,
        boxSizing: 'border-box',
        aspectRatio: '1/1',

        '&.disable': {
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
        },

        '& > svg': {
          fontSize: 16,
        },
      },

      '& .step-name': {
        '&.disable': {
          color: 'rgba(0, 0, 0, 0.12)',
        },
      },
    },
    '& .divider': {
      width: '46px',
      height: '1px',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
  },

  PeopleLeadsSyncSetting: {
    padding: '24px 24px 16px',
    gap: 16,
  },

  PLSSHeadingWrapper: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  PLSSHeading: {
    gap: 6,
    '& .title': {
      fontSize: 16,
    },
  },

  PLSSFilterWrapper: {
    alignItems: 'center',
    gap: 24,
  },

  PLSSFilterCheckbox: {
    alignItems: 'center',
    gap: 4,
  },

  PLSSTable: {
    width: '100%',
    '& .MuiTableCell-body, & .MuiTableCell-head': {
      padding: '4px 0 4px 20px !important',
    },
    '& .form-input': {
      border: '1px solid #c4c4c4',
      borderRadius: 6,
      padding: '0px 12px',

      '&::before': {
        border: 'none !important',
      },
    },
  },

  PLSSTableSwitch: {
    '& .Mui-checked': {
      color: 'white',
    },
    '& .Mui-checked+.MuiSwitch-track': {
      backgroundColor: '#66BB6A',
      opacity: 0.85,
    },
  },

  PLSSTableTitle: {
    gap: 6,
    '& > svg': {
      width: 20,
      color: '#00000040',
    },
  },

  primaryButton: {
    padding: '4px 10px',
    gap: 7,
    height: 'fit-content',
    whiteSpace: 'pre',
    color: 'white',
    background: '#4CAF50',
    '&:hover': {
      background: '#4CAF50b0',
    },
    '& >svg': {
      fontSize: 18,
    },
    '&.disabled': {
      cursor: 'not-allowed',
      background: '#ffffffaa',
    },
  },

  secondaryButton: {
    padding: '4px 10px',
    gap: 7,
    height: 'fit-content',
    whiteSpace: 'pre',
    color: 'white',
    background: '#1976D2',
    '&:hover': {
      background: '#1976D2b0',
    },
    '&.disabled': {
      background: '#1976D2b0',
      cursor: 'not-allowed',
    },
    '& >svg': {
      fontSize: 18,
    },
    '&.disabled': {
      cursor: 'not-allowed',
      background: '#ffffffaa',
    },
  },

  ghostButton: {
    padding: '0 4px',
    gap: 7,
    height: 'fit-content',
    whiteSpace: 'pre',
    whiteSpace: 'pre',
    background: '#00000005',
    '&:hover': {
      background: '#00000010',
    },
    color: 'rgba(0, 0, 0, 0.38)',
    '& >svg': {
      fontSize: 18,
    },
    '&.disabled': {
      cursor: 'not-allowed',
      background: '#ffffffaa',
    },
  },

  linkButton: {
    background: '#00000005',
    padding: '0 4px',
    gap: 7,
    height: 'fit-content',
    whiteSpace: 'pre',
    color: '#1976D2',
    '& >svg': {
      // color: '#1976D2',
      fontSize: 18,
    },
    '&.disabled': {
      cursor: 'not-allowed',
    },
    '&:hover': {
      background: '#1976D210',
    },
  },

  textMuted: {
    color: 'rgba(0, 0, 0, 0.4)',
  },

  Checkbox: {
    color: '#1976D2 !important',
    '&.disabled': {
      color: 'grey !important',
    },
  },
}));

export const Row = styled.div`
  display: flex;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Span = styled.span`
  text-decoration: none;
`;
