import * as React from 'react';
// import Button from '@mui/material/Button';
import { Button } from 'src/components/shared';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CircularProgress, ListItemIcon, ListItemText } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

export default function FindMoreMenu({ findDetails, loading, disabled, isLoading }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const selectOption = (type) => {
    findDetails(type);
    handleClose();
  };

  return (
    <div>
      <Button
        size="small"
        id="basic-button"
        color="secondary"
        variant="contained"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disabled={disabled || isLoading}
        endIcon={
          isLoading ? (
            <CircularProgress size={16} style={{ color: '#ffffff' }} />
          ) : (
            <KeyboardArrowDownIcon />
          )
        }
        // loading={isLoading}
        sx={{
          pointerEvents: loading ? 'none' : 'auto',
          background: loading ? '#dbdbdb' : '#1976d2',
          textTransform: 'initial',
          '&.MuiButtonBase-root': {
            padding: '0 0 0 10px',
            transition: '250ms ease-in-out',
            '& > *': { transition: '250ms ease-in-out' },
          },
          '&.Mui-disabled': { color: 'white', opacity: 0.7 },
          '.MuiButton-endIcon': {
            background: loading ? '#dbdbdb' : '#1565c0',
            padding: '6px',
            borderBottomRightRadius: '5px',
            borderTopRightRadius: '5px',
          },
        }}
      >
        Find contact details
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => selectOption('workEmail')}>
          <ListItemIcon>
            <EmailIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Find work email</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => selectOption('personalEmail')}>
          <ListItemIcon>
            <EmailIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Find personal email</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => selectOption('mobile')}>
          <ListItemIcon>
            <PhoneIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Find phone</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
