import React, { useEffect } from 'react';
import { PageTitle } from 'src/components/App';
import { connect } from 'react-redux';
import NotesView from './NotesView';
import { fetchSessionsList, setSessionsList } from '../../actions/noteActions';

export const NotesContainer = (props) => {
  useEffect(() => {
    props.fetchSessions(props?.sessions?.paging, props?.sessions?.filters);
  }, []);
  return (
    <>
      <PageTitle title={`Notes`} />
      <NotesView {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({
  sessions: state.notes.sessionsList,
  loading: state.notes.loading.sessionsList,
  user: state.auth.user,
  fields: state.notes.sessionsList,
});

const mapDispatchToProps = (dispatch) => ({
  setSessions: (session) => dispatch(setSessionsList(session)),
  fetchSessions: (paging, filters, sort) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchSessionsList(paging, filters, sort, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesContainer);
