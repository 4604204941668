import {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import api from 'src/api';
import { convertObjectToQuerystring } from 'src/utils/helper';
const coursesContext = createContext({});

import toast from 'src/utils/toast';

export const useCourse = () => {
  const {
    courses,
    paging,
    setPaging,
    loading,
    post,
    put,
    filters,
    setFilters,
    course,
    get,
    deleteCourse,
  } = useContext(coursesContext);
  return {
    courses,
    course,
    get,
    paging,
    setPaging,
    loading,
    post,
    put,
    filters,
    setFilters,
    deleteCourse,
  };
};

export const CourseProvider = ({ children }) => {
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState({});
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [paging, setPaging] = useState({
    pageNo: 0,
    perPage: 10,
    _total: 0,
  });

  const payload = useMemo(() => {
    return {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      ...filters,
    };
  }, [paging, filters]);

  const cacheKey = JSON.stringify(payload);

  const ref = useRef(
    useMemo(() => {
      return {
        cacheKey,
        isInitial: true,
      };
    }, []),
  );
  const { current: refData } = ref;
  ref.current.isInitial = false;

  useEffect(() => {
    const hasParamsChanged = refData.cacheKey !== cacheKey;
    if (hasParamsChanged) {
      ref.current.cacheKey = cacheKey;
      fetchVariables();
    }
  }, [payload]);

  const fetchVariables = async () => {
    try {
      setLoading(true);
      const query = convertObjectToQuerystring({ ...payload, source: 'admin' });
      const response = await api(`/lms/course/?${query}`, null, 'get');
      setCourses(response.courses);
      setPaging({
        ...paging,
        _total: response.total.value,
      });
      setLoading(false);
    } catch (error) {
      setCourses([]);
      setLoading(false);
      setPaging({
        ...paging,
        _total: 0,
      });
    }
  };

  useEffect(() => {
    fetchVariables(paging);
  }, []);

  const put = async (id, payload) => {
    try {
      const response = await api(`/lms/course/${id}`, { ...payload, source: 'admin' }, 'put');
      const course = response.course;
      setCourses(
        courses.map((item) => {
          if (item.id === id) return course;
          return item;
        }),
      );
      toast.success('Course updated successfully');
      setLoading(false);
      return response;
    } catch (error) {}
  };

  const post = async (payload) => {
    try {
      const response = await api(`/lms/course`, { ...payload, source: 'admin' }, 'post');
      const variable = response.variable;
      setCourses([variable, ...courses]);
      await fetchVariables();
      toast.success('Course created successfully');
      return response;
    } catch (error) {}
  };

  const get = async (id) => {
    try {
      const response = await api(`/lms/course/${id}`, 'get');
      const course = response.course;
      setCourse(course);
      return response;
    } catch (error) {}
  };

  const deleteCourse = async (id) => {
    try {
      let response = await api(`/lms/course/${id}`, null, 'delete');

      setLoading(false);
      await fetchVariables();
      toast.success('Course deleted successfully');

      if (response?.course) {
        fetchVariables(paging);
      }
      return response;
    } catch (error) {}
  };

  return (
    <coursesContext.Provider
      value={{
        courses,
        paging,
        setPaging,
        filters,
        setFilters,
        loading,
        post,
        put,
        get,
        course,
        deleteCourse,
      }}
    >
      {children}
    </coursesContext.Provider>
  );
};

export const withCourseStore = (Component) => (props) =>
  (
    <CourseProvider>
      <Component {...props} />
    </CourseProvider>
  );
