import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import useStyles from './styles';
import { deleteNote, deleteTemplate } from '../../actions/LMSActions';
import { useDispatch } from 'react-redux';

export default function DeleteModal({ modalOpen, onClose, id, note }) {
  const dispatch = useDispatch();
  const DeleteIcon = () => {
    return (
      <svg
        width="82"
        height="83"
        viewBox="0 0 82 83"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="82" height="82" rx="41" fill="#EF5350" fillOpacity="0.1" />
        <path
          d="M28.9583 54.8333C28.9583 56.9875 30.7208 58.75 32.875 58.75H48.5417C50.6958 58.75 52.4583 56.9875 52.4583 54.8333V31.3333H28.9583V54.8333ZM54.4167 25.4583H47.5625L45.6042 23.5H35.8125L33.8542 25.4583H27V29.375H54.4167V25.4583Z"
          fill="#EF5350"
          fillOpacity="0.5"
        />
      </svg>
    );
  };
  const classes = useStyles();
  const onDelete = () => {
    if (note) {
      dispatch(deleteNote(id));
      onClose()
    } else {
      dispatch(deleteTemplate(id));
    }
  };
  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.container}>
          <DeleteIcon />
          <Typography variant="h6" component="h2" className={classes.title}>
            Are you sure you want to delete this note?
          </Typography>
          <Typography mt={2} className={classes.subtitle}>
            This action cannot be undone.
          </Typography>
          <Box mt={4}>
            <Button onClick={onClose} className={`${classes.cancelBtn} ${classes.deleteAlignment}`}>
              Cancel
            </Button>
            <Button onClick={onDelete} className={`${classes.dltBtn} ${classes.deleteAlignment}`}>
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
