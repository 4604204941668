import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Grid } from '@mui/material';
import { Spacer, Stepper } from 'src/components/shared';

import SetPassword from '../../components/Setup/SetPassword';
import ConfirmPlan from '../../components/Setup/ConfirmPlan';
import Journey from '../../components/Setup/Journey';

import { checkUserVerificationStatus } from '../../api/authApis';

import { withBillingStore } from 'src/modules/admin/utils/billingStore';

function SetupContainer() {
  const [loading, setLoading] = useState(false);
  const [verification, setVerification] = useState({});

  const router = useSelector((state) => state.router);
  const tenant = useSelector((state) => state.app.tenant);
  const user = useSelector((state) => state.auth.user);

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const uid = router?.location?.query?.uid || undefined;
    const tid = router?.location?.query?.tid || undefined;

    if (tid && uid) {
      setLoading(true);
      checkUserVerificationStatus(uid, tid)
        .then((res) => {
          setLoading(false);
          setVerification(res);
        })
        .catch((err) => {
          setLoading(false);
          setVerification({});
        });
    }
  }, [router?.location?.query]);

  const onNext = () => {
    setActiveStep(activeStep + 1);
  };

  const title = useMemo(() => {
    return activeStep === 0
      ? 'Welcome to SuperReach'
      : activeStep === 1
      ? 'Confirm your plan'
      : 'You’re all set!';
  }, [activeStep]);

  return (
    <>
      <Typography variant="h1" sx={{ fontSize: '54px', fontWeight: '600', textAlign: 'center' }}>
        {title}
      </Typography>
      <Spacer x={6} y={6} />
      <Grid container>
        <Grid item xs={12} md={2} />
        <Grid item xs={12} md={8}>
          <Stepper
            sx={{
              '& .MuiStepIcon-root.Mui-active': { color: '#8eccf8 !important' },
              '& .MuiStepConnector-root': { background: '#848688 !important' },
            }}
            steps={['Create account', 'Confirm plan', 'Start your journey']}
            activeStep={activeStep}
          />
          {activeStep === 0 && (
            <SetPassword
              verifying={loading}
              query={router?.location?.query || {}}
              onNext={onNext}
            />
          )}
          {activeStep === 1 && (
            <ConfirmPlan onNext={onNext} verification={verification} tenant={tenant} user={user} />
          )}
          {activeStep === 2 && <Journey onNext={onNext} />}
        </Grid>
      </Grid>
    </>
  );
}

export default withBillingStore(SetupContainer);
