import { all, fork, put, call, select, takeLatest, takeEvery } from 'redux-saga/effects';
import * as resellerTypes from '../actions/resellerTypes';
import * as resellerActions from '../actions/resellerActions';
import * as resellerApis from '../api/resellerApis';
import toast from 'src/utils/toast';

const getAutomations = (state) => state.reseller.automations;

function* fetchResellerReport({ filters, paging, resellerFilters }) {
  try {
    let filter = {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      ...filters,
      ...(resellerFilters?.createdAtRange
        ? {
            createdAt_gte: resellerFilters.createdAtRange?.startDate,
            createdAt_lte: resellerFilters.createdAtRange?.endDate,
          }
        : {}),
    };
    const data = yield call(resellerApis.fetchResellerReport, filter);
    yield put(resellerActions.setResellerReport(data));
  } catch (error) {}
}

function* fetchResellerReportSummary({ resellerFilters }) {
  let filter = {
    ...(resellerFilters?.createdAtRange
      ? {
          createdAt_gte: resellerFilters.createdAtRange?.startDate,
          createdAt_lte: resellerFilters.createdAtRange?.endDate,
        }
      : {}),
  };
  try {
    const data = yield call(resellerApis.fetchResellerReportSummary, filter);
    yield put(resellerActions.setResellerReportSummary(data.stats));
  } catch (error) {}
}

function* fetchResellerTenantReport({ tenantId, filters, paging, resellerFilters }) {
  try {
    let filter = {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      ...filters,
      ...(resellerFilters?.createdAtRange
        ? {
            createdAt_gte: resellerFilters.createdAtRange?.startDate,
            createdAt_lte: resellerFilters.createdAtRange?.endDate,
          }
        : {}),
    };
    const data = yield call(resellerApis.fetchResellerTenantReport, tenantId, filter);
    yield put(resellerActions.setResellerTenantReport(data));
  } catch (error) {}
}

function* fetchResellerTenantReportSummary({ tenantId, resellerFilters }) {
  try {
    let filter = {
      ...(resellerFilters?.createdAtRange
        ? {
            createdAt_gte: resellerFilters.createdAtRange?.startDate,
            createdAt_lte: resellerFilters.createdAtRange?.endDate,
          }
        : {}),
    };
    const data = yield call(resellerApis.fetchResellerTenantReportSummary, tenantId, filter);
    yield put(resellerActions.setResellerTenantReportSummary(data.stats));
  } catch (error) {}
}

function* fetchResellerUserReportSummary({ tenantId, userId, resellerFilters }) {
  try {
    let filter = {
      ...(resellerFilters?.createdAtRange
        ? {
            createdAt_gte: resellerFilters.createdAtRange?.startDate,
            createdAt_lte: resellerFilters.createdAtRange?.endDate,
          }
        : {}),
    };
    const data = yield call(
      resellerApis.fetchResellerTenantUserReportSummary,
      tenantId,
      userId,
      filter,
    );
    yield put(resellerActions.setResellerUserReportSummary(data.stats));
  } catch (error) {}
}

function* fetchResellerUserReport({ tenantId, userId, filters, paging, resellerFilters }) {
  try {
    let filter = {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      ...filters,
      ...(resellerFilters?.createdAtRange
        ? {
            createdAt_gte: resellerFilters.createdAtRange?.startDate,
            createdAt_lte: resellerFilters.createdAtRange?.endDate,
          }
        : {}),
    };
    const data = yield call(resellerApis.fetchResellerTenantUserReport, tenantId, userId, filter);
    yield put(resellerActions.setResellerUserReport(data));
  } catch (error) {}
}

function* fetchAutomations({ filters, paging }) {
  try {
    const data = yield call(resellerApis.fetchAutomations, {});
    yield put(resellerActions.setAutomations(data));
  } catch (error) {}
}

function* postAutomation({ payload, resolve, reject }) {
  try {
    let automationsData = yield select(getAutomations);
    let automations = automationsData.data;
    const automation = yield call(resellerApis.createAutomation, payload);
    automations = [...automations, automation.automation[0]];
    yield put(
      resellerActions.setAutomations({ automations, total: automationsData?.paging?.count || 0 }),
    );
    resolve(true);
    toast.success('Automation added successfully!');
  } catch (error) {
    toast.error(error?.error?.message || 'An error occurred! Please try again.');
    reject(error);
  }
}

function* putAutomation({ automationId, payload, resolve, reject }) {
  try {
    const response = yield call(resellerApis.updateAutomation, automationId, payload);
    toast.success('Automation updated!');

    let automationsData = yield select(getAutomations);
    let automations = automationsData.data;
    automations = automations.map((item) => {
      if (item.id === automationId) {
        return response.automation[response.automation.length - 1];
      } else {
        return item;
      }
    });
    yield put(
      resellerActions.setAutomations({ automations, total: automationsData?.paging?.count || 0 }),
    );
    resolve(true);
  } catch (error) {
    toast.error(error?.error?.message || 'An error occurred! Please try again.');
    reject(error);
  }
}

function* deleteAutomation({ automationId, resolve, reject }) {
  try {
    const response = yield call(resellerApis.deleteAutomation, automationId);
    // let automations = yield select(getAutomations);
    let automationsData = yield select(getAutomations);
    let automations = automationsData.data;

    automations = automations.filter((item) => item.id !== automationId);
    yield put(
      resellerActions.setAutomations({ automations, total: automationsData?.paging?.count || 0 }),
    );
    toast.success('Automation deleted!');
    resolve(true);
  } catch (error) {
    toast.error(error?.error?.message || 'An error occurred! Please try again.');
  }
}

export function* watchSagas() {
  yield takeLatest(resellerTypes.FETCH_RESELLER_REPORT, fetchResellerReport);
  yield takeLatest(resellerTypes.FETCH_RESELLER_TENANT_REPORT, fetchResellerTenantReport);
  yield takeLatest(resellerTypes.FETCH_RESELLER_REPORT_SUMMARY, fetchResellerReportSummary);
  yield takeLatest(
    resellerTypes.FETCH_RESELLER_TENANT_REPORT_SUMMARY,
    fetchResellerTenantReportSummary,
  );
  yield takeLatest(
    resellerTypes.FETCH_RESELLER_USER_REPORT_SUMMARY,
    fetchResellerUserReportSummary,
  );
  yield takeLatest(resellerTypes.FETCH_RESELLER_TENANT_REPORT, fetchResellerTenantReport);
  yield takeLatest(resellerTypes.FETCH_RESELLER_USER_REPORT, fetchResellerUserReport);

  yield takeLatest(resellerTypes.FETCH_AUTOMATIONS, fetchAutomations);
  yield takeLatest(resellerTypes.POST_AUTOMATION, postAutomation);
  yield takeLatest(resellerTypes.PUT_AUTOMATION, putAutomation);
  yield takeLatest(resellerTypes.DELETE_AUTOMATION, deleteAutomation);
}

export default function* runSagas() {
  yield all([fork(watchSagas)]);
}
