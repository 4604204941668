import React, { useMemo, useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Timeline from '@mui/lab/Timeline';
import { ButtonBase, Typography } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AddNew from './AddNew';

import {
  LinkedinCard,
  WaitTimeCard,
  EndSequenceCard,
  EmailCard,
  SelectEmailCard,
  CallbackCard,
  TaskCard,
  SmsCard,
} from '../../SequenceEvents';

import Skeleton from './Skeleton';

import { TimelineButton, EventContainer, TimelineFiller } from './styles';
import { ConfirmDialog } from 'src/components/App';
import EmptyView from './EmptyView';

function SequenceSteps({ sequence, sequenceEvents, loading, ...props }) {
  const [showAddForm, setShowAddForm] = useState(false);
  const [addStepIndex, setAddStepIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  const [action, setAction] = useState('');
  const [actionEvent, setActionEvent] = useState({});
  const [actionTemplate, setActionTemplate] = useState({});

  const events = sequenceEvents;

  if (!events) return null;

  useEffect(() => {
    // Object.keys(events).map((key) => {
    //   const event = events[key];
    //   console.log(
    //     'EVENT : ',
    //     event.eventType,
    //     event.eventType === 'wait' ? ` (${event.waitTime}${event.unit})` : '',
    //     ' ORDER : ',
    //     event.eventOrder,
    //   );
    // });
    toggleForm(props?.new && !Object.keys(events).length);
  }, [events]);

  const hasEndSeq = useMemo(
    () => Object.values(events).filter((event) => event.eventType === 'endsquence').length > 0,
    [events],
  );

  const handleDragEnd = (data) => {
    const event = events[Object.keys(events)[data.source.index]];
    props.handleEventsReorder(sequence.id, event, data.destination.index);
  };

  const onDelete = (event) => {
    setAction('delete');
    setActionEvent(event);
  };

  const onEdit = (event, template = {}) => {
    setAction('edit');
    setActionEvent(event);
    setActionTemplate(template);
  };

  const onAddTemplate = (event) => {
    setAction('addTemplate');
    setActionEvent(event);
  };

  const renderEventCard = (event) => {
    switch (event.eventType) {
      case 'linkedin':
      case 'linkedinConnection':
      case 'linkedinMessage':
      case 'linkedinMail':
      case 'linkedinViewProfile':
        return (
          <LinkedinCard
            onDelete={() => onDelete(event)}
            onEdit={() => onEdit(event)}
            event={event}
          />
        );
        break;

      case 'wait':
        return (
          <WaitTimeCard
            event={event}
            onDelete={() => onDelete(event)}
            onEdit={() => onEdit(event)}
          />
        );
        break;

      case 'email':
        return (
          <EmailCard
            sequence={sequence}
            event={event}
            onDelete={() => onDelete(event)}
            onAddTemplate={() => onAddTemplate(event)}
            onEdit={onEdit}
            putSequenceEvent={props.putSequenceEvent}
            updateTemplateStatus={props.updateTemplateStatus}
            {...props}
          />
        );
        break;

      case 'selectEmailTemplate':
        return (
          <SelectEmailCard
            sequence={sequence}
            event={event}
            onDelete={() => onDelete(event)}
            putSequenceEvent={props.putSequenceEvent}
          />
        );
        break;

      case 'call':
        return (
          <CallbackCard
            event={event}
            onDelete={() => onDelete(event)}
            onEdit={() => onEdit(event)}
          />
        );
        break;

      case 'general':
        return (
          <TaskCard event={event} onDelete={() => onDelete(event)} onEdit={() => onEdit(event)} />
        );
        break;

      case 'sms':
        return (
          <SmsCard event={event} onDelete={() => onDelete(event)} onEdit={() => onEdit(event)} />
        );
        break;

      case 'specjob':
        return (
          <SelectEmailCard
            event={event}
            onDelete={() => onDelete(event)}
            onEdit={() => onEdit(event)}
          />
        );
        break;

      case 'endsquence':
      default:
        return (
          <EndSequenceCard
            event={event}
            onDelete={() => onDelete(event)}
            onEdit={() => onEdit(event)}
          />
        );
        break;
    }
  };

  const toggleForm = (show = false, index = 0) => {
    if (show === true) {
      setShowAddForm(true);
      setAddStepIndex(index);
    } else {
      setShowAddForm(false);
      setAddStepIndex(0);
      setAction('');
      setActionEvent({});
      setActionTemplate({});
    }
  };

  const handleSubmit = (data) => {
    const payload = JSON.parse(JSON.stringify(data));

    console.log('payload : 111 : ', payload);

    if (action === 'addTemplate' && actionEvent.id && actionEvent.id !== '') {
      const payloadData = {
        emailTemplates: [
          ...actionEvent.emailTemplates.map((item) => item.id),
          ...payload.emailTemplates,
        ],
        templateProps: {
          ...actionEvent.templateProps,
          [payload.emailTemplates[0]]: {
            status: true,
          },
        },
      };
      return props.putSequenceEvent(sequence.id, actionEvent?.id, payloadData);
    } else if (action === 'edit' && actionEvent.id && actionEvent.id !== '') {
      return props.putSequenceEvent(sequence.id, actionEvent?.id, payload);
    } else {
      if (Object.keys(events).length > 0) {
        payload.eventOrder = addStepIndex + 1;
      } else {
        payload.eventOrder = addStepIndex;
      }
      return props.postSequenceEvent(sequence.id, payload);
    }
  };

  const onDeleteConfirm = async () => {
    try {
      setIsDeleting(true);
      await props.deleteSequenceEvent(sequence.id, actionEvent.id);
      setIsDeleting(false);
      clearAction();
    } catch (error) {
      setIsDeleting(false);
    }
  };

  const clearAction = () => {
    setAction('');
    setActionEvent({});
  };

  if (loading) {
    return <Skeleton />;
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      {Object.keys(events).length ? (
        <Droppable droppableId="droppable" direction="vertical" key="events-droppable-1">
          {(droppableProvided) => (
            <Timeline
              key="events-droppable"
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {Object.keys(events).map((key) => {
                const event = events[key];
                const index = Object.keys(events).indexOf(key);
                return (
                  <div key={`sequence-stp-${key}`}>
                    <Draggable draggableId={`event-${key}`} index={index}>
                      {(draggableProvided, snapshot) => {
                        return (
                          <EventContainer
                            fullWidth={event.eventType !== 'wait'}
                            eventType={event.eventType}
                            key={`sequence-stp-content-${index}`}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            style={{
                              ...draggableProvided.draggableProps.style,
                              background: snapshot.isDragging ? 'rgba(245,245,245, 0.75)' : 'none',
                            }}
                          >
                            {renderEventCard(event)}
                            {event.eventType !== 'endsquence' ? (
                              <ButtonBase {...draggableProvided.dragHandleProps}>
                                <DragIndicatorIcon
                                  style={{
                                    fill: 'rgba(0,0,0,0.36)',
                                    marginLeft: '4px',
                                  }}
                                />
                              </ButtonBase>
                            ) : (
                              <div style={{ width: 28, height: 56 }}>
                                <div {...draggableProvided.dragHandleProps} />
                              </div>
                            )}
                          </EventContainer>
                        );
                      }}
                    </Draggable>
                    {event.eventType !== 'endsquence' ? (
                      <TimelineButton
                        onClick={() => {
                          toggleForm(true, event.eventOrder);
                        }}
                      />
                    ) : (
                      <TimelineFiller />
                    )}
                  </div>
                );
              })}
            </Timeline>
          )}
        </Droppable>
      ) : (
        <EmptyView
          onClickAdd={() => {
            toggleForm(true);
          }}
        />
      )}

      <AddNew
        open={
          showAddForm ||
          (action === 'edit' && actionEvent?.id && actionEvent?.id !== '') ||
          (action === 'addTemplate' && actionEvent?.id && actionEvent?.id !== '')
        }
        addStepIndex={addStepIndex}
        events={events}
        onClose={toggleForm}
        onSubmit={handleSubmit}
        hasEndSeq={hasEndSeq}
        // action={action === 'edit' ? 'edit' : 'add'}
        action={action}
        event={actionEvent}
        template={actionTemplate}
      />
      {/* <EmptyView /> */}
      <ConfirmDialog
        title="Delete step"
        open={action === 'delete' && actionEvent?.id ? true : false}
        onClose={clearAction}
        onConfirm={onDeleteConfirm}
        loading={isDeleting}
      >
        <Typography variant="body1" color="textSecondary">
          Are you sure you want to delete this step?
        </Typography>
      </ConfirmDialog>
    </DragDropContext>
  );
}

export default SequenceSteps;
