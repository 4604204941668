import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tooltip, Typography } from '@mui/material';
import { Button, Spacer, Drawer } from 'src/components/shared';
import SendIcon from '@mui/icons-material/Send';
import EmailIcon from '@mui/icons-material/Email';
import DeleteIcon from '@mui/icons-material/Delete';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { ProfileActionsWrapper, ActionButton, ProfileAction, ActionButtonWrapper } from './styles';
import { ConfirmDialog } from 'src/components/App';

function ProfileActions({ toggleContactActionDrawer, contact, ...props }) {
  const history = useHistory();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmLoader, setConfirmLoader] = useState(false);

  const handleDeleteUser = async () => {
    try {
      setIsLoading(true);
      await props.deleteContact(contact.id);
      props.fetchSequenceContacts(
        props?.sequence?.sequencesContacts?.paging,
        props?.sequence?.sequencesContacts?.filters,
        props?.sequence?.sequence?.id,
      );
      setIsLoading(false);
    } catch (error) {}
  };

  const handleUserDeleteConfirmation = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const isUnsubscribed = contact.status === 'unsubscribed';

  return (
    <>
      <ProfileActionsWrapper>
        <Tooltip
          title={
            isUnsubscribed
              ? 'Cannot perform this action on an unsubscribed user.'
              : 'Add to sequence'
          }
        >
          <ActionButtonWrapper>
            <ActionButton
              disabled={isUnsubscribed}
              type="sequence"
              iconButton={true}
              onClick={() => toggleContactActionDrawer('sequence')}
            >
              <SendIcon />
            </ActionButton>
            <ProfileAction variant="caption">Sequence</ProfileAction>
          </ActionButtonWrapper>
        </Tooltip>
        <Tooltip
          title={
            isUnsubscribed ? 'Cannot perform this action on an unsubscribed user.' : 'Send Email'
          }
        >
          <ActionButtonWrapper>
            <ActionButton
              disabled={isUnsubscribed}
              type="email"
              iconButton={true}
              onClick={() => toggleContactActionDrawer('emailForm')}
            >
              <EmailIcon />
            </ActionButton>
            <ProfileAction variant="caption">Email</ProfileAction>
          </ActionButtonWrapper>
        </Tooltip>
        <Tooltip
          title={
            isUnsubscribed ? 'Cannot perform this action on an unsubscribed user.' : 'Log activity'
          }
        >
          <ActionButtonWrapper>
            <ActionButton
              disabled={isUnsubscribed}
              type="note"
              iconButton={true}
              onClick={() => toggleContactActionDrawer('leaveNote')}
            >
              <NoteAddIcon />
            </ActionButton>
            <ProfileAction variant="caption">Note</ProfileAction>
          </ActionButtonWrapper>
        </Tooltip>
        <Tooltip
          title={
            isUnsubscribed
              ? 'Cannot perform this action on an unsubscribed user.'
              : 'Delete contact'
          }
        >
          <ActionButtonWrapper>
            <ActionButton
              disabled={isUnsubscribed}
              type="delete"
              iconButton={true}
              onClick={handleUserDeleteConfirmation}
            >
              <DeleteIcon />
            </ActionButton>
            <ProfileAction variant="caption">Delete</ProfileAction>
          </ActionButtonWrapper>
        </Tooltip>
      </ProfileActionsWrapper>
      <ConfirmDialog
        title="Delete Contact"
        open={isDialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDeleteUser}
      >
        <Typography variant="body1" color="textSecondary">
          Are you sure you want to delete this contact?
        </Typography>
      </ConfirmDialog>
    </>
  );
}

export default ProfileActions;
