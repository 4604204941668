import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({ palette }) => ({
  checkBoxChecked: {
    '&.Mui-checked': {
      color: `${palette.primary.main}`,
      '&:focus': {
        backgroundColor: `${palette.primary.main} !important`,
      },
    },
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26) !important',
    },
  },
  checkBoxRoot: {
    '&:focus': {
      backgroundColor: `${palette.primary.main} !important`,
    },
  },
}));

const CheckboxLabel = ({
  checked = false,
  handleChange = () => {},
  name = '',
  label = '',
  checkboxProps = {},
  ...props
}) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={handleChange}
          name={name}
          classes={{
            checked: classes.checkBoxChecked,
            root: classes.checkBoxRoot,
          }}
          {...checkboxProps}
        />
      }
      label={label}
      style={{ ...props.style, width: '100%' }}
      {...props}
    />
  );
};

export default CheckboxLabel;
