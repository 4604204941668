import React from 'react';
import { Box, Typography, List, CircularProgress, Stack, MenuItem } from '@mui/material';
import { Button, Spacer } from 'src/components/shared';
import { getTaskTypeText } from '../../utils/tasksUtils';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import moment from 'moment';
import { getEnrollmentStatusLabel } from 'src/modules/sequence/utils/sequenceHelper';
import { getSequenceStatusColorCode } from 'src/modules/contacts/utils/contactUtils';
import { enqueueSequencePersonalize } from 'src/modules/sequence/actions/enrolmentActions';
import { useDispatch, useSelector } from 'react-redux';
import { PendingCircle } from 'src/img/icons/icons';

function SequenceStages({ seqStag, enrolment, enrolmentLoading }) {
  if (!enrolment?.tasks?.length) return null;

  const dispatch = useDispatch();

  const tasks =
    enrolment.tasks
      .map((task) => {
        return {
          ...task,
          status: ['completed', 'replied'].includes(task?.status) ? 'completed' : task?.status,
        };
      })
      .sort((a, b) => {
        const statusOrder = { completed: 5, outofoffice: 4, error: 3, pending: 2, hold: 1 };
        if (statusOrder[b.status] < statusOrder[a.status]) return -1;
        if (statusOrder[b.status] > statusOrder[a.status]) return 1;
        return 0;
      }) || [];

  const sequenceEnrollments = useSelector((state) => state?.contacts?.sequenceEnrollment);
  const setActiveEnrolment = () => {
    const enrolments = Object.assign(
      {},
      ...sequenceEnrollments
        .filter((enrollment) => enrollment?.id === seqStag?.id)
        .map((enrollment) => ({ [enrollment.id]: enrollment })),
    );
    dispatch(enqueueSequencePersonalize(enrolments, 'edit'));
  };

  const nextSteps = (completed, total) => {
    if (enrolment?.status === 'inProgress') {
      return `Next step ${completed} of ${total}`;
    } else if (enrolment?.status === 'notInterested') {
      return `Step ${completed} of ${total}`;
    } else if (enrolment?.status === 'interested') {
      return `Step ${completed} of ${total}`;
    } else {
      return `Next step ${completed} of ${total}`;
    }
  };

  return (
    <Box sx={{ width: 320 }}>
      <Button
        iconButton
        sx={{ position: 'absolute', top: 10, right: 10 }}
        onClick={() => {
          window.open(`/sequence/${seqStag.sequence.id}`, '_blank');
        }}
      >
        <OpenInNewIcon sx={{ fontSize: 24, color: '#1976D2 !important' }} />
      </Button>
      <Box px={2} pt={1}>
        <Typography variant="body1" color="textPrimary" fontWeight={400} fontSize={'18px'}>
          {seqStag.sequence.name}
        </Typography>
        {!enrolmentLoading && enrolment?.status && enrolment?.status !== '' && (
          <>
            <Spacer y={1} />
            <Stack direction={'flex'} gap="5px">
              <Typography
                variant="body1"
                color={getSequenceStatusColorCode(enrolment.status)?.color}
              >
                {getEnrollmentStatusLabel(enrolment.status)}
              </Typography>
              <Typography variant="body1">
                {nextSteps(
                  tasks?.filter((task) => ['completed'].includes(task?.status)).length,
                  tasks?.length,
                )}
              </Typography>
            </Stack>
          </>
        )}
      </Box>

      {enrolmentLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center" p={2}>
          <CircularProgress size={20} color="secondary" />
        </Box>
      ) : (
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          {tasks
            ?.sort((a, b) => a?.taskOrderInSequence - b?.taskOrderInSequence)
            ?.map((item, id) => ({
              ...item,
              index: id,
            }))
            ?.filter((_, id, arr) => {
              if (arr.length < 5) {
                return true;
              } else {
                let pendingIndex = arr.findIndex((item) => item.status === 'pending');
                if (pendingIndex < 2) {
                  if (id < 5) {
                    return true;
                  } else {
                    return false;
                  }
                } else if (pendingIndex < arr.length - 4) {
                  if (pendingIndex - 2 < id && id < pendingIndex + 4) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  if (id > arr.length - 6) {
                    return true;
                  } else {
                    return false;
                  }
                }
              }
            })
            ?.map((task, index) => (
              <MenuItem className={`menu-list menu-${task.name}-seqData-action-items-${index}`}>
                {task.status === 'completed' ? (
                  <Box className="check-circle">
                    <CheckCircleOutlineIcon
                      sx={{
                        fill: '#709d74 !important',
                      }}
                    />
                  </Box>
                ) : task.status === 'error' ? (
                  <Box className="error-circle">
                    <ErrorIcon />
                  </Box>
                ) : task.status === 'pending' ? (
                  <Box className="pending-circle">
                    <PendingCircle />
                  </Box>
                ) : (
                  <Box className="incomplete-circle">
                    <PanoramaFishEyeIcon />
                  </Box>
                )}
                <Box>
                  <Box className="sequence-launch launch-icon">
                    <Typography onClick={setActiveEnrolment} sx={{ color: '#1976D2' }}>{`${
                      task?.index + 1
                    }. ${getTaskTypeText(task.taskType)}`}</Typography>
                  </Box>
                  <Box className="sequence-launch schedule-date">
                    {task?.status ? (
                      <Typography
                        sx={
                          task.status === 'completed' || task.status === 'pending'
                            ? { color: 'rgba(0,0,0,0.60)' }
                            : { color: 'rgba(0,0,0,0.38)' }
                        }
                      >
                        {task.status === 'completed'
                          ? task?.completedOn || task?.repliedOn
                            ? `Completed - ${moment(task?.completedOn || task?.repliedOn).format(
                                'Do MMMM',
                              )}`
                            : 'Completed'
                          : task.status === 'outofoffice'
                          ? `Out of office ${
                              task?.repliedOn
                                ? '- ' + moment(task?.repliedOn).format('Do MMMM')
                                : ''
                            }`
                          : task.status === 'pending'
                          ? task?.scheduledOn
                            ? `Due - ${moment(task?.scheduledOn).format('Do MMMM')}`
                            : 'Due'
                          : 'Not yet scheduled'}
                      </Typography>
                    ) : (
                      <Typography>Not yet scheduled</Typography>
                    )}
                  </Box>
                </Box>
              </MenuItem>
            ))}
        </List>
      )}
    </Box>
  );
}

export default SequenceStages;
