import moment from 'moment';
import api from 'src/api';

export const truncate = (input, viewMoreLink, limit = 200) => {
  if (viewMoreLink)
    return input?.length > limit
      ? `${input.substring(0, limit)}...` +
          `<a href='${viewMoreLink}' style="color:aliceblue; font-weight:bold";>View more</a>`
      : input;
  else {
    return input?.length > limit ? `${input.substring(0, limit)}...` : input;
  }
};
export const viewMoreString = ({
  input,
  limit,
  showAll,
  viewMoreEclipse = ' ...',
  viewLessEclipse = '',
  viewMoreComponent = '',
  viewLessComponent = '',
}) => {
  return input.length ? (
    !showAll ? (
      <div>
        {input.substring(0, limit)}
        {viewMoreEclipse}
        {viewMoreComponent}
      </div>
    ) : (
      <div>
        {input}
        {viewLessEclipse}
        {viewLessComponent}
      </div>
    )
  ) : null;
};
export const removeHTML = (input, viewMoreLink, limit = 200) => {
  const cleanText = input?.replace(/<\/?[^>]+(>|$)/g, '');
  return truncate(cleanText, viewMoreLink, limit);
};
export const calculateDaysLeft = (endDate) => {
  let startDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  let endingDate = moment(new Date(endDate));

  let daysLeft = endingDate.diff(startDate, 'days');
  if (daysLeft <= 0) {
    return 0;
  }
  return daysLeft;
};
export const getIFrameCode = (embedURL, session, competition) => {
  if (embedURL && embedURL !== '') {
    return `<iframe id='botIframe' src="${embedURL}&name=${session?.title}&type=${
      competition?.id ? 'competition' : 'normal'
    }" playsinline="" allow="autoplay; fullscreen; picture-in-picture; xr-spatial-tracking; encrypted-media; microphone" style="width: 100%; height: 100%; position: relative; border: none;"></iframe>`;
  } else {
    return undefined;
  }
};

export const checkCompetitionValid = (session, user) => {
  if (session?.competitions?.length) {
    let competitions = session?.competitions;
    for (let i = 0; i < competitions.length; i++) {
      if (competitions[i].status === 'live') {
        if (calculateDaysLeft(competitions[i].endDate)) {
          if (competitions[i].users?.findIndex((item) => item.id === user) !== -1) {
            return competitions[i];
          }
        }
      }
    }
  }
  return null;
};
export const calculateTheDate = (type) => {
  if (!type) return null;
  let { value } = type;
  let startDate, endDate;
  switch (value) {
    case 1:
      startDate = moment().day(1);
      endDate = moment();
      break;
    case 2:
      startDate = moment().day(-6);
      endDate = moment().day(0);
      break;
    case 3:
      startDate = moment().subtract(3, 'weeks').startOf('week');
      endDate = moment().subtract(0, 'weeks').startOf('week');
      break;
    case 4:
      startDate = moment().month(-3);
      endDate = moment().month(-1);
      break;
  }
  startDate = startDate.format('YYYY-MM-DD');
  endDate = endDate.format('YYYY-MM-DD');

  return {
    startDate,
    endDate,
  };
};

export const calculateDaysAgo = (item) => {
  let today = moment();
  let updatedDate = moment(item.updatedAt);
  let diff = today.diff(updatedDate, 'days');
  if (diff === 0) {
    return 'Today';
  }
  if (diff === 1) {
    return `${diff} day ago`;
  }
  return `${diff} days ago`;
};
export const calculateListDaysAgo = (item) => {
  let today = moment();
  let createdAt = moment(item.createdAt);
  let diff = today.diff(createdAt, 'days');
  if (diff === 0) {
    return 'Today';
  }
  if (diff === 1) {
    return `${diff} day ago`;
  }
  return `${diff} days ago`;
};

export const calculateIsToday = (item) => {
  const momentGivenDate = moment(item?.updatedAt);
  const isToday = momentGivenDate.isSame(moment(), 'day');
  if (isToday) {
    return item;
  }
};
export const calculateIsLastWeek = (item) => {
  const givenDate = moment(item?.updatedAt);
  const momentGivenDate = givenDate.format('YYYY-MM-DD');
  const lastWeekStart = moment().subtract(1, 'weeks').startOf('week');
  const lastWeekEnd = moment().subtract(1, 'weeks').endOf('week');
  const isLastWeek = moment(momentGivenDate, 'YYYY-MM-DD').isBetween(
    lastWeekStart,
    lastWeekEnd,
    null,
    '[]',
  );
  if (isLastWeek) {
    return item;
  }
};
export const calculateIsLastMonth = (item) => {
  const momentGivenDate = moment(item?.updatedAt);
  const currentDate = moment();
  const isLastMonth = moment(momentGivenDate).isBefore(currentDate, 'month');
  if (isLastMonth) {
    return item;
  }
};
export const TimeOptions = [
  {
    label: 'Show all time',
    value: 'allTime',
  },
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'Last week',
    value: 'lastWeek',
  },
  {
    label: 'Last month',
    value: 'lastMonth',
  },
];
export const TimeDifferenceCalculator = (targetTime) => {
  const targetDate = new Date(targetTime);
  const currentDate = new Date();
  const differenceInMillis = targetDate.getTime() - currentDate.getTime();
  const positiveDifference = Math.abs(differenceInMillis);
  const seconds = Math.floor(positiveDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const timeDifferenceParts = [];
  if (days > 0) timeDifferenceParts.push(`${days} days`);
  if (hours % 24 > 0) timeDifferenceParts.push(`${hours % 24} hours`);
  if (minutes % 60 > 0) timeDifferenceParts.push(`${minutes % 60} minutes`);

  if (!minutes % 60 > 0) timeDifferenceParts.push(`${seconds % 60} seconds`);

  const timeDifferenceString = timeDifferenceParts.join(', ');
  return timeDifferenceString;
};

// this function is to manipulate the iframe src so that we can send the new start time search param in video player
export const changeSRC = (iframeString, startTime) => {
  // Convert the string to a DOM element
  var tempDiv = document.createElement('div');
  tempDiv.innerHTML = iframeString;
  var iframeElement = tempDiv.firstChild;

  // Modify the src attribute
  let src = iframeElement.src + `&startTime=${startTime}`;
  iframeElement.src = src;

  // Convert the DOM element back to a string
  return tempDiv.innerHTML;
};

export const calculateDaysLeftForRoleplay = (endDate) => {
  let startDate = moment().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  let endingDate = moment(new Date(endDate));
  let daysLeft = endingDate.diff(startDate, 'days');
  if (isNaN(daysLeft)) {
    return '-';
    return '0 day';
  }
  if (daysLeft <= 0) {
    return '-';
    return `0 day`;
  }
  if (daysLeft < 7) {
    return daysLeft === 1 ? `1 day` : `${daysLeft} days`;
  }
  daysLeft = endingDate.diff(startDate, 'weeks');
  return `${daysLeft} weeks`;
};

export const calculatePercent = (row) => {
  if (!row.startDate || !row.endDate) {
    return 0;
  }
  let startDate = moment(row.startDate).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  let today = moment().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  let endDate = moment(row.endDate);
  let daysLeft = endDate.diff(startDate, 'days');
  let actualLeft = endDate.diff(today, 'days');
  actualLeft = actualLeft >= 0 ? actualLeft : 0;
  if (actualLeft === 0) {
    return 0;
  }

  let percentage = (actualLeft / daysLeft) * 100;
  return percentage;
};
//Competition Form//
export const sessionOptions = async (val) => {
  try {
    let data = await api(`/lms/session?_search=${val}&_size=${300}`, null, 'get');
    let optionList = [];
    data?.sessions?.map((session, index) => {
      if (session?.showRoleplay === true) {
        const option = {
          label: session?.title,
          value: session?.id,
        };
        optionList.push(option);
      }
    });
    return optionList;
  } catch (error) {}
};
export const frequencyOptions = (val) => {
  let optionList = [
    {
      label: 'Daily',
      value: 'daily',
    },
    {
      label: 'Weekly',
      value: 'weekly',
    },
    {
      label: 'Monthly',
      value: 'monthly',
    },
  ];

  return optionList;
};
export const prizeOptions = (prizes) => {
  let optionList = [];
  prizes?.map((option) => {
    optionList.push({
      label: option.title1,
      value: option.id,
    });
  });
  return optionList;
};
export const roleplayOptions = (val) => {
  let optionList = [];
  for (let i = 1; i <= 100; i++) {
    const option = {
      label: i.toString(),
      value: i,
    };

    optionList.push(option);
  }
  return optionList;
};
export const minTargetOptions = (val) => {
  let optionList = [];
  for (let i = 1; i <= 5; i += 0.5) {
    const option = {
      label: i.toString(),
      value: i,
    };

    optionList.push(option);
  }
  return optionList;
};
export const userOptions = async (val, chipValues) => {
  try {
    let data = await api(`/user?_search=${val}&_size=${300}`, null, 'get');
    let optionList = [];
    const filteredUser = data?.users?.filter((user) => {
      const users = [];
      chipValues.map((usr) => {
        users.push(usr.value);
      });
      if (users.includes(user.id)) {
        return;
      } else {
        return user;
      }
    });
    filteredUser.map((el) => {
      const option = {
        label: el.fname + ' ' + el?.lname,
        value: el?.id,
      };
      optionList.push(option);
    });
    return optionList;
  } catch (error) {}
};
