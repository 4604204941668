import api from 'src/api';
import { convertObjectToQuerystring } from 'src/utils/helper';
import { isEmpty } from 'lodash';
import toast from 'src/utils/toast';

export const bhCandidateSearch = (search) => {
  return api(`/crmsync/bhCandidateSearch?name=${search}`, undefined, 'GET');
};

export const fetchSeqStatus = (candidateId) => {
  return api(`/contact/${candidateId}/status`, undefined, 'GET');
};

export const getBullhornMatches = (payload) => {
  return api(`/actions/getbullhorncontacts`, payload, 'post');
};

export const bullhornMatch = (payload) => {
  return api(`/crmsync/crmmatch?service=bullhorn`, payload, 'post');
};

export const getVincereMatches = (payload) => {
  return api(`/crmsync/getcrmcontacts?service=vincere`, payload, 'post');
};

export const vincereMatch = (payload) => {
  return api(`/crmsync/crmmatch?service=vincere`, payload, 'post');
};

export const getSalesforceMatches = (payload) => {
  return api(`/crmsync/getcrmcontacts?service=salesforce`, payload, 'post');
};

export const salesforceMatch = (payload) => {
  return api(`/crmsync/crmmatch?service=salesforce`, payload, 'post');
};

export const getHubspotMatches = (payload) => {
  return api(`/crmsync/getcrmcontacts?service=hubspot`, payload, 'post');
};

export const hubspotMatch = (payload) => {
  return api(`/crmsync/crmmatch?service=hubspot`, payload, 'post');
};

export const getJobAdderMatches = (payload) => {
  return api(`/crmsync/getcrmcontacts?service=jobadder`, payload, 'post');
};

export const jobAdderMatch = (payload) => {
  return api(`/crmsync/crmmatch?service=jobadder`, payload, 'post');
};

export const unsubscribeContact = (contactId, unsubEmail) => {
  return api(`/contact/${contactId}/unsubscribeV2?email=${unsubEmail}`, null, 'get');
};

export const addContactToBullhorn = (contactId, bullhornType) => {
  const payload = {
    bullhornType: bullhornType === 'Contact' ? 'ClientContact' : bullhornType,
  };
  return api(`/contact/${contactId}/addtobullhorn`, payload, 'post');
};

export const addContactToVincere = (contactId, vincereType) => {
  const payload = {
    id: contactId,
    vincereType: vincereType,
  };
  return api(`/crmsync/addtocrm?service=vincere`, payload, 'post');
};

export const addContactToSalesforce = (contactId, salesforceType) => {
  const payload = {
    id: contactId,
    salesforceType: salesforceType,
  };
  return api(`/crmsync/addtocrm?service=salesforce`, payload, 'post');
};

export const addContactToHubspot = (contactId, hubspotType) => {
  const payload = {
    id: contactId,
    hubspotType: hubspotType,
  };
  return api(`/crmsync/addtocrm?service=hubspot`, payload, 'post');
};

export const addContactToJobAdder = (contactId, jobAdderType) => {
  const payload = {
    id: contactId,
    jobadderType: jobAdderType,
  };
  return api(`/crmsync/addtocrm?service=jobadder`, payload, 'post');
};

export const bulkDeleteContactsEnrollments = (contactIds) => {
  const payload = {
    contacts: contactIds,
  };
  return api(`/enrollment/`, payload, 'delete');
};

export const bulkDeleteContacts = (contactIds) => {
  const payload = {
    contacts: contactIds,
  };
  return api(`/contact/bulk`, payload, 'delete');
};

export const bulkAddToCrmContacts = (contactIds, crmContactType) => {
  const payload = { contactIds, crmContactType };
  return api(`/crmsync/bulkaddtocrm`, payload, 'post');
};

export const fetchContactsLookup = async (search) => {
  let res = [];
  const filters = {
    _from: 0,
    _size: 10,
    _search: search,
  };
  let filter = `?${convertObjectToQuerystring(filters)}`;
  res = await api(`/contact${filter}`, null, 'get');
  res = res.contacts;
  if (res && res.length) {
    res = res.map((contact) => {
      const jobTitleStr = `${contact?.jobTitle ? contact?.jobTitle : ''}${
        contact?.jobTitle !== '' && contact?.employer !== '' ? ' at ' : ''
      }${contact?.employer ? contact?.employer : ''}`;

      return {
        ...contact,
        name: `${contact.title && contact.title !== '' ? contact.title + ' ' : ''} ${
          contact.fname
        } ${contact?.lname || ''} ${jobTitleStr && jobTitleStr !== '' ? `(${jobTitleStr})` : ''}`,
      };
    });
  }
  return res;
};
export const fetchContactsCalLookup = async (search) => {
  let res = [];
  const filters = {
    _from: 0,
    _search: search,
  };
  let filter = `?${convertObjectToQuerystring(filters)}`;
  try {
    const response = await api(`/contact${filter}`, null, 'get');
    res = response.contacts.map((i, key) => ({
      id: i.id,
      value: `${i.fname} ${i.lname}`,
    }));
    return res;
  } catch (error) {
    return res;
  }
};

export const fetchEnrollments = (filters, seqId) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/enrollment${filter}&sequence_eq=${seqId}`, null, 'get');
};

export const bulkEnrollments = (data) => {
  return api(`/enrollment/bulkInProgress`, data, 'put');
};

export const fetchEnrollmentsMinimum = (filters, seqId) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/enrollment/getEnrollments${filter}&sequence_eq=${seqId}`, null, 'get');
};

export const addContacts = (contacts) => {
  return api(`/contact`, contacts, 'post');
};

export const updateContacts = (id, contacts) => {
  return api(`/contact/${id}`, contacts.data, 'put');
};

export const fetchContactsElastic = (filters) => {
  // let filter = '';
  // if (!_.isEmpty(filters)) {
  //   filter = `?${convertObjectToQuerystring(filters)}`;
  // }
  return api(`/contact/elastic`, filters, 'post');
};

export const fetchContacts = (filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  // if (filters.status_eq) {
  //   filter = `/filterstatus` + filter;
  // }
  return api(`/contact${filter}`, null, 'get');
};
export const getEnrollment = (id) => {
  return api(`/enrollment/${id}`, 'get');
};
export const putEnrollment = (id, enrollment) => {
  return api(`/enrollment/${id}`, enrollment, 'put');
};
export const fetchContactsSummary = (seqId) => {
  return api(`/enrollment?sequence_eq=${seqId}`, null, 'get');
};

export const fetchContactDetails = (contactId) => {
  return api(`/contact/${contactId}`, null, 'get');
};
export const fetchEnrichSearch = (data) => {
  return api(`/enrich`, data, 'post');
};

export const addEmailBulk = (data) => {
  return api(`/emails/bulk`, data, 'post');
};

export const addPhonesBulk = (data) => {
  return api(`/phones/createBulk`, data, 'post');
};

export const fetchEnrich = async (data) => {
  try {
    return await api(`/enrich`, data, 'post');
  } catch (err) {
    return err;
  }
};

export const fetchContactsTask = (contactId, filters) => {
  return api(`/task?contact_eq=${contactId}${filters ? '&' + filters : ''}`, null, 'get');
};
export const fetchContactsNote = (contactId, filters) => {
  return api(`/note?contact_eq=${contactId}${filters ? '&' + filters : ''}`, null, 'get');
};
export const postContactNote = (data) => {
  return api(`/note`, data, 'post');
};

export const fetchSequenceEnroll = (contactId, filters) => {
  return api(`/enrollment/?contact_eq=${contactId}${filters ? '&' + filters : ''}`, null, 'get');
};

export const updateContactSocialMedia = (contactId, payload) => {
  return api(`/contact/${contactId}/sociallinks`, payload, 'post');
};

export const addContactPhone = (contactId, data) => {
  const payload = {
    ...data,
    contact: contactId,
  };
  return api(`/phones`, payload, 'post');
};

export const addContactEmail = (contactId, data) => {
  const payload = {
    ...data,
    contact: contactId,
  };
  return api(`/emails`, payload, 'post');
};

export const setPrimaryEmail = (payload) => {
  return api(`/setprimaryemail`, payload, 'put');
};

export const setPrimaryPhone = (payload) => {
  return api(`/setprimaryphone`, payload, 'put');
};

export const editContactPhone = (contactId, itemId, data) => {
  const payload = {
    ...data,
    contact: contactId,
  };
  return api(`/phones/${itemId}`, payload, 'put');
};

export const editContactEmail = (contactId, itemId, data) => {
  const payload = {
    ...data,
    contact: contactId,
  };
  return api(`/emails/${itemId}`, payload, 'put');
};

export const deleteContactDetailItem = (type, itemId) => {
  return api(`/${type}/${itemId}/`, null, 'delete');
};

export const createContact = (contact) => {
  return api('/candidate/add', contact, 'post');
};

export const updateContact = (contactId, contact) => {
  contact.contactId = contact;
  return api('/candidate/update-contact-details', contact, 'post');
};

export const deleteContact = (contactId) => {
  return api(`/contact/${contactId}`, null, 'delete');
};

export const fetchContactOptionsFilters = () => {
  return api('/candidate/contact-filters', null, 'get');
};

export const getContactTabs = () => {
  return api('/automation/tabs/get-tabs?object=contacts', null, 'get');
};

export const getNoteType = () => {
  return api(`/note/types`, null, 'get');
};
