import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PillarDetail from '../components/LMSPillar/components/PillarDetails';
import { fetchPillar } from '../actions/LMSActions';

const LMSSessionDetailContainer = (props) => {
  const pillarId = props.match.params.id;

  useEffect(() => {
    props.fetchPillar(pillarId);
  }, [props.match.params.id]);

  return (
    <>
      <PillarDetail {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({
  pillar: state.lms.pillar,
  loading: state.lms.loading.pillar,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPillar: (pillarId) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchPillar(pillarId, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LMSSessionDetailContainer);
