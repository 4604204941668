import React, { useMemo } from 'react';
import { Tooltip, Box } from '@mui/material';
import { Button, Menu, Spacer } from 'src/components/shared';

import DashboardIcon from '@mui/icons-material/Dashboard';
import EditIcon from '@mui/icons-material/Edit';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import PushPinIcon from '@mui/icons-material/PushPin';

const BoardActions = ({
  handleEditBoard,
  board,
  pinBoard,
  toggleWidgetsModal,
  setShowCloneBoardForm,
}) => {
  const pinned = useMemo(() => {
    return board?.pinned ? board.pinned : false;
  }, [board]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Tooltip
        title={
          pinned
            ? 'Unpin this board from your smart board view.'
            : 'Pin this board to your smart board view.'
        }
      >
        <div>
          <Button
            iconButton
            onClick={() => {
              pinBoard(board);
            }}
            size="small"
          >
            <PushPinIcon color={pinned ? 'primary' : 'default'} />
          </Button>
        </div>
      </Tooltip>
      <Spacer basis={0.5} />
      <Tooltip title="Manage what widgets are included in this board.">
        <div>
          <Button
            iconButton
            onClick={() => {
              toggleWidgetsModal();
            }}
            size="small"
          >
            <DashboardIcon />
          </Button>
        </div>
      </Tooltip>
      <Spacer basis={0.5} />
      <Tooltip title="Edit the board details.">
        <div>
          <Button
            iconButton
            onClick={() => {
              handleEditBoard(board);
            }}
            size="small"
          >
            <EditIcon />
          </Button>
        </div>
      </Tooltip>
      <Spacer basis={0.5} />
      <Tooltip title="Clone this board.">
        <div>
          <Button
            iconButton
            onClick={() => {
              setShowCloneBoardForm(true);
            }}
            size="small"
          >
            <CopyAllIcon />
          </Button>
        </div>
      </Tooltip>
      {/* <Spacer basis={0.5} />
      <Menu
        buttonStyles={{ padding: 0, textTransform: 'none', fontWeight: 'normal' }}
        options={[
          {
            label: 'Edit',
            onClick: () => {},
          },
        ]}
      /> */}
    </Box>
  );
};

export default BoardActions;
