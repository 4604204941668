import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, Chip, Typography, Box } from '@mui/material';
import { Spacer, Menu, Button, Avatar } from 'src/components/shared';
import { getContactStatusLabel } from 'src/utils/helper';
import { ContactStatus, StatusChip } from './styles';
import VisibilityIcon from '@mui/icons-material/Visibility';

export function useContactsColumns(
  contacts,
  fields,
  edit,
  drawerToggle,
  props,
  openContactDrawer,
  panels,
) {
  const [columns, setColumns] = useState([]);
  const enabledColumns = useMemo(() => {
    return fields.filter(
      (field) => field?.fieldOptions?.showOnGrid && field?.fieldOptions?.showOnGrid === true,
    );
  }, [fields]);

  useEffect(() => {
    let data = enabledColumns.map((item) => {
      return {
        name: item.fieldName,
        label: item.fieldName === 'imageUrl' ? ' ' : item.label,
        options: {
          ...(item.gridParams &&
            item.gridParams?.contacts && {
              ...item.gridParams.contacts,
            }),
          ...(item.type === 'custom' && {
            customBodyRenderLite: (index) => {
              const contact = contacts[index];
              if (!contact?.id) return '';
              switch (item.fieldType) {
                case 'user':
                case 'contact':
                  const user = contact[item.fieldName];
                  if (user && user?.id) {
                    return (
                      <Typography variant="body2">
                        {user.fname} {user.lname}
                      </Typography>
                    );
                  }
                  return '-';
                  break;

                default:
                  return <Typography variant="body2">{contact[item.fieldName] || '-'}</Typography>;
                  break;
              }
            },
          }),
          ...(item.type === 'default' && {
            customBodyRenderLite: (index) => {
              const contact = contacts[index];
              if (!contact?.id) return '';
              const contactName = `${contact.fname} ${contact.lname}`;
              const status = getContactStatusLabel(contact?.status);

              switch (item.fieldName) {
                case 'name':
                  return (
                    <Box display="flex">
                      <Link
                        className="text-truncate td-anchor-content-ellipsis"
                        to={`/contact/${contact.id}`}
                      >
                        <Typography variant="body2" color="secondary">
                          {`${contact?.fname} ${contact.lname || ''}`}
                        </Typography>
                      </Link>
                    </Box>
                  );
                  break;

                  jobTitle;

                case 'jobTitle':
                  return (
                    <Typography variant="body2" color="textPrimary">
                      {contact?.jobTitle || ''}
                    </Typography>
                  );
                  break;

                case 'status':
                  return <ContactStatus status={contact?.status}>{status}</ContactStatus>;
                  break;

                case 'account':
                  return contact?.employer || '';
                  break;

                case 'imageUrl':
                  return <Avatar name={contactName} src={contact.imageUrl} size={32} />;
                  break;

                case 'opens':
                  return contact.stats?.open || 0;
                  break;

                case 'sequences':
                  return contact.stats?.sequences || 0;
                  break;

                case 'replies':
                  return contact.stats?.reply || 0;
                  break;

                case 'sent':
                  return contact.stats?.sent || 0;
                  break;

                case 'bounces':
                  return contact.stats?.bounce || 0;
                  break;

                case 'failed':
                  return contact.stats?.failed || 0;
                  break;

                case 'clicked':
                  return contact.stats?.click || 0;
                  break;

                default:
                  return typeof contact[item.fieldName] !== 'undefined' && contact[item.fieldName]
                    ? contact[item.fieldName]
                    : '';
                  break;
              }
            },
          }),
        },
      };
    });

    data = [
      {
        name: 'view',
        label: ' ',
        options: {
          customBodyRenderLite: (dataIndex) => {
            const contact = contacts[dataIndex];
            return (
              <Box justifyContent="center" display="flex">
                <Button
                  iconButton={true}
                  onClick={() => {
                    openContactDrawer(contact.id);
                  }}
                >
                  <VisibilityIcon fontSize={'small'} style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                </Button>
              </Box>
            );
          },
        },
      },
      {
        name: 'stage',
        label: 'Stage',
        options: {
          customBodyRenderLite: (dataIndex) => {
            const contact = contacts[dataIndex];
            const panel = panels[contact?.panelId] || {};
            return <Typography variant="body2">{panel?.name}</Typography>;
          },
        },
      },
      ...data,
      // {
      //   name: 'id',
      //   label: ' ',
      //   options: {
      //     filter: false,
      //     sort: false,
      //     draggable: false,
      //     allowToggle: false,
      //     viewColumns: false,
      //     setCellHeaderProps: (value) => ({
      //       style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
      //     }),
      //     setCellProps: () => ({
      //       style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
      //     }),
      //     customBodyRenderLite: (index) => {
      //       const contact = contacts[index];
      //       return (
      //         <Menu
      //           options={[
      //             {
      //               label: 'Edit',
      //               icon: <EditIcon />,
      //               onClick: () => {
      //                 props.toggleAddContactForm(contact);
      //               },
      //             },
      //             {
      //               label: 'Delete',
      //               icon: <DeleteIcon />,
      //               confirm: true,
      //               confirmMessage: 'Are you sure you want to delete this contact?',
      //               onClick: () => {
      //                 onDelete(contact);
      //               },
      //             },
      //           ]}
      //         />
      //       );
      //     },
      //   },
      // },
    ];

    // data.push();
    setColumns(data);
  }, [enabledColumns, contacts]);

  return columns;
}
