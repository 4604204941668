import React from 'react';
import { TableFooter, TableRow, TableCell, Box, IconButton } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { makeStyles, useTheme } from '@mui/styles';

// style for the footer
const defaultFooterStyles = makeStyles(() => ({
  footerStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0px 24px 0px 24px',
  },
  paginationClasses: {
    '& [class*="MuiToolbar-root"]': {
      paddingBottom: '0px !important',
    },
  },
}));

const GridFooter = (props) => {
  const classes = defaultFooterStyles();
  const handleRowChange = (event) => {
    props.changeRowsPerPage(event.target.value);
  };
  const handlePageChange = (_, page) => {
    props.changePage(page);
  };

  const { count, textLabels, rowsPerPage, page, serverSide } = props;
  return (
    <TableFooter>
      <TableRow>
        <TableCell className={classes.footerStyle}>
          <TablePagination
            component="div"
            count={count}
            rowsPerPage={rowsPerPage || 25}
            page={page}
            className={classes.paginationClasses}
            labelRowsPerPage={textLabels.rowsPerPage}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${textLabels.displayRows} ${count}`
            }
            backIconButtonProps={{
              'aria-label': textLabels.previous,
            }}
            nextIconButtonProps={{
              'aria-label': textLabels.next,
            }}
            rowsPerPageOptions={[25, 50, 100]}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowChange}
          />
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};

export default GridFooter;
