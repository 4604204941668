import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ custom }) => ({
  headerLink: { color: '#1976D2', textDecoration: 'underline' },
  headerHeading: {
    letterSpacing: '0.17px',
    fontSize: '15px',
    fontWeight: 500,
  },
  headerSubTitle: {
    color: '#646464',
    letterSpacing: '0.17px',
    fontSize: '15px',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: 24,
  },
  mainCardIntegrations: {
    height: '100%',
    '& .MuiCardContent-root': {
      padding: 0,
      paddingBottom: 0,
    },
    '& .manage-link': {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '26px',
      padding: '8px 22px',
      borderradius: 5,
      margin: '8px 0',
    },
    '& .active-manage-link': {
      color: '#fff',
      backgroundColor: '#4CAF50',
      border: '1px solid #4CAF50',
    },
    '& .deactive-manage-link': {
      color: '#1976D2',
      border: '1px solid #8cbae8',
    },
  },
  cardContentIntegrationCard: {
    height: '100%',
    position: 'relative',
    maxHeight: 254,
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
  },
  cardImageHeader: {
    marginBottom: 10,
    '& svg': {
      width: 50,
      height: 50,
    },
  },
  integrationDescription: {
    display: 'flex',
    alignItems: 'start',
    color: 'rgba(0, 0, 0, 0.6)',
    paddingLeft: '28px',
  },
  cardHeader: {
    marginBottom: 8,
    '& .title': {
      fontWeight: 400,
    },
    '& .value': {
      fontSize: 13,
      fontWeight: 300,
      color: '#9d7596',
    },
  },

  bullhornTitle: {
    marginBottom: 6,
    display: 'flex',
    padding: '10px',
    '& .title': {
      fontWeight: 300,
      letterSpacing: 0.5,
      fontSize: 14,
    },
    '& .value': {
      fontWeight: 200,
      color: 'rgba(0, 0, 0, 0.38)',
    },
  },
  gridContent: {
    marginBottom: 30,
  },
  gridContentRowDataDeActive: {
    display: 'flex',
    alignItems: 'center',
    color: '#ebebeb',
  },
  gridContentRowDataActive: {
    display: 'flex',
    alignItems: 'center',
    color: '#4CAF50',
  },
  cardFooter: {
    bottom: 24,
    // position: 'absolute',
    width: 'calc(100% - 34px)',
  },
  gridFooterRowData: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '15px',
    justifyContent: 'space-between',
    '& .installation': {},
    '& .connect': {
      '& .manage-link': {
        color: '#5780ab',
        textDecoration: 'none',
        backgroundColor: 'transparent',
      },
    },
  },
  connected: {
    color: '#EF5350',
    '& svg': {
      fontSize: '2rem',
    },
  },
  disConnected: {
    color: '#ebebeb',
    '& svg': {
      fontSize: '2rem',
    },
  },
  subTitle: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '14px',
    fontWeight: '400',
    marginBottom: 0,
  },
  connectionModal: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    '& .time-input': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '10px 16px',
    },
    '& .submit-btn': {
      backgroundColor: '#1976D2',
      borderRadius: '4px',
      fontSize: '15px',
      fontWeight: '500',
      padding: '12px 24px',
      color: 'white',
      cursor: 'pointer',
    },
  },
}));
