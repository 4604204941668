import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Icon from 'src/components/lms/Icon';
import { Spacer } from '../shared';

const Empty = ({ title, desc }) => {
  return (
    <Stack alignItems="center" mt={3} pb={2}>
      <Icon name="empty" />
      <Spacer x={2} y={2} />
      <Typography variant="h1">{title}</Typography>
      <Spacer x={1} y={1} />
      <Typography variant="body2" color="textSecondary">
        {desc}
      </Typography>
    </Stack>
  );
};

Empty.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
};

Empty.defaultProps = {
  title: '',
  desc: '',
};

export default Empty;
