import React, { useEffect, useMemo } from 'react';
import { AppGrid } from 'src/components/App';
import { useColumns } from './useColumns';

const ReportGrid = ({ loading, resellersReport, resellerFilters, ...props }) => {
  const { data, paging, filters } = resellersReport;

  useEffect(() => {
    fetchData(filters, paging);
  }, [resellerFilters]);

  const columns = useColumns(data);

  const fetchData = (filters, paging) => {
    props.fetchResellerReport(filters, paging, resellerFilters);
  };

  const handleTableChange = ({ pageNo, perPage }) => {
    fetchData(filters, {
      pageNo,
      perPage,
    });
  };

  return (
    <>
      <AppGrid
        selectableRows="none"
        columns={columns}
        onTableChange={handleTableChange}
        data={data}
        loading={loading.resellersReport}
        options={{
          pagination: true,
          serverSide: true,
          page: paging.pageNo,
          rowsPerPage: paging.perPage,
          count: paging.count,
        }}
      />
    </>
  );
};

ReportGrid.propTypes = {};

export default ReportGrid;
