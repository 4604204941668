import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({ palette }) => ({
  noSidebar: {
    margin: '0 auto',
    height: '50px',
    maxWidth: '1536px',
    boxShadow: 'none',
    // borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    left: 0,
    right: 0,
    background: '#fafafa',
  },
  root: {
    margin: 0,
    height: '50px',
    left: '230px',
    width: 'calc(100% - 230px)',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  rootBoards: {
    margin: 0,
    height: '50px',
    left: '230px',
    width: 'calc(100% - 230px)',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  rootDashboard: {
    margin: 0,
    height: '50px',
    left: '300px',
    width: 'calc(100% - 300px)',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  rootCollapsed: {
    left: '64px',
    width: 'calc(100% - 64px)',
  },
  searchPopper: {
    width: '400px !important',
    marginLeft: '100px',
    overflowX: 'hidden',
  },
  toolbarStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 12px 0 6px',
  },
  userButton: {
    // background: 'rgba(0, 0, 0, 0.06)',
    textTransform: 'none',
    color: 'rgba(0, 0, 0, 0.6)',
    padding: '5px 12px',
  },
  drawer: {
    background: '#000',
    width: '150%',
    padding: '5px 12px',
  },
  menuPaper: {
    '& .MuiListItemIcon-root': {
      minWidth: '40px',
    },
  },
}));

export default useStyles;
