import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Button, Spacer } from 'src/components/shared';
import { Typography, Box, FormHelperText, Divider } from '@mui/material';

function LookupForm({ handleSubmit, lookup, onClose }) {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Form
        initialValues={{
          options: '',
        }}
        validationSchema={Yup.object().shape({
          options: Yup.string().test({
            message: () => 'Please enter options.',
            test: (value, values) => {
              if (!value || value == '') {
                return values.createError({
                  message: 'Please enter options.',
                  path: 'options',
                });
              }
              const hasNewLines = (value.match(/\n/g) || []).length > 0;
              const hasCommas = value.includes(',');
              if (hasNewLines && hasCommas) {
                return values.createError({
                  message: 'Please save your values either comma OR line separated. Not combined.',
                  path: 'options',
                });
              }
              return true;
            },
          }),
        })}
        onSubmit={async (values, form) => {
          setLoading(true);
          await handleSubmit(values);
          setLoading(false);
          onClose();
          form.prevent;
        }}
      >
        {(props) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                props.submitForm();
                return false;
              }}
              noValidate
            >
              <Form.Field.Input
                fullWidth
                variant="outlined"
                name="options"
                disabled={loading}
                multiline={true}
                maxRows={4}
                minRows={4}
              />

              <FormHelperText>Add values, one per line or separated by comma</FormHelperText>

              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={loading}
                  fullWidth
                  size="large"
                >
                  {lookup?.singularName ? `Save ${lookup?.singularName}` : 'Submit'}
                </Button>
              </Box>
            </form>
          );
        }}
      </Form>
    </>
  );
}

export default LookupForm;
