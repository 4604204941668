import React from 'react';
import {
  Wrapper,
  Content,
  ProgressSkeleton,
  ProgressWrapper,
  CourseWrap,
  Gradient,
  GradientTop,
  TitleSkeleton,
} from './styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { ReactComponent as SessionPlaceholder } from 'src/assets/lms/icons/SessionPlaceholder.svg';

function SessionSkeleton() {
  return (
    <Wrapper>
      <ProgressWrapper style={{ padding: '16px' }}>
        <ProgressSkeleton animation="wave" height={2} width={'100%'} />
      </ProgressWrapper>
      <Content>
        <Gradient />
        <GradientTop />

        <Stack alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
          <SessionPlaceholder style={{ width: '40%' }} />
        </Stack>

        <TitleSkeleton animation="wave" height={24} width={'60%'} />
      </Content>
      <CourseWrap>
        <Typography component="span" variant="body2" color="textSecondary" textAlign="left" noWrap>
          &nbsp;
        </Typography>
      </CourseWrap>
    </Wrapper>
  );
}

export default SessionSkeleton;
