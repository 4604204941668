import { makeStyles } from '@mui/styles';
import { themeColors } from 'src/modules/lms/config';

const useStyles = makeStyles(({ palette }) => ({
  loadMore: {
    color: '#9CA5AE !important',
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: '300',
    fontSize: '12px',
  },
}));
export default useStyles;
