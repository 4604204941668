export const invoices = [
  {
    id: 'SRXE05401',
    date: '2022/08/01',
    amount: 239,
    status: 'paid',
  },
  {
    id: 'SRXE05402',
    date: '2023/02/01',
    amount: 900,
    status: 'paid',
  },
  {
    id: 'SRXE05403',
    date: '2023/03/15',
    amount: 201,
    status: 'paid',
  },
  {
    id: 'SRXE05404',
    date: '2023/05/25',
    amount: 999,
    status: 'paid',
  },
];

export const dataProviders = [
  {
    name: 'RocketReach',
    price: '0.15 cents',
    contract: 'SuperReach',
    spent: 250,
    status: true,
  },
  {
    name: 'SharkReach',
    price: '0.20 cents',
    contract: 'SuperReach',
    spent: 200,
    status: true,
  },
  {
    name: 'RocketShark',
    price: '0.12 cents',
    contract: 'SuperReach',
    spent: 180,
    status: false,
  },
];

export const creditAllocation = [
  {
    user: 'Larissa Foster',
    credits: 10,
    expiry: '2023/10/12',
  },
  {
    user: 'Anil Vithani',
    credits: 40,
    expiry: '2023/8/10',
  },
];

export const consultantReport = [
  {
    consultant: 'Larissa Foster',
    Requests: 10,
    Found: 1,
    Spent: '$10',
  },
  {
    consultant: 'Anil Vithani',
    Requests: 8,
    Found: 2,
    Spent: '$7',
  },
  {
    consultant: 'Ezra',
    Requests: 12,
    Found: 6,
    Spent: '$12',
  },
];

export const dataProviderReport = {
  data: [
    {
      name: 'Provider 01',
      value: 20,
    },
    {
      name: 'Provider 02',
      value: 10,
    },
    {
      name: 'Provider 03',
      value: 20,
    },
    {
      name: 'Provider 04',
      value: 10,
    },
  ],
};
