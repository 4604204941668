import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';

export const GridContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: 10,
});

export const FormContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: 16,
  boxShadow: '0px 0px 4px rgb(0 0 0 / 25%)',
  maxWidth: 'calc(100% - 302px)',
});

export const useStyles = makeStyles(({ custom, transitions, palette, ...theme }) => {
  return {
    box: {
      '& .css-1p3rl23-MuiInputBase-root-MuiInput-root:before': {
        left: '10px',
        'border-bottom': '2px solid #E5E5E5;',
      },
    },
  };
});
