import { createContext, useState, useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as lmsApi from '../api/LMSApis';
import { fetchLmsComponent1, fetchLmsComponent2, fetchLmsComponent3 } from '../actions/LMSActions';

const lmsHomeContext = createContext({});
import usePersistState from 'src/components/hooks/usePersistState';

export const useHomeProvider = () => {
  const { sales, recruitment, filters, loading, setFilters, tenantCourses, refetchData } =
    useContext(lmsHomeContext);
  return { sales, recruitment, filters, loading, setFilters, tenantCourses, refetchData };
};

const dataInterface = {
  component1Title: '',
  component2Title: '',
  tabName: '',
  nav: [],
  component1: [],
  component2: [],
  component3: [],
};

export const LmsHomeStore = ({ children }) => {
  const [filters, setFilters] = usePersistState('lmsHomeFilters', {});
  const [sales, setSales] = useState(dataInterface);
  const [recruitment, setRecruitment] = useState(dataInterface);
  const [tenantCourses, setTenantCourses] = useState([]);
  const firstUpdate = useRef(false);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState({
    nav: false,
    component1: false,
    component2: false,
    component3: false,
  });

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    fetchLeftNav(filters);
    fetchComp1(filters);
    fetchComp2(filters);
    fetchComp3(filters);
    fetchTenantCourses(filters);
  }, [filters]);

  const refetchData = () => {
    fetchComp3(filters);
  };

  const fetchTenantCourses = async () => {
    try {
      const res = await lmsApi.getTenantCourses();
      setTenantCourses(res.courses);
    } catch (error) {
      setTenantCourses([]);
    }
  };

  const fetchComp1 = async () => {
    try {
      setLoading((prevState) => ({ ...prevState, component1: true }));
      const res = await dispatchComp1();
      setSales((prevState) => ({
        ...prevState,
        component1: res.sales,
      }));
      setRecruitment((prevState) => ({
        ...prevState,
        component1: res.recruitment,
      }));
      setLoading((prevState) => ({ ...prevState, component1: false }));
    } catch (error) {}
  };

  const dispatchComp1 = () => {
    const filter = getFilter();
    return new Promise((resolve, reject) => {
      dispatch(fetchLmsComponent1(filter, resolve, reject));
    });
  };

  const fetchComp2 = async () => {
    try {
      setLoading((prevState) => ({ ...prevState, component2: true }));
      const res = await dispatchComp2();
      setSales((prevState) => ({
        ...prevState,
        component2: res.sales,
      }));
      setRecruitment((prevState) => ({
        ...prevState,
        component2: res.recruitment,
      }));
      setLoading((prevState) => ({ ...prevState, component2: false }));
    } catch (error) {}
  };

  const dispatchComp2 = () => {
    const filter = getFilter();
    return new Promise((resolve, reject) => {
      dispatch(fetchLmsComponent2(filter, resolve, reject));
    });
  };

  const dispatchComp3 = () => {
    const filter = getFilter();
    return new Promise((resolve, reject) => {
      dispatch(fetchLmsComponent3(filter, resolve, reject));
    });
  };

  const fetchComp3 = async () => {
    try {
      setLoading((prevState) => ({ ...prevState, component3: true }));
      const res = await dispatchComp3();
      setSales((prevState) => ({
        ...prevState,
        component3: res.sales,
      }));
      setRecruitment((prevState) => ({
        ...prevState,
        component3: res.recruitment,
      }));
      setLoading((prevState) => ({ ...prevState, component3: false }));
    } catch (error) {}
  };

  const getFilter = () => {
    return {
      ...(filters?.experts && filters?.experts.length
        ? { experts: filters?.experts.map((item) => item.id) }
        : {}),
      ...(filters?.tags && filters?.tags.length
        ? { tags: filters?.tags.map((item) => item.id) }
        : {}),
    };
  };

  const fetchLeftNav = async () => {
    try {
      const filter = getFilter();
      const res = await lmsApi.getLeftNav(filter);
      const salesRes = res.filter((item) => item.categoryType === 'sales')[0];
      const recRes = res.filter((item) => item.categoryType === 'recruitment')[0];
      setSales((prevState) => ({
        ...prevState,
        component1Title: salesRes?.component1Title || '',
        component2Title: salesRes?.component2Title || '',
        nav: salesRes?.leftNavigation || [],
      }));
      setRecruitment((prevState) => ({
        ...prevState,
        component1Title: recRes?.component1Title || '',
        component2Title: recRes?.component2Title || '',
        nav: recRes?.leftNavigation || [],
      }));
    } catch (error) {}
  };

  return (
    <lmsHomeContext.Provider
      value={{
        sales,
        recruitment,
        filters,
        loading,
        setFilters,
        tenantCourses,
        refetchData,
      }}
    >
      {children}
    </lmsHomeContext.Provider>
  );
};

export const withHomePageStore = (Component) => (props) =>
  (
    <LmsHomeStore>
      <Component {...props} />
    </LmsHomeStore>
  );
