import React from 'react';
import { Typography } from '@mui/material';
import {
  ActivityItemWrapper,
  ActivityIcon,
  ActivityDisplayNameContainer,
  ActivityType,
  ActivityDeatil,
} from './styles';
import SendIcon from '@mui/icons-material/Send';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import TaskIcon from '@mui/icons-material/Task';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { time_ago } from './../../ContactTasks/helper';

function ActivityItem({ data }) {
  if (!data) return null;
  const [showMore, setShowMore] = React.useState(false);

  const { type, detailType, note: title, task, createdAt: date, createdBy } = data ?? {};
  const { description: subtitle } = task ?? {};
  const { fname = '', lname = '' } = createdBy ?? {};

  const getIcon = () => {
    switch (type) {
      // case 'linkedin':
      // case 'linkedinMail':
      // case 'linkedinConnection':
      // case 'general':
      case 'linkedinMessage':
        return <LinkedInIcon style={{ fill: 'rgb(128, 171, 205)', width: 18 }} />;
      case 'CONTACT_UPDATED':
        return <SaveAsIcon style={{ fill: 'rgb(128, 171, 205)', width: 18 }} />;
      case 'CONTACT_ADDED':
        return <PersonAddIcon style={{ fill: 'rgb(128, 171, 205)', width: 18 }} />;
      case 'call':
        return <CallIcon style={{ fill: 'rgb(128, 171, 205)', width: 18 }} />;
      case 'Meeting Note':
        return <TaskIcon style={{ fill: 'rgb(128, 171, 205)', width: 18 }} />;
      case 'Enrolled_In_Sequence':
        return <SendIcon style={{ fill: 'rgb(128, 171, 205)', width: 18 }} />;
      case 'email':
        return <EmailIcon style={{ fill: 'rgb(128, 171, 205)', width: 18 }} />;
      default:
        return <EmailIcon style={{ fill: 'rgb(128, 171, 205)', width: 18 }} />;
    }
  };

  return (
    <ActivityItemWrapper>
      <ActivityDisplayNameContainer>
        <span className="title">{`${fname} ${lname}`}</span>
        <span className="createdAt">{time_ago(date)}</span>
      </ActivityDisplayNameContainer>
      <ActivityDeatil className={`${!showMore ? 'clamp3lines' : ''}`}>
        {subtitle ? (
          <span dangerouslySetInnerHTML={{ __html: subtitle }} />
        ) : (
          <span dangerouslySetInnerHTML={{ __html: title }} />
        )}
      </ActivityDeatil>
      {subtitle?.length >= 120 && (
        <Typography
          color="secondary"
          variant="body2"
          style={{ cursor: 'pointer' }}
          onClick={() => setShowMore(!showMore)}
        >
          Show {showMore ? 'less' : 'more'}
        </Typography>
      )}
      <ActivityType>
        {getIcon()} {detailType} Note
      </ActivityType>
    </ActivityItemWrapper>
  );
}

export default ActivityItem;
