export const parseWidgetsData = (widgets, widgetsConfig, existingWidgets, viewport) => {
  const widgetIds = widgetsConfig ? Object.keys(widgetsConfig) : [];
  try {
    if (widgets && widgets.length) {
      return Object.assign(
        {},
        ...widgets
          .filter((item) => widgetIds?.includes(item.id))
          .map((widget) => ({
            [widget.id]: {
              i: widget.id,
              loading: true,
              data: widget.type === 'singleCard' ? {} : [],
              ...(existingWidgets &&
                existingWidgets[widget.id] && {
                  ...existingWidgets[widget.id],
                }),
              ...(widget.maxH &&
                widget.maxH > 0 && {
                  maxH: widget.maxH,
                }),
              ...(widget.maxW &&
                widget.maxW > 0 && {
                  maxW: widget.maxW,
                }),
              ...(widget.minH &&
                widget.minH > 0 && {
                  minH: widget.minH,
                }),
              ...(widget.minW &&
                widget.minW > 0 && {
                  minW: widget.minW,
                }),
              key: widget.key,
              type: widget.type,
              title: widget.title,
              iframeSrc: widget?.iframeSrc || '',
              description: widget.description,
              ...widgetsConfig[widget.id],
            },
          })),
      );
    }
    return {};
  } catch (error) {
    return {};
  }
};
