import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Alert, Box, Stack } from '@mui/material';
import { Form, Button } from 'src/components/shared';
import { Empty } from 'src/components/App';
import { fetchContactsLookup } from 'src/modules/contacts/api/contactApis';
import {
  enrollContactsToSequence,
  enrollContactsToSequenceTest,
  updateEnrolment,
} from 'src/modules/sequence/api/sequenceApi';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import toast from 'src/utils/toast';
import { addContactToSequence } from 'src/modules/contacts/actions/contactActions';

function EnrollContactsForm({ sequence, onClose, sequencesContacts, ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [sequenceErrors, setSequenceErrors] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const emailConnected = useMemo(() => {
    if (user?.nlAccessToken && user?.nlAccessToken !== '') {
      return true;
    }
    return false;
  }, [user]);

  const handleSubmit = async (values) => {
    const contact = values.contact;
    try {
      setIsLoading(true);
      await new Promise((resolve, reject) => {
        dispatch(addContactToSequence(sequence?.id, contact.id, resolve, reject));
      });
      setIsLoading(false);
      onClose();
    } catch (error) {
      setIsLoading(false);
    }
  };

  if (!emailConnected) {
    return (
      <Empty
        title="Connect Mailbox"
        description="To send emails via SuperReach your first need to connect your mailbox."
        buttonTitle="Connect"
        buttonProps={{
          variant: 'contained',
          color: 'secondary',
          endIcon: <OpenInNewIcon />,
        }}
        onClick={() => {
          onClose();
          dispatch(toggleContactDrawer());
          history.push('/profile/email');
        }}
      />
    );
  }

  const checkErrors = async (contact) => {
    if (!contact.id) {
      setErrors([]);
      setIsLoading(false);
      setSequenceErrors(false);
      return null;
    }
    try {
      setIsLoading(true);
      const res = await enrollContactsToSequenceTest(sequence.id, [{ id: contact.id }]);
      if (
        res?.enrollmentContacts[0].errors.length > 0 &&
        res?.enrollmentContacts[0].errors[0].message === 'Contact already enrolled'
      ) {
        setErrors(res?.enrollmentContacts[0].errors);
        setSequenceErrors(true);
        setIsLoading(false);
      } else {
        setErrors(res?.enrollmentContacts[0].errors);
        setSequenceErrors(false);
        setIsLoading(false);
      }
    } catch (error) {
      setErrors([]);
      setIsLoading(false);
    }
  };

  return (
    <Form
      initialValues={{
        contact: {},
      }}
      onSubmit={async (values) => {
        handleSubmit(values);
      }}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
              <Stack>
                <Box>
                  <Form.Field.AutoComplete
                    multiple={false}
                    fullWidth
                    showAvatar={false}
                    options={[]}
                    remoteMethod={(val) => {
                      return fetchContactsLookup(val);
                    }}
                    onChange={checkErrors}
                    name="contact"
                    variant="outlined"
                    optLabel="name"
                    optValue="id"
                  />
                </Box>
                <Box mt={2}>
                  {errors && errors.length
                    ? errors.map((error, index) => {
                        return (
                          <Box mb={1} key={`contact-enrollment-error-${index}`}>
                            <Alert severity="error" variant="standard">
                              {error.message}
                            </Alert>
                          </Box>
                        );
                      })
                    : null}
                </Box>
              </Stack>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  type="submit"
                  disabled={sequenceErrors}
                  color="secondary"
                  loading={isLoading}
                >
                  Next
                </Button>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}

export default EnrollContactsForm;
