import React, { useRef, useState, useEffect } from 'react';
import { Span, Row, Col } from 'src/modules/admin/containers/integration/styles';
import { PrimaryButton } from './styles';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Backdrop, Box, Card, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import { fetchCRMConfig, fetchTenantConfigData } from 'src/modules/app/actions/appActions';
import { useDispatch } from 'react-redux';
import {
  AddCRMLookup,
  fetchAccountsLookup,
  fetchCRMAccountsLookup,
} from 'src/modules/admin/api/adminApi';
import { putContact } from '../../actions/contactActions';
import { contactNameRegex, isEmailExist, linkedinRegex, specialCharacterRegex } from 'src/config';
import { fetchCountryLookup, fetchTimezoneLookup } from 'src/modules/users/api/usersApi';
import moment from 'moment';

const MandatoryField = ({
  fieldType,
  CRMField,
  integrations = [],
  crmTenantData,
  attributes,
  contact,
  onClose,
  crmFields,
  CRMDrawer,
  syncJobAdderNewContact,
  syncSalesforceNewContact,
  syncHubspotNewContact,
  syncBullhornNewContact,
  syncVincereNewContact,
  ...props
}) => {
  const dispatch = useDispatch();
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [customFields, setCustomFields] = useState([]);
  const [attributeObj, setAttributeObj] = useState({});
  const [countries, setCountries] = useState([]);
  const [timezones, setTimezones] = useState([]);
  const [openNewEmployerModal, setOpenNewEmployerModal] = useState(null);
  const [newEmployerLookup, setNewEmployerLookup] = useState({});
  const [addingNewEmployerLoading, setAddingNewEmployerLoading] = useState(false);
  const [initialAccountOption, setInitialAccountOption] = useState([]);
  const formRef = useRef();

  const crmActiveField = ['bullhorn', 'vincere', 'jobadder', 'hubspot', 'salesforce']?.find(
    (crmField) => contact?.[crmField + 'Id'],
  );
  const crmActiveFieldType = crmActiveField
    ? contact?.[crmActiveField + 'Type'] === 'ClientContact'
      ? 'Contact'
      : contact?.[crmActiveField + 'Type']
    : null;

  const addToCrm = async () => {
    if (CRMField === 'JobAdder') await syncJobAdderNewContact(contact.id, fieldType);
    else if (CRMField === 'Vincere') await syncVincereNewContact(contact.id, fieldType);
    else if (CRMField === 'Salesforce') await syncSalesforceNewContact(contact.id, fieldType);
    else if (CRMField === 'Hubspot') await syncHubspotNewContact(contact.id, fieldType);
    else if (CRMField === 'Bullhorn') await syncBullhornNewContact(contact.id, fieldType);
  };

  const fetchCountriesOptions = async () => {
    const res = await fetchCountryLookup();
    setCountries(res);
  };

  const fetchTimezonesOptions = async () => {
    const res = await fetchTimezoneLookup();
    setTimezones(res);
  };

  useEffect(() => {
    const fetchdata = async () => {
      const attributeObj = {};
      if (attributes?.length) attributes?.map((attr) => (attributeObj[attr?.fieldName] = attr));
      setAttributeObj(attributeObj);
      fetchCountriesOptions();
      fetchTimezonesOptions();
      if (getFieldType(fieldType)?.toLowerCase() === 'contact')
        setInitialAccountOption(
          await fetchCRMAccountsLookup(CRMField?.toLowerCase(), contact?.account?.name),
        );
    };
    fetchdata();
  }, []);

  const crmShortForm = {
    bullhorn: 'bh',
    vincere: 'vin',
    jobadder: 'ja',
    hubspot: 'hubspot',
    salesforce: 'sf',
  };

  const handleSubmit = async (values) => {
    if (
      Object.keys(values)?.find((key) => {
        return !values?.[key];
      })
    )
      return null;
    const data = {};
    if (getFieldType(fieldType)?.toLowerCase() === 'contact') {
      data[crmShortForm[CRMField?.toLowerCase()] + 'AccountId'] =
        newEmployerLookup?.name === values?.account?.name
          ? newEmployerLookup?.id
          : values?.account?.id;
      data.account =
        newEmployerLookup?.name === values?.account?.name
          ? newEmployerLookup?.srAccount
          : values?.account?.srAccount;
    } else if (values.account?.id) values.account = values.account.id; // existing account
    else if (values.account?.name) {
      values.employer = values.account?.name; // new account entry
      delete values.account;
    }
    setBtnLoading(true);
    Object.keys(values).forEach((key, index) => {
      if (key === 'account' && getFieldType(fieldType)?.toLowerCase() === 'contact') return null;
      data[key] = Array.isArray(values[key])
        ? values[key]
            ?.filter((item) => item && typeof item !== 'undefined' && item !== null)
            ?.map((x) => {
              return typeof x === 'string' ||
                x instanceof String ||
                typeof x === 'number' ||
                x instanceof Number
                ? x
                : typeof x === 'array'
                ? x?.reduce((acc, item) => {
                    acc.push(item.value);
                  }, [])
                : x.value;
            })
        : values[key];
    });
    if (data.DateOfBirth) {
      data.DateOfBirth = moment(data.DateOfBirth).format('YYYY-MM-DD');
    }
    const defaultAddressData = contact.address[0] || {};
    delete defaultAddressData?.id;
    data.address = [
      {
        ...defaultAddressData,
        country: data?.country || defaultAddressData?.country || '',
        city: data?.city || defaultAddressData?.city || '',
        countryCode: data?.countryCode || defaultAddressData?.countryCode || '',
        lat: data?.lat || defaultAddressData?.lat || '',
        long: data?.long || defaultAddressData?.long || '',
        location: data?.location || defaultAddressData?.location || '',
        street1: data?.street1 || defaultAddressData?.street1 || '',
        street2: data?.street2 || defaultAddressData?.street2 || '',
        town: data?.town || defaultAddressData?.town || '',
        state: data?.state || defaultAddressData?.state || '',
        zip: data?.zip || defaultAddressData?.zip || '',
        address1: data?.address1 || defaultAddressData?.address1 || '',
        address2: data?.address2 || defaultAddressData?.address2 || '',
        zip: data?.zip || defaultAddressData?.zip || '',
        googleAddress: data?.googleAddress || defaultAddressData?.googleAddress || '',
      },
    ];

    delete data?.country;
    delete data?.countryCode;
    delete data?.lat;
    delete data?.long;
    delete data?.location;
    delete data?.street1;
    delete data?.street2;
    delete data?.town;
    delete data?.state;
    delete data?.zip;
    delete data?.address1;
    delete data?.address2;
    delete data?.zip;
    delete data?.googleAddress;
    delete data?.city;
    await props.putContact(contact.id, { data });
    addToCrm();
    setBtnLoading(false);
    onClose();
  };

  const handleAddEmployerSubmit = async (values) => {
    setAddingNewEmployerLoading(true);
    const result = await AddCRMLookup({
      name: values?.employer,
      service: CRMField?.toLowerCase(),
    });
    if (!result.srAccount) {
      formRef?.current?.setFieldValue(`account`, '');
    } else {
      const newEmployer = {
        name: values?.employer,
        id: result?.crmCompany?.id,
        srAccount: result?.srAccount?.id,
      };
      formRef?.current?.setFieldValue(`account`, newEmployer);
      setNewEmployerLookup(newEmployer);
    }
    setAddingNewEmployerLoading(false);
    setOpenNewEmployerModal(null);
  };

  const handleBackdropClick = (e) => {
    if (e?.target?.classList?.contains('backdrop-content')) {
      onClose();
    }
  };

  const getFieldType = (fieldType) => {
    if (fieldType === 'Candidate') {
      return 'Candidate';
    } else if (fieldType === 'Contact') {
      return 'Contact';
    } else if (fieldType === 'Lead') {
      return 'Lead';
    }
    return fieldType;
  };

  useEffect(() => {
    const customField = [];
    const configData = crmTenantData?.config;
    const addressKeys = [
      'country',
      'countryCode',
      'lat',
      'long',
      'location',
      'street1',
      'street2',
      'town',
      'state',
      'zip',
      'address1',
      'address2',
      'zip',
      'googleAddress',
      'city',
    ];
    setLoading(true);
    if (crmActiveFieldType) onClose();
    if (Object.keys(attributeObj).length) {
      const data = crmTenantData?.id
        ? configData?.[getFieldType(fieldType)]
        : crmFields?.[CRMField?.toLowerCase()]?.defaultMappings?.[
            getFieldType(fieldType)?.toLowerCase()
          ];
      data?.map((ele) => {
        if (!ele.status || ele?.srKey === 'imageUrl' || !ele?.mandatory) return null;
        if (attributeObj?.[ele.srKey]?.fieldName === 'linkedinProfile') {
          !contact?.socialLinks?.find((k) => k.type === 'linkedin')?.link
            ? customField.push(attributeObj[ele.srKey])
            : null;
        } else if (attributeObj?.[ele.srKey]?.fieldName && addressKeys.includes(ele.srKey)) {
          !contact?.address?.[0]?.[ele?.srKey] ? customField.push(attributeObj[ele.srKey]) : null;
        } else if (['mobile', 'workPhone', 'homePhone'].includes(ele.srKey)) {
          let value = {};
          if (ele.srKey === 'mobile')
            value = contact.phones?.find((item) => item.type === 'mobile');
          else if (ele.srKey === 'workPhone')
            value = contact.phones?.find((item) => item.type === 'office');
          else if (ele.srKey === 'homePhone')
            value = contact.phones?.find((item) => item.type === 'home');
          if (!value) customField.push(attributeObj[ele.srKey]);
        } else if (['workEmail', 'personalEmail'].includes(ele.srKey)) {
          let value = {};
          if (ele.srKey === 'workEmail')
            value = contact.emails?.find((item) => item.type === 'work');
          else if (ele.srKey === 'personalEmail')
            value = contact.emails?.find((item) => item.type === 'personal');
          if (!value) customField.push(attributeObj[ele.srKey]);
        } else if (
          ['account', 'employer']?.includes(attributeObj?.[ele.srKey]?.fieldName) &&
          getFieldType(fieldType)?.toLowerCase() === 'contact'
        )
          customField.push(attributeObj['account']);
        else if (attributeObj?.[ele.srKey]?.fieldName && !contact?.[ele?.srKey])
          customField.push(attributeObj[ele.srKey]);
      });
      if (customField.length) {
        setCustomFields(customField);
      } else {
        if (fieldType) {
          addToCrm();
        }
        onClose();
      }
      setLoading(false);
    }
  }, [crmTenantData, attributeObj, crmFields, crmActiveFieldType]);

  const initialContactCRMAccount = initialAccountOption?.find(
    (item) => item?.name === contact?.account?.name,
  );
  const initialValues = {};
  customFields?.map((ele) => (initialValues[ele?.fieldName] = ''));

  return (
    <React.Fragment>
      <Col style={{ height: 'calc(100% - 20px)', marginTop: 20 }}>
        {loading ? (
          <Row style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress size={40} />
          </Row>
        ) : (
          <Form
            initialValues={{
              ...initialValues,
              ...(getFieldType(fieldType)?.toLowerCase() === 'contact'
                ? { account: initialContactCRMAccount?.name ? initialContactCRMAccount : {} }
                : {}),
            }}
            validationSchema={Yup.object().shape({
              ...(customFields &&
                customFields.length &&
                Object.assign(
                  {},
                  ...customFields.map(({ fieldName, fieldType, fieldOptions }) => {
                    if (fieldName === 'workEmail')
                      return {
                        [fieldName]: Yup.string()
                          .required('This field is required!')
                          .email('Invalid email format')
                          .test({
                            message: () => 'Email already exists',
                            test: (values) => isEmailExist(values),
                          }),
                      };
                    if (fieldName === 'workEmail')
                      return {
                        [fieldName]: Yup.string()
                          .email('Invalid email format')
                          .test({
                            message: () => 'Email already exists',
                            test: (values) => isEmailExist(values),
                          }),
                      };

                    if (fieldName === 'account')
                      return {
                        [fieldName]: Yup.object().test({
                          message: () => 'Please choose employer.',
                          test: (item) => item?.name && item?.name !== '',
                        }),
                      };

                    if (fieldName === 'personalEmail')
                      return {
                        [fieldName]: Yup.string()
                          .required('This field is required!')
                          .email('Invalid email format')
                          .test({
                            message: () => 'Should not be same as Work Email',
                            test: function () {
                              const { workEmail, personalEmail } = this.parent;
                              if (!personalEmail) return true;
                              return workEmail !== personalEmail;
                            },
                          })
                          .test({
                            message: () => 'Email already exists',
                            test: (values) => isEmailExist(values),
                          }),
                      };
                    if (fieldName === 'personalEmail')
                      return {
                        [fieldName]: Yup.string()
                          .email('Invalid email format')
                          .test({
                            message: () => 'Should not be same as Work Email',
                            test: function () {
                              const { workEmail, personalEmail } = this.parent;
                              if (!personalEmail) return true;
                              return workEmail !== personalEmail;
                            },
                          })
                          .test({
                            message: () => 'Email already exists',
                            test: (values) => isEmailExist(values),
                          }),
                      };

                    if (fieldName === 'fname')
                      // always required

                      return {
                        [fieldName]: Yup.string().required('Please enter First Name!'),
                        // .test(
                        //   'firstname',
                        //   'First Name cannot contain special character',
                        //   contactNameRegex,
                        // ),
                      };

                    if (fieldName === 'lname')
                      // always required
                      return {
                        [fieldName]: Yup.string().required('Please enter Last Name!'),
                        // .test(
                        //   'last name',
                        //   'Last Name cannot contain special character',
                        //   contactNameRegex,
                        // ),
                      };

                    if (fieldType === 'date') {
                      return {
                        [fieldName]: Yup.date()
                          .typeError('Please Enter Date in Correct format')
                          .required()
                          .label('Date')
                          .nullable(),
                      };
                    }

                    if (fieldName === 'jobTitle') {
                      return {
                        [fieldName]: Yup.string().required('Please enter Job Title'),
                        // .test(
                        //   'job title',
                        //   'Job Title cannot contain special character',
                        //   specialCharacterRegex,
                        // ),
                      };
                    }

                    if (fieldName === 'mobile') {
                      return { [fieldName]: Yup.string().required('Please enter Mobile Number!') };
                    }

                    if (fieldName === 'linkedinProfile')
                      return {
                        [fieldName]: Yup.string()
                          .required('This field is required!')
                          .matches(linkedinRegex, 'Enter valid linkedin profile url!'),
                      };
                    if (fieldName === 'linkedinProfile')
                      return {
                        [fieldName]: Yup.string().matches(
                          linkedinRegex,
                          'Enter valid linkedin profile url!',
                        ),
                      };

                    if (fieldName === 'country')
                      return {
                        [fieldName]: Yup.string().required('This field is required!'),
                      };

                    if (fieldName === 'city')
                      return {
                        [fieldName]: Yup.string().required('This field is required!'),
                      };

                    if (fieldType === 'formula' && !fieldOptions?.multiple)
                      return {
                        [fieldName]: Yup.object().test({
                          message: () => 'This field is required!',
                          test: (values) => {
                            if (!values) return false;
                            return Object.keys(values).length !== 0; // empty {}
                          },
                        }),
                      };

                    if (fieldType === 'lookup' && fieldOptions?.multiple)
                      return {
                        [fieldName]: Yup.array().test({
                          message: () => 'This field is required!',
                          test: (values) => {
                            if (!values) return false;
                            return values?.length !== 0; // empty {}
                          },
                        }),
                      };

                    return { [fieldName]: Yup.string().required('This field is required!') };
                  }),
                )),
            })}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validateOnBlur={true}
            validateOnChange={false}
            innerRef={formRef}
          >
            {({ values, submitForm }) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitForm();
                  }}
                  style={{ height: '100%' }}
                  noValidate
                >
                  <Col style={{ height: '100%', gap: 20 }}>
                    <Row
                      style={{
                        flex: 1,
                        justifyContent: 'space-between',
                      }}
                    >
                      <Col style={{ width: '100%' }}>
                        {customFields.length > 0
                          ? customFields.map((field, index) => {
                              if (field.fieldName === 'employer' || field.fieldName === 'account') {
                                return (
                                  <>
                                    {getFieldType(fieldType)?.toLowerCase() === 'contact' && (
                                      <Stack direction={'row'} gap={'4px'} alignItems={'center'}>
                                        <Typography variant="body2">Current value :</Typography>
                                        <Typography variant="body1">
                                          #{contact?.account?.name}
                                        </Typography>
                                      </Stack>
                                    )}
                                    <Form.Field.AutoComplete
                                      multiple={false}
                                      fullWidth
                                      options={
                                        getFieldType(fieldType)?.toLowerCase() !== 'contact'
                                          ? [contact?.account || {}]
                                          : newEmployerLookup?.name
                                          ? [newEmployerLookup]
                                          : initialAccountOption
                                      }
                                      variant="outlined"
                                      remoteMethod={(val) => {
                                        return getFieldType(fieldType)?.toLowerCase() === 'contact'
                                          ? fetchCRMAccountsLookup(
                                              CRMField?.toLowerCase(),
                                              val || contact?.account?.name,
                                            )
                                          : fetchAccountsLookup(val);
                                      }}
                                      addNew={getFieldType(fieldType)?.toLowerCase() === 'contact'}
                                      addNewLabel={'Add Account to CRM'}
                                      name="account"
                                      label={'Employer'}
                                      required={field?.fieldOptions?.required}
                                      loading={false}
                                      placeholder={
                                        getFieldType(fieldType)?.toLowerCase() === 'contact'
                                          ? 'Search'
                                          : 'Employer'
                                      }
                                      optLabel="name"
                                      optValue="id"
                                      allowAdd={true}
                                      onNewAdd={setOpenNewEmployerModal}
                                      key={field.id}
                                    />
                                  </>
                                );
                              }

                              if (field.fieldName === 'country') {
                                return (
                                  <Form.Field.Select
                                    fullWidth
                                    options={countries}
                                    variant="outlined"
                                    name="country"
                                    label={'Country'}
                                    placeholder="Select Country"
                                    optLabel="country"
                                    optValue="country"
                                    key={field.id}
                                  />
                                );
                              }

                              if (field.fieldName === 'Timezone') {
                                return (
                                  <Form.Field.Select
                                    fullWidth
                                    options={timezones}
                                    variant="outlined"
                                    name="Timezone"
                                    label={'Timezone'}
                                    placeholder="Select Timezone"
                                    optLabel="jstimeZone"
                                    optValue="jstimeZone"
                                    key={field.id}
                                  />
                                );
                              }

                              return (
                                <Row
                                  key={`sequence-custom-field-${index}`}
                                  style={{ width: '100%' }}
                                >
                                  <Form.Field.CustomField
                                    attribute={field}
                                    name={field.fieldName}
                                    key={`sequence-custom-field-input-${field.id}`}
                                    filter={
                                      field.fieldName === 'fname' || field.fieldName === 'lname'
                                        ? /^[a-zA-Z]+$/
                                        : null
                                    }
                                    label={field?.label}
                                    formData={values}
                                    sx={{ maxWidth: '452px' }}
                                  />
                                </Row>
                              );
                            })
                          : null}
                      </Col>
                    </Row>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      loading={btnLoading}
                      size="large"
                    >
                      {`Add ${fieldType}`?.toUpperCase()}
                    </Button>
                  </Col>
                </form>
              );
            }}
          </Form>
        )}
      </Col>
      <Backdrop
        sx={{
          position: 'absolute',
          backdropFilter: 'blur(2px)',
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openNewEmployerModal && getFieldType(fieldType)?.toLowerCase() === 'contact'}
        onClick={handleBackdropClick}
      >
        <Card
          className="backdrop-content"
          sx={{ width: 'calc(85% - 80px)', backboxcdropFilter: 'blur(3px)' }}
        >
          <Stack sx={{ padding: '20px' }}>
            <Typography variant="subtitle2">Add Account to CRM</Typography>
            <Form
              onSubmit={handleAddEmployerSubmit}
              initialValues={{ employer: openNewEmployerModal || '' }}
              enableReinitialize={true}
              noValidate
            >
              {({ values, ...props }) => {
                return (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      props.submitForm();
                      return false;
                    }}
                    style={{ height: '100%' }}
                    noValidate
                  >
                    <Form.Field.Input
                      fullWidth
                      variant="outlined"
                      name={'employer'}
                      label={'Company Name'}
                    />
                    <Box display="flex" justifyContent="flex-end" width="100%" mt={2}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          props.resetForm();
                          formRef?.current?.setFieldValue(`account`, '');
                          setOpenNewEmployerModal(null);
                        }}
                      >
                        Cancel
                      </Button>
                      <Spacer basis={2} />
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        loading={addingNewEmployerLoading}
                      >
                        Save
                      </Button>
                    </Box>
                  </form>
                );
              }}
            </Form>
          </Stack>
        </Card>
      </Backdrop>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  attributes: state.app.attributes.filter((item) => {
    if (
      item.scope.includes('contacts') &&
      (item.fieldType == 'lookup' ||
        item.fieldType === 'text' ||
        item.fieldType === 'textArea' ||
        item.fieldType === 'textEditor' ||
        item.fieldName === 'account')
    )
      return true;
    else false;
  }),
  crmFields: state.app.crmFields,
  crmNotes: state.app.crmNotes,
  crmTenantData: state.app.crmTenantData,
  attributes: state.app.attributes,
  integrations: state.admin.admin.integrations.data,
});

export const getAddress = (contact, key) => {
  return contact?.address && contact?.address.length ? contact?.address[0]?.[key] || '' : '';
};

const mapDisptachToProps = (dispatch) => ({
  putContact: (id, contacts) => {
    return new Promise((resolve, reject) => {
      dispatch(putContact(id, contacts, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDisptachToProps)(MandatoryField);
