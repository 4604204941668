import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const TemplateGridAction = ({
  drawerToggle,
  setShowCloneForm,
  showCloneForm,
  handleCloneTemplate,
  ...props
}) => {
  const { row } = props;
  const [anchorElement, setAnchorElement] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const menuId = 'action-menu-id';

  const handleActionsMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = (type) => () => {
    const rowData = { id: props.row.id, status: props.row.status };
    setAnchorElement(null);
    switch (type) {
      case 'deactivateOffice': {
        props.updateTemplateStatus(rowData);
        break;
      }
      case 'editOffice': {
        drawerToggle({ edit: props.row, open: true });
        break;
      }
      case 'copySequence': {
        setShowCloneForm(!showCloneForm);
        handleCloneTemplate(props.row);
        break;
      }

      default:
        break;
    }
  };

  let menuItem = useMemo(() => {
    return [
      ...(props.row?.owner?.id && props.row?.owner?.id === user.id
        ? [
            {
              title: 'Edit',
              itemType: 'editOffice',
            },
            {
              title: 'Change Status',
              itemType: 'deactivateOffice',
            },
          ]
        : []),

      {
        title: 'Clone',
        itemType: 'copySequence',
      },
    ];
  }, [user, props.row]);

  // if (row.parent_team_name) {
  //   menuItem = [{ title: 'Edit', itemType: 'editOffice' }, ...menuItem];
  // }

  return (
    <React.Fragment>
      <Typography aria-controls={menuId} aria-haspopup="true" onClick={handleActionsMenuOpen}>
        <MoreVertIcon style={{ fill: '#5780ab' }} />
      </Typography>
      <Menu
        anchorEl={anchorElement}
        id={menuId}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleMenuClose()}
      >
        {menuItem.map((mItem, index) => (
          <MenuItem key={mItem.itemType + index} onClick={handleMenuClose(mItem.itemType)}>
            {mItem.title}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default TemplateGridAction;
