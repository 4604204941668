import { styled } from '@mui/system';
import styledComponent from 'styled-components';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { getSequenceStatusColorCode } from 'src/modules/contacts/utils/contactUtils';

export const SeqInfo = styled(Typography)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.54)',
  fontSize: '14px',
}));

export const StatusChip = styledComponent.div`
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding: 6px;
  font-size:10px;
  height: 10px;
  width: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  flex: none;
  order: 0;
  align-self: stretch;
`;

export const ContactStatus = styledComponent.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  font-size: 12px;
  font-weight:14px;
  border-radius: 2px;
  background-color: ${(props) => getSequenceStatusColorCode(props.status).background};
  color: ${(props) => getSequenceStatusColorCode(props.status).color};
`;
// background-color: ${(props) => getContactStatusColor(props.status).bgColor};
// color: ${(props) => getContactStatusColor(props.status).color};
export const useStyles = makeStyles(({ custom }) => ({
  chip: {
    borderRadius: 4,
    '&.Inactive': {
      background: '#EF5350',
      color: '#ffffff',
    },
    '&.Active': {
      background: '#66BB6A',
      color: '#ffffff',
    },
    '&.Enrollmenterror, &.Hold/Stop, &.Systemhold': {
      backgroundColor: '#EF5350',
      color: '#ffffff',
    },
    '&.Notinanysequence': {
      backgroundColor: '#2196F3',
      color: '#ffffff',
    },
    '&.Spokentoasreasonssequencehold': {
      backgroundColor: '#CDDC39',
      color: '#ffffff',
    },
    '&.In-Progress': {},
  },
}));
