import React from 'react';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import Skeleton from '../Skeleton/Skeleton';

const CardsSkeleton = () => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item md={12} lg={12}>
          <Skeleton animation="wave" height={100} width={'100%'} />
        </Grid>
        <Grid item md={12} lg={4}>
          <Skeleton animation="wave" height={500} width={'100%'} />
        </Grid>
        <Grid item md={12} lg={4}>
          <Skeleton animation="wave" height={500} width={'100%'} />
        </Grid>
        <Grid item md={12} lg={4}>
          <Skeleton animation="wave" height={500} width={'100%'} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CardsSkeleton;
