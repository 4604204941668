import React, { useState, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { AppGrid, ConfirmDialog } from 'src/components/App';
import { Button, Drawer, Spacer, Modal } from 'src/components/shared';
import { useColumns } from './useColumns';
import NestedLookupsGrid from './NestedLookupsGrid';
import LookupOptionsForm from './LookupOptionsForm';
import OptionEditForm from './OptionEditForm';
import LookupForm from './LookupForm';

import SyncIcon from '@mui/icons-material/Sync';
import EditIcon from '@mui/icons-material/Edit';

function LookupsGrid({
  activeLookup,
  activeLookupObj,
  lookupList,
  loading,
  putLookupList,
  postLookupList,
  deleteLookupOption,
  syncLookupWithCrm,
  lookups,
  ...props
}) {
  const { data } = lookupList;
  const [showForm, setShowForm] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);
  const [editLookup, setEditLookup] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [syncing, setSyncing] = useState(false);

  const hasChild = useMemo(() => {
    return activeLookupObj?.options && activeLookupObj?.options.length
      ? activeLookupObj?.options.filter((item) => item.child && item.child.length > 0).length > 0
      : false;
  }, [activeLookupObj]);

  // const [activeOption, setActiveOption] = useState({});
  const [activeOptionId, setActiveOptionId] = useState('');
  // const [activeOptionIndex, setActiveOptionIndex] = useState([]);

  const activeOption = useMemo(() => {
    if (activeOptionId && activeOptionId !== '' && data.length) {
      return data.filter((item) => item.id === activeOptionId)[0];
    }
    return {};
  }, [activeOptionId, data]);

  const activeOptionIndex = useMemo(() => {
    if (activeOptionId && activeOptionId !== '' && data.length) {
      const index = data.findIndex((item) => item.id === activeOptionId);
      return [index];
    }
    return [];
  }, [activeOptionId, data]);

  const syncFieldWithCrm = async () => {
    try {
      setSyncing(true);
      await syncLookupWithCrm(activeLookup);
      setSyncing(false);
    } catch (error) {
      setSyncing(false);
    }
  };

  const openDeleteModal = (item) => {
    setDeleteItem(item);
  };

  const toggleEditModal = (item) => {
    setEditItem(item ? item : {});
  };

  const toggleEditLookup = (lookup) => {
    setEditLookup(!editLookup);
  };

  const looksColumns = useColumns(data, putLookupList, openDeleteModal, toggleEditModal);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const handleColumnChange = () => {};

  const handleSubmit = (data) => {
    return postLookupList(activeLookup, data);
  };

  const handleDelete = async () => {
    try {
      setDeleting(true);
      await deleteLookupOption(deleteItem.id);
      setDeleting(false);
      setDeleteItem({});
    } catch (error) {}
  };

  return (
    <>
      <AppGrid
        columns={looksColumns.map((col, index) => ({
          ...col,
          options: looksColumns?.columns?.length
            ? { ...col.options, ...looksColumns?.columns[index] }
            : col.options,
        }))}
        data={data}
        onSort={null}
        loading={loading.lookupList}
        onTableRowPerPageChange={null}
        onTableChange={null}
        onColumnChange={() => handleColumnChange(false)}
        onFilterChange={null}
        appliedFilters={null}
        title={activeLookupObj?.pluralName || ''}
        titleProps={{
          variant: 'h2',
        }}
        className="lookupsGrid"
        options={{
          sortOrder: {
            name: 'none',
            direction: 'none',
          },
          pagination: false,
          selectableRows: 'multiple',
          searchPlaceholder: 'Search Teams',
          searchOpen: true,
          search: false,
          filter: false,
          download: false,
          viewColumns: false,
          selectToolbarPlacement: 'replace',
          expandableRows: hasChild,
          expandableRowsHeader: false,
          expandableRowsOnClick: false,
          rowsExpanded: activeOptionIndex,
          onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
            if (curExpanded.length) {
              const index = curExpanded[0].index;
              if (index === activeOptionIndex[0]) {
                setActiveOptionId('');
              } else {
                data[index];
                setActiveOptionId(data[index].id);
              }
            } else {
              setActiveOptionId(data[index].id);
            }
          },
          renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            return (
              <tr>
                <td colSpan={colSpan}>
                  <Box ml={4} mb={1} sx={{ borderLeft: '1px solid #e8e8e8' }}>
                    <NestedLookupsGrid option={activeOption} putLookupList={putLookupList} />
                  </Box>
                </td>
              </tr>
            );
          },
          renderCustomFilterActions: () => {
            return (
              <>
                {activeLookupObj?.createdBy && activeLookupObj?.createdBy?.id && (
                  <>
                    <Button
                      color="secondary"
                      size="small"
                      onClick={toggleEditLookup}
                      iconButton={true}
                    >
                      <EditIcon />
                    </Button>
                    <Spacer x={1} y={1} />
                  </>
                )}

                {activeLookupObj?.CRMSyncEnabled && (
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={syncFieldWithCrm}
                      disableElevation
                      startIcon={<SyncIcon />}
                      loading={syncing}
                    >
                      Sync
                    </Button>
                    <Spacer x={1} y={1} />
                  </>
                )}

                {activeLookupObj?.editable && (
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={toggleForm}
                    disableElevation
                  >
                    Add {activeLookupObj?.singularName || 'option'}
                  </Button>
                )}
              </>
            );
          },
        }}
      />

      <Drawer title={`Add ${activeLookupObj?.singularName}`} open={showForm} onClose={toggleForm}>
        <LookupOptionsForm
          lookup={activeLookupObj}
          onClose={toggleForm}
          handleSubmit={handleSubmit}
        />
      </Drawer>

      <Modal
        open={editLookup}
        // open={true}
        title="Edit lookup"
        onClose={toggleEditLookup}
        size="xs"
      >
        <LookupForm
          lookups={lookups}
          lookup={activeLookupObj}
          putCentralLookup={props.putCentralLookup}
          onClose={toggleEditLookup}
        />
      </Modal>

      <Modal
        title={`Edit ${activeLookupObj?.singularName}`}
        open={editItem?.id}
        onClose={toggleEditModal}
        size="xs"
      >
        <OptionEditForm
          lookup={activeLookupObj}
          option={editItem}
          onClose={toggleEditModal}
          putLookupList={putLookupList}
        />
      </Modal>

      <ConfirmDialog
        title="Delete Option"
        open={deleteItem?.id}
        onClose={() => {
          setDeleteItem({});
        }}
        onConfirm={handleDelete}
        loading={deleting}
      >
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <Typography variant="body1" color="textSecondary">
            Are you sure you want to delete <b>{deleteItem.label}</b> option?
          </Typography>
        </Box>
      </ConfirmDialog>
    </>
  );
}

export default LookupsGrid;
