import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Grid, Box, Link, Alert } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import Typography from '@mui/material/Typography';
import { getCandidatesLookup, getAssignToLookup } from 'src/modules/users/api/usersApi';
import { fetchTeams, fetchOffices } from 'src/modules/auth/api/authApis';

function Signature({ editUser, status, formData, ...props }) {
  const [loading, setLoading] = useState(false);
  const [lists, setLists] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [defaultCandidate, setDefaultCandidate] = useState({});

  async function fetchData() {
    try {
      const teams = await fetchTeams();
      const offices = await fetchOffices();
      if (teams && offices) {
        const teamLists = teams?.teams.map((i, key) => ({ id: i.id, value: i.name }));
        const officeLists = offices?.offices.map((i, key) => ({ id: i.id, value: i.name }));
        setLists({ teamLists, officeLists });
      }
    } catch (error) {}
  }

  useEffect(() => {
    fetchData();
    if (editUser && editUser?.id) {
      setDefaultCandidate({
        id: editUser.id,
        value: editUser.username,
      });
      setCurrentUser(editUser);
    }
  }, [editUser]);

  const searchCandidates = async (search, lookupType) => {
    let res = [];
    if (lookupType === 'contact') {
      res = await getCandidatesLookup(search, []);
    } else if (lookupType === 'assignedTo') {
      res = await getAssignToLookup(search, 'id');
    } else if (lookupType === 'title') {
      res = await getAssignToLookup(search, 'id');
    }
    return res || [];
  };

  const handleSubmit = async (values, form) => {
    const data = { ...values };
    if (editUser && editUser?.id) {
      const updatedRow = { id: editUser?.id, data: data };
      await props.putUser(updatedRow);
    }
  };

  return (
    <Form
      initialValues={{
        signature: editUser?.signature || '',
        isSignatureMandatory: editUser?.isSignatureMandatory || false,
      }}
      // validationSchema={Yup.object().shape({
      //   signature: Yup.string().required('Please enter signature!'),
      // })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={false}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <Box display="flex" flexDirection="column" height="100%">
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Form.Field.TextEditor
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="signature"
                      // label="Signature"
                      showPlaceholder={false}
                      removeSpans={false}
                    />
                  </Grid>
                  <Grid item xs={8} mt="10px">
                    <Form.Field.Checkbox
                      name={'isSignatureMandatory'}
                      label="Append signature to all outgoing emails."
                      color="secondary"
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mt={2}>
                <Button variant="contained" color="secondary" type="submit" loading={loading}>
                  Update
                </Button>
              </Box>
              <Box mt={2}>
                <Alert
                  severity="info"
                  sx={{
                    color: '#013654',
                    '& svg': {
                      color: '#013654',
                    },
                  }}
                >
                  <Typography variant="body2" mb={1}>
                    You can choose to manually add your signature using the signature box above or
                    you can do it automatically with the steps below:
                  </Typography>

                  <Spacer y={1} />

                  <Typography variant="body2" mb={1}>
                    1. Use your registered SuperReach email ID to compose a new email. Address the
                    email to signature@superreach.com.
                  </Typography>

                  <Typography variant="body2" mb={1}>
                    2. In the email body, add your desired signature exactly as you want it to
                    appear in your outgoing emails.
                  </Typography>

                  <Typography variant="body2" mb={1}>
                    3. Once your signature is added, simply send the email to{' '}
                    <Link href="mailto:signature@superreach.com">signature@superreach.com</Link>
                  </Typography>

                  <Typography variant="body2" mb={1}>
                    4. Your signature will be automatically integrated into your SuperReach account
                    and visible in the signature box above within 30-60 minutes.
                  </Typography>

                  <Spacer y={1} />

                  <Typography variant="body2" mb={1}>
                    <b>Note:</b> To have your signature automatically added to all outgoing emails,
                    check the box labeled 'Append signature to all outgoing emails' below the
                    signature area. If you prefer to add the signature selectively, use the
                    signature variable in your emails to apply it where needed.
                  </Typography>
                </Alert>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}

export default Signature;
