import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class PageTitle extends Component {
  constructor(props) {
    super(props);
    this.titleEl = document.getElementsByTagName('title')[0];
  }

  render() {
    let fullTitle;
    if (this.props.title) {
      fullTitle = this.props.title + ' | ' + this.props.siteTitle;
    } else {
      fullTitle = this.props.siteTitle;
    }

    return ReactDOM.createPortal(fullTitle || '', this.titleEl);
  }
}
PageTitle.defaultProps = {
  title: null,
  siteTitle: 'SuperReach',
};

export default PageTitle;
