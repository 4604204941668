import React from 'react';
import useStyles from './style';
import { Breadcrumbs, Typography, Grid, Button, Stack, Box, IconButton } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { themeColors } from '../../config';
import { Link } from 'react-router-dom';
import { Form } from 'src/components/shared';
import { createNote, getNote } from '../../api/LMSApis';
import { useEffect } from 'react';
import toast from 'src/utils/toast';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { editNote } from '../../actions/LMSActions';

function CreteNote(props) {
  const dispatch = useDispatch();
  let history = useHistory();
  const classes = useStyles();
  const noteId = props.noteId;
  const [note, setNote] = React.useState({});
  const [manager, setManager] = React.useState({});
  const [visible, setVisible] = React.useState(true);
  const [session, setSession] = React.useState(history.location.state);
  const fetchSingleNote = async () => {
    const singleNote = await getNote(session?.id);
    setNote(singleNote?.user);
    setManager(singleNote?.admin);
    return singleNote;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchSingleNote();
  }, []);

  const currentUrl = props?.location?.pathname;
  const ArrowUpIcon = () => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M17 14.5L12 9.5L7 14.5L17 14.5Z" fill="#B5B7B8" />
      </svg>
    );
  };
  const ArrowDownIcon = () => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7 9.5L12 14.5L17 9.5H7Z" fill="#B5B7B8" />
      </svg>
    );
  };
  const breadcrumbs = [
    <Link underline="hover" key="1" className={classes.links} to="/lms">
      SuperReach Learning
    </Link>,
    <Link to={`/lms/session/${session.id}`} underline="hover" key="2" className={classes.links}>
      {note?.name ? note?.name : session?.title}
    </Link>,
  ];
  const noteCreate = async (values) => {
    const payload = {
      name: session?.title,
      content: values?.content,
      source: 'user',
      session: session?.id,
    };
    if (note?.id || noteId) {
      dispatch(editNote(payload, note?.id));
      history.push(`/lms/session/${session?.id}`);
    } else {
      try {
        const res = await createNote(payload);
        if (res) {
          toast.success('Note Created');
        }
        history.push(`/lms/session/${session?.id}`);
      } catch (error) {}
    }
  };
  return (
    <Box>
      <Grid container spacing={2} mb={5}>
        <Grid item xs={12}>
          <Box mb={2}>
            <Breadcrumbs
              separator={
                <NavigateNextIcon fontSize="small" style={{ color: themeColors.greyText }} />
              }
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Box>
          <Box mb={1}>
            <Typography fontSize={40} fontWeight={400} style={{ color: '#D2D2D2' }}>
              Notes
            </Typography>
          </Box>
          {note?.name || session?.title ? (
            <Box mb={4}>
              <Typography variant="h1" mb={2} className={classes.heading}>
                {note?.name ? note?.name : session?.title}
              </Typography>
            </Box>
          ) : null}

          <Box mb={7}>
            <Typography
              variant="p"
              mb={2}
              fontSize={16}
              fontWeight={400}
              style={{ color: '#D2D2D2' }}
            >
              Add notes, reminders, lists or prompts to help you continue your learning. Notes will
              be saved here for you to refer back to.
            </Typography>
          </Box>
          {/* We are hiding this component for now  */}
          {manager?.id ? (
            <Box mb={3} className={classes.mngSection}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Button disabled={true} className={classes.mngButton}>
                  Manager notes for this session
                </Button>
                <IconButton
                  onClick={() => {
                    if (visible === true) {
                      setVisible(false);
                    } else setVisible(true);
                  }}
                >
                  {visible ? <ArrowUpIcon /> : <ArrowDownIcon />}
                </IconButton>
              </Box>
              {visible === true ? (
                <Box mt={2} id="descriptionCustom" className={classes.description}>
                  <Typography mb={1}>{manager?.content}</Typography>
                </Box>
              ) : null}
            </Box>
          ) : null}

          <Box>
            <Form
              initialValues={{
                content: note?.content,
              }}
              enableReinitialize={true}
              onSubmit={() => {}}
            >
              {({ values, ...formProps }) => {
                return (
                  <>
                    <form onSubmit={() => {}} style={{ height: '100%' }} noValidate>
                      <div className={classes.container}>
                        <Box className={classes.form}>
                          <Form.Field.TextEditor
                            fullWidth
                            variant="outlined"
                            name="content"
                            textColor={themeColors.greyText}
                            showPlaceholder={true}
                            showAttachments={false}
                            placeholder={'note here'}
                          />
                        </Box>
                        <Box display={'flex'} justifyContent={'right'}>
                          <Button
                            className={classes.button}
                            onClick={() => noteCreate(formProps.getFieldProps().value)}
                          >
                            Save
                          </Button>
                        </Box>
                      </div>
                    </form>
                  </>
                );
              }}
            </Form>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CreteNote;
