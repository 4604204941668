import React, { useEffect, useState } from 'react';
import { Box, Stack, Card, CardContent, Typography, Chip, useTheme } from '@mui/material';
import styled from 'styled-components';
import images from 'src/config/images';

import { ReactComponent as Camera } from 'src/assets/icons/camera.svg';
import { ReactComponent as Robot } from 'src/assets/icons/Robot.svg';
import episodesIcon from '../../../../../assets/icons/episodes.svg';
import useStyles from './sessionDetail/styles';

import { useSessionProvider } from 'src/modules/lms/utils/sessionStore';

function SessionDetails({ session }) {
  const tags = session.tags;
  const theme = useTheme();
  const classes = useStyles();

  const { videos } = useSessionProvider();

  return (
    <Card className={classes.cardContainer}>
      <CardContent>
        <Typography variant="body2" color="textPrimary">
          Session details
        </Typography>
        <Stack direction="row" spacing={2} mt={2}>
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
            <Icon>
              <img src={episodesIcon} width="24" height="24" />
            </Icon>
            <Typography color="secondary" variant="body2">
              {videos.length} {videos.length > 1 || videos === 0 ? 'Videos' : ' Video'}
            </Typography>
          </Stack>
          {session?.showRoleplay ? (
            <Stack direction="row" spacing={1} alignItems="center">
              <Icon>
                <Robot />
              </Icon>
              <Typography color="secondary" variant="body2">
                Unlimited role-plays
              </Typography>
            </Stack>
          ) : null}
        </Stack>
        <Stack direction="row" spacing={1} mt={2} flexWrap="wrap">
          {tags?.map((tag, index) => (
            <Chip
              variant="outlined"
              key={`session-tag-${tag.id}-${index}`}
              label={tag.name}
              sx={{
                color: theme.palette.secondary.main,
                borderRadius: '4px',
                borderColor: theme.palette.secondary.main,
                marginBottom: '8px !important',
              }}
            />
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default SessionDetails;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #ffd47e;
  color: #ffd47e;
`;
