import React from 'react';
import CustomFieldsGrid from './CustomFieldsGrid';

const CustomFieldsView = ({
  attributes,
  modules,
  module,
  openFormDrawer,
  onOrderDrawerOpen = () => {},
  ...props
}) => {
  return (
    <>
      <h3 title={`Manage fields Page`} tip="Select active categories below" />
      <CustomFieldsGrid
        attributes={attributes}
        openFormDrawer={openFormDrawer}
        onOrderDrawerOpen={onOrderDrawerOpen}
        modules={modules}
        module={module}
        {...props}
      />
    </>
  );
};

export default CustomFieldsView;
