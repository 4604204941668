import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CourseDetail from '../components/LMScourse/components/CourseDetails';
import { fetchCourse } from '../actions/LMSActions';

const LMSSessionDetailContainer = (props) => {
  const courseId = props.match.params.id;

  useEffect(() => {
    props.fetchCourse(courseId);
  }, [props.match.params.id]);

  return (
    <>
      <CourseDetail {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({
  course: state.lms.course,
  loading: state.lms.loading.course,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCourse: (courseId) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchCourse(courseId, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LMSSessionDetailContainer);
