import React, { useRef } from 'react';
import { Box, ButtonBase, Grid, Stack, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import useStyles from './style';
import CompetitionProgress from './CompetitionProgress';
import TopPerformers from './TopPerformers';
import LeagueTable from './LeagueTable';
import WinningPrize from './WinningPrize';
import { useState } from 'react';
import { useEffect } from 'react';
import api from 'src/api';
import CompetitionHeader from '../LMSHeader/CompetitionHeader';
import { ArrowBack } from '@mui/icons-material';
import { Spacer } from 'src/components/shared';

const LeagueScreen = (props) => {
  const ref = useRef();
  const history = useHistory();
  const [report, setReport] = useState([]);
  const [competition, setCompetition] = useState({});
  const getReports = async () => {
    let id = props?.match?.params?.id;
    if (id) {
      try {
        const resp = await api(`lms/competition/report/${id}`, null, 'GET');
        const res = await api(`lms/competition/${id}`, null, 'GET');
        setCompetition(res.competition);
        if (resp.report.length) setReport(resp.report);
      } catch (err) {}
    }
  };

  useEffect(() => {
    getReports();
    ref.current = setInterval(() => {
      getReports();
    }, 120000);
    return () => {
      clearInterval(ref.current);
    };
  }, []);
  const classes = useStyles();
  return (
    <Box>
      <ButtonBase
        onClick={() => {
          // history.goBack();
          history.push('/lms/competition');
        }}
      >
        <Stack direction="row" gap={1} alignItems="center" mb={2}>
          <ArrowBack /> <Typography variant="body2">Back to Competitions</Typography>
        </Stack>
      </ButtonBase>
      <CompetitionHeader />

      <Grid container sx={{ marginBottom: '24px' }}>
        <Grid item xs={12} className={classes.headingContainer}>
          <Typography className="leagueHeading">
            {competition?.name || ''} - League table
          </Typography>
        </Grid>
      </Grid>
      <Grid container mb={3}>
        <Grid item xs={7} className={classes.tableContainer}>
          <LeagueTable report={report} competition={competition} />
        </Grid>
        <Grid item xs={5} className={classes.sidebar}>
          <CompetitionProgress competition={competition} />
          <TopPerformers report={report} competition={competition} type="column" />
          {competition?.prize ? <WinningPrize competition={competition} /> : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default LeagueScreen;
