import React, { useEffect, useState, useMemo, useRef } from 'react';

import { Box, Typography } from '@mui/material';
import { Tabs, Grid } from 'src/components/App';
import { Button, Spacer, Drawer } from 'src/components/shared';
import SequencesGrid from '../../components/Sequences/SequencesGrid';
import CreateSequenceForm from '../../components/Sequence/CreateSequence/CreateSequenceForm';
import AddIcon from '@mui/icons-material/Add';
import { isEmpty } from 'lodash';

import { sequenceTabs } from '../../config';

function SequencesView({ fields, sequenceTab, sequences, ...props }) {
  const [showDefaultModal, setShowDefaultModal] = useState(true);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [sequence, setSequence] = useState({});

  useEffect(() => {
    fetchSequences(sequences.paging, sequences.filters, sequences.sort);
  }, [sequenceTab]);

  const fetchSequences = (paging, filter, sort = undefined) => {
    props.fetchSequences(paging, filter, sequenceTab, sort);
  };

  const onEdit = (sequence) => {
    setShowCreateForm(true);
    setSequence(sequence);
    props.setSequence(sequence);
  };
  const onClose = () => {
    setShowCreateForm(false);
  };

  const onCreateSequence = (sequence, id) => {
    onClose();
    return props.putSequence(id, sequence);
  };
  const onDelete = (sequence) => {};

  const onBulkAction = () => {};

  const onTabChange = (tab) => {
    props.setSequenceTab(tab.id);
  };

  const tabs = useMemo(() => {
    const count = sequences?.paging?.count ? sequences?.paging?.count : 0;
    return sequenceTabs.map((tab, index) => {
      return {
        ...tab,
        name: tab.id === sequenceTab && !props.loading ? `${tab.name} (${count})` : tab.name,
      };
    });
  }, [sequences, sequenceTabs, sequenceTab, props.loading]);

  return (
    <>
      <Tabs tabs={tabs} onChange={onTabChange} value={sequenceTab} />
      <Spacer y={0.5} />
      <Drawer title="Update Sequence" open={showCreateForm} onClose={onClose}>
        <CreateSequenceForm
          sequenceAccessTypeList={props.sequenceAccessTypeList}
          sequenceEmailTypeList={props.sequenceEmailTypeList}
          user={props.user}
          sequence={sequence}
          onSubmit={onCreateSequence}
          onClose={onClose}
        />
      </Drawer>
      <SequencesGrid
        fields={fields}
        sequences={sequences}
        onEdit={onEdit}
        onDelete={onDelete}
        onBulkAction={onBulkAction}
        {...props}
        fetchSequences={fetchSequences}
      />
    </>
  );
}

export default SequencesView;
