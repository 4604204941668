import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { Spacer } from 'src/components/shared';
import { TaskWrapper } from './styles';
import { Link } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import TaskIcon from '@mui/icons-material/Task';
import { Menu, Button } from 'src/components/shared';
import { useHistory, useLocation } from 'react-router-dom';
import { putTask } from 'src/modules/tasks/actions/taskActions';
import { useDispatch } from 'react-redux';

function TaskItem({
  index,
  type,
  title,
  date = new Date(),
  dueTime,
  id,
  assignedTo,
  tasks,
  toggleAddTaskForm,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  function isToday(date = new Date()) {
    const today = new Date();
    const compare = new Date(date);
    if (today > compare) {
      return true;
    }
    return false;
  }

  return (
    <TaskWrapper key={index}>
      <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
        <Box mt={1}>
          <Link
            className="text-truncate td-anchor-content-ellipsis"
            to={`/tasks/all/${id}`}
            target="_blank"
          >
            <Typography color="textSecondary">
              <Typography variant="span" color="primary">
                Task
              </Typography>{' '}
              assigned to {assignedTo?.fname}
            </Typography>
          </Link>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          {isToday(date) ? (
            <Typography variant="body2" color="danger" sx={{ color: 'red' }}>
              Over Due: {moment(date).format('llll')}
            </Typography>
          ) : (
            <Typography variant="body2" color="textSecondary">
              Due: {moment(date).format('llll')}
            </Typography>
          )}
          <Menu
            options={[
              {
                label: 'Edit',
                icon: <EditIcon />,
                onClick: () => {
                  toggleAddTaskForm(tasks);
                },
              },
              {
                label: 'Show task details',
                icon: <InfoIcon />,
                onClick: () => {
                  history.push(`/tasks/${'inbox'}/${tasks.id}`);
                },
              },
              {
                label: 'Mark as complete',
                icon: <CheckCircleIcon />,
                onClick: () => {
                  return new Promise((resolve, reject) => {
                    dispatch(
                      putTask(
                        tasks.id,
                        {
                          status: 'completed',
                        },
                        resolve,
                        reject,
                      ),
                    );
                  });
                },
              },
            ]}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" ml={-1}>
        {getIcon(type)}
      </Box>
    </TaskWrapper>
  );
}

const getIcon = (type) => {
  switch (type) {
    case 'linkedinViewProfile':
      return (
        <Grid>
          <Spacer x={1} />
          <Typography variant="body2" display="flex" color="secondary" alignItems="center">
            <LinkedInIcon
              style={{ fill: '#1282B2', width: 24, height: 24, marginTop: 2, marginLeft: 10 }}
            />
            Send Linkedin View Profile
          </Typography>
          <Spacer x={1} />
        </Grid>
      );

    case 'linkedinMail':
      return (
        <Grid>
          <Spacer x={1} />
          <Typography variant="body2" display="flex" color="secondary" alignItems="center">
            <LinkedInIcon
              style={{ fill: '#1282B2', width: 24, height: 24, marginTop: 2, marginLeft: 10 }}
            />
            Send Linkedin Mail
          </Typography>
          <Spacer x={1} />
        </Grid>
      );

    case 'linkedinConnection':
      return (
        <Grid>
          <Spacer x={1} />
          <Typography variant="body2" display="flex" color="secondary" alignItems="center">
            <LinkedInIcon
              style={{ fill: '#1282B2', width: 24, height: 24, marginTop: 2, marginLeft: 10 }}
            />
            Send Linkedin Connection
          </Typography>
          <Spacer x={1} />
        </Grid>
      );

    case 'linkedin':
    case 'linkedinMessage':
      return (
        <Grid>
          <Spacer x={1} />
          <Typography variant="body2" display="flex" color="secondary" alignItems="center">
            <LinkedInIcon
              style={{ fill: '#1282B2', width: 24, height: 24, marginTop: 2, marginLeft: 10 }}
            />
            Send Linkedin Message
          </Typography>
          <Spacer x={1} />
        </Grid>
      );
      break;

    case 'general':
      return (
        <Grid>
          <Spacer x={1} />
          <Typography variant="body2" display="flex" color="secondary" alignItems="center">
            <TaskIcon
              style={{ fill: '#1282B2', width: 24, height: 24, marginTop: 2, marginLeft: 10 }}
            />
            General
          </Typography>
          <Spacer x={1} />
        </Grid>
      );
      break;

    case 'call':
      return (
        <Grid>
          <Spacer x={1} />
          <Typography variant="body2" display="flex" color="secondary" alignItems="center">
            <CallIcon
              style={{ fill: '#66BB6A', width: 24, height: 24, marginTop: 4, marginLeft: 10 }}
            />
            Call Back
          </Typography>
          <Spacer x={1} />
        </Grid>
      );
      break;

    default:
    case 'email':
      return (
        <Grid>
          <Spacer x={1} />
          <Typography
            variant="body2"
            color="secondary"
            display="flex"
            alignItems="center"
            textDecoration="underline"
          >
            <EmailIcon style={{ fill: '#42A5F5', width: 24, height: 24, marginLeft: 10 }} />
            Send Email
          </Typography>
          <Spacer x={1} />
        </Grid>
      );
      break;
  }
};

export default TaskItem;
