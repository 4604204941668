import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import useStyles from './style';
import { Form } from 'src/components/shared';
import TemplateCard from '../../shared/templateCard';
import CardsSkeleton from '../../shared/CardsSkeleton/CardsSkeleton';
import { useSelector } from 'react-redux';

function MyNotes({ notes,loading, ...props }) {

  const sortOrder = useSelector((state) => state?.lms?.notes?.filters?._sort);
  const classes = useStyles();
  const { data } = notes || {};
  const options = [
    { label: 'Latest', value: 'latest' },
    { label: 'Oldest', value: 'oldest' },
  ];
if(loading) return <CardsSkeleton/>
  return (
    <Box>
      <Grid container  mb={5}>
        <Grid item xs={12} className={classes.container}>
          <Grid mr={2} ml={2} display={'flex'} justifyContent={'space-between'}>
            <Grid item xs={6}>
              <Typography className={classes.title}>My Notes</Typography>
            </Grid>
            <Grid item xs={3} className={classes.filter}   id={"noteFilter"}>
              <Form
                initialValues={{
                  textEditor: '',
                }}
                enableReinitialize={false}
                onSubmit={() => {}}
                className={classes.filter}
              >
                <Form.Field.Select
                  className={classes.filter}
                  options={options}
                  showNone={false}
                  selected={'latest'}
                  value={sortOrder === 'createdAt:asc'?'oldest':'latest'}
                  onChange={(e)=>{
                  if(e==='latest'){
                   props.fetchNotes({},{_sort: 'createdAt:desc'},{})
                  }
                  else {
                    props.fetchNotes({},{_sort: 'createdAt:asc'},{})
                  }
                  }}
                  variant="outlined"
                  fullWidth
                  name="fieldType"
                  label="Sort by - Creation"
                />
              </Form>
            </Grid>
          </Grid>
       
          <Grid container>
      
            {data?.map((item) => (
              <TemplateCard
                note={true}
                data={item}
                edit={true}
                deletion={true}
                savedOn={'saved On'}
                id={item.id}
                key={item.id}
                deleteTemplate={props.deleteNote}
                loading={loading}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MyNotes;
