import React, { useMemo, useState, useEffect } from 'react';
import { Box, ListItem, ListItemSecondaryAction, ListItemText, Stack } from '@mui/material';
import { Button } from 'src/components/shared';

import { PreviewContainer, Preview, Progress, PreviewActions } from './styles';
import { fileUpload, deleteFile } from 'src/modules/app/api/appApis';
import { bytesToSize } from 'src/utils/helper';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// import s3Helper from 'src/utils/s3Helper.js';
import _ from 'lodash';

function File({ file, onUpload, onDelete, multiple = false, folder, fileName }) {
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const onProgress = (event) => {
    setProgress(Math.round((100 * event.loaded) / event.total));
  };

  useEffect(() => {
    if (!file.id) {
      setIsUploading(true);
      fileUpload('', file, onProgress)
        .then((res) => {
          onUpload(file, res);
          setIsUploading(false);
        })
        .catch((err) => {
          setIsUploading(false);
        });
    }
  }, [file]);

  const handleDeleteFile = async () => {
    await deleteFile(file.id);

    onDelete(file.id);
  };

  const preview = useMemo(() => {
    if (!multiple && !file.id) {
      return URL.createObjectURL(file);
    } else {
      return file.url;
    }
  }, [file, multiple]);

  return (
    <PreviewContainer>
      <Preview src={preview} />
      <PreviewActions className="actions">
        <Stack direction="row" spacing={2}>
          <Button iconButton>
            <EditIcon />
          </Button>
          <Button
            iconButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleDeleteFile();
            }}
          >
            <DeleteIcon />
          </Button>
        </Stack>
      </PreviewActions>
      {isUploading && <Progress value={progress} />}
    </PreviewContainer>
  );

  return (
    <ListItem>
      <a href={file.location} target="_blank">
        <ListItemText primary={file.name || file.path} />
      </a>
      <ListItemSecondaryAction>
        <Box display="flex" alignItems="center">
          {/* {loading && (
            <>
              <Spinner size={24} color="#333333" />
            </>
          )} */}
          {file.location && file.location && !loading && (
            <Button
              iconButton={true}
              onClick={() => {
                deleteFile(file);
              }}
            >
              <DeleteIcon />
            </Button>
          )}
        </Box>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default File;
