import React from 'react';
import { useRef } from 'react';

import Slider from 'react-slick';
import { useState } from 'react';
import { useEffect } from 'react';
import { Box } from '@mui/material';
import SessionPreview from '../../components/sessionPreview/SessionPreview';
import ActionSessionMenu from '../cardMedia/ActionSessionMenu';
import { Button } from '@mui/material';
import CardMedia from '../cardMedia';

export const MultiCarousel = ({
  images = [],
  sliderImages = true,
  slidesToShow,
  isClickable = false,
  dontShowAction = false,
  from,
  loading,
}) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  const [hover, setHover] = React.useState(false);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <Slider asNavFor={nav2} ref={slider1} dots={false} arrows={false} slidesToShow={slidesToShow}>
        {images.map((k, i) => {
          return (
            <Box component={'div'} display={'flex'} justifyContent="center" key={k.id}>
              <img
                src={k.imageUrl}
                alt={`image_carousel_${k.id}`}
                className="br-sm"
                style={{ width: '100%', height: '353px', objectFit: 'cover', borderRadius: '6px' }}
                onClick={() => {
                  if (isClickable) {
                    setModalOpen(true);
                    setData(k.data);
                  }
                }}
              />
            </Box>
          );
        })}
      </Slider>
      {sliderImages ? (
        <div style={{ marginTop: '20px' }}>
          <Slider
            asNavFor={nav1}
            ref={slider2}
            slidesToShow={images?.length > 2 ? 3 : images.length}
            swipeToSlide={true}
            focusOnSelect={true}
            arrows={false}
            dots={false}
          >
            {images.map((k, i) => {
              return (
                <Box
                  component={'div'}
                  sx={{
                    width: '140px',
                    height: '90px',
                    display: 'flex !important',
                    justifyContent: 'space-around',
                  }}
                  key={k.id}
                >
                  <img
                    src={k.thumbNailUrl}
                    alt={`image_carousel_${k.id}`}
                    className="image-fit br-sm"
                    style={{ height: '100%', borderRadius: '2px' }}
                  />
                </Box>
              );
            })}
          </Slider>
        </div>
      ) : null}
    </div>
  );
};
