import React, { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, ButtonBase, Stack } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import CloseIcon from '@mui/icons-material/Close';
import { Box, styled } from '@mui/system';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme, action }) => ({
  ...(action !== 'close' && {
    borderBottom: `1px dashed ${theme.palette.divider}`,
    '&.Mui-expanded': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  }),
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled(({ action, onClose, icon, ...props }) => (
  <MuiAccordionSummary
    expandIcon={
      icon ? (
        <Box className="customIconContainer">{icon}</Box>
      ) : action === 'close' ? (
        <ButtonBase onClick={onClose}>
          <CloseIcon />
        </ButtonBase>
      ) : (
        <Box className="arrowIconContainer">
          <ArrowDropUpIcon sx={{ color: 'rgba(0, 0, 0 , .60) !important' }} />
        </Box>
      )
    }
    {...props}
  />
))(({ theme, action, rotateIcon }) => ({
  '&.MuiAccordionSummary-root': {
    ...(action === 'close' && {
      borderBottom: '1px solid #dddddd',
    }),
    '&.Mui-expanded': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  '& .MuiAccordionSummary-expandIconWrapper, & .MuiAccordionSummary-expandIconWrapper .MuiButtonBase-root':
    {
      transform: 'rotate(0deg)',
      color: 'rgba(0, 0, 0, 0.6);',
      '&.Mui-expanded .arrowIconContainer': {
        color: 'rgba(0, 0, 0, 0.87);',
        ...(action !== 'close' && { transform: 'rotate(90deg)' }),
      },
      '& .MuiSvgIcon-root': {
        fontSize: '28px',
      },
    },
  '& .MuiAccordionSummary-expandIconWrapper, & .MuiAccordionSummary-expandIconWrapper .MuiButtonBase-root .customIconContainer':
    {
      transform: 'rotate(0deg)',
      color: 'rgba(0, 0, 0, 0.6);',
      '&.Mui-expanded': {
        color: theme.palette.secondary.main,
        transform: rotateIcon ? 'rotate(90deg)' : 'rotate(0deg)',
      },
      '& .MuiSvgIcon-root': {
      },
    },
  '& .MuiAccordionSummary-content': {
    '& .MuiTypography-root': {
      color: 'rgba(0, 0, 0, 0.6);',
    },
    '& .badge': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '24px',
      height: '24px',
      background: 'rgba(0, 0, 0, 0.38)',
      borderRadius: '64px',
      padding: 0,
      color: '#ffffff',
      margin: 0,
      marginRight: '8px',
    },
    '&.Mui-expanded': {
      '& .MuiTypography-root': {
        color: 'rgba(0, 0, 0, 0.87);',
      },
      '& .badge': {
        background: theme.palette.secondary.main,
      },
    },
  },
}));

const AppAccordion = ({
  title,
  subTitle,
  children,
  action,
  onClose,
  expanded,
  icon,
  badge,
  isLazyLoad,
  forceOpen,
  handleChange,
  handleFromParent = false,
  rotateIcon = true,
  sx,
  ...props
}) => {
  const [isExtended, setIsExtended] = useState(action === 'close' || expanded);

  useEffect(() => {
    if (!handleFromParent && expanded === true) {
      setIsExtended(true);
    }
  }, [expanded]);

  const loadChild = !isLazyLoad || isExtended;

  return (
    <Accordion
      expanded={handleFromParent ? expanded : isExtended}
      action={action}
      sx={sx}
      onChange={() => {
        if (handleFromParent) {
          handleChange();
        } else {
          if (action !== 'close' && !forceOpen) {
            setIsExtended(!isExtended);
          }
        }
      }}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        action={action}
        rotateIcon={rotateIcon}
        onClose={onClose}
        icon={icon}
      >
        <Box display="flex" alignItems="center">
          {badge && (
            <>
              <Typography class="badge">{badge}</Typography>
            </>
          )}
          <Stack gap={1}>
            <Typography variant="h3" className={'accordion-title'}>
              {title}
            </Typography>
            {subTitle && (
              <Typography variant="body2" className={'accordion-sub-title'}>
                {subTitle}
              </Typography>
            )}
          </Stack>
        </Box>
      </AccordionSummary>
      {loadChild ? <MuiAccordionDetails sx={{ padding: 0 }}>{children}</MuiAccordionDetails> : null}
    </Accordion>
  );
};

AppAccordion.propTypes = {
  action: PropTypes.oneOf(['collapse', 'close']),
  expanded: PropTypes.bool,
  onClose: PropTypes.func,
  badge: PropTypes.string,
  icon: PropTypes.any,
  isLazyLoad: PropTypes.bool,
  forceOpen: PropTypes.bool,
};
AppAccordion.defaultProps = {
  action: 'collapse',
  expanded: false,
  onClose: () => {},
  badge: '',
  icon: undefined,
  isLazyLoad: false,
  forceOpen: false,
  handleChange: () => {},
  handleFromParent: false,
};

export default memo(AppAccordion);
