import images from 'src/config/images';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CallIcon from '@mui/icons-material/Call';
import SmsIcon from '@mui/icons-material/Sms';
import ErrorIcon from '@mui/icons-material/Error';
import TaskIcon from '@mui/icons-material/Task';
import CircleIcon from '@mui/icons-material/Circle';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { taskErrorTypes } from '../config';

export const getPriorityIcon = (value, style = {}) => {
  let icon = '';
  switch (value) {
    case 'high':
      icon = (
        <CircleIcon
          sx={{
            fontSize: '10px',
            fill: '#D32F2F',
            color: '#D32F2F !important',
            marginLeft: '10px',
            ...style,
          }}
        />
      );
      break;
    case 'medium':
      icon = (
        <CircleIcon
          sx={{
            fontSize: '10px',
            fill: '#FFB400',
            color: '#FFB400 !important',
            marginLeft: '10px',
            ...style,
          }}
        />
      );
      break;
    case 'low':
    default:
      icon = (
        <CircleIcon
          sx={{
            fontSize: '10px',
            fill: 'rgba(0,0,0,0.25)',
            color: 'rgba(0,0,0,0.25) !important',
            marginLeft: '10px',
            ...style,
          }}
        />
      );
      break;
  }
  return icon;
};

export const getPriorityIconBack = (value) => {
  let icon = '';
  switch (value) {
    case 'high':
      icon = <ArrowUpwardIcon sx={{ fill: 'rgb(205, 19, 23)' }} />;
      break;
    case 'medium':
      icon = <ArrowUpwardIcon sx={{ fill: 'rgb(233, 127, 51)' }} />;
      break;
    case 'low':
    default:
      icon = <ArrowDownwardIcon sx={{ fill: 'rgb(45, 135, 56)' }} />;
      break;
  }
  return icon;
};

export const getTaskTypeIcon = (type, fontSize = '1.5rem', fill = undefined) => {
  let icon = '';
  switch (type) {
    case 'selectEmailTemplate':
      icon = <ContactMailIcon style={{ fill: '#E8779D', fontSize }} />;
      // icon = <EmailIcon style={{ fill: '#EC407A', fontSize }} />;
      break;
    case 'email':
      icon = <EmailIcon style={{ fill: '#EC407A', fontSize }} />;
      break;

    case 'linkedin':
    case 'linkedinConnection':
    case 'linkedinMessage':
    case 'linkedinMail':
    case 'linkedinViewProfile':
      if (!fill) fill = '#1282B2';
      icon = <LinkedInIcon style={{ fill, fontSize }} />;
      break;
    case 'call':
      icon = <CallIcon style={{ fill: '#66BB6A', fontSize }} />;
      break;
    case 'sms':
      icon = <SmsIcon style={{ fill: '#7E57C2', fontSize }} />;
      break;
    case taskErrorTypes.INBOX_DISCONNECTED:
      icon = <ErrorIcon style={{ fill: '#00bcd4', fontSize, transform: 'translate(0, -1px)' }} />;
      break;
    case 'general':
    default:
      icon = <TaskIcon style={{ fill: '#26A69A', fontSize }} />;
      // icon = <TaskIcon style={{ fill: '#3f51b5', fontSize }} />;
      // icon = <img src={images.sequence.AddTaskFilled} style={{ width: '24px' }} />;
      break;
  }
  return icon;
};

export const getTaskTypeText = (type) => {
  let icon = '';
  switch (type) {
    case 'selectEmailTemplate':
      return 'Select Email Template';
      // return 'Manual Email';
      break;
    case 'email':
      return 'Automated Email';
      break;
    case 'linkedin':
      return 'LinkedIn';
      break;
    case 'linkedinConnection':
      return 'LinkedIn Connection';
      break;
    case 'linkedinMessage':
      return 'LinkedIn Message';
      break;
    case 'linkedinMail':
      return 'LinkedIn Mail';
      break;
    case 'linkedinViewProfile':
      return 'LinkedIn View Profile';
      break;
    case 'call':
      return 'Call';
      break;
    case 'sms':
      return 'SMS';
      break;
    case taskErrorTypes.INBOX_DISCONNECTED:
      return 'Inbox Disconnected';
    case 'general':
    default:
      return 'To Do';
      break;
  }
  return icon;
};

export const getFilterLabel = (text = '', data) => {
  let newLabel = {};
  switch (text) {
    case 'consultantList': {
      newLabel = {
        type: 'assigned_to',
        title: 'All assignees',
        isSingle: true,
        showName: true,
      };
      break;
    }
    case 'priorityList': {
      newLabel = {
        type: 'priority',
        title: 'Priority',
      };
      break;
    }
    case 'taskStatusList': {
      newLabel = {
        type: 'status',
        title: 'Task Status',
      };
      break;
    }
    case 'taskTimePeriodList': {
      newLabel = {
        type: 'due',
        title: 'Time Period',
        isSingle: true,
        control: 'dateRangePicker',
      };
      break;
    }
    case 'taskTypeListForFilter': {
      newLabel = {
        type: 'type',
        title: 'Task type',
      };
      break;
    }
    default: {
      newLabel = {};
      break;
    }
  }

  return newLabel;
};

export const parseTaskFilterData = (data, tab) => {
  let filterListData = Object.keys(data)
    .sort()
    .filter((e) => e !== 'taskTypeList');

  switch (tab.id) {
    case 1: {
      filterListData = filterListData
        .filter((e) => e !== 'taskStatusList' && e !== 'taskTimePeriodList')
        .map((k) => ({
          name: k,
          data: data[k],
          optionLabel: 'name',
          optionValue: 'id',
          ...getFilterLabel(k, data),
        }));
      break;
    }
    case 2: {
      filterListData = filterListData
        .filter((e) => e !== 'taskStatusList' && e !== 'taskTimePeriodList')
        .map((k) => ({
          name: k,
          data: data[k],
          optionLabel: 'name',
          optionValue: 'id',
          ...getFilterLabel(k, data),
        }));
      break;
    }
    case 3: {
      filterListData = filterListData
        .filter((e) => e !== 'taskStatusList' && e !== 'taskTimePeriodList')
        .map((k) => ({
          name: k,
          data: data[k],
          optionLabel: 'name',
          optionValue: 'id',
          ...getFilterLabel(k, data),
        }));
      break;
    }
    case 4: {
      filterListData = filterListData
        .filter((e) => e !== 'taskTimePeriodList')
        .map((k) => ({
          name: k,
          data: data[k],
          optionLabel: 'name',
          optionValue: 'id',
          ...getFilterLabel(k, data),
        }));
      break;
    }
    default: {
      filterListData = filterListData.map((k) => ({
        name: k,
        data: data[k],
        optionLabel: 'name',
        optionValue: 'id',
        ...getFilterLabel(k, data),
      }));
      break;
    }
  }

  if (tab.id !== 1 && tab.id !== 2 && tab.id !== 3) {
    filterListData.push({
      name: 'dueDate',
      control: 'dateRangePicker',
      isSingle: true,
      title: 'Due date',
      type: 'dueDate',
      from: 'customfilterfrom',
      to: 'customfilterto',
    });
  }
  filterListData.push({
    name: 'createDate',
    control: 'dateRangePicker',
    isSingle: true,
    title: 'Created date',
    type: 'createDate',
    from: 'createdFrom',
    to: 'createdTo',
  });

  return filterListData;
};
