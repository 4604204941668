import * as sequenceTypes from './sequenceTypes';

export const submitPersonalizeSequence = (data, resume = true, resolve, reject) => ({
  type: sequenceTypes.SUBMIT_PERSONALIZE_SEQUENCE,
  data,
  resume,
  resolve,
  reject,
});

export const enrolAllContacts = (enrolments) => ({
  type: sequenceTypes.ENROLL_ALL_CONTACTS,
  enrolments,
});

export const setFetchErrorLoading = (value) => ({
  type: sequenceTypes.FETCH_ERRORS_LOADING,
  value,
});

export const fetchPopulateEnqueueTemplate = (
  taskId,
  contactId,
  templateId,
  subject = '',
  content = '',
  resolve,
  reject,
) => ({
  type: sequenceTypes.FETCH_POPULATE_ENQUEUE_TEMPLATE,
  taskId,
  contactId,
  templateId,
  subject,
  content,
  resolve,
  reject,
});

export const setPopulateEnqueueTemplate = (contactId, taskId, subject, content, errors) => ({
  type: sequenceTypes.SET_POPULATE_ENQUEUE_TEMPLATE,
  contactId,
  taskId,
  subject,
  content,
  errors,
});

export const fetchPersonalizeSteps = (steps) => ({
  type: sequenceTypes.FETCH_PERSONALIZE_STEPS,
  steps,
});

export const setPersonalizeSteps = (steps) => ({
  type: sequenceTypes.SET_PERSONALIZE_STEPS,
  steps,
});

export const setPersonalizeStep = (taskId, data, resolve, reject) => ({
  type: sequenceTypes.SET_PERSONALIZE_STEP,
  taskId,
  data,
  resolve,
  reject,
});

export const fetchEnrolmentErrors = (contactId, sequenceId, enrolmentId, dontFetchEnrollment) => ({
  type: sequenceTypes.FETCH_ENROLMENT_ERRORS,
  contactId,
  sequenceId,
  enrolmentId,
  dontFetchEnrollment
});

export const setReEnrolmentErrors = (id, data, resolve, reject) => ({
  type: sequenceTypes.SET_RE_ENROLMENT_ERROR,
  id,
  data,
  resolve,
  reject,
});

export const setEnrolmentErrors = (contactId, errors) => ({
  type: sequenceTypes.SET_ENROLMENT_ERRORS,
  contactId,
  errors,
});

export const setEnrolmentData = (enrolments) => ({
  type: sequenceTypes.SET_ENROLMENT_DATA,
  enrolments,
});

export const fetchEnqueueContact = (contactId) => ({
  type: sequenceTypes.FETCH_ENQUEUE_CONTACT,
  contactId,
});

export const setEnqueueContact = (contact) => ({
  type: sequenceTypes.SET_ENQUEUE_CONTACT,
  contact,
});

export const setActiveSeqPersonalize = (enrolmentId) => ({
  type: sequenceTypes.SET_ACTIVE_SEQUENCE_PERSONALIZE,
  enrolmentId,
});

export const clearPersonalizeSequence = () => ({
  type: sequenceTypes.CLEAR_PERSONALIZE_SEQUENCE,
});

export const enrollmentSuccess = (value) => ({
  type: sequenceTypes.ENROLLMENT_SUCCESS,
  value,
});

export const enqueueSequencePersonalize = (
  enrolments,
  action = 'enroll',
  index = 0,
) => ({
  type: sequenceTypes.ENQUEUE_SEQUENCE_PERSONALIZE,
  enrolments,
  action,
  index,
});
