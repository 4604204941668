import React from 'react';
import styled from 'styled-components';
import { Skeleton } from '@mui/lab';
import { Card, CardContent, Box, Divider } from '@mui/material';
import { Avatar, Spacer, Button } from 'src/components/shared';
import SnoozeIcon from '@mui/icons-material/Snooze';
import DoneIcon from '@mui/icons-material/Done';

import { Accordion, AccordionSummary } from '../TaskReplies/ReplyItem';

function UserProfile() {
  const loading = false;
  return (
    <>
      <Accordion expanded={false}>
        <AccordionSummary>
          <Box sx={{ width: '33%', flexShrink: 0 }} display="flex">
            <Skeleton animation="wave" height={24} width={150} />
          </Box>
          <Box sx={{ width: '54%', flexShrink: 0 }} display="flex">
            <Skeleton animation="wave" height={24} width={244} />
          </Box>
          <Box sx={{ width: '12%', flexShrink: 0 }} display="flex">
            <Skeleton animation="wave" height={20} width={120} />
          </Box>
        </AccordionSummary>
      </Accordion>
      <Accordion expanded={false}>
        <AccordionSummary>
          <Box sx={{ width: '33%', flexShrink: 0 }} display="flex">
            <Skeleton animation="wave" height={24} width={150} />
          </Box>
          <Box sx={{ width: '54%', flexShrink: 0 }} display="flex">
            <Skeleton animation="wave" height={24} width={244} />
          </Box>
          <Box sx={{ width: '12%', flexShrink: 0 }} display="flex">
            <Skeleton animation="wave" height={20} width={120} />
          </Box>
        </AccordionSummary>
      </Accordion>
    </>
  );
}

export default UserProfile;
