import React, { useState } from 'react';
import { ButtonBase, Card, CardContent, Stack, Tooltip, Typography } from '@mui/material';

import Bar from './Bar';
import { BarGroup, BarLegend, Bars } from './styles';
import { Modal } from 'src/components/shared';
import images from 'src/config/images';

import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function RoleplayReport({
  scores,
  sessionId,
  session,
  size = 'small',
  sx = {},
  showTitle = true,
  title,
}) {
  const [showAll, setShowAll] = useState(false);
  const allScores = scores
    ? scores.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      })
    : [];

  const featScores = allScores.slice(0, 10);

  const avgScores = allScores.slice(0, 3);
  let average = avgScores.reduce((acc, item) => {
    return acc + item.score;
  }, 0);
  average = (average / avgScores.length).toFixed(1);

  const getContent = (data, size = 'small') => {
    return (
      <>
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          sx={{ background: '#0C1721', padding: '4px 8px', borderRadius: '4px' }}
        >
          <img src={images.LMS.roleplay} />{' '}
          <Typography variant="body2" color="textSecondary">
            {allScores.length} role-plays completed
          </Typography>
          <Typography variant="body2" color="textSecondary">
            |
          </Typography>
          <Typography component="span" variant="body2" color="textSecondary">
            Average score{' '}
            <Typography component="span" variant="body2" color="secondary">
              {average}/5
            </Typography>
          </Typography>
        </Stack>
        <BarGroup size={size}>
          <BarLegend size={size}>
            <span>5</span>
            <span>4</span>
            <span>3</span>
            <span>2</span>
            <span>1</span>
            <span>0</span>
          </BarLegend>
          <Bars size={size}>
            {data.map((score, index) => (
              <Bar
                size={size}
                score={score}
                key={`report-${sessionId}-${index}`}
                session={session}
              />
            ))}
          </Bars>
        </BarGroup>
      </>
    );
  };

  return (
    <>
      <Card>
        <CardContent sx={{ ...sx }}>
          <Stack mb={1} direction="row" justifyContent="space-between" alignItems="center">
            {session && session?.id && showTitle ? (
              <Typography color="textPrimary" variant="body1">
                {title ? title : session.title}
              </Typography>
            ) : (
              <div />
            )}

            <Stack mb={1} direction="row" alignItems="center" gap={1.5}>
              {allScores.length > 10 && (
                <ButtonBase
                  onClick={() => {
                    setShowAll(true);
                  }}
                >
                  <Inventory2OutlinedIcon sx={{ fill: '#A2AFBC', fontSize: '20px' }} />
                </ButtonBase>
              )}

              <Tooltip title="The graph shows results from the last 10 role-plays.">
                <InfoOutlinedIcon sx={{ fill: '#A2AFBC', fontSize: '20px' }} />
              </Tooltip>
            </Stack>
          </Stack>
          {getContent(featScores, size)}
        </CardContent>
      </Card>
      <Modal
        title={session?.title || ''}
        open={showAll}
        onClose={() => {
          setShowAll(false);
        }}
      >
        {getContent(allScores, 'large')}
      </Modal>
    </>
  );
}

export default RoleplayReport;
