import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'src/components/shared';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import { Box, ListItemIcon, MenuItem, Typography, Stack, ButtonBase } from '@mui/material';
import { usePopupState, bindMenu, bindHover, bindFocus } from 'material-ui-popup-state/hooks';
import PushPinIcon from '@mui/icons-material/PushPin';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SearchInput from '../AppGrid/SearchInput';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import styled from 'styled-components';

import { putBoard } from 'src/modules/app/actions/boardActions';

const MenuItemWrap = styled(MenuItem)`
  & .removeButton {
    display: none;
  }
  & .removeButton svg {
    font-size: 16px;
    width: 16px;
    height: 16px;
    color: rgba(0, 0, 0, 0.6);
  }
  &:hover .removeButton {
    display: block;
  }
`;

function PinnedBoards() {
  const popupState = usePopupState({ variant: 'popover', popupId: 'pinnedBoards' });
  const boards = useSelector((state) => state.boards.boards);
  const [search, setSearch] = useState('');
  const history = useHistory();

  const dispatch = useDispatch();

  const togglePopup = (event, task) => {
    popupState.setAnchorEl(event.target);
    popupState.open();
  };

  const pinnedBoards = useMemo(() => {
    const boardsArr = Object.values(boards);
    return boardsArr.filter((item) => {
      if (search && search !== '') return item.name?.toLowerCase().includes(search?.toLowerCase()) && item.pinned === true;
      return item.pinned === true;
    });
  }, [boards, search]);

  const onChange = (key) => {
    setSearch(key);
  };

  const handleRemove = (e, board) => {
    e.stopPropagation();

    return new Promise((resolve, reject) => {
      dispatch(
        putBoard(
          board?.id,
          {
            pinned: board?.pinned ? !board.pinned : true,
          },
          resolve,
          reject,
        ),
      );
    });
  };

  return (
    <>
      <Button iconButton {...bindHover(popupState)} {...bindFocus(popupState)}>
        <PushPinIcon />
      </Button>
      <HoverMenu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box px={2} py={1}>
          <SearchInput placeholder="Search" searchText={search} onSearch={onChange} />
        </Box>
        <Box width={300}>
          {pinnedBoards.length
            ? pinnedBoards.map((board) => (
                <MenuItemWrap
                  key={`board-item-${board.id}`}
                  onClick={() => {
                    history.push(`/boards/${board.id}`);
                  }}
                >
                  <ListItemIcon>
                    <DashboardIcon
                      sx={{
                        fill:
                          board?.tabColor && board?.tabColor !== '#ffffff'
                            ? board?.tabColor
                            : 'rgba(0,0,0,0.60)',
                      }}
                    />
                  </ListItemIcon>
                  <Stack
                    flex={1}
                    width="100%"
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      color={
                        board?.tabColor && board?.tabColor !== '#ffffff'
                          ? board?.tabColor
                          : 'rgba(0,0,0,0.86)'
                      }
                    >
                      {board.name}
                    </Typography>
                    <ButtonBase
                      className="removeButton"
                      onClick={(e) => {
                        handleRemove(e, board);
                      }}
                    >
                      <RemoveCircleOutlineIcon
                        sx={{ fontSize: '16px', color: 'rgba(0,0,0,0.6)' }}
                      />
                    </ButtonBase>
                  </Stack>
                </MenuItemWrap>
              ))
            : null}
        </Box>
      </HoverMenu>
    </>
  );
}

export default PinnedBoards;
