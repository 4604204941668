import React, { useMemo } from 'react';
import { Card, CardContent, Tooltip, Typography } from '@mui/material';
import { Box, CircularProgress, ButtonBase, InputAdornment, Stack } from '@mui/material';
import { Form, Button, Spacer } from 'src/components/shared';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import ScheduleIcon from '@mui/icons-material/Schedule';
import RefreshIcon from '@mui/icons-material/Refresh';
import styled from 'styled-components';

import OutboxBulkActions from '../OutboxBulkActions';
import { HoverButton } from '../../Inbox/Messages/styles';

import { isEmpty } from 'lodash';

export const ScrolledList = styled.div`
  max-height: calc(100vh - 256px);
  overflow: auto;
`;

import Item from './Item';
import InboxSkeleton from '../../Inbox/InboxSkeleton';
import { Empty } from 'src/components/App';

function List({ data, loading, search, messageKey, filters, ...props }) {
  const hasSearch = filters?._search || false;
  delete filters?._search;
  const hasFilters = !isEmpty(filters);

  const initialVal = useMemo(() => {
    if (!data || !data.length) return {};
    return data.reduce(
      (acc, item) => {
        acc[item.id] = false;
        return acc;
      },
      { search: search, all: false },
    );
  }, [data]);

  return (
    <Form initialValues={initialVal} enableReinitialize={true}>
      {({ values, ...formProps }) => {
        const checkedLength = Object.keys(values).filter(
          (key) => key !== 'all' && key !== 'search' && values[key] === true,
        ).length;
        const checkedTasks = data.filter((item) => values[item.id] === true);

        return (
          <Card>
            <CardContent sx={{ paddingBottom: '8px' }}>
              <Stack direction="row" gap={1} alignItems="center">
                <Box pl={1} maxWidth={16}>
                  <Form.Field.Checkbox
                    name="all"
                    size="small"
                    sx={{ padding: 0 }}
                    label={<Box></Box>}
                    onChange={(val) => {
                      data.map((item) => {
                        formProps.setFieldValue(item.id, val);
                      });
                    }}
                  />
                </Box>
                {checkedLength ? (
                  <Stack direction="row" alignItems="center" height={'40px'}>
                    {/* <Spacer x={1} /> */}
                    <Typography variant="body2" color="textSecondary">
                      {checkedLength} Selected
                    </Typography>
                    <Spacer x={2} />
                    <Tooltip title="Send now">
                      <div>
                        <HoverButton
                          iconButton
                          onClick={() => {
                            props.handleBulkAction('send', checkedTasks);
                          }}
                        >
                          <SendIcon sx={{ fontSize: '22px' }} />
                        </HoverButton>
                      </div>
                    </Tooltip>
                    <Spacer x={1} />
                    <Tooltip title="Pause until">
                      <div>
                        <HoverButton
                          iconButton
                          onClick={() => {
                            props.handleBulkAction('scheduledOn', checkedTasks);
                          }}
                        >
                          <ScheduleIcon sx={{ fontSize: '22px' }} />
                        </HoverButton>
                      </div>
                    </Tooltip>
                  </Stack>
                ) : (
                  <Stack direction="row" gap={1} width={'100%'}>
                    <Form.Field.InputDebounced
                      name="search"
                      variant="outlined"
                      fullWidth
                      onChange={(val) => {
                        props.onSearch(val);
                      }}
                      placeholder="Search"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon sx={{ fontSize: '20px' }} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : search && search !== '' ? (
                              <ButtonBase
                                onClick={() => {
                                  props.onSearch(undefined);
                                }}
                              >
                                <CloseIcon sx={{ fontSize: '20px' }} />
                              </ButtonBase>
                            ) : null}
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        backgroundColor: '#ffffff',
                        borderRadius: '4px',
                        margin: 0,
                      }}
                    />
                    <Button iconButton disabled={loading} onClick={props.refetch}>
                      <RefreshIcon />
                    </Button>
                  </Stack>
                )}
              </Stack>
            </CardContent>
            {loading ? (
              <InboxSkeleton />
            ) : (
              <>
                {data && data.length ? (
                  <ScrolledList>
                    <>
                      {data.map((task, index) => {
                        return (
                          <Item
                            task={task}
                            messageKey={messageKey}
                            key={`inbox-item-${index}`}
                            {...props}
                          />
                        );
                      })}
                    </>
                  </ScrolledList>
                ) : (
                  <Box p={2}>
                    {hasFilters && !hasSearch && (
                      <Empty
                        showIcon={false}
                        description={`No scheduled emails matched with your filter. Please update your filters.`}
                      />
                    )}
                    {hasSearch && (
                      <Empty
                        showIcon={false}
                        title={`No scheduled emails matched with your search.`}
                        description={`Try using search options such as first or/and last name of the contact, the subject line of an email, text in the body of the email and the email address of the contact.`}
                      />
                    )}
                  </Box>
                )}
              </>
            )}
          </Card>
        );
      }}
    </Form>
  );
}

export default List;
