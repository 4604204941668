import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';

// import DatePicker from 'src/components/Automation/components/DateTimePickers/DatePicker';
import { DatePicker } from 'src/components/shared';
import { dateFormat } from 'src/config';

const useStyles = makeStyles(({ custom }) => ({
  pickerWrapper: {
    width: '100%',
  },
  datePickerWrapper: {
    width: 'calc(50% - 20px)',
    maxWidth: 140,
  },
  pickerDivider: {
    marginTop: '8px',
    padding: '0 8px',
    display: 'flex',
    alignItems: 'center',
  },
}));

const FilterDatePickers = ({ onChange, selectedDates, from = 'from', to = 'to' }) => {
  const classes = useStyles();

  const [customDates, setCustomDates] = useState({});
  const [minDate, setMinDate] = useState();

  // useEffect(() => {
  //   setCustomDates({
  //     [from]: selectedDates[from]
  //       ? selectedDates[from]
  //       : moment().format('YYYY-MM-DD'),
  //     [to]: selectedDates[to]
  //       ? selectedDates[to]
  //       : moment().format('YYYY-MM-DD'),
  //   });
  // }, [selectedDates]);

  const handleDatePicker = (name, typeDatePicker) => (date) => {
    let newDates = {
      ...customDates,
    };
    if (typeDatePicker === 'startDate') {
      if (customDates[to] && moment(date).diff(customDates[to]) >= 0) {
        newDates = {
          ...newDates,
          [name]: moment(date).format('YYYY-MM-DD'),
          [to]: moment(date).format('YYYY-MM-DD'),
        };
      } else {
        newDates = {
          ...newDates,
          [name]: moment(date).format('YYYY-MM-DD'),
        };
      }
      setMinDate(date);
    }
    if (typeDatePicker === 'endDate') {
      newDates = {
        ...customDates,
        [name]: moment(date).format('YYYY-MM-DD'),
      };
    }

    setCustomDates({
      ...customDates,
      ...newDates,
    });

    const newDateData = {
      ...customDates,
      ...newDates,
    };

    if (newDateData[from] && newDateData[to]) {
      onChange({
        isSingle: true,
        type: 'dateRangePicker',
        data: {
          ...customDates,
          ...newDates,
        },
      });
    }
  };

  return (
    <Box>
      <Grid container>
        <Box className={classes.datePickerWrapper}>
          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              className={classes.pickerWrapper}
              disableToolbar
              name={from}
              placeholder={dateFormat}
              variant="inline"
              inputVariant={undefined}
              format="dd-MM-yy"
              margin="normal"
              autoOk
              value={
                customDates[from]
                  ? moment(customDates[from], 'YYYY-MM-DD').toDate()
                  : null
              }
              onChange={handleDatePicker(from, 'startDate')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputLabelProps={{ shrink: false }}
            />
          </MuiPickersUtilsProvider> */}
        </Box>
        <Typography variant="body1" component="div" className={classes.pickerDivider}>
          To
        </Typography>
        <Box className={classes.datePickerWrapper}>
          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              className={classes.pickerWrapper}
              disableToolbar
              name={to}
              placeholder={dateFormat}
              variant="inline"
              inputVariant={undefined}
              format="dd-MM-yy"
              margin="normal"
              minDate={minDate}
              autoOk
              value={
                customDates[to]
                  ? moment(customDates[to], 'YYYY-MM-DD').toDate()
                  : null
              }
              onChange={handleDatePicker(to, 'endDate')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputLabelProps={{ shrink: false }}
            />
          </MuiPickersUtilsProvider> */}
        </Box>
      </Grid>
    </Box>
  );
};

export default FilterDatePickers;
