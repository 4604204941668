import React from 'react';
import * as Yup from 'yup';
import { Box, Typography, Grid } from '@mui/material';
import { Form, Button, Spacer } from 'src/components/shared';
import { FieldArray } from 'formik';

import { FilterBlock } from './styles';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import { filterTypes, conditions, conditionTypes, filterTabTypes } from '../../../../config';

const defaultFilters = [
  {
    column: '',
    conditionType: '',
    condition: '',
    value: '',
  },
];

function AdvancedFilters({ filters, onClose, onChange }) {
  const handleSubmit = (data) => {
    onChange({ _where: data.filters });
  };

  const commonProps = {
    fullWidth: true,
    variant: 'outlined',
    size: 'small',
    sx: { fontSize: '14px', backgroundColor: '#ffffff', margin: 0 },
    style: { fontSize: '14px' },
    margin: 'none',
  };

  return (
    <Form
      initialValues={{
        filters: filters?._where ? filters?._where : [{ filters: defaultFilters }],
      }}
      validationSchema={Yup.object().shape({})}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ values, ...formProps }) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formProps.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <>
              <FieldArray
                name="filters"
                render={(arrayHelpers) => (
                  <div>
                    {values.filters &&
                      values.filters.length > 0 &&
                      values.filters.map((filter, blockIndex) => {
                        return (
                          <FilterBlock
                            key={`filters-block-${blockIndex}`}
                            type={blockIndex == 0 ? 'and' : 'or'}
                            onRemove={() => {
                              arrayHelpers.remove(blockIndex);
                            }}
                          >
                            {filter.filters && filter.filters.length ? (
                              <>
                                {filter.filters.map((filterItem, filterIndex) => {
                                  return (
                                    <Grid
                                      container
                                      spacing={0}
                                      alignItems="center"
                                      pt={1}
                                      key={`filters-block-${blockIndex}-filter-${filterIndex}`}
                                    >
                                      <Grid item xs={4} pr={1}>
                                        <Form.Field.Select
                                          options={Object.values(filterTypes)}
                                          {...commonProps}
                                          name={`filters[${blockIndex}].filters[${filterIndex}].column`}
                                          optValue="key"
                                          optLabel="title"
                                        />
                                      </Grid>
                                      <Grid item xs={3} pr={1}>
                                        <Form.Field.Select
                                          options={conditions}
                                          name={`filters[${blockIndex}].filters[${filterIndex}].condition`}
                                          placeholder="option"
                                          optValue="key"
                                          optLabel="title"
                                          {...commonProps}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        {values.filters[blockIndex].filters[filterIndex]?.column &&
                                        values.filters[blockIndex].filters[filterIndex]?.column !==
                                          '' ? (
                                          <>
                                            {filterTypes[
                                              values.filters[blockIndex].filters[filterIndex].column
                                            ].type === 'text' ? (
                                              <Form.Field.Input
                                                name={`filters[${blockIndex}].filters[${filterIndex}].value`}
                                                InputProps={{ style: { fontSize: 14 } }}
                                                {...commonProps}
                                              />
                                            ) : null}

                                            {filterTypes[
                                              values.filters[blockIndex].filters[filterIndex].column
                                            ].type === 'date' ? (
                                              <Form.Field.Datepicker
                                                fullWidth={true}
                                                size="small"
                                                inputVariant="outlined"
                                                name={`filters[${blockIndex}].filters[${filterIndex}].value`}
                                                InputProps={{ style: { fontSize: 14 } }}
                                                {...commonProps}
                                              />
                                            ) : null}
                                          </>
                                        ) : null}
                                      </Grid>
                                      <Grid
                                        item
                                        xs={1}
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                        <Button
                                          iconButton={true}
                                          onClick={() => {
                                            arrayHelpers.replace(blockIndex, {
                                              filters: [
                                                ...values.filters[blockIndex].filters.filter(
                                                  (item, index) => index !== filterIndex,
                                                ),
                                              ],
                                            });
                                          }}
                                        >
                                          <CloseIcon
                                            sx={{ fontSize: '20px', color: 'rgba(0,0,0,0.54)' }}
                                          />
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                              </>
                            ) : null}

                            <Box mt={1}>
                              <Button
                                color="secondary"
                                size="small"
                                onClick={() => {
                                  arrayHelpers.replace(blockIndex, {
                                    filters: [
                                      ...values.filters[blockIndex].filters,
                                      defaultFilters[0],
                                    ],
                                  });
                                }}
                                startIcon={<AddIcon />}
                              >
                                Add Filter
                              </Button>
                            </Box>
                          </FilterBlock>
                        );
                      })}
                    <Button
                      color="secondary"
                      onClick={() => {
                        // arrayHelpers.push(defaultFilters[0]);
                        arrayHelpers.push({ filters: defaultFilters });
                      }}
                      startIcon={<AddIcon />}
                    >
                      Add Filter Block
                    </Button>
                  </div>
                )}
              />
            </>

            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Button color="secondary" size="small" variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Spacer x={2} y={2} />
              <Button color="secondary" size="small" type="submit" variant="contained">
                Apply
              </Button>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}

export default AdvancedFilters;
