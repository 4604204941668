import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { Tooltip, Typography, Box, Chip, Stack } from '@mui/material';
import { Menu, Button } from 'src/components/shared';

import competitionGridHeader from './CompetitionGridHeader';
import useStyles from './style';
import moment from 'moment';

import { capitalizeFirstLetter } from 'src/utils/helper';

import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { calculateDaysLeftForRoleplay } from 'src/modules/lms/utils/helper';

export function useCompetitionColumns(
  competitions,
  fields,
  onEdit,
  onDelete,
  toggleContactDrawer,
  handleCompetitionEdit,
  handleImmediateInviteChange,
) {
  const [columns, setColumns] = useState([]);
  const classes = useStyles();
  const history = useHistory();

  /*PendingStart Typo Bug*/
  const statusMappings = {
    pendingStart: 'Pending Start',
  };

  useEffect(() => {
    let data = competitionGridHeader.map((item) => {
      return {
        name: item.fieldName,
        label: item.label,
        options: {
          // setCellHeaderProps: (value) => ({
          //   style: { minWidth: 190, width: 190, maxWidth: 190, padding: 0 },
          // }),
          // setCellProps: () => ({
          //   style: { minWidth: 190, width: 190, maxWidth: 190, padding: 0 },
          // }),
          sort: false,
          ...(item.gridParams &&
            item.gridParams?.competitions && {
              ...item.gridParams.competitions,
            }),
          ...(item.type === 'default' && {
            customBodyRenderLite: (index) => {
              const competition = competitions[index];

              if (!competition?.id) return '';

              switch (item.fieldName) {
                case 'name':
                  return <Typography variant="body2">{competition?.name}</Typography>;
                  break;

                default:
                  return typeof competition[item.fieldName] !== 'undefined' &&
                    competition[item.fieldName]
                    ? 'competition[item.fieldName]'
                    : '';
                  break;
              }
            },
          }),
        },
      };
    });

    data.push({
      name: 'Users',
      label: 'Users',
      options: {
        filter: false,
        sort: false,

        customBodyRenderLite: (index) => {
          const competition = competitions[index];
          return <Typography variant="body2">{competition?.users?.length}</Typography>;
        },
      },
    });
    data.push({
      name: 'timeLeft',
      label: 'Time left',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (value) => ({
          style: { maxWidth: 60, padding: 0 },
        }),
        setCellProps: () => ({
          style: { maxWidth: 60, padding: 0 },
        }),
        customBodyRenderLite: (index) => {
          const competition = competitions[index];
          return (
            <Typography variant="body2">
              {calculateDaysLeftForRoleplay(competition?.endDate)}
            </Typography>
          );
        },
      },
    });

    data.push({
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (value) => ({
          style: { maxWidth: 140, padding: 0, textAlign: 'center' },
        }),
        setCellProps: () => ({
          style: { maxWidth: 140, padding: 0, textAlign: 'center' },
        }),
        customBodyRenderLite: (index) => {
          const competition = competitions[index];
          return (
            <Box ml={1} mt={-1}>
              <Chip
                className={classes[competition?.status]}
                label={capitalizeFirstLetter(
                  statusMappings[
                    competition?.status === 'pendingStart' ? 'Pending' : competition?.status
                  ] || competition?.status === 'pendingStart'
                    ? 'Pending'
                    : competition?.status,
                )}
              />
            </Box>
          );
        },
      },
    });
    data.push({
      name: 'completed',
      label: 'Completed',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (value) => ({
          style: { maxWidth: 42 },
        }),
        setCellProps: () => ({
          style: { maxWidth: 42 },
        }),
        customBodyRenderLite: (index) => {
          const competition = competitions[index];
          return (
            <Box display={'flex'}>
              <Typography variant="body2">{competition?.completedPer || 0}%</Typography>
            </Box>
          );
        },
      },
    });
    data.push({
      name: 'id',
      label: ' ',
      options: {
        sort: false,
        draggable: false,
        setCellHeaderProps: (value) => ({
          style: { maxWidth: 150 },
        }),
        setCellProps: () => ({
          style: { maxWidth: 150 },
        }),
        customBodyRender: (value) => {
          const competition = competitions?.find((tData) => tData.id === value);
          return (
            <Stack direction="row" alignItems="center" justifyContent="flex-start" gap={0}>
              <Tooltip title="Report">
                <div>
                  <Button
                    iconButton
                    onClick={() => {
                      history.push(`/lms/report/${competition?.id}`);
                    }}
                  >
                    <AssessmentIcon sx={{ fill: '#F28AE2' }} />
                  </Button>
                </div>
              </Tooltip>
              <Tooltip title="League table">
                <div>
                  <Button
                    iconButton
                    onClick={() => {
                      history.push(`/lms/league/${competition?.id}`);
                    }}
                  >
                    <EmojiEventsIcon sx={{ fill: '#FFD47E' }} />
                  </Button>
                </div>
              </Tooltip>
              {competition?.status !== 'completed' && (
                <Menu
                  options={[
                    {
                      label: 'Edit competition',
                      onClick: () => {
                        handleCompetitionEdit(competition);
                      },
                    },
                  ]}
                />
              )}
            </Stack>
          );
        },
      },
    });
    setColumns(data);
  }, [competitionGridHeader, competitions]);

  return columns;
}
