import React from 'react';
import { Box, Typography } from '@mui/material';
import { Button, Spacer } from 'src/components/shared';
import IntegrationsCards from './IntegrationsCards';

export default function ConnectEmail({
  editUser,
  disconnectIntegration,
  saveRescheduleEmail,
  status,
}) {
  const isConnected = editUser?.nlSyncState && editUser?.nlSyncState !== 'stopped' && editUser?.nlSyncState !== 'invalid';

  return (
    <Box mb={2}>
      <Typography variant="h2" color="textPrimary">
        Connect your email account
      </Typography>
      <Spacer x={0.5} y={0.5} />
      <Typography variant="body2" color="textSecondary">
        Connect your email account to log, track, send, and receive emails in the SuperReach.
      </Typography>
      <Spacer x={2} y={2} />

      <IntegrationsCards
        editAccount={editUser}
        disconnectIntegration={disconnectIntegration}
        saveRescheduleEmail={saveRescheduleEmail}
      />
      {/* <Spacer x={2} y={2} />
      {!isConnected && (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            const newWindow = window.open(
              `${process.env.REACT_APP_BASE_API_URL}/api/user/nlauth`,
              '_blank',
              'noopener,noreferrer',
            );
            if (newWindow) newWindow.opener = null;
          }}
        >
          Connect now
        </Button>
      )} */}
    </Box>
  );
}
