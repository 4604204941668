import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Grid, Box, TextField } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import { updateEventsDetails, addEventsDetails } from './api';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';
import moment from 'moment';
import { fetchContactsCalLookup } from '../contacts/api/contactApis';
import toast from 'src/utils/toast';

function EditEvent({ teamLead, setShowModal, editEvent, value, fetchEvent, formData, ...props }) {
  const [loading, setLoading] = useState(false);
  const [defaultCandidate, setDefaultCandidate] = useState({});
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();

  const startMemoTime = useMemo(() => {
    setStartTime(editEvent?.start && moment(editEvent?.start).format('HH:mm'));
    return editEvent?.start && moment(editEvent?.start).format('HH:mm');
  }, [editEvent?.start]);

  const endMemoTime = useMemo(() => {
    setEndTime(editEvent?.end && moment(editEvent?.end).format('HH:mm'));
    return editEvent?.end && moment(editEvent?.end).format('HH:mm');
  }, [editEvent?.end]);

  const handleSubmit = async (values, form) => {
    const data = { ...values };
    data.title = values.title;
    data.contact = values.contact.id || '';

    let startDate = values.start ? new Date(values.start) : new Date();
    let endDate = values.end ? new Date(values.end) : new Date();
    let startHour = data.startTime.split(/:(.*)/s);
    let endHour = data.endTime.split(/:(.*)/s);
    startDate.setHours([startHour[0]]);
    startDate.setMinutes([startHour[1]]);
    endDate.setHours([endHour[0]]);
    endDate.setMinutes([endHour[1]]);
    data.startTime = startDate.toISOString();
    data.endTime = endDate.toISOString();

    delete data.start;
    delete data.end;

    if (editEvent && editEvent.id) {
      await updateEventsDetails(editEvent.id, data);
      setShowModal(false);
      fetchEvent();
      toast.success('Event Updated!', 'tc');
    } else {
      await addEventsDetails(data);
      setShowModal(false);
      fetchEvent();
      toast.success('Event Added!', 'tc');
    }
  };

  return (
    <Form
      initialValues={{
        title: editEvent?.title.title || '',
        start: moment(editEvent?.start).format('MM-DD-YYYY'),
        end: moment(editEvent?.end).format('MM-DD-YYYY'),
        startTime: startTime || '12:00',
        endTime: endTime || '12:00',
        description: editEvent?.description || '',
        contact: {
          id: editEvent?.title?.extendedProps?.contact?.id || '',
          value: `${editEvent?.title?.extendedProps?.contact?.fname || ''} ${
            editEvent?.title?.extendedProps?.contact?.lname || ''
          }`,
        } || { id: '', value: '' },
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required('Please enter title!'),
        contact: Yup.object()
          .required('Please select contact')
          .test(
            'assignedTo-check',
            'Please choose a Assigned contact to continue.',
            async (value, { createError }) => {
              if (value && value.id && value.id !== '') {
                return true;
              }
              return false;
            },
          ),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={false}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              justifyContent="space-between"
              //   alignItems="flex-end"
              height="100%"
            >
              <Box>
                <Grid container spacing={1} mt={1}>
                  <Form.Field.Input
                    fullWidth
                    size="small"
                    rows={4}
                    variant="outlined"
                    name="title"
                    label="Title"
                  />
                  <Form.Field.Datepicker
                    fullWidth
                    size="small"
                    variant="outlined"
                    name="start"
                    label="Start"
                  />
                  <Form.Field.Datepicker
                    fullWidth
                    size="small"
                    variant="outlined"
                    name="end"
                    label="End"
                  />

                  <Form.Field.Timepicker
                    fullWidth
                    size="small"
                    name="startTime"
                    label="Start Time"
                    // value={startTime}
                    variant="outlined"
                    rows={3}
                    // xfieldPicker={true}
                  />

                  <Form.Field.Timepicker
                    fullWidth
                    size="small"
                    name="endTime"
                    label="End Time"
                    // value={endTime}
                    variant="outlined"
                    rows={2}
                    // xfieldPicker={true}
                  />

                  <Form.Field.AutoComplete
                    multiple={false}
                    size="small"
                    options={[]}
                    fullWidth
                    variant="outlined"
                    name="contact"
                    label="Contact"
                    remoteMethod={(search) => {
                      return fetchContactsCalLookup(search);
                    }}
                    optLabel="value"
                    optValue="id"
                  />
                  <Form.Field.Input
                    size="small"
                    fullWidth
                    rows={4}
                    variant="outlined"
                    name="description"
                    label="Description"
                  />
                </Grid>
              </Box>

              <Box container spacing={2} ml={42}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}
export default EditEvent;
