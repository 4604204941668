import styled from 'styled-components';
import { Paper, Container } from '@mui/material';

export const ContactSidebar = styled(Paper)`
  width: 300px;
  min-height: calc(100vh - 50px);
  margin-left: -25px;
  margin-top: -12px;
  overflow-y: auto;
  max-width: 300px;
  border-radius: 0;
  padding-top: 12px;
`;

export const ContactMainView = styled(Container)`
  flex: 1;
  height: 100%;
  min-height: calc(100vh - 50px);
  margin: 0;
`;
