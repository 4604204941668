import React, { useState } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Stack } from '@mui/material';
import { getPriorityIcon, getTaskTypeText, getTaskTypeIcon } from '../../utils/tasksUtils';
import { updateTemplate } from 'src/modules/admin/api/adminApi';
import { updateTask } from 'src/modules/tasks/api/taskApis';
import { Form, Button, Spacer } from 'src/components/shared';
import { postContactNote } from 'src/modules/contacts/actions/contactActions';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteIcon from '@mui/icons-material/Delete';
import toast from 'src/utils/toast';

function Call({ task, setShowDueDateForm, noteTypes, ...props }) {
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const template = task?.template || {};

  const user = useSelector((state) => state.auth.user);
  const { contact } = task;

  const handleSubmit = async (values, form) => {
    try {
      setLoading(true);
      let payload = {
        description: values.content,
      };
      if (values?.addNote === true) {
        payload.customNoteEnabled = true;
        payload.customNoteText = values.noteContent;
        payload.customNoteType = values.noteType;
      }
      await updateTask(task.id, payload);
      toast.success('Task updated successfully');
      setLoading(false);
    } catch (error) {
      toast.error('Error updating task');
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    props.toggleDeleteModel();
  };

  return (
    <Form
      initialValues={{
        content: task?.description || '',
        noteType: task?.customNoteType || '',
        addNote: task?.customNoteEnabled || false,
        noteContent: task?.customNoteText || '',
      }}
      validationSchema={Yup.object().shape({
        content: Yup.string().required('Content is required!'),
      })}
      onSubmit={async (values, form) => {
        handleSubmit(values, form);
        form.prevent;
      }}
      enableReinitialize={true}
    >
      {({ values, ...formProps }) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formProps.submitForm();
              return false;
            }}
            noValidate
          >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" gap={1} alignItems="center">
                {getTaskTypeIcon(task.taskType)}
                <Typography component="span" variant="h3" color="textPrimary">
                  {getTaskTypeText(task.taskType)}
                </Typography>
              </Stack>
              <Stack direction="row">
                <Button iconButton onClick={setShowDueDateForm}>
                  <AccessTimeIcon />
                </Button>
                <Button iconButton loading={deleting} onClick={handleDelete}>
                  <DeleteIcon />
                </Button>
                <Spacer x={1} />
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  size="small"
                  loading={loading}
                >
                  Save
                </Button>
              </Stack>
            </Stack>

            <Spacer x={2} y={2} />

            <Form.Field.TextEditor
              fullWidth
              variant="outlined"
              name="content"
              showPlaceholder={true}
            />

            <Box ml={2} mt={3} mb={1}>
              <Form.Field.Switch name="addNote" label="Add Note" />
            </Box>
            {values.addNote === true && (
              <Box maxWidth={400}>
                <Form.Field.Select
                  options={noteTypes || []}
                  fullWidth
                  variant="outlined"
                  name="noteType"
                  label="Note Type"
                  optLabel="label"
                  optValue="value"
                  showNone={false}
                />

                <Form.Field.Input
                  fullWidth
                  variant="outlined"
                  name="noteContent"
                  label="Content"
                  multiline={true}
                  maxRows={4}
                  minRows={4}
                />
              </Box>
            )}
          </form>
        );
      }}
    </Form>
  );
}

export default Call;
