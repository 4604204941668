import { themeColors } from '../../config';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({ palette }) => ({
  alignment: {
    marginTop: '18px',
  },
  container: {
    background: '#082037',
    borderRadius: '10px',
    padding: '31px 31px 50px 31px',
  },
  Dropdown: {
    '& button': {
      color: '#FAFAFA !important',
      width: '30%',
      marginBottom: '30px',
      background: ' #193247 !important',
    },
  },
  Search: {
    marginBottom: '30px',
    color: '#FAFAFA !important',
    width: '42%',
    opacity: '0.6',
    borderRadius: '10px',
    background: '#193247 !important',
    ' & input': {
      color: '#FAFAFA !important',
      borderRadius: '10px',
      background: ' #193247 !important',
    },
    '& label': {
      color: '#FAFAFA !important',
    },
  },
  Description: {
    color: '#fafafa !important',
  },
  chip: {
    background: '#13DEA1',
    marginRight: '10px',
  },

  form: {
    background: '#19324A',
    padding: '2%',
    borderRadius: '7px',
    '& iframe': {
      backgroundColor: '#19324A !important ',
    },
    '& .tox .tox-toolbar__primary': {
      backgroundColor: '#19324A !important ',
      color: '#D9D9D9 !important',
      border: '1px solid #5F5F5F !important ',
    },
    '& .tox-tinymce': {
      border: 'none !important',
    },
    '& .tox-tbtn--select': {
      color: '#D9D9D9 !important',
    },
    '& .tox .tox-tbtn svg ': {
      fill: '#D9D9D9 !important',
    },
    '.css-wax5jp': {
      backgroundColor: '#19324A !important ',
    },
    '& content': {
      color: '#D9D9D9 !important',
    },
    ' & .tox-tbtn': {
      '&:hover': {
        background: '#082037 !important',
      },
    },
    '& .css-i4bv87-MuiSvgIcon-root': {
      color: 'white !important',
    },
    '& .MuiAutocomplete-input': {
      color: 'white !important',
    },
  },
  heading: {
    fontWeight: '300',
    fontSize: '26px',
    color: themeColors.greyHeadingText,
  },
  paragraph: {
    fontWeight: '400',
    fontSize: '18px',
    color: themeColors.greyParagraphText,
    lineHeight: '28px',
  },
  links: {
    color: themeColors.greyText,
    fontWeight: '300',
    fontSize: '12px',
  },
  button: {
    background: '#CF0175',
  },
  buttonTrans: {
    background: '#102639',
    border: '1px solid #FFFFFF',
  },
  btnAlignment: {
    marginRight: '12px',
    marginTop: '30px',
    borderRadius: '3.30904px',
    textTransform: 'capitalize',
    color: '#ffff !important',
  },
  subtitle:{
    color:'rgba(250, 250, 250, 1) !important',
    fontWeight: '300',
    fontSize: '15.4096px',
  },
}));
export default useStyles;
