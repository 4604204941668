import React from 'react';
import { Box, CircularProgress, ButtonBase, InputAdornment } from '@mui/material';
import { Form, Button, Spacer } from 'src/components/shared';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import { isEmpty } from 'lodash';

function ContactsSearch({ search, loading, onChange, setShowDrawer, drawerFilters }) {
  return (
    <Box pl={1.2} pr={1.2} mb={1} display="flex" alignItems="center">
      <Form initialValues={{ search: search }} enableReinitialize={true}>
        <Form.Field.InputDebounced
          name="search"
          variant="outlined"
          fullWidth
          onChange={(val) => {
            onChange('_search', val);
          }}
          placeholder="search"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ fontSize: '20px' }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : search && search !== '' ? (
                  <ButtonBase
                    onClick={() => {
                      onChange('_search', undefined);
                    }}
                  >
                    <CloseIcon sx={{ fontSize: '20px' }} />
                  </ButtonBase>
                ) : null}
              </InputAdornment>
            ),
          }}
          sx={{
            backgroundColor: '#ffffff',
            borderRadius: '4px',
            margin: 0,
          }}
        />
      </Form>
      <Spacer basis={1.2} />
      <ButtonBase
        variant="contained"
        size="small"
        sx={{
          // backgroundColor: isEmpty(drawerFilters) ? '#ffffff' : '#082037',
          color: isEmpty(drawerFilters) ? '#082037' : '#ffffff',
          height: 40,
          width: 54,
          borderRadius: '4px',
        }}
        onClick={(event) => {
          setShowDrawer(event);
        }}
      >
        <FilterListIcon sx={{ fontSize: '20px' }} />
      </ButtonBase>
    </Box>
  );
}

export default ContactsSearch;
