import { Box, Input, Stack, Typography } from '@mui/material';
import React from 'react';
import { Form } from 'src/components/shared';
import { fetchTemplatesLookup } from 'src/modules/sequence/api/sequenceApi';
import { sequenceWaitUnitOptions } from '../../../config';

function Wait({ event }) {
  return (
    <>
      <Stack direction="row">
        <Stack
          flex={1}
          direction="row"
          sx={{ border: '1px solid rgba(0,0,0,0.12)', padding: '0px 16px' }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body2" color="textSecondary">
            Select Wait Period
          </Typography>
          <Form.Field.Select
            options={sequenceWaitUnitOptions}
            name={`${event.id}.unit`}
            showNone={false}
            sx={{
              borderRadius: 0,
              borderBottom: 'none',
              width: '100px',
              marginTop: '-4px !important',
            }}
            disableUnderline={true}
          />
        </Stack>
        <Stack
          flex={1}
          direction="row"
          sx={{ border: '1px solid rgba(0,0,0,0.12)', padding: '0px 16px' }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body2" color="textSecondary">
            Wait time
          </Typography>
          <Form.Field.Number
            showCounter={true}
            name={`${event.id}.waitTime`}
            showNone={false}
            sx={{ borderRadius: 0, marginLeft: '-1px', width: '160px' }}
            InputProps={{ disableUnderline: true }}
          />
        </Stack>
      </Stack>
    </>
  );
}

export default Wait;
