import React, { useState, useEffect } from 'react';
import { Tooltip, Chip, Typography, Box, Link } from '@mui/material';
import { Spacer, Menu, Button } from 'src/components/shared';

export function ueActivityReportColumns(data) {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setColumns([
      {
        label: 'User',
        name: 'name',
        options: {
          setCellHeaderProps: (value) => ({
            style: { width: 120 },
          }),
          setCellProps: () => ({
            style: { width: 120 },
          }),
        },
      },
      {
        label: 'Task Completed',
        name: 'tasksCompleted',
      },
      {
        label: 'Contacts Added',
        name: 'contactsAdded',
      },

      {
        label: 'Interested Contacts',
        name: 'intrestedContacts',
      },
      {
        label: 'Emails Sent',
        name: 'emailSent',
      },
      {
        label: 'Sequence Replies',
        name: 'replyOfSequence',
      },
    ]);
  }, [data]);

  return columns;
}
