import React from 'react';
import { Box, Typography, Chip } from '@mui/material';
import { Form, Spacer } from 'src/components/shared';

function ManageUserLicense({}) {
  return (
    <>
      <Form initialValues={{}}>
        {({ values }) => {
          return (
            <>
              <Typography>OutReach Seats</Typography>
              <Typography color="textSecondary">2/6 Monthly seats available</Typography>
            </>
          );
        }}
      </Form>
    </>
  );
}

export default ManageUserLicense;
