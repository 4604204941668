import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchTemplates, deleteTemplate } from '../actions/LMSActions';
import SessionDetail from '../components/LMSSession/components/sessionDetail';
import Template from '../components/MyTemplates/MyTemplate';

const LMSTemplatesContainer = (props) => {
  useEffect(() => {
    props.fetchTemplates();
  }, []);
  return (
    <>
      <Template {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({
  templates: state.lms.templates,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTemplates: (paging, filters, sort) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchTemplates({ paging, filters, sort, resolve, reject }));
    });
  },
  deleteTemplate: (templateId) => {
    dispatch(deleteTemplate(templateId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LMSTemplatesContainer);
