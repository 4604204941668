import React, { useEffect, useState } from 'react';
import {
  Box,
  Fade,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FilterListIcon from '@mui/icons-material/FilterList';
import { isArray } from 'lodash';

import FilterDatePickers from './DrawerFilter/DatePicker';
import Checkbox from './DrawerFilter/CheckboxLabel';

import useStyles from './styles';

const FilterPickers = ({
  className = '',
  allOptions = [],
  optValue = 'id',
  optLabel = 'name',
  title = '',
  name = '',
  type = '',
  isSingle = false,
  handleFilterChange = () => {},
  selectedPickers,
  drawerFilter = false,
  ...props
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [filterOptions, setFilterOptions] = useState([]);

  useEffect(() => {
    setFilterOptions([...allOptions]);
  }, [allOptions]);

  const handleBoxClick = (event) => {
    if (type === 'moreFilter') {
      handleFilterChange({
        name: type || name,
        isSingle,
      });
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckChange = (option) => (event) => {
    event.stopPropagation();
    if (selectedPickers.find((s) => s[optValue] === option[optValue])) {
      const deleteIndex = selectedPickers.findIndex(
        (s) => s[optValue] === option[optValue],
      );
      selectedPickers.splice(deleteIndex, 1);
    } else {
      selectedPickers.push(option);
    }

    handleFilterChange({
      name: type || name,
      filter: selectedPickers,
    });
  };

  const handleSingleChange = (option) => (event) => {
    event.stopPropagation();
    setAnchorEl(null);

    handleFilterChange({
      name: type || name,
      isSingle: type === 'assigned_to' ? false : isSingle,
      filter: [option],
      control: option.control,
    });
  };

  const onType = (event) => {
    setSearchValue(event.target.value);
    if (event.target.value) {
      const newFilteredOptions = allOptions.filter((option) =>
        option[optLabel]
          .toLocaleLowerCase()
          .includes(event.target.value.toLocaleLowerCase()),
      );
      setFilterOptions(newFilteredOptions);
    } else {
      setFilterOptions([...allOptions]);
    }
  };

  const handleClearFilter = () => {
    handleFilterChange({
      name: type || name,
      filter: [],
    });
  };

  const handleDatePicker = (data) => {
    handleFilterChange({
      ...data,
      type: type,
    });
  };

  const getFilterTitle = () => {
    return props?.filter?.showName
      ? isArray(selectedPickers)
        ? selectedPickers[0]?.name || title
        : selectedPickers?.name || title
      : title;
  };

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        className={`${className} ${classes.titleSelect} ${
          selectedPickers?.length ? classes.active : ''
        }`}
      >
        {type !== 'dateRangePicker' ? (
          <Box
            display="flex"
            flexDirection="row"
            alignItems={'center'}
            // maxWidth={128}
            {...(type !== 'moreFilter' && { maxWidth: 128 })}
          >
            <Box
              display="flex"
              alignItems="center"
              onClick={handleBoxClick}
              // maxWidth={128 - 40}
              {...(type !== 'moreFilter' && { maxWidth: 128 - 40 })}
            >
              {type === 'moreFilter' && (
                <FilterListIcon style={{ marginRight: 4 }} fontSize="small" />
              )}
              {type !== 'moreFilter' ? (
                <Tooltip title={getFilterTitle()} arrow>
                  <Typography variant="body2" noWrap>
                    {getFilterTitle()}
                    {!isSingle &&
                      Boolean(selectedPickers?.length) &&
                      ` (${(selectedPickers || []).length}) `}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant="body2" noWrap>
                  {getFilterTitle()}
                  {!isSingle &&
                    Boolean(selectedPickers?.length) &&
                    ` (${(selectedPickers || []).length}) `}
                </Typography>
              )}
            </Box>
            {type !== 'moreFilter' && (
              <svg
                className="MuiSvgIcon-root MuiSelect-icon"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M7 10l5 5 5-5z" />
              </svg>
            )}
            {type !== 'moreFilter' && Boolean(selectedPickers?.length) && (
              <CloseIcon onClick={handleClearFilter} fontSize="small" />
            )}
          </Box>
        ) : (
          <FilterDatePickers
            onChange={handleDatePicker}
            selectedDates={{
              ...props.selectedFilter?.data,
            }}
          />
        )}
      </Box>

      <Menu
        id={'test-menu-items'}
        anchorEl={anchorEl}
        TransitionComponent={Fade}
        classes={{
          paper: classes.menuPaper,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: drawerFilter ? 'left' : 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: drawerFilter ? 'left' : 'center',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <TextField
            placeholder="Search..."
            variant="outlined"
            size="small"
            onChange={(event) => {
              onType(event);
            }}
            value={searchValue || ''}
            className={classes.filterMenuSearch}
          />
        </MenuItem>
        {filterOptions.length ? (
          <Box className={classes.menuItemWrapper}>
            {filterOptions.map((option, index) => (
              <MenuItem
                key={`option-menu-check-${index}`}
                value={option[optValue]}
                selected={Boolean(
                  selectedPickers.find((s) => s[optValue] === option[optValue]),
                )}
              >
                {isSingle ? (
                  <Box
                    onClick={handleSingleChange(option)}
                    style={{ width: '100%' }}
                  >
                    {option[optLabel]}
                  </Box>
                ) : (
                  <Checkbox
                    handleChange={handleCheckChange(option)}
                    name="filter-picker"
                    label={option[optLabel]}
                    checked={Boolean(
                      selectedPickers.find(
                        (s) => s[optValue] === option[optValue],
                      ),
                    )}
                    classes={{
                      root: classes.checkBoxLabel,
                    }}
                  />
                )}
              </MenuItem>
            ))}
          </Box>
        ) : (
          <MenuItem disabled>
            <Typography>No options.</Typography>
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};

export default FilterPickers;
