import React from 'react';
import { Typography, Card, CardContent, Box, Grid } from '@mui/material';
import { Spacer } from 'src/components/shared';
import { Skeleton } from '@mui/lab';

const ReportCard = ({ title, value, loading }) => {
  return (
    <Grid item xs={4} md={4} lg={3}>
      <Card>
        <CardContent>
          <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              {typeof title === 'string' ? (
                <Typography variant="body2" color="textSecondary">
                  {title}
                </Typography>
              ) : (
                title
              )}
            </Box>
            <Spacer x={1} y={2} />
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '80px' }}>
              {loading ? (
                <Skeleton animation="wave" height={36} width={40} />
              ) : (
                <Typography sx={{ fontSize: '24px' }}>{value}</Typography>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ReportCard;
