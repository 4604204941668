import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({ palette }) => ({
  filterMenuSearch: {
    width: '100%',
  },
  menuPaper: {
    maxHeight: 300,
    marginTop: 8,
    minWidth: 300,
    overflowY: 'unset',
    paddingTop: 0,
    paddingBottom: 0,
  },
  menuPaperList: {},
  menuItemWrapper: {
    overflow: 'auto',
    maxHeight: 230,
  },
  checkBoxLabel: {
    marginBottom: 0,
  },
  titleSelect: {
    padding: 6,
    marginRight: 12,
    cursor: 'pointer',
    borderRadius: 4,
    '& svg': {
      cursor: 'pointer',
    },
    height: 25,
  },
  active: {
    backgroundColor: '#082037',
    color: '#ffffff',
    padding: '6px 12px',
  },
}));

export default useStyles;
