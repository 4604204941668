import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PageTitle, Tabs } from 'src/components/App';
import TasksView from './TasksView';
import { tasksTabs } from '../../config';

import {
  fetchTaskErrors,
  fetchTasks,
  setTaskErrorDetails,
  setTaskQueue,
  fetchEnrolmentDrafts,
  taskBulkUpdate,
} from '../../actions/taskActions';
import { toggleContactDrawer, toggleAddTaskForm } from 'src/modules/app/actions/appActions';
import { enqueueSequencePersonalize } from 'src/modules/sequence/actions/enrolmentActions';

export const TasksContainer = (props) => {
  const [activeTab, setActiveTab] = useState('all');

  useEffect(() => {
    if (props?.match?.params?.type && props?.match?.params?.type !== '') {
      setActiveTab(props?.match?.params?.type);
    }
  }, [props?.match?.params]);

  const onTabChange = (data) => {
    props.history.push(`/tasks/${data.id}`);
  };

  return (
    <>
      <Tabs tabs={tasksTabs} onChange={onTabChange} value={activeTab} />
      <PageTitle title={`Tasks`} />
      <TasksView {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.tasks.loading,
  tasks: state.tasks.tasks,
  taskErrors: state.tasks.taskErrors,
  enrolmentDrafts: state.tasks.enrolmentDrafts,
});

const mapDispatchToProps = (dispatch) => ({
  setTaskQueue: (ids) => dispatch(setTaskQueue(ids)),
  fetchTasks: (paging, filters, sort, tab) => dispatch(fetchTasks(paging, filters, sort, tab)),
  fetchEnrolmentDrafts: (paging, filters, sort) =>
    dispatch(fetchEnrolmentDrafts(paging, filters, sort)),
  toggleContactDrawer: (contactId) => dispatch(toggleContactDrawer(contactId)),
  toggleAddTaskForm: (task = {}) => dispatch(toggleAddTaskForm(task)),
  fetchTaskErrors: (paging, filters) => dispatch(fetchTaskErrors(paging, filters)),
  setTaskErrorDetails: (data) => dispatch(setTaskErrorDetails(data)),
  enqueueSequencePersonalize: (enrolments) =>
    dispatch(enqueueSequencePersonalize(enrolments, 'edit', true)),
  taskBulkUpdate: (tasks, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(taskBulkUpdate(tasks, payload, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TasksContainer);
