import { Card, Stack, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FilterBar, PageTitle, Tabs } from 'src/components/App';
import { Form, Spacer } from 'src/components/shared';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const filtersArr = [
  {
    key: 'createdAt',
    title: 'Filter By Date',
    custom: true,
    type: 'relativeDateRange',
    options: [
      { id: 'today', name: 'Today' },
      { id: 'yesterday', name: 'Yesterday' },
      { id: 'thisWeek', name: 'This week' },
      { id: 'lastWeek', name: 'Last week' },
      { id: 'thisMonth', name: 'This month' },
      { id: 'lastMonth', name: 'Last month' },
      { id: 'last3Month', name: 'Last 3 months' },
    ],
    isPrimary: true,
  },
];

export const RoleplayMinutesView = (props) => {
  const [filters, setFilters] = useState({ createdAt: filtersArr[0].options[5] });

  const handleFilterChange = (key, value) => {
    if (value && typeof value !== 'undefined') {
      setFilters({
        [key]: value,
      });
    } else {
      const newFilter = JSON.parse(JSON.stringify(filters));
      delete newFilter[key];
      setFilters(newFilter);
    }
  };
  return (
    <Stack>
      <PageTitle title={'Role-play Minutes'} />
      <Tabs
        tabs={[
          {
            id: 'summary',
            name: 'Role-play Summary',
          },
        ]}
        value={'summary'}
      />
      <Spacer y={2} />
      <Stack height={'100%'}>
        <FilterBar filters={filtersArr} appliedFilters={filters} onChange={handleFilterChange} />
        <Spacer y={1} />
        <Stack
          width={'100%'}
          gap={'20px'}
          gridTemplateColumns={'repeat(auto-fill, minmax(160px, 1fr))'}
          display={'grid'}
        >
          <Card
            sx={{
              aspectRatio: '1/1',
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Stack gap={'4px'} alignItems={'center'}>
              <Typography color="#00000099" fontSize={'14px'} fontWeight={500}>
                Minutes Remaining
              </Typography>
              <Stack height={'16px'} />
            </Stack>
            <Stack flex={1} alignItems={'center'} justifyContent={'center'}>
              <Typography color="#000000DE" fontSize={'36px'} fontWeight={600}>
                200
              </Typography>
            </Stack>
          </Card>
          <Card
            sx={{
              aspectRatio: '1/1',
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Stack gap={'4px'} alignItems={'center'}>
              <Typography color="#00000099" fontSize={'14px'} fontWeight={500}>
                Minutes Remaining
              </Typography>
              <Typography color="#00000061" fontSize={'13px'} fontWeight={500} lineHeight={'16px'}>
                Last 30 days
              </Typography>
            </Stack>
            <Stack flex={1} alignItems={'center'} justifyContent={'center'}>
              <Typography color="#000000DE" fontSize={'36px'} fontWeight={600}>
                100
              </Typography>
            </Stack>
          </Card>
          <Card
            sx={{
              aspectRatio: '1/1',
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Stack gap={'4px'} alignItems={'center'}>
              <Typography color="#00000099" fontSize={'14px'} fontWeight={500}>
                Last Purchase
              </Typography>
              <Typography color="#00000061" fontSize={'13px'} fontWeight={500} lineHeight={'16px'}>
                14/01/23
              </Typography>
            </Stack>
            <Stack flex={1} alignItems={'center'} justifyContent={'center'}>
              <Typography color="#000000DE" fontSize={'36px'} fontWeight={600}>
                $5000
              </Typography>
            </Stack>
          </Card>
          <Card
            sx={{
              aspectRatio: '1/1',
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Stack gap={'4px'} alignItems={'center'}>
              <Typography color="#00000099" fontSize={'14px'} fontWeight={500}>
                Role-plays Completed
              </Typography>
              <Stack height={'16px'} />
            </Stack>
            <Stack flex={1} alignItems={'center'} justifyContent={'center'}>
              <Typography color="#000000DE" fontSize={'36px'} fontWeight={600}>
                250
              </Typography>
            </Stack>
          </Card>
        </Stack>
      </Stack>
      <Spacer y={5} />
      <Tabs
        tabs={[
          {
            id: 'management',
            name: 'Role-play Minute Management',
          },
        ]}
        value={'management'}
      />
      <Spacer y={2} />
      <Stack>
        <Typography color="#00000099">Control your spend each month</Typography>
        <Spacer y={2} />
        <Stack direction="row" gap={'24px'}>
          <Card
            sx={{
              width: 'calc(50% - 16px)',
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Stack gap={'8px'}>
              <Typography color="#000000DE" fontWeight={500}>
                Account credit limit
              </Typography>
              <Typography color="#000000DE">
                Set the maximum you want to spend each month on role-play minutes.
              </Typography>
              <Form
                initialValues={{
                  userLimit: '',
                }}
                enableReinitialize={true}
                onSubmit={() => {}}
              >
                <Form.Field.Select
                  sx={{ maxWidth: '250px' }}
                  variant="outlined"
                  name="creditLimit"
                  placeholder={'$500'}
                  value={null}
                  options={[]}
                  optLabel="label"
                  optValue="value"
                />
              </Form>
            </Stack>
          </Card>
          <Card
            sx={{
              width: 'calc(50% - 16px)',
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Stack gap={'8px'}>
              <Stack direction="row" justifyContent={'space-between'}>
                <Typography color="#000000DE" fontWeight={500}>
                  User minute limit
                </Typography>
                <Typography color="#1976D2" fontWeight={500}>
                  23 users
                </Typography>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} gap={'6px'}>
                <Typography color="#000000DE">Minute limit for all users</Typography>
                <Tooltip
                  arrow
                  describeChild
                  tooltip
                  title={
                    <Stack className="tooltipWrapper">
                      <Typography
                        style={{
                          whiteSpace: 'pre-line',
                          fontSize: 14,
                          color: 'white',
                        }}
                      >
                        {`We’ve gone ahead and created a default allocation for your users (your total minute allowance divided evenly across all of your active users).

                        Remember, you will never be charged more than your Account limit.`}
                      </Typography>
                    </Stack>
                  }
                >
                  <InfoOutlinedIcon style={{ fill: 'rgba(0, 0, 0, 0.26)', width: 22 }} />
                </Tooltip>
              </Stack>
              <Form
                initialValues={{
                  userLimit: '',
                }}
                enableReinitialize={true}
                onSubmit={() => {}}
              >
                <Form.Field.Select
                  sx={{ maxWidth: '250px' }}
                  variant="outlined"
                  name="userLimit"
                  placeholder={'$500'}
                  value={null}
                  options={[]}
                  optLabel="label"
                  optValue="value"
                />
              </Form>
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default RoleplayMinutesView;
