import React from 'react';
import styled from 'styled-components';
import { Skeleton } from '@mui/lab';
import { Card, CardContent, Box, Divider } from '@mui/material';
import { Avatar, Spacer, Button } from 'src/components/shared';
import SnoozeIcon from '@mui/icons-material/Snooze';
import DoneIcon from '@mui/icons-material/Done';

function TaskHeadSkeleton() {
  const loading = false;
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <Skeleton animation="wave" height={55} width={36} sx={{ bgcolor: 'grey.800' }} />
        <Spacer x={1} />
        <Box>
          <Skeleton animation="wave" height={24} width={100} sx={{ bgcolor: 'grey.800' }} />
          <Skeleton animation="wave" height={18} width={60} sx={{ bgcolor: 'grey.800' }} />
        </Box>
      </Box>

      <Box display="flex" alignItems="center">
        <Button
          sx={{ color: '#dddddd', border: '1px solid #dddddd' }}
          variant="outlined"
          loading={loading}
        >
          <Skeleton animation="wave" height={20} width={66} sx={{ bgcolor: 'grey.800' }} />
        </Button>
        <Spacer x={1} />
        <Button
          sx={{ color: '#dddddd', border: '1px solid #dddddd' }}
          variant="outlined"
          loading={loading}
        >
          <Skeleton animation="wave" height={20} width={66} sx={{ bgcolor: 'grey.800' }} />
        </Button>
        <Spacer x={1} />
        <Button
          sx={{ color: '#dddddd', border: '1px solid #dddddd' }}
          variant="outlined"
          loading={loading}
        >
          <Skeleton animation="wave" height={20} width={66} sx={{ bgcolor: 'grey.800' }} />
        </Button>
      </Box>
    </Box>
  );
}

export default TaskHeadSkeleton;
