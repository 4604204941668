import * as types from '../actions/appTypes';
import { RESET_STORE } from 'src/modules/app/actions/appTypes';
import { uniqBy } from 'lodash';

const initialState = {
  loading: {
    boards: false,
    widgets: false,
    templates: false,
  },
  boards: {},
  pinnedBoards: [],
  activeBoard: '',
  boardWidgets: {},
  boardFilters: {},
  templates: {
    paging: {
      pageNo: 0,
      perPage: 6,
      count: 0,
    },
    filters: {},
    data: [],
  },
  widgets: {
    paging: {
      pageNo: 0,
      perPage: 9,
      count: 0,
    },
    filters: {},
    data: [],
  },
};

const BoardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_BOARD_FILTERS:
      return {
        ...state,
        boardFilters: {
          ...state.boardFilters,
          [action.boardId]: {},
        },
      };

    case types.SET_BOARD_FILTERS:
      return {
        ...state,
        boardFilters: {
          ...state.boardFilters,
          [action.boardId]: {
            ...state.boardFilters[action.boardId],
            ...action.filters,
          },
        },
      };

    case types.FETCH_WIDGET_DATA:
      return {
        ...state,
        boardWidgets: {
          ...state.boardWidgets,
          [action.widgetId]: {
            ...state.boardWidgets[action.widgetId],
            loading: true,
          },
        },
      };

    case types.SET_WIDGET_DATA:
      return {
        ...state,
        boardWidgets: {
          ...state.boardWidgets,
          [action.widgetId]: {
            ...state.boardWidgets[action.widgetId],
            data:
              state.boardWidgets[action.widgetId].type === 'singleCard'
                ? action?.data || {}
                : action.data?.data || [],
            loading: false,
          },
        },
      };

    case types.SET_PINNED_BOARDS:
      return {
        ...state,
        pinnedBoards: action.boards,
      };

    case types.SET_BOARD_WIDGETS:
      return {
        ...state,
        boardWidgets: action.widgets,
      };

    case types.SET_ACTIVE_BOARD:
      return {
        ...state,
        activeBoard: action.boardId,
      };

    case types.FETCH_BOARD_TEMPLATES:
      return {
        ...state,
        loading: {
          ...state.loading,
          templates: true,
        },
        templates: {
          ...state.templates,
          data: [],
          paging: {
            ...state.templates.paging,
            ...action.paging,
          },
          filters: {
            ...state.templates.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };

    case types.SET_BOARD_TEMPLATES:
      return {
        ...state,
        loading: {
          ...state.loading,
          templates: false,
        },
        templates: {
          ...state.templates,
          data: action.data.boardTemplates,
          paging: {
            ...state.templates.paging,
            count: action.data.total.value,
          },
          filters: {
            ...state.templates.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };

    case types.FETCH_WIDGETS:
      return {
        ...state,
        loading: {
          ...state.loading,
          widgets: true,
        },
        widgets: {
          ...state.widgets,
          data: [],
          paging: {
            ...state.widgets.paging,
            ...action.paging,
          },
          filters: {
            ...state.widgets.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };

    case types.SET_WIDGETS:
      return {
        ...state,
        loading: {
          ...state.loading,
          widgets: false,
        },
        widgets: {
          ...state.widgets,
          data: action.data.widgets,
          paging: {
            ...state.widgets.paging,
            count: action.data.total.value,
          },
          filters: {
            ...state.widgets.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };

    case types.FETCH_BOARDS:
      return {
        ...state,
        loading: {
          ...state.loading,
          boards: true,
        },
        boards: {},
        boardWidgets: {},
      };

    case types.POST_BOARD:
      return {
        ...state,
        boardWidgets: {},
      };

    case types.SET_BOARDS:
      return {
        ...state,
        loading: {
          ...state.loading,
          boards: false,
        },
        boards: action.boards,
      };

    default:
      return state;
  }
};

export default BoardReducer;
