import React, { useState } from 'react';
import { ButtonBase, Grid, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import SessionBanner from './components/SessionBanner';
import SessionActionBar from './components/SessionActionBar';
import SessionVideo from './components/SessionVideo';
import SessionExperts from './components/SessionExperts';

import NextSessions from './components/NextSessions';
import CompetitionReport from './components/CompetitionReport';
import RoleplayProgress from './components/RoleplayProgress';
import SessionDetails from './components/SessionDetails';
import SessionRoleplay from './components/SessionRoleplay';

import AiCTA from './components/AiCTA';

import SessionDescription from './components/SessionDescription';
import { Spacer } from 'src/components/shared';

import { withSessionStore, useSessionProvider } from 'src/modules/lms/utils/sessionStore';

import SessionSkeleton from './components/SessionSkeleton';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { themeColors } from '../../config';

function LMSSession({ session, ...props }) {
  const {
    view,
    setView,
    reports,
    competition,
    rolePlayUrl,
    toggleRolePlay,
    showRolePlay,
    setShowRolePlay,
  } = useSessionProvider();

  const handleViewChange = (view) => {
    setView(view);
  };

  if (props.loading) {
    return <SessionSkeleton />;
  }

  return (
    <>
      <Link to="/lms">
        <Stack direction="row" gap={1} alignItems="center" color={themeColors.greyText}>
          <ArrowBack /> <Typography variant="body2">Back to Homepage</Typography>
        </Stack>
      </Link>
      <Spacer x={2} y={2} />
      <SessionBanner session={session} />
      <Spacer x={2} y={2} />
      <Grid container spacing={2}>
        <Grid item lg={8} xs={12}>
          <SessionActionBar
            session={session}
            handleViewChange={handleViewChange}
            fetchScore={props.fetchScore}
          />
          {view === 'desc' && (
            <>
              <Spacer x={3} y={3} />
              <SessionVideo session={session} user={props.user} />
            </>
          )}

          <Spacer x={3} y={3} />
          <SessionDescription session={session} view={view} />
          <Spacer x={3} y={3} />
          <SessionExperts experts={session?.expert || []} />
        </Grid>
        <Grid item lg={4} xs={12}>
          <CompetitionReport session={session} competition={competition} />

          <AiCTA session={session} score={props?.score} />
          {props?.score?.roleplayScore?.length === 0 ? null : <Spacer x={3} y={3} />}
          <RoleplayProgress session={session} score={props.score} />
          <Spacer x={3} y={3} />
          <SessionDetails session={session} />
          <Spacer x={3} y={3} />
          <NextSessions session={session} />
        </Grid>
      </Grid>

      {showRolePlay && showRolePlay == true && (
        <SessionRoleplay
          report={reports}
          rolePlayModal={showRolePlay}
          setRolePlayModal={setShowRolePlay}
          session={session}
          competition={competition}
          embedURL={rolePlayUrl}
          fetchScore={props.fetchScore}
        />
      )}
    </>
  );
}

export default withSessionStore(LMSSession);
