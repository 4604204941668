import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { PageTitle } from 'src/components/App';
import ResetPasswordView from './ResetPasswordView';
import { resetPassword } from '../../actions/authActions';

export const ResetPasswordContainer = (props) => {
  return (
    <>
      <PageTitle title="Reset password" />
      <ResetPasswordView {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({
  location: state.router.location,
});

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (payload) => {
    return new Promise((resolve, reject) => {
      dispatch(resetPassword(payload, resolve, reject));
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPasswordContainer);
