import React from 'react';
import PropTypes from 'prop-types';

import {
  Switch,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Box,
  FormHelperText,
} from '@mui/material';
import { withStyles } from '@mui/styles';

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 36,
    height: 18,
    padding: 0,
    display: 'flex',
    marginRight: '12px',
  },
  switchBase: {
    padding: 3,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        // backgroundColor: theme.palette.secondary.main,
        // borderColor: theme.palette.secondary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 36 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
    boxSizing: 'border-box',
  },
  checked: {},
}))(Switch);

function SwitchComponent({
  label,
  labelPlacement,
  color,
  onChange,
  error,
  value,
  helperText,
  sx,
  ...props
}) {
  const handleChange = (event) => {
    onChange(!value);
  };

  return (
    <Box mt={2} sx={sx}>
      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            value={value}
            control={<AntSwitch color={color} checked={value} onChange={handleChange} />}
            label={label}
            labelPlacement={labelPlacement}
            {...props}
          />
          {helperText && helperText !== '' ? <FormHelperText>{helperText}</FormHelperText> : null}
        </FormGroup>
      </FormControl>
    </Box>
  );
}

SwitchComponent.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'success', 'error']),
  size: PropTypes.oneOf(['medium', 'small']),
  disabled: PropTypes.bool,
  sx: PropTypes.object,
};

SwitchComponent.defaultProps = {
  className: undefined,
  label: undefined,
  labelPlacement: 'end',
  onChange: () => {},
  variant: 'standard',
  color: 'secondary',
  size: 'medium',
  disabled: false,
  sx: {}
};
export default SwitchComponent;
