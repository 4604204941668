import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, Box } from '@mui/material';
import Stack from '@mui/material/Stack';

const Paging = ({ size, perPage, page, count, onChange }) => {
  const pages = Math.ceil(count / perPage);

  page = page + 1;

  const handleChange = (data, newPage) => {
    if (page !== newPage) {
      onChange(newPage - 1);
    }
  };

  return (
    <Box mt={3} display="flex" alignItems="center" justifyContent="center" width="100%">
      <Stack spacing={2}>
        <Pagination count={pages} defaultPage={page} size={size} onChange={handleChange} />
      </Stack>
    </Box>
  );
};

Paging.propTypes = {
  size: PropTypes.string,
  page: PropTypes.number,
  perPage: PropTypes.number,
  count: PropTypes.number,
};

Paging.defaultProps = {
  size: 'small',
  page: 0,
  perPage: 10,
  count: 0,
};

export default Paging;
