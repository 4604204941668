import * as React from 'react';
import { Link } from 'react-router-dom';
import { Chip, Grid, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Modal, Button } from 'src/components/shared';

import { MultiCarousel } from '../multiCarousel/MultiCarousel';
import CardMedia from '../cardMedia';
import MissingImage from '../MissingImage';
import { AuthBgSetup, NoImage } from './style';
import { truncate } from '../../utils/helper';

export default function CoursePreview({ open = false, handleClose = () => {}, data }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      size="lg"
      sx={{
        '& .MuiPaper-root': {
          background: 'linear-gradient(105deg, #000 -5.01%, #132B4E 134.12%)',
          padding: '12px',
          position: 'relative',
        },
        '& .MuiSvgIcon-root': {
          color: 'rgba(255, 255, 255, 0.54)',
        },
      }}
    >
      <>
        <Grid container spacing={4} sx={{ zIndex: 1, position: 'relative' }}>
          <Grid item xs={12} lg={7} mt={1}>
            {data?.sessions?.filter((session) => session.published).length > 1 ? (
              <MultiCarousel
                images={data?.sessions
                  ?.filter((session) => session.published)
                  ?.map((k) => ({
                    id: k.id,
                    imageUrl: k?.thumbnailImage,
                    thumbNailUrl: k?.thumbnailImage,
                  }))}
                slidesToShow={1}
              />
            ) : data?.sessions?.filter((session) => session.published).length === 1 ? (
              <CardMedia
                dontShowAction
                src={data.sessions[0]?.thumbnailImage}
                alt={data.sessions[0]?.title}
                id={data.sessions[0]?.id}
                isClickable={false}
                type={'sessionPreview'}
              />
            ) : (
              <NoImage>
                <MissingImage />
              </NoImage>
            )}
          </Grid>
          <Grid item xs={12} lg={5}>
            <Stack direction="column" justifyContent="space-between" height="100%" gap={2}>
              <Stack direction="column" gap={2}>
                <Stack direction="column" gap={1} alignItems="flex-start">
                  <Typography variant="body2" color="secondary" fontWeight={300}>
                    {data?.contentType === 'pillar' ? 'Pillar' : 'Course'}
                  </Typography>

                  <Typography
                    variant="body1"
                    color="textPrimary"
                    sx={{ fontSize: '26px', fontWeight: '300' }}
                  >
                    {data?.title}
                  </Typography>

                  <Chip
                    label={`${
                      data?.sessions
                        ? data?.sessions.filter((session) => session.published).length
                        : 0
                    } Sessions`}
                    color="secondary"
                    rounded={false}
                  />
                </Stack>

                {data?.tags?.length > 0 ? (
                  <Stack gap={1} direction="row" mt={1} flexWrap="wrap">
                    {data?.tags?.map((item, i) => (
                      <Chip
                        variant="outlined"
                        key={`session-tag-${item?.id || ''}-${i}`}
                        label={`${item?.name}`}
                        sx={{ color: '#7F7F7F', borderRadius: '4px', borderColor: '#7F7F7F' }}
                      />
                    ))}
                  </Stack>
                ) : null}

                <Typography variant="body2" color="textSecondary">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: truncate(data?.synopsis, `/lms/${data?.contentType}/${data?.id}`),
                    }}
                  />
                  {/* In this Supersession, Naomi Dawkins shares the secrets to structuring your intro,
                  so you can really engage your prospects and seamlessly navigate to the discovery
                  part of your conversation. */}
                </Typography>
              </Stack>
              <Box display="flex" justifyContent="flex-end">
                <Link to={`/lms/${data?.contentType}/${data?.id}`}>
                  <Button
                    variant="contained"
                    size="large"
                    sx={{ backgroundColor: '#125151', color: '#ffffff' }}
                  >
                    Explore this {data?.contentType === 'pillar' ? 'Pillar' : 'Course'}
                  </Button>
                </Link>
              </Box>
            </Stack>
          </Grid>
        </Grid>

        <AuthBgSetup />
      </>
    </Modal>
  );
}
