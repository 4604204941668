import React, { useState, useEffect } from 'react';
import { Container } from '@mui/material';
import { Spacer, Drawer } from 'src/components/shared';
import { customFieldsTabs } from '../../config';
import CustomFields from '../../components/CustomFields';
import CustomFieldForm from '../../components/CustomFields/CustomFieldForm';
import { Intro } from '../../components/Lookups';
import { useHistory, useLocation } from 'react-router';
import CustomFieldsOrder from '../../components/CustomFields/customFieldsOrder/CustomFieldsOrder';

const CustomFieldsView = ({ ...props }) => {
  const location = useLocation();
  const history = useHistory();
  const [formOpen, setFormOpen] = useState(false);
  const [orderOpen, setOrderOpen] = useState(false);
  const [orderType, setOrderType] = useState('form');
  const [attributeData, setAttributeData] = useState({});
  const [currentTab, setCurrentTab] = useState(customFieldsTabs[0].id);
  const query = new URLSearchParams(location?.search);
  const taskQuery = query.get('task') || '';
  useEffect(() => {
    if (taskQuery === 'addField') {
      openFormDrawer();
      history.replace({
        search: null,
      });
    }
  }, [taskQuery]);

  const onTabChange = (tab) => {
    setCurrentTab(tab.id);
  };

  const onDrawerClose = () => {
    setFormOpen(false);
    setAttributeData({});
  };

  const openFormDrawer = () => {
    setFormOpen(true);
  };

  const onOrderDrawerClose = () => {
    setOrderOpen(false);
  };

  const onOrderDrawerOpen = (type) => {
    setOrderType(type);
    setOrderOpen(true);
  };

  const onEditAttribute = (data) => {
    setFormOpen(true);
    setAttributeData(data);
  };

  return (
    <>
      <Intro
        title="Manage Your Fields"
        description="Choose how fields appear on SuperReach. This includes custom fields to help you tailor data to your business needs."
      />

      <Container maxWidth="xl">
        <CustomFields
          {...props}
          module={currentTab}
          openFormDrawer={openFormDrawer}
          onOrderDrawerOpen={onOrderDrawerOpen}
          onEdit={onEditAttribute}
        />
      </Container>
      <Spacer y={3} />
      <Drawer
        open={formOpen}
        title={attributeData?.id ? 'Edit attribute' : 'Create custom attribute'}
        onClose={onDrawerClose}
      >
        <CustomFieldForm
          {...props}
          attribute={attributeData}
          onClose={onDrawerClose}
          module={currentTab}
        />
      </Drawer>
      <Drawer
        open={orderOpen}
        title={`Change Field Order - ${orderType === 'form' ? 'Add to SR form' : ''}`}
        onClose={onOrderDrawerClose}
      >
        <CustomFieldsOrder
          {...props}
          type={orderType}
          module={currentTab}
          openFormDrawer={openFormDrawer}
          onOrderDrawerOpen={onOrderDrawerOpen}
          onEdit={onEditAttribute}
        ></CustomFieldsOrder>
      </Drawer>
    </>
  );
};

export default CustomFieldsView;
