import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Stack, InputAdornment } from '@mui/material';
import { getPriorityIcon, getTaskTypeText, getTaskTypeIcon } from '../../utils/tasksUtils';
import { saveTemplate, updateTemplate } from 'src/modules/admin/api/adminApi';
import { updateTask } from 'src/modules/tasks/api/taskApis';
import { Form, Button, Spacer } from 'src/components/shared';
import { postContactNote } from 'src/modules/contacts/actions/contactActions';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteIcon from '@mui/icons-material/Delete';
import toast from 'src/utils/toast';
import { fetchFile, fetchTemplatesLookup } from 'src/modules/app/api/appApis';

import LayersIcon from '@mui/icons-material/Layers';

function Email({
  task,
  setShowDueDateForm,
  noteTypes,
  showActions = true,
  onSuccess = () => {},
  ...props
}) {
  // const template = task?.template || {};

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [template, setTemplate] = useState();
  const [attachments, setAttachments] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const template1 = task?.template || {};
    setTemplate(template1);
    setSubject(template1?.subject || '');
    setContent(template1?.content || '');
    if (template1?.attachments && template1?.attachments.length) {
      fetchAttachments(template1?.attachments);
    }
  }, [task]);

  const user = useSelector((state) => state.auth.user);
  const { contact } = task;

  const handleSubmit = async (values, form, saveAndSend) => {
    try {
      setLoading(true);
      if (
        template &&
        template?.id &&
        template?.id !== '' &&
        task.taskType !== 'selectEmailTemplate'
      ) {
        await updateTemplate(template.id, {
          subject: values.subject,
          content: values.content,
        });
      }

      let additionalData = {};
      if (task.taskType === 'selectEmailTemplate' && values?.template?.id) {
        const newTemplate = await saveTemplate({
          type: 'clonedEmail',
          name: values.template.name,
          subject: values.subject,
          content: values.content,
          category: values.template.category,
          isPublished: true,
          attachments: values.attachments.length ? values.attachments.map((file) => file?.id) : [],
          sharedWithTeams: values.template?.sharedWithTeams || [],
          sharedWithUsers: values.template?.sharedWithUsers || [],
          permissionType: values.template.permissionType,
          sourceTemplate: values.template.id,
        });
        additionalData = {
          template: newTemplate.template.id,
        };
      } else {
        const newTemplate = await saveTemplate({
          type: 'clonedEmail',
          name: values.subject,
          subject: values.subject,
          content: values.content,
          isPublished: true,
          permissionType: 'organisation',
        });
        additionalData = {
          template: newTemplate.template.id,
        };
      }

      const payload = {
        customNoteEnabled: values?.addNote,
        customNoteText: values.noteContent,
        customNoteType: values.noteType,
        ...(saveAndSend ? { allowForceSend: true, scheduledOn: new Date() } : {}),
        ...additionalData,
      };
      const updated = await updateTask(task.id, payload);
      onSuccess(updated?.task || {});
      toast.success(`Task updated ${saveAndSend ? 'and email sended' : ''} successfully`);
      setLoading(false);
    } catch (error) {
      toast.error('Error updating task');
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    props.toggleDeleteModel();
  };

  const fetchAttachments = async (attachments) => {
    const promises = [];
    for (let i = 0; i < attachments?.length; i++) {
      const attachmentId = attachments[i];
      promises.push(fetchFile(attachmentId));
    }

    try {
      let response = await Promise.all(promises);
      if (response && response.length) {
        const attaches = response.map((file) => ({
          ...file.upload,
        }));
        setAttachments(attaches);
      }
    } catch (error) {
      setAttachments([]);
    }
  };

  return (
    <Form
      initialValues={{
        template: template,
        subject: subject,
        content: content,
        noteType: task?.customNoteType || '',
        addNote: task?.customNoteEnabled || false,
        noteContent: task?.customNoteText || '',
        attachments: attachments,
      }}
      validationSchema={Yup.object().shape({
        subject: Yup.string().required('Subject is required!'),
        content: Yup.string().required('Content is required!'),
      })}
      onSubmit={async (values, form) => {
        handleSubmit(values, form);
        form.prevent;
      }}
      enableReinitialize={true}
    >
      {({ values, ...formProps }) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formProps.submitForm();
              return false;
            }}
            noValidate
          >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" gap={1} alignItems="center">
                {getTaskTypeIcon(task.taskType)}
                <Typography component="span" variant="h3" color="textPrimary">
                  {getTaskTypeText(task.taskType)}
                </Typography>
              </Stack>
              <Stack direction="row">
                {showActions && (
                  <>
                    <Button iconButton onClick={setShowDueDateForm}>
                      <AccessTimeIcon />
                    </Button>
                    <Button iconButton loading={deleting} onClick={handleDelete}>
                      <DeleteIcon />
                    </Button>
                    <Spacer x={1} />
                  </>
                )}

                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  size="small"
                  loading={loading}
                >
                  Save
                </Button>
                {showActions && (
                  <>
                    <Spacer x={2} />
                    <Button
                      onClick={() => handleSubmit(values, null, true)}
                      variant="contained"
                      color="secondary"
                      size="small"
                      loading={loading}
                    >
                      Save and Send
                    </Button>
                  </>
                )}
              </Stack>
            </Stack>

            {task.taskType === 'selectEmailTemplate' && (
              <Form.Field.AutoComplete
                multiple={false}
                fullWidth
                options={[]}
                variant="outlined"
                remoteMethod={(search) => {
                  return fetchTemplatesLookup(search, 'email');
                }}
                name="template"
                label="Apply template"
                optLabel="name"
                optValue="id"
                onChange={(value) => {
                  if (value && value.id) {
                    setTemplate(value);
                    setContent(value.content);
                    setSubject(value.subject);
                    if (value.attachments && value.attachments.length) {
                      fetchAttachments(value.attachments);
                    }
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LayersIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}

            <Form.Field.Input
              fullWidth
              variant="outlined"
              name="subject"
              placeholder="Subject"
              label="Subject"
              disabled={loading}
              autoComplete="on"
              emojiPicker={true}
            />

            <Form.Field.TextEditor
              fullWidth
              variant="outlined"
              name="content"
              showPlaceholder={true}
            />

            <Form.Field.File name="attachments" maxSize={8} />

            <Box ml={2} mt={3} mb={1}>
              <Form.Field.Switch name="addNote" label="Add Note" />
            </Box>
            {values.addNote === true && (
              <Box maxWidth={400}>
                <Form.Field.Select
                  options={noteTypes || []}
                  fullWidth
                  variant="outlined"
                  name="noteType"
                  label="Note Type"
                  optLabel="label"
                  optValue="value"
                  showNone={false}
                />

                <Form.Field.Input
                  fullWidth
                  variant="outlined"
                  name="noteContent"
                  label="Content"
                  multiline={true}
                  maxRows={4}
                  minRows={4}
                />
              </Box>
            )}
          </form>
        );
      }}
    </Form>
  );
}

export default Email;
