import React from 'react';
import { Box, Typography, IconButton, Grid } from '@mui/material';
import CardMedia from '../../shared/cardMedia';

import useStyles from './style';
import { calculateListDaysAgo, removeHTML, truncate } from '../../utils/helper';

import StarIcon from '@mui/icons-material/Star';

function Session({ item, removeFromList }) {
  const classes = useStyles();

  console.log('item?.course : ', item?.course);

  return (
    <>
      <CardMedia
        isClickable={true}
        data={item.course}
        dontShowAction
        src={item?.course?.thumbnailImage || ''}
        type={'thumbnailImage'}
        contentType={'course'}
      />
      <Typography className={classes.header}>{item?.course?.title || ''}</Typography>
      {item?.course?.synopsis ? (
        <Box className={classes.descriptionBox} mt={0.5}>
          <Typography
            className={classes.description}
            dangerouslySetInnerHTML={{
              __html: removeHTML(item?.course?.synopsis),
            }}
          ></Typography>
        </Box>
      ) : null}

      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography className={classes.caption}>{calculateListDaysAgo(item)}</Typography>
        <IconButton
          onClick={() => {
            removeFromList(item?.id);
          }}
        >
          <StarIcon sx={{ fill: 'rgba(255, 212, 126, 1)' }} />
        </IconButton>
      </Box>
    </>
  );
}

export default Session;
