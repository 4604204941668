import * as appTypes from './appTypes';

export const handleBoardResize = (viewport) => ({
  type: appTypes.HANDLE_DASHBOARD_RESIZE,
  viewport,
});

export const setBoardFilters = (filters, boardId) => ({
  type: appTypes.SET_BOARD_FILTERS,
  filters,
  boardId,
});

export const fetchWidgetData = (widgetId) => ({ type: appTypes.FETCH_WIDGET_DATA, widgetId });
export const setWidgetData = (widgetId, data) => ({
  type: appTypes.SET_WIDGET_DATA,
  widgetId,
  data,
});

export const fetchPinnedBoards = () => ({ type: appTypes.FETCH_PINNED_BOARDS });
export const setPinnedBoards = (boards) => ({ type: appTypes.SET_PINNED_BOARDS, boards });

export const fetchBoardTemplates = (filters, paging) => ({
  type: appTypes.FETCH_BOARD_TEMPLATES,
  filters,
  paging,
});
export const setBoardTemplates = (data) => ({ type: appTypes.SET_BOARD_TEMPLATES, data });

export const fetchBoards = (viewport) => ({ type: appTypes.FETCH_BOARDS, viewport });
export const setBoards = (boards) => ({ type: appTypes.SET_BOARDS, boards });

export const postBoard = (board, resolve, reject) => ({
  type: appTypes.POST_BOARD,
  board,
  resolve,
  reject,
});

export const cloneBoard = (boardId, board, resolve, reject) => ({
  type: appTypes.CLONE_BOARD,
  boardId,
  board,
  resolve,
  reject,
});

export const putBoard = (boardId, board, resolve, reject) => ({
  type: appTypes.PUT_BOARD,
  boardId,
  board,
  resolve,
  reject,
});

export const deleteBoard = (boardId, resolve, reject) => ({
  type: appTypes.DELETE_BOARD,
  boardId,
  resolve,
  reject,
});

export const fetchWidgets = (filters, paging) => ({
  type: appTypes.FETCH_WIDGETS,
  filters,
  paging,
});
export const setWidgets = (data) => ({ type: appTypes.SET_WIDGETS, data });

export const setActiveBoard = (boardId, viewport) => ({
  type: appTypes.SET_ACTIVE_BOARD,
  boardId,
  viewport,
});
export const addWidgetsToBoard = (payload, viewport, resolve, reject) => ({
  type: appTypes.ADD_WIDGETS_TO_BOARD,
  payload,
  viewport,
  resolve,
  reject,
});

export const removeWidgetsFromBoard = (widgetId, resolve, reject) => ({
  type: appTypes.REMOVE_WIDGET_FROM_BOARD,
  widgetId,
  resolve,
  reject,
});

export const useTemplateForBoard = (template, resolve, reject) => ({
  type: appTypes.USE_TEMPLATE_FOR_BOARD,
  template,
  resolve,
  reject,
});

export const updateBoardLayout = (widgets, viewport) => ({
  type: appTypes.UPDATE_BOARD_LAYOUT,
  widgets,
  viewport,
});
export const setBoardWidgets = (widgets) => ({ type: appTypes.SET_BOARD_WIDGETS, widgets });

export const clearBoardFilters = (boardId) => ({
  type: appTypes.CLEAR_BOARD_FILTERS,
  boardId,
});
