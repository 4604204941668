import React from 'react';
import { Skeleton, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Avatar, Spacer } from 'src/components/shared';

const Item = ({ width }) => {
  return (
    <ListItem>
      <ListItemIcon sx={{ minWidth: '48px' }}>
        <Skeleton variant="circular" width={30} height={30} />
      </ListItemIcon>
      <ListItemText
        primary={<Skeleton variant="text" width={`${width}%`} height={20} />}
        secondary={<Skeleton variant="text" width="20%" height={16} />}
      />
    </ListItem>
  );
};

function NotificationsSkeleton({ showButtons }) {
  return (
    <>
      <Item width={60} />
      <Spacer basis={2} />
      <Item width={66} />
      <Spacer basis={2} />
      <Item width={45} />
      <Spacer basis={2} />
      <Item width={80} />
      <Spacer basis={2} />
    </>
  );
}

export default NotificationsSkeleton;
