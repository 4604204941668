import { Card, Chip } from '@mui/material';
import styled from 'styled-components';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export const DayChip = styled(Chip).attrs({})`
  border-radius: 4px;
  /* width: 66px; */
  cursor: pointer;
  height: 30px;
  align-items: center;
  opacity: ${(props) => (props.disabled === true ? 0.6 : 1)};
`;

export const CardWrapper = styled(Card)`
  margin-bottom: 10px;
  padding: 6px 10px;
  border: 1px solid #dddddd;
  border-left: ${(props) => (props.type === 'default' ? '4px solid #082037' : 'none')};
  display: flex;
  justify-content: space-between;
`;

export const FieldsTable = styled(Table)``;
export const Tr = styled(TableRow)`
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.12); */
`;
export const Td = styled(TableCell)`
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.12); */
  padding: 4px;
`;
export const Th = styled(TableCell)`
  padding: 4px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.12); */
`;
export const TBody = styled(TableBody)``;

export const TableHeader = ({ view }) => {
  return (
    <TableHead>
      <Tr>
        <Th></Th>
        <Th></Th>
        <Th></Th>
        <Th align="center">Active</Th>
        {view == 'form' ? <Th align="center">Mandatory</Th> : null}
        <Th></Th>
      </Tr>
    </TableHead>
  );
};
