import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchSession, fetchSessionScore } from '../actions/LMSActions';
import SessionDetails from '../components/LMSSession';

const LMSSessionDetailContainer = (props) => {
  const userId = props.user;
  const sessionId = props.match.params.id;
  useEffect(() => {
    const session = [{ value: sessionId }];
    const user = [{ value: userId }];
    props.fetchSession(sessionId);
    props.fetchScore(user, session);
  }, [props.match.params.id, props.user]);

  // return (
  //   <>
  //     <SessionDetail {...props} />
  //   </>
  // );

  return (
    <>
      <SessionDetails {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({
  session: state.lms.session,
  loading: state.lms.loading.session,
  user: state.auth.user.id,
  score: state.lms.score,
});

const mapDispatchToProps = (dispatch) => ({
  fetchScore: (userId, sessionId) => {
    return new Promise((res, rej) => {
      dispatch(fetchSessionScore(userId, sessionId, res, rej));
    });
  },
  fetchSession: (sessionId) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchSession(sessionId, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LMSSessionDetailContainer);
