import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import RolePlayView from './RolePlayView';
import { fetchTenantSettings, updateSettings } from 'src/modules/admin/actions/adminActions';

export const RolePlayContainer = (props) => {
  useEffect(() => {
    if (!props.tenantSettings?.data?.length) {
      props.fetchTenantSettings();
    }
  }, []);

  return <RolePlayView {...props} />;
};

const mapStateToProps = (state) => {
  const tenantSettings = state.admin.admin.tenantSettings?.data?.length
    ? state.admin.admin.tenantSettings?.data[0]
    : {};
  return {
    tenantSettings,
    tenant: state.app.tenant,
    settingValue: state.admin.admin.tenantSettings,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchTenantSettings: () => dispatch(fetchTenantSettings()),
  updateSettings: (data, id) => dispatch(updateSettings(data, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RolePlayContainer);
