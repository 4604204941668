import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {  fetchNotes,deleteNote } from '../actions/LMSActions';
import MyNotes from '../components/MyNotes/MyNotes';

const LMSCreateNotesContainer = (props) => {
  useEffect(() => {
    props.fetchNotes();
  },[]);
  return (
    <>
      <MyNotes {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({
  notes: state.lms.notes,
  loading: state.lms.loading.notes,
});

const mapDispatchToProps = (dispatch) => ({
  fetchNotes: (paging, filters, sort) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchNotes({ paging, filters, sort, resolve, reject }));
    });
  },
  deleteNote: (noteId) => {
    dispatch(deleteNote(noteId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LMSCreateNotesContainer);
