import styled from 'styled-components';
import PhotoIcon from '@mui/icons-material/Photo';

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;

export const CropContainer = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
`;

export const AvatarPreview = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 120px;
`;

export const AvatarImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 120px;
  object-fit: cover;
  cursor: pointer;
  border: 1px solid #cccccc60;
`;

export const AvatarPlaceholder = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 120px;
  background-color: #dddddd;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #999999;
  cursor: pointer;
`;

export const PlaceholderIcon = styled(PhotoIcon)``;
