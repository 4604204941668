import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { grey, green, red } from '@mui/material/colors';
import {
  Box,
  Typography,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Form, Button, Spacer } from 'src/components/shared';

import LockIcon from '@mui/icons-material/Lock';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { PasswordLintAlert } from './styles';

const PasswordLint = ({ isValid, title }) => {
  return (
    <PasswordLintAlert>
      {isValid ? (
        <CheckCircleIcon
          style={{
            color: green[800],
          }}
        />
      ) : (
        <ErrorOutlineIcon
          style={{
            color: grey[400],
          }}
        />
      )}
      <Spacer x={1} y={1} />
      <Typography color={isValid ? green[800] : 'textSecondary'}>{title}</Typography>
    </PasswordLintAlert>
  );
};

function ResetPasswordForm({ query, onSubmit }) {
  const [loading, setLoading] = React.useState(false);

  const lowerCaseRegex = new RegExp('^(?=.*[a-z])');
  const upperCaseRegex = new RegExp('^(?=.*[A-Z])');
  const numberRegex = new RegExp('^(?=.*[0-9])');
  // const specialCharRegex = new RegExp('^(?=.*[!@#$%^&*])');
  const specialCharRegex = new RegExp(/[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/);
  const eightChatRegex = new RegExp('^(?=.{8,})');

  return (
    <>
      <Form
        initialValues={{
          password: '',
          confPassword: '',
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .required('Password is required!')
            .min(8, 'Password must be at least 8 characters long!')
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
              'Password must contain 8 Characters, One Uppercase, One Lowercase, One Number and one Special Character',
            ),
          confPassword: Yup.string()
            .required('Confirm Password is required!')
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        })}
        onSubmit={async (values, form) => {
          form.prevent;
          setLoading(true);
          await onSubmit(values, form);
          setLoading(false);
        }}
      >
        {(props) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                props.submitForm();
                return false;
              }}
              noValidate
            >
              <Form.Field.Input
                fullWidth
                variant="outlined"
                type="password"
                name="password"
                placeholder="New Password"
                disabled={loading}
                autoComplete="on"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon style={{ fill: '#d1d1d1' }} />
                    </InputAdornment>
                  ),
                }}
              />

              <Form.Field.Input
                fullWidth
                variant="outlined"
                name="confPassword"
                type="password"
                placeholder="Confirm New Password"
                disabled={loading}
                autoComplete="on"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon style={{ fill: '#d1d1d1' }} />
                    </InputAdornment>
                  ),
                }}
              />

              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={loading}
                  fullWidth
                  size="large"
                  endIcon={<ArrowForwardIcon />}
                >
                  Next
                </Button>
              </Box>
              <Spacer x={2} y={2} />
              <div>
                <PasswordLint
                  isValid={eightChatRegex.test(props.values.password)}
                  title="At least 8 characters long"
                />
                <PasswordLint
                  isValid={lowerCaseRegex.test(props.values.password)}
                  title="One lowercase character"
                />
                <PasswordLint
                  isValid={upperCaseRegex.test(props.values.password)}
                  title="One uppercase character"
                />
                <PasswordLint
                  isValid={numberRegex.test(props.values.password)}
                  title="One numeric character"
                />
                <PasswordLint
                  isValid={specialCharRegex.test(props.values.password)}
                  title="One special character"
                />
              </div>

              {/* <List dense>
                <ListItem dense>
                  <ListItemIcon>
                    <CheckCircleIcon
                      style={{
                        color: eightChatRegex.test(props.values.password)
                          ? green[900]
                          : grey[300],
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText secondary="At least 8 characters long" />
                </ListItem>
                <ListItem dense>
                  <ListItemIcon>
                    <CheckCircleIcon
                      style={{
                        color: lowerCaseRegex.test(props.values.password)
                          ? green[900]
                          : grey[300],
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText secondary="One lowercase character" />
                </ListItem>
                <ListItem dense>
                  <ListItemIcon>
                    <CheckCircleIcon
                      style={{
                        color: upperCaseRegex.test(props.values.password)
                          ? green[900]
                          : grey[300],
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText secondary="One uppercase character" />
                </ListItem>
                <ListItem dense>
                  <ListItemIcon>
                    <CheckCircleIcon
                      style={{
                        color: numberRegex.test(props.values.password)
                          ? green[900]
                          : grey[300],
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText secondary="One numeric character" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleIcon
                      style={{
                        color: specialCharRegex.test(props.values.password)
                          ? green[900]
                          : grey[300],
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText secondary="One special character" />
                </ListItem>
              </List> */}
            </form>
          );
        }}
      </Form>
    </>
  );
}

export default ResetPasswordForm;
