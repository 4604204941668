import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import EditTemplate from '../components/MyTemplates/EditTemplate';

export const LMSEditTemplateContainer = (props) => {
  const templateId = props.match.params.id;

  return (
    <>
      <EditTemplate templateId={templateId} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LMSEditTemplateContainer);
