import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, useTheme } from '@mui/material';
import { Button, Spacer } from 'src/components/shared';
import images from 'src/config/images';
import styled from 'styled-components';

const Icon = styled.img`
  width: 100px;
  height: auto;
  margin-bottom: 20px;
`;

function Empty({ title, description, buttonTitle, onClick, buttonProps, showIcon }) {
  const { palette } = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      maxWidth={400}
      margin="20px auto"
    >
      {showIcon && (
        <Icon
          src={palette.mode === 'dark' ? images.LMS.empty : images.app.emptyIcon}
          style={{ width: '54px' }}
        />
      )}

      <Typography variant="h3" color="textSecondary" textAlign="center">
        {title}
      </Typography>
      <Spacer x={1} y={1} />
      {description && description !== '' ? (
        <Typography variant="body2" color="textSecondary" textAlign="center">
          {description}
        </Typography>
      ) : null}

      {buttonTitle && buttonTitle !== '' ? (
        <>
          <Spacer x={2} y={2} />
          <Button onClick={onClick} {...buttonProps}>
            {buttonTitle}
          </Button>
        </>
      ) : null}
    </Box>
  );
}

Empty.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonTitle: PropTypes.string,
  buttonProps: PropTypes.object,
  showIcon: PropTypes.bool,
};

Empty.defaultProps = {
  onClick: () => {},
  title: '',
  description: '',
  buttonTitle: '',
  buttonProps: {
    size: 'small',
    color: 'secondary',
    variant: 'contained',
  },
  showIcon: true,
};

export default Empty;
