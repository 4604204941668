import * as sequenceTypes from '../actions/sequenceTypes';
import { RESET_STORE } from 'src/modules/app/actions/appTypes';
import { sequenceStepTypes } from '../config';
const initialState = {
  loading: {
    sequences: false,
    sequence: false,
    sequenceEvents: false,
    sequencesList: false,
    unsubscribeCheck: false,
    sequencesContacts: false,
    sequenceStats: false,
  },
  // sequenceStats: sequenceStepTypes,
  sequenceStats: [],
  enrollmentsStats: {},
  sequences: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    sort: {},
    filters: {},
    data: [],
    columns: [],
    columnsOrder: [],
  },
  sequencesContacts: {
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    sort: {},
    filters: {},
    data: [],
    columns: [],
    columnsOrder: [],
  },
  sequenceTab: 'published',
  sequence: {},
  sequenceEvents: [],
  sequenceList: [],
  isUnsubscribeCheck: false,
};

const SequenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case sequenceTypes.CLEAR_SEQUENCE_DATA:
      return {
        ...state,
        sequence: {},
        sequenceEvents: [],
        sequenceList: [],
        sequencesContacts: {
          paging: {
            pageNo: 0,
            perPage: 10,
            count: 0,
          },
          sort: {},
          filters: {},
          data: [],
          columns: [],
          columnsOrder: [],
        },
        sequenceStats: [],
        enrollmentsStats: {},
      };

    case sequenceTypes.FETCH_SEQUENCE_STATS:
      return {
        ...state,
        loading: {
          ...state.loading,
          sequenceStats: true,
        },
        // sequenceStats: sequenceStepTypes,
        sequenceStats: [],
        enrollmentsStats: {},
      };

    case sequenceTypes.SET_SEQUENCE_STATS:
      return {
        ...state,
        loading: {
          ...state.loading,
          sequenceStats: false,
        },
        sequenceStats: action.steps,
        enrollmentsStats: action.enrollmentsStats,
      };

    case sequenceTypes.FETCH_SEQUENCE_EVENTS:
      return {
        ...state,
        loading: {
          ...state.loading,
          sequenceEvents: true,
        },
        sequenceEvents: [],
      };

    case sequenceTypes.SET_SEQUENCE_EVENTS:
      return {
        ...state,
        loading: {
          ...state.loading,
          sequenceEvents: false,
        },
        sequenceEvents: action.events,
      };

    case sequenceTypes.SET_SEQUENCE_TAB:
      return {
        ...state,
        sequenceTab: action.tab,
      };

    case sequenceTypes.FETCH_SEQUENCE:
      return {
        ...state,
        loading: {
          ...state.loading,
          sequence: true,
        },
        sequence: {},
      };

    case sequenceTypes.SET_SEQUENCE:
      return {
        ...state,
        loading: {
          ...state.loading,
          sequence: false,
        },
        sequence: action.sequence,
      };

    case sequenceTypes.FETCH_SEQUENCE_CONTACTS:
      return {
        ...state,
        loading: {
          ...state.loading,
          sequencesContacts: true,
        },
        sequencesContacts: {
          ...state.sequencesContacts,
          paging: {
            ...state.sequencesContacts.paging,
            ...action.paging,
          },
          data: [],
          filters: {
            ...state.sequencesContacts.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };

    case sequenceTypes.SET_SEQUENCE_CONTACTS:
      return {
        ...state,
        loading: {
          ...state.loading,
          sequencesContacts: false,
        },
        sequencesContacts: {
          ...state.sequencesContacts,
          data: action.data.enrolments,
          paging: {
            ...state.sequencesContacts.paging,
            count: action.data.total.value,
          },
        },
      };

    case sequenceTypes.FETCH_SEQUENCES:
      return {
        ...state,
        loading: {
          ...state.loading,
          sequences: true,
        },
        sequences: {
          ...state.sequences,
          paging: {
            ...state.sequences.paging,
            ...action.paging,
          },
          data: [],
          filters: {
            ...state.sequences.filters,
            ...action.filters,
          },
          sort: action.sort,
        },
      };

    case sequenceTypes.SET_SEQUENCES:
      return {
        ...state,
        loading: {
          ...state.loading,
          sequences: false,
        },
        sequences: {
          ...state.sequences,
          data: action.sequences,
          paging: {
            ...state.sequences.paging,
            count: action.total.value,
          },
        },
      };

    case sequenceTypes.FETCH_SEQUENCES_LIST:
      return {
        ...state,
        loading: {
          ...state.loading,
          setSequencesList: true,
        },
        setSequencesList: [],
      };

    case sequenceTypes.SET_SEQUENCES_LIST:
      return {
        ...state,
        loading: {
          ...state.loading,
          sequencesList: false,
        },
        sequencesList: action.data,
      };

    case sequenceTypes.IS_UNSUBSCRIBE_CHECK:
      return {
        ...state,
        loading: {
          ...state.loading,
          unsubscribeCheck: true,
        },
        isUnsubscribeCheck: false,
      };

    case sequenceTypes.IS_UNSUBSCRIBE_SET:
      return {
        ...state,
        loading: {
          ...state.loading,
          isUnsubscribeCheck: false,
        },
        sequencesList: action.data,
      };

    case RESET_STORE:
      return initialState;

    default:
      return state;
  }
};
export default SequenceReducer;
