import React from 'react';
import { Box } from '@mui/material';
import { LoadingContainer, LoadingLogo } from './styles';

function AppLoader() {
  return (
    <div>
      <div className="wrapper">
        <div className="page-content">
          <section className="desktop-front-bg">
            <LoadingContainer>
              <LoadingLogo />
            </LoadingContainer>
          </section>
        </div>
      </div>
    </div>
  );
}

export default AppLoader;
