import { createContext, useState, useContext } from 'react';
const dashContext = createContext({});
import usePersistState from 'src/components/hooks/usePersistState';

import {
  getNotifications,
  fetchNotificationCount,
  updateNotifications,
} from 'src/modules/app/api/appApis';

export const useNotificationsStore = () => {
  const {
    notifications,
    notificationCount,
    fetchNotifications,
    clearNotifications,
    getNotificationsCount,
    clearSingle,
    clearId,
  } = useContext(dashContext);
  return {
    notifications,
    notificationCount,
    fetchNotifications,
    clearNotifications,
    getNotificationsCount,
    clearSingle,
    clearId,
  };
};

export const NotificationsProvider = ({ children }) => {
  const [notificationCount, setNotificationCount] = useState(0);
  const [clearId, setClearId] = useState('');
  const [notifications, setNotifications] = useState({
    loading: false,
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    data: [],
  });

  const clearSingle = async (id) => {
    try {
      setClearId(id);
      await updateNotifications([id], {
        seen: true,
        fetched: true,
      });
      await fetchNotifications(notifications?.paging || {}, notifications?.filters || {}, false);
      setClearId('');
    } catch (error) {}
  };

  const clearNotifications = async () => {
    try {
      if (notifications?.data?.length)
        await updateNotifications(
          notifications?.data?.map((item) => item.id),
          {
            seen: true,
            fetched: true,
          },
        );
      fetchNotifications(notifications?.paging);
    } catch (error) {}
  };

  const getNotificationsCount = async () => {
    try {
      const res = await fetchNotificationCount();
      setNotificationCount(() => res.count);
    } catch (error) {
      setNotificationCount(0);
    }
  };

  const fetchNotifications = async (newPage = {}, filters = {}, loading = true) => {
    try {
      loading &&
        setNotifications((prevState) => ({
          ...prevState,
          loading: true,
          data: [],
          paging: {
            ...prevState.paging,
            pageNo: newPage.pageNo,
            perPage: newPage.perPage,
          },
          filters,
        }));
      let filter = {};
      if (filters && filters?.type && filters?.type.length) {
        filter = {
          type_eq: filters?.type.map((item) => item.value).join(','),
        };
      }
      const res = await getNotifications(newPage, filter);
      setNotifications((prevState) => ({
        ...prevState,
        loading: false,
        data: res.notes,
        paging: {
          ...prevState.paging,
          count: res.total?.value || 0,
        },
      }));
      // if (res?.notes?.length > 0)
      //   updateNotifications(
      //     res.notes.map((item) => item.id),
      //     {
      //       seen: true,
      //       fetched: true,
      //     },
      //   );
      getNotificationsCount();
    } catch (error) {}
  };

  return (
    <dashContext.Provider
      value={{
        notifications,
        notificationCount,
        fetchNotifications,
        clearNotifications,
        getNotificationsCount,
        clearSingle,
        clearId,
      }}
    >
      {children}
    </dashContext.Provider>
  );
};

export const withNotificationsProvider = (Component) => (props) =>
  (
    <NotificationsProvider>
      <Component {...props} />
    </NotificationsProvider>
  );
