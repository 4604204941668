export const getPercentage = (value, total, isString = false) => {
  let progress = 0;
  if (parseInt(value) > 0 && parseInt(total) > 0) {
    progress = Math.floor((100 * value) / total);
  }
  if (isString) {
    return `${progress}%`;
  } else {
    return progress;
  }
};

export const handleEventsSorting = (events, event, eventOrder, increment = true) => {
  events = events.sort(function (a, b) {
    return a.eventOrder - b.eventOrder;
  });

  let sortedEvents = [
    ...events.filter((item) => item.eventOrder < eventOrder),
    {
      ...event,
      eventOrder,
    },
    ...events
      .filter((item) => item.eventOrder >= eventOrder && item.id !== event.id)
      .map((item) => ({ ...item, eventOrder: increment ? item.eventOrder + 1 : item.eventOrder })),
  ];
  sortedEvents = Object.assign({}, ...sortedEvents.map((event) => ({ [event.id]: event })));
  return sortedEvents;
};

export const getFilterLabel = (text = '', data) => {
  let newLabel = {};
  switch (text) {
    case 'assignees': {
      newLabel = {
        type: 'assignees',
        title: 'All Assignees',
        isSingle: true,
        showName: true,
      };
      break;
    }
    case 'created_by': {
      newLabel = {
        type: 'created_by',
        title: 'Created by',
      };
      break;
    }
    case 'type': {
      newLabel = {
        type: 'type',
        title: 'Type',
      };
      break;
    }

    case 'access_level': {
      newLabel = {
        type: 'access_level',
        title: 'Access level',
      };
      break;
    }
    case 'in_progress': {
      newLabel = {
        type: 'in_progress',
        title: 'In Progress',
      };
      break;
    }
    default: {
      newLabel = {};
      break;
    }
  }

  return newLabel;
};

export const parseSequenceFilterData = (data, tab) => {
  let filterListData = Object.keys(data);
  filterListData = filterListData
    .filter((e) => e !== 'created_date')
    .map((k) => ({
      name: k,
      data: data[k],
      optionLabel: 'name',
      optionValue: 'id',
      ...getFilterLabel(k, data),
    }));

  filterListData.push({
    name: 'createDate',
    control: 'dateRangePicker',
    isSingle: true,
    title: 'Created date',
    type: 'createDate',
    from: 'createdFrom',
    to: 'createdTo',
  });

  return filterListData;
};
